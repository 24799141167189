import { __, date, DATE_FORMAT, IDateRange } from 'common-services';
import * as React from 'react';

import { ColumnContainer } from '../../../atoms';
import * as S from './OrderImportWidget.styled';

export type OrderImportWidgetType = 'time-saved' | 'errors' | 'imported-orders';

export interface IProps {
  className?: string;
  dateRange: IDateRange;
  dates?: { beginDate: Date; endDate: Date };
  dateFormat?: DATE_FORMAT;
  value: number;
  type: OrderImportWidgetType;
  mean: number;
  showTooltip: boolean;
}

const OrderImportWidget: React.FC<IProps> = ({
  className,
  dateRange,
  dates,
  dateFormat,
  mean,
  value,
  showTooltip,
  type,
}) => {
  const dateRangeDescription = date.getDateRangeShortDescription(dateRange, dates, dateFormat);
  return (
    <S.Container className={className}>
      <S.HeaderRow>
        <ColumnContainer>
          <S.TextTitle>{getTitle(type)}</S.TextTitle>
          {dateRangeDescription ? <S.TextGrey>{dateRangeDescription}</S.TextGrey> : null}
        </ColumnContainer>
        <S.IconContainer widgetType={type}>
          <S.WidgetIcon disableHover={true} name={getIconName(type)} widgetType={type} />
        </S.IconContainer>
      </S.HeaderRow>
      <S.ValueContainer>
        {type === 'time-saved' ? renderTimeValue(value) : <S.TextValue>{value}</S.TextValue>}
      </S.ValueContainer>
      <S.TextGrey>
        {__('OrderImportWidget.mean', { value: type === 'time-saved' ? getMeanTime(mean) : mean })}
      </S.TextGrey>
      {showTooltip ? (
        <S.TooltipContainer>
          <S.Tooltip
            position="bottom"
            text={__('OrderImportWidget.tooltip')}
            width="200px"
            className="tooltip-widget"
            themeMode="dark"
          >
            <S.InfoIcon name="Info" disableHover={true} />
          </S.Tooltip>
        </S.TooltipContainer>
      ) : null}
    </S.Container>
  );
};

function renderTimeValue(value: number) {
  const hours = Math.floor(value / 3600).toFixed(0);
  const minutes = (Math.floor(value / 60) % 60).toFixed(0);
  return (
    <S.TextValue>
      {hours}
      <S.TextTimeUnit>{__('OrderImportWidget.hours')}</S.TextTimeUnit>
      {minutes}
      <S.TextTimeUnit>{__('OrderImportWidget.minutes', { count: minutes })}</S.TextTimeUnit>
    </S.TextValue>
  );
}

function getMeanTime(mean: number) {
  const hours = Math.floor(mean / 3600).toFixed(0);
  const minutes = (Math.floor(mean / 60) % 60).toFixed(0);
  const result = hours ? hours + __('OrderImportWidget.hours') : '';
  return `${result} ${minutes}${__('OrderImportWidget.minutes', { count: minutes })}`;
}

function getTitle(type: OrderImportWidgetType) {
  switch (type) {
    case 'time-saved':
      return __('OrderImportWidget.time-saved');
    case 'errors':
      return __('OrderImportWidget.errors');
    default:
      return __('OrderImportWidget.imported-orders');
  }
}

function getIconName(type: OrderImportWidgetType) {
  switch (type) {
    case 'time-saved':
      return 'Clock';
    case 'errors':
      return 'Error';
    default:
      return 'Info';
  }
}

export default React.memo(OrderImportWidget);
