import { __ } from 'common-services';
import { FormSection } from '../../../../molecules';
import * as S from './ReferenceListDetail.style';
import { columnsProductTable, getColumnsProductTable } from './ReferenceListDetail.constants';
import * as React from 'react';
import { IGroupedDataReferenceList } from 'common-services/dist/associate-reference/interface';

interface ProductFormProps {
  data: IGroupedDataReferenceList;
  productRef: React.MutableRefObject<HTMLDivElement>;
}

const ProductFormComponent: React.FC<ProductFormProps> = ({ data, productRef }) => {
  return (
    <>
      <FormSection
        id="product"
        title={__('Components.SellerOffer.referenceList.referenceListSelected.productSection.title')}
        sectionRef={productRef}
        withoutPaddingBottom={true}
      >
        <S.ProductTable
          selectable={false}
          columns={getColumnsProductTable()}
          emptyText=""
          onClickRow={() => null}
          values={[data]}
          productColumns={columnsProductTable}
        />
      </FormSection>
    </>
  );
};

export default ProductFormComponent;
