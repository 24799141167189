import { __ } from 'common-services';
import * as React from 'react';

import * as S from './StepIndicator.styled';

export interface IProps {
  className?: string;
  current?: number;
  lang?: LOCALE;
  numberOfSteps?: number;
  showTextStep?: boolean;
}

const StepIndicator: React.FC<IProps> = ({ numberOfSteps = 5, lang, current = 0, className, showTextStep = false }) => {
  const components = [];

  for (let i = 0; i < numberOfSteps; i++) {
    const isSelected = i <= current;
    components.push(<S.StepBar key={'step-' + i} isSelected={isSelected} />);
  }

  return (
    <S.Container className={className}>
      {showTextStep ? (
        <S.TextStep>{__('LoginRegisterFlow.step', { step: current + 1, total: numberOfSteps, lang })}</S.TextStep>
      ) : null}
      <S.Row>{components}</S.Row>
    </S.Container>
  );
};

export default StepIndicator;
