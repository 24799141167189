import { __, ImageGalleryObject, IMessage } from 'common-services';
import * as React from 'react';

import * as S from './ImageMessage.styled';

export interface IProps {
  imageUrls?: Array<string>;
  message: IMessage;
  onDownloadImage?: (message: IMessage) => void;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
}

const ImageMessage: React.FC<IProps> = ({ imageUrls, message, onDownloadImage, touchImage }) => {
  // force media domain migration
  // *.cloudinary.com is apparently banned in some organization proxies
  const imageUrl = message.message
    .replace('res.cloudinary.com/consentiotrade', 'media.consentio.co')
    .replace('consentiotrade-res.cloudinary.com', 'media.consentio.co');

  if (message.isRemoved)
    return (
      <S.TextWrapper className="image-message-container">
        <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
      </S.TextWrapper>
    );

  return (
    <S.Container
      className="image-message-container"
      onClick={() => {
        const images: Array<ImageGalleryObject> = (imageUrls || [imageUrl]).map(url => ({
          src: url,
        }));
        touchImage(images, imageUrls?.indexOf(imageUrl) || 0);
      }}
    >
      <S.ImgContainer>
        <S.Img src={imageUrl} />
        {renderActions()}
      </S.ImgContainer>
    </S.Container>
  );

  /**
   * Render actions row
   */
  function renderActions() {
    if (!onDownloadImage) return null;
    return (
      <S.Actions className="image-actions" onClick={e => e.stopPropagation()}>
        {onDownloadImage ? <S.Download name="Download" onClick={() => onDownloadImage(message)} /> : null}
      </S.Actions>
    );
  }
};

export default React.memo(ImageMessage);
