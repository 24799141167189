import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, TagBubble, TextLabel } from '../../atoms';
import SimpleSearchComponent from '../../molecules/SimpleSearch/SimpleSearch.component';

interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const WsWrapper = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
  justify-content: center;
  align-items: center;
`;

const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const HeaderDashboards = styled(ColumnContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const HeaderFilters = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const LeftFiltersRow = styled(RowContainer)`
  align-items: center;
`;

export const SimpleSearch = styled(SimpleSearchComponent)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 235px;
  max-width: 492px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 185px;
  }
`;

export const RightCtaRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
