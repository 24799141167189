import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, DropzoneContainer as DC, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  height: 100%;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  justify-content: flex-start;
  position: relative;
  outline: none;
  overflow: hidden;
`;

export const DropzoneContainer = styled(DC)`
  height: 100%;
`;

export const CartContainer = styled(RowContainer)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.headerHeight};
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  height: calc(${(p: IStyledProps) => p.theme.contentHeight} - ${(p: IStyledProps) => p.theme.headerHeight});
  width: 100%;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
`;

export const GroupHeaderContainer = styled(ColumnContainer)`
  min-height: min-content;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TextBold = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ButtonsContainer = styled(RowContainer)`
  button:first-of-type {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  }
`;

export const GroupHeaderButton = styled(Button)``;
