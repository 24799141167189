import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
