import { ORDER_STATUS } from 'common-services';

import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, LettersAvatar, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  first?: boolean;
  status?: ORDER_STATUS;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  height: calc(
    ${(p: IStyledProps) => p.theme.contentHeightWithBreadcrumb} - ${(p: IStyledProps) => p.theme.headerHeight} * 2
  );
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  overflow: auto;
`;

export const TableOrders = styled.table`
  border-collapse: separate;
  border-spacing: 0x;
  width: 100%;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Header = styled.tr`
  height: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const Footer = styled.tr`
  td {
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const Body = styled.tbody`
  height: 100%;
`;

export const Row = styled(RowContainer)`
  align-items: center;
`;

export const ImageCol = styled.td`
  vertical-align: middle;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(9)};
`;
export const ImageColHead = styled.td``;

const Col = styled.td`
  width: 15%;
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const BigCol = styled(Col)`
  width: 25%;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const RegularCol = styled(Col)`
  width: 16%;
`;
export const SmallCol = styled(Col)`
  width: 10%;
`;
export const SmallColRight = styled(SmallCol)`
  text-align: right;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

const Head = styled.td`
  width: 15%;
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const BigHead = styled(Head)`
  width: 25%;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const RegularHead = styled(Head)`
  width: 16%;
`;
export const SmallHead = styled(Head)`
  width: 10%;
`;
export const SmallHeadRight = styled(SmallHead)`
  text-align: right;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TotalImageCol = styled.td`
  vertical-align: middle;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

const TotalCol = styled.td`
  width: 15%;
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TotalBigCol = styled(TotalCol)`
  width: 25%;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const TotalRegularCol = styled(TotalCol)`
  width: 20%;
`;
export const TotalSmallCol = styled(TotalCol)`
  width: 10%;
  text-align: right;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const OrderTr = styled.tr`
  height: 72px;
  max-height: 72px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  cursor: pointer;
  overflow: hidden;
  > td {
    max-height: 72px;
    height: 72px;
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    border-top: ${(p: IStyledProps) => (p.first ? '1px solid ' + p.theme.colors.grey3 : 'none')};
  }
`;

export const ContactImagesRow = styled(RowContainer)`
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const ContactImage = styled(LettersAvatar)`
  width: 30px;
  height: 30px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: 4px;
`;

export const ContactNameColumn = styled(ColumnContainer)`
  justify-content: center;
  min-width: 85px;
`;

export const TextCompany = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextName = styled(TextCompany)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
