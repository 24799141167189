import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, ImageCover, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  color?: string;
  from?: 'showroom' | 'pricelist' | 'product'; // 'product' for ProductCard component
  image?: string;
  margin?: string;
  theme: ITheme;
}

export const Body = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 5 : 3)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const AboutContainer = styled(ColumnContainer)`
  width: 100%;
  max-width: 1117px;
  align-self: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

export const CompanyImage = styled(ImageCover)`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const AboutTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const AboutSubtitle = styled(AboutTitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const AboutText = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  line-height: 24px;
`;

export const AboutParagraph = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const AboutLink = styled(AboutParagraph)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Contact = styled.div`
  margin: 0;
  height: auto;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ContactCard = styled(Contact)`
  height: auto;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ContactInfo = styled(RowContainer)`
  align-items: flex-start;
`;

export const Name = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const PhoneEmail = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: flex;
  flex-direction: row;
  text-decoration: none;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Avatar = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-image: url(${(p: IStyledProps) => p.image});
`;

export const ChatButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Icon = styled(FontIcon)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
