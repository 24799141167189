import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}
export const Container = styled.div`
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme!.colors.grey3};
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Content = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  text-transform: uppercase;
  align-items: center;
  align-self: center;
`;

export const Title = styled.div`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: 0 11px;
  cursor: default;
  align-self: center;
  text-align: right;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: center;
`;

export const SubTitle = styled.div`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: 0 11px 0 0;
  cursor: default;
  align-self: center;
  text-align: left;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const RightContainer = styled(ColumnContainer)`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const MembersContainer = styled(RowContainer)`
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  align-items: center;
  justify-content: center;
  height: 46px;
`;

export const AddMember = styled(RowContainer)`
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  height: 36px;
  justify-content: center;
  width: 36px;
  z-index: 1;
`;
