import * as React from 'react';

import FontIcon, { IFontIconKeys } from '../FontIcon';
import * as S from './SuggestionsDropdown.styled';

export interface IItem {
  key: string;
  value: string;
  value2?: string;
  icon?: IFontIconKeys;
  customProps?: any;
}

export interface IProps {
  children: React.ReactNode;
  closeOnClick?: boolean;
  options?: Array<IItem>;
  width?: string;
}

interface IState {
  isVisible: boolean;
}

export default class SuggestionsDropdown extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      isVisible: false,
    };
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { children, closeOnClick, options = [], width } = this.props;
    const { isVisible } = this.state;

    return (
      <S.Container ref={this.containerRef} onMouseLeave={() => (closeOnClick ? null : this.hide())} width={width}>
        <S.Trigger onClick={this.handleOnFocus} onFocus={this.handleOnFocus}>
          {children}
        </S.Trigger>
        {isVisible && (
          <S.Content position={'bottom'} numOptions={options.length}>
            {options.map((o: IItem, idx: number, array) => (
              <S.Item
                key={o.key}
                onClick={e => {
                  e.stopPropagation();
                }}
                isLast={idx === array.length - 1}
                {...o?.customProps}
              >
                {o.icon ? (
                  <S.IconItem>
                    <FontIcon name={o.icon} />
                  </S.IconItem>
                ) : null}
                <S.ItemText>
                  <S.ItemTitle>{o.value}</S.ItemTitle>
                  {o.value2 ? <S.ItemSubtitle>{o.value2}</S.ItemSubtitle> : null}
                </S.ItemText>
              </S.Item>
            ))}
          </S.Content>
        )}
      </S.Container>
    );
  }

  /**
   * Hide content
   */
  public hide() {
    this.setState({ isVisible: false });
  }

  /**
   * Show content
   */
  public show() {
    this.setState({ isVisible: true });
  }

  /**
   * Alert if clicked on outside of element
   */
  private handleClickOutside = (event: MouseEvent) => {
    if (this.containerRef?.current && !this.containerRef.current.contains(event.target as any)) {
      this.hide();
    }
  };

  /**
   * Event to be called to get focus either on click or on focus
   */
  private handleOnFocus = (event: React.FocusEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.show();
  };
}
