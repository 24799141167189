import * as React from 'react';
import * as S from './IOSLoading.styled';

export interface IProps {
  className?: string;
  color?: string;
  loading: boolean;
}

const IOSLoading: React.FC<IProps> = React.memo(({ className, color, loading = true }) => {
  if (!loading) return null;

  const loadingBars = [];
  for (let i = 0; i < 12; i++) {
    loadingBars.push(<S.LoadingBar key={i} />);
  }
  return (
    <S.Container className={className} color={color}>
      {loadingBars}
    </S.Container>
  );
});

export default IOSLoading;
