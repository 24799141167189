import styled, { css, ITheme } from '../../../styled-components';
import {
  Badge as B,
  Button,
  ColumnContainer,
  FontIcon,
  LettersAvatar,
  Ribbon,
  RowContainer,
  SelectWorkingStatus as SWS,
  SimpleDropdown,
  TextLabel,
} from '../../atoms';
import { InputWithLabel as IWL, NavigationTabs as NT } from '../../molecules';

interface IStyledProps {
  hasAction?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  padding?: string;
  theme: ITheme;
  isSelected?: boolean;
  badgeCount?: number;
  isMain?: boolean;
}

export const Container = styled.header`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(500px, 5fr) fit-content(200px);
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  padding: 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const LeftContainer = styled(RowContainer)`
  align-items: center;
`;

export const RightContainer = styled(RowContainer)`
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  max-height: calc(${(p: IStyledProps) => p.theme.headerHeight} - 3px);

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  }
`;

export const TextContainer = styled(TextLabel)`
  padding-top: 3px;
`;

export const Item = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => (p.isLast && !p.isFirst ? p.theme.colors.green1 : p.theme.colors.black)};
  cursor: ${(p: IStyledProps) => (p.hasAction ? 'pointer' : 'default')};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Breadcrum = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.isLast && !p.isFirst ? p.theme.colors.black : p.theme.colors.grey1)};
  cursor: ${(p: IStyledProps) => (p.hasAction ? 'pointer' : 'default')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const UserSettingsContainer = styled(SimpleDropdown)`
  position: relative;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const UserRow = styled(RowContainer)`
  max-width: 300px;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    max-width: 260px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 220px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 100px;
  }
`;

export const UserAvatar = styled(LettersAvatar)`
  display: flex;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
`;

export const UserAvatarModal = styled(LettersAvatar)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextRegular = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
  
`;

export const ChevronIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 12px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: 2px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  }
`;

export const UserStatusModal = styled(ColumnContainer)`
  position: absolute;
  right: -10px;
  top: 42px;
  z-index: 50;
  width: 356px;
  max-width: 356px;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  margin: 0;
  min-height: min-content;
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  overflow: hidden;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};

  #main-header-notifications {
    margin-bottom: 0;
  }
`;

export const HeaderUser = styled.div`
  display: grid;
  grid-template-columns: 42px minmax(200px, 1fr);
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  overflow: hidden;
`;

export const UserNameCol = styled(ColumnContainer)``;

export const TextName = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextSubtitle = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DividerGrey = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const WorkingStatusLink = styled(Button)`
  height: 24px;
`;

export const InputWithLabel = styled(IWL)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: 0;
`;

export const SelectWorkingStatus = styled(SWS)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 100%;
`;

export const MenuButton = styled(Button)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TermsContainer = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextSmall = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextLink = styled.a`
  display: inline;
  text-decoration: none;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  cursor: pointer;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  &:hover {
    opacity: 0.8;
  }
`;

export const TextGrey2 = styled(TextSmall)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-bottom: 0;
`;

export const NotificationsModal = styled(ColumnContainer)`
  position: absolute;
  right: -10px;
  top: 42px;
  z-index: 50;
  width: 545px;
  max-width: 545px;
  max-height: 90vh;
  overflow: auto;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  margin: 0;
  min-height: min-content;
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};

  #main-header-notifications {
    margin-bottom: 0;
  }
`;

const getBadgeWidth = (p: IStyledProps) => {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '16px';
  if (p.badgeCount < 1000) return '23px';
  return '30px';
};

export const NotificationsContainer = styled(SimpleDropdown)`
  position: relative;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(p.badgeCount > 0 ? 2.5 : 2)};

  &:after {
    content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
    background-color: ${(p: IStyledProps) => p.theme.colors.red1};
    width: ${getBadgeWidth};
    height: 15px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    padding-right: 1px;
    transform: translateX(50%);
    color: ${(p: IStyledProps) => p.theme.colors.white};
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
    padding-left: 1px;
    padding-top: 1px;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  }
`;

export const NotificationRow = styled(RowContainer)`
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Header = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TitleRowNotifications = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
`;

export const NotificationTitle = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
`;

export const KebabIcon = styled(FontIcon)`
  font-size: 20px;
  height: 26px;
  width: 26px;
  line-height: 26px;
  padding-bottom: 2px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const NotificationIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 18px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: 2px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  }
`;
export const HeaderNotifications = styled(RowContainer)`
  align-items: left;
`;
export const NotificationTab = styled(TextLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  cursor: pointer;
  ${(p: IStyledProps) =>
    p.isSelected &&
    css`
      background-color: ${p.theme.colors.grey4};
      color: ${p.theme.colors.black};
      font-weight: ${p.theme.fontWeight.Bold};
    `}
`;

export const TextBlue = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  align-self: end;
  cursor: pointer;
`;

export const NotificationsList = styled(ColumnContainer)`
  overflow: auto;
`;

export const Row = styled(RowContainer)`
  align-items: center;
`;
export const ZeroCaseWrapper = styled(ColumnContainer)`
  align-items: center;
`;

export const Image = styled.img<IStyledProps>`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(6)} 0;
  max-width: 170px;
  min-width: 170px;
`;

export const ZeroCaseTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-align: center;
`;
export const ZeroCaseText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  text-align: center;
`;

export const Badge = styled(RowContainer)`
  font-family: 'Nunito';
  background-color: ${(p: IStyledProps) => p.theme.colors.red1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: 50%;
  text-align: center;
  display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding: 0px ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const NavigationTabs = styled(NT)`
  width: auto;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(9)};
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  & .cart-tab {
    border-bottom: 2px solid transparent;
    color: ${(p: IStyledProps) => p.theme.colors.green1};
    & .cart-tab-container {
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
      border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
      color: ${(p: IStyledProps) => p.theme.colors.green1};
      background-color: ${(p: IStyledProps) => p.theme.colors.white};
      border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
      padding: 2px 10px;
      margin: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: ${(p: IStyledProps) => p.theme.paddingSize()};
    }
  }
  & .cart-tab.selected {
    border-bottom: 2px solid transparent;
    & .cart-tab-container {
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
      background-color: ${(p: IStyledProps) => p.theme.colors.green1};
      border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
      padding: 2px 10px;
      margin: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: ${(p: IStyledProps) => p.theme.paddingSize()};
    }
  }
  & .cart-tab.selected-empty {
    border-bottom: 2px solid transparent;
    & .cart-tab-container-empty {
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
      background-color: ${(p: IStyledProps) => p.theme.colors.grey1};
      border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
      margin: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: 2px 10px;
    }
  }
  & .cart-tab-empty {
    border-bottom: 2px solid transparent;
    color: ${(p: IStyledProps) => p.theme.colors.white};
    & .cart-tab-container-empty {
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
      background-color: ${(p: IStyledProps) => p.theme.colors.white};
      border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
      margin: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: 2px 10px;
    }
  }
  & .navigation-tab.selected {
    border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.blue1};
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
    & .navigation-tab-container {
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
      background-color: ${(p: IStyledProps) => p.theme.colors.blue2.replace('1)', '0.6)')};
      border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
      margin: ${(p: IStyledProps) => p.theme.paddingSize()};
      padding: ${(p: IStyledProps) => p.theme.paddingSize()};
    }
  }
`;

export const InformativeIndicator = styled.div`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: ${(p: IStyledProps) => p.theme.colors.red1};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  ${(p: IStyledProps) =>
    p.isMain &&
    css`
      position: relative;
      right: 10px;
      top: 6px;
    `}
`;

export const WorkspaceSelectRow = styled(RowContainer)`
  align-items: center;
  max-width: 350px;
  min-width: 100px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    max-width: 260px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 220px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 200px;
    width: 150px;
  }
`;

export const RowCenter = styled(RowContainer)`
  align-items: center;
`;

export const ConsentioLogoSquare = styled.img`
  object-fit: contain;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  min-width: 45px;
  width: 45px;
  height: 45px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
    width: 35px;
    height: 35px;
  }
`;

export const WorkspaceTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-transform: uppercase;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  }
`;

export const ChevronWorkspaceIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Breadcrumbs = styled(RowContainer)`
  width: 100%;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  min-height: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  max-height: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  align-items: center;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const ChatButton = styled(Button)`
  min-width: 0;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.blue2 : p.theme.colors.grey4)};
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.blue1 : p.theme.colors.black)};
  height: 32px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const TotalBadge = styled(B)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;
