import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  borderRadius?: string;
  isClickable?: boolean;
  relation?: number;
  src?: string;
  theme: ITheme;
}

export const Container = styled.div<IStyledProps>`
  padding-top: ${(p: IStyledProps) => (p.relation ? 100 / p.relation : 56.25)}%;
  position: relative;
  overflow: hidden;
  cursor: ${(p: IStyledProps) => (p.isClickable ? 'pointer' : 'inherit')};
  ${(p: IStyledProps) =>
    p.borderRadius &&
    css`
      border-radius: ${p.borderRadius};
    `}
`;

export const ImageBG = styled.div`
  background: url(${(p: IStyledProps) => p.src}) no-repeat center center;
  background-size: cover;
  filter: blur(8px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
