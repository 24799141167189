import styled, { ITheme } from '../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  text-align: center;
`;

export const Date = styled.div`
  font-size: 12px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
