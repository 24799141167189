import { AVAILABILITY } from '../../../constants';
import styled, { css, ITheme } from '../../../styled-components';
import {
  Button,
  CheckBox as CB,
  ColumnContainer,
  FontIcon,
  Picture as P,
  RowContainer,
  TagBubble,
  TextLabel,
} from '../../atoms';
import { ActionsModal as AM, FormContainer as FC, FormMenu, SimpleSearch } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  numberOfImages?: number;
  isActive?: AVAILABILITY;
  withoutPaddingBottom?: boolean;
  isDragActive?: boolean;
}

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled(RowContainer)`
  overflow: hidden;
  height: 100%;
  flex: 1;
  max-width: 100%;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  -webkit-overflow-scrolling: touch;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: 0;
  padding-bottom: 0;
`;

export const FormContainer = styled(FC)`
  max-width: 832px;
`;

export const FormCol = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0)};
`;

export const SubColumn = styled(ColumnContainer)`
  flex-basis: 50%;
  flex: 1;
`;

export const SubRow = styled(RowContainer)`
  width: 100%;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  ${(p: IStyledProps) =>
    p.withoutPaddingBottom &&
    css`
      padding-bottom: 0;
    `};
`;

export const Row = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TagsSelectRow = styled(RowContainer)`
  width: 100%;
  align-items: center;
`;

export const TagsRow = styled(RowContainer)`
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const TextPlaceholder = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  position: relative;
`;

export const AssociatedLot = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize()} 0 ${p.theme.paddingSize(3)} 0`};
  position: relative;
`;

export const Lot = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  position: relative;
  top: -6px;
`;

export const TextLink = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ProductTag = styled(TagBubble)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Title = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-transform: uppercase;
`;

export const SearchWrapper = styled(RowContainer)`
  min-height: 58px;
  overflow: visible;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 780px;
`;

export const PriceGroupSearch = styled(SimpleSearch)`
  max-width: 100%;
`;

export const PriceGroupSection = styled(ColumnContainer)`
  width: 100%;
`;

export const PriceGroupHeader = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const POR = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  line-height: 1.6;
`;
export const TableContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  overflow-y: auto;
  flex: 1;
`;
export const AddButton = styled(Button)`
  align-self: flex-end;
`;

export const Picture = styled(P)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CheckBoxRow = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  justify-content: flex-start;
`;

export const CheckBox = styled(CB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const CheckBoxLabel = styled.label`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const DeleteButton = styled(Button)`
  align-self: left;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const CloneButton = styled(Button)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const ColumnRight = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  top: 0;
  right: 0;
  max-width: 290px;
  overflow: auto;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 35%;
    max-height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight} * 2 )`};
  }
`;

export const MenuLeft = styled(FormMenu)`
  padding-right: 0;
  @media (max-width: 1325px) {
    display: none;
  }
`;

export const EmptyColumn = styled(ColumnContainer)`
  flex-basis: 5%;
`;

const Subtitle = styled(TextLabel)`
  width: auto;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const Sustainabilitytitle = styled(Subtitle)`
  margin-bottom: 0;
`;

export const GreenSpan = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green2};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: 3px;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: max-content;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
export const GreenSubtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  top: -10px;
`;
export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  position: relative;
  top: -6px;
  display: inline-block;
`;
export const Sustainabilitytext = styled(Text)`
  top: 0;
  padding-bottom: ${(p: IStyledProps) => (p.withoutPaddingBottom ? p.theme.paddingSize() : p.theme.paddingSize(4))};
`;
export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  top: 0px;
  padding: 0;
`;

export const ModifyLink = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  display: inline-block;
`;

export const Sup = styled.sup`
  position: relative;
  top: -0.5em;
`;
export const SupInfo = styled.sup`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  position: relative;
  top: -0.9em;
`;
export const ExtLink = styled.a`
  text-decoration: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const SubRowInfo = styled(SubRow)`
  position: absolute;
  bottom: -96px;
`;
export const ModalContainer = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const ActionsModal = styled(AM)`
  overflow: visible;
  & .actions-modal-header > div {
    max-width: 90%;
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & .form-wrapper {
    overflow: visible;
  }
`;
export const CenteredButton = styled(Button)`
  align-self: center;
  min-width: 180px;
`;

export const PlasticButton = styled(Button)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  height: auto;
`;
export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: auto;
`;
export const GreyText = styled(TextLabel)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const TextCenter = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextUpload = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextUploadGreen = styled(TextUpload)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: 1px;
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  background-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.light2 : p.theme.colors.white)};
  width: 100%;
  height: auto;
  min-height: min-content;
  &:focus {
    outline: none;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 450px;
  }
`;

export const SelectButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 45%;
  border-color: ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;
export const SaveButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 45%;
`;

export const UploadFileIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const FileDropzone = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
`;
export const TextModal = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  position: relative;
`;

export const AttachmentWrapper = styled(RowContainer)`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
    .kebab {
      display: block;
    }
  }
`;
export const KebabIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
  display: none;
  cursor: pointer;
  &:hover.trash {
    display: block;
  }
`;
export const DeleteAtt = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  position: relative;
  left: 94px;
  top: 22px;
  height: max-content;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  cursor: pointer;
`;
export const TrashIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: max-content;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
export const DeleteAttText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const RightElement = styled(RowContainer)`
  width: 78%;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const CalendarTitle = styled(TextLabel)`
  white-space: nowrap;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 15px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const StatusRow = styled(RowContainer)``;
export const StatusCheckBox = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
export const StatusCheckBoxRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  justify-content: flex-start;
`;
export const BoldText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding: 0;
`;
export const IdColumn = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const GreyInfoIcon = styled(InfoIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const BlueText = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  position: relative;
  background-color: transparent;
`;

export const Col = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;


export const Label = styled(TextLabel)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 3px 6px;
  border-radius: 6px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const Kebab = styled(FontIcon)`
  font-size: 16px;
  padding-left: 3px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const KebabBox = styled.div`
  position: absolute;
  top: 38px;
  right: -10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 0px; // Add 8px padding to top and bottom
  z-index: 1;
  width: 180px;
`;

export const DeleteText = styled.span`
  color: red;
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  margin: 6px; // Add margin around the text
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
`;

export const InfoText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  margin: 6px; // Add margin around the text
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
`;

export const KebabRow = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
`;

export const InfoKebab = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 15px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  
`;
