import {
  Api,
  chatActions,
  LOCALE,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceService,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import { getExcelPriceList, sendExcelPriceList } from '../actions/pricelist';
import Showroom, { DispatchProps, OwnProps, StateProps } from '../components/organisms/Showroom';
import { IReduxState } from '../reducers';

const emptyfeatured = { recommended: [], favorite: [], recent: [] };

function mapStateToProps(state: IReduxState, props: OwnProps): StateProps {
  const {
    catalog: { catalogs, clients },
    user: { user },
    product: { featured, prices, priceGroupIds, sectionsEnabled },
    order: { cart },
    chat: { channels },
    contact: { inConsentio },
  } = state;
  const { contact, match } = props;
  let items: Array<IOrderItem> = [];
  let orderId = 0;
  let isServedFlow = false;
  const channelId = match.params?.channelId;

  const contactId = contact ? contact.id : 0;
  const channel = channels[channelId];
  const c = contactId ? inConsentio[contactId] : inConsentio[channel?.defaultBuyerId];
  const k = c && productService.getKey(user.id, c.id);
  if (cart[k]) {
    items = cart[k].items;
    orderId = cart[k].orderId;
    isServedFlow = cart[k].isServedFlow;
  }
  const catalogKey =
    c && productService.getKey(channel?.workspaceId || c.mySellerWorkspaceId || user.sellerWorkspaceId, c.id);
  const prodTypes = state.prodType.prodTypes;
  const countries = state.country.countries;
  const catalog =
    catalogs[
      Number(props.match.params?.workspaceId) ||
        channel?.workspaceId ||
        (sellerWorkspaceService.isActive(catalogs[c?.mySellerWorkspaceId], user.id)
          ? c?.mySellerWorkspaceId
          : user.sellerWorkspaceId)
    ];
  const client = clients[catalog?.id]?.find(cl => cl.userId === contactId);
  return {
    amSeller: true,
    cart: items,
    catalog,
    catalogs,
    catalogId: catalog?.id,
    client,
    contact: c,
    countries,
    featured: catalogKey
      ? {
          favorite: featured.favorite[catalogKey],
          recent: featured.recent[catalogKey],
          recommended: featured.recommended[catalogKey],
        }
      : emptyfeatured,
    isServedFlow,
    lang: user.settings.language as LOCALE,
    me: state.user.user,
    name: contactId ? null : channel?.name,
    orderId,
    priceMode: 'edit',
    prices: prices[catalogKey],
    prodTypes,
    priceGroupIds: priceGroupIds[catalogKey],
    sectionsEnabled: sectionsEnabled[catalogKey],
    channelMembers: contactId ? undefined : channel?.members,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      cartClean: orderActions.cartClean,
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      cartUpdateItem: (
        key: string,
        contactId: number,
        item: IOrderItem,
        priceMode: IPriceMode,
        deliveryAddress?: IAddress,
      ) => orderActions.cartUpdateItem(key, contactId, item, true, priceMode, deliveryAddress),
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      clientsGet: sellerWorkspaceActions.clientsGet,
      featuredToggle: productActions.featuredToggle,
      getPrices: productActions.getPrices,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelBySection,
      orderFakeNew: orderActions.orderFakeNew.bind(undefined, true),
      productGet: productActions.productGet,
      productShare: productActions.productShare,
      sendMessage: chatActions.sendMessage,
      touchImage: modalActions.touchImage,
      downloadExcel: getExcelPriceList,
      sendExcel: sendExcelPriceList,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Showroom);
