import { i18n, LOCALE } from 'common-services';

import en from './en';

if (typeof window === 'undefined') {
  (async () =>
    i18n.setLocales(
      {
        en,
        es: (await import('./es')).default,
        fr: (await import('./fr')).default,
        de: (await import('./de')).default,
        nl: (await import('./nl')).default,
        it: (await import('./it')).default,
        ca: (await import('./ca')).default,
      },
      LOCALE.EN,
    ))();
} else {
  i18n.setLocales({ ...i18n.default.translations, en }, LOCALE.EN);
}

export default async function (l: LOCALE, translations?: Record<string, any>) {
  if (!i18n.default.translations[l] || translations) {
    let locales = i18n.default.translations;
    switch (l) {
      case LOCALE.ES:
        locales = { ...locales, es: translations || (await import('./es')).default };
        break;
      case LOCALE.FR:
        locales = { ...locales, fr: translations || (await import('./fr')).default };
        break;
      case LOCALE.DE:
        locales = { ...locales, de: translations || (await import('./de')).default };
        break;
      case LOCALE.NL:
        locales = { ...locales, nl: translations || (await import('./nl')).default };
        break;
      case LOCALE.IT:
        locales = { ...locales, it: translations || (await import('./it')).default };
        break;
      case LOCALE.CA:
        locales = { ...locales, ca: translations || (await import('./ca')).default };
        break;
    }
    return i18n.setLocales(locales, l);
  }
  return i18n.useLocale(l);
}
