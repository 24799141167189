import { __, sellerWorkspaceService, utils } from 'common-services';
import * as React from 'react';

import { LettersAvatar } from '../../atoms';
import Members from '../Members/Members.component';
import * as S from './WorkspaceCard.styled';

export interface IProps {
  changeWorkspace: (catalogId: number) => void;
  className?: string;
  contacts: { [contactId: number]: IContact };
  kind: IWorkspaceType;
  isActive: boolean;
  me: IUser;
  navigateToCatalog: (catalogId: number, type: IWorkspaceType) => void;
  workspace: IWorkspace;
  workspaceSellerChangeStatus: (status: 'active' | 'inactive') => void;
}

export default class WorkspaceCard extends React.PureComponent<IProps> {
  public static defaultProps = {
    kind: 'seller',
  };

  public render() {
    const { workspace, className, isActive, kind, navigateToCatalog } = this.props;

    return (
      <S.Workspace className={className} onClick={() => navigateToCatalog(workspace.id, kind)} isActive={isActive}>
        <S.WorkspaceContent>
          {this.getWorkspaceHeader()}
          {this.getWorkspaceBody()}
        </S.WorkspaceContent>
      </S.Workspace>
    );
  }

  private getWorkspaceHeader() {
    const { workspace, contacts, me, isActive, changeWorkspace, kind, workspaceSellerChangeStatus } = this.props;

    return (
      <S.WorkspaceHeader timestamp={workspace.createdAt} imageUrl={workspace.companyImage}>
        {!workspace.companyImage ? <S.WorkspaceIcon name="Team-icon" disableHover={true} /> : null}
        <Members contacts={contacts} me={me} members={workspace.members} disableHover={true} mode="icon" />
        <S.WorkspaceHeaderRight>
          <S.SimpleDropdown
            className="workspace-card-dropdown"
            closeOnClick
            disabled={workspace.id === me.sellerWorkspaceId || (kind === 'buyer' && isActive)}
            hAlign="right"
            onSelect={(name: string) => {
              if (name === 'default_catalog' || (kind === 'buyer' && name === 'active_catalog')) {
                return changeWorkspace(workspace.id);
              }
              if (name === 'inactive_catalog') {
                return workspaceSellerChangeStatus('inactive');
              }
              if (name === 'active_catalog') {
                return workspaceSellerChangeStatus('active');
              }
            }}
            options={[
              ...(kind === 'buyer'
                ? []
                : [
                    {
                      key: 'default_catalog',
                      value: __('Workspaces.seller.default_catalog'),
                    },
                  ]),
              isActive
                ? {
                    key: 'inactive_catalog',
                    value:
                      kind === 'buyer'
                        ? __('Workspaces.buyer.disable_catalog')
                        : __('Workspaces.seller.disable_catalog'),
                  }
                : {
                    key: 'active_catalog',
                    value:
                      kind === 'buyer' ? __('Workspaces.buyer.enable_catalog') : __('Workspaces.seller.enable_catalog'),
                  },
            ]}
          >
            <CatalogSelect isActive={isActive} kind={kind} isDefault={workspace.id === me.sellerWorkspaceId} />
          </S.SimpleDropdown>
          {workspace.id === me.sellerWorkspaceId ? (
            <>
              <S.Default>{__('Workspaces.by_default')}</S.Default>
              <S.TooltipIconWrapper onClick={e => e.stopPropagation()}>
                <S.Tooltip
                  position="top"
                  text={__('Workspaces.default_tooltip')}
                  width="470px"
                  className="tooltip-defaultworkspace"
                  themeMode="dark"
                >
                  <S.InfoIcon name="Info" disableHover={true} />
                </S.Tooltip>
              </S.TooltipIconWrapper>
            </>
          ) : null}
        </S.WorkspaceHeaderRight>
      </S.WorkspaceHeader>
    );
  }

  private renderRole(myRole: ICatalogRole) {
    const { kind } = this.props;

    let textTitle = '';
    let textDescription = '';

    if (myRole === 'admin') {
      textTitle = kind === 'seller' ? __('Constants.CatalogRoles.author') : __('Constants.BuyerWorkspaceRoles.author');
      textDescription =
        kind === 'seller'
          ? __('Constants.CatalogRoles.editor_description')
          : __('Constants.BuyerWorkspaceRoles.author_description');
    } else if (myRole === 'editor') {
      textTitle = kind === 'seller' ? __('Constants.CatalogRoles.editor') : __('Constants.BuyerWorkspaceRoles.editor');
      textDescription =
        kind === 'seller'
          ? __('Constants.CatalogRoles.editor_description')
          : __('Constants.BuyerWorkspaceRoles.editor_description');
    } else {
      textTitle = kind === 'seller' ? __('Constants.CatalogRoles.viewer') : __('Constants.BuyerWorkspaceRoles.viewer');
      textDescription =
        kind === 'seller'
          ? __('Constants.CatalogRoles.viewer_description')
          : __('Constants.BuyerWorkspaceRoles.viewer_description');
    }

    return (
      <S.Role role={myRole}>
        {textTitle}
        <S.RoleDescription>
          {' '}
          <S.MiddleDot>·</S.MiddleDot> {textDescription}
        </S.RoleDescription>
      </S.Role>
    );
  }

  private renderProductInsights(productsCount, relatedCount) {
    const { kind } = this.props;

    if (kind === 'seller') {
      return (
        <>
          <S.TextGrey2>
            <S.TextNumber>{productsCount}</S.TextNumber>
            {' ' + __('Workspaces.products', { count: productsCount })}
          </S.TextGrey2>
          <S.TextGrey2>
            <S.TextNumber>{relatedCount}</S.TextNumber>
            {' ' + __('Workspaces.clients', { count: relatedCount })}
          </S.TextGrey2>
        </>
      );
    }

    return (
      <>
        <S.TextGrey2>
          <S.TextNumber>{relatedCount}</S.TextNumber>
          {' ' + __('Workspaces.providers', { count: relatedCount })}
        </S.TextGrey2>
      </>
    );
  }

  private getWorkspaceBody() {
    const { workspace, contacts, me, kind } = this.props;
    const adminId = workspace.members.find(m => m.role === 'admin')?.userId;
    const amAdmin = me.id === adminId;
    const admin: ILightContact =
      adminId !== me.id
        ? contacts[adminId]
        : {
            avatar: me.settings.avatar,
            avatarColor: utils.getAvatarColor(me.name),
            name: me.name,
            phoneNumbers: [me.phone + ''],
            companyName: me.companyName,
            id: me.id,
            isUnregistered: false,
          };
    const myRole = sellerWorkspaceService.getRole(workspace, me.id);
    const productsCount = workspace.numberOfProducts;
    const clientsCount = kind === 'seller' ? workspace.numberOfClients : workspace.numberOfSuppliers;

    return (
      <S.WorkspaceBody>
        <S.WorkspaceBodyLeft>
          <S.WorkspaceTitleContainer>
            <S.WorkspaceTitle>{sellerWorkspaceService.getCatalogName(workspace, contacts, me)}</S.WorkspaceTitle>
            {this.renderRole(myRole)}
          </S.WorkspaceTitleContainer>
          <S.StatsContainer>{this.renderProductInsights(productsCount, clientsCount)}</S.StatsContainer>
        </S.WorkspaceBodyLeft>
        <S.WorkspaceBodyRight>
          <LettersAvatar size={30} text={admin?.name} img={admin?.avatar} avatarColor={admin?.avatarColor} />
          <S.Author>{__('Constants.CatalogRoles.author')}</S.Author>
          <S.AuthorName>{amAdmin ? __('Components.Chat.you') : admin?.name}</S.AuthorName>
        </S.WorkspaceBodyRight>
      </S.WorkspaceBody>
    );
  }
}

interface ICatalogSelectProps {
  isActive: boolean;
  isDefault: boolean;
  kind: string;
}

const CatalogSelect: React.FC<ICatalogSelectProps> = ({ isActive, isDefault, kind }) => {
  const statusActive = kind === 'seller' ? __('Workspaces.seller.status.active') : __('Workspaces.buyer.status.active');
  const statusInactive =
    kind === 'seller' ? __('Workspaces.seller.status.inactive') : __('Workspaces.buyer.status.inactive');
  return (
    <S.CatalogSelect isActive={isActive}>
      <S.ActiveIndicator isActive={isActive} />
      <S.TextSelect>{isActive ? statusActive : statusInactive}</S.TextSelect>
      {isDefault || (kind === 'buyer' && isActive) ? null : <S.ChevronIcon name="Down" />}
    </S.CatalogSelect>
  );
};
