import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const MemberSelected = styled(RowContainer)`
  text-decoration: none;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: 2px ${(p: IStyledProps) => p.theme.paddingSize()};
  cursor: pointer;
  align-items: center;
  height: 40px;
  max-width: 250px;
  overflow: hidden;
`;

export const MemberSelectedColumn = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const MemberSelectedValue = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
`;

const FontIconWrapper = styled.div`
  align-self: center;
  max-width: 30px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const DownIconWrapper = styled(FontIconWrapper)`
  margin-right: 0;
`;
