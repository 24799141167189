import { IOrderItem, IProduct } from 'common-services';
import * as React from 'react';

import * as S from './ProductList.styled';

interface IProps {
  className?: string;
  // this is only for force the render if the amount of a product change
  cart?: Array<IOrderItem>;
  hasCart?: boolean;
  ProductComponent: React.FC<{ product: IProduct; idx: number }>;
  products: Array<any>;
  selectedProducts?: Array<number>;
}

const ProductList: React.FC<IProps> = ({ className, ProductComponent, products }) => {
  return (
    <S.Container className={className}>
      <S.ContainerList>
        {products.map((product, idx: number) => (
          <ProductComponent product={product} key={product.id} idx={idx} />
        ))}
      </S.ContainerList>
    </S.Container>
  );
};

export default React.memo(ProductList);
