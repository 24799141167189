import { __, validations } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import ActionsModal from '../ActionsModal';
import * as S from './ConfirmOrderModal.styled';

export interface IProps {
  b2cFlow?: boolean;
  className?: string;
  defaultEmail?: string;
  defaultMode?: 'email-form' | 'success';
  onClose: () => void;
  onLogin: () => void;
  onSubmit: (email: string, setSuccessMode: () => void) => void;
  ownerName: string;
}

/**
 * Modal to enter email and confirm public shop's order.
 * Success confirmation.
 * TODO: handle success unregistered flow from B2C see copy 'success_b2c'
 */
const ConfirmOrderModal: React.FC<IProps> = ({
  b2cFlow = false,
  className,
  defaultEmail,
  defaultMode = 'email-form',
  onClose,
  onLogin,
  onSubmit,
  ownerName,
}) => {
  const [mode, setMode] = React.useState<'email-form' | 'success'>(defaultMode);
  const [email, setEmail] = React.useState(defaultEmail || '');
  const emailValidated = email && validations.validateEmail(email);
  return (
    <ActionsModal
      className={className}
      minHeight="500px"
      onClose={mode === 'email-form' ? onClose : undefined}
      title=""
    >
      <S.BodyContainer>
        <S.MainImage src={mode === 'email-form' ? IMAGES.cart : IMAGES.emailCheck} />

        <S.TextTitle mode={mode}>{getTitle()}</S.TextTitle>
        {mode === 'email-form' ? (
          <>
            <S.Input
              name="confirm-order-modal-email-input"
              value={email}
              placeholder={__('ConfirmOrderModal.form.email_placeholder')}
              onChange={(name, value) => setEmail(value + '')}
            />
            <S.CTAForm
              type="principal"
              onClick={() => onSubmit(email, () => setMode('success'))}
              disabled={!emailValidated}
            >
              {defaultEmail ? __('ConfirmOrderModal.form.cta_change') : __('ActionsModal.next')}
            </S.CTAForm>
            <S.TextAccount>
              {__('ConfirmOrderModal.form.have_account') + ' '}
              <S.LinkLogin onClick={onLogin}>{__('ConfirmOrderModal.form.log_in')}</S.LinkLogin>
            </S.TextAccount>
          </>
        ) : (
          <>
            <S.TextDescription>
              {__('ConfirmOrderModal.success_unregistered.description', { name: ownerName })}
            </S.TextDescription>
            <S.CTAOk type="principal" onClick={onClose}>
              {__('ConfirmOrderModal.success_unregistered.ok')}
            </S.CTAOk>
          </>
        )}
      </S.BodyContainer>
      <S.FooterContainer>
        <S.TextFooter>Powered by CONSENTIO</S.TextFooter>
        <S.TextFooter>www.consentio.co</S.TextFooter>
      </S.FooterContainer>
    </ActionsModal>
  );

  function getTitle() {
    if (mode === 'success') return __('ConfirmOrderModal.success_unregistered.title');
    if (defaultEmail) return __('ConfirmOrderModal.form.title_update');
    return b2cFlow ? __('ConfirmOrderModal.form.title_b2c') : __('ConfirmOrderModal.form.title');
  }
};

export default React.memo(ConfirmOrderModal);
