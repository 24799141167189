import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  error?: boolean;
  red?: boolean;
  isLast?: boolean;
}

export const Buttons = styled(RowContainer)`
  justify-content: flex-end;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  align-items: flex-end;
`;

export const RowGeneral = styled(RowContainer)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  flex: 1;
`;

export const LettersAvatarContainer = styled(ColumnContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  position: relative;
`;

export const CTA = styled(Button)`
  flex: 0.4;
  width: auto;
`;
