import {
  __,
  constants,
  contactSelectors,
  countrySelectors,
  currency,
  date,
  ORDER_STATUS,
  orderActions,
  orderService,
  sellerWorkspaceService,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { downloadBinaryFile, downloadFile } from '../../../services/file';
import theme from '../../../theme';
import { ColumnContainer } from '../../atoms';
import { BubbleColor } from '../../atoms/TagBubble/TagBubble.component';
import ContactCell from '../ContactCell';
import * as S from './ImportJob.styled';

interface IProps {
  importJob: IImportJob;
  index: number;
  allFormats: Array<IFormat>;
  catalogs: { [id: number]: IWorkspace };
  contacts: { [contactId: number]: IContact };
  clients?: { [catalogId: number]: Array<IClient> };
  suppliers?: { [id: number]: Array<ISupplier> };
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  me: IUser;
  onOrderClick: (jobId: number, order: IOrder) => void;
  contactName: string;
  isEdi?: boolean;
}

/**
 * Render import job state
 */
const ImportJob: React.FC<IProps> = ({
  contactName,
  importJob,
  index,
  catalogs,
  contacts,
  clients,
  suppliers,
  downloadImportJobFile,
  me,
  allFormats,
  onOrderClick,
  orderOriginalFileDownload,
  isEdi,
}) => {
  const { createdAt, files, id, kind, catalogHash } = importJob;
  const orderFormat = allFormats.find(of => of.slug === kind);
  const authorName = me.id === importJob.userId ? me.name : contacts[importJob.userId]?.name || '';
  const catalog = catalogs && Object.values(catalogs).find(c => c.hashId === catalogHash);
  return (
    <S.ImportJobContainer key={index + '_' + id}>
      {files.length ? (
        <S.FilesContainer>
          {files.map((file, idx) => (
            <FileImported
              key={idx + '_' + file.id}
              file={file}
              idx={idx}
              downloadImportJobFile={downloadImportJobFile}
              downloadEdiFile={() => {
                const order = file.orders[0];
                orderOriginalFileDownload(me.id, order.id, (data: string, contenType: string, filename: string) => {
                  const fileName =
                    filename ||
                    `order-${order.externalIdSeller || order.externalIdBuyer || order.hashId}-${new Date().getTime()}`;
                  downloadBinaryFile(data, contenType, fileName);
                });
              }}
              me={me}
              orderFormat={orderFormat}
              onOrderClick={onOrderClick}
              authorName={authorName}
              catalog={catalog}
              createdAt={createdAt}
              contacts={contacts}
              clients={clients}
              suppliers={suppliers}
              contactName={contactName}
              isEdi={isEdi}
            />
          ))}
        </S.FilesContainer>
      ) : null}
    </S.ImportJobContainer>
  );
};

export default React.memo(ImportJob);

const FileImported: React.FC<{
  file: IImportFile;
  idx: number;
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  downloadEdiFile: () => void;
  me: IUser;
  clients?: { [catalogId: number]: Array<IClient> };
  suppliers?: { [id: number]: Array<ISupplier> };
  orderFormat: IFormat;
  onOrderClick: (jobId: number, order: IOrder) => void;
  authorName: string;
  catalog: IWorkspace;
  createdAt: number;
  contacts: Record<number, IContact>;
  contactName: string;
  isEdi: boolean;
}> = ({
  file,
  createdAt,
  idx,
  downloadImportJobFile,
  downloadEdiFile,
  me,
  clients,
  suppliers,
  orderFormat,
  onOrderClick,
  authorName,
  catalog,
  contacts,
  contactName,
  isEdi,
}) => {
  const { fileId } = file;
  const logo = orderFormat?.logo_url || '';
  const label = orderFormat?.label || '';
  const typeLabel = orderService.getFormatTypeLabel(orderFormat?.accepted_doc_types.join(',') || '');
  const isProcessing = file.status === ORDER_STATUS.PROCESSING || file.status === ORDER_STATUS.QUEUED;
  const isFailed = file.status === ORDER_STATUS.FAILED || file.status === ORDER_STATUS.UNKNOWN;
  const statusLabel = orderService.getImportFileStatusLabel(file.status);
  const progress =
    isProcessing || isFailed
      ? 0
      : (file.orders.filter(o => ![ORDER_STATUS.INCOMPLETE, ORDER_STATUS.CANCELED].includes(o.status)).length /
          file.orders.length) *
        100;
  const allCancelled = file.orders.filter(o => o.status === ORDER_STATUS.CANCELED).length === file.orders.length;
  /**
   * Render order table
   */
  const renderOrderTable = (orders: Array<IOrder>, jobId: number) => {
    const salesOrders = orders.filter(o => o.sellerId === me.id);
    const purchasesOrders = orders.filter(o => o.sellerId !== me.id);
    const hasSalesOrders = salesOrders?.length;
    const hasPurchasesOrders = purchasesOrders?.length;
    return hasSalesOrders || hasPurchasesOrders ? (
      <OrderTable
        orders={hasSalesOrders ? salesOrders : purchasesOrders}
        me={me}
        catalog={catalog}
        orderClick={order => onOrderClick(jobId, order)}
        clients={clients}
        suppliers={suppliers}
      />
    ) : null;
  };
  return (
    <React.Fragment key={idx + '_' + fileId}>
      <S.ImportJobHeader>
        {logo || isEdi ? (
          <S.FormatLogoContainer>
            <S.OrderFormatLogoSmall src={isEdi ? constants.MEDIA_URL + '/v1657813968/logos/edi.png' : logo} />
          </S.FormatLogoContainer>
        ) : null}
        <ColumnContainer>
          <S.FileRow>
            <S.DownloadIcon
              name="Download"
              onClick={e => {
                e.stopPropagation();
                isEdi
                  ? downloadEdiFile()
                  : downloadImportJobFile(me.id, file.id, data => downloadFile(data, file.mimeType, fileId));
              }}
            />
            <S.TextFileLink
              onClick={e => {
                e.stopPropagation();
                isEdi
                  ? downloadEdiFile()
                  : downloadImportJobFile(me.id, file.id, data => downloadFile(data, file.mimeType, fileId));
              }}
            >
              {file.name}
            </S.TextFileLink>
          </S.FileRow>
          <S.TextJobDescription>
            <>
              {isEdi ? 'EDI TEXT' : null}
              {utils.formatText(
                isEdi
                  ? __('MagicOrders.JobDescriptionEDI', {
                      label,
                      typeLabel,
                      date: date.formatLongDate(createdAt || ''),
                      hour: date.formatTime(createdAt || '', me.settings.hourFormat),
                      catalog: sellerWorkspaceService.getCatalogName(catalog, contacts, me),
                    })
                  : __('MagicOrders.JobDescription', {
                      label,
                      typeLabel,
                      date: date.formatLongDate(createdAt || ''),
                      hour: date.formatTime(createdAt || '', me.settings.hourFormat),
                      authorName,
                      catalog: sellerWorkspaceService.getCatalogName(catalog, contacts, me),
                    }),
                (s, i) => (
                  <S.TextBold key={s + i}>{s}</S.TextBold>
                ),
              )}
            </>
          </S.TextJobDescription>

          {isProcessing ? (
            <S.FileRow>
              <S.SpinnerLoading loading={true} className="spinner-ios-loading" color={theme.colors.blue1} />
              <S.TextBlue>{statusLabel}:</S.TextBlue>
              <S.TextGrey>{__('Components.OrdersImport.import_time')}</S.TextGrey>
            </S.FileRow>
          ) : isFailed ? (
            <S.FileRow>
              <S.FailedIcon name="Close" disableHover={true} />
              <S.TextRedSmall>
                {__('MagicOrders.failed')}:{' '}
                {file.errors.map(e => (
                  <span key={e.id}>
                    {e.type !== 'unknown' && e.type !== 'unexpected'
                      ? orderService.getImportOrderErrorLabel(e.type, e.code, e.name, contactName)
                      : e.name}
                  </span>
                ))}
              </S.TextRedSmall>
            </S.FileRow>
          ) : allCancelled ? null : progress < 100 ? (
            <>
              <S.ProgressBar
                barHeight={10}
                progress={progress}
                hideCount={true}
                title={__('MagicOrders.completing', {
                  count: Math.ceil(Math.min(progress, 100)),
                })}
              />
            </>
          ) : (
            <S.FileRow>
              <S.SuccessIcon name="Check" disableHover={true} />
              <S.TextGreen>{__('MagicOrders.completed')}</S.TextGreen>
            </S.FileRow>
          )}
        </ColumnContainer>
      </S.ImportJobHeader>
      {file.orders.length ? renderOrderTable(file.orders, file.jobId) : null}
    </React.Fragment>
  );
};

const OrderTable: React.FC<{
  catalog: IWorkspace;
  me: IUser;
  orderClick: (order: IOrder) => void;
  orders: Array<IOrder>;
  clients?: { [catalogId: number]: Array<IClient> };
  suppliers?: { [id: number]: Array<ISupplier> };
}> = ({ catalog, clients, suppliers, orders, me, orderClick }) => {
  if (orders.length === 0) return null;
  return (
    <S.Container>
      <S.Table>
        <thead>
          <S.Header>
            <S.StatusCol>
              <S.TextHeader>{__('MagicOrders.Table.Status')}</S.TextHeader>
            </S.StatusCol>
            <S.DeliveryCol>
              <S.TextHeader>{__('MagicOrders.Table.colleague')}</S.TextHeader>
            </S.DeliveryCol>
            <S.TimeCol>
              <S.TextHeader>{__('MagicOrders.Table.TimeSaved')}</S.TextHeader>
            </S.TimeCol>
            <S.DeliveryCol>
              <S.TextHeader>{__('MagicOrders.Table.Reference')}</S.TextHeader>
            </S.DeliveryCol>
            <S.DeliveryCol>
              <S.TextHeader>{__('MagicOrders.Table.client')}</S.TextHeader>
            </S.DeliveryCol>
            <S.ArticlesCol>
              <S.TextHeader>{__('MagicOrders.Table.Delivery')}</S.TextHeader>
            </S.ArticlesCol>
            <S.ArticlesCol>
              <S.TextHeader>{__('MagicOrders.Table.Created')}</S.TextHeader>
            </S.ArticlesCol>
            <S.ArticlesCol>
              <S.TextHeader>{__('MagicOrders.Table.Items')}</S.TextHeader>
            </S.ArticlesCol>
            <S.PriceCol>
              <S.TextHeader>{__('MagicOrders.Table.Price')}</S.TextHeader>
            </S.PriceCol>
          </S.Header>
        </thead>
        <S.Body>
          {orders.map(order => (
            <OrderTableRow
              catalog={catalog}
              clients={clients}
              suppliers={suppliers}
              key={order.id}
              me={me}
              order={order}
              orderClick={orderClick}
            />
          ))}
        </S.Body>
      </S.Table>
    </S.Container>
  );
};

/**
 * Order table new for orders dashboard
 */
const OrderTableRow: React.FC<{
  me: IUser;
  order: IOrder;
  orderClick: (order: IOrder) => void;
  catalog: IWorkspace;
  clients: { [catalogId: number]: Array<IClient> };
  suppliers: { [id: number]: Array<ISupplier> };
}> = ({ me, order, orderClick, catalog, clients, suppliers }) => {
  const countries = useSelector(countrySelectors.getCountries);
  const contacts = useSelector(contactSelectors.getContacts());
  const hourFormat = me.settings.hourFormat;
  const dateFormatted = date.formatLongDate(order.createdAt!, undefined, undefined, 'EEE, dd MMM yyyy');
  const dateFormattedHours = date.formatTime(order.createdAt!, hourFormat);
  const priceDisplay = order.price === 0 ? '-' : currency.getPrice(order.currency, order.price, order.pricePrecision);
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  const hasMissingDeliveryAddress = order.issues.find(
    err => err.type === 'address-not-found' || err.type === 'address-code-not-found',
  );
  const missingProductsCount = order.issues.filter(err =>
    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(err.type),
  ).length;
  // const amOwner = me.id === order.sellerId || me.id === order.buyerId || me.id === order.delegateId;
  const isRead = true; // TODO !amOwner || (order.readBy ? !!order.readBy.find(r => r === me.id) : order.isRead);
  const mismatchPriceIssue = order.issues?.find(i => i.type === 'no-pricegroup-matching');

  const { buyer, seller } = orderService.getSellerBuyer(
    order,
    contacts,
    me,
    clients?.[catalog?.id],
    suppliers?.[catalog?.id],
  );
  return (
    <S.OrderTr
      isRead={isRead}
      onClick={e => {
        e.preventDefault();
        orderClick(order);
      }}
    >
      <S.StatusCol>
        <S.FlexColumn>
          <S.StatusTag
            label={__(`Messages.Order.status.${order.status}`)}
            disabled={true}
            color={order.status as BubbleColor}
          />
        </S.FlexColumn>
      </S.StatusCol>
      <S.ColleagueCol>
        <S.FlexColumn>
          <ContactCell
            avatar={seller?.companyLogo}
            primaryText={seller?.companyName}
            secondaryText={seller?.name}
            workingStatus={seller?.workingStatus}
          />
        </S.FlexColumn>
      </S.ColleagueCol>
      <S.TimeCol>
        <S.FlexColumn>
          <S.TextBlack isRead={isRead} status={order.status}>
            {timeSaved(order.items.length)} mins
          </S.TextBlack>
        </S.FlexColumn>
      </S.TimeCol>
      <S.HashCol>
        <S.FlexColumn>
          <S.TextBlack isRead={isRead} status={order.status}>
            {order.externalIdSeller || order.externalIdBuyer || '#' + order.hashId}
          </S.TextBlack>
        </S.FlexColumn>
      </S.HashCol>
      <S.ColleagueCol>
        <S.FlexColumn>
          <ContactCell
            avatar={buyer?.companyLogo}
            imgText={buyer?.companyName || buyer?.name}
            primaryText={buyer?.companyName}
            secondaryText={buyer?.name}
            workingStatus={buyer?.workingStatus}
          />
        </S.FlexColumn>
      </S.ColleagueCol>
      {hasMissingDeliveryAddress ? (
        <S.DeliveryCol>
          <S.TextIssue status={order.status}>
            {order.status === ORDER_STATUS.CANCELED ? '-' : __('MagicOrders.Table.field_incomplete')}
          </S.TextIssue>
        </S.DeliveryCol>
      ) : (
        <S.DeliveryCol>
          {order.deliverTo ? (
            <S.FlexColumn>
              <S.TextBlack isRead={isRead} status={order.status}>
                {order.deliverTo.displayName || order.deliverTo.rest}
              </S.TextBlack>
              <S.TextSecondary isRead={isRead}>
                {order.deliverTo.city +
                  ' ' +
                  (countries[order.deliverTo.country]
                    ? countries[order.deliverTo.country].name
                    : order.deliverTo.country)}
              </S.TextSecondary>
            </S.FlexColumn>
          ) : (
            <S.TextGrey isRead={isRead}>-</S.TextGrey>
          )}
        </S.DeliveryCol>
      )}

      <S.CreatedCol>
        <S.FlexColumn>
          <S.TextBlack isRead={isRead} status={order.status}>
            {dateFormatted}
          </S.TextBlack>

          {order.showHours ? (
            <S.TextSecondary isRead={isRead} status={order.status}>
              {dateFormattedHours}
            </S.TextSecondary>
          ) : null}
        </S.FlexColumn>
      </S.CreatedCol>

      {missingProductsCount > 0 && order.status !== ORDER_STATUS.CANCELED ? (
        <S.ArticlesCol>
          {<S.TextIssue status={order.status}>{__('MagicOrders.Table.field_incomplete')}</S.TextIssue>}
        </S.ArticlesCol>
      ) : (
        <S.ArticlesCol>
          <S.AmountFlexRow>
            {togglePriceMismatchEnable && order.issues.find(i => i.type === 'kind-loss-of-line') ? (
              <S.TooltipPrice
                position="bottom"
                text={__('Components.OrdersList.lost_line_info')}
                width="200px"
                themeMode="dark"
              >
                <S.InfoIcon disableHover={true} name="Info" />
              </S.TooltipPrice>
            ) : null}
            <S.TextBlack isRead={isRead} status={order.status}>
              {order.items.length}
            </S.TextBlack>
          </S.AmountFlexRow>
        </S.ArticlesCol>
      )}

      <S.PriceCol isRead={isRead} status={order.status}>
        <S.PriceFlexRow>
          {togglePriceMismatchEnable && mismatchPriceIssue ? (
            <S.TooltipContainer>
              <S.TooltipPrice
                position="bottom"
                text={__('Components.OrdersList.mismatch_price_tooltip')}
                width="200px"
                themeMode="dark"
              >
                <S.InfoIcon disableHover={true} name="Info" />
              </S.TooltipPrice>
            </S.TooltipContainer>
          ) : null}
          <S.PriceText>{priceDisplay}</S.PriceText>
        </S.PriceFlexRow>
      </S.PriceCol>
    </S.OrderTr>
  );
};

/**
 * Calculate the number of seconds you save with consentio, 1 minute for the order and 0.5 for the order item.
 */
function timeSaved(numItems: number) {
  const TIME_FOR_ITEM = 0.5;
  return 1 + numItems * TIME_FOR_ITEM;
}
