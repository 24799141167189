import * as React from 'react';
import { useSelector } from 'react-redux';

import { IReduxState } from '../reducers';

import WorkspaceOrders, { IRouteProps, IStateProps } from '../components/organisms/WorkspaceOrders';

// Define a constant for the empty object to avoid recreating it on each render
const EMPTY_OBJECT = {} as const;

// Create a functional component
const WorkspaceOrdersContainer: React.FC<IRouteProps> = props => {
  // Use useSelector hook to select state from Redux store
  const state = useSelector<IReduxState, IStateProps>(state => {
    const {
      catalog: { catalogs },
      order: { orders, searchId, totalResults, workspaceUnreads, filters },
      user: { user },
      workspace: { suppliers, workspaces },
      contact: { inConsentio },
    } = state;

    const orderFiltersState = filters || EMPTY_OBJECT;
    const workspaceId = props.match.params?.workspaceId;

    return {
      asSeller: true,
      catalogs: catalogs || EMPTY_OBJECT,
      contacts: inConsentio,
      filters: orderFiltersState[0] || EMPTY_OBJECT,
      me: user!,
      orders,
      orderUnreads: workspaceId ? workspaceUnreads[workspaceId] : undefined,
      searchId,
      suppliers,
      totalResults,
      type: 'sale',
      workspaces,
    };
  });

  // Render the WorkspaceOrders component with the selected state
  return <WorkspaceOrders {...state} {...props} />;
};

export default WorkspaceOrdersContainer;
