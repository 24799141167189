import {
  __,
  BUSINESS_TYPE,
  constants,
  contactActions,
  countryService,
  EventTrack,
  modalActions,
  notificationsActions,
  qs,
  RenderTrack,
  userActions,
} from 'common-services';
import { History } from 'history';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import * as React from 'react';

import * as navActions from '../../../actions/nav';
import * as webUserActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import {
  getNavigatorCountryCode,
  ILoginData,
  REGISTER_INFO_STEPS,
  REGISTER_STEPS,
  ROUTE_PATHS,
} from '../../../constants';
import getPath from '../../../util/routes';
import { getUrlPathParams } from '../../../util/utils';
import { Business, InputStep, VerifyCode, WelcomeLoginRegister } from './Fragments';

declare var global: IGlobalWeb;

export interface IProps {
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  authStep?: REGISTER_STEPS;
  contactUrl?: string;
  countries: { [key: string]: ICountry };
  defaultEmail?: string;
  defaultMode?: 'phone' | 'email';
  error?: string;
  hideLoading: typeof modalActions.hideLoading;
  history: History;
  inModal?: boolean;
  isRegister?: boolean;
  lang?: LOCALE;
  loading: boolean;
  me: IUser;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navAuthAction: typeof navActions.navAuthAction;
  navResetAuthAction: typeof navActions.navResetAuthAction;
  redirect?: (myId?: number) => void;
  register: typeof webUserActions.register;
  resendCode: typeof webUserActions.resendCode;
  resetPassword: typeof userActions.resetPassword;
  resetUser: typeof webUserActions.resetUser;
  scanContacts: typeof contactActions.scanContacts;
  setRegisterInfo: typeof webUserActions.setRegisterInfo;
  showLoading: typeof modalActions.showLoading;
  updatePassword: typeof webUserActions.updatePassword;
  updateSettings: typeof userActions.updateSettings;
  userExists: typeof userActions.userExists;
  userSet: typeof userActions.userSet;
  isEmailValid: typeof userActions.isEmailValid;
  verifyEmailCode: typeof webUserActions.verifyEmailCode;
  verifyPhone: typeof webUserActions.verifyPhone;
  verifyPhoneCode: typeof webUserActions.verifyPhoneCode;
  verifyUser: typeof userActions.verifyUserData;
  webloginOauth: typeof webUserActions.webloginOauth;
  webloginPassword: typeof webUserActions.webloginPassword;
  notificationShow: typeof notificationsActions.notificationShow;
}

interface IState {
  codeEmail: string;
  codePhone: string;
  email: string;
  emailVerificationNeeded: boolean;
  invite: string;
  isRegister: boolean;
  last4DigitsPhone: string;
  loginData: ILoginData;
  mode: 'phone' | 'email';
  oauthToken: string;
  recoverPassword: boolean;
  registerInfoStep: REGISTER_INFO_STEPS;
  twoFARequired: boolean;
}

/**
 * Login / register flow
 */
export default class LoginRegisterFlow extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let h: string;
    if (typeof window !== 'undefined') {
      const urlParams = getUrlPathParams(window.location.pathname, '');
      const inv = qs.parse(window.location.search, ['h', 'c']) as { h: string; c: string; d: string };
      // We fucked up and used the name query parameter "h" for tracking invite hashes
      // on invite system and to force the user hash id when creating sharing links
      // If we don't overiide this when in pricelist, register won't succeed
      //
      // We must change one of the query parameter names
      h = window.location.pathname.startsWith('/pricelist/') ? '' : inv.h;
      // If landing is public channel, retrieve the invite id from the pathname
      if ((urlParams[0] === 'c' || urlParams[0] === 'p') && urlParams[1]) h = urlParams[1];
    }

    this.state = {
      codeEmail: '',
      codePhone: '',
      email: typeof window !== 'undefined' ? global.localStorage.getItem('email') || '' : '',
      emailVerificationNeeded: true,
      invite: h,
      isRegister:
        props.isRegister ||
        (typeof window !== 'undefined' && window.location.pathname.startsWith('/register') ? true : false),
      last4DigitsPhone: '',
      loginData: this.getInitialFormLoginData(),
      mode: props.defaultMode || this.getInitialMode(),
      oauthToken: '',
      recoverPassword: false,
      registerInfoStep: REGISTER_INFO_STEPS.VERIFY,
      twoFARequired: false,
    };
  }

  public render() {
    const {
      addContactByPricelistUrl,
      authStep,
      contactUrl,
      countries,
      inModal,
      lang,
      loading,
      me,
      navAuthAction,
      redirect,
      resetPassword,
      resetUser,
      setRegisterInfo,
      userExists,
      verifyEmailCode,
      isEmailValid,
      verifyPhone,
      webloginOauth,
      webloginPassword,
      notificationShow,
    } = this.props;
    const { codeEmail, codePhone, email, isRegister, loginData, mode, oauthToken } = this.state;

    let trackExtra = {};
    const { phoneNumber } = loginData;
    if (email) {
      trackExtra = { email };
    } else if (phoneNumber) {
      const { countryPrefix } = loginData;
      const phoneNumberWithoutSpaces = phoneNumber.replace(/ /g, '');
      const phone = `${countryPrefix}${phoneNumberWithoutSpaces}`;
      trackExtra = { phone };
    }

    switch (authStep) {
      case REGISTER_STEPS.REGISTER:
        return this.getRegisterInfoStep(trackExtra);
      case REGISTER_STEPS.VERIFY_CODE:
        return this.getVerifyCodeStep(trackExtra);

      case REGISTER_STEPS.RESET_PASSWORD:
        return (
          <InputStep
            countries={countries}
            cta={__('Components.Onboarding.ResetPassword.cta', { locale: lang })}
            goBack={() => navAuthAction(REGISTER_STEPS.WELCOME_LOGIN_REGISTER)}
            inputType="password"
            lang={lang}
            label={__('Components.Onboarding.ResetPassword.label', { locale: lang })}
            me={me}
            name="reset_password"
            placeholder={__('Components.Onboarding.ResetPassword.placeholder', { locale: lang })}
            onNext={(value, onError) => {
              const params =
                mode === 'phone'
                  ? { phone: `${me.country}${me.phone}`, code: codePhone, password: value }
                  : { email, code: codeEmail, password: value };
              resetPassword(params, error => {
                if (!error) {
                  navAuthAction(REGISTER_STEPS.WELCOME_LOGIN_REGISTER);
                }
                notificationShow(
                  {
                    style: error ? 'error' : 'info',
                    title: error
                      ? __('Notification.error.title', { locale: lang })
                      : __('LoginRegisterFlow.ResetPassword.notification_title', { locale: lang }),
                    subtitle: error
                      ? __('Notification.error.description', { locale: lang })
                      : __('LoginRegisterFlow.ResetPassword.notification_subtitle', { locale: lang }),
                    closable: true,
                  },
                  4000,
                );
              });
            }}
            title={__('Components.Onboarding.ResetPassword.title', { locale: lang })}
            trackRender={(time: number) =>
              RenderTrack.track('OnboardRecoverPassword', { renderTime: time, ...trackExtra })
            }
            userExists={userExists}
          />
        );

      case REGISTER_STEPS.WELCOME_LOGIN_REGISTER:
      default:
        return (
          <WelcomeLoginRegister
            addContactByPricelistUrl={addContactByPricelistUrl}
            contactUrl={contactUrl}
            countries={countries}
            email={email}
            getTwoFactorVerificationCode={this.getTwoFactorVerificationCode}
            inModal={inModal}
            isOauth={oauthToken !== ''}
            isRegister={isRegister}
            lang={lang}
            loading={loading}
            loginData={loginData}
            mode={mode}
            navAuthAction={navAuthAction}
            redirect={redirect}
            resetLoginData={() => this.setState({ loginData: this.getInitialFormLoginData() })}
            resetUser={resetUser}
            setParentState={this.setState.bind(this)}
            setRegisterInfo={setRegisterInfo}
            updateLoginData={this.updateLoginData}
            userExists={userExists}
            verifyEmailCode={verifyEmailCode}
            isEmailValid={isEmailValid}
            verifyPhone={verifyPhone}
            webloginOauth={webloginOauth}
            webloginPassword={webloginPassword}
          />
        );
    }
  }

  /**
   * Get verify code step
   */
  private getVerifyCodeStep = (trackExtra: any, fromRegister?: boolean) => {
    const { addContactByPricelistUrl, contactUrl, lang, loading, navAuthAction, redirect, webloginPassword } =
      this.props;
    const { codeEmail, codePhone, email, last4DigitsPhone, loginData, mode, recoverPassword, twoFARequired } =
      this.state;
    return (
      <VerifyCode
        {...this.props}
        code={twoFARequired || mode === 'phone' ? codePhone : codeEmail}
        email={email}
        fromRegister={fromRegister}
        goBack={() => navAuthAction(REGISTER_STEPS.WELCOME_LOGIN_REGISTER)}
        goToNextStep={onErrorCb => {
          if (twoFARequired) {
            const params = loginData.phoneValid
              ? { phone: loginData.phoneParsed.number.replace(/\D/g, '') }
              : { email };

            webloginPassword({ ...params, code: codePhone, password: loginData.password }, (result, resultUser) => {
              if (result.startsWith('error')) {
                let textError = '';
                switch (result) {
                  case 'error_login_attempts':
                    textError = __('Components.Onboarding.error_login_password_attempts', { locale: lang });
                    break;
                  case 'error_blocked':
                    textError = __('Components.Onboarding.error_login_password_blocked', { locale: lang });
                    break;
                  default:
                    textError =
                      mode === 'phone'
                        ? __('Components.Onboarding.error_login_password_phone', { locale: lang })
                        : __('Components.Onboarding.error_login_password_email', { locale: lang });
                }

                return onErrorCb(textError);
              }
              if (contactUrl)
                addContactByPricelistUrl(resultUser, contactUrl, () => redirect && redirect(resultUser.id));
              else if (redirect) redirect(resultUser.id);
            });
            return;
          }
          if (recoverPassword) navAuthAction(REGISTER_STEPS.RESET_PASSWORD);
          else this.setState({ registerInfoStep: REGISTER_INFO_STEPS.NAME });
        }}
        lang={lang}
        last4DigitsPhone={last4DigitsPhone}
        loading={loading}
        loginData={loginData}
        mode={twoFARequired ? 'phone' : mode}
        recoverPassword={recoverPassword}
        setCode={val =>
          twoFARequired || mode === 'phone' ? this.setState({ codePhone: val }) : this.setState({ codeEmail: val })
        }
        step={1}
        totalSteps={this.getTotalSteps()}
        twoFARequired={twoFARequired}
        trackRender={(time: number) => RenderTrack.track('OnboardingVerifyCode', { renderTime: time, ...trackExtra })}
      />
    );
  };

  /**
   * Get register info step
   */
  private getRegisterInfoStep(trackExtra: any) {
    const { countries, lang, me, modalOpen, modalClose, userExists, setRegisterInfo, verifyUser, navAuthAction } =
      this.props;
    const { mode, registerInfoStep, emailVerificationNeeded } = this.state;
    const totalSteps = this.getTotalSteps();
    let stepNumber = 0;

    switch (registerInfoStep) {
      case REGISTER_INFO_STEPS.VERIFY:
        return this.getVerifyCodeStep(trackExtra, true);

      case REGISTER_INFO_STEPS.COMPANY:
        stepNumber = emailVerificationNeeded ? 3 : 2;
        return (
          <InputStep
            canSkip={true}
            countries={countries}
            cta={__('LoginRegisterFlow.Company.cta', { locale: lang })}
            goBack={() => this.setState({ registerInfoStep: REGISTER_INFO_STEPS.NAME })}
            inputType="default"
            label={__('LoginRegisterFlow.Company.subtitle', { locale: lang })}
            me={me}
            name="info_company_name"
            onNext={value => {
              setRegisterInfo({ companyName: value });
              this.setState({
                registerInfoStep: REGISTER_INFO_STEPS.BUSINESS,
              });
            }}
            onSkip={() => {
              modalOpen(
                __('LoginRegisterFlow.Company.modal_consumer.title', { locale: lang }),
                () => {
                  modalClose();
                  setRegisterInfo({ businessType: BUSINESS_TYPE.CONSUMER });
                  if (mode === 'phone') this.setState({ registerInfoStep: REGISTER_INFO_STEPS.EMAIL });
                  else this.register(this.onErrorModal);
                },
                {
                  icon: IMAGES.informativePineapple,
                  text2: __('LoginRegisterFlow.Company.modal_consumer.description', { locale: lang }),
                  showCancelButton: true,
                  buttonText: __('LoginRegisterFlow.Company.modal_consumer.cta', { locale: lang }),
                },
                'nice',
              );
            }}
            placeholder={__('LoginRegisterFlow.Company.placeholder', { locale: lang })}
            skipText={__('LoginRegisterFlow.Company.skip', { locale: lang })}
            step={stepNumber}
            title={__('LoginRegisterFlow.Company.title', { locale: lang })}
            totalSteps={totalSteps}
            trackRender={(time: number) => RenderTrack.track('OnboardAddCompany', { renderTime: time, ...trackExtra })}
            userExists={userExists}
          />
        );

      case REGISTER_INFO_STEPS.BUSINESS:
        stepNumber = emailVerificationNeeded ? 4 : 3;
        return (
          <Business
            cta={__('LoginRegisterFlow.Business.cta', { locale: lang })}
            goBack={() => this.setState({ registerInfoStep: REGISTER_INFO_STEPS.COMPANY })}
            lang={lang}
            me={me}
            onNext={(bsnType: BUSINESS_TYPE) => {
              setRegisterInfo({ businessType: bsnType });
              if (mode === 'phone') this.setState({ registerInfoStep: REGISTER_INFO_STEPS.EMAIL });
              else this.register(this.onErrorModal);
            }}
            step={stepNumber}
            subtitle={__('LoginRegisterFlow.Business.subtitle', { locale: lang })}
            title={__('LoginRegisterFlow.Business.title', { companyName: me.companyName, locale: lang })}
            totalSteps={totalSteps}
          />
        );

      case REGISTER_INFO_STEPS.EMAIL:
        return (
          <InputStep
            countries={countries}
            cta={__('LoginRegisterFlow.Email.cta', { locale: lang })}
            goBack={() => this.setState({ registerInfoStep: REGISTER_INFO_STEPS.BUSINESS })}
            inputType="email"
            label={__('LoginRegisterFlow.Email.subtitle', { locale: lang })}
            me={me}
            name="info_email"
            placeholder={__('LoginRegisterFlow.Email.placeholder', { locale: lang })}
            onNext={(value, onError) => {
              setRegisterInfo({ email: value });
              this.register(onError);
            }}
            step={5}
            title={__('LoginRegisterFlow.Email.title', { locale: lang })}
            totalSteps={totalSteps}
            trackRender={(time: number) => RenderTrack.track('OnboardAddEmail', { renderTime: time, ...trackExtra })}
            verifyUser={verifyUser}
            userExists={userExists}
          />
        );

      case REGISTER_INFO_STEPS.NAME:
      default:
        stepNumber = emailVerificationNeeded ? 2 : 1;
        return (
          <InputStep
            countries={countries}
            cta={__('LoginRegisterFlow.Name.cta', { locale: lang })}
            goBack={() => {
              if (mode === 'phone') this.setState({ codePhone: '' });
              else this.setState({ codeEmail: '' });
              if (emailVerificationNeeded) {
                this.setState({ registerInfoStep: REGISTER_INFO_STEPS.VERIFY });
              } else {
                navAuthAction(REGISTER_STEPS.WELCOME_LOGIN_REGISTER);
              }
            }}
            inputType="default"
            label={__('LoginRegisterFlow.Name.subtitle', { locale: lang })}
            me={me}
            name="info_name"
            placeholder={__('LoginRegisterFlow.Name.placeholder', { locale: lang })}
            onNext={value => {
              setRegisterInfo({ name: value });
              this.setState({ registerInfoStep: REGISTER_INFO_STEPS.COMPANY });
            }}
            step={stepNumber}
            title={__('LoginRegisterFlow.Name.title', { locale: lang })}
            totalSteps={totalSteps}
            trackRender={(time: number) => RenderTrack.track('OnboardAddName', { renderTime: time, ...trackExtra })}
            userExists={userExists}
          />
        );
    }
  }

  /**
   * Get total steps for register
   */
  private getTotalSteps = () => {
    const { twoFARequired, mode, emailVerificationNeeded } = this.state;
    if (twoFARequired) return 0;
    let result = 5;
    if (mode === 'email') result--;
    if (!emailVerificationNeeded) result--;
    return result;
  };

  /**
   * Update login data with key / value
   * Also compute extra fields for phone validation as phoneParsed and phoneValid
   */
  private updateLoginData = (name: string, value: string | number) => {
    const { countries } = this.props;
    const { loginData } = this.state;
    const selectedCountry = countries[name === 'country' ? value : loginData.country];
    const phone = name === 'phoneNumber' ? value + '' : loginData.phoneNumber;
    const parsed =
      phone && selectedCountry ? parsePhoneNumberFromString(phone, selectedCountry.iso2Code as CountryCode) : undefined;

    this.setState({
      loginData: {
        ...loginData,
        [name]: value,
        countryPrefix: name === 'country' && selectedCountry ? selectedCountry.phonePrefix : loginData.countryPrefix,
        countryCode: selectedCountry ? selectedCountry.iso2Code : undefined,
        phoneParsed: parsed,
        phoneValid: parsed ? parsed.isValid() : false,
      },
    });
  };

  /**
   * Get initial form login data.
   * Prefill country or phone if pre-existing
   */
  private getInitialFormLoginData(): ILoginData {
    const { countries } = this.props;
    const phone = typeof window !== 'undefined' ? localStorage.getItem('phone') : '';
    let selectedCountry = getNavigatorCountryCode(countries);
    if (!selectedCountry && typeof window !== 'undefined')
      selectedCountry = countryService.getCountryFromLocale(countries, window.navigator.language);
    const parsed =
      phone && selectedCountry ? parsePhoneNumberFromString(phone, selectedCountry.iso2Code as CountryCode) : undefined;
    return {
      country: selectedCountry ? selectedCountry.id : '',
      countryPrefix: selectedCountry ? selectedCountry.phonePrefix : '',
      countryCode: selectedCountry ? selectedCountry.iso2Code : '',
      phoneNumber: phone || '',
      password: '',
      phoneParsed: parsed,
      phoneValid: parsed ? parsed.isValid() : false,
    };
  }

  /**
   * Get two factor verification code
   */
  private getTwoFactorVerificationCode = (lastFourDigitsPhone?: string) => {
    const { navAuthAction } = this.props;
    if (lastFourDigitsPhone) this.setState({ last4DigitsPhone: lastFourDigitsPhone });
    this.setState({ twoFARequired: true });
    navAuthAction(REGISTER_STEPS.VERIFY_CODE);
  };

  /**
   * Register user
   */
  private register = (onError: (text: string) => void) => {
    const { codePhone, codeEmail, invite, loginData, oauthToken } = this.state;
    const {
      addContactByPricelistUrl,
      contactUrl,
      hideLoading,
      history,
      lang,
      me,
      redirect,
      register,
      scanContacts,
      setRegisterInfo,
      showLoading,
      updatePassword,
    } = this.props;
    setRegisterInfo({ inviteHashId: invite });

    register({ codePhone, codeEmail, oauthToken }, result => {
      if (result && result.status === 403) {
        return onError(__('Components.Onboarding.error_user_registred', { locale: lang }));
      }
      if (result && result.status === 404) {
        return onError(__('Components.Onboarding.error_generic', { locale: lang }));
      }
      if (result && !result.ok) {
        return onError(__('Components.Onboarding.error_generic', { locale: lang }));
      }
      EventTrack.userRegister({ user: me, result: 'success', error: '' });

      scanContacts(result.user.id!, () => null);

      const callbackPassword = rslt => {
        if (rslt === 'error') {
          return onError(__('Components.Onboarding.error_generic', { locale: lang }));
        }
        if (contactUrl) addContactByPricelistUrl(result.user, contactUrl, () => redirect && redirect(result.user.id!));
        else if (redirect) redirect();
      };
      if (loginData.password) {
        updatePassword(loginData.password, callbackPassword);
      } else {
        callbackPassword('');
      }

      showLoading('LoginRegisterFlow.showLoading', '', true, 'light');
      // This wait is currently performed so user information has the time to propagate
      // To segment and Stonly
      setTimeout(() => {
        if (history && !redirect) {
          history.replace(
            getPath({
              path: ROUTE_PATHS.ROOT,
            }),
          );
        }
        hideLoading('LoginRegisterFlow.hideLoading');
      }, 3000);
    });
  };

  /**
   * Error modal
   */
  private onErrorModal = (error: string) => {
    const { lang, modalOpen, modalClose } = this.props;
    modalOpen(
      __('Errors.unknown.title', { locale: lang }),
      () => modalClose(),
      { text2: error, icon: constants.ERROR_ICON },
      'nice',
    );
  };

  /**
   * Get initial login mode.
   * Check if user has used email or phone to login first
   */
  private getInitialMode() {
    const hasEmail = typeof window !== 'undefined' && !!global.localStorage.getItem('email');
    const modePreference = typeof window !== 'undefined' && global.localStorage.getItem('mode');
    return modePreference === 'email' && hasEmail ? 'email' : 'phone';
  }
}
