import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RowContainer } from '../components/atoms';
import styled from '../styled-components';

const Container = styled(RowContainer)`
  width: 100%;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`;

export type IBroadcastRouterProps = RouteComponentProps<{ channelId?: string }>;

const Component: React.FC<IBroadcastRouterProps> = React.memo(props => {
  const { Children } = props as any;
  return (
    <Container id="BroadcastContainer">
      <Children {...props} />
    </Container>
  );
});

export default Component;
