import {
  Api,
  buyerWorkspaceActions,
  modalActions,
  orderActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WorkspacePrepareOrders, {
  IDispatchProps,
  IRouteProps,
  IStateProps,
} from '../components/organisms/WorkspacePrepareOrders';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: IRouteProps): IStateProps {
  const {
    country: { countries },
    catalog: { catalogs, clients },
    order: { facets, facetsGlobal, hasMore, orders, searchId, totalResults },
    user: { user },
  } = state;

  const orderFiltersState = state.order.filters || emptyObject;
  return {
    catalog: catalogs[props.match.params.workspaceId],
    clients,
    contacts: state.contact.inConsentio,
    countries,
    facets,
    facetsGlobal,
    filters: orderFiltersState[0] || emptyObject,
    hasMore,
    me: user!,
    orders,
    searchId,
    totalResults,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      orderFiltersSet: orderActions.orderFiltersSet,
      orderGetByHash: orderActions.orderGetByHash,
      ordersAllMarkAsRead: orderActions.ordersAllMarkAsRead,
      orderScrollSearch: orderActions.orderScrollSearch,
      orderSearch: orderActions.orderSearch,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      requestProInfo: userActions.requestProInfo,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
      touchImage: modalActions.touchImage,
      unreadCommentsGet: sellerWorkspaceActions.unreadCommentsGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePrepareOrders);
