import { Api, contactActions, userActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as navActions from '../actions/nav';
import { resendCode, updatePassword, verifyEmailCode, verifyPhone, verifyPhoneCode } from '../actions/user';
import TwoFAModal, { IDispatchProps, IStateProps } from '../components/organisms/2FAModal';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState): IStateProps {
  const { loading, error, user } = state.user;
  return {
    loading,
    error,
    countries: state.country.countries,
    me: user,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, props: IStateProps): IDispatchProps {
  return bindActionCreators(
    {
      addContactByPricelistUrl: contactActions.addContactByPricelistUrl,
      addPhone: userActions.addPhone,
      checkPassword: userActions.checkPassword,
      navAuthAction: navActions.navAuthAction,
      navResetAuthAction: navActions.navResetAuthAction,
      resendCode,
      resetPassword: userActions.resetPassword,
      update2fa: userActions.update2fa,
      updatePassword,
      updateSettings: userActions.updateSettings,
      userExists: userActions.userExists,
      userSet: userActions.userSet,
      verifyEmailCode,
      verifyPhone,
      verifyPhoneCode,
      verifyUser: userActions.verifyUserData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFAModal);
