import styled, { ITheme } from '../../../../../styled-components';
import {
  Button,
  ColumnContainer,
  ExpansionPanel,
  FontIcon,
  Input as InputAtom,
  RowContainer,
  TextLabel,
} from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;
`;
