import { __, currency as currencyServices, date, IOrderDashboard, sellerWorkspaceService } from 'common-services';
import * as React from 'react';

import { DashboardBanner, SummaryWidget, TopProductsWidget, TopUsersWidget } from '../../molecules';
import { getDashboardData } from './Dashboard.utils';
import { TABS } from './OrdersList.constants';
import * as S from './OrdersList.styled';
import {
  getBarChartWidgetTitle,
  getPreviousGmvDescription,
  getPreviousGmvVariationDescription,
  getSummaryWidgetTitle,
} from './OrdersList.utils';

const BarChartWidget = React.lazy(() => import('../../molecules/Widgets/BarChartWidget/BarChartWidget.component'));

interface DashboardWidgetsProps {
  orders: Array<IOrder>;
  catalogHash?: string;
  catalogs: { [id: number]: IWorkspace };
  me: IUser;
  type: IOrderType;
  modalOpen: (name: string, onConfirm: () => void, options: any, modalType: string) => void;
  requestProInfo: (userId: number, workspaceId: number, source: string) => void;
  dashboard: IOrderDashboard;
  dateRange: IDateRange;
  search: ISearchOrder;
  tabSelected: string;
  contacts: { [cId: number]: IContact };
}

const DashboardWidgetsComponent: React.FC<DashboardWidgetsProps> = ({
  orders,
  catalogHash,
  catalogs,
  me,
  type,
  modalOpen,
  requestProInfo,
  dashboard,
  dateRange,
  search,
  tabSelected,
  contacts,
}) => {
  if (!orders.length) return null;

  const catalog = catalogHash
    ? Object.values(catalogs).find(c => c.hashId === catalogHash)
    : catalogs[me.sellerWorkspaceId];

  if ((!catalog || !sellerWorkspaceService.isProPlan(catalog.plan)) && type === 'sale') {
    return (
      <DashboardBanner
        onClick={() =>
          modalOpen('dashboards', () => requestProInfo(me.id, me.sellerWorkspaceId, 'dashboards'), {}, 'consentiopro')
        }
      />
    );
  }

  if (!dashboard || !Object.keys(dashboard.widgets).length) return null;

  const { data, hasSummaryData, hasTopProductsData, hasBarChartsData, hasTopUsersData } = getDashboardData(dashboard);
  const { summaryData, topUsersData, topProductsData, barChartsData } = data;
  const { dates } = dashboard;

  if (!hasSummaryData && !hasTopProductsData && !hasBarChartsData && !hasTopUsersData) return null;

  const { variationDescription, percentage } = getPreviousGmvVariationDescription(
    dateRange,
    summaryData.gmv,
    summaryData.previousGmv,
  );

  if (tabSelected === TABS.UNREAD) return null;

  return (
    <S.DashboardContainer className="orders-list-dashboard-container">
      {hasSummaryData && (
        <SummaryWidget
          className="orders-widget"
          count={__('Widgets.OrderSummary.count_orders', { count: summaryData.count })}
          totalPrice={currencyServices.getPriceAbbreviation(search.currency, summaryData.gmv)}
          title={getSummaryWidgetTitle(dateRange, type)}
          isHigher={Number(percentage) >= 0}
          totalPrevious={
            summaryData.previousGmv
              ? getPreviousGmvDescription(
                  dateRange,
                  currencyServices.getPriceAbbreviation(search.currency, summaryData.previousGmv),
                )
              : ''
          }
          previousChange={summaryData.previousCount ? variationDescription : ''}
          width="24%"
        />
      )}
      {hasTopProductsData && (
        <TopProductsWidget
          amSeller={type === 'sale'}
          className="orders-widget"
          currency={search.currency}
          data={topProductsData}
          limit={4}
          width="25%"
        />
      )}
      {hasBarChartsData && (
        <BarChartWidget
          className="orders-widget"
          currency={search.currency}
          data={barChartsData}
          groupBy={
            dates.beginDate && dates.endDate
              ? date.getGroupByFromDates({ beginDate: new Date(dates.beginDate), endDate: new Date(dates.endDate) })
              : undefined
          }
          title={getBarChartWidgetTitle(type)}
          width="27%"
        />
      )}
      {hasTopUsersData && (
        <TopUsersWidget
          amSeller={type === 'sale'}
          className="orders-widget"
          contacts={contacts}
          currency={search.currency}
          data={topUsersData}
          limit={3}
          width="24%"
        />
      )}
    </S.DashboardContainer>
  );
};

export const DashboardWidgets = React.memo(DashboardWidgetsComponent);
