import styled, { ITheme } from '../../../styled-components';
import { TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  darkBackground?: boolean;
}

export const Header = styled.div`
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  width: 100%;
  position: relative;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p: IStyledProps) => (p.darkBackground ? p.theme.colors.grey4 : p.theme.colors.white)};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  text-align: center;
  width: 100%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const SubTitleText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
export const FontIconWrapperRight = styled.div`
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const FontIconWrapperLeft = styled.div`
  position: absolute;
  left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
