import {
  __,
  date,
  IContact,
  ImageGalleryObject,
  IOrderRequest,
  IUser,
  IWorkspace,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import { AsYouType } from 'libphonenumber-js/mobile';
import * as React from 'react';

import * as constants from '../../../../constants';
import * as S from '../OrderRequestTable.styled';

interface IProps {
  catalogs?: { [id: number]: IWorkspace };
  className?: string;
  contacts: { [contactId: number]: IContact };
  isUnselect?: boolean;
  me: IUser;
  orderRequest: IOrderRequest;
  orderRequestClick: (order: IOrderRequest) => void;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  workspaceSelected?: string;
}

const OrderTableRow: React.FC<IProps> = ({
  catalogs,
  className,
  contacts,
  me,
  orderRequest,
  orderRequestClick,
  workspaceSelected,
}) => {
  function onRowClick(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    e.preventDefault();
    orderRequestClick(orderRequest);
  }

  const orderDate = orderRequest.createdAt;
  const dateFormat = me.settings.dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  const hourFormat = me.settings.hourFormat;
  const dateFormatted = date.formatLongDate(orderDate!, undefined, undefined, dateFormat);
  const timeFormatted = date.formatTime(orderDate!, hourFormat);

  // use both parameters id and hash because in realtime we don't have hash
  // remove when orderRequest.catalog_id and orderRequest.catalog_hash is done through backend
  const catalog =
    catalogs && Object.values(catalogs).find(c => c.hashId === orderRequest.hashId || c.id === orderRequest.catalogId);

  const seller = orderRequest.sellerId === me.id ? constants.meAsContact(me) : contacts[orderRequest.sellerId];

  const companyColumn = (
    <S.ContactCol>
      <S.TextBlack isRead={true}>{orderRequest.companyName || '-'}</S.TextBlack>
    </S.ContactCol>
  );
  const phoneColumn = (
    <S.ContactCol>
      <S.TextBlack isRead={true}>
        {orderRequest.phone ? new AsYouType().input('+' + orderRequest.phone) : '-'}
      </S.TextBlack>
    </S.ContactCol>
  );
  const emailColumn = (
    <S.ContactCol>
      <S.TextBlack isRead={true}>{orderRequest.email || '-'}</S.TextBlack>
    </S.ContactCol>
  );
  const emailValidationColumn = (
    <S.ContactCol>
      <S.TextBlack isRead={true}>
        {orderRequest.emailValidated ? (
          <S.TagBubble
            label={__('OrderRequests.confirmed', { count: orderRequest.items.length })}
            color="confirmed"
            disabled={true}
          />
        ) : (
          <S.TagBubble
            label={__('OrderRequests.not_confirmed', { count: orderRequest.items.length })}
            disabled={true}
          />
        )}
      </S.TextBlack>
    </S.ContactCol>
  );
  const workspaceColumn = (
    <S.ContactCol>
      <S.FlexRow>
        {workspaceSelected ? (
          <>
            <S.ContactImage img={seller.avatar} text={seller.name} avatarColor={seller.avatarColor} />
            <S.TextBlack isRead={true}>{seller.name}</S.TextBlack>
          </>
        ) : (
          <>
            <S.ContactImage
              img={catalog?.companyLogo.replace('w_450', '')}
              text={sellerWorkspaceService.getCatalogName(catalog, contacts, me)}
              avatarColor={utils.getAvatarColor(sellerWorkspaceService.getCatalogName(catalog, contacts, me))}
              type="team"
            />
            <S.TextBlack isRead={true}>{sellerWorkspaceService.getCatalogName(catalog, contacts, me)}</S.TextBlack>
          </>
        )}
      </S.FlexRow>
    </S.ContactCol>
  );
  const itemsColumn = (
    <S.ArticlesCol>
      <S.TextBlack isRead={true}>{orderRequest.items.length}</S.TextBlack>
    </S.ArticlesCol>
  );
  const dateColumn = (
    <S.DateCol>
      <S.FlexColumn>
        <S.TextDate isRead={true}>{dateFormatted}</S.TextDate>
        <S.TextTime isRead={true}>{timeFormatted}</S.TextTime>
      </S.FlexColumn>
    </S.DateCol>
  );

  return (
    <S.OrderTr checked={false} className={className} isRead={true} isSelected={false} onClick={e => onRowClick(e)}>
      <S.FillCol selectable={false} />
      {companyColumn}
      {phoneColumn}
      {emailColumn}
      {emailValidationColumn}
      {workspaceColumn}
      {dateColumn}
      {itemsColumn}
    </S.OrderTr>
  );
};

export default React.memo(OrderTableRow);
