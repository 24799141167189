import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { __ } from 'common-services';

import getPath from '../../../util/routes';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';

import { SelectClientModal } from '../../molecules';

interface RouteParams {
  workspaceId?: string;
}

interface SelectClientOrderModalProps {
  isPurchase: boolean;
  contacts: { [id: number]: IContact };
  me: IUser;
  type: 'sale' | 'purchase';
  catalogHash?: string;
  modalOpen: (title: string, onConfirm: () => void, options: any, modalType: string) => void;
  createNewContact: (userId: number, contactId: number, contactName: string, callback: (error?: Error) => void) => void;
  contactUpdateMySellerWorkspace: (
    userId: number,
    contactId: number,
    workspaceId: number,
    catalogHash: string,
    callback: (error?: Error) => void,
  ) => void;
  notificationShow: (notification: INotification) => void;
  onClose: () => void;
}

export const SelectClientOrderModal: React.FC<SelectClientOrderModalProps> = ({
  isPurchase,
  contacts,
  me,
  type,
  catalogHash,
  modalOpen,
  createNewContact,
  contactUpdateMySellerWorkspace,
  notificationShow,
  onClose,
}) => {
  const history = useHistory();
  const { workspaceId } = useParams<RouteParams>();

  // Helper function to generate the correct route path
  const getRoutePath = (contactId: string) => {
    const pathParams = {
      workspaceId: workspaceId || '',
      clientId: type === 'sale' ? contactId : undefined,
      supplierId: type === 'sale' ? undefined : contactId,
    };

    return getPath({
      path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES_SELL : ROUTE_PATHS.WORKSPACE_PURCHASES_BUY,
      ...pathParams,
    });
  };

  // Show success notification after creating a contact
  const showSuccessNotification = () => {
    notificationShow({
      title: __('ClientsList.create_contact_success'),
      subtitle: __('ClientsList.create_contact_success_sub'),
      closable: true,
      style: 'info',
    });
  };

  // Create a new contact and update workspace
  const createContact = (contactId: number, contactName: string) => {
    createNewContact(me.id, contactId, contactName, err => {
      if (err) return;

      contactUpdateMySellerWorkspace(me.id, contactId, Number(workspaceId), catalogHash || '', error => {
        if (!error) {
          showSuccessNotification();
          history.push(getRoutePath(contactId.toString()));
          onClose();
        }
      });
    });
  };

  // Open modal for creating a new contact
  const openCreateContactModal = (contactId: number, contactName: string) => {
    modalOpen(
      __('WorkspaceClientEdit.client_sales.create_contact.title'),
      () => createContact(contactId, contactName),
      {
        text2: __('WorkspaceClientEdit.client_sales.create_contact.subtitle'),
        buttonText: __('WorkspaceClientEdit.client_sales.create_contact.cta'),
        showCancelButton: true,
        icon: IMAGES.informativePineapple,
      },
      'nice',
    );
  };

  // Handle contact selection
  const handleSelectContact = (contactId: number, contactName: string) => {
    if (contacts[contactId]) {
      history.push(getRoutePath(contactId.toString()));
      onClose();
    } else {
      openCreateContactModal(contactId, contactName);
    }
  };

  return (
    <SelectClientModal
      title={isPurchase ? __('Components.OrdersList.modal.buy.title') : __('Components.OrdersList.modal.sale.title')}
      subtitle={
        isPurchase ? __('Components.OrdersList.modal.buy.subtitle') : __('Components.OrdersList.modal.sale.subtitle')
      }
      onClose={onClose}
      isPurchase={isPurchase}
      selectContact={handleSelectContact}
      catalogId={Number(workspaceId)}
      me={me}
    />
  );
};
