import styled, { css, ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  disabled?: boolean;
  hasError?: boolean;
  iconUrl?: string;
  margin?: string;
  position?: 'start' | 'end';
  textAlign?: 'left' | 'right' | 'center';
  theme: ITheme;
  width?: string | number;
}

export const Input = styled.input`
  pointer-events: ${(p: IStyledProps) => (p.disabled ? 'none' : 'all')};
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'auto')};
  outline: none;
  border: 0;
  font-family: inherit;
  width: 0;
  flex: 1 1 auto;
  text-align: ${(p: IStyledProps) => p.textAlign || 'inherit'};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};

  &::-moz-placeholder {
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
  }

  &::-webkit-input-placeholder {
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
  }
`;

export const InputWrapper = styled.div`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin: ${(p: IStyledProps) => p.margin || '0'};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  border: ${(p: IStyledProps) => `1px solid ${p.hasError ? p.theme.colors.red1 : p.theme.colors.grey2}`};
  font-family: inherit;
  border-radius: 4px;
  display: flex;
  flex: 1;
  min-height: 36px;
  max-height: 36px;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  position: relative;
  &:focus-within {
    outline: none !important;
    border: 1px solid ${(p: IStyledProps) => (p.hasError ? p.theme.colors.red1 : p.theme.colors.green1)};
    box-shadow: 0 0 4px ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  ${(p: IStyledProps) =>
    p.width &&
    css`
      max-width: ${p.width};
      min-width: ${p.width};
    `};

  ${(p: IStyledProps) =>
    p.hasError &&
    css`
      outline: none !important;
      border-color: ${p.theme.colors.red1};
    `}

  ${(p: IStyledProps) =>
    p.iconUrl &&
    css`
      background-image: url(${p.iconUrl});
      background-repeat: no-repeat;
      background-position: 98% 8px;
    `}
`;

export const DisabledWrapper = styled(RowContainer)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-family: inherit;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  flex: 1;
  background: inherit;
  position: relative;
  justify-content: flex-start;

  ${(p: IStyledProps) =>
    p.width &&
    css`
      max-width: ${p.width};
      min-width: ${p.width};
    `};

  ${(p: IStyledProps) =>
    p.iconUrl &&
    css`
      background-image: url(${p.iconUrl});
      background-repeat: no-repeat;
      background-position: 98% 8px;
    `}
`;

export const Text = styled.label<IStyledProps>`
  font-size: inherit;
  font-weight: inherit;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  align-self: center;
  ${(p: IStyledProps) =>
    p.position === 'end' &&
    css`
      padding-left: 4px;
    `}
`;

export const TextEnd = styled(Text)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-self: center;
  ${(p: IStyledProps) =>
    p.position === 'end' &&
    css`
      padding-left: 4px;
    `}
`;

export const EyeIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  padding-right: 10px;
`;

export const EyeIcon = styled(FontIcon)`
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const StartIcon = styled(FontIcon)`
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: center;
  margin-right: 2px;
`;

export const ReadOnly = styled(TextLabel)`
  font-size: inherit;
  color: inherit;
`;

export const ErrorMessage = styled(RowContainer)`
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
