import {
  __,
  constants,
  contactActions,
  currency,
  debounce,
  EventTrack,
  INVITE_ORIGIN,
  INVITE_VIA,
  ISearchClient,
  modalActions,
  notificationsActions,
  orderActions,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  throttle,
  utils,
  VIA,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { clientListColumns, ROUTE_PATHS } from '../../../constants';
import SelectContacts from '../../../screens/select-contacts';
import { getInviteOptions } from '../../../services/invite';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { copyToClipboard } from '../../../util/utils';
import { Button, LettersAvatar } from '../../atoms';
import { InviteAddressbookModal, SimpleSearch, Table } from '../../molecules';
import NonClients from '../../molecules/NonClients';
import { IColumn, IDataCell } from '../../molecules/Table/Table.component';
import Workspace from '../Workspace/Workspace.component';
import WorkspaceClientEdit from '../WorkspaceClientEdit';
import * as S from './WorkspaceClients.styled';

export interface IStateProps {
  amEditor: boolean;
  catalog: IWorkspace;
  columnConfig?: Array<IVisibilityConfig>;
  catalogs: { [catalogId: number]: IWorkspace };
  channels: { [channelId: string]: IChannel };
  clientsOld: Array<IClient>;
  contacts: { [cId: number]: IContact };
  hasOutContacts: boolean;
  me: IUser;
  orders: Array<IOrder>;
  priceGroups: { [pricegroupId: string]: IPriceGroup };
}

export interface IDispatchProps {
  cartCleanIfNeeded: typeof orderActions.cartCleanIfNeeded;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  contactsInvite: typeof contactActions.contactsInvite;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
  createNewContact: typeof contactActions.createContactWithName;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  notificationShow: typeof notificationsActions.notificationShow;
  priceGroupsGet: typeof sellerWorkspaceActions.priceGroupsGet;
  tableVisibilityConfigGet: typeof sellerWorkspaceActions.tableVisibilityConfigGet;
}

type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ workspaceId: string; clientId: string }> & { t?: number };

const WorkspaceClients: React.FC<IProps> = ({
  t = Date.now(),
  me,
  amEditor,
  catalog,
  contacts,
  history,
  location,
  match: {
    params: { workspaceId, clientId },
  },
  priceGroups,
  priceGroupsGet,
  tableVisibilityConfigGet,
  cartCleanIfNeeded,
  catalogs,
  hasOutContacts,
  clientsOld,
  clientsAdd,
  contactUpdateMySellerWorkspace,
  createNewContact,
  contactsInvite,
  modalOpen,
  notificationShow,
  columnConfig,
  channels,
  navigateChannelByPath,
}) => {
  const [elasticClients, setElasticClients] = React.useState<Array<IClient>>([]);
  const [hasMore, setHasMore] = React.useState<boolean>();
  const [searchId, setSearchId] = React.useState<string>();
  const [searchState, setSearchState] = React.useState<ISearchClient>({
    workspaceId: catalog?.id,
    text: '',
    language: me.settings.language as LOCALE,
    sort: 'name_sort',
    sortOrder: 'asc',
  });
  const [showAddClients, setShowAddClients] = React.useState<boolean>();
  const [showInviteModal, setShowInviteModal] = React.useState<INVITE_VIA>();
  const [totalResults, setTotalResults] = React.useState<number>(0);

  const sendSearch: () => void = debounce(() => {
    sellerWorkspaceService
      .clientSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api)
      .then(onReceiveClients);
  }, 200);

  const scrollSearch = throttle(() => {
    sellerWorkspaceService.clientScrollSearch(searchId, api).then(onReceiveClients);
  }, 200);

  React.useEffect(() => {
    RenderTrack.track('WorkspaceClients', { renderTime: t });
    if (!priceGroups[workspaceId]) priceGroupsGet(Number(workspaceId));
    if (catalog) {
      sendSearch();
    }
    if (config.TOGGLE_CLIENT_CUSTOM_COLUMNS.enabled) {
      tableVisibilityConfigGet(me.id, Number(workspaceId), 'client_list');
    }
  }, []);

  React.useEffect(() => {
    setSearchState({ ...searchState, workspaceId: catalog?.id });
  }, [catalog?.id]);

  React.useEffect(() => {
    if (catalog) {
      sendSearch();
    }
  }, [searchState]);

  const clientsToShow = elasticClients.map(c => {
    c.name =
      c.name ||
      (c.names
        ? c.names.find(n => n.client_id === me.id)?.name ||
          c.names.find(n => n.client_id === catalog.ownerId)?.name ||
          c.names[0]?.name ||
          ''
        : '');
    return c;
  });

  const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;
  const columns: Array<IColumn> = getColumns(isQuoterMode);

  return (
    <>
      {clientId ? <WorkspaceClientEdit /> : null}
      <Workspace
        subtitle={''}
        tabSelected="clients"
        title={__('Components.Header.WorkspaceClients')}
        workspaceId={Number(workspaceId)}
      >
        <S.Container onScroll={onScroll} className="clients-scroll-container">
          {!!clientsToShow.length || searchState.text ? (
            <>
              <S.SearchContainer>
                <SimpleSearch
                  onChange={f => setSearchState({ ...searchState, text: utils.toLocaleLowerCaseNormalized(f) })}
                  placeHolder={__('Components.ChatList.search.placeholder')}
                  id="input_search_chats_list"
                />
                {amEditor ? renderAddClientsLink() : null}
              </S.SearchContainer>
              <S.Stats>
                {__('Components.OrdersList.results', {
                  count: totalResults,
                })}
              </S.Stats>
            </>
          ) : null}
          <NonClients
            hasHits={clientsToShow && !!clientsToShow.length}
            linkAction={() => setShowAddClients(true)}
            workspaceId={Number(workspaceId)}
          >
            <Table
              columns={columns}
              configId="client_list"
              productColumns={clientListColumns(isQuoterMode)}
              emptyText={__('ClientsList.empty')}
              isQuoterMode={isQuoterMode}
              onClickRow={data =>
                history.push(
                  getPath({ path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT, workspaceId, clientId: data.userId + '' }),
                  {
                    from: location.pathname,
                  },
                )
              }
              scrollClassName="clients-scroll-container"
              selectable={false}
              showCustomColumns={config.TOGGLE_CLIENT_CUSTOM_COLUMNS.enabled}
              showStickyHeader={true}
              sort={searchState?.sort}
              sortOrder={searchState?.sortOrder}
              updateSortMode={updateSortMode}
              values={clientsToShow}
            />
          </NonClients>
        </S.Container>
        {showAddClients ? renderAddClients() : null}
        {!showAddClients && showInviteModal ? renderInviteFromPhonebook() : null}
      </Workspace>
    </>
  );

  function updateSortMode(sortBy: string, sortDirection: 'desc' | 'asc') {
    setSearchState({ ...searchState, sort: sortBy, sortOrder: sortDirection });
    EventTrack.track('workspace_clients_sort', { workspace_id: catalog.id, sort: `${sortBy} ${sortDirection}` });
  }

  /**
   * return the add clients link
   */
  function renderAddClientsLink() {
    return (
      <S.LinkAdd
        onSelect={handleInviteOptions}
        options={getInviteOptions([
          INVITE_VIA.CONSENTIO,
          ...(hasOutContacts ? [INVITE_VIA.PHONEBOOK] : []),
          'copy',
          INVITE_VIA.EMAIL,
          INVITE_VIA.SMS,
        ])}
      >
        <Button type="link" iconName="Add-contact" iconSize="16px">
          {__('ClientsList.add_clients')}
        </Button>
      </S.LinkAdd>
    );
  }

  /**
   * Handle invite options: contacts / agenda / copy / email / SMS
   */
  function handleInviteOptions(key: string) {
    switch (key) {
      case INVITE_VIA.PHONEBOOK:
        setShowInviteModal(INVITE_VIA.PHONEBOOK);
        break;
      case 'copy':
        contactsInvite(
          me.id!,
          VIA.LINK,
          'web',
          INVITE_ORIGIN.WORKSPACE,
          undefined,
          (data, error) => {
            if (data?.inviteLink && !error)
              setTimeout(() =>
                copyToClipboard(data.inviteLink, err =>
                  notificationShow(
                    {
                      style: err ? 'error' : 'success',
                      title: err ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                      subtitle: err ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                      closable: true,
                    },
                    4000,
                  ),
                ),
              );
          },
          undefined,
          catalog.id,
        );
        break;
      case INVITE_VIA.EMAIL:
        setShowInviteModal(INVITE_VIA.EMAIL);
        break;
      case INVITE_VIA.SMS:
        setShowInviteModal(INVITE_VIA.SMS);
        break;
      case INVITE_VIA.CONSENTIO:
        setShowAddClients(true);
        break;
    }
  }

  /**
   * Render modal for add members
   */
  function renderAddClients() {
    const isWorkspaceClient = (id: number) => {
      return contacts[id]?.mySellerWorkspaceId === catalog.id;
    };
    const contactAddedIds = clientsOld.filter(cl => isWorkspaceClient(cl.userId)).map(c => c.userId);
    const myId = me.id;
    const contactsNotAdded = Object.values(contacts).filter(
      c => !contactAddedIds.includes(c.id) && c.isBuyer && c.id !== myId,
    );
    return (
      <SelectContacts
        catalogId={catalog?.id}
        contacts={contactsNotAdded}
        extraInfo={(c: IContact) => {
          if (c.mySellerWorkspaceId !== catalog.id) {
            return __('ClientsList.client_extra_info', {
              workspace: sellerWorkspaceService.getCatalogName(catalogs[c?.mySellerWorkspaceId], contacts, me),
            });
          }
        }}
        onClose={() => setShowAddClients(false)}
        onSave={(contactsToAdd: Array<number>) => {
          const clientNames = contactsToAdd.map(cId => contacts[cId].name).join(', ');
          const contactsAlreadyInWS = contactsToAdd.filter(cId => isWorkspaceClient(cId));
          const areAllContactsFromWorkspace = () => {
            return contactsToAdd.every(c => contactsAlreadyInWS.includes(c));
          };
          {
            !areAllContactsFromWorkspace()
              ? modalOpen(
                  contactsToAdd.length === 1
                    ? __('ContactInfo.Menu.workspace.modal.title', {
                        client: clientNames,
                        workspaceNew: catalog.name,
                      })
                    : __('ContactInfo.Menu.workspace.modal.title_many', {
                        client: clientNames,
                        workspaceNew: catalog.name,
                      }),
                  () => {
                    clientsAdd(
                      myId,
                      Number(workspaceId),
                      contactsToAdd.map(cId => {
                        const contact = contacts[cId];
                        return sellerWorkspaceService.contactToClient(contact, Number(workspaceId));
                      }),
                      error => {
                        if (error) {
                          return notificationShow({
                            title: __('ClientsList.error', { count: contactsToAdd.length }),
                            subtitle: __('ClientsList.error_description', { count: contactsToAdd.length }),
                            closable: true,
                            style: 'error',
                          });
                        }
                        return notificationShow({
                          title: __('ClientsList.success', { count: contactsToAdd.length }),
                          subtitle: __('ClientsList.success_description', { count: contactsToAdd.length }),
                          closable: true,
                          style: 'info',
                        });
                      },
                    );
                    contactsToAdd.forEach(contactId =>
                      contactUpdateMySellerWorkspace(me.id, contactId, catalog.id, catalog.hashId),
                    );
                  },
                  {
                    icon: IMAGES.informativePineapple,
                    text2:
                      contactsToAdd.length === 1
                        ? __('ContactInfo.Menu.workspace.modal.text', {
                            client: clientNames,
                            workspaceOld: catalogs[contacts[contactsToAdd[0]]?.mySellerWorkspaceId]?.name,
                            workspaceNew: catalog.name,
                          })
                        : __('ContactInfo.Menu.workspace.modal.text_many', {
                            client: clientNames,
                            workspaceNew: catalog.name,
                          }),
                    buttonText: __('ContactInfo.Menu.workspace.modal.cta'),
                    showCancelButton: true,
                    buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
                  },
                  'nice',
                )
              : clientsAdd(
                  myId,
                  Number(workspaceId),
                  contactsToAdd.map(cId => {
                    const contact = contacts[cId];
                    return sellerWorkspaceService.contactToClient(contact, Number(workspaceId));
                  }),
                  error => {
                    if (error) {
                      return notificationShow({
                        title: __('ClientsList.error', { count: contactsToAdd.length }),
                        subtitle: __('ClientsList.error_description', { count: contactsToAdd.length }),
                        closable: true,
                        style: 'error',
                      });
                    }
                    return notificationShow({
                      title: __('ClientsList.success', { count: contactsToAdd.length }),
                      subtitle: __('ClientsList.success_description', { count: contactsToAdd.length }),
                      closable: true,
                      style: 'info',
                    });
                  },
                );
          }
          setShowAddClients(false);
        }}
        origin={INVITE_ORIGIN.WORKSPACE}
        showInvite={true}
        showUnregistered={true}
        title={__('ClientsList.add_clients_consentio')}
        zeroCase="clients"
        trackingFrom="workspace-clients"
      />
    );
  }

  /**
   * handle the scroll event for sticky the searchbar
   */
  function onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      scrollSearch();
    }
  }

  function onReceiveClients(res: ISearchData<IClient>) {
    if (!res) return;
    const { data, toReplace } = res;
    if (searchState.text) {
      EventTrack.track('workspace_clients_search', { workspace_id: catalog.id, search: searchState.text });
    }
    if (toReplace) {
      setElasticClients(data.hits);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    } else {
      setElasticClients([...elasticClients, ...data.hits]);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    }
  }

  /**
   * Render the actions for the client (last column)
   */
  function renderActions(data: IClient) {
    if (contacts[data.userId] && !isWorkspaceClient(data)) {
      return (
        <S.TextWrapper>
          <S.TextBlue
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              modalOpen(
                __('ContactInfo.Menu.workspace.modal.title', {
                  client: data.name,
                  workspaceNew: catalog.name,
                }),
                () => contactUpdateMySellerWorkspace(me.id, data.userId, catalog.id, catalog.hashId),
                {
                  icon: IMAGES.informativePineapple,
                  text2: __('ContactInfo.Menu.workspace.modal.text', {
                    client: data.name,
                    workspaceOld: catalogs[contacts[data.userId]?.mySellerWorkspaceId]?.name,
                    workspaceNew: catalog.name,
                  }),
                  buttonText: __('ContactInfo.Menu.workspace.modal.cta'),
                  showCancelButton: true,
                  buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
                },
                'nice',
              );
            }}
          >
            {__('ClientsList.match_catalog', { newCatalog: catalog.name })}
          </S.TextBlue>
          <S.TooltipContainer>
            <S.Tooltip
              position="bottom"
              text={__('ClientsList.tooltip', {
                oldCatalog: catalogs[contacts[data.userId]?.mySellerWorkspaceId]?.name,
              })}
              width="200px"
              themeMode="dark"
            >
              <S.InfoIcon disableHover={true} name="Info" />
            </S.Tooltip>
          </S.TooltipContainer>
        </S.TextWrapper>
      );
    } else if (contacts[data.userId]) {
      return (
        <S.SellButton
          type="other"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            const key = productService.getKey(me.id, data.userId);
            cartCleanIfNeeded(key, data.currency, catalog.id, 0);
            history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_CLIENT_SELL, workspaceId, clientId: data.userId + '' }));
          }}
        >
          {utils.firstToUpperCase(__('Showroom.sale_cta').toLowerCase())}
        </S.SellButton>
      );
    } else if (data.userId !== me.id) {
      return (
        <S.SellButton
          type="link"
          withoutPadding={true}
          onClick={e => {
            e.stopPropagation();
            createNewContact(me.id, data.userId, data.name, err => {
              if (err) return null;
              contactUpdateMySellerWorkspace(
                me.id,
                data.userId,
                catalog.id,
                catalog.hashId,
                error =>
                  !error &&
                  notificationShow({
                    title: __('ClientsList.create_contact_success'),
                    subtitle: __('ClientsList.create_contact_success_sub'),
                    closable: true,
                    style: 'info',
                  }),
              );
            });
          }}
          iconName="Add-more"
          iconSize="18px"
        >
          {__('ClientsList.add_contact')}
        </S.SellButton>
      );
    }
    return null;
  }

  /**
   * Render modal with invite from address book
   */
  function renderInviteFromPhonebook() {
    return (
      <InviteAddressbookModal
        addressbookTitle={__('Components.ChatList.invite.addressbook.clients_title')}
        otherTitle={__('Components.ChatList.invite.other.clients_title')}
        catalogId={catalog.id}
        defaultInviteBy={showInviteModal}
        from="workspace-clients"
        me={me}
        onClose={() => setShowInviteModal(undefined)}
        origin={INVITE_ORIGIN.WORKSPACE}
      />
    );
  }

  /**
   * Is client of my workspace?
   */
  function isWorkspaceClient(client: IClient) {
    return contacts[client.userId]?.mySellerWorkspaceId === catalog.id;
  }

  /**
   * Get columns for the table
   */
  function getColumns(isQuoterMode: boolean): Array<IColumn> {
    const result = [];
    const columnConfigWorkspace = columnConfig?.find(v => v.view === 'client_list');
    const configCol = columnConfigWorkspace?.columns?.length
      ? (columnConfigWorkspace?.columns as Array<IProductColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig('client_list');

    const priceGroupsArray = Object.values(priceGroups);
    const getColor = (data: IClient) => (!isWorkspaceClient(data) && !!contacts[data.userId] ? 'grey' : 'black');

    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'client':
            result.push(
              getColumnField(column, {
                getElement: (data: IClient) => (
                  <S.FlexRow isDisabled={!isWorkspaceClient(data) && !!contacts[data.userId]}>
                    <S.ClientImagesRow>
                      <LettersAvatar
                        size={32}
                        text={data.company}
                        iconName={data.company ? undefined : 'Enterprise'}
                        img={data.logo}
                        avatarColor={utils.getAvatarColor(data.company || '-')}
                      />
                    </S.ClientImagesRow>
                    <S.ClientNameColumn>
                      <S.TextBlack>{data.company || '-'}</S.TextBlack>
                    </S.ClientNameColumn>
                  </S.FlexRow>
                ),
              }),
            );
            break;

          case 'contact':
            result.push(
              getColumnField(column, {
                getElement: (data: IClient) => (
                  <S.FlexRow isDisabled={!isWorkspaceClient(data) && !!contacts[data.userId]}>
                    <S.ClientImagesRow>
                      <LettersAvatar
                        size={32}
                        text={data.name}
                        img={data.avatar}
                        avatarColor={utils.getAvatarColor(data.name)}
                      />
                    </S.ClientImagesRow>
                    <S.ClientNameColumn>
                      {data.name ? <S.TextBlack>{data.name}</S.TextBlack> : null}
                      {contacts[data.userId] ? (
                        <S.LinkButton
                          type="link"
                          withoutPadding={true}
                          onClick={e => {
                            e.stopPropagation();
                            const channelToShow = Object.values(channels).find(
                              chan => chan.type === 'private' && chan.members.find(cm => cm.id === data.userId),
                            );
                            if (channelToShow)
                              navigateChannelByPath(ROUTE_PATHS.CHAT, channelToShow.id, (path: string) =>
                                history.push(path),
                              );
                          }}
                        >
                          {__('ClientsList.go_to_chat')}
                        </S.LinkButton>
                      ) : null}
                    </S.ClientNameColumn>
                  </S.FlexRow>
                ),
              }),
            );
            break;

          case 'price':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: data =>
                  data.priceMode === 'none'
                    ? __('Components.ContactDetails.none_prices_title')
                    : data.priceMode === 'read'
                    ? __('Components.ContactDetails.read_prices_title')
                    : __('Components.ContactDetails.edit_prices_title'),
              }),
            );
            break;

          case 'accept':
            result.push(
              getColumnField(column, {
                getElement: (data: IClient) =>
                  data.blockedOrders ? (
                    <S.TextGrey2>
                      <S.AddIcon name="Forbidden" />
                      {__('ClientsList.blocked')}
                    </S.TextGrey2>
                  ) : (
                    <S.TextBlack isDisabled={!isWorkspaceClient(data) && !!contacts[data.userId]}>
                      {utils.firstToUpperCase(
                        ['default', 'manual'].includes(data.orderAccept)
                          ? __('ClientsList.accept.manually')
                          : __('ClientsList.accept.automatic'),
                      )}
                    </S.TextBlack>
                  ),
              }),
            );
            break;

          case 'rates':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: (data: IClient) =>
                  priceGroupsArray
                    ? priceGroupsArray
                        .filter(priceGroup => priceGroup.clients.find(cl => cl.userId === data.userId))
                        .map(p => p.name || p.description || p.priceGroupId || '')
                        .join(', ')
                    : null,
              }),
            );
            break;

          case 'transport':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: data =>
                  data.transportCost
                    ? currency.getPricePerUnit(
                        data.currency,
                        data.transportCostUnit as any,
                        catalog?.defaultWeightUnit,
                        data.transportCost,
                      )
                    : null,
              }),
            );
            break;

          case 'currency':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: data => `${data.currency} - ${constants.CURRENCIES[data.currency]?.name()}`,
              }),
            );
            break;

          case 'reference-id':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: data => data.counterpartId,
              }),
            );
            break;

          case 'price-adjust':
            result.push(
              getColumnField(column, {
                getColor,
                getValue: data => {
                  if (data.priceAdjust === 0) return '-';
                  const isDiscount = data.priceAdjust < 0;
                  let title = isDiscount
                    ? __('AddCustomLineModal.cost_options.discount')
                    : __('AddCustomLineModal.cost_options.markup');
                  if (isQuoterMode) title = __('ClientsList.margin');
                  return `${title} ${Math.abs(data.priceAdjust)}%`;
                },
              }),
            );
            break;

          default:
            result.push(getColumnField(column));
            break;
        }
      });

    result.push(
      getColumnField(
        { name: 'actions', order: result.length, visible: true },
        { getElement: data => renderActions(data) },
      ),
    );

    return result;
  }

  function getColumnField(
    columnConfig: IColumnConfig,
    options?: {
      getColor?: (data: IClient, idx: number, rowIdx: number) => string;
      getValue?: (data: IClient, idx: number, rowIdx: number) => string | number;
      getElement?: (data: IClient, idx: number, rowIdx: number) => React.ReactElement;
      getDataCell?: (data: IClient, idx: number, rowIdx: number) => IDataCell;
    },
  ): IColumn {
    const { getValue, getElement, getColor } = options || {};
    const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;

    switch (columnConfig.name) {
      case 'client':
        return {
          id: 'client',
          title: __('ClientsList.client'),
          sort: 'company_sort',
          sortOrder: ['desc', 'asc'],
          element: getElement,
        };
      case 'contact':
        return {
          title: __('ClientsList.contact'),
          sort: 'name_sort',
          sortOrder: ['desc', 'asc'],
          id: 'contact',
          element: getElement,
        };
      case 'price':
        return {
          title: __('ClientsList.price'),
          id: 'price',
          getColor,
          value: getValue,
        };
      case 'accept':
        return {
          title: __('ClientsList.accept.title'),
          id: 'accept',
          element: getElement,
        };
      case 'rates':
        return {
          title: __('ClientsList.rates'),
          id: 'rates',
          getColor,
          value: getValue,
        };
      case 'transport':
        return {
          title: __('ClientsList.transport'),
          id: 'transport',
          getColor,
          value: getValue,
        };
      case 'actions':
        return {
          title: '',
          id: 'actions',
          element: getElement,
        };
      case 'currency':
        return {
          title: __('ClientsList.currency'),
          id: 'currency',
          getColor,
          value: getValue,
        };
      case 'reference-id':
        return {
          title: __('ClientsList.reference_id'),
          id: 'reference-id',
          getColor,
          value: getValue,
        };
      case 'price-adjust':
        return {
          title: isQuoterMode ? __('ClientsList.margin') : __('ClientsList.price_adjust'),
          id: 'price-adjust',
          getColor,
          value: getValue,
        };
    }
  }
};

export default WorkspaceClients;
