import * as React from 'react';
import { useSelector } from 'react-redux';
import MiniImages from '../MiniImages';
import { IColumn } from '../Table/Table.component';
import * as S from './ReferenceListTable.styled';
import { __, date, userSelectors, utils } from 'common-services';
import { IGroupedDataReferenceList } from 'common-services/dist/associate-reference/interface';
import { IMAGE_DEFAULT } from '../../../constants';

export const getColumns = (): Array<IColumn> => {
  const me = useSelector(userSelectors.getUser);
  return [
    {
      title: __('Components.SellerOffer.referenceList.table.productDescription'),
      id: 'productName',
      width: '120px',
      element: (data: IGroupedDataReferenceList) => {
        const productImages = data?.images?.map(image => image.image.url) || [];
        return (
          <>
            <S.ContainerImageAndText>
              <S.ProductImage imageUrl={productImages[0] || IMAGE_DEFAULT} size="extra-small" />
              <S.ProductText> {data.productName || ''}</S.ProductText>
            </S.ContainerImageAndText>
          </>
        );
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.table.SKU'),
      id: 'sku',
      width: '100px',
      sort: 'sku',
      sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
      element: (data: IGroupedDataReferenceList) => {
        return (
          <S.FlexRow>
            <S.TextBlack>{data.sku}</S.TextBlack>
          </S.FlexRow>
        );
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.table.associateReference'),
      id: 'associateReference',
      width: '100px',
      element: (data: IGroupedDataReferenceList) => {
        return <S.TextBlack>{data.references.length}</S.TextBlack>;
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.table.clients'),
      id: 'clients',
      width: '100px',
      sort: 'updatedAt',
      sortOrder: ['desc'],
      element: (data: IGroupedDataReferenceList) => {
        const limitedReferences = data.references.slice(0, 4);
        const clientsData = limitedReferences.map(reference => ({
            pictureUrl: reference.workspacePublicOverview.pictureUrl || "",
            personName: reference.workspacePublicOverview.publicName
        }));
        const clientsImages = clientsData.map(client => client.pictureUrl);
        const personNames:Array<ILightContact> = clientsData.map(client => ({
          avatarColor: {
            background: "",
            text: "",
          },
          companyName: client.personName,
          isUnregistered: false,
          name: client.personName,
          phoneNumbers: [],
        }));
    
        return (
          <MiniImages 
            className="pricegroups-table-images" 
            count={data.references.length} 
            imagesAndPersons={{imageUrl: clientsImages, person: personNames}}
            mode='imagesAndPersons'
          />
        );
    },
    },
    {
      title: __('Components.SellerOffer.referenceList.table.lastModify'),
      id: 'lastModify',
      width: '100px',
      element: (data: IGroupedDataReferenceList) => {
        const formatDate = date.dateToText(data.updatedAt, me.settings.hourFormat);
        return (
          <S.LeftContainer>
            <S.Text>{formatDate.date}</S.Text>
          </S.LeftContainer>
        );
      },
    },
  ];
};

export const columnsReferenceListTable = [
  { value: 'productName', label: __('Components.SellerOffer.referenceList.table.productDescription') },
  { value: 'sku', label: __('Components.SellerOffer.referenceList.table.SKU') },
  { value: 'associateReference', label: __('Components.SellerOffer.referenceList.table.associateReference') },
  { value: 'clients', label: __('Components.SellerOffer.referenceList.table.clients') },
  { value: 'lastModify', label: __('Components.SellerOffer.referenceList.table.lastModify') },
];
