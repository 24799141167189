import styled, { ITheme } from '../../../../../styled-components';
import { RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  code?: string;
}

export const Container = styled(RowContainer)`
  min-height: min-content;
  align-self: center;
  justify-content: center;
  align-items: flex-end;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  max-width: 90%;
  width: 520px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) =>
    ['privacy_disclaimer', 'price_disclaimer'].includes(p.code)
      ? p.theme.colors.orange1.replace('1)', '0.3)')
      : p.theme.colors.blue1.replace('1)', '0.2)')};
  white-space: pre-wrap;
`;

export const TextContainer = styled(RowContainer)`
  flex: 1;
  justify-content: center;
`;

export const MessageText = styled.span`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: ${(p: IStyledProps) => p.code === 'price_disclaimer' ? 'left' : 'center'};
`;

export const MessageTextBold = styled(MessageText)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const DateTimeText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  overflow: hidden;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: right;
`;

export const Link = styled.span`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  display: block;
`;

export const TextInfoLink = styled(MessageText)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

