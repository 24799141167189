import {
  Api,
  buyerWorkspaceActions,
  imageActions,
  modalActions,
  notificationsActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WorkspaceSettingsBuyer, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceSettingsBuyer';
import { IReduxState } from '../reducers';

const emptyArray: Array<any> = [];
function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    contact: { inConsentio },
    user: { user },
    workspace: { workspaces, addresses },
    country: { countries },
  } = state!;
  const workspaceId = Number(props.match.params.workspaceId) || user.buyerWorkspaceId;

  return {
    contacts: inConsentio,
    me: user!,
    workspace: workspaces[workspaceId],
    countries,
    addresses: addresses?.[workspaceId] || emptyArray,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      touchImage: modalActions.touchImage,
      workspaceAddressAdd: buyerWorkspaceActions.workspaceAddressAdd,
      workspaceAddressDelete: buyerWorkspaceActions.workspaceAddressDelete,
      workspaceAddressesGet: buyerWorkspaceActions.workspaceAddressesGet,
      workspaceAddressUpdate: buyerWorkspaceActions.workspaceAddressUpdate,
      workspaceUpdate: buyerWorkspaceActions.workspaceUpdate,
      catalogMemberDelete: sellerWorkspaceActions.catalogMemberDelete,
      catalogMembersUpdate: sellerWorkspaceActions.catalogMembersUpdate,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsBuyer);
