import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)``;

export const Text = styled(TextLabel)`
  width: auto;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextDescription = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: ${(p: IStyledProps) => p.theme.paddingView};
  padding-top: calc(${(p: IStyledProps) => p.theme.paddingView} * 2);
`;

export const QRCodeContainer = styled(ColumnContainer)`
  align-items: center;
  margin: ${(p: IStyledProps) => p.theme.paddingView} auto;
`;

export const QRCodeFooter = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingView} auto;
  align-items: center;
  margin-bottom: 0;
`;

export const Actions = styled(RowContainer)`
  align-items: center;
  align-self: center;
  text-align: center;
  margin: ${(p: IStyledProps) => p.theme.paddingView} auto;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const DownloadIcon = styled(FontIcon)`
  margin-right: 10px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: 16px;
`;
