import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { CheckBox as CB, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  clickable?: boolean;
  isDiscount?: boolean;
  overlay?: boolean;
  theme: ITheme;
  isActive?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(10)};

  .priceitem-table tr td:last-child {
    padding-right: 0;
  }
`;

const Col = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ProductCol = styled(Col)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextProduct = styled(TextBlack)`
  max-height: 40px;
`;

export const TextPrice = styled(TextBlack)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TextSemiBlack = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const TextGrey2 = styled(TextBlack)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  display: inline;
`;

export const TextPercentage = styled(TextBlack)`
  color: ${(p: IStyledProps) => (p.isDiscount ? p.theme.colors.red1 : p.theme.colors.green1)};
`;

export const ProductImage = styled.img`
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  ${(p: IStyledProps) =>
    p.overlay &&
    css`
      opacity: 0.35;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};
  &:hover {
    filter: grayscale(0.95) brightness(0.6);
  }
  cursor: pointer;
`;

export const FlexRow = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: flex-start;
  ${(p: IStyledProps) =>
    p.clickable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `};
`;

export const FlexCol = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: center;
`;

export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: 18px;
  height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const MoreWrapper = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  justify-content: center;
  display: flex;
`;

export const Link = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;

export const ActiveIndicator = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${(p: IStyledProps) => (p.isActive ? p.theme.colors.green1 : p.theme.colors.grey2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const CheckBox = styled(CB)`
  margin: 0 auto;
`;
