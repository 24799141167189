import { __, contactActions, INVITE_ORIGIN, notificationsActions, VIA } from 'common-services';
import * as React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { IReduxState } from '../../../reducers';
import { copyToClipboard } from '../../../util/utils';
import AddSuppliers from '../AddSuppliers';
import EmptyListResource from '../EmptyListResource';
import * as S from './NonProviders.styled';

interface IProps {
  children: any;
  fromProducts?: boolean;
  hasHits: boolean;
  workspaceId: number;
}

/**
 * Zero case for suppliers
 */
const NonProviders = React.memo(({ hasHits, children, fromProducts, workspaceId }: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useStore<IReduxState>().getState().user.user;
  const [showAddSuppliers, setShowAddSuppliers] = React.useState(false);
  return hasHits ? (
    children
  ) : (
    <S.CenterContainer>
      <EmptyListResource
        text={fromProducts ? __('NonProviders.title') : __('NonProviders.products.title')}
        text2={fromProducts ? __('NonProviders.description') : __('NonProviders.products.description')}
        imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
        showButton={false}
        links={[
          {
            text: __('NonProviders.link1'),
            action: () => {
              setShowAddSuppliers(true);
            },
            iconName: 'Add-contact',
          },
          {
            text: __('NonProviders.link2'),
            action: () => {
              dispatch(
                contactActions.contactsInvite(
                  me.id!,
                  VIA.LINK,
                  'web',
                  INVITE_ORIGIN.WORKSPACE,
                  undefined,
                  (data, error) => {
                    if (data?.inviteLink && !error)
                      setTimeout(() =>
                        copyToClipboard(data.inviteLink, err =>
                          dispatch(
                            notificationsActions.notificationShow(
                              {
                                style: err ? 'error' : 'info',
                                title: err ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                                subtitle: err ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                                closable: true,
                              },
                              4000,
                            ),
                          ),
                        ),
                      );
                  },
                  undefined,
                  workspaceId,
                ),
              );
            },
            iconName: 'Link',
          },
        ]}
      />
      {showAddSuppliers ? <AddSuppliers close={() => setShowAddSuppliers(false)} workspaceId={workspaceId} /> : null}
    </S.CenterContainer>
  );
});
export default NonProviders;
