import {
  __,
  addresses as addressUtils,
  constants,
  currency,
  EventTrack,
  modalActions,
  notificationsActions,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
  parsers,
  PRODUCT_UNIT,
  productActions,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userSelectors,
  utils,
} from 'common-services';
import { isAllowedSaleUnit } from 'common-services/dist/product/service';
import { History, Location } from 'history';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../../../bindings/config';
import { IMAGES } from '../../../../../assets';
import { IMAGE_DEFAULT, orderDetailBuyerColumns, orderDetailColumns, ROUTE_PATHS } from '../../../../../constants';
import { calculatePriceServedUnit } from '../../../../../domain/order';
import { AllProductUnitsArray } from '../../../../../domain/product';
import { logError } from '../../../../../services/log';
import getPath from '../../../../../util/routes';
import { priceUnitTranslate } from '../../../../../util/utils';
import { ColumnContainer, FontIcon, Input, Select, SimpleDropdown, Switch, TagBubble, Tooltip } from '../../../../atoms';
import { ContactCell, Table } from '../../../../molecules';
import SelectWorkspaceModal from '../../../../molecules/SelectWorkspaceModal';
import { IColumn, IDataCell, TYPE_CELL } from '../../../../molecules/Table/Table.component';
import * as S from './ItemsCard.styled';

interface IProps {
  addresses?: Array<IAddress>;
  amEditor: boolean;
  amSeller: boolean;
  anyPriceEmpty: boolean;
  canServeOrder: boolean;
  cartUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void;
  cartSet: typeof orderActions.cartSet;
  catalog?: IWorkspace;
  catalogs?: { [cId: number]: IWorkspace };
  columnConfig?: Array<IVisibilityConfig>;
  contactId: number;
  contacts?: { [key: number]: IContact };
  countries?: { [key: string]: ICountry };
  deletable?: boolean;
  editAfterAccept: boolean;
  getCartLoadSummaryText: typeof orderService.getCartLoadSummaryText;
  hideTotal: boolean;
  history: History<any>;
  inactiveCatalog?: boolean;
  isFavoriteCheck: boolean;
  isPrepare?: boolean;
  issues: Array<IOrderIssue>;
  items: Array<IOrderItem>;
  location: Location<any>;
  me: IUser;
  navigateToProductInfo: (item: IOrderItem) => void;
  navigateToShowroom: () => void;
  notificationShow: typeof notificationsActions.notificationShow;
  onEnableItem: (item: IOrderItem, issue: IOrderIssue) => void;
  onModifyAfterAccept: () => void;
  order: IOrder;
  orderDiscardLine: (issue: IOrderIssue) => void;
  orderEditable: boolean;
  orderUpdatePrepared: typeof orderActions.orderUpdatePrepared;
  orderItemRecover: typeof orderActions.orderItemRecover;
  orderItemStopDiscarding: typeof orderActions.orderItemStopDiscarding;
  openIssuesModal?: (orderItem: IOrderItem) => void;
  openBoxesPerPalletModal?: (orderItem: IOrderItem) => void;
  openBppConfirmationModal?: (orderItem: IOrderItem, newBppValue?: number) => void;
  previewMode?: boolean;
  priceMode: IPriceMode;
  pricePrecision: number;
  prodTypes: { [key: string]: IProdType };
  productAddAttributeValue?: (
    productId: number,
    attributeSlug: string,
    value: { value: number; client_id: number },
    cb: any,
  ) => void;
  productGetByHashPublic: typeof productActions.productGetByHashPublic;
  self: boolean;
  servedFlowEnabled: boolean;
  setIsFavoriteCheck: (checked: boolean) => void;
  showCustomColumns?: boolean;
  showPORProductModal: (item: IOrderItem) => void;
  showSearchProductModal: (item: IOrderItem, originLot?: number, mappingCode?: string) => void;
  tableVisibilityConfigGet: typeof sellerWorkspaceActions.tableVisibilityConfigGet;
  touchImage: typeof modalActions.touchImage;
  modalOpen: typeof modalActions.modalOpen;
  modalClose: typeof modalActions.modalClose;
  weAreSeller?: boolean;
  weAreBuyer?: boolean;
  workspaceSelected: IWorkspace; // Workspace selected from navigation
}

const ItemsCard: React.FC<IProps> = ({
  addresses,
  amEditor,
  amSeller,
  anyPriceEmpty,
  canServeOrder,
  cartUpdateItem,
  cartSet,
  catalog,
  catalogs,
  columnConfig,
  contactId,
  contacts,
  countries,
  deletable,
  editAfterAccept,
  getCartLoadSummaryText,
  hideTotal,
  history,
  inactiveCatalog,
  isFavoriteCheck,
  isPrepare,
  issues,
  items,
  location,
  me,
  navigateToProductInfo,
  navigateToShowroom,
  notificationShow,
  onEnableItem,
  onModifyAfterAccept,
  openIssuesModal,
  openBoxesPerPalletModal,
  openBppConfirmationModal,
  order,
  orderDiscardLine,
  orderEditable,
  orderUpdatePrepared,
  orderItemRecover,
  orderItemStopDiscarding,
  modalOpen,
  modalClose,
  previewMode,
  priceMode,
  pricePrecision,
  prodTypes,
  productAddAttributeValue,
  productGetByHashPublic,
  self,
  servedFlowEnabled,
  setIsFavoriteCheck,
  showCustomColumns,
  showPORProductModal,
  showSearchProductModal,
  tableVisibilityConfigGet,
  touchImage,
  weAreSeller,
  weAreBuyer,
  workspaceSelected,
}) => {
  const [sortItemsFilter, setSortItemsFilter] = React.useState<SortItemFilter>(catalog?.sortItemsBy);
  const [sortedItems, setSortedItems] = React.useState<Array<IOrderItem>>(getItemsSorted());
  const [sortOptions, setSortOptions] = React.useState<Array<{ key: string; value: string }>>(getSortOptions());
  const [priceEdit, setPriceEdit] = React.useState<boolean>(false);
  const [showSelectWorkspaceModal, setShowSelectWorkspaceModal] = React.useState<boolean>(false);
  const [newOrExistingLot, setNewOrExistingLot] = React.useState<string>();
  const [productList, setProductList] = React.useState<Array<IProduct>>([]);
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  React.useEffect(() => {
    if (workspaceSelected?.id)
      tableVisibilityConfigGet(me.id, workspaceSelected.id, weAreSeller ? 'order_detail' : 'order_detail_buyer');
  }, []);

  React.useEffect(() => {
    setSortOptions(getSortOptions());
  }, [items]);

  React.useEffect(() => {
    setSortedItems(getItemsSorted());
  }, [items, sortItemsFilter]);

  React.useEffect(() => {
    if (workspaceSelected?.id) {
      tableVisibilityConfigGet(me.id, workspaceSelected.id, weAreSeller ? 'order_detail' : 'order_detail_buyer');
    }
  }, [workspaceSelected, weAreSeller]);

  React.useEffect(() => {
    if (order && order.id > 0) {
      cartSet(
        productService.getKey(weAreSeller ? me.id : order.sellerId, weAreBuyer ? me.id : order.buyerId),
        order,
        contactId,
      );
    }
  }, [order]);

  React.useEffect(() => {
    const productHashIds = items.map(item => item.productHashId);
    const productList: Array<IProduct> = [];
    if (
      config.TOGGLE_BOXES_PER_PALLET.enabled &&
      catalog?.mandatoryPalletizationByClient &&
      order.status === ORDER_STATUS.PENDING &&
      order.id === 0
    ) {
      productHashIds.map((hashId: string) => {
        productGetByHashPublic(hashId, (product: IProduct) => {
          productList.push(product);
          const boxesPerPalletValues = product.boxesPerPalletValues;
          const defaultValue = boxesPerPalletValues?.find(b => b.isDefault)?.value;
          const clientValue = (boxesPerPalletValues?.find(item => item.clientId === order.buyerId) || {}).value;
          const item = items.find(item => item.productHashId === hashId);
          if (clientValue || defaultValue || boxesPerPalletValues?.length > 0) {
            cartUpdateItem({
              ...item,
              boxesPerPallet: location.search.includes('isRepeatedOrder')
                ? item.boxesPerPallet
                : clientValue || defaultValue || boxesPerPalletValues[0]?.value,
            });
          }
        });
      });
    }
    setProductList(productList);
  }, [order.id, order.items.length, items.length]);

  const isQuoterMode =
    (config.TOGGLE_MARGINS.enabled && weAreSeller && catalog?.plan?.addons?.quoterMarginsEnabled) || false;
  const shipping = (order.customItems || []).find(ci => ci.type === 'shipping');
  const shippingCost = (shipping && shipping.price) || 0;
  const itemWithOutPrice = items.find(i => !i.totalPrice);
  const price = orderService.getTotalPrice({ ...order, items, price: itemWithOutPrice ? 0 : order.price });

  const totalPrice = config.TOGGLE_ORDER_PRICE_UNITS.enabled
    ? currency.getPrice(
        order.currency,
        orderService.getTotalPrice({ ...order, items, price: itemWithOutPrice ? 0 : order.price }),
        pricePrecision,
      )
    : price
    ? currency.getPrice(order.currency, price, pricePrecision)
    : '-';

  const totalMargin = price && isQuoterMode ? orderService.getTotalMargin(order, pricePrecision) : undefined;
  const canEdit = amEditor && orderEditable && contactId && !inactiveCatalog;
  const myRole = catalog?.members.find(m => m.userId === me.id)?.role || 'viewer';
  const amWorkspaceEditor = ['admin', 'editor'].includes(myRole);
  const columns = getColumns(isQuoterMode, togglePriceMismatchEnable).filter(c => c);
  return (
    <>
      <S.SectionTitle>
        {__('Components.Cart.items.title')} ({items.length})
        {canEdit ? (
          <S.Row>
            <S.ActionLink
              iconName="Add-more"
              iconSize="18px"
              id="cta-add-button"
              onClick={navigateToShowroom}
              type="link"
              withoutPadding={true}
            >
              {__('Components.Cart.add')}
            </S.ActionLink>
            {canServeOrder && !editAfterAccept ? (
              <>
                <S.VerticalDivider />
                <S.ActionLink
                  iconName="Edit"
                  iconSize="15px"
                  id="cta-modify-button"
                  onClick={onModifyAfterAccept}
                  type="link"
                  withoutPadding={true}
                >
                  {__('Components.Cart.modify_quantities')}
                </S.ActionLink>
              </>
            ) : null}
          </S.Row>
        ) : null}
      </S.SectionTitle>
      <S.CardItem>
        <S.TableContainer>
          {hideTotal || isPrepare ? null : (
            <S.TotalPriceRow>{renderTitlePrice(totalPrice, totalMargin)}</S.TotalPriceRow>
          )}
          <S.TableHeadWrapper>
            <S.LeftRow>
              <S.SortByDropdown
                options={sortOptions}
                hAlign="left"
                onSelect={v => {
                  if (v === 'alphabetic') setSortItemsFilter('alphabetic');
                  if (v === 'added') setSortItemsFilter('added');
                  if (v === 'sku') setSortItemsFilter('sku');
                  EventTrack.track('order_items_sort', { order_id: order.id, sort: v });
                }}
              >
                <S.SortButton type="skip" iconName="Sort" iconSize="15px">
                  <S.SortBlackText>{__('Components.OrderDetails.sort_products.title')}: </S.SortBlackText>
                  <S.SortGreyText>{getSelectedSortLabel()}</S.SortGreyText>
                  <FontIcon name="Down" />
                </S.SortButton>
              </S.SortByDropdown>
              <Tooltip
                position="right"
                onLinkClick={(index: number) =>
                  !amWorkspaceEditor && index === 1
                    ? history.push(
                        getPath({
                          path: weAreSeller
                            ? ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER
                            : ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER,
                          workspaceId: catalog?.id + '',
                        }),
                      )
                    : history.push(
                        getPath({
                          path: weAreSeller
                            ? ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER
                            : ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER,
                          workspaceId: catalog?.id + '',
                          tab: weAreSeller ? 'order' : 'pdf',
                        }),
                      )
                }
                text={
                  amWorkspaceEditor
                    ? __('Components.OrderDetails.tooltip.edit_text')
                    : __('Components.OrderDetails.tooltip.read_text')
                }
                width="300px"
                themeMode="dark"
              >
                {previewMode ? null : <S.InfoIcon disableHover={true} name="Info" />}
              </Tooltip>
              {amEditor &&
              orderEditable &&
              order.sellerId !== me.id &&
              !weAreSeller &&
              !anyPriceEmpty &&
              ((!order.changedLogistics && !order.hasChanges && !order.changedItems) || !order.id) &&
              !self ? (
                <S.FavoriteContainer>
                  <S.Text>{__('Components.OrderDetails.favorite_confirm')}</S.Text>
                  <Switch
                    onChange={(_, checked: boolean) => {
                      setIsFavoriteCheck(checked);
                    }}
                    isChecked={isFavoriteCheck}
                    selectable={false}
                    containerMargin="0 0 0 20px"
                  />
                </S.FavoriteContainer>
              ) : null}
            </S.LeftRow>
          </S.TableHeadWrapper>
          <Table
            className="items-table"
            values={sortedItems}
            onClickRow={(el: IOrderItem) => {
              const clickable = issues.filter(
                issue => issue.orderItemId === el.id && issue.type !== 'no-pricegroup-matching',
              );
              return clickable.length > 0 ? null : navigateToProductInfo(el);
            }}
            emptyText={''}
            columns={columns}
            selectable={false}
            fixedColumns={[
              'product',
              'quantity',
              'price',
              'unit-price',
              'served-quantity',
              'ordered-quantity',
              'status',
              'margin',
              'cost',
            ]}
            showCustomColumns={showCustomColumns && amEditor && !previewMode}
            configId={weAreSeller ? 'order_detail' : 'order_detail_buyer'}
            productColumns={weAreSeller ? orderDetailColumns(isQuoterMode) : orderDetailBuyerColumns(isQuoterMode)}
          />
          {shippingCost > 0 ? (
            <S.FooterRow>
              <S.TotalSpan>
                <S.PriceText>{__('Components.OrderDetails.transport.transport_cost')}</S.PriceText>
              </S.TotalSpan>
              <S.PriceSpan>
                <S.ResumePrice>{currency.getPrice(order.currency, shippingCost)}</S.ResumePrice>
              </S.PriceSpan>
            </S.FooterRow>
          ) : null}
        </S.TableContainer>

        {hideTotal || isPrepare ? null : <S.PriceFooter>{renderTitlePrice(totalPrice, totalMargin)}</S.PriceFooter>}
      </S.CardItem>
    </>
  );
  /**
   * Render title price for the table
   */
  function renderTitlePrice(totalPrice: string, totalMargin: { margin: string; marginPercentage: string }) {
    if (priceMode !== 'none' && order.price) {
      const hasPendingIssues = !!order.issues.find(i => i.status === 'pending' || i.status === 'unknown');
      return (
        <S.PriceColumn>
          <S.PriceSpan>
            <S.PriceText>{__('Components.Cart.total')}</S.PriceText>

            <S.TotalResumePrice>{totalPrice}</S.TotalResumePrice>
          </S.PriceSpan>
          {totalMargin && totalMargin.margin !== '-' && !hasPendingIssues ? (
            <S.PriceSpan>
              <S.MarginText>
                {__('Components.Cart.items.margin') + ': ' + totalMargin?.margin}
                <S.MarginTextPercentage>{` (${totalMargin?.marginPercentage})`}</S.MarginTextPercentage>
              </S.MarginText>
            </S.PriceSpan>
          ) : null}
        </S.PriceColumn>
      );
    }
    return null;
  }

  /**
   * Get sorted options
   */
  function getSortOptions() {
    const result = [
      { key: 'added', value: __('Components.OrderDetails.sort_products.default') },
      { key: 'alphabetic', value: __('Components.OrderDetails.sort_products.alphabetical') },
    ];

    const hasSku = items.reduce((acc, item) => {
      if (item.sku) acc = true;
      return acc;
    }, false);
    if (hasSku) result.push({ key: 'sku', value: __('Components.OrderDetails.sort_products.sku') });

    return result;
  }

  /**
   * Get selected sort label
   */
  function getSelectedSortLabel() {
    switch (sortItemsFilter) {
      case 'alphabetic':
        return __('Components.OrderDetails.sort_products.alphabetical');
      case 'sku':
        return __('Components.OrderDetails.sort_products.sku');
      default:
        return __('Components.OrderDetails.sort_products.default');
    }
  }

  /**
   * Get items sorted according to filter
   */
  function getItemsSorted() {
    const itemsCopy = items.slice();
    return itemsCopy.sort(orderService.sortOrderItems(prodTypes, sortItemsFilter));
  }

  function getColumns(isQuoterMode: boolean, togglePriceMismatchEnable: boolean) {
    const configId = weAreSeller ? 'order_detail' : 'order_detail_buyer';
    const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));
    const isPrepared = !!order.items.find(i => i.isPrepared);
    const isPendingOrder = [ORDER_STATUS.PENDING, ORDER_STATUS.DRAFT].includes(order.status);
    const isItemDeletable =
      deletable && amEditor && orderEditable && (isPendingOrder || (canServeOrder && editAfterAccept));

    const result =
      isItemDeletable && order.status !== ORDER_STATUS.INCOMPLETE
        ? [
            getColumnField(
              { name: 'delete', order: -1, visible: true },
              {
                getElement: (data: IOrderItem) => {
                  return (
                    <>
                      {data.servedQuantity ||
                      (!data.amount &&
                        (order.origin === ORDER_ORIGIN.IMPORT_UI || order.origin === ORDER_ORIGIN.EMAIL)) ? (
                        <>
                          {deletable ? (
                            <S.IconWrapper>
                              <S.RemoveIcon
                                name="Close"
                                onClick={e => {
                                  e.stopPropagation();
                                  cartUpdateItem({
                                    ...data,
                                    servedQuantity: 0,
                                    ...(showServedColumn ? {} : { amount: 0 }),
                                  });
                                }}
                              />
                            </S.IconWrapper>
                          ) : null}
                        </>
                      ) : (
                        <S.CloseTd colSpan={2}>
                          <S.DeleteContainer>
                            <S.Deleted>{__('Components.ProductDetails.deleted')}</S.Deleted>
                            {amEditor && weAreSeller && editAfterAccept ? (
                              <S.Undo onClick={() => cartUpdateItem({ ...data, servedQuantity: data.amount })}>
                                {__('Components.ProductDetails.undo')}
                              </S.Undo>
                            ) : null}
                          </S.DeleteContainer>
                        </S.CloseTd>
                      )}
                    </>
                  );
                },
              },
            ),
          ]
        : [];

    result.push(
      getColumnField(
        { name: 'product', order: 0, visible: true },
        {
          getElement: (data: IOrderItem) => {
            const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
            const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');
            const typeVariety = productService.getProductTypeVarietyDisplay(
              data.type,
              prodTypes[data.type] ? prodTypes[data.type].name : '',
              data.title,
            );
            const notFoundIssue = issuesRelated?.find(i =>
              ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
            );
            const unavailableIssue = issuesRelated?.find(i => 'product-unavailable' === i.type);

            const productIssues = issues.find(i =>
              [
                'no-pieces-per-box',
                'no-boxes-per-pallet',
                'no-box-weight',
                'multi-boxes-per-pallet-without-buyer',
              ].includes(i.type),
            );
            const mbppIssues = issues.filter(err => err.type === 'multi-boxes-per-pallet-without-buyer');
            if (notFoundIssue && !data.alwaysIgnored) {
              return (
                <S.ProductErrorRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <ColumnContainer>
                    <S.Text showGrey={order.status === ORDER_STATUS.CANCELED}>
                      <>
                        {utils.formatText(
                          notFoundIssue?.type === 'many-internal-codes-for-external'
                            ? __('Components.ProductDetails.product_mapping_duplicated', {
                                name: data.title,
                                code: notFoundIssue?.code,
                              })
                            : __('Components.ProductDetails.product_missing', {
                                name: data.title,
                                code: notFoundIssue?.code,
                              }),
                          (s, index) => (
                            <S.TextBold showGrey={order.status === ORDER_STATUS.CANCELED} key={s + index}>
                              {s}
                            </S.TextBold>
                          ),
                        )}
                      </>
                    </S.Text>
                    {amEditor && weAreSeller && order.status !== ORDER_STATUS.CANCELED ? (
                      <S.Text>
                        {__('Components.ProductDetails.product_missing_actions.0') + ' '}
                        <S.TextLink
                          onClick={e => {
                            showProductsModal(data, e, undefined, notFoundIssue.code);
                          }}
                        >
                          {__('Components.ProductDetails.product_missing_actions.1')}
                        </S.TextLink>
                        {' ' + __('Components.ProductDetails.product_missing_actions.2') + ' '}
                        <S.TextLink onClick={e => discardLine(e, data)}>
                          {__('Components.ProductDetails.product_missing_actions.3')}
                        </S.TextLink>
                        .
                      </S.Text>
                    ) : null}
                  </ColumnContainer>
                </S.ProductErrorRow>
              );
            }
            if (unavailableIssue) {
              return (
                <S.ProductErrorRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <ColumnContainer>
                    <S.ItemTitle
                      showGrey={!!hasPendingIssue || !data.servedQuantity || order.status === ORDER_STATUS.CANCELED}
                    >
                      {utils.firstToUpperCase(`${data.productTitle || typeVariety + ' ' + data.size}`)}
                    </S.ItemTitle>
                    <TagBubble
                      color="error"
                      label={__('Components.ProductCard.out_of_stock')}
                      disabled={true}
                      showIcon={true}
                    />
                    {amEditor && weAreSeller && order.status !== ORDER_STATUS.CANCELED ? (
                      <S.Text>
                        {__('Components.ProductDetails.product_unavailable_actions.0') + ' '}
                        <S.TextLink
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            onEnableItem(data, unavailableIssue);
                          }}
                        >
                          {__('Components.ProductDetails.product_unavailable_actions.1')}
                        </S.TextLink>
                        {' ' + __('Components.ProductDetails.product_unavailable_actions.2') + ' '}
                        <S.TextLink onClick={e => discardLine(e, data)}>
                          {__('Components.ProductDetails.product_unavailable_actions.3')}
                        </S.TextLink>
                        .
                      </S.Text>
                    ) : null}
                  </ColumnContainer>
                </S.ProductErrorRow>
              );
            }

            const itemHasIssue = issuesRelated?.find(i => i.orderItemId === data.id);
            let completeInfo;
            switch (itemHasIssue?.type) {
              case 'no-pieces-per-box':
                completeInfo = __('Components.ProductDetails.complete_info_pieces_box');
                break;
              case 'no-boxes-per-pallet':
              case 'multi-boxes-per-pallet-without-buyer':
                completeInfo = __('Components.ProductDetails.complete_info_boxes_pallet');
                break;
              case 'no-box-weight':
                completeInfo = __('Components.ProductDetails.complete_info_box_weight');
                break;
              default:
                completeInfo = __('Components.ProductDetails.complete_info_default');
            }
            if (productIssues && itemHasIssue && config.TOGGLE_ORDER_ITEM_ISSUE.enabled) {
              return (
                <S.ProductErrorRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <ColumnContainer>
                    <S.ItemTitle showGrey={order.status === ORDER_STATUS.CANCELED}>
                      {utils.firstToUpperCase(`${data.productTitle || typeVariety + ' ' + data.size}`)}
                    </S.ItemTitle>

                    {amEditor && weAreSeller && order.status !== ORDER_STATUS.CANCELED ? (
                      <S.ProductErrorRow>
                        <S.PencilIcon disableHover={true} name="Edit" />
                        <S.TextLink
                          onClick={e => {
                            e.stopPropagation();
                            openIssuesModal(data);
                          }}
                        >
                          {' '}
                          {completeInfo}
                        </S.TextLink>
                      </S.ProductErrorRow>
                    ) : null}
                  </ColumnContainer>
                </S.ProductErrorRow>
              );
            }
            if (
              mbppIssues?.length > 0 &&
              itemHasIssue &&
              !config.TOGGLE_ORDER_ITEM_ISSUE.enabled &&
              config.TOGGLE_BOXES_PER_PALLET.enabled &&
              catalog?.mandatoryPalletizationByClient
            ) {
              return (
                <S.ProductErrorRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <ColumnContainer>
                    <S.ItemTitle showGrey={order.status === ORDER_STATUS.CANCELED}>
                      {utils.firstToUpperCase(`${data.productTitle || typeVariety + ' ' + data.size}`)}
                    </S.ItemTitle>

                    {amEditor && weAreSeller && order.status !== ORDER_STATUS.CANCELED ? (
                      <S.ProductErrorRow>
                        <S.PencilIcon disableHover={true} name="Edit" />
                        <S.TextLink
                          onClick={e => {
                            e.stopPropagation();
                            openIssuesModal(data);
                          }}
                        >
                          {' '}
                          {completeInfo}
                        </S.TextLink>
                      </S.ProductErrorRow>
                    ) : null}
                  </ColumnContainer>
                </S.ProductErrorRow>
              );
            }
            return (data.servedQuantity > 0 && !data.alwaysIgnored) ||
              (!data.amount &&
                (order.origin === ORDER_ORIGIN.IMPORT_UI || order.origin === ORDER_ORIGIN.EMAIL) &&
                !data.alwaysIgnored) ? (
              <ContactCell
                status={order.status}
                avatar={(
                  (data.images && data.images.length && data.images[0].image.url) ||
                  (prodTypes[data.type] && prodTypes[data.type].defaultImageUrl) ||
                  IMAGE_DEFAULT
                ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
                imgText={'image text'}
                primaryText={data.productTitle || typeVariety + ' ' + data.size}
                showGrey={order.status === ORDER_STATUS.CANCELED}
              />
            ) : (
              <S.CloseTd colSpan={2}>
                {isItemDeletable && order.status !== ORDER_STATUS.INCOMPLETE ? (
                  <S.TextGrey>{data.productTitle || typeVariety + ' ' + data.size}</S.TextGrey>
                ) : config.TOGGLE_DISCARD_MO.enabled ? (
                  <S.DiscardWrapper>
                    <S.Deleted>
                      {data.alwaysIgnored
                        ? __('Components.ProductDetails.discarded_always')
                        : __('Components.ProductDetails.discarded')}
                    </S.Deleted>
                    <S.DiscardContainer>
                      <S.GreyText>{data.productTitle || typeVariety + ' ' + data.size}</S.GreyText>
                      {order.status === ORDER_STATUS.ACCEPTED ? (
                        data.alwaysIgnored ? (
                          <S.TextLink
                            onClick={e => {
                              e.stopPropagation();
                              modalOpen(
                                __('Components.ProductDetails.recover_modal.title'),
                                () => {
                                  orderItemStopDiscarding(catalog.id, me.id, data.eanCode);
                                  // We call cartUpdateItem to refresh the item in the cart
                                  cartUpdateItem({
                                    ...data,
                                    alwaysIgnored: false,
                                  });
                                },

                                {
                                  text2: __('Components.ProductDetails.recover_modal.text'),
                                  buttonText: __('Components.ProductDetails.recover_modal.cta'),
                                  showCancelButton: true,
                                  icon: IMAGES.informativePineapple,
                                },
                                'nice',
                              );
                            }}
                          >
                            {__('Components.ProductDetails.stop_discarding')}
                          </S.TextLink>
                        ) : null
                      ) : (
                        <S.TextLink
                          onClick={e => {
                            e.stopPropagation();
                            orderItemRecover(catalog.id, me.id, order.id, data.id, o => {
                              data.alwaysIgnored = false;
                              const orderIdx = o.items.findIndex(i => i.id === data.id);
                              order.items[orderIdx] = { ...order.items[orderIdx], alwaysIgnored: false };
                            });
                          }}
                        >
                          {__('Components.ProductDetails.recover')}
                        </S.TextLink>
                      )}
                      {amEditor && weAreSeller && editAfterAccept ? (
                        <S.Undo onClick={() => cartUpdateItem({ ...data, servedQuantity: data.amount })}>
                          {__('Components.ProductDetails.undo')}
                        </S.Undo>
                      ) : null}
                    </S.DiscardContainer>
                  </S.DiscardWrapper>
                ) : (
                  <S.DeleteContainer>
                    <S.Deleted>{__('Components.ProductDetails.deleted')}</S.Deleted>
                    <S.TextGrey>{data.productTitle || typeVariety + ' ' + data.size}</S.TextGrey>
                    {amEditor && weAreSeller && editAfterAccept ? (
                      <S.Undo onClick={() => cartUpdateItem({ ...data, servedQuantity: data.amount })}>
                        {__('Components.ProductDetails.undo')}
                      </S.Undo>
                    ) : null}
                  </S.DeleteContainer>
                )}
              </S.CloseTd>
            );
          },
        },
      ),
    );

    const orderDetailColumnConfig = columnConfig?.find(c => c.view === configId);
    const configCol = orderDetailColumnConfig
      ? (orderDetailColumnConfig?.columns as Array<IColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig(configId);
    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'weight':
            if (isPrepared) {
              result.push(
                getColumnField(column, {
                  getElement: (data: IOrderItem) => {
                    const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                    const hasNotFoundIssue = !!issuesRelated?.find(i =>
                      ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                    );
                    const isDiscarded = data.servedQuantity === 0;
                    return (
                      <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                        {renderWeight(data)}
                      </S.Td>
                    );
                  },
                }),
              );
            }
            break;

          case 'category':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.category}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'variety':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.variety}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'units-per-box':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.piecesPerBox}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'box-weight':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.boxWeight ? `${data.boxWeight} ${data.weightUnit}` : ''}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'boxes-per-pallet':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  const boxesPerPalletValues = productList.find(p => p.id === data.childId)?.boxesPerPalletValues;
                  const defaultValue = boxesPerPalletValues?.find(b => b.isDefault)?.value;
                  const clientValues = boxesPerPalletValues?.filter(b => b.clientId === order.buyerId);
                  const defaultClientValue = clientValues?.find(b => b.isDefault)?.value;
                  const getBoxesPerPallet = (): number => {
                    if (data.boxesPerPallet) {
                      return data.boxesPerPallet;
                    } else if (defaultClientValue) {
                      return defaultClientValue;
                    } else if (clientValues?.length > 0) {
                      return clientValues[0].value;
                    } else if (defaultValue) {
                      return defaultValue;
                    } else if (boxesPerPalletValues && boxesPerPalletValues.length > 0) {
                      return boxesPerPalletValues[0].value;
                    } else {
                      return null;
                    }
                  };
                  const valuesArray = boxesPerPalletValues
                    ?.filter(b => b?.value !== getBoxesPerPallet())
                    .map(b => ({
                      key: b?.id?.toString(),
                      value: b?.value?.toString() + ' pp',
                      color: 'black',

                      action: () => {
                        cartUpdateItem({ ...data, boxesPerPallet: b.value });
                        openBppConfirmationModal(data, b.value);
                      },
                    }));
                  const addOption = {
                    key: 'add-option',
                    value: __('Components.Cart.boxesPerPallet.add_option'),
                    color: '#4a90e2',
                    action: () => {
                      openBoxesPerPalletModal(data);
                    },
                  };
                  valuesArray?.push(addOption as any);
                  return (
                    <>
                      {config.TOGGLE_BOXES_PER_PALLET.enabled &&
                      catalog?.mandatoryPalletizationByClient &&
                      amSeller &&
                      order.status === ORDER_STATUS.PENDING ? (
                        <S.ArrowContainer className="arrow-options" onClick={(e: any) => e.stopPropagation()}>
                          <S.AmountBlack
                            isClickable={!getBoxesPerPallet()}
                            showGrey={isDiscarded || hasNotFoundIssue}
                            onClick={() => {
                              openBoxesPerPalletModal(data);
                            }}
                          >
                            {getBoxesPerPallet()
                              ? getBoxesPerPallet() + ' pp'
                              : __('Components.Cart.boxesPerPallet.add_option')}
                          </S.AmountBlack>
                          {getBoxesPerPallet() ? (
                            <SimpleDropdown
                              options={(valuesArray as any) || []}
                              hAlign="left"
                              onSelect={(e: any) => {
                                e.stopPropagation();
                              }}
                            >
                              <S.Arrow name="Down-solid" />
                            </SimpleDropdown>
                          ) : null}
                        </S.ArrowContainer>
                      ) : (
                        <S.ArrowContainer className="arrow-options" onClick={(e: any) => e.stopPropagation()}>
                          <S.Amount
                            showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}
                          >
                            {data.boxesPerPallet ? data.boxesPerPallet + ' pp' : null}
                          </S.Amount>
                        </S.ArrowContainer>
                      )}
                    </>
                  );
                },
              }),
            );
            break;
          case 'contracts':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const displayedRefs = data.contracts?.length > 2 ? 1 : 2;
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.ReferenceCol>
                      {data.contracts?.slice(0, displayedRefs).map(c => (
                        <>
                          <S.Reference
                            showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                          >
                            {c.reference}
                          </S.Reference>
                        </>
                      ))}
                      {data.contracts?.length > 2 ? (
                        <Tooltip
                          component={
                            <S.ReferenceCol>
                              {data.contracts?.slice(1).map(c => (
                                <>
                                  <S.Reference
                                    showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                                  >
                                    {'· ' + c.reference}
                                  </S.Reference>
                                </>
                              ))}
                            </S.ReferenceCol>
                          }
                          themeMode="dark"
                        >
                          <S.BlueText>
                            {__('WorkspaceSupplierEdit.Menu.contracts.see_more', {
                              count: data.contracts?.length - 1,
                            })}
                          </S.BlueText>
                        </Tooltip>
                      ) : null}
                    </S.ReferenceCol>
                  );
                },
              }),
            );
            break;
          case 'size':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.size}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'region':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.region}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'brand':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.brand}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'origin':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.origin}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'ean':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {data.eanCode}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'images':
            result.push(
              getColumnField(column, {
                getDataCell: (data: IOrderItem) => {
                  const emptyImages: Array<ISizeImage> = [];
                  const images = data.images || emptyImages;
                  return {
                    type: TYPE_CELL.IMAGE,
                    images: images.filter(i => i.image.url).map(img => img.image.url),
                    onImageClick: index =>
                      touchImage(
                        images.map(img => ({ src: img.image.url, caption: img.image.comment })),
                        index,
                      ),
                    count: images.filter(i => i.image.url).length,
                  };
                },
              }),
            );
            break;
          case 'warehouse':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = data.servedQuantity === 0;
                  const address =
                    data.warehouseId && addresses ? addresses.find(a => a.id === data.warehouseId) : undefined;
                  return (
                    <S.Td showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}>
                      {address ? addressUtils.getAddressName(address, countries) : ''}
                    </S.Td>
                  );
                },
              }),
            );
            break;
          case 'load':
            result.push(
              getColumnField(column, {
                getElement: (item: IOrderItem) => {
                  const issuesRelated = issues.filter(issue => issue.orderItemId === item.id);
                  const hasNotFoundIssue = !!issuesRelated?.find(i =>
                    ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
                  );
                  const isDiscarded = item.servedQuantity === 0;
                  return (
                    <S.SubText showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                      {item
                        ? config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                          ? orderService.getCartLoadSummaryAltUnits(order.items)
                          : orderService.getCartLoadSummaryText(order.items)
                        : ''}
                    </S.SubText>
                  );
                },
              }),
            );
            break;
          case 'actions':
            if (
              !weAreSeller &&
              order.status === ORDER_STATUS.ACCEPTED &&
              !previewMode &&
              config.TOGGLE_PRODUCT_LOT.enabled
            ) {
              result.push(
                getColumnField(column, {
                  getElement: (data: IOrderItem) => {
                    return (
                      <S.SubtotalTd>
                        {!weAreSeller && order.status === ORDER_STATUS.ACCEPTED && config.TOGGLE_PRODUCT_LOT.enabled ? (
                          <SimpleDropdown
                            hAlign="right"
                            onSelect={(name: string) => handleLotOptions(name, data)}
                            options={[
                              { key: 'new', value: __('Components.ProductDetails.lot.assign_new') },
                              { key: 'existing', value: __('Components.ProductDetails.lot.assign_existing') },
                            ]}
                          >
                            <S.KebabIcon name="Kebab" />
                          </SimpleDropdown>
                        ) : null}
                        {showSelectWorkspaceModal ? renderSelectWorkspaceModal(data) : null}
                      </S.SubtotalTd>
                    );
                  },
                }),
              );
            }
            break;
          default:
            result.push(getColumnField(column));
            break;
        }
      });
    if (showServedColumn) {
      result.push(
        getColumnField(
          { name: 'ordered-quantity', order: 15, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');
              const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
              const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');

              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );

              const isDiscarded = data.servedQuantity === 0;
              return (
                <>
                  <S.AmountRow onClick={e => e.stopPropagation()}>
                    <S.QuantityRow>
                      {invalidAmountIssue ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
                      {(editable && !editAfterAccept) ||
                      (!data.amount &&
                        (order.origin === ORDER_ORIGIN.IMPORT_UI || order.origin === ORDER_ORIGIN.EMAIL)) ? (
                        <>
                          <S.AmountInput
                            id={`amount-${data.id}`}
                            name="amount"
                            onBlur={(key: string, value: string, error: string) => {
                              const v = Number(value);
                              cartUpdateItem(
                                { ...data, amount: v, servedQuantity: v },
                                invalidAmountIssue?.type === 'amount-not-valid' ? invalidAmountIssue : undefined,
                              );
                            }}
                            value={data.servedQuantity || 0}
                            minValue={0}
                            precision={data.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                            width="80px"
                            type="number"
                            textAlign="right"
                            autoFocus={false}
                            hasError={!data.servedQuantity}
                            containerMargin="0 6px 0 0"
                            isIssue={!!invalidAmountIssue}
                          />
                          <Select
                            containerMargin="0"
                            value={data.servedSaleUnit}
                            name={`served-sale-unit-${data.id}`}
                            options={data.saleUnits.map(s => ({
                              value: s,
                              label: parsers.getUnitText(
                                config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                                  ? priceUnitTranslate(s)
                                  : s,
                                data.weightUnit,
                                0,
                              ),
                            }))}
                            onChange={(n, v) => {
                              cartUpdateItem({
                                ...data,
                                saleUnit: v as PRODUCT_UNIT,
                                servedSaleUnit: v as PRODUCT_UNIT,
                              });
                            }}
                            width="100px"
                          />
                        </>
                      ) : (
                        <>
                          <S.Amount
                            showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                          >
                            {data.amount}
                          </S.Amount>
                          <S.Amount
                            showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                          >
                            {' '}
                            {parsers.getUnitText(
                              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                                ? priceUnitTranslate(data.saleUnit)
                                : data.saleUnit,
                              data.weightUnit,
                              data.amount,
                            )}
                          </S.Amount>
                        </>
                      )}
                    </S.QuantityRow>
                  </S.AmountRow>
                </>
              );
            },
          },
        ),
      );
      result.push(
        getColumnField(
          { name: 'served-quantity', order: 16, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );
              const isDiscarded = data.servedQuantity === 0;
              return (
                <S.Td showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                  {renderServedQuantity(data, isPendingOrder)}
                </S.Td>
              );
            },
          },
        ),
      );
    } else {
      result.push(
        getColumnField(
          { name: 'quantity', order: 17, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');
              const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');

              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );
              const isDiscarded = data.servedQuantity === 0;
              return (
                <S.AmountRow onClick={e => e.stopPropagation()}>
                  <S.QuantityRow>
                    {invalidAmountIssue ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
                    {(editable && !editAfterAccept) ||
                    (!data.amount &&
                      (order.origin === ORDER_ORIGIN.IMPORT_UI || order.origin === ORDER_ORIGIN.EMAIL)) ? (
                      <>
                        <S.AmountInput
                          id={`amount-${data.id}`}
                          name="amount"
                          onBlur={(key: string, value: string, error: string) => {
                            const v = Number(value);
                            cartUpdateItem(
                              { ...data, amount: v, servedQuantity: v },
                              invalidAmountIssue?.type === 'amount-not-valid' ? invalidAmountIssue : undefined,
                            );
                          }}
                          value={data.servedQuantity || 0}
                          minValue={0}
                          precision={data.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                          width="70px"
                          type="number"
                          textAlign="right"
                          autoFocus={false}
                          hasError={!data.servedQuantity}
                          containerMargin="0 6px 0 0"
                          isIssue={!!invalidAmountIssue}
                        />
                        <Select
                          containerMargin="0"
                          value={data.servedSaleUnit}
                          name={`served-sale-unit-${data.id}`}
                          options={data.saleUnits?.map(s => ({
                            value: s,
                            label: parsers.getUnitText(
                              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                                ? priceUnitTranslate(s)
                                : s,
                              data.weightUnit,
                              0,
                            ),
                          }))}
                          onChange={(n, v) => {
                            cartUpdateItem({
                              ...data,
                              saleUnit: v as PRODUCT_UNIT,
                              servedSaleUnit: v as PRODUCT_UNIT,
                            });
                          }}
                          width="100px"
                        />
                      </>
                    ) : (
                      <>
                        <S.Amount showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                          {data.amount}
                        </S.Amount>
                        <S.Amount showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                          {' '}
                          {parsers.getUnitText(
                            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                              ? priceUnitTranslate(data.saleUnit)
                              : data.saleUnit,
                            data.weightUnit,
                            data.amount,
                          )}
                        </S.Amount>
                      </>
                    )}
                  </S.QuantityRow>
                </S.AmountRow>
              );
            },
          },
        ),
      );
    }
    if (isQuoterMode) {
      result.push(
        getColumnField(
          { name: 'cost', order: 18, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const { totalCost } = data;
              const totalItemCost = orderService.getTotalItemCost(data);
              return (
                <S.ProductPriceRow>
                  {totalCost ? (
                    <S.TooltipContainer>
                      <Tooltip position="top" text={__('Components.Cart.tooltip_cost')} themeMode="dark" width="200px">
                        <S.InfoIconBlack disableHover={true} name="Info" />
                      </Tooltip>
                    </S.TooltipContainer>
                  ) : null}
                  <S.PriceColumn>
                    <S.ItemPrice>
                      {totalCost
                        ? currency.getPricePerUnit(
                            data.currency,
                            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                              ? priceUnitTranslate(data.priceUnit)
                              : data.priceUnit,
                            data.weightUnit,
                            totalCost,
                            pricePrecision,
                          )
                        : '-'}
                    </S.ItemPrice>
                    {totalItemCost ? (
                      <S.ItemPrice>{currency.getPrice(data.currency, totalItemCost, pricePrecision)}</S.ItemPrice>
                    ) : null}
                  </S.PriceColumn>
                </S.ProductPriceRow>
              );
            },
          },
        ),
      );
    }
    if (!isPrepare) {
      result.push(
        getColumnField(
          { name: 'unit-price', order: 19, visible: true },
          {
            getElement: (data: IOrderItem, index: number) => {
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );
              const isDiscarded = data.servedQuantity === 0;
              if (issuesRelated?.find(i => i.type === 'product-no-price')) {
                const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');
                return (
                  <S.ProductPriceRow>
                    <S.WarningIcon name="Warning" disableHover={true} />
                    <ColumnContainer>
                      <S.Text showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                        {__('Components.ProductDetails.price_missing')}
                      </S.Text>
                      {amEditor && weAreSeller && order.status !== ORDER_STATUS.CANCELED ? (
                        <S.Text showGrey={hasNotFoundIssue || isDiscarded}>
                          {__('Components.ProductDetails.price_missing_actions.0') + ' '}
                          <S.TextLink onClick={e => showPORModal(e, data)}>
                            {__('Components.ProductDetails.price_missing_actions.1')}
                          </S.TextLink>
                          {deletable ? (
                            <>
                              {' ' + __('Components.ProductDetails.price_missing_actions.2') + ' '}
                              <S.TextLink onClick={e => discardLine(e, data)}>
                                {__('Components.ProductDetails.price_missing_actions.3')}
                              </S.TextLink>
                              .
                            </>
                          ) : null}
                        </S.Text>
                      ) : null}
                    </ColumnContainer>
                  </S.ProductPriceRow>
                );
              }
              return renderPrice(data, index, isPendingOrder, togglePriceMismatchEnable);
            },
          },
          isQuoterMode,
        ),
      );
    }

    if (isQuoterMode) {
      result.push(
        getColumnField(
          { name: 'margin', order: 21, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const { margin, marginPercentage, totalItemMargin } = orderService.getProductItemMargin(data);
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );
              const isDiscarded = data.servedQuantity === 0;
              return (
                <S.PriceColumn>
                  <S.ItemPrice showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                    {margin
                      ? currency.getPricePerUnit(
                          data.currency,
                          config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                            ? priceUnitTranslate(data.priceUnit)
                            : data.priceUnit,
                          data.weightUnit,
                          margin,
                          pricePrecision,
                        )
                      : '-'}
                  </S.ItemPrice>
                  {totalItemMargin ? (
                    <S.ItemPrice showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
                      {currency.getPrice(data.currency, totalItemMargin, pricePrecision) + ' '}
                      <S.MarginPercentage
                        baseMarginPercentage={data.baseMarginPercentage}
                        marginPercentage={marginPercentage}
                      >{`(${marginPercentage.toFixed(2)}%)`}</S.MarginPercentage>
                    </S.ItemPrice>
                  ) : null}
                </S.PriceColumn>
              );
            },
          },
        ),
      );
    }

    if (!isPrepare) {
      result.push(
        getColumnField(
          { name: 'price', order: 20, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
              const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');
              const price = orderService.getTotalPriceItem(data);
              const boxesPerPallet = data.boxesPerPallet;

              const hasNotFoundIssue = !!issuesRelated?.find(i =>
                ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
              );
              const isDiscarded = data.servedQuantity === 0;
              return (
                <S.ItemPriceTotal
                  isCancelled={order.status === ORDER_STATUS.CANCELED}
                  showGrey={isDiscarded || hasNotFoundIssue || order.status === ORDER_STATUS.CANCELED}
                >
                  {config.TOGGLE_ORDER_PRICE_UNITS.enabled
                    ? priceMode !== 'none' && calculatePriceServedUnit(data, boxesPerPallet)
                      ? currency.getPrice(data.currency, calculatePriceServedUnit(data, boxesPerPallet), pricePrecision)
                      : '-'
                    : priceMode !== 'none' && price
                    ? currency.getPrice(data.currency, price, pricePrecision)
                    : '-'}
                </S.ItemPriceTotal>
              );
            },
          },
        ),
      );
    }

    if (isPrepare && !editAfterAccept) {
      result.push(
        getColumnField(
          { name: 'status', order: 22, visible: true },
          {
            getElement: (data: IOrderItem) => {
              return renderPrepared(data);
            },
          },
        ),
      );
    }
    return result;
  }

  /**
   * Render served quantity inputs
   */
  function renderServedQuantity(item: IOrderItem, isPendingOrder: boolean) {
    const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));

    const hasServedChange =
      showServedColumn && (item.servedQuantity !== item.amount || item.servedSaleUnit !== item.saleUnit);
    const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
    const issuesRelated = issues.filter(issue => issue.orderItemId === item.id);
    const hasNotFoundIssue = !!issuesRelated?.find(i =>
      ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
    );
    const isDiscarded = item.servedQuantity === 0;
    if (!editable || !item.servedQuantity)
      return (
        <S.AmountRow onClick={e => e.stopPropagation()}>
          <S.Amount
            showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
            hasServedChange={hasServedChange}
            servedQuantity={true}
          >
            {item.servedQuantity}{' '}
            {parsers.getUnitText(
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                ? priceUnitTranslate(item.servedSaleUnit)
                : item.servedSaleUnit,
              item.weightUnit,
              item.servedQuantity,
            )}
          </S.Amount>
        </S.AmountRow>
      );

    const saleUnits = item.saleUnits || [item.saleUnit]; // hack fallback
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.LeftRow>
          <Input
            id={`served-quantity-${item.id}`}
            name="servedQuantity"
            onBlur={(key: string, value: string, error: string) => {
              const v = Number(value);
              if (!error && !isNaN(v) && v !== item.servedQuantity) {
                let newCartItem = { ...item, servedQuantity: v };
                if (!showServedColumn) {
                  newCartItem = { ...newCartItem, amount: 0 };
                }
                cartUpdateItem(newCartItem);
              }
            }}
            value={item.servedQuantity || 0}
            minValue={0}
            precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
            width="80px"
            type="number"
            textAlign="right"
            autoFocus={false}
            hasError={!item.servedQuantity}
            disabled={!editable}
            containerMargin="0 6px 0 0"
          />
          <Select
            containerMargin="0"
            value={item.servedSaleUnit}
            name={`served-sale-unit-${item.id}`}
            options={saleUnits.map(s => ({
              value: s,
              label: parsers.getUnitText(
                config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId) ? priceUnitTranslate(s) : s,
                item.weightUnit,
                0,
              ),
            }))}
            onChange={(n, v) => {
              cartUpdateItem({
                ...item,
                servedSaleUnit: v as PRODUCT_UNIT,
              });
            }}
            width="100px"
          />
        </S.LeftRow>
      </S.AmountRow>
    );
  }

  /**
   * Render weight served
   */
  function renderWeight(item: IOrderItem) {
    const isPrepared = !!order.items.find(i => i.isPrepared);

    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        {isPrepared ? (
          item.isPrepared ? (
            <S.Row>
              <S.Amount>{item.servedWeight || 0}</S.Amount>
              <S.Amount> {item.weightUnit}</S.Amount>
            </S.Row>
          ) : (
            __('Components.Cart.items.prepare')
          )
        ) : (
          <Input
            id={`served-weight-${item.id}`}
            name="servedWeight"
            onBlur={(key: string, value: string, error: string) => {
              const v = Number(value);
              if (!error && !isNaN(v) && v !== item.servedWeight) {
                orderUpdatePrepared(me.id, item.orderId, item.id, item.catalogId, v, item.isPrepared, () => {
                  cartUpdateItem({ ...item, servedWeight: v });
                });
              }
            }}
            value={item.servedWeight || 0}
            minValue={0}
            precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
            width="80px"
            type="number"
            textAlign="right"
            autoFocus={false}
            containerMargin="0 6px 0 0"
            variableTextSingular={item.weightUnit}
            variableTextPlural={item.weightUnit}
            disabled={isPrepared}
          />
        )}
      </S.AmountRow>
    );
  }

  /**
   * Render weight served
   */
  function renderPrepared(item: IOrderItem) {
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.Option>
          <S.Check
            isChecked={item.isPrepared}
            onClick={val => {
              orderUpdatePrepared(me.id, item.orderId, item.id, item.catalogId, item.servedWeight, val, () => {
                cartUpdateItem({ ...item, isPrepared: val });
              });
            }}
            size={20}
          />
          <S.OptionText isChecked={item.isPrepared}>
            {item.isPrepared ? __('Components.Cart.items.prepared') : __('Components.Cart.items.prepare')}
          </S.OptionText>
        </S.Option>
      </S.AmountRow>
    );
  }

  /**
   * Render assign lot modal
   */
  function renderSelectWorkspaceModal(item: IOrderItem) {
    return (
      <SelectWorkspaceModal
        catalogs={Object.values(catalogs).filter(c => c.members.find(m => m.userId === me.id && m.role !== 'viewer'))}
        contacts={contacts}
        history={history}
        item={item}
        location={location}
        me={me}
        newOrExistingLot={newOrExistingLot}
        close={() => setShowSelectWorkspaceModal(false)}
        openProductSelectionModal={() => openProductSelectionModal(item, undefined)}
      />
    );
  }

  /**
   * Render price display or input
   */
  function renderPrice(item: IOrderItem, index: number, isPendingOrder: boolean, togglePriceMismatchEnable: boolean) {
    const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));

    const hasServedChange = showServedColumn && item.price !== item.servedPrice;
    const priceDisplay = priceToShow(item);
    const price = showServedColumn ? item.servedPrice : item.price;
    const issuesRelated = issues.filter(issue => issue.orderItemId === item.id);
    const invalidPriceIssue = issuesRelated?.find(i => i.type === 'price-not-valid');
    const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');
    const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');

    const hasNotFoundIssue = !!issuesRelated?.find(i =>
      ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
    );
    const isDiscarded = item.servedQuantity === 0;
    if (invalidPriceIssue) {
      return (
        <S.UnitPriceColumn>
          <S.ProductPriceRow>
            <S.WarningIcon name="Warning" disableHover={true} />
            {item.childId ? (
              <S.TablePriceInput
                id={`price-${item.id}`}
                name={showServedColumn ? 'servedPrice' : 'price'}
                onBlur={(key, v, error) => updateItemPrice(item, key, v, error)}
                value={0}
                minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                variableTextSingular={currency.getPricePerUnit(
                  item.currency,
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                    ? priceUnitTranslate(item.priceUnit)
                    : item.priceUnit,
                  item.weightUnit,
                )}
                precision={pricePrecision}
                width="120px"
                type="number"
                textAlign="right"
                autoFocus={index === 0}
                hasError={true}
                isIssue={true}
              />
            ) : (
              <S.NoDataText width="120px">{__('Components.ProductDetails.no_data')}</S.NoDataText>
            )}
          </S.ProductPriceRow>
          {item.childId ? (
            <S.Text width="120px" showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}>
              {__('order.priceUnit')}{' '}
              <S.TextLink
                onClick={() => cartUpdateItem({ ...item, isPor: false, servedPrice: item.price }, invalidPriceIssue)}
              >
                {currency.getPricePerUnit(
                  item.currency,
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                    ? priceUnitTranslate(item.priceUnit)
                    : item.priceUnit,
                  item.weightUnit,
                  item.price,
                )}
              </S.TextLink>
            </S.Text>
          ) : null}
        </S.UnitPriceColumn>
      );
    }

    const mismatchPriceIssue = issuesRelated?.find(i => i.type === 'no-pricegroup-matching');
    const pricePriceGroup = mismatchPriceIssue?.name ? Number(mismatchPriceIssue.name) : 0;
    const priceDiff = Number.isNaN(pricePriceGroup) ? 0 : item.price - pricePriceGroup;
    const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));

    // ToDo: this ñapa highlights the need of some abstract IProduct/IOrderItem properties
    // receiver for isAllowedSaleUnit, which receives a IProduct only. A possible solution can be
    // to pass a command object with 5 needed properties instead of IProduct.
    const itemToProduct: IProduct = item as unknown as IProduct;
    const availableSaleUnits = AllProductUnitsArray.filter(u =>
      isAllowedSaleUnit(itemToProduct, u, catalog?.mandatoryPalletizationByClient),
    );

    if (!editable || priceMode !== 'edit' || !item.servedQuantity) {
      return (
        <S.PriceGroupColumn>
          <S.PriceRow>
            <S.PriceGroupContainer>
              <S.PriceRow>
                {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
                <S.ItemPrice
                  showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                  isCancelled={order.status === ORDER_STATUS.CANCELED || order.status === ORDER_STATUS.INCOMPLETE}
                  hasServedChange={hasServedChange}
                >
                  {priceDisplay}
                </S.ItemPrice>
              </S.PriceRow>
              {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
                <S.TextPriceGroup
                  showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                  padding="6px 12px 0 0"
                >
                  {__('Components.ProductDetails.mismatch_price.price')}
                  <S.TextPriceMismatch
                    showGrey={hasNotFoundIssue || isDiscarded || order.status === ORDER_STATUS.CANCELED}
                  >
                    {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                      currency.getPricePerUnit(
                        item.currency,
                        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                          ? priceUnitTranslate(item.priceUnit)
                          : item.priceUnit,
                        item.weightUnit,
                      )}
                  </S.TextPriceMismatch>
                </S.TextPriceGroup>
              ) : null}
            </S.PriceGroupContainer>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
              ? renderArrowPercentage(priceDiff, 'price', item)
              : null}
          </S.PriceRow>
        </S.PriceGroupColumn>
      );
    }
    if (priceEdit || !item.price || showServedColumn) {
      return (
        <S.PriceColumn
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <S.PriceRow>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
            {config.TOGGLE_ORDER_PRICE_UNITS.enabled ? (
              <S.QuantityRow>
                <S.AmountInput
                  id={`price-${item.id}`}
                  name={showServedColumn ? 'servedPrice' : 'price'}
                  onBlur={(key, value, error) => {
                    updateItemPrice(item, key, value, error);
                  }}
                  value={price || 0}
                  minValue={0}
                  precision={2}
                  width="100px"
                  type="number"
                  textAlign="right"
                  autoFocus={false}
                  hasError={showServedColumn ? !item.servedPrice : !item.price}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  containerMargin="0 6px 0 0"
                  isIssue={!!invalidAmountIssue}
                  variableTextSingular={constants.CURRENCIES[item.currency].symbol}
                />
                <Select
                  containerMargin="0"
                  value={item.priceUnit}
                  name={`served-sale-unit-${item.id}`}
                  options={availableSaleUnits.map(s => ({
                    value: s,
                    label: parsers.getUnitText(
                      config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId) ? priceUnitTranslate(s) : s,
                      item.weightUnit,
                      0,
                    ),
                  }))}
                  onChange={(n, v) => {
                    cartUpdateItem({
                      ...item,
                      priceUnit: v as PRODUCT_UNIT,
                    });
                  }}
                  width="100px"
                />
              </S.QuantityRow>
            ) : (
              <Input
                id={`price-${item.id}`}
                name={showServedColumn ? 'servedPrice' : 'price'}
                onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
                value={price || 0}
                minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                variableTextSingular={currency.getPricePerUnit(
                  item.currency,
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                    ? priceUnitTranslate(item.priceUnit)
                    : item.priceUnit,
                  item.weightUnit,
                )}
                precision={pricePrecision}
                width="120px"
                type="number"
                textAlign="right"
                autoFocus={index === 0}
                hasError={showServedColumn ? !item.servedPrice : !item.price}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
              ? renderArrowPercentage(priceDiff, 'input', item)
              : null}
          </S.PriceRow>
          {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
            <S.TextPriceGroup padding="6px 0 0 0">
              {__('Components.ProductDetails.mismatch_price.price')}
              <S.TextLink onClick={() => updateItemPrice(item, 'servedPrice', mismatchPriceIssue.name, '')}>
                {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                  currency.getPricePerUnit(
                    item.currency,
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                      ? priceUnitTranslate(item.priceUnit)
                      : item.priceUnit,
                    item.weightUnit,
                  )}
              </S.TextLink>
            </S.TextPriceGroup>
          ) : null}
        </S.PriceColumn>
      );
    }

    return (
      <S.PriceEditRow
        enabled={priceMode === 'edit'}
        onClick={e => {
          if (priceMode === 'edit') setPriceEdit(true);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {config.TOGGLE_ORDER_PRICE_UNITS.enabled ? (
          <S.QuantityRow>
            <S.AmountInput
              id={`price-${item.id}`}
              name={showServedColumn ? 'servedPrice' : 'price'}
              onBlur={(key, value, error) => {
                updateItemPrice(item, key, value, error);
              }}
              value={price || 0}
              minValue={0}
              precision={2}
              width="100px"
              type="number"
              textAlign="right"
              autoFocus={false}
              hasError={showServedColumn ? !item.servedPrice : !item.price}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              containerMargin="0 6px 0 0"
              isIssue={!!invalidAmountIssue}
              variableTextSingular={constants.CURRENCIES[item.currency].symbol}
            />
            <Select
              containerMargin="0"
              value={item.priceUnit}
              name={`served-sale-unit-${item.id}`}
              options={availableSaleUnits.map(s => ({
                value: s,
                label: parsers.getUnitText(
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId) ? priceUnitTranslate(s) : s,
                  item.weightUnit,
                  0,
                ),
              }))}
              onChange={(n, v) => {
                cartUpdateItem({
                  ...item,
                  priceUnit: v as PRODUCT_UNIT,
                });
              }}
              width="100px"
            />
          </S.QuantityRow>
        ) : (
          <Input
            id={`price-${item.id}`}
            name={showServedColumn ? 'servedPrice' : 'price'}
            onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
            value={price || 0}
            minValue={currency.getPriceInputMinimumValue(pricePrecision)}
            variableTextSingular={currency.getPricePerUnit(
              item.currency,
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                ? priceUnitTranslate(item.priceUnit)
                : item.priceUnit,
              item.weightUnit,
            )}
            precision={pricePrecision}
            width="120px"
            type="number"
            textAlign="right"
            autoFocus={index === 0}
            hasError={showServedColumn ? !item.servedPrice : !item.price}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </S.PriceEditRow>
    );
  }
  /**
   * handle on click in import/export option
   */
  function handleLotOptions(op: string, item: IOrderItem): void {
    switch (op) {
      case 'new':
        if (Object.keys(catalogs).length > 1) {
          setShowSelectWorkspaceModal(true);
          setNewOrExistingLot('new');
        } else {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
              workspaceId: Object.values(catalogs)[0].id + '',
              productId: 'new',
            }) + `?originLot=${item.id}`,
          );
        }
        break;
      case 'existing':
        if (Object.keys(catalogs).length > 1) {
          setShowSelectWorkspaceModal(true);
          setNewOrExistingLot('existing');
        } else {
          openProductSelectionModal(item, undefined);
        }
        break;
      default:
        logError(new Error('unknow option: ' + op), 'extended.options');
        break;
    }
  }
  /**
   * Opens the modal to select products
   */
  function openProductSelectionModal(item: IOrderItem, e?: any) {
    setShowSelectWorkspaceModal(false);
    showProductsModal(item, e);
  }
  /**
   * Show search products modal
   */
  function showProductsModal(item: IOrderItem, e?: React.MouseEvent, originLot?: number, mappingCode?: string) {
    e?.stopPropagation();
    showSearchProductModal(item, originLot, mappingCode);
  }

  /**
   * Get price formated or 'P.O.R.' text.
   */
  function priceToShow(item: IOrderItem) {
    const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));
    const price = showServedColumn ? item.servedPrice : item.price;
    if (price === 0) return '-';
    if (price) {
      return currency.getPricePerUnit(
        item.currency,
        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
          ? priceUnitTranslate(item.priceUnit)
          : item.priceUnit,
        item.weightUnit,
        price,
        pricePrecision,
      );
    }
    return __('Components.ProductDetails.por_on');
  }
  /**
   * Render tooltip for mismatch price
   */
  function renderTooltip() {
    return (
      <S.TooltipContainer>
        <S.TooltipPrice
          position="bottom"
          text={__('Components.ProductDetails.mismatch_price.tooltip')}
          width="200px"
          themeMode="dark"
        >
          <S.InfoIconBlue disableHover={true} name="Info" />
        </S.TooltipPrice>
      </S.TooltipContainer>
    );
  }
  /**
   * Render arrow percentage for price mismatch
   */
  function renderArrowPercentage(priceDiff: number, from: 'input' | 'price', item: IOrderItem) {
    if (!priceDiff) return null;
    return (
      <S.ArrowPercentage
        currency={item.currency}
        isDiscount={priceDiff < 0}
        mode="currency"
        rightPosition={`-${from === 'input' ? 30 : 15}%`}
        value={priceDiff}
      />
    );
  }
  /**
   * Discard line (remove + solve issue)
   */
  function discardLine(e, data: IOrderItem) {
    e.stopPropagation();
    const issuesRelated = issues.filter(issue => issue.orderItemId === data.id);
    issuesRelated.forEach(i => orderDiscardLine({ ...i, status: 'ignored' }));
  }

  function showPORModal(e, item) {
    e.stopPropagation();
    showPORProductModal(item);
  }

  /**
   * Update POR item price
   * key: price or servedPrice
   */
  function updateItemPrice(item: IOrderItem, key?: string, value?: number | string, error?: string) {
    const v = Number(value);
    if (!error && v > 0) {
      const invalidPriceIssue = issues?.find(i => i.orderItemId === item.id && i.type === 'price-not-valid');
      cartUpdateItem({ ...item, [key]: v, isPor: false, servedPrice: v }, invalidPriceIssue);
    }
    setPriceEdit(false);
  }
};

/**
 * Get column according to a custom config
 */
function getColumnField(
  columnConfig: IColumnConfig,
  options?: {
    getValue?: (data: IOrderItem, idx: number, rowIdx: number) => string | number;
    getElement?: (data: IOrderItem, idx: number, rowIdx: number) => React.ReactElement;
    getDataCell?: (data: IOrderItem, idx: number, rowIdx: number) => IDataCell;
  },
  isQuoterMode?: boolean,
): IColumn {
  const { getValue, getElement, getDataCell } = options || {};

  switch (columnConfig.name) {
    case 'actions':
      return {
        id: 'actions',
        title: '',
        element: getElement,
        width: '7%',
      };
    case 'box-weight':
      return {
        id: 'box-weight',
        title: __('Components.ProductsList.Table.BoxWeight'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'boxes-per-pallet':
      return {
        id: 'boxes-per-pallet',
        element: getElement,
        title: __('Components.ProductsList.Table.BoxesPerPallet'),
        minWidth: '70px',
        width: '9%',
      };
    case 'brand':
      return {
        id: 'brand',
        title: __('Components.ProductsList.Table.Brand'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'category':
      return {
        id: 'category',
        title: __('Components.ProductsList.Table.Category'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'contracts':
      return {
        id: 'contracts',
        title: __('WorkspaceSupplierEdit.Menu.contracts.ref'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'cost':
      return {
        id: 'cost',
        title: __('Components.Cart.items.cost'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'delete':
      return {
        id: 'delete',
        title: '',
        element: getElement,
        width: '5%',
      };
    case 'ean':
      return {
        id: 'ean',
        title: __('Components.ProductsList.Table.Ean'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'images':
      return {
        id: 'images',
        title: __('Components.ProductsList.Table.Images'),
        getDataCell,
        minWidth: '70px',
        width: '9%',
      };
    case 'margin':
      return {
        id: 'margin',
        title: __('Components.Cart.items.margin'),
        element: getElement,
        minWidth: '80px',
        width: '9%',
      };
    case 'load':
      return {
        id: 'load',
        title: __('Components.ProductsList.Table.Load'),
        minWidth: '120px',
        width: '10%',
        element: getElement,
      };
    case 'product':
      return {
        id: 'product',
        title: __('Components.Cart.items.product'),
        element: getElement,
        minWidth: '160px',
        width: '20%',
      };

    case 'price':
      return {
        id: 'price',
        title: __('Components.Cart.items.price'),
        element: getElement,
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '120px',
        width: '10%',
      };
    case 'unit-price':
      return {
        id: 'unit-price',
        title: isQuoterMode ? __('Components.Cart.items.proposal') : __('Components.Cart.items.unit_price'),
        element: getElement,
        minWidth: '220px',
        width: '12%',
      };
    case 'quantity':
      return {
        id: 'quantity',
        title: __('Components.Cart.items.quantity'),
        element: getElement,
        minWidth: '220px',
        width: '12%',
      };
    case 'status':
      return {
        id: 'status',
        title: __('Components.Cart.items.prepare_title'),
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '140px',
        width: '12%',
        align: 'left',
        element: getElement,
      };
    case 'weight':
      return {
        id: 'weight',
        title: __('Components.Cart.items.weight'),
        element: getElement,
        minWidth: '70px',
        width: '9%',
      };
    case 'served-quantity':
      return {
        id: 'served-quantity',
        title: __('Components.Cart.items.served_quantity'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'ordered-quantity':
      return {
        id: 'ordered-quantity',
        title: __('Components.Cart.items.ordered_quantity'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'size':
      return {
        id: 'size',
        title: __('Components.ProductsList.Table.Size'),
        minWidth: '70px',
        element: getElement,
        width: '9%',
      };
    case 'origin':
      return {
        id: 'origin',
        title: __('Components.ProductsList.Table.Origin'),
        value: (data: IOrderItem) => data.origin,
        minWidth: '50px',
        width: '9%',
        element: getElement,
      };

    case 'packaging':
      return {
        id: 'packaging',
        title: __('Components.ProductsList.Table.Packaging'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'updated':
      return {
        id: 'updated',
        title: __('Components.ProductsList.Table.UpdatedAt'),
        value: getValue,
        minWidth: '60px',
        width: '8%',
      };
    case 'region':
      return {
        id: 'region',
        title: __('Components.ProductsList.Table.Region'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'type-of-production':
      return {
        id: 'type-of-production',
        title: __('Components.ProductsList.Table.TypeOfProduction'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) => parsers.getOrganicText(data.organic),
      };
    case 'sku':
      return {
        id: 'sku',
        title: __('Components.ProductsList.Table.Sku'),
        minWidth: '70px',
        width: '9%',
      };
    case 'warehouse':
      return {
        id: 'warehouse',
        title: __('Components.ProductsList.Table.Warehouse'),
        minWidth: '80px',
        width: '10%',
        element: getElement,
      };
    case 'variety':
      return {
        id: 'variety',
        title: __('Components.ProductsList.Table.variety'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'units-per-box':
      return {
        id: 'units-per-box',
        title: __('Components.ProductsList.Table.UnitsPerBox'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    default:
      return undefined;
  }
}

export default ItemsCard;
