import { IUser, realtime } from 'common-services';

import config from '../../bindings/config';
import { bcDebug, logError } from './log';

export function createRealTimeConnection(user: IUser, device: string, onConnect: () => void, dispatch: any) {
  realtime.init(
    config.REALTIME_URL,
    device,
    user.country && user.phone ? Number(`${user.country}${user.phone}`) : undefined,
    bcDebug,
    logError,
    onConnect,
    dispatch,
  );
}
