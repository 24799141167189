import { __, IDateRange, IOrderType } from 'common-services';

/**
 * Summary widget: get title according to date / order type
 */
export function getSummaryWidgetTitle(dateRange: IDateRange, orderType: IOrderType) {
  switch (dateRange) {
    case 'all':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_all')
        : __('Widgets.OrderSummary.title_purchase_all');
    case 'today':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_today')
        : __('Widgets.OrderSummary.title_purchase_today');
    case 'yesterday':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_yesterday')
        : __('Widgets.OrderSummary.title_purchase_yesterday');

    case 'week':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_week')
        : __('Widgets.OrderSummary.title_purchase_week');

    case 'month':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_month')
        : __('Widgets.OrderSummary.title_purchase_month');

    case 'year':
      return orderType === 'sale'
        ? __('Widgets.OrderSummary.title_sale_year')
        : __('Widgets.OrderSummary.title_purchase_year');

    case 'custom':
    default:
      return orderType === 'sale' ? __('Widgets.OrderSummary.title_sale') : __('Widgets.OrderSummary.title_purchase');
  }
}

/**
 * Get bar chart title
 */
export function getBarChartWidgetTitle(orderType: IOrderType) {
  return orderType === 'sale' ? __('Widgets.BarChart.title_sale') : __('Widgets.BarChart.title_purchase');
}

/**
 * Summary widget: get previous GMV description label
 */
export function getPreviousGmvDescription(dateRange: IDateRange, previousGmv: string) {
  switch (dateRange) {
    case 'today':
      return __('Widgets.OrderSummary.previous_gmv_yesterday', { amount: previousGmv });
    case 'yesterday':
      return __('Widgets.OrderSummary.previous_gmv_day_before', { amount: previousGmv });
    case 'week':
      return __('Widgets.OrderSummary.previous_gmv_past_week', { amount: previousGmv });
    case 'month':
      return __('Widgets.OrderSummary.previous_gmv_past_month', { amount: previousGmv });
    case 'year':
      return __('Widgets.OrderSummary.previous_gmv_past_year', { amount: previousGmv });
    case 'custom':
      return __('Widgets.OrderSummary.previous_gmv_past_period', { amount: previousGmv });
  }
}

/**
 * Summary widget: get previous GMV variation description label
 */
export function getPreviousGmvVariationDescription(dateRange: IDateRange, gmv: number, previousGmv: number) {
  if (!gmv || !previousGmv) return { variationDescription: '', percentage: 0 };

  const percentage = (((gmv - previousGmv) / previousGmv) * 100).toFixed(2);
  let variationDescription = '';
  switch (dateRange) {
    case 'today':
      variationDescription = __('Widgets.OrderSummary.change_variation_today', { percentage });
      break;
    case 'yesterday':
      variationDescription = __('Widgets.OrderSummary.change_variation_yesterday', { percentage });
      break;
    case 'week':
      variationDescription = __('Widgets.OrderSummary.change_variation_week', { percentage });
      break;
    case 'month':
      variationDescription = __('Widgets.OrderSummary.change_variation_month', { percentage });
      break;
    case 'year':
      variationDescription = __('Widgets.OrderSummary.change_variation_year', { percentage });
      break;
    case 'custom':
      variationDescription = __('Widgets.OrderSummary.change_variation_custom', { percentage });
      break;
  }
  return { variationDescription, percentage };
}
