import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, ExpansionPanel, RowContainer } from '../../atoms';

interface IStyledProps {
  background?: string;
  color?: string;
  hasAction?: boolean;
  paddingBottom?: string;
  theme: ITheme;
  width?: string;
}

export const Container = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: width 0.8s ease;
  ${(p: IStyledProps) =>
    p.paddingBottom &&
    css`
      padding-bottom: ${p.paddingBottom};
    `}
`;

export const Section = styled(ColumnContainer)`
  max-width: 700px;
  padding-bottom: 120px;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  line-height: 32px;
`;

export const TextLight = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextBlue = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: 40px;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  line-height: normal;
`;

export const TextTitlePadding = styled(TextTitle)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TextInfo = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextDescription = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  line-height: 30px;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  white-space: pre-wrap;
`;
export const TextDescriptionBold = styled(TextDescription)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TextColor = styled(Text)<IStyledProps>`
  color: ${(p: IStyledProps) => p.color};
  font-weight: inherit;
  font-size: inherit;
  ${(p: IStyledProps) =>
    p.hasAction &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `}
`;

export const ExpandableInfo = styled(ExpansionPanel)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const Row = styled(RowContainer)``;
export const Column = styled(ColumnContainer)``;
export const ImageWrapper = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 275px;
  background-image: url('${(p: IStyledProps) => p.background}');
  background-repeat: no-repeat;
  background-size: contain;
`;
export const ImageWrapper2 = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  height: 44px;
  background-image: url('${(p: IStyledProps) => p.background}');
  background-repeat: no-repeat;
  background-size: contain;
`;
export const ImageWrapper2Column = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  height: 200px;
  min-width: 365px;
  background-image: url('${(p: IStyledProps) => p.background}');
  background-repeat: no-repeat;
  background-size: contain;
`;
export const ImageWrapper3 = styled(RowContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  min-width: 340px;
  top: 4px;
  position: relative;
`;

export const CTA = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  min-width: 280px;
`;

export const ExpandableSection = styled(Section)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;
