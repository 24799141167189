import { __, EventTrack, notificationsActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import share from '../../../services/sharer';
import { copyToClipboard, deviceIsIpad } from '../../../util/utils';
import * as S from './Share.styled';

export interface IProps {
  hideShareButtons?: boolean;
  pricelistOwner?: { name: string; companyName: string; avatar?: string };
  notificationShow: typeof notificationsActions.notificationShow;
  toggleQRCodeVisibility?: () => void;
  trackShare?: (t: EventTrack.SHARE_MEDIUM) => void;
  urlToShare: string;
}

/**
 * Share link options + social
 */
const Share: React.FC<IProps> = ({
  hideShareButtons,
  toggleQRCodeVisibility,
  urlToShare,
  notificationShow,
  trackShare,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [linkCopied, setLinkCopied] = React.useState(false);
  return (
    <S.Container>
      <S.LinkContainer
        onClick={() => {
          if (!linkCopied)
            copyToClipboard(urlToShare, err => {
              if (err)
                dispatch(
                  notificationsActions.notificationShow(
                    {
                      style: 'error',
                      title: __('ClipboardError.title'),
                      subtitle: __('ClipboardError.subtitle'),
                      closable: true,
                    },
                    4000,
                  ),
                );
              if (!err) setLinkCopied(true);
            });
        }}
      >
        <S.TextGrey2>{linkCopied ? __('Components.Header.LinkCopied') : urlToShare}</S.TextGrey2>
      </S.LinkContainer>
      <S.Buttons>
        <S.ButtonShare
          type="link"
          iconName="External-link"
          onClick={() => {
            trackShare(EventTrack.SHARE_MEDIUM.OPEN_LINK);
            window.open(urlToShare, '_blank');
          }}
        >
          {__('ProductListShare.share_open')}
        </S.ButtonShare>

        <S.ButtonShare
          type="link"
          iconName="Clipboard"
          onClick={async () => {
            copyToClipboard(urlToShare, error =>
              notificationShow(
                {
                  style: error ? 'error' : 'info',
                  title: error ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                  subtitle: error ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                  closable: true,
                },
                4000,
              ),
            );
            if (trackShare) {
              trackShare(EventTrack.SHARE_MEDIUM.LINK);
            }
          }}
        >
          {__('ProductListShare.share_copy')}
        </S.ButtonShare>
        {toggleQRCodeVisibility ? (
          <S.ButtonShare type="link" iconName="QR" onClick={toggleQRCodeVisibility}>
            {__('ProductListShare.share_qr')}
          </S.ButtonShare>
        ) : null}

        {hideShareButtons ? null : (
          <S.SocialButtons>
            <S.SocialButton
              link={IMAGES.email}
              onClick={() => {
                share({
                  sharer: 'email',
                  url: urlToShare,
                  title: __('ProductListShare.email_title'),
                  subject: __('ProductListShare.email_subject'),
                });
                trackShare(EventTrack.SHARE_MEDIUM.EMAIL);
              }}
            />
            <S.SocialButton
              link={IMAGES.linkedin}
              onClick={() => {
                share({ sharer: 'linkedin', url: urlToShare });
                trackShare(EventTrack.SHARE_MEDIUM.LINKEDIN);
              }}
            />
            <S.SocialButton
              link={IMAGES.facebook}
              onClick={() => {
                share({ sharer: 'facebook', url: urlToShare });
                trackShare(EventTrack.SHARE_MEDIUM.FACEBOOK);
              }}
            />
            <S.SocialButton
              link={IMAGES.whatsapp}
              onClick={() => {
                share({
                  sharer: 'whatsapp',
                  url: urlToShare,
                  title: __('ProductListShare.email_title'),
                  web: !deviceIsIpad(),
                });
                trackShare(EventTrack.SHARE_MEDIUM.WHATSAPP);
              }}
            />
          </S.SocialButtons>
        )}
      </S.Buttons>
    </S.Container>
  );
};

export default React.memo(Share);
