import { __, constants, EventTrack, qs, notificationsActions, imageActions, modalActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import config from '../../../../../../bindings/config';
import { deviceIsIpad } from '../../../../../util/utils';
import { Picture, Switch, TextArea } from '../../../../atoms';
import { FormSection, InputWithLabel, Share } from '../../../../molecules';
import { resizeImage } from '../../../../../services/image';
import { logError } from '../../../../../services/log';
import * as S from './ShopOnline.styled';

const ShopOnline: React.FC<{
  catalog: IWorkspace;
  contacts: { [contactId: number]: IContact };
  editMode: boolean;
  me: IUser;
  sectionRef: React.RefObject<HTMLDivElement>;
  updateCatalog: (catalog: IWorkspace) => void;
}> = ({ catalog, contacts, editMode, me, sectionRef, updateCatalog }) => {
  const dispatch = useDispatch();
  const { bio, companyLogo, companyImage, workingStructure, additionalInfo } = catalog;
  return (
    <FormSection id="shop_online" sectionRef={sectionRef} title={__('WorkspaceSettings.Menu.ShopOnline.title')}>
      <InputWithLabel
        isRequired={true}
        label={editMode ? __('Components.Settings.pricelist.title') : __('Components.Settings.pricelist.label')}
        description={__('Components.Settings.pricelist.description')}
      >
        <S.SwitchRow>
          <S.SwitchLabel>{__('WorkspaceSettings.OnlineShop.switch')}</S.SwitchLabel>
          <Switch
            name="settings"
            isChecked={catalog.publicPriceList}
            onChange={(n, checked) => updateCatalog({ ...catalog, publicPriceList: checked })}
            disabled={!editMode}
            selectable={false}
          />
        </S.SwitchRow>
      </InputWithLabel>
      {catalog.publicPriceList ? (
        <Share
          hideShareButtons={catalog.id !== me.sellerWorkspaceId}
          pricelistOwner={getPricelistOwner()}
          notificationShow={(notification, milliseconds) =>
            dispatch(notificationsActions.notificationShow(notification, milliseconds) as any)
          }
          trackShare={medium => trackShare(catalog && catalog.hashId, medium)}
          urlToShare={getUrlToShare(me.id, catalog.priceListUrl, me.hashId)}
        />
      ) : null}
      <>
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.company_logo') + ' (150x150)'}
          description={__('Components.Settings.company_logo_description')}
          disabled={!editMode}
          asColumn={!!companyLogo}
        >
          <Picture
            editable={editMode}
            height="150px"
            imageUrl={companyLogo}
            onDelete={() => onPictureDelete('companyLogo')}
            onFileChange={file => onPictureChange(file, 'companyLogo')}
            pictureMode={true}
            picturePlaceholder={__('Components.Settings.company_logo_zero')}
            relation={constants.IMAGE_RELATION.COMPANY_LOGO}
            width="150px"
            withCrop={deviceIsIpad() ? false : true}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.company_image') + ' (1200x300)'}
          description={__('Components.Settings.company_image_description')}
          disabled={!editMode}
          asColumn={!!companyImage}
        >
          <Picture
            editable={editMode}
            height="200px"
            imageUrl={companyImage}
            margin="5px 0"
            onDelete={() => onPictureDelete('companyImage')}
            onFileChange={file => onPictureChange(file, 'companyImage')}
            relation={constants.IMAGE_RELATION.COMPANY_IMAGE}
            size="big"
            width="800px"
            withCrop={deviceIsIpad() ? false : true}
            zeroCase={__('Components.Settings.company_image_zero')}
            cropStrategy="contain"
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.shop.company_brief.title')}
          description={__('WorkspaceSettings.shop.company_brief.description')}
          disabled={!editMode}
          asColumn={!!bio}
        >
          <TextArea
            name="bio"
            value={bio || ''}
            placeholder={__('WorkspaceSettings.shop.company_brief.placeholder')}
            onChange={(n, v) => {
              updateCatalog({ ...catalog, bio: v as string });
            }}
            onBlur={(n, v) => {
              updateCatalog({ ...catalog, bio: v });
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            zeroCase={__('Components.Settings.about_you.zero')}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.shop.working_structure.title')}
          description={__('WorkspaceSettings.shop.working_structure.description')}
          disabled={!editMode}
          asColumn={!!workingStructure}
        >
          <TextArea
            name="working_structure"
            value={workingStructure || ''}
            placeholder={__('WorkspaceSettings.shop.working_structure.placeholder')}
            onChange={(n, v) => {
              updateCatalog({ ...catalog, workingStructure: v as string });
            }}
            onBlur={(n, v) => {
              updateCatalog({ ...catalog, workingStructure: v });
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            zeroCase={__('Components.Settings.about_you.zero')}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.shop.additional_info.title')}
          description={__('WorkspaceSettings.shop.additional_info.description')}
          disabled={!editMode}
          asColumn={!!additionalInfo}
        >
          <TextArea
            name="additional_info"
            value={additionalInfo || ''}
            placeholder={__('WorkspaceSettings.shop.additional_info.placeholder')}
            onChange={(n, v) => {
              updateCatalog({ ...catalog, additionalInfo: v as string });
            }}
            onBlur={(n, v) => {
              updateCatalog({ ...catalog, additionalInfo: v });
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            zeroCase={__('Components.Settings.about_you.zero')}
          />
        </InputWithLabel>
      </>
    </FormSection>
  );

  /**
   * Get pricelist owner info
   */
  function getPricelistOwner() {
    const owner = catalog.members.find(m => m.role === 'admin');
    if (owner && contacts[owner.userId]) {
      const ownerContact = contacts[owner.userId];
      return {
        name: ownerContact.name,
        companyName: ownerContact.companyName,
        avatar: ownerContact.companyLogo || ownerContact.avatar,
      };
    }
    if (owner && owner.userId === me.id) {
      return {
        name: me.name,
        companyName: me.companyName,
        avatar: catalog.companyImage || catalog.companyLogo || me.settings.avatar,
      };
    }
    return undefined;
  }

  /**
   * On delete picture with confirmation modal
   */
  function onPictureDelete(field: 'companyLogo' | 'companyImage'): void {
    dispatch(
      modalActions.modalOpen(
        __('Components.ProductDetails.confirm_remove_picture'),
        () => {
          updateCatalog({ ...catalog, [field]: undefined });
          dispatch(modalActions.modalClose());
        },
        {
          buttonText: __('Components.Modal.delete'),
        },
      ),
    );
  }

  /**
   * On picture change:
   * * Upload picture
   * * Update in user state
   */
  async function onPictureChange(file: File, field: 'companyLogo' | 'companyImage') {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = event =>
      logError(new Error('File could not be read! Code ' + (event.target as any).error.code), `upload.${field}`);
    reader.onload = () =>
      reader.result &&
      resizeImage(
        reader.result as string,
        (imageResized: string) =>
          dispatch(
            imageActions.mediaUploadWithProgress({ ...file, name: file.name, content: imageResized }, result =>
              updateCatalog({
                ...catalog,
                [field]: result
                  ? result.secure_url.replace(
                      'f_auto',
                      field === 'companyLogo' ? 'f_auto,h_150,w_150,c_limit' : 'f_auto,h_400,w_1600,c_limit',
                    )
                  : undefined,
              }),
            ) as any,
          ),
        field === 'companyImage' ? 1600 : undefined,
      );
  }
};

export default React.memo(ShopOnline);

/**
 * return the url to share with utms
 */
function getUrlToShare(myId: number, priceListUrl: string, contactHash: string) {
  const params = {
    utm_source: 'consentio',
    utm_campaign: 'share',
    utm_medium: myId,
    h: contactHash,
  };
  const queryString = qs.stringify(params);
  return `${config.WEB_URL}/pricelist/${priceListUrl}${queryString}`;
}

/**
 * Track share catalog / product action with an event track
 */
function trackShare(catalogHashId: string, medium: EventTrack.SHARE_MEDIUM) {
  const type = EventTrack.SHARE_TYPE.CATALOG;

  EventTrack.catalogShare({
    type,
    medium,
    search: '',
    catalogHashId,
    receivers: [],
    broadcasts: [],
    totalUsers: 0,
    productId: 0,
    productType: '',
    productVariety: '',
    numProducts: 0,
  });
}
