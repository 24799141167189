import styled, { css, ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../../../atoms';
import MentionModalComponent from '../../../ChatFooter/MentionModal/MentionModal.component';
import MI from '../../../MessageInput';

interface IStyledProps {
  color?: string;
  disabled?: boolean;
  isRead?: boolean;
  isFirst?: boolean;
  isEditing?: boolean;
  hasMetadata?: boolean;
  margin?: string;
  theme: ITheme;
}

export const MetaRow = styled.a`
  display: flex;
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  text-decoration: none;
  color: inherit;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const MetaColumn = styled(ColumnContainer)`
  max-width: 100%;
  overflow: hidden;
`;

export const MetaTitle = styled.p`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const MetaDescription = styled.p`
  overflow: hidden;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  white-space: nowrap;
  max-width: 500px;
  line-height: 18px;
  text-overflow: ellipsis;
`;

export const MetaImage = styled.img`
  width: 36px;
  min-width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  overflow: hidden;
`;

export const Link = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  :visited {
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
  }
  display: block;
`;

export const Text = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 20px;
  padding-right: 4px;
`;

export const TextItalic = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-style: italic;
`;

export const LinkImage = styled.img`
  width: 325px;
  max-width: 325px;
  max-height: 550px;
  height: auto;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const LoomLink = styled.a`
  display: block;
`;

export const Texts = styled(ColumnContainer)``;

export const TextWrapper = styled.div`
  ${(p: IStyledProps) =>
    p.hasMetadata
      ? css`
          background: white;
          padding: ${p.theme.paddingSize()} ${p.theme.paddingSize(2)};
          border-radius: ${p.theme.borderRadius};
          border: 1px solid ${p.theme.colors.light1};
          max-width: min(100%, 600px);
        `
      : ''}
`;

export const EditContainer = styled(ColumnContainer)`
  width: 100%;
  position: relative;
`;

export const EditButtons = styled(RowContainer)`
  align-items: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TextEdited = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: inline;
`;

export const Emoji = styled.span`
  display: inline-block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  min-width: 22px;
`;

export const BigEmoji = styled.span`
  display: inline-block;
  font-size: 45px;
  min-width: 45px;
  line-height: 48px;
`;

export const MentionModal = styled(MentionModalComponent)`
  bottom: 104px;
  left: 0px;
`;
export const SaveText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  display: inline;
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => (p.isEditing ? p.theme.colors.blue1.replace('1)', '0.4)') : p.theme.colors.blue1)};
`;
export const CancelText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  display: inline;
  cursor: pointer;
`;
export const MessageInput = styled(MI)`
  width: 100%;
  padding: 0;
`;
