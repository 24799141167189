import { userSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import config from '../../../../bindings/config';

import { WorkspaceOffersV1dot1 } from './WorkspaceOffersBuyer.component.v1.1';
import { WorkspaceOffersV1dot0 } from './WorkspaceOffersBuyer.component.v1.0';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  contactId?: string;
}>;

export type IProps = IRouteProps;

const WorkspaceOffers: React.FC<IProps> = props => {
  const toggleOfferingV1dot1 = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_OFFERING_V_1_1));
  return toggleOfferingV1dot1 ? <WorkspaceOffersV1dot1 {...props} /> : <WorkspaceOffersV1dot0 {...props} />;
};

export default React.memo(WorkspaceOffers);
