import styled, { ITheme } from '../../../styled-components';
import { Button, RowContainer } from '../../atoms';

interface IStyledProps {
  filtersOpened?: boolean;
  filtersSelected?: number;
  theme: ITheme;
}

const ButtonGroupItem = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  min-width: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

const getBackgroundColor = (p: IStyledProps) => {
  if (p.filtersOpened) return p.theme.colors.grey3;
  if (p.filtersSelected > 0) return p.theme.colors.blue2;
  return p.theme.colors.grey4;
};

const getFilterTextColor = (p: IStyledProps) => {
  if (p.filtersOpened || p.filtersSelected > 0) return p.theme.colors.black;
  return p.theme.colors.grey1;
};

export const FilterButton = styled(ButtonGroupItem)<IStyledProps>`
  height: 37px;
  background-color: ${getBackgroundColor};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  & span {
    font-weight: ${(p: IStyledProps) =>
      p.filtersOpened || p.filtersSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular};
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    color: ${getFilterTextColor};
  }
  .button-icon {
    color: ${getFilterTextColor};
  }
`;

export const Badge = styled(RowContainer)`
  font-family: 'Nunito';
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding: 0px ${(p: IStyledProps) => p.theme.paddingSize()};
`;
