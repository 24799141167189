import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, LettersAvatar, RowContainer, TextLabel, Badge } from '../../atoms';

interface IStyledProps {
  contentGrow?: boolean;
  isSelected?: boolean;
  onlyContent?: boolean;
  theme: ITheme;
  width?: string;
  minimized?: boolean;
  hasCloseIcon?: boolean;
  startMinimized?: boolean;
}

export const Modal = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 200;
  justify-content: center;
  align-items: center;
`;

export const UnreadBadge = styled(Badge)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CardContainer = styled(ColumnContainer)<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  min-width: min(90vw, ${(p: IStyledProps) => p.width || '500px'});
  align-self: center;
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-width: ${(p: IStyledProps) => p.width || '500px'};
  overflow: hidden;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  height: min(${(p: IStyledProps) => (p.minimized ? '47px' : `${p.theme.windowHeight} - 2 * 47px`)}, 650px);
  position: relative;
`;

export const Wrapper = styled(ColumnContainer)<IStyledProps>`
  max-height: ${(p: IStyledProps) => (p.onlyContent ? 'auto' : '100%')};
  overflow: hidden;
  ${(p: IStyledProps) =>
    p.contentGrow &&
    css`
      flex: 1;
    `}
`;

export const Header = styled(RowContainer)`
  min-height: 47px;
  max-height: 47px;
  background-color: ${(p: IStyledProps) => p.theme.colors.black};
  cursor: ${(p: IStyledProps) => (p.startMinimized ? 'pointer' : 'default')};
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 16px;
  position: absolute;
  top: 0;
  right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const MinimizeIcon = styled(CloseIcon)`
  right: ${(p: IStyledProps) => (p.hasCloseIcon ? p.theme.paddingSize(6) : p.theme.paddingSize())};
`;

export const TitleColumn = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex: 1 1;
  justify-content: flex-start;
  text-transform: uppercase;
  align-items: center;
  align-self: center;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  display: flex;
`;

export const SubTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const Avatar = styled(LettersAvatar)`
  width: 35px;
  justify-content: middle;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
