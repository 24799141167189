import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';
import Button from '../../atoms/Button/Button.component';

interface IStyledProps {
  theme: ITheme;
  image?: string;
  color?: string;
  backgroundColor?: string;
  from?: 'showroom' | 'pricelist';
}

export const TopContainer = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  min-height: max-content;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingView};
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    flex-wrap: wrap;
  }
`;

export const ContactCol = styled(ColumnContainer)`
  flex: 1;
  padding: 0 0 0 ${(p: IStyledProps) => p.theme.paddingView};
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const ContactRow = styled(RowContainer)`
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingView} 0;
`;

export const Image = styled(RowContainer)`
  position: relative;
  background-image: url('${(p: IStyledProps) => p.image}');
  border-bottom-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-top-left-radius: ${(p: IStyledProps) => (p.from === 'pricelist' ? p.theme.borderRadius : 0)};
  border-top-right-radius: ${(p: IStyledProps) => (p.from === 'pricelist' ? p.theme.borderRadius : 0)};
  width: 45%;
  min-width: 450px;
  height: 250px;
  max-width: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: calc(${(p: IStyledProps) => p.theme.paddingView} * 2);
  justify-content: flex-end;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-top: 30%;
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingView};
    margin-right: 0;
    background-size: cover;
    height: auto;
  }
`;

export const Contact = styled.div`
  margin: 0;
  height: auto;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const ContactCard = styled(Contact)`
  height: auto;
  margin-left: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const ContactInfo = styled(RowContainer)`
  align-items: flex-start;
`;

export const Avatar = styled.div`
  display: flex;
  width: ${(p: IStyledProps) => (p.from === 'pricelist' ? '60px' : '80px')};
  height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '60px' : '80px')};
  background-size: cover;
  border-radius: 40px;
  background-image: url(${(p: IStyledProps) => p.image});
`;

export const AvatarImage = styled.img`
  display: block;
  max-width: 260px;
  max-height: 70px;
  object-fit: contain;
`;

export const ChatButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
  height: 29px;
  padding: 0 35px;
  min-width: 50%;
  width: auto;
  background-color: ${(p: IStyledProps) => p.backgroundColor};
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 65%;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 100%;
  }
`;

export const Text = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: block;
`;

export const TextCompany = styled(Text)`
  max-width: 450px;
  p {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    color: ${(p: IStyledProps) => p.theme.colors.black};
  }
  span {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
    cursor: pointer;
    text-decoration: underline;
    display: block;
    text-align: right;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    max-width: 100%;
  }
`;

export const Name = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const Phone = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.green1};
  display: block;
`;

export const Email = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.green1};
  display: block;
`;

export const AvatarWrapper = styled.div`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  height: min-content;
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
  margin-right: -${(p: IStyledProps) => p.theme.paddingObject};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;

export const CompanyLogoWrapper = styled.div`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  height: min-content;
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;
