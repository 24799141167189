import styled, { ITheme } from '../../../styled-components';
import { LettersAvatar } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  isEndCell?: boolean;
  isEven?: boolean;
  noBorderLeft?: boolean;
  noBorderRight?: boolean;
  isScrolled?: boolean;
}

export const StyledContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const StyledTable = styled.table`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  border-collapse: collapse;
  color: ${(p: IStyledProps) => p.theme.colors.black};

  .seller {
    margin-top: 0px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
    border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  overflow-x: auto;
  overflow-y: visible;

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
    box-shadow: ${(p: IStyledProps) => (p.isScrolled ? '0 8px 10px -5px rgba(0,0,0,0.16)' : '')};
  }

  thead tr:nth-child(2) {
    border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  thead tr:nth-child(3) {
    border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
  }

  th {
    vertical-align: middle;
  }

  tbody tr:nth-child(odd) td:first-child {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4Opacity07};
    z-index: 1;
  }

  tbody tr:nth-child(even) td:first-child {
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
    z-index: 1;
  }

  tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    width: 150px;
    text-align: left;
  }

  tbody td:nth-child(3n + 1) {
    border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  tbody {
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
  }

  tr:nth-child(odd) {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4Opacity07};
  }
`;

export const StyledTh = styled.th`
  max-width: 150px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};

  .warehouse {
    border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
  &:nth-child(2) .warehouse {
    border-top-left-radius: 20px;
  }
  &:last-child .warehouse {
    border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    border-top-right-radius: 20px;
  }
`;

export const StyledCell = styled.td`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: 150px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  background-color: ${(p: IStyledProps) => (p.isEven ? 'green' : 'transparent')};
`;

export const SellerCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const WhCell = styled(SellerCell)`
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const GreyCell = styled(SellerCell)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: left;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  max-height: 56px;
`;

export const HeaderCell = styled(GreyCell)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-left: 1px solid ${(p: IStyledProps) => (p.noBorderLeft ? 'none' : p.theme.colors.grey3)};
  border-right: 1px solid ${(p: IStyledProps) => (p.noBorderRight ? 'none' : p.theme.colors.grey3)};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  justify-content: left;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const UnitText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const BlackText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  overflow: hidden;
`;

export const BodyText = styled(Text)`
  max-width: 150px;
`;

export const WarehouseNameText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  overflow: hidden;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const SellerImage = styled(LettersAvatar)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: 10px;
`;

export const WarehouseNameImage = styled(LettersAvatar)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;
