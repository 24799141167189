import { constants, ISection, LOCALE } from 'common-services';
import { XYCoord } from 'dnd-core';
import * as React from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Input, Picture } from '../../../atoms';
import * as S from './SectionLine.styled';

interface IProps {
  index: number;
  languages: Array<LOCALE>;
  moveSection: (dragIndex: number, hoverIndex: number) => void;
  onAddImage: (file: File) => void;
  onDelete: () => void;
  onTranslate: (locale: LOCALE, value: string) => void;
  section: ISection;
  editMode: boolean;
}

interface DragSection extends ISection {
  index: number;
  type: string;
}

const SectionLine: React.FC<IProps> = ({
  editMode,
  index,
  languages,
  moveSection,
  onAddImage,
  onDelete,
  onTranslate,
  section,
}: IProps) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const dragRef = React.useRef<HTMLDivElement>();

  const [, drop] = useDrop({
    accept: 'section',
    hover(item: DragSection, monitor: DropTargetMonitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveSection(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: { type: 'section', ...section, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (editMode) drag(drop(dragRef));

  const { imageUrl, tag } = section;
  return (
    <S.Container className="section-line">
      <S.TextNumber>{index + 1}</S.TextNumber>
      <S.SectionRow ref={dragRef} isDragging={editMode && isDragging}>
        {editMode ? <S.Icon name="Drag" cursor={isDragging ? 'grabbing' : 'grab'} /> : null}
        <S.TextCell>
          <S.TextTag>{tag}</S.TextTag>
        </S.TextCell>
        <S.ImageCell>
          {editMode ? (
            <Picture
              editable={editMode}
              imageUrl={imageUrl}
              size="extra-small"
              margin="0 5px"
              withCrop={true}
              relation={constants.IMAGE_RELATION.SECTION}
              onFileChange={onAddImage}
            >
              {imageUrl ? undefined : (
                <S.AddImageIcon
                  name="Add-image"
                  onClick={() => inputRef && inputRef.current && inputRef.current.click()}
                />
              )}
            </Picture>
          ) : null}
        </S.ImageCell>
        {languages.map((locale, idx) => {
          const rowIndex = index;
          const identifier = `${rowIndex}_${locale}`;
          return (
            <S.TextCell key={identifier}>
              <Input
                dataSelector={`translation_${identifier}`}
                tabIndex={idx}
                containerMargin={'5px'}
                name={identifier}
                value={section.translations[locale] || ''}
                disabled={!editMode}
                onBlur={(key: string, value: string, error: string) => {
                  if (!error) {
                    onTranslate(locale, value);
                  }
                }}
                onKeyDown={e => onKeyDown(e, locale, rowIndex, idx)}
              />
            </S.TextCell>
          );
        })}
        {editMode ? (
          <S.OptionsCell>
            <S.Divider />
            <S.Icon name="Trash" onClick={onDelete} />
          </S.OptionsCell>
        ) : null}
      </S.SectionRow>
    </S.Container>
  );

  /**
   * Keyboard navigation functionality to navigate between translations inputs
   */
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, locale: LOCALE, rowIndex: number, colIndex: number) {
    // move down or enter
    if (e.keyCode === 13 || e.keyCode === 40) {
      e.preventDefault();
      return setFocus(`[data-selector=translation_${rowIndex + 1}_${locale}]`);
    }
    // move right
    if (e.keyCode === 39 && e.currentTarget.selectionEnd === e.currentTarget.value.length) {
      e.preventDefault();
      const nextLocale = languages[colIndex + 1] || locale;
      return setFocus(`[data-selector=translation_${rowIndex}_${nextLocale}]`);
    }
    // tabulation
    if (e.keyCode === 9) {
      e.preventDefault();
      const nextLocale = languages[colIndex + 1] || locale;
      return setFocus(`[data-selector=translation_${rowIndex}_${nextLocale}]`);
    }
    // move up
    if (e.keyCode === 38) {
      e.preventDefault();
      return setFocus(`[data-selector=translation_${rowIndex - 1}_${locale}]`);
    }
    // move left
    if (e.keyCode === 37 && e.currentTarget.selectionStart === 0) {
      e.preventDefault();
      const prevLocale = languages[colIndex - 1] || locale;
      return setFocus(`[data-selector=translation_${rowIndex}_${prevLocale}]`);
    }
  }

  /**
   * Set focus to an input element if it exists
   */
  function setFocus(selector: string) {
    if (selector) {
      const nextElement = document.querySelector(selector) as HTMLInputElement;
      if (nextElement) nextElement.focus();
    }
  }
};

export default React.memo(SectionLine);
