import styled, { ITheme } from '../../../styled-components';
import { BackButton as BB, ColumnContainer, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  hasImage?: boolean;
  isLast?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: 100%;
  justify-content: flex-start;
  position: relative;
  outline: none;
  overflow-y: auto;
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const CenterContainer = styled(ColumnContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(10)} 0;
`;

export const BackContainer = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const TextBack = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const BackButton = styled(BB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
