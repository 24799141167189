import { Api, broadcastActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import ChatBroadcastHeader, { OwnProps, StateProps, DispatchProps } from '../components/organisms/ChatBroadcastHeader';
import { BROADCAST_SECTIONS } from '../constants';
import { IReduxState } from '../reducers';
import { IBroadcastRouterProps } from './chat-broadcast-container';

function mapStateToPropsHeader(state: IReduxState, props: OwnProps): StateProps {
  const {
    broadcast: { broadcasts },
    contact: { inConsentio },
    user: { user },
  } = state;
  const bId = Number(props.match.params.channelId);
  const broadcast = broadcasts[bId];
  return {
    broadcast,
    section: props.match.path.endsWith('/info') ? BROADCAST_SECTIONS.INFO : undefined,
    contacts: inConsentio,
    me: user,
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IBroadcastRouterProps,
): DispatchProps {
  return bindActionCreators(
    {
      addMembers: broadcastActions.addMembers,
      createBroadcast: broadcastActions.createBroadcast,
      deleteMembers: broadcastActions.deleteMembers,
      getMembers: broadcastActions.getMembers,
      notificationShow: notificationsActions.notificationShow,
      updateBroadcast: broadcastActions.updateBroadcast,
    },
    dispatch,
  );
}
export default connect(mapStateToPropsHeader, mapDispatchToPropsDetails)(ChatBroadcastHeader);
