import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, TextLabel } from '../../atoms';
import VC from '../../organisms/LoginRegisterFlow/Fragments/VerifyCode/VerifyCode.component';

interface IStyledProps {
  padding?: string;
  theme: ITheme;
  textAlign?: string;
}

export const Container = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 300;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`;

export const ModalWrapper = styled.div`
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(8)} ${(p: IStyledProps) => p.theme.paddingSize(11)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;

export const CloseIcon = styled(FontIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const StepContainer = styled.div`
  max-width: 420px;
  min-width: 420px;
  margin: 0;
  position: relative;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 380px;
    min-width: 380px;
  }
`;

export const VerifyCode = styled(VC)`
  max-width: 420px;
  min-width: 420px;
  margin: 0;
  position: relative;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 380px;
    min-width: 380px;
  }
`;

export const Card = styled(ColumnContainer)`
  padding: 23px 10px 0 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
`;

export const Title = styled(TextLabel)<IStyledProps>`
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const SubmitButton = styled(Button)`
  min-width: 170px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} auto;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
`;

export const TextError = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  line-height: 1.2;
`;

const TextRegular = styled(TextLabel)`
  width: auto;
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.padding || '0'};
`;

export const TextErrorContainer = styled.div`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextSubtitle = styled(TextRegular)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
