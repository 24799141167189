import {
  __,
  colors,
  debounce,
  EventTrack,
  notificationsActions,
  orderActions,
  orderService,
  parsers,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { EmptyListResource, SimpleSearch } from '..';
import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import { AllProductUnitsArray } from '../../../domain/product';
import getPath from '../../../util/routes';
import { copyToClipboard } from '../../../util/utils';
import { FontIcon, Ribbon, RowContainer, Select } from '../../atoms';
import InputWithLabel from '../InputWithLabel';
import * as S from './OrderMagicImport.styled';

interface IProps {
  catalogIdSelected: number;
  catalogs: { [id: number]: IWorkspace };
  channelId?: string;
  client: IClient;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  clientUpdate: typeof sellerWorkspaceActions.clientUpdate;
  contact: IContact;
  contactId: number;
  customConf?: ICustomConf;
  formats: IFormatsResponse;
  history?: History<any>;
  importContactOrders: typeof orderActions.importClientOrders;
  match: {
    params: { workspaceId: string };
  };
  me: IUser;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  notificationShow: typeof notificationsActions.notificationShow;
  setParentState: (state: any) => void;
}

interface IState {
  files: Array<File>;
  formatSelected: string;
  showAllFormats: boolean;
  text: string;
  customConf?: ICustomConf;
}

export default class OrdersMagicImport extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement>;
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { client } = props;
    const formatSelected = client?.orderIncomingKind || '';
    this.state = {
      files: [],
      formatSelected,
      showAllFormats: !formatSelected,
      text: '',
    };
    this.containerRef = React.createRef();
  }

  public componentDidMount() {
    const { showAllFormats } = this.state;
    const { contact, customConf } = this.props;
    RenderTrack.track('OrdersMagicImport', {
      renderTime: this.t,
      contact_id: contact?.id,
      show_all_formats: showAllFormats,
    });
    if (customConf) {
      this.setState({ customConf });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { files, formatSelected, showAllFormats } = this.state;
    const { contact, client, customConf } = this.props;

    if (formatSelected !== prevState.formatSelected && files.length) this.setState({ files: [] });
    if (formatSelected !== prevState.formatSelected && formatSelected)
      EventTrack.track('import_orders_select_format', {
        contact_id: contact?.id,
        format: formatSelected,
      });
    if (prevProps.client !== client) {
      this.setState({
        showAllFormats: client?.orderIncomingKind ? false : true,
        formatSelected: client?.orderIncomingKind || '',
      });
    }
    if (prevState.showAllFormats !== showAllFormats) {
      RenderTrack.track('OrdersMagicImport', {
        renderTime: Date.now(),
        contact_id: contact?.id,
        show_all_formats: showAllFormats,
      });
    }
    if (customConf !== prevProps.customConf) {
      this.setState({ customConf });
    }
  }

  public render() {
    const {
      catalogIdSelected,
      catalogs,
      channelId,
      client,
      contact,
      formats,
      history,
      me,
      navigateChannelBySection,
      setParentState,
    } = this.props;
    const { customConf, files, formatSelected, showAllFormats, text } = this.state;
    const disabled = !sellerWorkspaceService.isActive(catalogs[catalogIdSelected], me.id);
    return (
      <>
        {disabled ? (
          <Ribbon
            actionText={__('Components.OrdersImport.inactive_ribbon_action')}
            onClickAction={() =>
              navigateChannelBySection(CHANNEL_SECTIONS.INFORMATION, channelId, !!contact, p =>
                history.push(getPath({ path: p as any, channelId })),
              )
            }
            text={__('Components.OrdersImport.inactive_ribbon', { name: catalogs[catalogIdSelected]?.name })}
            type="warning"
          />
        ) : null}
        <S.FormContainer ref={this.containerRef}>
          <S.TextSubtitleWithMargin>{__('Components.OrdersImport.select_order_type')}</S.TextSubtitleWithMargin>
          {showAllFormats ? (
            <FormatsList
              contact={contact}
              container={this.containerRef}
              formats={formats}
              formatSelected={formatSelected}
              navigateToAskFormat={(search: string) => setParentState({ tabSelected: 'ask-format', search })}
              setParentState={this.setState.bind(this)}
              setGlobalState={setParentState}
              updateClientInformation={this.updateClientInformation}
            />
          ) : (
            <UploadOrders
              catalogIdSelected={catalogIdSelected}
              catalogs={catalogs}
              client={client}
              customConf={customConf}
              files={files}
              formats={formats.all}
              formatSelected={formatSelected}
              importOrders={this.importOrders}
              disabled={disabled}
              me={me}
              setFiles={this.setFiles}
              setParentState={this.setState.bind(this)}
              text={text}
            />
          )}
        </S.FormContainer>
      </>
    );
  }

  /**
   * Handle click in button 'upload files'.
   */
  private importOrders = () => {
    const { catalogIdSelected, client, history, importContactOrders, setParentState } = this.props;
    const { files, formatSelected, customConf, text } = this.state;
    const fromChat = window.location.pathname.includes('/contact/');
    importContactOrders(
      catalogIdSelected,
      client?.userId,
      files,
      formatSelected,
      err => {
        setTimeout(() => setParentState({ tabSelected: 'list' }));
      },
      text,
      customConf,
    );
    if (fromChat) {
      history.push(
        getPath({
          path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
          workspaceId: catalogIdSelected + '',
          contactId: client.userId + '',
        }),
      );
    }
  };

  /**
   * Update client's order incoming kind after submitting the files to the server
   * It also updates clients' information
   */
  private updateClientInformation = (error?: Error) => {
    if (!error) {
      const { catalogs, catalogIdSelected, clientsAdd, clientUpdate, me, contact, client } = this.props;
      const { formatSelected } = this.state;
      const myRole = catalogs[catalogIdSelected].members.find(member => member.userId === me.id).role;
      if (myRole === 'viewer') return;
      this.setState({ files: [], text: '' });
      return client
        ? clientUpdate(me.id, catalogIdSelected, {
            ...client,
            orderIncomingEmail: this.getEmailAddress(client.userId),
            orderIncomingKind: formatSelected,
          })
        : clientsAdd(me.id, catalogIdSelected, [
            {
              ...sellerWorkspaceService.contactToClient(contact, catalogIdSelected),
              orderIncomingEmail: this.getEmailAddress(contact.id!),
              orderIncomingKind: formatSelected,
            },
          ]);
    }
  };

  /**
   * handle upload files of orders
   */
  private setFiles = (files: Array<File>) => {
    if (files.length > 0) {
      this.setState({ files: this.modifyFiles(this.state.files, files) });
    }
  };

  /**
   * add new files to the state
   */
  private modifyFiles = (existingFiles, files: Array<File>) => {
    const fileToUpload = [...existingFiles];
    files.forEach(file => {
      fileToUpload.push(file);
    });
    return fileToUpload;
  };

  /**
   * Get contact's email address to send orders
   */
  private getEmailAddress = (contactId: number) => {
    const { client, catalogIdSelected } = this.props;
    return client?.orderIncomingEmail || `${catalogIdSelected}_${contactId}@${config.EMAIL_DOMAIN_ADDRESS}`;
  };
}

const FormatsList: React.FC<{
  contact: IContact;
  container?: React.RefObject<HTMLDivElement>;
  formats: IFormatsResponse;
  formatSelected: string;
  navigateToAskFormat: (searchText: string) => void;
  setGlobalState: (state: any, cb?: () => void) => void;
  setParentState: (state: any, cb?: () => void) => void;
  updateClientInformation: (err?: Error) => void;
}> = ({
  contact,
  container,
  formats,
  formatSelected,
  navigateToAskFormat,
  setGlobalState,
  setParentState,
  updateClientInformation,
}) => {
  const [formatsToShow, setFormatsToShow] = React.useState<'all' | 'recommended' | 'recent'>('all');
  const [searchText, setSearchText] = React.useState('');
  const formatesFiltered = formats[formatsToShow]
    .map((f: IFormat | string) =>
      (f as IFormat),
    )
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter(of => {
      if (of.disabled) return false;
      if (searchText) return of.label.toLocaleLowerCase().search(searchText.toLocaleLowerCase()) !== -1;
      return true;
    });

  // Tracking events
  React.useEffect(() => {
    EventTrack.track('import_orders_filter_formats', {
      contact_id: contact?.id,
      filter: formatsToShow,
    });
  }, [formatsToShow, contact]);
  React.useEffect(() => trackFormatSearch(contact, searchText), [searchText, contact]);
  return (
    <>
      <SimpleSearch
        id="search-products"
        query={searchText}
        placeHolder={__('MagicOrders.search.placeholder')}
        onChange={setSearchText}
      />
      {searchText ? null : (
        <S.OrderFormatRow>
          <S.ButtonTab type="skip" selected={formatsToShow === 'all'} onClick={() => setFormatsToShow('all')}>
            {__('MagicOrders.Filters.all', { count: formats.all.length })}
          </S.ButtonTab>
          {formats.recent.length ? (
            <S.ButtonTab type="skip" selected={formatsToShow === 'recent'} onClick={() => setFormatsToShow('recent')}>
              {__('MagicOrders.Filters.recent', { count: formats.recent.length })}
            </S.ButtonTab>
          ) : null}
        </S.OrderFormatRow>
      )}
      <S.OrderFormatRow>
        {formatesFiltered.length ? (
          formatesFiltered.map((orderFormat: IFormat, idx: number) => {
            const { label, slug, logo_url, accepted_doc_types, tooltip_url } = orderFormat;
            const isSelected = slug === formatSelected;
            return (
              <S.OrderFormatContainer key={slug + idx}>
                <S.OrderFormatSquare
                  isSelected={isSelected}
                  onClick={() => {
                    setParentState({ formatSelected: slug, showAllFormats: false }, updateClientInformation);
                  }}
                >
                  <S.OrderFormatLogo src={logo_url} />
                  {isSelected ? (
                    <>
                      <S.OverlayGrey />
                      <S.CheckIconWrapper id="icon-check">
                        <FontIcon name="Check" disableHover={true} />
                      </S.CheckIconWrapper>
                    </>
                  ) : null}
                </S.OrderFormatSquare>
                {tooltip_url ? (
                  <S.TooltipIconWrapper>
                    <S.Tooltip
                      position={'bottom'}
                      container={container}
                      image={tooltip_url}
                      width="470px"
                      className="tooltip-importfile"
                      themeMode="dark"
                    >
                      <S.InfoIcon name="Info" disableHover={true} />
                    </S.Tooltip>
                  </S.TooltipIconWrapper>
                ) : null}
                <S.TextLight>{label}</S.TextLight>
                <S.Text>{orderService.getFormatTypeLabel(accepted_doc_types.join(',') || '')}</S.Text>
              </S.OrderFormatContainer>
            );
          })
        ) : (
          <S.CenterContainer>
            <EmptyListResource
              buttonText={__('MagicOrders.zero_formats.cta')}
              imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
              showButton={true}
              text={__('MagicOrders.zero_formats.title', { search: searchText })}
              text2={__('MagicOrders.zero_formats.subtitle')}
              buttonAction={() => navigateToAskFormat(searchText)}
            />
          </S.CenterContainer>
        )}
      </S.OrderFormatRow>
      {formatesFiltered.length ? (
        <>
          <S.RequestInfo>
            {formatTextColor(__('MagicOrders.ask'), colors.blue1, () => setGlobalState({ tabSelected: 'ask-format' }))}
          </S.RequestInfo>
          <S.ButtonHow
            iconName="Info"
            type="link"
            iconSize="22px"
            onClick={() => setGlobalState({ hideOrdersMagicZero: false })}
          >
            {__('MagicOrders.how')}
          </S.ButtonHow>
        </>
      ) : null}
    </>
  );
};

/**
 * Track a format search
 */
const trackFormatSearch = debounce((contact: IContact, search: string) => {
  if (search)
    EventTrack.track('import_orders_format_search', {
      contact_id: contact?.id,
      search,
    });
}, 500);

/*
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatTextColor(text: string, color: string, action?: () => void) {
  return text.split('**').map((s, index) =>
    index % 2 ? (
      <S.TextColor key={s + index} color={color} onClick={action}>
        {s}
      </S.TextColor>
    ) : (
      s
    ),
  );
}

const UploadOrders: React.FC<{
  catalogIdSelected: number;
  catalogs: { [id: number]: IWorkspace };
  client?: IClient;
  customConf?: ICustomConf;
  files: Array<File>;
  formats: Array<IFormat>;
  formatSelected: string;
  importOrders: () => void;
  disabled?: boolean;
  me: IUser;
  setParentState: (state: any) => void;
  setFiles: (files: Array<File>) => void;
  text: string;
}> = ({
  catalogs,
  client,
  catalogIdSelected,
  customConf = {},
  files,
  formats,
  formatSelected,
  importOrders,
  disabled,
  me,
  setParentState,
  setFiles,
  text,
}) => {
  const [mailCopied, setMailCopied] = React.useState(false);
  const orderFormatSelected = formatSelected ? formats.find(of => of.slug === formatSelected) : undefined;
  if (!orderFormatSelected) return null;
  const isTextFormat = orderFormatSelected.accepted_doc_types.join(',').includes('.txt');
  return (
    <>
      <FormatSelectedCard
        catalogIdSelected={catalogIdSelected}
        catalogs={catalogs}
        client={client}
        formats={formats}
        formatSelected={formatSelected}
        me={me}
        setParentState={setParentState}
      />
      {formatSelected === 'custom' ? (
        <S.CustomContainer>
          <S.TextSubtitle>{__('MagicOrders.customFormat.title')}</S.TextSubtitle>
          <S.TextLightGrey>{__('MagicOrders.customFormat.subtitle')}</S.TextLightGrey>
          <RowContainer margin="18px 0 0 0">
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.productCode')}>
              <Select
                name="productCode"
                value={customConf.productCode}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) => setParentState({ customConf: { ...customConf, productCode: v } })}
                containerMargin="4px 24px 4px 0"
                width="220px"
              />
            </InputWithLabel>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.description')}>
              <Select
                name="description"
                value={customConf.description}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) => setParentState({ customConf: { ...customConf, description: v } })}
                containerMargin="4px 0px"
                width="220px"
              />
            </InputWithLabel>
          </RowContainer>
          <RowContainer>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.quantity')}>
              <Select
                name="quantity"
                value={customConf.quantity}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) => setParentState({ customConf: { ...customConf, quantity: v } })}
                containerMargin="4px 24px 4px 0"
                width="220px"
              />
            </InputWithLabel>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.sellUnit')}>
              <Select
                name="sellUnit"
                value={customConf.sellUnit}
                options={AllProductUnitsArray.map(x => ({
                  value: x,
                  label: parsers.getUnitText(x, catalogs[catalogIdSelected].defaultWeightUnit),
                }))}
                onChange={(n, v) => setParentState({ customConf: { ...customConf, sellUnit: v } })}
                containerMargin="4px 0px"
                width="220px"
              />
            </InputWithLabel>
          </RowContainer>
          <InputWithLabel isRequired={false} label={__('MagicOrders.customFormat.priceUnit')}>
            <Select
              name="priceUnit"
              value={customConf.unitPrice}
              options={Array.from(Array(26))
                .map((e, i) => i + 65)
                .map(x => String.fromCharCode(x))}
              onChange={(n, v) => setParentState({ customConf: { ...customConf, unitPrice: v } })}
              containerMargin="4px 0 0 0"
              width="220px"
            />
          </InputWithLabel>
        </S.CustomContainer>
      ) : null}
      <S.UploadOrdersContainer>
        <S.TextSubtitleWithMargin>
          {isTextFormat ? __('Components.OrdersImport.import_title') : __('Components.OrdersImport.upload_orders')}
        </S.TextSubtitleWithMargin>
        {isTextFormat ? (
          <S.FileContainer>
            <FileDropzone
              files={files}
              formats={formats}
              formatSelected={formatSelected}
              importOrders={importOrders}
              setFiles={setFiles}
              disabled={disabled}
            />
            <TextArea text={text} importOrders={importOrders} setParentState={setParentState} disabled={disabled} />
          </S.FileContainer>
        ) : (
          <FileDropzone
            files={files}
            formats={formats}
            formatSelected={formatSelected}
            importOrders={importOrders}
            setFiles={setFiles}
            disabled={disabled}
          />
        )}
      </S.UploadOrdersContainer>

      {formatSelected === 'custom' ? null : (
        <S.UploadOrdersContainer>
          <S.TextSubtitleEmail>
            <S.InfoMailIcon name="Info" disableHover={true} />
            {__('MagicOrders.email_title')}
          </S.TextSubtitleEmail>
          {__('MagicOrders.email_description')
            .split('\n')
            .map(s => (
              <S.TextLight key={s}>{s}</S.TextLight>
            ))}
          <S.TextEmail>
            {client?.orderIncomingEmail}
            {mailCopied ? (
              <S.ButtonCopy type="skip" iconName="Check" withoutPadding={true} iconSize="16px">
                {__('Components.Header.LinkCopied')}
              </S.ButtonCopy>
            ) : (
              <S.ButtonCopy
                withoutPadding={true}
                iconName="Clipboard"
                type="link"
                iconSize="14px"
                onClick={async () => {
                  setMailCopied(true);
                  copyToClipboard(client.orderIncomingEmail);
                  EventTrack.track('import_orders_copy_email', {
                    contact_id: client?.userId,
                  });
                }}
              >
                {__('MagicOrders.copy')}
              </S.ButtonCopy>
            )}
          </S.TextEmail>
        </S.UploadOrdersContainer>
      )}
    </>
  );
};

const FormatSelectedCard: React.FC<{
  catalogIdSelected: number;
  catalogs: { [id: number]: IWorkspace };
  client?: IClient;
  formats: Array<IFormat>;
  formatSelected: string;
  me: IUser;
  setParentState: (state: any) => void;
}> = ({ catalogIdSelected, catalogs, client, formats, formatSelected, me, setParentState }) => {
  const orderFormat = formats.find(of => client?.orderIncomingKind && of.slug === client?.orderIncomingKind);
  const myRole = catalogs[catalogIdSelected].members.find(member => member.userId === me.id).role;
  const canIEdit = myRole !== 'viewer' ? true : false;
  if (!orderFormat) return null;
  const { label, slug, logo_url, accepted_doc_types } = orderFormat;
  const isSelected = slug === formatSelected;
  return (
    <S.OrderFormatSelectedContainer>
      <S.OrderFormatSquare
        isSelected={isSelected}
        onClick={() => setParentState({ formatSelected: slug, showAllFormats: false })}
      >
        <S.OrderFormatLogo src={logo_url.replace('w_250', 'w_100')} />
      </S.OrderFormatSquare>
      <S.OrderFormatSelectedRight>
        <S.OrderFormatTitle>{label}</S.OrderFormatTitle>
        <S.OrderFormatSubTitle>{orderService.getFormatTypeLabel(accepted_doc_types.join(',') || '')}</S.OrderFormatSubTitle>
        {canIEdit ? (
          <S.ButtonLink
            type="link"
            iconName="Edit"
            iconSize="15px"
            onClick={() => setParentState({ showAllFormats: true })}
            withoutPadding={true}
          >
            {__('MagicOrders.change_format')}
          </S.ButtonLink>
        ) : null}
      </S.OrderFormatSelectedRight>
    </S.OrderFormatSelectedContainer>
  );
};

/**
 * Render import order text area
 */
const TextArea: React.FC<{
  disabled: boolean;
  text: string;
  setParentState: (state: any) => void;
  importOrders: () => void;
}> = ({ disabled, text, importOrders, setParentState }) => {
  return (
    <S.TextAreaContainer>
      <S.TextArea
        name="import-text-area"
        onChange={e => setParentState({ text: e.target.value })}
        placeholder={__('Components.OrdersImport.insert_text')}
        rows={8}
        value={text}
        disabled={disabled}
      />
      <S.TextAreaFooter className="text-area-footer">
        <S.ButtonUpload onClick={importOrders} disabled={!text} type="principal" iconName="Share-web" iconSize="15px">
          {__('Components.OrdersImport.import')}
        </S.ButtonUpload>
      </S.TextAreaFooter>
    </S.TextAreaContainer>
  );
};

/**
 * Render files drop zone
 */
const FileDropzone: React.FC<{
  disabled: boolean;
  files: Array<File>;
  formats: Array<IFormat>;
  formatSelected: string;
  importOrders: () => void;
  setFiles: (files: Array<File>) => void;
}> = ({ disabled, files, formats, formatSelected, importOrders, setFiles }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const orderFormatSelected = formats.find(of => of.slug === formatSelected);
  if (!orderFormatSelected) return null;
  return (
    <>
      <Dropzone
        accept={orderFormatSelected.accepted_doc_types}
        multiple={true}
        onDrop={acceptedFiles => {
          if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
          } else {
            dispatch(
              notificationsActions.notificationShow(
                {
                  title: __('ProductEdit.additionalFiles.format_error.title'),
                  subtitle: __('ProductEdit.additionalFiles.format_error.description', {
                    formats: orderFormatSelected.accepted_doc_types,
                  }),
                  closable: true,
                  style: 'error',
                },
                6000,
              ),
            );
          }
        }}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input type="file" multiple={true} accept={orderFormatSelected.accepted_doc_types.join(',')} {...getInputProps()} />
              <S.UploadFileIcon name="Upload" />
              <S.TextUpload>
                {__('MagicOrders.add_orders')}
                {files.length ? (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('MagicOrders.orders_added', { count: files.length })}</S.TextUploadGreen>
                  </>
                ) : null}
              </S.TextUpload>
              <S.TextCenter>{__('MagicOrders.add_orders_description')}</S.TextCenter>
              <S.SelectButton type={files.length ? 'secondary' : 'principal'} disabled={disabled}>
                {files.length ? __('MagicOrders.select_more') : __('Components.OrdersImport.select')}
              </S.SelectButton>
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
      {files.length ? (
        <S.SelectButton type="principal" onClick={importOrders}>
          {__('MagicOrders.cta')}
        </S.SelectButton>
      ) : null}
    </>
  );
};
