import styled, { ITheme } from '../../../../styled-components';
import { Button, ColumnContainer, Input as InputAtom, LettersAvatar, RowContainer } from '../../../atoms';

interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  flex: 1;
  overflow: hidden;
`;
export const Input = styled(InputAtom)`
  max-width: 450px;
`;
export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
export const BoldText = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const GreyText = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  overflow: hidden;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FormCol = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0)};
`;

export const DeleteButton = styled(Button)`
  align-self: left;
`;

export const UserRow = styled(RowContainer)`
  align-items: center;
`;
export const UserColumn = styled(ColumnContainer)`
  align-items: flex-start;
`;

export const UserAvatar = styled(LettersAvatar)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
