import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
  hideOnIpad?: boolean;
}

export const Container = styled(ColumnContainer)`
  min-width: max-content;
  @media (max-width: 1325px) {
    ${(p: IStyledProps) =>
      p.hideOnIpad &&
      css`
        display: none;
      `};
  }
`;
export const Section = styled(RowContainer)`
  cursor: pointer;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: -6px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-right: ${(p: IStyledProps) => (p.isSelected ? '3px' : 0)} solid ${(p: IStyledProps) => p.theme.colors.black};
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.black : p.theme.colors.grey2)};
  min-width: 300px;
  max-width: 300px;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;
export const Icon = styled(FontIcon)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  font-size: 24px;
  color: inherit;
`;
export const Content = styled(ColumnContainer)`
  flex: 1;
`;
export const Title = styled.span`
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Semi : p.theme.fontWeight.Regular)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: inherit;
`;
export const Description = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const AlertIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: 1.125rem;
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
