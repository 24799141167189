import {
  __,
  date,
  DATE_TYPE,
  HOUR_FORMAT,
  IMember,
  IOrder,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
  utils,
} from 'common-services';
import { addDays } from 'date-fns';
import * as React from 'react';

import { getOrderSourceText } from '../../../../../constants';
import { DatePicker } from '../../../../atoms';
import { BubbleColor } from '../../../../atoms/TagBubble/TagBubble.component';
import * as SCart from '../../OrderDetails.styled';
import * as S from './OrderInfo.styled';

interface IProps {
  buyerName: string;
  companyName?: string;
  hourFormat: HOUR_FORMAT;
  membersAsClient?: Array<IMember>;
  myId: number;
  navigateToComments: () => void;
  noClientIssue: IOrderIssue;
  numComments: number;
  numUpdates: number;
  order: IOrder;
  orderDateFixIssue: typeof orderActions.orderDateFixIssue;
  previewMode?: boolean;
  sellerName: string;
  servedFlowEnabled: boolean;
  setShowClientsModal: (s: boolean) => void;
  weAreAuthor: boolean;
  weAreSeller: boolean;
}

const FORMAT_DATE = 'EEEE, dd MMMM yyyy';

const OrderInfo: React.FC<IProps> = ({
  buyerName,
  companyName,
  hourFormat,
  membersAsClient,
  myId,
  navigateToComments,
  noClientIssue,
  numComments,
  numUpdates,
  order,
  orderDateFixIssue,
  previewMode,
  sellerName,
  servedFlowEnabled,
  setShowClientsModal,
  weAreAuthor,
  weAreSeller,
}) => {
  const statusLabel = orderService.getOrderActionLiterals(order, weAreSeller, weAreAuthor, buyerName, sellerName);
  const { origin, id, status, createdAt, deliveryEstimatedAt } = order;
  let showStatusTag = !!id;
  if (previewMode && status === ORDER_STATUS.DRAFT) showStatusTag = false;

  const tagSection = (
    <S.TagContainer>
      {showStatusTag ? <S.Tag label={statusLabel.status} disabled={true} color={status as BubbleColor} /> : null}
      {origin !== ORDER_ORIGIN.INTERNAL ? <S.Tag label={getOrderSourceText(origin)} disabled={true} /> : null}
      {weAreSeller && order.sentToERP ? (
        <S.Tag label={__('Components.Cart.sent_to_erp')} disabled={true} color="sent" />
      ) : null}
    </S.TagContainer>
  );
  let creationDate: string;
  if (order.showHours) {
    creationDate =
      utils.capitalizeWords(date.formatDate(createdAt || '', FORMAT_DATE)) +
      ' · ' +
      date.formatTime(createdAt || '', hourFormat);
  } else {
    creationDate = utils.capitalizeWords(date.formatDate(createdAt || '', FORMAT_DATE));
  }

  let createdAtSection: JSX.Element = null;
  if (createdAt) {
    const issue = order.issues.find(i => i.type === 'date-not-valid' && i.code === 'order_date');
    createdAtSection = issue ? (
      <S.Text>
        {__('Components.Cart.creationDate')}
        <DatePicker
          id="creationDate"
          initDate={new Date(order.createdAt)}
          onDateChange={d => {
            orderDateFixIssue(myId, order.id, d, issue.id, false, DATE_TYPE.CREATED);
          }}
          customInput={
            <SCart.IssueText>
              {__('Components.Cart.date_order_issue')} <SCart.IssueIcon name="Down" />
            </SCart.IssueText>
          }
        />
      </S.Text>
    ) : (
      <S.Text>
        {__('Components.Cart.creationDate')}
        <S.TextBlack>{creationDate}</S.TextBlack>
      </S.Text>
    );
  }

  let editableUntil = null;
  if (servedFlowEnabled && !previewMode) {
    let dateFormatted = __('Components.Cart.acceptedOrder.noLimitDate');
    if (deliveryEstimatedAt) {
      const editableUntilDate = addDays(new Date(deliveryEstimatedAt), 7).getTime();
      dateFormatted = utils.capitalizeWords(date.formatDate(editableUntilDate, FORMAT_DATE));
    }
    editableUntil = (
      <>
        <S.Text>
          {__('Components.Cart.acceptedOrder.editableUntil')}
          <S.TextBlack>{dateFormatted}</S.TextBlack>
          <S.Tooltip
            position="right"
            text={__('Components.Cart.acceptedOrder.tooltip')}
            className="tooltip-add-line"
            themeMode="dark"
          >
            <S.InfoIcon name="Info" disableHover={true} />
          </S.Tooltip>
        </S.Text>
      </>
    );
  }

  const buyerAndSellerInfo = (
    <>
      <S.Text>
        {__('Components.Cart.buyer')}
        {noClientIssue ? (
          <>
            <S.IssueText onClick={() => setShowClientsModal(true)}>
              {__('Components.Cart.errors.no_client_code', { code: noClientIssue.code })}
            </S.IssueText>
          </>
        ) : (
          <S.TextBlack>
            {companyName ? __('Components.Cart.client_name', { name: buyerName, company: companyName }) : buyerName}
          </S.TextBlack>
        )}
        {membersAsClient?.length > 1 ? (
          <S.EditLink id="edit_transport_button" onClick={() => setShowClientsModal(true)} name="Edit" />
        ) : null}
      </S.Text>
      <S.Text>
        {__('Components.Cart.seller')}
        <S.TextBlack>{sellerName}</S.TextBlack>
      </S.Text>
    </>
  );

  const numCommentsAndUpdate = (
    <S.Shortcuts>
      <S.CommentsButton type="link" iconName="Comment" iconSize="16px" onClick={navigateToComments}>
        {__('Components.Cart.numComments', { count: numComments })}
      </S.CommentsButton>
      {numUpdates ? (
        <S.UpdatesButton type="link" iconName="Recent" iconSize="16px" onClick={navigateToComments}>
          {__('Components.Cart.updates', { count: numUpdates })}
        </S.UpdatesButton>
      ) : null}
    </S.Shortcuts>
  );

  return (
    <>
      {tagSection}
      {createdAtSection}
      {editableUntil}
      {buyerAndSellerInfo}
      {previewMode ? null : numCommentsAndUpdate}
    </>
  );
};

export default React.memo(OrderInfo);
