import {
  __,
  constants,
  currency as currencyService,
  CURRENCY_CODES,
  ITopProductsResponse,
  prodTypeSelectors,
  productService,
  utils,
} from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import * as SW from '../Widgets.styled';
import * as S from './TopProductsWidget.styled';

export interface IProps {
  className?: string;
  currency: CURRENCY_CODES;
  amSeller: boolean;
  data: ITopProductsResponse;
  limit: number;
  width?: string;
}

const TopProductsWidget: React.FC<IProps> = ({ amSeller, className, currency, data, limit, width }) => {
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const { products } = data;
  const productsToShow = products.slice(0, limit).sort((p1, p2) => p2.gmv - p1.gmv);
  const [productSelectedIdx, setProductSelectedIdx] = React.useState(0);

  if (!productsToShow.length) return null;
  const productSelected = productsToShow[productSelectedIdx];
  const productImages = getProductsImages();
  const percentage = productSelected ? (productSelected.percentage * 100).toFixed(1) : 0;
  return (
    <SW.Container width={width} className={className}>
      <S.HeaderContainer>
        <SW.TextTitle>
          {amSeller ? __('Widgets.TopProducts.title_sale') : __('Widgets.TopProducts.title_purchase')}
        </SW.TextTitle>
      </S.HeaderContainer>
      <S.BodyContainer>
        <S.GalleryCol>
          <S.TextGrey1>
            {amSeller
              ? __('Widgets.TopProducts.part_total_sales', { percentage })
              : __('Widgets.TopProducts.part_total_purchases', { percentage })}
          </S.TextGrey1>
          <S.ProductImageRow>
            <S.ChevronContainer onClick={() => handleChangeImageLeft()}>
              <S.ChevronIcon name="Back" />
            </S.ChevronContainer>
            <S.ProductImage src={productImages[productSelectedIdx]} />
            <S.ChevronContainer onClick={() => handleChangeImageRight()}>
              <S.ChevronIcon name="Right" />
            </S.ChevronContainer>
          </S.ProductImageRow>
        </S.GalleryCol>
        <S.ProductsCol>
          {productsToShow.map((product, idx) => {
            const { gmv, productId, type, variety } = product;
            const isSelected = idx === productSelectedIdx;
            const gmvProduct = currencyService.getPriceAbbreviation(currency, gmv);

            return (
              <S.ProductNameCol key={idx + '_' + productId} onClick={() => setProductSelectedIdx(idx)}>
                <S.TextProduct isSelected={isSelected}>
                  {product.productTitle
                    ? utils.firstToUpperCase(product.productTitle)
                    : productService.getProductTypeVarietyDisplay(
                        type,
                        prodTypes[type] ? prodTypes[type].name : '',
                        variety,
                      )}
                </S.TextProduct>
                <S.TextGMV isSelected={isSelected}>{gmvProduct}</S.TextGMV>
              </S.ProductNameCol>
            );
          })}
        </S.ProductsCol>
      </S.BodyContainer>
    </SW.Container>
  );

  /**
   * Get products images
   */
  function getProductsImages() {
    const result = [];
    productsToShow.forEach(product => {
      const { imageUrl, type } = product;
      if (imageUrl) result.push(imageUrl);
      else if (prodTypes[type] && prodTypes[type].defaultImageUrl) result.push(prodTypes[type].defaultImageUrl);
      else result.push(constants.DEFAULT_IMAGE_URL);
    });
    return result;
  }

  /**
   * Handle click on chevron left
   */
  function handleChangeImageLeft() {
    let newIndex = productSelectedIdx - 1;
    if (newIndex < 0) newIndex = productsToShow.length - 1;
    setProductSelectedIdx(newIndex);
  }

  /**
   * Handle click on chevron right
   */
  function handleChangeImageRight() {
    let newIndex = productSelectedIdx + 1;
    if (newIndex > productsToShow.length - 1) newIndex = 0;
    setProductSelectedIdx(newIndex);
  }
};

export default React.memo(TopProductsWidget);
