import {
  __,
  debounce,
  EventTrack,
  INVITE_ORIGIN,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userActions,
  utils,
  WORKING_STATUS,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as userWebActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import { getDefaultColumns, ROUTE_PATHS } from '../../../constants';
import SelectContacts from '../../../screens/select-contacts';
import getPath from '../../../util/routes';
import { ColumnContainer, Select } from '../../atoms';
import { EmptyListResource, FormSection, InputWithLabel, Table } from '../../molecules';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceColleagues.styled';

export interface IStateProps {
  channels: Record<string, IChannel>;
  contacts: { [contactId: number]: IContact };
  me: IUser;
  workspace: IWorkspace;
}

export interface IDispatchProps {
  catalogMemberDelete: typeof sellerWorkspaceActions.catalogMemberDelete;
  catalogMembersUpdate: typeof sellerWorkspaceActions.catalogMembersUpdate;
  catalogUpdate: typeof sellerWorkspaceActions.catalogUpdate;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelByPath: (path: ROUTE_PATHS, channelId: string, cb: (path: string) => void) => void;
  notificationShow: typeof notificationsActions.notificationShow;
  requestProInfo: typeof userActions.requestProInfo;
  supportAction: typeof userWebActions.supportAction;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string }>;

interface IState {
  members: Array<ICatalogMember>;
  newRole: ICatalogRole;
  searchText: string;
  showAddMembers?: boolean;
}

class WorkspaceColleagues extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      members: props.workspace?.members || [],
      newRole: 'viewer',
      searchText: '',
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceColleagues', { renderTime: this.t });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { workspace } = this.props;
    if (prevProps.workspace !== workspace && workspace) {
      this.setState({ members: workspace.members });
    }
  }

  public render() {
    const { workspace, history, location, match, me, modalOpen, requestProInfo, supportAction } = this.props;
    const { showAddMembers } = this.state;
    const role = sellerWorkspaceService.getRole(workspace, me.id);
    const amAdmin = workspace && role === 'admin';
    const isPro = workspace ? sellerWorkspaceService.isProPlan(workspace.plan) || workspace?.type === 'buyer' : false;
    return (
      <>
        <Workspace
          isBuyerWorkspace={workspace?.type === 'buyer'}
          parentSections={[
            {
              label: __('Components.Header.WorkspaceSettings'),
              action: () => {
                history.push(
                  getPath({
                    path:
                      workspace?.type === 'buyer'
                        ? ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER
                        : ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER,
                    workspaceId: workspace.id + '',
                    tab: 'team',
                  }),
                );
              },
            },
          ]}
          subtitle=""
          tabSelected={workspace?.type === 'buyer' ? 'settings-buyer' : 'settings-seller'}
          title={__('WorkspaceColleagues.title')}
          workspaceId={workspace?.id}
        >
          <S.Container className="workspace-colleagues-container">
            <S.BodyContainer className="workspace-colleagues-body-container">
              <FormSection
                title={__('WorkspaceColleagues.title')}
                subtitle={
                  amAdmin
                    ? workspace?.type === 'buyer'
                      ? __('WorkspaceColleagues.subtitle_buyer')
                      : __('WorkspaceColleagues.subtitle_seller', { count: workspace?.plan.numLicenses || 1 })
                    : __('WorkspaceColleagues.subtitle_viewer')
                }
                subtitleAction={supportAction}
              >
                <S.TextBold>
                  {workspace?.type === 'buyer'
                    ? __('WorkspaceColleagues.colleagues_added_buyer', { count: workspace?.members.length || 1 })
                    : __('WorkspaceColleagues.colleagues_added_seller', {
                        count: workspace?.members.length || 1,
                        total: workspace?.plan.numLicenses || 1,
                      })}
                </S.TextBold>

                <S.SearchRow>
                  <S.SimpleSearch
                    onChange={this.onSearch}
                    placeHolder={__('WorkspaceColleagues.search_placeholder')}
                    id="input_search_workspace_colleagues"
                  />
                  {amAdmin ? (
                    <S.AddMembersButton
                      type="principal"
                      id="workspace-members-cta-create"
                      onClick={() => {
                        if (!isPro) {
                          modalOpen(
                            'teams',
                            () => requestProInfo(me.id, workspace.id, 'pricelist'),
                            {},
                            'consentiopro',
                          );
                          return;
                        }
                        this.setState({ showAddMembers: true });
                      }}
                    >
                      {__('Components.ProductsList.AddPartners')}
                    </S.AddMembersButton>
                  ) : null}
                </S.SearchRow>
                {this.renderTable(amAdmin)}
              </FormSection>
            </S.BodyContainer>
          </S.Container>
        </Workspace>
        {showAddMembers ? this.renderAddMembers(workspace) : null}
      </>
    );
  }

  /**
   * Renders table if there is at least one order
   */
  private renderTable(amAdmin: boolean) {
    const { searchText } = this.state;
    const { workspace } = this.props;
    if (!workspace?.members?.length) return null;
    const members = this.getMembersToShow();
    if (searchText && !members.length) {
      return (
        <EmptyListResource
          text={__('Components.ProductsList.QueryEmptyResults', { search: searchText })}
          text2={__('Components.ProductsList.EmptyResultsTryAgain', { search: searchText })}
          showButton={false}
          imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
        />
      );
    }
    return (
      <Table
        className="colleagues-table"
        productColumns={getDefaultColumns()}
        columns={this.getColumns(amAdmin)}
        emptyText=""
        isReadRow={() => true}
        onClickRow={() => null}
        rowCursor="inherit"
        selectable={false}
        values={members}
      />
    );
  }

  /**
   * Get members to show filtered by search and sorted alphabetically
   */
  private getMembersToShow = () => {
    const { contacts, me } = this.props;
    const { members, searchText } = this.state;

    let result = members;
    if (searchText.length > 1) {
      result = members.filter(m => {
        const filter = utils.toLocaleLowerCaseNormalized(searchText);
        const name = m.userId === me.id ? me.name : contacts[m.userId]?.name;
        const email = m.userId === me.id ? me.email : contacts[m.userId]?.email;
        const phoneNumber = m.userId === me.id ? `${me.country}${me.phone}` : contacts[m.userId]?.phoneNumbers?.[0];
        return (
          utils.toLocaleLowerCaseNormalized(name).includes(filter) ||
          utils.toLocaleLowerCaseNormalized(email).includes(filter) ||
          utils.toLocaleLowerCaseNormalized(phoneNumber).includes(filter)
        );
      });
    }
    return result.sort((a, b) => {
      if (a.role === 'admin') return -1;
      if (b.role === 'admin') return 1;
      const nameA = a.userId === me.id ? me.name : contacts[a.userId]?.name;
      const nameB = b.userId === me.id ? me.name : contacts[b.userId]?.name;
      return nameA < nameB ? -1 : 1;
    });
  };

  private getColumns(amAdmin: boolean) {
    const { contacts, me } = this.props;
    return [
      {
        title: '',
        id: 'delete',
        width: '50px',
        element: (member: ICatalogMember) => {
          return member.role === 'admin' || !amAdmin ? null : (
            <S.RemoveIcon name="Close" onClick={() => this.removeMember(member.userId)} />
          );
        },
      },
      {
        title: __('WorkspaceColleagues.table.colleague'),
        id: 'colleague',
        element: (member: ICatalogMember) => {
          const isMyself = member.userId === me.id;
          const memberContact = isMyself ? undefined : contacts[member.userId];
          const name = isMyself ? me.name : contacts[member.userId]?.name;
          const avatar = isMyself ? me.settings.avatar : contacts[member.userId]?.avatar;
          const workingStatus = isMyself ? me.settings.workingStatus : contacts[member.userId]?.workingStatus;
          return (
            <S.UserRow>
              <S.UserAvatar
                text={name || ''}
                img={avatar}
                avatarColor={utils.getAvatarColor(name)}
                size={32}
                workingStatus={workingStatus || WORKING_STATUS.AVAILABLE}
                bubbleSize={12}
              />
              <ColumnContainer>
                <S.Text>{name + (isMyself ? ` (${__('Components.Chat.you')})` : '')}</S.Text>
                {isMyself || !memberContact ? null : (
                  <S.TextLink onClick={() => this.navigateToChat(member.userId)}>
                    {__('ClientsList.go_to_chat')}
                  </S.TextLink>
                )}
              </ColumnContainer>
            </S.UserRow>
          );
        },
      },
      {
        title: __('WorkspaceColleagues.table.permissions'),
        id: 'permissions',
        width: '200px',
        element: (member: ICatalogMember) => {
          const isMyself = member.userId === me.id;
          return (
            <>
              {amAdmin ? (
                isMyself ? (
                  <S.TextGrey1>{__('Constants.CatalogRoles.author')}</S.TextGrey1>
                ) : (
                  <Select
                    isSearchable={false}
                    name={`select-role-${member.userId}`}
                    value={member.role}
                    onChange={(name, val) => this.updateRole(member.userId, val as ICatalogRole)}
                    options={[
                      { value: 'viewer', label: __('Constants.CatalogRoles.viewer') },
                      { value: 'editor', label: __('Constants.CatalogRoles.editor') },
                    ]}
                    maxWidth="180px"
                    containerMargin="0"
                  />
                )
              ) : (
                <S.TextGrey1>{this.getMemberRoleLiteral(member.role)}</S.TextGrey1>
              )}
            </>
          );
        },
      },
    ];
  }

  /**
   * Get member's role literal
   */
  private getMemberRoleLiteral = (role: ICatalogRole) => {
    switch (role) {
      case 'admin':
        return __('Constants.CatalogRoles.author');
      case 'editor':
        return __('Constants.CatalogRoles.editor');
      default:
        return __('Constants.CatalogRoles.viewer');
    }
  };

  /**
   * Render modal for add members
   */
  private renderAddMembers = (workspace: IWorkspace): JSX.Element => {
    const { me, contacts, catalogMembersUpdate } = this.props;
    const { members, newRole } = this.state;
    const contactAddedIds = members.map(m => m.userId);
    const contactsNotAdded = Object.values(contacts).filter(c => !contactAddedIds.includes(c.id));
    return (
      <SelectContacts
        onClose={() => this.setState({ showAddMembers: false })}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          const membersToAdd: Array<ICatalogMember> = contactsToAdd.map(userId => ({
            userId,
            catalogId: workspace.id,
            status: 'active' as 'active',
            role: newRole,
          }));
          EventTrack.track('workspace_colleague_add', {
            workspace_id: workspace.id,
            members_count: membersToAdd.length,
          });
          const membersBeforeAdd = members.slice();
          this.setState({ members: [...members, ...membersToAdd], showAddMembers: false });
          catalogMembersUpdate(me.id, workspace.id, membersToAdd, err => {
            if (err) this.setState({ members: membersBeforeAdd });
          });
        }}
        origin={INVITE_ORIGIN.WORKSPACE}
        title={__('Catalog.add_members.title')}
        ExtraInputs={() => (
          <InputWithLabel isRequired={true} label={__('Catalog.add_members.role_label')}>
            <Select
              isSearchable={false}
              name="memberRole"
              value={newRole}
              onChange={(name, val) => this.setState({ newRole: val as ICatalogRole })}
              options={[
                { value: 'viewer', label: __('Constants.CatalogRoles.viewer') },
                { value: 'editor', label: __('Constants.CatalogRoles.editor') },
              ]}
              width="auto"
              maxWidth="200px"
              containerMargin="10px 0 0 0"
            />
          </InputWithLabel>
        )}
        trackingFrom="workspace-colleagues"
      />
    );
  };

  /**
   * Navigate to a chat
   */
  private navigateToChat = (contactId: number) => {
    const { channels, navigateChannelByPath, history } = this.props;
    const channelToShow = Object.values(channels).find(
      chan => chan.type === 'private' && chan.members.find(cm => cm.id === contactId),
    );
    if (channelToShow) navigateChannelByPath(ROUTE_PATHS.CHAT, channelToShow.id, (path: string) => history.push(path));
  };

  /**
   * Update a member's role
   */
  private updateRole = (userId: number, r: ICatalogRole) => {
    const { catalogMembersUpdate, me, workspace } = this.props;
    const { members } = this.state;
    EventTrack.track('workspace_colleague_update', {
      workspace_id: workspace.id,
      member_id: userId,
      new_role: r,
    });
    const membersBeforeUpdate = [...members];
    const membersWithRoleUpdated = membersBeforeUpdate.map(m => {
      if (m.userId === userId) return { ...m, role: r };
      return m;
    });
    this.setState({ members: membersWithRoleUpdated });
    catalogMembersUpdate(me.id, workspace.id, [{ ...members.find(m => m.userId === userId), role: r }], err => {
      if (err) this.setState({ members: membersBeforeUpdate });
    });
  };

  /**
   * Remove a workspace member with confirmation modal
   */
  private removeMember = (userId: number) => {
    const { contacts, catalogMemberDelete, modalOpen, me, workspace } = this.props;
    const { members } = this.state;
    modalOpen(
      __('Catalog.update_members.remove', contacts[userId]),
      () => {
        EventTrack.track('workspace_colleague_delete', {
          workspace_id: workspace.id,
          member_id: userId,
        });
        const membersBeforeDelete = [...members];
        this.setState({ members: membersBeforeDelete.filter(m => m.userId !== userId) });
        catalogMemberDelete(me.id, workspace.id, userId, err => {
          if (err) this.setState({ members: membersBeforeDelete });
        });
      },
      {
        showCancelButton: true,
        buttonText: __('Components.ProductsList.Remove'),
        actionType: 'dangerous',
      },
      'nice',
    );
  };

  /**
   * On Search set state debounced
   */
  private onSearch = debounce(search => this.setState({ searchText: search }), 250);
}

export default WorkspaceColleagues;
