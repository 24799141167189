import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  isFirst?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  overflow: hidden;
  padding: 0px 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.5)')};
`;

export const OptionItem = styled.span<IStyledProps>`
  display: block;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.white : p.theme.colors.transparent)};
  padding: 4px 10px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: ${(p: IStyledProps) => (p.isSelected ? 1.0 : 0.8)};
  }
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Text = styled(TextLabel)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.black : p.theme.colors.grey2)};
  text-align: center;
`;
