import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  color?: 'red' | 'blue' | 'green' | 'grey';
  count?: number;
  size?: number;
  theme: ITheme;
  selected? : boolean;
}

function getBadgeWidth(p: IStyledProps) {
  if (p.count < 10) return `${p.size}px`;
  if (p.count < 100) return `${Math.min(p.size + 4, 22)}px`;
  if (p.count < 1000) return `${Math.min(p.size + 4, 26)}px`;
  return '30px';
}

export const Badge = styled.div`
  position: relative;
  align-self: center;
  &:after {
    content: '${(p: IStyledProps) => (p.count >= 1000 ? '999+' : p.count)}';
    width: ${getBadgeWidth};
    height: ${(p: IStyledProps) => p.size}px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
    line-height: 1;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
    background-color: ${(p: IStyledProps) => (p.color === 'red' ? p.theme.colors.red1 : p.theme.colors.blue1)};
    color: ${(p: IStyledProps) => p.theme.colors.white};
    
    display: ${(p: IStyledProps) => (p?.count > 0 ? 'flex' : 'none')};
  }
`;


export const BadgeCart = styled(Badge)`
  &:after {
    text-align: center;
    padding: 4px 2px 2px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    background-color: ${(p: IStyledProps) => (p.selected === true ? p.theme.colors.white : p.theme.colors[`${p.color}1`])};
    color: ${(p: IStyledProps) => (p.selected === false ? p.theme.colors.white : p.theme.colors[`${p.color}1`])};
    display: flex;
  }
`;
export const CirclePoint = styled.div`
  height: ${(p: IStyledProps) => p.size}px;
  width: ${(p: IStyledProps) => p.size}px;
  border-radius: 50%;

  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  background-color: ${(p: IStyledProps) => (p.color === 'red' ? p.theme.colors.red1 : p.theme.colors.blue1)};
`;
