import styled, { css, ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  FontIcon,
  LettersAvatar,
  RowContainer,
  SimpleDropdown,
  TextArea,
  TextLabel,
} from '../../atoms';
import { getReactionColor } from '../PostMessage/Fragments/Reactions.styled';

interface IStyledProps {
  hasReactions?: boolean;
  reaction?: PostReaction;
  seeMore?: boolean;
  showSeeMore?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-self: flex-start;
  min-height: min-content;
  position: relative;
`;

export const Header = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const Avatar = styled(LettersAvatar)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const HeaderContent = styled(ColumnContainer)`
  overflow: hidden;
  min-height: 33px;
`;

export const RowName = styled(RowContainer)`
  align-items: center;
  padding-bottom: 1px;
`;

export const RoleBubble = styled.span<IStyledProps>`
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  border-radius: 3px;
  padding: 1px 3px;
  line-height: 15px;
`;

export const ContentBody = styled(RowContainer)`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TextName = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 22px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p: IStyledProps) =>
    p.showSeeMore &&
    !p.seeMore &&
    css`
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    `}
`;

export const TextBold = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 18px;
`;

export const TextGreySmall = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) => (p.reaction ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${(p: IStyledProps) => (p.reaction ? getReactionColor(p) : p.theme.colors.grey1)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  line-height: 15px;
  padding-right: 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const TextMiddlePoint = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  line-height: 15px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Link = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  line-height: 22px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  :visited {
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const BottomActions = styled(RowContainer)<IStyledProps>`
  width: 100%;
  justify-content: space-between;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SeeMoreContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-left: 2px;
  justify-content: center;
  height: 22px;
`;

export const TextLink = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-align: right;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const OptionsDropDown = styled(SimpleDropdown)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize()};
  right: -3px;
`;

export const KebabIcon = styled(FontIcon)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextAreaInput = styled(TextArea)`
  margin-top: 2px;
`;

export const EditInputContainer = styled(ColumnContainer)``;

export const EditActionsContainer = styled(RowContainer)`
  button:first-child {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const EditAction = styled(Button)``;

export const ReactionsRow = styled(RowContainer)`
  align-items: center;
`;

export const ReactionsPickerRow = styled(RowContainer)`
  align-items: center;
  position: relative;

  &:hover {
    .comment-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-32%);
  z-index: 1;
  min-width: max-content;
  margin: 0;
  min-height: min-content;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.6s ease-in-out;

  &:hover {
    .comment-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const PopupPreview = styled(Popup)`
  top: -45px;
`;

export const PreviewContainer = styled(RowContainer)`
  &:hover {
    .comment-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
  position: relative;
`;
