import { __, sellerWorkspaceService } from 'common-services';
import { History, Location } from 'history';
import * as React from 'react';

import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { FontIcon, SimpleDropdown, WorkspaceAvatar } from '../../atoms';
import * as S from './SelectWorkspaceModal.styled';

interface IProps {
  catalogs: Array<IWorkspace>;
  contacts: { [key: number]: IContact };
  item: IOrderItem;
  me: IUser;
  newOrExistingLot: string;
  openProductSelectionModal: () => void;
  close: () => void;
  history: History<any>;
  location: Location<any>;
}

interface IState {
  selectedWorkspace: IWorkspace;
}

class SelectWorkspaceModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedWorkspace: props.catalogs[0],
    };
  }
  public render() {
    const { catalogs, contacts, item, me, newOrExistingLot, openProductSelectionModal, close, history } = this.props;
    const { selectedWorkspace } = this.state;
    return (
      <S.Modal
        title={
          newOrExistingLot === 'new'
            ? __('Components.ProductDetails.lot.modal.new.title')
            : __('Components.ProductDetails.lot.modal.existing.title')
        }
        onClose={close}
        subtitle={''}
      >
        <S.Container>
          <S.ModalText>
            {newOrExistingLot === 'new'
              ? __('Components.ProductDetails.lot.modal.new.text')
              : __('Components.ProductDetails.lot.modal.existing.text')}
          </S.ModalText>
          <S.BoldText>{__('Components.ProductDetails.lot.modal.select')}</S.BoldText>
          {catalogs.length > 1 ? (
            <SimpleDropdown
              vAlign="flex-start"
              onSelect={(workspaceId: string) => this.handleSelectWorkspace(workspaceId)}
              options={catalogs.map(c => ({
                key: c.id + '',
                value: sellerWorkspaceService.getCatalogName(c, contacts, me),
                avatar: (
                  <WorkspaceAvatar
                    catalog={c}
                    showActiveIcon={true}
                    isActive={sellerWorkspaceService.isActive(c, me.id)}
                  />
                ),
              }))}
            >
              <S.WorkspaceSelected>
                <S.ActiveIndicator
                  isActive={sellerWorkspaceService.isActive(
                    catalogs.find(c => c.id === selectedWorkspace?.id),
                    me.id,
                  )}
                />
                <WorkspaceAvatar catalog={selectedWorkspace} />
                <S.WorkspaceSelectedColumn>
                  <S.WorkspaceSelectedLabel>{selectedWorkspace?.name}</S.WorkspaceSelectedLabel>
                  <S.WorkspaceSelectedValue>
                    {sellerWorkspaceService.getCatalogName(selectedWorkspace, contacts, me)}
                  </S.WorkspaceSelectedValue>
                </S.WorkspaceSelectedColumn>
                <S.DownIconWrapper>
                  <FontIcon name="Down" />
                </S.DownIconWrapper>
              </S.WorkspaceSelected>
            </SimpleDropdown>
          ) : null}
          <S.ButtonRow>
            <S.ModalCta onClick={() => close()} type="secondary">
              {__('Components.ProductDetails.lot.modal.cancel')}
            </S.ModalCta>
            {newOrExistingLot === 'new' ? (
              <S.ModalCta
                id="poducts-list-cta-create"
                onClick={() =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                      workspaceId: selectedWorkspace.id + '',
                      productId: 'new',
                    }) + `?originLot=${item.id}`,
                  )
                }
              >
                {__('Components.ProductsList.CreateProduct')}
              </S.ModalCta>
            ) : (
              <S.SelectProductCta onClick={openProductSelectionModal}>
                {__('Components.ProductDetails.lot.modal.existing.cta')}
              </S.SelectProductCta>
            )}
          </S.ButtonRow>
        </S.Container>
      </S.Modal>
    );
  }

  /**
   * Saves selected workspace on SelectWorkspaceModal's state and OrderItem's state
   */
  private handleSelectWorkspace(workspaceId: string) {
    const { catalogs } = this.props;
    const myWorkspace = catalogs.find(c => c.id === Number(workspaceId));

    this.setState({ selectedWorkspace: myWorkspace });
  }
}

export default SelectWorkspaceModal;
