import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import FontIcon from '../FontIcon';

interface IStyledProps {
  color?: string;
  hAlign?: 'right' | 'left';
  isLast?: boolean;
  isLink?: boolean;
  itemMinWidth?: string;
  margin?: string;
  numOptions?: number;
  position?: 'bottom' | 'top';
  theme: ITheme;
  vAlign?: 'center' | 'flex-start' | 'flex-end';
  width?: string;
  isSelected?: boolean;
  noPadding?: boolean;
}

export const Container = styled.div`
  padding: 0;
  align-self: ${(p: IStyledProps) => p.vAlign || 'center'};
  position: relative;
  margin: ${(p: IStyledProps) => p.margin || 0};
  ${(p: IStyledProps) =>
    p.width &&
    css`
      width: ${p.width};
    `}
`;

export const Trigger = styled.div`
  margin: 0;
  padding: 0;
  align-self: center;
  position: relative;
`;

export const Content = styled.ul<IStyledProps>`
  overflow: auto;
  max-height: 350px;
  position: absolute;
  ${(p: IStyledProps) =>
    p.position === 'top' &&
    css`
      top: calc(-5px - ${p.numOptions} * 30px);
    `}
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: ${(p: IStyledProps) => p.itemMinWidth || 'max-content'};
  min-width: 100%;
  ${(p: IStyledProps) =>
    !p.noPadding &&
    css`
      padding: 3px 0;
    `}
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  left: ${(p: IStyledProps) => (p.hAlign === 'left' ? 0 : 'inherit')};
  right: ${(p: IStyledProps) => (p.hAlign === 'right' ? 0 : 'inherit')};
  z-index: 9;
`;

export const Item = styled.li<IStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-width: ${(p: IStyledProps) => p.itemMinWidth || 'max-content'};
  width: 100%;
  text-align: ${(p: IStyledProps) => p.hAlign};
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ItemText = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const ItemTitle = styled.div<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.color || (p.isLink ? p.theme.colors.blue1 : p.theme.colors.black)};
`;

export const ItemSubtitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const IconWrapper = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  position: absolute;
  top: -5px;
  right: 0;
`;

export const IconItem = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: 15px;
  height: 15px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p: IStyledProps) =>
    p.color &&
    css`
      color: ${p.color};
    `}
`;

export const ImageItem = styled.div`
  width: 15px;
  height: 15px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarItem = styled.div`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  align-self: center;
`;

export const InfoIcon = styled(FontIcon)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;