import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';
import RadioInput from '../../atoms/RadioInput';

interface IStyledProps {
  theme: ITheme;
  isAdmin?: boolean;
  isLast?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

export const ContactItem = styled(RowContainer)`
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  height: 64px;
  border-bottom: 1px solid ${(p: IStyledProps) => (p.isLast ? p.theme.colors.transparent : p.theme.colors.light1)};
  cursor: ${(p: IStyledProps) => (p.disabled ? 'auto' : 'pointer')};
  &:hover {
    opacity: 0.9;
  }
`;
export const TextContent = styled(ColumnContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingObject};
  flex: 1;
  max-width: calc(100% - 127px);
`;
export const Title = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => (p.isAdmin ? '4px' : 0)};
`;
export const SubTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const RadioButton = styled(RadioInput)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
`;
export const EmptyCheckBox = styled.div`
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  width: 40px;
`;
export const Admin = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;
export const Group = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-right: 5px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-height: 25px;
`;

export const Broadcast = styled(Group)`
  background: ${(p: IStyledProps) => p.theme.colors.red1.replace('1)', '0.6)')};
`;

export const AdminText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 1px 5px 0;
`;
export const Row = styled(RowContainer)``;
export const Date = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
