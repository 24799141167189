import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
}
const paddingCardVertical = '32px';

export const PageModalContainer = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.light1.replace('1)', '0.92)')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Container = styled.div`
  max-width: 600px;
  min-width: 600px;
  height: 100%;
`;

export const Card = styled(ColumnContainer)`
  padding: ${paddingCardVertical} ${(p: IStyledProps) => p.theme.paddingView};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
  margin: ${(p: IStyledProps) => p.theme.paddingView};
`;

export const Title = styled(TextLabel)`
  width: auto;
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-transform: uppercase;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingView};
  padding-bottom: 30px;
`;

export const TextContainer = styled.div`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const TextRegular = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextBold = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TextLink = styled.a`
  color: ${(p: IStyledProps) => p.theme.colors.primary};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    opacity: 0.75;
  }
`;

export const ImageLogo = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

export const ImageIELogoPosition = styled.div`
  position: absolute;
  top: 25px;
  left: ${(p: IStyledProps) => p.theme.paddingView};
`;
export const ImageIELogo = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const RowCenterContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-around;
  margin: auto;
  padding-top: ${(p: IStyledProps) => p.theme.paddingObject};
  width: 75%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingView};
  top: 25px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
