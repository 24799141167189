import { __, date, orderActions, RenderTrack, to } from 'common-services';
import { History, Location } from 'history';
import * as React from 'react';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import { OrderDetails } from '../../../screens';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button } from '../../atoms';
import { FilterButton, ImportJob, OrderImportWidget, SimpleSearch, ZeroCase } from '../../molecules';
import * as S from './OrderMagicList.styled';

interface IProps {
  allFormats: Array<IFormat>;
  catalogs: { [id: number]: IWorkspace };
  clearFilters: () => void;
  clientId?: number;
  contacts: { [contactId: number]: IContact };
  clients?: { [catalogId: number]: Array<IClient> };
  suppliers?: { [id: number]: Array<ISupplier> };
  dateRange: IDateRange;
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  filtersCount: number;
  hashId?: string;
  hideFilters: boolean;
  hideFilterButton?: boolean;
  history?: History<any>;
  importJobs?: Array<IImportJob>;
  location: Location<unknown>;
  match: {
    path: string;
    params: { channelId?: string; workspaceId?: string; jobId?: string; hashId?: string };
  };
  me: IUser;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  orderImportWidgetsGet: typeof orderActions.orderImportWidgetsGet;
  searchState: ISearchImportJob;
  setParentState: (state: any) => void;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  isEdi?: boolean;
  onOpenOrder?: (jobId: number, hashId: string) => void;
  onOpenJob?: (jobId?: number) => void;
}
interface IState {
  orderSelected?: IOrder;
  widgetsResponse?: IOrderImportWidgetsResponse;
  importJobSelected?: IImportJob;
}

export default class OrderMagicList extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props) {
    super(props);
    this.t = Date.now();
    this.state = {};
  }

  public async componentDidMount() {
    const {
      dateRange,
      me,
      orderImportWidgetsGet,
      clientId,
      match: { params },
      importJobs,
    } = this.props;
    const { importJobSelected } = this.state;
    RenderTrack.track('OrderMagicList', { renderTime: this.t, contact_id: clientId });
    orderImportWidgetsGet(
      me.id,
      dateRange === 'all' ? { beginDate: new Date(0), endDate: new Date() } : date.getDatesFromRange(dateRange),
      (data: IOrderImportWidgetsResponse) => {
        if (data) this.setState({ widgetsResponse: data });
      },
    );
    if (!params?.hashId && params?.jobId) {
      const importJob = importJobSelected || importJobs.find(ij => ij.id === Number(params?.jobId));
      if (!importJob) {
        const { data } = await to(api.orderImport.getImportJob(me.id, Number(params?.jobId)));
        this.setState({ importJobSelected: data });
      } else {
        this.setState({ importJobSelected: importJob });
      }
    }
  }

  public async componentDidUpdate(prevProps: IProps) {
    const {
      dateRange,
      me,
      orderImportWidgetsGet,
      clientId,
      searchState,
      match: { params },
      importJobs,
    } = this.props;
    const { orderSelected, importJobSelected } = this.state;
    const { dateRange: prevDateRange, searchState: prevSearchState, clientId: prevClientId } = prevProps;

    const { beginDate, endDate } = searchState;
    const { beginDate: prevBeginDate, endDate: prevEndDate } = prevSearchState;

    if (
      prevClientId !== clientId ||
      prevDateRange !== dateRange ||
      prevBeginDate !== beginDate ||
      prevEndDate !== endDate
    ) {
      const dates =
        dateRange === 'custom'
          ? { beginDate, endDate }
          : date.getDatesFromRange(dateRange === 'all' ? 'year' : dateRange);
      orderImportWidgetsGet(me.id, dates, (data: IOrderImportWidgetsResponse) => {
        if (data) this.setState({ widgetsResponse: data });
      });
    }

    if (params?.jobId && params?.jobId !== prevProps.match.params?.jobId) {
      const importJob =
        importJobSelected && importJobSelected.id === Number(params?.jobId)
          ? importJobSelected
          : importJobs.find(ij => ij.id === Number(params?.jobId));
      if (!importJob) {
        const { data } = await to(api.orderImport.getImportJob(me.id, Number(params?.jobId)));
        this.setState({ importJobSelected: data });
      } else {
        this.setState({ importJobSelected: importJob });
      }
    }
    if (!params?.hashId && params?.hashId !== orderSelected?.hashId) {
      this.setState({ orderSelected: undefined });
    }
    if (!params?.jobId) {
      this.setState({ importJobSelected: undefined });
    }
  }

  public render() {
    const {
      filtersCount,
      hideFilters,
      hideFilterButton,
      me,
      importJobs,
      allFormats,
      catalogs,
      clientId,
      contacts,
      clients,
      suppliers,
      downloadImportJobFile,
      orderOriginalFileDownload,
      onOpenJob,
      onScroll,
      setParentState,
      isEdi,
    } = this.props;
    const { orderSelected, importJobSelected } = this.state;
    const hasImportJobs = (importJobs || []).length;
    return (
      <>
        <S.ListContainer onScroll={onScroll}>
          {hasImportJobs ? this.renderWidgets() : null}
          {!hideFilterButton ? (
            <S.FiltersRow>
              <FilterButton
                filtersOpened={!hideFilters}
                filtersSelected={filtersCount}
                onClick={() => setParentState({ hideFilters: !hideFilters })}
              />
            </S.FiltersRow>
          ) : null}
          {hasImportJobs
            ? importJobs.map((importJob: IImportJob, index: number) => (
                <ImportJob
                  key={index + '_' + importJob.id}
                  importJob={importJob}
                  index={index}
                  allFormats={allFormats}
                  catalogs={catalogs}
                  contacts={contacts}
                  clients={clients}
                  suppliers={suppliers}
                  downloadImportJobFile={downloadImportJobFile}
                  orderOriginalFileDownload={orderOriginalFileDownload}
                  me={me}
                  onOrderClick={this.onOrderClick}
                  contactName={contacts[clientId]?.name}
                  isEdi={isEdi}
                />
              ))
            : this.renderZeroCase()}
        </S.ListContainer>
        {importJobSelected ? (
          <ModalImportJob
            importJob={importJobSelected}
            allFormats={allFormats}
            catalogs={catalogs}
            contacts={contacts}
            clients={clients}
            suppliers={suppliers}
            downloadImportJobFile={downloadImportJobFile}
            orderOriginalFileDownload={orderOriginalFileDownload}
            me={me}
            onOrderClick={this.onOrderClick}
            isEdi={isEdi}
            clientId={clientId}
            onCloseJob={() => onOpenJob()}
          />
        ) : null}
        {orderSelected ? this.renderNewCart() : null}
      </>
    );
  }

  private renderZeroCase() {
    const { filtersCount, isEdi } = this.props;
    return filtersCount ? null : (
      <S.CenterContainer>
        <ZeroCase
          title={isEdi ? __('MagicOrders.zero_case.edi_title') : __('MagicOrders.zero_case.import_title')}
          subtitle={isEdi ? __('MagicOrders.zero_case.edi_text') : __('MagicOrders.zero_case.import_text')}
          link={isEdi ? IMAGES.ediCarrot : IMAGES.informativePineapple}
          isCta={isEdi}
        />
      </S.CenterContainer>
    );
  }

  private renderWidgets() {
    const { dateRange, isEdi } = this.props;
    const { widgetsResponse } = this.state;
    if (!widgetsResponse || !widgetsResponse.count?.total) return null;
    return (
      <S.WidgetsRow>
        {isEdi ? null : (
          <OrderImportWidget
            className="order-import-widget"
            type="time-saved"
            dateRange={dateRange}
            value={widgetsResponse.time_saved?.total || 0}
            mean={widgetsResponse.time_saved?.mean || 0}
            showTooltip={true}
          />
        )}
        {isEdi ? null : (
          <OrderImportWidget
            className="order-import-widget"
            type="errors"
            dateRange={dateRange}
            value={widgetsResponse.errors_saved?.total || 0}
            mean={widgetsResponse.errors_saved?.mean || 0}
            showTooltip={true}
          />
        )}
        <OrderImportWidget
          className="order-import-widget"
          type="imported-orders"
          dateRange={dateRange}
          value={widgetsResponse.count?.total || 0}
          mean={widgetsResponse.count?.mean || 0}
          showTooltip={false}
        />
      </S.WidgetsRow>
    );
  }

  /**
   * Show Cart with order details
   */
  private renderNewCart() {
    const { orderSelected } = this.state;
    const { clientId, contacts, match, history, location, navigateChannelBySection, me } = this.props;
    return (
      <S.Modal className="order-magic-list-modal">
        <OrderDetails
          amSeller={true}
          closeCart={() => {
            this.setState({ orderSelected: undefined });
          }}
          contact={contacts[clientId]}
          channelId={match.params.channelId}
          orderId={orderSelected.id}
          hideBack={true}
          history={history}
          location={location}
          match={match}
          navCloneAction={() => {
            this.setState({ orderSelected: { ...orderSelected, id: 0 } });
          }}
          navShowroom={() =>
            navigateChannelBySection(
              orderSelected.sellerId === me.id ? CHANNEL_SECTIONS.SHOWROOM_SELL : CHANNEL_SECTIONS.SHOWROOM_BUY,
              match.params.channelId,
              true,
              (path: string) => history.push(path),
            )
          }
          backLink={__('Components.Cart.back_orders')}
          openedFrom="other"
        />
      </S.Modal>
    );
  }

  /**
   * On order clicked
   */
  private onOrderClick = (jobId?: number, order?: IOrder) => {
    const { onOpenOrder } = this.props;
    onOpenOrder?.(jobId, order?.hashId);
    this.setState({ orderSelected: order });
  };
}

const ModalImportJob: React.FC<{
  importJob: IImportJob;
  allFormats: Array<IFormat>;
  catalogs: { [id: number]: IWorkspace };
  contacts: { [contactId: number]: IContact };
  clients?: { [catalogId: number]: Array<IClient> };
  suppliers?: { [id: number]: Array<ISupplier> };
  clientId?: number;
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  me: IUser;
  onOrderClick: (jobId: number, order: IOrder) => void;
  isEdi?: boolean;
  onCloseJob: () => void;
}> = ({
  me,
  allFormats,
  catalogs,
  clientId,
  contacts,
  clients,
  suppliers,
  downloadImportJobFile,
  orderOriginalFileDownload,
  isEdi,
  importJob,
  onOrderClick,
  onCloseJob,
}) => {
  const [files, setFiles] = React.useState(importJob.files);
  const [text, setText] = React.useState('');
  React.useEffect(() => {
    setFiles(
      importJob.files.map(f => ({
        ...f,
        orders: f.orders.filter(
          o => o.hashId.startsWith(text) || o.externalIdBuyer.startsWith(text) || o.externalIdSeller.startsWith(text),
        ),
      })),
    );
  }, [importJob]);
  return (
    <S.Modal>
      <S.RowHeader>
        <SimpleSearch
          id="importJob"
          onChange={(t: string) => {
            setText(t);
            setFiles(
              importJob.files.map(f => ({
                ...f,
                orders: f.orders.filter(
                  o => o.hashId.startsWith(t) || o.externalIdBuyer.startsWith(t) || o.externalIdSeller.startsWith(t),
                ),
              })),
            );
          }}
          placeHolder={__('Components.OrdersList.search')}
        />
        {files[0].orders.length ? (
          <Button type="secondary" onClick={onCloseJob}>
            {__('MagicOrders.backToAllJobs')}
          </Button>
        ) : null}
      </S.RowHeader>
      {files[0].orders.length || !text ? (
        <ImportJob
          importJob={{ ...importJob, files }}
          index={0}
          allFormats={allFormats}
          catalogs={catalogs}
          contacts={contacts}
          clients={clients}
          suppliers={suppliers}
          downloadImportJobFile={downloadImportJobFile}
          orderOriginalFileDownload={orderOriginalFileDownload}
          me={me}
          onOrderClick={onOrderClick}
          contactName={contacts[clientId]?.name}
          isEdi={isEdi}
        />
      ) : (
        <ZeroCase
          link={IMAGES.ordersNoResult}
          buttonText={__('MagicOrders.backToAllJobs')}
          title={__('MagicOrders.zeroCase.title', { text })}
          subtitle={__('MagicOrders.zeroCase.subtitle')}
        />
      )}
    </S.Modal>
  );
};
