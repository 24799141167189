import styled, { ITheme } from '../../../../../styled-components';
import {
  ArrowPercentage as AP,
  Button,
  CheckBox,
  ColumnContainer,
  FontIcon,
  Input,
  RowContainer,
  SimpleDropdown,
  TextLabel,
  Tooltip,
} from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  align?: string;
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  margin?: string;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  width?: string;
}

export const SubText = styled.span`
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const Text = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
`;
export const NoDataText = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const PriceText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const MarginText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MarginTextPercentage = styled.span`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const ResumePrice = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TotalResumePrice = styled(ResumePrice)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const CardItem = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 100%;
`;

export const Td = styled.td`
  vertical-align: middle;
  text-align: left;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
const TextSpan = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: ${(p: IStyledProps) => (p.align ? p.align : 'left')};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  &:last-child {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
    @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
      padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    }
  }
`;

export const ActionLink = styled(Button)``;

const UpperSpan = styled(TextSpan)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const PriceSpan = styled(UpperSpan)`
  text-align: right;
`;
export const TotalSpan = styled(UpperSpan)`
  text-align: right;
`;

export const FavoriteContainer = styled(RowContainer)`
  margin: 10px ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: flex-start;
`;

export const LeftRow = styled(RowContainer)`
  align-items: center;
`;

export const Row = styled(RowContainer)`
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const VerticalDivider = styled.div`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 1px;
  height: 18px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const SectionTitle = styled(RowContainer)`
  align-items: center;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: 0;
`;

export const SortByDropdown = styled(SimpleDropdown)`
  align-self: flex-start;
`;

const ButtonGroupItem = styled(Button)`
  min-width: 0;
  padding-left: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SortButton = styled(ButtonGroupItem)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const SortBlackText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SortGreyText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const InfoIconBlack = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const InfoIconBlue = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const PencilIcon = styled(InfoIconBlue)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  height: 100%;
`;
export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const AmountInput = styled(Input)`
  border: ${(p: IStyledProps) =>
    `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.grey2}`};
  &:focus-within {
    border: ${(p: IStyledProps) =>
      `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.green1}`};
  }
`;

export const TablePriceInput = styled(AmountInput)`
  margin-right: 0;
`;

export const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const ItemPrice = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  text-align: right;
  flex: none;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: auto;
`;

const getMarginColor = (p: IStyledProps) => {
  if (!p.baseMarginPercentage || !p.marginPercentage) {
    return p.theme.colors.black;
  }
  return p.marginPercentage.toFixed(2) >= p.baseMarginPercentage.toFixed(2)
    ? p.theme.colors.green1
    : p.theme.colors.red1;
};

export const MarginPercentage = styled(Text)`
  font-weight: ${(p: IStyledProps) => (p.baseMarginPercentage ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${getMarginColor};
  flex: none;
  display: inline;
  width: auto;
`;

export const ItemPriceTotal = styled(ItemPrice)`
  float: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const AmountRow = styled(RowContainer)`
  align-items: start;
  align-self: center;
  cursor: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Amount = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const AmountBlack = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  display: flex;
  color: ${(p: IStyledProps) =>
    p.isClickable ? p.theme.colors.blue1 : p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const PriceRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

export const PriceColumn = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  justify-content: flex-start;
  align-items: flex-start;
`;
export const PriceGroupColumn = styled(PriceColumn)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const PriceEditRow = styled(RowContainer)`
  cursor: ${(p: IStyledProps) => (p.enabled ? 'pointer' : 'default')};
  align-self: center;
  justify-content: flex-start;
`;

export const SubtotalTd = styled(Td)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  justify-content: flex-end;
`;

export const ProductPriceRow = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: 20px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 20px;
  width: 20px;
`;

export const Option = styled(RowContainer)`
  align-items: center;
`;

export const Check = styled(CheckBox)``;

export const OptionText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.isChecked ? p.theme.colors.green1 : p.theme.colors.black)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const KebabIcon = styled(FontIcon)`
  font-size: 20px;
  height: 26px;
  width: 26px;
  padding-bottom: 2px;
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TooltipPrice = styled(Tooltip)`
  position: relative;
  top: -1px;
`;

export const TextPriceGroup = styled(TextLabel)`
  text-align: right;
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextPriceMismatch = styled(TextLabel)`
  display: inline;
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ArrowPercentage = styled(AP)<IStyledProps>`
  right: ${(p: IStyledProps) => p.rightPosition};
`;
export const QuantityRow = styled(RowContainer)`
  align-items: center;
`;
export const TotalPriceRow = styled(RowContainer)`
  float: right;
`;
export const FooterRow = styled(RowContainer)`
  float: right;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const PriceFooter = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: flex-end;
`;
export const ProductErrorRow = styled(RowContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;
export const TextBold = styled(Text)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const ItemTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  flex: 1;
  align-self: start;
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
`;
export const DeleteContainer = styled(RowContainer)`
  justify-content: flex-start;
`;
export const DiscardContainer = styled(RowContainer)`
  justify-content: flex-start;
  display: inline;
`;
export const DiscardWrapper = styled(ColumnContainer)`
  justify-content: flex-start;
`;
export const Deleted = styled(Text)`
  width: auto;
`;
export const Undo = styled(TextLink)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const CloseTd = styled(Td)`
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const IconWrapper = styled.div`
  width: auto;
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;

export const TextGrey = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const UnitPriceColumn = styled(ColumnContainer)`
  align-self: center;
  width: fit-content;
  align-items: flex-end;
`;
export const TableHeadWrapper = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;
export const Reference = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
`;
export const BlueText = styled(TextLabel)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ReferenceCol = styled(ColumnContainer)``;

export const PriceGroupContainer = styled(ColumnContainer)``;

export const Arrow = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ArrowContainer = styled(RowContainer)`
  justify-content: flex-start;
`;
export const GreyText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
