import {
  __,
  contactSelectors,
  date,
  getNearestDeliveryDate,
  IOffer,
  notificationsActions,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { BubbleColor } from '../../atoms/TagBubble/TagBubble.component';
import ContactCell from '../ContactCell';
import MiniImages from '../MiniImages';
import { IColumn } from '../Table/Table.component';
import { getProductImages } from './Functions';
import * as S from './OfferTable.styled';
import { uniqBy } from 'lodash';

const getColumns = (): Array<IColumn> => {
  const contacts = useSelector(contactSelectors.getContacts());
  const me = useSelector(userSelectors.getUser);
  const params = useParams() as any;
  const dispatch = useDispatch<any>();
  const toggleCantRepeatNorDraftNotHistory = useSelector(
    userSelectors.hasToggleEnabled(config.TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY),
  );
  const history = useHistory();
  return [
    {
      title: __('Components.SellerOffer.columns.name'),
      id: 'name',
      width: '140px',
      element: (data: IOffer) => {
        return <S.NameText>{data.name}</S.NameText>;
      },
    },
    {
      title: __('Components.SellerOffer.columns.client'),
      id: 'client',
      width: '80px',
      element: (data: IOffer) => {
        const contact = contacts[data.buyerId];
        if (!contact) return <S.TextBlack>{contact?.name}</S.TextBlack>;

        return (
          <ContactCell
            avatar={contact.companyLogo}
            primaryText={contact.companyName}
            secondaryText={contact.name}
            workingStatus={contact.workingStatus}
          />
        );
      },
    },
    {
      title: __('Components.SellerOffer.columns.products'),
      id: 'products',
      width: '80px',
      element: (data: IOffer) => {
        const uniqOfferedProducts = uniqBy(data.offeredProducts, 'sku');
        const productImages = getProductImages(uniqOfferedProducts);
        const fourProductImages = productImages.slice(0, 4);

        // The actual amount of products in the offer
        const reducedProducts = data.offeredProducts.reduce((acc, el) => {
          if (!acc.includes(el.sku)) {
            acc.push(el.sku);
          }
          return acc;
        }, []);

        return fourProductImages.length ? (
          <MiniImages
            className="pricegroups-table-images"
            images={fourProductImages}
            // onImageClick={index => onImageClick(touchImage, productImages, index)}
            count={reducedProducts.length}
          />
        ) : (
          <S.TextBlack>{__('WorkspacePriceGroups.products', { count: reducedProducts.length })}</S.TextBlack>
        );
      },
    },

    {
      title: __('Components.SellerOffer.columns.status'),
      id: 'status',
      width: '80px',
      element: (data: IOffer) => {
        let color = 'draft';
        switch (data.status) {
          case 'Published':
            color = 'accepted';
            break;
          case 'Expired':
            color = 'error';
            break;
        }
        return (
          <S.StatusTag
            label={__('Components.OffersList.states.' + data.status.toLowerCase() + '.name')}
            disabled={true}
            color={__('Components.OffersList.states.' + data.status.toLowerCase() + '.color') as BubbleColor}
          />
        );
      },
    },
    {
      title: __('Components.SellerOffer.columns.delivery_date'),
      id: 'date',
      width: '120px',
      element: (data: IOffer) => {
        const nearestDate = getNearestDeliveryDate(data);
        const formatDate = date.dateToText(nearestDate, me.settings.hourFormat);
        const dateFormatted = date.formatLongDate(nearestDate!);

        return (
          <>
            <S.TextBlack>{formatDate.date}</S.TextBlack>
            <S.Text> {utils.firstToUpperCase(dateFormatted)}</S.Text>
          </>
        );
      },
    },
    {
      title: __('Components.SellerOffer.columns.last_update'),
      id: 'updatedAt',
      width: '100px',
      sort: 'updatedAt',
      sortOrder: ['desc', 'asc'],
      element: (data: IOffer) => {
        const formatDate = date.dateToText(data.updatedAt, me.settings.hourFormat);
        const lastUpdateHour = date.formatTime(data.updatedAt!, me.settings.hourFormat);
        // const lastChangedPersonName = data.lastChangedBy
        //   ? getLastChangeByContactName(data.lastChangedBy, contacts, me)
        //   : undefined;

        return (
          <S.LeftContainer>
            <S.Text>
              {formatDate.date} · {lastUpdateHour}
            </S.Text>
            {/* {lastChangedPersonName ? ' · ' + lastChangedPersonName : ''} */}
          </S.LeftContainer>
        );
      },
    },
    ...(config.TOGGLE_REPEAT_OFFER.enabled
      ? [
          {
            title: '',
            id: 'repeat',
            width: '80px',
            element: (data: IOffer) => {
              return (
                <S.CtaButton
                  type="secondary"
                  disabled={toggleCantRepeatNorDraftNotHistory}
                  onClick={async e => {
                    e.stopPropagation();
                    await history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                        workspaceId: params.workspaceId,
                        offerId: 'repeat',
                      }),
                      {
                        prevOffer: data,
                      },
                    );
                    dispatch(
                      notificationsActions.notificationShow({
                        title: __('Components.OffersList.notification.repeat'),
                        subtitle: __('Components.OffersList.notification.description'),
                        closable: true,
                        style: 'success',
                      }),
                    );
                  }}
                >
                  {__('Components.OffersList.repeat')}
                </S.CtaButton>
              );
            },
          },
        ]
      : []),
  ];
};

export interface IProps {
  data?: Array<IOffer>;
  onLoadMore?: () => void;
  updateSortMode?: (sort: string, sortOrder: 'desc' | 'asc') => void;
  sort?: string;
  sortOrder?: 'desc' | 'asc';
}

const OfferTable: React.FC<IProps> = ({ data, onLoadMore, updateSortMode, sort, sortOrder }) => {
  const params = useParams() as any;
  const history = useHistory();
  const toggleCantRepeatNorDraftNotHistory = useSelector(
    userSelectors.hasToggleEnabled(config.TOGGLE_CANT_REPEAT_NOR_DRAFT_NOR_HISTORY),
  );
  const [rowCursorPointer, setRowCursorPointer] = React.useState<number>();

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!tableContainerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;

      if (scrollHeight - scrollTop <= clientHeight + 1) {
        onLoadMore();
      }
    };

    const container = tableContainerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [onLoadMore]);

  React.useEffect(() => {
    if (toggleCantRepeatNorDraftNotHistory) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const index = data.findIndex(item => {
        const deliveryDate = new Date(item.updatedAt);
        deliveryDate.setHours(0, 0, 0, 0);
        return deliveryDate.getTime() !== today.getTime();
      });
      setRowCursorPointer(index !== -1 ? index + 1 : undefined);
    }
  }, [data]);

  const columns = [
    { value: 'name', label: __('Components.SellerOffer.columns.name') },
    { value: 'client', label: __('Components.SellerOffer.columns.client') },
    { value: 'products', label: __('Components.SellerOffer.columns.products') },
    { value: 'status', label: __('Components.SellerOffer.columns.status') },
    { value: 'date', label: __('Components.SellerOffer.columns.delivery_date') },
    { value: 'updatedAt', label: __('Components.SellerOffer.columns.last_update') },
    ...(config.TOGGLE_REPEAT_OFFER.enabled ? [{ value: 'repeat', label: '' }] : []),
  ];

  return (
    <S.Container ref={tableContainerRef} style={{ flex: 1 }}>
      <S.OfferTable
        toggleCantRepeatNorDraftNotHistory={rowCursorPointer}
        selectable={false}
        columns={getColumns()}
        emptyText=""
        onClickRow={d => {
          if (toggleCantRepeatNorDraftNotHistory) {
            const deliveryDate = new Date(d.updatedAt);
            deliveryDate.setHours(0, 0, 0, 0);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (deliveryDate.getTime() === today.getTime()) {
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                  workspaceId: params.workspaceId,
                  offerId: d.id,
                }),
              );
            }
          } else {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
                workspaceId: params.workspaceId,
                offerId: d.id,
              }),
            );
          }
        }}
        values={data}
        productColumns={columns}
        showStickyHeader={true}
        sort={sort}
        sortOrder={sortOrder}
        updateSortMode={(sort, sortOrder) => updateSortMode(sort, sortOrder)}
      />
    </S.Container>
  );
};

export default OfferTable;
