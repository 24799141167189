import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { IOrderRequest, IWorkspace } from 'common-services';

import OrderRequestDetails from '../../../screens/order-request-details';

import * as S from './OrdersList.styled';

interface OrderRequestDetailsWrapperProps {
  selectedOrderRequest: IOrderRequest | undefined;
  setSelectedOrderRequest: React.Dispatch<React.SetStateAction<IOrderRequest | undefined>>;
  workspaceSelected: IWorkspace;
}

const OrderRequestDetailsWrapperComponent: React.FC<OrderRequestDetailsWrapperProps> = ({
  selectedOrderRequest,
  setSelectedOrderRequest,
  workspaceSelected,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ workspaceId?: string; channelId?: string; contactId?: string }>();

  if (!selectedOrderRequest) {
    return null;
  }

  return (
    <S.CartContainer>
      <OrderRequestDetails
        goBack={() => setSelectedOrderRequest(undefined)}
        history={history}
        location={location}
        match={params}
        request={selectedOrderRequest}
        workspaceSelected={workspaceSelected}
      />
    </S.CartContainer>
  );
};

export const OrderRequestDetailsWrapper = React.memo(OrderRequestDetailsWrapperComponent);
