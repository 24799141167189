import {
  Api,
  buyerWorkspaceActions,
  LOCALE,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as navActions from '../actions/nav';
import ProductsBuyer, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceProductsBuyer';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    product: { featured },
    workspace: { workspaces, addresses },
    contact: { inConsentio },
    order: { cart },
    user: { user },
    nav: { filterBuyerProducts },
  } = state!;
  const prodTypes = state.prodType.prodTypes;
  const catalogId = props.match.params.workspaceId ? Number(props.match.params.workspaceId) : user.sellerWorkspaceId;
  return {
    featured,
    workspaceAssignedId: catalogId,
    workspace: workspaces[catalogId],
    workspaces,
    contacts: inConsentio,
    countries: state.country.countries,
    address: addresses?.[catalogId]?.[0],
    carts: cart,
    me: user,
    prodTypes,
    filterBuyerProducts,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      cartUpdateItem: orderActions.cartUpdateItem,
      catalogSync: sellerWorkspaceActions.catalogSync,
      featuredToggle: productActions.featuredToggle,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      touchImage: modalActions.touchImage,
      workspaceAddressesGet: buyerWorkspaceActions.workspaceAddressesGet,
      buyerWorkspaceAssign: buyerWorkspaceActions.workspaceAssign,
      setFilterBuyerProducts: navActions.setFilterBuyerProducts,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsBuyer);
