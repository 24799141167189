import * as Sentry from '@sentry/react';
import { i18n, userActions, userSelectors } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route as RouterRoute, RouteProps } from 'react-router-dom';

import locales from '../../../../locales';
import { REGISTER_STEPS } from '../../../constants';
import { IReduxState } from '../../../reducers';
import LoginModal from '../../../screens/login-modal';

// Add sentry tracking to routes
const Route = Sentry.withSentryRouting(RouterRoute);

export interface IProps extends RouteProps {
  component: any;
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUser);
  const auth = useSelector((state: IReduxState) => state.nav.auth);
  const isLoading = useSelector((state: IReduxState) => state.user.loading);
  // commenting this to try a new approach with a set timeout
  // React.useEffect(() => {
  //   if (!isLoading && (!user || !user.id)) {
  //     console.log('PrivateRoute - isLoading, user >> ', isLoading, user);
  //     dispatch(userActions.showLogin());
  //   }
  // }, [user, isLoading]);

  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (!isLoading) {
      if (!user || !user.id) {
        // Set a timeout to confirm user absence
        timeoutRef.current = setTimeout(() => {
          dispatch(userActions.showLogin());
        }, 500);
      } else {
        // If user is present, clear the timeout
        clearTimeout(timeoutRef.current);
      }
    }
    // Cleanup function to clear timeout if component unmounts
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [user, isLoading]);

  React.useEffect(() => {
    if (user.id) {
      locales(i18n.getLanguageCode(user.settings.language));
    }
  }, [user.settings.language]);

  return (
    <Route
      {...rest}
      render={props =>
        user?.id ? <Component showInviteOnboarding={auth === REGISTER_STEPS.INVITE} {...props} /> : <LoginModal />
      }
    />
  );
};

export default React.memo(PrivateRoute);
