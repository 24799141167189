import { __, ICountry, INVITE_ORIGIN, IUser, RenderTrack, utils } from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { InviteForm } from '../../molecules';
import * as S from './InviteOnboarding.styled';

export interface IStateProps {
  countries: { [key: string]: ICountry };
  me: IUser;
}

export interface IDispatchProps {
  navResetAuthAction: () => void;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps<{}>;

interface IState {
  step: number;
}

class InviteOnboarding extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      step: 1,
    };
  }

  public componentDidMount() {
    RenderTrack.track('InviteOnboarding', { renderTime: this.t });
  }

  public render() {
    const { step } = this.state;
    return (
      <S.Container>
        <S.Body>
          <S.TextStep>{__('Components.InviteOnboarding.step', { step, total: 2 })}</S.TextStep>
          {step === 1 ? this.renderStep1() : null}
          {step === 2 ? this.renderStep2() : null}
        </S.Body>
      </S.Container>
    );
  }

  /**
   * Render step 1 with image + explanations
   */
  private renderStep1() {
    const { me } = this.props;
    return (
      <>
        <S.Title>{__('Components.InviteOnboarding.1.title', { name: me.name })}</S.Title>
        <S.Image alt="invite-onboarding" src={IMAGES.welcome} />
        <S.DescriptionContainer>
          <S.TextDescription>
            {
              utils.formatText(
                me.settings.isSeller
                  ? __('Components.InviteOnboarding.1.text_seller1')
                  : __('Components.InviteOnboarding.1.text_buyer1'),
                (s: string, idx: number) => <S.TextBold key={s + '_' + idx}>{s}</S.TextBold>,
              ) as any // TYPEERROR
            }
          </S.TextDescription>
          <S.TextDescription>
            {
              utils.formatText(
                me.settings.isSeller
                  ? __('Components.InviteOnboarding.1.text_seller2')
                  : __('Components.InviteOnboarding.1.text_buyer2'),
                (s: string, idx: number) => <S.TextBold key={s + '_' + idx}>{s}</S.TextBold>,
              ) as any // TYPEERROR
            }
          </S.TextDescription>
          <S.TextDescription>
            {
              utils.formatText(
                me.settings.isSeller
                  ? __('Components.InviteOnboarding.1.text_seller3')
                  : __('Components.InviteOnboarding.1.text_buyer3'),
                (s: string, idx: number) => <S.TextBold key={s + '_' + idx}>{s}</S.TextBold>,
              ) as any // TYPEERROR
            }
          </S.TextDescription>
        </S.DescriptionContainer>
        <S.CTA type="principal" onClick={() => this.setState({ step: 2 })}>
          {__('Components.InviteOnboarding.1.next')}
        </S.CTA>
      </>
    );
  }

  /**
   * Render step 2 with form
   */
  private renderStep2() {
    const { countries, me, navResetAuthAction } = this.props;
    return (
      <>
        <S.Title>{__('Components.InviteOnboarding.2.title')}</S.Title>
        <S.TextBigRegular>{__('Components.InviteOnboarding.2.subtitle')}</S.TextBigRegular>
        <InviteForm
          className="onboarding-invite-form"
          countries={countries}
          from="onboarding"
          onClose={navResetAuthAction}
          me={me}
          origin={INVITE_ORIGIN.ONBOARD}
          trackingFrom="onboarding"
        />
      </>
    );
  }
}

export default InviteOnboarding;
