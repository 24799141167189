import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, ExpansionPanel as EP, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
}

export const CardItem = styled(ColumnContainer)`
  width: 100%;
`;

export const ActionButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.margin || p.theme.paddingSize()} 0;
  width: 45%;
  min-width: 200px;
`;

export const FormContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  overflow-y: auto;
  flex: 1;
`;

export const FormRow = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ExpansionPanel = styled(EP)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ExpansionTitle = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const CancelButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  min-width: 200px;
  width: 45%;
`;

export const ButtonsContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  justify-content: space-between;
`;

export const PlacesContainer = styled.div`
  width: 100%;
`;

export const MapContainer = styled(ColumnContainer)`
  width: 100%;
  height: 150px;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const MarkerImage = styled.img`
  object-fit: contain;
  width: 20px;
  height: auto;
  position: relative;
  top: -20px;
  left: -10px;
`;

export const CardInputs = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ErrorIcon = styled(FontIcon)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
