import { date, IContact, ImageGalleryObject, IOrderAggregationItem, ISearchOrder } from 'common-services';
import * as React from 'react';

import theme from '../../../theme';
import { ExpansionPanel } from '../../atoms';
import { Table } from './Fragments';
import * as S from './OrderDelivery.styled';

interface IProps {
  contacts: { [cId: number]: IContact };
  myId: number;
  orderItems: Array<IOrderAggregationItem>;
  searchState: ISearchOrder;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
}

const OrderDeliveryTable: React.FC<IProps> = ({ searchState, contacts, orderItems, touchImage }) => {
  return (
    <S.Container className="delivery-table-container">
      {orderItems
        .reduce((acc: Array<Array<Array<IOrderAggregationItem>>>, o, idx, arr) => {
          if (!idx || date.formatLongDate(o.orderDeliveryAt) !== date.formatLongDate(arr[idx - 1].orderDeliveryAt)) {
            acc.push([[o]]);
          } else {
            const i = acc[acc.length - 1].findIndex(t => t[0].type === o.type);
            if (i !== -1) {
              acc[acc.length - 1][i].push(o);
            } else {
              acc[acc.length - 1].push([o]);
            }
          }
          return acc;
        }, [])
        .map(t =>
          searchState.withoutDeliveryAt ? (
            t.map(o => (
              <Table
                key={o[0]?.childId}
                contacts={contacts}
                searchState={searchState}
                touchImage={touchImage}
                orderItems={o}
              />
            ))
          ) : (
            <ExpansionPanel
              contentWithLeftPadding={false}
              key={t[0][0].childId + t[0][0].orderHash}
              title={date.formatLongDate(t[0][0].orderDeliveryAt).toLocaleUpperCase()}
              defaultExpanded={true}
              iconPosition="left"
              titleContainerStyle={{ paddingLeft: theme.paddingSize(2) }}
            >
              {t.map(o => (
                <Table
                  key={o[0]?.childId}
                  contacts={contacts}
                  searchState={searchState}
                  touchImage={touchImage}
                  orderItems={o}
                />
              ))}
            </ExpansionPanel>
          ),
        )}
    </S.Container>
  );
};

export default React.memo(OrderDeliveryTable);
