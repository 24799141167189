import { __, contactActions, INVITE_ORIGIN, notificationsActions, VIA } from 'common-services';
import * as React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { IReduxState } from '../../../reducers';
import { copyToClipboard } from '../../../util/utils';
import EmptyListResource from '../EmptyListResource';
import * as S from './NonClients.styled';

interface IProps {
  children: any;
  hasHits: boolean;
  linkAction: () => void;
  workspaceId: number;
}

/**
 * Zero case for clients
 */
const NonClients = React.memo(({ hasHits, children, linkAction, workspaceId }: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useStore<IReduxState>().getState().user.user;
  return hasHits ? (
    children
  ) : (
    <S.CenterContainer>
      <EmptyListResource
        text={__('NonClients.title')}
        text2={__('NonClients.description')}
        imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
        showButton={false}
        links={[
          {
            text: __('NonClients.link1'),
            action: () => {
              linkAction();
            },
            iconName: 'Add-contact',
          },
          {
            text: __('NonClients.link2'),
            action: () => {
              dispatch(
                contactActions.contactsInvite(
                  me.id!,
                  VIA.LINK,
                  'web',
                  INVITE_ORIGIN.WORKSPACE,
                  undefined,
                  (data, error) => {
                    if (data?.inviteLink && !error)
                      setTimeout(() =>
                        copyToClipboard(data.inviteLink, err =>
                          dispatch(
                            notificationsActions.notificationShow(
                              {
                                style: err ? 'error' : 'info',
                                title: err ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                                subtitle: err ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                                closable: true,
                              },
                              4000,
                            ),
                          ),
                        ),
                      );
                  },
                  undefined,
                  workspaceId,
                ),
              );
            },
            iconName: 'Link',
          },
        ]}
      />
    </S.CenterContainer>
  );
});
export default NonClients;
