import * as React from 'react';
import styled, { ITheme } from '../../../styled-components';
import { Button, CheckBox as CB, ColumnContainer, FontIcon, Ribbon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  isDragActive?: boolean;
  margin?: string;
  theme: ITheme;
  width?: string;
}

export const Container = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  max-height: 100%;
`;

export const ScrollContainer = styled(ColumnContainer)`
  overflow-y: auto;
  width: 100%;
  flex: 1;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  max-height: 100%;
`;

export const FormContainer = styled.div`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 650px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} auto 0;
  height: fit-content;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
`;

export const ErrorsContainer = styled.div`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 650px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} auto 0;
  height: fit-content;
  -webkit-overflow-scrolling: touch;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const ButtonUpload = styled(Button)`
  background-color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.theme.colors.green1)};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Title = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Subtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TextLink = styled.a`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const CheckboxRow = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  align-items: center;
`;

export const CheckBox = styled(CB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Back = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const CheckBoxLabel = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const UploadFileIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: 1px;
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  height: auto;
  min-height: min-content;
  &:focus {
    outline: none;
  }
`;

export const SelectButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextUpload = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextCenter = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const TextUploadGreen = styled(TextUpload)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
  bottom: -1px;
`;

export const IssuesHeader = styled(RowContainer)`
  align-items: baseline;
`;

export const LinesContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const IssueLabel = styled(Text)`
  width: fit-content;
  background-color: ${(p: IStyledProps) => p.theme.colors.orange2.replace('1)', '0.3)')};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-left: 3px solid ${(p: IStyledProps) => p.theme.colors.orange1};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IssueTextBold = styled(Text)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const IssuesColumn = styled(ColumnContainer)``;

export const HeaderRibbon = styled(Ribbon)`
  width: 100%;
`;
