import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

export type IRibbonType = 'info' | 'warning' | 'error';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  showColor?: boolean;
  ribbonType?: IRibbonType;
  badgeCount?: number;
  size?: string;
}

export const Ribbon = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => getBackgroundColor(p)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-items: center;
  position: relative;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  width: 100%;
`;

export const Content = styled(ColumnContainer)`
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  flex: 1;
`;
export const Action = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  cursor: pointer;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.6)};
`;

function getBackgroundColor(p: IStyledProps) {
  const colors: Record<IRibbonType, string> = {
    info: p.theme.colors.blue2,
    warning: p.theme.colors.orange2,
    error: p.theme.colors.red2,
  };
  return colors[p.ribbonType];
}

function getIconColor(p: IStyledProps) {
  const colors: Record<IRibbonType, string> = {
    info: p.theme.colors.black,
    warning: p.theme.colors.orange1,
    error: p.theme.colors.red1,
  };
  return colors[p.ribbonType];
}

export const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  display: inline;
`;

export const TextBold = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const CloseIcon = styled(FontIcon)``;

export const IconWrapper = styled.div`
  width: auto;
  margin-right: ${getMarginRight};
  font-size: ${(p: IStyledProps) => p.size || p.theme.fontSize.H2};
  height: ${(p: IStyledProps) => p.size || p.theme.fontSize.H2};
  align-items: center;
  display: flex;
  top: 0;
  position: relative;
  color: ${getIconColor};

  &:after {
    content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
    background-color: ${(p: IStyledProps) => p.theme.colors.red1};
    width: ${getBadgeWidth};
    height: 15px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    padding-right: 1px;
    transform: translateX(50%);
    color: ${(p: IStyledProps) => p.theme.colors.white};
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
    padding-left: 1px;
    padding-top: 0;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  }
`;

function getBadgeWidth(p: IStyledProps) {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '18px';
  if (p.badgeCount < 1000) return '24px';
  return '30px';
}

function getMarginRight(p: IStyledProps) {
  if (!p.badgeCount) return p.theme.paddingSize(1.5);
  if (p.badgeCount < 10) return p.theme.paddingSize(2);
  if (p.badgeCount < 100) return p.theme.paddingSize(2.5);
  if (p.badgeCount < 1000) return p.theme.paddingSize(3);
  return p.theme.paddingSize(3.5);
}
