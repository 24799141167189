import {
  __,
  CHANNEL_DISPLAY,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  contactActions,
  EventTrack,
  modalActions,
  notificationsActions,
  RenderTrack,
  utils,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { ADMIN_OWNER_ROLES, ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { Button } from '../../atoms';
import { ChatPublicHeader } from '../../molecules';
import * as S from './ChatPublicInfo.styled';

type IRouteProps = RouteComponentProps<{ channelId?: string }>;
export interface IStateProps {
  channel: IChannel;
  channels: { [key: string]: IChannel };
  contacts: { [contactId: number]: IContact };
  hasOutContacts: boolean;
  me: IUser;
}

export interface IDispatchProps {
  channelArchive: typeof chatActions.channelArchive;
  channelLeave: typeof chatActions.channelLeave;
  channelMute: typeof chatActions.channelMute;
  createNewContact: typeof contactActions.createNewContact;
  deleteMembers: typeof chatActions.deleteMembers;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  updateMember: typeof chatActions.updateMember;
}

export type IProps = IStateProps & IDispatchProps & IRouteProps;

/**
 * Public channel info
 */
class ChatPublicInfo extends React.PureComponent<IProps, {}> {
  private t: number;
  constructor(public props: IProps) {
    super(props);
    this.t = Date.now();
  }

  public componentDidMount() {
    RenderTrack.track('ChatPublicInfo', { renderTime: this.t });
  }

  public render() {
    const { channel } = this.props;
    if (!channel) return null;
    return (
      <S.Container className="chat-public-container">
        {this.renderHeader()}
        <S.BodyContainer className="chat-public-body-container">
          <S.ContentContainer className="chat-public-content-container">
            {channel.description ? (
              <S.CardContainer>
                <S.TitleCard>{__('ChatPublicInfo.channel_description')}</S.TitleCard>
                <S.ContentCardText>
                  {utils.splitTextUrls(channel.description).map((t, i) => {
                    if (!t.text) return <br key={i + ''} />;
                    if (t.isLink) {
                      const { url } = utils.analyzeUrl(t.text);
                      return (
                        <S.Link
                          key={i + url}
                          href={url}
                          target="_blank"
                          onClick={() => {
                            EventTrack.track('description_link_click', {
                              channel_id: channel.id,
                              channel_type: CHANNEL_TYPE.PUBLIC,
                              link: url,
                            });
                          }}
                        >
                          {url}
                        </S.Link>
                      );
                    }
                    return t.text;
                  })}
                </S.ContentCardText>
              </S.CardContainer>
            ) : null}
            {this.renderMembers()}
            {this.renderFooter()}
          </S.ContentContainer>
        </S.BodyContainer>
      </S.Container>
    );
  }

  private renderHeader = () => {
    const { channel, history } = this.props;
    return (
      <ChatPublicHeader
        channel={channel}
        className="chat-public-header-container"
        history={history}
        onTabChange={this.onTabChange}
        tabSelected="info"
      />
    );
  };

  /**
   * On tab click, performs a page change
   */
  private onTabChange = (tab: string) => {
    const { channel, history } = this.props;
    switch (tab) {
      case 'messages':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId: channel.id }));
        break;
      case 'info':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL_INFO, channelId: channel.id }));
        break;
    }
  };

  private renderMembers = () => {
    const { channel, contacts, me } = this.props;
    const iAmOwnerOrAdmin = ADMIN_OWNER_ROLES.includes(channel.role);

    // Top part
    let adminOwnerMembers = channel.members.filter(m => ADMIN_OWNER_ROLES.includes(m.role));
    if (iAmOwnerOrAdmin) {
      adminOwnerMembers = [...adminOwnerMembers, chatService.meAsChannelMember(channel, me)] as Array<IMember>;
    }

    if (!adminOwnerMembers.length) return null;
    adminOwnerMembers = adminOwnerMembers.sort((m1, m2) => {
      if (m1.role === CHANNEL_MEMBER_ROLE.OWNER && m2.role !== CHANNEL_MEMBER_ROLE.OWNER) return -1;
      if (m1.role !== CHANNEL_MEMBER_ROLE.OWNER && m2.role === CHANNEL_MEMBER_ROLE.OWNER) return -1;
      return m1.name > m2.name ? 1 : -1;
    });

    // Bottom part
    const otherMembers = iAmOwnerOrAdmin
      ? channel.members.filter(m => !ADMIN_OWNER_ROLES.includes(m.role)).sort((m1, m2) => (m1.name > m2.name ? 1 : -1))
      : [];

    return (
      <S.CardContainer>
        <S.TitleCard>
          {__('ChatPublic.members_title', { count: channel.numMembers }) + ' · '}
          <S.TitleCardGrey>{channel.numMembers}</S.TitleCardGrey>
        </S.TitleCard>
        <S.SubtitleCard>{__('ChatPublic.owners_admin')}</S.SubtitleCard>
        <S.CardBottom>
          {adminOwnerMembers.map((member: IMember, idx: number, array: Array<IMember>) => {
            const contact = contacts[member.id];
            return (
              <S.MemberItem
                className="chat-public-member-card-header"
                contact={contact}
                isLast={idx === array.length - 1}
                key={member.id}
                member={member}
                subtitle={member.companyName || contact?.companyName}
                isInfoSection={true}
              />
            );
          })}
        </S.CardBottom>
        {otherMembers.length ? (
          <S.OtherMemberSection>
            <S.SubtitleCard>{__('ChatPublic.other_members')}</S.SubtitleCard>
            <S.CardBottom>
              {otherMembers.map((member: IMember, idx: number, array: Array<IMember>) => {
                const contact = contacts[member.id];
                return (
                  <S.MemberItem
                    className="chat-public-member-card-header"
                    contact={contact}
                    isLast={idx === array.length - 1}
                    key={member.id}
                    member={member}
                    subtitle={member.companyName || contact?.companyName}
                    isInfoSection={true}
                  />
                );
              })}
            </S.CardBottom>
          </S.OtherMemberSection>
        ) : null}
      </S.CardContainer>
    );
  };

  /**
   * Render channels group actions (create / leave)
   */
  private renderFooter = () => {
    const { channel } = this.props;
    const amActive = channel.status === 'active';
    return (
      <S.FormSection
        title={__('Channel.activity.title')}
        subtitle={
          channel.role === 'owner'
            ? __('Channel.activity.description_no_leave_channel')
            : __('Channel.activity.description_channel')
        }
      >
        <S.Row>
          <S.ArchiveButton onClick={this.archiveChannel} type="secondary" iconName="Archive">
            {channel.display !== CHANNEL_DISPLAY.ARCHIVED
              ? __('Channel.archive_channel')
              : __('Channel.unarchive_channel')}
          </S.ArchiveButton>
          {channel.role === 'owner' || !amActive ? null : (
            <Button type="delete" onClick={this.leaveChannel} iconName="Leave-group">
              {__('Channel.leave_channel')}
            </Button>
          )}
        </S.Row>
      </S.FormSection>
    );
  };
  /**
   * Leave channel with confirmation modal
   */
  private leaveChannel = () => {
    const { modalOpen, modalClose, channelLeave, channel, me, history } = this.props;
    modalOpen(
      __('Channel.leave_channel'),
      () => {
        channelLeave(me.id!, channel.id);
        modalClose();
        setTimeout(() => history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST })), 1000);
      },
      {
        icon: IMAGES.cautionGrape,
        text2: __('Channel.leave_channel_confirmation'),
        buttonText: __('Channel.leave_channel'),
        actionType: 'dangerous',
        showCancelButton: true,
      },
      'nice',
    );
  };

  /**
   * On archive channel
   */
  private archiveChannel = () => {
    const { channel, channelArchive, me, notificationShow } = this.props;
    const toArchive = channel.display !== CHANNEL_DISPLAY.ARCHIVED;
    channelArchive(me.id, channel.id, toArchive, (error?: Error) => {
      if (error) {
        notificationShow(
          {
            title: __('Notification.error.title'),
            subtitle: __('Notification.error.description'),
            closable: true,
            style: 'error',
          },
          4000,
        );
      } else {
        notificationShow(
          {
            title: toArchive ? __('Notification.archive.title') : __('Notification.unarchive.title'),
            subtitle: toArchive
              ? __('Notification.archive.description_group')
              : __('Notification.unarchive.description_group'),
            closable: true,
            style: 'info',
          },
          4000,
        );
      }
    });
  };
}

export default ChatPublicInfo;
