import { Api, modalActions, notificationsActions, productActions, productService } from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import ProductsImport, { IDispatchProps, IStateProps } from '../components/organisms/ProductsImport';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: RouteComponentProps<any>): IStateProps {
  const myId = state.user.user!.id!;
  const catalogId = Number(props.match.params.workspaceId) || state.user.user!.sellerWorkspaceId!;
  const prodTypes = state.prodType.prodTypes;
  return {
    catalogId,
    hasPrices: !!Object.keys(state.product.prices[productService.getKey(catalogId, myId)] || emptyObject).length,
    prodTypes,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      productsBulkImport: productActions.productsBulkImport,
      modalOpen: modalActions.modalOpen,
      modalClose: modalActions.modalClose,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsImport);
