import { utils } from 'common-services';
import * as React from 'react';

import * as S from './FormSection.styled';

export interface IProps {
  children?: React.ReactNode;
  className?: string;
  containerPadding?: number;
  hasOverflowX?: boolean;
  id?: string;
  numHeaders?: number;
  sectionRef?: React.RefObject<HTMLDivElement>;
  subtitle?: string;
  subtitleAction?: () => void;
  tag?: string;
  tagColor?: string;
  title?: string;
  titleTooltip?: string;
  withoutPaddingBottom?: boolean;
  withoutPaddingTop?: boolean;
}

const FormSection: React.FC<IProps> = ({
  children,
  className,
  containerPadding,
  hasOverflowX,
  id,
  numHeaders,
  sectionRef,
  subtitle,
  subtitleAction,
  tag,
  tagColor,
  title,
  titleTooltip,
  withoutPaddingBottom,
  withoutPaddingTop,
}) => {
  return (
    <S.ContainerWrapper id={id} numHeaders={numHeaders} ref={sectionRef} className={className}>
      <S.Container
        containerPadding={containerPadding}
        withoutPaddingBottom={withoutPaddingBottom}
        id={`form-section-container-${id}`}
      >
        <S.TitleWrapper>
          {title ? <S.Title>{title}</S.Title> : null} {tag ? <S.Tag tagColor={tagColor}>{tag}</S.Tag> : null}
          {titleTooltip ? (
            <S.TooltipTitle position="bottom" text={titleTooltip} width="200px" themeMode="dark">
              <S.InfoIcon disableHover={true} name="Info" />
            </S.TooltipTitle>
          ) : null}
        </S.TitleWrapper>
        {subtitle ? (
          <S.Subtitle>
            {
              utils.formatText(subtitle, (s, idx) => (
                <S.SubtitleLink key={s + idx} onClick={() => subtitleAction?.()}>
                  {s}
                </S.SubtitleLink>
              )) as any // TYPEERROR
            }
          </S.Subtitle>
        ) : null}
        <S.Wrapper withoutPaddingTop={withoutPaddingTop} hasOverflowX={hasOverflowX}>
          {children}
        </S.Wrapper>
      </S.Container>
    </S.ContainerWrapper>
  );
};

export default React.memo(FormSection);
