import * as React from 'react';

import * as S from './GenericTutorial.styled';

export interface IProps {
  action?: () => void;
  action2?: () => void;
  actionLink?: () => void;
  cta?: string;
  cta2?: string;
  imageSize?: number;
  imageUrl?: string;
  link?: string;
  text?: string;
  title: string;
}

/**
 * Generic tutorial containing: image, title, text + CTA
 */
const GenericTutorial: React.FC<IProps> = ({
  cta,
  cta2,
  title,
  text,
  imageUrl,
  action,
  action2,
  link,
  actionLink,
  imageSize = 120,
}) => {
  return (
    <S.Container>
      {imageUrl ? (
        <S.Row>
          <S.Image src={imageUrl} imageSize={imageSize} />
        </S.Row>
      ) : null}
      <S.Row>
        <S.Title>{title}</S.Title>
      </S.Row>
      {text
        ? text.split('\n').map((t, i) => (
            <S.Row key={'generic-tutorial-p-' + i}>
              <S.Body>{t}</S.Body>
            </S.Row>
          ))
        : null}
      {cta || cta2 ? (
        <S.CTAContainer>
          {cta ? (
            <S.CTA id="generic-tutorial-cta-1" onClick={action} type="principal" isLast={!cta2}>
              {cta}
            </S.CTA>
          ) : null}
          {cta2 ? (
            <S.CTA id="generic-tutorial-cta-2" onClick={action2} type="principal" isLast={true}>
              {cta2}
            </S.CTA>
          ) : null}
        </S.CTAContainer>
      ) : null}
      {link ? (
        <S.LinkContainer>
          <S.CTA id="generic-tutorial-cta-link" onClick={actionLink} type="link">
            {link}
          </S.CTA>
        </S.LinkContainer>
      ) : null}
    </S.Container>
  );
};

export default GenericTutorial;
