import {
  __,
  buyerWorkspaceSelectors,
  IProductKind,
  IReference,
  IReferenceResponse,
  IReferenceSupplier,
  modalActions,
  notificationsActions,
  productKindActions,
  referenceActions,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, RouteComponentProps, useHistory } from 'react-router-dom';

import { IMAGES } from '../../../../assets';
import { getDefaultColumns, ROUTE_PATHS } from '../../../../constants';
import getPath from '../../../../util/routes';
import { Select } from '../../../atoms';
import { FormContainer, FormMenu, FormSection, InputWithLabel, Table } from '../../../molecules';
import Workspace from '../../Workspace/Workspace.component';
import * as S from './ReferenceEdit.styled';
import getColumns from '../../../../columns';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  referentialId?: string;
}>;

export type IProps = IRouteProps;

const ReferenceEdit: React.FC<IProps> = ({
  match: {
    params: { workspaceId, referentialId },
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const sectionsRef = React.useRef<HTMLDivElement>();
  const referentialRef = React.useRef<HTMLDivElement>();
  const deleteRef = React.useRef<HTMLDivElement>();
  const sellersRef = React.useRef<HTMLDivElement>();
  const [viewing, setViewing] = React.useState<string>('referential');
  const [productKinds, setProductKinds] = React.useState<Array<IProductKind>>([]);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);

  const [reference, setReference] = React.useState<IReference>({
    name: '',
    pictureURL: '',
    description: '',
    buyerTag: '',
    workspaceId: workspaceId ? +workspaceId : 0,
    productKindId: 0,
    linkedProducts: [],
    createdAt: '',
    updatedAt: '',
    id: 0,
  } as any as IReference);

  const [referenceResponse, setReferenceResponse] = React.useState<IReferenceResponse>({
    name: '',
    id: 0,
    pictureURL: '',
    description: '',
    buyerTag: '',
    workspaceId: workspaceId ? +workspaceId : 0,
    productKindId: 0,
    linkedProducts: [],
    createdAt: '',
    updatedAt: '',
    suppliers: [],
  });

  const isNewReference = referentialId === 'new';

  React.useEffect(() => {
    if (workspaceId) {
      dispatch(
        productKindActions.productKindsGet(res => {
          setProductKinds(res);
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    const offset = 150;
    if (sectionsRef.current)
      sectionsRef.current.addEventListener('scroll', () => {
        if (sectionsRef.current.scrollTop + offset >= deleteRef?.current?.offsetTop) {
          setViewing('delete');
        } else if (sectionsRef.current.scrollTop + offset >= sellersRef?.current?.offsetTop) {
          setViewing('sellers');
        } else if (sectionsRef.current.scrollTop + offset >= referentialRef?.current?.offsetTop) {
          setViewing('referential');
        }
      });
  }, []);

  // set todays date in createdAt and updatedAt if it is a new reference
  React.useEffect(() => {
    if (isNewReference) {
      setReference({
        ...reference,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    } else {
      dispatch(
        referenceActions.getReference(+workspaceId, +referentialId, res => {
          setReference(res);
          setReferenceResponse(res);
        }),
      );
    }
  }, [workspaceId, referentialId]);

  const [errors, setErrors] = React.useState<Map<string, string | (() => JSX.Element)>>(new Map());

  const setError = (key: string, error: string | (() => JSX.Element)) => {
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    setErrors(cloneErrors);
  };
  return (
    <Workspace
      isBuyerWorkspace={true}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.referential'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_REFERENTIAL,
                workspaceId: workspaceId + '',
              }),
            );
          },
        },
      ]}
      subtitle={''}
      title={!isNewReference ? referenceResponse.name :__('Components.Referential.reference.new')}
      tabSelected="referential"
      workspaceId={Number(workspaceId)}
    >
      <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
      <S.Container>
        <FormMenu
          sections={[
            {
              id: 'referential',
              title: __('Components.Referential.reference.menu'),
              icon: 'Edit',
            },
            ...(isNewReference
              ? []
              : [
                  {
                    id: 'sellers',
                    title: __('Components.Referential.reference.sellers'),
                    icon: 'Team-icon',
                  } as any,
                  {
                    id: 'delete',
                    title: __('Components.Referential.reference.delete'),
                    icon: 'Trash',
                  } as any,
                ]),
          ]}
          selected={viewing}
        />
        <FormContainer
          className="WorkspaceSupplierEdit-FormContainer"
          canSave={hasChanges && !errors.size && !!reference.productKindId && !!reference.name}
          save={() => {
            isNewReference
              ? dispatch(
                  referenceActions.referenceCreate(+workspaceId, reference, () => {
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_REFERENTIAL,
                        workspaceId: workspaceId + '',
                      }),
                    );
                  }),
                )
              : dispatch(
                  referenceActions.referenceUpdate(+workspaceId, reference, () => {
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_REFERENTIAL,
                        workspaceId: workspaceId + '',
                      }),
                    );
                  }),
                );
            setHasChanges(false);
          }}
          sectionsRef={sectionsRef}
        >
          <Referential
            setReference={setReference}
            reference={reference}
            referenceResponse={referenceResponse}
            productKinds={productKinds}
            referentialRef={referentialRef}
            setHasChanges={setHasChanges}
            isNewReference={isNewReference}
            errors={errors}
            setError={setError}
          />
          {isNewReference ? null : (
            <>
              <Sellers sellersRef={sellersRef} referenceResponse={referenceResponse} workspaceId={workspaceId} />
              <Delete deleteRef={deleteRef} reference={reference} deleteReference={deleteReference} />
            </>
          )}
        </FormContainer>
      </S.Container>
    </Workspace>
  );

  function deleteReference() {
    dispatch(
      modalActions.modalOpen(
        __('Components.Referential.reference.delete_title'),
        async () => {
          try {
            await dispatch(referenceActions.referenceDelete(+workspaceId, referenceResponse.id));
          } catch (err) {
            return null;
          }
          dispatch(modalActions.modalClose());
          history.goBack();
          dispatch(
            notificationsActions.notificationShow(
              {
                style: 'success',
                title: __('Components.Referential.reference.delete_success_title'),
                subtitle: __('Components.Referential.reference.delete_success_description'),
                closable: true,
              },
              3000,
            ),
          );
        },
        {
          showCancelButton: true,
          buttonText: __('Components.Referential.reference.delete'),
          text2: __('Components.Referential.reference.delete_description'),
          actionType: 'dangerous',
          icon: IMAGES.error,
        },
        'nice',
      ),
    );
    setHasChanges(false);
  }
};
const Referential = React.memo(
  ({
    referentialRef,
    setReference,
    reference,
    referenceResponse,
    setHasChanges,
    productKinds,
    isNewReference,
    errors,
    setError,
  }: {
    productKinds: Array<IProductKind>;
    referentialRef: React.RefObject<HTMLDivElement>;
    setReference: (reference: IReference) => void;
    reference: IReference;
    referenceResponse: IReferenceResponse;
    setHasChanges: (hasChanges: boolean) => void;
    isNewReference: boolean;
    errors: Map<string, string | (() => JSX.Element)>;
    setError: (key: string, error: string | (() => JSX.Element)) => void;
  }) => {
    const productKind = productKinds.find(pk => pk.id === reference.productKindId);
    const newReference = isNewReference ? 'title' : 'title_detail';
    const translatedProductKinds = productKinds
      .map(pk => ({
        name: utils.translateProductKind(pk.name),
        id: pk.id,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <FormSection id="referential" title={__(`Components.Referential.reference.${newReference}`)} sectionRef={referentialRef}>
        <S.GreyText>{__('Components.Referential.reference.description')}</S.GreyText>
        <InputWithLabel isRequired={true} label={__('Components.Referential.reference.name')}>
          <S.Input
            name="name"
            placeholder={__('Components.Referential.reference.name_placeholder')}
            value={isNewReference ? '' : referenceResponse.name}
            trim={true}
            isRequired={true}
            type="text"
            onChange={(n, v) => {
              setError(n, v ? '' : 'empty');
              setReference({ ...reference, name: v.toString() });
              setHasChanges(true);
            }}
            onBlur={(n, value) => {
              setError(n, value ? '' : 'empty');
            }}
            disabled={false}
            hasError={!!errors.get('name')}
          />
        </InputWithLabel>
        <InputWithLabel isRequired={true} label={__('Components.Referential.reference.product')} disabled={false}>
          <Select
            containerMargin="0"
            width="450px"
            name="productkinds"
            onChange={(n, value) => {
              setReference({
                ...reference,
                productKindId: translatedProductKinds.find(pf => pf.name === value)?.id,
              });
              setHasChanges(true);
            }}
            options={translatedProductKinds.map(pk => ({ label: pk.name, value: pk.name }))}
            value={utils.translateProductKind(productKind?.name)}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={false}
          label={__('Components.Referential.reference.code')}
          description={__('Components.Referential.reference.code_description')}
          disabled={false}
        >
          <S.Input
            name="code"
            placeholder={__('Components.Referential.reference.code')}
            value={referenceResponse.description || ''}
            trim={true}
            isRequired={false}
            onChange={(n, v) => null}
            onBlur={(n, value) => {
              setReference({ ...reference, description: value.toString() });
              setHasChanges(true);
            }}
            disabled={false}
            hasError={false}
          />
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Sellers = React.memo(
  ({
    sellersRef,
    referenceResponse,
    workspaceId,
  }: {
    sellersRef: React.RefObject<HTMLDivElement>;
    referenceResponse: IReferenceResponse;
    workspaceId: string;
  }) => {
    const workspaceIdNumber = Number(workspaceId);
    const supplierIds = referenceResponse.suppliers.map(s => Number(s.id));

    const suppliers = useSelector<any, Array<ISupplier> | null>(state =>
      supplierIds.map(supplierId => buyerWorkspaceSelectors.getSupplier(workspaceIdNumber, supplierId)(state)),
    );
    const referenceSuppliers = referenceResponse?.suppliers;

    return (
      <FormSection id="sellers" title={__('Components.Referential.reference.sellers_title')} sectionRef={sellersRef}>
        <S.FormCol className="sellers">
          {referenceResponse.suppliers?.length === 0 ? (
            <S.GreyText>{__('Components.Referential.reference.providers')}</S.GreyText>
          ) : (
            <Table
              className="sellers-table"
              productColumns={getDefaultColumns()}
              columns={getColumns()}
              emptyText=""
              isReadRow={() => true}
              onClickRow={() => null}
              rowCursor="inherit"
              selectable={false}
              values={referenceSuppliers.map((refSupplier, idx) => {
                const supplier = suppliers.find(s => s?.userId === +refSupplier?.id);
                return { supplier, refSupplier, key: idx };
              })}
            />
          )}
        </S.FormCol>
      </FormSection>
    );
  },
);

const Delete = React.memo(
  ({
    deleteRef,
    reference,
    deleteReference,
  }: {
    deleteRef: React.RefObject<HTMLDivElement>;
    reference: IReference;
    deleteReference: () => void;
  }) => {
    return (
      <FormSection id="delete" title={__('Components.Referential.reference.delete')} sectionRef={deleteRef}>
        <S.FormCol className="delete">
          <S.DeleteButton type="delete" iconSize="15px" iconName="Trash" onClick={deleteReference}>
            {__('Components.Referential.reference.delete')}
          </S.DeleteButton>
        </S.FormCol>
      </FormSection>
    );
  },
);

export default React.memo(ReferenceEdit);
