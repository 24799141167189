import { utils } from 'common-services';
import * as React from 'react';

import { LettersAvatar } from '../../atoms';
import * as S from './MiniImages.styled';

export interface IProps {
  className?: string;
  count: number;
  from?: 'table' | 'other';
  images?: Array<string>;
  mode?: 'images' | 'icon' | 'imagesAndPersons';
  onImageClick?: (selected: number) => void;
  persons?: Array<ILightContact>;
  showZeroCase?: boolean;
  imagesAndPersons?: { imageUrl?: Array<string>; person?: Array<ILightContact> };
}

/**
 * Mini images in a line (4 images max)
 */
const MiniImages = ({
  className,
  count,
  from = 'other',
  images,
  mode = 'images',
  onImageClick,
  persons,
  showZeroCase = true,
  imagesAndPersons,
}: IProps) => {
  const renderPersons = React.useCallback(() => {
    if (persons?.length)
      return persons.map((person, index) => {
        return (
          <S.ArticleImageContainer className="article-img" key={index}>
            <LettersAvatar
              avatarColor={person?.avatarColor || utils.getAvatarColor(person?.name || 'unkown')}
              borderRadius="4px"
              img={person?.avatar}
              size={36}
              text={person?.name}
            />
          </S.ArticleImageContainer>
        );
      });
  }, [persons]);

  const renderImages = React.useCallback(() => {
    if (images?.length)
      return images?.map((imageUrl, index) => {
        return (
          <S.ArticleImageContainer className="article-img" key={index}>
            <S.ArticleImage
              src={imageUrl}
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onImageClick?.(index);
              }}
            />
          </S.ArticleImageContainer>
        );
      });
  }, [images, onImageClick]);

  const renderImagesAndPersons = React.useCallback(() => {
    if (imagesAndPersons.imageUrl?.length) {
      return imagesAndPersons.imageUrl.map((imageUrl, index) => {
        if (imageUrl !== '') {
          return (
            <S.ArticleImageContainer key={index}>
              <S.ArticleImage
                src={imageUrl}
                onClick={e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  onImageClick?.(index);
                }}
              />
            </S.ArticleImageContainer>
          );
        } else {
          return (
            <S.ArticleImageContainer key={index}>
              <LettersAvatar
                avatarColor={
                  imagesAndPersons?.person[index]?.avatarColor ||
                  utils.getAvatarColor(imagesAndPersons?.person[index]?.name || 'unknown')
                }
                borderRadius="4px"
                img={imagesAndPersons?.person[index]?.avatar}
                size={36}
                text={imagesAndPersons?.person[index]?.name}
              />
            </S.ArticleImageContainer>
          );
        }
      });
    }
  }, [imagesAndPersons, onImageClick]);

  if (!images?.filter(i => i).length && !persons?.length && !count)
    return showZeroCase ? <S.TextCountArticles>0</S.TextCountArticles> : null;
  return (
    <>
      <S.ArticleImagesRow className={className} from={from}>
        {mode === 'imagesAndPersons' ? (
          renderImagesAndPersons()
        ) : mode === 'images' ? (
          <>{persons ? renderPersons() : renderImages()}</>
        ) : (
          <S.PeopleIconContainer>
            <S.PeopleIcon name="Team-icon" disableHover={true} />
          </S.PeopleIconContainer>
        )}
        <S.TextCountArticles>{count}</S.TextCountArticles>
      </S.ArticleImagesRow>
      <S.TextCountArticlesResponsive from={from}>{count}</S.TextCountArticlesResponsive>
    </>
  );
};

export default MiniImages;
