import {
  __,
  ColumnConfigType,
  date,
  IClient,
  IContact,
  ImageGalleryObject,
  IPriceGroup,
  IProdType,
  IUser,
  prodTypeSelectors,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { getPriceGroupColumns } from '../../../constants';
import { navSelectors } from '../../../selectors';
import Members from '../Members';
import MiniImages from '../MiniImages';
import Table from '../Table';
import { IColumn } from '../Table/Table.component';
import * as S from './PriceGroupTable.styled';

export interface IProps {
  className?: string;
  clients?: { [id: number]: Array<IClient> };
  contacts: { [cId: number]: IContact };
  isQuoterMode: boolean;
  me: IUser;
  onItemClick: (priceGroup: IPriceGroup) => void;
  priceGroups: Array<IPriceGroup>;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  product?: IProduct;
  selecteds?: Array<string>;
}

/**
 * Price groups table for team
 */
const PriceGroupTable: React.FC<IProps> = ({
  className = 'pricegroup-table',
  priceGroups,
  product,
  contacts,
  isQuoterMode,
  me,
  onItemClick,
  selecteds,
  touchImage,
}) => {
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);

  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const dispatch = useDispatch<any>();
  React.useEffect(() => {
    if (me.id && workspaceSelected?.id)
      dispatch(sellerWorkspaceActions.tableVisibilityConfigGet(me.id, workspaceSelected.id, 'pricegroup'));
  }, [dispatch, me, workspaceSelected?.id]);

  const getColumns = React.useCallback(() => {
    const columnConfigWorkspace = useSelector(
      sellerWorkspaceSelectors.getColumnConfig(workspaceSelected?.id, 'pricegroup'),
    );
    const result = product
      ? [
          getColumnField({ name: 'checkbox', order: -1, visible: true }, isQuoterMode, {
            getElement: (data: IPriceGroup) => (
              <S.CheckBox className="add-pricegroups-checkbox" isChecked={selecteds?.includes(data.priceGroupId)} />
            ),
          }),
        ]
      : [];

    const configCol = columnConfigWorkspace?.columns?.length
      ? (columnConfigWorkspace?.columns as Array<IColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig('pricegroup' as ColumnConfigType);
    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'title':
            result.push(
              getColumnField(column, isQuoterMode, {
                getElement: (data: IPriceGroup) => {
                  return <S.TextName>{data.name || data.description || data.priceGroupId || ''}</S.TextName>;
                },
              }),
            );
            break;
          case 'products':
            result.push(
              getColumnField(column, isQuoterMode, {
                getElement: (data: IPriceGroup) => {
                  const productImages = getProductImages(data, prodTypes);
                  const fourProductImages = productImages.slice(0, 4);
                  return fourProductImages.length ? (
                    <MiniImages
                      className="pricegroups-table-images"
                      images={fourProductImages}
                      onImageClick={index => onImageClick(touchImage, productImages, index)}
                      count={data.prices.length}
                    />
                  ) : (
                    <S.TextBlack>{__('WorkspacePriceGroups.products', { count: data.prices.length })}</S.TextBlack>
                  );
                },
              }),
            );
            break;
          case 'included-clients':
            result.push(
              getColumnField(column, isQuoterMode, {
                getElement: (data: IPriceGroup) => {
                  return data.clients.length ? (
                    <Members
                      className="price-group-members"
                      contacts={contacts}
                      clients={data.clients}
                      disableHover={true}
                      me={me}
                      members={data.clients.map(c => ({
                        userId: c.userId,
                      }))}
                      size={36}
                    />
                  ) : (
                    <S.TextBlack>{__('WorkspacePriceGroups.products.zero')}</S.TextBlack>
                  );
                },
              }),
            );
            break;
          case 'transport-cost':
            result.push(
              getColumnField(column, isQuoterMode, {
                getElement: (data: IPriceGroup) => {
                  return (
                    <S.TextBlack>
                      {data.shouldAddTransportCost
                        ? __('Constants.TransportCostIncluded.not_included')
                        : __('Constants.TransportCostIncluded.included')}
                    </S.TextBlack>
                  );
                },
              }),
            );
            break;
          case 'updated':
            result.push(
              getColumnField(column, isQuoterMode, {
                getValue: (data: IPriceGroup) => {
                  const lastUpdateDate = data.updatedAt;
                  const hourFormat = me.settings.hourFormat;
                  const dateFormatted =
                    date.formatLongDate(lastUpdateDate!) + ' · ' + date.formatTime(lastUpdateDate!, hourFormat);

                  const lastChangedPersonName = data.lastChangedBy
                    ? getLastChangeByContactName(data.lastChangedBy, contacts, me)
                    : undefined;
                  return (
                    utils.firstToUpperCase(dateFormatted) + (lastChangedPersonName ? ' · ' + lastChangedPersonName : '')
                  );
                },
              }),
            );

            break;
          case 'status':
            result.push(
              getColumnField(column, isQuoterMode, {
                getValue: (data: IPriceGroup) =>
                  data.status === 'active' ? __('Constants.Status.active') : __('Constants.Status.inactive'),
              }),
            );

            break;

          default:
            result.push(getColumnField(column, isQuoterMode));
            break;
        }
      });
    return result;
  }, []);

  const columns: Array<IColumn> = [
    ...(product
      ? [
          {
            title: '',
            width: '55px',
            element: (data: IPriceGroup) =>
              product ? (
                <S.CheckBox className="add-pricegroups-checkbox" isChecked={selecteds?.includes(data.priceGroupId)} />
              ) : null,
          },
        ]
      : []),
    {
      title: isQuoterMode ? __('WorkspaceMargins.table.name') : __('WorkspacePriceGroups.table.name'),
      element: (data: IPriceGroup) => (
        <S.TextName>{data.name || data.description || data.priceGroupId || ''}</S.TextName>
      ),
    },
    {
      title: __('WorkspacePriceGroups.table.products'),
      element: (data: IPriceGroup) => {
        const productImages = getProductImages(data, prodTypes);
        const fourProductImages = productImages.slice(0, 4);
        return fourProductImages.length ? (
          <MiniImages
            className="pricegroups-table-images"
            images={fourProductImages}
            onImageClick={index => onImageClick(touchImage, productImages, index)}
            count={data.prices.length}
          />
        ) : (
          <S.TextBlack>{__('WorkspacePriceGroups.products', { count: data.prices.length })}</S.TextBlack>
        );
      },
    },
    {
      title: __('WorkspacePriceGroups.table.clients'),
      element: (data: IPriceGroup) =>
        data.clients.length ? (
          <Members
            className="price-group-members"
            contacts={contacts}
            clients={data.clients}
            disableHover={true}
            me={me}
            members={data.clients.map(c => ({
              userId: c.userId,
            }))}
            size={36}
          />
        ) : (
          <S.TextBlack>{__('WorkspacePriceGroups.products.zero')}</S.TextBlack>
        ),
    },
    ...(isQuoterMode
      ? []
      : [
          {
            title: __('WorkspacePriceGroupEdit.include_transport_cost.title'),
            element: (data: IPriceGroup) => (
              <S.TextBlack>
                {data.shouldAddTransportCost
                  ? __('Constants.TransportCostIncluded.not_included')
                  : __('Constants.TransportCostIncluded.included')}
              </S.TextBlack>
            ),
          },
        ]),
    ...(product
      ? [
          {
            title: __('WorkspacePriceGroups.table.last_update'),
            value: (data: IPriceGroup) => {
              const lastUpdateDate = data.updatedAt;
              const hourFormat = me.settings.hourFormat;
              const dateFormatted =
                date.formatLongDate(lastUpdateDate!) + ' · ' + date.formatTime(lastUpdateDate!, hourFormat);

              const lastChangedPersonName = data.lastChangedBy
                ? getLastChangeByContactName(data.lastChangedBy, contacts, me)
                : undefined;
              return (
                utils.firstToUpperCase(dateFormatted) + (lastChangedPersonName ? ' · ' + lastChangedPersonName : '')
              );
            },
          },
        ]
      : []),
    {
      title: __('WorkspacePriceGroups.table.status'),
      value: (data: IPriceGroup) =>
        data.status === 'active' ? __('Constants.Status.active') : __('Constants.Status.inactive'),
    },
  ];

  const tableColumns = config.TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS.enabled ? getColumns().filter(c => c) : columns;
  return (
    <Table
      className={className}
      values={priceGroups}
      onClickRow={onItemClick}
      emptyText={''}
      columns={tableColumns}
      nonRemovableColumns={['title']}
      selectable={false}
      showStickyHeader={true}
      scrollClassName="price-groups-scroll"
      showCustomColumns={config.TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS.enabled}
      configId={'pricegroup'}
      productColumns={getPriceGroupColumns(isQuoterMode)}
    />
  );
};

export default PriceGroupTable;

function getProductImages(priceGroup: IPriceGroup, prodTypes: Record<string, IProdType>) {
  return priceGroup.prices.reduce((acc, price) => {
    if (price.productImageUrl) acc.push(price.productImageUrl);
    else if (prodTypes[price.productType] && prodTypes[price.productType].defaultImageUrl)
      acc.push(prodTypes[price.productType].defaultImageUrl);
    return acc;
  }, [] as Array<string>);
}

function getLastChangeByContactName(lastChangedBy: number, contacts: Record<number, IContact>, me: IUser) {
  if (me.id === lastChangedBy) return me.name;
  return contacts[lastChangedBy]?.name;
}

function onImageClick(
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void,
  imagesArray: Array<string>,
  index: number,
) {
  touchImage(
    imagesArray.map(url => ({ src: url })),
    index,
  );
}
/**
 * Get column according to a custom config
 */
function getColumnField(
  columnConfig: IColumnConfig,
  isQuoterMode: boolean,
  options?: {
    getValue?: (data: IPriceGroup, idx: number, rowIdx: number) => string | number;
    getElement?: (data: IPriceGroup, idx: number, rowIdx: number) => React.ReactElement;
  },
): IColumn {
  const { getValue, getElement } = options || {};
  switch (columnConfig.name) {
    case 'title':
      return {
        id: 'title',
        title: isQuoterMode ? __('WorkspaceMargins.table.name') : __('WorkspacePriceGroups.table.name'),
        element: getElement,
        minWidth: '120px',
        width: '15%',
      };
    case 'products':
      return {
        id: 'products',
        title: __('WorkspacePriceGroups.table.products'),
        element: getElement,
        minWidth: '120px',
        width: '15%',
      };
    case 'included-clients':
      return {
        id: 'included-clients',
        title: __('WorkspacePriceGroups.table.clients'),
        element: getElement,
        minWidth: '120px',
        width: '15%',
      };
    case 'transport-cost':
      return {
        id: 'transport-cost',
        title: __('WorkspacePriceGroupEdit.include_transport_cost.title'),
        element: getElement,
        minWidth: '70px',
        width: '9%',
      };
    case 'status':
      return {
        id: 'status',
        title: __('WorkspacePriceGroups.table.status'),
        value: getValue,
        minWidth: '70px',
        width: '9%',
      };
    case 'updated':
      return {
        id: 'updated',
        title: __('WorkspacePriceGroups.table.last_update'),
        value: getValue,
        minWidth: '70px',
        width: '22%',
      };

    default:
      return undefined;
  }
}
