import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  side?: 'left' | 'right';
  messageRead?: boolean;
}

export const TextSizeContainer = styled(ColumnContainer)`
  width: 290px;
`;

export const TextWrap = styled.span`
  color: ${(p: IStyledProps) => p.theme!.colors.black};
  float: left;
  display: block;
  font-weight: ${(p: IStyledProps) => p.theme!.fontWeight.Semi};
  margin-right: ${(p: IStyledProps) => p.theme.paddingView};
  font-size: ${(p: IStyledProps) => p.theme!.fontSize.normal};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Download = styled.span`
  font-size: inherit;
  margin-left: 5px;
  color: ${(p: IStyledProps) => p.theme!.colors.blue1};
  cursor: pointer;
`;

export const Size = styled.span`
  font-size: inherit;
`;

export const DownloadContainer = styled.div`
  display: flex;
`;

export const Icon = styled(FontIcon)`
  align-self: center;
  max-width: 30px;
  font-size: ${(p: IStyledProps) => p.theme!.fontSize.big};
  text-align: center;
  vertical-align: middle;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
`;

export const DocumentIcon = styled(FontIcon)`
  align-self: center;
  width: 40px;
  height: 40px;
  font-size: 40px;
  text-align: center;
  vertical-align: middle;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  margin: 0;
  padding: 5px 7px;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  width: 400px;
  max-width: 400px;
  &:hover {
    opacity: 0.75;
  }
`;

export const MimeTypeLogo = styled.img`
  width: 45px;
  height: 45px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  object-fit: cover;
  align-self: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const TextWrapper = styled(ColumnContainer)`
  width: auto;
`;

export const TextItalic = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-style: italic;
  padding-right: 2px;
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const KebabIconContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: end;
  justify-content: center;
`;
