import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel, Tooltip } from '../../atoms';

interface IStyledProps {
  containerPadding?: number;
  hasOverflowX?: boolean;
  numHeaders?: number;
  tagColor?: string;
  theme: ITheme;
  withoutPaddingBottom?: boolean;
  withoutPaddingTop?: boolean;
}

export const ContainerWrapper = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-height: max-content;
  ${(p: IStyledProps) =>
    typeof p.numHeaders !== undefined
      ? css`
          min-height: calc(${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight} * ${p.numHeaders});
        `
      : ''};
`;

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  ${(p: IStyledProps) =>
    p.containerPadding
      ? css`
          padding: ${p.theme.paddingSize(p.containerPadding)};
        `
      : css`
          padding: ${p.theme.paddingSize(5)} ${p.theme.paddingSize(6)};
        `}
  ${(p: IStyledProps) =>
    p.withoutPaddingBottom &&
    css`
      padding-bottom: 0;
    `};
  width: 100%;
  transition: background-color ease-in 0.3s;
`;
export const TitleWrapper = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
`;
export const Tag = styled(TextLabel)<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.tagColor || p.theme.colors.blue1};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;
export const Title = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Wrapper = styled(ColumnContainer)<IStyledProps>`
  position: relative;

  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 0;
  max-width: 100%;
  ${(p: IStyledProps) =>
    p.hasOverflowX &&
    css`
      overflow-x: auto;
    `};
  ${(p: IStyledProps) =>
    p.withoutPaddingTop &&
    css`
      padding-top: 0;
    `};
`;

export const Subtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const SubtitleLink = styled(Subtitle)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const TooltipTitle = styled(Tooltip)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
