import {
  __,
  associateReferenceActions,
  associateReferenceActionsApi,
  associateReferenceSelectors,
  modalActions,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { SelectClientModal } from '../../../molecules';
import * as S from './components/ReferenceListDetail.style';
import ProductFormComponent from './components/ProductFormSection';
import AssociatedReferenceFormComponent from './components/AddAssociatedReferenceFormSection';
import { useDispatch, useSelector } from 'react-redux';
import { getSections } from './components/ReferenceListDetail.constants';
import { highlightFormSection } from '../../../molecules/FormMenu/FormMenu.component';
import { Dispatch } from 'redux';
import ReferenceModalComponent from './components/DetailReferenceModal';
import ValidateReferenceComponent from './components/ValidateReference';
import { IReduxState } from '../../../../reducers';
import { ValidateReferenceState, transformToLabelValueList } from './components/ConstantsAndFunctions';
import { IMAGES } from '../../../../assets';
import { IAssociationsReference } from 'common-services/dist/associate-reference/interface';
import { useParams } from 'react-router-dom';
import history from '../../../../history';
import getPath from '../../../../util/routes';
import { ROUTE_PATHS } from '../../../../constants';

const ReferenceListDetail: React.FC = () => {
  const sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  const productRef: React.RefObject<HTMLDivElement> = React.createRef();
  const associatedReferenceRef: React.RefObject<HTMLDivElement> = React.createRef();
  const [showClientModal, setShowClientModal] = React.useState<boolean>(false);
  const [clientSelectedId, setClientSelectedId] = React.useState({ buyerWorkspaceId: 0, imageUrl: '' });
  const [showReferenceModal, setShowReferenceModal] = React.useState<boolean>(false);
  const [viewing, setViewing] = React.useState('product');
  const me = useSelector(userSelectors.getUser);
  const workspaceSellerId = useSelector((state: IReduxState) => state.nav.workspaceSelected);
  const dispatch = useDispatch<Dispatch<any>>();
  const associateReferenceSelect = useSelector(associateReferenceSelectors.getAssociateReferenceSelect);
  const params = useParams<{ referenceId: string }>();
  const [validateReference, setValidateReference] = React.useState<ValidateReferenceState>({
    isPresent: false,
    reference: undefined,
  });
  const [productSelect, setProductSelect] = React.useState({
    label: '',
    value: '',
    id: 0,
    kindId: 0,
    kindName: '',
  });
  const [productReferenceList, setProductReferenceList] = React.useState([
    { label: '', value: '', id: 0, kindId: 0, kindName: '' },
  ]);

  const handleSelectClient = (buyerWorkspaceId: number, logo: string) => {
    dispatch(
      associateReferenceActionsApi.getListReferenceByProduct(buyerWorkspaceId.toString(), res => {
        setProductReferenceList(transformToLabelValueList(res));
      }),
    );
    const validateReferenceSelect = associateReferenceSelect.references.find(
      reference => reference.workspacePublicOverview?.id === buyerWorkspaceId,
    );
    setClientSelectedId({ buyerWorkspaceId, imageUrl: logo });
    if (validateReferenceSelect) {
      setValidateReference({
        isPresent: true,
        reference: validateReferenceSelect,
      });
    } else {
      setShowClientModal(false);
      setShowReferenceModal(true);
    }
  };

  const resetState = () => {
    setProductSelect({ label: '', value: '', id: 0, kindId: 0, kindName: '' });
    setClientSelectedId({ buyerWorkspaceId: 0, imageUrl: '' });
    setValidateReference({ isPresent: false, reference: undefined });
    setShowReferenceModal(false);
  };

  const handleDeleteReference = async (id: number) => {
    const referenceToDelete = associateReferenceSelect.references.find(reference => reference.id === id);
    dispatch(
      associateReferenceActionsApi.deleteAssociateReference(
        referenceToDelete.workspacePublicOverview.id,
        referenceToDelete.id,
        {
          products: [
            {
              sellerWorkspaceId: workspaceSellerId,
              sku: associateReferenceSelect.sku,
            },
          ],
        },
        () => {
          dispatch(associateReferenceActions.deleteReference(id));
        },
      ),
    );
  };

  const onShowDeleteModal = async (data: IAssociationsReference) => {
    dispatch(
      modalActions.modalOpen(
        __('Components.SellerOffer.referenceList.referenceListSelected.deleteReferenceModal.title'),
        () => {
          handleDeleteReference(data.id);
          modalActions.modalClose();
        },
        {
          icon: IMAGES.cautionGrape,
          text2: __('Components.SellerOffer.referenceList.referenceListSelected.deleteReferenceModal.message', {
            productReference: data.name,
          }),
          buttonText: __('Components.SellerOffer.referenceList.referenceListSelected.deleteReferenceModal.remove'),
          actionType: 'dangerous',
          showCancelButton: true,
        },
        'nice',
      ),
    );
  };

  const onAssociateReference = () => {
    const addReference = () => {
      dispatch(
        associateReferenceActionsApi.addAssociateReference(
          clientSelectedId.buyerWorkspaceId,
          productSelect.value,
          {
            products: [{ sellerWorkspaceId: workspaceSellerId, sku: associateReferenceSelect.sku }],
          },
          () => {
            dispatch(
              associateReferenceActions.addReference(
                {
                  id: productSelect.id,
                  name: productSelect.label,
                  kindId: productSelect.kindId,
                  workspacePublicOverview: {
                    id: clientSelectedId.buyerWorkspaceId,
                    pictureUrl: clientSelectedId.imageUrl,
                    publicName: '',
                  },
                  kindName: productSelect.kindName,
                  rank: 0,
                },
                clientSelectedId.buyerWorkspaceId,
              ),
            );
          },
        ),
      );
    };
    const deleteAndAddReference = () => {
      dispatch(
        associateReferenceActionsApi.deleteAssociateReference(
          validateReference.reference.workspacePublicOverview.id,
          validateReference.reference.id,
          {
            products: [
              {
                sellerWorkspaceId: workspaceSellerId,
                sku: associateReferenceSelect.sku,
              },
            ],
          },
          () => {
            dispatch(associateReferenceActions.deleteReference(validateReference.reference.id));
            addReference();
          },
        ),
      );
    };
    if (validateReference.reference) {
      deleteAndAddReference();
    } else {
      addReference();
    }

    resetState();
  };

  React.useEffect(() => {
    const offset = 200;
    const handleScroll = () => {
      if (sectionsRef.current) {
        const scrollTop = sectionsRef.current.scrollTop;
        const productOffset = productRef.current?.offsetTop || 0;
        const associatedReferenceOffset = associatedReferenceRef.current?.offsetTop || 0;
        if (scrollTop + offset >= associatedReferenceOffset) {
          if (viewing !== 'associated-reference') {
            setViewing('associated-reference');
            highlightFormSection('associated-reference');
          }
        } else if (scrollTop + offset >= productOffset) {
          if (viewing !== 'product') {
            setViewing('product');
            highlightFormSection('product');
          }
        }
      }
    };

    const sectionsEl = sectionsRef.current;
    if (sectionsEl) {
      sectionsEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (sectionsEl) {
        sectionsEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, [viewing]);

  React.useEffect(() => {
    dispatch(
      associateReferenceActionsApi.getProductAssociationBySku(workspaceSellerId, params.referenceId, res => {
        if (res.length === 0) {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST,
              workspaceId: workspaceSellerId.toString(),
            }),
          );
        } else {
          dispatch(associateReferenceActions.setAssociateReferenceSelect(res[0]));
        }
      }),
    );
  }, []);

  return (
    <>
      <S.Container>
        <S.ContentContainer>
          <S.MenuLeft sections={getSections()} selected={viewing} />
          <S.FormContainer sectionsRef={sectionsRef} save={() => null}>
            <ProductFormComponent data={associateReferenceSelect} productRef={productRef} />

            <AssociatedReferenceFormComponent
              data={associateReferenceSelect.references}
              associatedReferenceRef={associatedReferenceRef}
              setShowClientModal={setShowClientModal}
              onShowDeleteModal={onShowDeleteModal}
            />

            {showClientModal && (
              <SelectClientModal
                title={__('SelectClientModal.topic.references.title')}
                subtitle={__('SelectClientModal.topic.references.subtitle')}
                catalogId={workspaceSellerId}
                onClose={() => {
                  setShowClientModal(false);
                  setClientSelectedId({ buyerWorkspaceId: 0, imageUrl: '' });
                }}
                selectContact={(cId, cName, buyerWorkspaceId, logo) => {
                  handleSelectClient(buyerWorkspaceId, logo);
                }}
                me={me}
              />
            )}

            {showReferenceModal && (
              <ReferenceModalComponent
                productSelect={productSelect}
                products={productReferenceList}
                setClientSelectedId={setClientSelectedId}
                setProductSelect={setProductSelect}
                setShowReferenceModal={setShowReferenceModal}
                onAssociateReference={onAssociateReference}
              />
            )}

            {validateReference.isPresent && (
              <ValidateReferenceComponent
                setShowClientModal={setShowClientModal}
                setShowReferenceModal={setShowReferenceModal}
                setValidateReference={setValidateReference}
                validateReference={validateReference}
              />
            )}
          </S.FormContainer>
        </S.ContentContainer>
      </S.Container>
    </>
  );
};

export default ReferenceListDetail;
