import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { Badge as B, Button, ColumnContainer, FontIcon, RowContainer, TextLabel, WorkspaceAvatar as WA } from '../../atoms';

interface IStyledProps {
  isLast?: boolean;
  isSelected?: boolean;
  marginBottomMultiplier?: number;
  marginTopMultiplier?: number;
  showOneLine?: boolean;
  theme: ITheme;
}

export const ContainerModal = styled(ColumnContainer)`
  position: absolute;
  left: -4px;
  top: 27px;
  z-index: 50;
  width: 370px;
  max-width: 370px;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  margin: 0;
  min-height: min-content;
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  overflow: hidden;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const HeaderRow = styled(RowContainer)`
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

export const RowCenter = styled(RowContainer)`
  align-items: center;
`;

export const HeaderInfoCol = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: 215px;
`;

export const WorkspaceAvatar = styled(WA)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextRegular = styled(TextLabel)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${(p: IStyledProps) => p.theme.colors.black};

  ${(p: IStyledProps) =>
    p.showOneLine &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: ${p.theme.paddingSize()};
    `}
`;

export const TextSemiBold = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextPriceListUrl = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  cursor: pointer;
  padding-top: 4px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const LinkButton = styled(Button)`
  padding-bottom: 0;
  height: 28px;
`;

export const Divider = styled.div<IStyledProps>`
  height: 1px;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(p.marginTopMultiplier || 2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.marginBottomMultiplier || 2)};
`;

export const SettingsCol = styled(ColumnContainer)``;

export const SettingRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)} ${(p: IStyledProps) => p.theme.paddingSize(0.5)};

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ChevronIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 14px;
  line-height: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const WorkspaceLineRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey4 : p.theme.colors.transparent)};
  height: 42px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const CheckIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-size: 14px;
  line-height: 14px;
  width: 14px;
  height: 14px;
`;

export const BottomLink = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
`;

export const Badge = styled(B)``;
