import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  side?: 'left' | 'right';
  messageRead?: boolean;
  isCta?: boolean;
}

export const ZeroCaseWrapper = styled(ColumnContainer)`
  margin: 0 auto;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(8)} 0;
  width: 40%;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

export const Image = styled.img<IStyledProps>`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(6)} 0;
  max-width: 170px;
  min-width: 170px;
`;

export const ZeroCaseTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-align: center;
`;
export const ZeroCaseText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  text-align: center;
  display: inline-flex;
`;
export const TextBlue = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  display: contents;
  ${(p: IStyledProps) =>
    p.isCta &&
    css`
      cursor: pointer;
    `}
`;
export const ZeroCaseButton = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-align: center;
  display: inline-flex;
`;
