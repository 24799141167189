import { __, date, modalActions, orderActions, qs, RenderTrack } from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import { CHANNEL_SECTIONS, ROUTE_PATHS, THIRD_COLUMN_SECTIONS } from '../../../constants';
import OrdersList from '../../../screens/orders-list';
import getPath from '../../../util/routes';
import { BackButton } from '../../atoms';
import { NavigationTabs } from '../../molecules';
import * as S from './ContactOrders.styled';

export type OwnProps = {
  contact?: IContact;
  next?: () => void;
} & RouteComponentProps<{ channelId?: string; hashId?: string }>;

export interface StateProps {
  catalogs: { [id: number]: IWorkspace };
  contacts: { [cId: number]: IContact };
  facets: IFacets;
  filters: IOrderFilters;
  hasMore?: boolean;
  me: IUser;
  orders: Array<IOrder>;
  orderUnreads: IOrderUnreads;
  searchId: string;
  totalResults: number;
  workspaces: { [id: number]: IWorkspace };
}

export interface DispatchProps {
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  orderFiltersSet: typeof orderActions.orderFiltersSet;
  orderGetByHash: typeof orderActions.orderGetByHash;
  ordersAllMarkAsRead: typeof orderActions.ordersAllMarkAsRead;
  orderScrollSearch: typeof orderActions.orderScrollSearch;
  orderSearch: typeof orderActions.orderSearch;
  orderToggleReadStatus: typeof orderActions.toggleOrderReadStatus;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = StateProps & DispatchProps & OwnProps;

interface IState {
  fromMessages: boolean;
  dateRange?: IDateRange;
  // It's necessary to clone an order
  orderSelected?: IOrder;
  orderType?: IOrderType | 'unread';
  rightModalData?: {
    section: THIRD_COLUMN_SECTIONS | CHANNEL_SECTIONS;
    sectionId?: number;
    asSeller: boolean;
  };
}

/**
 * New version of contact orders page
 */
class ContactOrders extends React.PureComponent<IProps, IState> {
  private t: number;
  private lastDatesUsed: { beginDate: Date; endDate: Date };

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();

    const { dateRange, orderType } = this.getInitialFilters();

    this.state = {
      dateRange,
      fromMessages: (props.location.state as any)?.from === 'messages',
      orderType,
    };
  }

  public componentDidMount() {
    const { contact } = this.props;
    RenderTrack.track('ContactOrders', {
      contactId: contact && contact.id ? contact.id.toString() : '',
      renderTime: this.t,
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { contact } = this.props;
    const { orderType } = this.state;

    // Perform new search if contact has changed
    if ((prevProps.contact && prevProps.contact !== contact) || prevState.orderType !== orderType) {
      this.saveFiltersPerContact(prevProps.contact);
    }
  }

  public componentWillUnmount() {
    const { contact } = this.props;
    this.saveFiltersPerContact(contact);
  }

  public render() {
    const { contact, history, match, location, me, navigateChannelBySection, orderUnreads } = this.props;
    const { fromMessages, orderType } = this.state;
    const tabs = [
      ...(me.settings.isSeller && contact?.isBuyer
        ? [
            {
              id: 'sale',
              label: __('Components.OrdersList.my_sales'),
              action: () => this.setState({ orderType: 'sale' }),
            },
          ]
        : []),
      ...(me.settings.isBuyer && contact?.isSeller
        ? [
            {
              id: 'purchase',
              label: __('Components.OrdersList.my_purchases'),
              action: () => this.setState({ orderType: 'purchase' }),
            },
          ]
        : []),
      {
        id: 'unread',
        label: __('Components.OrdersList.title_unreads'),
        badge: orderUnreads[contact?.id]?.total,
        action: () => this.setState({ orderType: 'unread' }),
      },
    ];

    return (
      <>
        <S.Modal className="contact-orders-modal">
          <S.Container className="contact-orders-container">
            <S.BackContainer>
              <BackButton
                text={__('Showroom.back_to_chat', { name: contact?.name || '' })}
                onClick={() =>
                  navigateChannelBySection(CHANNEL_SECTIONS.MESSAGES, match.params.channelId, true, () =>
                    history.push(getPath({ path: ROUTE_PATHS.CHAT, channelId: match.params.channelId })),
                  )
                }
              />
            </S.BackContainer>
            <S.BodyContainer className="contact-orders-body-container">
              <S.SubHeaderContainer>
                <NavigationTabs selected={orderType} tabs={tabs} history={history} />
              </S.SubHeaderContainer>
              <OrdersList
                contactId={contact?.id}
                fromChat={true}
                fromMessages={fromMessages}
                hashId={match.params?.hashId}
                history={history}
                location={location}
                match={match}
                onlyUnreads={orderType === 'unread'}
                tabSelected={orderType}
                type={orderType === 'unread' ? undefined : orderType}
                updateUrl={this.updateUrl}
                viewMode={'order'}
              />
            </S.BodyContainer>
          </S.Container>
        </S.Modal>
      </>
    );
  }

  /**
   * Update the current url in the browser if navigate to/from a order.
   */
  private updateUrl = (searchState: ISearchOrder, dateRange: IDateRange, order?: IOrder | { hashId?: string }) => {
    const { history, match } = this.props;
    const { orderType } = this.state;
    const customDates = { beginDate: searchState.beginDate, endDate: searchState.endDate };

    const { beginDate, endDate } = dateRange === 'custom' ? customDates : date.getDatesFromRange(dateRange);
    history.replace(
      getPath({
        path: ROUTE_PATHS.CONTACT_ORDERS,
        channelId: match.params.channelId,
        hashId: order ? order?.hashId || 'new' : '',
      }).replace('?', '') +
        qs.stringify({
          range: dateRange,
          since: beginDate && beginDate.getTime(),
          type: orderType,
          until: endDate && endDate.getTime(),
        }),
    );
  };

  /**
   * Get initial filters for this contact
   */
  private getInitialFilters() {
    // Load selected orders from url params
    const { filters, location, me, match } = this.props;
    const { since, type, until, range } = qs.parse(location.search || '', ['range', 'since', 'type', 'until']) as {
      range?: IDateRange;
      since?: string;
      type?: string;
      until?: string;
    };
    const dateRange =
      range && ['all', 'today', 'yesterday', 'week', 'month', 'year', 'custom'].includes(range) ? range : filters.range;
    const defaultType = filters.type;
    const sinceTime = since && Number(since) ? Number(since) : filters.since;
    const untilTime = until && Number(until) ? Number(until) : filters.until;
    const beginDate = sinceTime ? new Date(sinceTime) : undefined;
    const endDate = untilTime ? new Date(untilTime) : undefined;
    const dates = date.getDatesFromRange(dateRange);
    const orderType =
      this.state?.orderType ||
      ((type || defaultType || match.params?.hashId
        ? type || defaultType
        : me.settings.isSeller
        ? 'sale'
        : 'purchase') as IOrderType);
    this.lastDatesUsed = { beginDate: beginDate || dates.beginDate, endDate: endDate || dates.endDate };

    return {
      dateRange: dateRange || 'all',
      orderType,
    };
  }

  /**
   * Save order filters for a contact
   */
  private saveFiltersPerContact(contact: IContact) {
    if (!contact) return;
    const { orderFiltersSet } = this.props;
    const { dateRange, orderType } = this.state;
    if (contact)
      orderFiltersSet(contact?.id, {
        range: dateRange,
        since: this.lastDatesUsed.beginDate ? this.lastDatesUsed.beginDate.getTime() : undefined,
        until: this.lastDatesUsed.endDate ? this.lastDatesUsed.endDate.getTime() : undefined,
        type: orderType as IOrderType,
      });
  }
}

export default ContactOrders;
