import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../../atoms';

interface IStyledProps {
  isSelected?: boolean;
  goNext?: boolean;
  theme: ITheme;
}

export const HeaderContainer = styled(RowContainer)`
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: center;
  padding: 5px ${(p: IStyledProps) => p.theme.paddingView};
  padding-bottom: 1px;
`;

export const BodyContainer = styled(RowContainer)`
  width: 100%;
  padding: 5px ${(p: IStyledProps) => p.theme.paddingObject};
  padding-top: 0;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 5px 8px;
  }
`;

export const GalleryCol = styled(ColumnContainer)`
  width: 48%;
  min-width: 135px;
  align-items: center;
`;

export const ProductImageRow = styled(RowContainer)`
  justify-content: center;
`;

export const ChevronContainer = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  margin: 0 2px;
  cursor: pointer;
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.light1};
  }
`;

export const ProductsCol = styled(ColumnContainer)`
  width: 52%;
  padding-left: 8px;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding-left: 4px;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin: 0 2px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextGrey1 = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: center;
`;

export const TextProduct = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.black : p.theme.colors.grey1)};
  line-height: 1.3;
  padding-bottom: 1px;
`;

export const TextGMV = styled(Text)`
  display: inline;
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  line-height: 1.3;
`;

export const ProductNameCol = styled(ColumnContainer)`
  width: 100%;
  margin: 1px 2px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  object-fit: cover;
`;
