import { __, IOfferSummary, utils } from 'common-services';
import * as React from 'react';

import { FacetFilter } from '../../atoms';
import * as S from './OfferFilter.styled';

function sortFacets(
  facets: Record<string, number>,
  sortBy: 'name' | 'count',
  order: 'asc' | 'desc' = 'asc',
): Record<string, number> {
  return Object.fromEntries(
    Object.entries(facets).sort(([keyA, valueA], [keyB, valueB]) => {
      if (sortBy === 'name') {
        // Check if the keys are strings
        return order === 'asc'
          ? (keyA as string).localeCompare(keyB as string)
          : (keyB as string).localeCompare(keyA as string);
      } else {
        // Compare the numerical values
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
    }),
  );
}

export interface IProps {
  catalog: IWorkspace;
  changeSearchState: (s: any) => void;
  clearFilters: () => void;
  className?: string;
  data?: Array<IOfferSummary>;
  numberOfHeaders?: number;
  onHide?: (hide: boolean) => void;
  searchState: any; // use correct type when its properly defined
  showClosed?: boolean;
  showOver?: 'always' | 'never' | 'only-ipad';
}

const Filters: React.FC<IProps> = ({
  catalog,
  changeSearchState,
  onHide,
  className,
  clearFilters,
  data,
  numberOfHeaders,
  searchState,
  showClosed,
  showOver = 'never',
}) => {
  const [hidden, setHidden] = React.useState(showClosed);
  React.useEffect(() => {
    setHidden(showClosed);
  }, [setHidden, showClosed]);

  const hasFilters = searchState.productKinds?.length || searchState.sellers?.length || searchState.warehouses?.length;

  // Group the offer summaries by reference
  const [references, setReferences] = React.useState<Array<Record<string, Array<IOfferSummary>>>>([]);
  React.useEffect(() => {
    if (data) {
      const grouped = data.reduce((acc, offer) => {
        const reference = offer.buyerReference.name;
        if (!acc[reference]) {
          acc[reference] = [];
        }
        acc[reference].push(offer);
        return acc;
      }, {} as unknown as Record<string, Array<IOfferSummary>>);
      setReferences(
        Object.keys(grouped).map(key => ({ [key]: grouped[key] })) as Array<Record<string, Array<IOfferSummary>>>,
      );
    }
  }, [data]);

  // Group the references by product kind
  const productKindFacets = references.reduce((acc, offers) => {
    Object.keys(offers).forEach(reference => {
      let seen = false;
      offers[reference].map(o => {
        if (!seen && (o.quantity > 0 || o.totalQuantity > 0) && o.buyerReference.kindName !== '') {
          const kindName = utils.translateProductKind(o.buyerReference.kindName);
          acc[kindName] = (acc[kindName] || 0) + 1;
          seen = true;
        }
      });
    });
    return acc;
  }, {} as any);

  const productKindsNames = data.reduce((group, offer) => {
    const kindName = utils.translateProductKind(offer.buyerReference.kindName);
    group[kindName] = offer.buyerReference.kindId;
    return group;
  }, {});

  const getProductKindId = (name: string) => {
    return productKindsNames[name];
  };
  const getProductKindsIds = (names: Array<string>) => {
    return names.map(name => productKindsNames[name]);
  };

  // Group the references by seller
  const sellerFacets = references.reduce((acc, offers) => {
    Object.keys(offers).forEach(reference => {
      const seen = {};
      offers[reference].map(o => {
        if (
          !seen[o.seller.name] &&
          (o.quantity > 0 || o.totalQuantity > 0) &&
          o.buyerReference.kindName !== '' &&
          o.seller.name !== ''
        ) {
          acc[o.seller.name] = (acc[o.seller.name] || 0) + 1;
          seen[o.seller.name] = true;
        }
      });
    });
    return acc;
  }, {} as any);

  const sellerNames = data.reduce((group, offer) => {
    group[offer.seller.name] = offer.seller.id;
    return group;
  }, {});

  const getSellerId = (name: string) => {
    return sellerNames[name];
  };
  const getSellersIds = (names: Array<string>) => {
    return names.map(name => sellerNames[name]);
  };

  // Group the references by warehouse
  const warehouseFacets = references.reduce((acc, offers) => {
    Object.keys(offers).forEach(reference => {
      const seen = {};
      offers[reference].map(o => {
        if (
          !seen[o.buyerWarehouseName] &&
          (o.quantity > 0 || o.totalQuantity > 0) &&
          o.buyerReference.kindName !== '' &&
          o.buyerWarehouseName !== ''
        ) {
          acc[o.buyerWarehouseName] = (acc[o.buyerWarehouseName] || 0) + 1;
          seen[o.buyerWarehouseName] = true;
        }
      });
    });
    return acc;
  }, {} as any);

  const warehouseNames = data.reduce((group, offer) => {
    group[offer.buyerWarehouseName] = offer.buyerWarehouseId;
    return group;
  }, {});

  const getWarehouseId = (name: string) => {
    return warehouseNames[name];
  };
  const getWarehousesIds = (names: Array<string>) => {
    return names.map(name => warehouseNames[name]);
  };

  return (
    <S.Wrapper hide={hidden} showOver={showOver} numberOfHeaders={numberOfHeaders}>
      <S.FacetsContainer className={className} hide={hidden}>
        <S.Header>
          {hasFilters ? (
            <S.Clear withoutPadding={true} type="skip" hide={hidden} onClick={clearFilters}>
              {__('Facets.clear')}
            </S.Clear>
          ) : null}
          <S.Title>{__('Facets.filter_by')}</S.Title>
          <S.CloseIcon
            name="Close"
            onClick={() => {
              onHide?.(true);
              setHidden(true);
            }}
            hide={hidden}
          />
        </S.Header>
        <S.FiltersBody>
          {Object.keys(productKindFacets).length ? (
            <FacetFilter
              title={__('Components.OffersList.productKind')}
              options={Object.keys(sortFacets(productKindFacets, 'name', 'asc')).map(productKind => ({
                name: productKind,
                key: productKind,
                count: productKindFacets[productKind],
                checked: searchState?.productKinds?.includes(getProductKindId(productKind)),
              }))}
              numVisibleOptions={3}
              startFolded={false}
              noCountSort={true}
              onChange={v =>
                changeSearchState({ ...searchState, productKinds: getProductKindsIds(v as Array<string>) })
              }
            />
          ) : null}
          {Object.keys(warehouseFacets).length ? (
            <FacetFilter
              title={__('Components.OffersList.address')}
              options={Object.keys(sortFacets(warehouseFacets, 'name', 'asc')).map(warehouse => ({
                name: warehouse,
                key: warehouse,
                count: warehouseFacets[warehouse],
                checked: searchState?.warehouses?.includes(getWarehouseId(warehouse)),
              }))}
              numVisibleOptions={3}
              startFolded={false}
              noCountSort={true}
              onChange={v => changeSearchState({ ...searchState, warehouses: getWarehousesIds(v as Array<string>) })}
            />
          ) : null}
          {Object.keys(sellerFacets).length ? (
            <FacetFilter
              title={__('Components.OffersList.supplier')}
              options={Object.keys(sortFacets(sellerFacets, 'name', 'asc')).map(seller => ({
                name: seller,
                key: seller,
                count: sellerFacets[seller],
                checked: searchState?.sellers?.includes(getSellerId(seller)),
              }))}
              numVisibleOptions={3}
              startFolded={false}
              noCountSort={true}
              onChange={v => changeSearchState({ ...searchState, sellers: getSellersIds(v as Array<string>) })}
            />
          ) : null}
        </S.FiltersBody>
        <S.ApplyButton
          type="principal"
          onClick={() => {
            onHide?.(true);
            setHidden(true);
          }}
        >
          {__('Facets.apply_filters')}
        </S.ApplyButton>
      </S.FacetsContainer>
    </S.Wrapper>
  );
};

export default React.memo(Filters);
