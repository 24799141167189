import {
  Api,
  contactActions,
  ICountry,
  IGlobalWeb,
  IUser,
  modalActions,
  notificationsActions,
  userActions,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as appActionsNav from '../actions/nav';
import * as webUserActions from '../actions/user';
import FontIcon from '../components/atoms/FontIcon';
import LoginRegisterFlow from '../components/organisms/LoginRegisterFlow';
import SmallScreen from '../components/organisms/SmallScreen';
import { REGISTER_STEPS } from '../constants';
import { IReduxState } from '../reducers';
import styled, { css, ITheme } from '../styled-components';

declare var global: IGlobalWeb;

interface IStyledProps {
  authStep?: REGISTER_STEPS;
  theme: ITheme;
}

interface IDispatchProps {
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  hideLoading: typeof modalActions.hideLoading;
  hideLogin: () => void;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navAuthAction: typeof appActionsNav.navAuthAction;
  navResetAuthAction: typeof appActionsNav.navResetAuthAction;
  register: typeof webUserActions.register;
  resendCode: typeof webUserActions.resendCode;
  resetPassword: typeof userActions.resetPassword;
  resetUser: typeof webUserActions.resetUser;
  scanContacts: typeof contactActions.scanContacts;
  setRegisterInfo: typeof webUserActions.setRegisterInfo;
  showLoading: typeof modalActions.showLoading;
  showLogin: typeof userActions.showLogin;
  updatePassword: typeof webUserActions.updatePassword;
  updateSettings: typeof userActions.updateSettings;
  userExists: typeof userActions.userExists;
  userSet: typeof userActions.userSet;
  isEmailValid: typeof userActions.isEmailValid;
  verifyEmailCode: typeof webUserActions.verifyEmailCode;
  verifyPhone: typeof webUserActions.verifyPhone;
  verifyPhoneCode: typeof webUserActions.verifyPhoneCode;
  verifyUser: typeof userActions.verifyUserData;
  webloginOauth: typeof webUserActions.webloginOauth;
  webloginPassword: typeof webUserActions.webloginPassword;
  notificationShow: typeof notificationsActions.notificationShow;
}

interface IProps {
  authStep?: REGISTER_STEPS;
  closeable?: boolean;
  contactUrl?: string;
  countries: { [k: string]: ICountry };
  defaultEmail?: string;
  defaultMode?: 'phone' | 'email';
  error?: string;
  history: History;
  isRegister?: boolean;
  lang?: LOCALE;
  loading: boolean;
  me: IUser;
  open: boolean;
  redirect?: () => void;
}

type ILoginModalProps = IProps & IDispatchProps;

function mapStateToProps(state: IReduxState, props: RouteComponentProps): IProps {
  const { loading, error, showingLogin, showingRegister, loginLanguage, user, redirectOnLogin, contactUrl } =
    state.user;
  return {
    authStep: state.nav.auth,
    contactUrl,
    countries: state.country.countries,
    defaultEmail: global.localStorage?.getItem('email_public'),
    defaultMode: global.localStorage?.getItem('email_public') ? 'email' : 'phone',
    error,
    history: props.history,
    isRegister: showingRegister,
    lang: loginLanguage,
    loading,
    me: user,
    open: showingLogin || showingRegister,
    redirect: redirectOnLogin,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, props: any): IDispatchProps {
  return bindActionCreators(
    {
      addContactByPricelistUrl: contactActions.addContactByPricelistUrl,
      hideLoading: modalActions.hideLoading,
      hideLogin: userActions.hideLogin,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navAuthAction: appActionsNav.navAuthAction,
      navResetAuthAction: appActionsNav.navResetAuthAction,
      notificationShow: notificationsActions.notificationShow,
      register: webUserActions.register,
      resendCode: webUserActions.resendCode,
      resetPassword: userActions.resetPassword,
      resetUser: webUserActions.resetUser,
      scanContacts: contactActions.scanContacts,
      setRegisterInfo: webUserActions.setRegisterInfo,
      showLoading: modalActions.showLoading,
      showLogin: userActions.showLogin,
      updatePassword: webUserActions.updatePassword,
      updateSettings: userActions.updateSettings,
      userExists: userActions.userExists,
      userSet: userActions.userSet,
      isEmailValid: userActions.isEmailValid,
      verifyEmailCode: webUserActions.verifyEmailCode,
      verifyPhone: webUserActions.verifyPhone,
      verifyPhoneCode: webUserActions.verifyPhoneCode,
      verifyUser: userActions.verifyUserData,
      webloginOauth: webUserActions.webloginOauth,
      webloginPassword: webUserActions.webloginPassword,
    },
    dispatch,
  );
}

const PageModalContainer = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 300;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`;

const ModalWrapper = styled.div`
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: calc(${(p: IStyledProps) => p.theme.paddingView} * 2.5)
    calc(${(p: IStyledProps) => p.theme.paddingView} * 3.5);
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};

  ${(p: IStyledProps) =>
    [REGISTER_STEPS.REGISTER, REGISTER_STEPS.VERIFY_CODE, REGISTER_STEPS.RESET_PASSWORD].includes(p.authStep) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      bottom: 0;
      background: white;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 350;
      overflow: auto;
      border-radius: 0;
      box-shadow: none;
    `}
`;

const CloseIcon = styled(FontIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

const Component: React.FC<ILoginModalProps> = props => {
  return props.open ? (
    <PageModalContainer
      id="login_modal_container"
      onClick={e => props.closeable && (e.target as HTMLElement).id === 'login_modal_container' && props.hideLogin()}
    >
      <SmallScreen />
      <ModalWrapper authStep={props.authStep}>
        {props.closeable ? <CloseIcon name="Close" onClick={props.hideLogin} /> : null}
        <LoginRegisterFlow {...props} inModal={true} />
      </ModalWrapper>
    </PageModalContainer>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Component));
