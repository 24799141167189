import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
}

export const Container = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const Row = styled(RowContainer)``;

export const StepBar = styled.div`
  width: 42px;
  height: 4px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.green1 : p.theme.colors.grey3)};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextStep = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
