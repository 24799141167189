import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';

interface IStyledProps {
  theme: ITheme;
  padding?: string;
  backgroundColor?: string;
}

const StyledSubHeader = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  padding: ${(p: IStyledProps) => p.padding || `${p.theme.paddingSize()} ${p.theme.paddingSize(2)}`};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme!.colors.grey3};
`;

export default StyledSubHeader;
