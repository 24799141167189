import { IGlobalWeb } from 'common-services';

import config from '../../bindings/config';
import { messaging } from '../../setupFcm';
import { CONSENTIO_LOGO_C } from '../constants';

declare var global: IGlobalWeb;

/**
 * Register service worker for FCM push
 */
export function registerServiceWorker() {
  if (!config.TOGGLE_PUSH_NOTIFICATIONS.enabled || !messaging) return;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        updateViaCache: 'none',
      })
      .then(registration => {
        global.swRegistration = registration;
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(err => {
        console.log('Service worker registration failed, error:', err);
      });
  }
}

/**
 * Handle message push with type "push-received"
 * Do not show messages push if we are in the chat section
 */
export function handlePushReceived(payload: any, dispatch: any, alwaysShow?: boolean) {
  const type = (payload && payload.data && payload.data.type) || '';
  if (payload.notification && payload.notification.title) {
    if (
      type.includes('message') &&
      !alwaysShow &&
      (window.location.pathname.startsWith('/contact') ||
        window.location.pathname.startsWith('/chat') ||
        window.location.pathname.startsWith('/group') ||
        window.location.pathname.startsWith('/broadcast') ||
        window.location.pathname.startsWith('/public') ||
        window.location.pathname.startsWith('/channel'))
    )
      return Promise.resolve();
    return displayDesktopNotification(payload);
  }
  return Promise.resolve();
}

/**
 * Show desktop notification from payload
 */
function displayDesktopNotification(payload: any) {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon || CONSENTIO_LOGO_C,
    data: {
      ...payload.data,
      link: payload.notification.click_action,
    },
  };

  if (global.swRegistration) {
    // @ts-ignore
    return global.swRegistration.showNotification(notificationTitle, notificationOptions);
  }
}
