import * as React from 'react';

import { FontIcon } from '..';
import * as S from './Notification.styled';

export interface IStateProps {
  notification: INotification;
}

export interface IDispatchProps {
  clear: () => void;
}

export type IProps = IStateProps & IDispatchProps;

const Notification: React.FC<IProps> = ({ notification, clear }) => {
  return (
    <S.Container
      toShow={notification && notification.toShow}
      kind={notification !== undefined ? notification.style : 'info'}
    >
      <S.Content>
        <S.Icon kind={notification !== undefined ? notification.style : 'info'}>
          <FontIcon name={getIcon(notification)} disableHover={true} />
        </S.Icon>
        <S.MessageContainer>
          <S.Title>{notification && notification.title}</S.Title>
          {notification && notification.subtitle ? <S.Subtitle>{notification.subtitle}</S.Subtitle> : null}
        </S.MessageContainer>
      </S.Content>
      {notification && notification.closable ? renderCloseIcon(() => clear()) : null}
    </S.Container>
  );
};

function getIcon(notif: INotification) {
  if (!notif) {
    return 'Info';
  }
  switch (notif.style) {
    case 'error':
      return 'Error';
    case 'warning':
      return 'Warning';
    case 'success':
      return 'Successful';
    default:
      return 'Info';
  }
}

function renderCloseIcon(onCloseClick?: () => void) {
  return (
    <S.IconWrapper>
      <FontIcon name="Close" onClick={onCloseClick} />
    </S.IconWrapper>
  );
}

export default React.memo(Notification);
