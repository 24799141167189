import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const MenuRow = styled(RowContainer)`
  align-items: center;
  cursor: pointer;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: inherit;
  font-weight: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Chevron = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: inherit;
  font-weight: inherit;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
