import { Api } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Alert, { IStateProps } from '../components/organisms/Alert';
import { IReduxState } from '../reducers';

function mapStateToPropsDetails(state: IReduxState): IStateProps {
  return {
    show: state.modal.connecting && !!state.user.user.id,
  };
}

function mapDispatchToPropsDetails(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(Alert);
