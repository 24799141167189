import {
  Api,
  contactActions,
  mappingActions,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import WorkspaceMagicOrders, {
  IDispatchProps,
  IStateProps,
  OwnProps,
} from '../components/organisms/WorkspaceMagicOrders';
import { IReduxState } from '../reducers';

const emptyObject = {};
function mapStateToProps(state: IReduxState, props: OwnProps): IStateProps {
  const {
    catalog: { clients, catalogs },
    country: { countries },
    contact: { inConsentio },
    order: { jobsImport },
    prodType: { prodTypes },
    product: { prices, priceGroupIds },
    user: { user, hideOrdersMagicZero },
    workspace: { suppliers },
  } = state!;
  const {
    match: {
      params: { clientId, workspaceId },
    },
  } = props;
  const catalog = catalogs[workspaceId];
  return {
    catalog,
    catalogs,
    clients,
    contacts: inConsentio,
    countries,
    hideOrdersMagicZero,
    importJobsResponse: (jobsImport || emptyObject)[clientId],
    me: user!,
    priceGroupIds,
    prices,
    prodTypes,
    suppliers,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      clientUpdate: sellerWorkspaceActions.clientUpdate,
      contactUpdateMySellerWorkspace: contactActions.contactUpdateMySellerWorkspace,
      downloadImportJobFile: orderActions.downloadImportJobFile,
      getAddressMappings: mappingActions.addressMappingsGet,
      getPrices: productActions.getPrices,
      getProductMappings: mappingActions.productMappingsGet,
      importContactOrders: orderActions.importClientOrders,
      importFormatsGet: orderActions.importFormatsGet,
      importJobsGet: orderActions.importJobsGet,
      importOrderFormatAsk: orderActions.importOrderFormatAsk,
      mappingDelete: mappingActions.mappingDelete,
      mappingUpdate: mappingActions.mappingUpdate,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelBySection,
      notificationShow: notificationsActions.notificationShow,
      orderImportWidgetsGet: orderActions.orderImportWidgetsGet,
      orderOriginalFileDownload: orderActions.orderOriginalFileDownload,
      ordersMagicZeroHide: userActions.ordersMagicZeroHide,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      requestProInfo: userActions.requestProInfo,
      createNewContact: contactActions.createContactWithName,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMagicOrders);
