import {
  __,
  apiParsers,
  buyerWorkspaceActions,
  buyerWorkspaceService,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_MEMBER_STATUS,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  constants,
  contactActions,
  debounce,
  EventTrack,
  i18n,
  imageActions,
  INVITE_ORIGIN,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  productService,
  realtime,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userService,
  utils,
  WORKING_STATUS,
} from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ACCEPT_ALL_FILES, MAX_FILE_SIZE, MAX_UPLOAD_FILES, MAX_VIDEO_SIZE, ROUTE_PATHS } from '../../../constants';
import { openChannel as openChannelAction } from '../../../screens/chat';
import SelectContacts from '../../../screens/select-contacts';
import { bindPasteListener, clearPasteListener, convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import share from '../../../services/sharer';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { Ribbon } from '../../atoms';
import {
  ChannelModal,
  ChatFooter,
  ForwardMessage,
  GenericTutorial,
  InviteLinkModal,
  Message,
  MultipleFilesModal,
  Scroll,
} from '../../molecules';
import * as S from './Chat.styled';
import { PrivateChatFooter } from './Fragments';

type IChatRouteProps = RouteComponentProps<{
  channelId?: string;
  workspaceId?: string;
  contactId?: string;
  clientId?: string;
  supplierId?: string;
}>;
export interface IChatStateProps {
  canBuy?: boolean;
  canSell?: boolean;
  catalog?: IWorkspace;
  channel: IChannel;
  channels: { [channelId: string]: IChannel };
  contact?: IContact;
  contacts: { [contactId: number]: IContact };
  draft?: IDraftMessage;
  imBlocking?: boolean;
  initialized: boolean;
  isAll?: boolean;
  isModal?: boolean;
  isUnregistered?: boolean;
  lastMessageAt: Record<string, number>;
  lastReadContactAt: number;
  me: IUser;
  messages: Array<IMessage>;
  prices?: Record<string, Record<number, IPrice>>;
  prodTypes: { [key: string]: IProdType };
  unreadMessageCount: number;
  workspaceSelected: IWorkspace;
}

export interface IChatDispatchProps {
  addMessageReaction: typeof chatActions.addMessageReaction;
  addMembers: typeof chatActions.addMembers;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  suppliersAdd: typeof buyerWorkspaceActions.suppliersAdd;
  contactBlock: typeof contactActions.contactBlock;
  contactsInvite: typeof contactActions.contactsInvite;
  deleteMessage: typeof chatActions.deleteMessage;
  editMessage: typeof chatActions.editMessage;
  forwardMessage: typeof chatActions.forwardMessage;
  getMessages: typeof chatActions.getMessages;
  getPrices: typeof productActions.getPrices;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  navigateToShowroom: typeof navActions.navigateToShowroom;
  navMiniChannelAction: typeof navActions.navMiniChannelAction;
  notificationShow: typeof notificationsActions.notificationShow;
  openChannel: typeof openChannelAction;
  orderClone: typeof orderActions.orderClone;
  removeMessageReaction: typeof chatActions.removeMessageReaction;
  sendMessage: typeof chatActions.sendMessage;
  setActiveChannel: typeof chatActions.setActiveChannel;
  setMessageDraft: typeof chatActions.setMessageDraft;
  touchFile: typeof chatActions.downloadFile;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = IChatStateProps &
  IChatDispatchProps &
  IChatRouteProps & {
    onClose?: () => void;
    onMinimize?: () => void;
    styleModal?: React.CSSProperties;
    startMinimized?: boolean;
  };

interface IState {
  filesPreviews: Array<IPreviewFile>;
  forwardMessage?: IMessage;
  imageUrls: Array<string>;
  inviteLink: string;
  isAll: boolean;
  isLoading: boolean;
  minimized: boolean;
  replyToMessage?: IMessage;
  shouldLoadMore: boolean;
  showAddMembers: boolean;
  showInviteLinkModal: boolean;
  showSendLink: boolean;
}

class Chat extends React.PureComponent<IProps, IState> {
  private t: number;
  private channelOpened: boolean;
  private inputRef: HTMLTextAreaElement | null;
  constructor(public props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      filesPreviews: [],
      imageUrls: [],
      inviteLink: '',
      isAll: false,
      isLoading: true,
      minimized: props.startMinimized || false,
      shouldLoadMore: true,
      showAddMembers: false,
      showInviteLinkModal: false,
      showSendLink: false,
    };
  }

  public componentDidMount() {
    const { contact, channel, draft, initialized, me, openChannel, startMinimized } = this.props;
    this.setupPaste();

    if (contact?.isDefaultContact && contact?.isNew) {
      EventTrack.track('chat_default_contact_first_time_open', { contact_id: contact?.id });
    }

    if (channel && initialized) {
      RenderTrack.track('Chat', {
        renderTime: this.t,
        channelId: channel.id,
      });
      if (!startMinimized) {
        openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
        this.channelOpened = true;
      }
    }
    if (draft && draft.replyToMessage) {
      this.setReplyToMessage(draft.replyToMessage);
    }
  }

  public componentWillUnmount() {
    const { channel, initialized, me, setActiveChannel } = this.props;
    if (channel && initialized) {
      setActiveChannel(me.id);
    }
    clearPasteListener();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { contact, channel, draft, initialized, me, openChannel, setActiveChannel } = this.props;
    const { minimized } = this.state;
    if (minimized !== prevState.minimized) {
      if (minimized) {
        setActiveChannel(me.id);
      } else {
        openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
        this.channelOpened = true;
      }
    }
    if (channel && initialized && (!this.channelOpened || !prevProps.channel || prevProps.channel.id !== channel.id)) {
      this.setupPaste();

      RenderTrack.track('Chat', {
        renderTime: this.t,
        channelId: channel.id,
      });
      this.setState({ isLoading: true });
      if (!minimized) {
        openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
      }
      this.channelOpened = true;
      this.setState({ isAll: false });
    }
    if (prevProps.channel && channel && prevProps.channel.id !== channel.id) {
      this.setReplyToMessage(draft && draft.replyToMessage);
      this.setState({ filesPreviews: [] });
      if (this.inputRef) this.inputRef.focus();
    }
    if (prevProps.messages !== this.props.messages) {
      this.updateImageUrls();
    }
    if (prevProps.contact !== contact && contact) {
      if (contact.isDefaultContact && contact.isNew) {
        EventTrack.track('chat_default_contact_first_time_open', { contact_id: contact.id });
      }
    }
  }

  public render() {
    const {
      channel,
      contact,
      isModal,
      navMiniChannelAction,
      onClose,
      onMinimize,
      startMinimized,
      styleModal,
      unreadMessageCount,
    } = this.props;
    const { minimized } = this.state;
    if (!channel) return null;
    return isModal ? (
      <ChannelModal
        style={styleModal}
        title={contact?.name}
        type={channel.type}
        avatar={contact?.avatar}
        avatarColor={contact?.avatarColor}
        minimized={minimized}
        onMinimize={() => {
          if (onMinimize) {
            onMinimize();
          }
          this.setState({ minimized: !minimized });
        }}
        onClose={() => (onClose ? onClose() : navMiniChannelAction(''))}
        startMinimized={startMinimized}
        unreadMessageCount={unreadMessageCount}
      >
        {this.renderChat()}
      </ChannelModal>
    ) : (
      this.renderChat()
    );
  }

  private renderChat = () => {
    const {
      channel,
      contact,
      contacts,
      contactsInvite,
      draft,
      getMessages,
      imBlocking,
      isUnregistered,
      me,
      messages,
      modalOpen,
      setMessageDraft,
    } = this.props;
    const { replyToMessage, forwardMessage, showAddMembers, showInviteLinkModal, showSendLink } = this.state;
    if (!channel) return null;
    const replyFromSender =
      replyToMessage && me.id !== replyToMessage.senderId
        ? channel.members.find(cm => cm.id === replyToMessage.senderId)
        : undefined;
    const isContactAvailable = !contact || contact.workingStatus === WORKING_STATUS.AVAILABLE;
    const outOfOfficeMessage = this.getOutOfOfficeMessage(isContactAvailable, contact);
    return (
      <S.DropzoneContainer
        accept={ACCEPT_ALL_FILES}
        multiple={true}
        noClick={true}
        onFilesChange={acceptedFiles => this.handleSendFile(acceptedFiles)}
      >
        <React.Fragment>
          <S.Container>
            <Scroll
              id="message-container"
              loadMore={() =>
                !this.state.isAll &&
                this.state.shouldLoadMore &&
                this.setState({ shouldLoadMore: false }, () =>
                  getMessages(channel.id, me.id, false, (isAll?: boolean) =>
                    this.setState({ isAll, shouldLoadMore: true }),
                  ),
                )
              }
            >
              {this.renderGroupHeader()}
              {this.shouldShowMessages(messages) ? this.renderMessages() : this.renderTutorial()}
            </Scroll>
            {!isContactAvailable ? (
              <Ribbon
                hideIcon={true}
                text={outOfOfficeMessage}
                type={contact?.workingStatus === WORKING_STATUS.NOT_AVAILABLE ? 'warning' : 'info'}
              />
            ) : null}
            {channel.type === CHANNEL_TYPE.PRIVATE ? (
              <PrivateChatFooter
                channel={channel}
                contact={contact}
                contacts={contacts}
                draftText={draft ? draft.text : ''}
                imBlocking={imBlocking}
                isUnregistered={isUnregistered}
                modalOpen={modalOpen}
                me={me}
                name={contact?.name || ''}
                onTextInputRef={input => (this.inputRef = input)}
                replyToMessage={replyToMessage}
                replyFromSenderName={replyFromSender ? replyFromSender.name : me.name}
                replyFromSenderAvatar={
                  replyFromSender
                    ? (contact && contact.avatar) ||
                      (contacts[replyFromSender.id] && contacts[replyFromSender.id].avatar)
                    : me.settings.avatar
                }
                replyFromSenderAvatarColor={
                  replyFromSender ? replyFromSender.avatarColor : utils.getAvatarColor(me.name)
                }
                sendMessage={this.sendMessage}
                setMessageDraft={setMessageDraft}
                setReplyToMessage={this.setReplyToMessage}
                unblock={this.unblock}
                contactsInvite={contactsInvite}
                handleSendFile={this.handleSendFile}
              />
            ) : (
              <ChatFooter
                channel={channel}
                contacts={contacts}
                draftText={draft ? draft.text : ''}
                id={channel.id}
                modalOpen={modalOpen}
                me={me}
                name={channel ? channel.name : ''}
                onTextInputRef={input => (this.inputRef = input)}
                replyFromSenderName={replyFromSender ? replyFromSender.name : me.name}
                replyFromSenderAvatar={
                  replyFromSender && me.id !== replyFromSender.id
                    ? (contact && contact.avatar) ||
                      (contacts[replyFromSender.id] && contacts[replyFromSender.id].avatar)
                    : me.settings.avatar
                }
                replyToMessage={replyToMessage}
                replyFromSenderAvatarColor={
                  replyFromSender ? replyFromSender.avatarColor : utils.getAvatarColor(me.name)
                }
                sendMessage={this.sendMessage}
                setMessageDraft={setMessageDraft}
                setReplyToMessage={this.setReplyToMessage}
                handleSendFile={this.handleSendFile}
              />
            )}
          </S.Container>
          {forwardMessage ? this.renderForwardModal(forwardMessage) : null}
          {this.renderFileModal()}
          {showAddMembers ? this.renderAddMembers() : null}
          {showInviteLinkModal ? this.renderInviteLinkModal() : null}
          {showSendLink ? this.renderSendLinkModal() : null}
        </React.Fragment>
      </S.DropzoneContainer>
    );
  };

  /**
   * Get contact's message when they are out of office
   */
  private getOutOfOfficeMessage(isContactAvailable: boolean, contact: IContact) {
    let result = '';
    if (!isContactAvailable) {
      result = contact?.workingStatus === WORKING_STATUS.AWAY ? contact?.messageOnAway : contact?.messageOnNotAvailable;
    }
    const workingStatusLiterals = constants.workingStatusLiterals(contact?.workingStatus, contact?.name);
    return result || workingStatusLiterals.public_message;
  }

  /**
   * Render forward message modal
   */
  private renderForwardModal(message: IMessage) {
    const { channels, channel, contacts, forwardMessage, lastMessageAt, history, me } = this.props;
    return (
      <ForwardMessage
        channelId={channel.id}
        channels={channels}
        close={this.closeForwardMessageModal}
        contacts={contacts}
        forwardMessage={forwardMessage}
        history={history}
        lastMessageAt={lastMessageAt}
        message={message}
        me={me}
      />
    );
  }

  /**
   * Close forward message modal
   */
  private closeForwardMessageModal = () => {
    this.setState({ forwardMessage: undefined });
  };

  /**
   * Update reply to message in state
   */
  private setReplyToMessage = (message?: IMessage) => {
    this.setState({ replyToMessage: message });
  };

  private sendMessage = (
    channelId: string,
    text: string,
    isFile?: boolean,
    file?: IFile,
    replyToMessage?: IMessage,
    extraData?: any,
  ) => {
    const { channel, contact, messages, sendMessage, me } = this.props;
    if (messages.length <= 1 && contact) {
      realtime.sendContactCreate(contact!.id!);
    }

    let messageToSend = text;
    messageToSend = text.split(/([^\S\r]+)/).reduce((acc, t) => {
      if (t.startsWith('@') && t.length > 1) {
        const m = channel.members.find(
          member => member.name.replace(/ /g, '_').toLocaleLowerCase() === t.substring(1).toLocaleLowerCase(),
        );
        acc += m ? `@mention{${m.id}}` : ` ${t}`;
      } else {
        acc += `${t}`;
      }
      return acc;
    }, '');

    sendMessage(
      me.id,
      {
        channelId: channelId || channel.id,
        // Create the message one minute ahead to ensure it is displayed at the bottom while being sent
        createdAt: new Date().getTime() + 60000,
        extraData: extraData ?? {},
        // If we have a mention at the start of the message,
        // the algorithm will generate a heading space
        message: messageToSend.trim(),
        messageId: 0,
        messageType: isFile ? 'file' : 'text',
        reactions: {},
        senderId: me.id,
      },
      file,
      replyToMessage,
    );
    EventTrack.track('message_send', {
      channelId: channelId || channel.id,
      length: text.length,
      messageType: isFile ? 'file' : 'text',
      replyToMessageId: replyToMessage && replyToMessage.messageId + '',
      type: channel.type,
    });
    this.goToBottom();
  };

  /**
   * scroll all the way down chat container when sending a message.
   */
  private goToBottom = () => {
    const container = document.getElementById('message-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  /**
   * Render a tutorial if no messages
   * Introduces feature and throw baits for trading
   */
  private renderTutorial() {
    const { isLoading } = this.state;
    const { channel, contact, canBuy, canSell, me, catalog, workspaceSelected } = this.props;
    if (isLoading || !workspaceSelected) return null;
    const isClient = contact?.clientOf?.includes(workspaceSelected.id);
    const isSupplier = contact?.supplierOf?.includes(workspaceSelected.id);
    const isBuyerWs = workspaceSelected?.type === 'buyer';
    const isSellerWs = workspaceSelected?.type === 'seller';
    const defaultWorkspaceId = contact?.mySellerWorkspaceId;
    const { action, action2, title, description, cta, cta2 } = chatService.getZeroCaseLiteralsNew(
      !!canSell,
      !!canBuy,
      channel,
      isSellerWs,
      isBuyerWs,
      workspaceSelected.id,
      defaultWorkspaceId,
      isClient,
      isSupplier,
      (type: 'buy' | 'sell') => {
        this.navigateToShowroom(type === 'sell' ? me.id : contact?.id);
        EventTrack.track('channel_zero_case_cta_click', {
          channel_id: channel.id,
          action: type === 'sell' ? 'send-offer' : 'see-catalog',
        });
      },
      contact,
    );
    return (
      <GenericTutorial
        action={action || isSellerWs ? this.addClient : this.addSupplier}
        action2={action2}
        actionLink={() => {
          window.open(
            userService.getConsentioUniversityLink(
              sellerWorkspaceService.isProPlan(catalog?.plan),
              i18n.default.currentLocale() as LOCALE,
              canBuy,
              canSell,
              isSellerWs,
              config.TOGGLE_UNIVERSITY.enabled,
            ),
            '_blank',
          );
          EventTrack.track('channel_zero_case_cta_click', { channel_id: channel.id, action: 'university' });
        }}
        cta={cta}
        cta2={cta2}
        imageSize={170}
        imageUrl={IMAGES.messagesGrey}
        link={__('Messages.Chat.zero_cases.university_link')}
        text={description}
        title={title}
      />
    );
  }

  private addClient = () => {
    const { me, clientsAdd, contact, workspaceSelected, notificationShow } = this.props;
    clientsAdd(
      me.id,
      workspaceSelected.id,
      [sellerWorkspaceService.contactToClient(contact, workspaceSelected.id)],
      error => {
        if (error) {
          return notificationShow({
            title: __('ClientsList.error', { count: 1 }),
            subtitle: __('ClientsList.error_description', { count: 1 }),
            closable: true,
            style: 'error',
          });
        }
        return notificationShow({
          title: __('ClientsList.success', { count: 1 }),
          subtitle: __('ClientsList.success_description', { count: 1 }),
          closable: true,
          style: 'info',
        });
      },
    );
  };
  private addSupplier = () => {
    const { me, suppliersAdd, contact, workspaceSelected, notificationShow } = this.props;
    suppliersAdd(
      me.id,
      workspaceSelected.id,
      [buyerWorkspaceService.contactToSupplier(contact, workspaceSelected.id)],
      error => {
        if (error) {
          notificationShow({
            title: __('SuppliersList.error', { count: 1 }),
            subtitle: __('SuppliersList.error_description', { count: 1 }),
            closable: true,
            style: 'error',
          });
        }

        return notificationShow({
          title: __('SuppliersList.success', { count: 1 }),
          subtitle: __('SuppliersList.success_description', { count: 1 }),
          closable: true,
          style: 'info',
        });
      },
    );
  };

  /**
   * Render header with group options
   */
  private renderGroupHeader() {
    const { channel } = this.props;
    const amAdmin = [CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(channel.role);
    if (
      channel.type !== CHANNEL_TYPE.GROUP ||
      (channel.invitePolicy === 'admin' && !amAdmin) ||
      channel.status !== 'active'
    )
      return null;
    return (
      <S.GroupHeaderContainer>
        <S.TextBold>{__('Components.Chat.group_header.title')}</S.TextBold>
        <S.TextRegular>{__('Components.Chat.group_header.description')}</S.TextRegular>
        <S.ButtonsContainer>
          <S.GroupHeaderButton
            iconName="Add-contact"
            iconSize="18px"
            iconType="background"
            onClick={() => this.setState({ showAddMembers: true })}
            type="link"
            withoutPadding={true}
          >
            {__('Components.Chat.group_header.add_member')}
          </S.GroupHeaderButton>
          <S.GroupHeaderButton
            iconName="Link"
            iconSize="18px"
            iconType="background"
            onClick={() => {
              this.setState({ showInviteLinkModal: true });
              EventTrack.track('channel_invite_open_modal', { channel_id: channel.id, type: channel.type });
            }}
            type="link"
            withoutPadding={true}
          >
            {__('Components.Chat.group_header.link_invite')}
          </S.GroupHeaderButton>
        </S.ButtonsContainer>
      </S.GroupHeaderContainer>
    );
  }

  /**
   * Render messages
   */
  private renderMessages() {
    const {
      addMessageReaction,
      removeMessageReaction,
      channel,
      contact,
      contacts,
      history,
      lastReadContactAt,
      messages,
      me,
      prodTypes,
      touchFile,
      touchImage,
    } = this.props;
    const { imageUrls } = this.state;
    if (!messages) return null;
    return messages.map((m, i, list) => {
      const isFirst = !i || differenceInCalendarDays(m.createdAt, list[i - 1].createdAt) !== 0;
      const isLast = i === list.length - 1;
      const { replyFrom } = m;
      const replyFromSender =
        replyFrom && me.id !== replyFrom.senderId
          ? channel.members.find(cm => cm.id === replyFrom.senderId)
          : undefined;
      const showReplyMessage = replyFrom && (replyFromSender || me.id === replyFrom.senderId);
      const otherPart = contact || (channel.members && channel.members.find(cm => cm.id === m.senderId));

      return (
        <React.Fragment key={m.messageId + m.message + i}>
          {isFirst && <Message message={m} history={history} isDate={true} me={me} />}
          <Message
            addMessageReaction={addMessageReaction}
            channelType={channel.type}
            cloneOrder={this.cloneOrder}
            contactEmail={contact?.email}
            contactName={channel.workspaceId ? channel.name : contact?.name}
            contactNameToDisplay={userService.getDisplayName(contact?.name, contact?.companyName)}
            contacts={contacts}
            goToBottom={this.goToBottom}
            history={history}
            imageUrls={imageUrls}
            isFirst={isFirst || m.senderId !== list[i - 1].senderId || list[i - 1].messageType === 'admin'}
            isLast={isLast}
            isRead={m.createdAt <= lastReadContactAt}
            isUnregistered={contact?.isUnregistered}
            key={m.messageId + m.message}
            me={me}
            message={m}
            members={
              channel.members.map(member => ({
                ...member,
                id: member.id,
                name: member.name || contacts[member.id]?.name || '',
                companyName: '',
              })) as Array<IMember>
            }
            navigateToShowroom={this.navigateToShowroom}
            onDelete={channel.status === 'active' ? this.onDeleteMessage : null}
            onEdit={channel.status === 'active' && m.messageType === 'text' ? this.onEditMessage : null}
            onForward={this.onForwardMessage}
            onOrderMessageClick={this.onOrderMessageClick}
            onReply={channel.status === 'active' ? this.onReplyToMessage : undefined}
            prodTypes={prodTypes}
            removeMessageReaction={removeMessageReaction}
            replyFrom={showReplyMessage && m.replyFrom}
            replyFromSenderName={replyFromSender ? replyFromSender.name : me.name}
            replyFromSenderAvatar={
              replyFromSender
                ? (contact && contact.avatar) || (contacts[replyFromSender.id] && contacts[replyFromSender.id].avatar)
                : me.settings.avatar
            }
            replyFromSenderAvatarColor={replyFromSender ? replyFromSender.avatarColor : utils.getAvatarColor(me.name)}
            senderAvatar={
              me.id !== m.senderId
                ? (contact && contact.avatar) || (contacts[otherPart?.id] && contacts[otherPart.id].avatar)
                : me.settings.avatar
            }
            senderAvatarColor={
              me.id === m.senderId
                ? utils.getAvatarColor(me.name)
                : (contact && contact.avatarColor) || utils.getAvatarColor(otherPart && otherPart.name)
            }
            senderName={me.id === m.senderId ? me.name : (otherPart && otherPart.name) || ''}
            touchFile={touchFile}
            touchImage={touchImage}
          />
        </React.Fragment>
      );
    });
  }

  /**
   * Boolean condition if we should show the messages or the tutorial.
   * In case the first message is unique and is the admin privacy disclaimer, we still show the tutorial.
   */
  private shouldShowMessages(messages: Array<IMessage>) {
    const { lastMessageAt } = this.props;
    const onlyAdminMessages = messages.filter(message => message.messageType === 'admin').length === messages.length;
    return lastMessageAt && !onlyAdminMessages;
  }

  /**
   * Render modal for add members
   */
  private renderAddMembers(): JSX.Element {
    const { addMembers, channel, contacts, me } = this.props;
    const contactAddedIds = channel.members.filter(member => member.status === 'active').map(member => member.id);
    const contactsNotAdded = Object.values(contacts).filter(c => !contactAddedIds.includes(c.id) && c.id !== me.id!);

    return (
      <SelectContacts
        channelId={channel?.id}
        onClose={() => this.setState({ showAddMembers: false })}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          if (channel.id)
            addMembers(
              me.id,
              channel.id,
              channel.imageUrl,
              contactsToAdd.map(mId => ({
                id: mId,
                companyName: contacts[mId].companyName,
                name: contacts[mId].name,
                role: CHANNEL_MEMBER_ROLE.MEMBER,
                status: CHANNEL_MEMBER_STATUS.ACTIVE,
                avatarColor: contacts[mId].avatarColor,
              })),
              { from: 'chat-header' },
            );
          this.setState({ showAddMembers: false });
        }}
        origin={INVITE_ORIGIN.GROUP}
        showUnregistered={true}
        title={__('Channel.invite_modal.title_consentio')}
        subtitle={__('Channel.invite_modal.subtitle_consentio')}
        showInvite={true}
        trackingFrom="chat-group"
      />
    );
  }

  /**
   * Render modal with invite link
   */
  private renderInviteLinkModal() {
    const { channel, me } = this.props;
    return (
      <InviteLinkModal
        channel={channel}
        description={__('Components.Chat.link_invite_modal.description')}
        inviteOrigin={INVITE_ORIGIN.GROUP}
        me={me}
        onClose={() => this.setState({ showInviteLinkModal: false })}
        onSendLink={(inviteLink: string, through: 'message' | 'whatsapp') => {
          this.setState({ showSendLink: through === 'message', inviteLink, showInviteLinkModal: false });
          if (through === 'whatsapp') {
            share({
              sharer: 'whatsapp',
              url: inviteLink,
              title: __('ChatPublic.link_invite.external'),
              web: !deviceIsIpad(),
            });
          }
        }}
        title={__('Components.Chat.group_header.link_invite')}
        trackFrom="chat-group"
      />
    );
  }

  /**
   * Render send link modal
   */
  private renderSendLinkModal() {
    const { inviteLink } = this.state;
    const { channels, channel, contacts, history, lastMessageAt, me, sendMessage } = this.props;
    const message: IMessage = {
      channelId: '',
      createdAt: new Date().getTime() + 60000,
      extraData: {},
      message: __('Components.Chat.invite_message', { link: inviteLink }),
      messageId: 0,
      messageType: 'text',
      reactions: {},
      senderId: me.id,
    };
    return (
      <ForwardMessage
        channelId={channel.id}
        channels={channels}
        close={() => this.setState({ showSendLink: false })}
        contacts={contacts}
        forwardMessage={(myId: number, msg: IMessage, channelIds: Array<string>) => {
          channelIds.forEach(channelId => {
            sendMessage(myId, {
              ...msg,
              channelId,
            });
            EventTrack.track('message_send', {
              channelId,
              length: msg.message.length,
              messageType: 'text',
              type: channels[channelId]?.type,
            });
          });
        }}
        history={history}
        lastMessageAt={lastMessageAt}
        message={message}
        me={me}
        title={__('Components.Chat.send_invite_title')}
      />
    );
  }

  /**
   * On forward message, opens modal
   */
  private onForwardMessage = (message: IMessage) => {
    this.setState({ forwardMessage: message });
  };

  /**
   * On edit message
   */
  private onEditMessage = (text: string, message: IMessage, setEditMessage: (text?: string) => void) => {
    const { channel, editMessage, me } = this.props;
    setEditMessage(undefined);
    const textCleaned = text.trim();
    if (textCleaned && textCleaned !== message.message) {
      const registeredUser = channel.members.filter(member => member.isRegistered !== undefined && member.isRegistered);
      const textWithMentions = textCleaned.split(/([^\S\r]+)/).reduce((acc, t) => {
        if (t.startsWith('@')) {
          const m = registeredUser.find(
            member => member.name.replace(/ /g, '_').toLocaleLowerCase() === t.substring(1).toLocaleLowerCase(),
          );
          acc += m ? `@mention{${m.id}}` : ` ${t}`;
        } else {
          acc += `${t}`;
        }
        return acc;
      }, '');

      editMessage(me.id, { ...message, message: textWithMentions });
    }
  };

  /**
   * On delete message, opens nice modal confirmation
   */
  private onDeleteMessage = (message: IMessage) => {
    const { deleteMessage, me, modalOpen, modalClose } = this.props;
    modalOpen(
      __('Components.Chat.delete_message.title'),
      () => {
        deleteMessage(me.id, message);
        modalClose();
      },
      {
        text2: __('Components.Chat.delete_message.description'),
        showCancelButton: true,
        buttonText: __('Components.Chat.delete'),
        actionType: 'dangerous',
        icon: IMAGES.error,
        closeable: true,
      },
      'nice',
    );
  };

  /**
   * On reply to message, set state then focus text input to write a message
   */
  private onReplyToMessage = (message: IMessage) => {
    this.setReplyToMessage(message);
    if (this.inputRef) this.inputRef.focus();
  };

  /**
   * Click action on order message
   */
  private onOrderMessageClick = (message: IMessage) => {
    const { history, isModal, match } = this.props;
    const order = message.extraData as IOrder;
    history.push(
      getPath(
        isModal
          ? {
              path: ROUTE_PATHS.CONTACT_SALES,
              workspaceId: match.params.workspaceId,
              contactId: match.params.contactId || match.params.clientId || match.params.supplierId,
              hashId: order.hashId,
            }
          : {
              path: ROUTE_PATHS.CONTACT_ORDERS,
              channelId: message.channelId,
              hashId: order.hashId,
            },
      ),
      { from: 'messages' },
    );
  };
  /**
   * unblock action for contacts blocked.
   */
  private unblock = () => {
    const { contact, imBlocking, channel, modalOpen, me, contactBlock, modalClose } = this.props;
    if (!contact) return;
    EventTrack.blockUser({
      myId: me.id,
      contactId: contact.id!,
      action: imBlocking ? 'preunblock' : 'preblock',
    });
    modalOpen(
      imBlocking
        ? __('Components.ContactDetails.unblock_contact.modal.title')
        : __('Components.ContactDetails.block_contact.modal.title'),
      () => {
        EventTrack.blockUser({
          myId: me.id,
          contactId: contact.id!,
          action: imBlocking ? 'unblock' : 'block',
        });
        contactBlock(me.id, contact.id!, !imBlocking, (err?: Error) => {
          if (!err) modalClose();
          else {
            modalOpen(__('modal_error.text'), modalClose, {
              buttonText: __('modal_error.accept'),
              icon: 'Delete',
            });
          }
        });
      },
      {
        buttonText: imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.button')
          : __('Components.ContactDetails.block_contact.modal.button'),
        icon: 'warning',
        text2: imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.text', channel.members[0])
          : __('Components.ContactDetails.block_contact.modal.text', channel.members[0]),
        showCancelButton: true,
      },
    );
  };

  /**
   * Render order's modal attachments
   */
  private renderFileModal() {
    const { filesPreviews } = this.state;
    const { contact, channel } = this.props;
    if (!filesPreviews || !filesPreviews.length) return null;

    return (
      <MultipleFilesModal
        files={filesPreviews}
        onClose={() => this.setState({ filesPreviews: [] })}
        receiverName={contact?.name || channel.name}
        setFiles={(files: Array<IPreviewFile>) => this.setState({ filesPreviews: files })}
        sendFiles={(files: Array<IPreviewFile>) => {
          files.forEach(file => {
            this.sendFile(file.file, file.fileString);
          });
          this.setState({ filesPreviews: [] });
        }}
      />
    );
  }

  private setupPaste() {
    bindPasteListener((f: File) => {
      convertToIFile(f, (file: IFile, fileString: string) => this.setState({ filesPreviews: [{ file, fileString }] }));
    });
  }

  /**
   * Handle select files converting them to IFile, then adding them to state
   */
  private handleSendFile = (files: Array<File>) => {
    const { filesPreviews } = this.state;

    if (files && files.length) {
      const totalFiles = files.length;

      const result = [...filesPreviews];
      let iteration = 0;
      let excludedFilesForSize = 0;
      files.forEach(f => {
        convertToIFile(
          f,
          (file: IFile, fileString: string) => {
            // Files that are not images with exceeding size are excluded
            // Images are lowered afterwards
            let excludeFile = false;

            const isVideoFile = file.type.startsWith('video/');
            const maxFileSize = isVideoFile ? MAX_VIDEO_SIZE : MAX_FILE_SIZE;

            if (!file.type.startsWith('image/') && file.size > maxFileSize) {
              excludeFile = true;
              excludedFilesForSize++;
            }
            if (result.length <= MAX_UPLOAD_FILES && !excludeFile) result.push({ file, fileString });
            iteration++;
            if (iteration === totalFiles) {
              this.setState({ filesPreviews: result });
              if (excludedFilesForSize > 0)
                this.props.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 }));
            }
          },
          () => iteration++,
        );
      });
    }
  };

  /**
   * Sends a file through the chat. If the file is a message the function uploads the image to cloudinary,
   * if is another file type then upload to rtapi and then inject to chat.
   */
  private sendFile = (file: IFile, fileString: string) => {
    const { channel, mediaUpload } = this.props;
    const isVideoFile = file.type.startsWith('video/');
    const maxFileSize = isVideoFile ? MAX_VIDEO_SIZE : MAX_FILE_SIZE;

    if (file.type.startsWith('image/') || isVideoFile) {
      if (['image/gif', 'image/webp'].includes(file.type) || isVideoFile) {
        if (file.size > maxFileSize) {
          this.props.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 }));
          return;
        }
        mediaUpload({ ...file, content: fileString }, data => {
          if (data?.secure_url)
            this.sendMessage(channel.id, data.secure_url, true, undefined, undefined, {
              media_metadata: apiParsers.APICloudinaryToMetadata(data),
            });
        });
      } else {
        resizeImage(fileString, (imageResized: string) =>
          mediaUpload({ ...file, content: imageResized }, data => {
            if (data?.secure_url)
              this.sendMessage(channel.id, data.secure_url, true, undefined, undefined, {
                media_metadata: apiParsers.APICloudinaryToMetadata(data),
              });
          }),
        );
      }
    } else if (file) {
      // What to do with file max size ?
      if (file.size > maxFileSize) {
        this.props.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 }));
        return;
      }
      this.sendMessage(channel.id, '', true, file);
    }
  };

  /**
   * handle click on showroom, search or product message
   */
  private navigateToShowroom = (ownerId: number, search?: string, productId?: number, productHash?: string) => {
    const { channel, history, navigateToShowroom } = this.props;
    navigateToShowroom(
      ownerId,
      search,
      productId,
      productHash,
      channel.type === CHANNEL_TYPE.PRIVATE ? channel.id : undefined,
      (path: string) => history.push(path),
    );
  };

  /**
   * Clone orderItems and navigate to cart screen
   */
  private cloneOrder = (order: IOrder) => {
    const { prices, getPrices } = this.props;
    const pricesToShow = prices?.[productService.getKey(order.catalogId!, order.buyerId)];
    return pricesToShow
      ? this.openCloneOrder(order)
      : getPrices(order.catalogId!, order.buyerId, false, order.deliverTo?.id, () =>
          setTimeout(() => this.openCloneOrder(order)),
        );
  };

  /**
   * Clone orderItems and navigate to cart screen
   */
  private openCloneOrder = (order: IOrder) => {
    const { contact, prices, orderClone, history, channel } = this.props;
    const pricesToShow = prices?.[productService.getKey(order.catalogId!, order.buyerId)];
    orderClone(order, contact!, pricesToShow, () => {
      history.push(
        getPath({
          path: ROUTE_PATHS.CONTACT_ORDERS,
          channelId: channel.id,
          hashId: 'new',
        }),
      );
    });
  };

  /**
   * When having new messages we store the images urls in order to show them all at once in the gallery
   */
  private updateImageUrls = debounce(() => {
    const { messages } = this.props;
    const result: Array<string> = [];
    messages.forEach(message => {
      if (message.messageType === 'image' && message.message) {
        result.push(
          message.message
            .replace('res.cloudinary.com/consentiotrade', 'media.consentio.co')
            .replace('consentiotrade-res.cloudinary.com', 'media.consentio.co'),
        );
      }
    });
    this.setState({ imageUrls: result });
  }, 100);
}

export default Chat;
