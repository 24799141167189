import { Api, chatActions, contactActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelByPath } from '../actions/nav';
import Mention, { DispatchProps, HeritageProps, StateProps } from '../components/organisms/Mention';
import { IReduxState } from '../reducers';

function mapStateToPropsChatList(state: IReduxState, props: HeritageProps): StateProps {
  const {
    chat,
    contact: { inConsentio },
    user: { user },
  } = state;
  return {
    channels: chat.channels,
    contacts: inConsentio,
    me: user,
  };
}

function mapDispatchToPropsChatList(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      createChannel: chatActions.createChannel,
      createNewContact: contactActions.createContactWithName,
      navigateChannelByPath,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChatList, mapDispatchToPropsChatList)(Mention);
