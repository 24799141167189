export default {
  grape: {
    co2Max: 0.5833742290103133,
    co2Min: 0.35515454714679195,
    waterMax: 0.2605327763175893,
    waterMin: 0.17397199575513156,
  },
  apple: {
    co2Max: 0.10040916318568605,
    co2Min: 0.0561092562382103,
    waterMax: 2.7998194428560073,
    waterMin: 0.007283222584369707,
  },
  banana: {
    co2Max: 0.2245352936491345,
    co2Min: 0.2245352936491345,
    waterMax: 1.0382502804280094,
    waterMin: 1.0382502804280094,
  },
  cherry: {
    co2Max: 0.42962358863994193,
    co2Min: 0.42962358863994193,
    waterMax: 28.293444755671796,
    waterMin: 28.293444755671796,
  },
  clementine: {
    co2Max: 0.29197287862719584,
    co2Min: 0.29197287862719584,
    waterMax: 17.71958818343522,
    waterMin: 17.71958818343522,
  },
  cocoa: {
    co2Max: 3.1373861216611103,
    co2Min: 3.1373861216611103,
    waterMax: 2.666577616225963,
    waterMin: 2.666577616225963,
  },
  coffee: {
    co2Max: 1.516957674059291,
    co2Min: 1.516957674059291,
    waterMax: 0.5012158567409374,
    waterMin: 0.5012158567409374,
  },
  mango: {
    co2Max: 0.12232730187118263,
    co2Min: 0.12232730187118263,
    waterMax: 11.877888308318596,
    waterMin: 11.877888308318596,
  },
  peach: {
    co2Max: 0.18525219800643303,
    co2Min: 0.18436755441204916,
    waterMax: 12.640860549291732,
    waterMin: 12.50705994144706,
  },
  pear: {
    co2Max: 0.06494319496940017,
    co2Min: 0.061260962270313545,
    waterMax: 6.589108690142707,
    waterMin: 6.228603097779681,
  },
  pineapple: {
    co2Max: 0.3340874192216089,
    co2Min: 0.3340874192216089,
    waterMax: 0.5483934690666737,
    waterMin: 0.5483934690666737,
  },
  walnut: {
    co2Max: 1.328416043536065,
    co2Min: 0.9413395342920153,
    waterMax: 22.187884217599507,
    waterMin: 20.169565220155523,
  },
  alfalfa: {
    co2Max: 0.14622958160303529,
    co2Min: 0.14622958160303529,
    waterMax: 0.020215238677947847,
    waterMin: 0.020215238677947847,
  },
  beans: {
    co2Max: 0.5822965374596483,
    co2Min: 0.17514851908405213,
    waterMax: 5.208522485951989,
    waterMin: 0.032891893797608165,
  },
  soybean: {
    co2Max: 0.2595293819557447,
    co2Min: 0.2595293819557447,
    waterMax: 1.7949113067298956,
    waterMin: 1.7949113067298956,
  },
  peas: {
    co2Max: 0.21202015953374775,
    co2Min: 0.1953280806604348,
    waterMax: 0.9162219823577087,
    waterMin: 0.7480536346829196,
  },
  potato: {
    co2Max: 0.07395661392882558,
    co2Min: 0.058451473149438396,
    waterMax: 0.7939710483487135,
    waterMin: 0.047545069836727016,
  },
  leek: {
    co2Max: 0.41457886452978987,
    co2Min: 0.19619092451993245,
    waterMax: 3.4641910257934043,
    waterMin: 0.13015076764084244,
  },
  carrot: {
    co2Max: 0.06312761353792205,
    co2Min: 0.04265292663153774,
    waterMax: 4.2000405054322005,
    waterMin: 1.5692622542983248,
  },
  cauliflower: {
    co2Max: 0.2799868992770629,
    co2Min: 0.16941977488190968,
    waterMax: 0.23078957879893935,
    waterMin: 0.07070931229063504,
  },
  chicory: {
    co2Max: 0.3545706367354926,
    co2Min: 0.07443620879960258,
    waterMax: 0.6120492656461671,
    waterMin: 0.14675323593133882,
  },
  lettuce: {
    co2Max: 0.21786099110108356,
    co2Min: 0.10919017238389289,
    waterMax: 2.147943006362479,
    waterMin: 1.48672886678464,
  },
  melon: {
    co2Max: 0.31310109290586113,
    co2Min: 0.14449934189136138,
    waterMax: 4.687166664400451,
    waterMin: 3.894859609954308,
  },
  onion: {
    co2Max: 1.7248690018004245,
    co2Min: 0.045379385339451926,
    waterMax: 77.59173887039393,
    waterMin: 1.8632627870859673,
  },
  squash: {
    co2Max: 0.28146451579465054,
    co2Min: 0.12203755688846163,
    waterMax: 3.282583932171635,
    waterMin: 2.2003891914596987,
  },
  strawberry: {
    co2Max: 3.008697579797658,
    co2Min: 0.16603309492432433,
    waterMax: 5.9957041054168165,
    waterMin: 2.6875572647318506,
  },
  tomato: {
    co2Max: 2.3622753814093156,
    co2Min: 0.14345819050115205,
    waterMax: 1.6252006344243952,
    waterMin: 0.9056253197990394,
  },
};
