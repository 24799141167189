import { __, constants } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../../assets';
import * as S from './Reactions.styled';

/**
 * Modal to add post reactions
 */
export const PostReactionActions = ({
  addReaction,
  className,
  lang,
}: {
  addReaction: (reaction: PostReaction) => void;
  className?: string;
  lang?: string;
}) => {
  return (
    <S.ReactionsContainer className={className}>
      <S.ReactionImageContainer onClick={() => addReaction('love')}>
        <S.ReactionImage toScaleUp={true} src={IMAGES.reactions.love} alt="love" />
        <S.SimpleTooltip className="reaction-tooltip">
          {__('Messages.ChatPublic.reactions.love', { locale: lang })}
        </S.SimpleTooltip>
      </S.ReactionImageContainer>

      <S.ReactionImageContainer onClick={() => addReaction('like')}>
        <S.ReactionImage toScaleUp={true} src={IMAGES.reactions.like} alt="like" />
        <S.SimpleTooltip className="reaction-tooltip">
          {__('Messages.ChatPublic.reactions.like', { locale: lang })}
        </S.SimpleTooltip>
      </S.ReactionImageContainer>

      <S.ReactionImageContainer onClick={() => addReaction('interesting')}>
        <S.ReactionImage toScaleUp={true} src={IMAGES.reactions.interesting} alt="interesting" />
        <S.SimpleTooltip className="reaction-tooltip">
          {__('Messages.ChatPublic.reactions.interesting', { locale: lang })}
        </S.SimpleTooltip>
      </S.ReactionImageContainer>

      <S.ReactionImageContainer onClick={() => addReaction('go')}>
        <S.ReactionImage toScaleUp={true} src={IMAGES.reactions.go} alt="go" />
        <S.SimpleTooltip className="reaction-tooltip">
          {__('Messages.ChatPublic.reactions.go', { locale: lang })}
        </S.SimpleTooltip>
      </S.ReactionImageContainer>

      <S.ReactionImageContainer onClick={() => addReaction('funny')}>
        <S.ReactionImage toScaleUp={true} src={IMAGES.reactions.funny} alt="funny" />
        <S.SimpleTooltip className="reaction-tooltip">
          {__('Messages.ChatPublic.reactions.funny', { locale: lang })}
        </S.SimpleTooltip>
      </S.ReactionImageContainer>
    </S.ReactionsContainer>
  );
};

/**
 * Modal of the reactions of a post
 */
export const PostReactionsPreview = ({
  className,
  lang,
  message,
}: {
  className?: string;
  lang?: LOCALE;
  message: IMessage;
}) => {
  const { reactions } = message;
  const reactionKeys = Object.keys(reactions) as Array<PostReaction>;
  return (
    <S.ReactionsPreviewContainer className={className}>
      {reactionKeys.includes('love') && reactions.love.length ? (
        <S.ReactionImageContainer>
          <S.ReactionImage src={IMAGES.reactions.love} alt="love" size={25} />
          <S.TextReactionsCount>{reactions.love.length}</S.TextReactionsCount>
          <S.SimpleTooltip className="reaction-tooltip">
            {__('Messages.ChatPublic.reactions.love', { locale: lang })}
          </S.SimpleTooltip>
        </S.ReactionImageContainer>
      ) : null}

      {reactionKeys.includes('like') && reactions.like.length ? (
        <S.RowCenter>
          <S.ReactionImageContainer>
            <S.ReactionImage src={IMAGES.reactions.like} alt="like" size={25} />
            <S.SimpleTooltip className="reaction-tooltip">
              {__('Messages.ChatPublic.reactions.like', { locale: lang })}
            </S.SimpleTooltip>
          </S.ReactionImageContainer>
          <S.TextReactionsCount>{reactions.like.length}</S.TextReactionsCount>
        </S.RowCenter>
      ) : null}

      {reactionKeys.includes('interesting') && reactions.interesting.length ? (
        <S.RowCenter>
          <S.ReactionImageContainer>
            <S.ReactionImage src={IMAGES.reactions.interesting} alt="interesting" size={25} />
            <S.SimpleTooltip className="reaction-tooltip">
              {__('Messages.ChatPublic.reactions.interesting', { locale: lang })}
            </S.SimpleTooltip>
          </S.ReactionImageContainer>
          <S.TextReactionsCount>{reactions.interesting.length}</S.TextReactionsCount>
        </S.RowCenter>
      ) : null}

      {reactionKeys.includes('go') && reactions.go.length ? (
        <S.RowCenter>
          <S.ReactionImageContainer>
            <S.ReactionImage src={IMAGES.reactions.go} alt="go" size={25} />
            <S.SimpleTooltip className="reaction-tooltip">
              {__('Messages.ChatPublic.reactions.go', { locale: lang })}
            </S.SimpleTooltip>
          </S.ReactionImageContainer>
          <S.TextReactionsCount>{reactions.go.length}</S.TextReactionsCount>
        </S.RowCenter>
      ) : null}

      {reactionKeys.includes('funny') && reactions.funny.length ? (
        <S.RowCenter>
          <S.ReactionImageContainer>
            <S.ReactionImage src={IMAGES.reactions.funny} alt="funny" size={25} />
            <S.SimpleTooltip className="reaction-tooltip">
              {__('Messages.ChatPublic.reactions.funny', { locale: lang })}
            </S.SimpleTooltip>
          </S.ReactionImageContainer>
          <S.TextReactionsCount>{reactions.funny.length}</S.TextReactionsCount>
        </S.RowCenter>
      ) : null}
    </S.ReactionsPreviewContainer>
  );
};

/**
 * Post reactions summary
 */
export const PostReactionsSummary = ({
  className,
  iconSize = 20,
  message,
  totalReactions,
}: {
  className?: string;
  iconSize?: number;
  message: IMessage;
  totalReactions: number;
}) => {
  if (totalReactions === 0) return null;
  const { reactions } = message;
  const reactionKeys = Object.keys(reactions).filter(key =>
    ['love', 'like', 'funny', 'go', 'interesting'].includes(key),
  ) as Array<PostReaction>;

  return (
    <S.PostReactionsContainer className={className}>
      <S.ReactionsRow>
        {reactionKeys
          .sort((r1, r2) => {
            return constants.SORT_REACTIONS_MAP[r2] - constants.SORT_REACTIONS_MAP[r1];
          })
          .map(reaction => (
            <S.ReactionSmallImage
              alt={reaction}
              className="reaction-small-img"
              iconSize={iconSize}
              key={reaction}
              src={getPostReactionImage(reaction)}
            />
          ))}
        <S.TextTotalReactions
          className="reaction-small-img"
          numberOfReactionTypes={reactionKeys.length}
          iconSize={iconSize}
        >
          {totalReactions}
        </S.TextTotalReactions>
      </S.ReactionsRow>
    </S.PostReactionsContainer>
  );
};

export const getPostReactionImage = (reaction: PostReaction) => {
  switch (reaction) {
    case 'like':
      return IMAGES.reactions.like;
    case 'love':
      return IMAGES.reactions.love;
    case 'interesting':
      return IMAGES.reactions.interesting;
    case 'go':
      return IMAGES.reactions.go;
    case 'funny':
      return IMAGES.reactions.funny;
    default:
      return '';
  }
};
