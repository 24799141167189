import * as React from 'react';

import * as S from './FilterSwitch.styled';

export interface IProps {
  className?: string;
  keySelected: string;
  selectAll?: boolean;
  onSelectOption: (key: string) => void;
  options: Array<{ key: string; value: string; badge?: number; amount?: number }>;
  optionMinWidth?: string;
}

const FilterSwitch: React.FC<IProps> = ({
  className,
  keySelected,
  onSelectOption,
  optionMinWidth,
  options,
  selectAll,
}) => {
  return (
    <S.Container id="filter-switch" className={className}>
      {options.map(({ key, value, badge, amount }, index, array) => (
        <S.OptionItem
          isFirst={index === 0}
          isLast={index === array.length - 1}
          isSelected={selectAll || key === keySelected}
          allSelected={selectAll}
          key={index + key}
          onClick={() => onSelectOption(key)}
          minWidth={optionMinWidth}
        >
          <S.Badge badgeCount={badge}>
            <S.TextBadge>{badge}</S.TextBadge>
          </S.Badge>
          <S.Text isSelected={selectAll || key === keySelected}>
            {value} {amount ? `(${amount})` : ''}
          </S.Text>
        </S.OptionItem>
      ))}
    </S.Container>
  );
};

export default React.memo(FilterSwitch);
