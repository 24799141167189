import * as React from 'react';

import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  alignItems?: string;
  alignSelf?: string;
  cursor?: string;
  flex?: string;
  flexWrap?: string;
  height?: string;
  justifyContent?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  overflow?: string;
  padding?: string;
  position?: string;
  style?: React.CSSProperties;
  theme: ITheme;
  width?: string;
}

const FlexContainer = styled.div.attrs((p: IStyledProps) => ({
  style: {
    alignItems: p.alignItems,
    alignSelf: p.alignSelf,
    cursor: p.cursor,
    flex: p.flex,
    flexWrap: p.flexWrap,
    height: p.height,
    justifyContent: p.justifyContent,
    margin: p.margin,
    maxHeight: p.maxHeight,
    maxWidth: p.maxWidth,
    minHeight: p.minHeight,
    minWidth: p.minWidth,
    overflow: p.overflow,
    padding: p.padding,
    position: p.position,
    width: p.width,
    ...(p.style || {}),
  },
}))`
  display: flex;
`;

const ColumnContainer = styled(FlexContainer).withConfig({
  componentId: 'ColumnContainer',
} as any)`
  flex-direction: column;
`;
const RowContainer = styled(FlexContainer).withConfig({
  componentId: 'RowContainer',
} as any)`
  flex-direction: row;
`;

export { ColumnContainer, RowContainer };
