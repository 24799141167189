import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  hasAction?: boolean;
  isLast?: boolean;
  padding?: string;
  theme: ITheme;
}

export const Container = styled.header`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  text-transform: uppercase;
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Title = styled.div`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: default;
  align-self: center;
  text-align: right;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: center;
`;

export const SubTitle = styled.div`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0 0;
  cursor: default;
  align-self: center;
  text-align: left;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const Item = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => (p.isLast ? p.theme.colors.green1 : p.theme.colors.black)};
  cursor: ${(p: IStyledProps) => (p.hasAction ? 'pointer' : 'default')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  ${(p: IStyledProps) =>
    !p.isLast &&
    css`
      max-width: 24%;
    `}
`;
