import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, IOSLoading, ProgressBar as PB, RowContainer } from '../../atoms';

interface IStyledProps {
  isDragActive?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  paddingBottom?: string;
  showImportJobs?: boolean;
  theme: ITheme;
  width?: string;
}

export const Modal = styled(ColumnContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  height: ${(p: IStyledProps) => p.theme.contentHeightWithBreadcrumb};
  width: 100%;
`;

export const ListContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  width: ${(p: IStyledProps) => p.width || '100%'};
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: width 0.8s ease;
  ${(p: IStyledProps) =>
    p.paddingBottom &&
    css`
      padding-bottom: ${p.paddingBottom};
    `}
`;

const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

const TextSmall = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const WidgetsRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize()} ${p.theme.paddingSize(4)}`};
`;

export const FiltersRow = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

const ProgressBar = styled(PB)`
  max-width: 230px;
`;

export const RowHeader = styled(RowContainer)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(4)} ${p.theme.paddingSize(5)}`};
  justify-content: space-between;
`;
