import { Api, chatActions, chatService, contactActions, modalActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import ChatPublicInfo, { DispatchProps, StateProps } from '../components/organisms/ChatPublicInfo';
import { IReduxState } from '../reducers';

const emptyChannel: IChannel = chatService.emptyChannel();

function mapStateToPropsDetails(state: IReduxState, props: any): StateProps {
  const {
    contact: { inConsentio },
    chat: { channels },
    user: { user },
    contact: { hasOutContacts },
  } = state;
  const cId = props.match.params.channelId;
  const channel = cId && cId !== 'new' ? channels[cId] : emptyChannel;

  const channelState = channels[channel?.id] || channel;
  return {
    channel: channelState || emptyChannel,
    channels,
    contacts: inConsentio,
    hasOutContacts,
    me: user,
  };
}

function mapDispatchToPropsDetails(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, props: any): DispatchProps {
  return bindActionCreators(
    {
      channelArchive: chatActions.channelArchive,
      channelLeave: chatActions.channelLeave,
      channelMute: chatActions.channelMute,
      createNewContact: contactActions.createNewContact,
      deleteMembers: chatActions.deleteMembers,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      updateMember: chatActions.updateMember,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(ChatPublicInfo);
