import { contactActions, countryService, ICountry, IUser, RenderTrack, userActions } from 'common-services';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js/mobile';
import * as React from 'react';

import * as navActions from '../../../actions/nav';
import * as webUserActions from '../../../actions/user';
import { ILoginData, TWO_FA_STEPS } from '../../../constants';
import * as S from './2FAModal.styled';
import { AddPhone, Password, Success } from './Fragments';

export interface IDispatchProps {
  addPhone: typeof userActions.addPhone;
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  checkPassword: typeof userActions.checkPassword;
  navAuthAction: typeof navActions.navAuthAction;
  navResetAuthAction: typeof navActions.navResetAuthAction;
  resendCode: typeof webUserActions.resendCode;
  update2fa: typeof userActions.update2fa;
  updatePassword: typeof webUserActions.updatePassword;
  updateSettings: typeof userActions.updateSettings;
  userExists: typeof userActions.userExists;
  userSet: typeof userActions.userSet;
  verifyEmailCode: typeof webUserActions.verifyEmailCode;
  verifyPhone: typeof webUserActions.verifyPhone;
  verifyPhoneCode: typeof webUserActions.verifyPhoneCode;
}

export interface IStateProps {
  countries: { [k: string]: ICountry };
  error?: string;
  isOpened?: boolean;
  loading: boolean;
  me: IUser;
  onClose?: (toActive?: boolean) => void;
  toActivate?: boolean;
}

type IProps = IStateProps & IDispatchProps;

interface IState {
  code: string;
  hasPhoneNumber: boolean;
  loginData?: ILoginData;
  step: TWO_FA_STEPS;
}

export default class TwoFAModal extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const loginData = this.getInitialLoginData();
    let step = props.toActivate ? TWO_FA_STEPS.VERIFY_CODE : TWO_FA_STEPS.PASSWORD;
    if (!props.me.phone) step = TWO_FA_STEPS.PASSWORD;
    this.state = {
      code: '',
      hasPhoneNumber: !!props.me.phone,
      loginData,
      step,
    };
  }

  public componentDidMount() {
    const { step } = this.state;
    RenderTrack.track('TwoFAModal', { renderTime: this.t, step });
    const { me, onClose, verifyPhone } = this.props;
    if (step === TWO_FA_STEPS.VERIFY_CODE) {
      verifyPhone(me.phone + '', me.country, false, result => {
        if (result === 'error') onClose();
      });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { step } = this.state;
    if (prevState.step !== step && step) RenderTrack.track('TwoFAModal', { renderTime: Date.now(), step });
  }

  public render() {
    const { isOpened, onClose } = this.props;
    if (!isOpened) return null;
    return (
      <S.Container id="2fa_modal_container">
        <S.ModalWrapper>
          <S.CloseIcon name="Close" onClick={() => onClose?.()} />
          {this.renderCurrentStep()}
        </S.ModalWrapper>
      </S.Container>
    );
  }

  private renderCurrentStep() {
    const {
      addContactByPricelistUrl,
      addPhone,
      checkPassword,
      countries,
      me,
      navAuthAction,
      navResetAuthAction,
      onClose,
      resendCode,
      toActivate,
      update2fa,
      userExists,
      verifyEmailCode,
      verifyPhone,
      verifyPhoneCode,
    } = this.props;
    const { code, hasPhoneNumber, loginData, step } = this.state;

    switch (step) {
      case TWO_FA_STEPS.ADD_PHONE:
        return (
          <AddPhone
            countries={countries}
            loading={false}
            loginData={loginData}
            goToNextStep={() => {
              this.setState({ step: TWO_FA_STEPS.VERIFY_CODE });
            }}
            updateLoginData={this.updateLoginData}
            userExists={userExists}
            verifyPhone={verifyPhone}
          />
        );
      case TWO_FA_STEPS.PASSWORD:
        return (
          <Password
            checkPassword={checkPassword}
            hasPhoneNumber={hasPhoneNumber}
            loading={false}
            me={me}
            update2fa={update2fa}
            goToNextStep={(password: string) => {
              this.updateLoginData('password', password);
              if (!hasPhoneNumber) {
                this.setState({ step: TWO_FA_STEPS.ADD_PHONE });
              } else {
                onClose(toActivate);
              }
            }}
          />
        );

      case TWO_FA_STEPS.VERIFY_CODE:
        return (
          <S.VerifyCode
            addContactByPricelistUrl={addContactByPricelistUrl}
            addPhone={addPhone}
            code={code}
            goBack={() => this.setState({ step: TWO_FA_STEPS.ADD_PHONE })}
            goToNextStep={() => {
              update2fa(
                me.id,
                {
                  enable: true,
                  code,
                  password: '',
                },
                (err: Error) => {
                  if (!err) {
                    this.setState({ step: TWO_FA_STEPS.SUCCESS });
                  }
                },
              );
            }}
            loading={false}
            loginData={loginData}
            me={me}
            mode="phone"
            navAuthAction={navAuthAction}
            navResetAuthAction={navResetAuthAction}
            recoverPassword={false}
            resendCode={resendCode}
            setCode={(codeUpdate: string) => this.setState({ code: codeUpdate })}
            shouldAddPhone={!hasPhoneNumber}
            showBackButton={!hasPhoneNumber ? true : false}
            step={0}
            totalSteps={0}
            verifyEmailCode={verifyEmailCode}
            verifyPhoneCode={verifyPhoneCode}
          />
        );

      case TWO_FA_STEPS.SUCCESS:
        return <Success onEnd={() => onClose?.(toActivate)} />;
      default:
        return null;
    }
  }

  /**
   * Update login data with key / value
   * Also compute extra fields for phone validation as phoneParsed and phoneValid
   */
  private updateLoginData = (name: string, value: string | number) => {
    const { countries } = this.props;
    const { loginData } = this.state;
    const selectedCountry = countries[name === 'country' ? value : loginData.country];
    const phone = name === 'phoneNumber' ? value + '' : loginData.phoneNumber;
    const parsed =
      phone && selectedCountry ? parsePhoneNumberFromString(phone, selectedCountry.iso2Code as CountryCode) : undefined;

    this.setState({
      loginData: {
        ...loginData,
        [name]: value,
        countryPrefix: name === 'country' && selectedCountry ? selectedCountry.phonePrefix : loginData.countryPrefix,
        countryCode: selectedCountry ? selectedCountry.iso2Code : undefined,
        phoneParsed: parsed,
        phoneValid: parsed ? parsed.isValid() : false,
      },
    });
  };

  private getInitialLoginData(): ILoginData {
    const { countries, me } = this.props;
    if (!me.country || !me.phone) {
      const navigatorCountry =
        typeof window !== 'undefined'
          ? countryService.getCountryFromLocale(countries, window.navigator.language || '')
          : undefined;
      return {
        country: navigatorCountry?.id || '',
        phoneNumber: '',
        countryPrefix: navigatorCountry?.phonePrefix || '',
        countryCode: navigatorCountry?.iso2Code || '',
        phoneValid: false,
        password: '',
      };
    }
    const countryPrefix = me.country + '';
    const selectedCountry = Object.values(countries).find(c => c.phonePrefix === countryPrefix);
    const phone = me.phone;
    const parsed =
      phone && selectedCountry
        ? parsePhoneNumberFromString(phone + '', selectedCountry.iso2Code as CountryCode)
        : undefined;

    return {
      country: countryPrefix,
      phoneNumber: phone + '',
      countryPrefix: selectedCountry ? selectedCountry.phonePrefix : countryPrefix,
      countryCode: selectedCountry ? selectedCountry.iso2Code : undefined,
      phoneParsed: parsed,
      phoneValid: parsed ? parsed.isValid() : false,
      password: '',
    };
  }
}
