import { __, INVITE_ORIGIN, IUser, VIA, contactActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { IMAGES } from '../../../assets';
import share from '../../../services/sharer';
import { deviceIsIpad } from '../../../util/utils';

import * as S from './ShareInvite.styled';

export interface IProps {
  catalogId?: number;
  channelId?: string;
  className?: string;
  me: IUser;
  origin: INVITE_ORIGIN;
}

const ShareInvite: React.FC<IProps> = ({ className, catalogId, channelId, me, origin }) => {
  const dispatch = useDispatch();
  const onShare = (type: VIA) => {
    dispatch(
      contactActions.contactsInvite(
        me.id!,
        type,
        'web',
        origin,
        undefined,
        (data, error) => {
          if (data?.inviteLink && !error) handleShare(type, data.inviteLink);
        },
        channelId,
        catalogId,
      ) as any,
    );
  };

  const handleShare = (type: VIA, inviteLink: string) => {
    switch (type) {
      case 'whatsapp':
        share({
          sharer: 'whatsapp',
          url: inviteLink,
          title: __('Components.InviteOnboarding.2.share_message'),
          web: !deviceIsIpad(),
        });
        break;
      case 'facebook':
        share({ sharer: 'facebook', url: inviteLink });
        break;
      case 'linkedin':
        share({ sharer: 'linkedin', url: inviteLink });
        break;
      case 'email':
        share({
          sharer: 'email',
          url: inviteLink,
          title: __('Components.InviteOnboarding.2.share_message'),
          subject: __('Components.InviteOnboarding.2.subject_share_message'),
        });
        break;
    }
  };

  return (
    <S.Container className={className}>
      <S.ShareLink
        type="link"
        iconUrl={IMAGES.facebook}
        iconSize="19px"
        onClick={() => {
          onShare(VIA.FACEBOOK);
        }}
      >
        Facebook
      </S.ShareLink>
      <S.Divider />
      <S.ShareLink
        type="link"
        iconUrl={IMAGES.linkedin}
        iconSize="19px"
        onClick={() => {
          onShare(VIA.LINKEDIN);
        }}
      >
        LinkedIn
      </S.ShareLink>
      <S.Divider />
      <S.ShareLink
        type="link"
        iconUrl={IMAGES.whatsapp}
        iconSize="19px"
        onClick={() => {
          onShare(VIA.WHATSAPP);
        }}
      >
        WhatsApp
      </S.ShareLink>
      <S.Divider />
      <S.ShareLink
        type="link"
        iconUrl={IMAGES.email}
        iconSize="19px"
        onClick={() => {
          onShare(VIA.EMAIL);
        }}
      >
        {__('Components.AddNonRegisteredUser.email')}
      </S.ShareLink>
    </S.Container>
  );
};

export default React.memo(ShareInvite);
