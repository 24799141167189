import { PRODUCT_UNIT } from 'common-services';
import * as React from 'react';

import { logError } from '../services/log';
import { sizes } from '../theme';

/**
 * Check if device is iPad
 */
export function deviceIsIpad() {
  return navigator.userAgent && navigator.userAgent.match(/iPad/i) != null;
}

/**
 * Check if an element is visible in the view port (visible screen)
 */
export function isElementInViewport(elementId: string, offset: number = 0) {
  const element = document.getElementById(elementId);
  if (!element) return false;
  const top = element.getBoundingClientRect().top;
  return top + offset >= 0 && top - offset <= window.innerHeight;
}

/**
 * Transform color like "rgba(0,0,0)" to hex "#000000"
 */
export function rgbaToHex(color: string) {
  if (color.startsWith('#')) return color;
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  /* tslint:disable:no-bitwise */
  const hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
    .toString(16)
    .slice(1)}`;
  /* tslint:enable:no-bitwise */
  return hex;
}

/**
 * Transform an hexadecimal color applying a darkener/lightener
 * e.g.
 * LightenDarkenColor('#ababab', 20) lightens the color
 * LightenDarkenColor('#ababab', -20) darkens the color
 * @param color the hex color
 * @param increment the lightener/darkener factor
 * @returns the color lightened/darkened
 */
export function lightenDarkenColor(color: string, increment: number): string {
  let usePound = false;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num: number = parseInt(color, 16);
  /* tslint:disable:no-bitwise */
  let r: number = (num >> 16) + increment;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  /* tslint:disable:no-bitwise */
  let b = ((num >> 8) & 0x00ff) + increment;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  /* tslint:disable:no-bitwise */
  let g = (num & 0x0000ff) + increment;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  /* tslint:disable:no-bitwise */
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

/**
 * Copy an element to clipboard
 */
export function copyToClipboard(elementToCopy: string, cb?: (error?: Error) => void) {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
      .writeText(elementToCopy)
      .then(() => {
        cb?.();
      })
      .catch(error => {
        cb?.(error);
        logError(error, 'copyToClipboard');
      });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = elementToCopy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    new Promise((res, rej) => {
      document.execCommand('copy') ? res(true) : rej();
      textArea.remove();
    })
      .then(() => cb?.())
      .catch(error => cb?.(error));
  }
}

export function deviceIsIOS() {
  return typeof window !== 'undefined'
    ? // @ts-ignore
      /iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor) && !window.MSStream
    : undefined;
}

export function deviceIsSafari() {
  return typeof window !== 'undefined'
    ? /Safari/.test(navigator.userAgent || navigator.vendor) &&
        // @ts-ignore
        !window.MSStream &&
        !/Chrome/.test(navigator.userAgent || navigator.vendor)
    : undefined;
}

export function hasMobileSize() {
  return typeof window !== 'undefined' ? window.innerWidth <= sizes.mobile : false;
}

export function isMobileDevice() {
  return typeof navigator !== 'undefined' ? /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) : false;
}

/**
 * Convert a string with newlines in multiple paragraphs
 */
export function toParagraph(text: string = '') {
  return text.split('\n').reduce((acc, item, idx, array) => {
    acc.push(item);
    if (idx < array.length - 1) {
      acc.push(<br key={idx} />);
    }
    return acc;
  }, []);
}

/**
 * Get url path params as an array of strings
 */
export function getUrlPathParams(url: string, baseUrl: string): Array<string> {
  const urlSplit = url.includes('?') ? url.split('?')[0] : url;
  return urlSplit.replace(baseUrl + '/', '').split('/');
}
/**
 * Change the price unit from BOX to SHELF, PALLET to CART
 */
export const priceUnitTranslate = (unit: PRODUCT_UNIT) => {
  switch (unit) {
    case PRODUCT_UNIT.KG:
      return PRODUCT_UNIT.SHELF;
    case PRODUCT_UNIT.BOX:
      return PRODUCT_UNIT.SHELF;
    case PRODUCT_UNIT.PALLET:
      return PRODUCT_UNIT.CART;
    case PRODUCT_UNIT.PIECE:
      return PRODUCT_UNIT.PIECE;
  }
};
