import { __, currency, IOrderItem, IUser } from 'common-services';
import { History } from 'history';
import * as React from 'react';

import * as S from './PORProduct.styled';
import { ActionsModal } from '../../../../molecules';

interface IProps {
  close: () => void;
  history: History;
  item: IOrderItem;
  me: IUser;
  onAccept: (item: IOrderItem, updateProductPrice: boolean) => void;
  pricePrecision: number;
}

interface IState {
  price: number;
  updateProductPrice: boolean;
}

class PORProduct extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { price: 0, updateProductPrice: false };
  }

  public render() {
    const { price, updateProductPrice } = this.state;
    const { close, item, pricePrecision } = this.props;
    return (
      <ActionsModal title={__('Components.Cart.por_product.title', {name:item.title})} onClose={close} subtitle={__('Components.Cart.por_product.subtitle')} minHeight='300px'>
        <S.Container>
          <S.Row paddingMultiplier={3}>
            <S.BigText>{__('Components.Cart.por_product.input_text')}</S.BigText>
            <S.TextInput
              name="price-input"
              type="number"
              precision={pricePrecision}
              onChange={this.onChange}
              minValue={currency.getPriceInputMinimumValue(pricePrecision)}
              width="150px"
              textAlign="right"
              variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
              variableTextPlural={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
            />
          </S.Row>
          <S.Row>
            <S.CheckBox
              isChecked={updateProductPrice}
              onClick={() => this.setState({ updateProductPrice: !updateProductPrice })}
            />
            <S.CheckBoxText>{__('Components.Cart.por_product.checkbox')}</S.CheckBoxText>
          </S.Row>
          <S.AcceptButton disabled={price < 0.01} onClick={this.closeAndChangeItemPrice}>
            {updateProductPrice
              ? __('Components.Cart.por_product.accept_with_checkbox')
              : __('Components.Cart.por_product.accept')}
          </S.AcceptButton>
        </S.Container>
      </ActionsModal>
    );
  }

  private onChange = (key: string, value: number) => {
    this.setState({ price: Number(value) });
  };

  /**
   * closes the modal an changes the product price
   */
  private closeAndChangeItemPrice = () => {
    const { close, onAccept, item } = this.props;
    const { price, updateProductPrice } = this.state;
    close();
    onAccept({ ...item, price }, updateProductPrice);
  };
}

export default PORProduct;
