import { __ } from 'common-services';
import * as React from 'react';

import * as S from '../OrderDelivery.styled';
import Row from './OrderDeliveryRow.component';
import Total from './OrderDeliveryTotal.component';

interface IProps {
  contacts: { [cId: number]: IContact };
  orderItems: Array<IOrderAggregationItem>;
  searchState: ISearchOrder;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
}

const OrderDeliveryTable: React.FC<IProps> = ({ contacts, orderItems, searchState, touchImage }) => {
  return (
    <S.TableOrders key={orderItems[0]?.childId}>
      <thead>
        <S.Header>
          <S.ImageColHead />
          <S.BigHead>{__('Components.OrdersList.Table.product')}</S.BigHead>
          <S.SmallHead>{__('Components.OrdersList.Table.origin')}</S.SmallHead>
          <S.SmallHead>{__('Components.OrdersList.Table.category')}</S.SmallHead>
          <S.SmallHead>{__('Components.OrdersList.Table.size')}</S.SmallHead>
          <S.SmallHeadRight>{__('Components.OrdersList.Table.quantity')}</S.SmallHeadRight>
          <S.SmallHeadRight>{__('Components.OrdersList.Table.unitPrice')}</S.SmallHeadRight>
          <S.SmallHeadRight>{__('Components.OrdersList.Table.Price')}</S.SmallHeadRight>
          <S.RegularHead>{__('Components.OrdersList.Table.provider')}</S.RegularHead>
        </S.Header>
      </thead>
      <S.Body>
        {orderItems.map((oi, i) => (
          <Row
            first={i === 0}
            key={oi.orderHash + i}
            contacts={contacts}
            searchState={searchState}
            touchImage={touchImage}
            orderItem={oi}
          />
        ))}
      </S.Body>
      <Total orderItems={orderItems} searchState={searchState} />
    </S.TableOrders>
  );
};

export default React.memo(OrderDeliveryTable);
