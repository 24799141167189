import { __, IBroadcast, utils, INVITE_ORIGIN, broadcastActions } from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import config from '../../../../bindings/config';

import { BROADCAST_SECTIONS, ROUTE_PATHS } from '../../../constants';
import SelectContacts from '../../../screens/select-contacts';
import getPath from '../../../util/routes';
import { FontIcon } from '../../atoms';
import { MiniImages, NavigationTabs } from '../../molecules';
import * as S from './ChatBroadcastHeader.styled';

export type OwnProps = RouteComponentProps<{ section?: string; channelId?: string }>;

export interface StateProps {
  broadcast?: IBroadcast;
  children?: React.ReactNode;
  contacts: Record<number, IContact>;
  me: IUser;
  section?: BROADCAST_SECTIONS;
}
export interface DispatchProps {
  addMembers: typeof broadcastActions.addMembers;
}

type IProps = StateProps & DispatchProps & OwnProps;

const ChatBroadcastHeader: React.FC<IProps> = ({ broadcast, addMembers, children, contacts, me, section, history }) => {
  const [showAddContacts, setShowAddContacts] = React.useState<boolean>();
  const tabs = [];
  if (broadcast) {
    tabs.push({
      id: 'messages',
      label: utils.firstToUpperCase(__('Components.ContactDetails.Header.chat').toLocaleLowerCase()),
      action: () => history.push(getPath({ path: ROUTE_PATHS.BROADCAST, channelId: broadcast.id + '' })),
    });
    tabs.push({
      id: 'info',
      label: utils.firstToUpperCase(__('Components.ContactDetails.Header.info').toLocaleLowerCase()),
      action: () =>
        history.push(
          getPath({
            path: ROUTE_PATHS.BROADCAST_INFO,
            channelId: broadcast.id + '',
          }),
        ),
    });
  } else {
    tabs.push({
      id: 'new',
      label: utils.firstToUpperCase(__('Components.Broadcast.new_link').toLocaleLowerCase()),
      action: () => null,
    });
  }

  const tabSelected = !section ? 'messages' : section === BROADCAST_SECTIONS.INFO ? 'info' : 'new';
  return (
    <S.RightContainer>
      <S.Container>
        <S.Content>
          <S.Title>{__('Components.Broadcast.title')}</S.Title>
          <S.SubTitle>{broadcast ? broadcast.name : __('Components.Broadcast.new')}</S.SubTitle>
        </S.Content>
      </S.Container>
      <NavigationTabs selected={tabSelected} tabs={tabs} history={history} />
      <S.MembersContainer
        className="chat-header-members-container"
        onClick={() =>
          history.push(
            getPath({
              path: ROUTE_PATHS.BROADCAST_INFO,
              channelId: broadcast.id + '',
            }),
          )
        }
      >
        <MiniImages count={broadcast?.numberOfMembers} mode={'icon'} />
        <S.AddMember onClick={() => setShowAddContacts(true)}>
          <FontIcon id="group-icon" name="Add-contact" />
        </S.AddMember>
      </S.MembersContainer>

      {children}
      {showAddContacts ? renderAddContacts() : null}
    </S.RightContainer>
  );

  /**
   * Render modal for add members
   */
  function renderAddContacts(): JSX.Element {
    const contactAddedIds = Object.keys(broadcast.members);
    const contactsNotAdded = Object.values(contacts).filter(
      c => !contactAddedIds.includes(c.id + '') && c.id !== me.id!,
    );

    return (
      <SelectContacts
        channelId={broadcast?.id}
        onClose={() => setShowAddContacts(false)}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          addMembers(me.id, broadcast.id, contactsToAdd);
          setShowAddContacts(false);
        }}
        origin={INVITE_ORIGIN.GROUP}
        showUnregistered={true}
        title={__('Channel.invite_modal.title_consentio')}
        subtitle={__('Channel.invite_modal.subtitle_consentio')}
        trackingFrom="chat-broadcast-header"
      />
    );
  }
};

export default ChatBroadcastHeader;
