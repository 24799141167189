import { __, constants, modalActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ACCEPT_ALL_FILES, MAX_FILE_SIZE, MAX_UPLOAD_FILES, MAX_VIDEO_SIZE } from '../../../constants';
import { convertToIFile } from '../../../services/file';
import { ImageThumbnail, Picture } from '../../atoms';
import * as S from './MultipleFilesModal.styled';

export interface IProps {
  files: Array<IPreviewFile>;
  onClose: () => void;
  receiverName: string;
  sendFiles: (files: Array<IPreviewFile>) => void;
  setFiles: (files: Array<IPreviewFile>) => void;
}

const MultipleFilesModal: React.FC<IProps> = ({ files, onClose, receiverName, setFiles, sendFiles }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [selected, setSelected] = React.useState(0);
  const pictureRef = React.useRef<Picture>();

  /**
   * Convert File to an IFile and add it to the array
   */
  const handleSelectFile = React.useCallback(
    (newFiles: Array<File>) => {
      if (newFiles && newFiles.length) {
        const totalFiles = newFiles.length;

        const result = [...files];
        let iteration = 0;
        let excludedFilesForSize = 0;
        (newFiles as Array<File>).forEach(f => {
          convertToIFile(
            f,
            (newFile: IFile, fileString: string) => {
              // Files that are not images with exceeding size are excluded
              // Images are lowered afterwards
              let excludeFile = false;
              const isVideoFile = newFile.type.startsWith('video/');
              const maxFileSize = isVideoFile ? MAX_VIDEO_SIZE : MAX_FILE_SIZE;

              if (!newFile.type.startsWith('image/') && newFile.size > maxFileSize) {
                excludeFile = true;
                excludedFilesForSize++;
              }
              if (result.length <= MAX_UPLOAD_FILES && !excludeFile) result.push({ file: newFile, fileString });
              iteration++;

              if (iteration === totalFiles) {
                setFiles(result);
                setSelected(result.length - 1);
                if (excludedFilesForSize > 0)
                  dispatch(
                    modalActions.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 })),
                  );
              }
            },
            () => iteration++,
          );
        });
      }
    },
    [dispatch, files, setFiles, setSelected],
  );

  /**
   * Open the image picker
   */
  const openImagePicker = React.useCallback(() => {
    const imageInput = document.getElementById('multiple-files-modal-input') as HTMLInputElement;
    if (imageInput) imageInput.click();
  }, []);

  const file = files[selected]?.file;
  const isImage = file.type.startsWith('image/');
  let imagePreview = file && isImage ? `data:${file.type};base64,${file.content}` : '';
  if (!isImage) imagePreview = constants.getLogoFromMime(file.type);

  return (
    <S.Container className="multiple-files-modal-container">
      <S.Content>
        {file ? (
          <S.ImageContainer>
            <S.TextFileName>{file.name}</S.TextFileName>
            {isImage ? (
              <S.Image src={imagePreview} alt={file.name} isImage={true} />
            ) : (
              <S.FileContainer>
                <S.Image src={imagePreview} alt={file.name} isImage={false} />
              </S.FileContainer>
            )}
          </S.ImageContainer>
        ) : null}
        <S.ThumbnailsRow>
          {files.length > 1
            ? files.slice(0, MAX_UPLOAD_FILES).map((f, i) => {
                return (
                  <ImageThumbnail
                    key={i + '_' + f.file.name}
                    imagePreview={f}
                    isSelected={i === selected}
                    onDeleteClick={() => {
                      setFiles([...files.filter((_, j) => j !== i)]);
                      if (i <= selected) setSelected(Math.max(0, selected - 1));
                    }}
                    onImageClick={() => setSelected(i)}
                  />
                );
              })
            : null}
          {files.length < MAX_UPLOAD_FILES ? (
            <S.AddContainer onClick={() => openImagePicker()}>
              <S.AddIcon name="Plus" disableHover={true} />
            </S.AddContainer>
          ) : null}
        </S.ThumbnailsRow>
        <S.CloseIcon name="Close" onClick={onClose} />
        <S.SendContainer onClick={() => sendFiles(files.slice(0, MAX_UPLOAD_FILES))}>
          <S.SendIcon name="Send" />
          <S.TextSend>{__('Components.Chat.send_to', { name: receiverName })}</S.TextSend>
        </S.SendContainer>
      </S.Content>
      <Picture
        accept={ACCEPT_ALL_FILES}
        cropStrategy="contain"
        hidden={true}
        multiple={true}
        id="multiple-files-modal-input"
        onFilesChange={handleSelectFile}
        ref={pictureRef}
        withCrop={false}
      />
    </S.Container>
  );
};

export default React.memo(MultipleFilesModal);
