import { __, CHANNEL_TYPE, date, DATE_FORMAT, HOUR_FORMAT, IAvatarColor } from 'common-services';
import * as React from 'react';

import { LettersAvatar } from '../../atoms';
import * as S from './ContactItemSelectable.styled';

export interface IProps {
  avatar?: string;
  avatarColor: IAvatarColor;
  dateFormat?: DATE_FORMAT;
  disabled?: boolean;
  hourFormat?: HOUR_FORMAT;
  isAdmin?: boolean;
  isLast?: boolean;
  isRadio?: boolean;
  name: string;
  onPress?: () => void;
  selected?: boolean;
  self: boolean;
  subtitle?: string;
  time?: number;
  type?: CHANNEL_TYPE;
}

const ContactItemSelectable: React.FC<IProps> = React.memo(
  ({
    avatar,
    avatarColor,
    dateFormat,
    disabled,
    hourFormat,
    isAdmin,
    isLast,
    isRadio,
    name,
    onPress,
    selected,
    self,
    subtitle,
    time,
    type,
  }) => {
    if (!name) return null;
    return (
      <S.ContactItem
        onClick={() => {
          if (!self && !disabled && onPress) onPress();
        }}
        isLast={isLast}
        disabled={self || disabled}
      >
        {self || disabled ? (
          <S.EmptyCheckBox />
        ) : (
          <S.RadioButton
            checked={selected}
            className="contact-selectable-radio"
            isRadio={isRadio}
            onClick={onPress}
            value={`${name}-radio`}
          />
        )}
        <LettersAvatar text={name} img={avatar || ''} avatarColor={avatarColor} size={44} type={type} />
        <S.TextContent>
          <S.Row>
            {isAdmin ? (
              <S.Admin>
                <S.AdminText>{__('Components.Channel.admin')}</S.AdminText>
              </S.Admin>
            ) : null}
            {type === 'group' ? (
              <S.Group>
                <S.AdminText>{__('Components.Channel.group')}</S.AdminText>
              </S.Group>
            ) : null}
            {type === 'broadcast' ? (
              <S.Broadcast>
                <S.AdminText>{__('Components.Channel.broadcast')}</S.AdminText>
              </S.Broadcast>
            ) : null}
            <S.Title isAdmin={isAdmin}>{name}</S.Title>
          </S.Row>
          {subtitle ? <S.SubTitle>{subtitle}</S.SubTitle> : null}
        </S.TextContent>
        {time ? <S.Date>{date.timeToText(time, hourFormat, undefined, undefined, dateFormat)}</S.Date> : null}
      </S.ContactItem>
    );
  },
);

export default ContactItemSelectable;
