import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, DropzoneContainer, RowContainer, TextLabel } from '../../atoms';
import { PostHeader } from '../../molecules/PostMessage/PostMessage.component';

interface IStyledProps {
  hasImage?: boolean;
  isLast?: boolean;
  tabSelected?: string;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: 100%;
  justify-content: flex-start;
  position: relative;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextGrey1 = styled(TextRegular)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const BodyContainer = styled(RowContainer)<IStyledProps>`
  flex: 1;
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  max-width: 1052px;
  width: 1052px;
  align-self: center;
  justify-content: ${(p: IStyledProps) => (p.tabSelected === 'info' ? 'center' : 'space-between')};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    max-width: 952px;
    width: 952px;
  }

  @media (max-width: 1500px) {
    max-width: 902px;
    width: 902px;
  }

  @media (max-width: 1350px) {
    max-width: 802px;
    width: 802px;
    justify-content: center;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    max-width: 100%;
    width: 100%;
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  }
`;

export const RightContainer = styled(ColumnContainer)`
  width: auto;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};

  @media (max-width: 1350px) {
    display: none;
  }
`;

export const ContentContainer = styled(ColumnContainer)`
  min-width: 615px;
  max-width: 615px;

  #chat-public-channel-description {
    display: none;
  }

  @media (max-width: 1500px) {
    min-width: ${(p: IStyledProps) => (p.tabSelected === 'info' ? 615 : 580)}px;
    max-width: ${(p: IStyledProps) => (p.tabSelected === 'info' ? 615 : 580)}px;
  }

  @media (max-width: 1350px) {
    max-width: 615px;
    width: 615px;

    #chat-public-channel-description {
      display: flex;
    }
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 100%;
    width: 100%;
  }
`;

export const CardContainer = styled(ColumnContainer)`
  min-height: min-content;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const CardTop = styled(RowContainer)`
  min-height: min-content;
  width: 100%;
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CardBottom = styled(ColumnContainer)`
  min-height: min-content;
  width: 100%;
  align-items: center;
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
`;

export const CardBottomActions = styled(RowContainer)`
  width: 65%;
  justify-content: space-around;
  align-items: center;
`;

export const CardButton = styled(Button)``;

export const FakePostInput = styled(RowContainer)`
  width: 100%;
  height: 33px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  cursor: pointer;
`;

export const MemberCard = styled(CardContainer)`
  margin: 0;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  width: 370px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    width: 310px;
  }

  @media (max-width: 1500px) {
    width: 300px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const DescriptionCard = styled(MemberCard)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TitleCard = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TitleCardGrey = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const SubtitleCard = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextDescription = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const MemberItem = styled(PostHeader)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 0 : 2)};
`;

export const DropZone = styled(DropzoneContainer)``;

export const SeeMoreContainer = styled(ColumnContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-left: 2px;
  justify-content: center;
  height: 22px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TextLink = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-align: right;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const LoadingContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)} 0;
`;
