import { WORKING_STATUS } from 'common-services';
import * as React from 'react';

import FontIcon, { IFontIconKeys } from '../FontIcon';
import * as S from './LettersAvatar.styled';

export interface IProps {
  avatarColor: { background: string; text: string };
  borderRadius?: string;
  className?: string;
  disabled?: boolean;
  iconName?: IFontIconKeys;
  img: string;
  onClick?: () => void;
  size?: number;
  text: string;
  type?: string;
  workingStatus?: WORKING_STATUS;
  bubbleSize?: number;
}

const LettersAvatar: React.FC<IProps> = ({
  avatarColor = { background: '', text: '' },
  borderRadius,
  bubbleSize = 12,
  className,
  disabled = false,
  iconName,
  img,
  onClick,
  size = 30,
  text = '',
  type,
  workingStatus,
}) => {
  let chars: string = '';
  const array = text.match(/\b(\w)/g);
  if (array) {
    chars = array.slice(0, 2).join('');
  }
  if (img) {
    if (type === 'comment') {
      return (
        <S.OuterSquare
          color={avatarColor.background}
          size={size}
          img={img}
          className={className}
          onClick={onClick}
          disabled={disabled}
        />
      );
    }
    return (
      <S.Outer
        color={avatarColor.background}
        size={size}
        img={img}
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        {workingStatus ? <S.WorkingStatus status={workingStatus} size={bubbleSize} /> : null}
      </S.Outer>
    );
  }
  if (type === 'group') {
    return (
      <S.OuterGroup size={size} img={img} className={className} onClick={onClick} disabled={disabled}>
        <S.InnerGroup color={avatarColor.text} size={size}>
          <FontIcon id="group-icon" name="Group" disableHover={disabled} />
        </S.InnerGroup>
      </S.OuterGroup>
    );
  }
  if (type === 'team') {
    return (
      <S.OuterGroup size={size} img={img} className={className} disabled={disabled}>
        <S.InnerGroup color={avatarColor.text} size={size}>
          <FontIcon id="team-icon" name="Team-icon" disableHover={disabled} />
        </S.InnerGroup>
      </S.OuterGroup>
    );
  }
  if (type === 'broadcast') {
    return (
      <S.OuterGroup
        color={avatarColor.background}
        size={size}
        img={img}
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        <S.InnerGroup color={avatarColor.text} size={size}>
          <FontIcon id="broadcast-icon" name="Diffusion" disableHover={disabled} />
        </S.InnerGroup>
      </S.OuterGroup>
    );
  }
  if (type === 'comment') {
    return (
      <S.OuterSquare
        color={avatarColor.background}
        size={size}
        img={img}
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        <S.Inner color={avatarColor.text} size={size}>
          {chars && chars.toUpperCase()}
        </S.Inner>
      </S.OuterSquare>
    );
  }
  return (
    <S.Outer
      color={avatarColor.background}
      size={size}
      img={img}
      className={className}
      borderRadius={borderRadius}
      onClick={onClick}
      disabled={disabled}
    >
      {iconName ? (
        <S.Icon color={avatarColor.text} size={size} name={iconName} />
      ) : (
        <S.Inner color={avatarColor.text} size={size}>
          {chars && chars.toUpperCase()}
        </S.Inner>
      )}
      {workingStatus ? <S.WorkingStatus status={workingStatus} size={bubbleSize} /> : null}
    </S.Outer>
  );
};

export default LettersAvatar;
