import {
  Api,
  countryActions,
  imageActions,
  modalActions,
  notificationsActions,
  prodTypeActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Settings, { ISettingsDispatchProps, ISettingsStateProps } from '../components/organisms/Settings';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState): ISettingsStateProps {
  const {
    catalog: { catalogs },
    contact: { inConsentio },
    user: { user },
    tag: { tags },
  } = state;
  return {
    catalog: catalogs[user.sellerWorkspaceId],
    contacts: inConsentio,
    editMode: true,
    me: user!,
    tags,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): ISettingsDispatchProps {
  return bindActionCreators(
    {
      catalogMembersUpdate: sellerWorkspaceActions.catalogMembersUpdate,
      countriesGet: countryActions.countriesGet,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      prodTypesGet: prodTypeActions.prodTypesGet,
      sendEmailVerification: userActions.sendEmailVerification,
      notificationShow: notificationsActions.notificationShow,
      updateUser: userActions.updateUser,
      verifyUser: userActions.verifyUserData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
