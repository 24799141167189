import * as React from 'react';

import { IFontIconKeys } from '../FontIcon';
import * as S from './FilterSwitchIcon.styled';

export interface IProps {
  className?: string;
  keySelected: string;
  onSelectOption: (key: string) => void;
  options: Array<{ key: string; icon: IFontIconKeys }>;
}

const FilterSwitchIcon: React.FC<IProps> = ({ className, keySelected, onSelectOption, options }) => {
  return (
    <S.Container id="filter-switch-icon" className={className}>
      {options.map(({ key, icon }, index, array) => (
        <S.OptionItem
          isFirst={index === 0}
          isLast={index === array.length - 1}
          isSelected={key === keySelected}
          key={index + key}
          onClick={() => onSelectOption(key)}
        >
          <S.Icon name={icon} disableHover={true} isSelected={key === keySelected} />
        </S.OptionItem>
      ))}
    </S.Container>
  );
};

export default React.memo(FilterSwitchIcon);
