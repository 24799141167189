import { AxiosError } from 'axios';
import {
  __,
  apiParsers,
  broadcastActions,
  CHANNEL_TYPE,
  chatActions,
  EventTrack,
  IBroadcast,
  IContact,
  IDraftMessage,
  IFile,
  imageActions,
  IMessage,
  IProdType,
  IUser,
  modalActions,
  RenderTrack,
  throttle,
  utils,
} from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import * as userAppActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import { MAX_FILE_SIZE, MAX_VIDEO_SIZE } from '../../../constants';
import { bindPasteListener, clearPasteListener, convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { ChatFooter, FileModal, Message, Scroll, ZeroCase } from '../../molecules';
import * as S from './ChatBroadcast.styled';

type RouteProps = RouteComponentProps<{ contactId?: string }>;

export interface StateProps {
  broadcast?: IBroadcast;
  contacts: Record<number, IContact>;
  draft?: IDraftMessage;
  initialized: boolean;
  isAll?: boolean;
  messages?: Array<IMessage>;
  me: IUser;
  prodTypes: { [key: string]: IProdType };
}

export interface DispatchProps {
  createBroadcast: typeof broadcastActions.createBroadcast;
  getMessages: typeof broadcastActions.getMessages;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateToShowroom: typeof navActions.navigateToShowroom;
  openChannel: (broadcastId: string, myId: number) => void;
  sendMessage: typeof broadcastActions.sendMessage;
  setActiveBroadcast: typeof broadcastActions.setActiveBroadcast;
  setMessageDraft: typeof broadcastActions.setMessageDraft;
  supportAction: typeof userAppActions.supportAction;
  touchFile: typeof chatActions.downloadFile;
  touchImage: typeof modalActions.touchImage;
}

type IProps = StateProps & DispatchProps & RouteProps;

interface IState {
  filePreview?: IPreviewFile;
}

export default class ChatBroadcast extends React.PureComponent<IProps, IState> {
  private t: number;
  private channelOpened: boolean;
  private onLoadMore: () => void;
  constructor(public props: IProps) {
    super(props);
    this.t = Date.now();
    /**
     * Due to a large amount of repainting, we set a throttle to limit getMessages calls.
     */
    this.onLoadMore = throttle(() => {
      const { me, broadcast } = this.props;
      props.getMessages(me.id, broadcast.id!);
    }, 1000);
    this.state = {};
  }

  public componentDidMount() {
    const { broadcast, initialized, me, openChannel } = this.props;
    this.setupPaste();

    if (broadcast && initialized) {
      RenderTrack.track('ChatBroadcast', {
        renderTime: this.t,
        broadcastId: broadcast.id + '',
        numMembers: broadcast.numberOfMembers,
      });
      openChannel(broadcast.id!, me.id);
      this.channelOpened = true;
    }
  }

  public componentWillUnmount() {
    const { broadcast, initialized, setActiveBroadcast } = this.props;
    if (broadcast && initialized) {
      setActiveBroadcast();
    }
    clearPasteListener();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { broadcast, initialized, me, openChannel } = this.props;
    if (
      broadcast &&
      initialized &&
      (!this.channelOpened || !prevProps.broadcast || prevProps.broadcast!.id !== broadcast!.id)
    ) {
      RenderTrack.track('ChatBroadcast', {
        renderTime: this.t,
        broadcastId: broadcast.id + '',
        numMembers: broadcast.numberOfMembers,
      });
      openChannel(broadcast.id!, me.id);
      this.channelOpened = true;
      this.setupPaste();
    }
  }

  public render() {
    const { broadcast, contacts, modalOpen, me, draft, setMessageDraft } = this.props;
    if (!broadcast) return null;
    return (
      <React.Fragment>
        <S.Container>
          <Scroll loadMore={this.onLoadMore}>{this.renderMessages()}</Scroll>
          <ChatFooter
            contacts={contacts}
            draftText={draft ? draft.text : ''}
            handleSendFile={files => this.handleSendFile(files[0])}
            id={broadcast.id}
            me={me}
            modalOpen={modalOpen}
            name={broadcast.name}
            sendMessage={this.sendMessage}
            setMessageDraft={setMessageDraft}
          />
        </S.Container>
        {this.renderFileModal()}
      </React.Fragment>
    );
  }

  /**
   * Handle send a file parsing File to IFile before to call a sendFile
   */
  private handleSendFile = (f: File) => {
    if (f) convertToIFile(f, (file: IFile, fileString: string) => this.setState({ filePreview: { file, fileString } }));
  };

  /**
   * Render file modal
   */
  private renderFileModal() {
    const { filePreview } = this.state;
    if (!filePreview) return null;
    return (
      <FileModal
        hideCategories={true}
        feedbackText={__('Components.Chat.send_to', { name: '' })}
        file={filePreview.file}
        modalClose={() => this.setState({ filePreview: undefined })}
        sendFile={() => {
          this.sendFile(filePreview.file, filePreview.fileString);
          this.setState({ filePreview: undefined });
        }}
      />
    );
  }

  /**
   * Sends a file through the chat. If the file is a message the function uploads the image to cloudinary,
   * if is another file type then upload to rtapi and then inject to chat.
   */
  private sendFile = (file: IFile, fileString: string) => {
    const { mediaUpload } = this.props;

    const isVideoFile = file.type.startsWith('video/');
    const maxFileSize = isVideoFile ? MAX_VIDEO_SIZE : MAX_FILE_SIZE;

    if (file.type.startsWith('image/') || isVideoFile) {
      if (['image/gif', 'image/webp'].includes(file.type) || isVideoFile) {
        if (file.size > maxFileSize) {
          this.props.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 }));
          return;
        }
        mediaUpload({ ...file, content: fileString }, data => {
          if (data?.secure_url)
            this.sendMessage('', data.secure_url, true, undefined, {
              media_metadata: apiParsers.APICloudinaryToMetadata(data),
            });
        });
      } else {
        resizeImage(fileString, (imageResized: string) =>
          mediaUpload({ ...file, content: imageResized }, data => {
            if (data?.secure_url)
              this.sendMessage('', data.secure_url, true, undefined, {
                media_metadata: apiParsers.APICloudinaryToMetadata(data),
              });
          }),
        );
      }
    } else if (file) {
      if (file.size > maxFileSize) {
        this.props.modalOpen(__('Components.Chat.max_size_exceeded', { max: maxFileSize / 1000000 }));
        return;
      }
      this.sendMessage('', '', true, file);
    }
  };

  /**
   * Bind paste listener to all document and attach callback
   */
  private setupPaste() {
    bindPasteListener((f: File) => {
      convertToIFile(f, (file: IFile, fileString: string) => this.setState({ filePreview: { file, fileString } }));
    });
  }

  private sendMessage = (channelId: string, text: string, isFile?: boolean, file?: IFile, extraData?: any) => {
    const { broadcast, sendMessage, modalClose, modalOpen, me, supportAction } = this.props;
    sendMessage(
      me.id,
      {
        channelId: broadcast!.id!,
        createdAt: new Date().getTime() + 60000,
        extraData: extraData ?? {},
        message: text,
        messageId: 0,
        messageType: isFile ? 'file' : 'text',
        reactions: {},
        senderId: me.id,
      },
      file,
      (err: AxiosError) => {
        if (err) {
          const isLimitError = err.response && err.response.status === 429;
          modalOpen(
            isLimitError
              ? __('Components.Broadcast.message_error.limit_text')
              : __('Components.Broadcast.message_error.text'),
            isLimitError ? supportAction : modalClose,
            {
              buttonText: isLimitError
                ? __('Components.Broadcast.message_error.limit_button')
                : __('Components.Broadcast.message_error.button'),
              icon: 'Delete',
            },
          );
        }
      },
    );
    EventTrack.track('message_send', {
      channelId: broadcast.id,
      messageType: isFile ? 'file' : 'text',
      length: text.length,
      type: CHANNEL_TYPE.BROADCAST,
    });
  };

  private renderMessages() {
    const { isAll, history, messages, me, prodTypes, touchFile, touchImage } = this.props;
    if (!messages)
      return (
        <ZeroCase
          link={IMAGES.messagesGrey}
          title={__('Components.Broadcast.zerocase_title')}
          subtitle={__('Components.Broadcast.zerocase_subtitle')}
        />
      );
    const idx = messages.findIndex(m => m.messageId > 0);
    let messagesToRender = messages;
    if (!isAll) {
      messagesToRender = idx !== -1 ? messages.slice(idx, messages.length) : [];
    }
    return messagesToRender.map((m, i, list) => {
      const isFirst = !i || differenceInCalendarDays(m.createdAt, list[i - 1].createdAt) !== 0;
      return (
        <React.Fragment key={m.messageId + m.message + i}>
          {isFirst && <Message message={m} history={history} isDate={true} me={me} />}
          <Message
            history={history}
            isFirst={isFirst || m.senderId !== list[i - 1].senderId || list[i - 1].messageType === 'admin'}
            key={m.messageId + m.message}
            message={m}
            me={me}
            navigateToShowroom={this.navigateToShowroom}
            prodTypes={prodTypes}
            replyFrom={m.replyFrom}
            replyFromSenderName={me.name}
            senderAvatar={me.settings.avatar}
            senderAvatarColor={utils.getAvatarColor(me.name)}
            senderName={me.name}
            touchFile={touchFile}
            touchImage={touchImage}
          />
        </React.Fragment>
      );
    });
  }

  /**
   * handle click on showroom, search or product message
   */
  private navigateToShowroom = (ownerId: number, search?: string, productId?: number, productHash?: string) => {
    const { history, navigateToShowroom } = this.props;
    navigateToShowroom(ownerId, search, productId, productHash, undefined, (path: string) => history.push(path));
  };
}
