import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, ExpansionPanel, FontIcon, RowContainer, SimpleDropdown } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Expansion = styled(ExpansionPanel)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: auto;
  padding-bottom: 0;
`;

export const Buttons = styled(RowContainer)`
  justify-content: space-between;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CTA = styled(Button)`
  flex: 0.45;
  width: auto;
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Dropdown = styled(SimpleDropdown)`
  text-align: left;
`;
