import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';
import LettersAvatar from '../../atoms/LettersAvatar/LettersAvatar.component';

interface IStyledProps {
  color?: string;
  isEditing?: boolean;
  isFirst?: boolean;
  isReactionActionOpen?: boolean;
  isRead?: boolean;
  isReply?: boolean;
  isText?: boolean;
  margin?: string;
  reactionActionOpen?: 'bottom' | 'top';
  theme: ITheme;
}

export const Container = styled(RowContainer)<IStyledProps>`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-top: ${(p: IStyledProps) => (p.isFirst ? p.theme.paddingSize(3) : p.theme.paddingSize(p.isText ? 0 : 1))};
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize()} ${p.theme.paddingSize(2)}`};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  width: calc(100% - ${(p: IStyledProps) => p.theme.paddingView});
  align-self: flex-start;
  position: relative;
  & .date {
    display: none;
  }
  & .message-actions {
    display: ${(p: IStyledProps) => (p.isReactionActionOpen ? 'flex' : 'none')};
  }
  &:hover {
    & .date {
      display: block;
    }
    & .message-actions {
      display: flex;
    }
  }
  min-height: max-content;
`;

export const Row = styled(RowContainer)`
  position: relative;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ColumnTextWrap = styled(ColumnContainer)`
  width: calc(100% - 45px);
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: break-word;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextWrap = styled(ColumnContainer)`
  width: 100%;
  ${(p: IStyledProps) =>
    p.isReply
      ? css`
          background: white;
          padding: ${p.theme.paddingSize()} ${p.theme.paddingSize(2)};
          border-radius: ${p.theme.borderRadius};
          border: 1px solid ${p.theme.colors.light1};
          max-width: 600px;
        `
      : ''}
`;

const Text = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 15px;
  overflow: hidden;
`;

export const CheckIcon = styled(FontIcon)`
  width: 15px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => (p.isRead ? p.theme.colors.blue1 : p.theme.colors.grey1)};
  padding: 0 4px;
`;

export const WhoWhen = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  line-height: 15px;
`;

export const TextName = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: 6px;
`;

export const TextNameGrey2 = styled(TextName)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const DateTimeText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const DateWrapper = styled(RowContainer)`
  width: 54px;
  min-width: 54px;
  align-items: center;
`;

export const OptionIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin: ${(p: IStyledProps) => `calc(${p.theme.paddingObject} / 2) calc(${p.theme.paddingObject} / 4)`};
`;

export const Reply = styled(OptionIcon)`
  transform: scale(-1, 1);
`;

export const Actions = styled(Row)<IStyledProps>`
  height: max-content;
  position: relative;
  position: absolute;
  right: 20px;
  margin-top: -15px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: 0 calc(${(p: IStyledProps) => p.theme.paddingObject} / 4);
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  & .emoji-picker-react {
    position: absolute;
    z-index: 5;
    ${(p: IStyledProps) =>
      p.reactionActionOpen === 'top' &&
      css`
        bottom: 28px;
      `}
    right: -36px;
  }
`;

export const ForwardText = styled.span`
  position: absolute;
  top: -25px;
  right: 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: 0 calc(${(p: IStyledProps) => p.theme.paddingObject} / 4);
  &::after {
    content: '';
    position: absolute;
    right: 20%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #e8e8e8;
    clear: both;
  }
`;

export const ReplyText = styled(ForwardText)`
  left: 0;
  right: auto;
  &::after {
    left: 20%;
    right: auto;
  }
`;

export const Action = styled(ColumnContainer)`
  align-items: center;
  position: relative;
  padding: 0 4px;
  justify-content: center;
  opacity: 0.75;
  height: max-content;
  cursor: pointer;
  & .actionText {
    display: none;
  }
  &:hover {
    opacity: 1;

    & .actionText {
      display: block;
    }
  }
`;

export const Avatar = styled(LettersAvatar)``;

export const AvatarWrapper = styled.div`
  position: relative;
  align-self: flex-start;
`;

export const UpdateIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: -5px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding-left: 3px;
  padding-top: 1px;
`;
