import { __, currency, CURRENCY_CODES, WEIGHT_UNIT } from 'common-services';
import * as React from 'react';

import * as S from './OrderAggregationFooter.styled';

export interface IProps {
  groupedBy: 'order' | 'product';
  groups?: any;
  numberOfOrders?: number;
  searchCurrency: CURRENCY_CODES;
  weightUnit: WEIGHT_UNIT;
}

const OrderAggregationFooter: React.FC<IProps> = ({
  numberOfOrders,
  groups,
  groupedBy,
  searchCurrency,
  weightUnit,
}) => {
  const groupedByProduct = groupedBy === 'product';
  let totalPriceDisplay = '-';
  let totalAmount = '-';
  if (groups) {
    totalPriceDisplay = currency.getPriceAbbreviation(searchCurrency, groups.priceSum);
    totalAmount = getTotalAmountText(groups.amount);
  }
  return (
    <S.FooterAggregation>
      <S.TableOrders>
        <S.Body>
          <S.RowTotal>
            <S.EmptyCol groupedByProduct={groupedByProduct} />
            <S.Col>
              <S.FooterItem>
                <S.Text>{__('Components.OrdersList.Footer.Orders')}</S.Text>
                <S.TextPrimary>{groups ? groups.count : numberOfOrders}</S.TextPrimary>
              </S.FooterItem>
            </S.Col>
            {groupedByProduct ? (
              <React.Fragment>
                <S.SmallCol>
                  <S.FooterItem>
                    <S.Text>{__('Components.OrdersList.Footer.Load')}</S.Text>
                    <S.TextPrimary>{totalAmount}</S.TextPrimary>
                  </S.FooterItem>
                </S.SmallCol>
                <S.SmallCol>
                  <S.FooterItem>
                    <S.Text>{__('Components.OrdersList.Footer.TotalPrice')}</S.Text>
                    <S.TextPrimary id="totalPrice">{totalPriceDisplay}</S.TextPrimary>
                  </S.FooterItem>
                </S.SmallCol>
              </React.Fragment>
            ) : null}
          </S.RowTotal>
        </S.Body>
      </S.TableOrders>
    </S.FooterAggregation>
  );

  /**
   * Get total amount
   * Example: "X pallets & X boxes & X kg"
   */
  function getTotalAmountText(amount: { [key: string]: number }) {
    const result = [];

    if (amount.pallet)
      result.push(amount.pallet.toFixed(1) + ' ' + __('Constants.ProductUnitPlural.pallet', { count: amount.pallet }));

    if (amount.box)
      result.push(amount.box.toFixed(0) + ' ' + __('Constants.ProductUnitPlural.box', { count: amount.box }));

    if (amount.kg) result.push(`${amount.kg.toFixed(1)} ${weightUnit}`);

    if (amount.pieces)
      result.push(amount.pieces.toFixed(0) + ' ' + __('Constants.ProductUnitPlural.piece', { count: amount.pieces }));

    return result.length ? result.join(' & ') : '-';
  }
};

export default React.memo(OrderAggregationFooter);
