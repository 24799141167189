import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, DatePicker as DP, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  hide?: boolean;
  numHeaders?: number;
}

export const Wrapper = styled(ColumnContainer)<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-right: ${(p: IStyledProps) => (p.hide ? 0 : '1px')} solid ${(p: IStyledProps) => p.theme.colors.grey3};
  max-width: ${(p: IStyledProps) => (p.hide ? 0 : '275px')};
  width: 275px;
  position: relative;
  transition: max-width 0.5s;
  z-index: 3;
  @media screen and (max-width: calc(${(p: IStyledProps) => p.theme.sizes.ipad} + 275px)) {
    position: absolute;
    left: 0;
    z-index: 3;
    height: 100%;
    background: ${(p: IStyledProps) => p.theme.colors.white};
    ${(p: IStyledProps) =>
      p.numHeaders &&
      css`
        height: calc(${p.theme.contentHeightWithBreadcrumb} - ${p.numHeaders} * ${p.theme.headerHeight});
      `}
  }
`;

export const FacetsContainer = styled(ColumnContainer)`
  transition: max-width 0.5s;
  overflow: hidden;
`;

export const Header = styled(RowContainer)`
  width: 275px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Body = styled(ColumnContainer)`
  width: 275px;
  overflow-y: auto;
`;

export const CloseIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: 28px;
  width: 28px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  position: absolute;
  top: 11px;
  right: 11px;
  display: ${(p: IStyledProps) => (p.hide ? 'none' : 'block')};
`;

export const Clear = styled(Button)`
  position: absolute;
  top: 8px;
  left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  display: ${(p: IStyledProps) => (p.hide ? 'none' : 'block')};
`;

export const Row = styled(RowContainer)`
  align-items: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ArrowIcon = styled(FontIcon)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  transform: rotate(180deg);
`;

export const DatePicker = styled(DP)`
  margin: 0;
  width: 120px;
`;
