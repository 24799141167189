import {
  __,
  CHANNEL_DISPLAY,
  chatActions,
  contactActions,
  EventTrack,
  IChannel,
  IContact,
  IUser,
  IWorkspace,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as navActions from '../../../actions/nav';

import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { BackButton, Switch } from '../../atoms';
import { ContactCard, FormContainer, FormSection } from '../../molecules';
import * as S from './ContactInfo.styled';

export type OwnProps = {
  contact?: IContact;
  next?: () => void;
} & RouteComponentProps<{ channelId?: string }>;

export interface StateProps {
  catalogs: Array<IWorkspace>;
  channel: IChannel;
  contacts: { [cId: number]: IContact };
  me: IUser;
  workspaces: Record<number, IWorkspace>;
}

export interface DispatchProps {
  catalogsByClientGet: typeof sellerWorkspaceActions.catalogsByClientGet;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
  channelArchive: typeof chatActions.channelArchive;
  channelMute: typeof chatActions.channelMute;
  contactBlock: typeof contactActions.contactBlock;
  contactUpdate: typeof contactActions.contactUpdate;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  supportAction: () => void;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
}

type IProps = OwnProps & StateProps & DispatchProps;

export default class ContactInfo extends React.PureComponent<IProps> {
  public componentDidMount() {
    const { contact, catalogsByClientGet, me } = this.props;
    if (contact) catalogsByClientGet(me.id!, contact?.id!);
    RenderTrack.track('ContactInfo', { contactId: contact?.id ? contact.id.toString() : '', renderTime: Date.now() });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { catalogsByClientGet, contact, me } = this.props;
    if (prevProps.contact !== contact && contact) catalogsByClientGet(me.id!, contact?.id!);
  }

  public render() {
    const {
      contact,
      channel,
      channelMute,
      clientsAdd,
      contacts,
      contactUpdate,
      contactUpdateMySellerWorkspace,
      match,
      me,
      modalOpen,
      navigateChannelBySection,
      notificationShow,
      catalogs,
      workspaces,
      history,
    } = this.props;
    return (
      <>
        <S.BackContainer>
          <BackButton
            text={__('Showroom.back_to_chat', { name: contact?.name || '' })}
            onClick={() =>
              navigateChannelBySection(CHANNEL_SECTIONS.MESSAGES, match.params.channelId, true, () =>
                history.push(getPath({ path: ROUTE_PATHS.CHAT, channelId: match.params.channelId })),
              )
            }
          />
        </S.BackContainer>
        <S.Container>
          <FormContainer className="ContactInfo-FormContainer" canSave={false} save={() => null} withoutMenu={true}>
            <>
              <Contact
                channel={channel}
                channelMute={channelMute}
                contact={contact}
                contactUpdate={(c: IContact) => contactUpdate(me.id, c)}
                me={me}
              />
              <Notes />
              <Files />
              <Workspace
                catalogs={catalogs}
                clientsAdd={clientsAdd}
                contact={contact}
                contacts={contacts}
                contactUpdateMySellerWorkspace={(workspaceId: number, workspaceHash: string) =>
                  contactUpdateMySellerWorkspace(me.id, contact.id!, workspaceId, workspaceHash)
                }
                me={me}
                modalOpen={modalOpen}
                navigateToClient={this.navigateToClient}
                notificationShow={notificationShow}
                workspaces={workspaces}
              />
              <Activity channel={channel} archiveChannel={this.archiveChannel} />
              <Security contact={contact} blockContact={this.blockContact} reportContact={this.reportContact} me={me} />
            </>
          </FormContainer>
        </S.Container>
      </>
    );
  }

  /**
   * On archive chat
   */
  private archiveChannel = () => {
    const { channel, channelArchive, me, notificationShow } = this.props;
    const toArchive = channel.display !== CHANNEL_DISPLAY.ARCHIVED;
    channelArchive(me.id, channel.id, toArchive, (error?: Error) => {
      if (error) {
        notificationShow(
          {
            title: __('Notification.error.title'),
            subtitle: __('Notification.error.description'),
            closable: true,
            style: 'error',
          },
          4000,
        );
      } else {
        notificationShow(
          {
            title: toArchive ? __('Notification.archive.title') : __('Notification.unarchive.title'),
            subtitle: toArchive
              ? __('Notification.archive.description_contact')
              : __('Notification.unarchive.description_contact'),
            closable: true,
            style: 'info',
          },
          4000,
        );
      }
    });
  };

  /**
   * Show confirm modal with the info about block a caontact.
   */
  private blockContact = () => {
    const { contact, contactBlock, me, modalClose, modalOpen } = this.props;
    EventTrack.blockUser({
      myId: me.id!,
      contactId: contact.id!,
      action: contact.imBlocking ? 'preunblock' : 'preblock',
    });
    modalOpen(
      contact.imBlocking
        ? __('Components.ContactDetails.unblock_contact.modal.title', { name: contact.name })
        : __('Components.ContactDetails.block_contact.modal.title', { name: contact.name }),
      () => {
        EventTrack.blockUser({
          myId: me.id!,
          contactId: contact.id!,
          action: contact.imBlocking ? 'unblock' : 'block',
        });
        contactBlock(me.id!, contact.id!, !contact.imBlocking, (err: Error) => {
          if (!err) modalClose();
          else {
            modalOpen(__('modal_error.text'), modalClose, {
              buttonText: __('modal_error.accept'),
              icon: 'Delete',
            });
          }
        });
      },
      {
        buttonText: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.button')
          : __('Components.ContactDetails.block_contact.modal.button'),
        icon: contact.imBlocking ? IMAGES.addUser : IMAGES.blockUser,
        subtitle: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.subtitle')
          : __('Components.ContactDetails.block_contact.modal.subtitle'),
        text2: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.text')
          : __('Components.ContactDetails.block_contact.modal.text'),

        showCancelButton: true,
        actionType: 'dangerous',
      },
      'nice',
    );
  };

  private reportContact = () => {
    const { contact, modalOpen, me, supportAction } = this.props;
    modalOpen(
      __('Components.ContactDetails.report_contact.modal.title', contact),
      () =>
        EventTrack.reportUser({
          myId: me.id!,
          contactId: contact.id!,
        }),
      {
        icon: IMAGES.reportUser,
        text2: __('Components.ContactDetails.report_contact.modal.text'),
        buttonText: __('Components.ContactDetails.report_contact.modal.button'),
        showCancelButton: true,
        buttonCancelText: __('Components.ContactDetails.report_contact.modal.button2'),
        cancelAction: () => supportAction(),
        actionType: 'dangerous',
      },
      'nice',
    );
  };

  private navigateToClient = (workspaceId: number) => {
    const { history, contact, location } = this.props;
    history.push(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT,
        workspaceId: workspaceId + '',
        clientId: contact?.id + '',
      }),
      { from: location.pathname },
    );
  };
}

const Contact = React.memo(
  ({
    channel,
    channelMute,
    contact,
    contactUpdate,
    me,
  }: {
    channel?: IChannel;
    channelMute: typeof chatActions.channelMute;
    contact: IContact;
    contactUpdate: (contact: IContact) => void;
    me: IUser;
  }) => {
    return (
      <FormSection id="contact" title={__('ContactInfo.Menu.contact.title')}>
        <ContactCard
          contact={contact}
          action={
            <S.RowSwitch>
              <S.SwitchLabel>{__('Channel.mute_notifications')}</S.SwitchLabel>
              <Switch
                isChecked={channel?.muted || false}
                onChange={(n: string, checked: boolean) => channelMute(me.id!, channel.id, checked)}
              />
            </S.RowSwitch>
          }
          contactUpdate={contactUpdate}
        />
      </FormSection>
    );
  },
);

const Notes = React.memo(() => {
  return null;
});

const Files = React.memo(() => {
  return null;
});

const Activity = React.memo(({ channel, archiveChannel }: { channel: IChannel; archiveChannel: () => void }) => {
  if (!channel) return null;
  return (
    <FormSection
      id="activity"
      title={__('ContactInfo.Menu.activity.title')}
      subtitle={__('ContactInfo.Menu.activity.description')}
    >
      <S.ButtonsContainer>
        <S.ArchiveButton type="secondary" onClick={archiveChannel} iconName="Archive">
          {channel.display !== CHANNEL_DISPLAY.ARCHIVED ? __('ContactInfo.archive') : __('ContactInfo.unarchive')}
        </S.ArchiveButton>
      </S.ButtonsContainer>
    </FormSection>
  );
});

const Workspace = React.memo(
  ({
    catalogs,
    clientsAdd,
    contact,
    contacts,
    contactUpdateMySellerWorkspace,
    me,
    modalOpen,
    navigateToClient,
    notificationShow,
    workspaces,
  }: {
    catalogs: Array<IWorkspace>;
    clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
    contact?: IContact;
    contacts: { [cId: number]: IContact };
    contactUpdateMySellerWorkspace: (workspaceId: number, workspaceHash: string) => void;
    me: IUser;
    modalOpen: typeof modalActions.modalOpen;
    navigateToClient: (workspaceId: number) => void;
    notificationShow: typeof notificationsActions.notificationShow;
    workspaces: Record<number, IWorkspace>;
  }) => {
    const canSell = contact?.isBuyer && me.settings.isSeller;
    if (!workspaces || !contact || !canSell) return null;

    const name = sellerWorkspaceService.getCatalogName(workspaces[contact.mySellerWorkspaceId], contacts, me);
    const defaultName = sellerWorkspaceService.getCatalogName(workspaces[me.sellerWorkspaceId], contacts, me);
    return (
      <FormSection
        id="workspace"
        title={__('ContactInfo.Menu.workspace.title')}
        subtitle={__('ContactInfo.Menu.workspace.description')}
      >
        <S.Workspace
          catalogIdSelected={contact.mySellerWorkspaceId}
          catalogs={workspaces}
          contacts={contacts}
          filteredCatalogs={Object.values(workspaces).filter(w => w.status === 'active')}
          me={me}
          setCatalogIdSelected={(id: number) => {
            modalOpen(
              __('ContactInfo.Menu.workspace.modal.title', {
                client: contact.name,
                workspaceOld: name,
                workspaceNew: workspaces[id].name,
              }),
              () => contactUpdateMySellerWorkspace(id, workspaces[id].hashId),
              {
                icon: IMAGES.informativePineapple,
                text2: __('ContactInfo.Menu.workspace.modal.text', {
                  client: contact.name,
                  workspaceOld: name,
                  workspaceNew: workspaces[id].name,
                }),
                buttonText: __('ContactInfo.Menu.workspace.modal.cta'),
                showCancelButton: true,
                buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
              },
              'nice',
            );
          }}
        />
        {catalogs.find(c => c.id === contact.mySellerWorkspaceId) ? (
          <S.TeamLink onClick={() => navigateToClient(contact.mySellerWorkspaceId)}>
            {__('ContactInfo.Menu.workspace.footer', {
              workspace_name: name,
            })}
          </S.TeamLink>
        ) : (
          <S.TextWrapper>
            <S.BlackText>
              {__('ContactInfo.Menu.workspace.no_client', {
                workspace: name,
              })}
            </S.BlackText>
            <S.TeamLink
              onClick={() => {
                clientsAdd(
                  me.id,
                  contact.mySellerWorkspaceId,
                  [sellerWorkspaceService.contactToClient(contact, contact.mySellerWorkspaceId)],
                  err => {
                    notificationShow(
                      {
                        style: err ? 'error' : 'success',
                        title: err ? __('ClientsList.error', { count: 1 }) : __('ClientsList.success', { count: 1 }),
                        subtitle: err
                          ? __('ClientsList.error_description', { count: 1 })
                          : __('ClientsList.success_description', { count: 1 }),
                        closable: true,
                      },
                      4000,
                    );
                  },
                );
              }}
            >
              {__('ContactInfo.Menu.workspace.no_client_add')}
            </S.TeamLink>
          </S.TextWrapper>
        )}

        {sellerWorkspaceService.isActive(workspaces[contact.mySellerWorkspaceId], me.id) ? null : (
          <S.TeamUnactive>
            <S.WarningIcon name={'Warning'} disableHover={true} />
            <S.Text>{__('ContactInfo.Menu.workspace.inactive', { default_name: defaultName, name })}</S.Text>
          </S.TeamUnactive>
        )}
      </FormSection>
    );
  },
);

const Security = React.memo(
  ({
    contact,
    blockContact,
    reportContact,
  }: {
    contact: IContact;
    blockContact: () => void;
    reportContact: () => void;
    me: IUser;
  }) => {
    return (
      <FormSection
        id="security"
        title={__('ContactInfo.Menu.security.title')}
        subtitle={__('ContactInfo.Menu.security.subtitle')}
      >
        <S.ButtonsContainer>
          <S.ActionButton type="secondary" onClick={blockContact} iconName="Forbidden">
            {contact?.imBlocking
              ? __('Components.ContactDetails.unblock_contact.button')
              : __('Components.ContactDetails.block_contact.button')}
          </S.ActionButton>
          <S.ActionButton type="delete" onClick={reportContact} iconName="Report">
            {__('Components.ContactDetails.report_contact.button')}
          </S.ActionButton>
        </S.ButtonsContainer>
      </FormSection>
    );
  },
);
