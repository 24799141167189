import {
  Api,
  contactActions,
  IClient,
  IPriceGroup,
  modalActions,
  notificationsActions,
  orderActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelByPath } from '../actions/nav';
import WorkspaceClients, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceClients';
import { IReduxState } from '../reducers';

const emptyPriceGroups: { [priceGroupID: string]: IPriceGroup } = {};
const emptyArray: Array<IClient> = [];

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId: string }>): IStateProps {
  const {
    catalog: { catalogs, clients, priceGroups, columnConfig },
    contact: { hasOutContacts },
    chat: { channels },
    contact: { inConsentio },
    order: { orders },
    user: { user },
  } = state;

  const catalogId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;
  const catalog = catalogs[catalogId || user.sellerWorkspaceId];
  return {
    amEditor: !(catalog?.members.find(m => m.userId === user.id)?.role === 'viewer'),
    catalog,
    catalogs,
    channels,
    clientsOld: clients[catalogId] || emptyArray,
    columnConfig: columnConfig[catalogId],
    contacts: inConsentio,
    hasOutContacts,
    me: user,
    orders,
    priceGroups: priceGroups[catalogId] || emptyPriceGroups,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      contactsInvite: contactActions.contactsInvite,
      contactUpdateMySellerWorkspace: contactActions.contactUpdateMySellerWorkspace,
      createNewContact: contactActions.createContactWithName,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelByPath,
      contactsAddressbookGet: contactActions.contactsAddressbookGet,
      notificationShow: notificationsActions.notificationShow,
      priceGroupsGet: sellerWorkspaceActions.priceGroupsGet,
      tableVisibilityConfigGet: sellerWorkspaceActions.tableVisibilityConfigGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceClients);
