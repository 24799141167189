import { Api, imageActions, modalActions, notificationsActions, sellerWorkspaceActions, tagActions } from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WorkspaceSections, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceSections';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    catalog: { catalogs },
    user: { user },
    tag: { tags },
  } = state!;

  const catalogId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;

  return {
    catalog: catalogs[catalogId],
    me: user!,
    tags,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      catalogUpdate: sellerWorkspaceActions.catalogUpdate,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      touchImage: modalActions.touchImage,
      tagsGet: tagActions.tagsGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSections);
