import styled, { ITheme } from '../../../styled-components';
import {
  Button,
  CheckBox as CB,
  ColumnContainer,
  FontIcon,
  Input,
  RowContainer,
  TextLabel,
  Tooltip as TooltipAtom,
} from '../../atoms';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  max-height: 100%;
`;

export const Body = styled(ColumnContainer)`
  position: relative;
  float: left;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 520px;
`;

export const Column = styled(ColumnContainer)`
  flex: 1;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Row = styled(RowContainer)`
  justify-content: space-between;
  width: 100%;
  position: relative;
  flex: 1;
  max-height: 100%;
`;

export const TitleRow = styled(Row)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ScrollRow = styled(RowContainer)`
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  position: relative;
  flex: 1;
`;

export const InfoRow = styled(Row)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  overflow: visible;
`;

export const RightCard = styled(ColumnContainer)`
  max-width: 360px;
  min-width: 360px;
  border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  position: relative;
  max-height: 100%;
  overflow: hidden;
  @media screen and (max-width: calc(${(p: IStyledProps) => p.theme.sizes.desktop_m} + 275px)) {
    position: absolute;
    right: 0;
    z-index: 1;
    height: 100%;
    background: white;
    border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
`;

export const Title = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const EditLink = styled(FontIcon)`
  color: inherit;
  display: inline;
  padding: 1px 4px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  background: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TextRef = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const AlignLeft = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
  min-height: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const SectionTitle = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 0;
`;

export const SectionTitleCustomItems = styled(SectionTitle)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const SectionTitleComments = styled(SectionTitle)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const CardItem = styled.div`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const BackContainer = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  display: flex;
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CommentsIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 22px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 22px;
  width: 22px;
  padding-bottom: 2px;
`;

export const CommentsHeader = styled(RowContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
`;

export const TextHeader = styled(Text)`
  width: auto;
`;

export const TextLink = styled(TextHeader)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextLinkInline = styled(TextLink)`
  padding-left: 0;
`;

export const General = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  padding-left: 0;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-height: min-content;
`;
export const CustomItemsContainer = styled(ColumnContainer)`
  padding-left: 0;
`;

export const Comments = styled(General)`
  padding: 0;
`;

export const VerticalLine = styled.span`
  width: 1px;
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const DownloadWrapper = styled(RowContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const DownloadIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const DropdownText = styled(Text)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const DropdownContent = styled(RowContainer)`
  max-width: 234px;
  min-width: 204px;
  height: 36px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ActionsContainer = styled(RowContainer)`
  justify-content: flex-end;
  flex: 1;
  cursor: pointer;
  align-items: center;
`;

export const ClientsView = styled(Button)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  position: absolute;
  top: 45px;
  right: 50px;
  z-index: 1;
`;

export const DownIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const Tooltip = styled(TooltipAtom)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
`;

export const ExternalInput = styled(Input)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 174px;
  max-width: 174px;
`;

export const AddButton = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  display: inline-flex;
  padding: 5px 7px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  background: ${(p: IStyledProps) => (p.enabled ? p.theme.colors.green1 : p.theme.colors.green2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border: 1px solid ${(p: IStyledProps) => (p.enabled ? p.theme.colors.green1 : p.theme.colors.green2)};
  cursor: ${(p: IStyledProps) => (p.enabled ? 'pointer' : 'not-allowed')};
`;

export const CloseButton = styled(AddButton)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.black};
  background: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const CenterContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} 0;
`;
export const MembersNumber = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IssueText = styled(TextLabel)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  display: flex;
`;

export const IssueCTA = styled(IssueText)`
  cursor: pointer;
`;

export const IssueIcon = styled(FontIcon)`
  font-size: 9px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ModalButton = styled(Button)`
  width: 45%;
`;

export const InputTitle = styled(TextLabel)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ModalRow = styled(RowContainer)`
  justify-content: space-between;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
`;
export const CtaButton = styled(ModalButton)`
  background-color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.theme.colors.green1)};
`;

export const SearchContainer = styled(RowContainer)`
  display: flex;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
