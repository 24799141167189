import * as React from 'react';

import * as S from './ImageCover.styled';

export interface IProps {
  borderRadius?: string;
  children?: any;
  className?: string;
  imageUrl: string;
  relation?: number;
  relationFixed?: boolean;
  touchImage?: () => void;
}

interface IState {
  height: number;
  image?: string;
  width: number;
}

/**
 * Edit existing product / create new product
 */
export default class ImageCover extends React.PureComponent<IProps, IState> {
  private img: HTMLImageElement;
  constructor(props: IProps) {
    super(props);
    this.state = {
      image: props.imageUrl,
      width: 0,
      height: 0,
    };
    this.getMeta(props.imageUrl);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.getMeta(this.props.imageUrl);
    }
  }

  public componentWillUnmount() {
    if (this.img) this.img.removeEventListener('load', this.getImageData);
  }

  public render() {
    const { width, height, image } = this.state;
    const { borderRadius, className, touchImage, children, relation, relationFixed } = this.props;
    const imageRelation = width / height;
    const isHorizontal = !relation || relation > imageRelation;
    return (
      <S.Container
        borderRadius={borderRadius}
        className={className}
        isClickable={!!touchImage}
        onClick={() => touchImage?.()}
        relation={isHorizontal && !relationFixed ? imageRelation : relation}
      >
        <S.ImageBG src={image} />
        <S.Image
          src={image}
          style={{
            width: isHorizontal ? '100%' : 'auto',
            height: isHorizontal ? 'auto' : '100%',
          }}
        />
        {children}
      </S.Container>
    );
  }

  /**
   * get image sizes
   */
  private getImageData = (e: Event) => {
    const { imageUrl } = this.props;
    this.setState({
      width: (e.target as HTMLImageElement).naturalWidth,
      height: (e.target as HTMLImageElement).naturalHeight,
      image: imageUrl,
    });
  };
  /**
   * Extract by js the width and heiht of the image in the url.
   */
  private getMeta = (url: string) => {
    if (typeof window === 'undefined') return;
    this.img = new Image();
    this.img.addEventListener('load', this.getImageData);
    this.img.src = url;
  };
}
