import { __ } from 'common-services';
import { FormSection } from '../../../../molecules';
import * as S from './ReferenceListDetail.style';
import { columnsAssociatedReferenceTable, getColumnsAssociatedReferenceTable } from './ReferenceListDetail.constants';
import * as React from 'react';
import { IAssociationsReference } from 'common-services/dist/associate-reference/interface';

interface AssociatedReferenceProps {
  associatedReferenceRef: React.MutableRefObject<HTMLDivElement>;
  data: Array<IAssociationsReference>;
  setShowClientModal: React.Dispatch<React.SetStateAction<boolean>>;
onShowDeleteModal: (data: IAssociationsReference) => Promise<void>
}

const AssociatedReferenceFormComponent: React.FC<AssociatedReferenceProps> = ({
  associatedReferenceRef,
  data,
  setShowClientModal,
  onShowDeleteModal,
}) => {
  return (
    <>
      <FormSection
        id="associated-reference"
        title={__('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.title')}
        sectionRef={associatedReferenceRef}
        withoutPaddingBottom={true}
      >
        <S.AssociatedReferenceTable
          selectable={false}
          columns={getColumnsAssociatedReferenceTable(onShowDeleteModal)}
          emptyText=""
          onClickRow={() => null}
          values={data}
          productColumns={columnsAssociatedReferenceTable}
        />

        <S.AddAssociateReferenceButton
          iconName={'Add-more'}
          type="link"
          onClick={() => { setShowClientModal(true)}}
          withoutPadding
        >
          {__('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.addAssociatedReference')}
        </S.AddAssociateReferenceButton>
      </FormSection>
    </>
  );
};

export default AssociatedReferenceFormComponent;


