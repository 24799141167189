import styled, { ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  ProgressBar as PB,
  FontIcon,
  RowContainer,
  TextLabel,
  Input,
  CheckBox,
  LettersAvatar,
} from '../../atoms';
import { Col } from '../../atoms/TableHeader/TableHeader.styled';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  numberOfImages?: number;
}
export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const Text = styled(TextLabel)`
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const TextTitle = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const TextCentered = styled(TextBlack)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;
export const TextBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const TextNumber = styled(TextBold)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const TextPrice = styled(TextBold)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const ClientNameColumn = styled(ColumnContainer)`
  min-width: 85px;
`;

export const SendButton = styled(Button)`
  margin: 0 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const ContactImagesRow = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - 2 * ${p.theme.headerHeight})`};
`;
export const TableContainer = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
`;
export const BackContainer = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
`;

export const Back = styled(RowContainer)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;
export const CartContainer = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 2;
`;
export const ShowroomContainer = styled(ColumnContainer)`
  overflow: hidden;
  flex: 1;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const ShowroomContainerRow = styled(RowContainer)`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const OrderCard = styled(ColumnContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m};
  min-width: min(
    ${(p: IStyledProps) => p.theme.sizes.desktop_m},
    100% - ${(p: IStyledProps) => p.theme.paddingSize(6)}
  );
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  position: relative;
`;

export const Resume = styled(RowContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m};
  cursor: pointer;
`;

export const ResumeLeft = styled(RowContainer)`
  flex: 1;
  justify-items: flex-start;
`;
export const ResumeRight = styled(ColumnContainer)`
  flex: 1;
  align-items: flex-end;
  justify-items: flex-start;
`;

const ProgressBar = styled(PB)`
  max-width: 336px;
  min-width: 336px;
`;

export const DownIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0;
  left: -${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: flex-start;
`;

export const Row = styled(RowContainer)`
  align-items: center;
`;

const DataRow = styled(RowContainer)`
  align-items: center;
  height: 60px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey4};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const DataRowHead = styled(DataRow)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
  }
`;

export const HeadRow = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

const Td = styled(ColumnContainer)`
  justify-content: center;
  width: 157px;
`;

export const ProductTd = styled(Td)`
  flex: 1;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

const Th = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: left;
  width: 157px;
`;
export const ProductTh = styled(Th)`
  flex: 1;
`;

export const TitleRow = styled(RowContainer)`
  align-items: flex-start;
`;

export const TextBlackSmall = styled(TextBlack)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ContactImage = styled(LettersAvatar)`
  width: 30px;
  height: 30px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const ContactNameColumn = styled(ColumnContainer)`
  justify-content: center;
  min-width: 85px;
`;

export const TextSecondary = styled(TextBlack)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const ButtonsRow = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  justify-content: flex-end;
`;

export const ActionButton = styled(Button)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const DeleteActionButton = styled(ActionButton)`
  border: none;
  background-color: transparent;
`;
