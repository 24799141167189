import { __, ICountry, ILightContact, INVITE_ORIGIN, IUser } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import { ContactsList, EmptyListResource } from '../../molecules';
import ActionsModal from '../ActionsModal';
import { ContactsSelected, Search } from './Fragments';
import * as S from './SelectContacts.styled';

export interface IScreenProps {
  countries: { [key: string]: ICountry };
  me: IUser;
}

interface IStateProps {
  canIncludeMyself?: boolean;
  catalogId?: number;
  changeStep?: (step: number) => void;
  channelId?: string;
  contacts: Array<ILightContact>;
  defaultSelected?: Array<number>;
  extraInfo?: (contact: any) => string;
  ExtraInputs?: any;
  onClose: () => void;
  onDelete?: (contact: ILightContact) => void;
  onSave: (contactsSelected: Array<number>) => void;
  onSelect?: (contact: ILightContact) => void;
  origin: INVITE_ORIGIN;
  showCta?: boolean;
  showInvite?: boolean;
  showUnregistered?: boolean;
  skippable?: boolean;
  step?: number;
  stepsNumber?: number;
  subtitle?: string;
  title: string;
  trackingFrom: string;
  zeroCase?: 'agenda' | 'clients' | 'general';
}

type IProps = IScreenProps & IStateProps;

const SelectContacts: React.FC<IProps> = ({
  canIncludeMyself = false,
  catalogId,
  changeStep,
  channelId,
  contacts,
  countries,
  defaultSelected,
  extraInfo,
  ExtraInputs,
  me,
  onClose,
  onDelete,
  onSave,
  onSelect,
  origin,
  showCta = true,
  showInvite = false,
  showUnregistered,
  skippable,
  step,
  stepsNumber,
  subtitle,
  title,
  trackingFrom,
  zeroCase = 'general',
}) => {
  const [mode, setMode] = React.useState<'add-clients' | 'invite'>('add-clients');
  const [search, setSearch] = React.useState<string>('');
  const [selected, setSelected] = React.useState<Array<number>>(defaultSelected || []);

  /**
   * Render add clients mode with Consentio contacts to choose
   */
  const renderAddClients = () => {
    return (
      <>
        {contacts.length ? (
          <React.Fragment>
            <S.ExtraContainer>{ExtraInputs ? <ExtraInputs /> : null}</S.ExtraContainer>
            <Search setSearch={s => setSearch(s)} id={'search-members'} />
            {selected.length ? (
              <ContactsSelected
                canIncludeMyself={canIncludeMyself}
                contacts={contacts}
                myId={me.id}
                onUnselectContact={handleSelectContact}
                selected={selected}
              />
            ) : null}
            <S.Scroll>
              <ContactsList
                contacts={contacts}
                onSelectContact={handleSelectContact}
                contactSelected={(cId: number) => selected.includes(cId)}
                extraInfo={extraInfo}
                search={search}
                action="switch"
                showUnregistered={showUnregistered}
              />
            </S.Scroll>

            <S.CTAContainer showInvite={showInvite}>
              {showInvite ? (
                <S.CTA type="secondary" onClick={() => setMode('invite')}>
                  {__('Components.ChatList.invite.addressbook.cta_other')}
                </S.CTA>
              ) : null}
              {showCta ? (
                <S.CTA
                  disabled={!selected.length}
                  type="principal"
                  id="select-contacts-cta-create"
                  onClick={() => onSave(selected)}
                >
                  {__('Catalog.add_members.add', { count: selected.length })}
                </S.CTA>
              ) : null}
            </S.CTAContainer>
          </React.Fragment>
        ) : (
          renderZeroCase()
        )}
      </>
    );
  };

  /**
   * Render zero case
   */
  const renderZeroCase = () => {
    let textTitle = __('ActionsModal.general.zero_case.title');
    let description = __('ActionsModal.general.zero_case.description');
    let cta = __('ActionsModal.general.zero_case.cta');

    switch (zeroCase) {
      case 'clients':
        textTitle = __('ActionsModal.clients.zero_case.title');
        description = __('ActionsModal.clients.zero_case.description');
        cta = __('ActionsModal.clients.zero_case.cta');
        break;
      case 'agenda':
        textTitle = __('ActionsModal.agenda.zero_case.title');
        description = __('ActionsModal.agenda.zero_case.description');
        cta = __('ActionsModal.agenda.zero_case.cta');
        break;
    }
    return (
      <S.CenterContainer>
        <EmptyListResource
          imageUrl={IMAGES.contactsAdded}
          showButton={true}
          text={textTitle}
          text2={description}
          buttonText={cta}
          buttonAction={() => setMode('invite')}
          imageSize="170px"
        />
        <S.DividerContainer>
          <S.DividerBar />
          <S.TextGrey>{__('Components.Onboarding.LoginPhoneEmail.or')}</S.TextGrey>
          <S.DividerBar />
        </S.DividerContainer>
        <S.ShareInvite
          catalogId={catalogId}
          channelId={channelId}
          className="select-contact-share-invite"
          me={me}
          origin={origin}
        />
      </S.CenterContainer>
    );
  };

  /**
   * Render invite form (sms / email)
   */
  const renderInviteForm = () => {
    return (
      <S.InviteForm
        catalogId={catalogId}
        channelId={channelId}
        className="add-clients-invite-form"
        countries={countries}
        from="invite-contacts"
        onClose={onClose}
        me={me}
        origin={origin}
        trackingFrom={trackingFrom}
      />
    );
  };

  /**
   * Handler select/unselect a contact.
   */
  const handleSelectContact = React.useCallback(
    (cId: number) => {
      let newSelected = [...selected];
      const idx = selected.indexOf(cId);
      if (idx > -1) {
        newSelected.splice(idx, 1);
        if (onDelete) onDelete(contacts.find(c => c.id === cId));
      } else {
        newSelected = [cId, ...newSelected];
        if (onSelect) onSelect(contacts.find(c => c.id === cId));
      }
      setSelected(newSelected);
    },
    [contacts, onDelete, onSelect, selected],
  );

  return (
    <ActionsModal
      changeStep={changeStep}
      className="add-clients-select-contacts"
      onClose={onClose}
      skippable={skippable}
      step={step}
      stepsNumber={stepsNumber}
      subtitle={mode === 'invite' ? __('Components.InviteOnboarding.2.subtitle') : subtitle}
      title={mode === 'invite' ? __('Components.ChatList.invite.other.title') : title}
      width="650px"
    >
      {mode === 'invite' ? renderInviteForm() : renderAddClients()}
    </ActionsModal>
  );
};

export default React.memo(SelectContacts);
