import styled from 'styled-components';
import { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  padding: calc(${(p: IStyledProps) => p.theme.paddingObject} / 3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  background: ${(p: IStyledProps) => p.theme.colors.secondary.replace('1)', '0.9)')};
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  & > span {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  & > span:nth-child(1) {
    padding-left: 3px;
  }

  & > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
