import styled, { css, ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../../../atoms';
import { FilterButton as FB } from '../../../../molecules';

interface IStyledProps {
  color?: string;
  from?: 'showroom' | 'pricelist' | 'product'; // 'product' for ProductCard component
  margin?: string;
  theme: ITheme;
}

export const BodyWrapper = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const Body = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
`;

export const RowBody = styled(RowContainer)`
  width: 100%;
  flex: 1;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 5 : 0)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding: 0;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const ButtonGroup = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-width: max-content;
`;

const ButtonGroupItem = styled(Button)`
  min-width: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FilterButton = styled(FB)`
  border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
  height: 40px;
`;

export const SortButton = styled(ButtonGroupItem)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const HeaderItemContainer = styled(RowContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
  align-items: center;
`;

export const ListGroup = styled(ColumnContainer)<IStyledProps>`
  ${(p: IStyledProps) =>
    p.from === 'pricelist' &&
    css`
      padding-left: ${p.theme.paddingSize(4)};
    `}
`;
export const BoldText = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
