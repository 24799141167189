import styled, { css, ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  src?: string;
  enabled?: boolean;
  selected?: boolean;
  isPublic?: boolean;
  showInIpad?: boolean;
  amount?: number;
  status?: string;
}

export const FakeContainer = styled.div`
  position: relative;
  width: 290px;
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
    position: relative;
    bottom: 0;
    left: 0;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    max-width: 100%;
    min-width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 80px;
  }
`;

export const Row = styled(RowContainer)`
  align-items: center;
  flex-wrap: wrap;
`;

const RightContainer = styled.form`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  align-self: flex-start;
  max-width: 270px;
  min-width: 270px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  flex-direction: column;
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};
  ${(p: IStyledProps) =>
    p.isPublic &&
    css`
      margin: 0 ${p.theme.paddingSize(2)};
    `}

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    top: 0;
    left: 0;
    flex-direction: column;
    position: relative;
    justify-content: space-around;
    margin: 0;
    border-radius: 0;
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    height: min-content;
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
    overflow-y: visible;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    align-items: center;
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    flex-direction: row;
    position: absolute;
    justify-content: space-around;
    margin: 0;
    border-radius: 0;
    border: none;
    border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    height: 100%;
  }
`;

export const RightContainerSmall = styled(RightContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  display: none;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
  }
`;

export const RightContainerFull = styled(RightContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: none;
  }
`;

export const PriceRow = styled(Row)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(3)} 0 0 0`};
  min-width: 220px;
  width: 100%;
  align-items: normal;
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    flex-direction: column;
  }
`;

export const PriceEditRow = styled(Row)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(3)} 0 0 0`};
  cursor: ${(p: IStyledProps) => (p.enabled ? 'pointer' : 'default')};
`;
export const PricePORRow = styled(Row)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(4)} 0 0 0`};
  position: relative;
  justify-content: left;
`;
export const POR = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const AmountRow = styled(RowContainer)`
  padding: 0;
  min-width: 220px;
  width: 100%;
  align-items: normal;
  flex-direction: column;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SmallPriceRow = styled(RowContainer)`
  flex: 1;
`;

export const Price = styled.h1`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  display: flex;
  color: ${(p: IStyledProps) => (p.enabled ? p.theme.colors.black : 'inherit')};
`;

export const PriceUnit = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  line-height: 1;
  padding: 0 5px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const Title = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: start;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  flex: 1;
`;
export const GreyText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: 0;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  flex: 1;
`;
export const SubText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const BoldTitle = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const UnitText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => (p.enabled ? p.theme.colors.black : 'inherit')};
`;

export const ResumePrice = styled.span`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-left: 0.33em;
`;

export const Add = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(1)} 0 ${p.theme.paddingSize(3)} 0`};
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
  }
`;

export const SubtotalRow = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  &:before {
    float: left;
    width: 0;
    white-space: nowrap;
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
  }
`;

export const IconEditWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: 'inherit';
  padding: 0 3px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  background: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-self: center;
`;

export const ItemLoadContainer = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const AddButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  width: 100%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  }
`;
export const RemoveButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  width: 100%;
`;

export const IncDecButtons = styled(Row)`
  justify-content: flex-end;
`;

export const IncDecButton = styled.button`
  background: ${(p: IStyledProps) => (p.amount === 1 ? p.theme.colors.white : p.theme.colors.green1)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => (p.amount === 1 ? p.theme.colors.green1 : p.theme.colors.white)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:hover {
    opacity: 0.7;
  }
`;

export const IncDecText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
`;

export const Amount = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  display: flex;
  color: inherit;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TrashIcon = styled(FontIcon)`
  font-size: 18px;
  width: 18px;
  height: 18px;
`;

export const AddressRow = styled(RowContainer)`
  align-items: baseline;
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(1)} 0 0 0`};
`;
export const AddressText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
export const AddressColumn = styled(ColumnContainer)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-items: center;
  justify-content: space-between;
`;

export const BlackText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const LinkButton = styled(Button)`
  display: inline;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: min-content;
`;
export const TextGray = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
