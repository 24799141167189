import { __, ILightContact } from 'common-services';
import * as React from 'react';

import ContactSelected from '../ContactSelected';
import * as S from './ContactsSelected.styled';

interface IProps {
  canIncludeMyself?: boolean;
  contacts: Array<ILightContact>;
  myId: number;
  selected: Array<number>;
  onUnselectContact: (cId: number) => void;
  zeroContactsLabel?: string;
}

const ContactsSelected: React.FC<IProps> = ({
  canIncludeMyself = false,
  contacts,
  myId,
  onUnselectContact,
  selected,
  zeroContactsLabel = __('Components.ContactsSelected.default'),
}) => {
  return (
    <S.Container>
      {selected.length ? (
        selected.map(cId =>
          cId !== myId || canIncludeMyself ? (
            <ContactSelected
              key={cId}
              contact={contacts.find(c => c.id === cId)}
              onUnselectContact={onUnselectContact}
            />
          ) : null,
        )
      ) : (
        <S.Text>{zeroContactsLabel}</S.Text>
      )}
    </S.Container>
  );
};
export default React.memo(ContactsSelected);
