import * as Sentry from '@sentry/react';
import axios from 'axios';

import Config from '../../bindings/config';

export function bcError(category: string, message: string, data: any) {
  return breadcrumb(category, message, data, 'error');
}

export function bcInfo(category: string, message: string, data: any) {
  return breadcrumb(category, message, data, 'info');
}

export function bcDebug(category: string, message: string, data: any) {
  return breadcrumb(category, message, data, 'debug');
}

function breadcrumb(category: string, message: string, data: any, level: string) {
  if (Config.APP_ENV === 'dev') {
    const time = new Date().toLocaleTimeString();
    if (level === 'error') {
      console.error(`${time} [${level}] ${category}: ${message}`, data);
    } else {
      console.log(`${time} [${level}] ${category}: ${message}`, data);
    }
  }
}

/**
 * Transform a path by replacing numeric segments and UUIDs with named parameters.
 * @param path - The original path
 * @return The transformed path
 */
function transformPath(path: string): string {
  const segments = path.split('/');
  return segments
    .map((segment, index) => {
      // Check for numeric IDs
      if (/^\d+$/.test(segment) && index > 0) {
        const paramName = segments[index - 1] + 'Id';
        return `{${paramName}}`;
      }
      // Check for UUIDs
      // UUID v4 format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
      // where x is any hexadecimal digit and y is one of 8, 9, A, or B.
      if (/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(segment) && index > 0) {
        const paramName = segments[index - 1] + 'Uuid';
        return `{${paramName}}`;
      }
      return segment;
    })
    .join('/');
}

/**
 * Log error in console (only in dev mode) and send the error to Sentry with enhanced details.
 * @param error - The error object to be logged
 * @param logger - A string identifier for the logger (e.g., component or function name)
 * @param additionalContext - Optional object containing any additional context
 */
export function logError(error: Error | unknown, logger: string, additionalContext?: Record<string, unknown>): void {
  // Prepare the error object for Sentry
  let sentryError: Error;
  let errorContext: Record<string, unknown> = { ...additionalContext };

  if (axios.isAxiosError(error)) {
    // Handle Axios errors
    sentryError = error;

    // Handle Axios errors
    const method = error.config?.method?.toUpperCase() || 'UNKNOWN';
    const originalPath = error.config?.url || 'UNKNOWN';
    const transformedPath = transformPath(originalPath);

    sentryError.message = `API request error on ${method} to ${transformedPath}`;

    errorContext = {
      ...errorContext,
      axiosError: {
        request: {
          baseUrl: error.config?.baseURL,
          method: error.config?.method,
          url: originalPath,
          path: transformedPath,
          headers: error.config?.headers,
          data: error.config?.data,
          params: error.config?.params,
        },
        response: {
          status: error.response?.status,
          data: error.response?.data,
        },
      },
    };
  } else if (error instanceof Error) {
    // Handle standard Error objects
    sentryError = error;
  } else {
    // Handle unknown error types
    sentryError = new Error(String(error));
  }

  // Log the error in the console using the existing bcError function
  bcError(logger, sentryError.message, errorContext);

  // Set the logger as a tag
  Sentry.setTag('logger', logger);

  // Capture the exception with additional context
  Sentry.captureException(sentryError, {
    extra: errorContext,
  });
}
