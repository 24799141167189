import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, SubHeader, TextLabel } from '../../atoms';
import { FilterButton as FB, SimpleSearch } from '../../molecules';
interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const Column = styled(RowContainer)`
  flex: 1;
  width: 100%;
`;
export const WsWrapper = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
  max-width: 100vw;
`;

export const SubheaderCustom = styled(SubHeader)`
  display: flex-column;
  border-bottom: none;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  min-height: 90px;
`;

export const DateContainer = styled(ColumnContainer)`
  margin-left: 18px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 10px;
`;

export const SubheaderLeftSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-bottom: 0;
  }
`;

export const Search = styled(SimpleSearch)`
  min-width: 220px;
`;

export const SubheaderRow = styled(RowContainer)`
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FilterButton = styled(FB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 40px;
`;

export const TextBlack = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px 0;
`;

export const TextBlackBold = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const GridGroup = styled(RowContainer)<IStyledProps>`
  display: grid;
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  grid-template-columns: repeat(auto-fill, 230px);
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    grid-template-columns: repeat(auto-fill, 25vw);
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    grid-template-columns: repeat(auto-fill, 44vw);
  }
`;

export const Group = styled(ColumnContainer)`
  flex: 1;
  overflow: auto;
`;

export const Stats = styled(TextLabel)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const ProductCardWrap = styled(ColumnContainer)``;
export const FlexRow = styled(RowContainer)`
  height: 48px;
  align-items: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ClientNameColumn = styled(ColumnContainer)`
  min-width: 85px;
`;

export const ClientImagesRow = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Modal = styled(ColumnContainer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
