import * as React from 'react';

import { __ } from 'common-services';

import { EmptyListResource, OrderRequestTable } from '../../molecules';

import * as S from './OrdersList.styled';

interface RequestsContentProps {
  orderRequests: Array<IOrderRequest>;
  catalogs: { [id: number]: IWorkspace };
  contacts: { [cId: number]: IContact };
  hasMore: boolean;
  me: IUser;
  openOrderRequestSelected: (request: IOrderRequest) => void;
  catalogHash?: string;
  totalResults: number;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  setShowShare: (show: boolean) => void;
}

const RequestsContentComponent: React.FC<RequestsContentProps> = ({
  orderRequests,
  catalogs,
  contacts,
  hasMore,
  me,
  openOrderRequestSelected,
  catalogHash,
  totalResults,
  touchImage,
  setShowShare,
}) => {
  return orderRequests.length ? (
    <>
      <S.TextTableHeader>{__('Components.OrdersList.requests_description')}</S.TextTableHeader>
      <OrderRequestTable
        catalogs={catalogs}
        contacts={contacts}
        hasMore={hasMore}
        me={me}
        orderRequestClick={openOrderRequestSelected}
        orderRequests={orderRequests}
        workspaceSelected={catalogHash}
        totalResults={totalResults}
        touchImage={touchImage}
      />
    </>
  ) : (
    <S.CenterContainer className="orders-list-center-container">
      <EmptyListResource
        text={__('Components.OrdersList.request.EmptyResults.title')}
        text2={__('Components.OrdersList.request.EmptyResults.description')}
        buttonText={__('Components.OrdersList.request.EmptyResults.cta')}
        buttonAction={() => setShowShare(true)}
        showButton={true}
      />
    </S.CenterContainer>
  );
};

export const RequestsContent = React.memo(RequestsContentComponent);
