import { Api, IPricelist, IResetAction, to } from 'common-services';
import { Dispatch } from 'redux';
import { IReduxState } from '../reducers';

type IDispatch = (dispatch: Dispatch<Action>, getState: () => IReduxState, api: Api) => Promise<void>;

/**
 * Call api for a Public price list by customUrl
 */
export function getPriceList(customUrl: string, contactHash?: string): IDispatch {
  return async (dispatch: Dispatch<Action>, getState: () => IReduxState, api: Api) => {
    dispatch(getPriceListAction());
    const { user } = getState().user;
    const { data, err } = await to(api.product.getPricelist(customUrl, (user && user.id) || 0, contactHash));
    if (!err) {
      dispatch(getPriceListSuccessAction(data!));
    } else {
      dispatch(getPriceListErrorAction((err.response && err.response.status) || 500));
    }
  };
}

/**
 * Call api for getting a price list in excel
 */
export function getExcelPriceList(catalogId: number, sellerId: number, buyerId: number, filename: string): IDispatch {
  return async (dispatch: Dispatch<Action>, getState: () => IReduxState, api: Api) => {
    await to(
      api.product.getExcelPricelist(catalogId, sellerId, buyerId, (data: string) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }),
    );
  };
}

/**
 * Call api for sending  Public price list in excel
 */
export function sendExcelPriceList(sellerId: number, catalogId: number, buyerId: number): IDispatch {
  return async (dispatch: Dispatch<Action>, getState: () => IReduxState, api: Api) => {
    await to(api.product.sendExcelPricelist(sellerId, catalogId, buyerId)); // TODO manage error
  };
}

export enum PricelistTypes {
  PRICELIST_GET = 'PRICELIST_GET',
  PRICELIST_GET_SUCCESS = 'PRICELIST_GET_SUCCESS',
  PRICELIST_GET_ERROR = 'PRICELIST_GET_ERROR',
  PRICELIST_EMAIL = 'PRICELIST_EMAIL',
}

interface GetPriceListAction {
  type: PricelistTypes.PRICELIST_GET;
  loading: boolean;
}

interface GetPriceListSuccessAction {
  type: PricelistTypes.PRICELIST_GET_SUCCESS;
  loading: boolean;
  pricelist: IPricelist;
}

interface GetPriceListErrorAction {
  type: PricelistTypes.PRICELIST_GET_ERROR;
  loading: boolean;
  error: number;
}

interface SetEmailAction {
  type: PricelistTypes.PRICELIST_EMAIL;
  email: string;
}

/**
 *  Action called before call the api.priceListGet
 */
function getPriceListAction(): GetPriceListAction {
  return { type: PricelistTypes.PRICELIST_GET, loading: true };
}

/**
 *  Action called after call the api.priceListGet and the response is ok
 */
function getPriceListSuccessAction(pricelist: IPricelist): GetPriceListSuccessAction {
  return { type: PricelistTypes.PRICELIST_GET_SUCCESS, loading: false, pricelist };
}

/**
 *  Action called after call the api.priceListGet and the response is error
 */
function getPriceListErrorAction(error: number): GetPriceListErrorAction {
  return { type: PricelistTypes.PRICELIST_GET_ERROR, loading: false, error };
}

/**
 *  Persist the user email.
 */
export function setEmailAction(email: string): SetEmailAction {
  return { type: PricelistTypes.PRICELIST_EMAIL, email };
}

export type Action =
  | GetPriceListAction
  | GetPriceListSuccessAction
  | GetPriceListErrorAction
  | IResetAction
  | SetEmailAction;
