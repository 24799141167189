import { __, associateReferenceSelectors } from 'common-services';
import Workspace from '../../Workspace/Workspace.component';
import history from '../../../../history';
import getPath from '../../../../util/routes';
import { ROUTE_PATHS } from '../../../../constants';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../../../reducers';
import ReferenceListDetail from './ReferenceListDetail.component';
import * as React from 'react';

const WorkspaceReferenceListDetail: React.FC = () => {
  const workspaceSelected = useSelector((state: IReduxState) => state.nav.workspaceSelected);
  const associateReferenceSelect = useSelector(associateReferenceSelectors.getAssociateReferenceSelect);

  return (
    <Workspace
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.offers'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFERS,
                workspaceId: workspaceSelected.toString(),
              }),
            );
          },
        },
        {
          label: __('Components.ProductsList.Tabs.referenceList'),
          action: () =>
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST,
                workspaceId: workspaceSelected.toString(),
              }),
            ),
        },
      ]}
      subtitle=""
      title={associateReferenceSelect.productName}
      tabSelected={'offers'}
      workspaceId={Number(workspaceSelected)}
    >
      <ReferenceListDetail/>
    </Workspace>
  );
};

export default WorkspaceReferenceListDetail;
