import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  content: string;
  cursor?: string;
  theme?: ITheme;
  disableHover?: boolean;
}

export const FontIcon = styled.div`
  font-family: 'Consentio-icons' !important;
  font-size: 99%;
  display: inherit;

  &:before {
    cursor: ${(props: IStyledProps) => (props.disableHover ? 'inherit' : props.cursor)};
    top: 0;
    left: 0;
    content: '${(props: IStyledProps) => props.content}';
    align-self: center;
    color: inherit;
  }

  ${(props: IStyledProps) =>
    !props.disableHover &&
    css`
      &:hover {
        opacity: 0.75;
      }
    `}
`;
