
/**
 * Generate a .csv file and show download dialog.
 */
export const downloadExport = (me: IUser, catalog: IWorkspace, catalogExportProducts:any) => {
    const element = document.createElement('a');
    const timemark = new Date().toLocaleString().replace(', ', '__');
    catalogExportProducts(me.id, catalog.id, (data: string) => {
        const file = new Blob([data], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.target = '_blank';
        element.download = `consentio-product-export-${timemark}.csv`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        element.remove();
    });
}

/**
 * Generate a .csv file and show download dialog.
 */

export const downloadCostExport = (workspaceId: number, costExportProducts:any) => {
  const element = document.createElement('a');
  const time = new Date().toLocaleString().replace(', ', '__');
  costExportProducts (workspaceId, (data: string) => {
      const file = new Blob([data], { type: 'text/csv' });
      element.href = URL.createObjectURL(file);
      element.target = '_blank';
      element.download = `consentio-cost-export-${time}.csv`;
      document.body.appendChild(element);
      element.click();
      element.remove();
  });
}

