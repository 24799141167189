import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../atoms';
import SimpleSearchComponent from '../../../molecules/SimpleSearch';

interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const WsWrapper = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
  max-width: 100vw;
`;

export const HeaderDashboards = styled(ColumnContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const HeaderFilters = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const LeftFiltersRow = styled(RowContainer)`
  align-items: center;
`;

export const SimpleSearch = styled(SimpleSearchComponent)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 235px;
  max-width: 492px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 185px;
  }
`;

export const RightCtaRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;
