import { constants, CURRENCY_CODES, PRICE_ADJUSTMENT_TYPE } from 'common-services';

/**
 * Get price adjusts for select component
 */
export function getPriceAdjustmentTypes(currency?: CURRENCY_CODES, isPor?: boolean): Array<{ value: string; label: string }> {
  const result = [
    {
      value: PRICE_ADJUSTMENT_TYPE.BASE,
      label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.BASE),
    },
  ];

  if (isPor) {
    return [
      {
        value: PRICE_ADJUSTMENT_TYPE.FIXED,
        label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.FIXED),
      },
      {
        value: PRICE_ADJUSTMENT_TYPE.POR,
        label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.POR),
      },
    ];
  } else {
    result.push(
      {
        value: PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE,
        label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE, currency),
      },
      {
        value: PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE,
        label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE, currency),
      },
      // TODO: Uncomment when supporting these types
      // {
      //   value: PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE,
      //   label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE, currency),
      // },
      // {
      //   value: PRICE_ADJUSTMENT_TYPE.MARKUP_PRICE,
      //   label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.MARKUP_PRICE, currency),
      // },
    );
  }



  return [
    ...result,
    {
      value: PRICE_ADJUSTMENT_TYPE.FIXED,
      label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.FIXED),
    },
    {
      value: PRICE_ADJUSTMENT_TYPE.POR,
      label: constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.POR),
    },
  ];
}
