import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  hasContent?: boolean;
}

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;

  .workspace-settings-members {
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;
export const SwitchDescription = styled(TextLabel)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 95%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: inline-flex;
`;
export const SwitchLink = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  cursor: pointer;
`;

export const ButtonAdd = styled(Button)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const FormatItem = styled(RowContainer)`
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)} 0;
  cursor: pointer;
`;

export const Formats = styled(ColumnContainer)`
  border-bottom: 1px solid ${(p: IStyledProps) => (p.hasContent ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FormatColumn = styled(ColumnContainer)`
  flex: 1;
`;

export const FormatTitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const FormatText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const FormatIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding: 2px;
  height: 23px;
  width: 23px;
  border-radius: 3px;
`;

export const FormatArrow = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: 0;
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;
