import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';

import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';
import MI from '../../molecules/MessageInput/MessageInput.component';
import RM from '../Message/Fragments/ReplyMessage';

interface IStyledProps {
  height?: string;
  hidden?: boolean;
  isDragActive?: boolean;
  isSelected?: boolean;
  theme: ITheme;
  defaultHeight?: number;
}

export const Container = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingObject};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  justify-self: flex-end;
  height: ${(p: IStyledProps) => p.height || 'min-content'};
  outline: none;
  width: 100%;
  ${(p: IStyledProps) =>
    p.isDragActive
      ? css`
          background-color: ${p.theme.colors.black};
          opacity: 0.1;
          & textarea {
            background: ${p.theme.colors.white};
            opacity: 0.1;
          }
        `
      : ''};
`;

export const MessageInput = styled(MI)`
  padding: 0;
`;

export const InputTextWithAutocomplete = styled(ReactTextareaAutocomplete)`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 9;
  justify-content: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 5px 8px;
  line-height: 20px;
  outline: none;
  resize: none;
  font-family: inherit;
  max-height: 66px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  &:focus-within {
    outline: none !important;
    border-color: ${(p: IStyledProps) => p.theme.colors.purple1};
  }
`;

export const CloseIcon = styled(FontIcon)`
  margin: 0 11px calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme!.colors.black};
`;

export const Message = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 6px 0;
`;

export const RowReply = styled(RowContainer)`
  justify-items: center;
  align-items: center;
  overflow: hidden;
  padding-top: 3px;
`;

export const EmojiAutocompleteElement = styled(RowContainer)`
  width: 35px;
  height: 35px;
  font-size: 25px;
  margin-top: 0px;
  padding-top: 10px;
  padding-left: 5px;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ReplyMessage = styled(RM)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3.replace('1)', '0.5)')};
  display: flex;
  flex: 9;
  flex-direction: column;
  margin: 0 3px 10px 0;
  width: 95%;
  max-width: calc(100% - 37px);
`;
