import { utils } from 'common-services';
import * as React from 'react';

import * as S from './MentionModal.styled';

interface IProps {
  className?: string;
  contacts: Record<number, IContact>;
  innerRef?: React.RefObject<HTMLDivElement>;
  members: Array<IMember>;
  mentionSelected: number;
  onClick?: (member: IMember) => void;
  onMouseEnter?: () => void;
  showMentionModal?: string;
}

const MentionModal = ({
  className,
  contacts,
  members,
  mentionSelected,
  onClick,
  onMouseEnter,
  innerRef,
  showMentionModal,
}: IProps) => {
  if (!members.length) return null;
  return (
    <S.MentionModal id="mention-modal" className={className} ref={innerRef}>
      {members.map((member, idx) => {
        const contact = contacts[member.id];
        const memberName = (contact || member).name;
        const memberCompany = (contact || member).companyName ? ` (${(contact || member).companyName})` : null;
        return (
          <S.Member
            key={idx}
            isSelected={idx === mentionSelected}
            onClick={() => {
              onClick?.(member);
            }}
            onMouseEnter={() => {
              onMouseEnter?.();
            }}
            id={`member-select-${member.id}`}
          >
            <S.MemberAvatar
              size={30}
              text={memberName}
              img={(contact || member).avatar}
              avatarColor={(contact || member).avatarColor}
              disabled={false}
            />
            <S.MemberText>
              <>
                {showMentionModal !== '@'
                  ? utils.highlightWordsWithPattern(memberName, showMentionModal.substring(1), (s, i) => (
                      <S.MemberTextHighlight key={i}>{s}</S.MemberTextHighlight>
                    ))
                  : memberName}
                {memberCompany}
              </>
            </S.MemberText>
          </S.Member>
        );
      })}
    </S.MentionModal>
  );
};

export default React.memo(MentionModal);
