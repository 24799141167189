import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const ScrollContainer = styled(ColumnContainer)`
  overflow-y: hidden;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const Link = styled(Button)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: inline;
`;
