import { IMAGES } from '../../../assets';
import { EmptyListResource } from '../../molecules';
import * as S from './WorkspaceReferenceList.styled';
import { __ } from 'common-services';
import * as React from 'react';

export function EmptyReferenceList(setShowClientsModal: React.Dispatch<React.SetStateAction<boolean>>) {
  return (
    <S.CenterContainer>
      <EmptyListResource
        text={__('Components.SellerOffer.referenceList.zeroCase.title')}
        text2={__('Components.SellerOffer.referenceList.zeroCase.subtitle')}
        buttonText={__('Components.SellerOffer.referenceList.zeroCase.buttonText')}
        buttonType="principal"
        imageUrl={IMAGES.sellerWholesaleZeroCase}
        buttonAction={() => {
          if (setShowClientsModal) {
            setShowClientsModal(true);
          }
        }}
      />
    </S.CenterContainer>
  );
}
