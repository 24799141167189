import { Api, mappingActions, modalActions, orderActions, productActions, userActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import MappingCodeList, { IDispatchProps, IStateProps, OwnProps } from '../components/organisms/MappingCodeList';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: OwnProps): IStateProps {
  const {
    catalog: { catalogs },
    chat: { channels },
    contact: { inConsentio },
    country: { countries },
    nav: { workspaceSelected },
    order: { formats },
    prodType: { prodTypes },
    product: { prices, priceGroupIds },
    user: { user },
  } = state;
  const {
    match: {
      params: { channelId, clientId },
    },
  } = props;
  const channel = channels[channelId];
  const catalog =
    catalogs[channel?.workspaceId] ||
    catalogs[props.contact?.mySellerWorkspaceId] ||
    catalogs[workspaceSelected] ||
    catalogs[user.sellerWorkspaceId];
  return {
    catalog,
    clientId: clientId ? Number(clientId) : props.contact?.id || channel?.defaultBuyerId || 0,
    contacts: inConsentio,
    countries,
    formats,
    me: user!,
    priceGroupIds,
    prices,
    prodTypes,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      getAddressMappings: mappingActions.addressMappingsGet,
      getPrices: productActions.getPrices,
      getProductMappings: mappingActions.productMappingsGet,
      importFormatsGet: orderActions.importFormatsGet,
      mappingDelete: mappingActions.mappingDelete,
      mappingUpdate: mappingActions.mappingUpdate,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      mappingSearch: mappingActions.mappingSearch,
      mappingScrollSearch: mappingActions.mappingScrollSearch,
      requestProInfo: userActions.requestProInfo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingCodeList);
