import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer } from '../../atoms';
import AM from '../ActionsModal/ActionsModal.component';
import SimpleSearch from '../SimpleSearch';

interface IStyledProps {
  hasSelected?: boolean;
  margin?: string;
  theme: ITheme;
}

export const ActionsModal = styled(AM)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Scroll = styled.div<IStyledProps>`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.hasSelected ? 10 : 2)};
`;

export const Contacts = styled(ColumnContainer)`
  display: flex;
  flex-direction: column;
`;

export const CTA = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  height: 44px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingObject};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: absolute;
  bottom: ${(p: IStyledProps) => p.theme.paddingView};
  right: ${(p: IStyledProps) => p.theme.paddingView};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-shadow: 1px 1px 3px ${(p: IStyledProps) => p.theme.colors.grey1};
  & > .button-icon {
    padding: 0 5px 4px 0;
  }
`;

export const Search = styled(SimpleSearch)`
  margin: 0;
  margin-top: 5px;
  flex: 0;
`;

export const SectionTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  width: 100%;
  padding: calc(${(p: IStyledProps) => p.theme.paddingView} / 2) 0;
`;
