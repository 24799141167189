import styled, { css, ITheme } from '../../../styled-components';
import { FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  from?: 'table' | 'other';
  theme: ITheme;
}

export const ArticleImagesRow = styled(RowContainer)`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 3px;
  max-width: max-content;
  min-width: 36px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  .article-img {
    margin-right: -7px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    ${(p: IStyledProps) =>
      p.from === 'table' &&
      css`
        .article-img:nth-child(n + 2) {
          display: none;
        }
      `}
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    ${(p: IStyledProps) =>
      p.from === 'table' &&
      css`
        display: none;
      `}
  }
`;

export const ArticleImageContainer = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ArticleImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
  &:hover {
    filter: grayscale(0.95) brightness(0.6);
  }
  cursor: pointer;
`;

export const TextCountArticles = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding-left: 16px;
`;

export const TextCountArticlesResponsive = styled(TextCountArticles)`
  ${(p: IStyledProps) =>
    css`
      display: none;
    `}

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    ${(p: IStyledProps) =>
      p.from === 'table' &&
      css`
        display: block;
      `}
  }
`;

export const PeopleIconContainer = styled(RowContainer)`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
  justify-content: center;
`;

export const PeopleIcon = styled(FontIcon)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
