import { i18n, IOffer } from "common-services";

export const buildDefaultOfferName = (client: IClient, offerDate: Date): string => {
  return `${client?.company} (${client?.name}) ${Intl.DateTimeFormat(i18n.default.currentLocale(), {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(offerDate)}`;
};

export const isOfferNameCustom = (offer: IOffer, client: IClient, offerDate: Date): boolean => {
  if (!offer || !offer.name || offer.name === '') {
    return false;
  }
  return offer.name !== buildDefaultOfferName(client, offerDate);
};