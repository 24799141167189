import {
  __,
  constants,
  contactActions,
  date,
  EventTrack,
  IContact,
  INVITE_ORIGIN,
  IOrderRequest,
  IProdType,
  IUser,
  modalActions,
  notificationsActions,
  orderActions,
  parsers,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userService,
  utils,
  VIA,
} from 'common-services';
import { touchImage } from 'common-services/dist/modal/actions';
import { getCartLoadSummaryText } from 'common-services/dist/order/service';
import { History } from 'history';
import { AsYouType } from 'libphonenumber-js/mobile';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { IMAGE_DEFAULT, orderDetailColumns } from '../../../constants';
import { copyToClipboard } from '../../../util/utils';
import { ColumnContainer, FontIcon, Ribbon, Tooltip } from '../../atoms';
import { ContactCell, Table } from '../../molecules';
import { IColumn, IDataCell, TYPE_CELL } from '../../molecules/Table/Table.component';
import * as S from './OrderRequestDetails.styled';

type IRouteProps = RouteComponentProps<{ requestId?: string }>;

export interface StateProps {
  contacts: { [id: number]: IContact };
  me: IUser;
  prodTypes: { [key: string]: IProdType };
  columnConfig?: Array<IVisibilityConfig>;
}

export interface DispatchProps {
  modalOpen: typeof modalActions.modalOpen;
  contactsInvite: typeof contactActions.contactsInvite;
  modalClose: typeof modalActions.modalClose;
  requestAskForInformation: typeof orderActions.requestAskForInformation;
  notificationShow: typeof notificationsActions.notificationShow;
  tableVisibilityConfigGet: typeof sellerWorkspaceActions.tableVisibilityConfigGet;
  touchImage: typeof modalActions.touchImage;
}

export interface HeritageProps {
  goBack?: () => void;
  history: History<any>;
  location: any;
  match: any;
  request: IOrderRequest;
  workspaceSelected: IWorkspace;
}

export type IProps = StateProps & DispatchProps & IRouteProps & HeritageProps;

interface IState {
  companyRequested: boolean;
  invitationSent: boolean;
  phoneRequested: boolean;
  sortedItems: Array<IOrderItem>;
  sortItemsFilter: SortItemFilter;
  sortOptions: Array<{ key: string; value: string }>;
}

/**
 * Order request details
 */
export default class OrderRequestDetails extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props) {
    super(props);
    this.t = Date.now();
    this.state = {
      companyRequested: false,
      phoneRequested: false,
      invitationSent: this.hasInvitationSent(),
      sortedItems: [],
      sortItemsFilter: props.catalog?.sortItemsBy,
      sortOptions: [],
    };
  }

  public componentDidMount() {
    this.setState({
      sortOptions: this.getSortOptions(),
    });
    RenderTrack.track('OrderRequestDetails', { renderTime: this.t });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { request, workspaceSelected, me, tableVisibilityConfigGet } = this.props;
    const { sortItemsFilter } = this.state;
    const hasItemsUpdate = prevProps.request.items !== request.items;
    if (hasItemsUpdate || prevState.sortItemsFilter !== sortItemsFilter)
      this.setState({
        sortOptions: hasItemsUpdate ? this.getSortOptions() : this.state.sortOptions,
      });

    if (prevProps.workspaceSelected !== workspaceSelected && workspaceSelected) {
      tableVisibilityConfigGet(me.id, workspaceSelected.id, 'order_detail');
    }
  }

  public render() {
    const { contacts, goBack, request, me, notificationShow } = this.props;
    const { companyName, createdAt, email, emailValidated, phone, sellerId } = request;
    const { invitationSent, phoneRequested, companyRequested } = this.state;
    const sellerName =
      sellerId === me.id
        ? userService.getDisplayName(me.name, me.companyName)
        : userService.getDisplayName(contacts[sellerId]?.name, contacts[sellerId]?.companyName);

    const dateFormat = me.settings.dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    const hourFormat = me.settings.hourFormat;

    return (
      <S.Container className="order-request-details">
        <S.BackContainer>
          <S.Back onClick={goBack}>
            <S.ChevronIcon name="Back" />
            {__('OrderRequestDetails.back')}
          </S.Back>
          {invitationSent ? null : (
            <S.InviteButton
              type="secondary"
              id="order-request-details-invite-button"
              onClick={() => this.onInviteContact()}
            >
              {__('OrderRequestDetails.invite')}
            </S.InviteButton>
          )}
        </S.BackContainer>
        {this.renderRibbon()}
        <S.Content>
          <S.TextTitleH1>{__('OrderRequestDetails.title')}</S.TextTitleH1>
          <S.TagsRow>
            <S.Tag
              label={
                emailValidated
                  ? __('OrderRequestDetails.email_confirmed')
                  : __('OrderRequestDetails.email_not_confirmed')
              }
              color={emailValidated ? 'confirmed' : undefined}
              disabled={true}
            />
            <S.Tag label={__(`Components.Cart.tags.public-shop`)} disabled={true} />
          </S.TagsRow>
          <S.InfoRow>
            <S.TextBold>
              {__('OrderRequestDetails.creation_date') + ': '}
              <S.TextBlack>
                {date.formatLongDate(createdAt, undefined, undefined, dateFormat) +
                  ' · ' +
                  date.formatTime(createdAt, hourFormat)}
              </S.TextBlack>
            </S.TextBold>
          </S.InfoRow>
          <S.InfoRow>
            <S.TextBold>
              {__('OrderRequestDetails.company') + ': '}
              {companyName ? (
                <S.TextBlack>{companyName}</S.TextBlack>
              ) : companyRequested ? (
                <S.TextBlack>{__('OrderRequestDetails.requested')}</S.TextBlack>
              ) : (
                <S.Link type="link" withoutPadding={true} onClick={() => this.onRequestInfo('company')}>
                  {__('OrderRequestDetails.request_company')}
                </S.Link>
              )}
            </S.TextBold>
          </S.InfoRow>
          <S.InfoRow>
            <S.TextBold>
              {__('OrderRequestDetails.email') + ': '}
              <S.TextEmail href={`mailto:${email}`}>{email}</S.TextEmail>
            </S.TextBold>
            <S.CopyIcon
              name="Clipboard"
              onClick={() => {
                copyToClipboard(email, error =>
                  notificationShow(
                    {
                      style: error ? 'error' : 'info',
                      title: error ? __('ClipboardError.title') : __('OrderRequestDetails.notification_copy.title'),
                      subtitle: error
                        ? __('ClipboardError.subtitle')
                        : __('OrderRequestDetails.notification_copy.description'),
                      closable: true,
                    },
                    4000,
                  ),
                );
              }}
            />
          </S.InfoRow>
          <S.InfoRow>
            <S.TextBold>
              {__('OrderRequestDetails.phone') + ': '}
              {phone ? (
                <S.TextBlack>{new AsYouType().input('+' + phone)}</S.TextBlack>
              ) : phoneRequested ? (
                <S.TextBlack>{__('OrderRequestDetails.requested')}</S.TextBlack>
              ) : (
                <S.Link type="link" withoutPadding={true} onClick={() => this.onRequestInfo('phone')}>
                  {__('OrderRequestDetails.request_phone')}
                </S.Link>
              )}
            </S.TextBold>
          </S.InfoRow>
          <S.InfoRow>
            <S.TextBold>
              {__('OrderRequestDetails.seller') + ': '}
              <S.TextBlack>{sellerName + (sellerId === me.id ? ' ' + __('Facets.you') : '')}</S.TextBlack>
            </S.TextBold>
          </S.InfoRow>
          {config.TOGGLE_REQUEST_ORDER.enabled ? this.renderRequestArticlesNew() : this.renderRequestArticles()}
        </S.Content>
      </S.Container>
    );
  }

  private renderRibbon() {
    const { request } = this.props;
    const { email } = request;
    return (
      <Ribbon
        type={request.emailValidated ? 'info' : 'warning'}
        text={
          request.emailValidated
            ? __('OrderRequestDetails.ribbon_confirmed', { email })
            : __('OrderRequestDetails.ribbon_not_confirmed', { email })
        }
      />
    );
  }

  private getSortOptions = () => {
    const { request } = this.props;
    const result = [
      { key: 'added', value: __('Components.OrderDetails.sort_products.default') },
      { key: 'alphabetic', value: __('Components.OrderDetails.sort_products.alphabetical') },
    ];

    const hasSku = request.items.reduce((acc, item) => {
      if (item.sku) acc = true;
      return acc;
    }, false);
    if (hasSku) result.push({ key: 'sku', value: __('Components.OrderDetails.sort_products.sku') });

    return result;
  };

  private renderRequestArticlesNew() {
    const { request } = this.props;
    const { items } = request;
    const { sortOptions } = this.state;

    const columns = this.getColumnsRequest();
    return (
      <>
        <S.SectionTitle>
          {__('Components.Cart.items.title')} ({items.length})
        </S.SectionTitle>
        <S.CardItem>
          <S.TableContainer>
            <S.TableHeadWrapper>
              <S.LeftRow>
                <S.SortByDropdown
                  options={sortOptions}
                  hAlign="left"
                  onSelect={v => {
                    if (v === 'alphabetic') this.setState({ sortItemsFilter: 'alphabetic' });
                    if (v === 'added') this.setState({ sortItemsFilter: 'added' });
                    if (v === 'sku') this.setState({ sortItemsFilter: 'sku' });
                    EventTrack.track('order_items_sort', { order_id: request.id, sort: v });
                  }}
                >
                  <S.SortButton type="skip" iconName="Sort" iconSize="15px">
                    <S.SortBlackText>{__('Components.OrderDetails.sort_products.title')}: </S.SortBlackText>
                    <S.SortGreyText>{this.getSelectedSortLabel()}</S.SortGreyText>
                    <FontIcon name="Down" />
                  </S.SortButton>
                </S.SortByDropdown>
              </S.LeftRow>
            </S.TableHeadWrapper>
          </S.TableContainer>
          <Table
            className="items-table"
            values={items}
            onClickRow={(el: IOrderItem) => {
              EventTrack.track('order_item_click', { order_id: request.id, product_id: el.id });
            }}
            emptyText={''}
            columns={columns}
            selectable={false}
            fixedColumns={['product', 'quantity']}
            showCustomColumns={true}
            configId={'order_detail'}
            productColumns={orderDetailColumns(false)}
          />
        </S.CardItem>
      </>
    );
  }

  private getSelectedSortLabel = () => {
    const { sortItemsFilter } = this.state;
    switch (sortItemsFilter) {
      case 'alphabetic':
        return __('Components.OrderDetails.sort_products.alphabetical');
      case 'sku':
        return __('Components.OrderDetails.sort_products.sku');
      default:
        return __('Components.OrderDetails.sort_products.default');
    }
  };

  /**
   * Get items sorted according to filter
   */

  private getColumnField(
    columnConfig: IColumnConfig,
    options?: {
      getValue?: (data: IOrderItem, idx: number, rowIdx: number) => string | number;
      getElement?: (data: IOrderItem, idx: number, rowIdx: number) => React.ReactElement;
      getDataCell?: (data: IOrderItem, idx: number, rowIdx: number) => IDataCell;
    },
    isQuoterMode?: boolean,
  ): IColumn {
    const { getValue, getElement, getDataCell } = options || {};

    switch (columnConfig.name) {
      case 'box-weight':
        return {
          id: 'box-weight',
          title: __('Components.ProductsList.Table.BoxWeight'),
          value: (data: IOrderItem) => (data.boxWeight ? `${data.boxWeight} ${data.weightUnit}` : ''),
          minWidth: '70px',
          width: '9%',
        };
      case 'brand':
        return {
          id: 'brand',
          title: __('Components.ProductsList.Table.Brand'),
          minWidth: '70px',
          width: '9%',
        };
      case 'category':
        return {
          id: 'category',
          title: __('Components.ProductsList.Table.Category'),
          minWidth: '70px',
          width: '9%',
          value: (data: IProduct) =>
            parsers.getCategoryText(data.category as (typeof constants.PRODUCT_CATEGORIES)[number]),
        };
      case 'contracts':
        return {
          id: 'contracts',
          title: __('WorkspaceSupplierEdit.Menu.contracts.ref'),
          minWidth: '70px',
          width: '9%',
          element: getElement,
        };
      case 'ean':
        return {
          id: 'ean',
          title: __('Components.ProductsList.Table.Ean'),
          minWidth: '70px',
          width: '9%',
          value: (data: IProduct) => data.eanCode,
        };
      case 'images':
        return {
          id: 'images',
          title: __('Components.ProductsList.Table.Images'),
          getDataCell,
          minWidth: '70px',
          width: '9%',
        };
      case 'load':
        return {
          id: 'load',
          title: __('Components.ProductsList.Table.Load'),
          minWidth: '120px',
          width: '10%',
          element: getElement,
        };
      case 'origin':
        return {
          id: 'origin',
          title: __('Components.ProductsList.Table.Origin'),
          value: (data: IOrderItem) => data.origin,
          minWidth: '50px',
          width: '9%',
        };
      case 'packaging':
        return {
          id: 'packaging',
          title: __('Components.ProductsList.Table.Packaging'),
          minWidth: '70px',
          width: '9%',
        };
      case 'product':
        return {
          id: 'product',
          title: __('Components.Cart.items.product'),
          element: getElement,
          minWidth: '160px',
          width: '20%',
        };
      case 'quantity':
        return {
          id: 'quantity',
          title: __('Components.Cart.items.quantity'),
          element: getElement,
          minWidth: '220px',
          width: '12%',
        };
      case 'region':
        return {
          id: 'region',
          title: __('Components.ProductsList.Table.Region'),
          minWidth: '70px',
          width: '9%',
        };
      case 'served-quantity':
        return {
          id: 'served-quantity',
          title: __('Components.Cart.items.served_quantity'),
          element: getElement,
          minWidth: '70px',
          width: '8%',
        };
      case 'size':
        return {
          id: 'size',
          title: __('Components.ProductsList.Table.Size'),
          minWidth: '70px',
          value: (data: IOrderItem) => data.size,
          width: '9%',
        };
      case 'sku':
        return {
          id: 'sku',
          title: __('Components.ProductsList.Table.Sku'),
          minWidth: '70px',
          width: '9%',
        };
      case 'type-of-production':
        return {
          id: 'type-of-production',
          title: __('Components.ProductsList.Table.TypeOfProduction'),
          minWidth: '70px',
          width: '9%',
          value: (data: IProduct) => parsers.getOrganicText(data.organic),
        };
      case 'warehouse':
        return {
          id: 'warehouse',
          title: __('Components.ProductsList.Table.Warehouse'),
          minWidth: '120px',
          width: '10%',
          value: getValue,
        };
      case 'weight':
        return {
          id: 'weight',
          title: __('Components.Cart.items.weight'),
          element: getElement,
          minWidth: '70px',
          width: '9%',
        };
      default:
        return undefined;
    }
  }

  private getColumnsRequest() {
    const { prodTypes, columnConfig, workspaceSelected, request } = this.props;

    const result = [];

    result.push(
      this.getColumnField(
        { name: 'product', order: 0, visible: true },
        {
          getElement: (data: IOrderItem) => {
            const typeVariety = productService.getProductTypeVarietyDisplay(
              data.type,
              prodTypes[data.type] ? prodTypes[data.type].name : '',
              data.title,
            );

            return (
              <ContactCell
                avatar={(
                  (data.images && data.images.length && data.images[0].image.url) ||
                  (prodTypes[data.type] && prodTypes[data.type].defaultImageUrl) ||
                  IMAGE_DEFAULT
                ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
                imgText={'image text'}
                primaryText={data.productTitle || typeVariety + ' ' + data.size}
                showGrey={false}
              />
            );
          },
        },
      ),
    );
    result.push(
      this.getColumnField(
        { name: 'quantity', order: 17, visible: true },
        {
          getElement: (data: IOrderItem) => {
            return (
              <S.AmountRow onClick={e => e.stopPropagation()}>
                <S.QuantityRow>
                  <S.Amount showGrey={false}>{data.amount}</S.Amount>
                  <S.Amount showGrey={false}>
                    {parsers.getUnitText(data.saleUnit, data.weightUnit, data.amount)}
                  </S.Amount>
                </S.QuantityRow>
              </S.AmountRow>
            );
          },
        },
      ),
    );

    const configCol =
      columnConfig && columnConfig.find(c => c.view === 'order_detail')
        ? columnConfig.find(c => c.view === 'order_detail').columns
        : sellerWorkspaceService.getDefaultColumnConfig('order_detail');

    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'brand':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.brand;
                },
              }),
            );
            break;
          case 'category':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.category;
                },
              }),
            );
            break;
          case 'contracts':
            result.push(
              this.getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const displayedRefs = data.contracts?.length > 2 ? 1 : 2;
                  return (
                    <S.ReferenceCol>
                      {data.contracts?.slice(0, displayedRefs).map(c => (
                        <>
                          <S.Reference>{c.reference}</S.Reference>
                        </>
                      ))}
                      {data.contracts?.length > 2 ? (
                        <Tooltip
                          component={
                            <S.ReferenceCol>
                              {data.contracts?.slice(1).map(c => (
                                <>
                                  <S.Reference>{'· ' + c.reference}</S.Reference>
                                </>
                              ))}
                            </S.ReferenceCol>
                          }
                          themeMode="dark"
                        >
                          <S.BlueText>
                            {__('WorkspaceSupplierEdit.Menu.contracts.see_more', {
                              count: data.contracts?.length - 1,
                            })}
                          </S.BlueText>
                        </Tooltip>
                      ) : null}
                    </S.ReferenceCol>
                  );
                },
              }),
            );
            break;
          case 'ean':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.eanCode;
                },
              }),
            );
            break;
          case 'images':
            result.push(
              this.getColumnField(column, {
                getDataCell: (data: IOrderItem) => {
                  const emptyImages: Array<ISizeImage> = [];
                  const images = data.images || emptyImages;
                  return {
                    type: TYPE_CELL.IMAGE,
                    images: images.filter(i => i.image.url).map(img => img.image.url),
                    onImageClick: index =>
                      touchImage(
                        images.map(img => ({ src: img.image.url, caption: img.image.comment })),
                        index,
                      ),
                    count: images.filter(i => i.image.url).length,
                  };
                },
              }),
            );
            break;
          case 'load':
            result.push(
              this.getColumnField(column, {
                getElement: (item: IOrderItem) => {
                  return <S.SubText>{item ? getCartLoadSummaryText([item]) : ''}</S.SubText>;
                },
              }),
            );
            break;
          case 'origin':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.origin;
                },
              }),
            );
            break;
          case 'packaging':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.packaging;
                },
              }),
            );
            break;
          case 'sku':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.sku;
                },
              }),
            );
            break;
          case 'size':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.size;
                },
              }),
            );
            break;
          case 'warehouse':
            result.push(
              this.getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return '';
                },
              }),
            );
            break;
          case 'weight':
            result.push(
              this.getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  return <S.Td>{this.renderWeight(data)}</S.Td>;
                },
              }),
            );
            break;
          default:
            break;
        }
      });

    return result;
  }

  private renderWeight(item: IOrderItem) {
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.Row>
          <S.Amount>{item.servedWeight || 0}</S.Amount>
          <S.Amount> {item.weightUnit}</S.Amount>
        </S.Row>
      </S.AmountRow>
    );
  }

  /**
   * Render articles
   */
  private renderRequestArticles() {
    const { prodTypes, request } = this.props;
    const { id, items } = request;

    return (
      <S.ArticlesContainer>
        <S.TextTitleH1>{__('OrderRequestDetails.articles', { count: items.length })}</S.TextTitleH1>
        <S.Table>
          <thead>
            <tr>
              <S.Th colSpan={2} />
              <S.Th>{__('OrderRequestDetails.table.product')}</S.Th>
              <S.Th>{__('OrderRequestDetails.table.amount')}</S.Th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => {
              const typeVariety = productService.getProductTypeVarietyDisplay(
                item.type,
                prodTypes[item.type] ? prodTypes[item.type].name : '',
                item.title,
              );
              return (
                <S.Tr key={id + '_' + idx}>
                  <S.EmptyTd />
                  <S.ProductImageTd>
                    <S.ProductImage
                      src={(
                        (item.images && item.images.length && item.images[0].image.url) ||
                        (prodTypes[item.type] && prodTypes[item.type].defaultImageUrl) ||
                        IMAGE_DEFAULT
                      ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
                    />
                  </S.ProductImageTd>
                  <S.ProductDescriptionTd>
                    <ColumnContainer>
                      <S.TextProductName>{utils.firstToUpperCase(`${typeVariety} ${item.size}`)}</S.TextProductName>
                      {item.packaging ? <S.TextBlack>{item.packaging}</S.TextBlack> : null}
                    </ColumnContainer>
                  </S.ProductDescriptionTd>
                  <S.ProductAmountTd>
                    <S.TextBlack>
                      {item.amount} {parsers.getUnitText(item.saleUnit, item.weightUnit, item.amount)}
                    </S.TextBlack>
                  </S.ProductAmountTd>
                </S.Tr>
              );
            })}
          </tbody>
        </S.Table>
      </S.ArticlesContainer>
    );
  }

  /**
   * Invite contact
   */

  private onInviteContact() {
    const { request, contactsInvite, me, notificationShow } = this.props;
    contactsInvite(
      me.id,
      VIA.EMAIL,
      'web',
      INVITE_ORIGIN.REGULAR,
      [
        {
          name: request.email,
          email: request.email,
          phone: request.phone,
          counterpart_id: '',
        },
      ],
      (data, error) => {
        if (error || data?.errors.length) {
          notificationShow(
            {
              title: __('Notification.error.title'),
              subtitle: __('Notification.error.description'),
              closable: true,
              style: 'error',
            },
            4000,
          );
        } else {
          notificationShow(
            {
              title: __('OrderRequestDetails.notification_invite.title'),
              subtitle: __('OrderRequestDetails.notification_invite.description', { name: request.email }),
              closable: true,
              style: 'info',
            },
            4000,
          );
          this.setState({ invitationSent: true });
        }
      },
    );
  }

  /**
   * Request info, show modal to confirm to send request
   */
  private onRequestInfo(type: 'company' | 'phone') {
    const { me, modalClose, modalOpen, request, requestAskForInformation, notificationShow } = this.props;
    const { companyRequested, phoneRequested } = this.state;
    const title =
      type === 'company'
        ? __('OrderRequestDetails.request.company.title')
        : __('OrderRequestDetails.request.phone.title');
    const description =
      type === 'company'
        ? __('OrderRequestDetails.request.company.description', { email: request.email })
        : __('OrderRequestDetails.request.phone.description', { email: request.email });

    modalOpen(
      title,
      () => {
        modalClose();
        const successNotification = () =>
          notificationShow(
            {
              title: __('OrderRequestDetails.notification_ask_info.title'),
              subtitle: '',
              closable: true,
              style: 'info',
            },
            3000,
          );
        if (companyRequested || phoneRequested) return successNotification();
        requestAskForInformation(me.id, request.id, () => {
          successNotification();
          if (type === 'phone') this.setState({ phoneRequested: true });
          if (type === 'company') this.setState({ companyRequested: true });
        });
      },
      {
        text2: description,
        icon: IMAGES.email,
        showCancelButton: true,
        buttonText: __('OrderRequestDetails.request.cta'),
      },
      'nice',
    );
  }

  /**
   * Check if we already have this contact (invitation already sent)
   */
  private hasInvitationSent() {
    const { contacts, request } = this.props;
    return Object.values(contacts).reduce((acc, contact) => {
      if (acc) return acc;
      if (contact.email === request.email) acc = true;
      return acc;
    }, false);
  }
}
