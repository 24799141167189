import { PICTURE_SMALL_SIZE } from '../../../constants';
import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, ImageThumbnail as IT, RowContainer, TextArea } from '../../atoms';
import ActionsModal from '../../molecules/ActionsModal/ActionsModal.component';

interface IStyledProps {
  bigInput?: boolean;
  hasImage?: boolean;
  hasMultipleImages?: boolean;
  isLast?: boolean;
  theme: ITheme;
}

export const CardButton = styled(Button)``;

export const WritePostModal = styled(ActionsModal)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const ModalContent = styled(ColumnContainer)`
  min-height: min-content;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  overflow-y: auto;
`;

export const InputContainer = styled(ColumnContainer)`
  justify-content: space-between;
  height: min-content;
`;

export const InputArea = styled(TextArea)<IStyledProps>`
  width: 100%;
  height: auto;

  textarea {
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    width: 100%;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
    color: ${(p: IStyledProps) => p.theme.colors.black};
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
    font-family: 'Nunito';
    padding: 2px 0;
    overflow-y: hidden;
    &:focus-within {
      box-shadow: none;
    }
  }

  ${(p: IStyledProps) =>
    p.bigInput &&
    css`
      min-height: 235px;
    `};
`;

export const CTAContainer = styled(RowContainer)<IStyledProps>`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(p.hasImage ? 3 : 2)};

  ${(p: IStyledProps) =>
    p.hasImage &&
    css`
      min-height: 60px;
    `};
`;

export const ModalCTA = styled(Button)`
  min-width: 260px;
  align-self: center;
`;

export const AddImageCTA = styled(Button)`
  align-self: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const ImageContainer = styled(ColumnContainer)`
  position: relative;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ImagePreview = styled.img<IStyledProps>`
  width: 100%;
  height: auto;
  max-height: 325px;
  object-fit: contain;

  ${(p: IStyledProps) =>
    p.hasMultipleImages &&
    css`
      width: auto;
      max-width: 100%;
      min-height: 325px;
      height: 325px;
    `}
`;

export const ThumbnailsContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};

  #post-write-modal-thumbnail-add {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  }
`;

export const ImageThumbnail = styled(IT)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const ImageActionsContainer = styled(RowContainer)`
  align-items: center;
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  .write-post-modal-action-button:first-child {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const ActionButton = styled(RowContainer)`
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

export const EditIcon = styled(FontIcon)`
  width: 15px;
  height: 15px;
  font-size: 15px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const CropIcon = styled(FontIcon)`
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const CloseIcon = styled(FontIcon)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;
export const FontIconWrapper = styled.label`
  padding: 8px 0;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme!.colors.blue1};
`;
export const EmojiRow = styled(RowContainer)`
  position: relative;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const EmojiWrapper = styled.div<IStyledProps>`
  position: absolute;
  ${(p: IStyledProps) =>
    !p.hasImage &&
    css`
      top: -294px;
    `}
  left: 118px;
  z-index: 1;
`;

export const ButtonContainer = styled(RowContainer)`
  width: 100%;
  margin-top: 0px;
  padding-top: 10px;
`;

export const AddContainer = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: ${PICTURE_SMALL_SIZE};
  height: ${PICTURE_SMALL_SIZE};
  margin: 0;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const AddContainerAbsolute = styled(AddContainer)`
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
`;

export const AddIcon = styled(FontIcon)`
  font-size: 26px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const PollRow = styled(RowContainer)``;

export const PollContainer = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
