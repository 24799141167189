import styled, { ITheme, keyframes } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  size?: string;
  borderSize?: string;
}

const SpinKeyframes = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const Loading = styled.div<IStyledProps>`
  border: ${(p: IStyledProps) => p.borderSize} solid ${(p: IStyledProps) => p.theme.colors.white};
  border-top: ${(p: IStyledProps) => p.borderSize} solid ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: 50%;
  width: ${(p: IStyledProps) => p.size};
  height: ${(p: IStyledProps) => p.size};
  animation: ${SpinKeyframes} 1.5s linear infinite;
`;
