import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  messageRead?: boolean;
  side?: 'left' | 'right';
  theme: ITheme;
}

const Row = styled(RowContainer)`
  position: relative;
  justify-content: flex-start;
`;

export const Container = styled(ColumnContainer)`
  width: auto;
  max-width: min(100%, 600px);
  max-height: 350px;
  align-self: ${(p: IStyledProps) => 'flex-start'};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    opacity: 0.75;
  }
`;

export const ImgContainer = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.black};
  & .image-actions {
    display: none;
  }
  &:hover {
    & img {
      opacity: 0.75;
    }
    & .image-actions {
      display: flex;
    }
  }
`;

export const Img = styled.img`
  width: auto;
  max-width: min(100%, 600px);
  height: 300px;
  float: left;
  display: block;
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Download = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2) calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  margin: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Actions = styled(Row)`
  position: absolute;
  top: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
  right: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
`;

export const TextWrapper = styled(ColumnContainer)`
  width: auto;
`;

export const TextItalic = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-style: italic;
  padding-right: 2px;
`;
