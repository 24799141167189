import styled, { css, ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms/FlexContainer';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  size?: number;
  isChecked?: boolean;
  isRadio?: boolean;
}

export const Container = styled.div`
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p: IStyledProps) =>
    !p.disabled &&
    css`
      &:hover {
        opacity: 0.8;
      }
    `};
  padding-top: 2px;
`;

const getBorderColor = (p: IStyledProps) => {
  if (p.isChecked) {
    return p.disabled ? p.theme.colors.grey3 : p.theme.colors.green1;
  } else {
    return p.disabled ? p.theme.colors.grey3 : p.theme.colors.grey2;
  }
};

const getBackgroundColor = (p: IStyledProps) => {
  if (p.isChecked) {
    return p.disabled ? p.theme.colors.grey3 : p.theme.colors.green1;
  } else {
    return p.theme.colors.transparent;
  }
};

export const RadioInput = styled(RowContainer)<IStyledProps>`
  border: 1px solid ${getBorderColor};
  background-color: ${getBackgroundColor};
  border-radius: ${(p: IStyledProps) => (p.isRadio ? '50%' : p.theme.borderRadius)};
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => p.size}px;
  min-width: ${(p: IStyledProps) => p.size}px;
  width: ${(p: IStyledProps) => p.size}px;
`;
