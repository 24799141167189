import { __, INVITE_VIA } from 'common-services';

import { IItem } from '../components/atoms/SimpleDropdown/SimpleDropdown.component';

/**
 * Options to show wen click in invite by other way
 */
export function getInviteOptions(items: Array<INVITE_VIA | 'copy'>) {
  const options: Record<INVITE_VIA | 'copy', IItem> = {
    [INVITE_VIA.CONSENTIO]: { key: 'consentio', value: __('Invite.consentio'), icon: 'Add-more' },
    [INVITE_VIA.PHONEBOOK]: { key: 'phonebook', value: __('Invite.phonebook'), icon: 'Agenda' },
    copy: { key: 'copy', value: __('Invite.copy'), icon: 'Link' },
    [INVITE_VIA.EMAIL]: { key: 'email', value: __('Invite.email'), icon: 'Read' },
    [INVITE_VIA.SMS]: { key: 'sms', value: __('Invite.sms'), icon: 'Comment' },
  };
  return items.map(i => options[i]);
}
