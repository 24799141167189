import * as React from 'react';

import theme from '../../../theme';
import { RowContainer } from '../../atoms';
import { IFontIconKeys } from '../../atoms/FontIcon';
import * as S from './InputEdit.styled';

export interface IProps {
  name: string;
  value: string | number;
  editIcon?: IFontIconKeys;
  saveIcon?: IFontIconKeys;
  cancelIcon?: IFontIconKeys;
  onSave: (value: any) => void;
  children?: Array<any>;
  editable?: boolean;
}

const InputEdit: React.FC<IProps> = ({
  name,
  value,
  editIcon,
  editable = true,
  saveIcon,
  cancelIcon,
  onSave,
  children,
}) => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isSavable, setIsSavable] = React.useState(true);
  const [tempValue, setTempValue] = React.useState(value);

  React.useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleEdit = () => setIsEditMode(true);
  const handleSave = () => {
    if (!isSavable) {
      return;
    }
    onSave(tempValue); // Call the onSave prop with the new value
    setIsEditMode(false);
  };
  const handleCancel = () => {
    setTempValue(value); // Reset tempValue to initial value on cancel
    setIsEditMode(false);
  };

  // Dynamically generate IDs for icons based on "name" prop
  const titleId = `${name}_title`;
  const inputId = `${name}_input`;
  const editIconId = `${name}_edit_button`;
  const saveIconId = `${name}_save_button`;
  const cancelIconId = `${name}_cancel_button`;

  const handleChange = (key: string, value: string | number, error?: string) => {
    if (!error && value && value.toString().length > 0) {
      setIsSavable(true);
      setTempValue(value);
    } else {
      setIsSavable(false);
    }
  };

  // Extract or use default components
  const TitleTextElement = children?.find(child => child.props.id === titleId) || (
    <S.TitleText>{tempValue}</S.TitleText>
  );
  const InputComponent = children?.find(child => child.props.id === inputId) || (
    <S.DefaultInput
      type="text"
      width="400px"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleSave();
        }
        if (e.key === 'Escape') {
          handleCancel();
        }
      }}
      value={tempValue}
      onChange={handleChange}
    />
  );
  const EditIcon = children?.find(child => child.props.id === editIconId) || (
    <S.Icon enabled={true} name={editIcon ? editIcon : 'Edit'} id={editIconId} onClick={handleEdit} />
  );
  const SaveIcon = children?.find(child => child.props.id === saveIconId) || (
    <S.Icon
      color={theme.colors.white}
      backgroundColor={theme.colors.green1}
      backgroundColorDisabled={theme.colors.green2}
      enabled={isSavable}
      cursor={isSavable ? 'pointer' : 'not-allowed'}
      name={saveIcon ? editIcon : 'Check'}
      id={saveIconId}
      onClick={handleSave}
    />
  );
  const CancelIcon = children?.find(child => child.props.id === cancelIconId) || (
    <S.Icon
      color={theme.colors.black}
      backgroundColor={theme.colors.white}
      backgroundColorDisabled={theme.colors.white}
      enabled={true}
      name={cancelIcon ? cancelIcon : 'Close'}
      id={cancelIconId}
      onClick={handleCancel}
    />
  );

  return (
    <RowContainer flex="1" justifyContent="center" alignItems="center" minHeight="70px">
      {!isEditMode ? (
        <>
          {TitleTextElement}
          {editable && EditIcon}
        </>
      ) : (
        <>
          {InputComponent}
          {SaveIcon}
          {CancelIcon}
        </>
      )}
    </RowContainer>
  );
};

export default React.memo(InputEdit);
