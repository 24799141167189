import {
  __,
  IAttachment,
  IChannel,
  IClient,
  IContact,
  ImageGalleryObject,
  IOrder,
  IUser,
  ORDER_FILE_TYPE,
} from 'common-services';
import * as React from 'react';

import { NavigationLinks } from '../../../../atoms';
import AttachmentMessage from '../AttachmentMessage';
import * as S from './Attachments.styled';

interface IProps {
  attachments: Array<IAttachment>;
  clients: Array<IClient>;
  commentsChannel: IChannel;
  contacts: { [cId: number]: IContact };
  me: IUser;
  order: IOrder;
  orderAttachmentDownload: (myId: number, orderId: number, attachmentId: number, cb?: (data: string) => void) => void;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
}

interface IState {
  categoryFilter?: ORDER_FILE_TYPE;
}

interface IFilterItem {
  count: number;
  disabled?: boolean;
  key: ORDER_FILE_TYPE | 'all';
  label: string;
}

export default class Attachments extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    const { attachments, contacts, clients, me, order, orderAttachmentDownload, touchImage } = this.props;
    const { categoryFilter } = this.state;
    const filteredAttachments = categoryFilter ? attachments.filter(a => a.type === categoryFilter) : attachments;
    return (
      <S.AttachmentsCol>
        {this.renderFilters()}
        {filteredAttachments.map(attachment => {
          const senderName =
            attachment.userId === me.id
              ? me.name
              : contacts[attachment.userId]?.name || clients.find(c => c.userId === attachment.userId)?.name || '';
          return (
            <AttachmentMessage
              key={attachment.id + '_'}
              attachment={attachment}
              senderName={senderName}
              me={me}
              order={order}
              orderAttachmentDownload={orderAttachmentDownload}
              touchImage={touchImage}
            />
          );
        })}
      </S.AttachmentsCol>
    );
  }

  /**
   * Render filter buttons by category.
   */
  private renderFilters = () => {
    const { commentsChannel, attachments } = this.props;
    const { categoryFilter } = this.state;
    const enabledCategories: Array<IFilterItem> = [
      {
        key: ORDER_FILE_TYPE.DELIVERY_NOTE,
        label: __('Components.OrderDetails.file_type.delivery_note'),
        count: commentsChannel?.extraData?.totals?.attachments.delivery_note || 0,
        disabled: !attachments.find(a => a.type === ORDER_FILE_TYPE.DELIVERY_NOTE),
      },
      {
        key: ORDER_FILE_TYPE.INVOICE,
        label: __('Components.OrderDetails.file_type.invoice'),
        count: commentsChannel?.extraData?.totals?.attachments.invoice || 0,
        disabled: !attachments.find(a => a.type === ORDER_FILE_TYPE.INVOICE),
      },
      {
        key: ORDER_FILE_TYPE.QUALITY_CONTROL,
        label: __('Components.OrderDetails.file_type.quality_control'),
        count: commentsChannel?.extraData?.totals?.attachments.quality_control || 0,
        disabled: !attachments.find(a => a.type === ORDER_FILE_TYPE.QUALITY_CONTROL),
      },
      {
        key: ORDER_FILE_TYPE.OTHER,
        label: __('Components.OrderDetails.file_type.other'),
        count: commentsChannel?.extraData?.totals?.attachments.other || 0,
        disabled: !attachments.find(a => a.type === ORDER_FILE_TYPE.OTHER),
      },
    ];
    if (enabledCategories.length > 1)
      enabledCategories.unshift({
        key: 'all',
        label: __('Components.OrderDetails.file_type.all'),
        count: attachments.length,
      });
    return (
      <NavigationLinks
        options={enabledCategories}
        onSelectOption={this.onSelectOption}
        keySelected={categoryFilter || 'all'}
      />
    );
  };

  /**
   * On select attachment category.
   */
  private onSelectOption = (key: string, disabled?: boolean) => {
    if (key === 'all') {
      this.setState({ categoryFilter: undefined });
    } else if (!disabled) {
      this.setState({ categoryFilter: key as ORDER_FILE_TYPE });
    }
  };
}
