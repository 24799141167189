import { __, IPricelist, qs, utils } from 'common-services';
import * as React from 'react';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { getDeepLink } from '../../../services/link';
import * as S from './SmallScreen.styled';

interface IProps {
  className?: string;
}

const SmallScreen: React.FC<IProps> = ({ className }) => {
  return (
    <S.Container className={className}>
      {window.location.href.includes('/magic-orders/') ? renderMOInfo() : renderGenericInfo()}
      <S.ConsentioLogo src={IMAGES.consentioLogo} />
    </S.Container>
  );

  /**
   * Render generic info
   */
  function renderGenericInfo() {
    return (
      <>
        <S.Img src="https://media.consentio.co/image/upload/v1589440096/logos/z_hands.svg" />
        <S.Row>
          <S.Star src="https://media.consentio.co/image/upload/v1589440096/logos/z_star.svg" />
          <S.Star src="https://media.consentio.co/image/upload/v1589440096/logos/z_star.svg" />
          <S.Star src="https://media.consentio.co/image/upload/v1589440096/logos/z_star.svg" />
          <S.Star src="https://media.consentio.co/image/upload/v1589440096/logos/z_star.svg" />
          <S.Star src="https://media.consentio.co/image/upload/v1589440096/logos/z_star.svg" />
        </S.Row>
        <S.Text>{__('Components.SmallScreen.title')}</S.Text>
        <S.Text>{__('Components.SmallScreen.subtitle')}</S.Text>
        <S.CTA onClick={() => (window.location.href = getDeepLink(getPricelistUrl()))}>
          {__('Components.SmallScreen.cta')}
        </S.CTA>
      </>
    );
  }

  /**
   * Render generic info
   */
  function renderMOInfo() {
    return (
      <>
        <S.Img src={IMAGES.informativePineapple} />
        <S.Text>{__('Components.SmallScreen.MO.title')}</S.Text>
        <S.Link href={window.location.href}>{window.location.hostname}</S.Link>
      </>
    );
  }

  /**
   * return the pricelist url
   */
  function getPricelistUrl() {
    const params = {
      utm_source: 'consentio',
      utm_campaign: 'pricelist',
    };
    const queryString = qs.stringify(params);
    return `${config.WEB_URL}${queryString}`;
  }
};

export default SmallScreen;
