import { CHANNEL_TYPE, chatActions, contactActions, orderActions, productService, qs } from 'common-services';
import { CHANNEL_SECTIONS, channelSectionToPath, REGISTER_STEPS, ROUTE_PATHS } from '../constants';
import { IReduxState } from '../reducers';
import getPath from '../util/routes';

declare var global: IGlobalWeb;

export interface IChatGroupsExpanded {
  private: boolean;
  group: boolean;
  public: boolean;
  buySellGroup: boolean;
  clients: boolean;
  workspace: boolean;
  general: boolean;
}

export enum NavTypes {
  CHAT_GROUPS_EXPANDED_SET = 'CHAT_GROUPS_EXPANDED_SET',
  LAST_CHANNEL_SET = 'LAST_CHANNEL_SET',
  NAV_AUTH = 'NAV_AUTH',
  NAV_CHANNEL = 'NAV_CHANNEL',
  NAV_MINI_CHANNEL = 'NAV_MINI_CHANNEL',
  NAV_RESET_AUTH = 'NAV_RESET_AUTH',
  NAV_RESET_CHANNEL = 'NAV_RESET_CHANNEL',
  WORKSPACE_SELECT = 'WORKSPACE_SELECT',
  FILTER_BUYER_PRODUCTS = 'FILTER_BUYER_PRODUCTS',
}

interface NavChannelAction {
  type: NavTypes.NAV_CHANNEL;
  channelId: string;
  section?: CHANNEL_SECTIONS;
  sectionId?: number;
}

interface NavMiniChannelAction {
  type: NavTypes.NAV_MINI_CHANNEL;
  channelId: string;
}
interface NavLastChannelSetAction {
  type: NavTypes.LAST_CHANNEL_SET;
  channelId: string;
}
interface ChatGroupsExpandedSet {
  type: NavTypes.CHAT_GROUPS_EXPANDED_SET;
  expanded: IChatGroupsExpanded;
}
interface NavResetChannelAction {
  type: NavTypes.NAV_RESET_CHANNEL;
  channelId: string;
}
interface NavAuthAction {
  type: NavTypes.NAV_AUTH;
  step: REGISTER_STEPS;
}
interface NavResetAuthAction {
  type: NavTypes.NAV_RESET_AUTH;
}
interface NavWorkspaceSelectAction {
  type: NavTypes.WORKSPACE_SELECT;
  workspaceId: number;
  workspaceType: IWorkspaceType;
}
interface NavFilterBuyerProductsAction {
  type: NavTypes.FILTER_BUYER_PRODUCTS;
  workspaceId: number;
  filters: ISearch;
}

/**
 * Navigate for a particular channel to a channel section (in the chat's third column). See [[CHANNEL_SECTIONS]].
 */
export function navChannelAction(channelId: string, section?: CHANNEL_SECTIONS, sectionId?: number): NavChannelAction {
  return {
    type: NavTypes.NAV_CHANNEL,
    channelId,
    section,
    sectionId,
  };
}

/**
 * Open a particular channel to a mini chat.
 */
export function navMiniChannelAction(channelId: string): NavMiniChannelAction {
  return {
    type: NavTypes.NAV_MINI_CHANNEL,
    channelId,
  };
}

export function navLastChannelSet(channelId: string): NavLastChannelSetAction {
  return {
    type: NavTypes.LAST_CHANNEL_SET,
    channelId,
  };
}

export function chatGroupsExpandedSet(expanded: IChatGroupsExpanded): ChatGroupsExpandedSet {
  global.localStorage.setItem('chatGroupsExpanded', JSON.stringify(expanded));
  return {
    type: NavTypes.CHAT_GROUPS_EXPANDED_SET,
    expanded,
  };
}

/**
 * Reset the navigation of the chat's third column for a particular channel
 */
export function navResetChannelAction(channelId: string): NavResetChannelAction {
  return {
    type: NavTypes.NAV_RESET_CHANNEL,
    channelId,
  };
}

/**
 * Navigate to a particular auth step, See [[REGISTER_STEPS]].
 */
export function navAuthAction(step: REGISTER_STEPS): NavAuthAction {
  return {
    type: NavTypes.NAV_AUTH,
    step,
  };
}

/**
 * Reset auth navigation step.
 */
export function navResetAuthAction(): NavResetAuthAction {
  return {
    type: NavTypes.NAV_RESET_AUTH,
  };
}

/**
 * Saves last buyer workspace selected
 */
export function workspaceBuyerSelect(workspaceId: number): NavWorkspaceSelectAction {
  return {
    type: NavTypes.WORKSPACE_SELECT,
    workspaceId,
    workspaceType: 'buyer',
  };
}

/**
 * Saves last seller workspace selected
 */
export function workspaceSellerSelect(workspaceId: number): NavWorkspaceSelectAction {
  return {
    type: NavTypes.WORKSPACE_SELECT,
    workspaceId,
    workspaceType: 'seller',
  };
}

/**
 * Saves last seller workspace selected
 */
export function setFilterBuyerProducts(workspaceId: number, filters: ISearch): NavFilterBuyerProductsAction {
  return {
    type: NavTypes.FILTER_BUYER_PRODUCTS,
    workspaceId,
    filters,
  };
}

/**
 * Navigation to channel sections by section constant
 */
export function navigateChannelBySection(
  section: CHANNEL_SECTIONS,
  channelId: string,
  isContact: boolean,
  cb: (path: string) => void,
) {
  return (dispatch: any, getState: () => IReduxState) => {
    dispatch(navChannelAction(channelId, section));
    cb(getPath({ path: channelSectionToPath(section, isContact) as any, channelId }));
  };
}

/**
 * Navigation to channel sections by url path.
 */
export function navigateChannelByPath(path: ROUTE_PATHS, channelId: string, cb: (path: string) => void) {
  return async (dispatch: any, getState: () => IReduxState) => {
    const { nav, chat } = getState();
    const section = nav.channels[channelId] && nav.channels[channelId].section;
    cb(
      getPath({
        path: (section
          ? channelSectionToPath(section, chat.channels[channelId].type === CHANNEL_TYPE.PRIVATE)
          : path) as any,
        channelId,
      }),
    );
  };
}

/**
 * Navigation to showroom with a product opened
 */
export function navigateToProduct(
  ownerId: number,
  productId: number,
  productHash: string,
  channelId?: string,
  cb?: (path: string) => void,
) {
  return async (dispatch: any, getState: () => IReduxState) => {
    dispatch(navigateToShowroom(ownerId, undefined, productId, productHash, channelId, cb));
  };
}

/**
 * Navigation to showroom
 */
export function navigateToShowroom(
  ownerId: number,
  query?: string,
  productId?: number,
  productHash?: string,
  channelId?: string,
  cb?: (path: string) => void,
) {
  return async (dispatch: any, getState: () => IReduxState) => {
    const {
      user: { user },
      chat: { channels },
      contact: { inConsentio },
    } = getState();
    const amSeller = ownerId === user.id;
    const section = amSeller ? CHANNEL_SECTIONS.SHOWROOM_SELL : CHANNEL_SECTIONS.SHOWROOM_BUY;
    if (channelId) {
      dispatch(navChannelAction(channelId, section));
      return cb(
        getPath({
          path: channelSectionToPath(section, true) as any,
          channelId,
          productHash,
        }) + qs.stringify({ query }),
      );
    }
    if (amSeller) {
      if (productId) {
        return cb(getPath({ path: ROUTE_PATHS.PRODUCTS, productId: '' + productId }));
      }
      return cb(getPath({ path: ROUTE_PATHS.PRODUCTS }) + qs.stringify({ query }));
    }
    const channel = Object.values(channels).find(
      c => c.type === CHANNEL_TYPE.PRIVATE && c.members?.[0]?.id === ownerId,
    );
    if (channel) {
      dispatch(navChannelAction(channel.id, section));
      return cb(
        getPath({
          path: channelSectionToPath(section, true) as any,
          channelId: channel.id,
          productHash,
        }) + qs.stringify({ query }),
      );
    }
    const contact = inConsentio[ownerId];
    if (contact) {
      return dispatch(
        chatActions.createChannel(
          user.id,
          [ownerId],
          contact.name,
          contact.avatar,
          CHANNEL_TYPE.PRIVATE,
          (c: IChannel) => {
            dispatch(navChannelAction(c.id, section));
          },
          true,
        ),
      );
    }
    dispatch(
      contactActions.createNewContact(user.id, ownerId, (err?: Error) => {
        if (err) {
          return console.log(err);
        }
        const c = getState().contact.inConsentio[ownerId];
        chatActions.createChannel(
          user.id,
          [ownerId],
          c.name,
          c.avatar,
          CHANNEL_TYPE.PRIVATE,
          (ch: IChannel) => {
            dispatch(navChannelAction(ch.id, section));
          },
          true,
        );
      }),
    );
  };
}

/**
 * Navigate to showroom buy and add a product to the cart
 */
export function navigateToShowroomBuy(
  contact: IContact,
  cb: (path: string) => void,
  priceMode: IPriceMode,
  item?: IOrderItem,
) {
  return async (dispatch: any, getState: () => IReduxState) => {
    if (!contact) return;
    const {
      chat: { channels },
      user: { user },
    } = getState();

    const channelToShow = Object.values(channels).find(
      chan => chan.type === 'private' && chan.members.find(cm => cm.id === contact.id),
    );

    const addItemToCart = (orderItem?: IOrderItem) => {
      if (orderItem) {
        const cartKey = productService.getKey(contact.id, user.id);
        dispatch(orderActions.cartClean(cartKey));
        dispatch(orderActions.cartUpdateItem(cartKey, contact.id, orderItem, false, priceMode));
      }
    };

    if (channelToShow) {
      addItemToCart(item);
      dispatch(
        navigateChannelByPath(item ? ROUTE_PATHS.CONTACT_CART_BUY : ROUTE_PATHS.CONTACT_BUY, channelToShow.id, cb),
      );
    } else {
      dispatch(
        chatActions.createChannel(
          user.id,
          [contact.id],
          contact.name,
          contact.avatar,
          CHANNEL_TYPE.PRIVATE,
          (c: IChannel) => {
            addItemToCart(item);
            dispatch(navigateChannelByPath(item ? ROUTE_PATHS.CONTACT_CART_BUY : ROUTE_PATHS.CONTACT_BUY, c.id, cb));
          },
          true,
        ),
      );
    }
  };
}

export type Action =
  | ChatGroupsExpandedSet
  | NavChannelAction
  | NavMiniChannelAction
  | NavResetChannelAction
  | NavAuthAction
  | NavResetAuthAction
  | NavLastChannelSetAction
  | NavWorkspaceSelectAction
  | NavFilterBuyerProductsAction
  | IResetAction;
