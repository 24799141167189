import { __, constants, CURRENCY_CODES, modalActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, ColumnContainer, Select, SimpleDropdown } from '../../../../atoms';
import { FormSection, InputWithLabel } from '../../../../molecules';
import * as S from './CurrencyPreferences.styled';
interface IProps {
  catalog: IWorkspace;
  editMode: boolean;
  errors: Map<string, string | (() => JSX.Element)>;
  sectionRef: React.RefObject<HTMLDivElement>;
  setError: (key: string, value: string) => void;
  updateCatalog: (catalog: IWorkspace) => void;
}
const CurrencyPreferences: React.FC<IProps> = ({ catalog, editMode, errors, setError, sectionRef, updateCatalog }) => {
  const dispatch = useDispatch();
  const { defaultCurrency, currencyConversions } = catalog;
  const additionalCurrencies = Object.values(constants.CURRENCIES)
    .map(l => ({ value: l.code + ' - ' + l.name(), key: l.code }))
    .filter(c => c.key !== defaultCurrency && currencyConversions[c.key] === undefined);
  return (
    <FormSection sectionRef={sectionRef} id="currency" title={__('WorkspaceSettings.Menu.Currency.title')}>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.default_currency.label')}
        description={__('Components.Settings.default_currency.description')}
        disabled={!editMode}
      >
        <Select
          name="default_currency"
          value={catalog.defaultCurrency}
          options={Object.values(constants.CURRENCIES).map(l => ({
            value: l.code,
            label: l.code + ' - ' + l.name(),
          }))}
          onChange={(n, v) => onChangeDefaultCurrency(v)}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
          hasError={!!errors.get('default_currency')}
        />
      </InputWithLabel>
      {Object.keys(currencyConversions).length > 0 || editMode ? (
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.secondary_currencies.label')}
          description={__('Components.Settings.secondary_currencies.description', {
            defaultCurrency: catalog.defaultCurrency,
          })}
          disabled={!editMode}
          asColumn={!!Object.keys(currencyConversions).length}
        >
          {additionalCurrencies.length > 0 && editMode ? (
            <SimpleDropdown
              hAlign="left"
              vAlign="flex-start"
              margin="0"
              onSelect={(v: string) => {
                setError('conversion_' + v, 'empty');
                updateCatalog({ ...catalog, currencyConversions: { ...currencyConversions, [v]: 0 } });
              }}
              position="bottom"
              options={additionalCurrencies}
            >
              <Button type="link" iconName="Add-more" iconSize="18px" withoutPadding>
                {__('Components.Settings.secondary_currencies.add')}
              </Button>
            </SimpleDropdown>
          ) : null}
          {Object.keys(currencyConversions).map((k, i) => (
            <React.Fragment key={'conversion_' + k}>
              <S.ExchangeRate>
                <S.ExchangeRatePrefix>
                  <S.Text>1 {defaultCurrency} = </S.Text>
                </S.ExchangeRatePrefix>
                <S.ExchangeRateMain>
                  <S.Input
                    name={'conversion_' + k}
                    value={currencyConversions[k].toString()}
                    isRequired={true}
                    precision={4}
                    textAlign={'right'}
                    disabled={!editMode}
                    minValue={0.001}
                    type="number"
                    onBlur={(n, v) => {
                      setError(n, v ? '' : 'empty');
                      updateCatalog({
                        ...catalog,
                        currencyConversions: { ...currencyConversions, [k]: Number(v) },
                      });
                    }}
                    hasError={currencyConversions[k] === 0 || !!errors.get('conversion_' + k)}
                  />
                </S.ExchangeRateMain>
                <S.ExchangeRateSuffix>
                  <S.Text>{k}</S.Text>
                </S.ExchangeRateSuffix>
                {editMode ? <S.ExchangeRateDelete onClick={() => onRemoveSecondaryCurrency(k)} name="Trash" /> : null}
              </S.ExchangeRate>
              {i < Object.keys(currencyConversions).length - 1 ? <S.HorizontalDivider /> : null}
            </React.Fragment>
          ))}
        </InputWithLabel>
      ) : null}
    </FormSection>
  );

  // onChangeDefaultCurrency manage the main currency change
  // A modal will be show to the user in order to let them understand the consequences
  // Side effects:
  // - all exchange_rates will be set as 0 (and invalid to force user to fill them)
  // - if we had exchange rate to the currency that now it's main, remove exchange rate and add inverse one
  //
  // TODO: icon-image must change before deploy
  function onChangeDefaultCurrency(v: string): void {
    if (v !== defaultCurrency) {
      let body: string = __('Components.Settings.default_currency.modal.description', { currency: v });
      if (Object.keys(currencyConversions).length > 0) {
        body += '\n\n' + __('Components.Settings.default_currency.modal.description_exchange');
      }
      dispatch(
        modalActions.modalOpen(
          __('Components.Settings.default_currency.modal.title'),
          () => {
            const conversions = {};
            Object.keys(currencyConversions).forEach(k => {
              conversions[k !== v ? k : defaultCurrency] = 0;
              setError('conversion_' + (k !== v ? k : defaultCurrency), 'empty');
            });
            updateCatalog({
              ...catalog,
              defaultCurrency: v as CURRENCY_CODES,
              currencyConversions: conversions,
            });
            dispatch(modalActions.modalClose());
          },
          {
            text2: body,
            buttonText: __('Components.Settings.default_currency.modal.cta'),
            showCancelButton: true,
            buttonCancelText: __('Components.Settings.default_currency.modal.cancel'),
            closeable: false,
            icon: constants.MEDIA_URL + '/f_auto/v1591707232/ilustrations/Currency-change.png',
          },
          'nice',
        ),
      );
    }
  }
  // onRemoveSecondaryCurrency remove exchange rate from user configuration
  // TODO: should raise error if we have still contacts with this currency or set those to main_currency?
  function onRemoveSecondaryCurrency(currency: string): void {
    dispatch(
      modalActions.modalOpen(
        __('Components.Settings.remove_secondary_currency_modal.title', { currency }),
        () => {
          const conversions = { ...catalog.currencyConversions };
          delete conversions[currency];
          setError('conversion_' + currency, '');
          updateCatalog({ ...catalog, currencyConversions: conversions });
          dispatch(modalActions.modalClose());
        },
        {
          text2: __('Components.Settings.remove_secondary_currency_modal.body', {
            currency,
            defaultCurrency,
          }),
          buttonText: __('Components.Settings.default_currency.modal.cta'),
          showCancelButton: true,
          buttonCancelText: __('Components.Settings.default_currency.modal.cancel'),
          closeable: false,
          icon: constants.MEDIA_URL + '/f_auto/v1591703444/ilustrations/Currency-remove.png',
        },
        'nice',
      ),
    );
  }
};

export default React.memo(CurrencyPreferences);
