import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel, Tooltip as TooltipAtom } from '../../atoms';

interface IStyledProps {
  color?: string;
  isDragActive?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  paddingBottom?: string;
  selected?: boolean;
  showImportJobs?: boolean;
  theme: ITheme;
  width?: string;
}

export const FormContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: width 0.8s ease;
  ${(p: IStyledProps) =>
    p.paddingBottom &&
    css`
      padding-bottom: ${p.paddingBottom};
    `}
`;

export const Text = styled(TextLabel)``;

export const TextCenter = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const TextLight = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextSubtitleEmail = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextSubtitleWithMargin = styled(TextSubtitle)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextUpload = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextUploadGreen = styled(TextUpload)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextEmail = styled(TextLabel)`
  display: flex;
  flex-direction: row;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  align-items: center;
`;

export const OrderFormatRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  min-height: min-content;
  flex-wrap: wrap;
`;

export const OrderFormatContainer = styled(ColumnContainer)`
  position: relative;
  align-items: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  min-height: min-content;
`;

export const OrderFormatSelectedContainer = styled(RowContainer)`
  align-items: center;
  min-height: min-content;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const OrderFormatSelectedRight = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  justify-content: center;
  height: 100%;
`;

export const OrderFormatTitle = styled(TextLight)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TextLightGrey = styled(TextLight)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const OrderFormatSubTitle = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const OrderFormatSquare = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  min-height: min-content;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  overflow: hidden;
  position: relative;

  ${(p: IStyledProps) =>
    !p.isSelected &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    `}
`; // original opacity value was 0.75 so image flicks on hover, when upgrading tootlip, change it back

export const OverlayGrey = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.light1.replace('1)', '0.5)')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const OrderFormatLogo = styled.img`
  object-fit: cover;
  width: 80px;
  height: 80px;
`;

export const CheckIconWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 0 3px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: 1px;
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  background-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.light2 : p.theme.colors.white)};
  width: 450px;
  height: auto;
  min-height: min-content;
  &:focus {
    outline: none;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 450px;
  }
`;

export const SelectButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const ButtonUpload = styled(Button)``;

export const UploadOrdersContainer = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  min-height: min-content;
  max-width: 650px;
`;
export const CustomContainer = styled(UploadOrdersContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextAreaContainer = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.5)')};
  width: 570px;
  height: 100%;
  justify-content: space-between;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  min-height: min-content;
  &:focus-within {
    border-color: ${(p: IStyledProps) => p.theme.colors.green1};

    .text-area-footer {
      border-top-color: ${(p: IStyledProps) => p.theme.colors.green1};
    }
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 450px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  line-height: 1.2;
  resize: none;
  border: none;
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  &:focus-within {
    outline: none !important;
  }
`;

export const TextAreaFooter = styled(RowContainer)`
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.5)')};
  padding: 4px ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  justify-content: flex-end;
  min-height: min-content;
`;

export const ButtonTab = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-width: auto;
  height: auto;
  background-color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.grey4 : p.theme.colors.white)};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
`;
export const ButtonLink = styled(Button)`
  padding: 0;
  height: 30px;
`;

export const UploadFileIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TooltipIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const Tooltip = styled(TooltipAtom)`
  margin-left: 5px;
  padding-bottom: 4px;
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: 16.5px;
`;

export const ButtonCopy = styled(Button)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const InfoMailIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding: 1.5px;
  height: 19px;
  width: 19px;
  border-radius: 3px;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
export const FileContainer = styled(RowContainer)`
  width: fit-content;
`;

export const RequestInfo = styled(Text)`
  display: flex;
  flex-direction: row;
`;

export const ButtonHow = styled(Button)`
  box-shadow: ${p => p.theme.boxShadow};
  align-self: flex-end;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 58px;
  position: fixed;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TextColor = styled(Text)`
  color: ${(p: IStyledProps) => p.color};
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  &:hover {
    opacity: 0.8;
  }
`;
