import {
  __,
  broadcastActions,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_MEMBER_STATUS,
  CHANNEL_TYPE,
  chatActions,
  contactService,
  EventTrack,
  IChannel,
  IContact,
  imageActions,
  INVITE_ORIGIN,
  INVITE_VIA,
  IUser,
  modalActions,
  RenderTrack,
} from 'common-services';
import * as React from 'react';

import SelectContacts from '../../../screens/select-contacts';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import { deviceIsIpad } from '../../../util/utils';
import { Input, Picture } from '../../atoms';
import { ActionsModal, InputWithLabel, InviteAddressbookModal } from '../../molecules';
import * as S from './ChatCreate.styled';

export interface OwnProps {
  mode: 'group' | 'broadcast';
  name?: string;
  onCreate: (channel: IChannel | IBroadcast) => void;
  onClose: () => void;
}

export interface StateProps {
  me: IUser;
  contacts: { [contactId: number]: IContact };
  channels: { [key: string]: IChannel };
  broadcasts: { [key: string]: IBroadcast };
  role: CHANNEL_MEMBER_ROLE;
}

export interface DispatchProps {
  addMembers: typeof chatActions.addMembers;
  channelLeave: typeof chatActions.channelLeave;
  channelMute: typeof chatActions.channelMute;
  createChannel: typeof chatActions.createChannel;
  deleteMembers: typeof chatActions.deleteMembers;
  hideLoading: typeof modalActions.hideLoading;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  showLoading: typeof modalActions.showLoading;
  touchImage: typeof modalActions.touchImage;
  updateChannel: typeof chatActions.updateChannel;
  addBroadcastMembers: typeof broadcastActions.addMembers;
  createBroadcast: typeof broadcastActions.createBroadcast;
  deleteBroadcastMembers: typeof broadcastActions.deleteMembers;
  updateBroadcast: typeof broadcastActions.updateBroadcast;
  getMembers: typeof broadcastActions.getMembers;
}

export type IProps = OwnProps & StateProps & DispatchProps;

interface IState {
  data?: IChannel | IBroadcast;
  name: string;
  imageUrl: string;
  errors: Map<string, string>;
  step: number;
}

export default class ChatCreate extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      name: props.name || '',
      imageUrl: '',
      errors: new Map(),
      step: 1,
    };
  }

  public componentDidMount() {
    RenderTrack.track(this.props.mode === 'broadcast' ? 'BroadcastCreate' : 'ChatGroupCreate', { renderTime: this.t });
  }

  public render() {
    const { mode } = this.props;
    const { step } = this.state;
    switch (step) {
      case 2:
        if (mode === 'group') {
          return this.renderInviteModal();
        }
      case 3:
        return this.renderAddContacts();
      default:
        return this.renderGeneralSection();
    }
  }

  /**
   * reander the general section
   */
  private renderGeneralSection = () => {
    const { createBroadcast, createChannel, me, mode, onClose, updateChannel, updateBroadcast } = this.props;
    const { data, name, imageUrl, errors, step } = this.state;
    return (
      <ActionsModal
        onClose={onClose}
        title={
          mode === 'broadcast'
            ? __('BroadcastCreate.title')
            : name
            ? __('ChatGroupCreate.title_community')
            : __('ChatGroupCreate.title')
        }
        subtitle={mode === 'broadcast' ? __('BroadcastCreate.subtitle') : __('ChatGroupCreate.subtitle')}
        step={step}
        stepsNumber={mode === 'group' ? 3 : 2}
      >
        <S.RowGeneral>
          {mode === 'group' ? (
            <S.LettersAvatarContainer>
              <Picture
                editable={true}
                imageUrl={imageUrl}
                onDelete={this.onPicturedDelete}
                onFileChange={this.onPictureChange}
                pictureMode={true}
                picturePlaceholder={__('Components.Picture.placeholder.group')}
                relation={1}
                size="big"
                withCrop={deviceIsIpad() ? false : true}
              />
            </S.LettersAvatarContainer>
          ) : null}
          <InputWithLabel
            isRequired={true}
            label={mode === 'broadcast' ? __('BroadcastCreate.name_label') : __('Channel.name_label')}
          >
            <Input
              value={name}
              onChange={(n, value) => {
                this.setError('name', value ? '' : 'empty');
                this.setState({ name: (value as string) || '' });
              }}
              onBlur={(key: string, value: string | number) => {
                this.setState({ name: (value as string) || '' });
              }}
              hasError={!!errors.get('name')}
              maxLength={64}
              placeholder={
                mode === 'broadcast' ? __('BroadcastCreate.name_placeholder') : __('Channel.name_placeholder')
              }
              width="350px"
            />
          </InputWithLabel>
        </S.RowGeneral>
        <S.Buttons>
          <S.CTA
            type="principal"
            disabled={!name}
            id="channel-info-cta-create"
            onClick={() => {
              if (data?.id) {
                mode === 'broadcast'
                  ? updateBroadcast(me.id!, data.id, name)
                  : updateChannel(me.id!, data.id, { name, imageUrl });
                this.setState({ step: 2 });
              } else {
                mode === 'broadcast'
                  ? createBroadcast(me.id, name, [], c => this.setState({ data: c, step: 2 }))
                  : createChannel(
                      me.id,
                      [],
                      name,
                      imageUrl,
                      CHANNEL_TYPE.GROUP,
                      c => this.setState({ data: c, step: 2 }),
                      true,
                    );
              }
            }}
          >
            {mode === 'broadcast' ? __('Components.Channel.create_broadcast') : __('Components.Channel.create')}
          </S.CTA>
        </S.Buttons>
      </ActionsModal>
    );
  };
  private renderInviteModal = () => {
    const { me } = this.props;
    const { data } = this.state;
    return (
      <InviteAddressbookModal
        addressbookSubtitle={__('Channel.invite_modal.addresbook.subtitle')}
        addressbookTitle={__('Channel.invite_modal.addresbook.title')}
        changeStep={this.changeStep}
        channelId={data.id}
        defaultInviteBy={INVITE_VIA.PHONEBOOK}
        from="chat-group-create"
        me={me}
        onClose={this.onCreate}
        origin={INVITE_ORIGIN.GROUP}
        skippable={true}
        step={2}
        stepsNumber={3}
      />
    );
  };

  private changeStep = (step: number, contacts?: Array<IContact>) => {
    const { mode } = this.props;
    const { data } = this.state;
    this.setState({
      step,
      data: {
        ...data,
        members:
          mode === 'broadcast'
            ? ({
                ...data.members,
                ...(contacts?.reduce((aux, c) => {
                  aux[c.id] = contactService.contactToMember;
                  return aux;
                }, {}) || {}),
              } as any)
            : [...(data.members as Array<IMember>), ...(contacts?.map(contactService.contactToMember) || [])],
      },
    });
    if (!contacts?.length) {
      EventTrack.track(mode === 'broadcast' ? 'broadcast_create_skip' : 'group_create_skip', {
        channel_id: data?.id,
        step: 'invite-agenda',
      });
    }
  };

  private onCreate = () => {
    const { onCreate } = this.props;
    const { data } = this.state;
    onCreate(data);
  };

  /**
   * Render modal for add members
   */
  private renderAddContacts = (): JSX.Element => {
    const { addMembers, addBroadcastMembers, mode, contacts, me, onCreate } = this.props;
    const { imageUrl, data } = this.state;
    const contactAddedIds =
      mode === 'broadcast'
        ? Object.keys(data.members).map(i => Number(i))
        : (data.members as Array<IMember>).map(m => m.id);
    const contactsNotAdded = Object.values(contacts).filter(
      c => !contactAddedIds.includes(c.id) && c.isBuyer && c.id !== me.id!,
    );
    return (
      <SelectContacts
        onClose={() => {
          onCreate(data);
          EventTrack.track(mode === 'broadcast' ? 'broadcast_create_skip' : 'group_create_skip', {
            channel_id: data?.id,
            step: 'add-consentio-contacts',
          });
        }}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          mode === 'broadcast'
            ? addBroadcastMembers(me.id, data.id, contactsToAdd)
            : addMembers(
                me.id,
                data.id,
                imageUrl,
                contactsToAdd.map(mId => ({
                  id: mId,
                  companyName: contacts[mId].companyName,
                  name: contacts[mId].name,
                  role: CHANNEL_MEMBER_ROLE.MEMBER,
                  status: CHANNEL_MEMBER_STATUS.ACTIVE,
                  avatarColor: contacts[mId].avatarColor,
                })),
                { from: 'chat-group-create' },
              );
          onCreate(data);
        }}
        origin={INVITE_ORIGIN.GROUP}
        showUnregistered={true}
        title={
          mode === 'broadcast' ? __('BroadcastCreate.title_consentio') : __('Channel.invite_modal.title_consentio')
        }
        step={mode === 'group' ? 3 : 2}
        stepsNumber={mode === 'group' ? 3 : 2}
        changeStep={s => this.setState({ step: s })}
        trackingFrom="chat-create"
      />
    );
  };

  /**
   * Set error for a particular field represented by key
   */
  private setError = (key: string, error?: string) => {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    if (error) {
      cloneErrors.set(key, error);
    } else {
      cloneErrors.delete(key);
    }
    this.setState({ errors: cloneErrors });
  };

  /**
   * On picture change:
   * * Upload picture
   * * Update in product state
   */
  private onPictureChange = (file: File) => {
    const { mediaUpload } = this.props;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = event =>
      logError(new Error('File could not be read! Code ' + (event.target as any).error.code), 'upload.image');
    reader.onload = () =>
      reader.result &&
      resizeImage(reader.result as string, (imageResized: string) =>
        mediaUpload({ ...file, name: file.name, content: imageResized }, result => {
          this.setState({ imageUrl: result ? result.secure_url : undefined });
        }),
      );
  };

  /**
   * On delete picture with confirmation modal
   */
  private onPicturedDelete = (): void => {
    const { modalOpen, modalClose } = this.props;
    modalOpen(
      __('Components.ProductDetails.confirm_remove_picture'),
      () => {
        this.setState({ imageUrl: undefined });
        modalClose();
      },
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  };
}
