import {
  __,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  contactActions,
  EventTrack,
  imageActions,
  INVITE_ORIGIN,
  modalActions,
  notificationsActions,
  RenderTrack,
  throttle,
  utils,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ACCEPT_ANIMATED_IMAGES, ADMIN_OWNER_ROLES, MAX_PICTURES_PER_POST, ROUTE_PATHS } from '../../../constants';
import { convertToIFile, convertURLToFile } from '../../../services/file';
import share from '../../../services/sharer';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/hooks';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { IOSLoading, LettersAvatar, Picture } from '../../atoms';
import { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import {
  ChannelModal,
  ChatPublicHeader,
  EmptyListResource,
  ForwardMessage,
  InviteLinkModal,
  PostMessage,
  PostWriteModal,
} from '../../molecules';
import * as CIS from '../ChatPublicInfo/ChatPublicInfo.styled';
import * as S from './ChatPublic.styled';

export type IRouteProps = RouteComponentProps<{ channelId?: string }>;
export interface IStateProps {
  channel: IChannel;
  channels: { [id: string]: IChannel };
  contacts: { [contactId: number]: IContact };
  initialized: boolean;
  lastMessageAt: Record<string, number>;
  lastReadContactAt: number;
  isAll?: boolean;
  isModal?: boolean;
  me: IUser;
  messages: Array<IMessage>;
  unreadMessageCount: number;
}

export interface IDispatchProps {
  addMembers: typeof chatActions.addMembers;
  contactsInvite: typeof contactActions.contactsInvite;
  deleteMessage: typeof chatActions.deleteMessage;
  getMessages: typeof chatActions.getMessages;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navMiniChannelAction: typeof navActions.navMiniChannelAction;
  notificationShow: typeof notificationsActions.notificationShow;
  openChannel: typeof chatActions.openChannel;
  sendMessage: typeof chatActions.sendMessage;
  setActiveChannel: typeof chatActions.setActiveChannel;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = IStateProps &
  IDispatchProps &
  IRouteProps & { onClose?: () => void; onMinimize?: () => void; styleModal?: React.CSSProperties };

interface IState {
  imageChannelPreview?: IPreviewFile;
  imageCropIndex?: number;
  imagePreviews?: Array<IImagePreview>;
  inviteLink: string;
  isAll: boolean;
  isLoading: boolean;
  minimized: boolean;
  pictureFilesOriginal?: Array<File>;
  postToEdit?: IMessage;
  postToShare?: IMessage;
  showPostLinkModal: boolean;
  showSendLinkModal: boolean;
  showWritePost?: 'post' | 'poll';
}

/**
 * Public channel with posts
 */
class ChatPublic extends React.PureComponent<IProps, IState> {
  private t: number;
  private channelOpened: boolean;
  private pictureRef: React.RefObject<Picture>;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.pictureRef = React.createRef();
    this.state = {
      inviteLink: '',
      isAll: false,
      isLoading: true,
      minimized: false,
      showPostLinkModal: false,
      showSendLinkModal: false,
    };
  }

  public componentDidMount() {
    const { channel, initialized, openChannel, me } = this.props;

    if (channel && initialized) {
      RenderTrack.track('ChatPublic', {
        renderTime: this.t,
        channelId: channel.id,
      });
      openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
      this.channelOpened = true;
    }
  }

  public componentWillUnmount() {
    const { channel, initialized, me, setActiveChannel } = this.props;
    if (channel && initialized) {
      setActiveChannel(me.id);
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { channel, initialized, me, openChannel, setActiveChannel } = this.props;
    const { minimized } = this.state;
    if (minimized !== prevState.minimized) {
      if (minimized) {
        setActiveChannel(me.id);
      } else {
        openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
        this.channelOpened = true;
      }
    }
    if (channel && initialized && (!this.channelOpened || !prevProps.channel || prevProps.channel.id !== channel.id)) {
      this.setState({ isLoading: true });
      RenderTrack.track('ChatPublic', {
        renderTime: this.t,
        channelId: channel.id,
      });
      openChannel(me.id, channel.id, () => this.setState({ isLoading: false }));
      this.channelOpened = true;
      this.setState({ isAll: false });
    }

    if (channel && prevProps.channel?.id !== channel.id) {
      this.scrollToTop();
    }
  }

  public render() {
    const { channel, isModal, navMiniChannelAction, onClose, onMinimize, styleModal, unreadMessageCount } = this.props;
    const { minimized } = this.state;
    if (!channel) return null;
    return isModal ? (
      <ChannelModal
        style={styleModal}
        title={channel?.name}
        type={channel.type}
        minimized={minimized}
        onMinimize={() => {
          if (onMinimize) {
            onMinimize();
          }
          this.setState({ minimized: !minimized });
        }}
        onClose={() => (onClose ? onClose() : navMiniChannelAction(''))}
        unreadMessageCount={unreadMessageCount}
      >
        {this.renderContent()}
      </ChannelModal>
    ) : (
      this.renderContent()
    );
  }

  /**
   * Render all page content
   */
  private renderContent = () => {
    const { channel } = this.props;
    if (!channel) return null;
    const { showPostLinkModal, showSendLinkModal, showWritePost } = this.state;
    return (
      <S.Container className="chat-public-container" onScroll={this.onScroll}>
        {this.renderHeader()}
        <S.BodyContainer className="chat-public-body-container">
          <S.ContentContainer className="chat-public-content-container">
            {this.renderWritePost()}
            {this.renderDescription()}
            {this.renderPosts()}
            {this.renderZeroCase()}
          </S.ContentContainer>
          {this.renderMembersCard()}
        </S.BodyContainer>
        {showWritePost ? this.renderWritePostModal() : null}
        {showPostLinkModal ? this.renderPostLinkModal() : null}
        {showSendLinkModal ? this.renderSendPostLinkModal() : null}
        <Picture
          accept={ACCEPT_ANIMATED_IMAGES}
          cropStrategy="contain"
          hidden={true}
          id="chat-public-file-input"
          multiple={true}
          onCropCancel={() => this.setState({ showWritePost: 'post' })}
          onCropShown={() => this.setState({ showWritePost: undefined })}
          onFileChange={(f, fromCrop?: boolean) => this.handleSelectFile([f], fromCrop ? false : true, fromCrop)}
          onFilesChange={files => (files.length ? this.handleSelectFile(files as Array<File>, false) : undefined)}
          ref={this.pictureRef}
          withCrop={false}
        />
      </S.Container>
    );
  };

  private renderHeader = () => {
    const { channel, history } = this.props;
    return (
      <ChatPublicHeader
        channel={channel}
        className="chat-public-header-container"
        history={history}
        onTabChange={this.onTabChange}
        tabSelected="messages"
      />
    );
  };

  /**
   * On tab click, performs a page change
   */
  private onTabChange = (tab: string) => {
    const { channel, history } = this.props;
    switch (tab) {
      case 'messages':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId: channel.id }));
        break;
      case 'info':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL_INFO, channelId: channel.id }));
        break;
    }
  };

  /**
   * Render members card on the right of the content
   */
  private renderMembersCard = () => {
    const { channel, contacts, me } = this.props;
    let adminOwnerMembers = channel.members.filter(m => ADMIN_OWNER_ROLES.includes(m.role));
    if (ADMIN_OWNER_ROLES.includes(channel.role)) {
      adminOwnerMembers = [...adminOwnerMembers, chatService.meAsChannelMember(channel, me)] as Array<IMember>;
    }
    if (!adminOwnerMembers.length) return null;
    return (
      <S.RightContainer className="chat-public-right-container">
        <ChannelDescriptionCard
          channelId={channel.id}
          description={channel.description}
          onTrack={(seeMore: boolean) => {
            EventTrack.track('channel_description_see_more_content', {
              channel_id: channel.id,
              type: channel.type,
              see_more: seeMore,
            });
          }}
        />
        <S.MemberCard>
          <S.TitleCard>
            {__('ChatPublic.members_title', { count: channel.numMembers }) + ' · '}
            <S.TitleCardGrey>{channel.numMembers}</S.TitleCardGrey>
          </S.TitleCard>
          <S.SubtitleCard>{__('ChatPublic.owners_admin')}</S.SubtitleCard>
          <S.CardBottom>
            {adminOwnerMembers
              .sort((m1, m2) => {
                if (m1.role === CHANNEL_MEMBER_ROLE.OWNER && m2.role !== CHANNEL_MEMBER_ROLE.OWNER) return -1;
                if (m1.role !== CHANNEL_MEMBER_ROLE.OWNER && m2.role === CHANNEL_MEMBER_ROLE.OWNER) return -1;
                return m1.name > m2.name ? 1 : -1;
              })
              .map((member: IMember, idx: number, array: Array<IMember>) => {
                const contact = contacts[member.id];
                return (
                  <S.MemberItem
                    className="chat-public-member-card-header"
                    contact={contact}
                    isLast={idx === array.length - 1}
                    key={member.id}
                    member={member}
                    subtitle={member.companyName || contact?.companyName}
                  />
                );
              })}
          </S.CardBottom>
        </S.MemberCard>
      </S.RightContainer>
    );
  };

  /**
   * Render write post card section
   */
  private renderWritePost() {
    const { channel, me } = this.props;
    // Viewers cannot write new posts
    if (channel.role === CHANNEL_MEMBER_ROLE.VIEWER) return null;
    return (
      <S.CardContainer className="chat-public-create-post">
        <S.DropZone
          imageSize={75}
          multiple={true}
          onFilesChange={(files: Array<File>) => files.length && this.handleSelectFile(files, true)}
          showText={false}
        >
          <S.CardTop>
            <LettersAvatar
              size={35}
              text={me.name}
              avatarColor={utils.getAvatarColor(me.name)}
              img={me.settings.avatar}
            />
            <S.FakePostInput
              onClick={() =>
                this.setState({
                  showWritePost: 'post',
                  postToEdit: undefined,
                  imagePreviews: undefined,
                  pictureFilesOriginal: undefined,
                })
              }
            >
              <S.TextGrey1>{__('ChatPublic.create_post')}</S.TextGrey1>
            </S.FakePostInput>
          </S.CardTop>
          <S.CardBottom>
            <S.CardBottomActions>
              <S.CardButton
                id="chat-public-post-text-button"
                iconName="Edit"
                iconSize="15px"
                onClick={() =>
                  this.setState({
                    showWritePost: 'post',
                    postToEdit: undefined,
                    imagePreviews: undefined,
                    pictureFilesOriginal: undefined,
                  })
                }
                type="skip"
                withoutPadding={true}
              >
                {__('ChatPublic.post')}
              </S.CardButton>
              <S.CardButton
                id="chat-public-write-post-image-button"
                iconName="Add-image"
                iconSize="18px"
                onClick={() => this.onAddImageClick()}
                type="skip"
                withoutPadding={true}
              >
                {__('ChatPublic.image')}
              </S.CardButton>
              <S.CardButton
                id="chat-public-write-post-poll-button"
                iconName="Poll"
                iconSize="18px"
                onClick={() =>
                  this.setState({
                    showWritePost: 'poll',
                    postToEdit: undefined,
                    imagePreviews: undefined,
                    pictureFilesOriginal: undefined,
                  })
                }
                type="skip"
                withoutPadding={true}
              >
                {__('ChatPublic.poll')}
              </S.CardButton>
            </S.CardBottomActions>
          </S.CardBottom>
        </S.DropZone>
      </S.CardContainer>
    );
  }

  /**
   * Renders description for small screens
   */
  private renderDescription = () => {
    const { channel } = this.props;
    if (!channel.description) return null;
    return (
      <CIS.CardContainer id="chat-public-channel-description">
        <CIS.TitleCard>{__('ChatPublicInfo.channel_description')}</CIS.TitleCard>
        <CIS.ContentCardText>
          {utils.splitTextUrls(channel.description).map((t, i) => {
            if (!t.text) return <br key={i + ''} />;
            if (t.isLink) {
              const { url } = utils.analyzeUrl(t.text);
              return (
                <CIS.Link
                  key={i + url}
                  href={url}
                  target="_blank"
                  onClick={() => {
                    EventTrack.track('description_link_click', {
                      channel_id: channel.id,
                      channel_type: CHANNEL_TYPE.PUBLIC,
                      link: url,
                    });
                  }}
                >
                  {url}
                </CIS.Link>
              );
            }
            return t.text;
          })}
        </CIS.ContentCardText>
      </CIS.CardContainer>
    );
  };

  /**
   * Render zero case when you are:
   *  - Admin or owner
   *  - When there is less or equal of 1 message (default admin message is always the first)
   */
  private renderZeroCase() {
    const { channel, messages } = this.props;
    const { isLoading } = this.state;
    if (!ADMIN_OWNER_ROLES.includes(channel.role) || messages.length > 1) return null;

    if (isLoading)
      return (
        <S.LoadingContainer>
          <IOSLoading loading={true} />
        </S.LoadingContainer>
      );

    return (
      <S.CardContainer className="chat-public-zero-case">
        <EmptyListResource
          text={__('ChatPublic.zero_case.title')}
          text2={__('ChatPublic.zero_case.description')}
          imageUrl={IMAGES.messages}
          imageSize="150px"
          showButton={false}
        />
      </S.CardContainer>
    );
  }

  /**
   * Loads more posts when scroll is at the bottom
   */
  private onScroll = throttle((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      !this.state.isAll &&
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 800
    ) {
      this.loadMorePosts();
    }
  }, 50);

  /**
   * Api call to get more posts
   */
  private loadMorePosts = throttle(() => {
    const { channel, getMessages, me } = this.props;
    getMessages(channel.id, me.id, false, (isAll?: boolean) => this.setState({ isAll, isLoading: false }));
  }, 250);

  /**
   * Render posts
   */
  private renderPosts() {
    const { channel, contacts, messages, me, touchImage } = this.props;
    if (!messages) return null;
    return messages.map((m, i) => {
      if (m.isRemoved) return null;
      const sender =
        m.senderId === me.id
          ? {
              avatar: me.settings.avatar,
              avatarColor: utils.getAvatarColor(me.name),
              id: me.id,
              name: me.name,
              role: channel.role,
            }
          : m.sender || channel.members.find(member => member.id === m.senderId);

      return (
        <PostMessage
          channelId={channel.id}
          channelName={channel.name}
          contact={contacts[sender?.id]}
          key={m.messageId + m.message + i}
          message={m}
          onOptionClick={this.onPostOptionClick}
          options={this.getPostOptions(m)}
          sender={sender as IMember}
          touchImage={touchImage}
        />
      );
    });
  }

  /**
   * Get options of a post.
   */
  private getPostOptions = (message: IMessage) => {
    if (message.messageType === 'admin') return [];
    // Admins can delete posts.
    // Only the author can edit their posts
    const { channel, me } = this.props;
    const amOwnerOrAdmin = ADMIN_OWNER_ROLES.includes(channel.role);
    const amAuthor = message.senderId === me.id;

    let options: Array<IItem> = [];
    if (amAuthor) options = [{ key: 'edit', value: __('ChatPublic.options.edit'), icon: 'Edit' }];
    if (amOwnerOrAdmin || channel.invitePolicy === 'any')
      options.push({ key: 'share', value: __('ChatPublic.options.share'), icon: 'Forward-web' });
    if (amAuthor || amOwnerOrAdmin)
      options.push({ key: 'delete', value: __('ChatPublic.options.delete'), icon: 'Trash', color: theme.colors.red1 });
    return options;
  };

  /**
   * On click on a post option (modify / delete)
   */
  private onPostOptionClick = (key: string, message: IMessage) => {
    const { channel, deleteMessage, me, modalOpen, modalClose } = this.props;
    if (key === 'delete') {
      modalOpen(
        __('ChatPublic.post_delete_modal.title'),
        () => {
          deleteMessage(me.id, message);
          modalClose();
        },
        {
          showCancelButton: true,
          actionType: 'dangerous',
          text2: __('ChatPublic.post_delete_modal.description'),
          buttonText: __('ChatPublic.post_delete_modal.cta'),
        },
        'nice',
      );
    } else if (key === 'edit') {
      const { extraData, messageType } = message;
      const { imageUrls } = extraData as IPost;
      this.setState({
        imagePreviews: imageUrls?.length
          ? imageUrls.map(url => ({
              imageUrl: url,
            }))
          : undefined,
        pictureFilesOriginal: undefined,
        postToEdit: message,
        showWritePost: messageType === 'poll' ? 'poll' : 'post',
      });
    } else if (key === 'share') {
      this.setState({
        postToShare: message,
        showPostLinkModal: true,
      });
      EventTrack.track('post_share_open_modal', { channel_id: channel.id, post_id: message.messageId });
    }
  };

  /**
   * Render write a post modal
   */
  private renderWritePostModal = () => {
    const { me, channel } = this.props;
    const { imagePreviews, imageCropIndex, showWritePost, postToEdit } = this.state;

    return (
      <PostWriteModal
        channel={channel}
        className="chat-public-write-post-modal"
        handleSelectFile={this.handleSelectFile}
        imagePreviewsInit={imagePreviews}
        initImageIndex={imageCropIndex || 0}
        me={me}
        mode={showWritePost}
        onAddImageClick={this.onAddImageClick}
        onClose={() =>
          this.setState({
            showWritePost: undefined,
            imagePreviews: undefined,
            pictureFilesOriginal: undefined,
            imageCropIndex: undefined,
          })
        }
        post={postToEdit}
        toShow={!!showWritePost}
      />
    );
  };

  /**
   * Renders invite modal for a post
   */
  private renderPostLinkModal = () => {
    const { me, channel } = this.props;
    const { postToShare } = this.state;
    return (
      <InviteLinkModal
        channel={channel}
        description={__('ChatPublic.post_link_invite.description')}
        inviteOrigin={INVITE_ORIGIN.PUBLIC_POST}
        me={me}
        onClose={() => this.setState({ showPostLinkModal: false })}
        onSendLink={(inviteLinkReceived: string, through: 'message' | 'whatsapp') => {
          this.setState({ showPostLinkModal: false, inviteLink: inviteLinkReceived });
          if (through === 'message') {
            this.setState({ showSendLinkModal: true });
          } else if (through === 'whatsapp') {
            share({
              sharer: 'whatsapp',
              url: inviteLinkReceived,
              title: __('ChatPublic.post_link_invite.external'),
              web: !deviceIsIpad(),
            });
          }
        }}
        post={postToShare}
        title={__('ChatPublic.post_link_invite.title')}
        trackFrom="chat-public"
      />
    );
  };

  /**
   * Renders send link modal for post through Consentio
   */
  private renderSendPostLinkModal = () => {
    const { me, channel, contacts, history, channels, sendMessage, lastMessageAt } = this.props;
    const { inviteLink } = this.state;
    const message: IMessage = {
      channelId: '',
      createdAt: new Date().getTime() + 60000,
      extraData: {},
      message: __('ChatPublic.post_link_invite.message', { link: inviteLink }),
      messageId: 0,
      messageType: 'text',
      reactions: {},
      senderId: me.id,
    };
    return (
      <ForwardMessage
        channelId={channel.id}
        channels={channels}
        close={() => this.setState({ showSendLinkModal: false })}
        contacts={contacts}
        forwardMessage={(myId: number, msg: IMessage, channelIds: Array<string>) => {
          channelIds.forEach(channelId => {
            sendMessage(myId, {
              ...msg,
              channelId,
            });
            EventTrack.track('message_send', {
              channelId,
              length: msg.message.length,
              messageType: 'text',
              type: channels[channelId]?.type,
            });
          });
        }}
        history={history}
        lastMessageAt={lastMessageAt}
        message={message}
        me={me}
        title={__('Components.Chat.send_invite_title')}
      />
    );
  };

  /**
   * Open file input selector
   */
  private onAddImageClick = async (withCrop?: boolean, imageIndex?: number) => {
    const { pictureFilesOriginal, imagePreviews } = this.state;
    if (withCrop && this.pictureRef.current) {
      let pictureFile = pictureFilesOriginal?.[imageIndex];
      const imageUrl = imagePreviews?.[imageIndex || 0]?.imageUrl;

      // If we are editing a post, we need to download the image to do the crop
      if (!pictureFile && imageUrl) {
        pictureFile = await convertURLToFile(imageUrl);
        const pictureFilesOriginalCopy = (pictureFilesOriginal || []).slice();
        pictureFilesOriginalCopy[imageIndex] = pictureFile;
        this.setState({ pictureFilesOriginal: pictureFilesOriginalCopy });
      }
      this.pictureRef.current.loadCrop(pictureFile);
      this.setState({ imageCropIndex: imageIndex });
    } else {
      this.openImagePicker();
    }
  };

  /**
   * Open the image picker
   */
  private openImagePicker = () => {
    const imageInput = document.getElementById('chat-public-file-input') as HTMLInputElement;
    if (imageInput) imageInput.click();
  };

  /**
   * Handle converting an array of File to IFile after having selecting it
   */
  private handleSelectFile = (files: Array<File>, resetEdit: boolean, fromCrop?: boolean) => {
    const { imageCropIndex, imagePreviews, pictureFilesOriginal } = this.state;
    const imagePreviewsCopy = imagePreviews ? imagePreviews.slice() : [];
    const pictureFilesOriginalCopy = pictureFilesOriginal ? pictureFilesOriginal.slice() : [];
    let iterations = 0;

    files.forEach(f => {
      if (f) {
        convertToIFile(
          f,
          (file: IFile, fileString: string) => {
            if (fromCrop && typeof imageCropIndex === 'number' && imageCropIndex >= 0) {
              imagePreviewsCopy[imageCropIndex] = { file, fileString };
              pictureFilesOriginalCopy[imageCropIndex] = f;
            } else if (imagePreviewsCopy?.length < MAX_PICTURES_PER_POST) {
              imagePreviewsCopy.push({ file, fileString });
              pictureFilesOriginalCopy.push(f);
            }
            if (iterations === files.length - 1) {
              this.setState(
                {
                  showWritePost: 'post',
                  imagePreviews: imagePreviewsCopy,
                  pictureFilesOriginal: pictureFilesOriginalCopy,
                },
                () => resetEdit && this.setState({ postToEdit: undefined }),
              );
            }
            iterations++;
          },
          () => iterations++,
        );
      }
    });
  };

  /**
   * Scroll to top when navigating to a new channel
   */
  private scrollToTop = () => {
    const container = document.getElementsByClassName('chat-public-container');
    if (container?.length) {
      container[0].scrollTop = 0;
    }
  };
}

export default ChatPublic;

/**
 * Description card with 8 max lines and a "see more" link
 */
export const ChannelDescriptionCard = React.memo(
  ({
    channelId,
    description,
    lang,
    onTrack,
  }: {
    channelId: string;
    description: string;
    lang?: LOCALE;
    onTrack: (seeMore: boolean) => void;
  }) => {
    const [seeMore, setSeeMore] = React.useState(false);
    const [showSeeMore, setShowSeeMore] = React.useState(false);
    const descriptionRef = React.createRef<HTMLSpanElement>();
    const [windowWidth] = useWindowSize();

    React.useEffect(() => {
      setSeeMore(false);
      setShowSeeMore(false);
    }, [channelId]);

    React.useEffect(() => {
      if (descriptionRef.current?.clientHeight > 8 * 22) {
        setShowSeeMore(true);
      }
    }, [description, descriptionRef, windowWidth]);

    if (!description) return null;
    return (
      <S.DescriptionCard>
        <CIS.TitleCard>{__('ChatPublicInfo.channel_description', { locale: lang })}</CIS.TitleCard>
        <CIS.ContentCardText maxLines={8} ref={descriptionRef} showSeeMore={showSeeMore} seeMore={seeMore}>
          {utils.splitTextUrls(description).map((t, i) => {
            if (!t.text) return <br key={i + ''} />;
            if (t.isLink) {
              const { url } = utils.analyzeUrl(t.text);
              return (
                <CIS.Link
                  key={i + url}
                  href={url}
                  target="_blank"
                  onClick={() => {
                    EventTrack.track('description_link_click', {
                      channel_id: channelId,
                      channel_type: CHANNEL_TYPE.PUBLIC,
                      link: url,
                    });
                  }}
                >
                  {url}
                </CIS.Link>
              );
            }
            return t.text;
          })}
          {showSeeMore && !seeMore ? (
            <S.SeeMoreContainer>
              <S.TextLink
                onClick={() => {
                  setSeeMore(!seeMore);
                  onTrack(!seeMore);
                }}
              >
                {seeMore ? __('ChatPublic.see_less', { locale: lang }) : __('ChatPublic.see_more', { locale: lang })}
              </S.TextLink>
            </S.SeeMoreContainer>
          ) : null}
        </CIS.ContentCardText>
      </S.DescriptionCard>
    );
  },
);
