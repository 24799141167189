import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, TagBubble, TextLabel } from '../../atoms';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
  justify-content: center;
  align-items: center;
`;

export const EmptyCell = styled(ColumnContainer)`
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
`;

export const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: fit-content;
  border-radius: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TextBlack = styled(Text)``;

export const NameText = styled(Text)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
`;
export const LeftContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;
export const ZeroCaseImg = styled(RowContainer)`
  justify-content: left;
`;
