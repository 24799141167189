import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, Input as InputAtom, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isActive?: boolean;
  theme: ITheme;
  typeWorkspace?: 'seller' | 'buyer';
}

export const Column = styled(ColumnContainer)`
  width: 100%;
`;

export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => `calc(${p.theme.contentHeight} - 3 * ${p.theme.headerHeight})`};
`;

export const Container = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow-y: auto;
  position: relative;
`;

export const Title = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const TeamTitleContainer = styled(RowContainer)`
  min-height: min-content;
`;

export const TeamTitleCol = styled(ColumnContainer)`
  min-height: min-content;
`;

export const TeamTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: 2px;
`;

export const TeamSubtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const Subtitle = styled(TeamSubtitle)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TeamActiveIndicator = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(p: IStyledProps) => (p.isActive ? p.theme.colors.green1 : p.theme.colors.grey2)};
  margin-top: 5px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TeamsRow = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-width: 388px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  border-radius: 10px;
  min-height: min-content;
  flex-wrap: wrap;
`;

export const ZeroCaseContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 284px;
  min-height: min-content;
`;

export const TextGrey2 = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: center;
`;

export const CreateButton = styled(Button)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Input = styled(InputAtom)`
  max-width: 450px;
`;

export const Logo = styled(ColumnContainer)`
  max-width: 120px;
  max-height: 120px;
  align-self: center;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

export const ModalButton = styled(Button)`
  align-self: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const OptionsContainer = styled(ColumnContainer)`
  align-self: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const BusinessContainer = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  cursor: pointer;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const IconContainer = styled(RowContainer)`
  height: 28px;
  min-width: 28px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  justify-content: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const BusinessIcon = styled(FontIcon)`
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => (p.typeWorkspace === 'seller' ? p.theme.colors.orange1 : p.theme.colors.blue1)};
`;

export const TitleText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const SubtitleText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  padding-top: 2px;
`;
