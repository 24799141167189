import { __ } from 'common-services';
import * as React from 'react';

import * as S from './FormContainer.styled';

export interface IProps {
  canSave?: boolean;
  children?: React.ReactNode;
  className?: string;
  cta?: string;
  discardChanges?: () => void;
  full?: boolean;
  save: () => void;
  sectionsRef?: React.RefObject<HTMLDivElement>;
  withoutMenu?: boolean;
}

const FormContainer: React.FC<IProps> = ({
  canSave,
  children,
  className,
  cta,
  discardChanges,
  full,
  save,
  sectionsRef,
  withoutMenu,
}) => (
  <S.FormContainer className={className} withoutMenu={withoutMenu} full={full}>
    <S.Sections ref={sectionsRef}>{children}</S.Sections>
    {canSave ? (
      <S.SaveSection>
        {discardChanges ? (
          <S.DiscardButton onClick={save}>{__('Components.ProductDetails.discard')}</S.DiscardButton>
        ) : null}
        <S.SaveButton id="save-button" onClick={save}>
          {cta || __('Components.ProductDetails.save')}
        </S.SaveButton>
      </S.SaveSection>
    ) : null}
  </S.FormContainer>
);

export default React.memo(FormContainer);
