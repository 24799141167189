import { __, IFeaturedType } from 'common-services';
import * as React from 'react';

import { FontIcon } from '../../atoms';
import { IFontIconKeys } from '../../atoms/FontIcon';
import * as S from './FeaturedCard.styled';

export interface IProps {
  amSeller: boolean;
  amount: number;
  type: IFeaturedType;
  onClick: () => void;
}

export const FeaturedCard: React.FC<IProps> = ({ amSeller, amount, type, onClick }) => {
  return (
    <S.Container onClick={amount > 0 ? onClick : () => null} clickable={amount > 0}>
      {type === 'recommended' ? (
        <S.Recommended clickable={amount > 0}>{renderFeatured('Recommended', amount)}</S.Recommended>
      ) : null}
      {type === 'favorite' ? (
        <S.Favorite clickable={amount > 0}>{renderFeatured('Favorits', amount)}</S.Favorite>
      ) : null}
      {type === 'recent' ? <S.Recent clickable={amount > 0}>{renderFeatured('Recent', amount)}</S.Recent> : null}
      <S.Right>
        {amount === 0 ? (
          <S.Zero>{formatTextBold(getZeroCaseLabel())}</S.Zero>
        ) : (
          <React.Fragment>
            <S.Type>{getTitleLabel()}</S.Type>
            <S.Results>{__('Showroom.products', { count: amount })}</S.Results>
          </React.Fragment>
        )}
      </S.Right>
    </S.Container>
  );

  /**
   * Get title label
   */
  function getTitleLabel() {
    switch (type) {
      case 'recommended':
        return __('Showroom.featured.recommended');
      case 'favorite':
        return __('Showroom.featured.favorite');
      case 'recent':
        return __('Showroom.featured.recent');
    }
  }

  /**
   * Get zero cases description label
   */
  function getZeroCaseLabel() {
    switch (type) {
      case 'recommended':
        return __('Showroom.featured.zero_recommended');
      case 'favorite':
        return amSeller ? __('Showroom.featured.zero_favorite_seller') : __('Showroom.featured.zero_favorite_buyer');
      case 'recent':
        return amSeller ? __('Showroom.featured.zero_recent_seller') : __('Showroom.featured.zero_recent_buyer');
    }
  }
};

/**
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatTextBold(text: string) {
  return text.split('**').map((s, index) => (index % 2 ? <S.TextBold key={s + index}>{s}</S.TextBold> : s));
}

function renderFeatured(icon: IFontIconKeys, amount: number) {
  return (
    <S.Inner clickable={amount > 0}>
      <S.Icon>
        <FontIcon name={icon} disableHover={true} />
      </S.Icon>
    </S.Inner>
  );
}

export default React.memo(FeaturedCard);
