import ActionsModal from '../ActionsModal/ActionsModal.component';
import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  color?: 'green' | 'blue' | 'orange' | 'grey' | 'purple';
  theme: ITheme;
}

export const ContainerModal = styled(ActionsModal)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  overflow-y: auto;

  @media (max-height: 680px) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  }
`;

export const BodyContainer = styled(ColumnContainer)`
  overflow: hidden;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 100%;
  overflow-y: auto;
`;

export const HeaderRow = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const HeaderLinkInfo = styled(ColumnContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextGroupTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const LinkContainer = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 280px;
  cursor: pointer;
`;

export const TextGrey2 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextDescription = styled(TextGrey2)`
  line-height: 1.6;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ButtonOption = styled(RowContainer)`
  width: 100%;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  &:hover {
    opacity: 0.8;
  }
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  cursor: pointer;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

const getIconColor = (p: IStyledProps) => {
  switch (p.color) {
    case 'green':
      return css`
        color: ${p.theme.colors.green1};
        background-color: ${p.theme.colors.green2};
      `;
    case 'blue':
      return css`
        color: ${p.theme.colors.blue1};
        background-color: ${p.theme.colors.blue2};
      `;
    case 'purple':
      return css`
        color: ${p.theme.colors.purple1};
        background-color: ${p.theme.colors.purple2};
      `;
    case 'orange':
      return css`
        color: ${p.theme.colors.orange1};
        background-color: ${p.theme.colors.orange2};
      `;
    case 'grey':
    default:
      return css`
        color: ${p.theme.colors.grey2};
        background-color: ${p.theme.colors.grey4};
      `;
  }
};

export const IconImage = styled.img`
  object-fit: contain;
  height: 28px;
  width: 28px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const IconContainer = styled(RowContainer)`
  height: 28px;
  width: 28px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  justify-content: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  ${getIconColor};
`;

export const ButtonIcon = styled(FontIcon)`
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 18px;
`;

export const TitleText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
