import { __, chatActions, constants, EventTrack, imageActions, modalActions, userSelectors } from 'common-services';
import { CHANNEL_MEMBER_ROLE, INVITE_ORIGIN } from 'common-services/dist/common';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { MAX_FILE_SIZE } from '../../../constants';
import { IReduxState } from '../../../reducers';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import share from '../../../services/sharer';
import { deviceIsIpad } from '../../../util/utils';
import { Picture } from '../../atoms';
import ChatPublicEdit from '../ChatPublicEdit';
import ForwardMessage from '../ForwardMessage';
import InviteLinkModal from '../InviteLinkModal';
import * as S from './ChatPublicHeader.styled';

interface IProps {
  channel: IChannel;
  className?: string;
  history?: { push: (url: string) => void; replace?: (url: string) => void };
  lang?: LOCALE;
  onTabChange: (tab: string) => void;
  showMute?: boolean;
  tabSelected: string;
}

/**
 * Chat public header
 */
const ChatPublicHeader: React.FC<IProps> = ({
  channel,
  className,
  history,
  lang,
  onTabChange,
  showMute = true,
  tabSelected,
}: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useSelector(userSelectors.getUser);
  const channels = useSelector((state: IReduxState) => state.chat.channels);
  const lastMessageAt = useSelector((state: IReduxState) => state.chat.lastMessageAt);
  const contacts = useSelector((state: IReduxState) => state.contact.inConsentio);

  /**
   * Get navigation tabs
   */
  const getTabs = React.useCallback(() => {
    const tabs = [];
    tabs.push({
      id: 'messages',
      label: __('ChatPublic.posts', { locale: lang }),
      badge: 0,
      action: () => onTabChange('messages'),
    });
    tabs.push({
      id: 'info',
      label: __('Components.ContactDetails.Header.info', { locale: lang }),
      action: () => onTabChange('info'),
    });
    return tabs;
  }, [onTabChange, lang]);

  const [showEditChannel, setShowEditChannel] = React.useState(false);
  const [showInviteLinkModal, setShowInviteLinkModal] = React.useState(false);
  const [showSendLink, setShowSendLink] = React.useState(false);
  const [inviteLink, setInviteLink] = React.useState('');
  const [imageChannelPreview, setImageChannelPreview] = React.useState<IPreviewFile>();

  const uploadImage = React.useCallback(
    (file: IFile, fileString: string, cb?: (url: string) => void) => {
      if (file.type.startsWith('image/')) {
        if (['image/gif', 'image/webp'].includes(file.type)) {
          if (file.size > MAX_FILE_SIZE) {
            dispatch(modalActions.modalOpen(__('Components.Chat.max_size_exceeded', { max: 3, locale: lang })));
            return;
          }
          dispatch(
            imageActions.mediaUploadWithProgress(
              { ...file, content: fileString },
              f => f && f.secure_url && cb?.(f.secure_url),
            ),
          );
        } else {
          resizeImage(
            fileString,
            (imageResized: string) =>
              dispatch(
                imageActions.mediaUploadWithProgress(
                  { ...file, content: imageResized },
                  f => f && f.secure_url && cb?.(f.secure_url),
                ),
              ),
            1600,
          );
        }
      }
    },
    [dispatch, lang],
  );

  const handleEditChannelPicture = React.useCallback(
    (f: File) => {
      if (f)
        convertToIFile(f, (file: IFile, fileString: string) => {
          setShowEditChannel(true);
          setImageChannelPreview({ file, fileString });
        });
    },
    [setShowEditChannel, setImageChannelPreview],
  );

  /**
   * Render edit channel modal
   */
  const renderEditChannelModal = React.useCallback(() => {
    return (
      <>
        <ChatPublicEdit
          channel={channel}
          className="chat-public-edit-modal"
          imageChannelPreview={imageChannelPreview}
          onClose={() => {
            setShowEditChannel(false);
            setImageChannelPreview(undefined);
          }}
          renderTime={Date.now()}
          setImageChannelPreview={setImageChannelPreview}
          toShow={showEditChannel}
          uploadImage={uploadImage}
        />
        <Picture
          className="chat-public-edit-input-picture"
          cropStrategy="contain"
          hidden={true}
          onCropShown={() => setShowEditChannel(false)}
          onFileChange={handleEditChannelPicture}
          relation={constants.IMAGE_RELATION.PUBLIC_CHANNEL}
          withCrop={true}
        />
      </>
    );
  }, [channel, uploadImage, handleEditChannelPicture, showEditChannel, imageChannelPreview]);

  /**
   * Render modal with invite link
   */
  const renderInviteLinkModal = React.useCallback(() => {
    return (
      <InviteLinkModal
        channel={channel}
        description={__('ChatPublic.link_invite.description', { locale: lang })}
        inviteOrigin={INVITE_ORIGIN.PUBLIC}
        me={me}
        onClose={() => setShowInviteLinkModal(false)}
        onSendLink={(inviteLinkReceived: string, through: 'message' | 'whatsapp') => {
          setShowInviteLinkModal(false);
          setInviteLink(inviteLinkReceived);
          if (through === 'message') setShowSendLink(true);
          else if (through === 'whatsapp') {
            share({
              sharer: 'whatsapp',
              url: inviteLinkReceived,
              title: __('ChatPublic.link_invite.external', { locale: lang }),
              web: !deviceIsIpad(),
            });
          }
        }}
        title={__('ChatPublic.link_invite.title', { locale: lang })}
        trackFrom="chat-public"
      />
    );
  }, [channel, lang, me]);

  /**
   * Render send link modal
   */
  const renderSendLinkModal = React.useCallback(() => {
    const message: IMessage = {
      channelId: '',
      createdAt: new Date().getTime() + 60000,
      extraData: {},
      message: __('ChatPublic.link_invite.message', { link: inviteLink, locale: lang }),
      messageId: 0,
      messageType: 'text',
      reactions: {},
      senderId: me.id,
    };
    return (
      <ForwardMessage
        channelId={channel.id}
        channels={channels}
        close={() => setShowSendLink(false)}
        contacts={contacts}
        forwardMessage={(myId: number, msg: IMessage, channelIds: Array<string>) => {
          channelIds.forEach(channelId => {
            dispatch(
              chatActions.sendMessage(myId, {
                ...msg,
                channelId,
              }),
            );
            EventTrack.track('message_send', {
              channelId,
              length: msg.message.length,
              messageType: 'text',
              type: channels[channelId]?.type,
            });
          });
        }}
        history={history}
        lastMessageAt={lastMessageAt}
        message={message}
        me={me}
        title={__('Components.Chat.send_invite_title', { locale: lang })}
      />
    );
  }, [channel, channels, dispatch, history, lastMessageAt, inviteLink, me, setShowSendLink, contacts, lang]);

  const amAdmin = [CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(channel.role);
  const showInviteButton = amAdmin || channel.invitePolicy === 'any';
  const thumbnailUrl = channel.configuration?.thumbnail;

  return (
    <>
      <S.HeaderContainer className={className}>
        <S.HeaderContent>
          <S.HeaderImageContainer hasThumbnail={!!thumbnailUrl}>
            {thumbnailUrl ? <S.HeaderThumbnailImage src={thumbnailUrl} /> : null}
            <S.HeaderImageWrapper>
              <S.HeaderImage src={channel.imageUrl || IMAGES.publicChannelDefault.landscape} />
            </S.HeaderImageWrapper>
          </S.HeaderImageContainer>
          <S.HeaderInfo>
            <S.HeaderLeft>
              <S.TextChannelName>{'#' + channel.name}</S.TextChannelName>
              <S.TextSubtitle>
                {__('ChatPublic.members', { count: channel.numMembers, locale: lang })}
                {' · '}
                <S.WorldIcon name="World" disableHover={true} />
              </S.TextSubtitle>
            </S.HeaderLeft>
            <S.HeaderRight>
              {showMute ? (
                <S.MuteDropdown
                  options={[
                    {
                      key: 'mute',
                      value: channel.muted
                        ? __('Components.ChatList.options.unmute', { locale: lang })
                        : __('Components.ChatList.options.mute', { locale: lang }),
                      icon: channel.muted ? 'Notification' : 'Notification-off',
                    },
                  ]}
                  hAlign="right"
                  onSelect={key => {
                    if (key === 'mute') dispatch(chatActions.channelMute(me.id, channel.id, !channel.muted));
                  }}
                >
                  <S.MuteIcon name={channel.muted ? 'Notification-off' : 'Notification'} />
                </S.MuteDropdown>
              ) : null}
              {amAdmin ? (
                <S.HeaderButton
                  id="chat-public-edit-button"
                  iconName="Edit"
                  iconSize="18px"
                  iconType="background"
                  onClick={() => setShowEditChannel(true)}
                  type="link"
                  withoutPadding={true}
                >
                  {__('ChatPublic.edit_channel', { locale: lang })}
                </S.HeaderButton>
              ) : null}
              {showInviteButton ? (
                <S.HeaderButton
                  id="chat-public-invite-button"
                  iconName="Add-contact"
                  iconSize="18px"
                  iconType="background"
                  onClick={() => {
                    setShowInviteLinkModal(true);
                    EventTrack.track('channel_invite_open_modal', { channel_id: channel.id, type: channel.type });
                  }}
                  type="link"
                  withoutPadding={true}
                >
                  {__('ChatPublic.invite', { locale: lang })}
                </S.HeaderButton>
              ) : null}
            </S.HeaderRight>
          </S.HeaderInfo>
        </S.HeaderContent>
        <S.NavigationTabs selected={tabSelected} tabs={getTabs()} TabRowContainer={S.NavigationTabsRow} />
      </S.HeaderContainer>
      {renderEditChannelModal()}
      {showInviteLinkModal ? renderInviteLinkModal() : null}
      {showSendLink ? renderSendLinkModal() : null}
    </>
  );
};

export default React.memo(ChatPublicHeader);
