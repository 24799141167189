import { utils, __ } from 'common-services';
import * as React from 'react';
import * as S from './LostLines.styled';

const LostLines: React.FC<{ lostLines: IOrderIssue }> = ({ lostLines }) => {
  const [open, setOpen] = React.useState(false);
  const data = lostLines.extraData ? JSON.parse(lostLines.extraData) : {};
  return (
    <>
      <S.Text>
        <>
          {utils.formatText(
            __('Components.Cart.errors.lost_line', {
              count: data.items_lost?.length || 1,
              hashId: data.order_hash,
              name: data.items_lost?.[0],
            }),
            (text, i) => (
              <S.TextBold key={text + i}>{text}</S.TextBold>
            ),
          )}
          {data.items_lost?.[0].length > 1 ? (
            <S.TextLink onClick={() => setOpen(!open)}>
              {__('SuppliersList.view_products')}
              <S.IssueIcon name="Down" />
            </S.TextLink>
          ) : null}
        </>
      </S.Text>
      {open ? (
        <S.LostLineUl>
          {data.items_lost.map((text: string) => (
            <S.LostLine key={text}>{text}</S.LostLine>
          ))}
        </S.LostLineUl>
      ) : null}
    </>
  );
};

export default LostLines;
