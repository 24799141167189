import {
  __,
  addresses,
  currency,
  CURRENCY_CODES,
  date,
  DATE_TYPE,
  IAddress,
  IContact,
  ICountry,
  IDeliveryMethodType,
  IOrder,
  IOrderIssue,
  IUser,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
  SCHEDULE,
  utils,
  WEIGHT_UNIT,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { CHANNEL_SECTIONS } from '../../../../../constants';
import { DatePicker } from '../../../../atoms';
import { ActionsModal, AddressAutoComplete, AddressModal, UpdateConfirmationModal } from '../../../../molecules';
import * as SCart from '../../OrderDetails.styled';
import * as S from './TransportCard.styled';

interface IProps {
  addDeliveryAddress: (address: IAddress, cb: (address: IAddress) => void) => void;
  addPickupAddress: (address: IAddress, cb: (address: IAddress) => void) => void;
  contact: IContact;
  countries: { [key: string]: ICountry };
  currency: CURRENCY_CODES;
  deliveryAddressIssue?: IOrderIssue;
  pickupAddressIssue?: IOrderIssue;
  history: History<any>;
  logisticAddresses: Array<IAddress>;
  pickupAddresses: Array<IAddress>;
  me: IUser;
  order: IOrder;
  orderAddressFixIssue: (
    myId: number,
    orderId: number,
    address: IAddress,
    addressType: 'shipping' | 'pickup',
    issueId: number,
    fixAll: boolean,
  ) => void;
  orderDateFixIssue: typeof orderActions.orderDateFixIssue;
  orderUpdateIssues: (issue: IOrderIssue, orderId: number, myId: number, cb: (order: IOrder) => void) => void;
  showLogistic: () => void;
  amEditor: boolean;
  hasItems: boolean;
  weightUnit: WEIGHT_UNIT;
  amViewer?: boolean;
}

interface IState {
  addressSelected?: string;
  pickupSelected?: string;
  showUpdateConfirmation: boolean;
  deliveryAddress: { id?: number; section?: CHANNEL_SECTIONS };
  pickupAddress: { id?: number; section?: CHANNEL_SECTIONS };
  showSearchAddress?: boolean;
}

class TransportCard extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      deliveryAddress: {},
      pickupAddress: {},
      showUpdateConfirmation: false,
    };
  }

  public render() {
    const { amEditor, order, contact, deliveryAddressIssue, hasItems, pickupAddressIssue, showLogistic } = this.props;
    const transportAdded = this.isTransportAdded();
    const canEdit =
      (!transportAdded && order.status !== 'canceled' && contact && !contact.imBlocking && !contact.imBlocked) ||
      (order.status !== 'canceled' &&
        (contact || order.origin === ORDER_ORIGIN.IMPORT_UI) &&
        !contact?.imBlocking &&
        !contact?.imBlocked &&
        !deliveryAddressIssue &&
        !pickupAddressIssue);
    const shipping = (order.customItems || []).find(ci => ci.type === 'shipping');
    const shippingCost = (shipping && shipping.price) || 0;
    return (
      <>
        <S.Container>
          <S.Row>
            <S.SectionTitle>{__('Components.OrderDetails.transport.title')}</S.SectionTitle>
            {deliveryAddressIssue || pickupAddressIssue ? <S.InfoIcon name="Warning" disableHover={true} /> : null}
          </S.Row>
          <S.CardItem>
            {transportAdded ? this.renderTransport() : this.renderAddressIssues()}
            {hasItems ? (
              <S.Text>
                {__('Components.Cart.load')}
                <S.TextBlack>
                  {config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order.catalogId)
                    ? orderService.getCartLoadSummaryAltUnits(order.items)
                    : orderService.getCartLoadSummaryText(order.items)}
                </S.TextBlack>
              </S.Text>
            ) : null}
            {shippingCost > 0 ? (
              <S.Text>
                {__('Components.OrderDetails.transport.transport_cost')}
                <S.TextBlack>{currency.getPrice(order.currency, shippingCost)}</S.TextBlack>
              </S.Text>
            ) : null}
            {order.incoterms.length && order.incoterms[0] ? (
              <S.Text>
                {__('Components.Cart.incoterms')}
                <S.TextBlack>{order.incoterms.join(', ')}</S.TextBlack>
              </S.Text>
            ) : null}
          </S.CardItem>
          {canEdit && amEditor ? (
            <S.AddAddressLink
              id="edit_transport_button"
              onClick={showLogistic}
              type="link"
              iconName="Add-more"
              iconSize="18px"
              withoutPadding={true}
            >
              {__('Components.OrderDetails.transport.add_edit_information')}
            </S.AddAddressLink>
          ) : null}
        </S.Container>
        {this.renderUpdateConfirmationModal()}
        {this.renderAddDeliveryAddress()}
        {this.renderAddPickupAddress()}
        {this.renderModalSearchAddress()}
      </>
    );
  }

  private renderAddDeliveryAddress() {
    const { addDeliveryAddress, countries, me } = this.props;
    const { deliveryAddress } = this.state;
    if (!deliveryAddress.section) return null;
    return deliveryAddress.section ? (
      <ActionsModal
        onClose={() => this.setState({ deliveryAddress: {} })}
        title={deliveryAddress.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
      >
        <AddressAutoComplete
          countries={countries}
          me={me}
          onCancel={this.navResetDeliveryAddressAction}
          onSubmit={(adr: IAddress) => {
            addDeliveryAddress(adr, address => {
              this.setState({
                addressSelected: addresses.getAddressKey(address),
                showUpdateConfirmation: true,
                showSearchAddress: false,
                deliveryAddress: {},
              });
            });
          }}
          tradeCurrency={this.props.currency}
          weightUnit={this.props.weightUnit}
        />
      </ActionsModal>
    ) : null;
  }

  private renderAddPickupAddress() {
    const { addPickupAddress, countries, me } = this.props;
    const { pickupAddress } = this.state;
    if (!pickupAddress.section) return null;
    return pickupAddress.section ? (
      <ActionsModal
        onClose={() => this.setState({ pickupAddress: {} })}
        title={pickupAddress.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
      >
        <AddressAutoComplete
          countries={countries}
          me={me}
          onCancel={this.navResetPickupAddressAction}
          onSubmit={(adr: IAddress) => {
            addPickupAddress(adr, address => {
              this.setState({
                pickupSelected: addresses.getAddressKey(address),
                showUpdateConfirmation: true,
                showSearchAddress: false,
                pickupAddress: {},
              });
            });
          }}
          tradeCurrency={this.props.currency}
          weightUnit={this.props.weightUnit}
        />
      </ActionsModal>
    ) : null;
  }
  private renderModalSearchAddress() {
    const { order, logisticAddresses, pickupAddresses, pickupAddressIssue, deliveryAddressIssue } = this.props;
    const { showSearchAddress } = this.state;
    return showSearchAddress ? (
      <AddressModal
        addresses={order.deliveryMethod === 'shipping' ? logisticAddresses : pickupAddresses}
        catalogId={order.catalogId}
        createAddress={() => {
          this.setState({
            showSearchAddress: false,
          });
          if (order.deliveryMethod === 'shipping') {
            this.navDeliveryAddressAction();
          } else {
            this.navPickupAddressAction();
          }
        }}
        onClose={() => this.setState({ showSearchAddress: false })}
        title={__('Components.OrderDetails.transport.address_title')}
        subtitle={__('Components.OrderDetails.transport.address_subtitle', {
          code: deliveryAddressIssue?.code || pickupAddressIssue?.code || '',
        })}
        onSelectAddress={(a: IAddress) => {
          if (order.deliveryMethod === 'shipping') {
            this.setState({
              addressSelected: addresses.getAddressKey(a),
              showUpdateConfirmation: true,
              showSearchAddress: false,
            });
          } else if (order.deliveryMethod === 'pickup') {
            this.setState({
              pickupSelected: addresses.getAddressKey(a),
              showUpdateConfirmation: true,
              showSearchAddress: false,
            });
          }
          // }
        }}
      />
    ) : null;
  }

  /**
   * Will render pickup and/or delivery addresses issues fix fragments
   */
  private renderAddressIssues = () => {
    const { order, deliveryAddressIssue, pickupAddressIssue } = this.props;
    const { addressSelected, pickupSelected } = this.state;
    if (!deliveryAddressIssue && !pickupAddressIssue)
      return <S.TextBlack>{__('Components.OrderDetails.transport.not_included')}</S.TextBlack>;

    return (
      <>
        {pickupAddressIssue
          ? this.renderAddressIssue(
              'pickup',
              pickupAddressIssue,
              order.pickupEstimatedAt,
              order.pickupEstimatedSchedule,
              pickupSelected,
            )
          : null}
        {deliveryAddressIssue
          ? this.renderAddressIssue(
              'shipping',
              deliveryAddressIssue,
              order.deliveryEstimatedAt,
              order.deliveryEstimatedSchedule,
              addressSelected,
            )
          : null}
      </>
    );
  };

  /**
   * Checks if transport has been added.
   * If any of these information is set, we consider that transport is added
   */
  private isTransportAdded = () => {
    const {
      order: { customItems, pickupFrom, deliverTo, incoterms, deliveryEstimatedAt, pickupEstimatedAt },
    } = this.props;
    const shipping = (customItems || []).find(ci => ci.type === 'shipping');
    return (
      pickupFrom ||
      deliverTo ||
      deliveryEstimatedAt ||
      pickupEstimatedAt ||
      (shipping && shipping.price) ||
      (incoterms.length && incoterms[0])
    );
  };

  /**
   * Render address issue for delivery or pickup
   */
  private renderAddressIssue(
    addressType: 'shipping' | 'pickup',
    addressIssue: IOrderIssue,
    estimatedAt: string,
    estimatedSchedule: SCHEDULE,
    addressSelected?: string,
  ) {
    const { amEditor, amViewer, countries, logisticAddresses, me, order, orderDateFixIssue, pickupAddresses } =
      this.props;
    const estimatedDate = estimatedAt ? utils.capitalizeWords(date.formatLongDate(estimatedAt)) : '';
    const estimatedScheduleDisplay =
      estimatedAt && estimatedSchedule && estimatedSchedule !== SCHEDULE.UNKNOWN
        ? ' · ' + orderService.getScheduleLabel(estimatedSchedule).toLocaleLowerCase()
        : '';
    const addressesList = addressType === 'shipping' ? logisticAddresses : pickupAddresses;
    const dateIssue = order.issues.find(
      i => i.type === 'date-not-valid' && i.code === (addressType === 'shipping' ? 'delivery_date' : 'pickup_date'),
    );
    return (
      <S.AddressIssueRow>
        <S.AddressIssueCard>
          <S.Text>
            {addressType === 'shipping'
              ? __('Components.OrderDetails.transport.shipping')
              : __('Components.OrderDetails.transport.pickup')}
            :
            {amEditor && order.status !== ORDER_STATUS.CANCELED ? (
              config.TOGGLE_SEARCH_ADDRESS.enabled ? (
                <SCart.IssueCTA onClick={() => this.setState({ showSearchAddress: true })}>
                  {addressType === 'shipping'
                    ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                    : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
                </SCart.IssueCTA>
              ) : (
                <S.AddressesSelect
                  onChange={e => {
                    if (e.target.value === 'create-address') {
                      if (addressType === 'shipping') {
                        this.navDeliveryAddressAction();
                      } else {
                        this.navPickupAddressAction();
                      }
                    } else {
                      if (addressType === 'shipping') {
                        this.setState({ addressSelected: e.target.value, showUpdateConfirmation: true });
                      } else if (addressType === 'pickup') {
                        this.setState({ pickupSelected: e.target.value, showUpdateConfirmation: true });
                      }
                    }
                  }}
                  value={addressSelected || ''}
                >
                  <S.SelectOption value="" disabled>
                    {addressType === 'shipping'
                      ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                      : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
                  </S.SelectOption>
                  {addressesList
                    .sort((a, b) => {
                      if (a.displayName > b.displayName) {
                        return 1;
                      }
                      if (b.displayName > a.displayName) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((addr, i) => (
                      <S.SelectOption value={addresses.getAddressKey(addr)} key={i}>
                        {addresses.getAddressName(addr, countries)}
                      </S.SelectOption>
                    ))}
                  {!amViewer ? (
                    <S.SelectOption value="create-address">
                      {__('Components.OrderDetails.transport.create_address')}
                    </S.SelectOption>
                  ) : null}
                </S.AddressesSelect>
              )
            ) : (
              <SCart.IssueText>
                {addressType === 'shipping'
                  ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                  : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
              </SCart.IssueText>
            )}
          </S.Text>
          <S.Text>
            {__('Components.OrderDetails.transport.estimated_date')}
            {dateIssue ? (
              <DatePicker
                id="delivery-estimated-at"
                initDate={new Date(order.deliveryEstimatedAt || Date.now())}
                onDateChange={d => {
                  orderDateFixIssue(
                    me.id,
                    order.id,
                    d,
                    dateIssue.id,
                    false,
                    addressType === 'shipping' ? DATE_TYPE.DELIVERY : DATE_TYPE.PICKUP,
                  );
                }}
                customInput={
                  <SCart.IssueText>
                    {addressType === 'shipping'
                      ? __('Components.Cart.delivery_date_issue')
                      : __('Components.Cart.pickup_date_issue')}{' '}
                    <SCart.IssueIcon name="Down" />
                  </SCart.IssueText>
                }
              />
            ) : (
              estimatedDate && <S.TextBlack>{estimatedDate + estimatedScheduleDisplay}</S.TextBlack>
            )}
          </S.Text>
        </S.AddressIssueCard>
      </S.AddressIssueRow>
    );
  }

  /**
   * Render transport info
   */
  private renderTransport = () => {
    const { order, deliveryAddressIssue, pickupAddressIssue } = this.props;
    const { addressSelected, pickupSelected } = this.state;
    if (order.deliveryMethod === 'pickup') {
      return pickupAddressIssue
        ? this.renderAddressIssue(
            'pickup',
            pickupAddressIssue,
            order.pickupEstimatedAt,
            order.pickupEstimatedSchedule,
            pickupSelected,
          )
        : this.renderDeliveryMethod(
            'pickup',
            order.pickupFrom,
            order.pickupEstimatedAt,
            order.pickupEstimatedTimeRange,
            order.transportDetails,
          );
    } else {
      return deliveryAddressIssue
        ? this.renderAddressIssue(
            'shipping',
            deliveryAddressIssue,
            order.deliveryEstimatedAt,
            order.deliveryEstimatedSchedule,
            addressSelected,
          )
        : this.renderDeliveryMethod(
            'shipping',
            order.deliverTo,
            order.deliveryEstimatedAt,
            order.deliveryEstimatedTimeRange,
            order.transportDetails,
          );
    }
  };

  /**
   * Render the delivery method with its respective icon / title / address / estimated date
   */
  private renderDeliveryMethod = (
    deliveryMethod: IDeliveryMethodType,
    address: IAddress,
    estimatedAt: string,
    estimatedSchedule: Array<string>,
    transportDetails: string,
  ) => {
    if (!address && !estimatedAt) return null;
    const { countries, order, orderDateFixIssue, me } = this.props;
    const estimatedDate = estimatedAt ? utils.capitalizeWords(date.formatLongDate(estimatedAt)) : '';

    const estimatedLoadDate = order.loadEstimatedAt
      ? utils.capitalizeWords(date.formatLongDate(order.loadEstimatedAt))
      : '';

    const estimatedScheduleDisplay =
      estimatedAt && estimatedSchedule && estimatedSchedule.length
        ? ' ' + estimatedSchedule[0] + ' - ' + estimatedSchedule[1]
        : '';

    const dateIssue = order.issues.find(
      i => i.type === 'date-not-valid' && i.code === (deliveryMethod === 'shipping' ? 'delivery_date' : 'pickup_date'),
    );
    return (
      <S.Card>
        {deliveryMethod === 'pickup' ? (
          <>
            {address ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Pickup.address')}:
                <S.TextBlack>
                  {address.displayName || address.rest ? (address.displayName || address.rest) + ' · ' : ''}
                  {addresses.getAddressName(address, countries, false)}
                </S.TextBlack>
              </S.Text>
            ) : null}
            {estimatedDate ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Pickup.date')}:<S.TextBlack>{estimatedDate}</S.TextBlack>
              </S.Text>
            ) : null}
            {estimatedScheduleDisplay ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Pickup.time')}:
                <S.TextBlack>{estimatedScheduleDisplay}</S.TextBlack>
              </S.Text>
            ) : null}
          </>
        ) : (
          <>
            {address ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Delivery.address')}:{' '}
                <S.TextBlack>
                  {address.displayName || address.rest ? (address.displayName || address.rest) + ' · ' : ''}
                  {addresses.getAddressName(address, countries, false)}
                </S.TextBlack>
              </S.Text>
            ) : null}
            {config.TOGGLE_LOAD_DATE?.enabled && estimatedLoadDate ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Load.date')}:
                {estimatedLoadDate && <S.TextBlack>{estimatedLoadDate}</S.TextBlack>}
              </S.Text>
            ) : null}
            <S.Text>
              {__('Components.OrderDetails.transport.Delivery.date')}:
              {dateIssue ? (
                <DatePicker
                  id="delivery-estimated-at"
                  initDate={new Date(order.deliveryEstimatedAt)}
                  onDateChange={d => {
                    orderDateFixIssue(
                      me.id,
                      order.id,
                      d,
                      dateIssue.id,
                      false,
                      deliveryMethod === 'shipping' ? DATE_TYPE.DELIVERY : DATE_TYPE.PICKUP,
                    );
                  }}
                  customInput={
                    <SCart.IssueText>
                      {deliveryMethod === 'shipping'
                        ? __('Components.Cart.delivery_date_issue')
                        : __('Components.Cart.pickup_date_issue')}{' '}
                      <SCart.IssueIcon name="Down" />
                    </SCart.IssueText>
                  }
                />
              ) : (
                estimatedDate && <S.TextBlack>{estimatedDate}</S.TextBlack>
              )}
            </S.Text>
            {estimatedScheduleDisplay ? (
              <S.Text>
                {__('Components.OrderDetails.transport.Delivery.time')}:{' '}
                <S.TextBlack>{estimatedScheduleDisplay}</S.TextBlack>
              </S.Text>
            ) : null}
          </>
        )}
        {transportDetails ? (
          <S.Text>
            {__('Components.OrderDetails.transport.transport')}: <S.TextBlack>{transportDetails}</S.TextBlack>
          </S.Text>
        ) : null}
      </S.Card>
    );
  };

  /**
   * Render update address confirmation modal (to fix address issue)
   */
  private renderUpdateConfirmationModal = () => {
    const { countries, logisticAddresses, pickupAddresses, amViewer } = this.props;
    const { addressSelected, pickupSelected, showUpdateConfirmation } = this.state;
    if (!showUpdateConfirmation) return null;
    const addressType = addressSelected ? 'shipping' : 'pickup';
    const addressesList = addressType === 'shipping' ? logisticAddresses : pickupAddresses;
    const address = addressesList?.find(
      addr => addresses.getAddressKey(addr) === (addressType === 'shipping' ? addressSelected : pickupSelected),
    );
    return (
      <UpdateConfirmationModal
        checkLabel={__('Components.OrderDetails.transport.update_confirmation.check')}
        onClose={() =>
          this.setState({ showUpdateConfirmation: false, addressSelected: undefined, pickupSelected: undefined })
        }
        ctaChecked={__('Components.OrderDetails.transport.update_confirmation.cta2')}
        ctaNotChecked={__('Components.OrderDetails.transport.update_confirmation.cta')}
        defaultCheck={!address?.externalId || amViewer}
        disableCheck={!address?.externalId || amViewer}
        onConfirm={isChecked => this.changeOrderAddress(!isChecked, address, addressType)}
        title={__('Components.OrderDetails.transport.update_confirmation.title', {
          name: addresses.getAddressName(address, countries),
        })}
      />
    );
  };

  /**
   * Change order address to fix issue
   */
  private changeOrderAddress(fixAll: boolean, address: IAddress, addressType: 'shipping' | 'pickup') {
    const { me, order, orderAddressFixIssue, orderUpdateIssues, deliveryAddressIssue, pickupAddressIssue } = this.props;

    const addressIssue = addressType === 'shipping' ? deliveryAddressIssue : pickupAddressIssue;

    orderUpdateIssues({ ...addressIssue, status: 'solved' }, order.id!, me.id!, o => {
      orderAddressFixIssue(me.id!, order.id, address, addressType, addressIssue.id, fixAll);
    });

    this.setState({
      showUpdateConfirmation: false,
      addressSelected: addressType === 'shipping' ? undefined : this.state.addressSelected,
      pickupSelected: addressType === 'pickup' ? undefined : this.state.pickupSelected,
    });
  }

  /**
   * Open de address modal
   */
  private navDeliveryAddressAction = () => {
    this.setState({ deliveryAddress: { id: undefined, section: CHANNEL_SECTIONS.ADDRESS_EDIT } });
  };

  /**
   * Close de address modal
   */
  private navResetDeliveryAddressAction = () => {
    this.setState({ deliveryAddress: {} });
  };

  /**
   * Open de address modal
   */
  private navPickupAddressAction = () => {
    this.setState({ pickupAddress: { id: undefined, section: CHANNEL_SECTIONS.ADDRESS_EDIT } });
  };

  /**
   * Close de address modal
   */
  private navResetPickupAddressAction = () => {
    this.setState({ pickupAddress: {} });
  };
}

export default TransportCard;
