import * as React from 'react';

import { LettersAvatar } from '..';
import RadioInput from '../RadioInput';
import Tooltip from '../Tooltip';
import * as S from './ClientSelectable.styled';
import { getAvatarColor } from 'common-services/dist/utils/utils';
import { __ } from 'common-services';

interface IProps {
  action?: 'remove' | 'switch' | 'radio' | 'none';
  className?: string;
  client: IClient;
  isLast: boolean;
  isSelected: boolean;
  onSelectClient: () => void;
  ediCode?: string;
}

const ClientSelectable: React.FC<IProps> = ({
  action,
  className,
  client,
  ediCode,
  isLast,
  isSelected,
  onSelectClient,
}) => {
  return (
    <S.ClientContainer
      isLast={isLast}
      className={className}
      onClick={() => {
        if (action !== 'remove') onSelectClient();
      }}
    >
      {action !== 'none' ? (
        <S.ActionWrapper onClick={onSelectClient}>
          {action === 'switch' || action === 'radio' ? (
            <RadioInput checked={isSelected} value="" isRadio={action === 'radio'} />
          ) : null}
          {action === 'remove' ? <S.CloseIcon name="Close" id="remove-client-icon" /> : null}
        </S.ActionWrapper>
      ) : null}
      <S.Icon>
        <LettersAvatar size={35} text={client.company} img={client.logo} avatarColor={getAvatarColor(client.company)} />
      </S.Icon>
      <S.Info>
        <S.Row>
          <S.InfoTitle>
            {client.name}
            <S.InfoGrey> - {ediCode ? __('Components.Cart.edi_code') : __('Components.Cart.no_edi_code')}</S.InfoGrey>
          </S.InfoTitle>{' '}
        </S.Row>
        <S.InfoSubtitle>{client.company}</S.InfoSubtitle>
      </S.Info>
    </S.ClientContainer>
  );
};

export default React.memo(ClientSelectable);
