import styled, { css, ITheme } from '../../../styled-components';
import {
  ArrowPercentage as AP,
  CheckBox,
  ColumnContainer,
  FontIcon,
  Input,
  RowContainer,
  TextLabel,
  Tooltip,
} from '../../atoms';

interface IStyledProps {
  amount?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  theme: ITheme;
  width?: string;
}
export const AmountInput = styled(Input)`
  border: ${(p: IStyledProps) =>
    `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.grey2}`};
  &:focus-within {
    border: ${(p: IStyledProps) =>
      `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.green1}`};
  }
`;
export const PriceInput = styled(AmountInput)`
  margin-right: 0;
`;
export const Row = styled(RowContainer)`
  align-items: center;
`;

export const ImageContainer = styled(ColumnContainer)<IStyledProps>`
  max-height: 90px;
  min-height: 90px;
  min-width: 90px;
  max-width: 90px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  cursor: ${(p: IStyledProps) => (p.isClickable ? 'pointer' : 'inherit')};
`;

export const TypePackagingContainer = styled(ColumnContainer)<IStyledProps>`
  flex: 1;
  padding: 3px 0;
  cursor: ${(p: IStyledProps) => (p.isClickable ? 'pointer' : 'inherit')};
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: ${(p: IStyledProps) => p.width || '100%'};
`;

export const TextBold = styled(Text)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const ItemTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  flex: 1;
  align-self: center;
`;

export const ItemPrice = styled(Text)`
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: right;
  flex: none;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: auto;
`;

export const ItemPriceTotal = styled(ItemPrice)`
  justify-content: flex-end;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const IconWrapper = styled.div`
  width: auto;
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;

export const AmountRow = styled(RowContainer)`
  align-items: center;
  align-self: center;
  cursor: auto;
  justify-content: flex-end;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Amount = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  display: flex;
  color: ${(p: IStyledProps) =>
    p.showGrey ? p.theme.colors.grey2 : p.servedQuantity ? p.theme.colors.black : p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Select = styled.select`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  background-color: transparent;
  color: inherit;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  ${(p: IStyledProps) => {
    const color = p.theme.colors.grey1;
    return css`
      color: ${color};
      background-image: linear-gradient(45deg, transparent 50%, ${color} 50%),
        linear-gradient(135deg, ${color} 50%, transparent 50%);
    `;
  }} background-position: calc(100% - 5px) 0.5em, 100% 0.5em;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const PriceRow = styled(RowContainer)`
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
`;

export const PriceColumn = styled(ColumnContainer)`
  align-self: center;
  align-items: flex-end;
  justify-content: center;
`;

export const PriceEditRow = styled(RowContainer)`
  padding: 26.5px 0;
  cursor: ${(p: IStyledProps) => (p.enabled ? 'pointer' : 'default')};
  align-self: center;
  justify-content: flex-end;
`;

export const IconEditWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: 'inherit';
  padding: 0 3px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  background: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-self: center;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
`;

export const Price = styled.h1`
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  display: flex;
  color: ${(p: IStyledProps) =>
    p.enabled ? p.theme.colors.green1 : p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black};
`;

export const PriceUnit = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  line-height: 1;
  padding: 0 5px;
`;

export const Td = styled.td`
  vertical-align: middle;
  text-align: left;
  position: relative;
`;

export const ImageTd = styled(Td)`
  width: 90px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const ProductTd = styled(Td)`
  justify-content: flex-start;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CloseTd = styled(Td)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 42px;
  min-width: 42px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const SubtotalTd = styled(Td)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  justify-content: flex-end;
`;

export const Tr = styled.tr`
  height: 104px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ProductErrorRow = styled(RowContainer)`
  align-items: center;
`;

export const ProductPriceRow = styled(ProductErrorRow)`
  justify-content: flex-end;
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: 20px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 20px;
  width: 20px;
`;
export const DeleteContainer = styled(RowContainer)`
  justify-content: flex-start;
`;
export const Deleted = styled(Text)`
  width: auto;
`;
export const Undo = styled(TextLink)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const Option = styled(RowContainer)`
  align-items: center;
`;

export const Check = styled(CheckBox)``;

export const OptionText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.isChecked ? p.theme.colors.green1 : p.theme.colors.black)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const KebabIcon = styled(FontIcon)`
  font-size: 20px;
  height: 26px;
  width: 26px;
  padding-bottom: 2px;
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TooltipPrice = styled(Tooltip)`
  position: relative;
  top: -1px;
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextPriceGroup = styled(TextLabel)`
  text-align: right;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextPriceMismatch = styled(TextLabel)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ArrowPercentage = styled(AP)<IStyledProps>`
  position: absolute;
  right: ${(p: IStyledProps) => p.rightPosition};
`;
