import { IGlobalWeb } from 'common-services';

declare var global: IGlobalWeb;

export enum CONVERSIONS {
  LOGIN = 'login',
  REGISTER = 'register',
}
const conversionData: { [key: string]: string } = {
  login: 'AW-845495392/HjWmCIGDnXMQ4PiUkwM',
  register: 'AW-845495392/IhWNCJvrhHUQ4PiUkwM',
};

export function trackConversion(kind: CONVERSIONS) {
  if (global.gtag === undefined) {
    console.log(`Conversion ${kind} with value ${conversionData[kind]} not sent. Global gtag undefined`);
    return;
  }
  global.gtag('event', 'conversion', { send_to: conversionData[kind] });
}
