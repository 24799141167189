import * as React from 'react';

import * as S from './Error.styled';

export interface IProps {
  className?: string;
  text: string;
  action?: () => void;
  link?: string;
}

const Error: React.FC<IProps> = ({ action, className, link, text }) => {
  return (
    <S.Container className={className}>
      <S.Icon name="Error" disableHover={true} />
      <S.TextError>{text}</S.TextError>
      {action && link ? <S.Link onClick={action}>{link}</S.Link> : null}
    </S.Container>
  );
};

export default React.memo(Error);
