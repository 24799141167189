import { __, constants, IFileNotification } from 'common-services';
import * as React from 'react';

import ProgressBar from '../ProgressBar/ProgressBar.component';
import * as S from './NotificationUpload.styled';

export interface IStateProps {
  notification: IFileNotification;
}

export interface IDispatchProps {
  clear: (fileId: string) => void;
}

export type IProps = IStateProps & IDispatchProps;

const NotificationUpload: React.FC<IProps> = ({ notification }) => {
  if (!notification) return null;
  const isImageFile = notification.file?.type?.startsWith('image/') ?? true;
  return (
    <S.Container toShow={notification.toShow}>
      {notification.file && notification.file.content ? (
        <S.PreviewImg
          src={isImageFile ? notification.file.content : constants.getLogoFromMime(notification.file.type)}
        />
      ) : null}
      <S.Col>
        <S.Title>{notification.file?.name || ''}</S.Title>
        <ProgressBar
          progress={notification.progress || 0}
          title={
            notification.step === 'processing'
              ? __('NotificationUpload.step.processing')
              : __('NotificationUpload.step.uploading')
          }
        />
      </S.Col>
    </S.Container>
  );
};

export default React.memo(NotificationUpload);
