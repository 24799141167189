import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, Input, RowContainer, TextLabel } from '../../atoms';
import TooltipComponent from '../../atoms/Tooltip/Tooltip.component';

interface IStyledProps {
  disabled?: boolean;
  disableHover?: boolean;
  hasOverflow?: boolean;
  hiddenAtScreenWidth?: string;
  isLast?: boolean;
  isSearch?: boolean;
  isSelected?: boolean;
  overlay?: boolean;
  selected?: boolean;
  status?: string;
  theme: ITheme;
}

export const TableContainer = styled.div<IStyledProps>`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: ${(p: IStyledProps) => (p.hasOverflow ? 'auto' : 'visible')};
  height: 100%;
`;

export const SearchStats = styled(TextLabel)`
  display: flex;
  justify-content: flex-start;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const POR = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  line-height: 1.6;
`;
export const HiddenInput = styled.input`
  max-width: 0px;
  max-height: 0px;
  opacity: 0;
`;

const ShareTouchable = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: ${(p: IStyledProps) => (p.disableHover ? 'not-allowed' : 'pointer')};
  align-self: center;
`;

export const ShareTouchableTable = styled(ShareTouchable)`
  margin-left: 0;
`;

export const StatsRow = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const TextBlack = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px 0;
`;

export const TextGrey = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

const getBubbleColor = (p: IStyledProps) => {
  if (p.status === 'inactive') return p.theme.colors.grey2;
  if (p.status === 'active') return p.theme.colors.green1;
  if (p.status === 'unavailable') return p.theme.colors.red1;
  return p.theme.colors.green1;
};

const StatusBubble = styled.div<IStyledProps>`
  width: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  height: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  border-radius: 50%;
  background-color: ${getBubbleColor};
`;

export const OptionRow = styled(RowContainer)<IStyledProps>`
  min-height: 36px;
  max-height: 36px;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 100%;
  &:hover {
    background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.grey4)};
  }
`;

export const StatusBubbleOption = styled(StatusBubble)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextOption = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const PriceInput = styled(Input)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const TextBoldBlack = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SelectButton = styled(Button)`
  min-width: 125px;
  max-width: 125px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const AddButton = styled(Button)`
  min-width: 158px;
  max-width: 158px;
`;

export const RefMissingRow = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CartRow = styled(RowContainer)`
  padding-right: 14%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding-right: 12%;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: 10%;
  }
`;
export const CartRowCenter = styled(CartRow)`
  justify-content: normal;
`;

export const ErrorIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: 18px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const AmountInput = styled(Input)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  &:focus-within {
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  }
`;

export const CloseIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 13px;
  width: 23px;
  height: 13px;
  line-height: 13px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const Close = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 42px;
  min-width: 42px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;
export const Tooltip = styled(TooltipComponent)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-self: center;
`;
export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
