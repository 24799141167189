import { __, addresses as addressUtils, countrySelectors, CURRENCY_CODES, WEIGHT_UNIT } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import theme from '../../../theme';
import { RowContainer, SimpleDropdown } from '../../atoms';
import * as S from './AddressesTable.styled';

export interface IProps {
  addresses: Array<IAddress>;
  addressSelected?: number;
  className?: string;
  disabled?: boolean;
  emptyAddressText: string;
  navAddressAction: (addressId: number | undefined) => void;
  navResetAddressAction: () => void;
  onAddressDelete: (adr: IAddress) => void;
  onMakeAddressAsDefault?: (adr: IAddress) => void;
  showAddButton?: boolean;
  tradeCurrency?: CURRENCY_CODES;
  weightUnit: WEIGHT_UNIT;
}

const AddressesTable: React.FC<IProps> = ({
  addresses,
  className,
  disabled,
  emptyAddressText,
  navAddressAction,
  onAddressDelete,
  onMakeAddressAsDefault,
  showAddButton = false,
}) => {
  return (
    <S.Container className={className}>
      {addresses?.length ? (
        <React.Fragment>
          {addresses
            .sort((a, b) => {
              if (a.isDefault && !b.isDefault) return -1;
              if (!a.isDefault && b.isDefault) return 1;
              const nameA = a.displayName || a.rest;
              const nameB = b.displayName || b.rest;
              return nameA < nameB ? -1 : 1;
            })
            .map((address, idx) => (
              <AddressRow
                address={address}
                className="addresses-table-address-row"
                isFirst={idx === 0}
                key={addressUtils.getAddressKey(address)}
                navAddressAction={navAddressAction}
                onAddressDelete={onAddressDelete}
                onMakeAddressAsDefault={onMakeAddressAsDefault}
              />
            ))}
          {!disabled && showAddButton ? (
            <S.AddMoreCta
              iconName={'Add-more'}
              type="link"
              onClick={() => navAddressAction(undefined)}
              withoutPadding
              id="add-address-link"
            >
              {__('Components.Settings.address.EmptyResults.cta')}
            </S.AddMoreCta>
          ) : null}
        </React.Fragment>
      ) : (
        <S.ZeroCaseContainer className="addresses-empty-address">
          <S.ZeroCaseText>{emptyAddressText}</S.ZeroCaseText>
          {!disabled && showAddButton ? (
            <S.AddMoreCta iconName={'Add-more'} type="link" onClick={() => navAddressAction(undefined)} withoutPadding>
              {__('Components.Settings.address.EmptyResults.cta')}
            </S.AddMoreCta>
          ) : null}
        </S.ZeroCaseContainer>
      )}
    </S.Container>
  );
};

/**
 * Returns a row component of an address item
 */
const AddressRow = ({
  address,
  className,
  isFirst,
  navAddressAction,
  onAddressDelete,
  onMakeAddressAsDefault,
}: {
  address: IAddress;
  className?: string;
  isFirst: boolean;
  navAddressAction: (addressId: number | undefined) => void;
  onAddressDelete: (adr: IAddress) => void;
  onMakeAddressAsDefault: (adr: IAddress) => void;
}) => {
  const countries = useSelector(countrySelectors.getCountries);
  const name = address.displayName || address.rest;
  const thirdLine = [];
  if (address.zip) thirdLine.push(address.zip);
  if (address.city) thirdLine.push(address.city);
  if (address.country) {
    thirdLine.push(countries[`${address.country}`]?.name || address.country);
  }
  return (
    <S.AddressRow className={className} isFirst={isFirst} onClick={() => navAddressAction(address.id)}>
      <RowContainer>
        <S.AddressIconContainer>
          <S.AddressIcon disableHover={true} name="Address" />
        </S.AddressIconContainer>
        <S.AddressInfoCol>
          <S.TextTitle>{name}</S.TextTitle>
          {address.displayName ? <S.TextRegular>{address.rest}</S.TextRegular> : null}
          {thirdLine.length ? <S.TextGrey2>{thirdLine.join(', ')}</S.TextGrey2> : null}
        </S.AddressInfoCol>
      </RowContainer>
      <S.RowCenter>
        {address.isDefault ? (
          <S.TagDefault
            className="address-default-tag"
            color="canceled"
            disabled={true}
            label={__('Components.Settings.default')}
          />
        ) : null}
        <SimpleDropdown
          hAlign="right"
          options={[
            { key: 'edit', value: __('Components.Settings.edit_address') },
            { key: 'delete', value: __('Components.Settings.delete_address'), color: theme.colors.red1 },
            onMakeAddressAsDefault && !address.isDefault
              ? {
                  key: 'default',
                  value: __('Components.Settings.make_default_address'),
                  tooltip: __('Components.Settings.default_address_tooltip'),
                }
              : null,
          ]}
          onSelect={(key: string) => {
            if (key === 'edit') navAddressAction(address.id);
            if (key === 'delete') onAddressDelete(address);
            if (key === 'default') onMakeAddressAsDefault(address);
          }}
        >
          <S.KebabIconContainer>
            <S.KebabIcon name="Kebab" />
          </S.KebabIconContainer>
        </SimpleDropdown>
      </S.RowCenter>
    </S.AddressRow>
  );
};

export default React.memo(AddressesTable);
