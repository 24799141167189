import * as React from 'react';

import * as S from './ButtonFilters.styled';

export interface IProps {
  className?: string;
  keySelected: string;
  onSelectOption: (key: string) => void;
  options: Array<{ key: string; value: string; amount?: number }>;
}

/**
 * Menu line with button filters.
 * @deprecated Check component FilterSwitch
 */
const ButtonFilters: React.FC<IProps> = ({ className, options, onSelectOption, keySelected }) => {
  const selectedIndex = options.findIndex(opt => opt.key === keySelected);
  return (
    <S.Container id="dates-filters" className={className}>
      {options.map(({ key, value, amount }, index, array) => {
        const isFirst = index === 0;
        const isLast = index === array.length - 1;
        const isSelected = key === keySelected;
        return (
          <React.Fragment key={index + key}>
            <S.OptionItem isFirst={isFirst} isLast={isLast} isSelected={isSelected} onClick={() => onSelectOption(key)}>
              <S.Text isSelected={isSelected}>
                {value} {amount ? `(${amount})` : ''}
              </S.Text>
            </S.OptionItem>
            <S.Text>{!isLast && !isSelected && index !== selectedIndex - 1 ? '|' : null}</S.Text>
          </React.Fragment>
        );
      })}
    </S.Container>
  );
};

export default React.memo(ButtonFilters);
