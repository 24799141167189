import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  imageSize?: number;
  isLast?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  flex: 1;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
`;

export const Image = styled.img`
  width: ${(p: IStyledProps) => p.imageSize}px;
  height: ${(p: IStyledProps) => p.imageSize}px;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Title = styled.p`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  flex: 1;
  align-self: center;
  text-align: center;
`;

export const Body = styled.p`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const CTAContainer = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CTA = styled(Button)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 0 : 3)};
  min-width: 220px;
  align-self: center;
`;

export const LinkContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Row = styled(RowContainer)``;
