import emoji from '@jukben/emoji-search';
import { __, IUser, utils } from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { ACCEPT_ALL_FILES } from '../../../constants';
import { FontIcon, LettersAvatar } from '../../atoms';
import * as S from './MessageInput.styled';

interface IProps {
  autoFocus?: boolean;
  className?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  me?: IUser;
  name?: string;
  onChangeText: (text: string) => void;
  onFileUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e?) => void;
  onInputRef?: (HTMLTextAreaElement) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onSendFiles?: (files: Array<File>) => void;
  onSendMessage?: (text: string) => void;
  placeholder?: string;
  sendButtonId?: string;
  sendMultipleFiles?: boolean;
  showEmojiSelector?: boolean;
  showSendButton?: boolean;
  showSendFiles: boolean;
  text: string;
  maxLength?: number;
}

const MessageInput = (props: IProps) => {
  const {
    autoFocus = false,
    className,
    inputRef,
    me,
    name,
    onChangeText,
    onFileUpload,
    onFocus,
    onInputRef,
    onKeyDown,
    onSendFiles,
    onSendMessage,
    placeholder,
    sendButtonId,
    sendMultipleFiles = false,
    showEmojiSelector,
    showSendFiles,
    showSendButton = true,
    text,
    maxLength,
  } = props;

  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [emojisSuggestions, setEmojisSuggestions] = React.useState<Array<{ char: string; name: string }>>();
  const [emojiSearch, setEmojiSearch] = React.useState('');
  const [emojiSelected, setEmojiSelected] = React.useState(0);
  const innerInputRef = React.useRef<HTMLTextAreaElement>();
  const isLogged = !!(me?.id && me?.name);

  /**
   * Hide and reset state for emojis suggestions modal
   */
  const resetEmojiSuggestions = React.useCallback(() => {
    setEmojisSuggestions(undefined);
    setEmojiSelected(0);
    setEmojiSearch('');
  }, []);

  /**
   * Focus input caret at a given position
   */
  const focusWithCaretAtPosition = React.useCallback((input: HTMLTextAreaElement, position: number) => {
    if (input) {
      input.focus();
      input.setSelectionRange(position, position);
    }
  }, []);

  /**
   * Check input content to display or not the emojis suggestion modal
   */
  const showEmojisSuggestions = React.useCallback(() => {
    const input = (inputRef || innerInputRef)?.current;
    if (input) {
      const textBeforeCaret = input.value.slice(0, input.selectionStart) as string;
      const textFromLastColon = utils.getTextAfterLastMention(input.value, input.selectionStart, ':');

      const normalizedEmojiSearch = utils.toLocaleLowerCaseNormalized(textFromLastColon);
      if (normalizedEmojiSearch.length === 0 && !textBeforeCaret.endsWith(':')) {
        resetEmojiSuggestions();
        return;
      }

      const emojiSuggestions = emoji(normalizedEmojiSearch)
        .slice(0, 10)
        .map(({ name: emojiName, char }) => ({ name: emojiName, char }));

      setEmojisSuggestions(emojiSuggestions);
      setEmojiSearch(textFromLastColon);
    }
  }, [inputRef, innerInputRef, resetEmojiSuggestions]);

  /**
   * Add emoji to input
   */
  const addEmojiToInput = React.useCallback(
    (emojiName: string, emojiChar: string) => {
      const input = (inputRef || innerInputRef)?.current;
      if (input) {
        const inputText = input.value;
        const emojiNameIndex = inputText.indexOf(emojiName);
        if (emojiNameIndex === -1) return;

        const textAfterEmoji = inputText.slice(emojiNameIndex + emojiName.length);
        const textBeforeAndEmoji = inputText.slice(0, emojiNameIndex) + emojiChar;
        const newText = textBeforeAndEmoji + textAfterEmoji;

        onChangeText(newText);
        resetEmojiSuggestions();

        setTimeout(() => {
          focusWithCaretAtPosition(input, textBeforeAndEmoji.length - 1);
          showEmojisSuggestions();
        });
      }
    },
    [inputRef, innerInputRef, focusWithCaretAtPosition, onChangeText, resetEmojiSuggestions, showEmojisSuggestions],
  );

  return (
    <Dropzone
      onDrop={acceptedFiles => onSendFiles?.(acceptedFiles)}
      noClick={true}
      disabled={!showSendFiles}
      multiple={sendMultipleFiles}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <S.RowInput {...getRootProps({ isDragActive })} className={className}>
          {isLogged ? (
            <LettersAvatar
              size={36}
              text={me.name}
              img={me.settings && me.settings.avatar}
              avatarColor={utils.getAvatarColor(me.name)}
            />
          ) : null}
          <S.RowInputContainer isDragActive={isDragActive} isLogged={isLogged}>
            <input {...getInputProps()} hidden accept={ACCEPT_ALL_FILES} />
            {showSendFiles ? (
              <S.IconLabel htmlFor="upload-file-input">
                <S.FileIcon id="upload-file" name="Plus" onClick={() => null} />
                <input
                  type="file"
                  id="upload-file-input"
                  onChange={onFileUpload}
                  hidden
                  accept={ACCEPT_ALL_FILES}
                  multiple={sendMultipleFiles}
                />
              </S.IconLabel>
            ) : null}
            {showEmojiSelector ? (
              <EmojiPicker
                postContent={text}
                setPostContent={onChangeText}
                showEmojiPicker={showEmojiPicker}
                toggleEmojiPicker={setShowEmojiPicker}
              />
            ) : null}
            <S.Input
              autoFocus={autoFocus}
              autoIncreaseHeight={true}
              inputRefObject={inputRef}
              inputRef={(ref: HTMLTextAreaElement) => {
                onInputRef?.(ref);
                innerInputRef.current = ref;
              }}
              maxLength={maxLength || 512}
              name={name}
              onChange={(_, val) => {
                onChangeText((val + '').trimStart());
                showEmojisSuggestions();
              }}
              onFocus={() => {
                onFocus?.();
                showEmojisSuggestions();
              }}
              onKeyDown={e => {
                if (emojisSuggestions?.length) {
                  if (['Tab', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape'].includes(e.key)) e.preventDefault();
                  switch (e.key) {
                    case 'ArrowDown':
                      setEmojiSelected(emojiSelected > 0 ? emojiSelected - 1 : emojisSuggestions.length - 1);
                      break;
                    case 'Tab':
                    case 'ArrowUp':
                      setEmojiSelected(emojiSelected < emojisSuggestions.length - 1 ? emojiSelected + 1 : 0);
                      break;
                    case 'Enter':
                      const currentEmoji = emojisSuggestions[emojiSelected];
                      addEmojiToInput(':' + emojiSearch, currentEmoji.char);
                      break;
                    case 'Escape':
                      resetEmojiSuggestions();
                      break;
                    default:
                      break;
                  }
                  return;
                }

                if (onKeyDown) onKeyDown(e);
                else if (text && e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                  e.preventDefault();
                  onSendMessage(text);
                }
              }}
              placeholder={placeholder || __('Components.OrderDetails.comments_placeholder')}
              rows={1}
              showCharactersCount={false}
              value={text}
            />
            {showSendButton ? (
              <S.SendIcon
                id={sendButtonId || 'message-input-send-cta'}
                name="Send-solid"
                onClick={text ? () => onSendMessage(text) : null}
                hasText={!!text?.length}
                disableHover={!text}
              />
            ) : null}
          </S.RowInputContainer>

          {emojisSuggestions?.length ? (
            <S.EmojisModal className="emojis-selection-modal">
              {emojisSuggestions.map((emojiSuggest, idx) => (
                <S.EmojiItem
                  isSelected={idx === emojiSelected}
                  key={idx}
                  onClick={() => addEmojiToInput(':' + emojiSearch, emojiSuggest.char)}
                >
                  {emojiSuggest.char}
                </S.EmojiItem>
              ))}
            </S.EmojisModal>
          ) : null}
        </S.RowInput>
      )}
    </Dropzone>
  );
};

interface IEmojiPickerProps {
  postContent: string;
  showEmojiPicker: boolean;
  toggleEmojiPicker: (toShow: boolean) => void;
  setPostContent: (value: string) => void;
}
const Picker = React.lazy(() => import('emoji-picker-react'));

const EmojiPicker: React.FC<IEmojiPickerProps> = ({
  postContent,
  setPostContent,
  showEmojiPicker,
  toggleEmojiPicker,
}) => {
  const emojiContainerRef = React.createRef<HTMLDivElement>();
  /**
   * On click outside, hide emoji picker
   */
  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (emojiContainerRef?.current && !emojiContainerRef.current.contains(event.target as any)) {
        toggleEmojiPicker(false);
      }
    },
    [emojiContainerRef, toggleEmojiPicker],
  );
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <S.EmojiRow onClick={() => toggleEmojiPicker(!showEmojiPicker)}>
      <S.FontIconWrapper>
        <FontIcon name="Emoji" />
      </S.FontIconWrapper>
      <S.EmojiWrapper hidden={!showEmojiPicker} ref={emojiContainerRef} onClick={e => e.stopPropagation()}>
        {showEmojiPicker ? (
          <Picker
            onEmojiClick={(e, data) => {
              e.stopPropagation();
              setPostContent(postContent + data.emoji);
            }}
            disableSearchBar={false}
            searchPlaceholder={__('Components.Chat.emoji_search_placeholder')}
            disableSkinTonePicker={true}
            preload={true}
            native
          />
        ) : null}
      </S.EmojiWrapper>
    </S.EmojiRow>
  );
};

export default React.memo(MessageInput);
