import { constants, ICountry, IProdType, slugify } from 'common-services';
import { getCategoryText, getOrganicText } from 'common-services/dist/parsers';

/**
 * Get product categories formatted for select component
 */
export function getCategories(): Array<any> {
  return [
    {
      value: '',
      label: '',
    },
    ...constants.PRODUCT_CATEGORIES.map(pc => ({
      value: pc,
      label: getCategoryText(pc),
    })),
  ];
}

/**
 * Get product types formatted for select component
 */
export function getProductTypes(prodTypes: { [key: string]: IProdType }): Array<{ label: string; value: string }> {
  let productTypes: Array<{ label: string; value: string; name: string }> = [];
  for (const [key, value] of Object.entries(prodTypes)) {
    productTypes.push({ label: value.name, name: key, value: key });
  }
  productTypes = [
    ...productTypes.sort((a, b) => {
      return slugify(a.label) < slugify(b.label) ? -1 : 1;
    }),
  ];
  const otherType = productTypes.find(e => e.value === '-');
  productTypes.splice(productTypes.indexOf(otherType), 1);
  return [{ value: '', label: '' }, ...productTypes, otherType];
}

/**
 * Get countries formatted for select component
 */
export function getCountries(countries: { [k: string]: ICountry }): Array<{ label: string; value: string }> {
  const origins = Object.keys(countries)
    .filter(k => countries[k].originAllowed)
    .map(k => ({ label: countries[k].name, value: countries[k].iso2Code }))
    .sort((a: any, b: any) => (slugify(a.label) > slugify(b.label) ? 1 : -1));
  return [{ value: '', label: '' }, ...origins];
}

/**
 * Get organic types formatted for select component
 */
export function getOrganicTypes() {
  return constants.ORGANIC_TYPES.map(b => ({
    ...b,
    label: getOrganicText(b.label as IOrganic),
  }));
}
/**
 * Max picture count that can have a product
 */
export const MAX_UPLOAD_PICTURES = 4;
/**
 * Max tag length
 */
export const MAX_TAG_LENGTH = 50;
