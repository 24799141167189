import { IPricelist } from 'common-services';

import { Action, PricelistTypes } from '../actions/pricelist';

export interface IReduxStatePricelist {
  error?: number;
  loading: boolean;
  pricelist?: IPricelist;
  email?: string;
}
const INITIAL_STATE: IReduxStatePricelist = {
  loading: false,
};

export default function pricelistReducer(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case PricelistTypes.PRICELIST_GET:
      return { ...state, loading: action.loading };
    case PricelistTypes.PRICELIST_GET_SUCCESS:
      return { ...state, loading: action.loading, pricelist: action.pricelist };
    case PricelistTypes.PRICELIST_GET_ERROR:
      return { ...state, loading: action.loading, error: action.error, pricelist: undefined };
    case PricelistTypes.PRICELIST_EMAIL:
      return { ...state, email: action.email };
    case 'RESET_STATE': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
