export const getBuildNumber = () => '';
export const getDeviceLocale = () => (navigator.languages ? navigator.languages[0] : navigator.language);
export const isEmulator = () => '';
export const getReadableVersion = () => {
  if (typeof window !== 'undefined') {
    return document.querySelector('meta[name="ui-version"]').getAttribute('content');
  }
  return '';
};
export const getUniqueID = () => '';
export const getModel = () => '';
export const getSystemVersion = () => '';
export const getSystemName = () => '';
