export function isRequiredValidation(value: string | number): boolean {
  if (!value) return true;
  if (isNaN(Number(value))) return value.toString().trim().length === 0;
  return !Number(value);
}

export function isNumberValidation(value: string): boolean {
  return value ? !Number(value) : false;
}

export function maxLengthValidation(value: string, maxLengthNum: number): boolean {
  return !value || value.length > maxLengthNum;
}

/**
 * Validate phone number. Checks if it is a number and length greater than 9 and lower than 15.
 */
export function validatePhoneNumber(phoneNumber: string): boolean {
  if (isNaN(Number(phoneNumber))) return false;
  return phoneNumber.length >= 9 && phoneNumber.length <= 15;
}