import { utils } from 'common-services';

import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer/FlexContainer.styled';
import FontIcon from '../FontIcon/FontIcon.component';

interface IStyledProps {
  hasImage?: boolean;
  theme: ITheme;
  timestamp?: number;
  size?: number;
  isActive?: boolean;
}

export const Container = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  width: ${(p: IStyledProps) => p.size}px;
  min-width: ${(p: IStyledProps) => p.size}px;
  height: ${(p: IStyledProps) => p.size}px;
  background-color: ${(p: IStyledProps) => (p.hasImage ? p.theme.colors.transparent : utils.getTeamColor(p.timestamp))};
  position: relative;
`;

export const Image = styled.img`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  width: ${(p: IStyledProps) => p.size}px;
  height: ${(p: IStyledProps) => p.size}px;
  object-fit: cover;
`;

export const TeamIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.size * (2 / 3)}px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  width: ${(p: IStyledProps) => p.size * (2 / 3)}px;
  height: ${(p: IStyledProps) => p.size * (2 / 3)}px; ;
`;

export const ActiveIndicator = styled.div`
  width: ${(p: IStyledProps) => Math.min(15, p.size * (2 / 5))}px;
  height: ${(p: IStyledProps) => Math.min(15, p.size * (2 / 5))}px;
  background-color: ${(p: IStyledProps) => (p.isActive ? p.theme.colors.green1 : p.theme.colors.grey2)};
  border: 2px solid ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(45%) translateY(30%);
`;
