import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, DropzoneContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  contentGrow?: boolean;
  isSelected?: boolean;
  minHeight?: string;
  onlyContent?: boolean;
  theme: ITheme;
  width?: string;
  overFlowVisible?: boolean;
}

export const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  padding: 40px;
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled(DropzoneContainer)<IStyledProps>`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  min-width: min(90vw, ${(p: IStyledProps) => p.width || '580px'});
  align-self: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-width: ${(p: IStyledProps) => p.width || '580px'};
  overflow: ${(p: IStyledProps) => (p.overFlowVisible ? 'visible' : 'hidden')};
  max-height: max(
    ${(p: IStyledProps) => `${p.theme.windowHeight} - 2 * ${p.theme.headerHeight}, ${p.minHeight || '650px'}`}
  );
  min-height: min(
    ${(p: IStyledProps) => `${p.theme.windowHeight} - 2 * ${p.theme.headerHeight}, ${p.minHeight || '650px'}`}
  );
  position: relative;

  @media (max-height: 680px) {
    max-height: min(
      ${(p: IStyledProps) => `${p.theme.windowHeight} - 1.5 * ${p.theme.headerHeight}, ${p.minHeight || '650px'}`}
    );

    ${(p: IStyledProps) =>
      css`
        min-height: min(${p.theme.windowHeight} - 1.5 * ${p.theme.headerHeight}, ${p.minHeight || '650px'});
      `}
  }
`;

export const Wrapper = styled(ColumnContainer)<IStyledProps>`
  max-height: ${(p: IStyledProps) => (p.onlyContent ? 'auto' : '100%')};
  overflow: ${(p: IStyledProps) => (p.overFlowVisible ? 'visible' : 'auto')};
  ${(p: IStyledProps) =>
    p.contentGrow &&
    css`
      flex: 1;
    `}
`;

export const Header = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  z-index: 2;
`;

export const TitleColumn = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  flex: 1;
`;

export const TitleRow = styled(RowContainer)``;

export const BackButton = styled(FontIcon)`
  height: 35px;
  width: 35px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: 50%;
  padding-left: 10px;
`;

export const BackButtonTitle = styled(BackButton)`
  position: relative;
  top: -6px;
`;

export const StepsRow = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  align-items: center;
`;

export const Steps = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const Skip = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  cursor: pointer;
`;

export const Title = styled(TextLabel)`
  line-height: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: flex;
`;

export const SubTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
