import * as React from 'react';

import * as S from './CheckBox.styled';

export interface IProps {
  className?: string;
  size?: number;
  isChecked?: boolean;
  disabled?: boolean;
  onClick?: (checked: boolean, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CheckBox: React.FC<IProps> = ({
  className,
  size = 20,
  onClick = () => null,
  isChecked = false,
  disabled = false,
}) => {
  return (
    <S.Container
      className={className}
      disabled={disabled}
      onClick={e => (disabled ? undefined : onClick(!isChecked, e))}
    >
      <S.CheckBox size={size} isChecked={isChecked} disabled={disabled}>
        {isChecked ? <S.Icon name="Checkmark" size={size} disableHover={true} /> : null}
      </S.CheckBox>
    </S.Container>
  );
};

export default React.memo(CheckBox);
