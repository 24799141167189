import { EventTrack, qs } from 'common-services';

/**
 * Verify if have a invite code in the querystring and track it
 */
export function trackQueryParams() {
  let h: string;
  let c: string;
  if (typeof window !== 'undefined') {
    const inv = qs.parse(window.location.search, ['h', 'c']) as { h: string; c: string };
    // We fucked up and used the name query parameter "h" for tracking invite hashes
    // on invite system and to force the user hash id when creating sharing links
    // If we don't override this when in pricelist, register won't succeed
    //
    // We must change one of the query parameter names
    if (window.location.pathname.startsWith('/pricelist/')) {
      h = '';
      c = '';
    } else {
      h = inv.h;
      c = inv.c;
    }
  }
  if (h) {
    EventTrack.track('invite_visit', {
      hashId: h,
      channelId: c,
    });
  }
}
