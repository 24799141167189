import { __, utils } from 'common-services';
import * as React from 'react';

import { FontIcon, Tooltip } from '../../atoms';
import { IFontIconKeys } from '../../atoms/FontIcon/FontIcon.component';
import * as S from './InputWithLabel.styled';

interface IProps {
  alertIcon?: IFontIconKeys;
  align?: string;
  asColumn?: boolean;
  children: React.ReactNode;
  className?: string;
  description?: string;
  disabled?: boolean;
  footerText?: string | Array<string | void>;
  hideRequiredText?: boolean;
  id?: string;
  isRecommended?: boolean;
  isRequired: boolean;
  isVerified?: boolean;
  label?: string;
  tooltipText?: string;
  width?: string;
}

/**
 * Container with label for input
 */
export default React.memo(
  ({
    alertIcon,
    align,
    asColumn,
    children,
    className,
    description,
    disabled,
    footerText,
    hideRequiredText,
    id,
    isRecommended,
    isRequired,
    isVerified,
    label,
    tooltipText,
    width,
  }: IProps) => {
    return (
      <S.Container width={width} align={align} isVerified={isVerified} className={className} id={id}>
        {disabled || !label ? null : (
          <S.InputLabel>
            {label.trim() !== '' ? <S.InputLabelName>{formatTextHiglighted(label)}</S.InputLabelName> : null}
            {!isRequired && !hideRequiredText && !isRecommended && (
              <S.InputLabelOptional> {__('Components.Form.optional')}</S.InputLabelOptional>
            )}
            {!isRequired && !hideRequiredText && isRecommended && (
              <>
                <S.InputLabelOptional> {__('Components.Form.recommended')}</S.InputLabelOptional>
                <Tooltip position="right" text={__('Components.Form.tooltip_text')} width="300px" themeMode="dark">
                  <S.InfoIcon disableHover={true} name="Info" />
                </Tooltip>
              </>
            )}
            {tooltipText ? (
              <Tooltip position="right" text={tooltipText} width="300px" themeMode="dark">
                <S.InfoIconBlack disableHover={true} name="Info" />
              </Tooltip>
            ) : null}
            {alertIcon ? (
              <S.AlertIcon name={alertIcon} tooltipLabel={__('WorkspaceSettings.empty_field')} disableHover={true} />
            ) : null}
          </S.InputLabel>
        )}
        {!disabled && isVerified ? (
          <S.CheckIconWrapper id="icon-check-email" disabled={disabled}>
            <FontIcon name="Check" disableHover={true} />
          </S.CheckIconWrapper>
        ) : null}
        {description && !disabled ? <S.InputDescription>{description}</S.InputDescription> : null}
        <S.Wrapper className="wrapper" disabled={disabled} asColumn={asColumn}>
          {disabled && isVerified ? (
            <S.CheckIconWrapper id="icon-check-email" disabled={disabled}>
              <FontIcon name="Check" disableHover={true} />
            </S.CheckIconWrapper>
          ) : null}
          {disabled && label ? <S.InputLabelName>{formatTextHiglighted(label)}</S.InputLabelName> : null}
          {children}
        </S.Wrapper>
        {footerText ? <S.InputDescription>{footerText as string}</S.InputDescription> : null}
      </S.Container>
    );
  },
);

/**
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatTextHiglighted(text: string) {
  return utils
    .firstToUpperCase(text)
    .split('**')
    .map((s, index) => (index % 2 ? <S.TextHiglighted key={s + index}>{s}</S.TextHiglighted> : s));
}
