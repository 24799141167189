import {
  __,
  CHANNEL_TYPE,
  chatActions,
  date,
  IAvatarColor,
  IContact,
  IMessage,
  IOrder,
  IProdType,
  IUser,
  modalActions,
  utils,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';

import { downloadURL } from '../../../services/file';
import { Reaction, SimpleDropdown } from '../../atoms';
import * as M from './Fragments';
import * as S from './Message.styled';

const Picker = React.lazy(() => import('emoji-picker-react'));
export interface IProps {
  addMessageReaction?: (myId: number, channelId: string, messageId: number, reaction: string) => void;
  channelType?: CHANNEL_TYPE;
  className?: string;
  cloneOrder?: (order: IOrder) => void;
  contactEmail?: string;
  contactName?: string;
  contactNameToDisplay?: string;
  contacts?: { [contactId: number]: IContact };
  goToBottom?: () => void;
  history: History<any>;
  imageUrls?: Array<string>;
  isComment?: boolean;
  isDate?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isOrderUpdate?: boolean;
  isRead?: boolean;
  isUnregistered?: boolean;
  me?: IUser;
  members?: Array<IMember>;
  message: IMessage;
  navigateToShowroom?: (
    ownerId: number,
    query?: string,
    productId?: number,
    productHash?: string,
    channelId?: number,
  ) => void;
  onDelete?: (message: IMessage) => void;
  onDownloadImage?: (message: IMessage) => void;
  onEdit?: (text: string, message: IMessage, setEditMessage: (text?: string) => void, mentions?: Array<number>) => void;
  onForward?: (message: IMessage) => void;
  onOrderMessageClick?: (message: IMessage) => void;
  onReply?: (message: IMessage) => void;
  prodTypes?: { [key: string]: IProdType };
  removeMessageReaction?: (myId: number, channelId: string, messageId: number, reaction: string) => void;
  replyFrom?: IMessage;
  replyFromSenderAvatar?: string;
  replyFromSenderAvatarColor?: IAvatarColor;
  replyFromSenderName?: string;
  senderAvatar?: string;
  senderAvatarColor?: IAvatarColor;
  senderName?: string;
  showReadCheck?: boolean;
  touchFile?: typeof chatActions.downloadFile;
  touchImage?: typeof modalActions.touchImage;
  action?: () => void;
}

const Message: React.FC<IProps> = props => {
  const [editMessage, setEditMessage] = React.useState(undefined);
  const [reactionActionOpen, setReactionActionOpen] = React.useState<'bottom' | 'top'>();
  const {
    className,
    isComment,
    isDate,
    isFirst,
    isOrderUpdate,
    isRead,
    me,
    message,
    replyFrom,
    senderAvatar,
    senderAvatarColor,
    senderName,
    showReadCheck = true,
  } = props;
  const hourFormat = me?.settings.hourFormat;
  if (isDate) return <M.DateMessage datetime={message.createdAt} />;
  /**
   * Remove in the future
   */
  if (message.messageType === 'file') {
    message.messageType = message.extraData.type ? message.extraData.type : 'image';
  }
  const { createdAt, messageId, senderId } = message;
  const isAdmin = message.messageType === 'admin';

  return isAdmin && !isOrderUpdate ? (
    renderContent(props, setEditMessage, editMessage)
  ) : (
    <S.Container
      className={className}
      isFirst={isFirst}
      isText={message.messageType === 'text'}
      isEditing={editMessage !== undefined}
      isReactionActionOpen={!!reactionActionOpen}
    >
      <S.DateWrapper>
        {isFirst ? (
          <S.AvatarWrapper>
            <S.Avatar
              text={senderName}
              img={senderAvatar || ''}
              avatarColor={senderAvatarColor}
              size={36}
              type="comment"
            />
            {isComment ? (
              <S.UpdateIcon
                name={isAdmin ? 'Recent' : message.messageType === 'attachment' ? 'Clip' : 'Message'}
                disableHover={true}
              />
            ) : null}
          </S.AvatarWrapper>
        ) : (
          <>
            <S.DateTimeText className="date">{date.formatTime(createdAt, hourFormat)}</S.DateTimeText>
            {messageId && me?.id === senderId && showReadCheck ? (
              <S.CheckIcon
                className="date"
                isRead={isRead}
                disableHover={true}
                name={isRead ? 'Double-check' : 'Check'}
              />
            ) : null}
          </>
        )}
      </S.DateWrapper>
      <S.ColumnTextWrap>
        {isFirst ? (
          <S.WhoWhen>
            {isAdmin ? <S.TextNameGrey2>{senderName}</S.TextNameGrey2> : <S.TextName>{senderName}</S.TextName>}
            <S.DateTimeText>{' · ' + date.formatTime(createdAt, hourFormat)}</S.DateTimeText>
            {messageId && me?.id === senderId && showReadCheck ? (
              <S.CheckIcon isRead={isRead} disableHover={true} name={isRead ? 'Double-check' : 'Check'} />
            ) : null}
          </S.WhoWhen>
        ) : null}
        <S.TextWrap isReply={!!replyFrom}>
          {renderReply(props)}
          {renderContent(props, setEditMessage, editMessage, isOrderUpdate)}
          {editMessage === undefined ? renderReactions(props) : null}
        </S.TextWrap>
      </S.ColumnTextWrap>
      {editMessage === undefined
        ? renderActions(props, setEditMessage, setReactionActionOpen, reactionActionOpen)
        : null}
    </S.Container>
  );
};

/**
 * Render message content according to its type
 */
function renderContent(
  props: IProps,
  setEditMessage: (text?: string) => void,
  editMessage?: string,
  isOrderUpdate?: boolean,
) {
  const {
    action,
    channelType,
    cloneOrder,
    contactEmail,
    contactName,
    contactNameToDisplay,
    contacts,
    goToBottom,
    imageUrls,
    isLast,
    history,
    members,
    message,
    me,
    navigateToShowroom,
    onEdit,
    onOrderMessageClick,
    prodTypes,
    touchFile,
    touchImage,
  } = props;
  switch (message.messageType) {
    case 'text':
      return (
        <M.TextMessage
          contacts={contacts}
          channelType={channelType}
          editMessage={editMessage}
          goToBottom={goToBottom}
          history={history}
          isLast={isLast}
          members={members!}
          message={message}
          me={me}
          navigateToShowroom={navigateToShowroom!}
          onEditMessageChange={setEditMessage}
          onTouchImage={touchImage}
          onSubmitEdit={onEdit}
        />
      );
    case 'attachment':
      return <M.AttachmentMessage message={message} touchFile={touchFile} touchImage={touchImage} />;
    case 'file':
      return <M.FileMessage message={message} touchFile={touchFile} />;
    case 'image':
      return (
        <M.ImageMessage
          imageUrls={imageUrls}
          message={message}
          onDownloadImage={downloadImage}
          touchImage={touchImage}
        />
      );
    case 'video':
      return <M.VideoMessage message={message} />;
    case 'order':
      return (
        <M.OrderMessage
          cloneOrder={cloneOrder}
          contactEmail={contactEmail}
          contactName={contactName}
          contactNameToDisplay={contactNameToDisplay}
          contacts={contacts}
          dateFormat={me?.settings.dateFormat}
          hourFormat={me?.settings.hourFormat}
          message={message}
          myId={me.id}
          onClick={onOrderMessageClick}
          prodTypes={prodTypes!}
        />
      );
    case 'product':
      return <M.ProductMessage message={message} touchProduct={navigateToShowroom} />;
    case 'admin':
      return isOrderUpdate ? (
        <M.OrderUpdateMessage message={message} />
      ) : (
        <M.AdminMessage
          members={members}
          myId={me?.id}
          message={message}
          hourFormat={me?.settings.hourFormat}
          action={action}
        />
      );
    default:
      return null;
  }
}

/**
 * Render message reply
 */
function renderReply(props: IProps) {
  const {
    me,
    members,
    replyFrom,
    replyFromSenderAvatar,
    replyFromSenderAvatarColor,
    replyFromSenderName,
    touchFile,
    touchImage,
  } = props;
  return replyFrom ? (
    <M.ReplyMessage
      enableSeeAllMessage={true}
      hourFormat={me.settings.hourFormat}
      me={me}
      members={members}
      message={replyFrom}
      onTouchFile={touchFile}
      onTouchImage={touchImage}
      senderAvatar={replyFromSenderAvatar}
      senderAvatarColor={replyFromSenderAvatarColor}
      senderName={replyFromSenderName}
    />
  ) : null;
}

/**
 * Render message reactions
 */
function renderReactions(props: IProps) {
  const { addMessageReaction, members, message, me, removeMessageReaction } = props;
  if (message.isRemoved || !message.reactions || !Object.keys(message.reactions).length) return null;
  return (
    <S.Row>
      {Object.keys(message.reactions).map(r => (
        <Reaction
          key={r}
          code={r}
          myId={me?.id}
          contactsId={message.reactions[r]}
          members={members}
          onClick={() =>
            message.reactions[r].includes(me?.id + '')
              ? removeMessageReaction(me?.id, message.channelId, message.messageId, r)
              : addMessageReaction(me?.id, message.channelId, message.messageId, r)
          }
        />
      ))}
    </S.Row>
  );
}

/**
 * Render actions row
 */
function renderActions(
  props: IProps,
  setEditMessage: (text?: string) => void,
  setReactionActionOpen: (open?: 'bottom' | 'top') => void,
  reactionActionOpen?: 'bottom' | 'top',
) {
  const { addMessageReaction, removeMessageReaction, message, me, onDelete, onEdit, onForward, onReply } = props;
  const amSender = message.senderId === me?.id;
  if (
    !['text', 'image', 'file', 'video'].includes(message.messageType) ||
    (!onReply && !onForward && !addMessageReaction && !onDelete) ||
    message.isRemoved
  )
    return null;
  const actionsId = `messages-actions-${message.messageId}`;
  return (
    <S.Actions
      className="message-actions"
      onClick={e => e.stopPropagation()}
      id={actionsId}
      reactionActionOpen={reactionActionOpen}
    >
      <SimpleDropdown
        onClickOutside={() => setReactionActionOpen(undefined)}
        onOpen={() => {
          const messageActions = document.getElementById(actionsId);
          if (messageActions) {
            const actionsRect = messageActions.getBoundingClientRect();
            // 300 is the approximate height of the emoji picker
            if (actionsRect.top - 106 <= 300) {
              setReactionActionOpen('bottom');
            } else {
              setReactionActionOpen('top');
            }
          }
        }}
        Content={
          <Picker
            onEmojiClick={(e, data) => {
              // If the selected reaction has already been reacted by the user, remove it
              const myId = me?.id;
              const hasUserAlreadyReacted = message.reactions[data.unified]?.includes(`${myId}`);
              if (hasUserAlreadyReacted) {
                removeMessageReaction(myId, message.channelId, message.messageId, data.unified);
              } else {
                addMessageReaction(myId, message.channelId, message.messageId, data.unified);
              }
            }}
            disableSearchBar={false}
            disableSkinTonePicker={true}
            preload={true}
            native
          />
        }
      >
        <S.Action>
          <S.OptionIcon name="Add-reaction" />
        </S.Action>
      </SimpleDropdown>
      {onReply ? (
        <S.Action onClick={() => onReply(message)}>
          <S.Reply name="Forward-web" />
          <S.ReplyText className="actionText">{__('Components.Chat.reply')}</S.ReplyText>
        </S.Action>
      ) : null}
      {onForward ? (
        <S.Action onClick={() => onForward(message)}>
          <S.OptionIcon name="Forward-web" />
          <S.ForwardText className="actionText">{__('Components.Chat.forward')}</S.ForwardText>
        </S.Action>
      ) : null}
      {onDelete && amSender ? (
        <S.Action onClick={() => onDelete(message)}>
          <S.OptionIcon name="Trash" />
          <S.ForwardText className="actionText">{__('Components.Chat.delete')}</S.ForwardText>
        </S.Action>
      ) : null}
      {onEdit && amSender ? (
        <S.Action onClick={() => setEditMessage(message.message)}>
          <S.OptionIcon name="Edit" />
          <S.ForwardText className="actionText">{__('Components.Chat.edit')}</S.ForwardText>
        </S.Action>
      ) : null}
    </S.Actions>
  );
}

function downloadImage(msg: IMessage): void {
  const extension = utils.getFileExtension(msg.message);
  const fileName = 'CONSENTIO-IMG-' + date.formatDate(Date.now(), 'yyyy-MM-dd') + '.' + extension;
  downloadURL(msg.message, fileName);
}

export default Message;
