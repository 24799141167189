import { __, chatSelectors, date, IInternalNotification, notificationsService, qs, utils } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import * as S from './NotificationCard.styled';

export interface IProps {
  me: IUser;
  notification: IInternalNotification;
  workspace: IWorkspace;
  markAsRead: (id: number) => void;
  selectedTab?: string;
}

const NotificationCard: React.FC<IProps> = ({ me, markAsRead, notification, workspace, selectedTab }) => {
  const history = useHistory();
  const channel = useSelector(chatSelectors.getChannelByContactId(notification.extraData?.userId || 0));

  const { orderHashId, workspaceType, jobId } = notification.extraData || {};

  const notificationText = notificationsService.getNotificationLiterals(notification, workspace);
  const notificationNavigate = React.useCallback(() => {
    switch (notificationText.actionType) {
      case 'order':
        history.push(
          getPath({
            path: ROUTE_PATHS.ORDER_LIST,
            hashId: orderHashId,
          }),
        );
        break;
      case 'chat':
        history.replace?.(
          getPath({
            path: ROUTE_PATHS.CHAT,
            channelId: channel?.id,
          }),
        );
        break;
      case 'chatList':
        history.push(
          getPath({
            path: ROUTE_PATHS.CHAT_LIST,
          }),
        );
        break;
      case 'workspace':
        history.push(
          getPath({
            path: workspaceType === 'buyer' ? ROUTE_PATHS.WORKSPACES_BUYER : ROUTE_PATHS.WORKSPACES_SELLER,
          }),
        );
        break;
      case 'request':
        history.push(
          getPath({
            path: ROUTE_PATHS.SALES,
          }) + qs.stringify({ range: 'all', view_mode: 'request' }),
        );
        break;
      case 'magic_order':
        history.push(
          getPath({
            path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
            workspaceId: workspace.id + '',
            jobId: jobId + '',
          }),
        );
        break;
      case 'comment':
        history.push(
          getPath({
            path: ROUTE_PATHS.ORDER_LIST,
            hashId: orderHashId,
          }),
        );
        setTimeout(() => {
          const commentSection = document.getElementById('comments');
          commentSection?.scrollIntoView();
        }, 0);
        break;
    }
  }, [history, orderHashId, channel, notificationText.actionType, workspaceType]);

  return (
    <S.Container
      className="notificationCard-container"
      onClick={() => {
        markAsRead(notification.id);
        notificationNavigate();
      }}
    >
      <S.NotificationIcon name={notificationText.icon || 'Warning'} notificationType={notification.type} />
      <S.TextContainer>
        <S.TextWrapper>
          {
            notificationText.titleComment
              ? (utils.formatText(
                  notificationText.titleComment,
                  (text: string) =>
                    text ? <S.NotificationTitleBold key={text}>{text}</S.NotificationTitleBold> : null,
                  (text: string) =>
                    text ? (
                      <S.NotificationTitle key={text}>
                        {text.length > 150 ? text.substring(0, 150) + ' ...' : text}
                      </S.NotificationTitle>
                    ) : null,
                ) as any)
              : // TYPEERROR
                (utils.formatText(
                  notificationText.title,
                  (text: string) =>
                    text ? <S.NotificationTitleBold key={text}>{text}</S.NotificationTitleBold> : null,
                  (text: string) => (text ? <S.NotificationTitle key={text}>{text}</S.NotificationTitle> : null),
                ) as any) // TYPEERROR
          }
        </S.TextWrapper>
        <S.Cta>{notificationText.action}</S.Cta>
      </S.TextContainer>
      <S.RightColumn>
        <S.TextGrey2>
          {date.formatLongDate(notification.createdAt, undefined, undefined, 'dd MMM') +
            ' · ' +
            date.dateToText(notification.createdAt, me.settings.hourFormat).hour}
        </S.TextGrey2>
        <S.AlertWrapper>
          {selectedTab === 'all' && notification.type === 'alert' ? (
            <S.TextOrange isRead={notification.isRead}>{__('MainHeader.notification_center.alert')}</S.TextOrange>
          ) : null}
          {!notification.isRead ? <S.UnreadIndicator /> : null}
        </S.AlertWrapper>
      </S.RightColumn>
    </S.Container>
  );
};

export default React.memo(NotificationCard);
