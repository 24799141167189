import { __, IUser, userActions } from 'common-services';
import * as React from 'react';

import { Input } from '../../../../atoms';
import * as CS from '../../2FAModal.styled';

interface IProps {
  checkPassword: typeof userActions.checkPassword;
  goToNextStep: (password: string) => void;
  hasPhoneNumber: boolean;
  loading: boolean;
  me: IUser;
  update2fa: typeof userActions.update2fa;
}

interface IState {
  errors: Map<string, string>;
  password: string;
  textError: string;
}

/**
 * Enter password + forget password link
 */
export default class Password extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      errors: new Map(),
      password: '',
      textError: '',
    };
  }

  public render() {
    const { loading } = this.props;
    const { errors, password, textError } = this.state;
    return (
      <CS.StepContainer>
        <form
          autoComplete="off"
          id="form-login-password"
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit();
          }}
        >
          <CS.Card id="2fa-login-password">
            <CS.Title>{__('TwoFAModal.Password.title')}</CS.Title>
            <CS.TextSubtitle>{__('TwoFAModal.Password.subtitle')}</CS.TextSubtitle>

            <Input
              autoComplete="new-password"
              name="password-2fa"
              placeholder={__('Components.Onboarding.Register.password_placeholder')}
              value={password}
              isRequired={true}
              type="password"
              onChange={(name, value) => {
                this.setError(name, value ? '' : 'empty');
                this.setState({ password: value + '' });
              }}
              hasError={!!(errors.get('password-2fa') || textError)}
              containerMargin="0 0 20px"
              autoFocus={true}
            />

            {textError ? (
              <CS.TextErrorContainer>
                <CS.TextError>{textError}</CS.TextError>
              </CS.TextErrorContainer>
            ) : null}

            <CS.SubmitButton
              disabled={loading || errors.size > 0}
              id="submit-password"
              loading={loading}
              type="principal"
            >
              {__('TwoFAModal.Password.cta')}
            </CS.SubmitButton>
          </CS.Card>
        </form>
      </CS.StepContainer>
    );
  }

  /**
   * Set error for a particular field represented by key
   */
  private setError(key: string, error: string) {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  }

  /**
   * Handle on submit click for checking password.
   * Validate form and exit if errors.
   */
  private onSubmit() {
    const { checkPassword, hasPhoneNumber, me, goToNextStep, update2fa } = this.props;
    const { password } = this.state;
    const validationErrors = this.validateForm();
    this.setState({ errors: validationErrors, textError: '' });
    if (validationErrors.size === 0) {
      checkPassword(me.id, password, (err?: Error) => {
        if (!err) {
          if (!hasPhoneNumber) return goToNextStep(password);
          update2fa(
            me.id,
            {
              enable: false,
              password,
              code: '',
            },
            (err2?: Error) => {
              if (!err2) {
                goToNextStep(password);
              }
            },
          );
        } else {
          const textError = __('TwoFAModal.Password.error');
          this.setState({ textError });
        }
      });
    }
  }

  /**
   * Validate form data (field not empty)
   */
  private validateForm() {
    const { password } = this.state;
    const errorsResult = new Map();
    if (!password) errorsResult.set('password-2fa', 'empty');
    return errorsResult;
  }
}
