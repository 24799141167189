import {
  countrySelectors,
  currency,
  IContact,
  ImageGalleryObject,
  IOrderAggregationItem,
  ISearchOrder,
  orderService,
  prodTypeSelectors,
  productService,
  utils,
} from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import MiniImages from '../../MiniImages';
import * as S from '../OrderDelivery.styled';

interface IProps {
  contacts: { [cId: number]: IContact };
  searchState: ISearchOrder;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  orderItem: IOrderAggregationItem;
  first: boolean;
}

const OrderDeliveryRow: React.FC<IProps> = ({ first, searchState, contacts, orderItem, touchImage }) => {
  const countries = useSelector(countrySelectors.getCountries);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  let priceDisplay = '-';
  if (orderItem.totalPrice === 0 && orderItem.isPor) priceDisplay = 'P.O.R';
  else if (orderItem.priceMode !== 'none')
    priceDisplay = currency.getPriceAbbreviation(searchState.currency, orderItem.totalPrice);
  let priceUnit = '-';
  if (orderItem.totalPrice === 0 && orderItem.isPor) priceUnit = 'P.O.R';
  else if (orderItem.priceMode !== 'none')
    priceUnit = currency.getPricePerUnit(
      orderItem.currency,
      orderItem.priceUnit,
      orderItem.weightUnit,
      orderItem.price,
      orderItem.pricePrecision,
    );

  const typeVariety = productService.getProductTypeVarietyDisplay(
    orderItem.type,
    prodTypes[orderItem.type] ? prodTypes[orderItem.type].name : '',
    orderItem.title,
  );
  const seller = contacts?.[orderItem.sellerId];
  return (
    <S.OrderTr className="orderitem-row" first={first}>
      <S.ImageCol>
        <MiniImages
          className="products-table-images"
          images={[orderItem.images?.[0]?.image.url]}
          onImageClick={i =>
            touchImage(
              orderItem.images?.map(img => ({ src: img.image.url, caption: img.image.comment })),
              i,
            )
          }
          count={orderItem.images?.length || 0}
          from="table"
        />
      </S.ImageCol>
      <S.BigCol>{typeVariety}</S.BigCol>
      <S.SmallCol>{Object.values(countries).find(c => c.iso2Code === orderItem.origin)?.name}</S.SmallCol>
      <S.SmallCol>{orderItem.category}</S.SmallCol>
      <S.SmallCol>{orderItem.size}</S.SmallCol>
      <S.SmallColRight>{orderService.getAmountText(orderItem)}</S.SmallColRight>
      <S.SmallColRight>{priceUnit}</S.SmallColRight>
      <S.SmallColRight>{priceDisplay}</S.SmallColRight>
      <S.RegularCol>
        <S.Row>
          <S.ContactImagesRow>
            <S.ContactImage
              img={seller.avatar}
              text={seller.companyName}
              avatarColor={utils.getAvatarColor(seller.companyName)}
            />
          </S.ContactImagesRow>
          <S.ContactNameColumn>
            {seller.companyName ? <S.TextCompany>{seller.companyName}</S.TextCompany> : null}
            <S.TextName>{seller.name}</S.TextName>
          </S.ContactNameColumn>
        </S.Row>
      </S.RegularCol>
    </S.OrderTr>
  );
};

export default React.memo(OrderDeliveryRow);
