import { TextLabel } from '..';
import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';

interface IStyledProps {
  theme: ITheme;
  toShow?: boolean;
}

export const Container = styled(RowContainer)`
  display: ${(p: IStyledProps) => (p.toShow ? 'flex' : 'none')};
  max-width: 500px;
  min-width: 300px;
  min-height: 58px;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  padding: 5px ${(p: IStyledProps) => p.theme.paddingObject};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-align: left;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Content = styled(RowContainer)`
  flex: 1;
`;

export const MessageContainer = styled(ColumnContainer)`
  margin-right: calc(${(p: IStyledProps) => p.theme.paddingObject} * 2);
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  line-height: 1.4;
`;

export const PreviewImg = styled.img`
  width: auto;
  height: 35px;
  max-width: 45px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: 10px;
  object-fit: cover;
`;

export const Col = styled(ColumnContainer)`
  width: 100%;
`;
