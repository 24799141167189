import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  isFeatured?: boolean;
  featuredColor?: string;
  isFirst?: boolean;
  height?: number;
  isTrash?: boolean;
  showPrice?: boolean;
  justifyContent?: string;
  fillPriceMode?: boolean;
}

const buttonHeight = 32;
const cardHeight = 75;

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: 100%;
  min-height: ${cardHeight}px;
  max-height: ${cardHeight}px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  ${(p: IStyledProps) =>
    p.isFirst &&
    css`
      padding-top: 4px;
    `}
`;

export const Content = styled(ColumnContainer)`
  height: 100%;
  overflow: hidden;
  flex: 1;
  justify-content: space-between;
`;

export const SwitchContainer = styled(RowContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Bottom = styled(RowContainer)`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const AmountRow = styled(RowContainer)`
  align-items: center;
  justify-content: ${(p: IStyledProps) => (p.fillPriceMode ? 'space-between' : 'flex-end')};
  flex: 1;
  width: ${(p: IStyledProps) => (p.fillPriceMode ? '100%' : 'auto')};
`;

export const TextRegular = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextBold = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TypeRow = styled(RowContainer)`
  flex: 1;
  padding-top: 2px;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  line-height: 20px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FeaturedFlagList = styled(RowContainer)`
  width: 50px;
  height: 28px;
  justify-content: flex-end;
`;

export const FeaturedFlag = styled(FontIcon)<IStyledProps>`
  opacity: 0.5;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-left: 2px;
`;

const controlsHeight = '26px';
export const CTAContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  min-height: ${controlsHeight};
`;

export const IncDecButtons = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IncreaseDecreaseButton = styled(RowContainer)`
  background: ${(p: IStyledProps) => (p.isTrash ? p.theme.colors.white : p.theme.colors.green1)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  min-width: ${buttonHeight}px;
  min-height: ${buttonHeight}px;
  max-width: ${buttonHeight}px;
  max-height: ${buttonHeight}px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: 0 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

export const TrashIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const TextControl = styled(TextBold)`
  font-size: 26px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  user-select: none;
`;

export const TextUnit = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const Featured = styled(RowContainer)`
  background-color: ${(p: IStyledProps) =>
    p.isFeatured && p.featuredColor
      ? p.featuredColor.replace('1)', '0.7)')
      : p.theme.colors.black.replace('1)', '0.4)')};
  color: white;
  border-radius: 3px;
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FeaturedIconWrapper = styled(RowContainer)`
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 26px;
`;

export const FeaturedIcon = styled(FontIcon)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: white;
`;

export const Select = styled.select`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-family: 'Nunito';
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  color: inherit;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  ${(p: IStyledProps) => {
    const color = p.theme.colors.grey1;
    return css`
      background-image: linear-gradient(45deg, transparent 50%, ${color} 50%),
        linear-gradient(135deg, ${color} 50%, transparent 50%);
    `;
  }}
  background-position: calc(100% - 5px) 0.5em, 100% 0.5em;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
  cursor: pointer;
`;

export const PriceRow = styled(RowContainer)`
  justify-content: flex-start;
  flex: 1;
`;

export const Price = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: flex;
  color: inherit;
`;

export const POR = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const PriceUnit = styled(POR)`
  padding: 0 5px;
  line-height: 1;
`;
