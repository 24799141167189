import { __ } from 'common-services';
import * as React from 'react';

import { ColumnContainer } from '../../atoms';
import * as S from './ActionsModal.styled';

export interface IProps {
  changeStep?: (step: number) => void;
  children: React.ReactNode;
  className?: string;
  contentGrow?: boolean;
  minHeight?: string;
  multiple?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  onDropFiles?: (files: Array<File>) => void;
  onlyContent?: boolean;
  skippable?: boolean;
  step?: number;
  stepsNumber?: number;
  subtitle?: string;
  title?: string;
  width?: string;
  overFlowVisible?: boolean;
}

const ActionsModal: React.FC<IProps> = ({
  changeStep,
  children,
  className,
  contentGrow = true,
  minHeight,
  multiple = false,
  onBack,
  onClose,
  onDropFiles,
  onlyContent,
  skippable,
  step,
  stepsNumber,
  subtitle,
  title,
  width,
  overFlowVisible,
}) => {
  const renderContent = React.useCallback(() => {
    return (
      <S.CardContainer
        className={className}
        disabled={!onDropFiles}
        minHeight={minHeight}
        multiple={multiple}
        onFilesChange={onDropFiles}
        width={width}
        overFlowVisible={overFlowVisible}
      >
        <S.Header className="actions-modal-header">
          <S.TitleColumn>
            {stepsNumber ? (
              <S.StepsRow>
                {step > 1 ? <S.BackButton name="Arrow" onClick={() => changeStep(--step)} /> : <S.Skip />}
                <S.Steps>{__('ActionsModal.steps', { step, stepsNumber })}</S.Steps>
                {skippable ? <S.Skip onClick={() => changeStep(++step)}>{__('ActionsModal.skip')}</S.Skip> : <S.Skip />}
              </S.StepsRow>
            ) : null}
            <S.TitleRow>
              {onBack ? <S.BackButtonTitle name="Arrow" onClick={() => onBack()} /> : null}
              <ColumnContainer margin={onBack ? '0 0 0 18px' : '0'}>
                {title ? <S.Title>{title}</S.Title> : null}
                {subtitle ? <S.SubTitle>{subtitle}</S.SubTitle> : null}
              </ColumnContainer>
            </S.TitleRow>
          </S.TitleColumn>
          {onClose && (!stepsNumber || !skippable) ? (
            <S.CloseIcon name="Close" onClick={onClose} id="action-modal-close-icon" />
          ) : null}
        </S.Header>
        <S.Wrapper
          onlyContent={onlyContent}
          contentGrow={contentGrow}
          overFlowVisible={overFlowVisible}
          className="form-wrapper"
        >
          {children}
        </S.Wrapper>
      </S.CardContainer>
    );
  }, [
    changeStep,
    children,
    className,
    contentGrow,
    minHeight,
    multiple,
    onClose,
    onDropFiles,
    onlyContent,
    skippable,
    step,
    stepsNumber,
    subtitle,
    title,
    width,
  ]);

  return onlyContent ? renderContent() : <S.Modal className={className}>{renderContent()}</S.Modal>;
};

export default React.memo(ActionsModal);
