import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &:after {
    flex: 0 0 1px;
    content: '';
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
  -webkit-overflow-scrolling: touch;
  mask-image: linear-gradient(transparent 0.1%, rgb(0, 0, 0) 5%);
`;
