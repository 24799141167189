import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  showColor?: boolean;
}

export const ClientRow = styled(RowContainer)`
  max-width: max-content;
`;

export const ClientNameColumn = styled(ColumnContainer)`
  justify-content: space-between;
  min-width: 85px;
  height: 100%;
  flex: 1;
`;

export const AvatarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TextContactName = styled(TextLabel)`
  width: auto;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextCompany = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextGrey2 = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
