import styled, { css, ITheme } from '../../../../../styled-components';
import { ColumnContainer } from '../../../../atoms';

interface IStyledProps {
  color?: string;
  from?: 'showroom' | 'pricelist' | 'product'; // 'product' for ProductCard component
  margin?: string;
  theme: ITheme;
}

export const Body = styled(ColumnContainer)<IStyledProps>`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }

  ${(p: IStyledProps) =>
    p.from === 'showroom' &&
    css`
      padding: 0 ${p.theme.paddingSize(3)};
    `}
`;
