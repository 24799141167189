import * as React from 'react';

import * as S from './Drag.styled';

// Define the interface for the props expected by HandleWrapper and DragHandle
interface HandleWrapperProps {
  isDragging: boolean;
}
interface DragHandleProps extends HandleWrapperProps {
  rank: number;
}
const DragHandle: React.FC<DragHandleProps> = props => {
  return (
    <S.HandleWrapper {...props}>
      <span>{props.rank}</span>
      <S.Icon name="Drag" />
    </S.HandleWrapper>
  );
};
export default DragHandle;
