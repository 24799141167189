import { AxiosError } from 'axios';
import {
  __,
  buyerWorkspaceActions,
  constants,
  EventTrack,
  IAddress,
  IContact,
  ICountry,
  imageActions,
  IUser,
  IWorkspace,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  validations,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, RouteComponentProps, useHistory } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { Picture, Radio, Ribbon, Select, Switch } from '../../atoms';
import {
  ActionsModal,
  AddressAutoComplete,
  AddressesTable,
  FormContainer,
  FormMenu,
  FormSection,
  InputWithLabel,
  MembersUpdate,
} from '../../molecules';
import { highlightFormSection, Sections } from '../../molecules/FormMenu/FormMenu.component';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceSettingsBuyer.styled';

export interface IStateProps {
  workspace: IWorkspace;
  contacts: { [contactId: number]: IContact };
  me: IUser;
  countries: ICountries;
  addresses: Array<IAddress>;
}

interface ICountries {
  [key: string]: ICountry;
}
export interface IDispatchProps {
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressAdd: typeof buyerWorkspaceActions.workspaceAddressAdd;
  workspaceAddressDelete: typeof buyerWorkspaceActions.workspaceAddressDelete;
  workspaceAddressesGet: typeof buyerWorkspaceActions.workspaceAddressesGet;
  workspaceAddressUpdate: typeof buyerWorkspaceActions.workspaceAddressUpdate;
  workspaceUpdate: typeof buyerWorkspaceActions.workspaceUpdate;
  catalogMemberDelete: typeof sellerWorkspaceActions.catalogMemberDelete;
  catalogMembersUpdate: typeof sellerWorkspaceActions.catalogMembersUpdate;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string; tab?: string }>;

interface IState {
  address: { id?: number; section?: CHANNEL_SECTIONS };
  errors: Map<string, string | (() => JSX.Element)>;
  hasChanges: boolean;
  newWorkspace: IWorkspace;
  viewing?: string;
  newRole: ICatalogRole;
}

class WorkspaceSettingsBuyer extends React.PureComponent<IProps, IState> {
  private t: number;
  private sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private teamRef: React.RefObject<HTMLDivElement> = React.createRef();
  private addressesRef: React.RefObject<HTMLDivElement> = React.createRef();
  private pdfRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      address: {},
      errors: new Map(),
      hasChanges: false,
      newWorkspace: props.workspace,
      viewing: props.match.params?.tab || 'team',
      newRole: 'viewer',
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceSettingsBuyer', { renderTime: this.t });
    const scrolOffset = 200;
    const { me, workspace, workspaceAddressesGet } = this.props;
    if (workspace) {
      workspaceAddressesGet(me.id, workspace.id);
    }

    // Manage scroll
    if (this.sectionsRef.current) {
      this.sectionsRef.current.addEventListener('scroll', () => {
        const currentScrollTop = this.sectionsRef.current?.scrollTop;
        const scrollTopWithOffset = currentScrollTop + scrolOffset;

        const sections: Array<{ offset: number; section: string }> = [
          { offset: this.teamRef?.current?.offsetTop, section: 'team' },
          { offset: this.addressesRef?.current?.offsetTop, section: 'addresses' },
          { offset: this.pdfRef?.current?.offsetTop, section: 'pdf' },
        ];

        sections.forEach(({ offset, section }) => {
          if (scrollTopWithOffset >= offset) {
            this.setState({ viewing: section });
          }
        });
      });
      const { viewing } = this.state;
      if (viewing !== 'team') {
        const el = document.getElementById(viewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(viewing);
      }
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { match, me, workspaceAddressesGet, workspace } = this.props;
    const { viewing } = this.state;

    if (!prevProps.workspace && workspace) {
      workspaceAddressesGet(me.id, workspace.id);
      this.setState({ newWorkspace: workspace });
    }

    // If URL changes, we scroll to the correct section
    if (match !== prevProps.match) {
      const newViewing = match.params.tab || 'team';
      if (newViewing !== viewing) {
        this.setState({ viewing: newViewing });
        const el = document.getElementById(newViewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(newViewing);
      }
    }
  }

  public render() {
    const {
      addresses,
      catalogMemberDelete,
      catalogMembersUpdate,
      contacts,
      countries,
      history,
      location,
      match,
      me,
      modalClose,
      modalOpen,
      workspace,
      workspaceAddressDelete,
      workspaceAddressAdd,
      workspaceAddressUpdate,
    } = this.props;
    const { hasChanges, newWorkspace, errors, viewing, address } = this.state;
    const myRole = sellerWorkspaceService.getRole(workspace, me.id);
    const amEditor = myRole !== 'viewer';
    const addressSelectedObj = address.id ? addresses.find(adr => adr.id === address.id) : undefined;
    return (
      <Workspace
        subtitle={''}
        title={__('Components.Header.WorkspaceSettings')}
        tabSelected="settings-buyer"
        workspaceId={workspace?.id}
        isBuyerWorkspace={true}
      >
        <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
        {!amEditor ? (
          <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} id="team-settings-readonly-ribbon" />
        ) : null}
        <S.Container>
          {this.getMenu(viewing)}
          <FormContainer
            className="TeamSetting-FormContainer"
            canSave={hasChanges && !errors.size}
            save={this.onCTAClick}
            sectionsRef={this.sectionsRef}
          >
            {!newWorkspace ? null : (
              <>
                <TeamSection
                  catalogName={sellerWorkspaceService.getCatalogName(newWorkspace, contacts, me)}
                  editMode={amEditor}
                  errors={errors}
                  id="team"
                  onPictureChange={this.onPictureChange}
                  onPicturedDelete={this.onPicturedDelete}
                  sectionRef={this.teamRef}
                  setError={this.setError}
                  updateWorkspace={this.updateWorkspace}
                  workspace={newWorkspace}
                  contacts={contacts}
                  me={me}
                  role={myRole}
                  removeMember={cId =>
                    modalOpen(
                      __('Catalog.update_members.remove', contacts[cId]),
                      () => {
                        EventTrack.track('workspace_colleague_delete', {
                          workspace_id: workspace.id,
                          member_id: cId,
                        });
                        catalogMemberDelete(me.id, workspace.id, cId);
                      },
                      {
                        showCancelButton: true,
                        buttonText: __('Components.ProductsList.Remove'),
                      },
                      'nice',
                    )
                  }
                  updateRolMember={(userId: number, r: ICatalogRole) => {
                    EventTrack.track('workspace_colleague_update', {
                      workspace_id: workspace.id,
                      member_id: userId,
                      new_role: r,
                    });
                    catalogMembersUpdate(me.id, workspace.id, [
                      { ...workspace.members.find(m => m.userId === userId), role: r },
                    ]);
                  }}
                />
                <Addresses
                  addresses={addresses}
                  addressesRef={this.addressesRef}
                  amEditor={amEditor}
                  countries={countries}
                  me={me}
                  modalClose={modalClose}
                  modalOpen={modalOpen}
                  navAddressAction={(addressId: number) =>
                    this.navAddressAction(addressId, CHANNEL_SECTIONS.ADDRESS_EDIT)
                  }
                  navResetAddressAction={() => this.navResetAddressAction()}
                  workspace={workspace}
                  // @ts-ignore
                  workspaceAddressDelete={workspaceAddressDelete}
                />
                <Pdf
                  editMode={amEditor}
                  id="pdf"
                  sectionRef={this.pdfRef}
                  updateWorkspace={this.updateWorkspace}
                  workspace={newWorkspace}
                />
              </>
            )}
          </FormContainer>
        </S.Container>
        {address.section ? (
          <ActionsModal
            onClose={() => this.setState({ address: {} })}
            title={address.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
          >
            <AddressAutoComplete
              address={addressSelectedObj}
              countries={countries}
              me={me}
              onCancel={this.navResetAddressAction}
              onSubmit={(formAddress: IAddress) => {
                const userID = me.id;
                const workspaceID = workspace.id;

                if (formAddress.id) {
                  workspaceAddressUpdate(userID, workspaceID, formAddress);
                } else {
                  workspaceAddressAdd(userID, workspaceID, formAddress);
                }

                this.setState({ address: {} });
              }}
              weightUnit={workspace.defaultWeightUnit}
            />
          </ActionsModal>
        ) : null}
      </Workspace>
    );
  }

  /**
   * Open address modal
   */
  private navAddressAction = (id: number, section: CHANNEL_SECTIONS) => {
    this.setState({ address: { id, section } });
  };

  /**
   * Close address modal
   */
  private navResetAddressAction = () => {
    this.setState({ address: {} });
  };

  /**
   * Set error for a particular field represented by key
   */
  private setError = (key: string, error: string | (() => JSX.Element)) => {
    const cloneErrors = new Map(this.state.errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  };

  /**
   * Change the local state of user with the changes in the form.
   */
  private updateWorkspace = (workspace: IWorkspace) => {
    this.setState({
      hasChanges: true,
      newWorkspace: workspace,
    });
  };

  /**
   * On delete picture with confirmation modal
   */
  private onPicturedDelete = (field: 'companyLogo' | 'companyImage'): void => {
    const { modalClose, modalOpen } = this.props;
    const { newWorkspace } = this.state;
    modalOpen(
      __('Components.ProductDetails.confirm_remove_picture'),
      () => {
        this.setState({ newWorkspace: { ...newWorkspace, [field]: undefined }, hasChanges: true });
        modalClose();
      },
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  };

  /**
   * On picture change:
   * * Upload picture
   * * Update in user state
   */
  private onPictureChange = async (file: File, field: 'companyLogo' | 'companyImage') => {
    const { mediaUpload } = this.props;
    const { newWorkspace } = this.state;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = event =>
      logError(new Error('File could not be read! Code ' + (event.target as any).error.code), `upload.${field}`);
    reader.onload = () =>
      reader.result &&
      resizeImage(reader.result as string, (imageResized: string) =>
        mediaUpload({ ...file, name: file.name, content: imageResized }, result =>
          this.setState({
            newWorkspace: {
              ...newWorkspace,
              [field]: result
                ? result.secure_url.replace(
                  'f_auto',
                  field === 'companyLogo' ? 'f_auto,h_150,w_150,c_limit' : 'f_auto,h_300,w_1200,c_limit',
                )
                : undefined,
            },
            hasChanges: true,
          }),
        ),
      );
  };

  /**
   * On CTA click
   */
  private onCTAClick = () => {
    const { workspaceUpdate, me, notificationShow } = this.props;
    const { newWorkspace } = this.state;
    EventTrack.track('workspace_settings_update', { type: 'buyer', workspace_id: newWorkspace.id });
    workspaceUpdate(
      me.id,
      {
        ...newWorkspace,
      },
      error => {
        if (error) {
          return notificationShow({
            title: __('WorkspaceSettings.error'),
            subtitle: __('WorkspaceSettings.error_description'),
            closable: true,
            style: 'error',
          });
        }
        return notificationShow({
          title: __('WorkspaceSettings.success'),
          subtitle: __('WorkspaceSettings.success_description'),
          closable: true,
          style: 'info',
        });
      },
    );
    this.setState({ hasChanges: false });
  };

  private getMenu(viewing: string) {
    const sections: Sections = [
      {
        id: 'team',
        title: __('WorkspaceSettings.Menu.Team.title'),
        description: __('WorkspaceSettings.Menu.Team.description'),
        icon: 'Catalog',
      },
    ];

    sections.push({
      id: 'addresses',
      title: __('WorkspaceSettings.Menu.Addresses.title'),
      description: __('WorkspaceSettings.Menu.Addresses.description'),
      icon: 'Address',
    });

    sections.push({
      id: 'pdf',
      title: __('WorkspaceSettings.Menu.Order.title'),
      description: __('WorkspaceSettings.Menu.Pdf.description'),
      icon: 'Unread-orders',
    });

    return <FormMenu sections={sections} selected={viewing} />;
  }
}

export default WorkspaceSettingsBuyer;

const TeamSection = React.memo(
  ({
    catalogName,
    contacts,
    editMode,
    errors,
    id,
    me,
    role,
    onPictureChange,
    onPicturedDelete,
    sectionRef,
    setError,
    updateWorkspace,
    workspace,
    removeMember,
    updateRolMember,
  }: {
    catalogName: string;
    editMode: boolean;
    contacts: Record<number, IContact>;
    errors: Map<string, string | (() => JSX.Element)>;
    me: IUser;
    id: string;
    role: ICatalogRole;
    onPictureChange: (file: File, field: 'companyLogo' | 'companyImage') => void;
    onPicturedDelete: (field: 'companyLogo' | 'companyImage') => void;
    sectionRef: React.RefObject<HTMLDivElement>;
    setError: (key: string, value: string) => void;
    updateWorkspace: (workspace: IWorkspace) => void;
    workspace: IWorkspace;
    removeMember: (cId: number) => void;
    updateRolMember: (userId: number, r: ICatalogRole) => void;
  }) => {
    const { companyLogo } = workspace;
    const history = useHistory();
    return (
      <FormSection id={id} sectionRef={sectionRef} title={__('WorkspaceSettings.Menu.Team.title')}>
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.team_name')}
          description={__('Components.Settings.team_name_description')}
          disabled={!editMode}
        >
          <S.Input
            name="name"
            placeholder={__('Components.Settings.team_name_placeholder')}
            value={workspace.name}
            trim={true}
            isRequired={true}
            onChange={(n, v) => {
              setError(n, v ? '' : 'empty');
              updateWorkspace({ ...workspace, [n]: v });
            }}
            onBlur={(n, value) => {
              setError(n, value ? '' : 'empty');
            }}
            disabled={!editMode}
            hasError={!!errors.get('name')}
            zeroCase={catalogName}
          />
        </InputWithLabel>

        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.company_logo') + ' (150x150)'}
          description={__('Components.Settings.company_logo_description')}
          disabled={!editMode}
          asColumn={!!companyLogo}
        >
          <Picture
            editable={editMode}
            height="150px"
            imageUrl={companyLogo}
            onDelete={() => onPicturedDelete('companyLogo')}
            onFileChange={file => onPictureChange(file, 'companyLogo')}
            pictureMode={true}
            picturePlaceholder={__('Components.Settings.company_logo_zero')}
            relation={constants.IMAGE_RELATION.COMPANY_LOGO}
            width="150px"
            withCrop={deviceIsIpad() ? false : true}
          />
        </InputWithLabel>

        <InputWithLabel
          isRequired={false}
          label={__('Components.Settings.orders_email')}
          description={__('Components.Settings.orders_email_description')}
          disabled={!editMode}
        >
          <S.Input
            name="ordersEmail"
            placeholder={__('Components.Settings.orders_email_placeholder')}
            value={workspace.ordersEmail}
            trim={true}
            isRequired={false}
            onChange={(n, v) => {
              if (v && !validations.validateEmail(v as string)) {
                setError(n, 'incorrect-email');
              } else {
                setError(n, '');
                updateWorkspace({ ...workspace, [n]: v });
              }
            }}
            onBlur={(n, value) => {
              if (value && !validations.validateEmail(value as string)) {
                setError(n, 'incorrect-email');
              } else {
                setError(n, '');
              }
            }}
            disabled={!editMode}
            hasError={!!errors.get('ordersEmail')}
            zeroCase={__('Components.Settings.orders_email_zerocase')}
          />
          {errors.get('ordersEmail') === 'incorrect-email' ? (
            <S.TextError>{__('Components.Onboarding.LoginPhoneEmail.email_validation')}</S.TextError>
          ) : null}
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Members.title')}
          description={__('WorkspaceSettings.Members.subtitle', { workspace: workspace.name })}
        >
          <S.SwitchRow>
            <MembersUpdate
              className="workspace-settings-members"
              contacts={contacts}
              disabled={true}
              me={me}
              members={workspace.members}
              onRemove={removeMember}
              onUpdateRole={updateRolMember}
              role={role}
              title={__('Catalog.update_members.title')}
            />
          </S.SwitchRow>
          <S.TextLink
            padding="12px 0 0 0"
            onClick={() =>
              history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER, workspaceId: workspace.id + '' }))
            }
          >
            {__('WorkspaceColleagues.access_link')}
          </S.TextLink>
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Addresses = React.memo(
  ({
    addresses,
    addressesRef,
    amEditor,
    countries,
    me,
    modalOpen,
    modalClose,
    navAddressAction,
    navResetAddressAction,
    workspace,
    workspaceAddressDelete,
  }: {
    addresses: Array<IAddress>;
    addressesRef: React.RefObject<HTMLDivElement>;
    amEditor: boolean;
    countries: ICountries;
    me: IUser;
    modalClose: typeof modalActions.modalClose;
    modalOpen: typeof modalActions.modalOpen;
    navAddressAction: (addressId: number | undefined) => void;
    navResetAddressAction: () => void;
    workspace: IWorkspace;
    workspaceAddressDelete: (
      myId: number,
      workspaceId: number,
      addressId: number,
      cb: (err?: AxiosError<any>) => void,
    ) => void;
  }) => {
    const props = {
      addresses,
      emptyAddressText: __('WorkspaceSettings.no_delivery_addresses'),
      navAddressAction,
      navResetAddressAction,
      onAddressDelete: (addr: IAddress) => {
        modalOpen(
          __('Components.AddNewAddress.modal_remove_text'),
          () => {
            workspaceAddressDelete(me.id, workspace.id, addr.id, () => modalClose());
          },
          {
            buttonText: __('Components.AddNewAddress.modal_remove_cta'),
            showCancelButton: true,
            actionType: 'dangerous',
          },
          'nice',
        );
      },
      showAddButton: amEditor,
      disabled: !amEditor,
      tradeCurrency: workspace.defaultCurrency,
      weightUnit: workspace.defaultWeightUnit,
    };
    return (
      <FormSection id="addresses" title={__('WorkspaceSettings.delivery_addresses')} sectionRef={addressesRef}>
        <InputWithLabel isRequired={true} width="90%" align="flex-start" disabled={!amEditor}>
          <AddressesTable {...props} />
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Pdf = React.memo(
  ({
    editMode,
    id,
    sectionRef,
    updateWorkspace,
    workspace,
  }: {
    editMode: boolean;
    id: string;
    sectionRef: React.RefObject<HTMLDivElement>;
    updateWorkspace: (workspace: IWorkspace) => void;
    workspace: IWorkspace;
  }) => {
    const dispatch = useDispatch();
    return (
      <FormSection
        sectionRef={sectionRef}
        id={id}
        title={__('WorkspaceSettings.Menu.Order.title')}
        numHeaders={editMode ? 2 : 3}
      >
        <InputWithLabel isRequired={true} footerText={__('WorkspaceSettings.Pdf.description')} disabled={!editMode}>
          <S.SwitchRow>
            <S.SwitchLabel>{__('WorkspaceSettings.Pdf.switch')}</S.SwitchLabel>
            <Switch
              name="showPricesPdf"
              isChecked={workspace.pdfShowPrices}
              onChange={(n, checked) => updateWorkspace({ ...workspace, pdfShowPrices: checked })}
              disabled={!editMode}
              selectable={false}
            />
          </S.SwitchRow>
        </InputWithLabel>

        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.PdfFormat.label')}
          disabled={!editMode}
          asColumn={true}
        >
          <Radio
            name={'pdf_version'}
            direction={'column'}
            options={[
              {
                label: __('WorkspaceSettings.PdfFormat.comfortable'),
                value: 'comfortable',
                tooltip: IMAGES.previewPDFComfortable,
              },
              {
                label: __('WorkspaceSettings.PdfFormat.compact'),
                value: 'compact',
                tooltip: IMAGES.previewPDFCompact,
              },
            ]}
            containerMargin={'4px 0'}
            tooltipType={'image'}
            alignItems={'flex-start'}
            styleLabel={{ margin: '5px 0' }}
            itemsChecked={[workspace.pdfVersion]}
            onChange={(key, value: 'compact' | 'comfortable', error) => {
              if (!error) {
                updateWorkspace({ ...workspace, pdfVersion: value });
              }
            }}
            disabled={!editMode}
          />
        </InputWithLabel>

        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Order.sort_items.title')}
          description={__('WorkspaceSettings.Order.sort_items.description_buyer')}
          disabled={!editMode}
        >
          <Select
            name="sort_items_by"
            value={workspace.sortItemsBy}
            options={getSortItemsByOptions()}
            onChange={(n, v) => {
              if (v !== workspace.sortItemsBy) {
                dispatch(
                  modalActions.modalOpen(
                    __('WorkspaceSettings.Order.sort_items.modal_title', {
                      option: getSortItemsByOptions().find(o => o.value === v)?.label,
                    }),
                    () => {
                      updateWorkspace({ ...workspace, sortItemsBy: v as SortItemFilter });
                      dispatch(modalActions.modalClose());
                    },
                    {
                      icon: IMAGES.informativePineapple,
                      buttonText: __('WorkspaceSettings.Order.sort_items.modal_cta'),
                      showCancelButton: true,
                      text2: __('WorkspaceSettings.Order.sort_items.modal_description'),
                    },
                    'nice',
                  ),
                );
              }
            }}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
          />
        </InputWithLabel>
      </FormSection>
    );
  },
);

// Sort by items options
const getSortItemsByOptions = () => [
  { value: 'alphabetic', label: __('Components.OrderDetails.sort_products.alphabetical') },
  { value: 'added', label: __('Components.OrderDetails.sort_products.default') },
  { value: 'sku', label: __('Components.OrderDetails.sort_products.sku') },
];
