import * as React from 'react';

import { useScrollPosition } from '../../../util/hooks';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import * as S from './TableHeader.styled';

export interface IProps {
  headerRef?: any;
  className?: string;
  columns: Array<ITableColumn | any>;
  hasLeftAction?: boolean;
  onSettingsClick?: () => void;
  removeSelection?: () => void;
  scrollClassName?: string; // Important to have when showStickyHeader is true
  selectable?: boolean;
  selectAll?: () => void;
  selected: 'all' | 'any' | 'none';
  showCustomColumns?: boolean;
  showStickyHeader?: boolean;
  headerHeight?: number;
  updateSortMode?: (sortBy?: string, sortOrder?: 'desc' | 'asc') => void;
  sort?: string;
  sortOrder?: 'desc' | 'asc';
}

/**
 * Table header
 */
const TableHeader: React.FC<IProps> = ({
  headerRef,
  className = 'table-header-container',
  columns,
  hasLeftAction = true,
  onSettingsClick,
  removeSelection,
  scrollClassName,
  selectable,
  selectAll,
  selected,
  showCustomColumns,
  showStickyHeader,
  headerHeight,
  sort,
  sortOrder,
  updateSortMode,
}) => {
  const scrollPosition = useScrollPosition(scrollClassName);
  const scrollThreshold = React.useRef(54);

  // Calculate scroll threshold to show shadow
  React.useEffect(() => {
    if (!scrollClassName) return;
    const scrollElement = document.getElementsByClassName(scrollClassName)?.[0];
    const headerElement = document.getElementsByClassName(className)?.[0];
    if (scrollElement && headerElement) {
      scrollThreshold.current = headerElement.getBoundingClientRect().top - scrollElement.getBoundingClientRect().top;
    }
  }, [scrollClassName, className]);

  return (
    <S.Head
      className={className}
      ref={headerRef}
      showStickyHeader={showStickyHeader}
      headerHeight={headerHeight}
      showHeaderShadow={showStickyHeader && scrollPosition > scrollThreshold.current}
    >
      <S.Header>
        {hasLeftAction ? (
          <S.FillCol
            selectable={selectable}
            showStickyHeader={showStickyHeader}
            showHeaderShadow={showStickyHeader && scrollPosition > scrollThreshold.current}
          >
            {selectable ? (
              <S.RadioLabel onClick={e => (selected === 'all' ? removeSelection() : selectAll())}>
                <S.SelectAllIcon selected={selected} name="Line" />
              </S.RadioLabel>
            ) : null}
          </S.FillCol>
        ) : null}
        {columns
          .filter(c => c)
          .map((c, idx, arr) => {
            const shouldShowSettings = showCustomColumns && onSettingsClick && idx === arr.length - 1;
            const ContentWrapper = shouldShowSettings || c.sort ? RowContainer : React.Fragment;

            // If we are sending a custom jsx cell, we render it, otherwise we render the default cell
            const renderCell = column => {
              switch (true) {
                case typeof column?.renderCell !== 'undefined':
                  return column.renderCell();
                default:
                  return (
                    <S.TextHeader isBold={!!column.isBold} align={column.align}>
                      {column.title}
                      {column.info ? (
                        <S.TooltipContainer>
                          <S.Tooltip position="top" text={column.info} width="200px" themeMode="dark">
                            <S.InfoIcon disableHover={true} name="Info" />
                          </S.Tooltip>
                        </S.TooltipContainer>
                      ) : null}
                    </S.TextHeader>
                  );
              }
            };
            return (
              <S.Col
                className={c?.cellClassName}
                colSpan={c?.colSpan ? c.colSpan : 1}
                isGrouped={c?.isGrouped}
                isBlue={c?.isBlue}
                key={`${c.title}-${idx}`}
                isFirst={!hasLeftAction && !idx}
                isLast={idx === arr.length - 1}
                color={c.color}
                width={c.width}
                hiddenAtScreenWidth={c.hiddenAtScreenWidth}
              >
                <ContentWrapper>
                  {renderCell(c)}
                  {c.sort && updateSortMode ? (
                    <ColumnContainer style={{ justifyContent: 'center' }}>
                      <S.SortButton className="table-header-sort-button" style={{ transform: 'rotateX(180deg)' }}>
                        {c.sortOrder?.includes('desc') && (sort !== c.sort || sortOrder === 'desc') ? (
                          <S.SortIcon
                            name="Down-solid"
                            onClick={() => updateSortMode?.(c.sort, sortOrder === 'asc' ? 'desc' : 'asc')}
                          />
                        ) : null}
                      </S.SortButton>
                      <S.SortButton className="table-header-sort-button">
                        {c.sortOrder?.includes('asc') && (sort !== c.sort || sortOrder === 'asc') ? (
                          <S.SortIcon
                            name="Down-solid"
                            onClick={() => updateSortMode?.(c.sort, sortOrder === 'asc' ? 'desc' : 'asc')}
                          />
                        ) : null}
                      </S.SortButton>
                    </ColumnContainer>
                  ) : null}
                  {shouldShowSettings ? (
                    <S.SettingsButton className="table-header-settings-button">
                      <S.SettingsIcon name="Section-settings" onClick={() => onSettingsClick?.()} />
                    </S.SettingsButton>
                  ) : null}
                </ContentWrapper>
              </S.Col>
            );
          })}
      </S.Header>
    </S.Head>
  );
};

export default TableHeader;
