import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, LettersAvatar as LA, TextLabel } from '../../atoms';

interface IStyledProps {
  backgroundColor?: string;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  display: none;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    z-index: 1000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background: ${(p: IStyledProps) => p.theme.colors.grey4};
    height: 100%;
    min-height: ${(p: IStyledProps) => p.theme.windowHeight};
    max-width: 100vw;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
    top: 0;
  }
`;

export const Text = styled.p`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding: 0 1em;
  text-align: center;
  line-height: 1.4;
  display: inline;
`;
export const Link = styled.a`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding: 0 1em;
  text-align: center;
  line-height: 1.4;
  text-decoration: none;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(12)};
`;

export const Img = styled.img`
  width: 22em;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;

export const Star = styled.img`
  flex: 1;
  height: 4em;
`;

export const Row = styled.div`
  display: flex;
  width: 27em;
  min-height: 3em;
  justify-content: space-between;
  align-items: center;
  margin: 0em 0em 2em;
`;

export const CTA = styled(Button)<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.backgroundColor || p.theme.colors.green1};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const ConsentioLogo = styled.img`
  height: 45px;
  width: 90px;
  object-fit: contain;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  opacity: 0.4;
`;
