import {
  __,
  CHANNEL_TYPE,
  chatActions,
  chatSelectors,
  contactActions,
  mappingActions,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  sellerWorkspaceActions,
  userActions,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { ROUTE_PATHS } from '../../../constants';
import Chat from '../../../screens/chat';
import getPath from '../../../util/routes';
import SalesOrdersMagic from '../SalesOrdersMagic';
import Workspace from '../Workspace/Workspace.component';

export interface IStateProps {
  catalog: IWorkspace;
  catalogs: { [id: number]: IWorkspace };
  clients: { [catalogId: number]: Array<IClient> };
  contacts: { [id: number]: IContact };
  countries: { [key: string]: ICountry };
  me: IUser;
  hideOrdersMagicZero: boolean;
  importJobsResponse?: IReduxJobsImport;
  priceGroupIds?: { [key: string]: Array<string> };
  prices?: { [key: string]: { [key: number]: IPrice } };
  prodTypes: { [key: string]: IProdType };
  suppliers: { [id: number]: Array<ISupplier> };
}
export type OwnProps = RouteComponentProps<{
  workspaceId: string;
  clientId: string;
  contactId: string;
  jobId: string;
  hashId: string;
}>;

export interface IDispatchProps {
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  getAddressMappings: typeof mappingActions.addressMappingsGet;
  getPrices: typeof productActions.getPrices;
  getProductMappings: typeof mappingActions.productMappingsGet;
  mappingDelete: typeof mappingActions.mappingDelete;
  mappingUpdate: typeof mappingActions.mappingUpdate;
  requestProInfo: typeof userActions.requestProInfo;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  clientUpdate: typeof sellerWorkspaceActions.clientUpdate;
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  importContactOrders: typeof orderActions.importClientOrders;
  importFormatsGet: typeof orderActions.importFormatsGet;
  importOrderFormatAsk: typeof orderActions.importOrderFormatAsk;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  notificationShow: typeof notificationsActions.notificationShow;
  orderImportWidgetsGet: typeof orderActions.orderImportWidgetsGet;
  ordersMagicZeroHide: typeof userActions.ordersMagicZeroHide;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  createNewContact: typeof contactActions.createContactWithName;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
}

type IProps = IStateProps & IDispatchProps & OwnProps;

const WorkspaceMagicOrders: React.FC<IProps> = (props: IProps) => {
  const {
    catalog,
    contacts,
    history,
    match,
    match: {
      params: { workspaceId, clientId: clientIdParam, contactId },
    },
    notificationShow,
    createNewContact,
    contactUpdateMySellerWorkspace,
  } = props;
  const clientId = clientIdParam || contactId;
  const dispatch = useDispatch<Dispatch<any>>();
  const contact = contacts[clientId];
  const fromChat = window.location.pathname.includes('/contact/');
  const [isNewImport, setIsNewImport] = React.useState(false);
  const [miniChannelId, setMiniChannelId] = React.useState<string | undefined>(undefined);
  const channel = useSelector(chatSelectors.getChannelByContactId(Number(clientId)));
  const me = useSelector(userSelectors.getUser);
  React.useEffect(() => {
    if (!contact || !me) return;
    if (channel) {
      setMiniChannelId(channel.id);
    } else {
      dispatch(
        chatActions.createChannel(
          me.id,
          [contact.id],
          contact.name,
          '',
          CHANNEL_TYPE.PRIVATE,
          (c: IChannel) => setMiniChannelId(c.id),
          true,
          true,
        ),
      );
    }
  }, [channel, me, contact]);
  return (
    <Workspace
      parentSections={[
        ...(fromChat
          ? [
              {
                label: __('Components.Sidebar.chat'),
                action: () =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.CHAT_UNREAD,
                    }),
                  ),
              },
              {
                label: __('Components.ChatList.workspace_chats', {
                  name: contacts[clientId]?.name,
                }),
                action: () =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.CHAT_LIST,
                    }),
                  ),
              },
            ]
          : []),
        ...(!fromChat
          ? [
              {
                label: clientId ? __('Components.Header.WorkspaceClients') : __('Components.Header.Sales'),
                action: () =>
                  history.push(
                    getPath({
                      path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENTS : ROUTE_PATHS.WORKSPACE_SALES,
                      workspaceId,
                    }),
                  ),
              },
            ]
          : []),
        ...(clientId && !fromChat
          ? [
              {
                label: contact?.name,
                action: () =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT,
                      workspaceId,
                      clientId: clientId + '',
                    }),
                  ),
              },
            ]
          : []),
        ...(match.params?.jobId || isNewImport
          ? [
              {
                label: __('Components.OrdersList.magic_orders'),
                action: () =>
                  history.push(
                    getPath(
                      fromChat
                        ? {
                            path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
                            workspaceId,
                            contactId: clientId + '',
                          }
                        : {
                            path: clientId
                              ? ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS
                              : ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                            workspaceId,
                            clientId: clientId + '',
                          },
                    ),
                  ),
              },
              ...(match.params?.hashId
                ? [
                    {
                      label: __('Components.OrdersList.importedFile'),
                      action: () =>
                        history.push(
                          getPath(
                            fromChat
                              ? {
                                  path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
                                  workspaceId,
                                  contactId: clientId + '',
                                  jobId: match.params.jobId,
                                }
                              : {
                                  path: clientId
                                    ? ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS
                                    : ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                                  workspaceId,
                                  clientId: clientId + '',
                                  jobId: match.params.jobId,
                                },
                          ),
                        ),
                    },
                  ]
                : []),
            ]
          : []),
      ]}
      subtitle=""
      title={
        isNewImport
          ? __('MagicOrders.Tabs.new')
          : match.params?.hashId ||
            (match.params.jobId ? __('Components.OrdersList.importedFile') : __('Components.OrdersList.magic_orders'))
      }
      tabSelected={fromChat ? 'chat' : clientId ? 'clients' : 'sale'}
      workspaceId={Number(workspaceId)}
    >
      <SalesOrdersMagic
        {...props}
        contact={contact}
        contactId={contact?.id}
        sellerWorkspace={catalog}
        createNewContact={createNewContact}
        contactUpdateMySellerWorkspace={contactUpdateMySellerWorkspace}
        onOpenOrder={(jobId, hashId) =>
          history.push(
            getPath(
              fromChat
                ? {
                    path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
                    workspaceId,
                    contactId: clientId + '',
                    jobId: jobId + '',
                    hashId,
                  }
                : {
                    path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS : ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                    workspaceId,
                    clientId: clientId + '',
                    jobId: jobId + '',
                    hashId,
                  },
            ),
          )
        }
        onOpenJob={jobId =>
          history.push(
            getPath(
              fromChat
                ? {
                    path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
                    workspaceId,
                    contactId: clientId + '',
                    jobId: jobId + '',
                  }
                : {
                    path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS : ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                    workspaceId,
                    clientId: clientId + '',
                    jobId: jobId ? jobId + '' : undefined,
                  },
            ),
          )
        }
        notificationShow={notificationShow}
        onNewImport={() => setIsNewImport(true)}
      />
      {config.TOGGLE_MINI_CHAT.enabled ||
      (config.TOGGLE_MINI_CHAT.organizations.includes(Number(workspaceId)) && miniChannelId) ? (
        <Chat
          match={{ params: { ...match.params, channelId: miniChannelId } } as any}
          isModal={true}
          history={history}
          location={{} as any}
          styleModal={{ right: '90px' }}
          startMinimized={true}
        />
      ) : null}
    </Workspace>
  );
};
export default React.memo(WorkspaceMagicOrders);
