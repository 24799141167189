import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, Input as InputAtom, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const ExchangeRate = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
`;

export const ExchangeRatePrefix = styled.div`
  margin: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
`;

export const ExchangeRateMain = styled.div`
  width: 100px;
`;

export const ExchangeRateSuffix = styled.div`
  margin: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
`;

export const ExchangeRateDelete = styled(FontIcon)`
  margin: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 1.2;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const Input = styled(InputAtom)`
  max-width: 450px;
`;

export const HorizontalDivider = styled.div`
  max-width: 300px;
  height: 5px;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;

export const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
`;
