import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import AM from '../ActionsModal/ActionsModal.component';
import SimpleSearch from '../SimpleSearch';

interface IStyledProps {
  theme: ITheme;
}

export const Text = styled(TextLabel)`
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  justify-content: flex-end;
`;

export const SearchContainer = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingView} 0;
  max-width: 400px;
  min-height: min-content;
`;
