import { __, CHANNEL_TYPE, EventTrack, ImageGalleryObject, IMessage, utils } from 'common-services';
import GraphemeSplitter from 'grapheme-splitter';
import { History } from 'history';
import * as React from 'react';

import Mention from '../../../../../screens/mention';
import * as S from './TextMessage.styled';

const emojiSplitter = new GraphemeSplitter();

interface IProps {
  channelType: CHANNEL_TYPE;
  contacts?: { [key: string]: IContact };
  editMessage?: string;
  goToBottom?: () => void;
  history: History<any>;
  isLast?: boolean;
  me: IUser;
  members: Array<IMember>;
  message: IMessage;
  navigateToShowroom: (ownerId: number, query: string) => void;
  onEditMessageChange: (text?: string) => void;
  onSubmitEdit: (
    text: string,
    message: IMessage,
    setEditMessage: (text?: string) => void,
    mentions?: Array<number>,
  ) => void;
  onTouchImage?: (images: Array<ImageGalleryObject>, selected: number) => void;
}

const TextMessage: React.FC<IProps> = React.memo(props => {
  const { contacts, editMessage, message, channelType } = props;
  const textAreaRef = React.useRef<HTMLTextAreaElement>();

  if (!message) return null;
  const { extraData, senderId } = message;

  if (message.isRemoved)
    return (
      <S.TextWrapper>
        <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
      </S.TextWrapper>
    );
  if (editMessage !== undefined) {
    return <EditForm {...props} textAreaRef={textAreaRef} />;
  }

  const isDefaultContact = contacts?.[senderId]?.isDefaultContact;
  return (
    <S.TextWrapper hasMetadata={extraData && extraData.type === 'metadata' && extraData.metadata.title}>
      {extraData && extraData.type === 'metadata' && extraData.metadata.title ? (
        <S.MetaRow
          href={extraData.url && extraData.url.text}
          target="_blank"
          onClick={() => {
            if (isDefaultContact && extraData.url?.text?.includes('https://university.consentio.co')) {
              EventTrack.track('chat_default_contact_university_click', {
                contact_id: senderId,
                url: extraData.url?.text,
              });
            }
            if (extraData.url?.text)
              EventTrack.track('message_link_click', {
                channel_id: message.channelId,
                message_id: message.messageId,
                channel_type: channelType,
                link: extraData.url.text,
              });
          }}
        >
          {extraData.metadata.image_url ? (
            <S.MetaImage src={extraData.metadata.image_url} alt={extraData.metadata.title} />
          ) : null}
          <S.MetaColumn>
            {extraData.metadata.title ? <S.MetaTitle>{extraData.metadata.title}</S.MetaTitle> : null}
            {extraData.metadata.description ? (
              <S.MetaDescription>{extraData.metadata.description}</S.MetaDescription>
            ) : null}
          </S.MetaColumn>
        </S.MetaRow>
      ) : null}
      {message.message.split(/(?:\r\n|\r|\n|\\n)/).map((p: string, idx: number, array: Array<string>) => (
        <S.Texts key={idx + p}>
          <TextParagraph {...props} p={p} isLastMessage={idx === array.length - 1} />
        </S.Texts>
      ))}
    </S.TextWrapper>
  );
});

/**
 * Render the message text.
 */
const TextParagraph = React.memo((props: IProps & { p: string; isLastMessage: boolean }) => {
  const { contacts, channelType, history, message, me, navigateToShowroom, onTouchImage, p, isLastMessage, members } =
    props;
  const { extraData, senderId } = message;
  const isDefaultContact = contacts?.[senderId]?.isDefaultContact;

  const paragraphs = utils.splitTextUrls(p).map((t, i, array) => {
    if (!t.text) return <br key={i + ''} />;
    if (t.isLink) {
      const { type, url, thumbnailUrl } = utils.analyzeUrl(t.text);
      if (type === 'image')
        return (
          <S.Link key={i + url} onClick={onTouchImage ? () => onTouchImage([{ src: url }], 0) : undefined}>
            <S.LinkImage key={i} src={url} alt={url} />
          </S.Link>
        );
      if (type === 'video')
        return (
          <S.LoomLink key={i + url} href={url} target="_blank" rel="noopener noreferrer">
            <S.LinkImage src={thumbnailUrl} alt={t.text} />
          </S.LoomLink>
        );
      return (
        <S.Link
          key={i + url}
          href={addProtocol(url)}
          target="_blank"
          onClick={e => {
            if (extraData.action === 'navigate:showroom') {
              e.preventDefault();
              return navigateToShowroom(extraData.ownerId, extraData.query);
            }
            if (isDefaultContact && url.includes('https://university.consentio.co')) {
              EventTrack.track('chat_default_contact_university_click', { contact_id: senderId, url });
            }
            EventTrack.track('message_link_click', {
              channel_id: message.channelId,
              message_id: message.messageId,
              channel_type: channelType,
              link: url,
            });
          }}
        >
          {url}
        </S.Link>
      );
    }
    return (
      <S.Text key={i + t.text}>
        {t.text.split(/(@mention{\d+})/g).map((ts, index, arr) => {
          const mention = ts.match(/@mention{(\d+)}/);
          if (mention) {
            const contactId = Number(mention[1]);
            const isMe = contactId === me.id;
            const member = members.find(m => m.id === contactId && m.isRegistered !== undefined && m.isRegistered);
            if (isMe || member)
              return (
                <Mention
                  contactId={contactId}
                  history={history}
                  key={mention[1] + index}
                  name={isMe ? me.name : member.name}
                />
              );
          }

          const textSplit = emojiSplitter.splitGraphemes(ts);
          const isBigEmoji = textSplit.length === 1 && textSplit[0].length > 1;

          if (isBigEmoji) return <S.BigEmoji key={i + ts + index}>{textSplit[0]}</S.BigEmoji>;

          const isLast = index === arr.length - 1;
          return (
            <React.Fragment key={ts + index}>
              {textSplit.map((s, idx) => {
                // tip to know quickly if it's an emoji
                if (s.length > 1) {
                  return <S.Emoji key={i + s + idx + index}>{s}</S.Emoji>;
                }
                return s;
              })}
              {isLastMessage && isLast && message.isEdited ? (
                <S.TextEdited> {__('Components.Chat.edited')}</S.TextEdited>
              ) : null}
            </React.Fragment>
          );
        })}
      </S.Text>
    );
  });
  return <>{paragraphs}</>;
});

/**
 * Render edit input + save / cancel buttons
 */
const EditForm = React.memo((props: IProps & { textAreaRef: React.RefObject<HTMLTextAreaElement> }) => {
  const {
    contacts,
    channelType,
    editMessage,
    goToBottom,
    isLast,
    members,
    me,
    message,
    onEditMessageChange,
    onSubmitEdit,
    textAreaRef,
  } = props;

  const editMessageWithMentions = editMessage
    .split(/(@mention{\d+})/g)
    .map((ts, index) => {
      const mention = ts.match(/@mention{(\d+)}/);
      const memberId = mention ? Number(mention[1]) : 0;
      const memberName = memberId === me.id ? me.name : members.find(m => m.id === memberId)?.name;
      return mention && memberName ? ts.replace(/@mention{(\d+)}/, `@${memberName.replace(/ /g, '_')}`) : ts;
    })
    .join('')
    .trimLeft();

  const [showMentionModal, setShowMentionModal] = React.useState<string>();
  const [mentionSelected, setMentionSelected] = React.useState(0);
  const mentionsContainerRef = React.useRef();

  const focusWithCaretAtPosition = (input, position) => {
    if (input) {
      input.focus();
      input.setSelectionRange(position, position);
    }
  };

  const addMentionInInput = React.useCallback(
    (member: IMember) => {
      if (!member) return;
      if (members.find(m => m.id === member.id && member.name) || member.id === me.id) {
        const text = textAreaRef.current.value;
        const caretAt = textAreaRef.current.selectionStart;
        const [, wordCaretEnd] = utils.getWordBoundariesAtPosition(text, caretAt);

        // We need to find where the mention start to strip it out of the input
        const textBeforeCaret = text.slice(0, caretAt);
        const textBeforeCaretSplitByArobas = textBeforeCaret.split('@');
        const textFromLastArobas = textBeforeCaretSplitByArobas[textBeforeCaretSplitByArobas.length - 1];
        const mentionStartsAt = textBeforeCaret.length - textFromLastArobas.length - 1;

        // Get where the next before and after the mention to reconstruct the input
        const textBeforeMention = text.slice(0, mentionStartsAt).trim();
        const mentionDisplayed = `@${member.name.replace(/ /g, '_')}`;
        const textAfterMention = text.slice(wordCaretEnd).trim();
        const nextText =
          textBeforeMention.length > 0
            ? `${textBeforeMention} ${mentionDisplayed}  ${textAfterMention}`
            : `${mentionDisplayed}  ${textAfterMention}`;
        const newCaretPosition = nextText.length - textAfterMention.length - 1;

        setMentionSelected(0);
        setShowMentionModal(undefined);
        onEditMessageChange(nextText);
        setTimeout(() => focusWithCaretAtPosition(textAreaRef.current, newCaretPosition));
      }
    },
    [me, members, onEditMessageChange, setMentionSelected, setShowMentionModal, textAreaRef],
  );

  const getMembersFilteredForMention = React.useCallback(() => {
    if (showMentionModal === undefined) {
      return [];
    }
    const normalizedLookup = utils.toLocaleLowerCaseNormalized(showMentionModal);

    return members.filter(m => {
      if ((m.isRegistered !== undefined && !m.isRegistered) || !m.name) {
        return false;
      }

      if (normalizedLookup.length === 0) {
        return true;
      }

      const lowercaseName = utils.toLocaleLowerCaseNormalized(m.name);
      const withoutSpace = lowercaseName.replace(/ /g, '');

      return withoutSpace && withoutSpace.startsWith(normalizedLookup);
    });
  }, [members, showMentionModal]);

  const scrollToMemberInList = React.useCallback(
    (member: IMember) => {
      if (!member) return;
      const mentionModal = document.getElementById('mention-modal');
      const memberElement = document.getElementById(`member-select-${member.id}`);
      // Don't scroll for the first 6 elements
      if (mentionSelected < 6 && mentionModal?.scrollTop === 0) return;

      memberElement?.scrollIntoView({ behavior: 'smooth' });
    },
    [mentionSelected],
  );
  React.useEffect(() => {
    scrollToMemberInList(getMembersFilteredForMention()[mentionSelected]);
  }, [getMembersFilteredForMention, mentionSelected, showMentionModal, scrollToMemberInList]);

  React.useEffect(() => {
    if (isLast) goToBottom();
  }, [isLast, goToBottom]);
  const computeShowMentionModal = (text, position) => {
    if (channelType === CHANNEL_TYPE.GROUP) {
      const textBeforeCaret = text.slice(0, position) as string;
      const textFromLastArobas = utils.getTextAfterLastMention(text, position);
      const normalizedLastMention = utils.toLocaleLowerCaseNormalized(
        textFromLastArobas.replace(/ /g, '').replace('_', ''),
      );

      if (normalizedLastMention.length === 0 && !textBeforeCaret.trim().endsWith('@')) {
        setShowMentionModal(undefined);
        return;
      }

      const matchingMembers = members.filter(member => {
        if (member.isRegistered !== undefined && !member.isRegistered) {
          return false;
        }
        const normalizedMemberName = utils.toLocaleLowerCaseNormalized(member.name.replace(/ /g, ''));
        return normalizedMemberName.startsWith(normalizedLastMention);
      });

      if (matchingMembers.length > 0) {
        setShowMentionModal(normalizedLastMention);
      } else {
        setShowMentionModal(undefined);
      }
    }
  };

  return (
    <S.EditContainer>
      <S.MessageInput
        autoFocus={true}
        name="edit-message-input"
        onChangeText={e => {
          onEditMessageChange(e);
          computeShowMentionModal(e, e.length);
        }}
        onFocus={e => {
          setTimeout(() => {
            if (textAreaRef && textAreaRef.current)
              textAreaRef.current.setSelectionRange(editMessageWithMentions.length, editMessageWithMentions.length);
          }, 50);
          const wordAtCaret = e ? utils.getWordAtPosition(e, e?.length) : undefined;
          if (wordAtCaret?.startsWith('@')) setShowMentionModal(wordAtCaret);
        }}
        onKeyDown={e => {
          const input = e.target as HTMLTextAreaElement;
          if (showMentionModal !== undefined) {
            if (['Tab', 'ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) e.preventDefault();
            switch (e.key) {
              case 'Tab':
              case 'ArrowDown':
                if (mentionSelected !== getMembersFilteredForMention().length - 1)
                  setMentionSelected(mentionSelected + 1);
                break;
              case 'ArrowUp':
                if (mentionSelected > 0) setMentionSelected(mentionSelected - 1);
                break;
              case 'Enter':
                const memberToAdd = getMembersFilteredForMention()[mentionSelected];
                addMentionInInput(memberToAdd);
                break;
              case 'ArrowLeft':
              case 'ArrowRight':
                const newCaretAt =
                  input.selectionStart > 0 ? input.selectionStart + (e.key === 'ArrowLeft' ? -1 : 1) : 0;
                const wordAtCaret = utils.getWordAtPosition(input.value, newCaretAt);

                if (!wordAtCaret.startsWith('@')) {
                  setMentionSelected(0);
                  setShowMentionModal(undefined);
                } else {
                  setShowMentionModal(wordAtCaret);
                }
                break;
              default:
                break;
            }
          }
          switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
              // See if we have a relevent @ sign before the caret
              const nextCaretPosition = input.selectionStart + (e.key === 'ArrowLeft' ? -1 : 1);
              const text = input.value;
              const textBeforeCaret = text.slice(0, nextCaretPosition) as string;
              const textFromLastArobas = utils.getTextAfterLastMention(text, nextCaretPosition);
              const lastMentionWithoutSpaces = textFromLastArobas.replace(/ /g, '');

              if (lastMentionWithoutSpaces.length === 0 && !textBeforeCaret.endsWith('@')) {
                setMentionSelected(0);
                setShowMentionModal(undefined);
              } else {
                setShowMentionModal(lastMentionWithoutSpaces);
              }
              break;
            default:
              break;
          }
        }}
        placeholder={__('Components.Chat.placeholder')}
        sendMultipleFiles={false}
        sendButtonId="edit-message-input"
        showEmojiSelector={true}
        showSendFiles={false}
        showSendButton={false}
        text={editMessageWithMentions}
        maxLength={1500}
      />
      <S.EditButtons>
        <S.SaveText
          onClick={() => {
            return onSubmitEdit(editMessageWithMentions, message, onEditMessageChange);
          }}
          disabled={editMessageWithMentions === ''}
        >
          {__('Components.AddNewAddress.save')}
        </S.SaveText>
        <S.CancelText onClick={() => onEditMessageChange(undefined)}>{__('Components.Modal.cancel')}</S.CancelText>
      </S.EditButtons>
      {showMentionModal !== undefined ? (
        <S.MentionModal
          className="mention-modal"
          contacts={contacts}
          innerRef={mentionsContainerRef}
          members={getMembersFilteredForMention()}
          mentionSelected={mentionSelected}
          onClick={(member: IMember) => addMentionInInput(member)}
          onMouseEnter={() => setMentionSelected(-1)}
          showMentionModal={showMentionModal}
        />
      ) : undefined}
    </S.EditContainer>
  );
});

/**
 * add http to the url if the url hasn't protocol
 */
function addProtocol(u: string) {
  if (!/^(?:(?:https?|ftp|file):\/\/)/i.test(u)) {
    return 'http://' + u;
  }
  return u;
}

export default TextMessage;
