import {
  __,
  constants,
  debounce,
  EventTrack,
  LOCALE,
  ModalActions,
  parsers,
  productService,
  qs,
  throttle,
  utils,
} from 'common-services';
import { productToGenericProduct } from 'common-services/dist/parsers';
import { History, Location } from 'history';
import * as React from 'react';

import { FeaturedCard, NonResults, SectionList, SimpleSearch } from '..';
import config from '../../../../bindings/config';
import { getListModeOptions, NO_TAG } from '../../../constants';
import ProductListShare from '../../../screens/product-list-share';
import { api } from '../../../store';
import { Button, FilterSwitchIcon, FontIcon, IOSLoading, RowContainer } from '../../atoms';
import { AddressModal, ProductFilter } from '../../molecules';
import { ProductCard, ProductList, ProductTable } from './Fragments';
import * as S from './SearchProducts.styled';

interface IProps {
  addresses?: Array<IAddress>;
  allSelected?: boolean;
  amSeller: boolean;
  cart?: Array<IOrderItem>;
  cartClean: (key: string) => void;
  cartUpdateItem: (item?: IOrderItem, deliveryAddress?: IAddress) => void;
  catalog: IWorkspace;
  companyColor?: string;
  contact?: IContact;
  inContract?: number;
  notInContract?: number;
  excludedProducts?: Array<number>;
  featured: { [key: string]: Array<string> };
  featuredToggle?: (type: IFeaturedType, productHashId: string) => void;
  fetchProducts?: (addressId?: number) => void;
  history: History<any>;
  isLogged: boolean;
  isServedFlow?: boolean;
  location: Location<any>;
  me: IUser;
  modalClose?: () => void;
  modalOpen?: (text: string, action?: any, extra?: IModalExtraInfo, name?: IModalName) => ModalActions;
  navigateToProduct?: (i: IOrderItem, product: IProduct) => void;
  notificationShow?: (notif: INotification, millis: number) => void;
  onSelectAll?: (allSelected: boolean) => void;
  onSelectAllSearch?: (search: ISearch) => void;
  openShareSearch?: () => void;
  originLot?: number;
  ownerId?: number;
  priceGroupIds: Array<string>;
  priceMode?: IPriceMode;
  prices: { [key: number]: IPrice };
  productsExcludedIds?: Array<number>;
  sectionsEnabled: boolean;
  selectAll?: boolean;
  selectedProducts?: Array<number>;
  selectMode?: 'integration' | 'multiple' | 'offers';
  sendExcel?: () => void;
  showFeatured: boolean;
  showShare: boolean;
  status: IStatus;
  title: string;
}

interface IState {
  address?: IAddress;
  facets: IFacets;
  facetsGlobal: IFacets;
  hasMore?: boolean;
  hideFilters?: boolean;
  isSearched?: boolean;
  listMode: 'card' | 'list';
  listToShow: IFeaturedFilters;
  productDisplayed?: IProduct;
  products: Array<IProduct>;
  productToShare?: GenericProduct;
  productToShareName?: string;
  searchFixed?: boolean;
  searchId?: string;
  searchState: ISearch;
  sectionSelected?: string;
  showAddressesModal?: boolean;
  showSearch?: boolean;
  showSections: boolean;
  showShare?: boolean;
  totalResults: number;
  boxTypes?: Array<ICustomBoxType>;
  palletTypes?: Array<ICustomPalletType>;
}

/**
 * SearchProducts with elastic search
 */
class SearchProducts extends React.PureComponent<IProps, IState> {
  /**
   * Update history URL with query and product id params
   */
  private updateURLParams = debounce((searchState: ISearch) => {
    const { productDisplayed } = this.state;
    const { location } = this.props;
    const productHash = productDisplayed ? productDisplayed.hashId : undefined;
    this.props.history.replace(
      location.pathname +
        qs.stringify({
          ...qs.parse(location.search || ''),
          ...searchState,
          id: productHash,
        }),
    );
  }, 25);

  /**
   * Send search with elastic
   */
  private sendSearch = debounce(() => {
    const { searchState } = this.state;
    const { me } = this.props;
    productService
      .productSearch(
        { ...searchState, featured: this.getFeatureds() },
        searchState.language || (me.settings.language as LOCALE),
        config.SEARCH_PREFIX,
        undefined,
        api,
        true,
      )
      .then(this.onReceiveProducts);
  }, 200);

  private getMore = throttle(() => {
    const { searchId } = this.state;
    productService.productScrollSearch(searchId, api).then(this.onReceiveProducts);
  }, 200);

  constructor(props: IProps) {
    super(props);
    const { query, tag } = qs.parse(props.location.search, ['query', 'tag']) as {
      query?: string;
      tag?: string;
    };

    const defaultListMode =
      typeof window !== 'undefined' && ['card', 'list'].includes(window.localStorage.getItem('products_modal_display'))
        ? (window.localStorage.getItem('products_modal_display') as 'card' | 'list')
        : 'list';

    this.state = {
      hideFilters: true,
      searchState: {
        text: query || '',
        index: props.catalog?.hashId,
        rate: props.priceGroupIds,
        language: props.me.settings.language as LOCALE,
        status: props.status ? [props.status] : [],
        featured: [],
        productsExcludedIds: props.productsExcludedIds,
        notInContract: props.notInContract,
        contract: props.inContract,
      },
      listToShow: 'all',
      listMode: defaultListMode,
      sectionSelected: tag,
      isSearched: false,
      showSections: !!(props.sectionsEnabled && props.catalog && props.catalog.sections.length && !query),
      totalResults: 0,
      products: [],
      facets: {},
      facetsGlobal: {},
    };
  }

  public componentDidMount() {
    const { catalog, me } = this.props;

    if (catalog) {
      this.sendSearch();
      api.product.getBoxTypes(me.id!, catalog.id).then(res => {
        this.setState({ boxTypes: res });
      });
      api.product.getPalletTypes(me.id!, catalog.id).then(res => {
        this.setState({ palletTypes: res });
      });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { addresses, catalog, me, priceGroupIds, productsExcludedIds, sectionsEnabled } = this.props;
    const { address, products, searchId, listToShow, sectionSelected, productDisplayed, searchState } = this.state;
    if (addresses && addresses !== prevProps.addresses && !address) {
      const defaultAddress = addresses.find(ad => ad.transportCost);
      if (defaultAddress) {
        this.setState({ address: defaultAddress });
      }
    }
    if (searchState.language !== prevState.searchState.language) {
      this.setState({ searchState: { ...searchState, language: searchState.language } });
    }
    if (
      (catalog && catalog !== prevProps.catalog && catalog.sections?.length) ||
      listToShow !== prevState.listToShow ||
      sectionSelected !== prevState.sectionSelected ||
      sectionsEnabled !== prevProps.sectionsEnabled
    ) {
      if (catalog.id !== prevProps.catalog.id) {
        api.product.getBoxTypes(me.id!, catalog.id).then(res => {
          this.setState({ boxTypes: res });
        });
        api.product.getPalletTypes(me.id!, catalog.id).then(res => {
          this.setState({ palletTypes: res });
        });
      }
      this.setState({ showSections: sectionsEnabled && listToShow === 'all' && !sectionSelected });
      if (products === prevState.products) {
        this.setState({ isSearched: false });
      }
    }
    if (prevState.productDisplayed !== productDisplayed || prevState.searchState.language !== searchState.language) {
      this.updateURLParams(searchState);
    }

    if (
      prevProps.priceGroupIds !== priceGroupIds ||
      prevProps.catalog !== catalog ||
      listToShow !== prevState.listToShow ||
      sectionSelected !== prevState.sectionSelected ||
      productsExcludedIds !== prevProps.productsExcludedIds
    ) {
      this.updateURLParams(searchState);
      if (catalog) {
        this.setState({
          searchState: {
            ...searchState,
            index: catalog?.hashId,
            rate: priceGroupIds,
            tag: sectionSelected,
            featured: this.getFeatureds(),
            productsExcludedIds,
          },
        });
      }
    }

    if (searchState !== prevState.searchState && catalog) {
      this.sendSearch();
    }
    if (searchId && prevState.searchId !== searchId) {
      this.setState({ isSearched: true });
    }
  }

  public render() {
    const {
      allSelected,
      cart,
      cartUpdateItem,
      catalog,
      excludedProducts,
      me,
      ownerId,
      prices,
      sectionsEnabled,
      selectedProducts,
      selectMode,
      title,
    } = this.props;
    const {
      boxTypes,
      facets,
      facetsGlobal,
      hideFilters,
      isSearched,
      listMode,
      listToShow,
      palletTypes,
      products,
      searchFixed,
      searchState,
      sectionSelected,
      showAddressesModal,
      showSections,
      showShare,
    } = this.state;
    const hasSections = sectionsEnabled && showSections && !!catalog.sections.find(s => facets && facets.tags?.[s.tag]);
    const amSeller = ownerId === me.id;
    return (
      <>
        <S.Container>
          {this.renderBack()}
          <S.Row>
            {hasSections || !isSearched ? null : (
              <ProductFilter
                clearFilters={() => {
                  this.setState({
                    searchState: {
                      text: searchState.text,
                      language: searchState.language,
                      index: searchState.index,
                      rate: searchState.rate,
                      tag: searchState.tag,
                      featured: searchState.featured,
                      productsExcludedIds: searchState.productsExcludedIds,
                      status: searchState.status,
                      sections: searchState.sections,
                      priceType: searchState.priceType,
                    },
                  });
                  EventTrack.track('modal_products_filter_clear');
                }}
                changeSearchState={s => this.setState({ searchState: s })}
                facets={facets}
                facetsGlobal={facetsGlobal}
                me={me}
                searchState={searchState}
                onHide={h => this.setState({ hideFilters: h })}
                showClosed={hideFilters}
                showOver="always"
                boxTypes={boxTypes}
                palletTypes={palletTypes}
              />
            )}
            <S.ScrollContainer className="search-container" onScroll={this.onScroll}>
              {searchFixed ? <S.ControlsRow /> : this.renderSearch(hasSections)}
              {this.renderFeatured()}
              {hasSections ? (
                <React.Fragment>
                  <S.BigTitle>{__('Showroom.sections')}</S.BigTitle>
                  <SectionList
                    facets={facets}
                    myLocale={searchState.language || (me.settings.language as LOCALE)}
                    sections={catalog ? catalog.sections : []}
                    onClick={(tag: string) => {
                      this.setState({ showSections: false, sectionSelected: tag });
                    }}
                  />
                </React.Fragment>
              ) : null}
              {sectionSelected ? (
                <S.BigTitle>
                  {sectionSelected === NO_TAG
                    ? __('Showroom.no_tag')
                    : __('Showroom.titles.section', {
                        name: utils.firstToUpperCase(
                          catalog.sections.find(s => s.tag === sectionSelected)?.translations[me.settings.language] ||
                            sectionSelected,
                        ),
                      })}
                </S.BigTitle>
              ) : null}
              {listToShow !== 'all' && !sectionSelected ? (
                <S.BigTitle>{__('Showroom.titles.' + listToShow)}</S.BigTitle>
              ) : null}
              {!hasSections && !products ? (
                <S.CenterContainer>
                  <IOSLoading loading={true} />
                </S.CenterContainer>
              ) : null}
              {hasSections || !products || !isSearched ? null : (
                <>
                  <NonResults amSeller={amSeller} hasHits={!!products?.length} searchText={searchState.text}>
                    {listMode === 'card' ? (
                      <ProductList
                        cart={cart}
                        products={products}
                        ProductComponent={this.renderProduct}
                        selectedProducts={selectedProducts}
                      />
                    ) : (
                      <ProductTable
                        allSelected={allSelected}
                        catalog={catalog}
                        excludedProducts={excludedProducts}
                        onSelectProduct={(prod: IProduct) => {
                          cartUpdateItem(
                            parsers.genericProductToOrderItem(productToGenericProduct(prod), prod.price, 1),
                          );
                        }}
                        prices={prices}
                        products={products}
                        selectedProducts={selectedProducts}
                        selectMode={selectMode}
                      />
                    )}
                  </NonResults>
                </>
              )}
              {showAddressesModal ? this.renderAddressesModal() : null}
            </S.ScrollContainer>
          </S.Row>

          {searchFixed ? (
            <S.SearchFixed className="search-products-header-fixed">
              <S.TitleContainer>
                <S.Title>{title}</S.Title>
              </S.TitleContainer>
              {this.renderBack()}
              <S.SearchFixedWrapper>{this.renderSearch(hasSections, true)}</S.SearchFixedWrapper>
            </S.SearchFixed>
          ) : null}
        </S.Container>
        {showShare ? this.renderShareModal() : null}
      </>
    );
  }

  private renderBack = () => {
    const { sectionSelected, listToShow } = this.state;
    return listToShow !== 'all' || sectionSelected !== undefined ? (
      <S.BackContainer>
        <S.Back onClick={() => this.setState({ sectionSelected: undefined, listToShow: 'all' })}>
          <S.ChevronIcon name="Back" disableHover={true} />
          {__('Showroom.back_sections')}
        </S.Back>
      </S.BackContainer>
    ) : null;
  };

  /**
   * Render search input, share button and filters
   */
  private renderSearch = (hasSections: boolean, isFixed?: boolean) => {
    const { allSelected, addresses, catalog, onSelectAll, onSelectAllSearch, selectAll } = this.props;
    const { products, searchState, showSearch, hideFilters, isSearched, listMode, totalResults } = this.state;
    return (
      <S.ControlsRow>
        {addresses ? this.renderShowAddresses() : null}
        <S.SearchContainer id="containersearch">
          <S.SearchRow>
            <S.SearchWrapper id="search-products-wrapper">
              <S.FilterButton
                filtersOpened={!hideFilters}
                filtersSelected={this.getFiltersCount()}
                onClick={() => this.setState({ hideFilters: !hideFilters })}
              />
              <SimpleSearch
                id="search-products"
                query={searchState.text}
                onChange={this.setSearch}
                placeHolder={__('Components.ProductsList.search.placeholder')}
                RightButton={
                  searchState.text && products && products.length ? () => this.renderShareButton(true) : undefined
                }
              />
              {!showSearch && !searchState.text ? this.renderShareButton(false) : null}
              <S.RightContainer searchFixed={isFixed}>
                <FilterSwitchIcon
                  options={getListModeOptions()}
                  keySelected={listMode}
                  onSelectOption={(key: string) => {
                    this.setState({ listMode: key as 'card' | 'list' });
                    window.localStorage.setItem('products_modal_display', key);
                    EventTrack.track('products_change_display', {
                      workspace_id: catalog.id,
                      display: key,
                      type: 'modal',
                    });
                  }}
                />
              </S.RightContainer>
            </S.SearchWrapper>
          </S.SearchRow>
          <S.SearchSecondRow>
            <RowContainer>
              {hasSections || !products || !isSearched ? null : (
                <S.SearchStats>
                  <S.Text>
                    {__('Components.ProductsList.SearchStats', {
                      hits: totalResults || 0,
                      seconds: ((Math.random() || 1) / 10).toFixed(3),
                    })}
                  </S.Text>
                </S.SearchStats>
              )}
              {selectAll ? (
                !searchState.text && !this.getFiltersCount() ? (
                  <S.Link onClick={() => onSelectAll(!allSelected)}>
                    {allSelected
                      ? __('WorkspacePriceGroupEdit.unselect_all')
                      : __('WorkspacePriceGroupEdit.select_all')}
                  </S.Link>
                ) : (
                  <S.Link onClick={() => onSelectAllSearch(searchState)}>
                    {__('WorkspacePriceGroupEdit.select_all_search', { count: totalResults || 0 })}
                  </S.Link>
                )
              ) : null}
            </RowContainer>
            {this.renderSortList()}
          </S.SearchSecondRow>
        </S.SearchContainer>
      </S.ControlsRow>
    );
  };

  private renderSortList() {
    const { searchState } = this.state;
    return (
      <S.Sort
        onSelect={(k: string) =>
          this.setState({
            searchState: { ...searchState, sort: k, sortOrder: k ? 'desc' : 'asc' },
          })
        }
        options={[
          {
            key: `updated_at`,
            value: __('WorkspacePriceGroupEdit.products.sort.updated_at'),
          },
          { key: '', value: __('WorkspacePriceGroupEdit.products.sort.alphabetical') },
        ]}
      >
        <Button type="skip" iconName="Sort" iconSize="14px" withoutPadding={true}>
          <S.SortBlackText>{__('PublicShowroom.sort_by')}: </S.SortBlackText>
          <S.SortGreyText>
            {searchState.sort
              ? searchState.sort === `updated_at`
                ? __('WorkspacePriceGroupEdit.products.sort.updated_at')
                : __('WorkspacePriceGroupEdit.products.sort.price_updated_at')
              : __('WorkspacePriceGroupEdit.products.sort.alphabetical')}
          </S.SortGreyText>
          <FontIcon name="Down" />
        </Button>
      </S.Sort>
    );
  }

  /**
   * Count selected filters
   */
  private getFiltersCount() {
    const { searchState } = this.state;
    const { brand, category, organic, origin, size, type, priceType, status, sections } = searchState;
    return (
      (origin?.length || 0) +
      (organic?.length || 0) +
      (brand?.length || 0) +
      (type?.length || 0) +
      (size?.length || 0) +
      (category?.length || 0) +
      (priceType?.length || 0) +
      (status?.length || 0) +
      (sections?.length || 0)
    );
  }

  /**
   * Render product item
   */
  private renderProduct = ({ product }: { product: IProduct }): JSX.Element => {
    const {
      allSelected,
      cart,
      cartUpdateItem,
      catalog,
      companyColor,
      excludedProducts,
      featured,
      featuredToggle,
      history,
      isLogged,
      isServedFlow,
      me,
      navigateToProduct,
      originLot,
      ownerId,
      priceMode,
      prices,
      selectedProducts,
      selectMode,
      showFeatured,
      showShare,
    } = this.props;
    const { address } = this.state;
    const amSeller = ownerId === me.id;
    const isSelected = allSelected
      ? !(excludedProducts || []).includes(product.id)
      : (selectedProducts || []).includes(product.id);
    return (
      <ProductCard
        amSeller={amSeller}
        cartItem={cart?.find(c => c.childId === product.id)}
        cartUpdateItem={item => cartUpdateItem(item, address)}
        color={companyColor}
        featuredToggle={featuredToggle}
        hideFeatured={!isLogged || !showFeatured}
        history={history}
        isFavorite={featured?.favorite?.includes(product.hashId)}
        isRecent={featured?.recent?.includes(product.hashId)}
        isRecommended={featured?.recommended?.includes(product.hashId)}
        isSelected={isSelected}
        isServedFlow={isServedFlow}
        key={'_' + product.id}
        navigateToProduct={navigateToProduct}
        openShareProduct={this.shareProduct}
        originLot={originLot}
        price={prices?.[product.id]}
        priceMode={priceMode || 'none'}
        pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
        product={product}
        productMappingStrategy={catalog.productMappingStrategy}
        selectMode={selectMode}
        showShare={showShare}
      />
    );
  };

  /**
   * Render the selector for addresses
   */
  private renderShowAddresses = () => {
    const { addresses, cart, modalClose, modalOpen, priceMode, ownerId, me } = this.props;
    const { address } = this.state;
    const a = address || addresses.find(ad => ad.transportCost);

    const amBuyer = ownerId !== me.id;
    if (amBuyer && (!priceMode || priceMode === 'none')) return null;

    return a ? (
      <S.AddressSelected
        onClick={() =>
          cart && cart.length
            ? modalOpen(
                __('Showroom.changeAddressCart'),
                () => {
                  modalClose();
                  this.setState({ showAddressesModal: true });
                },
                {
                  showCancelButton: true,
                },
                'nice',
              )
            : this.setState({ showAddressesModal: true })
        }
      >
        <S.AddressIcon name="Address" disableHover={true} />
        <S.Container>
          <S.AddressSelect>
            {__('Showroom.addressSeleceted')}
            <S.AddressTitle>{a.displayName}</S.AddressTitle>
          </S.AddressSelect>
          {a.city || a.zip ? <S.AddressText>{`${a.city ? a.city + ',' : ''} ${a.zip || ''}`}</S.AddressText> : null}
        </S.Container>
        <S.ChevronIcon name="Down" />
      </S.AddressSelected>
    ) : null;
  };

  /**
   * Render the modal with the all addresses for select
   */
  private renderAddressesModal = () => {
    const { addresses, catalog, cartClean, fetchProducts, me, contact, ownerId } = this.props;
    const { address } = this.state;
    return (
      <AddressModal
        catalogId={catalog.id}
        address={address}
        addresses={addresses}
        onSelectAddress={(a: IAddress) => {
          fetchProducts(a.id);
          this.setState({ showAddressesModal: false, address: a });
          cartClean(productService.getKey(ownerId, me.id === ownerId ? contact.id : me.id));
        }}
        onClose={() => {
          this.setState({ showAddressesModal: false });
        }}
        title={__('Showroom.addressModalTitle')}
        subtitle={__('Showroom.addressModalDescription')}
      />
    );
  };

  /**
   * On Share product event
   */
  private shareProduct = (product: GenericProduct, productName: string) => {
    const { searchState } = this.state;
    this.onShareSearch(product, productName, searchState);
  };

  /**
   * Render the share button for the search or for the all the products
   */
  private renderShareButton(isSearch?: boolean) {
    const { showShare } = this.props;
    const { searchState } = this.state;
    return showShare ? (
      <S.ShareTouchable
        id="products-list-share-catalog"
        isSearch={isSearch}
        onClick={() => this.onShareSearch(undefined, undefined, searchState)}
      >
        <S.ShareIcon name="Share-web" />
        <S.TextShare>
          {isSearch ? __('Components.ProductListShare.share_search') : __('Components.ProductListShare.share_showroom')}
        </S.TextShare>
      </S.ShareTouchable>
    ) : null;
  }

  /**
   * Render fake sections like favorites, recent and recommended
   * Block shouldn't be rendered if we are on some section (fake or not) or until we have prices
   */
  private renderFeatured = () => {
    const { me, ownerId, prices, priceGroupIds, showFeatured, featured } = this.props;
    const {
      listToShow,
      sectionSelected,
      searchState: { text },
    } = this.state;

    const shouldShowFeatured =
      listToShow === 'all' && showFeatured && prices !== undefined && sectionSelected === undefined && text === '';

    if (!shouldShowFeatured) {
      return null;
    }

    // Counters
    const amSeller = ownerId === me.id;
    const activePrices = prices
      ? Object.keys(prices).filter(k => !priceGroupIds?.length || prices[Number(k)].status === 'active')
      : [];
    const numFavorite = featured?.favorite
      ? activePrices.filter(k => featured.favorite.includes(prices[Number(k)].hashId)).length
      : 0;
    const numRecent = featured?.recent
      ? activePrices.filter(k => featured.recent.includes(prices[Number(k)].hashId)).length
      : 0;

    const numRecommended = featured?.recommended
      ? activePrices.filter(k => featured.recommended.includes(prices[Number(k)].hashId)).length
      : 0;

    return (
      <React.Fragment>
        <S.FeaturedContainer>
          <S.FeaturedList>
            {amSeller || numRecommended ? (
              <FeaturedCard
                amount={numRecommended}
                amSeller={amSeller}
                onClick={() => this.changeListToShow('recommended')}
                type="recommended"
              />
            ) : null}
            <FeaturedCard
              amount={numFavorite}
              amSeller={amSeller}
              onClick={() => this.changeListToShow('favorite')}
              type="favorite"
            />
            <FeaturedCard
              amount={numRecent}
              amSeller={amSeller}
              onClick={() => this.changeListToShow('recent')}
              type="recent"
            />
          </S.FeaturedList>
        </S.FeaturedContainer>
      </React.Fragment>
    );
  };

  /**
   * Render share product modal
   */
  private renderShareModal() {
    const { contact, history, amSeller, me } = this.props;
    const { productToShare, productToShareName, searchState, totalResults } = this.state;
    return (
      <S.ModalShare>
        <ProductListShare
          amSeller={amSeller}
          close={this.closeShareSearchModal}
          contactId={contact && contact.id}
          history={history}
          ownerId={amSeller ? me.id : contact?.id}
          catalogId={
            amSeller || contact?.isUnregistered
              ? contact?.mySellerWorkspaceId || me.sellerWorkspaceId
              : contact?.theirSellerWorkspaceId
          }
          productHashId={productToShare ? productToShare.productHashId : undefined}
          product={productToShare}
          productName={productToShareName}
          search={searchState ? searchState.text : ''}
          numResults={totalResults}
        />
      </S.ModalShare>
    );
  }

  /**
   * handle change text in the search input
   */
  private setSearch = (t: string) => {
    const { searchState } = this.state;
    this.setState({
      searchState: { ...searchState, text: t },
      showSections: !t.length,
      sectionSelected: undefined,
    });
  };

  /**
   * Change list to show between featured filters or All
   */
  private changeListToShow = (listToShow: IFeaturedFilters) => {
    const { me, ownerId } = this.props;
    EventTrack.filterResults({
      name: 'products-filter',
      filter: listToShow,
      place: 'showroom',
      role: ownerId === me.id ? 'seller' : 'buyer',
    });
    this.setState({ listToShow, showSections: listToShow === 'all' });
  };

  /**
   * return featureds filter for search
   */
  private getFeatureds() {
    const { listToShow } = this.state;
    const { prices, showFeatured, featured } = this.props;
    if (!showFeatured) return [];

    const activePrices = prices ? Object.values(prices) : [];
    switch (listToShow) {
      case 'recommended':
        return activePrices.filter(price => featured.recommended.includes(price.hashId)).length
          ? [...featured.recommended.filter(l => !!l)]
          : ['none'];
      case 'favorite':
        return activePrices.filter(price => featured.favorite.includes(price.hashId)).length
          ? [...featured.favorite.filter(l => !!l)]
          : ['none'];
      case 'recent':
        return activePrices.filter(price => featured.recent.includes(price.hashId)).length
          ? [...featured.recent.filter(l => !!l)]
          : ['none'];
    }
    return;
  }

  /**
   * receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return null;
    const { catalog } = this.props;
    const { searchState } = this.state;
    const filters = productService.getTrackedFilters(searchState);
    const { data, toReplace, facets, facetsGlobal } = res;
    if (filters.length) {
      EventTrack.track('modal_products_filter', {
        workspace_id: catalog.id,
        results: data.totalResults,
        filters,
      });
    }
    if (toReplace) {
      this.setState({
        products: data.hits,
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
        facets,
        facetsGlobal,
      });
    } else {
      this.setState({
        products: [...this.state.products, ...data.hits],
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
      });
    }
    if (searchState.text) {
      EventTrack.track('modal_products_search', {
        results: data.totalResults,
        text: searchState.text,
        workspace_id: catalog.id,
      });
    }
  };

  /**
   * handle the scroll event for sticky the searchbar
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore } = this.state;
    if (e.currentTarget.scrollTop > 110) {
      if (!this.state.searchFixed) this.setState({ searchFixed: true });
    }
    if (e.currentTarget.scrollTop < 90) {
      if (this.state.searchFixed) this.setState({ searchFixed: false });
    }
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.getMore();
    }
  };

  /**
   * On share product, opens modal
   */
  private onShareSearch = (product?: GenericProduct, productName?: string, searchState?: ISearch) => {
    this.setState({
      productToShare: product,
      productToShareName: productName,
      searchState,
      showShare: true,
    });
  };
  /**
   * Close share product modal
   */
  private closeShareSearchModal = () => {
    this.setState({
      showShare: false,
      productToShare: undefined,
      productToShareName: undefined,
    });
  };
}

export default SearchProducts;
