import * as React from 'react';
import * as S from './ReferenceListDetail.style';
import { __, utils } from 'common-services';
import { IColumn } from '../../../../molecules/Table/Table.component';
import { MiniImages } from '../../../../molecules';
import { SimpleDropdown } from '../../../../atoms';
import { Sections } from '../../../../molecules/FormMenu/FormMenu.component';
import { IAssociationsReference, IGroupedDataReferenceList } from 'common-services/dist/associate-reference/interface';
import { IMAGE_DEFAULT } from '../../../../../constants';

export const columnsProductTable = [
  {
    value: 'productDescription',
    label: __('Components.SellerOffer.referenceList.referenceListSelected.productSection.productDescription'),
  },
  { value: 'SKU', label: __('Components.SellerOffer.referenceList.referenceListSelected.productSection.SKU') },
];

export const getColumnsProductTable = (): Array<IColumn> => {
  return [
    {
      title: __('Components.SellerOffer.referenceList.referenceListSelected.productSection.productDescription'),
      id: 'productDescription',
      width: '120px',
      element: (data: IGroupedDataReferenceList) => {
        const productImages = data?.images?.map(image => image.image.url) || [IMAGE_DEFAULT];
        return (
          <>
            <S.ContainerImageAndText>
              <S.ProductImage imageUrl={productImages[0] || IMAGE_DEFAULT} size="extra-small" />
              <S.ProductText>{data.productName || ''}</S.ProductText>
            </S.ContainerImageAndText>
          </>
        );
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.referenceListSelected.productSection.SKU'),
      id: 'SKU',
      width: '100px',
      sort: 'sku',
      sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
      element: (data: IGroupedDataReferenceList) => {
        return (
          <S.FlexRow>
            <S.ProductText>{data.sku}</S.ProductText>
          </S.FlexRow>
        );
      },
    },
  ];
};

export const getSections = () => {
  const result: Sections = [
    {
      id: 'product',
      title: __('Components.SellerOffer.referenceList.referenceListSelected.productSection.title'),
      icon: 'Edit',
    },
    {
      id: 'associated-reference',
      title: __('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.title'),
      icon: 'Document',
    },
  ];

  return result;
};

export const columnsAssociatedReferenceTable = [
  {
    value: 'clientsReferences',
    label: __(
      'Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.clientsReferences',
    ),
  },
  {
    value: 'type',
    label: __('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.type'),
  },
  {
    value: 'clients',
    label: __('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.clients'),
  },
];

export const getColumnsAssociatedReferenceTable = (
  handleDeleteReference: (data: IAssociationsReference) => Promise<void>,
): Array<IColumn> => {
  return [
    {
      title: __(
        'Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.clientsReferences',
      ),
      id: 'clientsReferences',
      width: '120px',
      element: (data: IAssociationsReference) => {
        return (
          <>
            <S.ReferenceText>{data.name}</S.ReferenceText>
          </>
        );
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.type'),
      id: 'type',
      width: '60px',
      sort: 'type',
      sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
      element: (data: IAssociationsReference) => {
        return <S.ProductText>{utils.translateProductKind(data.kindName)}</S.ProductText>;
      },
    },
    {
      title: __('Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.clients'),
      id: 'clients',
      width: '80px',
      element: (data: IAssociationsReference) => {
        const productImages = [data.workspacePublicOverview.pictureUrl];
        const personNames: ILightContact = {
          avatarColor: {
            background: '',
            text: '',
          },
          companyName: data.workspacePublicOverview.publicName,
          isUnregistered: false,
          name: data.workspacePublicOverview.publicName,
          phoneNumbers: [],
        };
        return (
          <>
            <MiniImages
              imagesAndPersons={{ imageUrl: productImages, person: [personNames] }}
              count={productImages.length}
              mode="imagesAndPersons"
            />
          </>
        );
      },
    },
    {
      title: '',
      id: 'id',
      width: '0px',
      element: (data: IAssociationsReference) => {
        return (
          <>
            <SimpleDropdown
              options={[
                {
                  key: 'delete',
                  value: __(
                    'Components.SellerOffer.referenceList.referenceListSelected.associatedReferenceSection.deleteAssociation',
                  ),
                },
              ]}
              hAlign="left"
              onSelect={(key: string) => {
                if (key === 'delete') {
                  handleDeleteReference(data);
                }
              }}
            >
              <S.KebabIcon name="Kebab" />
            </SimpleDropdown>
          </>
        );
      },
    },
  ];
};
