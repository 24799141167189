import { __, date, IMessage, utils } from 'common-services';
import * as React from 'react';
import ReactPlayer from 'react-player';

import * as S from './VideoMessage.styled';

interface IProps {
  message: IMessage;
}

const VideoMessage: React.FC<IProps> = ({ message }) => {
  const [playPressed, setPlayPressed] = React.useState(false);

  if (message.isRemoved)
    return (
      <S.TextWrapper className="video-message-container">
        <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
      </S.TextWrapper>
    );

  const videoUrl = message.message;
  const mediaMetadata = message.extraData?.media_metadata as IMediaMetadata;
  const duration = mediaMetadata?.duration;
  return (
    <S.Container className="video-message-container">
      <ReactPlayer
        className="react-video-player"
        controls={true}
        height="auto"
        light={utils.getThumbnailFromVideoUrl(videoUrl)}
        onClickPreview={() => setPlayPressed(true)}
        playing={true}
        url={videoUrl}
        width="100%"
        playIcon={<PlayVideoIcon />}
      />
      {duration && !playPressed ? (
        <S.DurationContainer>
          <S.TextDuration>{date.getDurationLabel(duration)}</S.TextDuration>
        </S.DurationContainer>
      ) : null}
    </S.Container>
  );
};

const PlayVideoIcon = () => {
  return (
    <S.PlayContainer>
      <S.PlayIcon name="Chevron-right" disableHover={true} />
    </S.PlayContainer>
  );
};

export default React.memo(VideoMessage);
