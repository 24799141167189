import { __, IReferenceSupplier, ISupplier, utils } from 'common-services';
import * as React from 'react';

import { IColumn } from './components/molecules/Table/Table.component';
import * as S from './util/styles';

const getColumns = (): Array<IColumn> => [
  {
    title: __('Components.Referential.reference.sellers'),
    element: ({ supplier, refSupplier }: { supplier: ISupplier; refSupplier: IReferenceSupplier }) => {
      return (
        <S.UserRow>
          <S.UserAvatar
            text={refSupplier.workspace.name}
            img={refSupplier.workspace.pictureUrl}
            avatarColor={utils.getAvatarColor(refSupplier.workspace.name)}
            size={32}
          />
          <S.UserColumn>
            <S.BoldText>{refSupplier.workspace.name}</S.BoldText>
            <S.Text>
              {supplier && supplier.name ? supplier.name : __('Facets.unknown') + ` (${refSupplier?.id})`}
            </S.Text>
          </S.UserColumn>
        </S.UserRow>
      );
    },
  },
];

export default getColumns;
