import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TagBubble, TextLabel } from '../../atoms';
import Table from '../Table';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  toggleCantRepeatNorDraftNotHistory?: number;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
  justify-content: center;
  align-items: center;
`;

export const EmptyCell = styled(ColumnContainer)`
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
`;

export const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: 800;
`;
export const NameText = styled(TextBlack)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const LeftContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;
export const CtaButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const OfferTable = styled(Table)`
  tr:nth-child(n+${(p: IStyledProps) => p.toggleCantRepeatNorDraftNotHistory}) {
    cursor: default;
  }
`;
