import { __ } from 'common-services';
import * as React from 'react';

import theme from '../../../theme';
import { ColumnContainer, Modal, RowContainer } from '../../atoms';
import StyledTextLabel from '../../atoms/TextLabel';
import { StyledListLi, StyledListUl } from './ErrorModal.styled';

interface IProps {
  errors: Array<string>;
}

const ErrorModal: React.FC<IProps> = ({ errors = [] }) => {
  return (
    <Modal toShow={true} color={theme.colors.white} backgroundColor={/* theme.colors.secondary */ '#f09f00'}>
      <RowContainer flex="1" justifyContent="center" alignItems="center">
        <ColumnContainer>
          <StyledTextLabel
            margin="0 0 15px 0"
            textAlign="left"
            fontWeight="normal"
            width={'100%'}
            color={theme.colors.white}
            textTransform={'uppercase'}
          >
            {__('Components.ProductDetails.errors')}
          </StyledTextLabel>

          {errors.length ? (
            <StyledListUl>{errors.map((error, i) => <StyledListLi key={i}>{error}</StyledListLi>)}</StyledListUl>
          ) : null}
        </ColumnContainer>
      </RowContainer>
    </Modal>
  );
};

export default React.memo(ErrorModal);
