import { constants, parsers, to, __ } from 'common-services';
import * as React from 'react';
import * as S from './MappedProductModal.styled';

import { History } from 'history';
import { api } from '../../../../../store';
import { ActionsModal, ProductCard } from '../../../../molecules';
import { ProductList } from '../../../../molecules/SearchProducts/Fragments';

const MappedProductModal: React.FC<{
  amSeller: boolean;
  cart?: ICart;
  catalog: IWorkspace;
  close: () => void;
  featured: { [key: string]: Array<string> };
  history: History<any>;
  item: IOrderItem;
  myId: number;
  name?: string;
  priceMode: IPriceMode;
  products: Array<IProduct>;
  setProductSelected: (item: IOrderItem) => void;
  viewAll: () => void;
}> = ({
  amSeller,
  cart,
  catalog,
  close,
  featured,
  history,
  item,
  myId,
  name,
  priceMode,
  products,
  setProductSelected,
  viewAll,
}) => {
  const [prices, setPrices] = React.useState<Record<number, IPrice>>();
  React.useEffect(() => {
    to(
      api.buyerWorkspace.pricesSearchGet(
        myId,
        products.map(p => p.id),
      ),
    ).then(({ data }) => setPrices(data));
  }, []);
  /**
   * Render product item
   */
  const renderProduct = ({ product }: { product: IProduct }): JSX.Element => {
    const productData = { ...product, ...(prices?.[product.id] || {}) };
    const data = parsers.productToGenericProduct(productData);
    return (
      <ProductCard
        amSeller={amSeller}
        cartUpdateItem={selectedItem => setProductSelected(selectedItem)}
        hideFeatured={true}
        history={history}
        isFavorite={featured?.favorite?.includes(product.hashId)}
        isRecent={featured?.recent?.includes(product.hashId)}
        isRecommended={featured?.recommended?.includes(product.hashId)}
        key={'_' + product.id}
        price={productData.price}
        priceMode={priceMode || 'none'}
        pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
        productMappingStrategy={catalog.productMappingStrategy}
        selectMode={'integration'}
        showShare={false}
        data={data}
        navigate={() => null}
        orderItemId={item?.id}
      />
    );
  };
  return (
    <ActionsModal
      width="90%"
      title={__('Components.OrderDetails.productMappingModal.title', { code: name })}
      onClose={close}
      subtitle={''}
    >
      <S.ScrollContainer>
        <ProductList cart={cart?.items} products={products} ProductComponent={renderProduct} />
        <S.Link type="link" onClick={viewAll}>
          {__('Components.OrderDetails.productMappingModal.viewAll')}
        </S.Link>
      </S.ScrollContainer>
    </ActionsModal>
  );
};

export default React.memo(MappedProductModal);
