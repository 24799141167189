import { __, debounce, LOCALE, RenderTrack, sellerWorkspaceService, productService } from 'common-services';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { NO_TAG, ShowroomPage } from '../../../../../constants';
import { api } from '../../../../../store';
import { BackButton } from '../../../../atoms';
import { SectionCard } from '../../../../molecules';
import * as SR from '../../Showroom.styled';
import * as S from './Sections.styled';

interface IProps {
  catalog: IWorkspace;
  from: 'pricelist' | 'showroom';
  lang: LOCALE;
  me: IUser;
  onNavigate: (page: ShowroomPage, key?: string) => void;
  priceGroupIds?: Array<string>;
}

interface IState {
  facets: IFacets;
  searchState: ISearch;
}

/**
 * Screen with all sections
 */
class Sections extends React.PureComponent<IProps, IState> {
  /**
   * Send products' search
   */
  private sendProductsSearch = debounce(() => {
    const { searchState } = this.state;
    const { me } = this.props;
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
      .then(this.onReceiveProducts);
  }, 200);
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { catalog, priceGroupIds, lang } = props;
    this.state = {
      searchState: {
        text: '',
        index: catalog.hashId,
        rate: priceGroupIds,
        language: lang,
        status: ['active', 'unavailable'],
      },
      facets: {},
    };
  }

  public componentDidMount() {
    const { from } = this.props;
    RenderTrack.track(from === 'pricelist' ? 'PublicShopSections' : 'ShowroomSections', { renderTime: this.t });
    this.sendProductsSearch();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalog, priceGroupIds } = this.props;
    const { searchState } = this.state;
    // might be not needed
    if (prevState.searchState !== searchState) {
      this.sendProductsSearch();
    }

    // Control catalog or price groups update to fill the elastic search state
    if (
      (catalog && prevProps.catalog?.id !== catalog.id && catalog.hashId) ||
      priceGroupIds !== prevProps.priceGroupIds
    ) {
      this.setState({ searchState: { ...searchState, rate: priceGroupIds, index: catalog?.hashId || '' } });
    }
  }

  public render() {
    const { catalog, from, lang, onNavigate } = this.props;
    const { facets } = this.state;
    return (
      <S.Body from={from}>
        <SR.HeaderRow>
          <BackButton onClick={() => onNavigate('home')} text={__('PublicShowroom.back_text')} />
        </SR.HeaderRow>
        <SR.Group>
          <SR.GroupTitle>{__('PublicShowroom.Titles.sections')}</SR.GroupTitle>
          <SR.GridGroup from={from}>
            {catalog.sections
              .filter(s => facets && facets.tags?.[s.tag])
              .sort((a, b) => a.position - b.position)
              .map(s => (
                <SectionCard
                  onClick={() => onNavigate('sections', s.tag)}
                  amount={((facets && facets.tags?.[s.tag]) as number) || 0}
                  key={s.tag}
                  section={s}
                  myLocale={lang}
                />
              ))}
            {facets && facets.tags?.[NO_TAG] ? (
              <SectionCard
                onClick={() => onNavigate('sections', NO_TAG)}
                amount={facets.tags?.[NO_TAG] as number}
                key={NO_TAG}
                section={sellerWorkspaceService.sectionNew(NO_TAG, catalog.sections.length + 1, 0)}
                myLocale={lang}
              />
            ) : null}
          </SR.GridGroup>
        </SR.Group>
      </S.Body>
    );
  }

  /**
   * receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (res)
      this.setState({
        facets: res.facetsGlobal,
      });
  };
}

export default Sections;
