import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel/TextLabel.styled';
import TooltipAtom from '../Tooltip';

interface IStyledProps {
  theme: ITheme;
  checked?: boolean;
  margin?: string;
  disabled?: boolean;
}

export const RadioLabel = styled.label<IStyledProps>`
  margin: ${(p: IStyledProps) => p.margin || '0 ' + p.theme.paddingSize(3) + ' 0 0'};
  display: flex;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  user-select: none;
  flex-direction: column;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.grey2 : p.theme.colors.black)};
`;

export const Subtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TooltipIconWrapper = styled(RowContainer)`
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const Tooltip = styled(TooltipAtom)`
  margin-left: 5px;
  align-self: center;
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const RadioInputContainer = styled(RowContainer)``;

export const TextReadOnly = styled(TextLabel)`
  width: 100%;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin: ${(p: IStyledProps) => p.margin || '0'};
`;
