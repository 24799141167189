import {
  __,
  parsers,
  currency,
  i18n,
  productService,
  constants,
  IReferenceSellerView,
  IOfferedProduct,
  PRODUCT_UNIT,
} from 'common-services';
import * as React from 'react';
import { unitTranslator } from '../../../util/unit';
import { Input, Select } from '../../atoms';
import { IColumn } from '../Table/Table.component';
import * as S from './OfferedProductTable.styled';
import { checkRepeatedClientAssociations } from './Functions';

export enum Availability {
  total = 'total',
  address = 'address',
}

export enum DepartureAndDeliveredPrice {
  departureAndDeliveredPrice = 'departureAndDeliveredPrice',
  departurePrice = 'departurePrice',
  deliveredPrice = 'deliveredPrice',
}
interface ColumnsProps {
  clientAssociations: Array<IReferenceSellerView>;
  products: Array<
    GenericProduct & {
      cellClassName?: string;
    }
  >;
  prodTypes: {
    [key: string]: IProdType;
  };
  toggleAssociateProductsEnabled: boolean;
  availability: Availability;
  editable: boolean;
  removeReference(sku: string, associatedReferenceId: number): void;
  setHasChanges: () => void;
  setSelectedProduct: (value: React.SetStateAction<GenericProduct>) => void;
  departureAndDeliveredPrice: DepartureAndDeliveredPrice;
  setProductOffer: (sku: string, name: string, value: number | string | boolean, warehouseId?: number) => void;
  offeredProducts: Array<IOfferedProduct>;
  setSelectAvailable: (value: React.SetStateAction<string>) => void;
  selectAvailable: string;
  deleteProduct: (id: string) => void;
  addresses: Array<IAddress>;
}

export const getColumns = (props: ColumnsProps): Array<IColumn> => {
  const productColumns: Array<IColumn> = [
    {
      title: __('Components.ProductsList.Table.Sku'),
      id: 'sku',
      width: '80px',
      element: (data: GenericProduct) => {
        return <S.TextBlack>{data.sku}</S.TextBlack>;
      },
    },
    {
      title: __('Components.ProductsList.Table.TypeVariety'),
      id: 'name',
      width: '150px',
      element: (data: GenericProduct) => {
        const { hasRepeatedSkus, referenceSellerView } = checkRepeatedClientAssociations(
          data,
          props.clientAssociations,
          props.products,
        );
        return (
          <S.ProductWrapper>
            <S.TextBlack>
              {data.productTitle ||
                productService.getProductTypeVarietyDisplay(
                  data.type,
                  props.prodTypes[data.type]?.name || '',
                  data.variety,
                )}
            </S.TextBlack>
            {hasRepeatedSkus ? (
              <S.WarningRow>
                <S.WarningIcon name="Warning" disableHover={true} />
                <S.WarningText>
                  {__('Components.OffersList.table.warning', {
                    reference: referenceSellerView ? referenceSellerView.reference?.name : '',
                  })}
                </S.WarningText>
              </S.WarningRow>
            ) : null}
          </S.ProductWrapper>
        );
      },
    },
    ...(props.toggleAssociateProductsEnabled
      ? [
          {
            title: __('Components.OffersList.reference'),
            id: 'reference',
            width: '100px',
            info: __('Components.OffersList.table.tooltip'),
            element: (data: GenericProduct) => {
              const referenceSellerView = props.clientAssociations?.length
                ? props.clientAssociations?.find(ca => ca.sku === data.sku)
                : undefined;
              return referenceSellerView ? (
                <S.ReferenceRow>
                  <S.TextBlack>{referenceSellerView?.reference.name}</S.TextBlack>
                  {props.editable ? (
                    <S.CloseIcon
                      name="Close"
                      onClick={async () => {
                        props.removeReference(data.sku, referenceSellerView.reference.id);
                        props.setHasChanges();
                      }}
                    />
                  ) : null}
                </S.ReferenceRow>
              ) : props.editable ? (
                <S.ReferenceCta onClick={() => props.setSelectedProduct(data)}>
                  {__('Components.OffersList.table.associate_reference')}
                </S.ReferenceCta>
              ) : (
                <S.ZeroCaseReference>{'-'}</S.ZeroCaseReference>
              );
            },
          },
        ]
      : []),
    {
      title: __('Components.ProductsList.Table.Origin'),
      id: 'origin',
      width: '80px',
      element: (data: GenericProduct) => {
        const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
        return <S.TextBlack disabled={hasRepeatedSkus}>{data.origin}</S.TextBlack>;
      },
    },
    ...(props.departureAndDeliveredPrice === DepartureAndDeliveredPrice.deliveredPrice
      ? []
      : [
          {
            title: __('Components.OffersList.table.departurePrice'),
            id: 'offeredDeparturePrice',
            width: '120px',
            element: (data: GenericProduct) => {
              const products = props.offeredProducts.filter(p => p.sku === data.sku);
              if (products.length === 0) {
                return null;
              }
              const departurePrice = products[0].offeredDeparturePrice;
              const formattedDeparturePrice = departurePrice?.toFixed(2);

              if (props.editable) {
                return (
                  <>
                    <S.AmountInput
                      id={'offeredDeparturePrice-' + data.sku}
                      name={'offeredDeparturePrice'}
                      onBlur={(name, value: number) => {
                        props.setProductOffer(data.sku, name, value, undefined);
                        props.setHasChanges();
                      }}
                      value={departurePrice}
                      minValue={0}
                      width="120px"
                      type="number"
                      precision={2}
                      textAlign="left"
                      autoFocus={false}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      containerMargin="0 6px 0 0"
                      variableTextSingular={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
                    />
                  </>
                );
              } else if (
                props.availability === Availability.total &&
                props.departureAndDeliveredPrice === DepartureAndDeliveredPrice.deliveredPrice
              ) {
                return <S.TextBold>{'-'}</S.TextBold>;
              } else {
                return (
                  <S.TextBold>{`${formattedDeparturePrice}${currency.getPricePerUnit(
                    data.currency,
                    data.priceUnit,
                    data.weightUnit,
                  )}`}</S.TextBold>
                );
              }
            },
          },
        ]),
    {
      title: __('Components.OffersList.table.total_availability'),
      id: 'totalQuantity',
      width: '120px',
      element: (data: GenericProduct, rowIdx: number) => {
        if (props.availability === Availability.address || !props.editable) {
          const products = props.offeredProducts.filter(p => p.sku === data.sku);
          if (products.length === 0) {
            return null;
          }
          const isUnlimited = products.every(p => p.unlimitedQuantity === true);

          const initialAvailabilities: Record<string, number> = {};
          const totalAvailabilitiesPerSaleUnit = products.reduce((acc, product) => {
            if (product.offeredQuantity === 0 && product.totalQuantity > 0) {
              if (acc[product.totalQuantityUnit]) {
                acc[product.totalQuantityUnit] += product.totalQuantity;
              } else {
                acc[product.totalQuantityUnit] = product.totalQuantity;
              }
              return acc;
            }
            if (acc[product.offeredQuantityUnit]) {
              acc[product.offeredQuantityUnit] += product.offeredQuantity;
            } else {
              acc[product.offeredQuantityUnit] = product.offeredQuantity;
            }
            return acc;
          }, initialAvailabilities);

          const formatAvailabilityString = (availabilities: Record<string, number>): string => {
            return Object.entries(availabilities)
              .filter(([, quantity]) => quantity > 0)
              .map(([unit, quantity]) => `${quantity} ${unit ? unitTranslator(unit, quantity) : ''}`)
              .join(' + ');
          };

          const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
          return (
            <S.TextBold disabled={hasRepeatedSkus}>
              {isUnlimited
                ? __('Components.OffersList.table.unlimited')
                : (() => {
                    const availabilityString = formatAvailabilityString(totalAvailabilitiesPerSaleUnit);
                    return availabilityString || '-';
                  })()}
            </S.TextBold>
          );
        } else {
          const offeredProduct = props.offeredProducts.find(p => p.sku === data.sku);
          if (!offeredProduct) {
            return null;
          }

          const selectOptions = data.saleUnits.map(s => ({
            value: s,
            label: parsers.getUnitText(s, data.weightUnit, 0, i18n.getLanguageCode(i18n.default.currentLocale())),
          }));

          let totalQuantityUnit: string = selectOptions[0].value;
          // if offeredProduct.totalQuantityUnit is in the selectOptions, then set it as the default value
          if (selectOptions.find(option => option.value === offeredProduct.totalQuantityUnit)) {
            totalQuantityUnit = offeredProduct.totalQuantityUnit;
          }

          return (
            <S.InputRow>
              <Input
                id={`total-quantity-input-${rowIdx}`}
                name={'totalQuantity'}
                onChange={(name, value: number) => {
                  props.setProductOffer(data.sku, name, Number(value), undefined);
                  props.setHasChanges();
                }}
                value={offeredProduct.totalQuantity || 0}
                containerMargin="0"
                width="80px"
                type="number"
                textAlign="right"
                hasError={false}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <Select
                containerMargin="0 6px"
                value={totalQuantityUnit}
                name={`totalQuantityUnit`}
                dropUp={rowIdx >= props.products.length - 2 ? true : false}
                options={selectOptions}
                zIndex={3}
                onChange={(name, value) => {
                  props.setProductOffer(data.sku, name, value);
                  props.setSelectAvailable(value);
                  props.setHasChanges();
                }}
                width={'90px'}
              />
            </S.InputRow>
          );
        }
      },
    },
  ];

  if (props.editable) {
    productColumns.unshift({
      title: '',
      id: 'delete',
      width: '50px',
      element: (data: GenericProduct) => {
        return (
          <S.IconWrapper>
            <S.RemoveIcon
              name="Close"
              onClick={e => {
                props.deleteProduct(data.sku);
                props.setHasChanges();
                e.stopPropagation();
              }}
            />
          </S.IconWrapper>
        );
      },
    });
  }

  if (props.addresses?.length > 0) {
    props.addresses?.map(address => {
      productColumns.push({
        title: __('Components.OffersList.table.deliveredPrice'),
        id: 'price',
        width: '100px',
        cellClassName: 'offer-bordered-cell-left',
        element: (data: any, _, rowIdx: number) => {
          const priceValue =
            props.offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
              ?.offeredPrice || 0;
          const formattedPriceValue = priceValue?.toFixed(2);
          const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);

          if (
            (props.availability === Availability.total || props.availability === Availability.address) &&
            props.departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice
          ) {
            return <S.TextBold>-</S.TextBold>;
          }
          return props.editable ? (
            <Input
              id={`price-input-${rowIdx}`}
              name={'offeredPrice'}
              onBlur={(name, value: number) => {
                props.setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                props.setHasChanges();
              }}
              value={priceValue}
              disabled={hasRepeatedSkus}
              zeroCase="0"
              precision={2}
              variableTextSingular={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
              width="120px"
              type="number"
              textAlign="left"
              hasError={false}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          ) : (
            <S.TextBlack>{`${formattedPriceValue} ${currency.getPricePerUnit(
              data.currency,
              data.priceUnit,
              data.weightUnit,
            )}`}</S.TextBlack>
          );
        },
      });
      if (props.availability !== Availability.total) {
        productColumns.push({
          title: __('Components.OffersList.table.daily_availability'),
          id: 'daily-availability',
          width: '110px',
          cellClassName: 'offer-bordered-cell-right',
          element: (data: any, _, rowIdx: number) => {
            const offeredProduct = props.offeredProducts.find(
              p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id),
            );
            const unlimitedAvailability = offeredProduct?.unlimitedQuantity === true;
            const availabilityValue: number =
              props.offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
                ?.offeredQuantity || 0;

            if (props.editable) {
              const selectOptions = data.saleUnits.map(s => ({
                value: s,
                label: parsers.getUnitText(s, data.weightUnit, 0, i18n.getLanguageCode(i18n.default.currentLocale())),
              }));
              selectOptions.push({ value: 'unlimited', label: __('Components.OffersList.table.unlimited') });
              const { hasRepeatedSkus } = checkRepeatedClientAssociations(
                data,
                props.clientAssociations,
                props.products,
              );
              return (
                <S.InputRow>
                  {unlimitedAvailability ? null : (
                    <Input
                      id={`availability-input-${address.id}-${data.sku}`}
                      name={'offeredQuantity'}
                      onChange={(name, value: number) => {
                        props.setProductOffer(
                          data.sku,
                          name,
                          Number(value),
                          address.id !== -1 ? address.id : undefined,
                        );
                        props.setHasChanges();
                      }}
                      disabled={hasRepeatedSkus}
                      value={availabilityValue}
                      containerMargin="0"
                      width="80px"
                      type="number"
                      textAlign="left"
                      hasError={false}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <Select
                    containerMargin={unlimitedAvailability ? '0' : '0 6px'}
                    value={unlimitedAvailability ? 'unlimited' : offeredProduct?.offeredQuantityUnit}
                    name={`offeredQuantityUnit`}
                    dropUp={rowIdx >= props.products.length - 2 ? true : false}
                    options={selectOptions}
                    zIndex={3}
                    disabled={hasRepeatedSkus}
                    onChange={(name, value) => {
                      if (value === 'unlimited') {
                        props.setProductOffer(
                          data.sku,
                          'unlimitedQuantity',
                          true,
                          address.id !== -1 ? address.id : undefined,
                        );
                      } else {
                        props.setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                        if (unlimitedAvailability) {
                          props.setProductOffer(
                            data.sku,
                            'unlimitedQuantity',
                            false,
                            address.id !== -1 ? address.id : undefined,
                          );
                        }
                      }
                      props.setHasChanges();
                    }}
                    width={unlimitedAvailability ? '165px' : '90px'}
                  />
                </S.InputRow>
              );
            } else {
              return (
                <S.TextBlack>
                  {unlimitedAvailability
                    ? __('Components.OffersList.table.unlimited')
                    : `${availabilityValue || '-'} ${parsers.getUnitText(
                        data.saleUnit,
                        data.weightUnit,
                        availabilityValue,
                        i18n.getLanguageCode(i18n.default.currentLocale()),
                      )}`}
                </S.TextBlack>
              );
            }
          },
        });
      }
    });
  }

  return productColumns;
};
