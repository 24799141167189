import styled, { ITheme } from '../../../styled-components';
import { FontIcon } from '../../atoms';

interface IStyledProps {
  cursor?: string;
  theme: ITheme;
}
interface HandleWrapperProps {
  isDragging: boolean;
  rank: number;
}

export const HandleWrapper = styled.div<HandleWrapperProps>`
  display: flex;
  align-items: center;
  height: 1rem;
  vertical-align: bottom;
  margin-right: 0.5rem;
  svg {
    width: 100%;
    height: 100%;
  }
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`;
export const Icon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: 16px;
  width: 16px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  cursor: cursor;
`;
