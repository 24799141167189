import { __, parsers, productActions } from 'common-services';
import { History, Location } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { SearchProducts } from '..';
import * as S from './SearchProductModal.styled';

export interface IProps {
  addressId?: number;
  amSeller: boolean;
  cartClean: () => void;
  catalog: IWorkspace;
  close: () => void;
  contactId: number;
  featured: { [key: string]: Array<string> };
  history: History<any>;
  inContract?: number;
  notInContract?: number;
  isServedFlow?: boolean;
  location: Location<any>;
  me: IUser;
  name?: string;
  onSelectAllSearch?: (search: ISearch) => void;
  onSubmitSelectedProducts?: (
    selectedProducts: Array<GenericProduct>,
    allSelected: boolean,
    excludedProducts?: Array<number>,
  ) => void;
  originLot?: number;
  priceMode: IPriceMode;
  priceGroupIds?: Array<string>;
  prices: { [key: number]: IPrice };
  productsExcludedIds?: Array<number>;
  selectAll?: boolean;
  selectMode?: 'integration' | 'multiple' | 'offers';
  selectProductItem?: (item: IOrderItem) => void;
  showFeatured: boolean;
  status?: IStatus;
  subtitle?: string;
  title?: string;
}

const SearchProductModal: React.FC<IProps> = ({
  addressId,
  amSeller,
  cartClean,
  catalog,
  close,
  contactId,
  featured,
  history,
  inContract,
  notInContract,
  isServedFlow,
  location,
  me,
  name,
  onSelectAllSearch,
  onSubmitSelectedProducts,
  originLot,
  priceGroupIds,
  priceMode,
  prices,
  productsExcludedIds,
  selectAll,
  selectMode,
  selectProductItem,
  showFeatured,
  status,
  subtitle,
  title,
}) => {
  const dispatch = useDispatch<any>();
  const [allSelected, setAllSelected] = React.useState(false);
  const [excludedProducts, setExcludedProducts] = React.useState<Array<number>>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<Array<GenericProduct>>([]);

  React.useEffect(() => {
    fetchProducts();
    return () => {
      if (!originLot) history.replace(location.pathname);
    };
  }, []);

  return (
    <S.Modal title={title || __('Components.Cart.search_product.title', { name })} onClose={close} subtitle={subtitle}>
      <S.Container>
        <SearchProducts
          amSeller={amSeller}
          allSelected={allSelected}
          cartClean={cartClean}
          cartUpdateItem={onSelectProductItem}
          catalog={catalog}
          excludedProducts={excludedProducts}
          featured={featured}
          fetchProducts={fetchProducts}
          history={history}
          isLogged={true}
          isServedFlow={isServedFlow}
          location={location}
          me={me}
          onSelectAllSearch={onSelectAllSearch}
          onSelectAll={(allProductsSelected: boolean) => {
            setAllSelected(allProductsSelected);
            setExcludedProducts([]);
            setSelectedProducts([]);
          }}
          originLot={originLot}
          ownerId={me.id}
          priceGroupIds={priceGroupIds}
          priceMode={priceMode}
          prices={prices}
          productsExcludedIds={productsExcludedIds}
          sectionsEnabled={false}
          selectAll={selectAll}
          selectedProducts={selectedProducts.map(p => p.productId)}
          selectMode={selectMode}
          showFeatured={showFeatured}
          showShare={false}
          status={status}
          title={title}
          notInContract={notInContract}
          inContract={inContract}
        />
        {(selectMode === 'multiple' || selectMode === 'offers') && (selectedProducts?.length || allSelected)
          ? renderSelectProductsCTA()
          : null}
      </S.Container>
    </S.Modal>
  );

  /**
   * Render select products CTA
   */
  function renderSelectProductsCTA() {
    if (!onSubmitSelectedProducts) return null;
    return (
      <S.CTAWrapper>
        <S.CTA
          className="search-product-submit-products-cta"
          onClick={() => onSubmitSelectedProducts(selectedProducts, allSelected, excludedProducts)}
        >
          {__('Components.ProductCard.add_product', {
            count: allSelected
              ? Object.keys(prices).length - (productsExcludedIds || []).length - excludedProducts.length
              : selectedProducts.length,
          })}
        </S.CTA>
      </S.CTAWrapper>
    );
  }

  /**
   * On select a product item. If select mode multiple, keep a state with product selected ids
   */
  function onSelectProductItem(item: IOrderItem) {
    if (selectMode === 'multiple' || selectMode === 'offers') {
      // Exclusion mode when all are selected
      if (allSelected) {
        const excludedProductsCopy = excludedProducts.slice();
        if (excludedProductsCopy.includes(item.childId)) {
          excludedProductsCopy.splice(excludedProductsCopy.indexOf(item.childId), 1);
        } else {
          excludedProductsCopy.push(item.childId);
        }
        setExcludedProducts(excludedProductsCopy);
      } else {
        // Normal inclusion mode
        const selectedProductsCopy = selectedProducts.slice();
        const productFound = selectedProductsCopy.find(p => p.productId === item.childId);
        if (productFound) {
          selectedProductsCopy.splice(selectedProductsCopy.indexOf(productFound), 1);
        } else {
          selectedProductsCopy.push(parsers.orderItemToGenericProduct(item));
        }
        setSelectedProducts(selectedProductsCopy);
      }
    }
    selectProductItem?.(item);
  }

  /**
   * Fetch products' prices according to each case mode (Buy / Sell)
   */
  function fetchProducts() {
    if (!catalog || !contactId || me.id === contactId) return;
    dispatch(productActions.getPrices(catalog.id, contactId, undefined, addressId));
  }
};

export default SearchProductModal;
