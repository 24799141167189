import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  disabled?: boolean;
  isImage?: boolean;
}

const TextRegular = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const AttachmentContainer = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2) ${(p: IStyledProps) => p.theme.paddingObject};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  overflow: hidden;
`;

export const DocumentIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 45px;
  width: 50px;
  height: 50px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  line-height: 37px;
  padding-left: 1px;
  align-self: center;
`;

export const GalleryBigIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 35px;
  width: 50px;
  height: 50px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  line-height: 37px;
  align-self: center;
`;

export const MimeTypeLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  object-fit: cover;
  align-self: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  cursor: ${(p: IStyledProps) => (p.isImage ? 'pointer' : 'auto')};
`;

export const AttachmentInfoCol = styled(ColumnContainer)`
  justify-content: center;
  width: 90%;
  overflow: hidden;
`;

export const TextFile = styled(TextRegular)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextFileDescription = styled(TextFile)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TextFileUpload = styled(TextFile)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const DownloadIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.primary};
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingView};
  cursor: pointer;
  align-self: center;
`;
