import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
