import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  showTimeSelect?: boolean;
  theme: ITheme;
}

export const Container = styled.div<IStyledProps>`
  margin: 0;
  .react-datepicker {
    font-family: 'Nunito';
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    font-family: 'Nunito';
    width: 100%;
    display: flex;
    flex: 1;
  }
  input.date-picker {
    line-height: 1.2rem;
    outline: none;
    border: 0;
    font-family: inherit;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    width: 0;
    flex: 1 1 auto;
    cursor: auto;
    text-transform: none;
    background-color: transparent;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  }

  .react-datepicker__header {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: ${(p: IStyledProps) => (p.showTimeSelect ? '0' : '0.3rem')};
  }

  .react-datepicker__header--time {
    border-top-left-radius: 0;
    border-top-right-radius: 0.3rem;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background-color: ${(p: IStyledProps) => p.theme.colors.green1} !important;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
    &:hover {
      background-color: ${(p: IStyledProps) => p.theme.colors.green1} !important;
      opacity: 0.8;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${(p: IStyledProps) => p.theme.colors.transparent} !important;
    color: ${(p: IStyledProps) => p.theme.colors.black} !important;
    &:hover {
      background-color: ${(p: IStyledProps) => p.theme.colors.transparent} !important;
      color: ${(p: IStyledProps) => p.theme.colors.black} !important;
      opacity: 0.8;
    }
  }
  .react-datepicker__navigation {
    &:focus {
      outline: none;
    }
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${(p: IStyledProps) => p.theme.colors.black};
  }
  .react-datepicker__navigation--next {
    border-left-color: ${(p: IStyledProps) => p.theme.colors.black};
  }
`;

interface WrapperProps {
  iconUrl?: string;
  theme: ITheme;
  editable?: boolean;
}

export const DatePickerWrapper = styled.div`
  padding: 5px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  color: ${(p: WrapperProps) => p.theme.colors.black};
  font-size: ${(p: WrapperProps) => p.theme.fontSize.normal};
  border: 0.5px solid ${(p: WrapperProps) => p.theme.colors.grey2};
  font-family: inherit;
  border-radius: 4px;
  display: flex;
  flex: 1;
  min-height: 30px;

  ${(p: WrapperProps) =>
    p.editable
      ? `&:focus-within {
    outline: none;
    border: 1px solid ${(p: WrapperProps) => p.theme.colors.green1};
    box-shadow: 0 0 4px ${(p: WrapperProps) => p.theme.colors.grey3};
  }`
      : ''}

  ${(p: WrapperProps) =>
    p.iconUrl &&
    css`
      background-image: url(${p.iconUrl});
      background-repeat: no-repeat;
      background-position: 98% 8px;
    `};
`;

export const FontIconWrapper = styled.label`
  font-size: 18px;
  width: 22px;
  height: 18px;
  padding: 0;
  padding-top: 2px;
  color: ${(p: WrapperProps) => p.theme.colors.black};

  div: first-child: hover {
    opacity: ${(p: WrapperProps) => (p.editable ? 0.75 : 1)};
  }
  div: first-child::before {
    cursor: ${(p: WrapperProps) => (p.editable ? 'pointer' : 'default')};
  }
`;
