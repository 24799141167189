import styled, { ITheme } from '../../../../../styled-components';
import { RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  code?: string;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  width: 100%;
  align-self: flex-start;
  position: relative;
  & .date {
    display: none;
  }
  & .message-actions {
    display: none;
  }
  &:hover {
    & .date {
      display: block;
    }
    & .message-actions {
      display: flex;
    }
  }
  padding-bottom: 2px;
`;

export const MessageText = styled.span`
  padding-right: 4px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: contents;
  text-align: center;
`;

export const MessageTextBold = styled(MessageText)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;
