import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const ContainerList = styled.div`
  display: -ms-flexbox;
  display: grid;
  flex-wrap: wrap;
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  }
  @media (max-width: 640px) {
    justify-items: center;
  }
`;
