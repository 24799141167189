import { __ } from 'common-services';
import * as S from './ReferenceListDetail.style';
import * as React from 'react';
import { ValidateReferenceState } from './ConstantsAndFunctions';

interface ValidateReferenceProps {
  validateReference: ValidateReferenceState;
  setShowClientModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReferenceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setValidateReference: React.Dispatch<React.SetStateAction<ValidateReferenceState>>
}

const ValidateReferenceComponent: React.FC<ValidateReferenceProps> = ({
  validateReference,
  setShowClientModal,
  setShowReferenceModal,
  setValidateReference,
}) => {
  return (
    <>
      <S.ConfirmModal title={__('Components.SellerOffer.referenceList.referenceListSelected.confirmModal.title')}>

        <S.Message>
          {__('Components.SellerOffer.referenceList.referenceListSelected.confirmModal.message', {
            reference: validateReference.reference.name,
          })}
        </S.Message>
        <S.ContainerButtonsConfirmModal>
          <S.ButtonCustom
            type="principal"
            onClick={() => {
              setShowClientModal(false);
              setShowReferenceModal(true);
              setValidateReference({ ...validateReference, isPresent: false });
            }}
          >
            {__('Components.SellerOffer.referenceList.referenceListSelected.confirmModal.accept')}
          </S.ButtonCustom>

          <S.ButtonCustom
            onClick={() => setValidateReference({ isPresent: false, reference: undefined})}
            type="secondary"
          >
            {__('Components.SellerOffer.referenceList.referenceListSelected.confirmModal.cancel')}
          </S.ButtonCustom>
        </S.ContainerButtonsConfirmModal>
      </S.ConfirmModal>
    </>
  );
};

export default ValidateReferenceComponent;
