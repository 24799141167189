import {
  Api,
  IPriceGroup,
  modalActions,
  notificationsActions,
  productActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WorkspacePriceGroups, { IDispatchProps, IStateProps } from '../components/organisms/WorkspacePriceGroups';
import { IReduxState } from '../reducers';

const emptyPriceGroups: { [priceGroupId: string]: IPriceGroup } = {};

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    contact: { inConsentio },
    catalog: { catalogs, priceGroups },
    user: { user },
  } = state!;
  const catalogId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;
  return {
    catalogId,
    catalogs,
    contacts: inConsentio,
    me: user,
    priceGroups: (priceGroups || {})[catalogId] || emptyPriceGroups,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      getPrices: productActions.getPrices,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      priceGroupsDownload: productActions.priceGroupsDownload,
      priceGroupsGet: sellerWorkspaceActions.priceGroupsGet,
      requestProInfo: userActions.requestProInfo,
      syncPricegroups: sellerWorkspaceActions.syncPricegroups,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePriceGroups);
