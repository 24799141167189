import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  showColor?: boolean;
  disabled?: boolean;
}

export const Container = styled(RowContainer)`
  max-height: 100%;
  overflow: hidden;
`;

export const SectionRow = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  align-items: center;
`;

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;
`;

export const TextDescription = styled(TextLabel)`
  margin: 0;
  padding: 5px 0;
  width: 95%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextPlaceholder = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: left;
  padding: 5px 0 ${(p: IStyledProps) => p.theme.paddingObject} 0;
`;

export const SwitchLabel = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(p.disabled ? undefined : 8)};
`;

export const SectionsInfoCol = styled(ColumnContainer)`
  width: 45vw;
  padding-right: 30px;
  min-height: min-content;
`;

export const SectionsTabCol = styled(ColumnContainer)`
  width: 100%;
  margin: ${(p: IStyledProps) => p.theme.paddingView} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingView};
  overflow-y: auto;
  min-height: 300px;
`;

export const HeaderCell = styled(RowContainer)`
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  align-items: center;
`;

export const KebabIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const ImageCell = styled(RowContainer)`
  width: 60px;
  max-width: 60px;
  min-width: 60px;
`;

export const OptionsCell = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  min-width: 92px;
`;

export const HeaderText = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TranslateCta = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.black};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px;
  min-width: 100px;
`;
