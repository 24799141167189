import { constants, __ } from 'common-services';
import * as React from 'react';

import { FontIcon } from '../../atoms';
import * as S from './IE11Modal.styled';

/**
 * IE11Modal to let know the user that we don't support InternetExplorer 11 or lower
 */
const IE11Modal: React.FC = () => {
  const [opened, setOpened] = React.useState(true);
  if (!opened) return null;
  return (
    <S.PageModalContainer>
      <S.Container>
        <S.Card>
          <S.ImageIELogoPosition>
            <S.ImageIELogo src={constants.MEDIA_URL + '/v1589440095/logos/ie_logo.png'} alt="ie_logo" />
          </S.ImageIELogoPosition>
          {renderCloseIcon(() => setOpened(false))}
          <S.Title>{__('Components.IE11Modal.title')}</S.Title>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.dear')}</S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.introduction')}</S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>
              {__('Components.IE11Modal.microsoft_support_1')}
              <S.TextLink
                href="https://techcommunity.microsoft.com/t5/Windows-IT-Pro-Blog/The-perils-of-using-Internet-Explorer-as-your-default-browser/ba-p/331732"
                target="_blank"
              >
                {__('Components.IE11Modal.microsoft_support_2')}
              </S.TextLink>
              ,<S.TextBold display="inline">{__('Components.IE11Modal.microsoft_support_3')}</S.TextBold>
              {__('Components.IE11Modal.microsoft_support_4')}
            </S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.microsoft_edge')}</S.TextRegular>
            <S.RowCenterContainer>
              <S.TextLink href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
                <S.ImageLogo alt="download_edge" src={constants.MEDIA_URL + '/v1589440096/logos/edge_logo.png'} />
              </S.TextLink>
            </S.RowCenterContainer>
          </S.TextContainer>

          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.modern_browsers')}</S.TextRegular>
            <S.RowCenterContainer>
              <S.TextLink href="https://www.google.com/intl/es/chrome" target="_blank">
                <S.ImageLogo alt="download_chrome" src={constants.MEDIA_URL + '/v1589440095/logos/chrome_logo.png'} />
              </S.TextLink>
              <S.TextLink href="https://www.mozilla.org/en-US/firefox/new" target="_blank">
                <S.ImageLogo alt="download_firefox" src={constants.MEDIA_URL + '/v1589440096/logos/firefox_logo.png'} />
              </S.TextLink>
            </S.RowCenterContainer>
          </S.TextContainer>

          <S.TextContainer>
            <S.TextRegular>
              {__('Components.IE11Modal.help_1')}
              <S.TextLink href="mailto:info@consentio.co" target="_blank">
                info@consentio.co
              </S.TextLink>
              {__('Components.IE11Modal.help_2')}
            </S.TextRegular>
          </S.TextContainer>

          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.it_support')}</S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.consentio_support')}</S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.acknowledgements')}</S.TextRegular>
          </S.TextContainer>
          <S.TextContainer>
            <S.TextRegular>{__('Components.IE11Modal.greetings')}</S.TextRegular>
            <S.TextRegular>{__('Components.IE11Modal.consentio_team')}</S.TextRegular>
          </S.TextContainer>
        </S.Card>
      </S.Container>
    </S.PageModalContainer>
  );

  function renderCloseIcon(onCloseClick?: () => void) {
    return (
      <S.IconWrapper>
        <FontIcon name="Close" onClick={onCloseClick} />
      </S.IconWrapper>
    );
  }
};

export default React.memo(IE11Modal);
