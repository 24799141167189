import {
  Api,
  buyerWorkspaceActions,
  chatActions,
  contactActions,
  modalActions,
  notificationsActions,
  orderActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelByPath } from '../actions/nav';
import WorkspaceSuppliers, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceSuppliers';
import { IReduxState } from '../reducers';

const emptyArray = [];

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId: string }>): IStateProps {
  const {
    chat: { channels },
    contact: { inConsentio },
    contact: { hasOutContacts },
    prodType: { prodTypes },
    user: { user },
    workspace: { workspaces, suppliers },
  } = state;

  const workspaceId = Number(props.match.params.workspaceId) || user.buyerWorkspaceId;
  const workspace = workspaces[workspaceId];

  return {
    amEditor: !(workspace?.members.find(m => m.userId === user.id)?.role === 'viewer'),
    channels,
    contacts: inConsentio,
    me: user,
    hasOutContacts,
    prodTypes,
    suppliersOld: suppliers[workspace?.id] || emptyArray,
    workspace,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      contactsAddressbookGet: contactActions.contactsAddressbookGet,
      contactsInvite: contactActions.contactsInvite,
      createChannel: chatActions.createChannel,
      createNewContact: contactActions.createContactWithName,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelByPath,
      notificationShow: notificationsActions.notificationShow,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSuppliers);
