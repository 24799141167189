import styled, { css, ITheme } from '../../../styled-components';

export type IButtonType =
  // principal
  | 'principal'
  | 'informative'
  | 'landing'
  | 'landing-transparent'
  // Secondary
  | 'secondary'
  | 'other'
  | 'delete'
  // tertiary
  | 'link'
  | 'skip'
  | 'green'
  | 'purple'
  | 'circle';

export type IIconType = 'single' | 'background';

interface IStyledProps {
  buttonType?: IButtonType;
  disabled?: boolean;
  iconType?: IIconType;
  size?: string;
  theme: ITheme;
  withoutPadding?: boolean;
  selected?: boolean;
  badgeCount?: number;
}

const getBadgeWidth = (p: IStyledProps) => {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '16px';
  if (p.badgeCount < 1000) return '23px';
  return '30px';
};

export default {
  Button: styled.button`
    width: max-content;
    min-width: ${(p: IStyledProps) => (p.withoutPadding ? 0 : '120px')};
    color: ${(p: IStyledProps) => p.theme.colors.white};
    background-color: ${(p: IStyledProps) => p.theme.colors.green1};
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    font-family: 'Nunito';
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
    transition: all 0.6s;
    cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
    padding: 8px ${(p: IStyledProps) => (p.withoutPadding ? 0 : '35px')};
    margin: 0;
    border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    position: relative;
    border: 0;
    line-height: 1.6;
    height: 36px;
    white-space: nowrap;

    ${(p: IStyledProps) =>
      !p.disabled &&
      css`
        &:hover {
          opacity: 0.75;
        }
      `}
    &:focus {
      outline: 0;
    }

    ${(p: IStyledProps) => p.buttonType && stylesByType(p)}
  `,
  IconWrapper: styled.div`
    width: auto;
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
    font-size: ${(p: IStyledProps) => p.size || p.theme.fontSize.H2};
    height: ${(p: IStyledProps) => p.size || p.theme.fontSize.H2};
    align-items: center;
    display: flex;
    top: 0;
    position: relative;
    ${(p: IStyledProps) => p.buttonType && stylesIconByType(p)}
    ${(p: IStyledProps) =>
      p.iconType === 'background' &&
      css`
        width: ${p.size ? `calc(${p.size} + 4px)` : '30px'};
        height: ${p.size ? `calc(${p.size} + 4px)` : '30px'};
        border-radius: ${p.theme.borderRadius};
        justify-content: center;
        background-color: ${getIconBackgroundColorByType(p)};
      `}

    &:after {
      content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
      background-color: ${(p: IStyledProps) => p.theme.colors.red1};
      width: ${getBadgeWidth};
      height: 15px;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      padding-right: 1px;
      transform: translateX(50%);
      color: ${(p: IStyledProps) => p.theme.colors.white};
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
      padding-left: 1px;
      padding-top: 0;
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
    }
  `,
  IconImage: styled.img`
    width: ${(p: IStyledProps) => p.size || '15px'};
    height: ${(p: IStyledProps) => p.size || '15px'};
    object-fit: contain;
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  `,
};

/**
 * Return styles by button type
 */
function stylesByType(p: IStyledProps) {
  switch (p.buttonType) {
    case 'principal':
      return css`
        background-color: ${p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
        color: ${p.theme.colors.white};
      `;
    case 'informative':
      return css`
        background-color: ${p.disabled ? p.theme.colors.blue2 : p.theme.colors.blue1};
        color: ${p.theme.colors.white};
      `;
    case 'landing':
      return css`
        background-color: ${p.disabled ? p.theme.colors.orange2 : p.theme.colors.orange1};
        color: ${p.theme.colors.white};
      `;
    case 'landing-transparent':
      return css`
        background-color: ${p.theme.colors.transparent};
        color: ${p.theme.colors.white};
        border: 1px solid ${p.theme.colors.white};
      `;
    case 'secondary':
      return css`
        background-color: ${p.theme.colors.white};
        color: ${p.disabled ? p.theme.colors.grey2 : p.theme.colors.black};
        border: 1px solid ${p.disabled ? p.theme.colors.grey2 : p.theme.colors.black};
      `;
    case 'other':
      return css`
        background-color: ${p.theme.colors.white};
        color: ${p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
        border: 1px solid ${p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
      `;
    case 'delete':
      return css`
        background-color: ${p.theme.colors.white};
        color: ${p.disabled ? p.theme.colors.red2 : p.theme.colors.red1};
        border: 1px solid ${p.disabled ? p.theme.colors.red2 : p.theme.colors.red1};
      `;
    case 'link':
      return css`
        background-color: ${p.theme.colors.transparent};
        color: ${p.disabled ? p.theme.colors.blue2 : p.theme.colors.blue1};
      `;
    case 'skip':
      return css`
        background-color: ${p.theme.colors.transparent};
        color: ${p.disabled ? p.theme.colors.grey2 : p.theme.colors.black};
      `;
    case 'green':
      return css`
        background-color: ${p.theme.colors.transparent};
        color: ${p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
      `;
    case 'purple':
      return css`
        background-color: ${p.theme.colors.transparent};
        color: ${p.disabled ? p.theme.colors.purple2 : p.theme.colors.purple1};
      `;
    case 'circle':
      return css`
        background-color: ${p.selected ? p.theme.colors.blue2 : p.theme.colors.grey4};
        color: ${p.selected ? p.theme.colors.blue1 : p.theme.colors.grey2};
        border-radius: 50%;
        border: 1px solid ${p.selected ? p.theme.colors.blue1 : p.theme.colors.grey4};
        padding: 0;
        min-width: 36px;
        margin-right: ${p.theme.paddingSize()};
        font-size: ${p.size || p.theme.fontSize.H3};
      `;
    default:
      return css`
        opacity: ${p.disabled ? 0.3 : 1};
      `;
  }
}

/**
 * Return styles by button type
 */
function stylesIconByType(p: IStyledProps) {
  switch (p.buttonType) {
    case 'secondary':
    case 'skip':
      return css`
        color: ${p.disabled ? p.theme.colors.grey2 : p.theme.colors.black};
      `;
    case 'other':
    case 'green':
      return css`
        color: ${p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
      `;
    case 'delete':
      return css`
        color: ${p.disabled ? p.theme.colors.red2 : p.theme.colors.red1};
      `;
    case 'link':
      return css`
        color: ${p.disabled ? p.theme.colors.blue2 : p.theme.colors.blue1};
      `;
    case 'purple':
      return css`
        color: ${p.disabled ? p.theme.colors.purple2 : p.theme.colors.purple1};
      `;
    default:
      return css`
        color: ${p.theme.colors.white};
      `;
  }
}

/**
 * Get the background for the icon
 */
function getIconBackgroundColorByType(p: IStyledProps) {
  switch (p.buttonType) {
    case 'secondary':
    case 'skip':
      return p.theme.colors.grey4;
    case 'other':
    case 'green':
      return p.theme.colors.green2;
    case 'delete':
      return p.theme.colors.red2;
    case 'link':
      return p.theme.colors.blue2;
    case 'purple':
      return p.theme.colors.purple2;
    default:
      return p.theme.colors.white;
  }
}
