import { __, contactSelectors, orderSelectors, sellerWorkspaceSelectors, userSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { FacetFilter } from '../../atoms';
import { getMeAsContact } from '../Facets/Facets.component';
import * as S from './MappingFilter.styled';

export interface IProps {
  catalog: IWorkspace;
  changeSearchState: (s: ISearchMapping) => void;
  clearFilters: () => void;
  className?: string;
  facets: IFacets;
  facetsGlobal: IFacets;
  numberOfHeaders?: number;
  onHide?: (hide: boolean) => void;
  searchState: ISearchMapping;
  showClosed?: boolean;
  showOver?: 'always' | 'never' | 'only-ipad';
}

const Filters: React.FC<IProps> = ({
  catalog,
  changeSearchState,
  onHide,
  className,
  clearFilters,
  facets,
  facetsGlobal,
  numberOfHeaders,
  searchState,
  showClosed,
  showOver = 'never',
}) => {
  const me = useSelector(userSelectors.getUser);
  const contacts = useSelector(contactSelectors.getContacts());
  const clients = useSelector(sellerWorkspaceSelectors.getClients(catalog?.id));
  const formats = useSelector(orderSelectors.getFormats());
  const [hidden, setHidden] = React.useState(showClosed);
  React.useEffect(() => {
    setHidden(showClosed);
  }, [setHidden, showClosed]);
  const hasFilters = searchState.clients?.length || searchState.formats?.length;
  const clientFacets = facets?.buyers || {};
  const formatFacets = facets?.formats || {};
  return (
    <S.Wrapper hide={hidden} showOver={showOver} numberOfHeaders={numberOfHeaders}>
      <S.FacetsContainer className={className} hide={hidden}>
        <S.Header>
          {hasFilters ? (
            <S.Clear withoutPadding={true} type="skip" hide={hidden} onClick={clearFilters}>
              {__('Facets.clear')}
            </S.Clear>
          ) : null}
          <S.Title>{__('Facets.filter_by')}</S.Title>
          <S.CloseIcon
            name="Close"
            onClick={() => {
              onHide?.(true);
              setHidden(true);
            }}
            hide={hidden}
          />
        </S.Header>
        <S.FiltersBody>
          {Object.keys(formatFacets).length ? (
            <FacetFilter
              title={__('Facets.format_type')}
              options={getFormatOption(formatFacets, facetsGlobal, searchState, formats?.all)}
              numVisibleOptions={5}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, formats: v as Array<string> })}
            />
          ) : null}
          {Object.keys(clientFacets).length ? (
            <FacetFilter
              title={__('Facets.client')}
              options={getClientOption(clientFacets, facetsGlobal, searchState, contacts, clients, me)}
              numVisibleOptions={6}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, clients: (v as Array<string>).map(Number) })}
            />
          ) : null}
        </S.FiltersBody>
        <S.ApplyButton
          type="principal"
          onClick={() => {
            onHide?.(true);
            setHidden(true);
          }}
        >
          {__('Facets.apply_filters')}
        </S.ApplyButton>
      </S.FacetsContainer>
    </S.Wrapper>
  );
};

/**
 * Get client option for facet
 */
function getClientOption(
  clientFacets: { [cId: number]: number },
  facetsGlobal: IFacets,
  searchState: ISearchMapping,
  contacts: { [cId: number]: IContact },
  clients: Array<IClient>,
  me: IUser,
) {
  return Object.keys(clientFacets || {}).reduce((acc, clientIdStr) => {
    const clientId = Number(clientIdStr);
    const contact = clientId === me.id ? getMeAsContact(me) : contacts[clientId];
    const client = clientId === me.id ? undefined : clients?.find(c => c.userId === clientId);
    if (contact || client) {
      const name = contact?.name || client?.name;
      acc.push({
        name: clientId === me.id ? `${name} ${__('Facets.you')}` : name,
        key: clientId + '',
        count: clientFacets?.[clientId] || 0,
        globalCount: facetsGlobal?.buyers?.[clientId] || 0,
        checked: searchState.clients?.includes(clientId),
        image: contact?.avatar || client?.avatar,
        type: 'contact',
        amountType: 'mapping',
      });
    }
    return acc;
  }, [] as any);
}

/**
 * Get format option for facets
 */
function getFormatOption(
  formatFacets: { [key: string]: number },
  facetsGlobal: IFacets,
  searchState: ISearchMapping,
  formats: Array<IFormat>,
) {
  return Object.keys(formatFacets || {}).reduce((acc, id) => {
    const format = formats.find(f => f.slug === id);
    if (!format) return acc;
    acc.push({
      name: format.label,
      key: id,
      count: formatFacets?.[id] || 0,
      globalCount: facetsGlobal?.formats?.[id] || 0,
      checked: searchState.formats?.includes(id),
      image: format.logo_url,
      type: 'contact',
      amountType: 'mapping',
    });
    return acc;
  }, [] as any);
}

export default React.memo(Filters);
