import { constants, CURRENCY_CODES } from 'common-services';
import * as React from 'react';

import * as S from './ArrowPercentage.styled';

interface IProps {
  className?: string;
  isDiscount: boolean;
  value: number;
  currency?: CURRENCY_CODES;
  mode?: 'percentage' | 'currency';
}

/**
 * Arrow up or down, green or red to show a price difference in percentage
 */
const ArrowPercentage = ({
  className,
  currency = CURRENCY_CODES.EUR,
  isDiscount,
  mode = 'percentage',
  value,
}: IProps) => {
  return (
    <S.Container className={className}>
      <S.ArrowIcon name="Arrow" disableHover={true} isDiscount={isDiscount} />
      <S.PercentageText isDiscount={isDiscount}>{`${parseFloat(value.toFixed(2))}${
        mode === 'percentage' ? '%' : constants.CURRENCIES[currency]?.symbol
      }`}</S.PercentageText>
    </S.Container>
  );
};

export default React.memo(ArrowPercentage);
