import {
  __,
  buyerWorkspaceActions,
  colors,
  constants,
  currency,
  debounce,
  EventTrack,
  IBoxesPerPalletValue,
  imageActions,
  modalActions,
  notificationsActions,
  parsers,
  PLASTIC_TYPE,
  PRICE_ADJUSTMENT_TYPE,
  PRODUCT_UNIT,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  tagActions,
  utils,
} from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import * as userAppActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import * as webConstants from '../../../constants';
import { AllProductUnitsArray } from '../../../domain/product';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { priceUnitTranslate } from '../../../util/utils';
import {
  Button,
  ColumnContainer,
  DatePicker,
  FontIcon,
  Input,
  Radio,
  Ribbon,
  Select,
  SelectWorkingStatus,
  TextArea,
  Tooltip,
} from '../../atoms';
import { ActionsModal, Attachment, FormSection, InputWithLabel, PriceGroupTable, PriceItemTable } from '../../molecules';
import { Sections } from '../../molecules/FormMenu/FormMenu.component';
import {
  getCategories,
  getCountries,
  getOrganicTypes,
  getProductTypes,
  MAX_TAG_LENGTH,
  MAX_UPLOAD_PICTURES,
} from './ProductEdit.constants';
import * as S from './ProductEdit.styled';
import { validateProductForm } from './ProductEdit.utils';
import Sustainability from './Sustainability';

const Traceability = React.lazy(() => import('../Traceability'));
export type IProps = {
  addresses: Array<IAddress>;
  boxTypes: Array<ICustomBoxType>;
  catalog: IWorkspace;
  catalogId: number;
  contacts: Record<number, IContact>;
  countries: { [k: string]: ICountry };
  hasChanges?: boolean;
  me: IUser;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  palletTypes: Array<ICustomPalletType>;
  priceGroupAddPrices: typeof sellerWorkspaceActions.priceGroupAddPrices;
  priceGroupDeletePrice: typeof sellerWorkspaceActions.priceGroupDeletePrice;
  priceGroups: { [priceGroupId: string]: IPriceGroup };
  prices: Record<number, IPrice>;
  pricesAddProduct: typeof sellerWorkspaceActions.pricesAddProduct;
  pricesGetFromProduct: typeof sellerWorkspaceActions.pricesGetFromProduct;
  prodTypes: { [key: string]: IProdType };
  product: IProduct;
  productClone: () => void;
  productCreate: (workdpaceId: number, product: IProduct, cb?: (p?: IProduct) => void) => void;
  productRemove: (workdpaceId: number, productId: number, cb?: () => void) => void;
  productUpdate: (workdpaceId: number, product: IProduct, cb?: () => void) => void;
  requestProInfo: (userId: number, catalogId: number, reason: string) => void;
  setShowPriceGroups: (show: boolean) => void;
  showPriceGroups: boolean;
  supportAction: typeof userAppActions.supportAction;
  tags: Array<string>;
  tagsGet: typeof tagActions.tagsGet;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressesGet: typeof buyerWorkspaceActions.workspaceAddressesGet;
} & RouteComponentProps<{ productId: string }>;

export interface IState {
  countries: Array<{ label: string; value: string }>;
  editMode: boolean;
  errors: Map<string, string>;
  files: Array<File>;
  kebabBoxVisibility: { [key: string]: boolean };
  hasChanges: boolean;
  isCloning?: boolean;
  priceGroupItems: Array<IPriceGroupItem>;
  prodTypes: Array<{ label: string; value: string }>;
  product: IProduct;
  searchText: string;
  showAddToPriceGroup?: boolean;
  showPlasticModal?: boolean;
  showTraceability?: boolean;
  viewing?: string;
}

/**
 * Edit existing product / create new product
 */
class ProductEdit extends React.PureComponent<IProps, IState> {
  private t: number;
  private changeProductDebounce;
  private sectionsContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private imagesRef: React.RefObject<HTMLDivElement> = React.createRef();
  private characteristicsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private distributionRef: React.RefObject<HTMLDivElement> = React.createRef();
  private sustainabilityRef: React.RefObject<HTMLDivElement> = React.createRef();
  private additionalFilesRef: React.RefObject<HTMLDivElement> = React.createRef();
  private tagsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private priceRef: React.RefObject<HTMLDivElement> = React.createRef();
  private priceGroupsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private deleteRef: React.RefObject<HTMLDivElement> = React.createRef();
  private visibilityRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      ...this.initProductState(props.product),
      viewing: 'characteristics',
      kebabBoxVisibility: {},
    };
    this.changeProductDebounce = debounce(this.changeProduct, 500);
    props.tagsGet(props.me.id, props.catalogId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { product, priceGroups } = this.props;
    const { priceGroupItems } = this.state;
    if (product !== prevProps.product && product.id) {
      const initState = this.initProductState(product);
      this.setState(initState);
    }
    if (product.id && prevProps.priceGroups !== priceGroups) {
      this.setState({
        priceGroupItems: priceGroupItems.map(pgi =>
          priceGroups[pgi.priceGroupId]?.prices?.find(p => p.productId === product.id),
        ),
        showAddToPriceGroup: (priceGroups && !Object.keys(priceGroups).length) || !product.priceGroups?.length,
      });
    }
  }

  public componentDidMount() {
    const {
      product,
      catalog: { promotedBy },
      catalogId,
      workspaceAddressesGet,
      me,
    } = this.props;

    RenderTrack.track('ProductEdit', {
      renderTime: this.t,
      productId: product.id ? product.id.toString() : '',
      type: product.type,
    });
    const offset = 100;
    if (this.sectionsContainerRef.current)
      this.sectionsContainerRef.current.addEventListener('scroll', () => {
        if (
          this.state.product.id &&
          this.sectionsContainerRef.current.scrollTop + offset >= this.deleteRef?.current?.offsetTop
        ) {
          this.setState({ viewing: 'delete' });
        } else if (
          config.TOGGLE_SUSTAINABILITY.enabled &&
          !promotedBy &&
          this.sectionsContainerRef.current.scrollTop + offset >= this.sustainabilityRef?.current?.offsetTop
        ) {
          this.setState({ viewing: 'sustainability' });
        } else if (
          product.id &&
          this.sectionsContainerRef.current.scrollTop + offset >= this.additionalFilesRef?.current?.offsetTop
        ) {
          this.setState({ viewing: 'additional-files' });
        } else if (
          !promotedBy &&
          this.sectionsContainerRef.current.scrollTop + offset >= this.distributionRef?.current?.offsetTop
        ) {
          this.setState({ viewing: 'distribution' });
        } else if (this.sectionsContainerRef.current.scrollTop + offset >= this.priceRef?.current?.offsetTop) {
          this.setState({ viewing: 'price-section' });
        } else if (this.sectionsContainerRef.current.scrollTop + offset >= this.imagesRef?.current?.offsetTop) {
          this.setState({ viewing: 'images-section' });
        } else if (
          this.sectionsContainerRef.current.scrollTop + offset >=
          this.characteristicsRef?.current?.offsetTop
        ) {
          this.setState({ viewing: 'characteristics' });
        }
      });
    workspaceAddressesGet(me.id, catalogId);
  }

  public render() {
    const {
      catalog,
      catalog: { promotedBy },
      me,
      prodTypes,
    } = this.props;
    const { hasChanges, isCloning, product, viewing, showPlasticModal, showTraceability } = this.state;
    const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;
    return (
      <>
        <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
        <S.Container>
          {isCloning && !product.id ? <Ribbon type="info" text={__('Components.ProductDetails.clone')} /> : null}
          <S.ContentContainer className="product-edit-content-container">
            <S.MenuLeft sections={this.getSections()} selected={viewing} />
            <S.FormContainer
              canSave={hasChanges}
              className="product-edit-form-container"
              cta={product.id ? __('Components.ProductDetails.save') : __('Components.ProductDetails.create')}
              save={this.save}
              sectionsRef={this.sectionsContainerRef}
            >
              {this.renderCharacteristics()}
              {this.renderImages()}
              {this.renderPrice()}
              {!promotedBy ? this.renderDistribution() : null}
              {config.TOGGLE_SUSTAINABILITY.enabled && !promotedBy ? this.renderSustainability() : null}
              {product.id ? (
                <AdditionalFiles
                  me={me}
                  product={product}
                  additionalFilesRef={this.additionalFilesRef}
                  updateProduct={p => this.setState({ product: p })}
                />
              ) : null}
              {product.id ? this.renderDeleteProduct() : null}
            </S.FormContainer>

            <S.ColumnRight>
              {this.renderVisibility()}
              {this.renderLot()}
              {this.renderPriceGroups(isQuoterMode)}
              {this.renderSections()}
            </S.ColumnRight>
            {this.renderPriceGroupsModal(isQuoterMode)}
          </S.ContentContainer>
        </S.Container>
        {showPlasticModal ? (
          <PlasticModal
            product={product}
            close={() => this.setState({ showPlasticModal: false })}
            save={p => this.setState({ product: p, hasChanges: true })}
            language={me.settings.language}
            defaultWeightUnit={catalog.defaultWeightUnit}
            prodTypes={prodTypes}
          />
        ) : null}
        {showTraceability ? (
          <Traceability
            product={parsers.productToGenericProduct(product)}
            backLiteral={__('ProductEdit.Traceability.back')}
            showBack={true}
            close={() => this.setState({ showTraceability: false })}
            company={me.companyName}
            myId={me.id}
          />
        ) : null}
      </>
    );
  }

  /**
   * Initialize product state
   */
  private initProductState(product: IProduct): IState {
    const { countries: c, hasChanges, prodTypes } = this.props;
    const errors = new Map();
    const countries = getCountries(c);
    if (!product.id) {
      product.type.origin = countries[0].value;
      product.type.type = getProductTypes(prodTypes)[0].value;
    }
    return {
      countries,
      editMode: true,
      errors,
      hasChanges,
      prodTypes: getProductTypes(prodTypes),
      product,
      kebabBoxVisibility: {},
      priceGroupItems: this.state?.priceGroupItems || [],
      searchText: '',
      files: [],
    };
  }

  /**
   * Update multiple product fields in state
   */
  private changeProductMultiple(
    changes: Array<{ key: string; value: string | number | boolean | Array<PRODUCT_UNIT>; error?: string }>,
  ) {
    const product = { ...this.state.product };
    const { catalog } = this.props;
    const options = AllProductUnitsArray;
    changes.forEach(({ key, value, error }) => {
      this.setError(key, error);
      product[key] = value;
    });
    product.saleUnits = product.saleUnits.filter(u =>
      productService.isAllowedSaleUnit(product, u, catalog.mandatoryPalletizationByClient),
    );
    if (!product.saleUnits.length) {
      product.saleUnits.push(
        options.find(u => productService.isAllowedSaleUnit(product, u, catalog.mandatoryPalletizationByClient)),
      );
    }
    this.setState({
      hasChanges: true,
      product,
    });
  }

  /**
   * Update product type in state
   */
  private changeProductType = (key: string, value: string, error?: string) => {
    this.setError(key, error);

    const product = { ...this.state.product, type: { ...this.state.product.type, [key]: value } };

    this.setState({
      hasChanges: true,
      product,
    });
  };

  /**
   * Update product image with URL in state and return product updated
   */
  private changeProductImage(product: IProduct, url?: string, index: number = 0, hasChanges: boolean = true) {
    const imagesUpdate = Array.isArray(product.images) ? product.images.slice() : [];
    if (!url) return product;
    if (index < imagesUpdate.length) {
      imagesUpdate[index] = { ...imagesUpdate[index], image: { url }, imageId: 0 };
    } else {
      imagesUpdate.push({ image: { url } } as any);
    }
    product.images = imagesUpdate;
    this.setState({
      hasChanges,
      product,
    });
    return product;
  }

  private fixBoxPerPalletValues = (product: IProduct) => {
    if (!product?.boxesPerPalletValues) return;
    product.boxesPerPalletValues = product.boxesPerPalletValues.filter(e => e.value !== 0);
    product.boxesPerPalletValues = product.boxesPerPalletValues.map(e =>
      typeof e.value === 'string' ? { ...e, value: parseFloat(e.value) } : e,
    );
  };

  /**
   * Save product if validation form is correct
   */
  private save = () => {
    const { catalogId, pricesAddProduct, productUpdate, productCreate } = this.props;
    const { hasChanges, priceGroupItems, product } = this.state;
    if (!hasChanges) return;
    this.fixBoxPerPalletValues(product);
    const validationErrors = validateProductForm(product);
    this.setState({ errors: validationErrors });

    if (validationErrors.size === 0) {
      this.setState({ hasChanges: false }, () => {
        if (product.id) {
          productUpdate(catalogId, product, () => this.showSaveSuccessModal('updated'));
        } else {
          productCreate(catalogId, product, (p?: IProduct) => {
            if (product.priceGroups?.length) {
              pricesAddProduct(p.catalogId, p.id, product.priceGroups, pgis => {
                this.setState({
                  priceGroupItems: [
                    ...priceGroupItems,
                    ...pgis.filter(pgi => !priceGroupItems.find(pg => pg.priceGroupId === pgi.priceGroupId)),
                  ],
                });
                this.showSaveSuccessModal('created');
              });
            } else {
              this.showSaveSuccessModal('created');
            }
          });
        }
      });
    } else {
      if (validationErrors.has('type') || validationErrors.has('variety')) {
        this.scrollToSection('characteristics');
      } else if (validationErrors.has('price')) {
        this.scrollToSection('price-section');
      }
    }
  };

  /**
   * Scroll programmatically to a section
   */
  private scrollToSection = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * Update product field in state
   */
  private changeProduct = (key: string, value: any, error?: string) => {
    this.setError(key, error);
    const product = { ...this.state.product, [key]: value };
    this.setState({
      hasChanges: true,
      product,
    });
  };

  private renderVisibility() {
    const { product } = this.state;
    let subtitle = '';
    switch (product.status) {
      case webConstants.AVAILABILITY.ACTIVE:
        subtitle = __('ProductEdit.Visibility.subtitle');
        break;
      case webConstants.AVAILABILITY.INACTIVE:
        subtitle = __('ProductEdit.Visibility.subtitle_inactive');
        break;
      case webConstants.AVAILABILITY.UNAVAILABLE:
        subtitle = __('ProductEdit.Visibility.subtitle_out_of_stock');
        break;
    }
    return (
      <FormSection
        id="visibility"
        title={__('ProductEdit.Visibility.availability')}
        sectionRef={this.visibilityRef}
        withoutPaddingBottom={true}
      >
        {this.renderProductVisibleSwitch()}
        <S.TextPlaceholder>{subtitle}</S.TextPlaceholder>

        {product.status === webConstants.AVAILABILITY.UNAVAILABLE ? (
          <>
            <S.StatusRow>
              <S.CalendarTitle>{__('ProductEdit.Visibility.estimated_date')}</S.CalendarTitle>
              <Tooltip position="bottom" text={__('ProductEdit.Visibility.tooltip')} width="200px" themeMode="dark">
                <S.InfoIcon name="Info" disableHover={true} />
              </Tooltip>
            </S.StatusRow>
            <DatePicker
              id="availability-date"
              minDate={new Date(Date.now())}
              initDate={product.availableSoon.availableFrom ? new Date(product.availableSoon.availableFrom) : undefined}
              onDateChange={newDate => {
                const date = new Date(newDate).getTime();
                this.changeProduct('availableSoon', { ...product.availableSoon, availableFrom: date });
              }}
            />
            <S.StatusCheckBoxRow>
              <S.CheckBox
                className="product-availability"
                isChecked={product.availableSoon?.transitionOnAvailable}
                disabled={false}
                onClick={checked =>
                  this.changeProduct('availableSoon', { ...product.availableSoon, transitionOnAvailable: checked })
                }
              />
              <S.StatusCheckBox>{__('ProductEdit.Visibility.checkbox_label')}</S.StatusCheckBox>
            </S.StatusCheckBoxRow>
            <S.TextPlaceholder>{__('ProductEdit.Visibility.date_unavailable')}</S.TextPlaceholder>
          </>
        ) : null}
      </FormSection>
    );
  }
  private renderLot() {
    const { product } = this.state;
    return config.TOGGLE_PRODUCT_LOT.enabled && (product.id || product.originLots?.length) ? (
      <FormSection id="visibility" title={__('ProductEdit.Lot.Lot')} sectionRef={this.visibilityRef}>
        <S.FormCol className="product-edit-lot">
          {product.id ? (
            <>
              <S.Lot>LT-{product.id}</S.Lot>
              <Button
                type="link"
                iconName="Add-more"
                withoutPadding
                onClick={() =>
                  this.setState(
                    {
                      product: {
                        ...product,
                        id: undefined,
                        images: product.images.map(img => ({ image: { url: img.image.url }, imageId: 0 })),
                        sku: undefined,
                      },
                      hasChanges: true,
                      isCloning: true,
                      viewing: 'characteristics',
                    },
                    () => {
                      this.sectionsContainerRef.current.scrollTo(0, 0);
                      this.props.productClone();
                    },
                  )
                }
              >
                {__('ProductEdit.Lot.Cta')}
              </Button>
            </>
          ) : null}
          {product.originLots?.length ? (
            <>
              <S.AssociatedLot>{__('ProductEdit.Lot.Associated')}</S.AssociatedLot>
              {product.originLots.map((l, i) => (
                <S.Lot key={l + i}>LT-{l}</S.Lot>
              ))}
              <Button type="link" withoutPadding onClick={() => this.setState({ showTraceability: true })}>
                {__('ProductEdit.Lot.Traceability_cta')}
              </Button>
            </>
          ) : null}
        </S.FormCol>
      </FormSection>
    ) : null;
  }
  private renderImages() {
    return (
      <FormSection
        id="images-section"
        title={__('ProductEdit.Visibility.title')}
        sectionRef={this.imagesRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-images">{this.renderPictures()}</S.FormCol>
      </FormSection>
    );
  }

  private renderCharacteristics() {
    return (
      <FormSection
        id="characteristics"
        title={__('ProductEdit.Characteristics.title')}
        sectionRef={this.characteristicsRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-edit-characteristics">{this.renderCharacteristicsForm()}</S.FormCol>
      </FormSection>
    );
  }

  private renderDistribution() {
    const { product, catalog } = this.props;
    return (
      <FormSection
        id="distribution"
        title={__('ProductEdit.Distribution.title')}
        sectionRef={this.distributionRef}
        withoutPaddingBottom={true}
        numHeaders={product.id || config.TOGGLE_SUSTAINABILITY.enabled ? undefined : 2}
      >
        <S.FormCol className="product-edit-distribution">
          {config?.TOGGLE_BOXES_PER_PALLET?.enabled && catalog.mandatoryPalletizationByClient
            ? this.renderNewDistributionForm()
            : this.renderDistributionForm()}
        </S.FormCol>
      </FormSection>
    );
  }
  private renderSustainability() {
    const { prodTypes, me, catalog } = this.props;
    const { product } = this.state;
    const sustainability = Sustainability[product.type.type];
    const co2Max = sustainability?.co2Max?.toFixed(2);
    const co2Min = sustainability?.co2Min?.toFixed(2);
    const waterMax = (sustainability?.waterMax * 1000)?.toFixed(2);
    const waterMin = (sustainability?.waterMin * 1000)?.toFixed(2);
    const name = prodTypes[product.type.type]?.name;
    return (
      <FormSection
        id="sustainability"
        title={__('ProductEdit.Sustainability.title')}
        sectionRef={this.sustainabilityRef}
        withoutPaddingTop={true}
        numHeaders={product.id ? undefined : 2}
      >
        <S.GreenSpan>
          <FontIcon name="Producer-solid" disableHover={true} />
          <S.GreenSubtitle>{__('ProductEdit.Sustainability.green_subtitle')}</S.GreenSubtitle>
        </S.GreenSpan>
        {product.type.type ? (
          sustainability ? (
            <>
              {co2Max
                ? this.renderSustainabilityItem(
                    '🌤',
                    'sun',
                    __('ProductEdit.Sustainability.co2.title'),
                    co2Max === co2Min
                      ? __('ProductEdit.Sustainability.co2.similar_data', { product: name, co2Max })
                      : __('ProductEdit.Sustainability.co2.data', { product: name, co2Max, co2Min }),
                    '(1)',
                  )
                : null}
              {waterMax
                ? this.renderSustainabilityItem(
                    '💧',
                    'water',
                    __('ProductEdit.Sustainability.water.title'),
                    waterMax === waterMin
                      ? __('ProductEdit.Sustainability.water.similar_data', { product: name, waterMax })
                      : __('ProductEdit.Sustainability.water.data', { product: name, waterMax, waterMin }),
                    '(1)',
                  )
                : null}

              <S.Sustainabilitytext>
                {__('ProductEdit.Sustainability.own_data')}{' '}
                <S.ModifyLink onClick={this.showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.modify_cta')}
                </S.ModifyLink>
              </S.Sustainabilitytext>
            </>
          ) : (
            <>
              {this.renderSustainabilityItem(
                '🌤',
                'sun',
                __('ProductEdit.Sustainability.co2.title'),
                __('ProductEdit.Sustainability.no_data'),
                undefined,
                <S.ModifyLink onClick={this.showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.add_cta')}
                </S.ModifyLink>,
              )}
              {this.renderSustainabilityItem(
                '💧',
                'water',
                __('ProductEdit.Sustainability.water.title'),
                __('ProductEdit.Sustainability.no_data'),
                undefined,
                <S.ModifyLink onClick={this.showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.add_cta')}
                </S.ModifyLink>,
              )}
            </>
          )
        ) : (
          <>
            {this.renderSustainabilityItem(
              '🌤',
              'sun',
              __('ProductEdit.Sustainability.co2.title'),
              __('ProductEdit.Sustainability.co2.subtitle'),
            )}
            {this.renderSustainabilityItem(
              '💧',
              'water',
              __('ProductEdit.Sustainability.water.title'),
              __('ProductEdit.Sustainability.water.subtitle'),
            )}
          </>
        )}
        {this.renderSustainabilityItem(
          '🥤',
          'plastic',
          __('ProductEdit.Sustainability.plastic.title'),
          product.plasticType
            ? __('ProductEdit.Sustainability.plastic.data', {
                plastic_type: constants.getPlasticLiteral(product.plasticType),
                plastic_unit: constants
                  .getPriceUnitLabels(product.plasticUnit, me.settings.language, catalog.defaultWeightUnit)
                  .toLocaleLowerCase(),
                plastic_amount: product.plasticAmount,
              })
            : null,
          undefined,
          undefined,
          true,
        )}
        <S.PlasticButton
          iconName={product.plasticType ? 'Edit' : 'Add-more'}
          type="link"
          onClick={() => this.setState({ showPlasticModal: true })}
          withoutPadding
        >
          {product.plasticType
            ? __('ProductEdit.Sustainability.plastic.cta')
            : __('ProductEdit.Sustainability.plastic.cta_add')}
        </S.PlasticButton>
      </FormSection>
    );
  }

  private renderSustainabilityItem = (
    icon: string,
    label: string,
    title: string,
    subtitle: string,
    sup?: string,
    Cta?: React.ReactNode,
    withoutPadding?: boolean,
  ) => {
    return (
      <>
        <S.Sustainabilitytitle>
          <span role="img" aria-label={label}>
            {icon}
          </span>{' '}
          {title}
        </S.Sustainabilitytitle>
        {subtitle ? (
          <S.SubRow withoutPaddingBottom={true}>
            <S.Sustainabilitytext withoutPaddingBottom={withoutPadding}>
              <>
                {subtitle ? utils.formatText(subtitle, (s, idx) => <S.TextBold key={idx}>{s}</S.TextBold>) : ''}
                {sup ? <S.Sup>{sup}</S.Sup> : null} {Cta}
              </>
            </S.Sustainabilitytext>
          </S.SubRow>
        ) : null}
      </>
    );
  };

  private showSustainabilityModal = () => {
    const { modalOpen, supportAction } = this.props;

    modalOpen(
      __('ProductEdit.Sustainability.modal.title'),
      supportAction,
      {
        buttonText: __('ProductEdit.Sustainability.modal.cta'),
        text2: __('ProductEdit.Sustainability.modal.text'),
        showCancelButton: true,
      },
      'nice',
    );
  };

  private renderSections() {
    return (
      <FormSection id="tags" title={__('ProductEdit.Tags.title')} sectionRef={this.tagsRef} withoutPaddingBottom={true}>
        <S.FormCol className="product-edit-tags">{this.renderSectionsForm()}</S.FormCol>
      </FormSection>
    );
  }

  private renderPrice() {
    return (
      <FormSection
        id="price-section"
        title={__('ProductEdit.Price.title')}
        sectionRef={this.priceRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-edit-price">{this.renderPriceForm()}</S.FormCol>
      </FormSection>
    );
  }

  private renderPriceGroups(isQuoterMode: boolean) {
    const { catalog, priceGroups } = this.props;
    const { product } = this.state;

    const title = isQuoterMode
      ? __('ProductEdit.Margins.product_margins', { count: product.priceGroups?.length || 0 })
      : __('ProductEdit.PriceGroups.product_price_groups', { count: product.priceGroups?.length || 0 });
    const description = isQuoterMode
      ? __('ProductEdit.Margins.description', {
          count: product.priceGroups?.length || 0,
        })
      : __('ProductEdit.PriceGroups.description', {
          count: product.priceGroups?.length || 0,
        });
    const link = isQuoterMode
      ? __('ProductEdit.Margins.link', { count: product.priceGroups?.length || 0 })
      : __('ProductEdit.PriceGroups.link', { count: product.priceGroups?.length || 0 });
    const zeroCaseLink = isQuoterMode
      ? __('ProductEdit.Margins.link', { count: product.priceGroups?.length || 0 })
      : __('ProductEdit.NoPriceGroups.link');
    const zeroCase = isQuoterMode
      ? __('ProductEdit.Margins.description', { count: product.priceGroups?.length || 0 })
      : __('ProductEdit.NoPriceGroups.description');

    return (
      <FormSection
        id="price-groups"
        title={title}
        sectionRef={this.priceGroupsRef}
        tag={sellerWorkspaceService.isProPlan(catalog?.plan) ? undefined : 'PRO'}
        tagColor={colors.blue1}
      >
        <S.FormCol className="product-edit-price-groups">
          <S.TextPlaceholder>
            {priceGroups && Object.keys(priceGroups)?.length ? description : zeroCase}
          </S.TextPlaceholder>
          <S.TextLink onClick={this.showPricegroupsModal}>
            {priceGroups && Object.keys(priceGroups)?.length ? link : zeroCaseLink}
          </S.TextLink>
        </S.FormCol>
      </FormSection>
    );
  }

  private renderDeleteProduct() {
    const { product } = this.state;
    const {
      catalog: { promotedBy },
      me,
      modalOpen,
      prodTypes,
    } = this.props;
    return (
      <>
        <FormSection
          id="delete"
          title={__('Components.ProductDetails.other_options')}
          sectionRef={this.deleteRef}
          numHeaders={2}
        >
          <S.SubRow>
            <S.FormCol className="product-delete">
              <S.DeleteButton type="delete" iconName="Trash" iconSize="16px" onClick={() => this.onRemove(product)}>
                {__('Components.ProductDetails.remove_action')}
              </S.DeleteButton>
            </S.FormCol>
            <S.FormCol>
              {!promotedBy ? (
                <S.CloneButton
                  type="secondary"
                  onClick={() => {
                    modalOpen(
                      __('Components.ProductDetails.clone_title'),
                      () =>
                        this.setState(
                          {
                            product: {
                              ...product,
                              id: undefined,
                              images: product.images.map(img => ({ image: { url: img.image.url }, imageId: 0 })),
                              sku: undefined,
                              userId: me.id,
                            },
                            hasChanges: true,
                            isCloning: true,
                            viewing: 'characteristics',
                          },
                          () => {
                            this.sectionsContainerRef.current.scrollTo(0, 0);
                            this.props.productClone();
                          },
                        ),
                      {
                        buttonText: __('Components.ProductDetails.clone_cta'),
                        text2: __('Components.ProductDetails.clone_description', {
                          name: productService.getProductTypeVarietyDisplay(
                            product.type.type,
                            prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
                            product.type.variety,
                          ),
                        }),
                        showCancelButton: true,
                      },
                      'nice',
                    );
                  }}
                >
                  {__('Components.ProductDetails.clone_action')}
                </S.CloneButton>
              ) : null}
            </S.FormCol>
          </S.SubRow>
          {config.TOGGLE_SUSTAINABILITY.enabled && Sustainability[product.type.type] ? (
            <S.SubRowInfo>
              <S.SupInfo>{'(1)'}</S.SupInfo>
              <S.Text>
                {
                  utils.formatText(__('ProductEdit.Sustainability.data_origin'), (s, idx) =>
                    idx === 1 ? (
                      <S.ExtLink
                        key={idx}
                        target="_blank"
                        href="https://doc.agribalyse.fr/documentation/methodologie-acv"
                      >
                        {s}
                      </S.ExtLink>
                    ) : (
                      <S.ExtLink key={idx} target="_blank" href="https://www.iso.org/standard/38498.html">
                        {s}
                      </S.ExtLink>
                    ),
                  ) as any // TYPEERROR
                }
              </S.Text>
            </S.SubRowInfo>
          ) : null}
        </FormSection>
      </>
    );
  }
  private renderPriceGroupsModal(isQuoterMode: boolean) {
    const { catalog, contacts, me, modalClose, modalOpen, priceGroups, prices, prodTypes, showPriceGroups } =
      this.props;
    const { priceGroupItems, product, searchText, showAddToPriceGroup } = this.state;
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const itemsToShow = priceGroupItems?.filter(
      i => !searchText || priceGroups[i.priceGroupId]?.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    const productName = productService.getProductTypeVarietyDisplay(
      product.type.type,
      prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
      product.type.variety,
    );
    return showPriceGroups ? (
      <S.Modal>
        <S.PriceGroupSection>
          <S.PriceGroupHeader>
            <S.Title>{productName}</S.Title>
            <InputWithLabel isRequired={true} disabled={true} label={__('Components.ProductDetails.base_pice')}>
              {product.isPor ? (
                <S.POR>{__('Components.ProductsList.Table.POR')}</S.POR>
              ) : (
                <Input
                  name={'price'}
                  type={'number'}
                  value={typeof product.price === 'number' ? product.price.toString() : ''}
                  hasError={!!this.state.errors.get('price')}
                  onBlur={this.changeProduct}
                  isRequired={true}
                  disabled={true}
                  minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                  precision={pricePrecision}
                  variableTextSingular={constants.CURRENCIES[product.currency].symbol}
                  variableTextPlural={constants.CURRENCIES[product.currency].symbol}
                />
              )}
            </InputWithLabel>
            <InputWithLabel
              isRequired={true}
              label={
                isQuoterMode
                  ? __('Components.ProductDetails.margins_applied', {
                      name: productName,
                      count: priceGroupItems?.length,
                    })
                  : __('Components.ProductDetails.pricegroups_applied', {
                      name: productName,
                      count: priceGroupItems?.length,
                    })
              }
            >
              <Button
                type="link"
                onClick={() => this.setState({ showAddToPriceGroup: true })}
                iconName="Add-more"
                withoutPadding={true}
                iconSize="18px"
              >
                {isQuoterMode
                  ? __('Components.ProductDetails.add_to_margins')
                  : __('Components.ProductDetails.add_to_pricegroups')}
              </Button>
              {priceGroupItems?.length ? (
                <S.SearchWrapper id="search-products-wrapper">
                  <S.PriceGroupSearch
                    id="search-products-price-group"
                    query={searchText}
                    onChange={(text: string) => this.setState({ searchText: text })}
                    placeHolder={
                      isQuoterMode
                        ? __('Components.ProductDetails.search.placeholder_margin')
                        : __('Components.ProductDetails.search.placeholder')
                    }
                  />
                </S.SearchWrapper>
              ) : null}
            </InputWithLabel>
          </S.PriceGroupHeader>

          {priceGroupItems?.length ? (
            <PriceItemTable
              catalog={catalog}
              className="prices-items-table"
              contacts={contacts}
              disabled={false}
              fromProduct={true}
              hasMore={false}
              isQuoterMode={isQuoterMode}
              me={me}
              modalClose={modalClose}
              modalOpen={modalOpen}
              onRemovePrice={this.onRemovePrice}
              onUpdatePrice={this.onUpdatePrice}
              priceGroupItems={itemsToShow}
              priceGroups={priceGroups}
              prices={prices}
              products={[{ ...product, id: product.id || 0 }]}
              searchMore={() => null}
              showLoading={0}
              showStickyHeader={false}
              touchImage={() => null}
            />
          ) : null}
        </S.PriceGroupSection>
        {showAddToPriceGroup ? (
          <AddToPriceGroup
            contacts={contacts}
            isQuoterMode={isQuoterMode}
            me={me}
            setShowAddToPriceGroup={s => this.setState({ showAddToPriceGroup: s })}
            priceGroups={
              priceGroups
                ? Object.values(priceGroups).filter(p => !priceGroupItems?.find(i => i.priceGroupId === p.priceGroupId))
                : []
            }
            pricesAddProduct={this.pricesAddProduct}
            product={product}
          />
        ) : null}
      </S.Modal>
    ) : null;
  }
  /**
   * Update price from list in state
   */
  private onUpdatePrice = (priceGroupItem: IPriceGroupItem) => {
    const { catalogId, priceGroupAddPrices, catalog } = this.props;
    const { product } = this.state;
    EventTrack.track('workspace_pricegroup_update_price', {
      workspace_id: catalogId,
      pricegroup_id: priceGroupItem.priceGroupId,
      product_id: priceGroupItem.productId,
    });
    const { priceGroupId } = priceGroupItem;
    if (product.id) {
      priceGroupAddPrices(catalogId, priceGroupId, [priceGroupItem], catalog.plan.addons?.quoterMarginsEnabled);
    } else {
      const { priceGroups = [] } = product;
      const idx = priceGroups.findIndex(p => p === priceGroupId);
      if (idx !== -1) {
        priceGroups[idx] = priceGroupId;
      } else {
        priceGroups.push(priceGroupId);
      }
      this.setState({ product: { ...product, priceGroups } });
    }
  };

  /**
   * Remove price from list in state
   */
  private onRemovePrice = (priceGroupItem: IPriceGroupItem) => {
    const { catalogId, priceGroupDeletePrice } = this.props;
    const { product, priceGroupItems } = this.state;
    EventTrack.track('workspace_pricegroup_delete_price', {
      workspace_id: catalogId,
      pricegroup_id: priceGroupItem.priceGroupId,
      product_id: priceGroupItem.productId,
    });
    if (product.id) {
      priceGroupDeletePrice(
        catalogId,
        priceGroupItem.priceGroupId,
        priceGroupItem.productId,
        priceGroupItem.sku,
        (err?: Error) => {
          if (!err) {
            const newpriceGroupItems = [...priceGroupItems];
            const index = newpriceGroupItems.findIndex(i => i.priceGroupId === priceGroupItem.priceGroupId);
            if (index !== -1) {
              newpriceGroupItems.splice(index, 1);
            }
            this.setState({
              priceGroupItems: newpriceGroupItems,
              product: {
                ...product,
                priceGroups: [...newpriceGroupItems.map(p => p.priceGroupId)],
              },
            });
          }
        },
      );
    } else {
      const { priceGroups = [] } = product;
      const idx = priceGroups.findIndex(p => p === priceGroupItem.priceGroupId);
      if (idx !== -1) {
        priceGroups.splice(idx, 1);
      }
      this.setState({ product: { ...product, priceGroups } });
    }
  };

  /**
   * Render pictures
   */
  private renderPictures() {
    const { product, editMode } = this.state;
    const productImages: Array<ISizeImage> = product.images ? product.images.filter(i => i.image.url) : [];
    const image0 = productImages[0] ? productImages[0].image.url : undefined;
    const image1 = productImages[1] ? productImages[1].image.url : undefined;
    const image2 = productImages[2] ? productImages[2].image.url : undefined;
    const image3 = productImages[3] ? productImages[3].image.url : undefined;
    return (
      <ColumnContainer>
        <S.TextPlaceholder>{__('Components.ProductDetails.allowed_format')}</S.TextPlaceholder>
        <S.SubRow alignItems="flex-end">
          <S.Picture
            editable={editMode}
            imageUrl={image0}
            onDelete={() => this.onPicturedDelete(0)}
            onFileChange={file => this.onPictureChange(file, 0)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image1}
            onDelete={() => this.onPicturedDelete(1)}
            onFileChange={file => this.onPictureChange(file, image1 ? 1 : productImages.length)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image2}
            onDelete={() => this.onPicturedDelete(2)}
            onFileChange={file => this.onPictureChange(file, image2 ? 2 : productImages.length)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image3}
            onDelete={() => this.onPicturedDelete(3)}
            onFileChange={file => this.onPictureChange(file, image3 ? 3 : productImages.length)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
        </S.SubRow>
      </ColumnContainer>
    );
  }

  /**
   * Render form related with product characteristics
   */
  private renderCharacteristicsForm() {
    const {
      catalog: { promotedBy },
    } = this.props;
    const { product, countries, prodTypes, editMode } = this.state;
    return (
      <>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {this.renderSelectInput(
              'type',
              __('Components.ProductDetails.product.type'),
              true,
              prodTypes,
              this.changeProductType,
              product.type.type,
            )}
          </S.SubColumn>
          <S.EmptyColumn />
          <S.SubColumn>
            {this.renderTextInput(
              'variety',
              __('Components.ProductDetails.product.variety'),
              this.changeProductType,
              product.type.variety,
              true,
            )}
          </S.SubColumn>
        </S.SubRow>
        <S.Row>
          <InputWithLabel
            label={__('Components.ProductDetails.product.name')}
            isRecommended={true}
            isRequired={false}
            width="100%"
          >
            <TextArea
              name={'productTitle'}
              containerMargin={'4px 0'}
              width={'100%'}
              value={product.productTitle}
              onChange={this.changeProduct}
              disabled={!editMode}
              rows={3}
              placeholder={
                product.productTitle ||
                productService.getProductTypeVarietyDisplay(
                  product.type.type,
                  this.props.prodTypes[product.type.type]?.name || '',
                  product.type.variety,
                )
              }
            />
          </InputWithLabel>
        </S.Row>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {this.renderSelectInput(
              'origin',
              __('Components.ProductDetails.product.origin'),
              false,
              countries,
              this.changeProductType,
              product.type.origin,
            )}
            {this.renderTextInput(
              'packaging',
              __('Components.ProductDetails.distribution.packaging'),
              this.changeProductDebounce,
              product.packaging,
            )}
            {!promotedBy
              ? this.renderTextInput(
                  'size',
                  __('Components.ProductDetails.product.size'),
                  this.changeProductDebounce,
                  product.size,
                )
              : null}
            {!promotedBy
              ? this.renderTextInput(
                  'brand',
                  __('Components.ProductDetails.product.brand'),
                  this.changeProductDebounce,
                  product.brand,
                )
              : null}
            {!promotedBy ? (
              <S.SubRow>
                {this.renderSelectInput(
                  'eanType',
                  __('Components.ProductDetails.metadata.eanCode'),
                  true,
                  [
                    { value: 'EAN', label: 'EAN (GTIN)' },
                    { value: 'PLU', label: 'PLU' },
                    { value: 'UPC', label: 'UPC' },
                  ],
                  this.changeProductDebounce,
                  product.eanType,
                  { padding: '-1px 0 0 6px', width: '65%', marginRight: '6px' },
                )}
                {
                  <S.RightElement>
                    {this.renderTextInput(
                      'eanCode',
                      ' ',
                      this.changeProductDebounce,
                      product.eanCode,
                      undefined,
                      undefined,
                      255,
                    )}
                  </S.RightElement>
                }
              </S.SubRow>
            ) : null}
            {config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled && promotedBy && product.id ? (
              <S.IdColumn>
                <S.BoldText>{__('ProductEdit.unique_id.title')}</S.BoldText>
                <S.GreyText>
                  {product.hashId}
                  <Tooltip position="right" text={__('ProductEdit.unique_id.tooltip')} width="200px" themeMode="dark">
                    <S.GreyInfoIcon name="Info" disableHover={true} />
                  </Tooltip>
                </S.GreyText>
              </S.IdColumn>
            ) : null}
          </S.SubColumn>
          <S.EmptyColumn />

          <S.SubColumn>
            {this.renderTextInput(
              'region',
              __('Components.ProductDetails.product.region'),
              this.changeProduct,
              product.region,
            )}
            {!promotedBy
              ? this.renderSelectInput(
                  'category',
                  __('Components.ProductDetails.product.category'),
                  false,
                  getCategories(),
                  this.changeProduct,
                  product.category,
                )
              : null}
            {!promotedBy
              ? this.renderSelectInput(
                  'organic',
                  __('Components.ProductDetails.product.organic'),
                  false,
                  getOrganicTypes(),
                  this.changeProduct,
                  product.organic,
                )
              : null}
            {!promotedBy
              ? this.renderTextInput(
                  'sku',
                  __('Components.ProductDetails.metadata.sku'),
                  this.changeProductDebounce,
                  product.sku,
                )
              : null}
            {promotedBy
              ? this.renderTextInput(
                  'brand',
                  __('Components.ProductDetails.product.brand'),
                  this.changeProductDebounce,
                  product.brand,
                )
              : null}
            {config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled && !promotedBy && product.id ? (
              <S.IdColumn>
                <S.BoldText>{__('ProductEdit.unique_id.title')}</S.BoldText>
                <S.GreyText>
                  {product.hashId}
                  <Tooltip position="right" text={__('ProductEdit.unique_id.tooltip')} width="200px" themeMode="dark">
                    <S.GreyInfoIcon name="Info" disableHover={true} />
                  </Tooltip>
                </S.GreyText>
              </S.IdColumn>
            ) : null}
          </S.SubColumn>
        </S.SubRow>
        <InputWithLabel label={__('Components.ProductDetails.more_info.title')} isRequired={false} width="100%">
          <TextArea
            containerMargin="4px 0"
            maxLength={2048}
            name="moreInfo"
            onBlur={this.changeProduct}
            onChange={this.changeProduct}
            placeholder={__('Components.ProductDetails.more_info.placeholder')}
            disabled={!editMode}
            value={product.moreInfo || ''}
          />
        </InputWithLabel>
      </>
    );
  }

  /**
   * Renders from related with product distribution
   */
  private renderDistributionForm() {
    const { product } = this.state;
    const { addresses, catalog, catalogId, me, boxTypes, palletTypes } = this.props;
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const options = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
      ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
      : AllProductUnitsArray;
    return (
      <>
        <InputWithLabel label={__('Components.ProductDetails.sale_units.title')} isRequired={true} width="100%">
          <S.CheckBoxRow>
            {options.map((el, i) => (
              <React.Fragment key={el}>
                <S.CheckBox
                  className="sale-units"
                  isChecked={product.saleUnits.includes(el)}
                  disabled={
                    !productService.isAllowedSaleUnit(product, options[i], catalog.mandatoryPalletizationByClient)
                  }
                  onClick={() =>
                    this.changeProductMultiple([
                      {
                        key: 'saleUnits',
                        value: [...productService.toggleSaleUnit(product.saleUnits, options[i] as PRODUCT_UNIT)],
                      },
                    ])
                  }
                />

                <S.CheckBoxLabel>
                  {constants.getPriceUnitLabels(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(el) : el,
                    me.settings.language,
                    catalog.defaultWeightUnit,
                  )}
                </S.CheckBoxLabel>
              </React.Fragment>
            ))}
          </S.CheckBoxRow>
        </InputWithLabel>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {this.renderNumberInput(
              'boxWeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfWeight')
                : __('Components.ProductDetails.distribution.boxWeight'),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              product.boxWeight,
              undefined,
              0.01,
              undefined,
              2,
              catalog?.defaultWeightUnit,
              catalog?.defaultWeightUnit,
            )}
            {this.renderNumberInput(
              'piecesPerBox',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.piecesPerShelf')
                : __('Components.ProductDetails.distribution.piecesPerBox'),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              product.piecesPerBox,
              undefined,
              1,
            )}

            {this.renderNumberInput(
              'boxesPerPallet',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelvesPerCart')
                : __('Components.ProductDetails.distribution.boxesPerPallet'),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              product.boxesPerPallet,
              undefined,
              1,
            )}
            {this.renderNumberInput(
              'palletHeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartHeight')
                : __('Components.ProductDetails.distribution.palletHeight'),
              this.changeProduct,
              product.palletHeight,
              undefined,
              0,
              undefined,
              2,
              catalog?.lengthUnit,
            )}
          </S.SubColumn>

          <S.EmptyColumn />

          <S.SubColumn>
            {this.renderSelectInput(
              'boxId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfType')
                : __('Components.ProductDetails.distribution.boxType'),
              false,
              boxTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key: string, value: any, error?: string) => this.changeProduct(key, Number(value), error),
              product.boxId + '',
            )}
            {this.renderSelectInput(
              'palletId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartType')
                : __('Components.ProductDetails.distribution.palletType'),
              false,
              palletTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key: string, value: any, error?: string) => this.changeProduct(key, Number(value), error),
              product.palletId + '',
            )}
            {config.TOGGLE_PRODUCT_WAREHOUSE.enabled
              ? this.renderSelectInput(
                  'warehouseId',
                  __('Components.ProductDetails.distribution.warehouse'),
                  false,
                  [
                    { value: '', label: __('Facets.unspecified') },
                    ...addresses.map(a => ({
                      value: a.id,
                      label: a.displayName + (a.city || a.zip ? ` - ${a.city || ''} ${a.zip || ''}` : ''),
                    })),
                  ],
                  this.changeProduct,
                  product.warehouseId as any,
                )
              : null}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  }

  private renderNewDistributionForm() {
    const { product } = this.state;
    const { addresses, catalog, catalogId, me, boxTypes, palletTypes } = this.props;
    const options = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
      ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
      : AllProductUnitsArray;
    return (
      <>
        <InputWithLabel label={__('Components.ProductDetails.sale_units.title')} isRequired={true} width="100%">
          <S.CheckBoxRow>
            {options.map((el, i) => (
              <React.Fragment key={el}>
                <S.CheckBox
                  className="sale-units"
                  isChecked={product.saleUnits.includes(el)}
                  disabled={
                    !productService.isAllowedSaleUnit(product, options[i], catalog.mandatoryPalletizationByClient)
                  }
                  onClick={() =>
                    this.changeProductMultiple([
                      {
                        key: 'saleUnits',
                        value: [...productService.toggleSaleUnit(product.saleUnits, options[i] as PRODUCT_UNIT)],
                      },
                    ])
                  }
                />

                <S.CheckBoxLabel>
                  {constants.getPriceUnitLabels(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(el) : el,
                    me.settings.language,
                    catalog.defaultWeightUnit,
                  )}
                </S.CheckBoxLabel>
              </React.Fragment>
            ))}
          </S.CheckBoxRow>
        </InputWithLabel>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {this.renderNumberInput(
              'boxWeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfWeight')
                : __('Components.ProductDetails.distribution.boxWeight'),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              product.boxWeight,
              undefined,
              0.01,
              undefined,
              2,
              catalog?.defaultWeightUnit,
              catalog?.defaultWeightUnit,
            )}
            {this.renderNumberInput(
              'piecesPerBox',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.piecesPerShelf')
                : __('Components.ProductDetails.distribution.piecesPerBox'),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              product.piecesPerBox,
              undefined,
              1,
            )}

            {this.renderNumberInput(
              'palletHeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartHeight')
                : __('Components.ProductDetails.distribution.palletHeight'),
              this.changeProduct,
              product.palletHeight,
              undefined,
              0,
              undefined,
              2,
              catalog?.lengthUnit,
            )}
            {config.TOGGLE_BOXES_PER_PALLET.enabled && catalog.mandatoryPalletizationByClient
              ? this.renderBoxPerPalletSection(
                  'boxesPerPalletValues',
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                    ? __('Components.ProductDetails.distribution.shelvesPerCart')
                    : __('Components.ProductDetails.distribution.boxesPerPallet'),
                  (key, index, value, isDefault, error) =>
                    this.changeBoxPerPallet(key, index, value as number, isDefault, error),
                  0,
                  undefined,
                  1,
                )
              : this.renderNumberInput(
                  'boxesPerPallet',
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                    ? __('Components.ProductDetails.distribution.shelvesPerCart')
                    : __('Components.ProductDetails.distribution.boxesPerPallet'),
                  (key, value, error) =>
                    this.changeProductMultiple([
                      { key, value, error },
                      {
                        key: 'saleUnits',
                        value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                      },
                    ]),
                  product.boxesPerPallet,
                  undefined,
                  1,
                )}
          </S.SubColumn>

          <S.EmptyColumn />

          <S.SubColumn>
            {this.renderSelectInput(
              'boxId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfType')
                : __('Components.ProductDetails.distribution.boxType'),
              false,
              boxTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key: string, value: any, error?: string) => this.changeProduct(key, Number(value), error),
              product.boxId + '',
            )}
            {this.renderSelectInput(
              'palletId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartType')
                : __('Components.ProductDetails.distribution.palletType'),
              false,
              palletTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key: string, value: any, error?: string) => this.changeProduct(key, Number(value), error),
              product.palletId + '',
            )}
            {config.TOGGLE_PRODUCT_WAREHOUSE.enabled
              ? this.renderSelectInput(
                  'warehouseId',
                  __('Components.ProductDetails.distribution.warehouse'),
                  false,
                  [
                    { value: '', label: __('Facets.unspecified') },
                    ...addresses.map(a => ({
                      value: a.id,
                      label: a.displayName + (a.city || a.zip ? ` - ${a.city || ''} ${a.zip || ''}` : ''),
                    })),
                  ],
                  this.changeProduct,
                  product.warehouseId as any,
                )
              : null}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  }

  /**
   * Render form related to tags
   */
  private renderSectionsForm() {
    const { tags } = this.props;
    const { product } = this.state;

    const tagsOptions = tags
      .reduce((acc, tag) => {
        if (!product.tags.includes(tag)) acc.push(tag);
        return acc;
      }, [])
      .sort();
    return (
      <S.SubRow>
        <ColumnContainer width="100%">
          <S.TextPlaceholder>{__('Components.ProductDetails.tag.input_label')}</S.TextPlaceholder>
          <S.TagsSelectRow>
            <Select
              containerMargin="0 0px 4px 0"
              creatable={true}
              hasError={!!this.state.errors.get('tags')}
              name="tags"
              noOptionsMessage={__('Components.Settings.sections.no_tag_available')}
              onChange={(name, value) => this.addNewTag(value)}
              options={tagsOptions}
              value=""
              width="100%"
              placeholder={__('Components.ProductDetails.tag.placeholder')}
            />
          </S.TagsSelectRow>
          {product.tags.length ? (
            <S.TagsRow>
              {product.tags.map((tag, i) =>
                webConstants.NO_TAG !== tag ? (
                  <S.ProductTag
                    label={tag}
                    className="product-tag"
                    key={tag + i}
                    onDelete={label => {
                      const tagsCopy = [...product.tags];
                      tagsCopy.splice(product.tags.indexOf(label), 1);
                      this.changeProduct('tags', tagsCopy);
                    }}
                  />
                ) : null,
              )}
            </S.TagsRow>
          ) : null}
        </ColumnContainer>
      </S.SubRow>
    );
  }

  /**
   * Add a new product tag
   */
  private addNewTag = (newTag: string) => {
    const { product } = this.state;
    const { modalOpen, modalClose } = this.props;
    if (newTag && newTag.length > MAX_TAG_LENGTH) {
      return modalOpen(__('Components.ProductDetails.tag.error_max_length', { maxLength: MAX_TAG_LENGTH }), () => {
        modalClose();
      });
    }
    if (newTag && !product.tags.includes(newTag)) {
      this.changeProduct('tags', [...product.tags, newTag]);
    }
  };

  /**
   * Render form related to price
   */
  private renderPriceForm() {
    const { catalog, catalogId, me } = this.props;
    const { product, editMode } = this.state;
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const options = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
      ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
      : AllProductUnitsArray;

    return (
      <>
        <S.SubRow>
          <S.SubColumn>
            <Radio
              name={'is_por'}
              direction={'column'}
              options={[
                {
                  label: __('Components.ProductDetails.por_off'),
                  value: __('Components.ProductDetails.por_off'),
                },
                {
                  label: __('Components.ProductDetails.por_on'),
                  value: __('Components.ProductDetails.por_on'),
                },
              ]}
              containerMargin={'4px 0'}
              alignItems={'flex-start'}
              styleLabel={{ margin: '5px 0' }}
              itemsChecked={
                product.isPor ? [__('Components.ProductDetails.por_on')] : [__('Components.ProductDetails.por_off')]
              }
              onChange={(key, value, error) =>
                this.changeProductMultiple([
                  { key, value: !!value },
                  { key: 'isPor', value: !product.isPor },
                  { key: 'price', value: value ? 0 : product.price },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(product, catalog.mandatoryPalletizationByClient),
                  },
                ])
              }
              disabled={!editMode}
            />
          </S.SubColumn>
        </S.SubRow>
        <S.SubRow withoutPaddingBottom={true}>
          {product.isPor ? null : (
            <>
              <S.SubColumn>
                {this.renderNumberInput(
                  'price',
                  __('Components.ProductDetails.price.price'),
                  this.changeProduct,
                  typeof product.price === 'number' ? product.price.toString() : '',
                  true,
                  currency.getPriceInputMinimumValue(pricePrecision),
                  undefined,
                  pricePrecision,
                  constants.CURRENCIES[product.currency].symbol,
                  constants.CURRENCIES[product.currency].symbol,
                )}
              </S.SubColumn>
              <S.EmptyColumn />
            </>
          )}

          <S.SubColumn style={product.isPor ? { maxWidth: '50% ' } : null}>
            {this.renderSelectInput(
              'priceUnit',
              __('Components.ProductDetails.price.priceUnit'),
              true,
              options.map(u => ({
                label: constants.getPriceUnitLabels(
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(u) : u,
                  me.settings.language,
                  catalog.defaultWeightUnit,
                ),
                value: u,
              })),
              (key, value, error) =>
                this.changeProductMultiple([
                  { key, value, error },
                  { key: 'saleUnits', value: [value as PRODUCT_UNIT] },
                ]),
              product.priceUnit,
            )}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  }
  /**
   * Render product visible switch
   */
  private renderProductVisibleSwitch() {
    const { product } = this.state;
    return (
      <S.SubRow>
        <S.SubColumn>
          <SelectWorkingStatus
            name="status"
            value={product.status as webConstants.AVAILABILITY}
            onChange={(name, value) => this.changeProduct(name, value)}
            options={[
              { label: __('Components.Switch.available'), value: webConstants.AVAILABILITY.ACTIVE },
              { label: __('Components.Switch.unavailable'), value: webConstants.AVAILABILITY.UNAVAILABLE },
              { label: __('Components.Switch.hidden'), value: webConstants.AVAILABILITY.INACTIVE },
            ]}
          />
        </S.SubColumn>
      </S.SubRow>
    );
  }

  private renderTextInput(
    name: string,
    label: string,
    changeProduct: (key: string, value: string, error?: string) => void,
    value?: string | number,
    isRequired?: boolean,
    isRecommended?: boolean,
    maxLength?: number,
    placeholder?: string,
  ) {
    const { editMode } = this.state;
    return (
      <S.Row>
        <InputWithLabel label={label} isRequired={isRequired} isRecommended={isRecommended} width="100%">
          <Input
            name={name}
            containerMargin={'4px 0'}
            width={'100%'}
            type={'text'}
            value={value}
            hasError={!!this.state.errors.get(name)}
            onChange={changeProduct}
            isRequired={isRequired}
            disabled={!editMode}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        </InputWithLabel>
      </S.Row>
    );
  }

  private changeBoxPerPallet = (key: string, index: number, value: number, isDefault: boolean, error?: string) => {
    const newBoxPerPalletValue: IBoxesPerPalletValue = {
      value,
      isDefault,
    };
    if (isNaN(value)) return;
    const product = {
      ...this.state.product,
      boxesPerPalletValues: this.replaceOrAppend(this.state.product.boxesPerPalletValues, index, newBoxPerPalletValue),
    };

    this.setState({
      hasChanges: true,
      product,
    });
  };

  // replace a value in boxPerPallet or append a new one if index is out of bounds
  private replaceOrAppend(array, index, value) {
    if (!array) {
      array = [];
    }
    if (index >= 0 && index < array.length) {
      array[index] = value;
    } else {
      array.push(value);
    }
    return array;
  }

  // toggle the when you click on the kebab icon of box per pallet
  private toggleKebabBox = index => {
    this.setState(prevState => ({
      kebabBoxVisibility: {
        ...prevState.kebabBoxVisibility,
        [index]: !prevState.kebabBoxVisibility[index],
      },
    }));
  };

  // Add a new input to boxPerPalletValue
  private addNewInputBoxPerPallet = () => {
    const newBoxPerPalletValue: IBoxesPerPalletValue = {
      value: 0,
      isDefault: false,
    };

    const product = {
      ...this.state.product,
      boxesPerPalletValues: this.replaceOrAppend(
        this.state.product.boxesPerPalletValues,
        this.state.product.boxesPerPalletValues.length + 1,
        newBoxPerPalletValue,
      ),
    };
    this.setState({
      hasChanges: true,
      product,
    });
  };

  // delete a value from boxPerPallet
  private deleteBoxPerPalletValue = (index: number) => {
    const product = {
      ...this.state.product,
      boxesPerPalletValues: this.state.product.boxesPerPalletValues.filter((_, i) => i !== index),
    };
    this.setState({
      hasChanges: true,
      product,
    });
  };

  // make a value of boxPerPallet default
  private makePredefinedBoxPerPalletValue = (index: number) => {
    const product = {
      ...this.state.product,
      boxesPerPalletValues: this.state.product.boxesPerPalletValues.map((el, i) => {
        i === index ? (el.isDefault = true) : (el.isDefault = false);
        return el;
      }),
    };
    this.setState({
      hasChanges: true,
      product,
    });
  };

  private renderBoxPerPalletSection(
    name: string,
    label: string,
    changeProduct: (key: string, index: number, value: string | number, isDefault: boolean, error?: string) => void,
    value?: string | number,
    isRequired?: boolean,
    minValue?: number,
    maxValue?: number,
    precision?: number,
  ) {
    const { editMode, product, kebabBoxVisibility } = this.state;
    return (
      <S.Col>
        <InputWithLabel label={label} isRequired={isRequired} width="100%" className="">
          {product.boxesPerPalletValues && product.boxesPerPalletValues.length > 0
            ? product.boxesPerPalletValues.map((el, i) =>
                this.renderBoxPerPalletInput(
                  name,
                  changeProduct,
                  editMode,
                  el?.value ? el.value.toString() : '0',
                  isRequired,
                  minValue,
                  maxValue,
                  precision,
                  '',
                  '',
                  i,
                  kebabBoxVisibility[i],
                  this.toggleKebabBox,
                  el.isDefault,
                ),
              )
            : this.renderBoxPerPalletInput(
                name,
                changeProduct,
                editMode,
                '0',
                isRequired,
                minValue,
                maxValue,
                precision,
                '',
                '',
                0,
                kebabBoxVisibility[0],
                this.toggleKebabBox,
                true,
              )}
        </InputWithLabel>

        <S.BlueText
          onClick={() => {
            this.addNewInputBoxPerPallet();
          }}
        >
          {__('Components.ProductDetails.box_per_pallet.add_option')}
        </S.BlueText>
      </S.Col>
    );
  }

  private renderBoxPerPalletInput(
    name: string,
    changeProduct: (key: string, index: number, value: string | number, isDefault: boolean, error?: string) => void,
    editMode: boolean,
    value?: string,
    isRequired?: boolean,
    minValue?: number,
    maxValue?: number,
    precision?: number,
    variableTextSingular?: string,
    variableTextPlural?: string,
    index?: number,
    showKebabBox?: boolean,
    toggleKebabBox?: (index: number) => void,
    isDefault?: boolean,
  ) {
    return (
      <Input
        key={index}
        name={name}
        containerMargin={'4px 0'}
        width="100%"
        type={'number'}
        value={value}
        hasError={!!this.state.errors.get(name)}
        onChange={(_, value) => changeProduct(name, index, value, isDefault)}
        isRequired={isRequired}
        disabled={!editMode}
        minValue={minValue}
        maxValue={maxValue}
        precision={precision}
        variableTextSingular={variableTextSingular}
        variableTextPlural={variableTextPlural}
      >
        <S.Row>
          {isDefault ? (
            <S.Label>{__('Components.ProductDetails.box_per_pallet.predefined')}</S.Label>
          ) : (
            <>
              <S.Kebab name="Kebab" onClick={() => toggleKebabBox(index)} />
              {showKebabBox && (
                <S.KebabBox>
                  <S.KebabRow
                    onClick={() => {
                      this.makePredefinedBoxPerPalletValue(index);
                      toggleKebabBox(index);
                    }}
                  >
                    <S.InfoText>{__('Components.ProductDetails.box_per_pallet.make_predefined')}</S.InfoText>

                    <Tooltip
                      disabled={false}
                      position="right"
                      text={__('Components.ProductDetails.box_per_pallet.tooltip')}
                      width="300px"
                      themeMode="dark"
                    >
                      <S.InfoKebab name="Info" />
                    </Tooltip>
                  </S.KebabRow>
                  <S.KebabRow
                    onClick={() => {
                      this.deleteBoxPerPalletValue(index);
                      toggleKebabBox(index);
                    }}
                  >
                    <S.DeleteText>{__('Components.ProductDetails.box_per_pallet.delete_option')}</S.DeleteText>
                  </S.KebabRow>
                </S.KebabBox>
              )}
            </>
          )}
        </S.Row>
      </Input>
    );
  }

  private renderNumberInput(
    name: string,
    label: string,
    changeProduct: (key: string, value: string, error?: string) => void,
    value?: string | number,
    isRequired?: boolean,
    minValue?: number,
    maxValue?: number,
    precision?: number,
    variableTextSingular?: string,
    variableTextPlural?: string,
  ) {
    const { editMode } = this.state;
    return (
      <S.Row>
        <InputWithLabel label={label} isRequired={isRequired} width="100%">
          <Input
            name={name}
            containerMargin={'4px 0'}
            width="100%"
            type={'number'}
            value={value}
            hasError={!!this.state.errors.get(name)}
            onBlur={changeProduct}
            isRequired={isRequired}
            disabled={!editMode}
            minValue={minValue}
            maxValue={maxValue}
            precision={precision}
            variableTextSingular={variableTextSingular}
            variableTextPlural={variableTextPlural}
          />
        </InputWithLabel>
      </S.Row>
    );
  }

  private renderSelectInput(
    name: string,
    label: string,
    isRequired: boolean,
    options: Array<any>,
    onChange: (name: string, value: string, error?: string) => void,
    value?: string,
    style?: React.CSSProperties,
  ) {
    const { editMode } = this.state;
    return (
      <S.Row style={style}>
        <InputWithLabel label={label} isRequired={isRequired} width="100%">
          <Select
            containerMargin="4px 0"
            hasError={!!this.state.errors.get(name)}
            name={name}
            onChange={onChange}
            options={options}
            disabled={!editMode}
            value={value || ''}
            width="100%"
          />
        </InputWithLabel>
      </S.Row>
    );
  }

  /**
   * Show success modal when product is updated correctly
   */
  private showSaveSuccessModal = (mode: 'created' | 'updated') => {
    const { notificationShow } = this.props;
    notificationShow(
      {
        title:
          mode === 'updated'
            ? __('Components.ProductDetails.success_update')
            : __('Components.ProductDetails.success_create'),
        subtitle: '',
        closable: true,
        style: 'success',
      },
      5000,
    );
  };

  /**
   * On remove product callback. Showing a confirmation alert.
   */
  private onRemove(product: IProduct): void {
    const { catalogId } = this.props;
    this.props.modalOpen(
      __('Components.ProductDetails.confirm_remove'),
      () =>
        this.props.productRemove(catalogId, product.id!, () => {
          this.props.modalClose();
        }),
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  }

  /**
   * On picture change:
   * * Upload picture
   * * Update in product state
   */
  private onPictureChange(f: File, index: number) {
    if (index > MAX_UPLOAD_PICTURES - 1) return;
    const { mediaUpload } = this.props;
    const product = { ...this.state.product };
    convertToIFile(
      f,
      (file: IFile, fileString: string) => {
        if (fileString)
          resizeImage(fileString, (imageResized: string) =>
            mediaUpload({ ...file, content: imageResized }, result =>
              this.changeProductImage(product, result ? result.secure_url : undefined, index),
            ),
          );
      },
      (code: string) => {
        logError(new Error('File could not be read! Code ' + code), 'upload.image');
      },
    );
  }

  /**
   * On delete picture with confirmation modal
   */
  private onPicturedDelete(index: number): void {
    this.props.modalOpen(
      __('Components.ProductDetails.confirm_remove_picture'),
      () => {
        this.deleteProductPicture(index);
        this.props.modalClose();
      },
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  }

  /**
   * Delete picture from product state
   */
  private deleteProductPicture(index: number): void {
    const { product } = this.state;
    const imagesUpdate = Array.isArray(product.images) ? product.images.slice() : [];
    if (index < imagesUpdate.length) {
      imagesUpdate.splice(index, 1);
      this.setState({ product: { ...this.state.product, images: imagesUpdate }, hasChanges: true });
    }
  }

  /**
   * Add field error in state
   */
  private setError(key: string, error?: string) {
    if (error) {
      this.state.errors.set(key, error);
    } else {
      this.state.errors.delete(key);
    }
  }

  private getSections = (): Sections => {
    const {
      catalog: { promotedBy },
    } = this.props;
    const { product } = this.state;
    const result: Sections = [
      {
        id: 'characteristics',
        title: __('ProductEdit.Characteristics.title'),
        icon: 'Edit',
      },
      {
        id: 'images-section',
        title: __('ProductEdit.Visibility.title'),
        icon: 'Clip',
      },
      {
        id: 'price-section',
        title: __('ProductEdit.Price.title'),
        icon: 'Price',
      },
      !promotedBy
        ? {
            id: 'distribution',
            title: __('ProductEdit.Distribution.title'),
            icon: 'Pickup-package',
          }
        : null,
      ...(config.TOGGLE_SUSTAINABILITY.enabled && !promotedBy
        ? ([
            {
              id: 'sustainability',
              title: __('ProductEdit.Sustainability.left_title'),
              icon: 'Producer',
            },
          ] as Sections)
        : []),
      ...(product.id
        ? ([
            {
              id: 'additional-files',
              title: __('ProductEdit.additionalFiles.title'),
              icon: 'Document',
            },
          ] as Sections)
        : []),

      ...(product.id
        ? ([
            {
              id: 'delete',
              title: __('Components.ProductDetails.other_options'),
              icon: 'Kebab',
            },
          ] as Sections)
        : []),
    ];

    return result;
  };

  private showPricegroupsModal = () => {
    const { catalog, history, me, modalOpen, priceGroups, pricesGetFromProduct, requestProInfo, setShowPriceGroups } =
      this.props;
    const { product } = this.state;
    if (!sellerWorkspaceService.isProPlan(catalog?.plan)) {
      modalOpen('pricelist', () => requestProInfo(me.id, catalog.id, 'pricelist'), {}, 'consentiopro');
    } else {
      if (priceGroups && Object.keys(priceGroups)?.length) {
        if (product.id) {
          pricesGetFromProduct(catalog.id, product.id, priceGroupItems => {
            this.setState({
              priceGroupItems,
              showAddToPriceGroup: (priceGroups && !Object.keys(priceGroups).length) || !product.priceGroups?.length,
            });
            setShowPriceGroups(true);
          });
        } else {
          this.setState({
            showAddToPriceGroup: (priceGroups && !Object.keys(priceGroups).length) || !product.priceGroups?.length,
          });
          setShowPriceGroups(true);
        }
      } else {
        history.push(
          getPath({
            path: webConstants.ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT,
            workspaceId: catalog.id + '',
            priceGroupId: 'new',
          }),
        );
      }
    }
  };

  private pricesAddProduct = (selecteds: Array<string>) => {
    const { pricesAddProduct, priceGroups } = this.props;
    const { priceGroupItems, product } = this.state;
    if (product.id) {
      pricesAddProduct(product.catalogId, product.id, selecteds, pgis =>
        this.setState({
          priceGroupItems: [
            ...priceGroupItems,
            ...pgis.filter(p => !priceGroupItems.find(pg => pg.priceGroupId === p.priceGroupId)),
          ],
          product: {
            ...product,
            priceGroups: [...(product.priceGroups || []), ...selecteds.filter(p => !product.priceGroups?.includes(p))],
          },
        }),
      );
    } else {
      this.setState({
        priceGroupItems: [
          ...priceGroupItems,
          ...Object.keys(priceGroups)
            .filter(p => selecteds.includes(p) && !priceGroupItems.find(pg => pg.priceGroupId === p))
            .map(p => ({
              ...product,
              percentage: 0,
              priceGroupId: p,
              productId: 0,
              productImageUrl: '',
              productType: '',
              type: PRICE_ADJUSTMENT_TYPE.BASE,
              useBase: true,
              rank: 0,
            })),
        ],
        product: {
          ...product,
          priceGroups: [...(product.priceGroups || []), ...selecteds.filter(p => !product.priceGroups?.includes(p))],
        },
      });
    }
  };
}

export default ProductEdit;

function AddToPriceGroup({
  contacts,
  isQuoterMode,
  me,
  priceGroups,
  pricesAddProduct,
  product,
  setShowAddToPriceGroup,
}: {
  contacts: Record<number, IContact>;
  isQuoterMode: boolean;
  me: IUser;
  priceGroups: Array<IPriceGroup>;
  pricesAddProduct: (selecteds: Array<string>) => void;
  product: IProduct;
  setShowAddToPriceGroup: (show: boolean) => void;
}) {
  const [searchText, setSearchText] = React.useState('');
  const [selecteds, setSelecteds] = React.useState([]);
  return (
    <ActionsModal
      onClose={() => setShowAddToPriceGroup(false)}
      title={
        isQuoterMode
          ? __('Components.ProductDetails.add_to_margins')
          : __('Components.ProductDetails.add_to_pricegroups')
      }
      width="800px"
    >
      <S.SearchWrapper>
        <S.PriceGroupSearch
          id="search-price-groups"
          query={searchText}
          placeHolder={
            isQuoterMode ? __('WorkspaceMargins.search_placeholder') : __('WorkspacePriceGroups.search_placeholder')
          }
          onChange={(t: string) => setSearchText(t)}
        />
      </S.SearchWrapper>
      <S.TableContainer className="price-groups-scroll">
        <PriceGroupTable
          className="product-price-group-table"
          contacts={contacts}
          isQuoterMode={isQuoterMode}
          me={me}
          onItemClick={p => {
            const { priceGroupId } = p;
            if (selecteds.includes(priceGroupId)) {
              const s = [...selecteds];
              s.splice(s.indexOf(priceGroupId), 1);
              setSelecteds(s);
            } else {
              setSelecteds([...selecteds, priceGroupId]);
            }
          }}
          priceGroups={searchText ? priceGroups.filter(p => p.name.includes(searchText)) : priceGroups}
          product={product}
          selecteds={selecteds}
          touchImage={() => null}
        />
      </S.TableContainer>
      {selecteds.length ? (
        <S.AddButton
          type="principal"
          onClick={() => {
            pricesAddProduct(selecteds);
            setSelecteds([]);
          }}
        >
          {isQuoterMode
            ? __('Components.ProductDetails.add_to_margins_cta', { count: selecteds.length })
            : __('Components.ProductDetails.add_to_pricegroups_cta', { count: selecteds.length })}
        </S.AddButton>
      ) : null}
    </ActionsModal>
  );
}
const PlasticModal: React.FC<{
  product: IProduct;
  save: (p: IProduct) => void;
  close: () => void;
  language: string;
  defaultWeightUnit: WEIGHT_UNIT;
  prodTypes: Record<string, IProdType>;
}> = ({ product, close, save, language, defaultWeightUnit, prodTypes }) => {
  const [plasticType, setPlasticType] = React.useState(product.plasticType);
  const [plasticUnit, setPlasticUnit] = React.useState(product.plasticUnit);
  const [plasticAmount, setPlasticAmount] = React.useState(product.plasticAmount);
  return (
    <S.ActionsModal
      title={__('ProductEdit.Sustainability.plastic.modal_title', {
        name: productService.getProductTypeVarietyDisplay(
          product.type.type,
          prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
          product.type.variety,
        ),
      })}
      onClose={close}
      minHeight="auto"
    >
      <S.ModalContainer>
        <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_type')} isRequired={true} width="100%">
          <Select
            containerMargin="4px 0"
            hasError={false}
            name={'plasticType'}
            onChange={(key, value, error) => setPlasticType(value as PLASTIC_TYPE)}
            options={Object.values(PLASTIC_TYPE).map(u => ({ label: constants.getPlasticLiteral(u), value: u }))}
            disabled={false}
            value={plasticType}
            width="100%"
          />
        </InputWithLabel>
        <S.SubRow>
          <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_unit')} isRequired={true} width="110%">
            <Select
              containerMargin="4px 0"
              hasError={false}
              name={'plasticUnit'}
              onChange={(key, value, error) => setPlasticUnit(value as PRODUCT_UNIT)}
              options={Object.values(PRODUCT_UNIT).map(u => ({
                label: constants.getPriceUnitLabels(u, language, defaultWeightUnit),
                value: u,
              }))}
              disabled={false}
              value={plasticUnit}
              width="90%"
            />
          </InputWithLabel>
          <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_amount')} isRequired={true} width="90%">
            <Input
              name={'plasticAmount'}
              containerMargin={'4px 0'}
              width="100%"
              type={'number'}
              value={plasticAmount}
              hasError={false}
              onBlur={(key, value, error) => setPlasticAmount(Number(value))}
              isRequired={true}
              disabled={false}
              minValue={0}
              variableTextPlural="g"
            />
          </InputWithLabel>
        </S.SubRow>
        <S.CenteredButton
          type="principal"
          onClick={() => {
            save({ ...product, plasticType, plasticUnit, plasticAmount });
            close();
          }}
          disabled={
            product.plasticAmount === plasticAmount &&
            product.plasticType === plasticType &&
            product.plasticUnit === plasticUnit
          }
        >
          {__('Components.ProductDetails.save')}
        </S.CenteredButton>
      </S.ModalContainer>
    </S.ActionsModal>
  );
};
const AdditionalFilesModal: React.FC<{
  product: IProduct;
  userId: number;
  save: (p: IProduct, f: IAttachment) => void;
  close: () => void;
}> = ({ product, userId, close, save }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const modifyFiles = React.useCallback(
    existingFiles => {
      const fileToUpload = [...existingFiles];

      uploadedFiles.forEach(file => {
        fileToUpload.push(file);
      });
      return fileToUpload;
    },
    [uploadedFiles],
  );

  const setFiles = React.useCallback(
    (files: Array<File>) => {
      if (files.length > 0) {
        setUploadedFiles(modifyFiles(files));
      }
    },
    [modifyFiles],
  );
  return (
    <S.ActionsModal title={__('ProductEdit.additionalFiles.modal.title')} onClose={close} minHeight="auto">
      <S.TextModal>{__('ProductEdit.additionalFiles.modal.subtitle')}</S.TextModal>
      <FileDropzone
        files={uploadedFiles}
        setFiles={setFiles}
        close={close}
        onSubmit={() => {
          try {
            convertToIFile(uploadedFiles[uploadedFiles.length - 1], (res: IFile, fileString: string) => {
              dispatch(
                productActions.productUploadFile(userId, product.id, res, (attachment, error) => {
                  save(product, attachment);
                }),
              );
            });
          } catch (err) {
            logError(err, 'ProductEdit.uploadFile');
          }
          close();
        }}
      />
    </S.ActionsModal>
  );
};

/**
 * Render files drop zone
 */
const FileDropzone: React.FC<{
  files: Array<string>;
  onSubmit: () => void;
  close: () => void;
  setFiles: (files: Array<any>) => void;
}> = ({ files, onSubmit, close, setFiles }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  return (
    <>
      <S.FileDropzone>
        <Dropzone
          accept={webConstants.FORMAT_FILES_ALLOWED.join(',')}
          multiple={false}
          maxFiles={1}
          disabled={files.length > 0}
          onDrop={acceptedFiles => {
            if (acceptedFiles.length > 0) {
              setFiles(acceptedFiles);
            } else {
              dispatch(
                notificationsActions.notificationShow(
                  {
                    title: __('ProductEdit.additionalFiles.format_error.title'),
                    subtitle: __('ProductEdit.additionalFiles.format_error.description', {
                      formats: webConstants.FORMAT_FILES_ALLOWED.join(', '),
                    }),
                    closable: true,
                    style: 'error',
                  },
                  6000,
                ),
              );
            }
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
                <input
                  type="file"
                  multiple={false}
                  disabled={files.length > 0}
                  accept={webConstants.FORMAT_FILES_ALLOWED.join(',')}
                  {...getInputProps()}
                />
                <S.UploadFileIcon name="Upload" disableHover={true} />
                <S.TextUpload>
                  {__('MagicOrders.add_orders')}
                  {files.length ? (
                    <>
                      {' · '}
                      <S.TextUploadGreen> {__('MagicOrders.orders_added', { count: files.length })}</S.TextUploadGreen>
                    </>
                  ) : null}
                </S.TextUpload>
                <S.TextCenter>{__('MagicOrders.add_orders_description')}</S.TextCenter>
                {files.length === 0 ? (
                  <S.SelectButton type="secondary">{__('Components.OrdersImport.select')}</S.SelectButton>
                ) : null}
              </S.DropzoneContent>
            );
          }}
        </Dropzone>
      </S.FileDropzone>
      <S.Row>
        <S.SelectButton type="secondary" onClick={close}>
          {__('ProductEdit.additionalFiles.modal.cancel')}
        </S.SelectButton>
        <S.SaveButton type="principal" onClick={onSubmit} disabled={!files.length}>
          {__('ProductEdit.additionalFiles.modal.cta')}
        </S.SaveButton>
      </S.Row>
    </>
  );
};

const AdditionalFiles: React.FC<{
  me: IUser;
  product: IProduct;
  additionalFilesRef: React.RefObject<HTMLDivElement>;
  updateProduct: (p: IProduct) => void;
}> = ({ me, product, additionalFilesRef, updateProduct }) => {
  const [showDeleteAttachmentButton, setShowDeleteAttachmentButton] = React.useState(0);
  const [showAdditionalFilesModal, setShowAdditionalFilesModal] = React.useState(false);
  const dispatch = useDispatch<Dispatch<any>>();
  return (
    <>
      <FormSection
        id="additional-files"
        title={__('ProductEdit.additionalFiles.title')}
        sectionRef={additionalFilesRef}
        withoutPaddingTop={true}
        numHeaders={product.id ? undefined : 2}
      >
        <S.GreyText>{__('ProductEdit.additionalFiles.subtitle')}</S.GreyText>
        {product.attachments.length > 0 ? (
          <>
            {product.attachments.map((att, idx) => {
              return (
                <S.AttachmentWrapper key={idx} onMouseLeave={() => setShowDeleteAttachmentButton(0)}>
                  <Attachment
                    key={idx}
                    attachment={att}
                    download={download => {
                      try {
                        dispatch(productActions.getProductFile(me.id, product?.id, att.id, download));
                      } catch (err) {
                        logError(err, 'Error, unable to download attachment');
                      }
                    }}
                  />
                  {showDeleteAttachmentButton === att?.id ? (
                    <S.DeleteAtt className="trash" onClick={() => onDeleteAttachment(att)}>
                      <S.TrashIcon name="Trash" />{' '}
                      <S.DeleteAttText>{__('ProductEdit.additionalFiles.delete.title')}</S.DeleteAttText>
                    </S.DeleteAtt>
                  ) : null}
                  <S.KebabIcon
                    name="Kebab"
                    className="kebab"
                    onClick={() =>
                      showDeleteAttachmentButton === 0
                        ? setShowDeleteAttachmentButton(att?.id)
                        : setShowDeleteAttachmentButton(0)
                    }
                  />
                </S.AttachmentWrapper>
              );
            })}
          </>
        ) : null}

        <S.AddMoreCta
          iconName={'Add-more'}
          type="link"
          onClick={() => setShowAdditionalFilesModal(true)}
          withoutPadding
        >
          {__('ProductEdit.additionalFiles.link')}
        </S.AddMoreCta>
      </FormSection>

      {showAdditionalFilesModal ? (
        <AdditionalFilesModal
          product={product}
          userId={me.id}
          close={() => setShowAdditionalFilesModal(false)}
          save={(p, f) => updateProduct({ ...product, attachments: [...product.attachments, f] })}
        />
      ) : null}
    </>
  );

  function onDeleteAttachment(att: IAttachment) {
    setShowDeleteAttachmentButton(0);
    dispatch(
      modalActions.modalOpen(
        __('ProductEdit.additionalFiles.delete.title'),
        () => {
          try {
            dispatch(
              productActions.productDeleteFile(me.id, product.id, att.id, error => {
                this.save();
              }),
            );
          } catch (err) {
            logError(err, 'ProductEdit.uploadFile');
          }
          dispatch(modalActions.modalClose());
        },
        {
          text2: __('ProductEdit.additionalFiles.delete.text'),
          showCancelButton: true,
          buttonText: __('ProductEdit.additionalFiles.delete.cta'),
          actionType: 'dangerous',
          icon: IMAGES.error,
          closeable: true,
        },
        'nice',
      ),
    );
  }
};
