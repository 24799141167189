import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, Input, RowContainer } from '../../../../atoms';
import * as CS from '../../LoginRegisterFlow.styled';

interface IStyledProps {
  theme: ITheme;
}

export const InputCol = styled(ColumnContainer)`
  align-self: center;
`;

export const SubmitButton = styled(CS.SubmitButton)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextInput = styled(Input)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  margin-top: 12px;
  max-width: 400px;
`;

export const SkipLink = styled(SubmitButton)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Row = styled(RowContainer)`
  justify-content: center;
`;
