import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  badgeCount?: number;
  theme: ITheme;
}

const getBadgeWidth = (p: IStyledProps) => {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '16px';
  if (p.badgeCount < 1000) return '23px';
  return '30px';
};
export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
export const UnreadsButton = styled(RowContainer)`
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.boxShadow};
  background-color: ${p => p.theme.colors.light2};
  border: 1px solid ${p => p.theme.colors.light1};
  cursor: pointer;
  height: 42px;
  align-items: center;
  padding: 0 ${p => p.theme.paddingSize(2)};
  width: max-content;
`;
export const Column = styled(ColumnContainer)`
  align-items: flex-start;
`;
export const UnreadsButtonTitle = styled(Text)`
  font-size: ${p => p.theme.fontSize.normal};
  font-weight: ${p => p.theme.fontWeight.Semi};
  color: ${p => p.theme.colors.black};
`;
export const UnreadsButtonSubtitle = styled(Text)`
  font-size: ${p => p.theme.fontSize.normal};
  font-weight: ${p => p.theme.fontWeight.Regular};
  color: ${p => p.theme.colors.blue1};
  text-decoration: underline;
`;
export const UnreadsButtonIcon = styled(FontIcon)`
  color: ${p => p.theme.colors.primary};
  font-size: 32px;
  margin-right: ${p => p.theme.paddingView};
  position: relative;
  &:after {
    content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
    font-family: 'Nunito';
    background-color: ${(p: IStyledProps) => p.theme.colors.red1};
    width: ${getBadgeWidth};
    height: 15px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    top: -8px;
    right: 0;
    transform: translateX(50%);
    color: ${(p: IStyledProps) => p.theme.colors.white};
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  }
`;
