import {
  __,
  sellerWorkspaceService,
  utils,
  debounce,
  ISearchClient,
  throttle,
  buyerWorkspaceService,
  EventTrack,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import config from '../../../../bindings/config';

import { ROUTE_PATHS } from '../../../constants';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import ActionsModal from '../ActionsModal';
import ContactsList from '../ContactsList';
import SimpleSearch from '../SimpleSearch';
import * as S from './SelectClientModal.styled';

export interface IProps {
  catalogId: number;
  isPurchase?: boolean;
  onClose: () => void;
  selectContact: (contactId: number, contactName: string, buyerWorkspaceId: number, imageUrl: string) => void;
  me: IUser;
  title: string;
  subtitle: string;
}

const SelectClientModal: React.FC<IProps> = ({
  catalogId,
  isPurchase,
  onClose,
  selectContact,
  me,
  title,
  subtitle,
}) => {
  return (
    <ClientsModal
      onClose={onClose}
      isPurchase={isPurchase}
      selectContact={selectContact}
      catalogId={catalogId}
      me={me}
      title={title}
      subtitle={subtitle}
    />
  );
};

const clientSendSearch = debounce(
  (myId: number, searchState: ISearchClient, onReceiveClients: (res: ISearchData<IClient>) => void) => {
    sellerWorkspaceService
      .clientSearch(searchState, searchState.language, config.SEARCH_PREFIX, myId, api)
      .then(onReceiveClients);
  },
  200,
);

const clientScrollSearch = throttle((searchId: string, onReceiveClients: (res: ISearchData<IClient>) => void) => {
  sellerWorkspaceService.clientScrollSearch(searchId, api).then(onReceiveClients);
}, 200);

const supplierSendSearch = debounce(
  (myId: number, searchState: ISearchClient, onReceiveSuppliers: (res: ISearchData<ISupplier>) => void) => {
    buyerWorkspaceService
      .supplierSearch(searchState, searchState.language, config.SEARCH_PREFIX, myId, api)
      .then(onReceiveSuppliers);
  },
  200,
);

const supplierScrollSearch = throttle((searchId: string, onReceiveSuppliers: (res: ISearchData<ISupplier>) => void) => {
  buyerWorkspaceService.supplierScrollSearch(searchId, api).then(onReceiveSuppliers);
}, 200);

const ClientsModal: React.FC<{
  onClose: () => void;
  isPurchase: boolean;
  selectContact: (contactId: number, contactName: string, buyerWorkspaceId: number, imageUrl: string) => void;
  catalogId: number;
  me: IUser;
  title: string;
  subtitle: string;
}> = ({ onClose, selectContact, isPurchase, catalogId, me, title, subtitle }) => {
  const [elasticClients, setElasticClients] = React.useState<Array<IClient>>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [searchState, setSearchState] = React.useState<ISearchClient>({
    workspaceId: catalogId,
    text: '',
    language: me.settings?.language as LOCALE,
  });
  const [searchId, setSearchId] = React.useState<string>('');
  const [totalResults, setTotalResults] = React.useState<number>(0);

  React.useEffect(() => {
    isPurchase
      ? supplierSendSearch(me.id, searchState, onReceiveClients)
      : clientSendSearch(me.id, searchState, onReceiveClients);
  }, [searchState]);

  React.useEffect(() => {
    setSearchState({ ...searchState, workspaceId: catalogId });
  }, [catalogId]);

  /**
   * handle the scroll event for sticky the searchbar
   */
  function onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      isPurchase ? supplierScrollSearch(searchId, onReceiveClients) : clientScrollSearch(searchId, onReceiveClients);
    }
  }

  function onReceiveClients(res: ISearchData<IClient>) {
    if (!res) return;
    const { data, toReplace } = res;
    if (searchState.text) {
      EventTrack.track('workspace_clients_search', { workspace_id: catalogId, search: searchState.text });
    }
    if (toReplace) {
      setElasticClients(data.hits);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    } else {
      setElasticClients([...elasticClients, ...data.hits]);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    }
  }
  return (
    <ActionsModal
      minHeight="450px"
      onClose={onClose}
      title={
        title
          ? title
          : isPurchase
          ? __('Components.OrdersList.modal.buy.title')
          : __('Components.OrdersList.modal.sale.title')
      }
      subtitle={
        subtitle
          ? subtitle
          : isPurchase
          ? __('Components.OrdersList.modal.buy.subtitle')
          : __('Components.OrdersList.modal.sale.subtitle')
      }
    >
      <S.Text>
        {__('Components.OrdersList.results', {
          count: totalResults,
        })}
      </S.Text>
      <S.SearchContainer>
        <SimpleSearch
          placeHolder={__('Components.ChatList.search.placeholder')}
          id={'search-client-' + isPurchase}
          onChange={f => setSearchState({ ...searchState, text: utils.toLocaleLowerCaseNormalized(f) })}
        />
      </S.SearchContainer>
      <ContactsList
        contacts={elasticClients.map(sellerWorkspaceService.clientToLightContact)}
        onSelectContact={cId => selectContact(cId, elasticClients.find(c => c.userId === cId)?.name || '', elasticClients.find(c => c.userId === cId)?.buyerWorkspaceId, elasticClients.find(c => c.userId === cId)?.avatar || '')}
        action="radio"
        contactSelected={() => false}
        search={searchState.text}
        showUnregistered={true}
        onScroll={onScroll}
      />
    </ActionsModal>
  );
};

export default React.memo(SelectClientModal);
