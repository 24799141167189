import {
  __,
  addresses,
  constants,
  currency,
  date,
  HOUR_FORMAT,
  ICountry,
  IDeliveryMethodType,
  ILightUser,
  IMessage,
  IOrder,
  IOrderItem,
  IProdType,
  IPublicOrderResponse,
  modalActions,
  orderService,
  parsers,
  productService,
  RenderTrack,
  SCHEDULE,
  utils,
} from 'common-services';
import { getCategoryText, getOrganicText } from 'common-services/dist/parsers';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import config from '../../../../bindings/config';

import { ROUTE_PATHS } from '../../../constants';
import { getDeepLink } from '../../../services/link';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { FontIcon, LettersAvatar } from '../../atoms';
import { Message } from '../../molecules';
import * as S from './OrderPublic.styled';

type IRouteProps = RouteComponentProps<{ hashId: string }>;

export interface IStateProps {
  prodTypes: { [key: string]: IProdType };
  countries: { [key: string]: ICountry };
  hourFormat: HOUR_FORMAT;
}

export interface IDispatchProps {
  orderGetByHash: (orderHash: string, cb?: (data?: IPublicOrderResponse) => void) => void;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps & IDispatchProps & IRouteProps;

interface IState {
  data?: IPublicOrderResponse;
}

/**
 * Order public page
 */
export default class Order extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    const {
      match: {
        params: { hashId },
      },
      orderGetByHash,
    } = this.props;
    orderGetByHash(hashId, data => {
      this.setState({ data });
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { data } = this.state;
    if (prevState.data !== data && data && data.order) {
      const { order } = data;
      RenderTrack.track('OrderPublic', {
        orderId: order.id ? order.id.toString() : '',
        status: order.status,
        price: order.price,
        numItems: order.items.length,
        sellerId: order.sellerId.toString(),
        buyerId: order.buyerId.toString(),
        authorId: order.authorId.toString(),
        renderTime: Date.now(),
      });
    }
  }

  public render() {
    const {
      countries,
      match: {
        params: { hashId },
      },
    } = this.props;
    const { data } = this.state;
    if (!data || !data.order) return null;

    const { buyer, comments, order, seller } = data;

    const orderComments = comments && comments.length ? comments.filter(c => c.messageType === 'text') : [];

    const {
      deliveryEstimatedAt,
      deliveryEstimatedSchedule,
      items,
      pickupEstimatedAt,
      pickupEstimatedSchedule,
      pickupFrom,
      price,
      priceMode,
      pricePrecision,
      sellerId,
      deliverTo,
    } = order;

    const orderPrice = price ? currency.getPrice(order.currency, price, pricePrecision) : '-';
    const showPrice = Number(hashId) !== sellerId || priceMode !== 'none';
    const hasAtLeastOneImage = !!items.find(item => item.images.length && item.images[0].image);

    const estimatedDeliveryDate = deliveryEstimatedAt
      ? utils.capitalizeWords(date.formatLongDate(deliveryEstimatedAt))
      : '';
    const estimatedDeliverySchedule =
      deliveryEstimatedAt && deliveryEstimatedSchedule && deliveryEstimatedSchedule !== SCHEDULE.UNKNOWN
        ? ' · ' + orderService.getScheduleLabel(deliveryEstimatedSchedule).toLocaleLowerCase()
        : '';
    const estimatedPickupDate = pickupEstimatedAt ? utils.capitalizeWords(date.formatLongDate(pickupEstimatedAt)) : '';
    const estimatedPickupSchedule =
      pickupEstimatedAt && pickupEstimatedSchedule && pickupEstimatedSchedule !== SCHEDULE.UNKNOWN
        ? ' · ' + orderService.getScheduleLabel(pickupEstimatedSchedule).toLocaleLowerCase()
        : '';

    const author = seller.name && seller.company ? seller : buyer;

    return (
      <S.Global>
        <S.ContainerColumn style={{ maxWidth: 900 }}>
          <S.Header className="header">
            {!author.logo ? (
              <S.Title>{author.company}</S.Title>
            ) : (
              <S.Logo>
                <S.ImageLogo src={author.logo} />
              </S.Logo>
            )}
            <S.Container className="author">
              <S.Container>
                <S.Info>
                  <S.InfoTitle>{author.name}</S.InfoTitle>
                  <S.InfoSubtitle>{author.company}</S.InfoSubtitle>
                </S.Info>
                <S.Icon>
                  <LettersAvatar
                    size={55}
                    text={author.name}
                    img={author.avatar}
                    avatarColor={utils.getAvatarColor(author.name)}
                  />
                </S.Icon>
              </S.Container>
            </S.Container>
          </S.Header>
          <S.TitleOrderInfo>
            {__('order.info')} <S.TitleGreen>#{hashId}</S.TitleGreen>
          </S.TitleOrderInfo>
          <S.ContainerRow>
            <S.LeftColumn>
              <S.Container>
                <S.SubTitle>{__('order.articlesTitle')}</S.SubTitle>
                <S.SubTitleDivider />
              </S.Container>
              <S.Table>
                <tbody>
                  {this.renderProducts(order, showPrice, hasAtLeastOneImage)}
                  {this.renderPriceDetails(orderPrice, showPrice, hasAtLeastOneImage)}
                </tbody>
              </S.Table>
            </S.LeftColumn>

            <S.RightColumn>
              <S.Container>
                <S.SubTitle>{__('order.infoTitle')}</S.SubTitle>
                <S.SubTitleDivider />
              </S.Container>
              {this.renderOrderInformation(order)}
              {this.hasTransportIncluded(order) ? (
                <React.Fragment>
                  <S.Container>
                    <S.SubTitle>{__('order.transportTitle')}</S.SubTitle>
                    <S.SubTitleDivider />
                  </S.Container>
                  <S.Table>
                    <tbody>
                      {this.renderDeliveryMethod(
                        'pickup',
                        addresses.getAddressName(pickupFrom, countries, false),
                        estimatedPickupDate,
                        estimatedPickupSchedule,
                      )}
                      {this.renderDeliveryMethod(
                        'shipping',
                        addresses.getAddressName(deliverTo, countries, false),
                        estimatedDeliveryDate,
                        estimatedDeliverySchedule,
                      )}
                      {this.renderLogisticDetails(order)}
                    </tbody>
                  </S.Table>
                </React.Fragment>
              ) : null}
              <S.OrderButton
                onClick={() => {
                  window.location.href = getDeepLink(getPath({ path: ROUTE_PATHS.ORDER_LIST, hashId }));
                }}
              >
                {__('order.details')}
              </S.OrderButton>
            </S.RightColumn>
          </S.ContainerRow>
          {orderComments.length ? this.renderComments(orderComments, author) : null}
          {this.renderConsentioFooter(author)}
        </S.ContainerColumn>
      </S.Global>
    );
  }

  /**
   * Checks if transport has been added.
   * If any of these information is set, we consider that transport is added
   */
  private hasTransportIncluded = (order: IOrder) => {
    const { customItems, pickupFrom, deliverTo, incoterms, deliveryEstimatedAt, pickupEstimatedAt } = order;
    const shipping = (customItems || []).find(ci => ci.type === 'shipping');
    return (
      pickupFrom ||
      deliverTo ||
      deliveryEstimatedAt ||
      pickupEstimatedAt ||
      (shipping && shipping.price) ||
      (incoterms.length && incoterms[0])
    );
  };

  private renderPriceDetails(orderPrice: string, showPrice: boolean, hasAtLeastOneImage: boolean) {
    return showPrice && orderPrice ? (
      <S.Tr className="orderPrice">
        {hasAtLeastOneImage && <S.Td />}
        <S.PriceDetailsLeft className="price-details-left">
          <S.Text isBold={true}>{__('order.totalPrice')}</S.Text>
        </S.PriceDetailsLeft>
        <S.PriceDetailsRight className="price-details-right">
          <S.Text isBold={true}>{orderPrice}</S.Text>
        </S.PriceDetailsRight>
      </S.Tr>
    ) : null;
  }

  private renderProducts(order: IOrder, showPrice: boolean, hasAtLeastOneImage: boolean) {
    const { prodTypes } = this.props;
    const { pricePrecision } = order;
    return order.items.map((item, index) => {
      const { id, images, type, title, amount, saleUnit, priceUnit, size, weightUnit } = item as IOrderItem;
      const secondaryInformation = this.getProductSecondaryInformation(item);
      const amountItemDisplay =
        amount + ' ' + (parsers.getUnitText(saleUnit, weightUnit, amount) || '').toLocaleLowerCase();
      const priceItemDisplay =
        showPrice && !item.isPor
          ? item.price.toFixed(pricePrecision) + ' ' + currency.getPricePerUnit(order.currency, priceUnit, weightUnit)
          : '';
      const totalPriceDisplay =
        showPrice && !item.isPor ? currency.getPrice(order.currency, item.totalPrice, pricePrecision) : '';
      const hasImage = images.length && images[0].image;
      return (
        <S.Tr key={index + '-' + order.externalIdSeller || order.externalIdBuyer || order.hashId + '-' + id}>
          {hasAtLeastOneImage ? (
            <S.ImageTd className="product-image">
              {hasImage ? (
                <S.Image
                  alt={'image-product-' + index}
                  src={images[0].image.url.replace('f_auto', 'f_auto,c_thumb,w_68,h_68')}
                />
              ) : null}
            </S.ImageTd>
          ) : null}
          <S.ProductDescription
            hasAtLeastOneImage={hasAtLeastOneImage}
            className={'product-description-' + (hasAtLeastOneImage ? 'with-image' : 'without-image')}
          >
            <S.TextP isBold={true}>
              {productService.getProductTypeVarietyDisplay(type, prodTypes?.[type]?.name || '', title) + ' ' + size}
            </S.TextP>

            <S.TextP color={theme.colors.grey1}>
              {secondaryInformation.map((info, i) => (
                <React.Fragment key={info.title + '-' + i}>
                  {info.title + ': '}
                  {this.renderProductValue(info.value)}
                  {'  '}
                </React.Fragment>
              ))}
            </S.TextP>
            {priceItemDisplay ? (
              <S.TextP color={theme.colors.grey1}>
                {__('order.priceUnit')}
                <S.Text isBold={true}>{priceItemDisplay}</S.Text>
              </S.TextP>
            ) : null}
          </S.ProductDescription>
          <S.ProductPrice className="product-price">
            <S.TextP>{amountItemDisplay}</S.TextP>
            {totalPriceDisplay ? <S.TextP isBold={true}>{totalPriceDisplay}</S.TextP> : null}
          </S.ProductPrice>
        </S.Tr>
      );
    });
  }

  /**
   * Render product secondary info: Category, box type, origin etc.
   */
  private getProductSecondaryInformation(d: IOrderItem): Array<{
    title: string;
    value: string;
  }> {
    const category = getCategoryText(d.category as (typeof constants.PRODUCT_CATEGORIES)[number]);
    const boxType = d.box?.name || '';
    const palletType = d.pallet?.name || '';
    let organic = d.organic ? getOrganicText(d.organic) : '';
    const { packaging, brand, boxWeight } = d;

    if (d.organic) {
      organic = __('Constants.Organic.' + d.organic);
    }
    const listItems = [];
    if (packaging)
      listItems.push({
        title: __('order.product.packaging'),
        value: packaging,
      });
    if (d.origin)
      listItems.push({
        title: __('order.product.origin'),
        value: d.origin,
      });
    if (organic)
      listItems.push({
        title: __('order.product.organic'),
        value: organic,
      });
    if (brand)
      listItems.push({
        title: __('order.product.brand'),
        value: brand,
      });
    if (category)
      listItems.push({
        title: __('order.product.category'),
        value: category,
      });
    if (boxType)
      listItems.push({
        title: __('order.product.box_type'),
        value: boxType || null,
      });
    if (boxWeight)
      listItems.push({
        title: __('order.product.boxWeight'),
        value: boxWeight && boxWeight + ' kg',
      });
    // if (piecesPerBox)
    //   listItems.push({
    //     title: __('order.product.pieces_per_box'),
    //     value: piecesPerBox,
    //   });
    if (palletType)
      listItems.push({
        title: __('order.product.pallet_type'),
        value: palletType,
      });
    // if (boxesPerPallet)
    //   listItems.push({ title: __('order.product.boxesPerPallet'), value: data.boxesPerPallet });
    // if (sku) listItems.push({ title: __('order.product.sku'), value: sku });
    // if (eanCode) listItems.push({ title: __('order.product.eanCode'), value: eanCode });
    return listItems;
  }
  /**
   * Render a text product value. (rounded + background)
   */
  private renderProductValue(value: string) {
    return <S.TextShadow>{value}</S.TextShadow>;
  }

  /**
   * Render order information
   */
  private renderOrderInformation(order: IOrder) {
    const { hourFormat } = this.props;
    return (
      <S.ContainerColumn>
        <S.Text color={theme.colors.grey1}>
          {__('order.creationDate')}
          <S.TextGreen isBold={true}>
            {utils.capitalizeWords(date.formatLongDate(order.createdAt)) +
              ' · ' +
              date.formatTime(order.createdAt, hourFormat)}
          </S.TextGreen>
        </S.Text>
        {order.closedAt ? (
          <S.Text color={theme.colors.grey1}>
            {order.status === 'canceled' ? __('order.closed.canceled') : __('order.closed.accepted')}
            <S.TextGreen isBold={true}>
              {utils.capitalizeWords(date.formatLongDate(order.closedAt)) +
                ' · ' +
                date.formatTime(order.closedAt, hourFormat)}
            </S.TextGreen>
          </S.Text>
        ) : null}

        <S.Text color={theme.colors.grey1}>
          {__('order.total')}
          <S.TextGreen isBold={true}>{orderService.getCartLoadSummaryText(order.items)}</S.TextGreen>
        </S.Text>

        {order.moreInfo ? (
          <S.Text color={theme.colors.grey1}>
            {__('order.moreInfo')}
            {order.moreInfo}
          </S.Text>
        ) : null}
      </S.ContainerColumn>
    );
  }

  /**
   * Render the delivery method with its respective icon / title / address / estimated date
   */
  private renderDeliveryMethod(
    deliveryMethod: IDeliveryMethodType,
    address: string,
    estimatedDate: string,
    estimatedSchedule: string,
  ) {
    if (!address && !estimatedDate) return null;
    return (
      <S.Tr>
        <S.IconTd>
          <S.FontIconWrapper>
            <FontIcon name={deliveryMethod === 'pickup' ? 'Pickup-package' : 'Pickup-truck'} disableHover={true} />
          </S.FontIconWrapper>
        </S.IconTd>
        <S.DescriptionTd>
          <S.TextTitle>
            {deliveryMethod === 'pickup'
              ? __('Components.OrderDetails.transport.pickup')
              : __('Components.OrderDetails.transport.shipping')}
          </S.TextTitle>
          {address && <S.TextSmall lineHeight="1.5">{address}</S.TextSmall>}
          {estimatedDate && (
            <S.TextSmall lineHeight="1.5">
              {__('Components.OrderDetails.transport.estimated_date') + estimatedDate + estimatedSchedule}
            </S.TextSmall>
          )}
        </S.DescriptionTd>
      </S.Tr>
    );
  }

  /**
   * Render logistic details (load / cost / incoterms)
   */
  private renderLogisticDetails(order: IOrder) {
    const { incoterms, customItems } = order;
    const shipping = (customItems || []).find(ci => ci.type === 'shipping');
    const shippingCost = (shipping && shipping.price) || 0;

    return (
      <S.Tr>
        <S.Td colSpan={2}>
          {shippingCost > 0 ? (
            <S.TextGreyRegular>
              {__('Components.OrderDetails.transport.transport_cost')}
              <S.TextBold display="inline">{currency.getPrice(order.currency, shippingCost)}</S.TextBold>
            </S.TextGreyRegular>
          ) : null}
          {incoterms.length ? (
            <S.TextGreyRegular>
              {__('Components.OrderDetails.transport.incoterms')}
              <S.TextBold display="inline" textTransform="uppercase">
                {incoterms.join(', ')}
              </S.TextBold>
            </S.TextGreyRegular>
          ) : null}
        </S.Td>
      </S.Tr>
    );
  }

  private renderConsentioFooter(author: ILightUser) {
    return (
      <S.Consentio>
        <S.Container>
          <S.ConsentioLogo />
          <S.Text>{__('Pricelist.consentio', { name: author.company })}</S.Text>
        </S.Container>
        <S.ContainerStores>
          <S.Stores
            onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=com.consentio&hl=es_ES');
            }}
          >
            <S.FontIconWrapperStore>
              <FontIcon name={'Playstore'} />
            </S.FontIconWrapperStore>
            <S.StoresText>{__('Pricelist.for_android')}</S.StoresText>
          </S.Stores>
          <S.Stores
            onClick={() => {
              window.open('https://itunes.apple.com/us/app/consentio/id1347062712?mt=8');
            }}
          >
            <S.FontIconWrapperStore>
              <FontIcon name="Apple" />
            </S.FontIconWrapperStore>
            <S.StoresText>{__('Pricelist.for_ios')}</S.StoresText>
          </S.Stores>
        </S.ContainerStores>
      </S.Consentio>
    );
  }

  /**
   * render order comments
   */
  private renderComments(comments: Array<IMessage>, author: ILightUser) {
    const { history, touchImage } = this.props;
    const avatarColor = utils.getAvatarColor(author.name);
    return (
      <S.Container>
        <S.CommentsContainer>
          <S.Container>
            <S.SubTitle>{__('Components.Order.comments')}</S.SubTitle>
            <S.SubTitleDivider />
          </S.Container>
          {comments
            ? comments.map((m, i, list) => (
                <React.Fragment key={m.messageId + m.message + i}>
                  {(!i || differenceInCalendarDays(m.createdAt, list[i - 1].createdAt) !== 0) && (
                    <Message
                      history={history}
                      isDate={true}
                      key={m.messageId + m.createdAt}
                      message={m}
                      senderAvatar={author.avatar}
                      senderAvatarColor={avatarColor}
                      senderName={author.name}
                    />
                  )}
                  <Message
                    history={history}
                    key={m.messageId + m.message}
                    senderAvatar={author.avatar}
                    senderAvatarColor={avatarColor}
                    senderName={author.name}
                    message={m}
                    navigateToShowroom={() => null}
                    touchImage={touchImage}
                  />
                </React.Fragment>
              ))
            : null}
        </S.CommentsContainer>
      </S.Container>
    );
  }
}
