import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer/FlexContainer.styled';
import TextLabel from '../TextLabel/TextLabel.styled';

interface IStyledProps {
  height?: number;
  progress?: number;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: auto;
`;

export const Row = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ProgressContainer = styled.div<IStyledProps>`
  height: ${(p: IStyledProps) => p.height}px;
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  position: relative;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const ProgressBar = styled.div<IStyledProps>`
  height: ${(p: IStyledProps) => p.height}px;
  border-radius: 10px;
  width: ${(p: IStyledProps) => p.progress || 0}%;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: width 0.1s ease-in-out;

  ${(p: IStyledProps) =>
    p.progress > 0 &&
    p.progress < 100 &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => (p.progress ? p.theme.colors.blue1 : p.theme.colors.grey2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;
