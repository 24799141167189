import {
  __,
  constants,
  currency as currencyServices,
  CURRENCY_CODES,
  debounce,
  ORDER_STATUS,
  orderService,
} from 'common-services';
import * as React from 'react';

import { getDefaultColumns } from '../../../../../constants';
import { Input, RowContainer, Select, TextArea } from '../../../../atoms';
import { FormModal, Table } from '../../../../molecules';
import * as S from './CustomItems.styled';

interface IProps {
  canEdit: boolean;
  customItemDelete: (item: ICustomItem, index: number) => void;
  customItems: Array<ICustomItem>;
  customItemUpdate: (item: ICustomItem, index: number) => void;
  editAfterAccept: boolean;
  me: IUser;
  onModifyAfterAccept: () => void;
  order: IOrder;
  priceMode: IPriceMode;
}

interface IState {
  customItemIndex?: number;
  showCustomLineModal: boolean;
}

export default class CustomItems extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { showCustomLineModal: false };
  }
  public render() {
    const { canEdit, customItems, order, editAfterAccept, onModifyAfterAccept } = this.props;
    const { customItemIndex, showCustomLineModal } = this.state;
    const { pricePrecision, currency } = order;
    const onClick = () => {
      this.setState({ showCustomLineModal: true, customItemIndex: undefined });
      if (!editAfterAccept && order.status === ORDER_STATUS.ACCEPTED) {
        onModifyAfterAccept();
      }
    };

    return (
      <>
        {canEdit ? (
          <S.ActionLink id="cta-add-custom-line" onClick={onClick} type="link" iconName="Add-more" iconSize="18px">
            {__('Components.Cart.custom_items.add_line')}
          </S.ActionLink>
        ) : null}
        {this.renderTable()}
        {showCustomLineModal ? (
          <AddCustomLineModal
            currency={currency}
            customItem={customItemIndex >= 0 ? customItems[customItemIndex] : undefined}
            index={customItemIndex}
            onClose={() => this.setState({ customItemIndex: undefined, showCustomLineModal: false })}
            onSubmit={this.onSubmitCustomItem}
            order={order}
            pricePrecision={pricePrecision}
          />
        ) : null}
      </>
    );
  }

  private renderTable() {
    const { customItems, customItemDelete, order, priceMode } = this.props;
    if (!customItems.length) return null;
    const { pricePrecision, currency, price } = order;
    return (
      <>
        <Table
          className="custom-items-table"
          values={customItems}
          emptyText={''}
          columns={this.getColumns()}
          onClickRow={() => null}
          selectable={false}
          productColumns={getDefaultColumns()}
        />
        {priceMode !== 'none' && price ? (
          <S.FooterRow>
            <S.TextGrey1 textAlign="right">{__('Components.Cart.custom_items.total')}</S.TextGrey1>
            <S.ValueSpan>
              <S.TextPrice>{currencyServices.getPrice(currency, price, pricePrecision)}</S.TextPrice>
            </S.ValueSpan>
          </S.FooterRow>
        ) : null}
      </>
    );
  }

  private getColumns() {
    const { canEdit, customItems, customItemDelete, order, priceMode } = this.props;
    if (!customItems.length) return null;
    const { pricePrecision, currency, price } = order;

    return [
      ...(canEdit
        ? [
            {
              title: '',
              id: 'close',
              width: '30px',
              element: (data: ICustomItem, index: number) => {
                return (
                  <S.FlexRow>
                    <S.DeleteIcon name="Close" onClick={() => customItemDelete(data, index)} />
                  </S.FlexRow>
                );
              },
            },
          ]
        : []),
      {
        title: __('Components.Cart.custom_items.description'),
        id: 'description',
        width: '80%',
        element: (data: ICustomItem) => {
          return (
            <S.FlexRow>
              <S.Text>{data.title}</S.Text>
            </S.FlexRow>
          );
        },
      },
      {
        title: __('Components.Cart.custom_items.value'),
        id: 'cost',
        width: '20%',
        align: 'center',

        element: (data: ICustomItem, index: number) => {
          return (
            <S.ValueRow>
              <S.Text>
                {data.price && priceMode !== 'none'
                  ? currencyServices.getPrice(currency, data.price, pricePrecision)
                  : '-'}
              </S.Text>
              {canEdit ? (
                <S.EditContainer>
                  <S.EditIcon
                    name="Edit"
                    disableHover={true}
                    onClick={() => this.setState({ showCustomLineModal: true, customItemIndex: index })}
                  />
                </S.EditContainer>
              ) : null}
            </S.ValueRow>
          );
        },
      },
    ];
  }

  /**
   * On submit a custom item
   */
  private onSubmitCustomItem = (description: string, cost: number, index: number) => {
    const { customItemIndex } = this.state;
    const { customItems, customItemUpdate, order, editAfterAccept, onModifyAfterAccept } = this.props;

    let newCustomItem: ICustomItem;
    if (customItemIndex >= 0 && customItems[customItemIndex]) {
      newCustomItem = { ...customItems[customItemIndex] };
      newCustomItem.title = description;
      newCustomItem.price = cost;
    } else {
      newCustomItem = { orderId: order.id, title: description, price: cost, type: 'custom' };
    }
    customItemUpdate(newCustomItem, index);
    this.setState({ showCustomLineModal: false, customItemIndex: undefined });

    if (!editAfterAccept && order.status === ORDER_STATUS.ACCEPTED) {
      onModifyAfterAccept();
    }
  };
}

interface IModalProps {
  currency: CURRENCY_CODES;
  customItem?: ICustomItem;
  index: number;
  onClose: () => void;
  onSubmit: (description: string, cost: number, index: number) => void;
  order: IOrder;
  pricePrecision: number;
}

const AddCustomLineModal = ({ currency, customItem, index, onClose, onSubmit, order, pricePrecision }: IModalProps) => {
  const [cost, setCost] = React.useState(customItem ? Math.abs(customItem.price) : undefined);
  const [isDiscount, setIsDiscount] = React.useState(customItem ? customItem.price < 0 : true);
  const [description, setDescription] = React.useState(customItem ? customItem.title : '');

  let maxDiscountValue = orderService.getTotalPrice(order) - 0.01;
  if (isDiscount && customItem) {
    maxDiscountValue -= customItem.price;
  }

  const setCostWithCheck = desiredCost => {
    let costToSet = desiredCost;
    if (!costToSet) {
      costToSet = 0.01;
    }
    if (isDiscount && costToSet > maxDiscountValue) {
      costToSet = maxDiscountValue;
    }
    setCost(costToSet);
  };

  return (
    <FormModal
      className="custom-line-form-modal"
      ctaText={customItem ? __('AddCustomLineModal.cta_edit') : __('AddCustomLineModal.cta_add')}
      ctaDisabled={!description || !cost || Number.isNaN(cost) || (isDiscount && cost > maxDiscountValue)}
      onClose={onClose}
      onSubmit={() => onSubmit(description, (isDiscount ? -1 : 1) * cost, index)}
      subtitle={customItem ? undefined : __('AddCustomLineModal.subtitle')}
      title={customItem ? __('AddCustomLineModal.title_edit') : __('AddCustomLineModal.title_add')}
    >
      <S.FormContainer>
        <S.InputWithLabel label={__('AddCustomLineModal.description')} isRequired={true}>
          <TextArea
            maxLength={80}
            name="custom-item-description"
            value={description}
            placeholder={__('AddCustomLineModal.description_placeholder')}
            onChange={(name, val) => setDescription(val + '')}
            width="90%"
            rows={5}
          />
        </S.InputWithLabel>
        <S.InputWithLabel label={__('AddCustomLineModal.cost')} isRequired={true}>
          <RowContainer>
            <Select
              name="custom-item-cost-type"
              value={isDiscount ? 'discount' : 'markup'}
              onChange={(name, val) => setIsDiscount(val === 'discount')}
              options={[
                { value: 'discount', label: __('AddCustomLineModal.cost_options.discount') },
                { value: 'markup', label: __('AddCustomLineModal.cost_options.markup') },
              ]}
              containerMargin="0 12px 0 0"
              width="150px"
            />

            <Input
              name="custom-item-cost"
              type="number"
              onChange={debounce((_, value) => {
                setCostWithCheck(value ? Number(value) : 0);
              }, 1500)}
              value={cost}
              minValue={0.01}
              maxValue={isDiscount ? maxDiscountValue : undefined}
              variableTextSingular={constants.CURRENCIES[currency]?.symbol || '€'}
              precision={pricePrecision}
              startText={isDiscount ? '-' : '+'}
              containerMargin="0"
              width="100px"
              onBlur={(_, value) => setCostWithCheck(!Number.isNaN(value) ? Number(value) : 0.01)}
            />
          </RowContainer>
        </S.InputWithLabel>
      </S.FormContainer>
    </FormModal>
  );
};
