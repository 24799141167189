import * as React from 'react';
import * as S from './Skeleton.styled';

export interface IProps {
  borderRadius?: string;
  className?: string;
  height?: string;
  margin?: string;
  width?: string;
  widthFixed?: boolean;
}

const Skeleton: React.FC<IProps> = React.memo(
  ({ borderRadius, className, height = '36px', margin, width = '36px', widthFixed = false }) => {
    return (
      <S.SkeletonItem
        borderRadius={borderRadius}
        className={className}
        height={height}
        margin={margin}
        width={width}
        widthFixed={widthFixed}
      />
    );
  },
);

export default Skeleton;
