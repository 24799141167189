import { Loader } from '@googlemaps/js-api-loader';
import { __, constants, currency, CURRENCY_CODES, PRODUCT_UNIT, TRANSPORT_UNIT, WEIGHT_UNIT } from 'common-services';
import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import PlacesAutocomplete, { geocodeByPlaceId, Suggestion } from 'react-places-autocomplete';

import config from '../../../../bindings/config';
import { Input, Select, SuggestionsDropdown } from '../../atoms';
import InputWithLabel from '../InputWithLabel/InputWithLabel.component';
import * as S from './AddressAutoComplete.styled';
import { IMAGES } from '../../../assets';
import theme from '../../../theme';
import { getCountriesSelect } from '../../../constants';

export interface IProps {
  address?: IAddress;
  countries: { [key: string]: ICountry };
  deliveryMethod?: 'shipping' | 'pickup';
  isDefault?: boolean;
  me: IUser;
  onCancel: () => void;
  onSubmit: (address: IAddress) => void;
  tradeCurrency?: CURRENCY_CODES;
  weightUnit: WEIGHT_UNIT;
}

const renderSuggestion = (getSuggestionItemProps, suggestion) => {
  const {
    placeId,
    formattedSuggestion: { mainText, secondaryText },
  } = suggestion;
  const itemProps = getSuggestionItemProps(suggestion);

  return {
    key: placeId,
    value: mainText,
    value2: secondaryText,
    icon: 'Address',
    itemMinWidth: '100%',
    customProps: itemProps,
  };
};

const renderSuggestions = ({ getInputProps, suggestions, getSuggestionItemProps }, revokeAddress, errors) => {
  const renderedSuggestions = suggestions.map(suggestion => renderSuggestion(getSuggestionItemProps, suggestion));
  const dropdownProps = {
    children: getAutoCompleteInput(
      getInputProps,
      {
        placeholder: __('Components.AddressAutoComplete.address_placeholder'),
        className: 'location-search-input',
      },
      revokeAddress,
      errors,
    ),
    options: renderedSuggestions,
    closeOnClick: true,
    width: '100%',
  };

  return <SuggestionsDropdown {...dropdownProps} />;
};

const getAutoCompleteInput = (getInputProps, props, revokeAddress, errors) => {
  const { onChange, ...rest } = getInputProps(props);
  const onChangeAutoComplete = event => {
    revokeAddress();
    onChange(event);
  };
  const inputProps = {
    ...rest,
    hasError: !!errors.get('autocompleteInput'),
    errorMessage: (
      <>
        <S.ErrorIcon name="Error" disableHover={true} />
        {__('Components.AddressAutoComplete.error_autocomplete')}
      </>
    ),
    onChangeAutoComplete,
  };

  return <Input {...inputProps} />;
};

const renderMap = mapCenter => {
  if (!mapCenter) {
    return null;
  }

  return (
    <S.MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.GOOGLE_PLACES, libraries: ['places'], id: '__googleMapsScriptId' }}
        center={mapCenter}
        defaultZoom={11}
        options={{
          fullscreenControl: false,
        }}
      >
        <Marker lat={mapCenter.lat} lng={mapCenter.lng} />
      </GoogleMapReact>
    </S.MapContainer>
  );
};

/**
 * Form to add a new address
 */
const AddressAutoComplete: React.FC<IProps> = ({
  address,
  countries,
  deliveryMethod,
  isDefault,
  onCancel,
  onSubmit,
  tradeCurrency,
  weightUnit,
}) => {
  const [newAddress, setNewAddress] = React.useState<IAddress>(address || getInitialAddress(deliveryMethod));
  const [mapCenter, setMapCenter] = React.useState<{ lng: number; lat: number } | undefined>();
  const [apiLoaded, setAPILoaded] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<Map<string, string>>(new Map());

  React.useEffect(() => {
    loadAPI();
    setNewAddress(address || getInitialAddress(deliveryMethod));
  }, [deliveryMethod]);

  React.useEffect(() => {
    async function fetchMapCenter() {
      if (newAddress.latitude && newAddress.longitude) {
        setMapCenter({ lat: newAddress.latitude, lng: newAddress.longitude });
      } else if (apiLoaded && newAddress.placeId) {
        const [geocode] = await geocodeByPlaceId(newAddress.placeId);
        const {
          geometry: {
            location: { lat, lng },
          },
        } = geocode;
        setMapCenter({ lat: lat(), lng: lng() });
      }
    }
    fetchMapCenter();
  }, [apiLoaded, newAddress]);

  React.useEffect(() => {
    setCountriesSelect(getCountriesSelect(countries, c => c.name));
  }, [countries]);

  const [addressAutoComplete, setAddressAutoComplete] = React.useState<string>(getAddressDisplay(countries, address));
  const [countriesSelect, setCountriesSelect] = React.useState<Array<{ label: string; value: string }>>();
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const {
    additionalInfo,
    city,
    country,
    displayName,
    externalId,
    latitude,
    longitude,
    rest,
    transportCost,
    transportCostUnit,
    zip,
  } = newAddress;

  const revokeAddress = React.useCallback(() => {
    setNewAddress({ ...newAddress, placeId: '', latitude: 0, longitude: 0, city: '', zip: '', rest: '', country: '' });
    setMapCenter(undefined);
  }, [newAddress]);

  const renderPlaceAutoComplete = () => {
    if (!apiLoaded) {
      return null;
    }

    return (
      <S.PlacesContainer>
        <PlacesAutocomplete
          value={addressAutoComplete}
          onChange={props => {
            setAddressAutoComplete(props);
          }}
          onSelect={(selectedAddress, placeId, suggestion) => {
            handleSelectAutocomplete(selectedAddress, placeId, suggestion);
          }}
        >
          {props => renderSuggestions(props, revokeAddress, errors)}
        </PlacesAutocomplete>
      </S.PlacesContainer>
    );
  };

  const editMode = !!address;
  return (
    <>
      <S.FormContainer>
        <S.CardItem>
          <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.display_name')}>
            <Input
              name="displayName"
              type="text"
              placeholder={__('Components.AddressAutoComplete.display_name_placeholder')}
              value={displayName}
              isRequired={true}
              onChange={() => setHasChanges(true)}
              onBlur={(name, value) => {
                setError(name, value ? '' : 'empty');
                updateNewAddress(name, value || '');
              }}
              hasError={!!errors.get('displayName')}
            />
          </InputWithLabel>

          <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.address')}>
            {renderPlaceAutoComplete()}
          </InputWithLabel>

          <S.ExpansionPanel
            color={theme.colors.blue1}
            contentWithLeftPadding={false}
            defaultExpanded={false}
            iconPosition="left"
            renderTitle={title => <S.ExpansionTitle>{title}</S.ExpansionTitle>}
            title={__('Components.AddressAutoComplete.view_all_fields')}
            titleContainerStyle={{ paddingLeft: 0, paddingTop: 0, padding: 0, paddingBottom: theme.paddingSize(2) }}
          >
            <S.FormRow>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.rest')} width="48.5%">
                <Input
                  name="rest"
                  type="text"
                  placeholder={__('Components.AddressAutoComplete.rest_placeholder')}
                  value={rest}
                  isRequired={true}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, value || '');
                  }}
                  maxLength={150}
                />
              </InputWithLabel>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.zip_code')} width="48.5%">
                <Input
                  name="zip"
                  type="text"
                  placeholder={__('Components.AddressAutoComplete.zip_code')}
                  maxLength={20}
                  value={zip}
                  isRequired={true}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, value || '');
                  }}
                />
              </InputWithLabel>
            </S.FormRow>

            <S.FormRow>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.city')} width="48.5%">
                <Input
                  name="city"
                  type="text"
                  placeholder={__('Components.AddressAutoComplete.city_placeholder')}
                  value={city}
                  isRequired={true}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, value || '');
                  }}
                />
              </InputWithLabel>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.country')} width="48.5%">
                <Select
                  containerMargin="0"
                  width="100%"
                  name="country"
                  onChange={(name, val) => {
                    updateNewAddress(name, val);
                  }}
                  options={countriesSelect}
                  placeholder={__('Components.AddressAutoComplete.country_placeholder')}
                  value={country}
                />
              </InputWithLabel>
            </S.FormRow>

            <S.FormRow>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.latitude')} width="48.5%">
                <Input
                  name="latitude"
                  type="number"
                  placeholder={__('Components.AddressAutoComplete.latlong_placeholder')}
                  value={latitude}
                  minValue={-90}
                  maxValue={90}
                  isRequired={true}
                  precision={7}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, value || '');
                  }}
                />
              </InputWithLabel>
              <InputWithLabel isRequired={true} label={__('Components.AddressAutoComplete.latitude')} width="48.5%">
                <Input
                  name="longitude"
                  type="number"
                  placeholder={__('Components.AddressAutoComplete.latlong_placeholder')}
                  value={longitude}
                  minValue={-180}
                  maxValue={180}
                  isRequired={true}
                  precision={7}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, value || '');
                  }}
                />
              </InputWithLabel>
            </S.FormRow>
          </S.ExpansionPanel>

          {renderMap(mapCenter)}

          <InputWithLabel isRequired={false} label={__('Components.AddressAutoComplete.address_additional_info')}>
            <Input
              name="additionalInfo"
              type="text"
              placeholder={__('Components.AddressAutoComplete.address_additional_info_placeholder')}
              value={additionalInfo}
              onChange={() => setHasChanges(true)}
              onBlur={(name, value) => {
                updateNewAddress(name, value || '');
              }}
              maxLength={64}
            />
          </InputWithLabel>

          <InputWithLabel isRequired={false} label={__('Components.AddressAutoComplete.external_id')}>
            <Input
              name="externalId"
              type="text"
              placeholder={__('Components.AddressAutoComplete.external_id_placeholder')}
              value={externalId}
              onChange={() => setHasChanges(true)}
              onBlur={(name, value) => {
                updateNewAddress(name, value || '');
              }}
            />
          </InputWithLabel>
          {tradeCurrency ? (
            <InputWithLabel isRequired={false} label={__('Components.AddressAutoComplete.transport_cost')}>
              <S.CardInputs>
                <Input
                  name="transportCost"
                  type="number"
                  value={transportCost || 0}
                  onChange={() => setHasChanges(true)}
                  onBlur={(name, value) => {
                    updateNewAddress(name, Number(value) || 0);
                  }}
                  precision={2}
                  variableTextSingular={constants.CURRENCIES[tradeCurrency]?.symbol || ''}
                  variableTextPlural={constants.CURRENCIES[tradeCurrency]?.symbol || ''}
                  width="50%"
                />

                <Select
                  name="transportCostUnit"
                  value={transportCostUnit}
                  onChange={(name, val) => updateNewAddress(name, val)}
                  options={getTransportCostUnits(tradeCurrency, weightUnit)}
                  width="50%"
                  containerMargin="0 0 0 12px"
                />
              </S.CardInputs>
            </InputWithLabel>
          ) : null}
        </S.CardItem>
      </S.FormContainer>
      <S.ButtonsContainer>
        <S.CancelButton id="cancelButton" onClick={onCancel} type="secondary">
          {__('Components.AddressAutoComplete.cancel')}
        </S.CancelButton>
        <S.ActionButton
          id="submitButton"
          disabled={errors.size > 0 || !hasChanges}
          onClick={onSubmitClick}
          margin="0"
          type="principal"
        >
          {editMode ? __('Components.AddressAutoComplete.save') : __('Components.AddressAutoComplete.add_address')}
        </S.ActionButton>
      </S.ButtonsContainer>
    </>
  );

  function updateNewAddress(name: string, value: string | number) {
    setHasChanges(true);
    const addressUpdated = { ...newAddress, [name]: value };
    setNewAddress(addressUpdated);
    if (['country', 'rest', 'zip', 'city'].includes(name)) {
      setAddressAutoComplete(getAddressDisplay(countries, addressUpdated));
    }
  }

  async function handleSelectAutocomplete(selectAddress: string, placeId: string, suggestion: Suggestion) {
    const indexByType = (components: Array<google.maps.GeocoderAddressComponent>) =>
      components.reduce((acc, component) => {
        if (!component.types) return acc;
        for (const t of component.types) {
          if (acc[t] === undefined) {
            acc[t] = [];
          }
          acc[t].push(component);
        }
        return acc;
      }, {});

    if (!suggestion) {
      return;
    }

    const {
      formattedSuggestion: { mainText },
    } = suggestion;
    const [geocodeFromSuggestion] = await geocodeByPlaceId(placeId);

    const componentByType: { [key: string]: google.maps.GeocoderAddressComponent } = indexByType(
      geocodeFromSuggestion.address_components,
    );

    // These are the different name for a city:
    // * The default is locality
    // * UK and Sweden use postal_town
    // * Finland uses administrative_area_level_3
    const { long_name: locality = '' } = componentByType.locality?.[0] || { long_name: '' };
    const { long_name: administrativeAreaLevel3 = '' } = componentByType.administrative_area_level_3?.[0] || {
      long_name: '',
    };
    const { long_name: postalTown = '' } = componentByType.postal_town?.[0] || { long_name: '' };
    let cityVal = (locality as string) || (postalTown as string) || (administrativeAreaLevel3 as string);

    let { long_name: zipVal = '' } = componentByType.postal_code?.[0] || { long_name: '' };
    const { short_name: countryIso = '' } = componentByType.country?.[0] || { short_name: '' };
    const countryVal = Object.values(countries).find((c: ICountry) => c.iso2Code === countryIso);

    const {
      geometry: {
        location: { lat, lng },
      },
    } = geocodeFromSuggestion;
    const latitudeVal = lat();
    const longitudeVal = lng();

    if (zipVal === '' || cityVal === '') {
      // We need to query Google Maps and try to recover the zip
      const geocodes = await getGeocodeByLatLng(latitudeVal, longitudeVal);
      const getFirstNonEmptyComponent = type => {
        for (const geocodeByLatLng of geocodes) {
          const geocodeByType = indexByType(geocodeByLatLng.address_components);
          if (geocodeByType[type] && geocodeByType[type][0]) {
            return geocodeByType[type][0].long_name;
          }
        }
      };

      if (zipVal === '') {
        zipVal = getFirstNonEmptyComponent('postal_code');
      }

      // Still because of this UK / Sweden / Finland shenanighans
      if (cityVal === '') {
        cityVal = getFirstNonEmptyComponent('locality');
      }
      if (cityVal === '') {
        cityVal = getFirstNonEmptyComponent('postal_town');
      }
      if (cityVal === '') {
        cityVal = getFirstNonEmptyComponent('administrative_area_level_3');
      }
    }

    setAddressAutoComplete(selectAddress);
    setErrors(new Map());
    setHasChanges(true);
    setNewAddress({
      ...newAddress,
      country: countryVal.id,
      rest: mainText,
      zip: zipVal,
      city: cityVal,
      placeId,
      latitude: latitudeVal,
      longitude: longitudeVal,
      isDefault,
    });
  }

  /**
   * Validate fields and submit new address
   */
  function onSubmitClick() {
    if (errors.size > 0) return;

    const validationErrors = validateAddressForm(newAddress);
    setErrors(validationErrors);

    if (validationErrors.size === 0) {
      onSubmit(newAddress);
    }
  }

  /**
   * Set error for a particular field represented by key
   */
  function setError(key: string, error?: string) {
    const cloneErrors = new Map(errors);
    if (error) {
      cloneErrors.set(key, error);
    } else {
      cloneErrors.delete(key);
    }
    setErrors(cloneErrors);
  }

  /**
   * Loads dynamically google places api
   *
   * Check if api has been previously loaded (since it's called on every mount)
   */
  function loadAPI() {
    if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
      setAPILoaded(true);
      return;
    }

    const loader = new Loader({
      apiKey: config.GOOGLE_PLACES,
      id: '__googleMapsScriptId',
      libraries: ['places'],
    });
    loader.load().then(() => setAPILoaded(true));
  }
};

/**
 * Get address display string
 */
function getAddressDisplay(countries: Record<string, ICountry>, address?: IAddress) {
  let result = '';
  if (address) {
    const countryLiteral = countries[address.country]?.name;
    result = [address.rest, address.zip, address.city, countryLiteral].filter(e => e).join(', ');
  }
  return result;
}

/**
 * Get initial address object.
 */
function getInitialAddress(dm: 'shipping' | 'pickup'): IAddress {
  return {
    country: '',
    zip: '',
    rest: '',
    city: '',
    displayName: '',
    type: dm,
  };
}

/**
 * Validate Address form and set errors if needed.
 * TODO: improve validation for transport cost unit
 */
function validateAddressForm(address: IAddress) {
  const errorsResult = new Map();

  if (!address.displayName) {
    errorsResult.set('displayName', 'empty');
  }

  return errorsResult;
}

/**
 * Get transport cost units for select component
 */
function getTransportCostUnits(
  tradeCurrency: CURRENCY_CODES,
  weightUnit: WEIGHT_UNIT,
): Array<{ value: string; label: string }> {
  return [
    { value: TRANSPORT_UNIT.KG, label: currency.getPricePerUnit(tradeCurrency, PRODUCT_UNIT.KG, weightUnit) },
    {
      value: TRANSPORT_UNIT.PALLET,
      label: currency.getPricePerUnit(tradeCurrency, PRODUCT_UNIT.PALLET, weightUnit),
    },
  ];
}

/**
 * Given a latitude and longitude, return a promise of geocodes corresponding to these values.
 */
const getGeocodeByLatLng = (latitude: number, longitude: number): Promise<Array<google.maps.GeocoderResult>> => {
  const maps = window.google.maps as typeof google.maps;
  const geocoder = new maps.Geocoder();
  const OK = maps.GeocoderStatus.OK;
  const latlng = new maps.LatLng(latitude, longitude);

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

const Marker = React.memo(({ lat, lng }: { lat: number; lng: number }) => <S.MarkerImage src={IMAGES.placeMarker} />);

export default React.memo(AddressAutoComplete);
