import { Api, orderActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import OrderAggregation, { IDispatchProps, IRouteProps, IStateProps } from '../components/organisms/OrderAggregation';
import { IReduxState } from '../reducers';
import { navSelectors } from '../selectors';

function mapStateToProps(state: IReduxState, props: IRouteProps): IStateProps {
  const {
    catalog: { catalogs },
    contact: { inConsentio },
    order: { orders },
    user: { user },
  } = state;

  return {
    contacts: inConsentio,
    me: user,
    orders,
    publicUrl: catalogs[user.sellerWorkspaceId!]?.priceListUrl,
    workspaceSelected: navSelectors.getSelectedWorkspace(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      orderGetByHash: orderActions.orderGetByHash,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAggregation);
