
import * as React from 'react';
import Workspace from '../Workspace/Workspace.component';
import history from '../../../history';
import getPath from '../../../util/routes';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../../reducers';
import { ROUTE_PATHS } from '../../../constants';
import { __ } from 'common-services';
import CostImportComponent from './CostImportComponent/CostImport.component';

const WorkspaceCostImport: React.FC = () => {
  const workspaceSelected = useSelector((state: IReduxState) => state.nav.workspaceSelected);

  return (
    <Workspace
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.products'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                workspaceId: workspaceSelected.toString(),
              }),
            );
          },
        },
      ]}
      subtitle=""
      title={__('Components.ProductsList.costImport')}
      tabSelected={'products'}
      workspaceId={Number(workspaceSelected)}
    >
      <CostImportComponent/>
    </Workspace>
  );
};

export default WorkspaceCostImport;