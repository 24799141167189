import styled, { ITheme } from '../../../styled-components';
import { CheckBox as CB, TextLabel } from '../../atoms';

interface IStyledProps {
  checked?: boolean;
  overlay?: boolean;
  selectable?: boolean;
  theme: ITheme;
}

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextName = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 40px;
`;

export const CheckBox = styled(CB)`
  margin: 0 auto;
`;
