import {
  Api,
  chatActions,
  contactActions,
  modalActions,
  notificationsActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { supportAction } from '../actions/user';
import ContactInfo, { DispatchProps, OwnProps, StateProps } from '../components/organisms/ContactInfo';
import * as navActions from '../actions/nav';
import { IReduxState } from '../reducers';

const emptyObject = {};
function mapStateToProps(state: IReduxState, props: OwnProps): StateProps {
  const {
    chat: { channels },
    catalog: { catalogs, catalogsByClient },
    user: { user },
    contact: { inConsentio },
  } = state;
  return {
    channel: channels[props.match.params.channelId],
    catalogs: props.contact && catalogsByClient[props.contact.id] ? catalogsByClient[props.contact.id] : [],
    contacts: inConsentio,
    me: user,
    workspaces: catalogs || emptyObject,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      catalogsByClientGet: sellerWorkspaceActions.catalogsByClientGet,
      channelArchive: chatActions.channelArchive,
      channelMute: chatActions.channelMute,
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      contactBlock: contactActions.contactBlock,
      contactUpdate: contactActions.contactUpdate,
      contactUpdateMySellerWorkspace: contactActions.contactUpdateMySellerWorkspace,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      supportAction,
      navigateChannelBySection: navActions.navigateChannelBySection,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
