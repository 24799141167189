import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'redux';

import { __, CHANNEL_TYPE, chatActions, chatSelectors, qs, RenderTrack } from 'common-services';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import { ShowroomSell } from '../../../screens';
import Chat from '../../../screens/chat';
import OrdersList from '../../../screens/orders-list';
import ShowroomBuy from '../../../screens/showroom-buy';
import getPath from '../../../util/routes';
import { Ribbon } from '../../atoms';

import Workspace from '../Workspace/Workspace.component';

export type IRouteProps = RouteComponentProps<{
  hashId?: string;
  workspaceId: string;
  contactId?: string;
  clientId?: string;
  supplierId?: string;
  isRepeatedOrder?: string;
}>;

export interface IStateProps {
  asSeller: boolean;
  catalogs: { [id: number]: IWorkspace };
  contacts: { [cId: number]: IContact };
  filters: IOrderFilters;
  me: IUser;
  onlyUnreads?: boolean;
  orders: Array<IOrder>;
  orderUnreads?: number;
  searchId: string;
  suppliers: { [id: number]: Array<ISupplier> };
  type?: IOrderType;
  workspaces: { [id: number]: IWorkspace };
  totalResults: number;
}

type IProps = IStateProps & IRouteProps & { t?: number };

/**
 * Order list dashboard with widgets
 */
const WorkspaceOrders: React.FC<IProps> = ({
  type,
  match: { params },
  contacts,
  history,
  match,
  me,
  location,
  filters,
  onlyUnreads,
  catalogs,
  asSeller,
  orderUnreads,
  totalResults,
  workspaces,
  orders,
  t = Date.now(),
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const initialFilters = getInitialFilters();
  const isUnselect = initialFilters.isUnselect;
  const orderSelectedIds = initialFilters.selected ? initialFilters.selected.split(',').map(id => Number(id)) : [];
  const [contactSelected, setContactSelected] = React.useState<IContact>();
  const [viewMode, setViewMode] = React.useState<'order' | 'delivery' | 'request'>(initialFilters.viewMode);
  const [breadcrumb, setBreadcrumb] = React.useState<{
    parentSections: Array<IBreadcrumb>;
    title: string;
  }>({
    parentSections: [],
    title: '',
  });
  const [isOrderOpen, setIsOrderOpen] = React.useState(false);
  const [miniChannelId, setMiniChannelId] = React.useState('');

  React.useEffect(() => {
    RenderTrack.track('WorkspaceOrderList', {
      renderTime: t,
      type,
      workspaceId: params?.workspaceId,
    });
  }, []);

  React.useEffect(() => {
    const brc = params?.hashId
      ? [
          {
            label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
            action: () =>
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
                  workspaceId: params?.workspaceId,
                }),
              ),
          },
        ]
      : onlyUnreads
      ? [
          {
            label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
            action: () => {
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
                  workspaceId: params?.workspaceId,
                }),
              );
            },
          },
        ]
      : undefined;

    const order = params?.hashId ? orders.find(o => o.hashId === params?.hashId) : undefined;
    const title = params?.hashId
      ? order?.externalIdSeller || order?.externalIdBuyer || params?.hashId
      : onlyUnreads
      ? __('Components.OrdersList.title_unreads')
      : type === 'sale'
      ? __('Components.Header.Sales')
      : __('Components.Header.Orders');

    setBreadcrumb({
      parentSections: brc,
      title,
    });
  }, [params, type, history, onlyUnreads, orders]);

  React.useEffect(() => {
    const brc =
      params?.hashId || contactSelected
        ? [
            {
              label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
              action: () =>
                contactSelected
                  ? setContactSelected(undefined)
                  : history.push(
                      getPath({
                        path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
                        workspaceId: params?.workspaceId,
                      }),
                    ),
            },
          ]
        : onlyUnreads
        ? [
            {
              label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
              action: () => {
                history.push(
                  getPath({
                    path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
                    workspaceId: params?.workspaceId,
                  }),
                );
              },
            },
          ]
        : undefined;

    const order = params?.hashId ? orders.find(o => o.hashId === params?.hashId) : undefined;
    const title = params?.hashId
      ? order?.externalIdSeller || order?.externalIdBuyer || params?.hashId
      : contactSelected
      ? __('Components.Header.Products', { name: contactSelected.name })
      : onlyUnreads
      ? __('Components.OrdersList.title_unreads')
      : type === 'sale'
      ? __('Components.Header.Sales')
      : __('Components.Header.Orders');
    setBreadcrumb({
      parentSections: brc,
      title,
    });
  }, [params?.hashId, type, history, onlyUnreads, orders, contactSelected]);
  const contactId = Number(params?.contactId) || Number(params?.clientId) || Number(params?.supplierId);
  const channel = useSelector(chatSelectors.getChannelByContactId(contactId));
  const contact = contacts[contactId];
  React.useEffect(() => {
    if (!contact || !me) return;
    if (channel) {
      setMiniChannelId(channel.id);
    } else {
      dispatch(
        chatActions.createChannel(
          me.id,
          [contact.id],
          contact.name,
          '',
          CHANNEL_TYPE.PRIVATE,
          (c: IChannel) => setMiniChannelId(c.id),
          true,
          true,
        ),
      );
    }
  }, [channel, me, contact]);

  const fromChat = window.location.pathname.includes('/contact/');
  const fromClientWorkspace = window.location.pathname.includes('/client/');
  const fromSalesWorkspace = window.location.pathname.includes('/sales/');
  const fromSupplierWorkspace = window.location.pathname.includes('/supplier/');
  const paramsId = window.location.pathname.includes('/contact/')
    ? match.params?.contactId
    : window.location.pathname.includes('/supplier/')
    ? match.params?.supplierId
    : match.params?.clientId;

  const parentSectionsFromChat = [
    {
      label: __('Components.Sidebar.chat'),
      action: () =>
        history.push(
          getPath({
            path: ROUTE_PATHS.CHAT_UNREAD,
          }),
        ),
    },
    {
      label: __('Components.ChatList.workspace_chats', {
        name: contacts[match.params?.contactId]?.name,
      }),
      action: () =>
        history.push(
          getPath({
            path: ROUTE_PATHS.CHAT_LIST,
          }),
        ),
    },
    ...(isOrderOpen
      ? [
          {
            label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
            action: () =>
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.CONTACT_SALES : ROUTE_PATHS.CONTACT_PURCHASES,
                  workspaceId: match.params?.workspaceId,
                  contactId: match.params?.contactId,
                }),
              ),
          },
        ]
      : []),
  ];

  const parentSectionsFromClient = [
    {
      label: type === 'sale' ? __('Components.Header.WorkspaceClients') : __('Components.Header.WorkspaceSuppliers'),
      action: () =>
        history.push(
          getPath({
            path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
            workspaceId: match.params?.workspaceId,
          }),
        ),
    },
    {
      label: contacts[paramsId]?.name,
      action: () =>
        history.push(
          getPath({
            path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_CLIENT_EDIT : ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT,
            workspaceId: match.params?.workspaceId,
            clientId: paramsId,
            supplierId: paramsId,
          }),
        ),
    },
    ...(isOrderOpen
      ? [
          {
            label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
            action: () =>
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.CONTACT_SALES : ROUTE_PATHS.CONTACT_PURCHASES,
                  workspaceId: match.params?.workspaceId,
                  contactId: match.params?.contactId,
                }),
              ),
          },
        ]
      : []),
  ];
  const parentSectionsFromSales = [
    {
      label: contacts[paramsId]?.name,
      action: () =>
        history.push(
          getPath({
            path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_CLIENT_EDIT : ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT,
            workspaceId: match.params?.workspaceId,
            clientId: paramsId,
            supplierId: paramsId,
          }),
        ),
    },
    ...(isOrderOpen
      ? [
          {
            label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
            action: () =>
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_SALES : ROUTE_PATHS.WORKSPACE_PURCHASES,
                  workspaceId: match.params?.workspaceId,
                }),
              ),
          },
        ]
      : []),
  ];
  return (
    <>
      {window.location.pathname.includes('/buy/') || window.location.pathname.includes('/sell/') ? (
        <WorkspaceShowroom history={history} location={location} contact={contact} type={type} />
      ) : null}
      <Workspace
        subtitle={''}
        parentSections={
          fromChat
            ? parentSectionsFromChat
            : fromClientWorkspace || fromSupplierWorkspace
            ? parentSectionsFromClient
            : breadcrumb.parentSections
        }
        title={breadcrumb.title}
        tabSelected={
          fromChat
            ? 'chat'
            : fromClientWorkspace
            ? 'clients'
            : fromSupplierWorkspace
            ? 'suppliers'
            : fromSalesWorkspace
            ? 'sale'
            : type
        }
        workspaceId={Number(match.params?.workspaceId)}
        isBuyerWorkspace={type === 'purchase'}
      >
        {orderUnreads && !onlyUnreads ? (
          <Ribbon
            iconName="Unread-orders"
            badgeCount={orderUnreads}
            text={__('Components.OrdersList.num_unreads', { count: orderUnreads })}
            onClickAction={() => {
              history.push(
                getPath({
                  path: type === 'sale' ? ROUTE_PATHS.WORKSPACE_UNREAD_SALES : ROUTE_PATHS.WORKSPACE_UNREAD_PURCHASES,
                  workspaceId: match.params?.workspaceId,
                }) + location.search,
              );
            }}
            actionText={__('Components.OrdersList.view_unreads')}
            type="info"
          />
        ) : null}
        <OrdersList
          history={history}
          match={match}
          location={location}
          hashId={match.params?.hashId}
          catalogHash={
            asSeller
              ? catalogs[Number(match.params?.workspaceId)]?.hashId
              : workspaces[Number(match.params?.workspaceId)]?.hashId
          }
          updateUrl={updateUrl}
          type={type}
          tabSelected={onlyUnreads ? 'unread' : type}
          onlyUnreads={onlyUnreads}
          numberOfHeaders={2}
          viewMode={viewMode}
          setViewMode={vm => setViewMode(vm)}
          hideBack={true}
          contactId={Number(paramsId) || contactSelected?.id}
          setContactSelected={(contact?: IContact) => setContactSelected(contact)}
          breadcrumb={breadcrumb}
          updateBreadcrumb={newBreadcrumb => setBreadcrumb(newBreadcrumb)}
        />
        {(config.TOGGLE_MINI_CHAT.enabled ||
          config.TOGGLE_MINI_CHAT.organizations.includes(Number(match.params?.workspaceId))) &&
        miniChannelId ? (
          <Chat
            match={{ params: { ...match.params, channelId: miniChannelId } } as any}
            isModal={true}
            history={history}
            location={{} as any}
            styleModal={{ right: '90px' }}
            startMinimized={true}
          />
        ) : null}
      </Workspace>
    </>
  );

  /**
   * Update the current url in the browser if navigate to/from a order.
   */
  function updateUrl(
    searchState: ISearchOrder,
    dateRange: IDateRange,
    order?: (IOrder | { hashId?: string; buyerId?: number }) & { isRepeatedOrder?: boolean },
  ) {
    const isAll = totalResults > 0 && orderSelectedIds.length === totalResults;
    if (type || order.buyerId) {
      const fromChat = window.location.pathname.includes('/contact/');
      const fromClientWorkspace = window.location.pathname.includes('/client/');
      const fromSalesWorkspace = window.location.pathname.includes('/sales/');
      const fromSupplierWorkspace = window.location.pathname.includes('/supplier/');
      setIsOrderOpen(true);
      if (fromClientWorkspace) {
        const path = ROUTE_PATHS.WORKSPACE_CLIENT_SALES;
        history.push(
          getPath({
            path,
            hashId: order ? order.hashId || 'new' : '',
            workspaceId: match.params.workspaceId,
            clientId: match.params.clientId,
          }).replace('?', '') +
            qs.stringify({
              currency: searchState.currency,
              range: dateRange,
              selected: isAll ? 'all' : orderSelectedIds.join(','),
              since: searchState.beginDate && searchState.beginDate.getTime(),
              until: searchState.endDate && searchState.endDate.getTime(),
              is_unselect: isUnselect,
              view_mode: viewMode,
            }),
        );
      } else if (fromSupplierWorkspace) {
        const path = ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES;
        history.push(
          getPath({
            path,
            hashId: order ? order.hashId || 'new' : '',
            workspaceId: match.params.workspaceId,
            supplierId: match.params.supplierId,
          }).replace('?', '') +
            qs.stringify({
              currency: searchState.currency,
              range: dateRange,
              selected: isAll ? 'all' : orderSelectedIds.join(','),
              since: searchState.beginDate && searchState.beginDate.getTime(),
              until: searchState.endDate && searchState.endDate.getTime(),
              is_unselect: isUnselect,
              view_mode: viewMode,
            }),
        );
      } else {
        const path =
          type === 'purchase'
            ? fromChat
              ? ROUTE_PATHS.CONTACT_PURCHASES
              : ROUTE_PATHS.WORKSPACE_PURCHASES
            : fromChat
            ? ROUTE_PATHS.CONTACT_SALES
            : ROUTE_PATHS.WORKSPACE_SALES;
        history.push(
          getPath({
            path,
            hashId: order ? order.hashId || 'new' : '',
            workspaceId: match.params.workspaceId,
            contactId: match.params.contactId,
          }).replace('?', '') +
            qs.stringify({
              isRepeatedOrder: order?.isRepeatedOrder || '',
              currency: searchState.currency,
              range: dateRange,
              selected: isAll ? 'all' : orderSelectedIds.join(','),
              since: searchState.beginDate && searchState.beginDate.getTime(),
              until: searchState.endDate && searchState.endDate.getTime(),
              is_unselect: isUnselect,
              view_mode: viewMode,
            }),
        );
      }
    }
  }

  /**
   * Get initial filters for this contact
   */
  function getInitialFilters() {
    // Load selected orders from url params
    const { is_unselect, selected, view_mode } = qs.parse(location.search || '', [
      'is_unselect',
      'selected',
      'view_mode',
    ]) as {
      selected?: string;
      is_unselect?: string;
      view_mode?: 'order' | 'request' | 'delivery';
    };
    const defaultType = filters.type || (asSeller ? 'sale' : 'purchase');
    const orderType = (type || defaultType) as IOrderType;
    let viewMode = view_mode || 'order';
    if (orderType === 'sale' && !['order', 'request'].includes(viewMode)) viewMode = 'order';
    if (orderType === 'purchase' && !['order', 'delivery'].includes(viewMode)) viewMode = 'order';

    return {
      isUnselect: is_unselect === 'true',
      selected,
      viewMode,
    };
  }
};

export default WorkspaceOrders;

const WorkspaceShowroom: React.FC<{
  history: any;
  location: any;
  contact: IContact;
  type: IOrderType;
}> = React.memo(({ history, location, contact, type }) => {
  const match = useRouteMatch<{ workspaceId: string; supplierId: string; clientId: string }>();
  const { workspaceId, clientId, supplierId } = match.params;
  const [breadcrumb, setBreadcrumb] = React.useState<{
    parentSections: Array<IBreadcrumb>;
    title: string;
  }>({
    parentSections: [
      {
        label: type === 'sale' ? __('Components.Header.Sales') : __('Components.Header.Orders'),
        action: () =>
          history.push(
            type === 'sale'
              ? getPath({
                  path: ROUTE_PATHS.WORKSPACE_SALES,
                  workspaceId,
                })
              : getPath({
                  path: ROUTE_PATHS.WORKSPACE_PURCHASES,
                  workspaceId,
                }),
          ),
      },
      {
        label: contact?.name,
        action: () =>
          type === 'sale'
            ? history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_SALES_CLIENT, workspaceId, clientId }))
            : history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_PURCHASES_SUPPLIER, workspaceId, supplierId })),
      },
    ],
    title: type === 'sale' ? __('Components.OrdersList.modal.sale.title') : __('Components.OrdersList.modal.buy.title'),
  });
  return (
    <Workspace
      parentSections={breadcrumb.parentSections}
      subtitle={''}
      title={breadcrumb.title}
      tabSelected="sale"
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={type === 'purchase'}
    >
      {type === 'sale' ? (
        <ShowroomSell
          updateBreadcrumb={brc => setBreadcrumb(brc)}
          breadcrumb={{
            parentSections: breadcrumb.parentSections,
            title: breadcrumb.title,
          }}
          BackHeader={null}
          contact={contact}
          match={match}
          history={history}
          location={location}
        />
      ) : (
        <ShowroomBuy BackHeader={null} contact={contact} match={match} history={history} location={location} />
      )}
    </Workspace>
  );
});
