import styled, { ITheme } from '../../../styled-components';
import { FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  validated?: boolean;
}

export const Row = styled(RowContainer)`
  justify-content: space-between;
`;

export const Validation = styled(TextLabel)`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  color: ${(p: IStyledProps) => (p.validated ? p.theme.colors.green1 : p.theme.colors.grey1)};
`;

export const CheckIcon = styled(FontIcon)`
  width: 15px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: inherit;
  margin-right: 4px;
`;
