import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  theme: ITheme;
  fontSize?: string;
  padding?: string;
  isSelected?: boolean;
}

export const ReadOnlyText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.fontSize || p.theme.fontSize.big};
  padding: ${(p: IStyledProps) => p.padding || ''};
`;

export const OptionRow = styled(RowContainer)<IStyledProps>`
  min-height: 36px;
  max-height: 36px;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 100%;
  &:hover {
    background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.grey4)};
  }
`;

export const TextOption = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  flex: 1;
`;

export const InfoIcon = styled(FontIcon)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
