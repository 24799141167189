import * as React from 'react';

import * as S from './Badge.styled';

interface IProps {
  className?: string;
  color?: 'red' | 'blue' | 'green' | 'grey';
  count: number;
  showNumber?: boolean;
  size?: number;
  selected?: boolean;
  isCart?: boolean;
}

const Badge = ({ className, count, size = 18, color = 'blue', showNumber = true, selected, isCart }: IProps) => {
  if (isCart === true) {
    return <S.BadgeCart className={className} count={count} size={size} color={color} selected={selected} />;
  }
  return showNumber ? (
    <S.Badge className={className} count={count} size={size} color={color} />
  ) : (
    <S.CirclePoint className={className} size={size} color={color} />
  );
};

export default React.memo(Badge);
