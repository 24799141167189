import {
  __,
  debounce,
  EventTrack,
  modalActions,
  notificationsActions,
  productActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userActions,
  utils,
} from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { FORMAT_FILES_ALLOWED, ROUTE_PATHS } from '../../../constants';
import Workspace from '../Workspace/Workspace.component';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { Button, RowContainer, SimpleDropdown } from '../../atoms';
import { EmptyListResource, SimpleSearch } from '../../molecules';
import { Content } from '../../molecules/ConsentioProModal/ConsentioProModal.component';
import PriceGroupTable from '../../molecules/PriceGroupTable';
import * as S from './WorkspacePriceGroups.styled';

export interface IStateProps {
  catalogId: number;
  catalogs: { [cId: number]: IWorkspace };
  contacts: { [cId: number]: IContact };
  me: IUser;
  priceGroups: { [priceGroupId: string]: IPriceGroup };
}

export interface IDispatchProps {
  getPrices: typeof productActions.getPrices;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  priceGroupsDownload: typeof productActions.priceGroupsDownload;
  priceGroupsGet: typeof sellerWorkspaceActions.priceGroupsGet;
  requestProInfo: typeof userActions.requestProInfo;
  syncPricegroups: typeof sellerWorkspaceActions.syncPricegroups;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string }>;

interface IState {
  searchText: string;
  showPriceGroupImport: boolean;
}

class WorkspacePriceGroups extends React.PureComponent<IProps, IState> {
  private t: number;

  /**
   * Track search event
   */
  private trackSearch = debounce(() => {
    const { catalogId } = this.props;
    const { searchText } = this.state;
    EventTrack.track('workspace_pricegroups_search', { workspace_id: catalogId, search: searchText });
  }, 1000);

  constructor(props) {
    super(props);
    this.t = Date.now();
    this.state = { searchText: '', showPriceGroupImport: false };
  }

  public componentDidMount() {
    const { catalogs, catalogId, me, requestProInfo, modalOpen } = this.props;
    const catalog = catalogs[catalogId];
    RenderTrack.track('WorkspacePriceGroups', { renderTime: this.t });
    if (catalog && !sellerWorkspaceService.isProPlan(catalog.plan)) {
      modalOpen('pricelist', () => requestProInfo(me.id, catalogId, 'pricelist'), {}, 'consentiopro');
    } else {
      this.fetchPriceGroups();
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { searchText } = this.state;
    const { catalogs, catalogId, me, modalOpen, requestProInfo } = this.props;
    if (!Object.values(prevProps.catalogs).length && Object.values(catalogs).length) {
      this.fetchPriceGroups();
      const catalog = catalogs[catalogId];
      if (!sellerWorkspaceService.isProPlan(catalog.plan))
        modalOpen('pricelist', () => requestProInfo(me.id, catalogId, 'pricelist'), {}, 'consentiopro');
    }
    if (prevState.searchText !== searchText) this.trackSearch();
  }

  public render() {
    const { searchText, showPriceGroupImport } = this.state;
    const {
      catalogs,
      catalogId,
      contacts,
      history,
      location,
      match,
      me,
      priceGroups,
      priceGroupsDownload,
      requestProInfo,
      touchImage,
    } = this.props;
    const hasPriceGroups = !!Object.values(priceGroups).length;
    const priceGroupsToShow = hasPriceGroups ? this.filterPriceGroups() : [];
    const catalog = catalogs[catalogId];
    const amPro = sellerWorkspaceService.isProPlan(catalog?.plan);
    const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;
    const allowSyncPricegroups = catalog && catalog.allowSyncPricegroups;
    const amEditor = ['admin', 'editor'].includes(sellerWorkspaceService.getRole(catalog, me.id));
    const showCreateCTA = amPro && amEditor;

    let title = showPriceGroupImport
      ? __('WorkspacePriceGroups.dropzone.title')
      : __('Components.Header.WorkspacePriceGroups');
    if (isQuoterMode) title = __('WorkspaceMargins.title');

    return (
      <Workspace
        parentSections={
          showPriceGroupImport
            ? [
                {
                  label: isQuoterMode ? __('WorkspaceMargins.title') : __('Components.Header.WorkspacePriceGroups'),
                  action: () => {
                    this.setState({ showPriceGroupImport: false });
                  },
                },
              ]
            : undefined
        }
        subtitle={''}
        title={title}
        tabSelected="pricelist"
        workspaceId={catalogId}
      >
        <S.Container className="price-groups-scroll">
          <S.Header>
            <RowContainer>
              {hasPriceGroups ? (
                <S.SearchContainer>
                  <SimpleSearch
                    id="search-price-groups"
                    query={searchText}
                    placeHolder={
                      isQuoterMode
                        ? __('WorkspaceMargins.search_placeholder')
                        : __('WorkspacePriceGroups.search_placeholder')
                    }
                    onChange={(t: string) => this.setState({ searchText: t })}
                  />
                </S.SearchContainer>
              ) : null}
              {allowSyncPricegroups ? (
                <Button iconName="Share-web" iconSize="21px" type="link" onClick={this.confirmSyncPricegroups}>
                  {__('WorkspacePriceGroups.sync_pricegroups.button_text')}
                </Button>
              ) : null}
            </RowContainer>
            {showCreateCTA ? (
              <S.Row>
                <S.CTA
                  onClick={() =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT,
                        workspaceId: catalogId + '',
                        priceGroupId: 'new',
                      }),
                    )
                  }
                >
                  {isQuoterMode ? __('WorkspaceMargins.cta') : __('WorkspacePriceGroups.new_price_group')}
                </S.CTA>
                {isQuoterMode ? null : (
                  <SimpleDropdown
                    hAlign="right"
                    options={[
                      { key: 'export', value: __('WorkspacePriceGroups.options.export') },
                      { key: 'import', value: __('WorkspacePriceGroups.options.import') },
                    ]}
                    onSelect={(key: string) => {
                      if (key === 'export') {
                        priceGroupsDownload(me.id, catalogId, (data: string) => {
                          const url = window.URL.createObjectURL(new Blob([data]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', `pricegroups-${catalogId}-${new Date().getTime()}.csv`);
                          document.body.appendChild(link);
                          link.click();
                        });
                      } else {
                        this.setState({ showPriceGroupImport: true });
                      }
                    }}
                  >
                    <S.KebabIcon name="Kebab" />
                  </SimpleDropdown>
                )}
              </S.Row>
            ) : null}
          </S.Header>
          <S.Stats>
            {isQuoterMode
              ? __('WorkspaceMargins.margin_found', { count: priceGroupsToShow?.length })
              : __('WorkspacePriceGroups.price_group_found', { count: priceGroupsToShow?.length })}
          </S.Stats>
          {hasPriceGroups ? (
            priceGroupsToShow?.length ? (
              <PriceGroupTable
                className="team-prices-price-group-table"
                contacts={contacts}
                isQuoterMode={isQuoterMode}
                me={me}
                onItemClick={priceGroup =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT,
                      workspaceId: catalogId + '',
                      priceGroupId: priceGroup.priceGroupId,
                    }),
                  )
                }
                priceGroups={priceGroupsToShow}
                touchImage={touchImage}
              />
            ) : (
              <S.CenterContainer>
                <EmptyListResource
                  imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
                  showButton={false}
                  text={__('Components.ProductsList.QueryEmptyResults', { search: searchText })}
                  text2={__('Components.ProductsList.EmptyResultsTryAgain', { search: searchText })}
                />
              </S.CenterContainer>
            )
          ) : (
            <Content
              handleCTA={() => {
                if (!amPro) {
                  requestProInfo(me.id, catalogId, isQuoterMode ? 'margin' : 'pricelist');
                }
              }}
              selectedSection={isQuoterMode ? 'margin' : 'pricelist'}
              showCTA={!amPro}
            />
          )}
        </S.Container>
        {showPriceGroupImport ? (
          <PriceGroupImportModal
            close={() => {
              this.setState({ showPriceGroupImport: false });
              this.fetchPriceGroups();
            }}
            catalogId={catalogId}
            me={me}
          />
        ) : null}
      </Workspace>
    );
  }

  private confirmSyncPricegroups = () => {
    const { syncPricegroups, catalogId, notificationShow, modalOpen, modalClose } = this.props;

    modalOpen(
      __('WorkspacePriceGroups.sync_pricegroups.modal.title'),
      () => {
        syncPricegroups(catalogId);
        modalClose();
        notificationShow({
          style: 'info',
          title: __('WorkspacePriceGroups.sync_pricegroups.notification.title'),
          subtitle: __('WorkspacePriceGroups.sync_pricegroups.notification.subtitle'),
          closable: true,
        });
      },
      {
        text2: __('WorkspacePriceGroups.sync_pricegroups.modal.subtitle'),
        buttonText: __('WorkspacePriceGroups.sync_pricegroups.modal.button'),
        showCancelButton: true,
      },
      'nice',
    );
  };

  /**
   * Fetch price groups if user is pro
   */
  private fetchPriceGroups() {
    const { catalogId, catalogs, priceGroupsGet } = this.props;
    if (!catalogs[catalogId]) return;

    if (sellerWorkspaceService.isProPlan(catalogs[catalogId].plan)) {
      priceGroupsGet(catalogId);
    }
  }

  /**
   * Filter by name / description the price groups according to the search text
   */
  private filterPriceGroups() {
    const { priceGroups } = this.props;
    const { searchText } = this.state;
    const priceGroupsOrdered = Object.values(priceGroups).sort((pg1, pg2) => pg2.updatedAt - pg1.updatedAt);
    if (!searchText || searchText.length < 3) return priceGroupsOrdered;

    const searchNormalized = utils.toLocaleLowerCaseNormalized(searchText);
    return priceGroupsOrdered.filter(
      pg =>
        utils.toLocaleLowerCaseNormalized(pg.name).includes(searchNormalized) ||
        utils.toLocaleLowerCaseNormalized(pg.description).includes(searchNormalized),
    );
  }
}

export default WorkspacePriceGroups;

const PriceGroupImportModal: React.FC<{
  me: IUser;
  close: () => void;
  catalogId: number;
}> = ({ close, catalogId, me }) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const modifyFiles = React.useCallback(
    existingFiles => {
      const fileToUpload = [...existingFiles];

      uploadedFiles.forEach(file => {
        fileToUpload.push(file);
      });
      return fileToUpload;
    },
    [uploadedFiles],
  );

  const setFiles = React.useCallback(
    (files: Array<File>) => {
      if (files.length > 0) {
        setUploadedFiles(modifyFiles(files));
      }
    },
    [modifyFiles],
  );

  return (
    <S.ImportContainer>
      <S.ImportModal>
        <S.ImportTitle>{__('WorkspacePriceGroups.dropzone.title')}</S.ImportTitle>
        <S.DropzoneText>
          <S.ImportSubtitle>
            {
              utils.formatText(__('WorkspacePriceGroups.dropzone.text'), (s, idx) => (
                <S.LinkText
                  key={idx}
                  onClick={() => {
                    dispatch(
                      productActions.priceGroupsDownload(me.id, catalogId, (data: string) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `pricegroups-${catalogId}-${new Date().getTime()}.csv`);
                        document.body.appendChild(link);
                        link.click();
                      }),
                    );
                  }}
                >
                  {s}
                </S.LinkText>
              )) as any
            }
          </S.ImportSubtitle>
        </S.DropzoneText>
        <S.PriceGroupDropzone>
          <FileDropzone
            files={uploadedFiles}
            close={close}
            onSubmit={() => {
              try {
                const reader = new FileReader();
                reader.onload = function () {
                  dispatch(
                    productActions.priceGroupsUpload(catalogId, this.result as string, (res, error) => {
                      if (error) {
                        return dispatch(
                          notificationsActions.notificationShow({
                            title: __('WorkspaceSettings.error'),
                            subtitle: __('WorkspacePriceGroups.dropzone.error_description'),
                            closable: true,
                            style: 'error',
                          }),
                        );
                      } else {
                        return dispatch(
                          notificationsActions.notificationShow({
                            title: __('WorkspacePriceGroups.dropzone.success'),
                            subtitle: __('WorkspacePriceGroups.dropzone.success_description'),
                            closable: true,
                            style: 'success',
                          }),
                        );
                      }
                    }),
                  );
                };
                reader.onerror = err =>
                  dispatch(
                    modalActions.modalOpen(__('Components.Settings.error', { error: err }), () =>
                      dispatch(modalActions.modalClose()),
                    ),
                  );
                reader.readAsText(uploadedFiles[0]);
              } catch (err) {
                logError(err, 'WorkspacePriceGroups.priceGroupsUpload');
              }
              close();
            }}
            setFiles={setFiles}
          />
        </S.PriceGroupDropzone>
      </S.ImportModal>
    </S.ImportContainer>
  );
};

/**
 * Render files drop zone
 */
const FileDropzone: React.FC<{
  files?: Array<File>;
  close: () => void;
  onSubmit: () => void;
  setFiles: (files: Array<File>) => void;
}> = ({ files, onSubmit, close, setFiles }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  return (
    <>
      <Dropzone
        accept={['.csv']}
        multiple={false}
        onDrop={acceptedFiles => {
          if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
          } else {
            dispatch(
              notificationsActions.notificationShow(
                {
                  title: __('ProductEdit.additionalFiles.format_error.title'),
                  subtitle: __('ProductEdit.additionalFiles.format_error.description', {
                    formats: FORMAT_FILES_ALLOWED.join(', '),
                  }),
                  closable: true,
                  style: 'error',
                },
                6000,
              ),
            );
          }
        }}
        disabled={files?.length > 0}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input type="file" multiple={false} accept={'.csv'} {...getInputProps()} />
              <S.UploadFileIcon name="Upload" />
              <S.TextUpload>
                {__('MagicOrders.add_orders')}
                {files?.length ? (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('MagicOrders.orders_added', { count: files?.length })}</S.TextUploadGreen>
                  </>
                ) : null}
              </S.TextUpload>
              <S.TextCenter>{__('MagicOrders.add_orders_description')}</S.TextCenter>
              <S.SelectButton type={files?.length ? 'secondary' : 'principal'} disabled={files?.length > 0}>
                {__('Components.OrdersImport.select')}
              </S.SelectButton>
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
      <S.UploadButton type="principal" disabled={files?.length ? false : true} onClick={onSubmit}>
        {__('WorkspacePriceGroups.dropzone.cta')}
      </S.UploadButton>
    </>
  );
};
