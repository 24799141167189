import { __, IContact, IPriceGroup, IProdType, IUser, modalActions } from 'common-services';
import * as React from 'react';

import Members from '../../Members';
import MiniImages from '../../MiniImages';
import * as S from '../PriceGroupSelectTable.styled';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

interface IProps {
  className?: string;
  contacts: { [cId: number]: IContact };
  me: IUser;
  priceGroup: IPriceGroup;
  prodTypes: { [key: string]: IProdType };
  priceGroupsSelected: Array<string>;
  setPriceGroupsSelected: (pricegroups: Array<string>) => void;
}

const PriceGroupRow: React.FC<IProps> = ({
  className,
  contacts,
  me,
  priceGroup,
  priceGroupsSelected,
  setPriceGroupsSelected,
  prodTypes,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  function onImageClick(imagesArray: Array<string>, index: number) {
    dispatch(
      modalActions.touchImage(
        imagesArray.map(url => ({ src: url })),
        index,
      ),
    );
  }

  const { clients, shouldAddTransportCost, prices, status, priceGroupId } = priceGroup;

  const productImages = priceGroup.prices.reduce((acc, price) => {
    if (price.productImageUrl) acc.push(price.productImageUrl);
    else if (prodTypes[price.productType] && prodTypes[price.productType].defaultImageUrl)
      acc.push(prodTypes[price.productType].defaultImageUrl);
    return acc;
  }, [] as Array<string>);

  const fourProductImages = productImages.slice(0, 4);
  const checked = priceGroupsSelected ? priceGroupsSelected.includes(priceGroupId) : false;

  return (
    <S.PriceGroupTr className={className}>
      <S.FillCol selectable={true}>
        <S.RadioLabel
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <S.RadioInput
            id="selected"
            type="checkbox"
            checked={checked}
            onChange={e => {
              if (!priceGroupsSelected.includes(priceGroupId)) {
                setPriceGroupsSelected([...priceGroupsSelected, priceGroupId]);
              } else {
                const idx = priceGroupsSelected.indexOf(priceGroupId);
                if (idx !== -1) {
                  const os = [...priceGroupsSelected];
                  os.splice(idx, 1);
                  setPriceGroupsSelected(os);
                }
              }
            }}
          />
          <S.RadioCheckmark checked={checked} />
        </S.RadioLabel>
      </S.FillCol>
      <S.NameCol>
        <S.TextName>{priceGroup.name || priceGroup.description || ''}</S.TextName>
      </S.NameCol>
      <S.ProductsCol>
        {fourProductImages.length ? (
          <MiniImages
            className="pricegroups-table-images"
            images={fourProductImages}
            onImageClick={index => onImageClick(productImages, index)}
            count={prices.length}
            from="table"
          />
        ) : (
          <S.TextBlack>
            {prices.length === 0
              ? __('PriceGroupSelectTable.PriceGroupRow.not_added')
              : __('PriceGroupSelectTable.PriceGroupRow.products', {
                  count: prices.length,
                })}
          </S.TextBlack>
        )}
      </S.ProductsCol>

      <S.ClientsCol>
        {clients.length ? (
          <Members
            className="price-group-members"
            contacts={contacts}
            clients={clients}
            disableHover={true}
            me={me}
            members={clients.map(c => ({
              userId: c.userId,
            }))}
            size={36}
          />
        ) : (
          <S.TextBlack>{__('PriceGroupSelectTable.PriceGroupRow.not_added')}</S.TextBlack>
        )}
      </S.ClientsCol>

      <S.TransportCostCol>
        <S.TextBlack>
          {shouldAddTransportCost
            ? __('Constants.TransportCostIncluded.not_included')
            : __('Constants.TransportCostIncluded.included')}
        </S.TextBlack>
      </S.TransportCostCol>

      <S.StatusCol>
        <S.TextBlack>
          {status === 'active' ? __('Constants.Status.active') : __('Constants.Status.inactive')}
        </S.TextBlack>
      </S.StatusCol>
    </S.PriceGroupTr>
  );
};

export default React.memo(PriceGroupRow);
