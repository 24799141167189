import { __, chatActions, RenderTrack, userSelectors } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { convertToIFile } from '../../../services/file';
import { Input, TextArea } from '../../atoms';
import InputWithLabel from '../InputWithLabel';
import * as S from './ChatPublicEdit.styled';

interface IProps {
  channel: IChannel;
  className?: string;
  imageChannelPreview?: IPreviewFile;
  onClose: () => void;
  renderTime: number;
  setImageChannelPreview: (imageChannelPreview: IPreviewFile) => void;
  toShow: boolean;
  uploadImage: (file: IFile, fileString: string, cb?: (url: string) => void) => void;
}

/**
 * Chat public edit modal
 */
const ChatPublicEdit: React.FC<IProps> = ({
  channel,
  className,
  imageChannelPreview,
  onClose,
  renderTime,
  setImageChannelPreview,
  toShow,
  uploadImage,
}: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useSelector(userSelectors.getUser);
  const [channelName, setChannelName] = React.useState(channel.name);
  const [channelDescription, setChannelDescription] = React.useState(channel.description || '');
  const [channelImageUrl, setChannelImageUrl] = React.useState(channel.imageUrl || '');
  const [channelThumbnailImageUrl, setChannelThumbnailImageUrl] = React.useState(
    channel.configuration?.thumbnail || '',
  );
  const [channelThumbnailPreview, setChannelThumbnailPreview] = React.useState<IPreviewFile>();
  const [errors, setErrors] = React.useState<Map<string, string>>(new Map());
  const hasChanges =
    imageChannelPreview ||
    channelThumbnailPreview ||
    channelName !== channel.name ||
    channelDescription !== channel.description ||
    channelImageUrl !== channel.imageUrl ||
    channelThumbnailImageUrl !== channel.configuration?.thumbnail;

  React.useEffect(() => {
    if (toShow) RenderTrack.track('ChatPublicEdit', { channelId: channel.id, renderTime });
  }, [toShow, renderTime, channel]);

  React.useEffect(() => {
    setChannelName(channel.name);
    setChannelDescription(channel.description || '');
    setChannelImageUrl(channel.imageUrl || '');
    setChannelThumbnailImageUrl(channel.configuration?.thumbnail || '');
    setChannelThumbnailPreview(undefined);
  }, [channel]);

  const setError = React.useCallback(
    (key: string, error?: string) => {
      const cloneErrors = new Map(errors);
      if (error) {
        cloneErrors.set(key, error);
      } else {
        cloneErrors.delete(key);
      }
      setErrors(cloneErrors);
    },
    [errors, setErrors],
  );

  const imageData = imageChannelPreview
    ? `data:${imageChannelPreview.file.type};base64,${imageChannelPreview.file.content}`
    : undefined;

  const thumbnailData = channelThumbnailPreview
    ? `data:${channelThumbnailPreview.file.type};base64,${channelThumbnailPreview.file.content}`
    : undefined;

  /**
   * Wrapper to call update channel
   */
  const updateChannel = React.useCallback(
    (name: string, description: string, imageUrl: string, thumbnail?: string) => {
      dispatch(
        chatActions.updateChannel(
          me.id,
          channel.id,
          {
            name,
            description,
            imageUrl,
            thumbnail,
          },
          channel.invitePolicy,
          onClose,
        ),
      );
    },
    [channel, dispatch, me, onClose],
  );

  /**
   * On submit, uploda image and update channel
   */
  const onSubmit = React.useCallback(() => {
    if (imageData || thumbnailData) {
      let imageUrlToUpload;

      if (imageData)
        uploadImage(imageChannelPreview.file, imageChannelPreview.fileString, imageUrl => {
          imageUrlToUpload = imageUrl;
          if (!thumbnailData)
            updateChannel(channelName, channelDescription, imageUrlToUpload, channelThumbnailImageUrl);
        });
      if (thumbnailData)
        uploadImage(channelThumbnailPreview.file, channelThumbnailPreview.fileString, thumbnailUrl => {
          updateChannel(channelName, channelDescription, imageUrlToUpload || channelImageUrl, thumbnailUrl);
        });
    } else {
      updateChannel(channelName, channelDescription, channelImageUrl, channelThumbnailImageUrl);
    }
  }, [
    channelDescription,
    channelImageUrl,
    channelName,
    channelThumbnailImageUrl,
    channelThumbnailPreview,
    imageChannelPreview,
    imageData,
    thumbnailData,
    updateChannel,
    uploadImage,
  ]);

  if (!toShow) return null;

  return (
    <S.Container
      className={className}
      title={__('ChatPublicEdit.title')}
      onClose={onClose}
      minHeight="450px"
      contentGrow={false}
    >
      <S.ModalContent>
        <S.ImageContainer>
          <S.ChannelImage src={imageData || channelImageUrl || IMAGES.publicChannelDefault.landscape} />
          <S.PictureThumbnailContainer>
            <S.PictureThumbnail
              editable={true}
              height="90px"
              imageUrl={thumbnailData || channelThumbnailImageUrl || IMAGES.publicChannelThumbnailPlaceholder}
              onDelete={() => {
                setChannelThumbnailImageUrl('');
                setChannelThumbnailPreview(undefined);
              }}
              onFileChange={f => {
                if (f)
                  convertToIFile(f, (res, fileString) => {
                    setChannelThumbnailPreview({ file: res, fileString });
                  });
              }}
              relation={1}
              size="big"
              width="90px"
              withCrop={true}
            />
          </S.PictureThumbnailContainer>
          <S.ActionButtonsContainer>
            {channelImageUrl ? (
              <>
                <S.ActionButton
                  className="chat-public-edit-picture"
                  onClick={() => {
                    onAddPicture();
                  }}
                >
                  <S.ActionIcon name="Edit" />
                </S.ActionButton>
                <S.ActionButton
                  className="chat-public-delete-picture"
                  onClick={() => {
                    setImageChannelPreview(undefined);
                    setChannelImageUrl(IMAGES.publicChannelDefault.landscape);
                  }}
                >
                  <S.ActionIcon name="Trash" />
                </S.ActionButton>
              </>
            ) : (
              <S.ActionButton className="chat-public-add-picture" onClick={() => onAddPicture()}>
                <S.ActionIcon name="Add-picture" />
              </S.ActionButton>
            )}
          </S.ActionButtonsContainer>
        </S.ImageContainer>
        <S.FormContent>
          <InputWithLabel isRequired={true} label={__('ChatPublicEdit.name.label')}>
            <Input
              name="channelName"
              type="text"
              placeholder={__('ChatPublicEdit.name.placeholder')}
              value={channelName}
              isRequired={true}
              onChange={(key, value) => setChannelName(value + '')}
              onBlur={(name, value) => {
                setError(name, value ? '' : 'empty');
                setChannelName(value + '');
              }}
              hasError={!!errors.get('channelName')}
              startText="#"
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('ChatPublicEdit.description.label')}>
            <TextArea
              name="description"
              placeholder={__('ChatPublicEdit.description.placeholder')}
              value={channelDescription}
              onChange={(key, value) => setChannelDescription(value + '')}
              onBlur={(name, value) => {
                setChannelDescription(value + '');
              }}
              rows={5}
              maxLength={500}
              width="auto"
            />
          </InputWithLabel>
          <S.SubmitButton
            className="chat-public-edit-submit-button"
            disabled={errors.size > 0 || !hasChanges}
            onClick={() => onSubmit()}
            type="principal"
          >
            {__('ChatPublicEdit.save')}
          </S.SubmitButton>
        </S.FormContent>
      </S.ModalContent>
    </S.Container>
  );
};

/**
 * Click on the fake input to open the file picker
 */
function onAddPicture() {
  const imageInput = document.getElementsByClassName('chat-public-edit-input-picture')?.[0] as HTMLInputElement;
  if (imageInput) imageInput.click();
}

export default React.memo(ChatPublicEdit);
