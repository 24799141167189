import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer } from '../FlexContainer';
import FontIcon from '../FontIcon/FontIcon.component';
import TextLabel from '../TextLabel/TextLabel.styled';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const MoreImageIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  height: 25px;
  width: 25px;
  padding: 6px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-bottom: 5px;
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: center;
  max-width: 60px;
  line-height: 1.4;
`;

export const PictureInputFile = styled.input`
  display: none;
`;
