import * as React from 'react';
import * as S from './Loading.styled';

export interface IProps {
  sizeRatio?: number;
  id?: string;
}

const Loading: React.FC<IProps> = ({ sizeRatio = 1.0 }) => {
  const { size, borderSize } = getLoaderSize(sizeRatio);
  return <S.Loading size={size} borderSize={borderSize} />;
};

function getLoaderSize(sizeRatio: number) {
  let size = 30;
  size = size * sizeRatio;
  const borderSize = size / 10;

  return { size: size + 'px', borderSize: borderSize + 'px' };
}

export default React.memo(Loading);
