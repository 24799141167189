import { constants } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  amount?: number;
  color?: string;
  disabled?: boolean;
  featuredColor?: string;
  fromMessage?: boolean;
  ipadMargin?: string;
  isFeatured?: boolean;
  selected?: boolean;
  showCTA?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-height: min-content;
  max-width: 100%;
  height: auto;
  cursor: ${(p: IStyledProps) => (p.showCTA ? 'pointer' : 'inherit')};
  min-width: 230px;
  max-width: 230px;
  justify-content: flex-start;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  box-shadow: 1px 1px 5px 2px ${(p: IStyledProps) => (p.selected ? p.theme.colors.green2 : 'none')};
  border: 1px solid ${(p: IStyledProps) => (p.fromMessage ? p.theme.colors.transparent : p.theme.colors.grey3)};
  ${(p: IStyledProps) =>
    !p.fromMessage &&
    p.showCTA &&
    css`
      &:hover {
        opacity: 1;
        box-shadow: ${p.theme.selectedBoxShadow};
      }
    `}
  position: relative;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 168px;
    width: calc(33vw - ${(p: IStyledProps) => p.ipadMargin || '54px'});
    max-width: 230px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: calc(50vw - 36px);
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 147.5px;
  object-fit: cover;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    height: calc((33vw - 66px) / ${constants.IMAGE_RELATION.SECTION});
    max-height: 147.5px;
    min-height: 107.5px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: calc((50vw - 48px) / ${constants.IMAGE_RELATION.SECTION});
  }
`;

export const Row = styled(RowContainer)``;
export const PriceRow = styled(RowContainer)`
  justify-content: flex-start;
  flex: 1;
  min-height: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  position: relative;
  align-items: center;
`;

export const StockColumn = styled(ColumnContainer)`
  justify-content: center;
  align-items: flex-start;
  min-height: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  max-height: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const Column = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => `0 ${p.theme.paddingSize(2)} ${p.theme.paddingSize(2)}`};
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextGrey = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextSemiBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const Type = styled(TextSemiBold)`
  word-wrap: break-word;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  min-height: 39px;
  max-height: 39px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Packaging = styled(Text)`
  line-height: 30px;
  height: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  overflow: hidden;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  white-space: nowrap;
  text-overflow: ellipsis;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    line-height: 25px;
  }
`;

export const Price = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: flex;
  color: inherit;
`;

export const POR = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const PriceUnit = styled(POR)`
  padding: 0 5px;
  line-height: 1;
`;

export const TrashIcon = styled(FontIcon)`
  font-size: 18px;
  width: 18px;
  height: 18px;
`;

export const Header = styled(ColumnContainer)`
  // Fix to refresh element in iPad
  transform: translate3d(0, 0, 0);
  position: relative;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  overflow: hidden;
  justify-content: center;
  &:hover {
    .short-display {
      display: none;
    }
    .full-display {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Organic = styled.div`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: 3px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
`;

export const IncDecButtons = styled(Row)`
  flex: 1;
  justify-content: flex-end;
`;

export const IncDecButton = styled.button`
  background: ${(p: IStyledProps) => (p.amount === 1 ? p.theme.colors.white : p.theme.colors.green1)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => (p.amount === 1 ? p.theme.colors.green1 : p.theme.colors.white)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-family: 'Nunito';
  &:hover {
    opacity: 0.7;
  }
`;

// TODO: to refactor with icons. Otherwise text is not vertically centered in all browsers
export const IncDecText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  padding-bottom: 3px;
`;

export const AddButton = styled.button<IStyledProps>`
  background-color: ${(p: IStyledProps) => (p.selected ? p.color || p.theme.colors.green1 : p.theme.colors.white)};
  border: 1px solid ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.color || p.theme.colors.green1)};
  color: ${(p: IStyledProps) =>
    p.selected ? p.theme.colors.white : p.color || p.disabled ? p.theme.colors.green2 : p.theme.colors.green1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-family: 'Nunito';
  padding: 0;
  line-height: 30px;
  cursor: pointer;
  width: 100%;
  outline: none;
  min-height: 32px;
  transition: all 0.6s;
  ${(p: IStyledProps) =>
    !p.disabled &&
    css`
      &:hover {
        background-color: ${p.color || p.theme.colors.green1};
        color: ${p.theme.colors.white};
      }
    `}
`;

export const AmountRow = styled(Row)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0 0;
  cursor: auto;
`;

export const AddRow = styled(Column)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0 0;
  cursor: auto;
  flex: 1;
  justify-content: center;
  position: relative;
`;

export const Amount = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  display: flex;
  color: inherit;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding-right: 0;
  }
`;

export const Select = styled.select`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-family: 'Nunito';
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  color: inherit;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  ${(p: IStyledProps) => {
    const color = p.theme.colors.grey1;
    return css`
      background-image: linear-gradient(45deg, transparent 50%, ${color} 50%),
        linear-gradient(135deg, ${color} 50%, transparent 50%);
    `;
  }}
  background-position: calc(100% - 5px) 0.5em, 100% 0.5em;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
`;

export const Body = styled(Column)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  justify-content: flex-start;
  flex: 1;
  min-height: 152.5px;
  max-height: 222.5px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const ShareIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-bottom: 3px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const ShareTouchable = styled.div`
  flex-direction: row;
  align-items: center;
  max-width: 60px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const ErrorIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: 18px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const FeaturedFlagList = styled.div`
  position: absolute;
  top: -10px;
  right: 10px;
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow: visible;
`;
export const FeaturedItem = styled.img`
  height: 26px;
  margin-right: 4px;
`;

export const Featured = styled.div`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: ${(p: IStyledProps) =>
    (p.isFeatured ? p.featuredColor : p.theme.colors.black).replace('1)', '0.8)')};
  color: white;
  border-radius: 3px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  max-width: calc(100% - ${(p: IStyledProps) => p.theme.paddingSize(2)});

  &:hover {
    & span {
      display: inline-block;
    }
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    top: ${(p: IStyledProps) => p.theme.paddingSize()};
    left: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const FeaturedIcon = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  width: 28px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeaturedText = styled.span`
  display: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: white;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()} 0 2px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SustainabilityDataClosed = styled.div`
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  position: absolute;
  bottom: 0px;
  left: -3px;
  border-radius: 0 12px 12px 0;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  background-color: white;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  width: max-content;
  opacity: 0.9;
`;
export const SustainabilityDataOpen = styled.div`
  display: none;
  position: absolute;
  bottom: 0px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: white;
  width: 100%;
  opacity: 0.9;
`;
export const GreyText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const GreyBoldText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  overflow: hidden;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const BlackText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const TextBold = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const LeafIcon = styled(FontIcon)`
  position: absolute;
  right: 0px;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  background-color: ${(p: IStyledProps) => p.theme.colors.green2};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;
export const TextRed = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const TextGray = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
