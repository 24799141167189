import { WORKING_STATUS } from 'common-services';
import * as React from 'react';

import { AVAILABILITY, getWorkingStatusOptions } from '../../../constants';
import Select from '../SelectSearch';
import * as S from './SelectWorkingStatus.styled';

interface IProps {
  className?: string;
  autoFocus?: boolean;
  hasError?: boolean;
  name?: string;
  onChange: (name: string, value: WORKING_STATUS | AVAILABILITY, error?: string) => void;
  value: WORKING_STATUS | AVAILABILITY;
  options?: Array<{ label: string; value: WORKING_STATUS | AVAILABILITY }>;
}

export default React.memo(({ autoFocus, className, hasError, name = '', onChange, value, options }: IProps) => {
  return (
    <S.Container className={className}>
      <Select
        autoFocus={autoFocus}
        containerMargin="0"
        CustomOption={CustomOption}
        hasError={hasError}
        innerPadding="0 0 0 30px"
        isSearchable={false}
        name={name}
        onChange={onChange}
        options={options || getWorkingStatusOptions()}
        value={value}
        width="100%"
      />
      <S.StatusBubbleAbsolute workingStatus={value} />
    </S.Container>
  );
});

const CustomOption = ({ innerProps, data, isSelected }) => {
  return (
    <S.OptionRow {...innerProps} isSelected={isSelected}>
      <S.StatusBubbleOption workingStatus={data.value as AVAILABILITY | WORKING_STATUS} />
      <S.TextOption data-testid={data.label}>{data.label}</S.TextOption>
    </S.OptionRow>
  );
};
