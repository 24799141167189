import { utils } from 'common-services';
import * as React from 'react';

import * as S from './TextArea.styled';

interface IProps {
  autoFocus?: boolean;
  autoIncreaseHeight?: boolean;
  className?: string;
  containerMargin?: string;
  disabled?: boolean;
  inputRef?: (ref: HTMLTextAreaElement) => void;
  inputRefObject?: React.RefObject<HTMLTextAreaElement>;
  maxLength?: number;
  name: string;
  onBlur?: (name: string, value: string) => void;
  onChange: (name: string, value: string | number, error?: string) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  showCharactersCount?: boolean;
  value: string;
  width?: string;
  zeroCase?: string;
}

export default React.memo(
  ({
    autoFocus,
    autoIncreaseHeight = false,
    className,
    containerMargin = '0',
    disabled = false,
    inputRefObject,
    inputRef,
    maxLength = 1024,
    name,
    onBlur = (n: string, v: string) => null,
    onChange,
    onFocus,
    onKeyDown,
    placeholder = '',
    rows = 10,
    showCharactersCount = true,
    value,
    width,
    zeroCase,
  }: IProps) => {
    if (disabled) {
      return <S.ReadOnly>{value || zeroCase || '-'}</S.ReadOnly>;
    }

    return (
      <S.Container width={width} margin={containerMargin} className={className}>
        <S.TextArea
          autoFocus={autoFocus}
          id={name}
          maxLength={maxLength}
          maxRows={autoIncreaseHeight ? undefined : rows}
          minRows={rows}
          onBlur={e => onBlur!(name, utils.cleanHTML(e.target.value).trim())}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={inputRefObject || (r => inputRef?.(r))}
          rows={rows}
          value={value || ''}
        />
        {showCharactersCount ? <S.TextCount>{`${(value || '').length}/${maxLength}`}</S.TextCount> : null}
      </S.Container>
    );

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
      onChange(name, e.target.value, '');
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
      if (onKeyDown) onKeyDown(e);
    }

    function handleFocus(e: React.FocusEvent<HTMLTextAreaElement>): void {
      onFocus?.();
    }
  },
);
