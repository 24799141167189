import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../atoms';

interface IStyledProps {
  isSelected?: boolean;
  theme: ITheme;
}

export const HeaderContainer = styled(RowContainer)`
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: center;
  padding: 5px ${(p: IStyledProps) => p.theme.paddingView};
  padding-bottom: 1px;
`;

export const UsersContainer = styled(ColumnContainer)`
  padding: 5px ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const UserRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  overflow: hidden;
`;

export const UserInfoCol = styled(ColumnContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingObject};
  overflow: hidden;
`;

const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextGMV = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: right;
`;

export const UserPictureNameRow = styled(RowContainer)`
  align-items: center;
  max-width: 70%;
  overflow: hidden;
`;

export const UserImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  object-fit: scale-down;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TextCompany = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextName = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
