import { __ } from 'common-services';
import * as React from 'react';
import * as S from './Alert.styled';

export interface IProps {
  show: boolean;
}
export default React.memo(({ show }: IProps) => {
  if (!show) return null;
  return (
    <S.Container>
      {__('Components.Alert.Connecting')}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </S.Container>
  );
});
