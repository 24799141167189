import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextArea } from '../../atoms';

interface IStyledProps {
  hasText?: boolean;
  isDragActive?: boolean;
  isSelected?: boolean;
  isLogged?: boolean;
  theme: ITheme;
}

export const RowInput = styled(RowContainer)<IStyledProps>`
  position: relative;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  min-height: min-content;
  align-items: flex-start;
`;

export const SendIcon = styled(FontIcon)<IStyledProps>`
  font-size: 18px;
  width: 30px;
  height: 30px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => (p.hasText ? p.theme.colors.green1 : p.theme.colors.green2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-left: 7px;
  margin: 2px 0;
  margin-left: 4px;
  ${(p: IStyledProps) =>
    !p.hasText &&
    css`
      cursor: not-allowed;
    `};
`;

export const RowInputContainer = styled(RowContainer)<IStyledProps>`
  margin-left: ${(p: IStyledProps) => (p.isLogged ? p.theme.paddingSize(2) : '0')};
  padding: 2px ${(p: IStyledProps) => p.theme.paddingSize()};
  flex: 1;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: flex-start;
  &:focus-within {
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.black};
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
    padding: 1px 5px;
  }

  ${(p: IStyledProps) =>
    p.isDragActive &&
    css`
      border: 1px dashed ${p.theme.colors.black};
    `};
`;

export const Input = styled(TextArea)`
  textarea {
    border: none;
    min-height: 100%;
    background-color: transparent;
    &:focus-within {
      box-shadow: none;
    }
    padding-left: 6px;
    max-height: 250px;
  }
  flex: 1;
  position: relative;
  top: 1px;
`;

export const IconLabel = styled.label`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
`;

export const FileIcon = styled(FontIcon)`
  font-size: 18px;
  height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const FontIconWrapper = styled.label`
  padding: 8px 0;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme!.colors.black};
`;

export const EmojiRow = styled(RowContainer)`
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const EmojiWrapper = styled.div<IStyledProps>`
  position: absolute;
  top: -330px;
  left: 0px;
`;

export const EmojisModal = styled(RowContainer)`
  position: absolute;
  bottom: 42px;
  left: 80px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  min-height: 40px;
  max-width: max-content;
  align-items: center;
`;

export const EmojiItem = styled(ColumnContainer)<IStyledProps>`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.H1};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  cursor: pointer;

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;
