import { __, EventTrack, ICountry, IUser, throttle, UserExistsCode, validations } from 'common-services';
import * as React from 'react';

import { getCountriesSelect } from '../../../../../constants';
import { Error } from '../../../../atoms';
import { PasswordInput, RegisterStep } from '../../../../molecules';
import * as CS from '../../LoginRegisterFlow.styled';
import * as S from './InputStep.styled';

interface IProps {
  canSkip?: boolean;
  countries: { [key: string]: ICountry };
  cta: string;
  goBack?: () => void;
  inputType: 'password' | 'default' | 'email';
  isRecommended?: boolean;
  lang?: LOCALE;
  label: string;
  me: IUser;
  name: string;
  onNext: (
    value: string,
    onError: (text: string) => void,
    phoneData?: { phoneNumber: string; countryPrefix: number; country: string },
  ) => void;
  onSkip?: () => void;
  placeholder: string;
  step?: number;
  skipText?: string;
  title: string;
  totalSteps?: number;
  trackRender?: (t: number) => void;
  userExists: (
    params: { phone?: string; email?: string },
    onEnd: (result: string, code?: UserExistsCode) => void,
  ) => void;
  verifyUser?: (phone: string, email: string, cb: (code: string) => void) => void;
}

interface IState {
  countriesSelect: Array<{ label: string; value: string }>;
  errorMessage: string;
  errors: Map<string, string>;
  value: string;
}

export default class InputStep extends React.PureComponent<IProps, IState> {
  private constructedAt: number;
  private onSubmit = throttle(() => {
    const { inputType, lang, name, onNext, verifyUser } = this.props;
    const { value } = this.state;
    const { errors, errorMessage } = this.validateForm();

    this.setState({
      errors,
      errorMessage,
    });

    EventTrack.formSubmit({
      formName: 'onboard-add-fields',
      errors,
      result: errors.size === 0 ? 'success' : 'error',
      fields: { [name]: name === 'info_password' ? '***' : value },
    });

    if (errors.size === 0) {
      // verify email
      if (['email'].includes(inputType) && verifyUser) {
        verifyUser('', value, (code: string) => {
          if (code === 'email-taken') {
            this.setError(name, code);
            this.setState({
              errorMessage: __('Components.Onboarding.Register.error_email_already_taken', { locale: lang }),
            });
            return;
          }
          this.constructedAt = Date.now();
          onNext(value, this.onErrorCallback);
        });
      } else {
        this.constructedAt = Date.now();
        onNext(value, this.onErrorCallback);
      }
    }
  }, 500);

  constructor(props) {
    super(props);
    this.constructedAt = Date.now();
    this.state = {
      countriesSelect: getCountriesSelect(props.countries),
      errorMessage: '',
      errors: new Map(),
      value: this.getInitialValue(),
    };
  }

  public componentDidMount() {
    const { trackRender } = this.props;
    if (trackRender) trackRender(this.constructedAt);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { name, trackRender } = this.props;

    // Reset state when changing form
    if (prevProps.name !== name) {
      this.setState({
        errorMessage: '',
        errors: new Map(),
        value: this.getInitialValue(),
      });
      if (trackRender) trackRender(this.constructedAt);
    }
  }

  public render() {
    const {
      canSkip,
      cta,
      lang,
      label,
      goBack,
      inputType,
      name,
      onSkip,
      placeholder,
      skipText,
      step,
      title,
      totalSteps,
    } = this.props;
    const { errorMessage, value, errors } = this.state;

    const inputDisabled = !value || !!errors.get(name);

    return (
      <CS.Container fullWidth={true}>
        <RegisterStep
          className={`register-step-${name}`}
          currentStep={step}
          goBack={goBack}
          lang={lang}
          numberOfSteps={totalSteps}
          subtitle={label}
          title={title}
        >
          <CS.Form
            id={`form-input-step-${name}`}
            onSubmit={e => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            <CS.Card className={`onboarding-input-step-${name}`}>
              <S.InputCol>
                {this.renderTextInput({
                  name,
                  value,
                  maxLength: 50,
                  autoFocus: true,
                  isPasswordInput: inputType === 'password',
                  placeholder,
                })}
                {errorMessage ? (
                  <CS.TextErrorContainer alignSelf="flex-start">
                    <Error text={errorMessage} />
                  </CS.TextErrorContainer>
                ) : null}
              </S.InputCol>
              {canSkip ? (
                <S.Row>
                  <S.SkipLink onClick={onSkip} type="secondary">
                    {skipText || __('Components.Onboarding.Register.skip', { locale: lang })}
                  </S.SkipLink>
                  <S.SubmitButton
                    id="input-step-submit-button"
                    disabled={inputDisabled}
                    onClick={this.onSubmit}
                    type="principal"
                  >
                    {cta}
                  </S.SubmitButton>
                </S.Row>
              ) : (
                <S.SubmitButton
                  id="input-step-submit-button"
                  disabled={inputDisabled}
                  onClick={this.onSubmit}
                  type="principal"
                >
                  {cta}
                </S.SubmitButton>
              )}
            </CS.Card>
          </CS.Form>
        </RegisterStep>
      </CS.Container>
    );
  }

  /**
   * Render a text input
   */
  private renderTextInput(params: {
    name: string;
    value: string;
    placeholder: string;
    maxLength: number;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    autoFocus: boolean;
    isPasswordInput?: boolean;
  }) {
    const { errors } = this.state;
    const hasError = !!errors.has(params.name);
    if (params.isPasswordInput) {
      return (
        <PasswordInput
          hasError={hasError}
          isRegister={true}
          password={params.value}
          setError={err => this.setError(params.name, err)}
          updatePassword={val => this.setState({ value: val })}
          width="400px"
        />
      );
    }
    return (
      <S.TextInput
        autoFocus={params.autoFocus}
        className={`text-input-${params.name}`}
        hasError={hasError}
        name={params.name}
        onChange={(name: string, val: string) => {
          this.setState({ value: val }, () => {
            if (hasError) {
              this.setState(this.validateForm());
            }
          });
          if (!val) this.setError(name, 'empty');
        }}
        placeholder={params.placeholder}
        type={params.isPasswordInput ? 'password' : 'text'}
        value={params.value || ''}
        width="400px"
      />
    );
  }

  /**
   * Set error for a particular field represented by key
   */
  private setError(key: string, error: string) {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  }

  /**
   * Get initial value if already filled before in user
   */
  private getInitialValue() {
    const { me, name } = this.props;
    if (name === 'info_name') return me.name;
    if (name === 'info_company_name') return me.companyName;
    if (name === 'info_email') return me.email;
    return '';
  }

  /**
   * On error callback, print an error message
   */
  private onErrorCallback = (errorMessage: string) => {
    this.setState({ errorMessage });
  };

  /**
   * Validate register form data (fields not empty, phone number / email validation)
   */
  private validateForm(): { errors: Map<string, string>; errorMessage: string } {
    const { inputType, lang, name } = this.props;
    const { value } = this.state;
    const errors = new Map();
    let errorMessage = '';

    if (!value) {
      errors.set(name, 'empty');
      errorMessage = __('Components.Onboarding.Register.empty_fields', { locale: lang });
      return { errors, errorMessage };
    }

    switch (inputType) {
      case 'email':
        if (!validations.validateEmail(value)) {
          errors.set(name, 'validation');
          errorMessage = __('Components.Onboarding.Register.email_validation', { locale: lang });
        }
        break;
      case 'password':
        if (!validations.validatePassword(value)) {
          errors.set(name, 'validation');
          errorMessage = __('Components.Onboarding.Register.password_validation', { locale: lang });
        }
        break;
    }
    return { errors, errorMessage };
  }
}
