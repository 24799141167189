import styled, { ITheme } from '../../../styled-components';
import { TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  groupedByProduct?: boolean;
}

export const FooterAggregation = styled.div`
  bottom: 0;
  left: 0px;
  right: 0;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: min-content;
  padding: 5px 0px 5px ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 -5px 6px 0 ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const Text = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextPrimary = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const TableOrders = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
  position: relative;
`;

export const Body = styled.tbody`
  height: 100%;
`;

export const Td = styled.td`
  padding-right: 10px;
`;
export const EmptyCol = styled(Td)`
  width: ${(p: IStyledProps) => (p.groupedByProduct ? '40%' : '60%')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: ${(p: IStyledProps) => (p.groupedByProduct ? '35%' : '55%')};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    ${(p: IStyledProps) => (p.groupedByProduct ? '30%' : '50%')};
  }
`;
export const Col = styled(Td)`
  width: 15%;
`;
export const RegularCol = styled(Td)`
  width: 20%;
`;
export const SmallCol = styled.td`
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: 140px;
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
`;

export const RowTotal = styled.tr`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
