import { __, broadcastActions, CHANNEL_DISPLAY, notificationsActions, RenderTrack, utils } from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { Input, RowContainer, SimpleDropdown } from '../../atoms';
import { ContactsList, InputWithLabel, MembersSearch } from '../../molecules';
import * as S from './BroadcastInfo.styled';

type RouteProps = RouteComponentProps<{ channelId?: string }>;

export interface StateProps {
  myId: number;
  contacts: { [contactId: number]: IContact };
  broadcast: IBroadcast;
}

export interface DispatchProps {
  createBroadcast: typeof broadcastActions.createBroadcast;
  getMembers: typeof broadcastActions.getMembers;
  updateBroadcast: typeof broadcastActions.updateBroadcast;
  addMembers: typeof broadcastActions.addMembers;
  deleteMembers: typeof broadcastActions.deleteMembers;
  notificationShow: typeof notificationsActions.notificationShow;
}

type IProps = StateProps & DispatchProps & RouteProps;

interface IState {
  broadcastMembers: IBroadcastMembers;
  filterSelected: 'all' | 'added';
  name: string;
  search: string;
}

export default class BroadcastInfo extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props) {
    super(props);
    this.t = Date.now();
    const { broadcast } = props;
    this.state = {
      broadcastMembers: broadcast?.members || {},
      filterSelected: broadcast?.id ? 'added' : 'all',
      name: broadcast?.name || '',
      search: '',
    };
  }

  componentDidMount() {
    RenderTrack.track('ChatBroadcastInfo', { renderTime: this.t });
    const { myId, broadcast, getMembers } = this.props;
    if (broadcast?.id) getMembers(myId, broadcast.id);
  }

  componentDidUpdate(prevProps: IProps) {
    const { broadcast, getMembers, myId } = this.props;
    if (prevProps.broadcast?.id !== broadcast?.id && broadcast.id) {
      getMembers(myId, broadcast.id);
    }
    if (prevProps.broadcast !== broadcast) {
      this.setState({ name: broadcast.name });
      if (Object.values(broadcast.members).length) this.setState({ broadcastMembers: broadcast.members });
    }
  }

  render() {
    const { broadcast, createBroadcast, history, myId, notificationShow, updateBroadcast } = this.props;
    const { name, broadcastMembers, search, filterSelected } = this.state;

    const contactsToShow = this.getContactsToShow();
    return (
      <S.Container className="chat-broadcast-info-container">
        <S.CardContainer>
          <RowContainer margin="0 0 10px 0">
            <S.Left>
              <InputWithLabel isRequired={true} label={__('Components.Broadcast.name_label')} width="100%">
                <RowContainer>
                  <Input
                    name="name"
                    onBlur={(key: string, value: string | number, error?: string | undefined) => {
                      this.setState({ name: value as string });
                      if (name && broadcast.id) {
                        updateBroadcast(myId, broadcast.id, name, broadcast.display);
                      }
                    }}
                    onChange={(key: string, value: string | number, error?: string | undefined) => {
                      this.setState({ name: value as string });
                    }}
                    value={name}
                    type="text"
                    autoFocus={!broadcast?.id}
                    isRequired={true}
                    placeholder={__('Components.Broadcast.name_placeholder')}
                  />
                  {broadcast.id ? (
                    <SimpleDropdown
                      hAlign="right"
                      onSelect={(key: string) => {
                        if (key === 'archive')
                          updateBroadcast(
                            myId,
                            broadcast.id,
                            broadcast.name,
                            broadcast.display !== CHANNEL_DISPLAY.ARCHIVED
                              ? CHANNEL_DISPLAY.ARCHIVED
                              : CHANNEL_DISPLAY.NORMAL,
                            (error?: Error) => {
                              if (error) {
                                notificationShow(
                                  {
                                    title: __('Notification.error.title'),
                                    subtitle: __('Notification.error.description'),
                                    closable: true,
                                    style: 'error',
                                  },
                                  2000,
                                );
                              } else {
                                const toArchive = broadcast.display !== CHANNEL_DISPLAY.ARCHIVED;
                                notificationShow(
                                  {
                                    title: toArchive
                                      ? __('Notification.archive.title')
                                      : __('Notification.unarchive.title'),
                                    subtitle: toArchive
                                      ? __('Notification.archive.description_broadcast')
                                      : __('Notification.unarchive.description_broadcast'),
                                    closable: true,
                                    style: 'info',
                                  },
                                  2000,
                                );
                              }
                            },
                          );
                      }}
                      options={[
                        {
                          key: 'archive',
                          value:
                            broadcast.display !== CHANNEL_DISPLAY.ARCHIVED
                              ? __('Components.ChatList.options.archive')
                              : __('Components.ChatList.options.unarchive'),
                          icon: 'Archive',
                        },
                      ]}
                      itemMinWidth="100px"
                    >
                      <S.KebabIcon name="Kebab" />
                    </SimpleDropdown>
                  ) : null}
                </RowContainer>
              </InputWithLabel>
            </S.Left>
            <S.Right>
              {broadcast.id ? null : (
                <S.CreateButton
                  id="channel-info-cta-create"
                  disabled={!name || !Object.keys(broadcastMembers).length}
                  onClick={() =>
                    createBroadcast(
                      myId,
                      name,
                      Object.keys(broadcastMembers).map(k => Number(k)),
                      (b: IBroadcast) => history.push(getPath({ path: ROUTE_PATHS.BROADCAST, channelId: b.id })),
                    )
                  }
                >
                  {__('Components.Broadcast.create')}
                </S.CreateButton>
              )}
            </S.Right>
          </RowContainer>
          <MembersSearch
            numMembers={Object.keys(broadcastMembers).length}
            filterSelected={filterSelected}
            setFilterSelected={f => this.setState({ filterSelected: f })}
            setSearch={s => this.setState({ search: s })}
            totalContacts={contactsToShow.length}
            id={broadcast.id || '-new-broadcast'}
          />
          <S.Scroll>
            <ContactsList
              contacts={
                contactsToShow.filter(c => filterSelected !== 'added' || broadcastMembers[c.id]) as Array<ILightContact>
              }
              onSelectContact={this.updateMember}
              contactSelected={(cId: number) => !!broadcastMembers[cId]}
              search={search}
              action="switch"
            />
          </S.Scroll>
        </S.CardContainer>
      </S.Container>
    );
  }

  /**
   * this function will filter the users contacts and return only the ones available to be seen
   */
  private getContactsToShow(): Array<IContact> {
    const { contacts } = this.props;
    const { search } = this.state;
    const f = search && search.length > 1 && utils.toLocaleLowerCaseNormalized(search);
    return Object.values(contacts)
      .filter(contact => {
        return (
          !contact.isUnregistered &&
          (!f ||
            utils.toLocaleLowerCaseNormalized(contact.name).includes(f) ||
            utils.toLocaleLowerCaseNormalized(contact.companyName).includes(f) ||
            contact.phoneNumbers.find((p: string) => p.includes(f)))
        );
      })
      .sort((a, b) => {
        const aName = utils.toLocaleLowerCaseNormalized(a.name);
        const bName = utils.toLocaleLowerCaseNormalized(b.name);
        if (aName > bName) return -1;
        if (aName < bName) return 1;
        return 0;
      });
  }

  /**
   * Add/remove a member from broadcast.
   */
  private updateMember = (mId: number) => {
    const { broadcastMembers } = this.state;
    const { addMembers, myId, broadcast, deleteMembers } = this.props;
    const members = { ...broadcastMembers };
    if (members[mId]) {
      delete members[mId];
      if (broadcast.id) deleteMembers(myId, broadcast.id, mId);
    } else {
      members[mId] = { userId: mId };
      if (broadcast.id) addMembers(myId, broadcast.id, [mId]);
    }
    this.setState({ broadcastMembers: members });
  };
}
