import {
  Api,
  buyerWorkspaceActions,
  CHANNEL_TYPE,
  chatActions,
  contactActions,
  imageActions,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navChannelAction, navigateChannelBySection, navigateToShowroom, navMiniChannelAction } from '../actions/nav';
import Chat, { IChatDispatchProps, IChatStateProps } from '../components/organisms/Chat';
import { IChatListRouteProps } from '../components/organisms/ChatList';
import { IReduxState } from '../reducers';
import { navSelectors } from '../selectors';

const emptyMessages = [];

function mapStateToPropsChat(state: IReduxState, props: IChatListRouteProps): IChatStateProps {
  const channelId = props.match.params.channelId;
  const {
    catalog: { catalogs },
    chat: { channels, initialized, lastMessageAt, lastReadContactAt, messages, drafts, unreadMessageCount },
    contact: { inConsentio },
    prodType: { prodTypes },
    product: { prices },
    user: { user },
  } = state;
  const channel = channels[channelId];
  const chatStateProps = {
    channel,
    initialized,
    messages: emptyMessages,
    me: user,
    prodTypes,
    channels,
    prices,
    contacts: inConsentio,
    lastMessageAt,
    lastReadContactAt: lastReadContactAt[channelId] || 0,
    workspaceSelected: navSelectors.getSelectedWorkspace(state),
    unreadMessageCount: unreadMessageCount[channelId],
  };
  if (!channel) {
    return chatStateProps;
  }

  const contactId = channel.type === CHANNEL_TYPE.PRIVATE ? channel.members?.[0]?.id : 0;
  const contact = contactId ? inConsentio[contactId] : undefined;
  const catalog = catalogs[user.sellerWorkspaceId];
  return {
    ...chatStateProps,
    canSell: user.settings.isSeller && contact?.isBuyer,
    canBuy: user.settings.isBuyer && contact?.isSeller,
    catalog,
    channel,
    contact,
    draft: drafts && drafts[channelId],
    imBlocking: contact?.imBlocking,
    isUnregistered: contact?.isUnregistered,
    messages: messages[channelId] || emptyMessages,
  };
}

/**
 * Wrap the openChannel action. call contactUpdate if the channel is private
 */
export function openChannel(
  myId: number,
  channelId: string,
  cb?: (isAll?: boolean, messages?: Array<IMessage>, error?: Error) => void,
) {
  return (d: (object: any) => void, getState: () => IReduxState) => {
    d(chatActions.openChannel(myId, channelId, cb));
    const { chat, contact } = getState();
    const channel = chat.channels[channelId];
    if (channel.type === CHANNEL_TYPE.PRIVATE) {
      const c = contact.inConsentio[channel.members?.[0]?.id];
      if (c && c.isNew) d(contactActions.contactUpdate(myId, { ...c, isNew: false }));
    }
  };
}

function mapDispatchToPropsChat(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IChatListRouteProps,
): IChatDispatchProps {
  return bindActionCreators(
    {
      addMembers: chatActions.addMembers,
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      suppliersAdd: buyerWorkspaceActions.suppliersAdd,
      addMessageReaction: chatActions.addMessageReaction,
      contactBlock: contactActions.contactBlock,
      contactsInvite: contactActions.contactsInvite,
      deleteMessage: chatActions.deleteMessage,
      editMessage: chatActions.editMessage,
      forwardMessage: chatActions.forwardMessage,
      getMessages: chatActions.getMessages,
      getPrices: productActions.getPrices,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navChannelAction,
      navigateChannelBySection,
      navigateToShowroom,
      navMiniChannelAction,
      notificationShow: notificationsActions.notificationShow,
      openChannel,
      orderClone: orderActions.orderClone,
      removeMessageReaction: chatActions.removeMessageReaction,
      sendMessage: chatActions.sendMessage,
      setActiveChannel: chatActions.setActiveChannel,
      setMessageDraft: chatActions.setMessageDraft,
      touchFile: chatActions.downloadFile,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChat, mapDispatchToPropsChat)(Chat);
