import styled, { ITheme } from '../../../styled-components';
import { RowContainer, LettersAvatar as LA, FontIcon, TextLabel, ColumnContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  text-decoration: none;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 2px ${(p: IStyledProps) => p.theme.paddingSize()};
  cursor: pointer;
  align-items: center;
  height: 40px;
  min-width: 180px;
  max-width: 250px;
  overflow: hidden;
`;

export const LettersAvatar = styled(LA)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const NoSelectedContainer = styled(ColumnContainer)`
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
  justify-content: center;
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextName = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 168px;
  width: 100%;
`;

export const DownIcon = styled(FontIcon)`
  align-self: center;
  max-width: 30px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0 12px;
`;

export const MemberIcon = styled(FontIcon)`
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
