import {
  __,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_MEMBER_STATUS,
  chatActions,
  debounce,
  ModalActions,
  modalActions,
  ORDER_FILE_TYPE,
  ORDER_ORIGIN,
  orderService,
  productService,
  userService,
  utils,
} from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import { History } from 'history';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { MAX_FILE_SIZE } from '../../../../../constants';
import { convertToIFile, downloadFile } from '../../../../../services/file';
import { FileModal, Message, MessageInput, NavigationTabs } from '../../../../molecules';
import Attachments from '../Attachments';
import * as S from './Comments.styled';

interface IProps {
  addMessageReaction: (myId: number, channelId: string, messageId: number, reaction: string) => void;
  amSeller: boolean;
  removeMessageReaction: (myId: number, channelId: string, messageId: number, reaction: string) => void;
  catalog: IWorkspace;
  clients: Array<IClient>;
  contactId: number;
  commentsChannel: IChannel;
  history: History<any>;
  initialComments?: Array<IMessage>;
  isUnregistered: boolean;
  isContactBlocked: boolean;
  contacts: { [cId: number]: IContact };
  getMessages: typeof chatActions.getMessages;
  commentsId: string;
  commentsUnreadCount?: number;
  disabled?: boolean;
  lastReadAt: number;
  lastReadContactAt: number;
  me: IUser;
  modalOpen: (text: string, action?: any, extra?: IModalExtraInfo, name?: IModalName) => ModalActions;
  onLastMessageAt: (lastMessageAt: number) => void;
  order: IOrder;
  orderAttachmentDownload: (myId: number, orderId: number, attachmentId: number, cb?: (data: string) => void) => void;
  orderAttachmentsGet: (myId: number, orderId: number, cb?: (attachments: Array<IAttachment>) => void) => void;
  orderAttachmentUpload: (myId: number, orderId: number, attachment: IAttachment, cb?: (error?: Error) => void) => void;
  orderCommentSend: (myId: number, orderId: number, messages: Array<IMessage>, cb?: (err?: Error) => void) => void;
  orderPreviousCommentAdd: (m: IMessage) => void;
  prodTypes?: { [key: string]: IProdType };
  showRibbon: (commentsRibbon: string, updatesRibbon: string) => void;
  supportAction: () => void;
  touchFile: typeof chatActions.downloadFile;
  touchImage: typeof modalActions.touchImage;
  orderOriginalFileDownload: () => void;
  updatesUnreadCount?: number;
}

type CommentSection = 'messages' | 'files' | 'activity' | 'all';

interface IState {
  attachments: Array<IAttachment>;
  categoryFilter?: ORDER_FILE_TYPE;
  comment: string;
  commentsUnreadCount?: number;
  filePreview?: IFile;
  isAll: boolean;
  messages: Array<IMessage>;
  section: CommentSection;
  showBannerUnread?: number;
  updatesUnreadCount?: number;
}

class Comments extends React.PureComponent<IProps, IState> {
  private removeUnreadBanner = debounce(() => {
    this.setState({ showBannerUnread: undefined, commentsUnreadCount: 0, updatesUnreadCount: 0 });
  }, 5000);
  /**
   * Call to the server for comments.
   */
  private getMessages = debounce((messageId?: number) => {
    const { getMessages, me, order } = this.props;
    if (order.commentsChannel) getMessages(order.commentsChannel, me.id, messageId, this.addMessages);
  }, 250);
  /**
   * Call to the server for order attachments.
   */
  private getAttachments = debounce((showAttachments?: boolean) => {
    const { orderAttachmentsGet, me, order } = this.props;
    const { section } = this.state;
    orderAttachmentsGet(me.id, order.id, attachments =>
      this.setState({
        attachments,
        section: showAttachments ? 'files' : section,
      }),
    );
  }, 250);

  /**
   * Send new order message
   */
  private sendComments = debounce((comment: string) => {
    if (!comment.trim()) return;
    const { order, orderCommentSend, orderPreviousCommentAdd, me } = this.props;
    const m: IMessage = {
      channelId: 'order_' + order.id,
      createdAt: new Date().getTime() + 60000,
      extraData: {},
      message: comment.trim(),
      messageId: 0,
      messageType: 'text' as 'text',
      reactions: {},
      senderId: me.id!,
    };
    if (order.id) {
      orderCommentSend(me.id!, order.id, [m], (err: Error) => {
        if (!err) {
          this.setState({ messages: [m, ...this.state.messages], comment: '' });
        }
      });
    } else {
      orderPreviousCommentAdd(m);
      this.setState({ messages: [m, ...this.state.messages], comment: '' });
    }
  }, 500);

  constructor(props: IProps) {
    super(props);
    this.state = {
      attachments: [],
      comment: '',
      isAll: true,
      messages: [],
      section: 'all',
      showBannerUnread: props.lastReadAt,
      commentsUnreadCount: props.commentsUnreadCount,
      updatesUnreadCount: props.updatesUnreadCount,
    };
  }

  public componentDidMount() {
    const { initialComments, me, order } = this.props;
    if (order?.commentsChannel) {
      this.getMessages();
    }

    const mockMessage: IMessage = {
      channelId: 'order_' + order.id,
      createdAt: new Date().getTime() + 60000,
      extraData: {
        code: 'price_disclaimer',
        triggered_by: 123,
        receiver: 456,
      },
      messageId: 0,
      message: 'Mock message',
      messageType: 'admin',
      reactions: {},
      senderId: 1,
    };

    if (order?.id) this.getAttachments();

    this.setState({
      messages: [
        ...(order.moreInfo
          ? [
              {
                channelId: '',
                createdAt: new Date(order.createdAt).getTime(),
                extraData: {},
                message: order.moreInfo,
                messageId: 0,
                messageType: 'text' as 'text',
                reactions: {},
                senderId: me.id,
              },
            ]
          : initialComments || []),
        ...(config.TOGGLE_MO_PRICES_INFO.enabled && order.origin === ORDER_ORIGIN.IMPORT_UI && mockMessage
          ? [mockMessage]
          : []),
      ],
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { commentsChannel, order, commentsUnreadCount, lastReadAt, showRibbon, updatesUnreadCount } = this.props;
    const { messages } = this.state;
    if (
      (prevProps.order !== order && order.commentsChannel) ||
      (prevProps.commentsChannel !== commentsChannel && commentsChannel)
    ) {
      this.getMessages();
    } else if (prevProps.order?.id !== order?.id) {
      this.setState({
        attachments: [],
        comment: '',
        isAll: true,
        messages: [],
        section: 'all',
      });
    }
    if (prevState.messages.length !== messages.length && messages.length) {
      showRibbon(
        commentsUnreadCount ? this.getCommentsRibbonsLiterals() : '',
        updatesUnreadCount ? this.getUpdatesRibbonsLiterals() : '',
      );
    }
    if (!commentsUnreadCount && prevProps.commentsUnreadCount) {
      this.removeUnreadBanner();
    } else if (
      !prevProps.commentsChannel ||
      (commentsUnreadCount && !prevProps.commentsUnreadCount) ||
      (updatesUnreadCount && !prevProps.updatesUnreadCount)
    ) {
      this.setState({ showBannerUnread: lastReadAt, commentsUnreadCount, updatesUnreadCount });
    }
  }

  public render() {
    const { isContactBlocked, me, order, disabled } = this.props;
    const { comment, section } = this.state;
    return (
      <>
        <S.NavigationTabsContainer>
          <NavigationTabs selected={section} tabs={this.getCommentFiltersOptions()} history={{ push: () => null }} />
        </S.NavigationTabsContainer>
        <S.Container numHeaders={2}>
          {isContactBlocked || disabled ? null : (
            <MessageInput
              me={me}
              onChangeText={(text: string) => this.setState({ comment: text })}
              onFileUpload={this.onFileUpload}
              onSendFiles={files => this.sendFile(files)}
              onSendMessage={this.sendComments}
              showSendFiles={!!order.id}
              text={comment}
              placeholder={__('Components.OrderDetails.comments_placeholder')}
            />
          )}
          {this.renderActiveSection()}
        </S.Container>
        {this.renderAttachmentModal()}
      </>
    );
  }
  /**
   * Get date range filter options
   */
  private getCommentFiltersOptions = () => {
    const { commentsChannel } = this.props;
    const totalComments = commentsChannel?.extraData?.totals?.comments || 0;
    const totalUpdates = commentsChannel?.extraData?.totals?.updates || 0;
    const totalAttachments = commentsChannel?.extraData?.totals?.attachments
      ? Object.values(commentsChannel?.extraData?.totals?.attachments).reduce((acc: number, a: number) => acc + a, 0)
      : 0;
    return [
      {
        id: 'all',
        label: __('Messages.Comments.all') + ` (${totalComments + totalUpdates + totalAttachments})`,
        action: () =>
          this.setState({
            section: 'all',
          }),
      },
      {
        id: 'messages',
        label: __('Messages.Comments.messages') + ` (${totalComments})`,
        action: () =>
          this.setState({
            section: 'messages',
          }),
      },
      {
        id: 'files',
        label: __('Messages.Comments.files') + ` (${totalAttachments})`,
        action: () =>
          this.setState({
            section: 'files',
          }),
      },
      {
        id: 'activity',
        label: __('Messages.Comments.activity') + ` (${totalUpdates})`,
        action: () =>
          this.setState({
            section: 'activity',
          }),
      },
    ];
  };

  /**
   * Render active section
   */
  private renderActiveSection() {
    const { clients, contacts, commentsChannel, commentsId, me, order, orderAttachmentDownload, touchImage } =
      this.props;
    const { section, messages, attachments } = this.state;

    return (
      <S.MessagesContainer id={commentsId}>
        {section === 'all' || section === 'messages' || section === 'activity' ? this.renderComments() : null}
        {section === 'files' || (!messages.length && attachments.length) ? (
          <Attachments
            attachments={attachments}
            clients={clients}
            commentsChannel={commentsChannel}
            contacts={contacts}
            me={me}
            order={order}
            orderAttachmentDownload={orderAttachmentDownload}
            touchImage={touchImage}
          />
        ) : null}
      </S.MessagesContainer>
    );
  }

  /**
   * Render comment messages
   */
  private renderComments() {
    const {
      amSeller,
      catalog,
      history,
      isUnregistered,
      lastReadContactAt,
      me,
      order,
      orderAttachmentDownload,
      orderOriginalFileDownload,
      prodTypes,
      touchImage,
      supportAction,
    } = this.props;
    const { isAll, messages, section, showBannerUnread, commentsUnreadCount, updatesUnreadCount } = this.state;
    const { priceMode } = order;
    const showPrice = amSeller || priceMode !== 'none';
    const filteredMessages = messages.reduce((acc, message) => {
      // If showPrice is not true
      // We need to filter out the messages related to price changes
      const {
        extraData: { code, content },
      } = message;
      if (showPrice) {
        acc.push(message);
        return acc;
      } else if (code !== 'updated_order') {
        acc.push(message);
        return acc;
      }

      // Filter the message content of type price_changed
      const filteredContent = content.filter(c => {
        const { type } = c;
        return ![
          'price_changed',
          'price_update',
          'custom_item_added',
          'custom_item_removed',
          'custom_item_changed',
        ].includes(type);
      });
      if (filteredContent.length > 0)
        acc.push({ ...message, extraData: { ...message.extraData, content: [...filteredContent] } });
      return acc;
    }, [] as Array<IMessage>);
    const members = this.getChannelMembers(filteredMessages);

    return (
      <>
        {filteredMessages
          ? filteredMessages
              .filter(m => {
                return (
                  (section !== 'activity' && m.messageType !== 'admin') ||
                  (section === 'messages' && m.messageType === 'text') ||
                  (['activity', 'all'].includes(section) &&
                    [
                      'accepted_order',
                      'canceled_order',
                      'created_order',
                      'updated_order',
                      'order_external_id_updated',
                      'order_sent_to_erp',
                      'price_disclaimer',
                    ].includes(m.extraData.code))
                );
              })
              .map((m, i, list) => {
                const previous = list[i - 1];
                const later = list[i + 1];
                const isFirst = !previous || differenceInCalendarDays(m.createdAt, previous.createdAt) !== 0;
                const sender = this.getSender(m.senderId);
                const firstUnread =
                  showBannerUnread &&
                  ((!later && showBannerUnread < m.createdAt) ||
                    (later &&
                      showBannerUnread >= later.createdAt &&
                      showBannerUnread < m.createdAt &&
                      m.senderId !== me.id));
                if (
                  order?.origin !== ORDER_ORIGIN.INTERNAL &&
                  m.messageType === 'admin' &&
                  m.extraData.code === 'created_order'
                ) {
                  m.extraData.content = { isImported: true, link: __('Constants.download_file') };
                }
                let senderName =
                  m.messageType !== 'admin' || amSeller
                    ? userService.getDisplayName(sender.name, sender.companyName)
                    : sender.companyName;
                const isOrderUpdate = ['updated_order', 'order_external_id_updated', 'order_sent_to_erp'].includes(
                  m.extraData?.code,
                );
                if (isOrderUpdate && m.extraData?.content?.[0]?.is_automatic) {
                  senderName = sender.companyName + ' ' + __('Messages.Admin.automatic');
                }
                return (
                  <React.Fragment key={m.messageId + m.message + i}>
                    {isFirst && (
                      <Message
                        history={history}
                        isDate={true}
                        key={m.messageId + m.createdAt}
                        message={m}
                        senderAvatar={sender.avatar}
                        senderAvatarColor={sender.avatarColor}
                        senderName={senderName}
                        isComment={true}
                      />
                    )}
                    {m.messageType !== 'attachment' || section === 'all' ? (
                      <Message
                        history={history}
                        // TODO enable when move realtime to the chat screen.
                        // now we can't do it because update last message in the store.
                        // addMessageReaction={addMessageReaction}
                        // removeMessageReaction={removeMessageReaction}
                        isFirst={true}
                        isOrderUpdate={isOrderUpdate}
                        isRead={!isUnregistered && lastReadContactAt >= m.createdAt}
                        key={m.messageId + m.message}
                        me={me}
                        message={m}
                        navigateToShowroom={() => null}
                        senderAvatar={m.messageType === 'admin' ? catalog?.companyLogo : sender.avatar}
                        senderAvatarColor={sender.avatarColor}
                        senderName={senderName}
                        touchImage={touchImage}
                        touchFile={(url: string, cb: (data: string) => void): any =>
                          m.extraData.id
                            ? orderAttachmentDownload(me.id, order.id, m.extraData.id, dataFile =>
                                downloadFile(dataFile, m.extraData.mimeType, m.extraData.filename),
                              )
                            : downloadFile(m.extraData.data, m.extraData.mimeType, m.extraData.filename)
                        }
                        action={
                          m.extraData.content?.isImported
                            ? orderOriginalFileDownload
                            : m.extraData.code === 'price_disclaimer'
                            ? supportAction
                            : undefined
                        }
                        members={members}
                        prodTypes={prodTypes}
                        isComment={true}
                      />
                    ) : null}
                    {firstUnread ? (
                      <UnreadMessage
                        commentsUnreadCount={commentsUnreadCount}
                        updatesUnreadCount={updatesUnreadCount}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })
          : null}
        {isAll ? null : (
          <S.MoreWrapper>
            <S.Link onClick={() => this.getMessages(messages[messages.length - 1].createdAt)}>
              {__('Components.ProductsList.ShowMore')}
            </S.Link>
          </S.MoreWrapper>
        )}
      </>
    );
  }

  /**
   * Render order's modal attachments
   */
  private renderAttachmentModal() {
    const { filePreview } = this.state;
    const { order } = this.props;
    if (!filePreview) return null;
    return (
      <FileModal
        orderHash={order.externalIdSeller || order.externalIdBuyer || '#' + order.hashId}
        file={filePreview}
        modalClose={() => this.setState({ filePreview: undefined })}
        sendFile={(fileType: ORDER_FILE_TYPE) => this.uploadFile(filePreview, fileType)}
      />
    );
  }

  /**
   * Set the new messages to the state.
   * Communicate lastMessageAt to the parent in order to mark it as read if comments is visible on the view port.
   * Communicate unread count to the parent in order to show comments unread header.
   */
  private addMessages = (isAll: boolean, ms: Array<IMessage>) => {
    const { onLastMessageAt } = this.props;
    if (ms?.length) {
      onLastMessageAt(ms[0].createdAt);
    }
    const messages = [...this.state.messages];
    ms?.forEach(m => {
      const idx = messages.findIndex(
        item => m.messageId === item.messageId || (item.messageId === 0 && item.message === m.message),
      );
      if (idx !== -1) {
        messages[idx] = m;
      } else {
        if (messages.length && m.createdAt < messages[messages.length - 1].createdAt) {
          messages.push(m);
        } else {
          messages.unshift(m);
        }
      }
    });

    this.setState({ messages, isAll });
  };

  /**
   * On file upload open modal for preview
   */
  private onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { modalOpen } = this.props;

    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > MAX_FILE_SIZE) {
        modalOpen(__('Components.Chat.max_size_exceeded', { max: 3 }));
        return;
      }

      this.sendFile(Array.from(e.target.files));
    }
  };

  /**
   * Show a file preview to add an attachment file
   */
  private sendFile = (files: Array<File>) => {
    convertToIFile(files[0], (file: IFile) => this.setState({ filePreview: file }));
  };

  /**
   * Upload file or image to the server
   */
  private uploadFile = (file: IFile, fileType: ORDER_FILE_TYPE) => {
    const { me, order, orderAttachmentUpload } = this.props;
    const attachment = orderService.emptyAttachment(order.id, me.id);
    attachment.data = file.content;
    attachment.filename = file.name;
    attachment.mimeType = file.type;
    attachment.size = file.size;
    attachment.type = fileType;
    orderAttachmentUpload(me.id, order.id, attachment, err => {
      if (!err) {
        this.setState({ section: 'files', attachments: [attachment, ...this.state.attachments] });
      }
    });
    this.setState({ filePreview: undefined });
  };

  private getSender(senderId: number) {
    const { me, contacts, clients, commentsChannel } = this.props;
    if (me.id === senderId) {
      return {
        avatar: me.settings.avatar,
        avatarColor: utils.getAvatarColor(me.name),
        name: me.name,
        companyName: me.companyName,
      };
    }

    const sender = contacts[senderId];
    if (sender) {
      return {
        avatar: sender.avatar,
        avatarColor: sender.avatarColor,
        name: sender.name,
        companyName: sender.companyName,
      };
    }

    const senderMember = commentsChannel?.members.find(c => c.id === senderId);
    if (senderMember) {
      return {
        avatar: senderMember.avatar,
        avatarColor: senderMember.avatarColor,
        name: senderMember.name,
        companyName: senderMember.companyName,
      };
    }

    const senderClient = clients?.find(c => c.userId === senderId);
    if (senderClient) {
      return {
        avatar: senderClient.avatar,
        avatarColor: utils.getAvatarColor(senderClient.name),
        name: senderClient.name,
        companyName: senderClient.company || '',
      };
    }

    return {
      avatar: '',
      avatarColor: { text: '', background: '' },
      name: '',
      companyName: '',
    };
  }

  private calculateUpdates() {
    const { updatesUnreadCount } = this.props;
    const { messages } = this.state;
    return messages
      .filter(m => m.messageType === 'admin' && m.extraData.code === 'updated_order')
      .slice(0, updatesUnreadCount)
      .reduce(
        (acc, m) => {
          m.extraData?.content.forEach((i: any) => {
            switch (i.type) {
              case 'price_changed':
              case 'price_added':
                if (!acc.price.find((it: IOrderItem) => it.childId === i.item.childId)) {
                  acc.price.push(i);
                }
                break;
              case 'item_removed':
              case 'item_added':
              case 'amount_changed':
                if (!acc.quantity.find((it: IOrderItem) => it.childId === i.item.childId)) {
                  acc.quantity.push(i);
                }
                break;
              case 'custom_item_added':
              case 'custom_item_removed':
              case 'custom_item_changed':
                if (!acc.custom.find((ic: ICustomItem) => ic.id === i.customItem.id)) {
                  acc.custom.push(i);
                }
                break;
              case 'pickup_address_modified':
              case 'pickup_address_added':
              case 'delivery_address_modified':
              case 'delivery_address_added':
              case 'delivery_date_added':
              case 'delivery_date_modified':
              case 'pickup_date_added':
              case 'pickup_date_modified':
                acc.logistic.push(i);
            }
          });
          return acc;
        },
        { quantity: [], price: [], custom: [], logistic: [] },
      );
  }

  private getCommentsRibbonsLiterals = () => {
    const { commentsUnreadCount } = this.props;
    const { messages } = this.state;
    const sender = this.getSender(messages.find(m => m.messageType !== 'admin').senderId);
    return __('Components.Cart.unread_comments', {
      count: commentsUnreadCount,
      name: userService.getDisplayName(sender.name, sender.companyName),
    });
  };

  private getUpdatesRibbonsLiterals = () => {
    const { prodTypes } = this.props;
    const updates = this.calculateUpdates();
    const pricesCount = updates.price.length;
    const quantityCount = updates.quantity.length;
    const logisticCount = updates.logistic.length;
    const customCount = updates.custom.length;
    if (pricesCount && !quantityCount && !logisticCount && !customCount) {
      const item = updates.price[0].item;
      return __('Components.Cart.unread_update_price', {
        count: pricesCount,
        product: productService.getProductTypeVarietyDisplay(item.type, prodTypes?.[item.type]?.name || '', item.title),
      });
    }
    if (!pricesCount && quantityCount && !logisticCount && !customCount) {
      const item = updates.quantity[0].item;
      return __('Components.Cart.unread_update_quantity', {
        count: quantityCount,
        product: productService.getProductTypeVarietyDisplay(item.type, prodTypes?.[item.type]?.name || '', item.title),
      });
    }
    if (!pricesCount && !quantityCount && logisticCount && !customCount) {
      return __('Components.Cart.unread_update_logistic');
    }
    if (!pricesCount && !quantityCount && !logisticCount && customCount) {
      const customItem = updates.custom[0].customItem;
      return __('Components.Cart.unread_update_custom', {
        count: customCount,
        custom: customItem.title,
      });
    }
    if (pricesCount && quantityCount && !logisticCount && !customCount) {
      return __('Components.Cart.unread_updates', {
        count: updates.quantity.reduce(
          (acc: Array<number>, i: any) => {
            if (!acc.includes(i.item.childId)) {
              acc.push(i.item.childId);
            }
            return acc;
          },
          updates.price.map(i => i.item.childId),
        ).length,
      });
    }
    return __('Components.Cart.unread_updates_all');
  };

  /**
   * Get channel members from messages
   */
  private getChannelMembers = (messages: Array<IMessage>): Array<IMember> => {
    const { me, commentsChannel } = this.props;
    const members = [...(commentsChannel?.members || [])];

    members.push({
      avatar: me.settings.avatar,
      avatarColor: utils.getAvatarColor(me.name),
      companyName: me.companyName,
      id: me.id!,
      name: me.name,
      role: CHANNEL_MEMBER_ROLE.VIEWER,
      status: CHANNEL_MEMBER_STATUS.ACTIVE,
    });

    for (const message of messages) {
      if (!members.find(m => m.id === message.senderId)) {
        const sender = this.getSender(message.senderId);
        members.push({
          avatar: sender.avatar,
          avatarColor: utils.getAvatarColor(sender.name),
          companyName: sender.companyName,
          id: message.senderId,
          name: sender.name,
          role: CHANNEL_MEMBER_ROLE.VIEWER,
          status: CHANNEL_MEMBER_STATUS.ACTIVE,
        });
      }
    }
    return members;
  };
}

export default Comments;

const UnreadMessage = ({
  commentsUnreadCount,
  updatesUnreadCount,
}: {
  commentsUnreadCount: number;
  updatesUnreadCount: number;
}) => {
  let text = '';
  if (commentsUnreadCount) {
    text = updatesUnreadCount
      ? __('Components.Chat.unread_comments_updates', {
          count_comments: commentsUnreadCount,
          count_updates: updatesUnreadCount,
        })
      : __('Components.Chat.unread_comments', { count: commentsUnreadCount });
  } else if (updatesUnreadCount) {
    text = __('Components.Chat.unread_updates', { count: updatesUnreadCount });
  }
  return text ? (
    <S.UnreadMessage>
      <S.UpIcon name="Arrow" />
      {text.toUpperCase()}
      <S.UpIcon name="Arrow" />
    </S.UnreadMessage>
  ) : null;
};
