import {
  __,
  CHANNEL_DISPLAY,
  CHANNEL_TYPE,
  chatService,
  EventTrack,
  ModalActions,
  notificationsActions,
  qs,
  RenderTrack,
  utils,
} from 'common-services';
import * as React from 'react';

import config from '../../../../bindings/config';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { Switch } from '../../atoms';
import ActionsModal from '../../molecules/ActionsModal';
import ContactItemSelectable from '../../molecules/ContactItemSelectable';
import Share from '../../molecules/Share';
import QRCode from './Fragments/QRCode.component';
import * as S from './ProductListShare.styled';

export interface IOwnProps {
  catalogId: number;
  close?: () => void;
  contactId?: number;
  ownerId: number;
  history: { push: (path: string) => void };
  product?: GenericProduct;
  numResults?: number;
  productHashId?: string;
  productName?: string;
  search?: string;
  amSeller: boolean;
}

export interface IStateProps {
  broadcasts: { [broadcastId: string]: IBroadcast };
  catalog: IWorkspace;
  channels: { [channelId: string]: IChannel };
  contact: IContact;
  contacts: { [contactId: number]: IContact };
  lastMessageAt: Record<string, number>;
  broadcastLastMessageAt: Record<string, number>;
  unreadMessageCount: Record<string, number>;
  me: IUser;
  priceListUrl: string;
  publicPriceList: boolean;
}

export interface IDispatchProps {
  catalogUpdate: (myId: number, catalog: IWorkspace) => void;
  modalClose: () => ModalActions;
  modalOpen: (text: string, action: any, extra?: IModalExtraInfo) => ModalActions;
  navigateChannelBySection: (
    section: CHANNEL_SECTIONS,
    channelId: string,
    isContact: boolean,
    cb: (path: string) => void,
  ) => void;
  productShare: (
    myId: number,
    productId: number,
    receivers: Array<{ channelId?: string; contactId?: number; broadcastId?: number }>,
  ) => void;
  sendBroadcastMessage: (myId: number, msg: IMessage) => void;
  sendMessage: (myId: number, msg: IMessage) => void;
  notificationShow: typeof notificationsActions.notificationShow;
}

export type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
  filter: string;
  selected: Array<string>;
  selectedBroadcast: Array<string>;
  showQRCode: boolean;
}

export default class ProductListShare extends React.Component<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      filter: '',
      selected: [],
      selectedBroadcast: [],
      showQRCode: false,
    };
  }

  public componentDidMount() {
    RenderTrack.track('ProductListShare', { renderTime: this.t });
  }

  public render() {
    const { showQRCode } = this.state;
    const { close, productHashId, search } = this.props;

    return (
      <ActionsModal
        onClose={close}
        title={showQRCode ? __('ProductListShare.share_qr') : this.renderShareTitle(search, productHashId)}
      >
        <S.FormContainer>
          {showQRCode ? null : this.renderShareSubTitle(search, productHashId)}
          {showQRCode ? null : this.renderShareCatalog()}
          {showQRCode ? this.renderQRCode() : this.renderSearchAndChannels()}
        </S.FormContainer>
      </ActionsModal>
    );
  }

  /**
   * Render title for share product / catalog
   */
  private renderShareTitle(search: string, productHashId: string) {
    const typeText = search
      ? __('ProductListShare.search')
      : productHashId
      ? __('ProductListShare.product')
      : __('ProductListShare.catalog');
    return `${__('ProductListShare.share1')} ${typeText}`;
  }

  /**
   * Render title for share product / catalog
   */
  private renderShareSubTitle(search: string, productHashId: string) {
    const primaryText = search
      ? __('ProductListShare.header_text_primary_search', { search })
      : productHashId
      ? __('ProductListShare.header_text_primary_product')
      : __('ProductListShare.header_text_primary');
    return (
      <S.SubtitleContent>
        <S.SubtitleText>{__('ProductListShare.header_text')}</S.SubtitleText>
        <S.SubtitleTextPrimary> {primaryText} </S.SubtitleTextPrimary>
      </S.SubtitleContent>
    );
  }

  /**
   * Render share catalog or product
   */
  private renderShareCatalog() {
    const { catalog, catalogUpdate, contacts, me, ownerId, publicPriceList, notificationShow } = this.props;
    if (publicPriceList) {
      const urlToShare = this.getUrlToShare();
      return (
        <Share
          pricelistOwner={this.getPricelistOwner()}
          toggleQRCodeVisibility={() => {
            this.setState({ showQRCode: true });
            this.trackShare(EventTrack.SHARE_MEDIUM.QR);
          }}
          urlToShare={urlToShare}
          trackShare={this.trackShare}
          notificationShow={notificationShow}
        />
      );
    }
    return (
      <S.Column>
        <S.SubtitleText>
          {me.id !== ownerId
            ? __('ProductListShare.not_pricelist_buyer', { sellerName: contacts[ownerId].name })
            : __('ProductListShare.not_pricelist_seller')}
        </S.SubtitleText>
        <S.ActionRow>
          {me.id === ownerId ? <S.SubtitleText>{__('ProductListShare.open_catalog')}</S.SubtitleText> : null}
          {me.id === ownerId ? (
            <Switch
              name="settings"
              isChecked={publicPriceList}
              onChange={(n, checked) => catalogUpdate(me.id, { ...catalog, publicPriceList: checked })}
            />
          ) : null}
        </S.ActionRow>
      </S.Column>
    );
  }

  /**
   * Render QR code content
   */
  private renderQRCode() {
    const { contacts, me, ownerId, priceListUrl, productHashId, productName } = this.props;
    const amOwner = me.id === ownerId;
    return (
      <QRCode
        amOwner={amOwner}
        contactName={!amOwner && contacts[ownerId] ? contacts[ownerId].name : undefined}
        isPublicUrl={true}
        priceListUrl={priceListUrl}
        productHashId={productHashId}
        productName={productName}
        url={this.getUrlToShare()}
      />
    );
  }

  /**
   * Render search input + channel list
   */
  private renderSearchAndChannels() {
    const { selected, selectedBroadcast } = this.state;
    const channelViews = this.getChannelViews();

    return (
      <React.Fragment>
        <S.Search
          onChange={s => this.setState({ filter: s })}
          placeHolder={__('ProductListShare.search_placeholder')}
          id="input_search_product_share"
        />
        {this.renderChannelsList(channelViews)}
        {selected.length || selectedBroadcast.length ? (
          <S.CTA iconName="Send-message" iconSize="23px" id="button-next-share-product" onClick={this.onNext}>
            {__('ProductListShare.cta', { number: selected.length + selectedBroadcast.length })}
          </S.CTA>
        ) : null}
      </React.Fragment>
    );
  }

  /**
   * Render contact list (in + out consentio)
   */
  private renderChannelsList(channelViews: Array<IChannelView>) {
    const { contactId } = this.props;
    const { filter } = this.state;
    const f = filter && filter.length > 1 && utils.toLocaleLowerCaseNormalized(filter);
    const channelsToShow = channelViews.filter(
      channelView => !f || utils.toLocaleLowerCaseNormalized(channelView.name || '').includes(f),
    );
    const currentChannelIndex = contactId ? channelsToShow.findIndex(c => c.contactId === contactId) : -1;
    let currentChannel;
    if (currentChannelIndex !== -1) {
      currentChannel = channelsToShow.splice(currentChannelIndex, 1);
    }
    const latestChannels = channelsToShow.splice(0, 3);

    return (
      <S.Scroll>
        {currentChannel ? (
          <S.Contacts>
            <S.SectionTitle>{__('Components.ProductListShare.current')}</S.SectionTitle>
            {this.renderChannelItem(currentChannel[0], 0, true)}
          </S.Contacts>
        ) : null}
        <S.Contacts>
          <S.SectionTitle>{__('Components.ProductListShare.frequent')}</S.SectionTitle>
          {latestChannels.map((channelView, index, arr) =>
            this.renderChannelItem(channelView, index, index === arr.length - 1),
          )}
        </S.Contacts>
        {channelsToShow.length ? (
          <S.Contacts>
            <S.SectionTitle>{__('Components.ProductListShare.recent_chats')}</S.SectionTitle>
            {channelsToShow.map((channelView, index, arr) =>
              this.renderChannelItem(channelView, index, index === arr.length - 1),
            )}
          </S.Contacts>
        ) : null}
      </S.Scroll>
    );
  }

  /**
   * Render selectable contact item
   */
  private renderChannelItem(channelView: IChannelView, index: number, isLast: boolean) {
    const { lastMessageAt } = this.props;
    const { selected, selectedBroadcast } = this.state;
    const { id, avatar, avatarColor, name, subtitle, type } = channelView;
    const selectedIdx = type === 'broadcast' ? selectedBroadcast.indexOf(id) : selected.indexOf(id);
    const isSelected = selectedIdx !== -1;
    const { me } = this.props;
    return (
      <ContactItemSelectable
        key={index + '-' + id}
        avatar={avatar}
        avatarColor={avatarColor}
        isAdmin={false}
        isLast={isLast}
        selected={isSelected}
        time={lastMessageAt[id]}
        dateFormat={me.settings.dateFormat}
        hourFormat={me.settings.hourFormat}
        onPress={() => {
          if (type === 'broadcast') {
            const selectedCopy = selectedBroadcast.slice();
            if (isSelected) {
              selectedCopy.splice(selectedIdx, 1);
            } else {
              selectedCopy.push(id);
            }
            this.setState({ selectedBroadcast: selectedCopy });
          } else {
            const selectedCopy = selected.slice();
            if (isSelected) {
              selectedCopy.splice(selectedIdx, 1);
            } else {
              selectedCopy.push(id);
            }
            this.setState({ selected: selectedCopy });
          }
        }}
        self={false}
        subtitle={subtitle}
        name={name}
        type={type}
      />
    );
  }

  /**
   * Get channels views from contacts and channels.
   * Gather groups + private channels
   */
  private getChannelViews(): Array<IChannelView> {
    const { broadcasts, broadcastLastMessageAt, contacts, channels, lastMessageAt } = this.props;
    const { filter } = this.state;
    return chatService.getChannelViews(
      channels,
      broadcasts,
      contacts,
      filter,
      CHANNEL_DISPLAY.NORMAL,
      lastMessageAt,
      broadcastLastMessageAt,
      { publicExcluded: true },
    );
  }

  /**
   * On next action, share product to channels selected.
   * If we select only one channel, we navigate to it
   */
  private onNext = () => {
    const {
      channels,
      close,
      history,
      me,
      product,
      ownerId,
      productShare,
      navigateChannelBySection,
      sendMessage,
      sendBroadcastMessage,
      search,
    } = this.props;
    const { selected, selectedBroadcast } = this.state;
    if (selected.length || selectedBroadcast.length) {
      close();
      if (product) {
        productShare(me.id, product.productId, [
          ...selected.map(s => ({ channelId: s })),
          ...selectedBroadcast.map(s => ({ broadcastId: Number(s) })),
        ]);
        this.trackShare(EventTrack.SHARE_MEDIUM.INTERNAL);
      } else {
        const urlToShare = this.getUrlToShare();
        selected.forEach(channelId => {
          sendMessage(me.id, {
            channelId,
            createdAt: new Date().getTime() + 60000,
            extraData: {
              query: search,
              ownerId,
              action: 'navigate:showroom',
            },
            message: urlToShare,
            messageId: 0,
            messageType: 'text',
            reactions: {},
            senderId: me.id,
          });
          EventTrack.track('message_send', {
            channelId,
            length: urlToShare.length,
            messageType: 'text',
            type: CHANNEL_TYPE.PRIVATE,
          });
        });
        selectedBroadcast.forEach(s =>
          sendBroadcastMessage(me.id, {
            channelId: s,
            createdAt: new Date().getTime() + 60000,
            extraData: {
              query: search,
              ownerId,
              action: 'navigate:showroom',
            },
            message: this.getUrlToShare(),
            messageId: 0,
            messageType: 'text',
            reactions: {},
            senderId: me.id,
          }),
        );
        this.trackShare(EventTrack.SHARE_MEDIUM.INTERNAL);
        if (selected.length === 1) {
          return navigateChannelBySection(
            CHANNEL_SECTIONS.MESSAGES,
            selected[0],
            channels[selected[0]].type === 'private',
            (path: string) => history.push(path),
          );
        }
        if (selectedBroadcast.length === 1) {
          history.push(getPath({ path: ROUTE_PATHS.BROADCAST, channelId: selectedBroadcast[0] }));
        }
      }
    }
  };

  /**
   * Track share catalog / product action with an event track
   */
  private trackShare = (medium: EventTrack.SHARE_MEDIUM) => {
    const { catalog, search, product, channels, broadcasts, numResults } = this.props;
    const { selected, selectedBroadcast } = this.state;
    let type: EventTrack.SHARE_TYPE;

    const userIds = Array.from(
      new Set(
        selected.reduce((acc: Array<number>, channelId: string) => {
          return acc.concat(channels[channelId].members.map(m => m.id));
        }, []),
      ),
    );
    const nOfBroadcastUsers = selectedBroadcast.reduce((acc: number, broadcastId: string) => {
      return acc + broadcasts[broadcastId].numberOfMembers;
    }, 0);

    if (product) {
      type = EventTrack.SHARE_TYPE.PRODUCT;
    } else if (search) {
      type = EventTrack.SHARE_TYPE.SEARCH;
    } else {
      type = EventTrack.SHARE_TYPE.CATALOG;
    }

    EventTrack.catalogShare({
      type,
      medium,
      search: search ? search : '',
      catalogHashId: catalog?.hashId,
      receivers: userIds,
      broadcasts: selectedBroadcast,
      totalUsers: nOfBroadcastUsers + userIds.length,
      productId: product && product.productId,
      productType: product && product.type,
      productVariety: product && product.variety,
      numProducts: numResults,
    });
  };

  /**
   * return the url to share with utms
   */
  private getUrlToShare = () => {
    const { me, search, priceListUrl, productHashId, contact, ownerId } = this.props;
    const amSeller = me.id === ownerId;
    let hash = '';
    if (amSeller) {
      hash = me.hashId;
    } else if (contact) {
      hash = contact.hashId;
    }

    const params = {
      id: productHashId || '',
      h: hash,
      query: search,
      utm_source: 'consentio',
      utm_campaign: 'share',
      utm_medium: me.id,
    };
    const queryString = qs.stringify(params);
    return `${config.WEB_URL}/pricelist/${priceListUrl}${search ? '/products' : ''}${queryString}`;
  };

  /**
   * Get pricelist owner
   */
  private getPricelistOwner(): { name: string; companyName: string; avatar?: string } {
    const { catalog, contact, contactId, me, ownerId } = this.props;
    if (ownerId === contactId && contact)
      return {
        name: contact.name,
        companyName: contact.companyName,
        avatar: contact.companyLogo || contact.avatar,
      };
    return {
      name: me.name,
      companyName: me.companyName,
      avatar: catalog.companyImage || catalog.companyLogo || me.settings.avatar,
    };
  }
}
