import styled, { ITheme } from '../../../styled-components';
import { Button, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  align-items: center;
  width: auto;
`;

export const ShareLink = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  min-width: max-content;
`;

export const Divider = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey1};
  width: 1px;
  height: 12px;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
