import styled, { css, ITheme } from '../../../../../styled-components';
import { ColumnContainer } from '../../../../atoms';
import { ProductCard } from '../../../../molecules';

interface IStyledProps {
  color?: string;
  from?: 'showroom' | 'pricelist' | 'product'; // 'product' for ProductCard component
  margin?: string;
  theme: ITheme;
}

export const Body = styled(ColumnContainer)<IStyledProps>`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 5 : 3)};
    ${(p: IStyledProps) =>
      p.from === 'showroom' &&
      css`
        padding-right: 0;
      `}
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }

  ${(p: IStyledProps) =>
    p.from === 'showroom' &&
    css`
      padding: 0 ${p.theme.paddingSize(3)};
    `}
`;

export const Link = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
`;

export const LinkMore = styled(Link)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  align-self: center;
`;

export const ProductItem = styled(ProductCard)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const ExcelDropDownContainer = styled.div`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
