import { date, HOUR_FORMAT, __ } from 'common-services';
import * as React from 'react';

import { Button, Select } from '../../../../atoms';
import { FormSection, InputWithLabel } from '../../../../molecules';
import * as S from './ShippingPreferences.styled';
interface IProps {
  shippingRef: React.RefObject<HTMLDivElement>;
  amEditor: boolean;
  workspace: IWorkspace;
  updateCatalog: (catalog: IWorkspace) => void;
  me: IUser;
}
const ShippingPreferences: React.FC<IProps> = ({ shippingRef, amEditor, workspace, updateCatalog, me }) => {
  return (
    <FormSection
      id="shipping"
      title={__('WorkspaceSettings.Menu.ShippingOrder.title')}
      subtitle={__('WorkspaceSettings.ShippingOrder.description')}
      sectionRef={shippingRef}
    >
      <InputWithLabel
        isRequired={true}
        width="90%"
        align="flex-start"
        disabled={!amEditor}
        label={__('WorkspaceSettings.ShippingOrder.days.label')}
        footerText={__('WorkspaceSettings.ShippingOrder.days.footer') + getDaysString(workspace.shippingDays)}
      >
        {amEditor ? (
          <S.SwitchRow>
            {Array.from(Array(7)).map((_, i) => (
              <DayButton key={i} day={i} workspace={workspace} updateCatalog={updateCatalog} />
            ))}
          </S.SwitchRow>
        ) : null}
      </InputWithLabel>
      <InputWithLabel
        alertIcon={workspace && (workspace.estimatedTransportTime !== null || !amEditor) ? undefined : 'Error'}
        align="flex-start"
        description={__('WorkspaceSettings.ShippingOrder.time.description')}
        disabled={!amEditor}
        isRequired={true}
        label={__('WorkspaceSettings.ShippingOrder.time.label')}
        width="90%"
      >
        <Select
          name="estimatedTransportTime"
          value={workspace.estimatedTransportTime + ''}
          options={Array.from(Array(24)).map((_, i) => ({
            value: i + '',
            label: `${__('WorkspaceSettings.ShippingOrder.time.values', {
              count: i,
            })}`,
          }))}
          onChange={(n, v) => updateCatalog({ ...workspace, estimatedTransportTime: Number(v) })}
          disabled={!amEditor}
          containerMargin="4px 0"
          width="40%"
        />
      </InputWithLabel>
      <InputWithLabel
        alertIcon={workspace && (workspace.cutoffTime !== null || !amEditor) ? undefined : 'Error'}
        align="flex-start"
        description={__('WorkspaceSettings.ShippingOrder.cutoffTime.description')}
        disabled={!amEditor}
        isRequired={true}
        label={__('WorkspaceSettings.ShippingOrder.cutoffTime.label')}
        width="90%"
      >
        <Select
          name="cutoffTime"
          value={workspace.cutoffTime + ''}
          options={Array.from(Array(96)).map((_, i) => ({
            value: i + '',
            label:
              me.settings.hourFormat === HOUR_FORMAT.H24
                ? __('WorkspaceSettings.ShippingOrder.cutoffTime.values', {
                    count: ('0' + Math.floor(i / 4) + ':' + getMinutesCutOffTime(i)).substring(i >= 40 ? 1 : 0),
                  })
                : i < 52
                ? i < 48
                  ? ('0' + Math.floor(i / 4) + ':' + getMinutesCutOffTime(i)).substring(i >= 40 ? 1 : 0) + ' am'
                  : ('0' + Math.floor(i / 4) + ':' + getMinutesCutOffTime(i)).substring(i >= 40 ? 1 : 0) + ' pm'
                : ('0' + (Math.floor(i / 4) - 12) + ':' + getMinutesCutOffTime(i)).substring(i >= 88 ? 1 : 0) + ' pm',
          }))}
          onChange={(n, v) => updateCatalog({ ...workspace, cutoffTime: Number(v) })}
          disabled={!amEditor}
          containerMargin="4px 0"
          width="40%"
        />
      </InputWithLabel>
    </FormSection>
  );
};

const DayButton: React.FC<{
  day: number;
  workspace: IWorkspace;
  updateCatalog: (workspace: IWorkspace) => void;
}> = ({ day, workspace, updateCatalog }) => {
  const selected = workspace.shippingDays.includes(days[day]);
  return (
    <Button
      type="circle"
      selected={selected}
      onClick={() => {
        if (selected) {
          const idx = workspace.shippingDays.indexOf(days[day]);
          workspace.shippingDays.splice(idx, 1);
          updateCatalog({
            ...workspace,
            shippingDays: [...workspace.shippingDays],
          });
        } else {
          updateCatalog({
            ...workspace,
            shippingDays: [...workspace.shippingDays, days[day]],
          });
        }
      }}
    >
      {date.getWeekdayName(day, true)}
    </Button>
  );
};

const days: Array<IWeekDay> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * For the selected days, it returns a string explaining that we are selected.
 * * 7 days selected: return 'Every day'.
 * * 5 or 6 consecutive days selected: return for example 'From Monday to Saturday'
 * * in other case: return for example 'Monday, Thursday, Friday'
 */
function getDaysString(d: Array<IWeekDay>) {
  if (d.length === 7) {
    return __('WorkspaceSettings.ShippingOrder.all_days');
  }
  if (d.length >= 5) {
    const dayNumbers = d.map(v => days.indexOf(v)).sort();
    if (dayNumbers.every((v, i, arr) => !arr[i + 1] || arr[i + 1] === v + 1)) {
      return __('WorkspaceSettings.ShippingOrder.days_range', {
        from: date.getWeekdayName(dayNumbers[0]),
        to: date.getWeekdayName(dayNumbers[dayNumbers.length - 1]),
      });
    }
  }
  return d.map(v => date.getWeekdayName(v)).join(', ');
}
// Gets intervals of 15 minutes for a given time
const getMinutesCutOffTime = (index: number) => {
  switch (index % 4) {
    case 0:
      return '00';
    case 1:
      return '15';
    case 2:
      return '30';
    case 3:
      return '45';
  }
};

export default React.memo(ShippingPreferences);
