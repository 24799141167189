import { Api, to } from 'common-services';
import { Dispatch } from 'redux';
import { IReduxState } from '../reducers';

// TODO any in dispatch instead of action
type IDispatch = (dispatch: Dispatch<any>, getState: () => IReduxState, api: Api) => Promise<void>;

/**
 * Call api for getting a price list in excel
 */
export function getExcelOrder(orderId: number, userId: number, filename: string): IDispatch {
  return async (dispatch: Dispatch<any>, getState: () => IReduxState, api: Api) => {
    await to(
      api.order.invoiceExcelGet(orderId, userId, (data: string) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }),
    );
  };
}
