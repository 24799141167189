import {
  __,
  Api,
  batchActions,
  broadcastActions,
  BUSINESS_TYPE,
  buyerWorkspaceActions,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  constants,
  contactActions,
  contactService,
  countryActions,
  countryService,
  EventTrack,
  i18n,
  LOCALE,
  modalActions,
  orderActions,
  prodTypeActions,
  prodTypeService,
  qs,
  sellerWorkspaceActions,
  to,
  userActions,
} from 'common-services';
import { USER_INITIAL_STATE } from 'common-services/dist/user/reducer';
import { setDefaultLocale } from 'react-datepicker';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getModel, getReadableVersion, getSystemName, getSystemVersion, getUniqueID } from '../../bindings/info';
import Sentry from '../../bindings/sentry';
import locales from '../../locales';
import { IMAGES } from '../assets';
import { IReduxState } from '../reducers';
import { CONVERSIONS, trackConversion } from '../services/conversion';
import { bcDebug, logError } from '../services/log';
import { createRealTimeConnection } from '../services/realtime';
import { navResetAuthAction, workspaceBuyerSelect, workspaceSellerSelect } from './nav';
import * as notificationActions from './notification';

declare var global: IGlobalWeb;

function setUserSegment(user: IUser, deviceInfo: IDeviceInfo) {
  bcDebug('hooks', 'state_ready - segment user', '');
  global.segment.userInfo({ user, deviceInfo });
}

function setUserSentry(user: IUser) {
  bcDebug('hooks', 'state_ready - sentry user', '');
  if (user === undefined || user.id === undefined) return;
  const userId = user.id as number;

  Sentry.setUserContext({
    id: userId.toString(),
    username: user.name ? user.name : '',
    email: user.email ? user.email : '',
    extra: user,
  });
}

/**
 * Perform autoLogin if tokens are saved in local storage
 */
export function autoLogin() {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    dispatch(userActions.loginAction());
    const { data, err } = await to(api.user.get());
    if (!err) {
      trackConversion(CONVERSIONS.LOGIN);
      dispatch(userActions.loginSuccessAction(data));
      dispatch(initializeApp(data));
      return;
    }
    dispatch(userActions.loginErrorAction(''));
    dispatch(verifyEmail());
  };
}

/**
 * Api call to verify phone number and send SMS
 */
export function verifyPhone(phone: string, country: number, saveInUser: boolean, onEnd: (result: string) => void) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    const cleanPhone: string = phone.replace(/[^0-9]/g, '');
    const { err } = await to(api.user.verifyPhone(cleanPhone, country, 'sms', window.navigator.language));
    if (err) return onEnd('error');
    onEnd('success');
    if (saveInUser) {
      const user = getState().user.user;
      dispatch(userActions.userSet({ ...user, phone: Number(cleanPhone), country }));
    }
  };
}

/**
 * Verify phone code
 */
export function verifyPhoneCode(
  phone: string,
  country: number,
  code: string,
  onEnd: (result: 'success' | 'invalid' | 'error') => void,
) {
  return async (dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, getState: () => IReduxState, api: Api) => {
    const cleanPhone: string = phone.replace(/[^0-9]/g, '');
    const res = await to(api.user.verifyCode(cleanPhone, country, code));
    const valid = res.err ? 'invalid' : 'success';
    EventTrack.onboardPhoneVerification({
      flow: 'register',
      phone: `${country}${phone}`,
      valid,
    });
    if (!res.err) {
      if (res.data!.ok) {
        onEnd('success');
        dispatch(userActions.verificationCodeSet());
        return;
      } else if (res.data.status === 401) {
        return onEnd('invalid');
      }
      return onEnd('error');
    }
    return onEnd('error');
  };
}

/**
 * Api call for weblogin with oauth
 */
export function webloginOauth(
  params: { oauth_token: string; code?: string },
  onEnd?: (result: string, user?: IUser, country?: string, phone?: string) => void,
) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    dispatch(userActions.loginAction());
    const { code, oauth_token } = params;
    const { data } = await to(api.user.oauthlogin({ oauth_token, code }));
    const { user, status, isBlocked } = data;
    if (status !== 200) {
      dispatch(userActions.loginErrorAction('Invalid phone or password'));
      let loginError = data.required2fa ? '2fa_required' : 'error';
      if (isBlocked) loginError = 'error_blocked';
      if (status === 429)
        dispatch(
          modalActions.modalOpen(
            __('ModalAccessBlocked.title'),
            () => {
              dispatch(supportAction());
              dispatch(modalActions.modalClose());
            },
            {
              text2: __('ModalAccessBlocked.description'),
              buttonText: __('ModalAccessBlocked.cta'),
              icon: IMAGES.reportUser,
            },
            'nice',
          ),
        );
      if (status === 404) {
        loginError = 'error_user_not_found';
      }
      return onEnd?.(
        status === 429 ? 'error_login_attempts' : loginError,
        undefined,
        data.lastFourDigitsPhone?.replace(/\D/g, ''),
      );
    }
    trackConversion(CONVERSIONS.LOGIN);
    dispatch(userActions.loginSuccessAction(user));
    dispatch(initializeApp(user, () => onEnd('success', user)));
  };
}

/**
 * Api call for weblogin with password
 */
export function webloginPassword(
  params: { phone?: string; email?: string; code?: string; password: string },
  onEnd: (result: string, user?: IUser, country?: string, phone?: string) => void,
) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    dispatch(userActions.loginAction());
    const { code, phone, email, password } = params;
    const { data } = await to(api.user.loginPassword({ phone, code, email, password }));
    const { user, status, isBlocked } = data;
    if (status !== 200) {
      dispatch(userActions.loginErrorAction('Invalid phone or password'));
      let loginError = data.required2fa ? '2fa_required' : 'error';
      if (isBlocked) loginError = 'error_blocked';
      if (status === 429)
        dispatch(
          modalActions.modalOpen(
            __('ModalAccessBlocked.title'),
            () => {
              dispatch(supportAction());
              dispatch(modalActions.modalClose());
            },
            {
              text2: __('ModalAccessBlocked.description'),
              buttonText: __('ModalAccessBlocked.cta'),
              icon: IMAGES.reportUser,
            },
            'nice',
          ),
        );
      return onEnd(
        status === 429 ? 'error_login_attempts' : loginError,
        undefined,
        data.lastFourDigitsPhone?.replace(/\D/g, ''),
      );
    }
    trackConversion(CONVERSIONS.LOGIN);
    dispatch(userActions.loginSuccessAction(user));
    dispatch(initializeApp(user, () => onEnd('success', user)));
  };
}

/**
 * Resend code by SMS (via = 'sms') or call (via = 'call')
 */
export function resendCode(phone: string, country: number, via: 'sms' | 'call') {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    const cleanPhone: string = phone.replace(/[^0-9]/g, '');
    return to(api.user.verifyPhone(cleanPhone, country, via, window.navigator.language));
  };
}

function getDeviceInfo(): IDeviceInfo {
  return {
    appVersion: getReadableVersion(),
    model: getModel(),
    os: getSystemName(),
    osVersion: getSystemVersion(),
    uniqueID: getUniqueID(),
  };
}

/**
 * Initialize app with user, setting local. Init chat connection, Sentry, get user's contacts / orders / products
 * @param user user logged to init
 * @param callback function to execute at the end
 */
export function initializeApp(user: IUser, callback?: () => void) {
  return (dispatch: any, getState: () => IReduxState) => {
    const deviceInfo = getDeviceInfo();
    setUserSegment(user, deviceInfo);
    setUserSentry(user);
    document.title = `Consentio - ${user.name}`;
    const locale = user.settings.language.substring(0, 2);
    locales(i18n.getLanguageCode(locale));
    global.localStorage.setItem('language', locale);
    global.localStorage.setItem('username', user.name);
    global.localStorage.setItem('2fa_required', '');
    dispatch(navResetAuthAction());
    setDefaultLocale(locale);
    if (typeof window !== 'undefined') {
      (window as any).StonlyWidget?.('setWidgetLanguage', locale);
    }
    dispatch(broadcastActions.getBroadcasts(user.id!));
    createRealTimeConnection(
      user,
      deviceInfo.uniqueID,
      () => {
        dispatch(onConnect(locale as LOCALE));
      },
      dispatch,
    );
    const {
      nav: { workspaceSelected, workspaceType },
    } = getState();
    // Take workspace from redux if set
    if (workspaceSelected) {
      if (workspaceType === 'buyer') {
        dispatch(workspaceBuyerSelect(workspaceSelected));
        dispatch(buyerWorkspaceActions.suppliersGet(user.id, workspaceSelected));
      } else {
        dispatch(workspaceSellerSelect(workspaceSelected));
        dispatch(sellerWorkspaceActions.clientsGet(user.id, workspaceSelected));
      }
    } else {
      if (user.settings.isBuyer && user.buyerWorkspaceId) {
        dispatch(workspaceBuyerSelect(user.buyerWorkspaceId));
        dispatch(buyerWorkspaceActions.suppliersGet(user.id, user.buyerWorkspaceId));
      }
      if (user.settings.isSeller) {
        dispatch(workspaceSellerSelect(user.sellerWorkspaceId));
        dispatch(sellerWorkspaceActions.clientsGet(user.id, user.sellerWorkspaceId));
      }
    }
    dispatch(verifyEmail());

    const { country, prodType } = getState();
    if (country.language !== locale) dispatch(countryActions.countriesGet(locale));
    if (prodType.language !== locale) dispatch(prodTypeActions.prodTypesGet(locale));
    dispatch(sellerWorkspaceActions.catalogsGet(user.id!));
    dispatch(buyerWorkspaceActions.workspacesGet(user.id!));

    if (!user.settings.timezone) {
      dispatch(
        userActions.updateSettings({ ...user.settings, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
      );
    }
    dispatch(notificationActions.registerPushToken(global.pendingPushToken));

    if (callback) callback();
  };
}

function onConnect(lang: LOCALE) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    const {
      user: { user },
      contact: { inConsentio },
    } = getState();
    dispatch(modalActions.hideConnecting());

    const [lastChannelUpdated, lastContactUpdated, lastCountryUpdated, lastProdTypeUpdated] =
      window.localStorage.getItem('sync') === 'true'
        ? await Promise.all([
            chatService.getLastChannelUpdated(),
            contactService.getLastContactUpdated(),
            countryService.getLastCountryUpdated(),
            prodTypeService.getLastProdTypeUpdated(),
          ])
        : [0, 0, 0, 0];

    const { data, err } = await to(
      api.initialDataGet(
        user.id!,
        {
          contact: lastContactUpdated,
          country: lastCountryUpdated,
          prodType: lastProdTypeUpdated,
          channel: lastChannelUpdated,
        },
        inConsentio,
      ),
    );
    if (err) {
      window.localStorage.setItem('sync', 'false');
    }
    if (data) {
      window.localStorage.setItem('sync', 'true');
      dispatch(
        batchActions(
          chatActions.setChannelsAction(data.channels),
          contactActions.contactsAdd(data.contacts),
          Object.keys(data.countries!).length ? countryActions.countriesSet(data.countries!, lang) : undefined,
          Object.keys(data.prodTypes).length ? prodTypeActions.prodTypesSet(data.prodTypes!, lang) : undefined,
          orderActions.updateUnreads({
            ...Object.keys(data.contactsUnread).reduce((acc: Record<number, { total: number }>, k) => {
              acc[k] = { total: data.contactsUnread[k] };
              return acc;
            }, {}),
            0: {
              total: data.unreadSales + data.unreadPurchases,
              sales: data.unreadSales,
              purchases: data.unreadPurchases,
            },
          }),
          chatActions.updateUnreads(data.unreadMessages),
          orderActions.orderPendings(data.ordersPending, true),
          contactActions.hasContactsAddressbookSet(data.hasOutContacts),
          data.lastOrdersPerContact ? chatActions.updateLastOrderAt(data.lastOrdersPerContact) : undefined,
          data.user ? userActions.userSet(data.user) : undefined,
          notificationActions.setInternalNotificationsBadge(data.notificationsUnread),
          data.workspacesOrdersTotals ? orderActions.updateWorkspaceUnreads(data.workspacesOrdersTotals) : undefined,
        ),
      );
    }
  };
}

/**
 * Check verify email parameters from URL
 */
export function checkVerifyEmailFromURL() {
  const { email, verfcode } = qs.parse(window.location.search, ['email', 'verfcode']) as {
    email: string;
    verfcode: string;
  };
  global.pendingEmailVerification = {
    email,
    verfcode,
  };
}

/**
 * Verify email if location search params (email & verfcode) are specified
 */
export function verifyEmail() {
  return (dispatch: any, getState: () => IReduxState, api: Api) => {
    if (!global.pendingEmailVerification) return;
    const { email, verfcode } = global.pendingEmailVerification;
    if (email && verfcode) {
      const { user } = getState().user;
      dispatch(
        userActions.verifyEmail(user.id!, email, verfcode, err => {
          global.pendingEmailVerification = undefined;
          const image = err
            ? constants.MEDIA_URL + '/c_scale,w_500/v1586353455/ilustraciones_no_verificado-15.png'
            : constants.MEDIA_URL + '/c_scale,w_500/v1586353336/ilustracion_verificaci%C3%B3n-15.png';
          const body = err
            ? __('email_verification.body_error', { email })
            : __('email_verification.body_success', { email });
          const title = err ? __('email_verification.title_error') : __('email_verification.title_success');
          dispatch(
            modalActions.modalOpen(
              title,
              () => dispatch(err ? supportAction() : modalActions.modalClose()),
              {
                icon: image,
                text2: body,
                buttonText: err ? __('email_verification.cta_error') : __('email_verification.cta_success'),
                showCancelButton: false,
              },
              'nice',
            ),
          );
        }),
      );
    }
  };
}

/**
 * Create a user then perform a weblogin
 */
export function register(
  params: { codeEmail?: string; codePhone?: string; oauthToken?: string },
  onEnd: (result?: IUserLoginAPIResponse) => void,
) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    const state = getState();
    const user: IUser = state.user.user;
    const { codeEmail, codePhone, oauthToken } = params;

    dispatch(userActions.loginAction());
    const { data, err } = await to(
      api.user.register(
        {
          ...user,
          settings: {
            ...user.settings,
            language: i18n.default.currentLocale(),
            timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
          },
        },
        codePhone || '',
        codeEmail || '',
        oauthToken || '',
      ),
    );

    if (!err && data && data.ok) {
      trackConversion(CONVERSIONS.REGISTER);
      dispatch(userActions.loginSuccessAction(data.user!));
      dispatch(initializeApp(data.user!, () => onEnd(data)));
      return;
    }

    dispatch(userActions.loginErrorAction('register error'));
    return onEnd(data);
  };
}

/**
 * Contact support action.
 * if found in the user's contacts a default contact:
 *  * Select chat with the support contact
 *  * Or open mail application otherwise
 */
export function supportAction() {
  return async (dispatch: any, getState: () => IReduxState) => {
    const { inConsentio } = getState().contact;
    const contactSupport = Object.values(inConsentio).find((contact: IContact) => contact.isDefaultContact) as IContact;
    const { channels } = getState().chat;
    const channelSupport = contactSupport
      ? Object.values(channels).find(
          (channel: IChannel) =>
            channel.type === CHANNEL_TYPE.PRIVATE &&
            channel.members.find((member: IMember) => member.id === contactSupport.id),
        )
      : undefined;
    if (channelSupport) {
      document.location.replace(`/contact/${channelSupport.id}`);
    } else {
      const mailtoLink = document.createElement('a');
      mailtoLink.setAttribute('href', 'mailto:support@consentio.co');
      document.body.appendChild(mailtoLink);
      mailtoLink.click();
      mailtoLink.remove();
    }
    dispatch(modalActions.modalClose());
  };
}

/**
 * Api call to verify email
 * If code not passed, a new email is sent
 */
export function verifyEmailCode(
  params: { email: string; code?: string; keep?: boolean },
  onEnd: (result: 'success' | 'error') => void,
) {
  return async (dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, getState: () => IReduxState, api: Api) => {
    const { email } = params;

    dispatch(
      userActions.verifyEmailOnboarding(
        { ...params, lang: i18n.getLanguageCode(i18n.getLanguageCode(navigator.language)) },
        error => {
          const { code } = params;
          if (code) {
            EventTrack.onboardEmailVerification({
              flow: 'register',
              email,
              valid: error ? 'invalid' : 'success',
            });
          }
          if (!error) {
            const { user } = getState().user;
            dispatch(
              userActions.userSet({
                ...user,
                email,
                // If registering with email, reset phone number
                phone: !user.id ? undefined : user.phone,
                country: !user.id ? undefined : user.country,
              }),
            );
            onEnd('success');
            return;
          }
          onEnd('error');
        },
      ),
    );
  };
}

/**
 * Set user's name or company name
 */
export function setRegisterInfo(info: {
  email?: string;
  name?: string;
  companyName?: string;
  businessType?: BUSINESS_TYPE;
  inviteHashId?: string;
}) {
  return async (dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, getState: () => IReduxState) => {
    const user = getState().user.user;
    const { companyName, email, name, businessType, inviteHashId } = info;
    dispatch(
      userActions.userSet({
        ...user,
        name: name || user.name,
        companyName: companyName || user.companyName,
        email: email || user.email,
        inviteHashId: inviteHashId || user.inviteHashId,
        settings: businessType ? { ...user.settings, businessType } : user.settings,
      }),
    );
  };
}

/**
 * Resets user's state in redux
 */
export function resetUser() {
  return async (dispatch: ThunkDispatch<IReduxState, Api, AnyAction>) => {
    dispatch(userActions.userSet(USER_INITIAL_STATE.user));
  };
}

/**
 * Update's the user password
 */
export function updatePassword(password: string, cb?: (result: string) => void) {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    const { user } = getState().user;
    dispatch(
      userActions.updatePassword(user.id!, password, result => {
        if (result === 'error') {
          logError(new Error(result), 'updatePassword');
        }
        if (cb) cb(result);
      }),
    );
  };
}
