import {
  __,
  sellerWorkspaceService,
  validations,
  modalActions,
  EventTrack,
  sellerWorkspaceActions,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../../../constants';
import getPath from '../../../../../util/routes';
import { FormSection, InputWithLabel, MembersUpdate } from '../../../../molecules';
import * as S from './TeamSection.styled';
interface IProps {
  catalog: IWorkspace;
  catalogName: string;
  contacts: Record<number, IContact>;
  editMode: boolean;
  errors: Map<string, string | (() => JSX.Element)>;
  me: IUser;
  role: ICatalogRole;
  sectionRef: React.RefObject<HTMLDivElement>;
  setError: (key: string, value: string) => void;
  updateCatalog: (catalog: IWorkspace, hasChanges?: boolean) => void;
}
const TeamSection: React.FC<IProps> = ({
  catalog,
  catalogName,
  contacts,
  editMode,
  errors,
  me,
  role,
  sectionRef,
  setError,
  updateCatalog,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <FormSection id="team" sectionRef={sectionRef} title={__('WorkspaceSettings.Menu.Team.title')}>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.team_name')}
        description={__('Components.Settings.team_name_description')}
        disabled={!editMode}
      >
        <S.Input
          name="name"
          placeholder={__('Components.Settings.team_name_placeholder')}
          value={catalog.name}
          trim={true}
          isRequired={true}
          onChange={(n, v) => {
            setError(n, v ? '' : 'empty');
            updateCatalog({ ...catalog, [n]: v });
          }}
          onBlur={(n, value) => {
            setError(n, value ? '' : 'empty');
          }}
          disabled={!editMode}
          hasError={!!errors.get('name')}
          zeroCase={catalogName}
        />
      </InputWithLabel>

      <InputWithLabel
        isRequired={false}
        label={__('Components.Settings.orders_email')}
        description={__('Components.Settings.orders_email_description')}
        disabled={!editMode}
      >
        <S.Input
          name="ordersEmail"
          placeholder={__('Components.Settings.orders_email_placeholder')}
          value={catalog.ordersEmail}
          trim={true}
          isRequired={false}
          onChange={(n, v) => {
            if (v && !validations.validateEmail(v as string)) {
              setError(n, 'incorrect-email');
            } else {
              setError(n, '');
              updateCatalog({ ...catalog, [n]: v });
            }
          }}
          onBlur={(n, value) => {
            if (value && !validations.validateEmail(value as string)) {
              setError(n, 'incorrect-email');
            } else {
              setError(n, '');
            }
          }}
          disabled={!editMode}
          hasError={!!errors.get('ordersEmail')}
          zeroCase={__('Components.Settings.orders_email_zerocase')}
        />
        {errors.get('ordersEmail') === 'incorrect-email' ? (
          <S.TextError>{__('Components.Onboarding.LoginPhoneEmail.email_validation')}</S.TextError>
        ) : null}
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Members.title')}
        description={__('WorkspaceSettings.Members.subtitle', { workspace: catalog.name })}
      >
        <S.SwitchRow>
          <MembersUpdate
            className="workspace-settings-members"
            contacts={contacts}
            disabled={true}
            me={me}
            members={catalog.members}
            onRemove={removeMember}
            onUpdateRole={updateRolMember}
            role={sellerWorkspaceService.isProPlan(catalog.plan) ? role : 'viewer'}
            title={__('Catalog.update_members.title')}
          />
        </S.SwitchRow>
        <S.TextLink
          padding="12px 0 0 0"
          onClick={() =>
            history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER, workspaceId: catalog.id + '' }))
          }
        >
          {__('WorkspaceColleagues.access_link')}
        </S.TextLink>
      </InputWithLabel>
    </FormSection>
  );

  function removeMember(cId: number) {
    dispatch(
      modalActions.modalOpen(
        __('Catalog.update_members.remove', contacts[cId]),
        () => {
          EventTrack.track('workspace_colleague_delete', {
            workspace_id: catalog.id,
            member_id: cId,
          });
          const idx = catalog.members.findIndex(m => m.userId === cId);
          catalog.members.splice(idx, 1);
          updateCatalog({ ...catalog }, false);
          dispatch(sellerWorkspaceActions.catalogMemberDelete(me.id, catalog.id, cId) as any);
        },
        {
          showCancelButton: true,
          buttonText: __('Components.ProductsList.Remove'),
        },
        'nice',
      ),
    );
  }

  function updateRolMember(userId: number, r: ICatalogRole) {
    EventTrack.track('workspace_colleague_update', {
      workspace_id: catalog.id,
      member_id: userId,
      new_role: r,
    });
    const member = catalog.members.find(m => m.userId === userId);
    member.role = r;
    updateCatalog({ ...catalog }, false);
    dispatch(sellerWorkspaceActions.catalogMembersUpdate(me.id, catalog.id, [member]) as any);
  }
};

export default React.memo(TeamSection);
