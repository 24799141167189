import styled, { css, ITheme } from '../../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../../atoms';

interface IStyledProps {
  hasReactions?: boolean;
  height?: number;
  iconSize?: number;
  numberOfReactionTypes?: number;
  reaction?: PostReaction;
  size?: number;
  theme: ITheme;
  toScaleUp?: boolean;
}

export const getReactionColor = (p: IStyledProps) => {
  switch (p.reaction) {
    case 'interesting':
      return p.theme.colors.orange1;
    case 'funny':
      return p.theme.colors.green1;
    case 'love':
      return p.theme.colors.red1;
    case 'go':
      return p.theme.colors.purple1;
    case 'like':
      return p.theme.colors.blue1;
    default:
      return 'inherit';
  }
};

const ActionButton = styled(Button)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ReactionsContainer = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  border-radius: calc(3 * ${(p: IStyledProps) => p.theme.borderRadius});
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ReactionImage = styled.img<IStyledProps>`
  width: ${(p: IStyledProps) => p.size || 34}px;
  height: ${(p: IStyledProps) => p.size || 34}px;
  object-fit: contain;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};

  ${(p: IStyledProps) =>
    p.toScaleUp &&
    css`
      cursor: pointer;
      &:hover {
        transform: scale(1.4);
        transition: all 0.2s ease-in-out;
      }
    `}
`;

const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-50%);
  z-index: 1;
  min-width: max-content;
  margin: 0;
  min-height: min-content;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;

  &:hover {
    .post-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const ReactionImageContainer = styled(RowContainer)`
  position: relative;
  align-items: center;
  transition: visibility 0s, opacity 0.3s ease-in-out;
  &:hover {
    .reaction-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const SimpleTooltip = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${(p: IStyledProps) => p.theme.colors.black};
  border-radius: 10px;
  position: absolute;
  bottom: 130%;
  left: 50%;
  min-width: max-content;
  transform: translate(-50%, 0px);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
`;

export const PostReactionsContainer = styled(RowContainer)`
  align-items: center;
`;

export const ReactionsRow = styled(RowContainer)`
  .reaction-small-img:nth-child(2) {
    transform: translateX(-30%);
  }
  .reaction-small-img:nth-child(3) {
    transform: translateX(-60%);
  }
  .reaction-small-img:nth-child(4) {
    transform: translateX(-90%);
  }
  .reaction-small-img:nth-child(5) {
    transform: translateX(-120%);
  }
  .reaction-small-img:nth-child(6) {
    transform: translateX(-150%);
  }
  .reaction-small-img:first-child {
    transform: none;
  }
  align-items: center;
  cursor: pointer;
`;

export const ReactionSmallImage = styled.img<IStyledProps>`
  width: ${(p: IStyledProps) => p.iconSize || 20}px;
  height: ${(p: IStyledProps) => p.iconSize || 20}px;
  object-fit: contain;
`;

const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => ((p.iconSize || 20) > 15 ? p.theme.fontSize.big : p.theme.fontSize.normal)};
  line-height: 22px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextTotalReactions = styled(TextRegular)<IStyledProps>`
  margin-left: calc(
    ${(p: IStyledProps) => p.theme.paddingSize(1.5)} - ${(p: IStyledProps) => p.numberOfReactionTypes}px
  );
  position: relative;
  bottom: -1px;
`;

export const TextReactionsCount = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ReactionsPreviewContainer = styled(ReactionsContainer)`
  height: 45px;
`;

export const RowCenter = styled(RowContainer)`
  align-items: center;
`;
