import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';
import ModalBase from '../../atoms/Modal';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  loadingTheme?: 'light' | 'dark';
  theme: ITheme;
}

export const Modal = styled(ModalBase)<IStyledProps>`
  ${(p: IStyledProps) =>
    p.loadingTheme === 'light' &&
    css`
      background-color: ${p.theme.colors.white};
    `}
`;

export const Content = styled(RowContainer)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Col = styled(ColumnContainer)`
  align-items: center;
`;

export const LoadingText = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  text-align: center;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 0 0;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  line-height: 24px;
`;

export const LoadingDescription = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  text-align: center;
  margin: 0;
  line-height: 24px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;
