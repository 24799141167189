import { IFile } from 'common-services';

/**
 * Creates a download given a string
 * @param data File content data
 * @param contentType Content-type of the file to download
 * @param fileName The name of the file to download
 */
export function downloadFile(data: string, contentType: string, fileName: string) {
  const binary = atob(data);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const element = document.createElement('a');
  const file = new Blob([view], { type: contentType });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
}

export function downloadBinaryFile(binary: string, contentType: string, fileName: string) {
  const element = document.createElement('a');
  const file = new Blob([binary], { type: contentType });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
}

/**
 * Creates a download given an URL
 * @param imageURL The url with the object to download
 * @param fileName The filename for the downloaded file
 */
export function downloadURL(url: string, fileName: string) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = fileName; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', url);
  xhr.send();
}

/**
 * Takes a file and returns his content base64-encoded
 */
export function convertToIFile(
  file: File,
  cb: (res: IFile, fileString: string) => void,
  errorCb?: (code: string) => void,
) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const res = reader.result as string;
    const replaced = res.split(',')[1];
    const f: IFile = {
      content: replaced,
      name: file.name,
      size: file.size,
      type: file.type,
    };
    cb(f, res);
  };
  reader.onerror = event => {
    errorCb?.((event.target as any).error.code);
  };
}

/**
 * Fetch an image from url and convert it to a File
 */
export async function convertURLToFile(imageUrl: string) {
  const blob = await (await fetch(imageUrl)).blob();
  return new File([blob], 'fileName.jpg', { type: 'image/jpeg', lastModified: new Date().getTime() });
}

/*
 * callbackPaste holds the callback for paste
 */
let callbackPaste;

/**
 * Clear paste listeners
 * We usually have to call this in componentWillUnmount
 */
export function clearPasteListener() {
  global.removeEventListener('paste', handlePaste);
}

/**
 * Function that bind paste handler to global document and returns a File
 * if possible
 */
export function bindPasteListener(cb: (f: File) => void) {
  clearPasteListener();
  callbackPaste = cb; // needed to keep handlePaste unique and removable
  global.addEventListener('paste', handlePaste);
}

/**
 * Function that handle paste and returns a File if possible
 *
 * Firefox & chrome seeem to send always all files as image/png and
 * additionaly chrome is converting pdf in images
 *
 * It doesn't work with multiple files
 */
async function handlePaste(e: ClipboardEvent) {
  // Firefox seem to skip non-image files, this check prevents browser from crash
  if (!e.clipboardData.files || e.clipboardData.files.length === 0) {
    return;
  }

  if (e.clipboardData && e.clipboardData.items) {
    for (const i in e.clipboardData.items) {
      if (e.clipboardData.items.hasOwnProperty(i)) {
        const item = e.clipboardData.items[i];
        // paste coming always as image/png, don't know why
        if (item.kind === 'file' && item.type.match('^image/')) {
          const f = item.getAsFile();
          // we don't inject cb since we need handler unique to be removable
          if (callbackPaste) {
            callbackPaste(f);
          }
        }
      }
    }
  }
}
