import {
  __,
  buyerWorkspaceSelectors,
  contactSelectors,
  IInternalNotification,
  internalNotificationActions,
  sellerWorkspaceSelectors,
  to,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import { IReduxState } from '../../../reducers';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { SimpleDropdown } from '../../atoms';
import NotificationCard from '../../molecules/NotificationCard';
import * as S from './MainHeader.styled';

const NotificationsModal = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<any>>();
  const contacts = useSelector(contactSelectors.getContacts());
  const sellerWorkspaces = useSelector(sellerWorkspaceSelectors.getCatalogs());
  const buyerWorkspaces = useSelector(buyerWorkspaceSelectors.getWorkspaces());
  const me = useSelector(userSelectors.getUser);
  const workspaceIdStored = useSelector((state: IReduxState) => state.nav.workspaceSelected);

  const [notifications, setNotifications] = React.useState<Array<IInternalNotification>>([]);
  const [selectedTab, setSelectedTab] = React.useState('alert');
  const [workspaces, setWorkspaces] = React.useState<Array<IWorkspace>>([]);
  const [workspaceId, setWorkspaceId] = React.useState(workspaceIdStored);
  const workspace = sellerWorkspaces[workspaceId] || buyerWorkspaces[workspaceId];
  const [showRibbon, setShowRibbon] = React.useState(true);

  React.useEffect(() => {
    const workspacesToShow = [];
    if (me.settings.isSeller) workspacesToShow.push(...Object.values(sellerWorkspaces));
    if (me.settings.isBuyer) workspacesToShow.push(...Object.values(buyerWorkspaces));
    setWorkspaces(workspacesToShow);
  }, [buyerWorkspaces, sellerWorkspaces, me.settings.isBuyer, me.settings.isSeller]);

  React.useEffect(() => {
    api.notification.notificationsGet(me.id!, workspaceId, setNotifications);
  }, [me.id, workspaceId]);
  const unreadAlerts = notifications.filter(notif => notif.type === 'alert' && notif.isRead === false)?.length || 0;
  const unreadInformatives = notifications.some(notif => notif.type === 'informative' && notif.isRead === false);
  const hasUnread = !!(unreadAlerts || unreadInformatives);
  const hasAlerts = !!notifications.filter(notif => notif.type === 'alert')?.length;
  const hasInformatives = !!notifications.filter(notif => notif.type === 'informative')?.length;
  return (
    <S.NotificationsModal className="notifications-modal">
      <S.Header>
        <S.TitleRowNotifications>
          <S.NotificationTitle> {__('MainHeader.notification_center.title')}</S.NotificationTitle>
          <S.Row>
            <SimpleDropdown
              options={[
                { key: 'settings', value: __('MainHeader.notification_center.settings'), icon: 'Section-settings' },
              ]}
              hAlign="right"
              onSelect={(key: string) => {
                if (key === 'settings') history.push(getPath({ path: ROUTE_PATHS.SETTINGS, tab: 'notifications' }));
              }}
            >
              <S.KebabIcon name="Kebab" />
            </SimpleDropdown>
          </S.Row>
        </S.TitleRowNotifications>
      </S.Header>
      <S.HeaderNotifications>
        <S.NotificationTab isSelected={selectedTab === 'alert'} id="alert" onClick={() => setSelectedTab('alert')}>
          {__('MainHeader.notification_center.tabs.alert')}
          <S.Badge badgeCount={unreadAlerts}>{unreadAlerts}</S.Badge>
        </S.NotificationTab>
        <S.NotificationTab
          isSelected={selectedTab === 'informative'}
          id="informative"
          onClick={() => setSelectedTab('informative')}
        >
          {__('MainHeader.notification_center.tabs.informative')}
          {unreadInformatives ? <S.InformativeIndicator /> : null}
        </S.NotificationTab>
        <S.NotificationTab isSelected={selectedTab === 'all'} id="all" onClick={() => setSelectedTab('all')}>
          {__('MainHeader.notification_center.tabs.all')}
        </S.NotificationTab>
        <S.NotificationTab isSelected={selectedTab === 'unread'} id="unread" onClick={() => setSelectedTab('unread')}>
          {__('MainHeader.notification_center.tabs.unread')}
        </S.NotificationTab>
      </S.HeaderNotifications>
      {hasUnread ? (
        <S.TextBlue
          onClick={() =>
            dispatch(
              internalNotificationActions.notificationMarkAllAsRead(workspaceId, err => {
                if (!err) {
                  setNotifications(notifications.map(n => ({ ...n, isRead: true })));
                }
              }),
            )
          }
        >
          {__('MainHeader.notification_center.mark_unread')}
        </S.TextBlue>
      ) : null}
      {notifications.length === 0 ||
      (selectedTab === 'alert' && !hasAlerts) ||
      (selectedTab === 'informative' && !hasInformatives) ||
      (selectedTab === 'unread' && !hasUnread) ? (
        <S.ZeroCaseWrapper>
          <S.Image src={IMAGES.notificationsPineapple} />
          <S.ZeroCaseTitle> {__('MainHeader.notification_center.zero_case_title')}</S.ZeroCaseTitle>
          <S.ZeroCaseText>{__('MainHeader.notification_center.zero_case_text')}</S.ZeroCaseText>
        </S.ZeroCaseWrapper>
      ) : (
        <S.NotificationsList>
          {notifications.map((n, i) => {
            return selectedTab === 'all' || n.type === selectedTab || (selectedTab === 'unread' && !n.isRead) ? (
              <NotificationCard
                key={n.id}
                me={me}
                selectedTab={selectedTab}
                notification={n}
                workspace={workspace}
                markAsRead={async id => {
                  const { err } = await to(api.notification.notificationMarkAsRead(me.id, id));
                  if (!err) {
                    n.isRead = true;
                    setNotifications([...notifications]);
                  }
                }}
              />
            ) : null;
          })}
        </S.NotificationsList>
      )}
    </S.NotificationsModal>
  );
};

export default React.memo(NotificationsModal);
