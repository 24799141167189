import {
  __,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_TYPE,
  chatActions,
  chatSelectors,
  chatService,
  date,
  EventTrack,
  IPoll,
  IPollOption,
  modalActions,
  throttle,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { IReduxState } from '../../../reducers';
import theme from '../../../theme';
import { IOSLoading, LettersAvatar, ProgressBar } from '../../atoms';
import { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import {
  getPostReactionImage,
  PostReactionActions,
  PostReactionsPreview,
  PostReactionsSummary,
} from './Fragments/Reactions.component';
import * as S from './PostMessage.styled';

interface IProps {
  channelId: string;
  channelName: string;
  className?: string;
  contact?: IContact;
  isLast?: boolean;
  lang?: LOCALE;
  message: IMessage;
  offlineAction?: () => void;
  offlineMode?: boolean;
  onOptionClick?: (key: string, msg: IMessage) => void;
  options?: Array<IItem>;
  sender?: IMember;
  touchImage: typeof modalActions.touchImage;
}

const PostMessage = ({
  channelId,
  channelName,
  className,
  contact,
  isLast = false,
  lang,
  message,
  offlineAction,
  offlineMode = false,
  onOptionClick,
  options = [],
  sender,
  touchImage,
}: IProps) => {
  const { createdAt, extraData, messageType } = message;
  const { imageUrls, metadata } = extraData as IPost;
  const { options: pollOptions } = extraData as IPoll;

  const isPoll = messageType === 'poll';

  const [seeMore, setSeeMore] = React.useState(false);
  const [showSeeMore, setShowSeeMore] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);
  const imageRef = React.useRef<HTMLImageElement>();
  const [isImageLoading, setIsImageLoading] = React.useState(
    typeof window !== 'undefined' && !imageRef?.current?.complete ? true : false,
  );
  const textRef = React.useRef<HTMLSpanElement>();

  React.useEffect(() => {
    if (textRef.current?.clientHeight > 66) {
      setShowSeeMore(true);
    }
  }, []);
  React.useEffect(() => {
    setIsImageLoading(typeof window !== 'undefined' && !imageRef?.current?.complete ? true : false);
  }, [imageIndex]);

  const hasImage = imageUrls?.length;
  const hasMetadata = !!metadata?.title;
  const text = getContent(message, sender, channelName, lang);
  if (!text && !hasImage && !hasMetadata) return null;
  const hasMultipleImages = (imageUrls?.length || 0) > 1;

  return (
    <S.Container className={className}>
      <PostHeader
        className="post-message-header"
        contact={contact}
        lang={lang}
        member={sender}
        subtitle={utils.firstToUpperCase(date.dateDisplayFromNow(createdAt, { addSuffix: true }))}
      />
      {text ? (
        <S.CardBody className="post-message-card-body">
          <S.TextRegular showSeeMore={showSeeMore} seeMore={seeMore} ref={textRef}>
            {utils.splitTextUrls(text).map((t, i) => {
              if (!t.text) return <br key={i + ''} />;
              if (t.isLink) {
                const { url } = utils.analyzeUrl(t.text);
                return (
                  <S.Link
                    key={i + url}
                    href={url}
                    target="_blank"
                    onClick={() => {
                      EventTrack.track('message_link_click', {
                        channel_id: message.channelId,
                        message_id: message.messageId,
                        channel_type: CHANNEL_TYPE.PUBLIC,
                        link: url,
                      });
                    }}
                  >
                    {url}
                  </S.Link>
                );
              }

              return t.text.split(/\B(#[a-zA-Z0-9_-À-ÖØ-öø-ÿ]+)\b/).map((ts, index) => {
                if (ts.match(/(#[a-zA-Z0-9_-À-ÖØ-öø-ÿ])/))
                  return (
                    <S.TextBold key={ts + '_' + index} display="inline">
                      {ts}
                    </S.TextBold>
                  );
                return utils.formatText(ts, (s: string) => (
                  <S.TextBold key={s} display="inline">
                    {s}
                  </S.TextBold>
                ));
              });
            })}
          </S.TextRegular>
          {showSeeMore && !seeMore ? (
            <S.SeeMoreContainer>
              <S.TextLink
                onClick={() => {
                  setSeeMore(!seeMore);
                  EventTrack.track('post_see_more_content', {
                    channel_id: channelId,
                    post_id: message.messageId,
                    see_more: !seeMore,
                  });
                }}
              >
                {seeMore ? __('ChatPublic.see_less', { locale: lang }) : __('ChatPublic.see_more', { locale: lang })}
              </S.TextLink>
            </S.SeeMoreContainer>
          ) : null}
        </S.CardBody>
      ) : null}
      {imageUrls?.length ? (
        <>
          <S.ImageContainer>
            <S.Image
              isImageLoading={isImageLoading}
              src={imageUrls[imageIndex]}
              ref={imageRef}
              hasMultipleImages={hasMultipleImages}
              onLoad={e => {
                setIsImageLoading(false);
              }}
              hasText={!!text}
              onClick={() =>
                touchImage(
                  imageUrls.map(url => ({ src: url })),
                  imageIndex,
                )
              }
            />
            {hasMultipleImages ? (
              <>
                <S.ArrowContainer
                  sidePosition="left"
                  onClick={e => {
                    e.stopPropagation();
                    const nextIndex = imageIndex - 1;
                    setImageIndex(nextIndex < 0 ? imageUrls.length - 1 : nextIndex);
                  }}
                >
                  <S.ArrowIcon name="Right" disableHover={true} />
                </S.ArrowContainer>
                <S.ArrowContainer
                  sidePosition="right"
                  onClick={e => {
                    e.stopPropagation();
                    const nextIndex = imageIndex + 1;
                    setImageIndex(nextIndex >= imageUrls.length ? 0 : nextIndex);
                  }}
                >
                  <S.ArrowIcon name="Right" disableHover={true} />
                </S.ArrowContainer>
              </>
            ) : null}
            {isImageLoading ? (
              <S.ImageLoadingBackground>
                <IOSLoading color="white" loading={true} />
              </S.ImageLoadingBackground>
            ) : null}
          </S.ImageContainer>
          {hasMultipleImages ? (
            <S.DotsIndicator
              color={theme.colors.black}
              index={imageIndex}
              numberOfDots={imageUrls.length}
              onDotClick={(newIndex: number) => setImageIndex(newIndex)}
            />
          ) : null}
        </>
      ) : null}
      {isPoll && pollOptions?.length ? (
        <PostPoll className="post-message-poll" message={message} pollOptions={pollOptions} />
      ) : null}
      {hasMetadata && !hasImage ? (
        <PostLink metadata={metadata} className="post-message-metadata-link" showClose={false} message={message} />
      ) : null}
      {options.length ? (
        <S.OptionsDropDown onSelect={(key: string) => onOptionClick?.(key, message)} options={options} hAlign="right">
          <S.KebabIcon name="Kebab" />
        </S.OptionsDropDown>
      ) : null}
      <PostActions
        className="post-message-actions"
        lang={lang}
        message={message}
        offlineAction={offlineAction}
        offlineMode={offlineMode}
      />
      {offlineMode && isLast ? (
        <S.BlurredContainer className="post-message-blurred">
          <S.SignInContainer>
            <S.TextRegular>{__('ChatPublic.offline_see_content')}</S.TextRegular>
            <S.SignInButton type="principal" onClick={() => offlineAction?.()}>
              {__('ChatPublic.offline_cta')}
            </S.SignInButton>
            <S.TextLink onClick={() => offlineAction?.()}>{__('WelcomeLogin.logIn')}</S.TextLink>
          </S.SignInContainer>
        </S.BlurredContainer>
      ) : null}
    </S.Container>
  );
};

/**
 * Get content text
 */
function getContent(message: IMessage, sender: IMember, channelName: string, lang?: LOCALE) {
  const { message: content, messageType, extraData } = message;
  if (messageType === 'admin') {
    switch (extraData?.code) {
      case 'public_channel_created':
        return __('Messages.ChatPublic.public_channel_created', {
          senderName: sender?.name || '',
          channelName,
          locale: lang,
        });
    }
  }
  return content;
}

export const PostHeader = ({
  className,
  contact,
  lang,
  member,
  subtitle,
  isInfoSection,
}: {
  className?: string;
  contact?: IContact;
  lang?: LOCALE;
  member?: IMember;
  subtitle: string;
  isInfoSection?: boolean;
}) => {
  const role = member?.role || CHANNEL_MEMBER_ROLE.VIEWER;
  const showRoleBubble = [CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(role);
  return (
    <S.CardTop className={className}>
      <LettersAvatar
        size={48}
        text={member?.name || ''}
        avatarColor={utils.getAvatarColor(member?.name || '')}
        img={member?.avatar || contact?.avatar}
      />
      <S.HeaderContent isInfoSection={isInfoSection}>
        <S.RowName isInfoSection={isInfoSection}>
          <S.TextSemiBold>{member?.name || ''}</S.TextSemiBold>
          {showRoleBubble ? (
            <S.RoleBubble role={role}>
              {role === CHANNEL_MEMBER_ROLE.OWNER
                ? __('ChatPublic.role.owner', { locale: lang })
                : __('ChatPublic.role.admin', { locale: lang })}
            </S.RoleBubble>
          ) : null}
        </S.RowName>
        <S.TextDate>{subtitle}</S.TextDate>
      </S.HeaderContent>
    </S.CardTop>
  );
};

export const PostLink = ({
  className,
  metadata,
  message,
  onClose,
  showClose,
}: {
  className?: string;
  metadata: IMetadata;
  message?: IMessage;
  onClose?: () => void;
  showClose: boolean;
}) => {
  return (
    <S.MetadataContainer
      className={className}
      onClick={() => {
        if (metadata.link) {
          window.open(metadata.link, '_blank');
          if (message)
            EventTrack.track('message_link_click', {
              channel_id: message.channelId,
              message_id: message.messageId,
              channel_type: CHANNEL_TYPE.PUBLIC,
              link: metadata.link,
            });
        }
      }}
    >
      {metadata.image_url ? <S.MetadataImage src={metadata.image_url} /> : null}
      <S.MetadataRight>
        <S.TextTitleMetadata showClose={showClose}>{metadata.title}</S.TextTitleMetadata>
        {metadata.description ? <S.TextDescriptionMetadata>{metadata.description}</S.TextDescriptionMetadata> : null}
        {metadata.link ? (
          <S.TextLinkMetadata>
            {metadata.link.replace('https://', '').replace('http://', '').replace('wwww.', '')}
          </S.TextLinkMetadata>
        ) : null}
      </S.MetadataRight>
      {showClose ? (
        <S.CloseButton
          onClick={e => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          <S.CloseIcon name="Close" />
        </S.CloseButton>
      ) : null}
    </S.MetadataContainer>
  );
};

/**
 * Actions for a post (reactions, comments)
 */
const PostActions = ({
  className,
  lang,
  message,
  offlineAction,
  offlineMode,
}: {
  className?: string;
  lang?: LOCALE;
  message: IMessage;
  offlineAction?: () => void;
  offlineMode: boolean;
}) => {
  const me = useSelector(userSelectors.getUser);
  const channel = useSelector(chatSelectors.getChannelById(message.channelId));
  const contacts = useSelector((state: IReduxState) => state.contact.inConsentio);
  const threadMessages = useSelector((state: IReduxState) =>
    (state.chat.threads?.[message.messageId] || []).filter(m => !m.isRemoved),
  );
  const dispatch = useDispatch<Dispatch<any>>();
  const [comment, setComment] = React.useState('');
  const [showPreview, setShowPreview] = React.useState(true);
  const inputRef = React.createRef<HTMLTextAreaElement>();

  const hasReactions = Object.keys(message.reactions || {}).length > 0;
  const hasComments = message.thread?.totalMessage > 0 || threadMessages.length > 0;
  const showLikesCommentsSummary = hasReactions || hasComments;

  const sendPostComment = React.useCallback(
    (text: string) => {
      const newComment = {
        createdAt: new Date().getTime(),
        channelId: message.channelId,
        extraData: {},
        message: text.trim(),
        messageId: 0,
        messageType: 'text',
        parentMessageId: message.messageId,
        reactions: {},
        senderId: me.id,
      };
      dispatch(chatActions.sendMessage(me.id, newComment as IMessage));
      EventTrack.track('post_comment_send', {
        channel_id: message.channelId,
        length: text.length,
        message_id: message.messageId,
      });
      setComment('');
      setShowPreview(false);
    },
    [me, message, dispatch],
  );

  let currentReaction: PostReaction;
  let totalReactions = 0;
  Object.keys(message.reactions).forEach(key => {
    if (message.reactions[key].includes(me.id + '')) {
      currentReaction = key as PostReaction;
    }
    totalReactions += message.reactions[key].length;
  });

  const addReaction = React.useCallback(
    throttle((reaction: PostReaction) => {
      if (currentReaction || currentReaction === reaction) {
        dispatch(chatActions.removeMessageReaction(me.id, message.channelId, message.messageId, currentReaction));
      }
      if (currentReaction !== reaction) {
        dispatch(chatActions.addMessageReaction(me.id, message.channelId, message.messageId, reaction));
      }
    }, 500),
    [currentReaction, me, message, dispatch],
  );

  const messagesToShow = threadMessages.slice(0, showPreview ? 2 : undefined);

  return (
    <S.BottomContainer className={className}>
      {showLikesCommentsSummary ? (
        <S.LikesCommentSummary hasReactions={hasReactions}>
          {hasReactions ? (
            <S.ReactionsSummaryContainer>
              <PostReactionsSummary message={message} totalReactions={totalReactions} />
              <S.PopupPreview className="post-reactions-popup">
                <PostReactionsPreview message={message} />
              </S.PopupPreview>
            </S.ReactionsSummaryContainer>
          ) : null}
          {hasComments ? (
            <S.TextLink
              onClick={() => {
                if (offlineMode) {
                  offlineAction?.();
                  return;
                }
                if (message.thread?.totalMessage > messagesToShow.length) {
                  dispatch(chatActions.getThreadMessages(channel.id, me.id, message.messageId));
                  setShowPreview(false);
                }
              }}
            >
              {__('ChatPublic.comments', {
                count: Math.max(message.thread.totalMessage, threadMessages.length),
                locale: lang,
              })}
            </S.TextLink>
          ) : null}
        </S.LikesCommentSummary>
      ) : null}
      <S.ActionsContainer>
        <S.ButtonContainer>
          <S.ReactionButton
            id="post-react"
            reaction={currentReaction}
            iconName={currentReaction ? undefined : 'Like'}
            iconUrl={currentReaction ? getPostReactionImage(currentReaction) : undefined}
            iconSize={currentReaction ? '24px' : '15px'}
            type="skip"
            withoutPadding={true}
            onClick={() => (offlineMode ? offlineAction?.() : addReaction(currentReaction || 'like'))}
          >
            {currentReaction
              ? chatService.getPostReactionLiteral(currentReaction, lang)
              : __('ChatPublic.like', { locale: lang })}
          </S.ReactionButton>
          <S.Popup className="post-reactions-popup">
            <PostReactionActions
              addReaction={r => (offlineMode ? offlineAction?.() : addReaction(r))}
              className="post-reactions"
            />
          </S.Popup>
        </S.ButtonContainer>
        <S.ButtonContainer>
          <S.ActionButton
            id="post-comment"
            iconName="Message"
            iconSize="15px"
            onClick={() => (offlineMode ? offlineAction?.() : inputRef.current?.focus())}
            type="skip"
            withoutPadding={true}
          >
            {__('ChatPublic.comment', { locale: lang })}
          </S.ActionButton>
        </S.ButtonContainer>
      </S.ActionsContainer>
      <S.CommentsContainer>
        <S.MessageInput
          inputRef={inputRef}
          me={me}
          onChangeText={(text: string) => setComment(text)}
          onSendMessage={text => sendPostComment(text)}
          showSendFiles={false}
          text={comment}
          showEmojiSelector={offlineMode ? false : true}
          onFocus={() => (offlineMode ? offlineAction?.() : undefined)}
        />
      </S.CommentsContainer>
      <S.ThreadContainer>
        {messagesToShow.map((msg, idx, arr) => {
          const { messageId, createdAt, senderId } = msg;
          const isLast = idx === arr.length - 1;
          let sender;
          if (senderId === me.id) {
            sender = chatService.meAsChannelMember(channel, me);
          } else {
            const member: IMember = msg.sender || channel.members.find(m => m.id === senderId);
            const contact = contacts[senderId];
            sender = {
              avatar: member?.avatar || contact?.avatar || '',
              companyName: member?.companyName || contact?.companyName || '',
              name: member?.name || contact?.name || '',
              avatarColor: utils.getAvatarColor(member?.name || contact?.name || ''),
            };
          }
          return (
            <React.Fragment key={messageId + createdAt + idx}>
              <S.ThreadMessage
                avatar={sender.avatar}
                avatarColor={sender.avatarColor}
                className="chat-public-thread-message"
                companyName={sender.companyName}
                message={msg}
                name={sender.name}
                offlineAction={offlineAction}
                offlineMode={offlineMode}
              />
              {isLast ? null : <S.ThreadDivider />}
            </React.Fragment>
          );
        })}
        {!offlineMode && threadMessages.length && message.thread?.totalMessage > messagesToShow.length ? (
          <S.SeeMoreCommentsContainer>
            <S.TextLink
              onClick={() => {
                if (offlineMode) {
                  offlineAction?.();
                  return;
                }
                dispatch(chatActions.getThreadMessages(channel.id, me.id, message.messageId));
                setShowPreview(false);
              }}
            >
              {__('ChatPublic.see_more_comments', { locale: lang })}
            </S.TextLink>
          </S.SeeMoreCommentsContainer>
        ) : null}
        {offlineMode && threadMessages.length ? (
          <S.OfflineContainer>
            <S.TextGrey>
              {__('ChatPublic.offline_description', { locale: lang })}
              <S.TextLink display="inline" onClick={() => offlineAction?.()}>
                {__('ChatPublic.log_in', { locale: lang })}
              </S.TextLink>
            </S.TextGrey>
          </S.OfflineContainer>
        ) : null}
      </S.ThreadContainer>
    </S.BottomContainer>
  );
};

const PostPoll = ({
  className,
  message,
  pollOptions,
}: {
  className: string;
  message: IMessage;
  pollOptions: Array<IPollOption>;
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useSelector(userSelectors.getUser);

  const hasAnswered = pollOptions.reduce((acc: boolean, o) => {
    if (o.voterIds?.includes(me.id)) acc = true;
    return acc;
  }, false);

  const onPressOption = React.useCallback(
    (option: IPollOption) => {
      dispatch(chatActions.pollAnswer(me.id!, message, option));
    },
    [dispatch, me.id, message],
  );

  return (
    <S.PollContainer className={className}>
      {pollOptions.map((option, idx, arr) => {
        if (hasAnswered) {
          const allVotersCount = arr.reduce((acc, o) => acc + o.voterIds.length, 0);
          return (
            <React.Fragment key={idx}>
              <S.PollAnswer>
                <ProgressBar
                  barHeight={9}
                  progress={(option.voterIds.length / allVotersCount) * 100}
                  progressPrecision={1}
                  textClassName="poll-message-progress-text"
                  title={option.text}
                />
              </S.PollAnswer>
              {idx === arr.length - 1 ? (
                <S.TextPollVotes>{__('ChatPublic.votes', { count: allVotersCount })}</S.TextPollVotes>
              ) : null}
            </React.Fragment>
          );
        }
        return (
          <S.PollButton key={idx} type="secondary" onClick={() => onPressOption(option)}>
            {option.text}
          </S.PollButton>
        );
      })}
    </S.PollContainer>
  );
};

export default React.memo(PostMessage);
