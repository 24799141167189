import {
  __,
  addresses,
  buyerWorkspaceActions,
  MAPPING_TOPIC,
  mappingActions,
  mappingSelectors,
  mappingServices,
  modalActions,
  notificationsActions,
  orderActions,
  parsers,
  productActions,
  productService,
  to,
  userActions,
  WORKING_STATUS,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { getDefaultColumns, IMAGE_DEFAULT, ROUTE_PATHS } from '../../../constants';
import { api } from '../../../store';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { Button, Radio, SimpleDropdown, TextArea } from '../../atoms';
import {
  AddressModal,
  ContactCell,
  FormatsModal,
  FormContainer,
  FormMenu,
  FormSection,
  InputWithLabel,
  Members,
  SearchProductModal,
  SelectClientModal,
  Table,
} from '../../molecules';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceClientReferenceDetails.styled';

export interface IStateProps {
  catalog: IWorkspace;
  clientId: number;
  clientsOld?: Array<IClient>;
  contacts: { [id: number]: IContact };
  countries: { [key: string]: ICountry };
  formats: IFormatsResponse;
  me: IUser;
  priceGroupIds?: { [key: string]: Array<string> };
  prices?: { [key: string]: { [key: number]: IPrice } };
  prodTypes: { [key: string]: IProdType };
}
type IReferenceType = 'product' | 'delivery_address' | 'pickup_address';
export type OwnProps = RouteComponentProps<{
  workspaceId: string;
  clientId: string;
  codeId: string;
  referenceType?: 'product' | 'delivery' | 'pickup';
}>;

export interface IDispatchProps {
  getAddressMappings: typeof mappingActions.addressMappingsGet;
  getPrices: typeof productActions.getPrices;
  getProductMappings: typeof mappingActions.productMappingsGet;
  mappingCreate: typeof mappingActions.mappingCreate;
  mappingDelete: typeof mappingActions.mappingDelete;
  mappingUpdate: typeof mappingActions.mappingUpdate;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  requestProInfo: typeof userActions.requestProInfo;
}

type IProps = IStateProps & IDispatchProps & OwnProps;

const WorkspaceClientReferenceDetails: React.FC<IProps> = (props: IProps) => {
  const {
    catalog,
    clientId,
    clientsOld,
    contacts,
    countries,
    formats,
    history,
    location,
    mappingCreate,
    mappingDelete,
    mappingUpdate,
    match: {
      params: { workspaceId, codeId, referenceType: refTypePath },
    },
    me,
    modalClose,
    modalOpen,
    notificationShow,
    priceGroupIds,
    prices,
    prodTypes,
  } = props;
  const dispatch = useDispatch<any>();
  const isNew = codeId === 'new';
  const queryType = refTypePath;

  const sectionsRef = React.createRef<HTMLDivElement>();
  const configurationRef = React.createRef<HTMLDivElement>();
  const referenceRef = React.createRef<HTMLDivElement>();
  const removeRef = React.createRef<HTMLDivElement>();
  const [viewing, setViewing] = React.useState<string>('configuration');
  const [referenceType, setReferenceType] = React.useState<IReferenceType>('product');
  const [referenceDocument, setReferenceDocument] = React.useState<string>('');
  const [showSearchProduct, setShowSearchProduct] = React.useState<boolean>(false);
  const [showSearchAddress, setShowSearchAddress] = React.useState<boolean>(false);
  const [showSelectFormat, setShowSelectFormat] = React.useState<boolean>(false);
  const [showSelectClient, setShowSelectClient] = React.useState<boolean>(false);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [productSelected, setProductSelected] = React.useState<IProductMapping>();
  const [addressSelected, setAddressSelected] = React.useState<IAddressMapping>();
  const [formatSelected, setFormatSelected] = React.useState<string>();
  const [deliveryAddresses, setDeliveryAddresses] = React.useState<Array<IAddress>>([]);
  const [pickupAddresses, setPickupAddresses] = React.useState<Array<IAddress>>([]);
  const [clientSelectedId, setClientSelectedId] = React.useState<number>(clientId || 0);
  const [client, setClient] = React.useState<IClient>();

  const contact = contacts[clientSelectedId];
  React.useEffect(() => {
    if (catalog?.id && clientSelectedId) {
      to(api.sellerWorkspace.clientIdsGet(me.id, catalog.id, [clientSelectedId])).then(({ data, err }) => {
        if (!err && data) {
          setClient(data.clients[0]);
        }
      });
    } else {
      setClient(clientsOld?.find(c => c.userId === clientSelectedId));
    }
  }, [clientsOld, catalog, clientSelectedId]);
  const clientName = contact?.name || '';
  const catalogKey = productService.getKey(catalog?.id, clientSelectedId);

  const mode = referenceType === 'product' ? 'product' : 'address';
  const isRefEan = catalog?.productMappingStrategy === 'ean_code';

  const productMapping: IProductMapping = useSelector(
    isNew || queryType !== 'product'
      ? () => undefined
      : mappingSelectors.getProductMapping(catalog?.id, 0, Number(codeId)),
  );
  const deliveryMapping: IAddressMapping = useSelector(
    isNew || queryType !== 'delivery'
      ? () => undefined
      : mappingSelectors.getDeliveryAddressMapping(catalog?.id, 0, Number(codeId)),
  );
  const pickupMapping: IAddressMapping = useSelector(
    isNew || queryType !== 'pickup'
      ? () => undefined
      : mappingSelectors.getDeliveryAddressMapping(catalog?.id, 0, Number(codeId)),
  );

  // Get formats if not already loaded
  React.useEffect(() => {
    if (formats.all?.length === 0 && catalog) {
      dispatch(orderActions.importFormatsGet(me.id, catalog?.id));
    }
  }, [formats, catalog, me.id, dispatch]);

  // Set initial format from client
  React.useEffect(() => {
    if (formats.all?.length && isNew) {
      client ? setFormatSelected(client.orderIncomingKind) : setShowSelectFormat(true);
    }
  }, [client, formats, isNew]);

  // Set up edit mode
  React.useEffect(() => {
    if (codeId && codeId !== 'new') {
      if (queryType === 'product' && productMapping) {
        setReferenceType('product');
        setReferenceDocument(productMapping?.mapping.external || '');
        setProductSelected(productMapping);
        setFormatSelected(productMapping?.mapping.parseFormat || '');
      } else if (queryType === 'delivery' && deliveryMapping) {
        setReferenceType('delivery_address');
        setReferenceDocument(deliveryMapping?.mapping.external || '');
        setAddressSelected(deliveryMapping);
        setFormatSelected(deliveryMapping?.mapping.parseFormat || '');
      } else if (queryType === 'pickup' && pickupMapping) {
        setReferenceType('pickup_address');
        setReferenceDocument(pickupMapping?.mapping.external || '');
        setAddressSelected(pickupMapping);
        setFormatSelected(pickupMapping?.mapping.parseFormat || '');
      } else {
        history.push(
          getPath({
            path: clientSelectedId ? ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES : ROUTE_PATHS.WORKSPACE_REFERENCES,
            workspaceId,
            clientId: clientSelectedId + '',
          }),
        );
      }
    }
  }, [codeId, productMapping, deliveryMapping, pickupMapping, queryType, history, workspaceId, clientSelectedId]);

  // Set up scroll
  React.useEffect(() => {
    const offset = 150;
    if (sectionsRef.current)
      sectionsRef.current.addEventListener('scroll', () => {
        if (sectionsRef.current) {
          if (sectionsRef.current.scrollTop + offset >= removeRef?.current?.offsetTop) {
            setViewing('remove');
          } else if (sectionsRef.current.scrollTop + offset >= referenceRef?.current?.offsetTop) {
            setViewing('reference');
          } else if (sectionsRef.current.scrollTop + offset >= configurationRef?.current?.offsetTop) {
            setViewing('configuration');
          }
        }
      });
  }, [sectionsRef]);

  // Get addresses
  React.useEffect(() => {
    if (clientSelectedId && me && catalog) {
      api.user.getAddressesBySellerBuyer(clientSelectedId, me?.id, catalog?.id).then(data => {
        setDeliveryAddresses(data.shippingAddresses);
        setPickupAddresses(data.pickupAddresses);
      });
    } else if (catalog && me) {
      dispatch(
        buyerWorkspaceActions.workspaceAddressesGet(me?.id, catalog?.id, (addrs, err) => {
          if (!err) setPickupAddresses(addrs);
        }),
      );
    }
  }, [clientSelectedId, me, catalog]);

  const onDeleteMapping = React.useCallback(
    (kind: 'product' | 'address') => {
      let m: IMapping;
      if (queryType === 'product') m = productMapping?.mapping;
      else if (queryType === 'delivery') m = deliveryMapping?.mapping;
      else if (queryType === 'pickup') m = pickupMapping?.mapping;
      if (!m) return;
      const uniqueClient =
        productMapping.mapping.clients.length === 1 && productMapping.mapping.clients[0] === clientSelectedId;
      modalOpen(
        __('MappingCodeList.ReferenceRemoval.title'),
        () => {
          modalClose();
          mappingDelete(me.id, catalog.id, clientSelectedId, m.id, uniqueClient, () => {
            notificationShow({
              title: __('WorkspaceClientReferenceDetails.notification_success.deleted'),
              subtitle: '',
              closable: true,
              style: 'success',
            });
            goToMappingList();
          });
        },
        {
          actionType: 'dangerous',
          icon: IMAGES.error,
          text2:
            kind === 'product'
              ? __('MappingCodeList.ReferenceRemoval.subtitle_product')
              : __('MappingCodeList.ReferenceRemoval.subtitle_address'),
          closeable: true,
          showCancelButton: true,
          buttonText: __('MappingCodeList.ReferenceRemoval.confirm'),
        },
        'nice',
      );
    },
    [
      catalog,
      clientSelectedId,
      deliveryMapping,
      history,
      mappingDelete,
      me,
      modalClose,
      modalOpen,
      pickupMapping,
      productMapping,
      queryType,
    ],
  );

  const goToMappingList = () => {
    setTimeout(() => {
      history.push(
        getPath({
          path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES : ROUTE_PATHS.WORKSPACE_REFERENCES,
          workspaceId,
          clientId: clientId + '',
        }),
      );
    }, 500);
  };

  const selectProductItem = React.useCallback(
    (item: IOrderItem) => {
      setProductSelected({
        productFound: true,
        product: parsers.orderItemToProduct(item),
        mapping: isNew
          ? mappingServices.getEmptyMapping(
              isRefEan ? MAPPING_TOPIC.PRODUCT_EAN : MAPPING_TOPIC.PRODUCT_SKU,
              catalog?.id,
            )
          : { ...productMapping?.mapping, internal: isRefEan ? item.eanCode : item.sku },
      });
      setShowSearchProduct(false);
      setHasChanges(true);
    },
    [setProductSelected, modalClose, isNew, productMapping, isRefEan, catalog],
  );

  const canSave = (): boolean => {
    return (
      !!hasChanges &&
      !!referenceDocument &&
      !!formatSelected &&
      ((mode === 'product' && !!productSelected) || (mode === 'address' && !!addressSelected))
    );
  };

  // On save mapping
  const onSave = React.useCallback(() => {
    if (!canSave()) return;

    let internalReference = '';
    let topic = MAPPING_TOPIC.PRODUCT_EAN;
    if (mode === 'product') {
      internalReference = isRefEan ? productSelected.product.eanCode : productSelected.product.sku;
      topic = isRefEan ? MAPPING_TOPIC.PRODUCT_EAN : MAPPING_TOPIC.PRODUCT_SKU;
    } else {
      internalReference = addressSelected.address.externalId;
      topic = referenceType === 'delivery_address' ? MAPPING_TOPIC.ADDRESS_DELIVERY : MAPPING_TOPIC.ADDRESS_PICKUP;
    }

    if (isNew) {
      mappingCreate(
        me.id,
        catalog.id,
        clientSelectedId !== 0 ? [clientSelectedId] : undefined,
        topic,
        internalReference,
        referenceDocument,
        formatSelected,
        error => {
          if (error) {
            return notificationShow({
              title: __('WorkspaceClientReferenceDetails.notification_error.title'),
              subtitle: __('WorkspaceClientReferenceDetails.notification_error.description'),
              closable: true,
              style: 'error',
            });
          }
          notificationShow({
            title: __('WorkspaceClientReferenceDetails.notification_success.added'),
            subtitle: '',
            closable: true,
            style: 'success',
          });
          goToMappingList();
        },
      );
    } else {
      let m: IMapping;
      if (queryType === 'product') m = productMapping?.mapping;
      else if (queryType === 'delivery') m = deliveryMapping?.mapping;
      else if (queryType === 'pickup') m = pickupMapping?.mapping;
      if (!m) return;
      if (productMapping.mapping) {
        mappingUpdate(
          me.id,
          catalog.id,
          clientSelectedId !== 0 ? [clientSelectedId] : undefined,
          m.id,
          m.topic,
          referenceDocument,
          internalReference,
          formatSelected,
          error => {
            if (error) {
              return notificationShow({
                title: __('WorkspaceClientReferenceDetails.notification_error.title'),
                subtitle: __('WorkspaceClientReferenceDetails.notification_error.description'),
                closable: true,
                style: 'error',
              });
            }
            notificationShow({
              title: __('WorkspaceClientReferenceDetails.notification_success.saved'),
              subtitle: '',
              closable: true,
              style: 'success',
            });
            goToMappingList();
          },
        );
      } else {
        mappingCreate(
          me.id,
          catalog.id,
          clientSelectedId !== 0 ? [clientSelectedId] : undefined,
          topic,
          internalReference,
          referenceDocument,
          formatSelected,
          error => {
            if (error) {
              return notificationShow({
                title: __('WorkspaceClientReferenceDetails.notification_error.title'),
                subtitle: __('WorkspaceClientReferenceDetails.notification_error.description'),
                closable: true,
                style: 'error',
              });
            }
            mappingDelete(me.id, catalog.id, clientSelectedId, m.id, false, () => {
              notificationShow({
                title: __('WorkspaceClientReferenceDetails.notification_success.saved'),
                subtitle: '',
                closable: true,
                style: 'success',
              });
              goToMappingList();
            });
          },
        );
      }
    }
  }, [
    addressSelected,
    canSave,
    catalog,
    clientSelectedId,
    deliveryMapping,
    formatSelected,
    history,
    isNew,
    isRefEan,
    mappingCreate,
    mappingUpdate,
    me,
    mode,
    notificationShow,
    pickupMapping,
    productMapping,
    productSelected,
    queryType,
    referenceDocument,
    referenceType,
  ]);

  const format = formatSelected ? formats.all.find(f => f.slug === formatSelected) : undefined;

  const parentClientSections = clientId
    ? [
        {
          label: __('Components.Header.WorkspaceClients'),
          action: () =>
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_CLIENTS,
                workspaceId,
              }),
            ),
        },
        {
          label: clientName,
          action: () =>
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT,
                workspaceId,
                clientId: clientId + '',
              }),
            ),
        },
      ]
    : [];

  return (
    <Workspace
      parentSections={[
        ...parentClientSections,
        {
          label: __('WorkspaceClientEdit.reference_list.title'),
          action: () =>
            history.push(
              getPath({
                path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES : ROUTE_PATHS.WORKSPACE_REFERENCES,
                workspaceId,
                clientId: clientId + '',
              }),
            ),
        },
      ]}
      subtitle=""
      title={isNew ? __('MappingCodeList.Add.cta') : __('WorkspaceClientReferenceDetails.modify_reference')}
      tabSelected={clientId ? 'clients' : 'sale'}
      workspaceId={Number(workspaceId)}
    >
      <S.Container>
        <FormMenu
          sections={[
            {
              id: 'configuration',
              title: __('WorkspaceClientReferenceDetails.configuration'),
              icon: 'Edit',
            },
            {
              id: 'reference',
              title: __('WorkspaceClientReferenceDetails.reference_document'),
              icon: 'Document',
            },
            isNew
              ? null
              : {
                  id: 'remove',
                  title: __('WorkspaceClientReferenceDetails.remove_reference'),
                  icon: 'Trash',
                },
          ]}
          selected={viewing}
        />
        <FormContainer
          className="WorkspaceClientReferenceDetails-FormContainer"
          canSave={!!canSave()}
          save={() => onSave()}
          sectionsRef={sectionsRef}
        >
          <FormSection
            id="configuration"
            title={__('WorkspaceClientReferenceDetails.configuration')}
            sectionRef={configurationRef}
          >
            {isNew ? (
              <InputWithLabel
                label={__('WorkspaceClientReferenceDetails.reference_type')}
                description={__('WorkspaceClientReferenceDetails.reference_type_placeholder')}
                isRequired={true}
              >
                <Radio
                  name="reference_type"
                  direction="column"
                  options={[
                    {
                      label: __('WorkspaceClientReferenceDetails.product'),
                      value: 'product',
                    },
                    {
                      label: __('WorkspaceClientReferenceDetails.delivery_address'),
                      value: 'delivery_address',
                    },
                    {
                      label: __('WorkspaceClientReferenceDetails.pickup_address'),
                      value: 'pickup_address',
                    },
                  ]}
                  containerMargin={'4px 0'}
                  alignItems="flex-start"
                  styleLabel={{ margin: '5px 0' }}
                  itemsChecked={[referenceType]}
                  onChange={(key, value: IReferenceType, error) => {
                    if (!error) {
                      setReferenceType(value);
                      setHasChanges(true);
                      if (!clientSelectedId && value === 'delivery_address' && isNew) setShowSelectClient(true);
                    }
                  }}
                />
              </InputWithLabel>
            ) : null}
            <InputWithLabel label={__('WorkspaceClientReferenceDetails.reference_consentio')} isRequired={true}>
              {mode === 'product' && productSelected ? (
                <Table
                  className="WorkspaceClientReferenceDetails-Table"
                  columns={[
                    {
                      id: 'product',
                      title: __('MappingCodeList.ProductTable.product_field'),
                      element: (prodMapping: IProductMapping) => {
                        const { product } = prodMapping;
                        const typeVariety = productService.getProductTypeVarietyDisplay(
                          product.type.type,
                          prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
                          product.type.variety,
                        );

                        return (
                          <S.FlexRow>
                            {prodMapping.productFound ? (
                              <ContactCell
                                avatar={
                                  product.images[0]?.image?.url ||
                                  prodTypes[product.type.type]?.defaultImageUrl ||
                                  IMAGE_DEFAULT
                                }
                                primaryText={product.productTitle || typeVariety}
                                workingStatus={
                                  product.status === 'active' ? WORKING_STATUS.AVAILABLE : WORKING_STATUS.NOT_AVAILABLE
                                }
                              />
                            ) : (
                              <>
                                <S.WarningIcon name="Warning" disableHover={true} />
                                <S.TextGrey2>
                                  {__('ProductMapping.product_info.text_1')}
                                  <S.TextRegular>{' "' + prodMapping.mapping.internal + '" '}</S.TextRegular>
                                  {__('ProductMapping.product_info.text_2')}
                                </S.TextGrey2>
                              </>
                            )}
                          </S.FlexRow>
                        );
                      },
                      minWidth: '140px',
                      width: '25%',
                    },
                    {
                      id: 'internal-reference',
                      title: isRefEan ? __('MappingCodeList.ProductTable.ean_code') : 'SKU',
                      width: '10%',
                      value: (prodMapping: IProductMapping) =>
                        prodMapping.mapping.internal ||
                        (isRefEan ? prodMapping.product.eanCode : prodMapping.product.sku),
                      getColor: (prodMapping: IProductMapping) =>
                        prodMapping.productFound ? theme.colors.black : theme.colors.grey2,
                    },
                    {
                      id: 'action',
                      title: '',
                      width: '10%',
                      element: (prodMapping: IProductMapping) => {
                        return (
                          <S.FlexRow justifyContent="flex-end">
                            {prodMapping.productFound ? (
                              <SimpleDropdown
                                hAlign="right"
                                options={[
                                  {
                                    value: __('ProductMapping.main_action.change_product'),
                                    key: 'change_product',
                                  },
                                ]}
                                onSelect={(key: string) => key === 'change_product' && setShowSearchProduct(true)}
                              >
                                <S.KebabIcon name="Kebab" />
                              </SimpleDropdown>
                            ) : (
                              <S.MainActionButton onClick={() => setShowSearchProduct(true)} type="secondary">
                                {prodMapping.productFound
                                  ? __('ProductMapping.main_action.change_product')
                                  : __('ProductMapping.main_action.search_product')}
                              </S.MainActionButton>
                            )}
                          </S.FlexRow>
                        );
                      },
                    },
                  ]}
                  emptyText=""
                  onClickRow={(data, e) => null}
                  rowCursor="inherit"
                  selectable={false}
                  values={[productSelected]}
                  productColumns={getDefaultColumns()}
                />
              ) : mode === 'address' && addressSelected ? (
                <Table
                  className="WorkspaceClientReferenceDetails-Table"
                  columns={[
                    {
                      id: 'address',
                      title: __('MappingCodeList.AddressTable.address_field'),
                      element: (addressMapping: IAddressMapping) => {
                        const { address, addressFound } = addressMapping;

                        if (addressFound) {
                          return (
                            <S.FlexRow>
                              <S.AddressIcon name="Address" disableHover={true} />
                              <S.FlexCol>
                                <S.AddressTitle deleted={!addressFound}>
                                  {addresses.getAddressName(address, countries, true)}
                                </S.AddressTitle>
                                <S.TextRegular deleted={!addressFound}>
                                  {addresses.getAddressName(address, countries, false)}
                                </S.TextRegular>
                              </S.FlexCol>
                            </S.FlexRow>
                          );
                        }

                        return (
                          <S.FlexRow>
                            <S.WarningIcon name="Warning" disableHover={true} />
                            <S.FlexCol>
                              <S.TextGrey2>
                                {__('AddressMapping.address_info.text_1')}
                                <S.TextRegular>{' "' + addressMapping.mapping.internal + '" '}</S.TextRegular>
                                {__('AddressMapping.address_info.text_2')}
                              </S.TextGrey2>
                            </S.FlexCol>
                          </S.FlexRow>
                        );
                      },
                      minWidth: '140px',
                      width: '25%',
                    },
                    {
                      id: 'external-id',
                      title: __('MappingCodeList.AddressTable.external_id'),
                      width: '10%',
                      value: (addressMapping: IAddressMapping) =>
                        addressMapping.mapping.internal || addressMapping.address.externalId || '-',
                      getColor: (addressMapping: IAddressMapping) =>
                        addressMapping.addressFound ? theme.colors.black : theme.colors.grey2,
                    },
                    {
                      id: 'action',
                      title: '',
                      width: '10%',
                      element: (addressMapping: IAddressMapping) => {
                        return (
                          <S.FlexRow justifyContent="flex-end">
                            {addressMapping.addressFound ? (
                              <SimpleDropdown
                                options={[
                                  {
                                    value: __('AddressMapping.main_action.change_address'),
                                    key: 'change_address',
                                  },
                                ]}
                                onSelect={(key: string) => {
                                  if (key === 'change_address') {
                                    if (
                                      (client && referenceType === 'delivery_address') ||
                                      referenceType === 'pickup_address'
                                    ) {
                                      setShowSearchAddress(true);
                                    } else setShowSelectClient(true);
                                  }
                                }}
                                hAlign="right"
                              >
                                <S.KebabIcon name="Kebab" />
                              </SimpleDropdown>
                            ) : (
                              <S.MainActionButton
                                onClick={() => {
                                  if (
                                    (client && referenceType === 'delivery_address') ||
                                    referenceType === 'pickup_address'
                                  ) {
                                    setShowSearchAddress(true);
                                  } else setShowSelectClient(true);
                                }}
                                type="secondary"
                              >
                                {addressMapping.addressFound
                                  ? __('AddressMapping.main_action.change_address')
                                  : __('AddressMapping.main_action.search_address')}
                              </S.MainActionButton>
                            )}
                          </S.FlexRow>
                        );
                      },
                    },
                  ]}
                  emptyText=""
                  onClickRow={(data, e) => null}
                  rowCursor="inherit"
                  selectable={false}
                  values={[addressSelected]}
                  productColumns={getDefaultColumns()}
                />
              ) : (
                <S.SelectButton
                  type="link"
                  iconName="Add-more"
                  onClick={() => {
                    if (mode === 'product') {
                      setShowSearchProduct(true);
                    } else if (mode === 'address') {
                      (client && referenceType === 'delivery_address') || referenceType === 'pickup_address'
                        ? setShowSearchAddress(true)
                        : setShowSelectClient(true);
                    }
                  }}
                  withoutPadding={true}
                >
                  {mode === 'product'
                    ? __('WorkspaceClientReferenceDetails.select_product')
                    : __('WorkspaceClientReferenceDetails.select_address')}
                </S.SelectButton>
              )}
            </InputWithLabel>
          </FormSection>

          <FormSection
            id="reference"
            title={__('WorkspaceClientReferenceDetails.reference_document')}
            sectionRef={referenceRef}
            titleTooltip={__('WorkspaceClientReferenceDetails.reference_tooltip')}
            numHeaders={isNew ? 0 : undefined}
          >
            <InputWithLabel
              label={''}
              footerText={
                format?.slug === 'edifact' ? __('WorkspaceClientReferenceDetails.magic_order_format_footer') : ''
              }
              isRequired={true}
            >
              <TextArea
                maxLength={1024}
                name="reference_document"
                value={referenceDocument}
                placeholder={
                  format?.slug === 'edifact'
                    ? __('WorkspaceClientReferenceDetails.reference_document_placeholder_edi')
                    : __('WorkspaceClientReferenceDetails.reference_document_placeholder')
                }
                onChange={(name, val) => {
                  setReferenceDocument(val + '');
                  setHasChanges(true);
                }}
                width="90%"
                rows={5}
              />
            </InputWithLabel>
            {client ? (
              <InputWithLabel label={__('WorkspaceClientReferenceDetails.client')} isRequired={true}>
                <Members
                  contacts={contacts}
                  me={me}
                  members={[client].map(c => ({
                    userId: c.userId,
                  }))}
                  size={36}
                  clients={[client]}
                  onClick={() => null}
                />
              </InputWithLabel>
            ) : null}

            <InputWithLabel
              label={__('WorkspaceClientReferenceDetails.magic_order_format')}
              isRequired={true}
              tooltipText={__('WorkspaceClientReferenceDetails.magic_order_format_tooltip')}
            >
              {format ? (
                <S.FlexRow onClick={() => isNew && setShowSelectFormat(true)} cursor={isNew ? 'pointer' : 'inherit'}>
                  <ContactCell avatar={format.logo_url} primaryText={format.label} avatarSize={36} />
                </S.FlexRow>
              ) : (
                <S.SelectButton
                  type="link"
                  iconName="Add-more"
                  onClick={() => {
                    setShowSelectFormat(true);
                  }}
                  withoutPadding={true}
                >
                  {__('WorkspaceClientReferenceDetails.select_format')}
                </S.SelectButton>
              )}
            </InputWithLabel>
          </FormSection>

          {isNew ? null : (
            <FormSection
              id="remove"
              title={__('WorkspaceClientReferenceDetails.remove_reference')}
              sectionRef={removeRef}
              numHeaders={0}
            >
              {clientId ? null : (
                <S.TextRegular>{__('WorkspaceClientReferenceDetails.remove_placeholder')}</S.TextRegular>
              )}
              <Button
                type="delete"
                disabled={!clientId}
                onClick={() => onDeleteMapping(mode)}
                iconName="Trash"
                iconSize="18px"
              >
                {__('WorkspaceClientReferenceDetails.remove_reference')}
              </Button>
            </FormSection>
          )}
        </FormContainer>
      </S.Container>

      {showSearchProduct ? (
        <SearchProductModal
          amSeller={true}
          cartClean={undefined}
          catalog={catalog}
          close={() => setShowSearchProduct(false)}
          contactId={clientSelectedId}
          featured={{}}
          history={history}
          location={location}
          me={me}
          name={''}
          priceGroupIds={priceGroupIds[catalogKey]}
          priceMode={'read'}
          prices={prices[catalogKey]}
          selectMode="integration"
          selectProductItem={selectProductItem}
          showFeatured={false}
          status="active"
        />
      ) : null}

      {showSearchAddress ? (
        <AddressModal
          addresses={referenceType === 'delivery_address' ? deliveryAddresses : pickupAddresses}
          address={addressSelected?.address}
          catalogId={catalog?.id}
          client={client}
          disabledAddresses={(referenceType === 'delivery_address' ? deliveryAddresses : pickupAddresses)
            .filter(a => !a.externalId)
            .map(a => a.id)}
          onBack={
            referenceType === 'delivery_address' && !clientId
              ? () => {
                  setShowSearchAddress(false);
                  setShowSelectClient(true);
                }
              : undefined
          }
          onClose={() => {
            setShowSearchAddress(false);
          }}
          title={__('MappingCodeList.ChangeAddress.title')}
          subtitle={__('MappingCodeList.ChangeAddress.subtitle', {
            reference: referenceDocument || '',
          })}
          onSelectAddress={(a: IAddress) => {
            setShowSearchAddress(false);
            setAddressSelected({
              addressFound: true,
              address: a,
              mapping: isNew
                ? mappingServices.getEmptyMapping(
                    referenceType === 'delivery_address'
                      ? MAPPING_TOPIC.ADDRESS_DELIVERY
                      : MAPPING_TOPIC.ADDRESS_PICKUP,
                    catalog?.id,
                  )
                : addressSelected?.mapping,
            });
            setHasChanges(true);
          }}
        />
      ) : null}

      {showSelectFormat ? (
        <FormatsModal
          close={() => setShowSelectFormat(false)}
          formats={formats}
          initSelectedFormat={formatSelected}
          onSubmit={(f: IFormat) => {
            setFormatSelected(f.slug);
            setHasChanges(true);
          }}
        />
      ) : null}

      {showSelectClient ? (
        <SelectClientModal
          title={__('SelectClientModal.title')}
          subtitle={__('SelectClientModal.subtitle')}
          catalogId={catalog?.id}
          onClose={() => {
            setShowSelectClient(false);
            setClientSelectedId(0);
          }}
          selectContact={cId => {
            setShowSelectClient(false);
            setClientSelectedId(cId);
            setHasChanges(true);
            setShowSearchAddress(true);
          }}
          me={me}
        />
      ) : null}
    </Workspace>
  );
};

export default React.memo(WorkspaceClientReferenceDetails);
