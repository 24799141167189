import { PICTURE_SMALL_SIZE } from '../../../constants';
import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isImage?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: calc(${(p: IStyledProps) => p.theme.windowHeight} - 2 * ${(p: IStyledProps) => p.theme.headerHeight});
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Content = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ImageContainer = styled(ColumnContainer)`
  max-width: 80%;
  height: calc(${(p: IStyledProps) => p.theme.windowHeight} - 2 * ${(p: IStyledProps) => p.theme.headerHeight});
  margin: auto;
  justify-content: center;
`;

export const Image = styled.img<IStyledProps>`
  height: auto;
  max-height: calc(100% - 250px);
  max-width: 100%;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(24)};
  object-fit: contain;

  ${(p: IStyledProps) =>
    !p.isImage &&
    css`
      max-height: 100%;
      width: 150px;
      height: 150px;
      margin-bottom: 0;
    `}
`;

export const FileContainer = styled(ColumnContainer)`
  min-width: 700px;
  width: 100%;
  height: calc(100% - 300px);
  justify-content: center;
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(24)};
`;

export const TextFileName = styled(TextLabel)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseIcon = styled(FontIcon)`
  font-size: 23px;
  width: 23px;
  height: 23px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const SendContainer = styled(ColumnContainer)`
  position: absolute;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TextSend = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: right;
`;

export const SendIcon = styled(FontIcon)<IStyledProps>`
  font-size: 30px;
  width: 50px;
  height: 50px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3.5)};
  align-self: flex-end;
  align-items: center;
  justify-content: center;
`;

export const ThumbnailsRow = styled(RowContainer)`
  max-width: 75%;
  overflow: hidden;
  overflow-x: auto;
  align-items: center;

  position: absolute;
  bottom: 50px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const AddContainer = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: ${PICTURE_SMALL_SIZE};
  height: ${PICTURE_SMALL_SIZE};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const AddIcon = styled(FontIcon)`
  font-size: 26px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
