import styled, { ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  FontIcon,
  Input,
  RowContainer,
  SimpleDropdown,
  SubHeader,
  TextLabel,
} from '../../atoms';
import { FilterButton as FB, MiniImages as MI } from '../../molecules';

interface IStyledProps {
  disabled?: boolean;
  disableHover?: boolean;
  hasOverflow?: boolean;
  hiddenAtScreenWidth?: string;
  isLast?: boolean;
  isSearch?: boolean;
  isSelected?: boolean;
  overlay?: boolean;
  selected?: boolean;
  status?: string;
  theme: ITheme;
}

export const Column = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
`;

export const SubheaderCustom = styled(SubHeader)`
  position: relative;
  border-bottom: none;
  height: auto;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    height: ${(p: IStyledProps) => p.theme.headerHeight};
  }
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

const SubheaderLeft = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

export const SubheaderLeftSecond = styled(SubheaderLeft)`
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: row;
  }
`;
export const SubheaderLeftSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  max-width: 350px;
  align-items: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-bottom: 0;
  }
`;
export const SubheaderLeftFilters = styled(RowContainer)`
  display: flex;
  justify-content: flex-start;
`;

export const SubheaderRight = styled(RowContainer)`
  justify-content: flex-end;
  align-items: center;
`;

export const FontIconWrapper = styled.div`
  align-self: center;
  width: 10px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: 12px;
`;

export const ProductDetails = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 5;
`;

export const ShareTouchable = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: ${(p: IStyledProps) => (p.disableHover ? 'not-allowed' : 'pointer')};
  align-self: center;
`;

export const CreateButton = styled(Button)`
  margin: 0 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Sections = styled(Button)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FilterButton = styled(FB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 40px;
`;
