import { Api, chatActions, modalActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navMiniChannelAction } from '../actions/nav';
import { IChatListRouteProps } from '../components/organisms/ChatList';
import ChatPublicSinglePost, {
  IDispatchProps,
  IRouteProps,
  IStateProps,
} from '../components/organisms/ChatPublicSinglePost';
import { IReduxState } from '../reducers';

function mapStateToPropsChat(state: IReduxState, props: IRouteProps): IStateProps {
  const channelId = props.match.params.channelId;
  const postId = Number(props.match.params.postId);
  const {
    chat: { channels, initialized, lastMessageAt, lastReadContactAt, messages, unreadMessageCount },
    contact: { inConsentio },
    user: { user },
  } = state;
  const channel = channels[channelId];
  return {
    channel,
    channels,
    contacts: inConsentio,
    initialized,
    lastMessageAt,
    lastReadContactAt: lastReadContactAt[channelId] || 0,
    me: user,
    post: messages[channelId]?.find(m => m.messageId === postId),
    postId,
    unreadMessageCount: unreadMessageCount[channelId],
  };
}

function mapDispatchToPropsChat(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IChatListRouteProps,
): IDispatchProps {
  return bindActionCreators(
    {
      deleteMessage: chatActions.deleteMessage,
      editMessage: chatActions.editMessage,
      getMessage: chatActions.getMessage,
      getMessages: chatActions.getMessages,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navMiniChannelAction,
      notificationShow: notificationsActions.notificationShow,
      openChannel: chatActions.openChannel,
      sendMessage: chatActions.sendMessage,
      setActiveChannel: chatActions.setActiveChannel,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChat, mapDispatchToPropsChat)(ChatPublicSinglePost);
