import {
  __,
  buyerWorkspaceActions,
  buyerWorkspaceSelectors,
  chatSelectors,
  colors,
  contactActions,
  contactSelectors,
  date,
  modalActions,
  notificationsActions,
  ORDER_ACCEPT_TYPE,
  parsers,
  productActions,
  RenderTrack,
  sellerWorkspaceService,
  userSelectors,
  utils,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { getDefaultColumns, meAsContact, ROUTE_PATHS } from '../../../constants';
import ShowroomBuy from '../../../screens/showroom-buy';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Ribbon, Select, SimpleDropdown, Switch } from '../../atoms';
import Button from '../../atoms/Button/Button.component';
import { FormContainer, FormMenu, FormSection, MiniImages, Table } from '../../molecules';
import { InputWithLabel } from '../ChatList/ChatList.styled';
import RowList from '../PublicShowroom/RowList.component';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceSupplierEdit.styled';

export type IProps = { t?: number };

const WorkspaceSupplierEdit: React.FC<IProps> = ({ t = Date.now() }) => {
  const history = useHistory();
  const params = useParams<{ workspaceId: string; supplierId: string }>();
  const workspaceId = Number(params.workspaceId);
  const supplierId = Number(params.supplierId);
  const channel: IChannel = useSelector(chatSelectors.getChannelByContactId(supplierId));
  const channels: Record<number, IChannel> = useSelector(chatSelectors.getChannels());
  const supplier: ISupplier = useSelector(buyerWorkspaceSelectors.getSupplier(workspaceId, supplierId));
  const contacts: { [cId: number]: IContact } = useSelector(contactSelectors.getContacts());
  const workspace: IWorkspace = useSelector(buyerWorkspaceSelectors.getWorkspace(workspaceId));
  const me: IUser = useSelector(userSelectors.getUser);
  const [newSupplier, setNewSupplier] = React.useState<ISupplier>(supplier);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [viewing, setViewing] = React.useState<string>('supplier');
  const [showOrders, setShowOrders] = React.useState<boolean>();
  const [orderHashId, setOrderHashId] = React.useState<string>();
  const sectionsRef = React.useRef<HTMLDivElement>();
  const supplierRef = React.useRef<HTMLDivElement>();
  const purchasesRef = React.useRef<HTMLDivElement>();
  const productsRef = React.useRef<HTMLDivElement>();
  const colleaguesRef = React.useRef<HTMLDivElement>();
  const securityRef = React.useRef<HTMLDivElement>();
  const contractsRef = React.useRef<HTMLDivElement>();
  const dispatch = useDispatch<Dispatch<any>>();
  React.useEffect(() => {
    RenderTrack.track('WorkspaceSupplierEdit', {});
    if (!supplier) {
      dispatch(buyerWorkspaceActions.suppliersGet(me.id, Number(workspaceId)));
    }

    const offset = 150;
    if (sectionsRef.current)
      sectionsRef.current.addEventListener('scroll', () => {
        if (sectionsRef.current.scrollTop + offset >= securityRef?.current?.offsetTop) {
          setViewing('security');
        } else if (sectionsRef.current?.scrollTop + offset >= colleaguesRef?.current?.offsetTop) {
          setViewing('colleagues');
        } else if (sectionsRef.current?.scrollTop + offset >= productsRef?.current?.offsetTop) {
          setViewing('products');
        } else if (sectionsRef.current?.scrollTop + offset >= purchasesRef?.current?.offsetTop) {
          setViewing('accept');
        } else if (sectionsRef.current?.scrollTop + offset >= contractsRef?.current?.offsetTop) {
          setViewing('contracts');
        } else if (sectionsRef.current?.scrollTop + offset >= supplierRef?.current?.offsetTop) {
          setViewing('supplier');
        }
      });
  }, []);

  React.useEffect(() => {
    setNewSupplier(supplier);
  }, [supplier]);

  if (!workspace || !newSupplier) return null;
  const amEditor = sellerWorkspaceService.getRole(workspace, me.id) !== 'viewer';
  const contact = contacts[supplier?.userId];

  return (
    <>
      {window.location.pathname.includes('/buy/') ? (
        <WorkspaceShowroom history={history} location={location} contact={contact} />
      ) : null}
      <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
      <Workspace
        isBuyerWorkspace={true}
        parentSections={
          showOrders
            ? [
                {
                  label: __('Components.Header.WorkspaceSuppliers'),
                  action: () =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_SUPPLIERS,
                        workspaceId: workspace.id + '',
                      }),
                    ),
                },

                ...(orderHashId
                  ? [
                      {
                        label: newSupplier.name,
                        action: () => {
                          setShowOrders(false);
                          setOrderHashId(undefined);
                        },
                      },
                      {
                        label: __('Components.Header.Orders'),
                        action: () => {
                          setOrderHashId(undefined);
                        },
                      },
                    ]
                  : [
                      {
                        label: newSupplier.name,
                        action: () => {
                          setShowOrders(false);
                        },
                      },
                    ]),
              ]
            : [
                {
                  label: __('Components.Header.WorkspaceSuppliers'),
                  action: () =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_SUPPLIERS,
                        workspaceId: workspace.id + '',
                      }),
                    ),
                },
              ]
        }
        subtitle={''}
        title={showOrders ? (orderHashId ? orderHashId : __('Components.Header.Orders')) : newSupplier.name}
        tabSelected="suppliers"
        workspaceId={Number(workspaceId)}
      >
        {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
        {supplier && supplier.blockedOrders ? (
          <Ribbon
            text={__('WorkspaceClientEdit.blocked')}
            onClickAction={() => disableForAll(false)}
            actionText={amEditor ? __('WorkspaceClientEdit.unblock') : undefined}
            type="warning"
          />
        ) : null}
        <S.Container>
          <FormMenu
            sections={[
              {
                id: 'supplier',
                title: __('WorkspaceSupplierEdit.Menu.supplier.title'),
                description: __('WorkspaceSupplierEdit.Menu.supplier.description'),
                icon: 'Account',
              },
              {
                id: 'contracts',
                title: __('WorkspaceSupplierEdit.contracts.title'),
                description: __('WorkspaceSupplierEdit.Menu.contracts.title'),
                icon: 'Price-group',
              },
              {
                id: 'accept',
                title: __('WorkspaceSupplierEdit.Menu.purchases.title'),
                description: __('WorkspaceSupplierEdit.Menu.purchases.description'),
                icon: 'Pickup-package',
              },
              {
                id: 'products',
                title: __('WorkspaceSupplierEdit.Menu.products.title'),
                description: __('WorkspaceSupplierEdit.Menu.products.description'),
                icon: 'Recent',
              },
              // {
              //   id: 'colleagues',
              //   title: __('WorkspaceSupplierEdit.Menu.colleagues.title'),
              //   description: __('WorkspaceSupplierEdit.Menu.colleagues.description'),
              //   icon: 'Team-icon',
              // },
              // {
              //   id: 'delivery',
              //   title: __('WorkspaceSupplierEdit.Menu.delivery.title'),
              //   description: __('WorkspaceSupplierEdit.Menu.delivery.description'),
              //   icon: 'Pickup-truck',
              // },
              {
                id: 'security',
                title: __('WorkspaceSupplierEdit.Menu.security.title'),
                description: __('WorkspaceSupplierEdit.Menu.security.description'),
                icon: 'Security',
              },
            ]}
            selected={viewing}
          />
          <FormContainer
            className="WorkspaceSupplierEdit-FormContainer"
            canSave={hasChanges}
            save={() => {
              dispatch(
                buyerWorkspaceActions.supplierUpdate(me.id, workspace.id, newSupplier, error => {
                  if (error) {
                    return dispatch(
                      notificationsActions.notificationShow({
                        title: __('WorkspaceClientEdit.error'),
                        subtitle: __('WorkspaceClientEdit.error_description'),
                        closable: true,
                        style: 'error',
                      }),
                    );
                  }
                  return dispatch(
                    notificationsActions.notificationShow({
                      title: __('WorkspaceClientEdit.success'),
                      subtitle: __('WorkspaceClientEdit.success_description'),
                      closable: true,
                      style: 'success',
                    }),
                  );
                }),
              );
              setHasChanges(false);
            }}
            sectionsRef={sectionsRef}
          >
            <>
              <Supplier
                supplier={newSupplier}
                supplierRef={supplierRef}
                createNewContact={() =>
                  dispatch(
                    contactActions.createNewContact(
                      me.id,
                      supplier.userId,
                      err =>
                        !err &&
                        dispatch(
                          notificationsActions.notificationShow({
                            title: __('SuppliersList.create_contact_success'),
                            subtitle: __('SuppliersList.create_contact_success_sub'),
                            closable: true,
                            style: 'info',
                          }),
                        ),
                    ),
                  )
                }
                isContact={!!contact}
                myId={me.id}
                navigateToBuy={() => {
                  if (channel)
                    dispatch(
                      navActions.navigateChannelByPath(ROUTE_PATHS.CONTACT, channel.id, (path: string) =>
                        history.push(path),
                      ),
                    );
                }}
              />
              <Contracts
                amEditor={amEditor}
                contractsRef={contractsRef}
                history={history}
                myId={me.id}
                supplierId={newSupplier.userId}
                workspaceId={Number(workspaceId)}
              />
              <Purchases
                amEditor={amEditor}
                purchasesRef={purchasesRef}
                supplier={newSupplier}
                supplierUpdate={s => {
                  setNewSupplier(s);
                  setHasChanges(true);
                }}
              />
              <Products
                contacts={contacts}
                history={history}
                me={me}
                navigateToShowroomBuy={dispatch((contact, cb, item) =>
                  // TODO: when we have the pricemode for the supplier, we can use it here
                  navActions.navigateToShowroomBuy(contact, cb, 'none', item),
                )}
                productGet={dispatch((productHash, userId, contactId, amSeller, cb) =>
                  productActions.productGet(productHash, userId, contactId, amSeller, cb),
                )}
                productsRef={productsRef}
                supplier={newSupplier}
                openShowroom={() => {
                  if (contact) {
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY,
                        supplierId: params.supplierId,
                        workspaceId: params.workspaceId,
                      }),
                    );
                  }
                }}
              />

              {newSupplier.teammates.length > 0 ? (
                <Colleagues
                  contacts={contacts}
                  supplier={newSupplier}
                  colleaguesRef={colleaguesRef}
                  navigateToChat={(contactId: number) => {
                    const channelToShow = Object.values(channels).find(
                      chan => chan.type === 'private' && chan.members.find(cm => cm.id === contactId),
                    );
                    if (channelToShow)
                      dispatch(
                        navActions.navigateChannelByPath(ROUTE_PATHS.CHAT, channelToShow.id, (path: string) =>
                          history.push(path),
                        ),
                      );
                  }}
                />
              ) : null}
              <Security
                amEditor={amEditor}
                disableForAll={disableForAll}
                securityRef={securityRef}
                supplier={newSupplier}
                deleteSupplier={deleteSupplier}
              />
            </>
          </FormContainer>
          <S.ColumnRight>{renderSupplierOrders()}</S.ColumnRight>
        </S.Container>
      </Workspace>
    </>
  );
  /**
   * Delete a supplier.
   */
  function deleteSupplier() {
    dispatch(
      modalActions.modalOpen(
        __('WorkspaceSupplierEdit.Menu.privacy.title'),
        async () => {
          try {
            await api.buyerWorkspace.supplierDelete(me.id, supplier.workspaceId, supplier.userId);
          } catch (err) {
            return null;
          }
          dispatch(modalActions.modalClose());
          history.goBack();
          dispatch(
            notificationsActions.notificationShow(
              {
                style: 'info',
                title: __('WorkspaceSupplierEdit.Menu.privacy.notification.title'),
                subtitle: __('WorkspaceSupplierEdit.Menu.privacy.notification.subtitle'),
                closable: true,
              },
              3000,
            ),
          );
        },
        {
          showCancelButton: true,
          buttonText: __('WorkspaceSupplierEdit.Menu.privacy.remove_button'),
          text2: __('WorkspaceSupplierEdit.Menu.privacy.modal_description', { supplier: supplier.name }),
          actionType: 'dangerous',
        },
        'nice',
      ),
    );
  }
  function renderSupplierOrders() {
    return (
      <FormSection id="supplier-orders" title={__('WorkspaceSupplierEdit.supplier_orders.title')}>
        <S.FormCol className="supplier-orders">
          <S.TextPlaceholder>{__('WorkspaceSupplierEdit.supplier_orders.text')}</S.TextPlaceholder>
          <S.OrderButton
            onClick={() => {
              if (contact) {
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY,
                    supplierId: params.supplierId,
                    workspaceId: params.workspaceId,
                  }),
                );
              }
            }}
          >
            {__('WorkspaceSupplierEdit.supplier_orders.new_order')}
          </S.OrderButton>
          <S.TextLink
            onClick={() =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES,
                  supplierId: params.supplierId,
                  workspaceId: params.workspaceId,
                }),
              )
            }
          >
            {__('WorkspaceSupplierEdit.supplier_orders.link')}
          </S.TextLink>
        </S.FormCol>
      </FormSection>
    );
  }
  /**
   * Change the local state of user with the changes in the form.
   */
  function updateSupplier(s: ISupplier) {
    setHasChanges(true);
    setNewSupplier(s);
  }

  /**
   * disable the posibility of buy for this client to any members.
   */
  function disableForAll(enabled: boolean) {
    dispatch(
      modalActions.modalOpen(
        newSupplier.blockedOrders
          ? __('Components.ContactDetails.unblock_client_shop.modal.title', newSupplier)
          : __('Components.ContactDetails.block_client_shop.modal.title', newSupplier),
        () => {
          updateSupplier({ ...newSupplier, blockedOrders: enabled });
          dispatch(modalActions.modalClose());
        },
        {
          subtitle: newSupplier.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.subtitle', { teamName: workspace.name })
            : __('Components.ContactDetails.block_client_shop.modal.subtitle', { teamName: workspace.name }),
          buttonText: newSupplier.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.button')
            : __('Components.ContactDetails.block_client_shop.modal.button'),
          icon: newSupplier.blockedOrders ? IMAGES.addUser : IMAGES.blockUser,
          text2: newSupplier.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.text', newSupplier)
            : __('Components.ContactDetails.block_client_shop.modal.text', newSupplier),
          showCancelButton: true,
        },
        'nice',
      ),
    );
  }
};

const WorkspaceShowroom: React.FC<{ history: any; location: any; contact: IContact }> = React.memo(
  ({ history, location, contact }) => {
    const match = useRouteMatch<{ workspaceId: string; supplierId: string }>();
    const { supplierId, workspaceId } = match.params;
    return (
      <Workspace
        parentSections={[
          {
            label: __('Components.Header.WorkspaceSuppliers'),
            action: () =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_SUPPLIERS,
                  workspaceId,
                }),
              ),
          },
          {
            label: contact?.name,
            action: () => history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT, workspaceId, supplierId })),
          },
        ]}
        subtitle={''}
        title={__('Components.OrdersList.modal.sale.title')}
        tabSelected="clients"
        workspaceId={Number(workspaceId)}
        isBuyerWorkspace={true}
      >
        <ShowroomBuy BackHeader={null} contact={contact} match={match} history={history} location={location} />
      </Workspace>
    );
  },
);

const Supplier = React.memo(
  ({
    supplier,
    supplierRef,
    myId,
    createNewContact,
    navigateToBuy,
    isContact,
  }: {
    supplier: ISupplier;
    supplierRef: React.RefObject<HTMLDivElement>;
    createNewContact: (supplier: ISupplier) => void;
    isContact: boolean;
    myId: number;
    navigateToBuy: (cId: number) => void;
  }) => {
    return (
      <FormSection id="supplier" title={__('WorkspaceSupplierEdit.Menu.supplier.title')} sectionRef={supplierRef}>
        <S.SupplierRow>
          <S.AvatarHeader
            text={supplier.company}
            size={70}
            img={supplier.logo}
            avatarColor={utils.getAvatarColor(supplier.name)}
          />
          <S.AvatarHeader
            text={supplier.name}
            size={70}
            img={supplier.avatar}
            avatarColor={utils.getAvatarColor(supplier.name)}
          />
          <S.SupplierNameColumn>
            {supplier.name ? <S.TextContactName>{supplier.name}</S.TextContactName> : null}
            <S.TextCompany> {supplier.company}</S.TextCompany>
            {isContact ? (
              <S.TextLink
                onClick={e => {
                  e.stopPropagation();
                  navigateToBuy(supplier.userId);
                }}
              >
                <S.ChatIcon name="Chat" /> {__('WorkspaceSupplierEdit.go_to_chat')}
              </S.TextLink>
            ) : supplier.userId !== myId ? (
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  createNewContact(supplier);
                }}
                iconName="Add-more"
                withoutPadding={true}
              >
                {__('SuppliersList.add_contact')}
              </Button>
            ) : null}
          </S.SupplierNameColumn>
        </S.SupplierRow>
      </FormSection>
    );
  },
);

const Contracts = React.memo(
  ({
    amEditor,
    contractsRef,
    supplierId,
    workspaceId,
    myId,
    history,
  }: {
    amEditor: boolean;
    myId: number;
    contractsRef: React.RefObject<HTMLDivElement>;
    workspaceId: number;
    supplierId: number;
    history: History;
  }) => {
    const [contracts, setContracts] = React.useState<Array<IContract>>([]);
    React.useEffect(() => {
      api.buyerWorkspace.contractsGet(myId, workspaceId, supplierId).then(setContracts);
    }, []);

    return (
      <FormSection id="contracts" title={__('WorkspaceSupplierEdit.Menu.contracts.title')} sectionRef={contractsRef}>
        <Table
          productColumns={getDefaultColumns()}
          className="supplier-contracts-table"
          columns={[
            {
              title: __('WorkspaceSupplierEdit.contracts.ref'),
              id: 'reference',
              value: (row: IContract) => row.reference,
            },
            {
              title: __('WorkspaceSupplierEdit.contracts.products'),
              id: 'products',
              element: (data: IContract) => {
                return (
                  <MiniImages
                    className="pricegroups-table-images"
                    images={data.productImages}
                    count={data.totalProducts}
                  />
                );
              },
            },
            {
              title: '',
              id: 'kebab',
              align: 'left',
              element: (data: IContract) => {
                return (
                  <SimpleDropdown
                    hAlign="right"
                    options={[
                      { key: 'edit', value: __('WorkspaceSupplierEdit.Menu.contracts.edit_contract') },
                      {
                        key: 'delete',
                        value: __('WorkspaceSupplierEdit.Menu.contracts.delete_contract'),
                        color: colors.red1,
                      },
                    ]}
                    onSelect={(key: string) => {
                      switch (key) {
                        case 'edit':
                          history.push(
                            getPath({
                              path: ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT,
                              workspaceId: workspaceId + '',
                              supplierId: supplierId + '',
                              contractId: data.id + '',
                            }),
                          );
                          break;
                        case 'delete':
                          api.buyerWorkspace.contractDelete(myId, workspaceId, supplierId, data.id).then(() => {
                            setContracts(contracts.filter(c => c.id !== data.id));
                          });
                          break;
                      }
                    }}
                  >
                    <S.KebabIconContainer>
                      <S.KebabIcon name="Kebab" />
                    </S.KebabIconContainer>
                  </SimpleDropdown>
                );
              },
            },
          ]}
          emptyText={__('WorkspaceSupplierEdit.Menu.contracts.zero_case')}
          onClickRow={c =>
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT,
                workspaceId: workspaceId + '',
                supplierId: supplierId + '',
                contractId: c.id,
              }),
            )
          }
          values={contracts}
          selectable={false}
        />

        {amEditor ? (
          <S.AddMoreCta
            iconName={'Add-more'}
            type="link"
            onClick={() =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT,
                  workspaceId: workspaceId + '',
                  supplierId: supplierId + '',
                  contractId: 'new',
                }),
              )
            }
            withoutPadding
          >
            {__('WorkspaceSupplierEdit.contracts.add_contract')}
          </S.AddMoreCta>
        ) : null}
      </FormSection>
    );
  },
);

const Purchases = React.memo(
  ({
    amEditor,
    purchasesRef,
    supplier,
    supplierUpdate,
  }: {
    amEditor: boolean;
    purchasesRef: React.RefObject<HTMLDivElement>;
    supplier: ISupplier;
    supplierUpdate: (s: ISupplier) => void;
  }) => {
    return (
      <FormSection id="accept" title={__('WorkspaceSupplierEdit.Menu.purchases.title')} sectionRef={purchasesRef}>
        <InputWithLabel
          isRequired={true}
          footerText={
            supplier?.orderAccept === 'automatic'
              ? __('WorkspaceSupplierEdit.order_accept_auto_text')
              : __('WorkspaceSupplierEdit.order_accept_manual_text')
          }
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <Select
            value={supplier?.orderAccept}
            placeholder={__('ContactInfo.order_accept_manual')}
            options={[
              { value: 'automatic', label: __('ContactInfo.order_accept_auto') },
              { value: 'manual', label: __('ContactInfo.order_accept_manual') },
            ]}
            onChange={(key: string, value: string | number, error?: string | undefined) => {
              supplierUpdate({ ...supplier, orderAccept: value as ORDER_ACCEPT_TYPE });
            }}
            width="350px"
            containerMargin="0"
            disabled={!amEditor}
            zeroCase={__('ContactInfo.order_accept_manual')}
          />
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Products = React.memo(
  ({
    contacts,
    history,
    me,
    navigateToShowroomBuy,
    openShowroom,
    productGet,
    productsRef,
    supplier,
  }: {
    contacts: { [id: number]: IContact };
    history: History<any>;
    me: IUser;
    navigateToShowroomBuy: (contact: IContact, cb: (path: string) => void, item?: IOrderItem) => void;
    openShowroom: () => void;
    productGet: (
      productHash: string,
      userId: number,
      contactId: number,
      amSeller?: boolean,
      cb?: (product?: IProduct) => void,
    ) => void;
    productsRef: React.RefObject<HTMLDivElement>;
    supplier: ISupplier;
  }) => {
    const { purchasedProducts } = supplier;
    return (
      <FormSection id="products" title={__('WorkspaceSupplierEdit.products_title')} sectionRef={productsRef}>
        {purchasedProducts.length ? (
          <RowList>
            {purchasedProducts.map((product, i) => {
              const genericProduct = parsers.productLightToGenericProduct(product);
              return (
                <S.ProductCardContainer key={product.productId + '_' + i}>
                  <TeamMatePurchase
                    contact={me.id === product.buyerId ? meAsContact(me) : contacts[product.buyerId]}
                    isLast={true}
                    kind="header"
                    lastPurchase={product.purchasedAt}
                  />
                  <S.ProductCard
                    cartUpdateItem={item => {
                      if (product.productHashId) {
                        productGet(product.productHashId, me.id, supplier.userId, false, prodResult => {
                          navigateToShowroomBuy(
                            contacts[supplier.userId],
                            path => history.push(path),
                            parsers.genericProductToOrderItem(
                              parsers.productToGenericProduct(prodResult),
                              prodResult.price,
                              1,
                              prodResult.baseMarginPercentage,
                              false,
                            ),
                          );
                        });
                      } else {
                        navigateToShowroomBuy(contacts[supplier.userId], path => history.push(path));
                      }
                    }}
                    className="supplier-product"
                    data={genericProduct}
                    navigate={() => null}
                    orderItemId={0}
                    priceMode="none"
                    showCTA={!!product.productHashId}
                    hideFeatured={true}
                    showShare={false}
                  />
                </S.ProductCardContainer>
              );
            })}
          </RowList>
        ) : (
          <>
            <S.Text>{__('WorkspaceSupplierEdit.no_products')}</S.Text>
            <S.SaleButton onClick={openShowroom}>{__('WorkspaceSupplierEdit.supplier_orders.new_order')}</S.SaleButton>
          </>
        )}
      </FormSection>
    );
  },
);

const Colleagues = React.memo(
  ({
    colleaguesRef,
    contacts,
    navigateToChat,
    supplier,
  }: {
    colleaguesRef: React.RefObject<HTMLDivElement>;
    contacts: { [id: number]: IContact };
    navigateToChat: (contactId: number) => void;
    supplier: ISupplier;
  }) => {
    const { teammates } = supplier;
    return (
      <FormSection id="colleagues" title={__('WorkspaceSupplierEdit.colleagues_title')} sectionRef={colleaguesRef}>
        {teammates.map((teamMate, i, arr) => (
          <TeamMatePurchase
            contact={contacts[teamMate.teammateId]}
            isLast={i === arr.length - 1}
            kind="normal"
            key={teamMate.teammateId}
            lastPurchase={teamMate.lastPurchase}
            navigateToChat={navigateToChat}
          />
        ))}
      </FormSection>
    );
  },
);

const Security = React.memo(
  ({
    amEditor,
    deleteSupplier,
    disableForAll,
    securityRef,
    supplier,
  }: {
    amEditor: boolean;
    deleteSupplier: () => void;
    disableForAll: (block: boolean) => void;
    securityRef: React.RefObject<HTMLDivElement>;
    supplier: ISupplier;
  }) => {
    return (
      <FormSection
        id="security"
        title={__('WorkspaceSupplierEdit.Menu.security.title')}
        numHeaders={amEditor ? 2 : 3}
        sectionRef={securityRef}
      >
        <S.SwitchContainer>
          <S.SwitchRow>
            <S.SwitchLabel disabled={!amEditor}>
              {amEditor ? __('WorkspaceSupplierEdit.for_team') : __('WorkspaceSupplierEdit.for_team_block')}
            </S.SwitchLabel>
            <Switch
              name="settings"
              isChecked={amEditor ? supplier.blockedOrders : !supplier.blockedOrders}
              onChange={(n, checked) => disableForAll(checked)}
              disabled={!amEditor}
            />
          </S.SwitchRow>
        </S.SwitchContainer>
        <S.TextPlaceholder>{__('WorkspaceSupplierEdit.Menu.security.subtitle')}</S.TextPlaceholder>
        <S.FormCol className="privacy">
          <S.TitleLabel>{__('WorkspaceSupplierEdit.Menu.privacy.title')}</S.TitleLabel>
          <S.TextPlaceholder>{__('WorkspaceSupplierEdit.Menu.privacy.subtitle')}</S.TextPlaceholder>
          <S.DeleteButton type="delete" iconSize="15px" iconName="Trash" onClick={deleteSupplier}>
            {__('WorkspaceSupplierEdit.Menu.privacy.remove_button')}
          </S.DeleteButton>
        </S.FormCol>
      </FormSection>
    );
  },
);

const TeamMatePurchase = ({
  contact,
  isLast,
  kind,
  lastPurchase,
  navigateToChat,
}: {
  contact: ILightContact;
  isLast: boolean;
  kind: 'normal' | 'header';
  lastPurchase: number;
  navigateToChat?: (contactId: number) => void;
}) => {
  if (!contact) return kind === 'normal' ? null : <S.TeamMateEmpty />;
  return (
    <S.TeamMateRow isLast={isLast} kind={kind}>
      <S.TeamMateAvatar
        avatarColor={contact.avatarColor}
        img={contact.avatar}
        size={32}
        text={contact.name}
        workingStatus={contact.workingStatus}
      />
      <S.TeamMateContent kind={kind}>
        <S.TextMateName>{contact.name}</S.TextMateName>
        {lastPurchase ? (
          <S.TextGrey2>
            {kind === 'normal'
              ? __('WorkspaceSupplierEdit.last_purchase', {
                  date: date.formatDate(lastPurchase, 'dd/MM/yyy'),
                  hour: date.formatDate(lastPurchase, 'HH:mm'),
                })
              : date.formatDate(lastPurchase, 'dd/MM/yyy') + ' · ' + date.formatDate(lastPurchase, 'HH:mm')}
          </S.TextGrey2>
        ) : null}
      </S.TeamMateContent>
      {kind === 'normal' && navigateToChat ? (
        <S.ChatLink type="link" withoutPadding={true} onClick={() => navigateToChat(contact.id)}>
          {__('ClientsList.go_to_chat')}
        </S.ChatLink>
      ) : null}
    </S.TeamMateRow>
  );
};

export default React.memo(WorkspaceSupplierEdit);
