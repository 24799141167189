import TextareaAutosize from 'react-textarea-autosize';

import styled, { ITheme } from '../../../styled-components';
import TextLabel from '../TextLabel';

interface IStyledProps {
  disabled?: boolean;
  iconUrl?: string;
  margin?: string;
  theme: ITheme;
  width?: string;
}

export const Container = styled.div`
  width: ${(p: IStyledProps) => p.width || '100%'};
  margin: ${(p: IStyledProps) => p.margin || '0'};
  position: relative;
`;

export const TextArea = styled(TextareaAutosize)`
  width: 100%;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  line-height: 20px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  pointer-events: ${(p: IStyledProps) => (p.disabled ? 'none' : 'all')};
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'auto')};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  border-radius: 4px;
  resize: none;
  display: block;

  &:focus-within {
    outline: none !important;
    border-color: ${(p: IStyledProps) => p.theme.colors.green1};
    box-shadow: 0 0 4px ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ReadOnly = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-family: inherit;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  flex: 1;
  background: inherit;
  position: relative;
  justify-content: flex-start;
`;

export const TextCount = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  background-color: transparent;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
