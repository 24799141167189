import { __, colors, i18n, IContact, IUser, RenderTrack, userActions, utils } from 'common-services';
import { History } from 'history';
import * as React from 'react';

import config from '../../../../bindings/config';
import { orderImportZeroAsset } from '../../../assets';
import theme from '../../../theme';
import * as S from './OrderMagicZero.styled';

interface IProps {
  contact: IContact;
  history?: History<any>;
  match: {
    path: string;
    params: { channelId?: string; workspaceId: string };
  };
  me: IUser;
  ordersMagicZeroHide: typeof userActions.ordersMagicZeroHide;
  requestProInfo: typeof userActions.requestProInfo;
  setParentState: (state: any) => void;
  t?: number;
}

const OrdersMagicZero: React.FC<IProps> = ({
  contact,
  me,
  requestProInfo,
  ordersMagicZeroHide,
  setParentState,
  t = Date.now(),
}) => {
  const videoRef = React.useRef(null);
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    RenderTrack.track('OrdersMagicZero', { renderTime: t, contact_id: contact?.id });
  }, [t, contact]);
  React.useEffect(() => {
    function videoInViewport() {
      const rect = videoRef?.current?.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    function onVisibilityChange() {
      let prevVisible = videoInViewport();
      if (prevVisible) videoRef?.current?.play();
      return () => {
        const visible = videoInViewport();
        if (prevVisible !== visible) {
          if (!prevVisible) videoRef?.current?.play();
          prevVisible = visible;
        }
      };
    }
    const handlePlay = onVisibilityChange();
    containerRef?.current?.addEventListener('scroll', handlePlay, false);
    window.addEventListener('resize', handlePlay, false);
    return function cleanup() {
      containerRef?.current?.removeEventListener('scroll', handlePlay, false);
      window.removeEventListener('resize', handlePlay, false);
    };
  }, [containerRef?.current]);
  const currentLang = i18n.default.currentLocale();
  return (
    <S.Container ref={containerRef}>
      <S.TextBlue>MAGIC ORDERS</S.TextBlue>
      <S.Section>
        <S.TextTitle>{formatTextColor(__('MagicOrders.Zero.1.title'), colors.green1)}</S.TextTitle>
        <S.Text>{__('MagicOrders.Zero.1.text')}</S.Text>
        <S.ImageWrapper background={orderImportZeroAsset.dashboards[currentLang]} />
        <S.TextInfo>{__('MagicOrders.Zero.1.info')}</S.TextInfo>
        <S.CTA type="principal" onClick={ordersMagicZeroHide}>
          {__('MagicOrders.Zero.1.cta')}
        </S.CTA>
      </S.Section>
      <S.Section>
        <S.TextTitle>{formatTextColor(__('MagicOrders.Zero.2.title'), colors.blue1)}</S.TextTitle>
        <S.ImageWrapper2 background={orderImportZeroAsset.search} />
        <S.Row>
          <S.ImageWrapper2Column
            background={
              orderImportZeroAsset.formats[
                ['es', 'ca'].includes(currentLang) ? 'es' : 'fr' === currentLang ? 'fr' : 'other'
              ]
            }
          />
          <S.Column>
            <S.Text>{__('MagicOrders.Zero.2.text')}</S.Text>
            <S.CTA type="other" onClick={ordersMagicZeroHide}>
              {__('MagicOrders.Zero.2.cta')}
            </S.CTA>
          </S.Column>
        </S.Row>
      </S.Section>
      <S.Section>
        <S.TextTitle>{formatTextColor(__('MagicOrders.Zero.3.title'), colors.orange1)}</S.TextTitle>
        <S.Row>
          <S.Column>
            <S.Text>{__('MagicOrders.Zero.3.text')}</S.Text>
            <S.CTA type="principal" onClick={ordersMagicZeroHide}>
              {__('MagicOrders.Zero.3.cta')}
            </S.CTA>
          </S.Column>
          <S.ImageWrapper3>
            <video ref={videoRef} muted width="250">
              <source
                src="https://media.consentio.co/video/upload/v1635429351/gifs/growth_plant.mp4"
                type="video/mp4"
              />
            </video>
          </S.ImageWrapper3>
        </S.Row>
      </S.Section>
      <S.Section>
        <S.TextTitlePadding>{__('MagicOrders.Zero.4.title')}</S.TextTitlePadding>
        <S.ExpandableSection>
          <S.ExpandableInfo
            title={__('MagicOrders.Zero.4.sections.1.title')}
            iconPosition="right"
            titleSize={theme.fontSize.H1}
            defaultExpanded={false}
            titleWithLeftPadding={false}
            contentWithLeftPadding={false}
          >
            <S.TextDescription>{__('MagicOrders.Zero.4.sections.1.text')}</S.TextDescription>
          </S.ExpandableInfo>
          <S.ExpandableInfo
            title={__('MagicOrders.Zero.4.sections.2.title')}
            iconPosition="right"
            titleSize={theme.fontSize.H1}
            defaultExpanded={false}
            titleWithLeftPadding={false}
            contentWithLeftPadding={false}
          >
            <S.TextDescription>{__('MagicOrders.Zero.4.sections.2.text')}</S.TextDescription>
          </S.ExpandableInfo>
          <S.ExpandableInfo
            title={__('MagicOrders.Zero.4.sections.3.title')}
            iconPosition="right"
            titleSize={theme.fontSize.H1}
            defaultExpanded={false}
            titleWithLeftPadding={false}
            contentWithLeftPadding={false}
          >
            <S.TextDescription>
              {formatTextColor(__('MagicOrders.Zero.4.sections.3.text'), colors.blue1, () => {
                ordersMagicZeroHide();
                setParentState({ tabSelected: 'new' });
              })}
            </S.TextDescription>
          </S.ExpandableInfo>
          <S.ExpandableInfo
            title={__('MagicOrders.Zero.4.sections.4.title')}
            iconPosition="right"
            titleSize={theme.fontSize.H1}
            defaultExpanded={false}
            titleWithLeftPadding={false}
            contentWithLeftPadding={false}
          >
            <S.TextDescription>
              {formatTextColor(__('MagicOrders.Zero.4.sections.4.text'), colors.blue1, () => {
                ordersMagicZeroHide();
                setParentState({ tabSelected: 'ask-format' });
              })}
            </S.TextDescription>
          </S.ExpandableInfo>
          {config.TOGGLE_MO_PRICES_INFO.enabled ? (
            <S.ExpandableInfo
              title={__('MagicOrders.Zero.4.sections.5.title')}
              iconPosition="right"
              titleSize={theme.fontSize.H1}
              defaultExpanded={false}
              titleWithLeftPadding={false}
              contentWithLeftPadding={false}
            >
              <S.TextDescription>
                {
                  utils.formatText(__('MagicOrders.Zero.4.sections.5.text'), (text: string) =>
                    text ? <S.TextDescriptionBold key={text}>{text}</S.TextDescriptionBold> : null,
                  ) as any // TYPEERROR
                }
              </S.TextDescription>
            </S.ExpandableInfo>
          ) : null}
        </S.ExpandableSection>
        <S.TextLight>
          {formatTextColor(__('MagicOrders.Zero.support'), colors.blue1, () =>
            requestProInfo(me.id, contact?.mySellerWorkspaceId || me.sellerWorkspaceId, 'magic-orders'),
          )}
        </S.TextLight>
      </S.Section>
    </S.Container>
  );
};

/**
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatTextColor(text: string, color: string, action?: () => void) {
  return text.split('**').map((s, index) =>
    index % 2 ? (
      <S.TextColor key={s + index} color={color} onClick={action} hasAction={!!action}>
        {s}
      </S.TextColor>
    ) : (
      s
    ),
  );
}

export default OrdersMagicZero;
