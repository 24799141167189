import styled, { ITheme } from '../../../styled-components';
import ActionsModal from '../ActionsModal';

interface IStyledProps {
  theme: ITheme;
}

export const Scroll = styled.div`
  max-height: calc(${(p: IStyledProps) => p.theme.windowHeight} / 2 - ${(p: IStyledProps) => p.theme.paddingSize()});
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: min-content;
`;

export const Modal = styled(ActionsModal)`
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: max-content;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  margin: 0;
  min-height: min-content;
`;
