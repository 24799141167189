import { __ } from 'common-services';
import * as React from 'react';

import { SimpleSearch } from '../../molecules';
import * as S from './MembersSearch.styled';

interface IProps {
  numMembers: number;
  totalContacts: number;
  setSearch: (f: string) => void;
  filterSelected: 'all' | 'added';
  setFilterSelected: (f: 'all' | 'added') => void;
  hideFilters?: boolean;
  id: string;
  hideParticipantsInfo?: boolean;
}

export default React.memo(
  ({
    numMembers,
    filterSelected,
    hideFilters,
    hideParticipantsInfo,
    id,
    setFilterSelected,
    setSearch,
    totalContacts,
  }: IProps) => {
    return (
      <S.SearchContainer>
        <S.Left>
          <SimpleSearch
            onChange={setSearch}
            placeHolder={__('Components.ChatList.search.placeholder')}
            id={'search-' + id}
          />
          {hideFilters ? null : (
            <S.Filters>
              <S.FilterItem
                id="contact-filter-added"
                onClick={() => setFilterSelected('added')}
                selected={filterSelected === 'added'}
              >
                {__('Components.Channel.added', { numItems: numMembers })}
              </S.FilterItem>
              <S.FilterItem
                id="contact-filter-all"
                onClick={() => setFilterSelected('all')}
                selected={filterSelected === 'all'}
              >
                {__('Components.Channel.all', { numItems: totalContacts })}
              </S.FilterItem>
            </S.Filters>
          )}
        </S.Left>
        {hideParticipantsInfo ? null : (
          <S.Right>
            <S.RightText>{__('Components.Channel.members_added')}</S.RightText>
            <S.RightText>{numMembers ? __('Constants.NumMembers', { count: numMembers }) : ''}</S.RightText>
          </S.Right>
        )}
      </S.SearchContainer>
    );
  },
);
