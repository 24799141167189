import { BUSINESS_TYPE, constants, EventTrack, IUser, RenderTrack } from 'common-services';
import * as React from 'react';

import { ColumnContainer } from '../../../../atoms';
import { IFontIconKeys } from '../../../../atoms/FontIcon';
import { RegisterStep } from '../../../../molecules';
import * as CS from '../../LoginRegisterFlow.styled';
import * as S from './Business.styled';

export interface IProps {
  cta: string;
  goBack: () => void;
  lang?: LOCALE;
  me: IUser;
  onNext: (businessType: BUSINESS_TYPE) => void;
  step: number;
  subtitle: string;
  title: string;
  totalSteps: number;
}

interface IState {
  businessType?: BUSINESS_TYPE;
}

class Business extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {};
  }

  public componentDidMount() {
    RenderTrack.track('OnboardSegmentationBusiness', { renderTime: this.t });
  }

  public render() {
    const { cta, goBack, lang, step, subtitle, title, totalSteps } = this.props;
    const { businessType } = this.state;
    return (
      <CS.Container fullWidth={true}>
        <RegisterStep goBack={goBack} currentStep={step} subtitle={subtitle} title={title} numberOfSteps={totalSteps}>
          <CS.Form
            id={`form-business-type`}
            onSubmit={e => {
              e?.preventDefault();
              this.handleNext(businessType);
            }}
          >
            <CS.Card id="register-business-type">
              <S.OptionsContainer>
                {constants.getBusinessTypes(lang).map((info, i) => (
                  <S.BusinessContainer
                    key={i}
                    selected={businessType === info.value}
                    id={info.id}
                    onClick={() => this.setState({ businessType: info.value })}
                  >
                    <S.IconContainer>
                      <S.BusinessIcon disableHover={true} name={info.icon as IFontIconKeys} businessType={info.value} />
                    </S.IconContainer>
                    <ColumnContainer>
                      <S.TitleText selected={businessType === info.value}>{info.title}</S.TitleText>
                      {info.subtitle ? (
                        <S.SubtitleText selected={businessType === info.value}>{info.subtitle}</S.SubtitleText>
                      ) : null}
                    </ColumnContainer>
                  </S.BusinessContainer>
                ))}
              </S.OptionsContainer>
              <CS.SubmitButton id="business-type-submit-button" disabled={!businessType} type="principal">
                {cta}
              </CS.SubmitButton>
            </CS.Card>
          </CS.Form>
        </RegisterStep>
      </CS.Container>
    );
  }

  /**
   * Handle click to the next screen
   */
  private handleNext(businessType?: BUSINESS_TYPE) {
    if (!businessType) return;
    const { onNext } = this.props;
    EventTrack.formSubmit({
      formName: 'onboard-segmentation-business-type',
      fields: { businessType },
      errors: '',
      result: 'success',
    });
    onNext(businessType);
  }
}

export default Business;
