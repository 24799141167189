import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';
import { FilterButton as FB } from '../../molecules';

interface IStyledProps {
  color?: string;
  isLast?: boolean;
  kind?: 'normal' | 'header';
  margin?: string;
  disabled?: boolean;
  showColor?: boolean;
  theme: ITheme;
  numHeaders?: number;
  isDragActive?: boolean;
  clickable?: boolean;
  overlay?: boolean;
}

export const Container = styled(RowContainer)`
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;

const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
`;

export const FileDropzone = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
`;

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: 1px;
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  background-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.light2 : p.theme.colors.white)};
  width: 100%;
  height: auto;
  min-height: min-content;
  &:focus {
    outline: none;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 450px;
  }
`;

export const UploadFileIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextUpload = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextUploadGreen = styled(TextUpload)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const TextCenter = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const SelectButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 45%;
  border-color: ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const Row = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Column = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
`;

export const TextBlack = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px 0;
`;

const TextGrey = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const SearchWrapper = styled(RowContainer)`
  min-height: 54px;
  overflow: visible;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: 0;
  width: 100%;
  min-width: 400px;
  align-items: center;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 100%;
  }
`;

export const FilterButton = styled(FB)`
  height: 40px;
`;

export const RightContainer = styled(RowContainer)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const Sort = styled(SimpleDropdown)``;

export const SortBlackText = styled(TextBlack)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const SortGreyText = styled(TextGrey)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
