import { ColumnContainer, RowContainer } from './components/atoms';
import styled, { ITheme } from './styled-components';

interface IStyledProps {
  hasBreadcrumbs?: boolean;
  showHeader?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  width: 100%;
`;

export const Body = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  width: 100%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 100vw;
    flex-direction: column;
  }
`;

/**
 * Get content height
 */
const getPageContentHeight = (p: IStyledProps) => {
  if (p.showHeader) return p.hasBreadcrumbs ? p.theme.contentHeightWithBreadcrumb : p.theme.contentHeight;
  return p.theme.windowHeight;
};

export const ColumnRight = styled(ColumnContainer)<IStyledProps>`
  flex: 1;
  align-items: center;
  height: ${getPageContentHeight};
  overflow: hidden;
  position: relative;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: none;
  }
`;

export const Content = styled(Body)`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  flex: 1;
`;
