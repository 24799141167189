import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  overlay?: boolean;
  selectable?: boolean;
  checked?: boolean;
  theme: ITheme;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const Table = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
`;

export const PriceGroupTr = styled.tr`
  height: 55px;
  max-height: 55px;
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  cursor: pointer;
  overflow: hidden;
  box-shadow: none;
  border: none;

  & .read {
    display: none;
  }
  &:hover {
    & .read {
      display: block;
      float: right;
    }
  }
  > td {
    max-height: 55px;
  }
`;

// Generic styles for each column (header + rows)
export const FillCol = styled.td`
  min-width: 22px;
  width: 30px;
  max-width: 30px;
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  vertical-align: middle;

  ${(p: IStyledProps) =>
    p.selectable &&
    css`
      width: 65px;
      max-width: 65px;
      padding-left: ${p.theme.paddingObject};
    `};
`;

const Col = styled.td`
  min-width: 70px;
  max-width: 170px;
  vertical-align: middle;
  padding: 5px 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const StatusCol = styled(Col)`
  width: 70px;
  max-width: 80px;
  border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const ProductsCol = styled(Col)`
  width: 80px;
  max-width: 120px;
`;

export const ClientsCol = styled(ProductsCol)``;

export const TransportCostCol = styled(ProductsCol)``;

export const NameCol = styled(Col)`
  width: 150px;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextName = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 40px;
`;

export const TextSlim = styled(TextBlack)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const RadioCheckmark = styled.span`
  background-color: ${(p: IStyledProps) => (p.checked ? p.theme.colors.green1 : p.theme.colors.white)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => (p.checked ? 'none' : p.theme.colors.grey2)};
  width: 18px;
  height: 18px;
  padding: 0 2px;
  position: absolute;
  top: 11px;
  left: 10px;
`;

export const RadioLabel = styled.label`
  display: block;
  position: relative;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  input:checked + span {
    background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  }
  align-self: center;
  height: 100%;
  input:checked + span:after {
    display: block;
  }
`;

export const SearchContainer = styled(RowContainer)`
  display: flex;
  justify-content: space-between;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
