import { colors } from 'common-services';
import * as styledComponents from 'styled-components';

export interface IFontSize {
  tiny: string;
  big: string;
  normal: string;
  small: string;
  H1: string;
  H2: string;
  H3: string;
}

export interface IFontWeight {
  Bold: string;
  Light: string;
  Regular: string;
  Semi: string;
}

export interface ITheme {
  borderRadius: string;
  boxShadow: string;
  chatListWidth: any;
  colors: typeof colors;
  contentHeight: string;
  contentHeightWithBreadcrumb: string;
  fontSize: IFontSize;
  fontWeight: IFontWeight;
  headerHeight: string;
  inputHeight: string;
  landingMinHeight: string;
  /** @deprecated */
  paddingObject: string;
  paddingSize: (multiplier?: number) => string;
  /** @deprecated */
  paddingView: string;
  redBoxShadow: string;
  selectedBoxShadow: string;
  windowHeight: string;
  sizes: {
    ipad: string;
    mobile: string;
    desktop_m: string;
    desktop_ml: string;
    desktop_l: string;
  };
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<ITheme>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
