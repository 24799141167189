import { Action, NotificationTypes } from '../actions/notification';

export interface IReduxStateNotification {
  badgeCount?: number;
  showHeader: boolean;
  internalNotifications: Record<number, { alert: number; informative: number }>;
}
export const NOTIFICATION_INITIAL_STATE: IReduxStateNotification = {
  badgeCount: 0,
  showHeader: false,
  internalNotifications: {},
};

export default function notificationReducer(
  state = NOTIFICATION_INITIAL_STATE,
  action: Action | IResetAction,
): IReduxStateNotification {
  switch (action.type) {
    case NotificationTypes.NOTIFICATION_BADGE_SET: {
      return { ...state, badgeCount: action.value };
    }
    case NotificationTypes.NOTIFICATION_SHOW_HEADER: {
      return { ...state, showHeader: action.value };
    }
    case NotificationTypes.NOTIFICATION_INTERNAL_SET: {
      return { ...state, internalNotifications: action.notificationCounters };
    }
    case 'RESET_STATE': {
      return NOTIFICATION_INITIAL_STATE;
    }
    default:
      return state;
  }
}
