import { __, contactActions, EventTrack, IUser, userActions } from 'common-services';
import { AsYouType, CountryCode } from 'libphonenumber-js/mobile';
import * as React from 'react';

import { navAuthAction, navResetAuthAction } from '../../../../../actions/nav';
import * as appActionsUser from '../../../../../actions/user';
import { ILoginData } from '../../../../../constants';
import { RegisterStep } from '../../../../molecules';
import * as CS from '../../LoginRegisterFlow.styled';
import * as S from './VerifyCode.styled';

interface IProps {
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  addPhone?: typeof userActions.addPhone;
  className?: string;
  code: string;
  email?: string;
  fromRegister?: boolean;
  goBack: () => void;
  goToNextStep: (onError?: (error: string) => void) => void;
  lang?: LOCALE;
  last4DigitsPhone?: string;
  loading: boolean;
  loginData?: ILoginData;
  me: IUser;
  mode: 'phone' | 'email';
  navAuthAction: typeof navAuthAction;
  navResetAuthAction: typeof navResetAuthAction;
  recoverPassword: boolean;
  resendCode: (phone: string, countryPrefix: number, via: string) => void;
  setCode: (code: string) => void;
  shouldAddPhone?: boolean;
  showBackButton?: boolean;
  step: number;
  totalSteps?: number;
  trackRender?: (t: number) => void;
  twoFARequired?: boolean;
  verifyEmailCode: typeof appActionsUser.verifyEmailCode;
  verifyPhoneCode: typeof appActionsUser.verifyPhoneCode;
}

interface IState {
  errors: Map<string, string>;
  textError: string;
}

/**
 * Verify code (phone or email)
 */
export default class VerifyCode extends React.PureComponent<IProps, IState> {
  private constructedAt: number;

  constructor(props) {
    super(props);
    this.constructedAt = Date.now();
    this.state = {
      errors: new Map(),
      textError: '',
    };
  }

  public componentDidMount() {
    const { trackRender } = this.props;
    if (trackRender) trackRender(this.constructedAt);
  }

  public render() {
    const { className, fromRegister, step, totalSteps } = this.props;

    return (
      <CS.Container className={className} fullWidth={true}>
        <RegisterStep
          className={className}
          currentStep={fromRegister ? step : undefined}
          goBack={this.goBack}
          numberOfSteps={totalSteps}
          title={this.getTitle()}
          subtitle={this.getSubtitle()}
        >
          <CS.Form
            id="verify_code"
            onSubmit={e => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            <CS.Card id="onboarding-verify-code">{this.renderBody()}</CS.Card>
          </CS.Form>
        </RegisterStep>
      </CS.Container>
    );
  }

  private renderBody() {
    const { code, lang, loading, setCode } = this.props;
    const { errors, textError } = this.state;
    return (
      <>
        <S.CodeInputContainer>
          <S.CodeInput
            name="code"
            placeholder="000000"
            value={code}
            maxLength={6}
            onChange={event => {
              const value = event.target.value;
              this.setError('code', value ? '' : 'empty');
              setCode(value ? value.toString() : '');
            }}
            onBlur={event => {
              const value = event.target.value;
              this.setError('code', value ? '' : 'empty');
              if (value && value.toString().length !== 6) {
                this.setError('code', 'validation');
              }
            }}
            autoFocus={true}
            autoComplete="off"
          />
        </S.CodeInputContainer>

        {textError ? (
          <CS.TextErrorContainer>
            <CS.TextError>{textError}</CS.TextError>
          </CS.TextErrorContainer>
        ) : null}
        {this.renderCodeNotReceived()}
        <S.SubmitButton
          id="verify-code-submit-button"
          disabled={loading || errors.size > 0 || !code || code.length < 6}
          loading={loading}
          type="principal"
        >
          {__('Components.Onboarding.VerifyCode.cta', { locale: lang })}
        </S.SubmitButton>
      </>
    );
  }

  /*
   * Get title for register or reset password
   */
  private getTitle() {
    const { fromRegister, lang, mode, recoverPassword } = this.props;

    if (fromRegister) {
      return __('LoginRegisterFlow.Verify.title', { locale: lang });
    }
    if (recoverPassword) {
      return mode === 'phone'
        ? __('Components.Onboarding.VerifyCode.title_recover_phone', { locale: lang })
        : __('Components.Onboarding.VerifyCode.title_recover_email', { locale: lang });
    } else {
      return __('Components.Onboarding.VerifyCode.title', { locale: lang });
    }
  }

  /**
   * Get subtitle for register or reset password
   */
  private getSubtitle() {
    const { email, fromRegister, lang, last4DigitsPhone, loginData, me, mode, recoverPassword, twoFARequired } =
      this.props;
    const phoneNumber = new AsYouType(loginData.countryCode as CountryCode).input(
      '+' + loginData.countryPrefix + loginData.phoneNumber.replace(/\D/g, ''),
    );
    if (fromRegister) {
      return mode === 'phone'
        ? __('LoginRegisterFlow.Verify.subtitle_phone', { phoneNumber, locale: lang })
        : __('LoginRegisterFlow.Verify.subtitle_email', { email, locale: lang });
    }
    if (recoverPassword) {
      return mode === 'phone'
        ? __('Components.Onboarding.VerifyCode.description_recover_phone', {
            phoneNumber,
            locale: lang,
          })
        : __('Components.Onboarding.VerifyCode.description_recover_email', { email, locale: lang });
    } else if (twoFARequired) {
      return me.phone
        ? __('Components.Onboarding.VerifyCode.description_phone_2fa_verified', {
            phoneNumber: last4DigitsPhone || phoneNumber,
            locale: lang,
          })
        : __('Components.Onboarding.VerifyCode.description_phone_2fa', {
            phoneNumber: last4DigitsPhone || phoneNumber,
            locale: lang,
          });
    } else {
      return mode === 'phone'
        ? __('Components.Onboarding.VerifyCode.description_phone', { phoneNumber, locale: lang })
        : __('Components.Onboarding.VerifyCode.description_email', { email, locale: lang });
    }
  }

  /**
   * Render no receive code description + links
   */
  private renderCodeNotReceived() {
    const { lang, mode } = this.props;
    return (
      <S.CodeNotReceivedContainer>
        <CS.TextRegular textAlign="center">
          {__('Components.Onboarding.VerifyCode.no_receive_code', { locale: lang })}
          <CS.TextLink onClick={() => this.resendCode(mode === 'phone' ? 'sms' : 'email')}>
            {mode === 'phone'
              ? __('Components.Onboarding.VerifyCode.resend_sms', { locale: lang })
              : __('Components.Onboarding.VerifyCode.resend_email', { locale: lang })}
          </CS.TextLink>
        </CS.TextRegular>
      </S.CodeNotReceivedContainer>
    );
  }

  /**
   * hold logic for asking for a new phone verification
   * it does event tracking + third party request
   */
  private resendCode = (type: 'sms' | 'email') => {
    const { email, lang, loginData, mode, resendCode, verifyEmailCode } = this.props;
    if (mode === 'phone') {
      const finalNumber =
        loginData.phoneParsed && loginData.phoneParsed.number.replace(`+${loginData.countryPrefix}`, '');
      EventTrack.click({
        name: 'resend-phone-verification',
        data: { type, phone: `${loginData.countryPrefix}${finalNumber}` },
      });
      resendCode(finalNumber, Number(loginData.countryPrefix!), type);
    } else {
      verifyEmailCode?.({ email }, (result: 'success' | 'error') => {
        if (result === 'error')
          this.setState({ textError: __('Components.Onboarding.error_generic', { locale: lang }) });
      });
    }
  };

  /**
   * On back click. Go back to the previous step according to authStep.
   */
  private goBack = () => {
    const { goBack, setCode } = this.props;
    goBack();
    setCode('');
  };

  /**
   * Set error for a particular field represented by key
   */
  private setError(key: string, error: string) {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  }

  /**
   * Validate form (code field not empty and length > 4)
   */
  private validateForm = () => {
    const { code } = this.props;
    const errorsResult = new Map();

    if (!code) {
      errorsResult.set('code', 'empty');
    } else if (code.length < 4) {
      errorsResult.set('code', 'validation');
    }

    return errorsResult;
  };

  /**
   * Validate fields and submit verify code
   */
  private onSubmit = () => {
    const {
      code,
      email,
      goToNextStep,
      lang,
      loginData,
      me,
      mode,
      twoFARequired,
      verifyEmailCode,
      verifyPhoneCode,
      shouldAddPhone,
    } = this.props;
    const validationErrors = this.validateForm();
    this.setState({ errors: validationErrors, textError: '' });

    if (validationErrors.size === 0) {
      if (shouldAddPhone) return this.addUserPhone();
      if (twoFARequired) return goToNextStep(this.onError);

      if (mode === 'phone') {
        const finalNumber =
          loginData.phoneParsed && loginData.phoneParsed.number.replace(`+${loginData.countryPrefix}`, '');
        verifyPhoneCode(
          finalNumber || me.phone + '',
          Number(loginData.countryPrefix) || me.country!,
          code,
          (result: 'success' | 'invalid' | 'error') => {
            if (result === 'success') goToNextStep(this.onError);
            else this.setState({ textError: __('Components.Onboarding.VerifyCode.validation', { locale: lang }) });
          },
        );
      } else if (email) {
        verifyEmailCode({ email, code, keep: true }, (result: 'success' | 'error') => {
          if (result === 'success') goToNextStep(this.onError);
          else this.setState({ textError: __('Components.Onboarding.VerifyCode.validation', { locale: lang }) });
        });
      }
    }
  };

  /**
   * Add a user phone
   */
  private addUserPhone() {
    const { addPhone, code, lang, goToNextStep, loginData, me } = this.props;
    addPhone(me.id, loginData.phoneNumber, loginData.countryPrefix, code, loginData.password, ({ err }) => {
      if (err) return this.setState({ textError: __('Components.Onboarding.VerifyCode.validation', { locale: lang }) });
      goToNextStep(this.onError);
    });
  }

  /**
   * Callback to set an error
   */
  private onError = (error: string) => this.setState({ textError: error });
}
