import { __, CHANNEL_TYPE, IAvatarColor } from 'common-services';
import * as React from 'react';

import * as S from './ChannelModal.styled';

export interface IProps {
  avatar?: string;
  avatarColor?: IAvatarColor;
  children: React.ReactNode;
  className?: string;
  contentGrow?: boolean;
  minHeight?: string;
  minimized: boolean;
  onClose?: () => void;
  onMinimize: () => void;
  startMinimized?: boolean;
  style?: React.CSSProperties;
  subtitle?: string;
  title: string;
  type?: CHANNEL_TYPE;
  unreadMessageCount?: number;
  width?: string;
}

const ChannelModal: React.FC<IProps> = ({
  avatar,
  avatarColor,
  children,
  className,
  contentGrow = true,
  minHeight,
  minimized,
  onClose,
  onMinimize,
  startMinimized = false,
  style,
  subtitle,
  unreadMessageCount,
  title,
  type,
  width,
}) => {
  return (
    <S.Modal className={className} style={style}>
      <S.CardContainer className={className} minHeight={minHeight} minimized={minimized} width={width}>
        <S.Header onClick={() => (startMinimized ? onMinimize() : null)} startMinimized={startMinimized}>
          <S.TitleColumn>
            <S.Avatar text={title} size={35} img={avatar} avatarColor={avatarColor} type={type} />
            <S.Title>{title || __('Components.Channel.title')}</S.Title>
            <S.SubTitle>{subtitle || title ? '' : __('Components.Channel.new')}</S.SubTitle>
            {unreadMessageCount ? (
              <S.UnreadBadge className="unreads-badge" count={unreadMessageCount || 0} size={20} color="red" />
            ) : null}
          </S.TitleColumn>
          <S.MinimizeIcon
            name="Line"
            onClick={onMinimize}
            id="channel-modal-close-icon"
            hasCloseIcon={onClose && !onMinimize && !startMinimized}
          />
          {onClose && !onMinimize && !startMinimized ? (
            <S.CloseIcon name="Close" onClick={onClose} id="channel-modal-close-icon" />
          ) : null}
        </S.Header>
        {minimized ? null : <S.Wrapper contentGrow={contentGrow}>{children}</S.Wrapper>}
      </S.CardContainer>
    </S.Modal>
  );
};

export default React.memo(ChannelModal);
