import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, LettersAvatar, RowContainer, TextLabel } from '../../atoms';
import { ActionsModal as AM, ProductCard as PC } from '../../molecules';

interface IStyledProps {
  color?: string;
  isLast?: boolean;
  kind?: 'normal' | 'header';
  margin?: string;
  disabled?: boolean;
  showColor?: boolean;
  theme: ITheme;
  numHeaders?: number;
  isDragActive?: boolean;
}

export const Container = styled(RowContainer)`
  flex: 1;
  overflow: hidden;
`;

export const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
`;

export const TextGrey2 = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const SupplierRow = styled(RowContainer)`
  max-width: max-content;
`;

export const SupplierNameColumn = styled(ColumnContainer)`
  min-width: 85px;
  height: 100%;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const AvatarHeader = styled(LettersAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextContactName = styled(TextLabel)`
  width: auto;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextMateName = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  white-space: nowrap;
`;

export const TextCompany = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const SwitchContainer = styled(ColumnContainer)`
  max-width: max-content;
`;

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const SwitchLabel = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(p.disabled ? 1 : 8)};
`;

export const TeamMateEmpty = styled(RowContainer)`
  width: 100%;
  height: 35px;
`;

export const TeamMateRow = styled(RowContainer)<IStyledProps>`
  width: ${(p: IStyledProps) => (p.kind === 'normal' ? '400px' : '100%')};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 0 : 3.5)};

  ${(p: IStyledProps) =>
    p.kind === 'header' &&
    css`
      max-width: 180px;
    `}
`;

export const TeamMateAvatar = styled(LettersAvatar)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  margin-bottom: 0;
`;

export const TeamMateContent = styled(ColumnContainer)`
  flex: 1;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(p.kind === 'normal' ? 8 : 2)};
  overflow: hidden;
`;

export const ChatLink = styled(Button)`
  padding: 0;
  height: 20px;
`;

export const ProductCardContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ProductCard = styled(PC)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 100%;
`;
export const ColumnRight = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  top: 0;
  right: 0;
  max-width: 290px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 35%;
    max-height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight} * 2 )`};
  }
`;
export const FormCol = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0)};
`;

export const TextPlaceholder = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  text-align: left;
  padding: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  position: relative;
  top: -6px;
`;

export const TextLink = styled(TextLabel)`
  display: inline-flex;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const ChatIcon = styled(FontIcon)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;
export const OrderButton = styled(Button)`
  width: 100%;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const SaleButton = styled(Button)`
  width: 200px;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TitleLabel = styled.span`
  display: block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const DeleteButton = styled(Button)`
  align-self: left;
`;

export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: auto;
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const KebabIconContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: end;
  justify-content: center;
`;
