import { connect } from 'react-redux';

import WorkspaceOrders, { IRouteProps, IStateProps } from '../components/organisms/WorkspaceOrders';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: IRouteProps): IStateProps {
  const {
    catalog: { catalogs },
    order: { orders, searchId, totalResults, workspaceUnreads },
    user: { user },
    workspace: { suppliers, workspaces },
  } = state;

  const orderFiltersState = state.order.filters || emptyObject;
  return {
    asSeller: false,
    catalogs: catalogs || emptyObject,
    contacts: state.contact.inConsentio,
    filters: orderFiltersState[0] || emptyObject,
    me: user!,
    orders,
    orderUnreads: workspaceUnreads[props.match.params?.workspaceId],
    searchId,
    suppliers,
    totalResults,
    type: 'purchase',
    workspaces,
  };
}

export default connect(mapStateToProps)(WorkspaceOrders);
