import {
  __,
  constants,
  debounce,
  EventTrack,
  LOCALE,
  modalActions,
  parsers,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  throttle,
} from 'common-services';
import { History, Location } from 'history';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { getListModeOptions, NO_TAG, ShowroomPage } from '../../../../../constants';
import { api } from '../../../../../store';
import { sizes } from '../../../../../theme';
import {
  BackButton,
  FilterSwitchIcon,
  Ribbon,
  RowContainer,
  SimpleDropdown,
  VisibilityPricesRibbon,
} from '../../../../atoms';
import { NonResults, ProductCard, ProductTable, ProductFilter } from '../../../../molecules';
import * as SR from '../../Showroom.styled';
import * as S from './Products.styled';

interface IProps {
  AddressHeader?: React.ReactNode;
  amSeller: boolean;
  boxTypes?: Array<ICustomBoxType>;
  cart: Array<IOrderItem>;
  cartUpdateItem: (item: IOrderItem) => void;
  catalog: IWorkspace;
  client?: IClient;
  clientsAdd?: typeof sellerWorkspaceActions.clientsAdd;
  contact?: IContact;
  ExcelDropdown?: React.ReactNode;
  featured: { [key: string]: Array<string> };
  featuredToggle: (type: IFeaturedType, productHashId: string) => void;
  from: 'pricelist' | 'showroom';
  hideFeatured: boolean;
  history?: History<any>;
  isServedFlow?: boolean;
  lang: LOCALE;
  location?: Location<any>;
  me: IUser;
  myId: number;
  notificationShow?: (notif: INotification, millis: number) => void;
  onNavigate: (page: ShowroomPage, key?: string, query?: string, product?: IProduct) => void;
  onShareSearch?: (product?: GenericProduct, productName?: string) => void;
  palletTypes?: Array<ICustomPalletType>;
  priceGroupIds?: Array<string>;
  priceMode?: IPriceMode;
  prices: { [key: number]: IPrice };
  query: string;
  section: string;
  showBack?: boolean;
  showFeatured?: IFeaturedType;
  toHide?: boolean;
  touchImage: typeof modalActions.touchImage;
}

interface IState {
  facets: IFacets;
  facetsGlobal: IFacets;
  hasMore?: boolean;
  hideFilters?: boolean;
  listMode: 'card' | 'list';
  products: Array<IProduct>;
  searchId?: string;
  searchState: ISearch;
  totalResults: number;
}

/**
 * Products in the showroom or public shop
 */
class Products extends React.PureComponent<IProps, IState> {
  private t: number;
  /**
   * Send products' search
   */
  private sendProductsSearch = debounce(() => {
    const { searchState } = this.state;
    const { me } = this.props;
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
      .then(this.onReceiveProducts);
  }, 200);

  /**
   * Get more porducts on scroll
   */
  private getMore = throttle(() => {
    const { searchId } = this.state;
    productService.productScrollSearch(searchId, api).then(this.onReceiveProducts);
  }, 200);

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { catalog, from, priceGroupIds, me, lang, query, section } = props;

    const defaultListMode =
      typeof window !== 'undefined' &&
      ['card', 'list'].includes(window.localStorage.getItem('products_showroom_display'))
        ? (window.localStorage.getItem('products_showroom_display') as 'card' | 'list')
        : 'card';

    this.state = {
      listMode: defaultListMode,
      totalResults: 0,
      products: [],
      facets: {},
      facetsGlobal: {},
      searchState: {
        text: query || '',
        featured: this.getFeatureds(),
        index: catalog?.hashId || '',
        rate: priceGroupIds,
        tag: section,
        language: lang || (me.settings.language as LOCALE) || LOCALE.EN,
        status: ['active', 'unavailable'],
      },
      hideFilters: typeof window !== 'undefined' && (window.innerWidth < sizes.desktop_m || from === 'showroom'),
    };
  }

  public componentDidMount() {
    const { from, me } = this.props;
    RenderTrack.track(from === 'pricelist' ? 'PublicShopProducts' : 'ShowroomProducts', { renderTime: this.t });
    const { searchState } = this.state;
    if (searchState.index) this.sendProductsSearch(searchState, me, this.onReceiveProducts);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalog, lang, query, section, priceGroupIds, me, prices, showFeatured } = this.props;
    const { searchState } = this.state;
    if (
      prevProps.query !== query ||
      prevProps.lang !== lang ||
      prevProps.section !== section ||
      prevProps.showFeatured !== showFeatured
    ) {
      this.setState({
        searchState: {
          ...searchState,
          featured: this.getFeatureds(),
          text: (query as string) || '',
          language: (lang as LOCALE) || searchState.language,
          tag: (section as string) || '',
        },
      });
    }
    if (searchState !== prevState.searchState || (priceGroupIds !== prevProps.priceGroupIds && searchState.index)) {
      this.sendProductsSearch(searchState, me, this.onReceiveProducts);
    }
    // Control catalog or price groups update to fill the elastic search state
    if (
      (catalog && prevProps.catalog?.id !== catalog.id && catalog.hashId) ||
      priceGroupIds !== prevProps.priceGroupIds
    ) {
      this.setState({ searchState: { ...searchState, rate: priceGroupIds, index: catalog?.hashId || '' } });
    }
    if (prevProps.prices !== prices && Object.keys(prices || {}).length) {
      this.updatePricesInProducts();
    }
  }

  public render() {
    const {
      AddressHeader,
      amSeller,
      boxTypes,
      cart,
      cartUpdateItem,
      catalog,
      client,
      clientsAdd,
      contact,
      ExcelDropdown,
      featured,
      featuredToggle,
      from,
      hideFeatured,
      isServedFlow,
      me,
      notificationShow,
      onNavigate,
      palletTypes,
      priceMode,
      showBack,
      toHide,
      touchImage,
    } = this.props;
    if (toHide) return null;
    const { facets, facetsGlobal, searchState, products, hideFilters, listMode } = this.state;

    const sortingOrder =
      searchState.sortOrder === 'asc'
        ? searchState.sort === 'relevance'
          ? __('Components.ProductsList.Sort.relevance')
          : __('Components.ProductsList.Sort.asc')
        : searchState.sortOrder === 'desc'
        ? __('Components.ProductsList.Sort.desc')
        : __('Components.ProductsList.Sort.asc');

    return (
      <S.RowBody className="products-body" from={from}>
        <ProductFilter
          clearFilters={() => {
            EventTrack.track(from === 'pricelist' ? 'public_products_filter_clear' : 'showroom_products_filter_clear', {
              workspace_id: catalog.id,
            });
            this.setState({
              searchState: {
                text: searchState.text,
                language: searchState.language,
                index: searchState.index,
                rate: searchState.rate,
                tag: searchState.tag,
                featured: searchState.featured,
                productsExcludedIds: searchState.productsExcludedIds,
                status: searchState.status,
              },
            });
          }}
          changeSearchState={s => this.setState({ searchState: s })}
          facets={facets}
          facetsGlobal={facetsGlobal}
          me={me}
          onHide={hide => this.setState({ hideFilters: hide })}
          hideStatus={true}
          searchState={searchState}
          showClosed={hideFilters}
          boxTypes={boxTypes}
          palletTypes={palletTypes}
        />
        <S.BodyWrapper>
          {AddressHeader || null}
          {!client && amSeller && from !== 'pricelist' ? (
            <Ribbon
              actionText={__('ContactInfo.Menu.workspace.no_client_add')}
              iconName="Error"
              onClickAction={() =>
                contact &&
                clientsAdd(
                  me.id,
                  contact.mySellerWorkspaceId,
                  [sellerWorkspaceService.contactToClient(contact, contact.mySellerWorkspaceId)],
                  () => {
                    notificationShow(
                      {
                        title: __('ContactInfo.Menu.workspace.client_add_title'),
                        subtitle: __('ContactInfo.Menu.workspace.client_add_text'),
                        closable: true,
                        style: 'info',
                      },
                      3000,
                    );
                  },
                )
              }
              text={__('ContactInfo.Menu.workspace.no_client', {
                workspace: catalog?.name,
              })}
              type="info"
            />
          ) : null}
          {amSeller ? (
            <VisibilityPricesRibbon
              client={client}
              myId={me.id}
              catalogId={catalog?.id}
              priceMode={client?.priceMode}
              myRole={sellerWorkspaceService.getRole(catalog, me.id)}
            />
          ) : null}
          <S.Body onScroll={this.onScroll} from={from} className="products-container">
            <SR.HeaderRow>
              {showBack ? (
                <BackButton onClick={() => onNavigate('home')} text={__('PublicShowroom.back_text')} />
              ) : (
                <div />
              )}
              <RowContainer>
                <S.ButtonGroup>
                  <S.FilterButton
                    filtersOpened={!hideFilters}
                    filtersSelected={this.getFiltersCount()}
                    onClick={() => this.setState({ hideFilters: !hideFilters })}
                  />
                  <SimpleDropdown
                    options={[
                      { key: 'relevance asc', value: __('Components.ProductsList.Sort.relevance') },
                      {
                        key: `title_sort.${searchState.language} desc`,
                        value: __('Components.ProductsList.Sort.desc'),
                      },
                      {
                        key: `title_sort.${searchState.language} asc`,
                        value: __('Components.ProductsList.Sort.asc'),
                      },
                    ]}
                    hAlign="right"
                    itemMinWidth="140px"
                    onSelect={v => {
                      const s = v.split(' ');
                      this.setState({ searchState: { ...searchState, sort: s[0], sortOrder: s[1] as 'desc' | 'asc' } });
                      EventTrack.track(from === 'pricelist' ? 'public_products_sort' : 'showroom_products_sort', {
                        workspace_id: catalog.id,
                        sort: s[1],
                      });
                    }}
                  >
                    <S.SortButton type="skip" iconName="Sort" iconSize="15px">
                      <S.BoldText>{__('PublicShowroom.sort_by') + ':'}</S.BoldText> {sortingOrder}
                    </S.SortButton>
                  </SimpleDropdown>
                </S.ButtonGroup>

                <S.HeaderItemContainer>
                  <FilterSwitchIcon
                    options={getListModeOptions()}
                    keySelected={listMode}
                    onSelectOption={(key: string) => {
                      this.setState({ listMode: key as 'card' | 'list' });
                      window.localStorage.setItem('products_showroom_display', key);
                      EventTrack.track('products_change_display', {
                        workspace_id: catalog?.id,
                        display: key,
                        type: from,
                      });
                    }}
                  />
                </S.HeaderItemContainer>
                {amSeller && ExcelDropdown ? <S.HeaderItemContainer>{ExcelDropdown}</S.HeaderItemContainer> : null}
              </RowContainer>
            </SR.HeaderRow>
            <SR.Group>
              <SR.GroupTitle>{this.getTitle()}</SR.GroupTitle>
              <NonResults amSeller={amSeller} hasHits={!!products.length} searchText={searchState.text}>
                {listMode === 'card' ? (
                  <SR.GridGroup from={from}>
                    {products.map(p => {
                      const cartItem = cart?.find(c => c.childId === p.id);
                      return (
                        <ProductCard
                          amount={cartItem?.amount || 0}
                          amSeller={amSeller}
                          cartUpdateItem={cartUpdateItem}
                          data={parsers.productToGenericProduct(p)}
                          featuredToggle={featuredToggle}
                          isFavorite={featured?.favorite?.includes(p.hashId)}
                          isRecent={featured?.recent?.includes(p.hashId)}
                          isRecommended={featured?.recommended?.includes(p.hashId)}
                          isSelected={false}
                          isServedFlow={isServedFlow}
                          key={'_' + p.id}
                          navigate={() => onNavigate('products', undefined, undefined, p)}
                          openShareProduct={this.onShareSearch}
                          orderItemId={cartItem?.id || 0}
                          price={cartItem?.price || p.price}
                          priceMode={priceMode === 'edit' ? 'read' : priceMode || 'none'}
                          pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
                          saleUnit={cartItem?.saleUnit || p.saleUnits[0]}
                          servedQuantity={cartItem?.servedQuantity || 0}
                          hideFeatured={hideFeatured}
                          showShare={true}
                        />
                      );
                    })}
                  </SR.GridGroup>
                ) : (
                  <S.ListGroup from={from}>
                    <ProductTable
                      cartItems={cart}
                      cartUpdateItem={cartUpdateItem}
                      catalog={catalog}
                      clickProduct={this.navigateToProductFromId}
                      configId={amSeller ? 'product_list_showroom' : 'product_list_showroom_buyer'}
                      hasOverflow={false}
                      me={me}
                      onScroll={this.onScroll}
                      precision={catalog?.numberOfDecimalsShowed || constants.PRICE_PRECISION}
                      products={products}
                      role={sellerWorkspaceService.getRole(catalog, me.id)}
                      showCustomColumns={!!me.id}
                      touchImage={touchImage}
                    />
                  </S.ListGroup>
                )}
              </NonResults>
            </SR.Group>
          </S.Body>
        </S.BodyWrapper>
      </S.RowBody>
    );
  }

  /**
   * Open product details from id
   */
  private navigateToProductFromId = (id: number) => {
    const { products } = this.state;
    const { onNavigate } = this.props;
    const productData = products.find(p => p.id === id);
    if (productData) {
      onNavigate('products', undefined, undefined, productData);
    }
  };

  private updatePricesInProducts = () => {
    const { prices } = this.props;
    const { products } = this.state;
    this.setState({ products: products.map(p => ({ ...p, ...(prices?.[p.id] || {}) })) });
  };

  /**
   * Get product's filters count
   */
  private getFiltersCount = () => {
    const { searchState } = this.state;
    const { brand, category, organic, origin, size, type } = searchState;
    return (
      (origin?.length || 0) +
      (organic?.length || 0) +
      (brand?.length || 0) +
      (type?.length || 0) +
      (size?.length || 0) +
      (category?.length || 0)
    );
  };

  /**
   * On share product, opens modal
   */
  private onShareSearch = (product?: GenericProduct, productName?: string) => {
    const { onShareSearch } = this.props;
    onShareSearch(product, productName);
  };

  /**
   * receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return;
    const { data, toReplace, facets, facetsGlobal } = res;
    const { catalog, from, prices } = this.props;
    const { searchState } = this.state;
    const filters = productService.getTrackedFilters(searchState);
    if (filters.length) {
      EventTrack.track(from === 'pricelist' ? 'public_products_filter' : 'showroom_products_filter', {
        workspace_id: catalog.id,
        results: data.totalResults,
        filters,
        text: searchState.text,
      });
    }
    const productsToAdd = Object.keys(prices || {})
      ? data.hits.map(p => ({ ...p, ...(prices?.[p.id] || {}) }))
      : data.hits;
    if (toReplace) {
      this.setState({
        products: productsToAdd,
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
        facets,
        facetsGlobal,
      });
    } else {
      this.setState({
        products: [...this.state.products, ...productsToAdd],
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
      });
    }
    if (searchState.text) {
      EventTrack.track(from === 'pricelist' ? 'public_products_search' : 'showroom_products_search', {
        workspace_id: catalog.id,
        results: data.totalResults,
        text: searchState.text,
      });
    }
  };

  /**
   * handle the scroll event for get more results
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore, searchId } = this.state;
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.getMore(searchId, this.onReceiveProducts);
    }
  };

  private getTitle() {
    const { catalog, showFeatured, section } = this.props;
    const { searchState, totalResults } = this.state;
    if (searchState.text) return __('PublicShowroom.Titles.search', searchState);
    if (section) {
      const catalogSection =
        section === NO_TAG
          ? sellerWorkspaceService.sectionNew(NO_TAG, catalog?.sections.length + 1, 0)
          : catalog?.sections.find(s => s.tag === section);

      return (!catalogSection || catalogSection.tag) === NO_TAG
        ? __('Showroom.no_tag')
        : catalogSection.translations[searchState.language] || catalogSection.tag;
    }
    if (showFeatured) {
      switch (showFeatured) {
        case 'recommended':
          return __('PublicShowroom.Titles.recommended');
        case 'favorite':
          return __('PublicShowroom.Titles.favorite');
        case 'recent':
          return __('PublicShowroom.Titles.recent');
      }
    }
    return __('PublicShowroom.Titles.all_products_with_count', { count: totalResults });
  }

  /**
   * return featureds filter for search
   */
  private getFeatureds() {
    const { prices, featured, showFeatured } = this.props;
    if (!showFeatured) return [];

    const activePrices = prices ? Object.values(prices) : [];
    switch (showFeatured) {
      case 'recommended':
        return activePrices.filter(price => featured.recommended.includes(price.hashId)).length
          ? [...featured.recommended.filter(l => !!l)]
          : ['none'];
      case 'favorite':
        return activePrices.filter(price => featured.favorite.includes(price.hashId)).length
          ? [...featured.favorite.filter(l => !!l)]
          : ['none'];
      case 'recent':
        return activePrices.filter(price => featured.recent.includes(price.hashId)).length
          ? [...featured.recent.filter(l => !!l)]
          : ['none'];
      default:
        return;
    }
  }
}

export default Products;
