import { __, IModalExtraInfo, utils } from 'common-services';
import * as React from 'react';

import { Modal } from '../../atoms';
import * as S from './NiceModal.styled';

interface IProps {
  text: string;
  extra: IModalExtraInfo;
  isOpen: boolean;
  modalAction?: () => void;
  modalClose: () => void;
}

const ModalComponent: React.FC<IProps> = ({ text, extra, isOpen, modalAction, modalClose }) => {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState(false);

  return (
    <Modal showCloseIcon={extra.closeable} toShow={isOpen} onCloseClick={modalClose} maxWidth={'600px'}>
      <S.Container>
        <S.Content>
          {extra.icon && <S.Image src={extra.icon} />}
          {text.split(/(?:\r\n|\r|\n)/).map((t, i) => (
            <S.Row key={'title' + i}>
              <S.Title>{formatTitlePurple(t)}</S.Title>
            </S.Row>
          ))}
          {extra.subtitle ? <S.SubTitle>{extra.subtitle}</S.SubTitle> : null}
          <S.Col>
            {extra.text2 &&
              extra.text2.split(/(?:\r\n|\r|\n)/).map((t, i) => (
                <S.TextBody key={'body' + i}>
                  {
                    utils.formatTextWithDelimiters(t, ['##', '**'], (s, idx, delimiter) => {
                      switch (delimiter) {
                        case '##':
                          return (
                            <S.BodyBlue key={s + '_' + idx} onClick={extra.linkAction}>
                              {s}
                            </S.BodyBlue>
                          );
                        case '**':
                          return <S.BodyBold key={s + '_' + idx}>{s}</S.BodyBold>;
                        default:
                          return s;
                      }
                    }) as any // TYPEERROR
                  }
                </S.TextBody>
              ))}
          </S.Col>
          {extra.checkBox && (
            <S.CheckBoxRow>
              <S.CheckBox
                onClick={() => {
                  setCheckBoxChecked(!checkBoxChecked);
                  extra.checkBoxAction();
                }}
                className="add-pricegroups-checkbox"
                isChecked={checkBoxChecked}
              />

              <S.CheckBoxLabel
                onClick={() => {
                  setCheckBoxChecked(!checkBoxChecked);
                  extra.checkBoxAction();
                }}
              >
                {extra.checkBox}
              </S.CheckBoxLabel>
            </S.CheckBoxRow>
          )}
          <S.RowCTA twoCTA={extra.showCancelButton}>
            {extra.linkText ? <S.LinkText onClick={extra.linkAction || modalClose}>{extra.linkText}</S.LinkText> : null}
            {extra.showCancelButton && (
              <S.CTA
                id={extra.id && `modal-cancel-${extra.id}`}
                onClick={extra.cancelAction || modalClose}
                type="secondary"
              >
                {extra.buttonCancelText || __('Components.Modal.cancel')}
              </S.CTA>
            )}
            <S.CTA
              id={extra.id && `modal-accept-${extra.id}`}
              onClick={onAccept}
              type={extra.actionType === 'dangerous' ? 'delete' : 'principal'}
            >
              {extra.buttonText || __('Components.Modal.accept')}
            </S.CTA>
          </S.RowCTA>
        </S.Content>
      </S.Container>
    </Modal>
  );

  /**
   * Launch modal actions and close if modal is not persistent
   */
  function onAccept() {
    if (modalAction) modalAction();
    if (!extra.persistent) modalClose();
  }

  /**
   * Parser for title and body to change **whatever** for a featured text.
   */
  function formatTitlePurple(txt: string) {
    return txt.split('**').map((s, index) => (index % 2 ? <S.TitlePurple key={s + index}>{s}</S.TitlePurple> : s));
  }
};

export default ModalComponent;
