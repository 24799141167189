import { utils } from 'common-services';
import * as React from 'react';

import * as S from './RegisterStep.styled';

export interface IProps {
  children: React.ReactNode;
  className?: string;
  currentStep?: number;
  goBack?: () => void;
  lang?: LOCALE;
  numberOfSteps?: number;
  title: string;
  subtitle?: string;
}

const RegisterStep: React.FC<IProps> = ({
  children,
  className,
  currentStep,
  goBack,
  lang,
  numberOfSteps,
  subtitle,
  title,
}: IProps) => {
  return (
    <S.Container className={className}>
      {typeof currentStep === 'number' ? (
        <S.StepIndicator current={currentStep - 1} lang={lang} numberOfSteps={numberOfSteps} showTextStep={true} />
      ) : null}
      <S.TitleContainer>
        {goBack ? <S.BackButton className={`register-step-back-${currentStep}`} onClick={goBack} size={35} /> : null}
        <S.TextTitle>{title}</S.TextTitle>
      </S.TitleContainer>
      {subtitle ? (
        <S.SubitleContainer>
          <S.TextSubtitle>
            {
              utils.formatText(subtitle, text => (
                <S.TextSubtitleBold key={text} display="inline">
                  {text}
                </S.TextSubtitleBold>
              )) as any // TYPEERROR
            }
          </S.TextSubtitle>
        </S.SubitleContainer>
      ) : null}
      {children}
    </S.Container>
  );
};

export default React.memo(RegisterStep);
