import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;

  .workspace-settings-members {
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;
