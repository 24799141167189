import { sellerWorkspaceService } from 'common-services';
import * as React from 'react';

import { FontIcon, SimpleDropdown, WorkspaceAvatar } from '../../atoms';
import * as S from './WorkspaceSelect.styled';

/**
 *  Render a catalog management dropdown.
 */
const CatalogSelect: React.FC<{
  catalogIdSelected: number;
  catalogs: { [id: number]: IWorkspace };
  className?: string;
  contacts: { [id: number]: IContact };
  disabled?: boolean;
  filteredCatalogs: Array<IWorkspace>;
  me: IUser;
  setCatalogIdSelected: (id: number) => void;
}> = ({ catalogs, className, contacts, disabled, filteredCatalogs, catalogIdSelected, setCatalogIdSelected, me }) => {
  const catalog = catalogs[catalogIdSelected];
  if (!catalog) return null;
  const catalogName = sellerWorkspaceService.getCatalogName(catalog, contacts, me);
  return (
    <SimpleDropdown
      className={className}
      disabled={disabled}
      hAlign="left"
      vAlign="flex-start"
      onSelect={v => setCatalogIdSelected(Number(v))}
      options={filteredCatalogs.map(c => ({
        key: c.id + '',
        value: sellerWorkspaceService.getCatalogName(c, contacts, me),
        avatar: (
          <WorkspaceAvatar catalog={c} showActiveIcon={true} isActive={sellerWorkspaceService.isActive(c, me.id)} />
        ),
      }))}
      margin="12px 0 0 0"
    >
      {disabled ? (
        <S.WorkspaceSelectedColumn>
          <S.WorkspaceSelectedValue>{catalogName}</S.WorkspaceSelectedValue>
        </S.WorkspaceSelectedColumn>
      ) : (
        <S.WorkspaceSelected>
          <WorkspaceAvatar
            catalog={catalog}
            showActiveIcon={true}
            isActive={sellerWorkspaceService.isActive(catalog, me.id)}
          />
          <S.WorkspaceSelectedColumn>
            <S.WorkspaceSelectedValue>{catalogName}</S.WorkspaceSelectedValue>
          </S.WorkspaceSelectedColumn>
          <S.DownIconWrapper>
            <FontIcon name="Down" />
          </S.DownIconWrapper>
        </S.WorkspaceSelected>
      )}
    </SimpleDropdown>
  );
};

export default React.memo(CatalogSelect);
