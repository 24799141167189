import styled, { ITheme } from '../../../styled-components';
import { Button, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  link?: string;
}

export const Container = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  max-height: 90vh;
`;

export const Buttons = styled(RowContainer)`
  flex-wrap: wrap;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const ButtonShare = styled(Button)`
  padding: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  box-shadow: none;
  & > .button-icon {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
    padding-bottom: 2px;
  }
`;

export const SocialButtons = styled(RowContainer)`
  justify-content: flex-start;
  flex: 1;
`;

export const SocialButton = styled(Button)`
  background-image: url('${(p: IStyledProps) => p.link}');
  height: 30px;
  width: 30px;
  min-width: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
  margin-right: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
`;

export const LinkContainer = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 100%;
  cursor: pointer;
  overflow: hidden;
`;

export const TextGrey2 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  overflow: hidden;
  text-overflow: ellipsis;
`;
