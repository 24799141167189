import { __ } from 'common-services';
import * as React from 'react';
import { IMAGES } from '../../../../../assets';

import * as CS from '../../2FAModal.styled';
import * as S from './Success.styled';

interface IProps {
  onEnd: () => void;
}

/**
 * Success 2FA
 */
const Success = ({ onEnd }: IProps) => {
  return (
    <CS.StepContainer>
      <form
        id="form-success"
        onSubmit={e => {
          e.preventDefault();
          onEnd();
        }}
      >
        <CS.Card id="2fa-success">
          <S.Image src={IMAGES.checkOK} alt="success-2fa-check" />
          <CS.Title textAlign="center">{__('TwoFAModal.Success.title')}</CS.Title>
          <CS.TextSubtitle textAlign="center">{__('TwoFAModal.Success.subtitle')}</CS.TextSubtitle>

          <CS.SubmitButton id="submit-success">{__('TwoFAModal.Success.cta')}</CS.SubmitButton>
        </CS.Card>
      </form>
    </CS.StepContainer>
  );
};

export default React.memo(Success);
