import { __, constants, IPublicUser, IUser, IWorkspace, RenderTrack, utils } from 'common-services';
import * as React from 'react';

import { toParagraph } from '../../../../../util/utils';
import * as S from './About.styled';

interface IProps {
  amSeller: boolean;
  catalog: IWorkspace;
  channelId?: string;
  me: IUser;
  owner: IPublicUser;
  onChat: () => void;
}

/**
 * About content
 */
class About extends React.PureComponent<IProps> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
  }

  public componentDidMount() {
    RenderTrack.track('PublicShopAbout', { renderTime: this.t });
  }

  public render() {
    const {
      amSeller,
      me,
      catalog: { companyImage, bio, workingStructure, additionalInfo },
      owner: { avatar, name, phone, email },
      onChat,
    } = this.props;
    const phoneDisplay = phone ? `+${phone}` : undefined;
    return (
      <S.Body>
        <S.AboutContainer>
          {companyImage ? (
            <S.CompanyImage imageUrl={companyImage} relation={constants.IMAGE_RELATION.COMPANY_IMAGE} />
          ) : null}
          <S.AboutTitle>{__('PublicShowroom.Tabs.about')}</S.AboutTitle>
          {bio ? (
            <>
              <S.AboutSubtitle>{__('PublicShowroom.About.company_brief')}</S.AboutSubtitle>
              <S.AboutText>
                <S.AboutParagraph>
                  {utils.splitTextUrls(bio).map(s =>
                    s.isLink ? (
                      <S.AboutLink
                        key={s.text}
                        onClick={() => (typeof window !== 'undefined' ? window.open(s.text, '_blank') : undefined)}
                      >
                        {s.text}
                      </S.AboutLink>
                    ) : (
                      toParagraph(s.text)
                    ),
                  )}
                </S.AboutParagraph>
              </S.AboutText>
            </>
          ) : null}
          {workingStructure ? (
            <>
              <S.AboutSubtitle>{__('PublicShowroom.About.working_structure')}</S.AboutSubtitle>
              <S.AboutText>
                <S.AboutParagraph>
                  {utils.splitTextUrls(workingStructure).map(s =>
                    s.isLink ? (
                      <S.AboutLink
                        key={s.text}
                        onClick={() => (typeof window !== 'undefined' ? window.open(s.text, '_blank') : undefined)}
                      >
                        {s.text}
                      </S.AboutLink>
                    ) : (
                      toParagraph(s.text)
                    ),
                  )}
                </S.AboutParagraph>
              </S.AboutText>
            </>
          ) : null}
          {additionalInfo ? (
            <>
              <S.AboutSubtitle>{__('PublicShowroom.About.additional_info')}</S.AboutSubtitle>
              <S.AboutText>
                <S.AboutParagraph>
                  {utils.splitTextUrls(additionalInfo).map(s =>
                    s.isLink ? (
                      <S.AboutLink
                        key={s.text}
                        onClick={() => (typeof window !== 'undefined' ? window.open(s.text, '_blank') : undefined)}
                      >
                        {s.text}
                      </S.AboutLink>
                    ) : (
                      toParagraph(s.text)
                    ),
                  )}
                </S.AboutParagraph>
              </S.AboutText>
            </>
          ) : null}
          <S.Contact>
            <S.ContactInfo>
              {avatar ? <S.Avatar image={avatar} /> : null}
              <S.ContactCard>
                <S.Name>{name}</S.Name>
                {phoneDisplay ? (
                  <S.PhoneEmail href={`tel:${phoneDisplay}`}>
                    <S.Icon name="Phone" />
                    {phoneDisplay}
                  </S.PhoneEmail>
                ) : null}
                {email ? (
                  <S.PhoneEmail href={`mailto:${email}`}>
                    <S.Icon name="Read" />
                    {email}
                  </S.PhoneEmail>
                ) : null}
                <S.ChatButton onClick={() => onChat()} type="other">
                  {me.id && amSeller
                    ? __('Pricelist.go_my_products')
                    : __('Pricelist.chat_with', { name: utils.firstToUpperCase(name) })}
                </S.ChatButton>
              </S.ContactCard>
            </S.ContactInfo>
          </S.Contact>
        </S.AboutContainer>
      </S.Body>
    );
  }
}

export default About;
