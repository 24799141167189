import './app/util/polyfill';
import './index.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-image-crop/lib/ReactCrop.scss';

import { IGlobalWeb, LOCALE, qs } from 'common-services';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as notification from './app/services/notification';
import locales from './locales';
import * as Sentry from '@sentry/react';

declare var global: IGlobalWeb;
global.localStorage.setItem('platform', 'web');

// If device is iPad set height to 100% (fixing keyboard bug)
if (navigator.userAgent.match(/iPad/i)) {
  document.body.style.height = '100%';
}

const { lang } = qs.parse(window.location.search || '', ['lang']) as {
  lang?: string;
};
const initLocale = lang || global.localStorage.getItem('language') || window.navigator.language.substring(0, 2);
locales(initLocale as LOCALE).then(render);

function render() {
  const div = document.getElementById('root') as HTMLElement;
  const root = createRoot(div);
  root.render(<App />);
}

notification.registerServiceWorker();

// registerServiceWorker();
