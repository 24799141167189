import {
  __,
  broadcastActions,
  CHANNEL_DISPLAY,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  contactActions,
  contactService,
  EventTrack,
  IChatListChannelsV2,
  INVITE_ORIGIN,
  INVITE_VIA,
  modalActions,
  notificationsActions,
  qs,
  RenderTrack,
  RETURNING_IN,
  sellerWorkspaceService,
  userActions,
  utils,
  VIA,
  WORKING_STATUS,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import * as notificationActions from '../../../actions/notification';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import ChatCreate from '../../../screens/chat-create';
import { getInviteOptions } from '../../../services/invite';
import getPath from '../../../util/routes';
import { copyToClipboard } from '../../../util/utils';
import { Button, ChatCard, FontIcon } from '../../atoms';
import { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import { ChatGrouped, InviteAddressbookModal, SimpleSearch } from '../../molecules';
import * as S from './ChatList.styled';

declare var global: IGlobalWeb;

export type IChatListRouteProps = RouteComponentProps<{ channelId?: string }> & {
  isModal?: boolean;
  isUnread?: boolean;
};

export interface IChatListStateProps {
  broadcastLastMessageAt: Record<string, number>;
  broadcasts: { [broadcastId: number]: IBroadcast };
  chatGroupsExpanded?: navActions.IChatGroupsExpanded;
  channels: { [channelId: string]: IChannel };
  contactId?: number;
  contacts: { [contactId: number]: IContact };
  disabled: boolean;
  hasOutContacts: boolean;
  lastChannelId?: string;
  lastMessageAt: Record<string, number>;
  me: IUser;
  miniChannelId?: string;
  pendingOrders: { [contactId: number]: number };
  showNotificationHeader: boolean;
  unreadMessageCount: Record<string, number>;
  workspaceSelected?: IWorkspace;
}

export interface IChatListDispatchProps {
  accessedByInvitationHashId: typeof contactActions.accessedByInvitationHashId;
  channelArchive: typeof chatActions.channelArchive;
  channelMute: typeof chatActions.channelMute;
  chatGroupsExpandedSet: typeof navActions.chatGroupsExpandedSet;
  contactsAddressbookGet: typeof contactActions.contactsAddressbookGet;
  contactsInvite: typeof contactActions.contactsInvite;
  createChannel: typeof chatActions.createChannel;
  createNewBroadcast: typeof broadcastActions.createBroadcast;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navChannelAction: typeof navActions.navChannelAction;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  navLastChannelSet: typeof navActions.navLastChannelSet;
  navMiniChannelAction: typeof navActions.navMiniChannelAction;
  notificationShow: typeof notificationsActions.notificationShow;
  setChannelsAction: typeof chatActions.setChannelsAction;
  showNotificationRequestPermission: typeof notificationActions.showRequestPermission;
  updateBroadcast: typeof broadcastActions.updateBroadcast;
  updateUser: typeof userActions.updateUser;
}

export type IProps = IChatListStateProps & IChatListRouteProps & IChatListDispatchProps;

interface IState {
  archivedChannels: Array<IContactShow>;
  buySellGroupChannels: Array<IContactShow>;
  buySellGroupChannelsUnread: number;
  chatGroupsExpanded: navActions.IChatGroupsExpanded;
  clientsSupplierChannels: Array<IContactShow>;
  clientsSupplierChannelsUnread: number;
  createChatMode?: 'group' | 'broadcast';
  filter: string;
  groupBroadcastChannels: Array<IContactShow>;
  groupBroadcastChannelsUnread: number;
  outConsentio?: Array<IContact>;
  outContactsFiltered: Array<IContactValidPhone>;
  phoneInvited: Array<string>;
  privateChannels: Array<IContactShow>;
  privateChannelsUnread: number;
  publicChannels: Array<IContactShow>;
  publicChannelsUnread: number;
  showArchivedChats: boolean;
  showCreateChat?: string | boolean;
  showInviteClientModal?: INVITE_VIA | undefined;
  showInviteModal?: INVITE_VIA | undefined;
  showOpen?: boolean;
  trackInviteFrom?:
    | 'chat-list-general'
    | 'chat-list-clients'
    | 'chat-list-suppliers'
    | 'chat-list-contacts'
    | 'chat-list-zero-case';
  workingStatus: WORKING_STATUS;
}

/**
 * List of chats
 */
export default class ChatList extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement>;
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.containerRef = React.createRef();
    const {
      history,
      location: { pathname },
      match: {
        params: { channelId },
      },
    } = props;
    this.state = {
      archivedChannels: [],
      chatGroupsExpanded: this.getInitialChatGroupsExpanded(channelId, pathname),
      filter: '',
      groupBroadcastChannels: [],
      groupBroadcastChannelsUnread: 0,
      outContactsFiltered: [],
      phoneInvited: [],
      privateChannels: [],
      privateChannelsUnread: 0,
      publicChannels: [],
      publicChannelsUnread: 0,
      showArchivedChats: false,
      showOpen: false,
      workingStatus: props.me.settings.workingStatus || WORKING_STATUS.AVAILABLE,
      buySellGroupChannels: [],
      buySellGroupChannelsUnread: 0,
      clientsSupplierChannels: [],
      clientsSupplierChannelsUnread: 0,
    };
    const inviteHashId = (
      qs.parse(typeof window !== 'undefined' ? window.location.search : '', ['h']) as {
        h: string;
      }
    )?.h;
    if (inviteHashId) {
      props.accessedByInvitationHashId(inviteHashId, (channel?: IChannel, post?: IMessage) => {
        if (channel) {
          const isPublic = channel.type === CHANNEL_TYPE.PUBLIC;
          if (post && isPublic) {
            history.push(
              getPath({
                path: ROUTE_PATHS.PUBLIC_CHANNEL_POST,
                channelId: channel.id,
                postId: post.messageId ? post.messageId + '' : undefined,
              }),
            );
          } else if (!isPublic && channel.type === CHANNEL_TYPE.BROADCAST) {
            props.navigateChannelByPath(ROUTE_PATHS.BROADCAST, channel.id, (path: string) => history.push(path));
          } else {
            props.navigateChannelByPath(
              isPublic ? ROUTE_PATHS.PUBLIC_CHANNEL : ROUTE_PATHS.GROUP,
              channel.id,
              (path: string) => history.push(path),
            );
          }
        }
      });
    }
  }

  public componentDidMount() {
    const { match, channels, broadcasts, isModal, isUnread } = this.props;
    const { channelId } = match?.params || {};
    const channelsGrouped = this.getGroupedChannels(CHANNEL_DISPLAY.NORMAL);
    RenderTrack.track('ChatList', {
      renderTime: this.t,
      channelsCount: chatService.getTotalChannelsCountV2(channelsGrouped),
      display: CHANNEL_DISPLAY.NORMAL,
    });
    this.setState({
      privateChannels: channelsGrouped.privateChannels.channelsToShow,
      privateChannelsUnread: channelsGrouped.privateChannels.unread,
      publicChannels: channelsGrouped.publicChannels.channelsToShow,
      publicChannelsUnread: channelsGrouped.publicChannels.unread,
      groupBroadcastChannels: channelsGrouped.groupBroadcast.channelsToShow,
      groupBroadcastChannelsUnread: channelsGrouped.groupBroadcast.unread,
      buySellGroupChannels: channelsGrouped.buySellGroup.channelsToShow,
      buySellGroupChannelsUnread: channelsGrouped.buySellGroup.unread,
      clientsSupplierChannels: channelsGrouped.clientsSuppliers.channelsToShow,
      clientsSupplierChannelsUnread: channelsGrouped.clientsSuppliers.unread,
      archivedChannels:
        Object.keys(channels).length || Object.keys(broadcasts).length ? this.getChannelsArchived() : [],
    });
    if (!isModal && !isUnread) {
      if (
        Object.keys(channels).length &&
        (!channelId || (!channels[channelId] && !broadcasts[channelId] && channelId !== 'new'))
      ) {
        this.selectFirstChannel();
      } else if (Number.isInteger(Number(channelId)) && Object.keys(channels).length) {
        this.selectChannelFromContactId(Number(channelId));
      }
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const {
      broadcasts,
      channels,
      chatGroupsExpanded,
      contacts,
      isModal,
      isUnread,
      lastMessageAt,
      match,
      me,
      unreadMessageCount,
    } = this.props;
    const { channelId } = match?.params || {};
    const { archivedChannels, filter, outContactsFiltered, showArchivedChats, workingStatus } = this.state;
    if (
      prevState.filter !== filter ||
      prevProps.channels !== channels ||
      prevProps.broadcasts !== broadcasts ||
      prevProps.contacts !== contacts ||
      prevState.showArchivedChats !== showArchivedChats ||
      prevProps.lastMessageAt !== lastMessageAt ||
      prevProps.unreadMessageCount !== unreadMessageCount
    ) {
      const channelsGrouped = this.getGroupedChannels(CHANNEL_DISPLAY.NORMAL);
      this.setState({
        archivedChannels: showArchivedChats ? this.getChannelsArchived() : archivedChannels,
        privateChannels: channelsGrouped.privateChannels.channelsToShow,
        privateChannelsUnread: channelsGrouped.privateChannels.unread,
        publicChannels: channelsGrouped.publicChannels.channelsToShow,
        publicChannelsUnread: channelsGrouped.publicChannels.unread,
        groupBroadcastChannels: channelsGrouped.groupBroadcast.channelsToShow,
        groupBroadcastChannelsUnread: channelsGrouped.groupBroadcast.unread,
        buySellGroupChannels: channelsGrouped.buySellGroup.channelsToShow,
        buySellGroupChannelsUnread: channelsGrouped.buySellGroup.unread,
        clientsSupplierChannels: channelsGrouped.clientsSuppliers.channelsToShow,
        clientsSupplierChannelsUnread: channelsGrouped.clientsSuppliers.unread,
        outContactsFiltered: filter
          ? this.getOutcontactsToShow(channelsGrouped.privateChannels.channelsToShow)
          : outContactsFiltered,
      });
      if (prevState.showArchivedChats !== showArchivedChats)
        RenderTrack.track('ChatList', {
          renderTime: Date.now(),
          channelsCount: showArchivedChats
            ? archivedChannels.length
            : chatService.getTotalChannelsCountV2(channelsGrouped),
          display: showArchivedChats ? CHANNEL_DISPLAY.ARCHIVED : CHANNEL_DISPLAY.NORMAL,
        });
    }
    if (!isModal && !isUnread) {
      if (
        Object.keys(channels).length &&
        (!channelId || (!channels[channelId] && !broadcasts[channelId] && channelId !== 'new'))
      ) {
        this.selectFirstChannel();
      } else if (Number.isInteger(Number(channelId)) && Object.keys(channels).length) {
        this.selectChannelFromContactId(Number(channelId));
      }
    }
    if (!archivedChannels.length && archivedChannels.length === 0 && showArchivedChats) {
      this.setState({ showArchivedChats: false });
    }
    if (prevState.workingStatus !== workingStatus) {
      this.handleUpdateWorkingStatus(workingStatus);
    }
    if (prevProps.channels !== channels || (prevProps.broadcasts !== broadcasts && prevProps.contacts !== contacts)) {
      this.setState({ archivedChannels: this.getChannelsArchived() });
    }
    if (prevProps.me !== me) this.setState({ workingStatus: me.settings.workingStatus });
    if (prevProps.chatGroupsExpanded !== chatGroupsExpanded) {
      this.setState({ chatGroupsExpanded: { ...chatGroupsExpanded } });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  public render() {
    const { disabled, showNotificationHeader } = this.props;
    const { showArchivedChats, showCreateChat, createChatMode, showOpen } = this.state;

    if (disabled)
      return (
        <S.ContainerDisabled>
          <S.SkeletonRow>
            <S.Skeleton width="66%" />
            <S.Skeleton width="21px" />
          </S.SkeletonRow>
        </S.ContainerDisabled>
      );

    return (
      <S.OutContainer ref={this.containerRef} showOpen={showOpen} id="chat-list-out-container">
        <S.IpadGap />
        <S.Wrapper showOpen={showOpen}>
          <S.HideIcon name={showOpen ? 'Back' : 'Right'} onClick={() => this.setState({ showOpen: !showOpen })} />
          <S.Container className="chat-list-container">
            {!showArchivedChats ? this.renderHeader() : this.renderArchivedTitle()}
            {showNotificationHeader ? this.renderNotificationHeader() : null}
            {this.renderSearch()}
            <S.Scroll id="chat-result" numHeaders={showNotificationHeader ? 4 : 3}>
              <S.Channels>
                {this.getChannelsToShowCount() ? this.renderBodyList() : this.renderZeroCaseSearch()}
                {this.renderPhonebookContacts()}
              </S.Channels>
              {showArchivedChats ? null : this.renderInviteCard()}
            </S.Scroll>
            {showArchivedChats ? null : (
              <>
                {this.renderModal()}
                {showCreateChat ? (
                  <ChatCreate
                    mode={createChatMode}
                    name={typeof showCreateChat === 'string' ? showCreateChat : ''}
                    onCreate={this.onCreateNewChat}
                    onClose={this.onCloseNewChat}
                  />
                ) : null}
              </>
            )}
          </S.Container>
        </S.Wrapper>
      </S.OutContainer>
    );
  }

  private renderBodyList() {
    const { hasOutContacts, history, location, navigateChannelByPath, me, contacts, workspaceSelected } = this.props;
    const {
      archivedChannels,
      chatGroupsExpanded,
      filter,
      groupBroadcastChannels,
      groupBroadcastChannelsUnread,
      privateChannels,
      privateChannelsUnread,
      publicChannels,
      publicChannelsUnread,
      showArchivedChats,
      buySellGroupChannels,
      buySellGroupChannelsUnread,
      clientsSupplierChannels,
      clientsSupplierChannelsUnread,
    } = this.state;
    if (showArchivedChats) return this.renderChannelsList(archivedChannels);
    const showGroupBroadcast = !!groupBroadcastChannels.length;
    const showPublicChannel = !!publicChannels.length;
    const showPrivateChannel = !!privateChannels.length;
    const showBuySellGroupChannels = !!buySellGroupChannels.length;
    const totalUnread = this.getChannelsUnreadCount();
    return (
      <>
        {!filter ? (
          <S.UnreadChannelsContainer
            isSelected={location.pathname.endsWith(ROUTE_PATHS.CHAT_UNREAD)}
            onClick={() => navigateChannelByPath(ROUTE_PATHS.CHAT_UNREAD, '', path => history.push(path))}
          >
            <S.RowCenter>
              <S.MessageIcon name="Message" disableHover={true} />
              <S.UnreadRow>
                <S.TextUnreadTitle hasUnread={!!totalUnread}>
                  {__('Components.ChatList.unread.title')}
                </S.TextUnreadTitle>
                {totalUnread ? <S.BadgeIndicator /> : null}
              </S.UnreadRow>
            </S.RowCenter>
          </S.UnreadChannelsContainer>
        ) : null}
        {workspaceSelected ? (
          <ChatGrouped
            className="chat-grouped-workspace"
            titleSize="big"
            titleWeight="Bold"
            title={__('Components.ChatList.workspace_chats', {
              name: sellerWorkspaceService.getCatalogName(workspaceSelected, contacts, me),
            })}
            defaultExpanded={chatGroupsExpanded.workspace}
            hasSearch={false}
            unread={buySellGroupChannelsUnread + clientsSupplierChannelsUnread}
            onExpand={this.onExpandGroup}
            infoText={__('Components.ChatList.tooltips.workspace')}
            hasBackground={true}
          >
            <>
              {showBuySellGroupChannels ? (
                <ChatGrouped
                  className="chat-grouped-buySellGroup"
                  title={__('Components.ChatList.buy_sell_groups')}
                  defaultExpanded={chatGroupsExpanded.buySellGroup}
                  hasSearch={!!filter}
                  unread={buySellGroupChannelsUnread}
                  onExpand={this.onExpandGroup}
                  infoText={__('Components.ChatList.tooltips.buySell')}
                >
                  {this.renderChannelsList(buySellGroupChannels)}
                </ChatGrouped>
              ) : null}
              <ChatGrouped
                className="chat-grouped-clients"
                title={
                  workspaceSelected?.type === 'seller'
                    ? __('Components.ChatList.client_chats')
                    : __('Components.ChatList.supplier_chats')
                }
                cta={
                  workspaceSelected?.members?.find(m => m.userId === me.id) &&
                  workspaceSelected?.members?.find(m => m.userId === me.id)?.role !== 'viewer'
                    ? workspaceSelected?.type === 'seller'
                      ? __('Components.ChatList.invite.clients')
                      : __('Components.ChatList.invite.suppliers')
                    : null
                }
                defaultExpanded={chatGroupsExpanded.clients}
                hasSearch={!!filter}
                unread={clientsSupplierChannelsUnread}
                onAddClick={
                  workspaceSelected?.members?.find(m => m.userId === me.id) &&
                  workspaceSelected?.members?.find(member => member.userId === me.id).role !== 'viewer'
                    ? () =>
                        this.setState({
                          showInviteClientModal: hasOutContacts ? INVITE_VIA.PHONEBOOK : INVITE_VIA.SMS,
                          trackInviteFrom:
                            workspaceSelected?.type === 'seller' ? 'chat-list-clients' : 'chat-list-suppliers',
                        })
                    : null
                }
                onExpand={this.onExpandGroup}
                ctaBlue={true}
              >
                {this.renderChannelsList(clientsSupplierChannels)}
              </ChatGrouped>
            </>
          </ChatGrouped>
        ) : null}

        <ChatGrouped
          className="chat-grouped-general"
          titleSize="big"
          titleWeight="Bold"
          title={__('Components.ChatList.general_chats')}
          defaultExpanded={chatGroupsExpanded.general}
          hasSearch={false}
          unread={groupBroadcastChannelsUnread + privateChannelsUnread + publicChannelsUnread}
          onExpand={this.onExpandGroup}
          infoText={__('Components.ChatList.tooltips.general')}
        >
          <>
            {showGroupBroadcast ? (
              <ChatGrouped
                className="chat-grouped-group_other"
                title={__('Components.ChatList.groups.title')}
                cta={filter ? '' : __('Components.ChatList.groups.cta')}
                defaultExpanded={chatGroupsExpanded.group}
                hasSearch={!!filter}
                unread={groupBroadcastChannelsUnread}
                clickOptions={[
                  { key: 'group', value: __('Components.ChatList.create_group'), icon: 'Add-group' },
                  { key: 'broadcast', value: __('Components.Broadcast.add_new_broadcast'), icon: 'Add-diffusion' },
                ]}
                onAddClick={this.handleExtendedOptions}
                onExpand={this.onExpandGroup}
              >
                {this.renderChannelsList(groupBroadcastChannels)}
              </ChatGrouped>
            ) : null}
            {showPrivateChannel ? (
              <ChatGrouped
                className="chat-grouped-private_other"
                title={__('Components.ChatList.direct_messages.title')}
                cta={__('Components.ChatList.direct_messages.cta')}
                defaultExpanded={chatGroupsExpanded.private}
                hasSearch={!!filter}
                unread={privateChannelsUnread}
                onAddClick={() =>
                  this.setState({
                    showInviteModal: hasOutContacts ? INVITE_VIA.PHONEBOOK : INVITE_VIA.SMS,
                    trackInviteFrom: 'chat-list-contacts',
                  })
                }
                onExpand={this.onExpandGroup}
              >
                {this.renderChannelsList(privateChannels)}
              </ChatGrouped>
            ) : null}
            {showPublicChannel ? (
              <ChatGrouped
                className="chat-grouped-public"
                defaultExpanded={chatGroupsExpanded.public}
                hasSearch={!!filter}
                title={__('Components.ChatList.public.title')}
                unread={publicChannelsUnread}
                onExpand={this.onExpandGroup}
                cta={__('Components.ChatList.public.cta')}
                ctaId="chat-list-add-channel-cta"
                onAddClick={() => EventTrack.track('public_channel_ask_create')}
              >
                {this.renderChannelsList(publicChannels)}
              </ChatGrouped>
            ) : null}
          </>
        </ChatGrouped>
      </>
    );
  }

  /**
   * Callback when expanding a group.
   * Save preferences to the redux state.
   */
  private onExpandGroup = (expanded: boolean, className?: string) => {
    const { chatGroupsExpanded } = this.state;
    const { chatGroupsExpandedSet } = this.props;
    const key = className?.split('-')[2];
    if (!key) return;
    chatGroupsExpandedSet({ ...chatGroupsExpanded, [key]: expanded });
  };

  private renderChannelsList(channelsToShow: Array<IContactShow>) {
    const {
      channelArchive,
      channelMute,
      contacts,
      contactsInvite,
      lastMessageAt,
      match,
      me,
      notificationShow,
      unreadMessageCount,
      updateBroadcast,
    } = this.props;
    const { channelId } = match?.params || {};
    const { filter, showOpen } = this.state;
    return channelsToShow.length && lastMessageAt
      ? channelsToShow.map((c, i, arr) => {
          const contactId = !['group', 'broadcast'].includes(c.type) && c.members?.[0]?.id;
          const contact = contactId && contacts[contactId];
          let featuredSubtitle = c.pendingOrders
            ? __('Components.ChatList.subtitle', { count: c.pendingOrders, amount: c.pendingOrders })
            : undefined;
          if (contact?.isNew && !contact?.isUnregistered && !contact?.isDefaultContact) {
            featuredSubtitle = __('Messages.Chat.greetings');
          }
          const isLast = i === arr.length - 1;
          const isPublic = c.type === CHANNEL_TYPE.PUBLIC;
          const defaultPublicImage = isPublic
            ? c.configuration?.thumbnail || c.imageUrl || IMAGES.publicChannelDefault.thumbnail
            : undefined;
          return (
            <ChatCard
              amActive={c.status === 'active'}
              avatarColor={c.avatarColor}
              badge={unreadMessageCount?.[c.id]}
              channelArchive={channelArchive}
              channelId={c.id}
              channelMute={channelMute}
              className={`channel-${c.id || contactId}`}
              contact={contact}
              dateFormat={me.settings.dateFormat}
              featuredSubtitle={featuredSubtitle}
              hourFormat={me.settings.hourFormat}
              imBlocking={c.imBlocking}
              img={isPublic ? defaultPublicImage : c.imageUrl}
              isArchived={c.display === CHANNEL_DISPLAY.ARCHIVED}
              isBroadcast={c.type === CHANNEL_TYPE.BROADCAST}
              isGroup={c.type === CHANNEL_TYPE.GROUP}
              isInvited={contact?.isUnregistered}
              isLast={isLast}
              isMuted={c.muted}
              isNew={c.isNew}
              isSelected={!!channelId && channelId === c.id}
              key={c.id + (contactId || '') + '_' + i}
              lastTimeInvited={contact?.lastTimeInvited}
              myId={me.id!}
              notificationShow={notificationShow}
              onClick={this.handleClickChannel}
              resendInvite={contactsInvite}
              searchText={filter}
              showOpen={showOpen}
              subtitle={
                featuredSubtitle ||
                (c.type === CHANNEL_TYPE.BROADCAST
                  ? __('Constants.NumMembers', { count: c.numMembers || 0 })
                  : c.companyName)
              }
              time={lastMessageAt?.[c.id] || c.createdAt}
              title={`${isPublic ? '#' : ''}${c.name}`}
              type={c.type}
              updateBroadcast={updateBroadcast}
            />
          );
        })
      : null;
  }

  /**
   * Render zero case text for empty search results
   */
  private renderZeroCaseSearch() {
    const { filter } = this.state;
    if (!filter) return null;
    return (
      <S.ZeroCaseContainer>
        <S.TextGrey2 textAlign="center">{__('Components.ChatList.zero_case.empty', { search: filter })}</S.TextGrey2>
      </S.ZeroCaseContainer>
    );
  }

  private onCloseNewChat = () => this.setState({ showCreateChat: undefined, createChatMode: undefined });
  private onCreateNewChat = (c: IChannel | IBroadcast) => {
    const { history, notificationShow } = this.props;
    const { createChatMode } = this.state;
    history.push(
      createChatMode === 'broadcast'
        ? getPath({ path: ROUTE_PATHS.BROADCAST, channelId: c.id })
        : getPath({ path: ROUTE_PATHS.GROUP, channelId: c.id }),
    );
    this.setState({ showCreateChat: undefined, createChatMode: undefined });
    notificationShow({
      closable: true,
      subtitle: '',
      style: 'success',
      title:
        createChatMode === 'broadcast'
          ? __('Components.ChatList.new_broadcast_created')
          : __('Components.ChatList.new_group_created'),
    });
  };

  /**
   * Render phonebook contacts suggestions
   */
  private renderPhonebookContacts = () => {
    const { outContactsFiltered, filter: searchText } = this.state;
    if (!searchText || searchText.length <= 1 || !outContactsFiltered.length) return null;
    return (
      <>
        <S.ZeroCaseContactsBanner>
          <S.TextBanner>{__('Components.ChatList.zero_case.phonebook_contacts_found')}</S.TextBanner>
        </S.ZeroCaseContactsBanner>
        {outContactsFiltered.map((c, index) => this.getOutContactItem({ item: c, index }))}
      </>
    );
  };

  /**
   * Renders contact item in list
   */
  private getOutContactItem = ({ item, index }: { item: IContactValidPhone; index: number }): JSX.Element | null => {
    const { me } = this.props;
    const { outContactsFiltered, filter, showOpen } = this.state;
    const { contact } = item;
    const isLast = index === outContactsFiltered.length - 1;
    return (
      <ChatCard
        amActive={false}
        avatarColor={contact.avatarColor}
        channelId=""
        contact={contact}
        contactPhonebook={contact}
        dateFormat={me.settings.dateFormat}
        hourFormat={me.settings.hourFormat}
        img={contact.avatar || ''}
        isArchived={false}
        isInvited={false}
        isLast={isLast}
        isNew={false}
        key={contact.recordID + '_' + contact.name + '_' + contact.updatedAt + '_' + index}
        lastTimeInvited={0}
        myId={me.id}
        onClick={this.onSendInviteClickRow}
        searchText={filter}
        showOpen={showOpen}
        sendInvite={this.sendInvite}
        subtitle={item.phoneFormatted}
        title={contact.name}
        toInvite={true}
        type={CHANNEL_TYPE.PRIVATE}
      />
    );
  };

  /**
   * Get contacts from phonebook that matches the search
   */
  private getOutcontactsToShow = (privateChannels: Array<IContactShow>): Array<IContactValidPhone> => {
    const { contacts } = this.props;
    const { phoneInvited, filter: searchText, outConsentio } = this.state;
    if (!searchText || !outConsentio) return [];
    const filter = searchText && searchText.length > 1 ? utils.toLocaleLowerCaseNormalized(searchText) : '';
    // Avoid duplicated contacts
    const contactsExcluded = privateChannels.reduce((acc, channel) => {
      const contactId = channel.type === CHANNEL_TYPE.PRIVATE ? channel.members[0]?.id : undefined;
      if (contactId && contacts[contactId]) acc.push(contacts[contactId] as IContact);
      return acc;
    }, [] as Array<IContact>);

    const result = contactService.getValidPhoneContacts(
      outConsentio.filter(
        c =>
          c.name &&
          (utils.toLocaleLowerCaseNormalized(c.name).includes(filter) ||
            c.phoneNumbers.find(p => p.includes(filter))) &&
          !phoneInvited.find(p => c.phoneNumbers.includes(p)) &&
          !contactsExcluded.find(contact => contact.phoneNumbers.find(p => c.phoneNumbers.includes(p))),
      ),
    );
    return result;
  };

  /**
   * Send invite through SMS
   */
  private sendInvite = (contact: IContact) => {
    if (!contact.phoneNumbers?.length) return;
    const { me, contactsInvite } = this.props;
    const { phoneInvited } = this.state;
    if (!phoneInvited.includes(contact.phoneNumbers[0]))
      this.setState({ phoneInvited: [...phoneInvited, contact.phoneNumbers[0]] });

    setTimeout(
      () =>
        contactsInvite(me.id!, VIA.SMS, 'web', INVITE_ORIGIN.REGULAR, [
          {
            name: contact.name,
            phone: contact.phoneNumbers[0],
            email: '',
            counterpart_id: '',
          },
        ]),
      200,
    );
  };

  /**
   * Send invite through SMS (on click event)
   */
  private onSendInviteClickRow = (channelId, type, contactId, setInvited, contactPhonebook) => {
    setInvited(true);
    this.sendInvite(contactPhonebook);
  };

  /**
   * Render notification header
   */
  private renderNotificationHeader() {
    const { showNotificationRequestPermission } = this.props;
    const { showOpen } = this.state;
    return (
      <S.NotificationWrapper id="chat-list-notification-wrapper">
        <S.NotificationHeader>
          <S.NotificationIconContainer onClick={() => !showOpen && this.setState({ showOpen: true })}>
            <S.NotificationIcon name="Notification-solid-off" disableHover={true} />
          </S.NotificationIconContainer>
          <S.NotificationCol showOpen={showOpen}>
            <S.NotificationText>{__('Components.ChatList.notification.title')}</S.NotificationText>
            <S.NotificationLink onClick={showNotificationRequestPermission}>
              {__('Components.ChatList.notification.link')}
            </S.NotificationLink>
          </S.NotificationCol>
        </S.NotificationHeader>
      </S.NotificationWrapper>
    );
  }

  /**
   * Render the search input and the refresh icon.
   */
  private renderSearch() {
    const { contactsAddressbookGet, hasOutContacts, me } = this.props;
    const { outConsentio, showOpen } = this.state;
    return (
      <>
        <S.SearchContainer showOpen={showOpen}>
          <SimpleSearch
            onChange={f => this.setState({ filter: f })}
            placeHolder={__('Components.ChatList.search.placeholder')}
            id="input_search_chats_list"
            onFocus={() => {
              if (hasOutContacts && !outConsentio)
                contactsAddressbookGet(me.id!, data => this.setState({ outConsentio: data || [] }));
            }}
          />
        </S.SearchContainer>
        <S.SearchContainerIpad showOpen={showOpen}>
          <S.IpadSearchIcon
            name="Search"
            onClick={() => {
              if (!showOpen) this.setState({ showOpen: true });
              setTimeout(() => document.getElementById('input_search_chats_list')?.focus());
            }}
          />
        </S.SearchContainerIpad>
      </>
    );
  }

  /**
   * Render invite card
   */
  private renderInviteCard() {
    const { outContactsFiltered, showOpen } = this.state;
    const channelsCount = this.getChannelsToShowCount();
    return (
      <S.InviteCard channelsCount={channelsCount + outContactsFiltered.length} showOpen={showOpen}>
        <S.InviteCardTitle>
          {
            utils.formatText(__('Components.ChatList.invite_card_contacts.title'), s => (
              <S.InviteCardTitleGradient key={s}>{s}</S.InviteCardTitleGradient>
            )) as any // TYPEERROR
          }
        </S.InviteCardTitle>
        <S.InviteCardDescription>{__('Components.ChatList.invite_card_contacts.description')}</S.InviteCardDescription>
        <S.InviteCTA
          type="principal"
          onClick={() => {
            this.setState({ showInviteModal: INVITE_VIA.EMAIL, trackInviteFrom: 'chat-list-zero-case' });
            EventTrack.track('invite_contacts_click_cta', { from: 'chat-list' });
          }}
        >
          {__('Components.ChatList.invite_card_contacts.cta')}
        </S.InviteCTA>
      </S.InviteCard>
    );
  }

  /**
   * Render the modal to add non registered user.
   */
  private renderModal = () => {
    const { me, workspaceSelected } = this.props;
    const { showInviteModal, showInviteClientModal, trackInviteFrom } = this.state;
    if (showInviteModal)
      return (
        <InviteAddressbookModal
          defaultInviteBy={showInviteModal}
          from={trackInviteFrom || 'chat-list'}
          me={me}
          onClose={() => this.setState({ showInviteModal: undefined, trackInviteFrom: undefined })}
          origin={INVITE_ORIGIN.REGULAR}
        />
      );
    if (showInviteClientModal)
      return (
        <InviteAddressbookModal
          catalogId={workspaceSelected?.id}
          defaultInviteBy={showInviteClientModal}
          from={trackInviteFrom || 'chat-list'}
          me={me}
          onClose={() => this.setState({ showInviteClientModal: undefined })}
          origin={INVITE_ORIGIN.WORKSPACE}
          otherTitle={
            workspaceSelected?.type === 'seller'
              ? __('Components.ChatList.invite.other.clients_title')
              : __('Components.ChatList.invite.other.suppliers_title')
          }
        />
      );
    return null;
  };

  /**
   * Navigate to a specific channel and create it if not exist.
   */
  private handleClickChannel = (channelId: string, type: CHANNEL_TYPE, contactId: number) => {
    const {
      history,
      createChannel,
      me,
      contacts,
      navigateChannelByPath,
      navMiniChannelAction,
      isModal,
      match: { params },
      navChannelAction,
      navLastChannelSet,
    } = this.props;
    if (isModal) {
      return navMiniChannelAction(channelId);
    }
    navLastChannelSet(channelId || '');
    if (type === CHANNEL_TYPE.BROADCAST) {
      return history.push(getPath({ path: ROUTE_PATHS.BROADCAST, channelId }));
    }
    if (type === CHANNEL_TYPE.PUBLIC) {
      return history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId }));
    }
    if (channelId) {
      if (channelId === params.channelId) {
        navChannelAction(channelId, CHANNEL_SECTIONS.MESSAGES);
      }
      return navigateChannelByPath(contactId ? ROUTE_PATHS.CONTACT : ROUTE_PATHS.GROUP, channelId, (path: string) => {
        history.push(path);
      });
    }
    if (type === CHANNEL_TYPE.GROUP) {
      return this.setState({ showCreateChat: __('ChatGroupCreate.nameGroup', { name: me.name }) });
    }
    createChannel(
      me.id,
      [contactId],
      contacts[contactId].name,
      '',
      CHANNEL_TYPE.PRIVATE,
      (c: IChannel) => navigateChannelByPath(ROUTE_PATHS.CONTACT, c.id, (path: string) => history.push(path)),
      true,
      true,
    );
  };

  /**
   * Select first channel if channelId in URL is not defined.
   * If lastChannelId is defined in redux nav state, select it and scroll to it.
   */
  private selectFirstChannel() {
    const { disabled, lastChannelId, navigateChannelByPath, history } = this.props;
    const { clientsSupplierChannels, buySellGroupChannels, privateChannels, groupBroadcastChannels, publicChannels } =
      this.state;

    if (this.getChannelsUnreadCount() && !disabled && !lastChannelId) {
      return navigateChannelByPath(ROUTE_PATHS.CHAT_UNREAD, '', path => history.replace(path));
    }

    const channels = [
      ...clientsSupplierChannels,
      ...buySellGroupChannels,
      ...privateChannels,
      ...groupBroadcastChannels,
      ...publicChannels,
    ];
    let channelToShow = lastChannelId ? channels.find(c => c.id === lastChannelId) : undefined;
    if (!channelToShow) channelToShow = channels.find(c => c.id && c.type !== 'broadcast');
    if (channelToShow) {
      const route = channelToShow.type === CHANNEL_TYPE.PRIVATE ? ROUTE_PATHS.CONTACT : ROUTE_PATHS.GROUP;
      navigateChannelByPath(
        channelToShow.type === CHANNEL_TYPE.PUBLIC ? ROUTE_PATHS.PUBLIC_CHANNEL : route,
        channelToShow.id,
        (path: string) => {
          history.replace(path);
          const channelElement = document.getElementsByClassName(`channel-${lastChannelId}`)?.[0];
          if (!channelElement) return;
          setTimeout(() => {
            const viewportOffset = channelElement.getBoundingClientRect();
            if (viewportOffset.top >= window.innerHeight - 100) channelElement.scrollIntoView();
          });
        },
      );
    }
  }

  /**
   * If channelId is a number, we try to select channel from the contact id.
   * RETROCOMPATIBILITY, previously it was a number (contactId), used in old emails
   */
  private selectChannelFromContactId(contactId: number) {
    const { channels, history, navigateChannelByPath } = this.props;
    let channelToShow;
    if (contactId > 0) {
      channelToShow = Object.values(channels).find(
        chan => chan.type === CHANNEL_TYPE.PRIVATE && chan.members.find(cm => cm.id === contactId),
      );
    }
    if (channelToShow)
      navigateChannelByPath(ROUTE_PATHS.CONTACT, channelToShow.id, (path: string) => history.push(path));
  }

  /**
   * Render archived chats title + back
   */
  private renderArchivedTitle() {
    return (
      <S.ArchiveChatsHeader>
        <S.BackArrow name="Arrow" onClick={() => this.setState({ showArchivedChats: false })} />
        <S.TitleArchived>{__('Components.ChatList.archived_chats')}</S.TitleArchived>
      </S.ArchiveChatsHeader>
    );
  }

  /**
   * Render header with user status / invite button / add buttons
   */
  private renderHeader = () => {
    const { hasOutContacts } = this.props;
    const { archivedChannels, showOpen } = this.state;

    return (
      <>
        <S.AddButtonsIpad showOpen={showOpen}>
          <S.Link
            id="chat-list-invite-button"
            onSelect={this.handleInviteOptions}
            closeOnClick={true}
            options={getInviteOptions([
              ...(hasOutContacts ? [INVITE_VIA.PHONEBOOK] : []),
              'copy',
              INVITE_VIA.EMAIL,
              INVITE_VIA.SMS,
            ])}
          >
            <S.IpadIcon name="Add-contact" />
          </S.Link>
          <S.Link
            onSelect={this.handleExtendedOptions}
            closeOnClick={true}
            options={[
              { key: 'group', value: __('Components.ChatList.create_group'), icon: 'Add-group' },
              { key: 'broadcast', value: __('Components.Broadcast.add_new_broadcast'), icon: 'Add-diffusion' },
            ]}
          >
            <S.IpadIcon name="Plus" />
          </S.Link>
        </S.AddButtonsIpad>
        <S.AddButtons showOpen={showOpen}>
          <S.Link
            onSelect={this.handleInviteOptions}
            closeOnClick={true}
            options={getInviteOptions([
              ...(hasOutContacts ? [INVITE_VIA.PHONEBOOK] : []),
              'copy',
              INVITE_VIA.EMAIL,
              INVITE_VIA.SMS,
            ])}
          >
            <Button
              type="link"
              iconName="Add-contact"
              withoutPadding={true}
              iconSize="18px"
              id="chat-list-invite-button"
            >
              {__('Components.ChatList.invite.cta')}
            </Button>
          </S.Link>
          <S.Link
            hAlign="right"
            onSelect={this.handleExtendedOptions}
            closeOnClick={true}
            options={this.getChatsOptions()}
          >
            <S.FontIconWrapper>
              <FontIcon id="add-more" name={archivedChannels.length ? 'Down' : 'Plus'} />
            </S.FontIconWrapper>
          </S.Link>
        </S.AddButtons>
      </>
    );
  };

  /**
   * Get options
   */
  private getChatsOptions() {
    const { archivedChannels } = this.state;
    const result: Array<IItem> = [
      { key: 'group', value: __('Components.ChatList.create_group'), icon: 'Add-group' },
      { key: 'broadcast', value: __('Components.Broadcast.add_new_broadcast'), icon: 'Add-diffusion' },
    ];
    if (archivedChannels.length)
      result.push({
        key: 'archived',
        value: __('Components.ChatList.archived_chats'),
        icon: 'Archive',
      });
    return result;
  }

  /**
   * Get channels archived (using channelsToShow function)
   */
  private getChannelsArchived = (): Array<IContactShow> => {
    const { filter } = this.state;
    const {
      broadcastLastMessageAt,
      broadcasts,
      channels,
      contacts,
      lastMessageAt = {},
      pendingOrders,
      unreadMessageCount = {},
    } = this.props;

    return chatService.getChannelsToShow(
      channels,
      broadcasts,
      contacts,
      filter,
      pendingOrders,
      (search, results) => {
        EventTrack.search({
          name: 'user-search',
          search,
          results,
        });
      },
      CHANNEL_DISPLAY.ARCHIVED,
      lastMessageAt,
      broadcastLastMessageAt,
      unreadMessageCount,
    );
  };

  /**
   * Get channels to display, filtered with search text, and sorted with last message received
   */
  private getGroupedChannels = (display: CHANNEL_DISPLAY): IChatListChannelsV2 => {
    const { filter } = this.state;
    const {
      channels,
      broadcasts,
      broadcastLastMessageAt,
      contacts,
      lastMessageAt = {},
      unreadMessageCount = {},
      me,
      pendingOrders,
      workspaceSelected,
    } = this.props;

    const result = chatService.getChannelsOrganizedToShowV2(
      channels,
      broadcasts,
      contacts,
      filter,
      pendingOrders,
      (search, results) => {
        EventTrack.search({
          name: 'user-search',
          search,
          results,
        });
      },
      display,
      lastMessageAt,
      broadcastLastMessageAt,
      unreadMessageCount,
      me,
      workspaceSelected?.id,
      undefined,
      undefined,
      { unreadExcluded: false, countArchived: false, publicExcluded: false },
    );
    return result;
  };

  /**
   * handle Click on add group or add broadcast and navigate to the creation page.
   */
  private handleExtendedOptions = (key: string) => {
    const { history } = this.props;
    switch (key) {
      case 'group':
        this.setState({ showCreateChat: true, createChatMode: 'group' });
        break;
      case 'broadcast':
        this.setState({ showCreateChat: true, createChatMode: 'broadcast' });
        break;
      case 'archived':
        this.setState({ showArchivedChats: true });
        break;
    }
  };

  /**
   * handle Click on add group or add broadcast and navigate to the creation page.
   */
  private handleInviteOptions = (key: string) => {
    const { contactsInvite, notificationShow, me } = this.props;
    switch (key) {
      case 'phonebook':
        this.setState({ showInviteModal: INVITE_VIA.PHONEBOOK, trackInviteFrom: 'chat-list-general' });
        break;
      case 'copy':
        contactsInvite(me.id!, VIA.LINK, 'web', INVITE_ORIGIN.ONBOARD, undefined, (data, error) => {
          if (data?.inviteLink && !error)
            setTimeout(() =>
              copyToClipboard(data.inviteLink, err =>
                notificationShow(
                  {
                    style: err ? 'error' : 'info',
                    title: err ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                    subtitle: err ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                    closable: true,
                  },
                  4000,
                ),
              ),
            );
        });
        break;
      case 'email':
        this.setState({ showInviteModal: INVITE_VIA.EMAIL, trackInviteFrom: 'chat-list-general' });
        break;
      case 'sms':
        this.setState({ showInviteModal: INVITE_VIA.SMS, trackInviteFrom: 'chat-list-general' });
        break;
    }
  };

  /**
   * Handle click outside to close the channel list
   */
  private handleClickOutside = (event: MouseEvent) => {
    if (this.containerRef?.current && !this.containerRef.current.contains(event.target as any)) {
      if (this.state.showOpen) this.setState({ showOpen: false });
    }
  };

  /**
   * Handle updating working status. If not available selected, show modal to choose to deactivate notifications
   */
  private handleUpdateWorkingStatus(workingStatus: WORKING_STATUS) {
    const { modalOpen, modalClose } = this.props;
    if (
      workingStatus !== WORKING_STATUS.NOT_AVAILABLE ||
      global.localStorage.getItem('notAvailablePushModal') === 'hidden'
    ) {
      this.updateUserWorkingStatus(workingStatus, false);
    } else {
      modalOpen(
        __('ReceiveNotificationsModal.title'),
        () => {
          this.updateUserWorkingStatus(workingStatus, true);
          modalClose();
          global.localStorage.setItem('notAvailablePushModal', 'hidden');
        },
        {
          showCancelButton: true,
          text2: __('ReceiveNotificationsModal.description'),
          subtitle: __('ReceiveNotificationsModal.subtitle'),
          buttonCancelText: __('ReceiveNotificationsModal.cta_cancel'),
          icon: IMAGES.notificationsPineapple,
          buttonText: __('ReceiveNotificationsModal.cta_accept'),
          closeAction: () => {
            this.updateUserWorkingStatus(workingStatus, true);
            modalClose();
            global.localStorage.setItem('notAvailablePushModal', 'hidden');
          },
          cancelAction: () => {
            this.updateUserWorkingStatus(workingStatus, false);
            modalClose();
            global.localStorage.setItem('notAvailablePushModal', 'hidden');
          },
        },
        'nice',
      );
    }
  }

  /**
   * Update user's working status
   */
  private updateUserWorkingStatus(workingStatus: WORKING_STATUS, muteNotificationsWhileNotAvailable: boolean) {
    EventTrack.track('outofoffice_widget_update', {
      working_status: workingStatus,
      mute_notifications_while_not_available: muteNotificationsWhileNotAvailable,
    });
    const { me, updateUser } = this.props;
    updateUser({
      ...me,
      settings: {
        ...me.settings,
        workingStatus,
        muteNotificationsWhileNotAvailable,
        returnToAvailableOption: RETURNING_IN.UNSPECIFIED,
        returnToAvailableTime: undefined,
      },
    });
  }

  /**
   * Get count of the current channels to show
   */
  private getChannelsToShowCount() {
    const {
      archivedChannels,
      privateChannels,
      groupBroadcastChannels,
      publicChannels,
      showArchivedChats,
      clientsSupplierChannels,
      buySellGroupChannels,
    } = this.state;
    return showArchivedChats
      ? archivedChannels.length
      : privateChannels.length +
          groupBroadcastChannels.length +
          publicChannels.length +
          clientsSupplierChannels.length +
          buySellGroupChannels.length;
  }

  /**
   * Get count of the total unread messages in channels
   */
  private getChannelsUnreadCount() {
    const {
      privateChannelsUnread,
      groupBroadcastChannelsUnread,
      publicChannelsUnread,
      showArchivedChats,
      buySellGroupChannelsUnread,
      clientsSupplierChannelsUnread,
    } = this.state;
    return showArchivedChats
      ? 0
      : privateChannelsUnread +
          groupBroadcastChannelsUnread +
          publicChannelsUnread +
          buySellGroupChannelsUnread +
          clientsSupplierChannelsUnread;
  }

  /**
   * Get chat groups expanded state according to the current path
   */
  private getInitialChatGroupsExpanded(channelId: string, pathname: string) {
    const { chatGroupsExpanded, chatGroupsExpandedSet } = this.props;
    if (chatGroupsExpanded) return chatGroupsExpanded;
    const result = getInitialChatGroupsExpanded(channelId, pathname);

    chatGroupsExpandedSet(result);
    return result;
  }
}

export function getInitialChatGroupsExpanded(channelId: string, pathname: string) {
  let result: navActions.IChatGroupsExpanded = {
    private: true,
    group: true,
    public: true,
    workspace: true,
    general: true,
    buySellGroup: true,
    clients: true,
  };
  if (pathname.startsWith('/chats-unread'))
    result = {
      private: true,
      group: false,
      public: false,
      workspace: true,
      general: true,
      buySellGroup: false,
      clients: true,
    };
  else if (!channelId)
    result = {
      private: true,
      group: true,
      public: true,
      workspace: true,
      general: true,
      buySellGroup: true,
      clients: true,
    };
  else if (pathname.startsWith('/contact'))
    result = {
      private: true,
      group: false,
      public: false,
      workspace: true,
      general: true,
      buySellGroup: false,
      clients: true,
    };
  else if (pathname.startsWith('/public'))
    result = {
      private: true,
      group: false,
      public: true,
      workspace: false,
      general: true,
      buySellGroup: false,
      clients: false,
    };
  return result;
}
