import { Api, contactActions, modalActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { navResetAuthAction } from '../actions/nav';

import InviteOnboarding, { IDispatchProps, IStateProps } from '../components/organisms/InviteOnboarding';
import { IReduxState } from '../reducers';

function mapStateToPropsDetails(state: IReduxState): IStateProps {
  const {
    country: { countries },
    user: { user },
  } = state;

  return {
    countries,
    me: user,
  };
}

function mapDispatchToPropsDetails(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      contactsInvite: contactActions.contactsInvite,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navResetAuthAction,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(InviteOnboarding);
