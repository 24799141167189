import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TagBubble, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  src?: string;
  color?: string;
  heightAuto?: boolean;
  priceList?: boolean;
  isFeatured?: boolean;
  featuredColor?: string;
  isPublic?: boolean;
  showInIpad?: boolean;
  imgSelected?: boolean;
  withoutPaddingBottom?: boolean;
  status?: IStatus;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`;

export const LeftContainer = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => (p.isPublic ? 0 : p.theme.paddingSize(2))};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex: 1;
  width: 800px;
  max-width: 800px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-bottom: 100px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 160px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding-bottom: 0px;
  }
`;

export const TitleLabel = styled.h1`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  text-transform: uppercase;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin: ${(p: IStyledProps) => p.margin || p.theme.paddingSize(3) + ' 0'};
  margin-top: 0;
  line-height: 1.3;
`;
export const InfoTitle = styled.h1`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  line-height: 1.3;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;
export const CompanyText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FontIconWrapper = styled.div`
  padding-right: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const Column = styled(ColumnContainer)`
  flex-basis: 25%;
  flex: 1;
  &:not(:last-child) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-basis: calc(50% - ${(p: IStyledProps) => p.theme.paddingObject});
  }
`;

export const Row = styled(RowContainer)`
  flex-wrap: wrap;
  max-width: 100%;
`;

export const Body = styled(Row)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  z-index: ${(p: IStyledProps) => (p.isPublic ? 0 : 2)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  flex: 1;
  padding-right: ${(p: IStyledProps) => (p.isPublic ? 0 : p.theme.paddingSize(2))};
  overflow-y: auto;
  ${(p: IStyledProps) => {
    if (p.heightAuto) return css``;
    const height = `${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight}`;
    return p.priceList
      ? css`
          height: calc(${height} - 85px);
        `
      : css`
          height: calc(${height} * 2);
        `;
  }}
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    padding-right: ${(p: IStyledProps) => (p.isPublic ? 0 : p.theme.paddingSize(3))};
  }
`;

export const Characteristics = styled.div`
  display: grid;
  padding: 0;
  overflow-x: hidden;
  min-height: min-content;
  > span:nth-child(odd) {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const TableSpan = styled.span`
  height: 24px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  display: flex;
  align-items: center;
`;
export const TableField = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: 150px;
  display: inline-block;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const TableValue = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MoreInfo = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    text-align: justify;
  }
`;

export const MoreInfoP = styled.p`
  padding-top: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const MoreInfoSpan = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const MoreInfoA = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const NoPhoto = styled(Row)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-self: center;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
  }
`;

export const ButtonPhoto = styled(Button)``;

export const TextPhoto = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const RequestInfo = styled(Row)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const TextRequestInfo = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const SmallImages = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const SmallImage = styled.img`
  object-fit: cover;
  border-radius: 8px;
  width: 70px;
  height: 46px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
  border: ${(p: IStyledProps) => `2px solid ${p.imgSelected ? p.theme.colors.green1 : p.theme.colors.white}`};
`;

export const Tags = styled(Row)`
  align-items: center;
  width: auto;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0 0 0;
`;

export const Organic = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: 2px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: 10px;
`;
export const Back = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const Head = styled.div`
  width: 55%;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: 7px 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  overflow: hidden;
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'inline')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'inline' : 'none')};
    width: min(390px, 95%);
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'inline' : 'none')};
    width: min(339px, 95%);
  }
`;
export const LeftWrapper = styled(Column)`
  justify-content: flex-start;
  position: relative;
  min-height: min-content;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-right: 0;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 80px;
  }
`;

export const BackContainer = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  display: flex;
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  padding: 40px;
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  justify-content: center;
  align-items: center;
`;

export const ShareTouchable = styled.div`
  align-items: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-self: start;
  display: table;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;
export const ShareIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: 14px;
  padding-bottom: 3px;
  padding-right: 5px;
  vertical-align: middle;
  display: table-cell;
`;
export const TextShare = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  flex-wrap: wrap;
  text-align: center;
  line-height: 13px;
  padding: 5px 0;
  vertical-align: middle;
  display: table-cell;
`;

export const Featured = styled.div`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => (p.isFeatured ? p.theme.colors.black : p.theme.colors.grey3)};
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  & span.productinfo-featured-nohover {
    display: flex;
  }
  & span.productinfo-featured-hover {
    display: none;
  }
  &:hover span.productinfo-featured-nohover {
    display: none;
  }
  &:hover span.productinfo-featured-hover {
    display: flex;
  }
`;

export const FeaturedIcon = styled.div`
  color: ${(p: IStyledProps) => (p.isFeatured ? p.featuredColor : p.theme.colors.grey1)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  width: 28px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeaturedText = styled.span`
  color: ${(p: IStyledProps) => (p.isFeatured ? p.featuredColor : p.theme.colors.grey1)};
  display: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const InfoColumn = styled(ColumnContainer)`
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  margin: 7px 0;
  justify-content: flex-start;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
  }
`;
export const InfoTable = styled(ColumnContainer)`
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  justify-content: flex-start;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
    margin-top: 0;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    flex: none;
  }
`;
export const ProductRow = styled(RowContainer)`
  justify-content: flex-start;
  height: auto;
`;
export const NewColumn = styled(ColumnContainer)`
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  margin: 7px 0;
  justify-content: flex-start;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
  }
`;

export const FavoritesRow = styled.div`
  display: ${(p: IStyledProps) => (p.showInIpad ? 'none' : 'flex')};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: ${(p: IStyledProps) => (p.showInIpad ? 'flex' : 'none')};
  }
`;
export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  display: inline-block;
`;
export const BoldText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline-block;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const InfoText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  float: left;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-left: 0.33em;
  flex-wrap: wrap;
  display: inline-table;
  vertical-align: middle;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  &::before {
    content: '•';
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const LinkButton = styled(Button)`
  padding: 0;
  height: min-content;
`;

export const MoreInfoIcon = styled.div`
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: 18px;
  height: 18px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
export const StatusTag = styled(TagBubble)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: 10px;
`;

export const GreenSpan = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green2};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: 3px;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: max-content;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const GreenSubtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  top: -10px;
`;

export const SubRow = styled(RowContainer)`
  width: 100%;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  ${(p: IStyledProps) =>
    p.withoutPaddingBottom &&
    css`
      padding-bottom: 0;
    `};
`;

export const Subtitle = styled(TextLabel)`
  width: auto;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Sustainabilitytitle = styled(Subtitle)`
  margin-bottom: 0;
`;

export const Sup = styled.sup`
  position: relative;
  top: -0.5em;
`;

export const Sustainabilitytext = styled(Text)`
  top: 0;
  padding-bottom: ${(p: IStyledProps) => (p.withoutPaddingBottom ? p.theme.paddingSize() : p.theme.paddingSize(4))};
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  top: 0px;
  padding: 0;
`;

export const SubRowInfo = styled(SubRow)`
  align-items: center;
  position: absolute;
  bottom: -66px;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const SupInfo = styled.sup`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  position: relative;
  top: -0.3em;
`;

export const ExtLink = styled.a`
  text-decoration: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const Image = styled.img`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  width: 35px;
  height: 35px;
  object-fit: cover;
`;

export const ImgColumn = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  flex: 0;
`;

export const ContainerWrapper = styled(ColumnContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-height: max-content;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const ButtonTraceability = styled(Button)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

const getTextColor = (p: IStyledProps) => {
  if (p.status === 'unavailable') return p.theme.colors.red1;
  if (p.status === 'inactive') return p.theme.colors.grey2;
  return p.theme.colors.green1;
};

export const StatusText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${getTextColor};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextGray = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
