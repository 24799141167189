import { chatActions, constants, IAttachment, IMessage, modalActions, utils } from 'common-services';
import * as React from 'react';

import { downloadFile } from '../../../../../services/file';
import * as S from './AttachmentMessage.styled';

interface IProps {
  message: IMessage;
  touchFile: typeof chatActions.downloadFile;
  touchImage: typeof modalActions.touchImage;
}
const AttachmentMessage: React.FC<IProps> = ({ message, touchFile, touchImage }) => {
  const attachment = message.extraData as IAttachment;
  const { data, id, filename, mimeType, size } = attachment;
  return (
    <S.AttachmentContainer>
      <Logo attachment={attachment} touchImage={touchImage} />
      <S.AttachmentInfoCol>
        <S.TextFile>{utils.cropWithExtension(filename, 50)}</S.TextFile>
        <S.TextFileDescription>{utils.formatSize(size) + ' · ' + filename.split('.').pop()}</S.TextFileDescription>
      </S.AttachmentInfoCol>
      {id || data ? (
        <S.DownloadIcon
          name="Download"
          onClick={() => (id ? touchFile('', () => null) : downloadFile(data, mimeType, filename))}
        />
      ) : null}
    </S.AttachmentContainer>
  );
};

const Logo = ({ attachment, touchImage }: { attachment: IAttachment; touchImage: typeof modalActions.touchImage }) => {
  const { data, mimeType, thumbnail } = attachment;
  const isImage = mimeType.startsWith('image/') && !mimeType.startsWith('image/heic');
  let mimeTypeLogo: string = constants.getLogoFromMime(mimeType);
  if (isImage && (thumbnail || data)) mimeTypeLogo = `data:${mimeType};base64,${thumbnail || data}`;
  if (mimeTypeLogo)
    return <S.MimeTypeLogo src={mimeTypeLogo} isImage={isImage} onClick={() => isImage && touchImage([])} />;
  if (mimeType.startsWith('image/')) return <S.GalleryBigIcon name="Gallery" disableHover={true} />;
  return <S.DocumentIcon name="Document" disableHover={true} />;
};

export default React.memo(AttachmentMessage);
