import * as React from 'react';

import * as S from './BackButton.styled';

export interface IProps {
  className?: string;
  onClick?: () => void;
  size?: number;
  text?: string;
}

const BackButton: React.FC<IProps> = ({ className, onClick, size, text }) => {
  return (
    <S.Row onClick={onClick} className={className}>
      <S.BackContainer>
        <S.BackArrow name="Back" size={size} disableHover={true} />
      </S.BackContainer>
      {text ? <S.Text>{text}</S.Text> : null}
    </S.Row>
  );
};

export default React.memo(BackButton);
