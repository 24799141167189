import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import ActionsModal from '../ActionsModal';

interface IStyledProps {
  theme: ITheme;
  isActive?: boolean;
}

export const Modal = styled(ActionsModal)`
  overflow: visible;
  & .form-wrapper {
    overflow: visible;
  }
  :first-child {
    max-width: 45%;
    width: 45%;
    min-height: 40%;
  }
`;

export const Container = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
`;

export const WorkspaceSelected = styled(RowContainer)`
  text-decoration: none;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 2px ${(p: IStyledProps) => p.theme.paddingSize()};
  cursor: pointer;
  align-items: center;
  height: 40px;
  max-width: 250px;
  overflow: hidden;
`;

export const WorkspaceSelectedColumn = styled(ColumnContainer)`
  padding-left: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
`;

export const WorkspaceSelectedLabel = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  line-height: 1.2;
  padding-bottom: 1px;
`;

export const WorkspaceSelectedValue = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
`;

export const ActiveIndicator = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(p: IStyledProps) => (p.isActive ? p.theme.colors.green1 : p.theme.colors.grey2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
const FontIconWrapper = styled.div`
  align-self: center;
  max-width: 30px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0 12px;
`;

export const DownIconWrapper = styled(FontIconWrapper)`
  margin-right: 0;
`;
export const ModalCta = styled(Button)`
  width: 40%;
`;
export const SelectProductCta = styled(Button)``;

export const ModalText = styled(TextLabel)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const BoldText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)} 0;
`;
export const ButtonRow = styled(RowContainer)`
  justify-content: space-between;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
