import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  isDragActive?: boolean;
  size?: number;
  theme: ITheme;
}

export const DropzoneContainer = styled(ColumnContainer)<IStyledProps>`
  width: 100%;
  height: min-content;
  overflow: hidden;
  position: relative;
  outline: none;

  .drag-illustration {
    display: ${(p: IStyledProps) => (p.isDragActive ? 'flex' : 'none')};
  }
`;

export const DragOverlay = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white.replace('1)', '0.85)')};
  align-items: center;
  justify-content: center;
`;

export const IllustrationContainer = styled(ColumnContainer)``;

export const IllustrationImage = styled.img<IStyledProps>`
  width: ${(p: IStyledProps) => p.size || 100}px;
  height: ${(p: IStyledProps) => p.size || 100}px;
  object-fit: contain;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-self: center;
`;

export const TextDrag = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: center;
`;
