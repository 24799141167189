import {
  __,
  constants,
  modalActions,
  notificationsActions,
  productActions,
  productService,
  RenderTrack,
  utils,
} from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import { CONSENTIO_FORMAT_URL } from '../../../constants';
import Layout from '../../../layout-flex';
import { ExpansionPanel } from '../../atoms';
import * as S from './ProductsImport.styled';

export interface IStateProps {
  catalogId: number;
  hasPrices: boolean;
  prodTypes: { [key: string]: IProdType };
}

export interface IDispatchProps {
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  productsBulkImport: typeof productActions.productsBulkImport;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps<{}>;

interface IState {
  file?: File;
  importErrors?: Array<IImportError>;
  replace: boolean;
}

export default class ProductsImport extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      replace: false,
    };
  }

  public componentDidMount() {
    RenderTrack.track('ProductsImport', { renderTime: this.t });
  }

  public render() {
    const { history, match, location } = this.props;
    const { importErrors } = this.state;
    return (
      <Layout
        header={{
          show: true,
          title: __('Components.ProductsList.Tabs.products'),
          breadcrumbs: [
            { label: __('Components.ProductsList.Tabs.products'), action: () => history.goBack() },
            { label: __('Components.ProductsImport.subtitle') },
          ],
          tabSelected: 'products',
        }}
      >
        <S.Container>
          {importErrors?.length ? <S.HeaderRibbon type="warning" text={this.getRibbonText()} /> : null}
          <S.ScrollContainer>
            <S.FormContainer>
              {this.renderTitle()}
              {this.renderDropzone()}
              {this.renderCheckbox()}
              {this.renderUploadButton()}
            </S.FormContainer>
            {importErrors?.length ? (
              <S.FormContainer id="products-import-form-container">{this.renderIssues()}</S.FormContainer>
            ) : null}
          </S.ScrollContainer>
        </S.Container>
      </Layout>
    );
  }

  private getRibbonText = () => {
    return (
      <S.Text>
        {
          utils.formatText(__('Components.ProductsImport.Issues.ribbon'), s => (
            <S.TextLink
              key={s}
              onClick={() => {
                const el = document.getElementById('products-import-form-container');
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {s}
            </S.TextLink>
          )) as any // TYPEERROR
        }
      </S.Text>
    );
  };

  private renderTitle() {
    return (
      <React.Fragment>
        <S.Title>{__('Components.ProductsImport.Header.title')}</S.Title>
        <S.Subtitle>
          {__('Components.ProductsImport.Header.subtitle')}{' '}
          <S.TextLink href={CONSENTIO_FORMAT_URL} target="blank">
            {__('Components.ProductsImport.Header.more_info')}
          </S.TextLink>
        </S.Subtitle>
      </React.Fragment>
    );
  }

  private renderUploadButton() {
    const { file } = this.state;
    return (
      <S.ButtonUpload disabled={!file} onClick={() => this.bulkUpload()}>
        {__('Components.ProductsImport.UploadButton')}
      </S.ButtonUpload>
    );
  }

  private renderCheckbox() {
    const { hasPrices } = this.props;
    const { replace } = this.state;
    return hasPrices ? (
      <S.CheckboxRow>
        <S.CheckBox isChecked={replace} onClick={() => this.setState({ replace: !replace })} />
        <S.CheckBoxLabel onClick={() => this.setState({ replace: !replace })}>
          {__('Components.ProductsImport.Checkbox.text')}
        </S.CheckBoxLabel>
      </S.CheckboxRow>
    ) : null;
  }

  /**
   * bulkUpload manages the upload of csvs into rtapi
   * if successful, shows a notification
   */
  private bulkUpload = () => {
    const { productsBulkImport, catalogId, history, modalClose, modalOpen, notificationShow } = this.props;
    const { file, replace } = this.state;

    const reader = new FileReader();
    reader.onload = () => {
      productsBulkImport(
        reader.result as string,
        catalogId,
        replace,
        (data?: { products: Array<IProduct>; errors: Array<IImportError> }, error?: Error) => {
          if (error) return;
          if (data?.errors?.length > 0) {
            this.setState({ importErrors: data.errors, file: undefined });
          } else {
            history.goBack();
            notificationShow(
              {
                title: __('Components.ProductsImport.Notification.title'),
                subtitle: __('Components.ProductsImport.Notification.subtitle'),
                closable: true,
                style: 'success',
              },
              8000,
            );
          }
        },
      );
    };
    reader.onerror = err => modalOpen(__('Components.Settings.error', { error: err }), () => modalClose());
    reader.readAsText(file);
  };

  private renderDropzone() {
    const { file } = this.state;
    return (
      <Dropzone accept={'.csv'} multiple={false} onDrop={this.getFile}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input type="file" multiple={false} accept={'.csv'} {...getInputProps()} />
              <S.UploadFileIcon name="Upload" />
              <S.TextUpload>
                {__('Components.ProductsImport.Dropzone.subtitle')}{' '}
                {file ? (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('Components.ProductsImport.FileDescription.file_count')}</S.TextUploadGreen>
                  </>
                ) : null}
              </S.TextUpload>
              <S.TextCenter>{__('Components.ProductsImport.Dropzone.text')}</S.TextCenter>
              <S.SelectButton type={file ? 'secondary' : 'principal'} disabled={false}>
                {file
                  ? __('Components.ProductsImport.Dropzone.change')
                  : __('Components.ProductsImport.Dropzone.select')}
              </S.SelectButton>
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
    );
  }

  /**
   * Render import errors found in the file
   */
  private renderIssues() {
    const { importErrors } = this.state;
    return (
      <React.Fragment>
        <S.IssuesHeader>
          <S.Title>{__('Components.ProductsImport.Issues.title')}</S.Title>
          <S.WarningIcon name="Warning" disableHover={true} />
        </S.IssuesHeader>
        {/* <S.Subtitle>
          {__('Components.ProductsImport.Issues.subtitle') + ' '}
          <S.TextLink
            onClick={() => {
              window.open(
                userService.getConsentioUniversityLink(false, i18n.default.currentLocale() as LOCALE),
                '_blank',
              );
              EventTrack.track('products_import_help_click');
            }}
          >
            {__('Components.ProductsImport.Issues.subtitle_link')}
          </S.TextLink>
          </S.Subtitle> */}
        <S.IssuesColumn>
          {importErrors.map((error, idx) => {
            const errorLiteral = constants.getImportErrorLiteral(error);
            const { lines } = error;
            return (
              <ExpansionPanel
                key={idx}
                title={errorLiteral + ` (${lines.length})`}
                iconPosition="left"
                defaultExpanded={idx === 0}
                titleWithLeftPadding={false}
              >
                <S.LinesContainer>
                  {lines.map((line, i) => {
                    const { line: lineNumber, extraData } = line;
                    const sku = (extraData as IImportErrorProductExtraData)?.sku || '';
                    const eanCode = (extraData as IImportErrorProductExtraData)?.ean_code || '';
                    const type = (extraData as IImportErrorProductExtraData)?.type || '';
                    const variety = (extraData as IImportErrorProductExtraData)?.variety || '';
                    const hasInfo = sku || eanCode || type;

                    return (
                      <S.IssueLabel key={lineNumber + '_' + i}>
                        <>
                          {lineNumber > 0
                            ? __('ImportErrors.line', { line: lineNumber }) + (hasInfo ? ', ' : '')
                            : null}
                          {hasInfo
                            ? utils.formatText(this.getProductRelatedInfo(sku, eanCode, type, variety), (s, index) => (
                                <S.IssueTextBold key={s + '_' + index}>{s}</S.IssueTextBold>
                              ))
                            : null}
                          {error.errorType === 'other' && error.message ? ', ' + error.message : null}
                        </>
                      </S.IssueLabel>
                    );
                  })}
                </S.LinesContainer>
              </ExpansionPanel>
            );
          })}
        </S.IssuesColumn>
      </React.Fragment>
    );
  }

  /**
   * Get info abour the product related to the error
   */
  private getProductRelatedInfo = (sku: string, eanCode: string, type: string, variety: string) => {
    const { prodTypes } = this.props;
    if (sku) return __('Components.ProductsImport.Issues.product_sku', { sku });
    if (eanCode) return __('Components.ProductsImport.Issues.product_ean', { eanCode });
    if (type) {
      const typeVariety = productService.getProductTypeVarietyDisplay(
        type,
        prodTypes[type] ? prodTypes[type].name : '',
        variety,
      );
      return __('Components.ProductsImport.Issues.product_type', { typeVariety });
    }
    return '';
  };

  /**
   * handle upload .csv file of products
   */
  private getFile = (files: Array<File>) => {
    if (files && files.length > 0) {
      this.setState({ file: files[0] });
    } else {
      const { notificationShow } = this.props;
      notificationShow(
        {
          title: __('ProductEdit.additionalFiles.format_error.title'),
          subtitle: __('ProductEdit.additionalFiles.format_error.description', {
            formats: '.csv',
          }),
          closable: true,
          style: 'error',
        },
        6000,
      );
    }
  };
}
