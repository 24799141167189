import * as React from 'react';

import * as S from './WorkspaceAvatar.styled';

export interface IProps {
  className?: string;
  catalog: IWorkspace;
  size?: number;
  showActiveIcon?: boolean;
  isActive?: boolean;
}

const WorkspaceAvatar: React.FC<IProps> = ({ catalog, className, isActive, size = 30, showActiveIcon }) => {
  const workspaceImage = catalog?.companyImage || catalog?.companyLogo;
  return (
    <S.Container className={className} timestamp={catalog?.createdAt} hasImage={!!workspaceImage} size={size}>
      {workspaceImage ? (
        <S.Image src={workspaceImage} size={size} />
      ) : (
        <S.TeamIcon name="Team-icon" disableHover={true} size={size} />
      )}
      {showActiveIcon ? <S.ActiveIndicator isActive={isActive} size={size} /> : null}
    </S.Container>
  );
};

export default React.memo(WorkspaceAvatar);
