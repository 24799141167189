import * as React from 'react';

import * as S from './Scroll.styled';

export interface IProps {
  children: any;
  id?: string;
  loadMore: () => void;
}

export default class Scroll extends React.PureComponent<IProps> {
  private scrollLoadThreshold = 600;
  private scrollable: HTMLDivElement;
  private scrollTop: number;
  private scrollHeight: number;

  constructor(public props: IProps) {
    super(props);
  }

  public componentDidMount() {
    const heightDifference = this.scrollable.scrollHeight - this.scrollable.clientHeight;
    this.scrollable.scrollTop = heightDifference;
    this.scrollTop = heightDifference;
    this.scrollable.addEventListener('scroll', this.onScroll, { passive: true });
  }

  public componentDidUpdate(nextP: IProps) {
    this.updateScrollTop();
  }

  public componentWillUnmount() {
    this.scrollable.removeEventListener('scroll', this.onScroll);
  }

  public render() {
    const { id, children } = this.props;
    return (
      <S.Container
        id={id}
        ref={e => {
          this.scrollable = e!;
        }}
      >
        {children}
      </S.Container>
    );
  }

  /**
   * when the user scrolling to top call to load more results.
   */
  private onScroll = () => {
    if (this.scrollable && this.scrollable.scrollTop !== this.scrollTop) {
      const { scrollTop } = this.scrollable;
      if (scrollTop < this.scrollLoadThreshold) {
        this.props.loadMore();
      }
    }
  };

  /**
   * on change the size of the scroll update the current scroll position.
   */
  private updateScrollTop() {
    let newScrollTop = this.scrollable.scrollTop + this.scrollable.scrollHeight - (this.scrollHeight || 0);

    const scrollHeightDifference = this.scrollHeight ? this.scrollHeight - this.scrollable.scrollHeight : 0;

    if (scrollHeightDifference > 0) {
      newScrollTop += scrollHeightDifference;
    }

    if (newScrollTop !== this.scrollable.scrollTop) {
      this.scrollable.scrollTop = newScrollTop;
    }

    this.scrollTop = this.scrollable.scrollTop;
    this.scrollHeight = this.scrollable.scrollHeight;
  }
}
