import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TagBubble, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  isFirst?: boolean;
}

export const Container = styled.div`
  width: 100%;
  -webkit-overflow-scrolling: touch;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const ZeroCaseContainer = styled(ColumnContainer)`
  width: 100%;
  align-items: left;
  justify-content: center;
  position: relative;
`;

export const ZeroCaseText = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.8;
`;

export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: auto;
  padding-bottom: 0;
`;

export const AddressRow = styled(RowContainer)<IStyledProps>`
  width: 100%;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-left: none;
  border-right: none;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)} 0;
  ${(p: IStyledProps) =>
    !p.isFirst &&
    css`
      border-top: none;
    `}
  justify-content: space-between;
  cursor: pointer;
`;

export const AddressInfoCol = styled(ColumnContainer)``;

export const TextTitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextGrey2 = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const AddressIconContainer = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  width: 23px;
  height: 23px;
  border-radius: 3px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: center;
`;

export const AddressIcon = styled(FontIcon)`
  width: 15px;
  height: 15px;
  font-size: 15px;
  line-height: 15px;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
`;

export const KebabIconContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: center;
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const RowCenter = styled(RowContainer)`
  align-items: center;
`;

export const TagDefault = styled(TagBubble)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;
