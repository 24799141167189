import * as React from 'react';
import { Provider } from 'react-redux';

import appStart from './app/hooks/appStart';
import Routes from './app/routes';
import Alert from './app/screens/alert';
import GlobalModal from './app/screens/global-modal';
import Notification from './app/screens/notification';
import { trackQueryParams } from './app/services/analytics';
import configureStore from './app/store';
import { ThemeProvider } from './app/styled-components';
import theme from './app/theme';
const { store } = configureStore();
appStart(store);

const App: React.FC = () => {
  React.useEffect(() => {
    trackQueryParams();
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Routes />
          <GlobalModal />
          <Notification />
          <Alert />
        </React.Fragment>
      </ThemeProvider>
    </Provider>
  );
};
export default React.memo(App);
