import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  color?: string;
  image?: string;
  isProduct?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => (p.isProduct ? p.theme.colors.grey4 : p.theme.colors.white)};
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  overflow: hidden;
  align-items: center;
`;

export const ModalContainer = styled(Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  z-index: 2;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Content = styled(ColumnContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l};
  width: min(${(p: IStyledProps) => p.theme.sizes.desktop_l}, 100vw);
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  overflow: hidden;
`;
export const CartContent = styled(Content)`
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: none;
  }
`;
export const MobileContainer = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: none;
  overflow-y: auto;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
  }
`;

export const MobileHeader = styled(ColumnContainer)`
  position: relative;
  width: 100%;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  display: none;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
  }
`;
export const MobileItems = styled(ColumnContainer)`
  flex: 1;
`;
export const ButtonMobile = styled(Button)`
  width: 100%;
`;
export const ButtonMobileContainer = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  width: 100%;
  display: none;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: flex;
  }
`;

export const SubHeaderRow = styled(RowContainer)`
  align-items: center;
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: space-between;
`;

export const CompanyRow = styled(RowContainer)`
  width: auto;
  align-items: center;
`;

export const KebabIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const NameMobile = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: calc(${(p: IStyledProps) => p.theme.paddingView} / 2) ${(p: IStyledProps) => p.theme.paddingObject};
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingObject};
  right: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const ImageLogo = styled.img`
  align-self: center;
  object-fit: cover;
  width: 30px;
  height: 30px;
`;

export const HeaderRow = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => (p.isProduct ? p.theme.paddingSize(2) : 0)};
  width: 100%;
  position: relative;
  justify-content: space-between;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    flex-direction: column;
    padding-top: ${(p: IStyledProps) => (p.isProduct ? p.theme.paddingSize(2) : p.theme.paddingSize(5))};
  }
`;

export const Body = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const RowGroup = styled(RowContainer)`
  max-width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  margin: -${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const RowWrapper = styled(RowContainer)`
  max-width: 100%;
  position: relative;
  overflow-x: auto;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin: -${(p: IStyledProps) => p.theme.paddingSize(2)} -${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const Avatar = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-image: url(${(p: IStyledProps) => p.image});
`;

export const ArrowBackWrapper = styled(RowContainer)`
  z-index: 1;
  position: absolute;
  left: 9px;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const ArrowBack = styled(FontIcon)`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 27px;
  height: 25px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;

export const ArrowForwardWrapper = styled(ArrowBackWrapper)`
  left: auto;
  right: 0;
`;

export const ArrowForward = styled(ArrowBack)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const RowWraper = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  margin: -${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  width: max-content;
`;

export const PriceText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const ResumePrice = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TotalResumePrice = styled(ResumePrice)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const CardItem = styled(RowContainer)`
  width: 100%;
`;

export const Td = styled.td`
  vertical-align: middle;
  padding-top: ${(p: IStyledProps) => p.theme.paddingObject};
  text-align: left;
`;

export const Th = styled.th`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  text-align: left;
`;

export const ProductTh = styled(Th)``;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const UpperTh = styled(Th)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const PriceTh = styled(UpperTh)`
  text-align: left;
`;

export const TotalTh = styled(UpperTh)`
  text-align: right;
`;

export const CartRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
`;

export const CartWrapper = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} 0;
`;

export const CartTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const CartSubtitle = styled(CartTitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const RegisterTextHiglight = styled(CartSubtitle)`
  display: inline;
`;

export const RegisterText = styled(CartSubtitle)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const RegisterButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  width: 100%;
`;
export const StoreButton = styled.img`
  width: 128px;
  align-self: center;
  transition: all 0.6s;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  cursor: pointer;
  &:hover {
    box-shadow: none;
    opacity: 0.75;
  }
  &:active {
    transform: scale(0.95);
  }
`;
export const ConsentioLogo = styled(StoreButton)`
  box-shadow: none;
  width: 95px;
  &:hover {
    box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
    opacity: 0.75;
  }
`;
export const FooterContainer = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-self: center;
`;

export const TextFooter = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  text-align: center;
`;

export const RegisterAvatar = styled(Avatar)`
  height: 100px;
  width: 100px;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const EmptyCartButton = styled(Button)``;

export const TextBlack = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
`;

export const TextBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const Link = styled(Button)`
  display: inline-block;
`;
