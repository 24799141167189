import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  theme: ITheme;
}
export const Container = styled(RowContainer)`
  align-items: center;
`;
export const TextError = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
`;
export const Link = styled(TextError)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  text-align: undefined;
`;
export const Icon = styled(FontIcon)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  line-height: 1.2;
`;
