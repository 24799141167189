import { ORDER_STATUS } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import {
  ColumnContainer,
  FontIcon,
  IOSLoading,
  ProgressBar as PB,
  RowContainer,
  TagBubble,
  TextLabel,
  Tooltip,
} from '../../atoms';

interface IStyledProps {
  isDragActive?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  paddingBottom?: string;
  showImportJobs?: boolean;
  theme: ITheme;
  width?: string;
}

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TextSmall = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const TextRedSmall = styled(TextSmall)`
  color: ${(p: IStyledProps) => p.theme.colors.red1};
`;

export const TextJobDescription = styled(TextSmall)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextGreen = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const FormatLogoContainer = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
  min-height: min-content;
`;

export const OrderFormatLogoSmall = styled.img`
  object-fit: cover;
  width: 38px;
  height: 38px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const TextBlue = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const TextBold = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: inherit;
  display: inline;
`;

export const TextFileLink = styled(TextBold)`
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  &:hover {
    opacity: 0.8;
  }
`;

export const ImportJobContainer = styled(ColumnContainer)`
  min-height: min-content;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ImportJobHeader = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  min-height: min-content;
`;

export const FilesContainer = styled(ColumnContainer)`
  min-height: min-content;
`;

export const FileRow = styled(RowContainer)`
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-height: min-content;
`;

export const DownloadIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: 6px;
  cursor: pointer;
`;

export const SpinnerLoading = styled(IOSLoading)`
  transform: scale(0.4);
  margin: -${(p: IStyledProps) => p.theme.paddingSize()};
  margin-left: -${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FailedIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SuccessIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-size: 20px;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ProgressBar = styled(PB)`
  max-width: 230px;
`;
interface IStyledProps {
  checked?: boolean;
  isRead?: boolean;
  isSelected?: boolean;
  selectable?: boolean;
  status?: ORDER_STATUS;
  theme: ITheme;
}

export const Container = styled.div`
  width: 100%;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
`;

export const Table = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const Header = styled.tr`
  td {
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const Body = styled.tbody`
  height: 100%;
`;

export const TextHeader = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: inherit;
`;

export const OrderTr = styled.tr`
  height: 72px;
  max-height: 72px;
  background-color: ${(p: IStyledProps) => (p.isRead ? p.theme.colors.grey4 : p.theme.colors.white)};
  cursor: pointer;
  overflow: hidden;
  td {
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  & .read {
    display: none;
  }
  &:hover {
    & .read {
      display: block;
      float: right;
    }
  }
  > td {
    max-height: 72px;
  }
`;

const Col = styled.td`
  min-width: 70px;
  max-width: 170px;
  vertical-align: middle;
  padding: 5px 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const HashCol = styled(Col)`
  width: 80px;
  max-width: 80px;
`;

export const StatusCol = styled(Col)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  width: 110px;
`;

export const TimeCol = styled(Col)`
  width: 110px;
`;

export const ArticlesCol = styled(Col)`
  width: 80px;
`;

export const DeliveryCol = styled(Col)`
  width: 150px;
`;

export const PriceCol = styled(Col)`
  width: 120px;
  text-align: right;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};

  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(9)};
  }
`;

export const CreatedCol = styled(Col)`
  width: 100px;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
`;

export const TextGrey = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const TextSecondary = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Light : p.theme.fontWeight.Regular)};
`;

export const TextIssue = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.blue1)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const FlexColumn = styled(ColumnContainer)``;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
`;
export const ColleagueCol = styled(Col)`
  width: 150px;
`;

export const PriceFlexRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;
export const AmountFlexRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
`;

export const PriceText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: right;
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TooltipPrice = styled(Tooltip)``;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
