import styled, { ITheme } from '../../../../../styled-components';
import { RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
}

export const SearchContainer = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingView} 0;
  max-width: 400px;
  min-height: min-content;
`;
