import { constants, modalActions, sellerWorkspaceService, userSelectors } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProductTable } from '../../../../molecules';
import * as S from './ProductTable.styled';

interface IProps {
  allSelected?: boolean;
  catalog: IWorkspace;
  className?: string;
  excludedProducts?: Array<number>;
  onSelectProduct: (product: IProduct) => void;
  prices: { [key: number]: IPrice };
  products: Array<any>;
  selectedProducts?: Array<number>;
  selectMode?: 'integration' | 'multiple' | 'offers';
}

const ProductTableWrapper: React.FC<IProps> = ({
  allSelected,
  catalog,
  className,
  excludedProducts,
  onSelectProduct,
  prices,
  products,
  selectedProducts,
  selectMode,
}) => {
  const me = useSelector(userSelectors.getUser);
  const dispatch = useDispatch<any>();
  const touchImage = React.useCallback(
    (images: Array<ImageGalleryObject>, selected?: number) => dispatch(modalActions.touchImage(images, selected)),
    [dispatch],
  );

  return (
    <S.Container className={className}>
      <ProductTable
        catalog={catalog}
        configId="product_list_modal"
        disabled={true}
        excludedProducts={excludedProducts}
        me={me}
        onSelectProduct={onSelectProduct}
        precision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
        prices={prices}
        products={products}
        role={sellerWorkspaceService.getRole(catalog, me.id)}
        selectedProducts={allSelected ? 'all' : selectedProducts}
        selectMode={selectMode}
        showCustomColumns={true}
        touchImage={touchImage}
      />
    </S.Container>
  );
};

export default React.memo(ProductTableWrapper);
