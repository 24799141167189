import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}
export const Container = styled.div`
  flex: 1;
  flex-direction: column;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  display: flex;
  justify-content: flex-start;
  position: relative;
`;
