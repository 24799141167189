import * as React from 'react';

import { FontIcon } from '../../atoms';
import * as S from './EmptyAddress.styled';

interface IProps {
  text: string;
  text2?: string;
  buttonAction?: () => void;
  buttonText?: string;
  showButton?: boolean;
  className?: string;
  deliveryMethod?: 'pickup' | 'shipping';
}

/**
 * Placeholder to use when an address list is empty. Show icon, explanation + action to do.
 */
const EmptyAddress: React.FC<IProps> = ({
  text = '',
  text2 = '',
  buttonAction = () => null,
  buttonText = '',
  showButton = true,
  deliveryMethod,
}) => {
  return (
    <S.EmptyAddressContainer>
      <S.FontIconWrapper>
        <FontIcon
          name={deliveryMethod && deliveryMethod === 'shipping' ? 'Pickup-truck' : 'Pickup-package'}
          disableHover={true}
        />
      </S.FontIconWrapper>
      <S.Text>{text}</S.Text>
      {text2 ? <S.Text>{text2}</S.Text> : null}
      {showButton ? (
        <S.AddMoreCta iconName={'Add-more'} type="link" onClick={buttonAction} withoutPadding>
          {buttonText}
        </S.AddMoreCta>
      ) : null}
    </S.EmptyAddressContainer>
  );
};

export default React.memo(EmptyAddress);
