import { Api, modalActions, productActions } from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Athina, { IDispatchProps, IStateProps } from '../components/organisms/Athina';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: RouteComponentProps): IStateProps {
  const me = state.user.user!;

  return {
    me,
    countries: state.country.countries,
    prodTypes: state.prodType.prodTypes,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      analyzeProduct: productActions.analyzeProduct,
      modalOpen: modalActions.modalOpen,
      modalClose: modalActions.modalClose,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Athina);
