import { __, chatActions, contactActions, INVITE_ORIGIN, IUser, modalActions, VIA } from 'common-services';
import * as React from 'react';

import { ChatFooter } from '../../../../molecules';
import * as S from './PrivateChatFooter.styled';

interface IProps {
  acceptOnlyImages?: boolean;
  channel: IChannel;
  contact: IContact;
  contacts: Record<number, IContact>;
  contactsInvite: typeof contactActions.contactsInvite;
  draftText?: string;
  handleSendFile?: (files: Array<File>) => void;
  imBlocking?: boolean;
  isUnregistered?: boolean;
  me: IUser;
  modalOpen: typeof modalActions.modalOpen;
  name?: string;
  onTextInputRef?: (ref: HTMLTextAreaElement | null) => void;
  replyFromSenderAvatar?: string;
  replyFromSenderAvatarColor?: IAvatarColor;
  replyFromSenderName?: string;
  replyToMessage?: IMessage;
  sendMessage: (
    channelId: string,
    text: string,
    isFile?: boolean,
    file?: IFile,
    replyToMessage?: IMessage,
    mentions?: Array<number>,
  ) => void;
  setMessageDraft: typeof chatActions.setMessageDraft;
  setReplyToMessage?: (message?: IMessage) => void;
  unblock?: () => void;
}

export default React.memo(
  ({
    acceptOnlyImages,
    channel,
    contact,
    contacts,
    contactsInvite,
    draftText,
    handleSendFile,
    imBlocking,
    isUnregistered,
    me,
    modalOpen,
    name,
    onTextInputRef,
    replyFromSenderAvatar,
    replyFromSenderAvatarColor,
    replyFromSenderName,
    replyToMessage,
    sendMessage,
    setMessageDraft,
    setReplyToMessage,
    unblock,
  }: IProps) => {
    const [invited, setInvited] = React.useState<boolean>(false);
    const resendInvite = React.useCallback(
      (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        if (contactsInvite) {
          contactsInvite(
            me.id,
            contact.email ? VIA.EMAIL : VIA.SMS,
            'web',
            INVITE_ORIGIN.REGULAR,
            contact.email
              ? [{ name: contact.name || contact.email, email: contact.email, phone: '', counterpart_id: '' }]
              : [
                  {
                    name: contact.name || contact.phoneNumbers[0],
                    email: '',
                    phone: contact.phoneNumbers[0],
                    counterpart_id: '',
                  },
                ],
          );
          setInvited(true);
        }
      },
      [me, contact, setInvited, contactsInvite],
    );

    if (imBlocking)
      return (
        <S.Container>
          <S.Message>
            {__('Components.Chat.imBlocking', { name })}
            <S.MessageLink onClick={unblock}>{__('Components.Chat.imBlockingLink', { name })}</S.MessageLink>
          </S.Message>
        </S.Container>
      );
    if (isUnregistered)
      return (
        <S.Container>
          <S.Message>
            {__('Components.Chat.non_register', { name: name || '' })}
            {invited ? (
              <S.Message>{__('Components.Chat.invite_sent')}</S.Message>
            ) : (
              <S.MessageLink onClick={e => resendInvite(e)}>
                {__('Components.Chat.resend_invite', { name })}
              </S.MessageLink>
            )}
          </S.Message>
        </S.Container>
      );
    return (
      <ChatFooter
        acceptOnlyImages={acceptOnlyImages}
        channel={channel}
        contacts={contacts}
        draftText={draftText}
        handleSendFile={handleSendFile}
        id={channel.id}
        me={me}
        modalOpen={modalOpen}
        name={name}
        onTextInputRef={onTextInputRef}
        replyFromSenderAvatar={replyFromSenderAvatar}
        replyFromSenderAvatarColor={replyFromSenderAvatarColor}
        replyFromSenderName={replyFromSenderName}
        replyToMessage={replyToMessage}
        sendMessage={sendMessage}
        setMessageDraft={setMessageDraft}
        setReplyToMessage={setReplyToMessage}
      />
    );
  },
);
