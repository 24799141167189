import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const Text = styled(TextLabel)`
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextGrey = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const LinkAdd = styled(SimpleDropdown)``;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const ClientNameColumn = styled(ColumnContainer)`
  min-width: 85px;
`;

export const ClientImagesRow = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const SearchContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  min-width: 450px;
`;

export const ShowProducts = styled(Button)`
  float: right;
`;

export const AddContact = styled(Button)`
  float: right;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Stats = styled(TextLabel)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
