import { utils } from 'common-services';
import * as React from 'react';

import { isRequiredValidation } from '../../../util/validations';
import { IFontIconKeys } from '../FontIcon';
import * as S from './Input.styled';

export interface IProps {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  containerMargin?: string;
  dataSelector?: string;
  disabled?: boolean;
  errorMessage?: string | React.ReactNode;
  hasError?: boolean;
  iconUrl?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  id?: string;
  isRequired?: boolean;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  name: string;
  notEmpty?: boolean;
  onBlur?: (key: string, value: string | number | undefined, error?: string) => void;
  onChange?: (key: string, value: string | number, error?: string) => void;
  onChangeAutoComplete?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  precision?: number;
  startIcon?: IFontIconKeys;
  startText?: string;
  tabIndex?: number;
  textAlign?: 'left' | 'right' | 'center';
  trim?: boolean;
  type: 'text' | 'number' | 'password';
  value?: string | number;
  variableTextPlural?: string;
  variableTextSingular?: string;
  width?: string;
  zeroCase?: string;
  children?: React.ReactNode;
}

interface IState {
  hidePassword: boolean;
  value: string;
}

export default class Input extends React.PureComponent<IProps, IState> {
  public static defaultProps = {
    name: '',
    onChange: () => null,
    onBlur: () => null,
    placeholder: '',
    type: 'text',
    width: '',
    containerMargin: '4px 0',
    iconUrl: '',
    hasError: false,
    isRequired: false,
    maxLength: 50,
    precision: 0,
    disabled: false,
    minValue: 0,
    tabIndex: 0,
  };

  private defaultValueInput: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      hidePassword: true,
      value: this.parseNumberIfNeeded(props.value || '', props.type, props.precision!),
    };
    this.defaultValueInput =
      props.type === 'number'
        ? props.value || props.notEmpty
          ? Number(props.value).toFixed(props.precision)
          : ''
        : props.value || props.notEmpty
        ? (props.value as string)
        : '';
  }

  public componentDidUpdate(prevProps: IProps) {
    const { value, type, precision } = this.props;
    if (prevProps.value !== value) {
      this.setState({ value: this.parseNumberIfNeeded(value ? value : '', type, precision) });
    }
  }

  public componentDidMount() {
    // Check if the props.value is exactly 0, considering both `0` and `'0'`
    if (this.props.value === 0 || this.props.value === '0') {
      this.setState({ value: '0' }); // Set state.value to '0' as string, since your state.value is of type string
    }
  }

  public render() {
    const {
      autoComplete,
      autoFocus,
      className,
      containerMargin,
      dataSelector,
      hasError,
      errorMessage,
      iconUrl,
      id,
      inputRef,
      maxLength,
      name,
      onClick,
      onKeyDown,
      placeholder,
      disabled,
      startIcon,
      startText,
      tabIndex,
      textAlign,
      type,
      variableTextPlural,
      variableTextSingular,
      width,
      zeroCase,
      children,
    } = this.props;
    const { hidePassword, value } = this.state;
    let text = '';
    if (variableTextSingular && (!variableTextPlural || type !== 'number' || Number(value) === 1)) {
      text = variableTextSingular;
    } else if (variableTextPlural && type === 'number') {
      text = variableTextPlural;
    }

    if (disabled) {
      return (
        <S.DisabledWrapper width={width} margin={containerMargin} color="inherit" className={className}>
          {startText ? <S.Text>{startText}</S.Text> : null}
          <S.ReadOnly>{value || zeroCase || (type === 'number' ? 0 : '-')}</S.ReadOnly>
          {value || !zeroCase ? <S.TextEnd>{text ? text : null}</S.TextEnd> : null}
        </S.DisabledWrapper>
      );
    }

    return (
      <>
        <S.InputWrapper
          className={className}
          hasError={hasError}
          iconUrl={iconUrl}
          margin={containerMargin}
          width={width}
        >
          {startIcon ? <S.StartIcon name={startIcon} /> : null}
          {startText ? (
            <S.Text htmlFor={id || name} position="start" className="text_input">
              {startText}
            </S.Text>
          ) : null}
          <S.Input
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className="validate_input"
            data-cy={id || name}
            data-selector={dataSelector}
            id={id || name}
            name={name}
            onChange={e => this.handleChange(e)}
            ref={inputRef}
            maxLength={maxLength}
            onBlur={e => this.onBlur(value)}
            onClick={onClick}
            onFocus={() => {
              if (this.isZero(value)) {
                this.setState({ value: '' });
              }
            }}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            tabIndex={tabIndex}
            textAlign={textAlign}
            type={type === 'password' && hidePassword ? 'password' : 'text'}
            value={this.valueToShow()}
          />
          <S.TextEnd htmlFor={id || name} className="text_input" position="end">
            {text ? text : null}
          </S.TextEnd>
          <S.TextEnd>{children ? children : null}</S.TextEnd>
          {type === 'password' ? (
            <S.EyeIconContainer
              className="eye-icon-container"
              onClick={() => this.setState({ hidePassword: !hidePassword })}
            >
              <S.EyeIcon name={hidePassword ? 'Eye-no-see' : 'Eye-see'} />
            </S.EyeIconContainer>
          ) : null}
        </S.InputWrapper>
        {hasError && errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
      </>
    );
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const v = event.target.value;
    const { name, onChange, onChangeAutoComplete, type } = this.props;
    const { value } = this.state;

    if (type === 'number') {
      const sanitizedValue = v.replace(',', '.');
      if (!isNaN(Number(sanitizedValue))) {
        this.setState({ value: sanitizedValue });
        onChange!(name, sanitizedValue);
      } else {
        this.setState({ value });
      }
    } else {
      this.setState({ value: v });
      onChange!(name, v);
    }

    if (onChangeAutoComplete) {
      onChangeAutoComplete!(event);
    }
  }

  private onBlur(value: string) {
    const { name, type, trim } = this.props;
    let cleanValue = utils.cleanHTML(value);
    if (trim) {
      cleanValue = cleanValue.trim();
    }
    this.setState({ value: cleanValue });
    this.validateInput(cleanValue, true, (v: string, e?: string) => {
      this.props.onBlur!(name, type === 'number' && !isNaN(Number(v)) ? Number(v) : v, e);
    });
  }

  private validateInput(value: string, persist: boolean = false, cb?: (v: string, e?: string) => void) {
    const { type, isRequired } = this.props;
    let error = '';
    if (isRequired && isRequiredValidation(value)) {
      error = 'empty';
    } else if (type === 'number') {
      this.validateNumber(persist, cb);
    } else if (cb) cb(value, error);
  }

  private validateNumber(persist: boolean = false, cb?: (v?: string, e?: string) => void) {
    const { value } = this.state;
    const { maxValue, minValue, notEmpty, precision } = this.props;
    let val = this.defaultValueInput;
    const invalid = this.invalidNumber(Number(value));

    if (value && !invalid) {
      val = Number(value).toFixed(precision);
      this.defaultValueInput = val;
      if (cb) cb(val);
    } else if (invalid < 0 && (!minValue || minValue <= 0)) {
      val = minValue ? minValue.toString() : '0';
      this.defaultValueInput = val;
      if (cb) cb(val, 'min-error');
    } else if (invalid > 0 && maxValue) {
      val = maxValue.toString();
      this.defaultValueInput = val;
      if (cb) cb(val, 'max-error');
    } else if (!notEmpty) {
      val = '';
      if (cb) cb();
    }

    if (persist) {
      this.setState({ value: Number(val).toFixed(precision) });
    }
  }

  /**
   * Checks if the input's value is 0 or a "000.000" value type.
   */
  private isZero(valueInput: string) {
    const { precision } = this.props;
    return valueInput === '0' || new RegExp(`(^0+)(.?)(0{${precision},})$`).test(valueInput);
  }

  private parseNumberIfNeeded(
    val: string | number,
    type: 'text' | 'number' | 'password',
    precision: number = 0,
  ): string {
    if (type !== 'number') return val as string;
    const value = val ? Number(val).toFixed(precision) : '';
    const v = value.replace(',', '.');
    if (isNaN(Number(v))) return v || '';
    return v === '0' ? '' : v;
  }

  private invalidNumber(val: number) {
    const { maxValue, minValue } = this.props;
    if (minValue !== undefined && val < minValue) return -1;
    if (maxValue !== undefined && val > maxValue) return 1;
    return 0;
  }

  private valueToShow() {
    const { value } = this.state;
    const { minValue, type } = this.props;
    if (type !== 'number') return value;
    const isZero = this.isZero(value);
    if ((value && !isZero) || value === '') return value;
    return !minValue || (minValue < 1 && isZero) ? '0' : '';
  }
}
