import { __, constants, ISection, LOCALE } from 'common-services';
import * as React from 'react';

import { NO_TAG } from '../../../constants';
import { IMAGES } from '.././../../assets';
import * as S from './SectionCard.styled';

export interface IProps {
  amount: number;
  myLocale: LOCALE;
  onClick: () => void;
  section: ISection;
}

export const SectionCard: React.FC<IProps> = ({ amount, myLocale, section, onClick }) => {
  if (!section.imageUrl) {
    section.imageUrl = IMAGES.sectionZeroCase[Math.floor(Math.random() * IMAGES.sectionZeroCase.length)];
  }
  return (
    <S.SectionCard onClick={onClick}>
      <S.SectionImage
        imageUrl={(
          section.imageUrl || IMAGES.sectionDefaultCase[Math.floor(Math.random() * IMAGES.sectionDefaultCase.length)]
        ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
        relation={constants.IMAGE_RELATION.SECTION}
        relationFixed={true}
      />
      <S.SectionText>
        {section.tag === NO_TAG ? __('Showroom.no_tag') : section.translations[myLocale] || section.tag} ({amount})
      </S.SectionText>
    </S.SectionCard>
  );
};

export default React.memo(SectionCard);
