import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer } from '../../atoms';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
  padding?: string;
  textAlign?: string;
  fullWidth?: boolean;
}

export const Container = styled.div`
  margin: 0;
  position: relative;
  max-width: 800px;
  min-width: 400px;

  ${(p: IStyledProps) =>
    !p.fullWidth &&
    css`
      max-width: 400px;
      min-width: 400px;

      @media (max-width: ${p.theme.sizes.desktop_m}) {
        max-width: 350px;
        min-width: 350px;
      }

      @media (max-width: ${p.theme.sizes.ipad}) {
        max-width: 330px;
        min-width: 330px;
      }
    `}
`;

export const Card = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  min-width: 250px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextError = styled(TextLabel)`
  width: auto;
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  line-height: 1.2;
`;

export const TextErrorContainer = styled(ColumnContainer)`
  align-self: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextRegular = styled(TextLabel)`
  width: auto;
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.padding || '0'};
`;

export const TextLink = styled.a`
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-decoration-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Form = styled.form`
  width: 100%;
`;
