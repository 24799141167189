import * as React from 'react';
import { useSelector } from 'react-redux';
import { sellerWorkspaceService, userSelectors } from 'common-services';
import { RouteComponentProps } from 'react-router-dom';
import useReferenceComponent from '../../../../hooks/Workspaces/WorkspaceReference/useReferenceComponent';
import { navSelectors } from '../../../../selectors';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  referentialId?: string;
}>;

const ReferenceScreen: React.FC<IRouteProps> = props => {
  const me = useSelector(userSelectors.getUser);

  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);

  const [amEditor, setAmEditor] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (workspaceSelected && me) {
      const role = sellerWorkspaceService.getRole(workspaceSelected, me.id);
      setAmEditor(role !== 'viewer');
    }
  }, [workspaceSelected, me]);

  const ReferenceComponent = useReferenceComponent(amEditor, props);

  return <>{ReferenceComponent}</>;
};

export default ReferenceScreen;
