import {
  Api,
  modalActions,
  notificationsActions,
  productActions,
  productService,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from '../../bindings/config';
import WorkspacePriceGroupEdit, { IDispatchProps, IStateProps } from '../components/organisms/WorkspacePriceGroupEdit';
import { IReduxState } from '../reducers';

const emptyArray = [];
const emptyObject = {};

function mapStateToProps(
  state: IReduxState,
  props: RouteComponentProps<{ workspaceId?: string; priceGroupId?: string }>,
): IStateProps {
  const {
    contact: { inConsentio },
    catalog: { catalogs, clients, priceGroups },
    product: { prices },
    user: { user },
  } = state!;
  const catalogId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;
  const priceGroupId = decodeURIComponent(props.match.params.priceGroupId);
  const catalog = catalogs[catalogId];
  const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;

  return {
    catalogId,
    catalogs,
    clientsOld: clients[catalogId] || emptyArray,
    contacts: inConsentio,
    isQuoterMode,
    me: user,
    prices: prices[productService.getKey(catalogId, user.id!)] || emptyObject,
    priceGroups: Object.values(priceGroups[catalogId] || emptyObject),
    priceGroup: priceGroupId && priceGroups[catalogId] ? priceGroups[catalogId][priceGroupId] : undefined,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      getPrices: productActions.getPrices,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      priceGroupAddAllPrices: sellerWorkspaceActions.priceGroupAddAllPrices,
      priceGroupAddAllSearchPrices: sellerWorkspaceActions.priceGroupAddAllSearchPrices,
      priceGroupAddClients: sellerWorkspaceActions.priceGroupAddClients,
      priceGroupAddPrices: sellerWorkspaceActions.priceGroupAddPrices,
      priceGroupCreate: sellerWorkspaceActions.priceGroupCreate,
      priceGroupDelete: sellerWorkspaceActions.priceGroupDelete,
      priceGroupDeleteClient: sellerWorkspaceActions.priceGroupDeleteClient,
      priceGroupDeletePrice: sellerWorkspaceActions.priceGroupDeletePrice,
      priceGroupDeletePrices: sellerWorkspaceActions.priceGroupDeletePrices,
      priceGroupsGet: sellerWorkspaceActions.priceGroupsGet,
      priceGroupUpdate: sellerWorkspaceActions.priceGroupUpdate,
      priceGroupActivateRank: sellerWorkspaceActions.priceGroupActivateRank,
      priceGroupCheckRank: sellerWorkspaceActions.priceGroupCheckRank,
      notificationShow: notificationsActions.notificationShow,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePriceGroupEdit);
