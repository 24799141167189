import { __ } from 'common-services';
import * as React from 'react';

import * as S from './DashboardBanner.styled';

export interface IProps {
  onClick: () => void;
}

const DashboardBanner: React.FC<IProps> = React.memo(({ onClick }) => {
  return (
    <S.Container onClick={onClick}>
      <S.Header>
        <S.Title>
          {__('ConsentioProModal.dashboards.title')}
          <S.TitleBadge>Consentio Pro</S.TitleBadge>
        </S.Title>
        <S.SubTitle>{__('ConsentioProModal.dashboards.subtitle')}</S.SubTitle>
      </S.Header>

      <S.Benefits>
        <S.Benefit>
          <S.BenefitIcon disableHover={true} name="Chart" />
          <S.BenefitText>{__('ConsentioProModal.dashboards.benefit1Title')}</S.BenefitText>
        </S.Benefit>
        <S.Benefit>
          <S.BenefitIcon disableHover={true} name="Analysis" />
          <S.BenefitText>{__('ConsentioProModal.dashboards.benefit2Title')}</S.BenefitText>
        </S.Benefit>
        <S.Benefit>
          <S.BenefitIcon disableHover={true} name="Click" />
          <S.BenefitText>{__('ConsentioProModal.dashboards.benefit3Title')}</S.BenefitText>
        </S.Benefit>
      </S.Benefits>
      <S.CTA onClick={() => onClick()}>{__('ConsentioProModal.dashboards.moreInfo')}</S.CTA>
    </S.Container>
  );
});

export default DashboardBanner;
