import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';
import NavTabs from '../../molecules/NavigationTabs';

interface IStyledProps {
  amAdmin?: boolean;
  hasThumbnail?: boolean;
  showInviteButton?: boolean;
  theme: ITheme;
}

export const HeaderContainer = styled(ColumnContainer)`
  align-items: center;
  width: 100%;
  background: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const HeaderContent = styled(ColumnContainer)`
  width: 1100px;
  max-width: 1100px;
  align-self: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    max-width: 1000px;
    width: 1000px;
  }

  @media (max-width: 1500px) {
    max-width: 950px;
    width: 950px;
  }

  @media (max-width: 1350px) {
    max-width: 850px;
    width: 850px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 680px;
    width: 680px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 820px;
    width: 820px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const HeaderImageContainer = styled(RowContainer)<IStyledProps>`
  position: relative;
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.hasThumbnail ? 3 : 0)};
`;

export const HeaderImageWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    border-radius: 0;
  }
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: 284px;
  border-radius: 10px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    height: 258px;
  }

  @media (max-width: 1500px) {
    height: 244px;
  }

  @media (max-width: 1350px) {
    height: 219px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    height: 175px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    height: 211px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: auto;
    border-radius: 0;
  }
`;

export const HeaderThumbnailImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
  position: absolute;
  left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  bottom: 7%;
  border: 2px solid ${(p: IStyledProps) => p.theme.colors.white};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    width: 108px;
    height: 108px;
  }

  @media (max-width: 1350px) {
    width: 97px;
    height: 97px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 78px;
    height: 78px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: 94px;
    height: 94px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 70px;
    height: 70px;
    left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const HeaderInfo = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  justify-content: space-between;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const HeaderLeft = styled(ColumnContainer)``;

export const HeaderRight = styled.div<IStyledProps>`
  display: grid;
  grid-column-gap: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  grid-template-columns: fit-content(100px);
  grid-auto-columns: fit-content(100px);
  grid-auto-flow: column;
`;

export const NavigationTabsRow = styled(RowContainer)`
  min-width: 1052px;
  max-width: 1052px;
  align-self: center;
  align-items: center;

  div:first-child {
    margin-left: 0;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    max-width: 952px;
    min-width: 952px;
  }

  @media (max-width: 1500px) {
    max-width: 902px;
    min-width: 902px;
  }

  @media (max-width: 1350px) {
    max-width: 802px;
    min-width: 802px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 632px;
    min-width: 632px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 772px;
    min-width: 772px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const NavigationTabs = styled(NavTabs)`
  justify-content: center;
`;

export const TextChannelName = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  }
`;

export const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const HeaderButton = styled(Button)``;

export const WorldIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  display: inline-block;
  vertical-align: bottom;
`;

export const MuteDropdown = styled(SimpleDropdown)``;

export const MuteIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
`;
