import {PRODUCT_UNIT} from "common-services";
import {MINIMUM_ORDER_PRICE} from "common-services/dist/constants";

export function calculatePriceServedUnit(i: IOrderItem, boxesPerPallet: number) {
    switch (i.servedSaleUnit) {
        case PRODUCT_UNIT.BOX:
            return calculatePriceOfBoxes(i, i.servedQuantity, boxesPerPallet);
        case PRODUCT_UNIT.PALLET:
            return calculatePriceOfPallets(i, i.servedQuantity, boxesPerPallet);
        case PRODUCT_UNIT.PIECE:
            return calculatePriceOfPieces(i, i.servedQuantity, boxesPerPallet);
        case PRODUCT_UNIT.KG:
            return calculatePriceOfKgs(i, i.servedQuantity, boxesPerPallet);
        default:
            return 0;
    }
}
// These functions are similar to the ones in commons but can use multi boxes per pallet instead of item.boxesPerPAllet
function calculatePriceOfPallets(i: IOrderItem, numPallets: number, boxesPerPallet: number) {
    switch (i.priceUnit) {
        case PRODUCT_UNIT.PALLET:
            return numPallets * i.servedPrice;
        case PRODUCT_UNIT.KG:
            if (!i.boxWeight || !boxesPerPallet) return 0;
            return numPallets * i.servedPrice * i.boxWeight * boxesPerPallet;
        case PRODUCT_UNIT.PIECE:
            if (!boxesPerPallet || !i.piecesPerBox) return 0;
            return numPallets * i.servedPrice * i.piecesPerBox * boxesPerPallet;
        case PRODUCT_UNIT.BOX:
            if (!boxesPerPallet) return 0;
            return numPallets * i.servedPrice * boxesPerPallet;
        default:
            return 0;
    }
}
function calculatePriceOfBoxes(i: IOrderItem, numBoxes: number, boxesPerPallet: number) {
    switch (i.priceUnit) {
        case PRODUCT_UNIT.BOX:
            return numBoxes * i.servedPrice;
        case PRODUCT_UNIT.KG:
            if (!i.boxWeight) return 0;
            return numBoxes * i.servedPrice * i.boxWeight;
        case PRODUCT_UNIT.PIECE:
            if (!i.piecesPerBox) return 0;
            return numBoxes * i.servedPrice * i.piecesPerBox;
        case PRODUCT_UNIT.PALLET:
            if (!boxesPerPallet) return 0;
            return (numBoxes * i.servedPrice) / boxesPerPallet;
        default:
            return 0;
    }
}

function calculatePriceOfPieces(i: IOrderItem, numPieces: number, boxesPerPallet: number) {
    switch (i.priceUnit) {
        case PRODUCT_UNIT.PIECE:
            return numPieces * i.servedPrice;
        case PRODUCT_UNIT.KG:
            if (!i.boxWeight || !i.piecesPerBox) return 0;
            return (numPieces * i.servedPrice * i.boxWeight) / i.piecesPerBox;
        case PRODUCT_UNIT.BOX:
            if (!i.piecesPerBox) return 0;
            return (numPieces * i.servedPrice) / i.piecesPerBox;
        case PRODUCT_UNIT.PALLET:
            if (!boxesPerPallet || !i.piecesPerBox) return 0;
            return (numPieces * i.servedPrice) / (i.piecesPerBox! * boxesPerPallet);
        default:
            return 0;
    }
}
function calculatePriceOfKgs(i: IOrderItem, weight: number, boxesPerPallet: number) {
    switch (i.priceUnit) {
        case PRODUCT_UNIT.KG:
            return weight * i.servedPrice;
        case PRODUCT_UNIT.BOX:
            if (!i.boxWeight) return 0;
            return (weight * i.servedPrice) / i.boxWeight;
        case PRODUCT_UNIT.PIECE:
            if (!i.boxWeight || !i.piecesPerBox) return 0;
            return (weight * i.servedPrice * i.piecesPerBox) / i.boxWeight;
        case PRODUCT_UNIT.PALLET:
            if (!boxesPerPallet || !i.boxWeight) return 0;
            return (weight * i.servedPrice) / (i.boxWeight * boxesPerPallet);
        default:
            return 0;
    }
}
/**
 * return the total price of the order
 */
export function getTotalOrderPrice(order: IOrder, mandatoryPalletization: boolean): number {
    const discount = order.customItems && order.customItems.find(c => c.type === 'discount');
    const discountPrice = (discount && discount.price) || 0;
    const shipping = order.customItems && order.customItems.find(c => c.type === 'shipping');
    const shippingPrice = (shipping && shipping.price) || 0;
    const customItems = order.customItems && order.customItems.filter(c => c.type === 'custom');
    const customItemsPrice = (customItems || []).reduce((acc, customItem) => {
        acc += customItem.price;
        return acc;
    }, 0);
    if (order.items.find(i => i.isPor && i.servedQuantity > 0)) {
        return 0;
    }
    // totalItemsPrices is the price of each item using calculatePriceServedUnit(item)
    const totalItemsPrices = order.items.reduce((acc, item) => {
        let boxesPerPallet;
        if (mandatoryPalletization) {
            const boxesPerPalletValues = item.boxesPerPalletValues;
            const defaultValue = boxesPerPalletValues?.find(b => b.isDefault)?.value;
            const clientValue = (boxesPerPalletValues?.find(item => item.clientId === order.buyerId) || {}).value;
            boxesPerPallet = clientValue || defaultValue || boxesPerPalletValues[0]?.value;
        } else {
            boxesPerPallet = item.boxesPerPallet;
        }
        const price = calculatePriceServedUnit(item, boxesPerPallet);

        acc += price;
        return acc;
    }, 0);

    const totalPrice = totalItemsPrices + discountPrice + shippingPrice + customItemsPrice;
    return Math.max(MINIMUM_ORDER_PRICE, totalPrice);
}