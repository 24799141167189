import { __, constants, date, IAttachment, ImageGalleryObject, IOrder, IUser, utils } from 'common-services';
import { cropWithExtension, formatSize } from 'common-services/dist/utils/utils';
import * as React from 'react';

import { downloadFile } from '../../../../../services/file';
import * as S from './AttachmentMessage.styled';

interface IProps {
  attachment: IAttachment;
  senderName?: string;
  me: IUser;
  order: IOrder;
  orderAttachmentDownload: (myId: number, orderId: number, attachmentId: number, cb?: (data: string) => void) => void;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
}
const AttachmentMessage: React.FC<IProps> = ({
  attachment,
  senderName,
  me,
  order,
  orderAttachmentDownload,
  touchImage,
}) => {
  const { data, id, filename, mimeType, size, updatedAt, createdAt, thumbnail } = attachment;
  const isImage = mimeType.startsWith('image/') && !mimeType.startsWith('image/heic');
  const hourFormat = me.settings.hourFormat;
  let mimeTypeLogo: string = constants.getLogoFromMime(mimeType);
  if (isImage) mimeTypeLogo = `data:${mimeType};base64,${thumbnail || data}`;
  const attachmentDate = updatedAt || createdAt || Date.now();
  return (
    <S.AttachmentContainer>
      {mimeTypeLogo ? (
        <S.MimeTypeLogo
          src={mimeTypeLogo}
          isImage={isImage}
          onClick={() =>
            isImage &&
            orderAttachmentDownload(me.id, order.id, id, dataFile =>
              touchImage([{ src: `data:${mimeType};base64,${dataFile}` }], 0),
            )
          }
        />
      ) : mimeType.startsWith('image/') ? (
        <S.GalleryBigIcon name="Gallery" disableHover={true} />
      ) : (
        <S.DocumentIcon name="Document" disableHover={true} />
      )}
      <S.AttachmentInfoCol>
        <S.TextFile>{cropWithExtension(filename, 50)}</S.TextFile>
        <S.TextFileUpload>
          {__('Messages.Comments.uploaded_by', {
            name: senderName || '',
          }) +
            ' · ' +
            utils.capitalizeWords(date.formatDate(attachmentDate, 'EEEE, dd MMMM yyyy')) +
            ' · ' +
            date.formatTime(attachmentDate, hourFormat)}
        </S.TextFileUpload>
        <S.TextFileDescription>{formatSize(size) + ' · ' + filename.split('.').pop()}</S.TextFileDescription>
      </S.AttachmentInfoCol>
      {id || data ? (
        <S.DownloadIcon
          name="Download"
          onClick={() => {
            if (id) {
              orderAttachmentDownload(me.id, order.id, id, dataFile => downloadFile(dataFile, mimeType, filename));
            } else {
              downloadFile(data, mimeType, filename);
            }
          }}
        />
      ) : null}
    </S.AttachmentContainer>
  );
};

export default React.memo(AttachmentMessage);
