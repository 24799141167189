import { __ } from 'common-services';
import QRCode from 'qrcode.react';
import * as React from 'react';

import { CONSENTIO_LOGO_C } from '../../../../constants';
import { getDeepLink } from '../../../../services/link';
import theme from '../../../../theme';
import * as S from './QRCode.styled';

interface IProps {
  amOwner: boolean;
  contactName?: string;
  priceListUrl: string;
  productHashId?: string;
  productName?: string;
  url: string;
  isPublicUrl?: boolean;
}

export default ({ amOwner, contactName, isPublicUrl, priceListUrl, productHashId, productName, url }: IProps) => (
  <S.Container>
    <S.TextDescription>{__('ProductListShare.qr_description')}</S.TextDescription>
    <S.QRCodeContainer>
      <QRCode
        value={isPublicUrl ? url : getDeepLink(url)}
        bg={theme.colors.white}
        fg={theme.colors.black}
        size={200}
        className="qrcode"
        imageSettings={{ src: CONSENTIO_LOGO_C, height: 40, width: 40, excavate: true }}
      />
      <S.QRCodeFooter>
        <S.Text>{getQRCodeSubtitle(amOwner, productName || '', contactName)}</S.Text>
      </S.QRCodeFooter>
    </S.QRCodeContainer>
    <S.Actions onClick={() => downloadQRCodeImage(priceListUrl, productHashId)}>
      <S.DownloadIcon name="Download" />
      <S.Text>{__('ProductListShare.qr_download')}</S.Text>
    </S.Actions>
  </S.Container>
);

/**
 * Get subtitle text
 */
function getQRCodeSubtitle(amOwner: boolean, productName: string, contactName?: string) {
  if (productName) {
    return amOwner
      ? __('ProductListShare.own_product', { productName })
      : __('ProductListShare.other_product', { name: contactName, productName });
  } else {
    return amOwner ? __('ProductListShare.own_catalog') : __('ProductListShare.other_catalog', { name: contactName });
  }
}

/**
 * Download qrcode as image
 */
function downloadQRCodeImage(priceListUrl: string, productHashId: string) {
  const canvas: any = document.querySelector('.qrcode');
  const link = document.createElement('a');

  const filename = `qr-code-${encodeURIComponent(priceListUrl)}${productHashId ? '-' + productHashId : ''}.png`;

  link.href = canvas.toDataURL();
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  link.remove();
}
