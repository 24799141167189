import {
  __,
  buyerWorkspaceActions,
  constants,
  EventTrack,
  IContact,
  IGlobalWeb,
  imageActions,
  IUser,
  IWorkspace,
  IWorkspaceType,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import Layout from '../../../layout-flex';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { ColumnContainer, Picture } from '../../atoms';
import { ActionsModal, EmptyListResource, InputWithLabel, NavigationTabs, WorkspaceCard } from '../../molecules';
import * as S from './Workspaces.styled';

type ICatalogs = Record<number, IWorkspace>;

interface Tab {
  id: string;
  label: string;
  action: () => void;
}

declare var global: IGlobalWeb;

export const TAB_BUYER = 'tab_buyer';
export const TAB_SELLER = 'tab_seller';

export interface IStateProps {
  sellerWorkspaces: ICatalogs;
  buyerWorkspaces: ICatalogs;
  contacts: { [id: number]: IContact };
  me: IUser;
}

export interface IDispatchProps {
  buyerWorkspaceAssign: typeof buyerWorkspaceActions.workspaceAssign;
  clientsGet: typeof sellerWorkspaceActions.clientsGet;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  sellerWorkspaceAssign: typeof sellerWorkspaceActions.catalogAssign;
  notificationShow?: typeof notificationsActions.notificationShow;
  workspaceBuyerSelect: typeof navActions.workspaceBuyerSelect;
  workspaceSellerSelect: typeof navActions.workspaceSellerSelect;
  workspaceSellerChangeStatus: typeof sellerWorkspaceActions.workspaceChangeStatus;
  workspaceCreate: typeof sellerWorkspaceActions.workspaceCreate;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps<{}>;

export interface IState {
  selectedTab: typeof TAB_BUYER | typeof TAB_SELLER;
  showCreate?: boolean;
}

class Workspaces extends React.PureComponent<IProps, IState> {
  private timer: number;

  constructor(props: IProps) {
    super(props);
    this.timer = Date.now();

    const { match, me } = props;
    let selectedTab = me.settings.isSeller ? TAB_SELLER : TAB_BUYER;
    if (match.path.includes('workspaces-buyer') && me.settings.isBuyer) selectedTab = TAB_BUYER;
    if (match.path.includes('workspaces-seller') && me.settings.isSeller) selectedTab = TAB_SELLER;
    this.state = {
      selectedTab: selectedTab as typeof TAB_BUYER | typeof TAB_SELLER,
    };
  }

  public componentDidMount() {
    RenderTrack.track('Workspaces', {
      renderTime: this.timer,
    });
    global.localStorage.setItem('homeTeamsSeen', 'true');
    if (window.location.pathname.includes('/new')) {
      this.setState({ showCreate: true });
    }
  }

  public render() {
    const { match, me, location, history, sellerWorkspaces, buyerWorkspaces } = this.props;
    const { selectedTab, showCreate } = this.state;
    const relevantWorkspaces = {
      [TAB_BUYER]: buyerWorkspaces,
      [TAB_SELLER]: sellerWorkspaces,
    }[selectedTab];
    const tabs = this.getTabs();
    const layoutTitle = this.getLayoutTitle(tabs);
    return (
      <Layout
        header={{
          show: true,
          title: layoutTitle,
          subtitle: '',
          tabSelected: 'chat',
        }}
      >
        <S.Column>
          {tabs.length > 1 ? (
            <NavigationTabs selected={selectedTab} tabs={tabs} history={history} maxWidth={'250px'} />
          ) : null}
          <S.Container>{this.getContainerContent(relevantWorkspaces)}</S.Container>
        </S.Column>
        {showCreate ? (
          <WorkspaceCreate
            onCreate={this.onCreateWorkspace}
            onClose={() => this.setState({ showCreate: false })}
            typeSelected={
              !me.settings.isBuyer
                ? 'seller'
                : !Object.keys(buyerWorkspaces).length || !me.settings.isSeller
                ? 'buyer'
                : undefined
            }
          />
        ) : null}
      </Layout>
    );
  }

  /*
   * Generate an array of tabs depending on the user's settings,
   * and if there are buyer workspaces:
   * - If the user is not a buyer, never show buyer tab
   * - If the user is not a seller, never show seller tab
   * - If there are no buyer workspace but the user is also a seller, never show buyer tab
   * - Else, show both buyer and seller tabs
   */
  private getTabs(): Array<Tab> {
    const { me, buyerWorkspaces, history } = this.props;
    const { isBuyer, isSeller } = me.settings;
    const sellerTab = {
      id: TAB_SELLER,
      label: __('Components.Header.WorkspacesSeller'),
      action: () =>
        this.setState({ selectedTab: TAB_SELLER }, () =>
          history.replace(getPath({ path: ROUTE_PATHS.WORKSPACES_SELLER })),
        ),
    };
    const buyerTab = {
      id: TAB_BUYER,
      label: __('Components.Header.WorkspacesBuyer'),
      action: () =>
        this.setState({ selectedTab: TAB_BUYER }, () =>
          history.replace(getPath({ path: ROUTE_PATHS.WORKSPACES_BUYER })),
        ),
    };

    // If isBuyer and not isSeller, we only display Buyer Workspaces
    if (isBuyer && !isSeller) {
      return [{ ...buyerTab }];
    }
    if (isBuyer && Object.keys(buyerWorkspaces).length > 0) {
      return [{ ...sellerTab }, { ...buyerTab }];
    }

    return [{ ...sellerTab }];
  }

  private getLayoutTitle(tabs: Array<Tab>) {
    // If we have two tabs, we return the generic title
    if (tabs.length === 2) {
      return __('Components.Header.Workspaces');
    }

    // If we only have only, we return the corresponding title
    return tabs[0].label;
  }

  private getActiveTeamComponent(relevantWorkspaces: ICatalogs) {
    return (
      <>
        {this.renderTeamTitle(true)}
        {this.renderActiveTeam(relevantWorkspaces)}
      </>
    );
  }

  private getOtherTeamsComponent(relevantWorkspaces: ICatalogs) {
    return (
      <>
        {this.renderTeamTitle(false)}
        {this.renderOtherTeams(relevantWorkspaces)}
      </>
    );
  }

  private getContainerContent(workspaces: ICatalogs) {
    const { selectedTab } = this.state;
    const showBuyerZeroCase = selectedTab === TAB_BUYER && !Object.keys(workspaces).length;

    if (showBuyerZeroCase) {
      return (
        <S.CenterContainer>
          <EmptyListResource
            buttonAction={() => this.setState({ showCreate: true })} // TODO
            buttonText={__('Workspaces.buyer.zero_case.cta')}
            buttonType={'principal'}
            imageUrl={IMAGES.buyerWorkspaceZeroCase}
            showButton={true}
            text={__('Workspaces.buyer.zero_case.title')}
            text2={__('Workspaces.buyer.zero_case.description')}
          />
        </S.CenterContainer>
      );
    }

    return (
      <>
        <S.Title>{__('Workspaces.Label')}</S.Title>
        <S.Subtitle>{selectedTab === TAB_SELLER ? __('Workspaces.seller.subtitle') : ''}</S.Subtitle>

        <S.CreateButton
          id="workspaces-cta-create"
          type="principal"
          iconName="Add-more"
          onClick={() => this.setState({ showCreate: true })}
        >
          {__('WorkspaceCreate.cta')}
        </S.CreateButton>

        {this.getActiveTeamComponent(workspaces)}
        {this.getOtherTeamsComponent(workspaces)}
      </>
    );
  }

  /**
   * Render active team
   */
  private renderActiveTeam(workspaces: ICatalogs) {
    const { contacts, me, notificationShow, workspaceSellerChangeStatus } = this.props;
    const { selectedTab } = this.state;
    const activeCatalogs = Object.values(workspaces).filter(c =>
      selectedTab === TAB_SELLER ? sellerWorkspaceService.isActive(c, me.id) : c.id === me.buyerWorkspaceId,
    );
    const kind: IWorkspaceType = selectedTab === TAB_BUYER ? 'buyer' : 'seller';

    if (!activeCatalogs.length) return <S.TeamsRow>{this.renderTeamInactiveZeroCase('active')}</S.TeamsRow>;

    const teamCardProps = {
      changeWorkspace: this.changeWorkspace,
      contacts,
      isActive: true,
      kind,
      me,
      navigateToCatalog: this.navigateToCatalog,
    };

    const activeWorkspaceCards = activeCatalogs
      .sort((a, b) => {
        if (a.id === me.sellerWorkspaceId) return -1;
        if (b.id === me.sellerWorkspaceId) return 1;
        return -1;
      })
      .map((catalog, i) => (
        <WorkspaceCard
          {...teamCardProps}
          workspace={catalog}
          className={`active-team-${catalog.id}`}
          key={`${catalog.id}-${i}`}
          workspaceSellerChangeStatus={(status: 'active' | 'inactive') =>
            workspaceSellerChangeStatus(
              me.id,
              catalog.id,
              status,
              (err?: Error) =>
                err ||
                notificationShow(
                  {
                    style: 'success',
                    title: __('Workspaces.change_status_title'),
                    subtitle: __('Workspaces.inactive_subtitle', { name: catalog.name }),
                    closable: true,
                  },
                  5000,
                ),
            )
          }
        />
      ));

    return (
      <S.TeamsRow>
        {activeCatalogs.length ? activeWorkspaceCards : this.renderTeamInactiveZeroCase('active')}
      </S.TeamsRow>
    );
  }

  /**
   * Render other teams (inactive)
   */
  private renderOtherTeams(workspaces: ICatalogs) {
    const { contacts, notificationShow, me, workspaceSellerChangeStatus } = this.props;
    const { selectedTab } = this.state;
    const kind: IWorkspaceType = selectedTab === TAB_BUYER ? 'buyer' : 'seller';

    const teamCardProps = {
      changeWorkspace: this.changeWorkspace,
      contacts,
      isActive: false,
      kind,
      me,
      navigateToCatalog: this.navigateToCatalog,
    };

    const inactiveCatalogs = Object.values(workspaces).filter(c =>
      selectedTab === TAB_SELLER ? !sellerWorkspaceService.isActive(c, me.id) : c.id !== me.buyerWorkspaceId,
    );
    const otherWorkspaceCards = inactiveCatalogs.map((catalog, i) => (
      <WorkspaceCard
        {...teamCardProps}
        workspace={catalog}
        className={`inactive-team-${catalog.id}`}
        key={`${catalog.id}-${i}`}
        workspaceSellerChangeStatus={(status: 'active' | 'inactive') =>
          workspaceSellerChangeStatus(
            me.id,
            catalog.id,
            status,
            (err?: Error) =>
              err ||
              notificationShow(
                {
                  style: 'success',
                  title: __('Workspaces.change_status_title'),
                  subtitle: __('Workspaces.active_subtitle', { name: catalog.name }),
                  closable: true,
                },
                5000,
              ),
          )
        }
      />
    ));

    return (
      <S.TeamsRow>
        {inactiveCatalogs.length ? otherWorkspaceCards : this.renderTeamInactiveZeroCase('inactive')}
      </S.TeamsRow>
    );
  }

  /**
   * Render teams zero case
   */
  private renderTeamInactiveZeroCase(kind: 'active' | 'inactive') {
    const { selectedTab } = this.state;
    let text =
      selectedTab === TAB_SELLER
        ? __('Workspaces.seller.inactive_section.zero_case')
        : __('Workspaces.buyer.inactive_section.zero_case');
    if (kind === 'active') text = __('Workspaces.no_active_workspace');

    return (
      <S.ZeroCaseContainer>
        <S.TextGrey2>{text}</S.TextGrey2>
      </S.ZeroCaseContainer>
    );
  }

  /**
   * Render catalog title (active or inactive)
   */
  private renderTeamTitle(isActive: boolean) {
    const { selectedTab } = this.state;
    let title: string;
    let subtitle: string;

    if (selectedTab === TAB_SELLER) {
      title = isActive ? __('Workspaces.seller.active.title') : __('Workspaces.seller.inactive.title');
      subtitle = isActive ? __('Workspaces.seller.active.description') : '';
    } else {
      title = isActive ? __('Workspaces.buyer.active_section.title') : __('Workspaces.buyer.inactive_section.title');
      subtitle = isActive
        ? __('Workspaces.buyer.active_section.description')
        : __('Workspaces.buyer.inactive_section.description');
    }

    return (
      <S.TeamTitleContainer>
        <S.TeamActiveIndicator isActive={isActive} />
        <S.TeamTitleCol>
          <S.TeamTitle>{title}</S.TeamTitle>
          <S.TeamSubtitle>{subtitle}</S.TeamSubtitle>
        </S.TeamTitleCol>
      </S.TeamTitleContainer>
    );
  }

  /**
   * Call to change the catalog assigned
   */
  private changeWorkspace = (workspaceId: number) => {
    const {
      sellerWorkspaces,
      buyerWorkspaces,
      sellerWorkspaceAssign,
      buyerWorkspaceAssign,
      contacts,
      me,
      modalClose,
      modalOpen,
      notificationShow,
    } = this.props;
    const { selectedTab } = this.state;
    const relevantWorkspaces = selectedTab === TAB_SELLER ? sellerWorkspaces : buyerWorkspaces;
    const workspace = Object.values(relevantWorkspaces).find(c => c.id === workspaceId);
    const workspaceName = sellerWorkspaceService.getCatalogName(workspace, contacts, me);

    let literals;
    let modalTitle: string;
    let callbackAssign;

    if (selectedTab === TAB_SELLER) {
      literals = {
        buttonCancelText: __('Workspaces.seller.confirm_modal.cancel_cta'),
        buttonText: __('Workspaces.seller.confirm_modal.cta'),
        text2: __('Workspaces.seller.confirm_modal.description'),
      };
      modalTitle = __('Workspaces.seller.confirm_modal.title', { name: workspaceName });
      callbackAssign = sellerWorkspaceAssign;
    } else {
      literals = {
        buttonCancelText: __('Workspaces.buyer.confirm_modal_old.cancel_cta'),
        buttonText: __('Workspaces.buyer.confirm_modal_old.cta'),
        text2: __('Workspaces.buyer.confirm_modal_old.description'),
        subtitle: __('Workspaces.buyer.confirm_modal_old.subtitle'),
      };
      modalTitle = __('Workspaces.buyer.confirm_modal_old.title', { team_name: workspaceName });
      callbackAssign = buyerWorkspaceAssign;
    }

    const extra = {
      cancelAction: modalClose,
      icon: IMAGES.informativePineapple,
      showCancelButton: true,
      ...literals,
    };

    modalOpen(
      modalTitle,
      () => {
        modalClose();
        EventTrack.track('workspace_enable', {
          type: selectedTab === TAB_SELLER ? 'seller' : 'buyer',
          workspace_id: workspaceId,
        });
        callbackAssign(me.id, workspaceId, (err?: Error) => {
          if (!err)
            notificationShow({
              title: __('Workspaces.notification.title'),
              subtitle: __('Workspaces.notification.subtitle', { team_name: workspaceName }),
              closable: true,
              style: 'info',
            });
        });
      },
      extra,
      'nice',
    );
  };

  /**
   * Navigate to catalog
   */
  private navigateToCatalog = (workspaceId: number, type: 'seller' | 'buyer') => {
    const { clientsGet, history, me, workspaceBuyerSelect, workspaceSellerSelect } = this.props;
    if (!type || type === 'seller') {
      workspaceSellerSelect(workspaceId);
      clientsGet(me.id, workspaceId);
    } else {
      workspaceBuyerSelect(workspaceId);
      clientsGet(me.id, workspaceId);
    }
    let path = ROUTE_PATHS.WORKSPACE_PRODUCTS;
    if (!type || type === 'buyer') path = ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER;

    history.push(
      getPath({
        path,
        workspaceId: workspaceId + '',
      }),
    );
  };

  private onCreateWorkspace = (name: string, companyLogo: string, type: 'seller' | 'buyer') => {
    const { me, notificationShow, workspaceCreate, sellerWorkspaces, buyerWorkspaceAssign } = this.props;
    const defaultWorkspace = sellerWorkspaces[me.sellerWorkspaceId];
    workspaceCreate(
      me.id,
      getNewWorkspace(defaultWorkspace, name, companyLogo, type),
      type,
      (workspace: IWorkspace, error: Error) => {
        if (error) {
          return notificationShow({
            title: __('WorkspaceCreate.error'),
            subtitle: '',
            closable: true,
            style: 'error',
          });
        }
        notificationShow({
          title: __('WorkspaceCreate.success'),
          subtitle: '',
          closable: true,
          style: 'success',
        });
        this.setState({ showCreate: false });
        this.navigateToCatalog(workspace.id, type);

        if (!me.buyerWorkspaceId && type === 'buyer') {
          buyerWorkspaceAssign(me.id, workspace.id);
        }
      },
    );
  };
}

export default Workspaces;

export const WorkspaceCreate: React.FC<{
  onCreate: (name: string, image: string, type: 'seller' | 'buyer') => void;
  onClose: () => void;
  typeSelected?: 'seller' | 'buyer';
}> = ({ onCreate, onClose, typeSelected }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [name, setName] = React.useState('');
  const [image, setImage] = React.useState('');
  const [error, setError] = React.useState('');
  const [type, setType] = React.useState(typeSelected);
  return (
    <ActionsModal
      onClose={onClose}
      title={type ? __('WorkspaceCreate.title1') : __('WorkspaceCreate.title')}
      subtitle={type ? __('WorkspaceCreate.subtitle1') : __('WorkspaceCreate.subtitle')}
      minHeight="max-content"
    >
      {type ? (
        <>
          <S.Logo>
            <Picture
              editable={true}
              width="120px"
              imageUrl={image}
              onFileChange={onPictureChange}
              relation={constants.IMAGE_RELATION.COMPANY_LOGO}
              pictureMode={true}
              picturePlaceholder={__('WorkspaceCreate.logo_placeholder')}
              size="big"
              withCrop={deviceIsIpad() ? false : true}
            />
          </S.Logo>
          <InputWithLabel isRequired={true} label={__('WorkspaceCreate.name')}>
            <S.Input
              name="name"
              placeholder={__('WorkspaceCreate.name_placeholder')}
              value={name}
              trim={true}
              isRequired={true}
              onChange={(n, v) => {
                setError(v ? '' : 'empty');
                setName(v as string);
              }}
              onBlur={(n, value) => {
                setError(value ? '' : 'empty');
              }}
              hasError={!!error}
            />
          </InputWithLabel>
          <S.ModalButton onClick={() => onCreate(name, image, type)} disabled={!name} type="principal">
            {__('WorkspaceCreate.cta')}
          </S.ModalButton>
        </>
      ) : (
        <>
          <S.OptionsContainer>
            <S.BusinessContainer onClick={() => setType('seller')}>
              <S.IconContainer>
                <S.BusinessIcon disableHover={true} name="Producer" typeWorkspace="seller" />
              </S.IconContainer>
              <ColumnContainer>
                <S.TitleText>{__('WorkspaceCreate.seller.title')}</S.TitleText>
                <S.SubtitleText>{__('WorkspaceCreate.seller.subtitle')}</S.SubtitleText>
              </ColumnContainer>
            </S.BusinessContainer>
            <S.BusinessContainer onClick={() => setType('buyer')}>
              <S.IconContainer>
                <S.BusinessIcon disableHover={true} name="Wholesaler" typeWorkspace="buyer" />
              </S.IconContainer>
              <ColumnContainer>
                <S.TitleText>{__('WorkspaceCreate.buyer.title')}</S.TitleText>
                <S.SubtitleText>{__('WorkspaceCreate.buyer.subtitle')}</S.SubtitleText>
              </ColumnContainer>
            </S.BusinessContainer>
          </S.OptionsContainer>
        </>
      )}
    </ActionsModal>
  );
  async function onPictureChange(f: File) {
    convertToIFile(
      f,
      (file: IFile, fileString: string) => {
        if (fileString)
          resizeImage(fileString, (imageResized: string) =>
            dispatch(
              imageActions.mediaUploadWithProgress({ ...file, content: imageResized }, result =>
                setImage(result ? result.secure_url.replace('f_auto', 'f_auto,h_120,w_120,c_limit') : undefined),
              ),
            ),
          );
      },
      (code: string) => {
        logError(new Error('File could not be read! Code ' + code), `upload.companyLogo`);
      },
    );
  }
};

/**
 * Get new workspace to create
 */
export function getNewWorkspace(
  defaultWorkspace: IWorkspace,
  name: string,
  companyLogo: string,
  type: 'seller' | 'buyer',
): IWorkspace {
  return {
    additionalInfo: '',
    allowSyncPricegroups: defaultWorkspace.allowSyncPricegroups,
    allowUserExternalUpdate: defaultWorkspace.allowUserExternalUpdate,
    bio: '',
    companyColor: '',
    companyImage: '',
    companyLogo,
    createdAt: 0,
    currencyConversions: { ...defaultWorkspace.currencyConversions },
    cutoffTime: 14,
    defaultCurrency: defaultWorkspace.defaultCurrency,
    defaultWeightUnit: defaultWorkspace.defaultWeightUnit,
    estimatedTransportTime: 0,
    groupChannels: [],
    hashId: '',
    hasIntegrationExport: false,
    id: 0,
    lengthUnit: defaultWorkspace.lengthUnit,
    mandatoryPalletizationByClient: defaultWorkspace.mandatoryPalletizationByClient,
    members: [],
    name,
    numberOfActiveTeammates: 0,
    numberOfClients: 0,
    numberOfDecimalsShowed: 0,
    numberOfProducts: 0,
    numberOfSuppliers: 0,
    ordersEmail: '',
    ownerId: 0,
    pdfShowPrices: defaultWorkspace.pdfShowPrices,
    pdfVersion: defaultWorkspace.pdfVersion,
    plan: { type: 'basic', expiryDate: 0, numLicenses: 0 },
    priceListUrl: '',
    productMappingStrategy: 'ean_code',
    promotedBy: 0,
    publicPriceList: defaultWorkspace.publicPriceList,
    sections: [],
    sectionsEnabled: defaultWorkspace.sectionsEnabled,
    shippingDays: ['tuesday', 'wednesday', 'thursday', 'monday', 'sunday'],
    sortItemsBy: 'added',
    status: 'active',
    type,
    updatedAt: 0,
    workingStructure: '',
  };
}
