import { buyerWorkspaceSelectors, sellerWorkspaceSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import Layout from '../../../layout-flex';
import * as S from './Workspace.styled';

export type IProps = {
  children?: React.ReactNode;
  parentSections?: Array<{
    label: string;
    action?: () => void;
  }>;
  subtitle: string;
  tabSelected:
    | 'chat'
    | 'products'
    | 'sale'
    | 'purchase'
    | 'settings-buyer'
    | 'settings-seller'
    | 'clients'
    | 'pricelist'
    | 'prepare-orders'
    | 'suppliers'
    | 'multiple-carts'
    | 'offers'
    | 'referential';
  title: string;
  workspaceId: number;
  isBuyerWorkspace?: boolean;
};

const Workspace: React.FC<IProps> = ({
  children,
  isBuyerWorkspace,
  parentSections,
  subtitle,
  tabSelected,
  title,
  workspaceId,
}) => {
  const workspace = isBuyerWorkspace
    ? useSelector(buyerWorkspaceSelectors.getWorkspace(workspaceId))
    : useSelector(sellerWorkspaceSelectors.getCatalog(workspaceId));

  if (!config.TOGGLE_NO_WORKSPACE_MODAL.enabled && !workspace) return null;

  const breadcrumbs = [...(parentSections || [])];
  if (title && title !== '')
    breadcrumbs.push({
      label: `${title} ${subtitle}`,
    });

  return (
    <Layout
      header={{
        show: true,
        breadcrumbs,
        tabSelected,
      }}
    >
      <S.Container>
        <S.ChildrenContainer>{workspace ? children : null}</S.ChildrenContainer>
      </S.Container>
    </Layout>
  );
};

export default Workspace;
