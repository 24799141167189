import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  disabled?: boolean;
  selected?: boolean;
  shouldBeVisible?: (isSmallScreen: boolean) => boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const SubHeaderContainer = styled(RowContainer)`
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme!.colors.grey3};
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: calc(${(p: IStyledProps) => p.theme.headerHeight} - 2px);
  align-items: center;
`;

export const BackContainer = styled(RowContainer)`
  position: relative;
  align-items: center;
  left: -4px;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Modal = styled(RowContainer)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.headerHeight};
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: calc(${(p: IStyledProps) => p.theme.contentHeight} - 2 * ${(p: IStyledProps) => p.theme.headerHeight});
  width: 100%;
`;

export const BodyContainer = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;
