import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, LettersAvatar as LA, RowContainer, TextLabel } from '../../atoms';
import NavigationTabs from '../../molecules/NavigationTabs';
import SimpleSearch from '../../molecules/SimpleSearch';

interface IStyledProps {
  badgeCount?: number;
  color?: string;
  from?: 'showroom' | 'pricelist';
  hidden?: boolean;
  isLast?: boolean;
  show?: boolean;
  theme: ITheme;
}

const getBadgeWidth = (p: IStyledProps) => {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '16px';
  if (p.badgeCount < 1000) return '23px';
  return '30px';
};

export const Header = styled(ColumnContainer)`
  align-items: center;
  justify-content: flex-start;
  ${(p: IStyledProps) =>
    p.from === 'pricelist' &&
    css`
      width: 100vw;
    `};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const MenuMobileOverlay = styled(RowContainer)`
  display: ${(p: IStyledProps) => (p.show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  width: 100vw;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.8)')};
  z-index: 100;
`;

export const MenuMobile = styled(ColumnContainer)`
  position: fixed;
  top: 0;
  left: 0;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: min(350px, 100vw);
  width: min(350px, 100vw);
  overflow: hidden;
  transition: width 0.5s;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  z-index: 100;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
`;

export const MenuItem = styled(RowContainer)`
  border-bottom: ${(p: IStyledProps) => (p.isLast ? `1px solid ${p.theme.colors.grey3}` : 'none')};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

export const MenuSubItem = styled(MenuItem)`
  border-top: none;
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(10)};
`;

export const MenuItemText = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MenuSubItemText = styled(MenuItemText)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const MenuItemIcon = styled(FontIcon)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
`;

export const MenuItemLetters = styled(MenuItemText)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;
export const MenuItemLang = styled(MenuItemText)`
  flex: 1;
  text-align: right;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const MenuMobileLang = styled(MenuMobile)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const LangItem = styled(MenuItem)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  border-top: none;
`;

export const LangWrapper = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 100%;
`;

export const MenuItemChevron = styled(FontIcon)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  flex: 1;
  justify-content: flex-end;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const MenuItemLangIcon = styled(MenuItemChevron)`
  padding-right: 0;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  flex: 1;
`;

export const ConsentioLogo = styled.img`
  width: 45px;
  height: 45px;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: 3px;
`;

export const MenuMobileBanner = styled(RowContainer)`
  margin-right: 56px;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;

export const DownloadButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: calc(100% - ${(p: IStyledProps) => p.theme.paddingSize(6)});
`;

export const MenuMobileBannerText = styled.p`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MenuMobileBannerTextBold = styled.strong`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MobileHeader = styled(RowContainer)`
  max-height: 48px;
  min-height: 48px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
`;

export const HeaderRow = styled(RowContainer)<IStyledProps>`
  max-height: 75px;
  min-height: 75px;
  ${(p: IStyledProps) =>
    p.from === 'pricelist' &&
    css`
      max-width: ${p.theme.sizes.desktop_l};
    `}
  width: 100%;
  align-items: flex-end;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  display: ${(p: IStyledProps) => (p.hidden ? 'none' : 'flex')};
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '126px' : '86px')};
    min-height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '126px' : '86px')};
    display: flex;
  }
`;

export const DesktopHeader = styled(RowContainer)`
  align-items: flex-end;
  justify-content: space-between;
  display: none;
  flex: 1;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: flex;
  }
`;

export const Logo = styled.div`
  width: 57px;
  height: 57px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  cursor: pointer;
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
    height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 2 : 1)};
  }
`;

export const ImageLogo = styled.img`
  align-self: center;
  object-fit: cover;
  width: 57px;
  height: 57px;
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
    height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
  }
`;

export const LettersAvatar = styled(LA)`
  align-self: center;
  width: 57px;
  height: 57px;
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
    height: ${(p: IStyledProps) => (p.from === 'pricelist' ? '95px' : '70px')};
  }
`;

export const HeaderTexts = styled(ColumnContainer)`
  justify-content: flex-end;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 100px;
  max-width: 165px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 120px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 120px;
  }
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-bottom: 0;
  }
`;

export const ChatLink = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
  }
`;

export const Title = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.black};
  -webkit-line-clamp: ${(p: IStyledProps) => (p.from === 'pricelist' ? 2 : 1)};
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;

  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  }
`;

export const Subtitle = styled(Title)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-bottom: ${(p: IStyledProps) => (p.from === 'pricelist' ? p.theme.paddingSize() : 0)};

  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  }
`;

export const Hamburguer = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Cart = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const CartDesktop = styled(Cart)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Search = styled(SimpleSearch)<IStyledProps>`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: ${(p: IStyledProps) => (p.from === 'pricelist' ? '400px' : '285px')};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 2 : 1)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-bottom: 0;
  }
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Tabs = styled(NavigationTabs)`
  justify-content: flex-end;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-bottom: none;
  flex: 1;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const IconBadge = styled(RowContainer)`
  justify-content: center;
  align-items: center;
  height: ${(p: IStyledProps) => p.theme!.headerHeight};
  &:hover {
    color: ${(p: IStyledProps) => p.theme!.colors.purple1};
  }
  & > div {
    transition: transform ease 1s;
    font-size: 24px;
    margin: 0 auto 4px;
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
    position: relative;
    &:after {
      font-family: 'Nunito';
      content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
      background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
      width: ${getBadgeWidth};
      height: 15px;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
      top: -5px;
      right: 0;
      padding-right: 1px;
      transform: translateX(50%);
      color: ${(p: IStyledProps) => p.theme.colors.white};
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};
      padding-left: 1px;
      padding-top: 1px;
      font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
    }
  }
`;

export const ShareContainer = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  }
`;
