import styled, { ITheme, css } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  from?: 'pricelist' | 'showroom';
  hasBack?: boolean;
  isSearch?: boolean;
  sectionSelected?: boolean;
  theme: ITheme;
}

export const Column = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100vw;
`;

export const BackContainer = styled(RowContainer)`
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const Back = styled(RowContainer)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  align-items: center;
  &:hover {
    opacity: 0.75;
  }
`;

export const Container = styled(ColumnContainer)<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  overflow: hidden;
  position: relative;
`;

export const Modal = styled(ColumnContainer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
`;

export const CartContainer = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  width: 100%;
  overflow-y: hidden;
  z-index: 2;
`;

export const ModalShare = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  padding: 40px;
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  justify-content: center;
  align-items: center;
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

const paddingHorizontal = css`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const GridGroup = styled(RowContainer)<IStyledProps>`
  display: grid;
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(p.from === 'pricelist' ? 4 : 3)};
  grid-template-columns: repeat(auto-fill, 230px);
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    grid-template-columns: repeat(auto-fill, ${(p: IStyledProps) => (p.from === 'pricelist' ? '29vw' : '25vw')});
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    grid-template-columns: repeat(auto-fill, 44vw);
  }
  ${paddingHorizontal};
`;

export const Group = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const GroupTitle = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  ${paddingHorizontal}
`;

export const HeaderRow = styled(RowContainer)`
  width: 100%;
  ${paddingHorizontal};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  position: relative;
  justify-content: space-between;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize(0)};
    flex-direction: column;
  }
`;

export const ShareTouchable = styled(Button)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  .share-catalog-text {
    display: inline;
    @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
      display: none;
    }
  }
`;

export const ShareSpan = styled.span`
  font-family: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const AddressHeader = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const AddressIcon = styled(FontIcon)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: 16px;
  width: 16px;
  height: 16px;
`;

export const TextGrey1 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TextBlack = styled(TextGrey1)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
`;

export const Link = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: 4px 0;
`;

export const ExcelImage = styled.img`
  height: 28px;
  width: 28px;
`;

export const ExcelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: 4px;
  height: 40px;
  cursor: pointer;
`;

export const DownIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 5px 5px 5px 10px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
