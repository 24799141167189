import { ORDER_STATUS } from 'common-services';

import styled, { ITheme } from '../../../styled-components';
import {
  Button,
  CheckBox,
  ColumnContainer,
  FontIcon,
  Input,
  LettersAvatar,
  ProgressBar as PB,
  RowContainer,
  TextLabel,
} from '../../atoms';
import { FilterButton as FB } from '../../molecules';

interface IStyledProps {
  prepared?: boolean;
  position?: 'top' | 'below';
  isChecked?: boolean;
  isOpen?: boolean;
  theme: ITheme;
  status?: ORDER_STATUS;
  textAlign?: string;
  width?: string;
}

export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - 3 * ${p.theme.headerHeight})`};
  // Fix to refresh element in safari
  transform: translate3d(0, 0, 0);
`;

export const FilterButton = styled(FB)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Container = styled(ColumnContainer)`
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  align-items: flex-start;
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  max-width: 100%;
  width: 100%;
`;

export const OrdersContainer = styled(ColumnContainer)`
  overflow: hidden;
  flex: 1;
`;

export const OrdersContainerRow = styled(RowContainer)`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const ShowroomContainer = styled(OrdersContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ShowroomContainerRow = styled(OrdersContainerRow)``;

export const CartContainer = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 100%;
  width: 100%;
  z-index: 2;
`;

export const Column = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TitleColumn = styled(ColumnContainer)`
  align-items: flex-start;
`;

export const TotalResultsContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextSmall = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => (p.prepared ? p.theme.colors.green1 : p.theme.colors.black)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  font-weight: ${(p: IStyledProps) => (p.isOpen ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  display: flex;
`;
export const TextBlackSmall = styled(TextBlack)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextLink = styled(TextBlack)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;

export const TextSecondary = styled(TextBlack)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const ContactNameColumn = styled(ColumnContainer)`
  justify-content: center;
  min-width: 85px;
`;

export const ContactImagesRow = styled(RowContainer)`
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: ${(p: IStyledProps) => p.width || '30px'};
`;

export const ContactImage = styled(LettersAvatar)`
  width: 30px;
  height: 30px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const OrderCard = styled(ColumnContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  position: relative;
`;

export const Resume = styled(RowContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m};
`;

export const ResumeLeft = styled(RowContainer)`
  flex: 1;
  justify-items: flex-start;
`;

export const ProgressBar = styled(PB)`
  max-width: 336px;
  min-width: 336px;
`;

export const DownIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0;
  left: -${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: flex-start;
`;

export const WarningIcon = styled(DownIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  left: 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
`;

export const Row = styled(RowContainer)`
  align-items: center;
`;

export const DataRow = styled(RowContainer)`
  align-items: center;
  height: 60px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey4};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const WarningRow = styled(RowContainer)`
  align-items: center;
  height: 42px;
  background-color: ${(p: IStyledProps) => p.theme.colors.orange2};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  justify-content: flex-start;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const DataRowHead = styled(DataRow)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.white};
  }
`;

export const HeadRow = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Td = styled(ColumnContainer)`
  justify-content: center;
  width: 157px;
`;

export const ProductTd = styled(Td)`
  flex: 1;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ItemTitle = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-transform: uppercase;
  color: ${(p: IStyledProps) => (p.prepared ? p.theme.colors.green1 : p.theme.colors.black)};
`;

export const Amount = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  display: flex;
  color: ${(p: IStyledProps) => (p.prepared ? p.theme.colors.green1 : p.theme.colors.black)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Th = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: left;
  width: 157px;
`;
export const TdWarning = styled.div`
  width: 30px;
`;
export const WarningWrapper = styled.div``;

export const ProductTh = styled(Th)`
  flex: 1;
`;

export const Option = styled(RowContainer)`
  align-items: center;
`;

export const Check = styled(CheckBox)``;

export const OptionText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.isChecked ? p.theme.colors.green1 : p.theme.colors.black)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const CommentsButton = styled(Button)`
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 0;
  height: 22px;
`;
export const ButtonLink = styled(Button)`
  padding: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  height: 22px;
`;

export const FileRow = styled(RowContainer)`
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-height: min-content;
`;

export const SuccessIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-size: 20px;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextGreen = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const AmountInput = styled(Input)`
  color: ${(p: IStyledProps) => (p.prepared ? p.theme.colors.green1 : p.theme.colors.black)};
`;
