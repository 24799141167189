import {
  Api,
  buyerWorkspaceActions,
  modalActions,
  notificationsActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { workspaceBuyerSelect, workspaceSellerSelect } from '../actions/nav';
import Workspaces, { IDispatchProps, IStateProps } from '../components/organisms/Workspaces';
import { IReduxState } from '../reducers';

const emptyObject = {};

function mapStateToProps(state: IReduxState, props: RouteComponentProps): IStateProps {
  const {
    catalog: { catalogs },
    workspace: { workspaces },
    contact: { inConsentio },
    user: { user },
  } = state!;

  return {
    sellerWorkspaces: catalogs || emptyObject,
    buyerWorkspaces: workspaces || emptyObject,
    contacts: inConsentio,
    me: user,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      sellerWorkspaceAssign: sellerWorkspaceActions.catalogAssign,
      buyerWorkspaceAssign: buyerWorkspaceActions.workspaceAssign,
      clientsGet: sellerWorkspaceActions.clientsGet,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      workspaceBuyerSelect,
      workspaceSellerSelect,
      workspaceSellerChangeStatus: sellerWorkspaceActions.workspaceChangeStatus,
      workspaceCreate: sellerWorkspaceActions.workspaceCreate,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);
