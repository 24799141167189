import * as React from 'react';
import { debounce } from 'lodash';

import { Api, CURRENCY_CODES, LOCALE, ORDER_STATUS, orderService, orderActions } from 'common-services';
import config from '../../../../bindings/config';
import { api } from '../../../store';

interface SearchStateProps {
  viewMode: 'delivery' | 'order' | 'request';
  type: IOrderType;
  me: IUser;
  catalogHash?: string;
  catalogs: { [id: number]: IWorkspace };
  contactId?: number;
  onlyUnreads?: boolean;
}

interface SearchState {
  language: LOCALE;
  myId: number;
  beginDate?: Date;
  endDate?: Date;
  currency?: CURRENCY_CODES;
  buyerWsHash?: string;
  catalogHashs?: Array<string>;
  sellerId?: number;
  buyerId?: number;
  catalogHash?: string;
  onlyUnreads?: boolean;
  delegateId?: number;
  buyers?: Array<number>;
  sellers?: Array<number>;
  onlyAggregation?: boolean;
  withoutDeliveryAt?: boolean;
  status?: Array<ORDER_STATUS>;
}

export const useSearchState = ({
  viewMode,
  type,
  me,
  catalogHash,
  catalogs,
  contactId,
  onlyUnreads,
}: SearchStateProps) => {
  return React.useMemo(() => {
    const isPurchase = type === 'purchase';
    const catalog = catalogHash
      ? Object.values(catalogs).find(c => c.hashId === catalogHash)
      : catalogs[me.sellerWorkspaceId];

    const baseSearchState: SearchState = {
      language: me.settings.language as LOCALE,
      myId: me.id,
      currency: catalog?.defaultCurrency,
    };

    switch (viewMode) {
      case 'delivery':
        return {
          ...baseSearchState,
          buyerWsHash: isPurchase ? catalogHash : undefined,
          catalogHashs: isPurchase ? [catalogHash] : undefined,
          sellerId: !isPurchase ? me.id : undefined,
          buyerId: isPurchase ? me.id : undefined,
          currency: catalog?.defaultCurrency,
          onlyAggregation: false,
          withoutDeliveryAt: false,
          status: [ORDER_STATUS.ACCEPTED],
        };

      case 'order':
        return {
          ...baseSearchState,
          ...(isPurchase
            ? { buyerId: me.id, sellers: contactId ? [contactId] : undefined }
            : { sellerId: me.id, delegateId: me.id, buyers: contactId ? [contactId] : undefined }),
          ...(catalogHash
            ? {
                buyerWsHash: isPurchase ? catalogHash : undefined,
                catalogHash: !isPurchase ? catalogHash : undefined,
                currency: !isPurchase ? catalog?.defaultCurrency : undefined,
                sellerId: undefined,
                delegateId: undefined,
                buyers: !isPurchase && contactId ? [contactId] : undefined,
                sellers: isPurchase && contactId ? [contactId] : undefined,
                buyerId: undefined,
              }
            : {}),
          ...(onlyUnreads
            ? {
                onlyUnreads,
                currency: undefined,
                beginDate: undefined,
                endDate: undefined,
              }
            : {}),
          ...(catalogHash && onlyUnreads
            ? isPurchase
              ? { buyerId: me.id }
              : { sellerId: me.id, delegateId: me.id }
            : {}),
        };

      case 'request':
        return {
          ...baseSearchState,
          sellerId: !isPurchase ? me.id : undefined,
          ...(catalogHash
            ? {
                catalogHash,
                currency: catalog?.defaultCurrency,
                sellerId: undefined,
                delegateId: undefined,
                buyerId: undefined,
              }
            : {}),
        };

      default:
        return baseSearchState;
    }
  }, [viewMode, type, me, catalogHash, catalogs, contactId, onlyUnreads]);
};

interface UseSendSearchProps {
  viewMode: 'order' | 'request' | 'delivery';
  search: ISearchOrder;
  meId: number;
  orderSearch: (s: ISearchOrder, searchPrefix: string, userId?: number, withFacets?: boolean) => any;
  setIsSearched: (isSearched: boolean) => void;
  setFacets: (facets: IFacets) => void;
  setGlobalFacets: (facets: IFacets) => void;
  setOrderRequests: (requests: Array<IOrderRequest>) => void;
  setOrderItems: (items: Array<IOrderAggregationItem>) => void;
  setSearchId: (id: string) => void;
  setTotalResults: (total: number) => void;
}

export const useSendSearch = ({
  viewMode,
  search,
  meId,
  orderSearch,
  setIsSearched,
  setFacets,
  setGlobalFacets,
  setOrderRequests,
  setOrderItems,
  setSearchId,
  setTotalResults,
}: UseSendSearchProps) => {
  return React.useCallback(
    debounce(async () => {
      setIsSearched(false);
      try {
        let result;
        switch (viewMode) {
          case 'order':
            result = await orderSearch(search, config.SEARCH_PREFIX, meId, true);
            break;
          case 'request':
            result = await orderService.searchOrderRequest(search, config.SEARCH_PREFIX, api, meId);
            break;
          case 'delivery':
            result = await orderService.searchOrderItemByDelivery(search, config.SEARCH_PREFIX, api, meId);
            break;
          default:
            throw new Error(`Invalid viewMode: ${viewMode}`);
        }
        if (result) {
          const { data, facets, facetsGlobal } = result;
          setFacets(facets);
          setGlobalFacets(facetsGlobal);
          if (viewMode === 'request') {
            setOrderRequests(data.hits);
          } else if (viewMode === 'delivery') {
            setOrderItems(data.hits);
          }
          setIsSearched(true);
          setSearchId(data.searchId);
          setTotalResults(data.totalResults);
        }
      } catch (error) {
        console.error('Error in sendSearch:', error);
        // Here we might want to set an error state or show a notification to the user
      }
    }, 200, { leading: true }),
    [
      viewMode,
      search,
      meId,
      orderSearch,
      setIsSearched,
      setFacets,
      setGlobalFacets,
      setOrderRequests,
      setOrderItems,
      setSearchId,
      setTotalResults,
    ],
  );
};

interface UseScrollSearchProps {
  viewMode: 'order' | 'request' | 'delivery';
  searchId: string;
  orderScrollSearch: any;
  setOrderRequests: (callback: (prevState: Array<IOrderRequest>) => Array<IOrderRequest>) => void;
  setOrderItems: (callback: (prevState: Array<IOrderAggregationItem>) => Array<IOrderAggregationItem>) => void;
}

export const useScrollSearch = ({
  viewMode,
  searchId,
  orderScrollSearch,
  setOrderRequests,
  setOrderItems,
}: UseScrollSearchProps) => {
  return React.useCallback(
    debounce(async () => {
      try {
        let result;
        switch (viewMode) {
          case 'order':
            result = await orderScrollSearch(searchId);
            break;
          case 'request':
            result = await orderService.scrollSearchOrderRequest(searchId, api);
            if (result) {
              setOrderRequests(prevState => [...prevState, ...result.data.hits]);
            }
            break;
          case 'delivery':
            result = await orderService.scrollSearchOrderItem(searchId, api);
            if (result) {
              setOrderItems(prevState => [...prevState, ...result.data.hits]);
            }
            break;
          default:
            throw new Error(`Invalid viewMode: ${viewMode}`);
        }
        // Note: For 'order' viewMode, we assume orderScrollSearch handles state updates internally
        // If it doesn't, you might need to add state updates here similar to 'request' and 'delivery' cases
      } catch (error) {
        console.error('Error in scrollSearch:', error);
        // Here you might want to set an error state or show a notification to the user
      }
    }, 200, { leading: true }),
    [viewMode, searchId, orderScrollSearch, setOrderRequests, setOrderItems],
  );
};
