import { IFileNotification, INotification } from 'common-services';
import * as React from 'react';

import Notification from '../../atoms/Notification';
import NotificationUpload from '../../atoms/NotificationUpload';
import * as S from './NotificationGroup.styled';

export interface IStateProps {
  notification: INotification;
  files: { [fileId: string]: IFileNotification };
}

export interface IDispatchProps {
  clearNotification: () => void;
  clearFileNotification: (fileId: string) => void;
}

export type IProps = IStateProps & IDispatchProps;

const NotificationGroup: React.FC<IProps> = ({
  files = {},
  notification,
  clearFileNotification,
  clearNotification,
}) => {
  const toShow = notification?.toShow || Object.values(files).filter(f => f.toShow).length;
  return (
    <S.Container className="notification-group" toShow={!!toShow}>
      <Notification notification={notification} clear={clearNotification} />
      {Object.values(files).map((fileNotif, idx) => (
        <NotificationUpload notification={fileNotif} key={idx} clear={clearFileNotification} />
      ))}
    </S.Container>
  );
};

export default React.memo(NotificationGroup);
