import { WORKING_STATUS } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import FontIcon from '../FontIcon';

interface IStyledProps {
  borderRadius?: string;
  color?: string;
  disabled?: boolean;
  img?: string;
  size?: number;
  status?: WORKING_STATUS;
  theme: ITheme;
}

export const Outer = styled.div`
  background-color: ${(p: IStyledProps) => p.color || p.theme.colors.orange1};
  display: flex;
  flex-direction: row;
  border-radius: ${(p: IStyledProps) => p.borderRadius || p.theme.borderRadius};
  width: ${(p: IStyledProps) => p.size}px;
  min-width: ${(p: IStyledProps) => p.size}px;
  height: ${(p: IStyledProps) => p.size}px;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: ${(p: IStyledProps) => (!p.img && p.disabled ? 0.6 : 1)};
  ${(p: IStyledProps) =>
    p.img &&
    `background: ${p.disabled ? p.theme.colors.grey1.replace('1)', '0.6)') : ''} url("${p.img}"); 
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    background-blend-mode: multiply;
    opacity: ${p.disabled ? 0.8 : 1};
    `};
`;

export const OuterSquare = styled(Outer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const OuterGroup = styled(Outer)`
  background: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.7)')};
`;

export const Inner = styled.div`
  color: ${(p: IStyledProps) => p.color || p.theme.colors.white};
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p: IStyledProps) => p.size / 2}px;
  line-height: initial;
`;

export const InnerGroup = styled(Inner)`
  font-size: ${(p: IStyledProps) => p.size / 1.8}px;
`;

/**
 * Get working status color
 */
const getStatusColor = (p: IStyledProps) => {
  if (p.status === WORKING_STATUS.AWAY) return p.theme.colors.orange1;
  if (p.status === WORKING_STATUS.NOT_AVAILABLE) return p.theme.colors.red1;
  return p.theme.colors.green1;
};

export const WorkingStatus = styled.div<IStyledProps>`
  width: ${(p: IStyledProps) => p.size || '15'}px;
  height: ${(p: IStyledProps) => p.size || '15'}px;
  border: 2px solid ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: 50%;
  background-color: ${getStatusColor};
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(45%) translateY(30%);
`;

export const Icon = styled(FontIcon)<IStyledProps>`
  ${(p: IStyledProps) =>
    p.color
      ? css`
          color: ${p.color};
        `
      : null}
  ${(p: IStyledProps) =>
    p.size
      ? css`
          font-size: ${p.size / 1.8}px;
        `
      : null}
`;
