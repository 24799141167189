import {
  __,
  constants,
  currency,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
  parsers,
  PRODUCT_UNIT,
  productService,
  userSelectors,
  utils,
} from 'common-services';
import { History, Location } from 'history';
import * as React from 'react';

import config from '../../../../bindings/config';
import { IMAGE_DEFAULT, ROUTE_PATHS } from '../../../constants';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { ColumnContainer, FontIcon, ImageCover, Input, Select, SimpleDropdown, TagBubble } from '../../atoms';
import SelectWorkspaceModal from '../SelectWorkspaceModal';
import * as S from './OrderItem.styled';
import { useSelector } from 'react-redux';

export interface IProps {
  amEditor: boolean;
  autoFocus?: boolean;
  cartUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void;
  catalogs?: { [cId: number]: IWorkspace };
  contacts?: { [key: number]: IContact };
  deletable?: boolean;
  editable: boolean;
  editAfterAccept?: boolean;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  hidePrices?: boolean;
  history?: History<any>;
  index: number;
  isPrepare?: boolean;
  isPrepared?: boolean;
  issues?: Array<IOrderIssue>;
  item: IOrderItem;
  location?: Location<any>;
  me?: IUser;
  myId: number;
  navigate?: (i: IOrderItem) => void;
  onEnableItem: (item: IOrderItem, issue: IOrderIssue) => void;
  orderDiscardLine: (issue: IOrderIssue) => void;
  orderOrigin: ORDER_ORIGIN;
  orderStatus: ORDER_STATUS;
  orderUpdatePrepared: typeof orderActions.orderUpdatePrepared;
  priceMode: IPriceMode;
  pricePrecision: number;
  prodTypes: { [key: string]: IProdType };
  showGrey?: boolean;
  showPORProductModal: (item: IOrderItem) => void;
  showSearchProductModal: (item: IOrderItem, originLot?: number, mappingCode?: string) => void;
  showServedColumn?: boolean;
  weAreSeller?: boolean;
}

const OrderItem: React.FC<IProps> = ({
  amEditor,
  cartUpdateItem,
  deletable,
  editAfterAccept,
  hidePrices,
  isPrepare,
  isPrepared,
  item,
  navigate,
  orderStatus,
  priceMode,
  prodTypes,
  showServedColumn,
  weAreSeller,
  orderOrigin,
  catalogs,
  history,
  contacts,
  me,
  location,
  issues,
  showGrey,
  onEnableItem,
  editable,
  myId,
  orderUpdatePrepared,
  autoFocus,
  pricePrecision,
  showSearchProductModal,
  showPORProductModal,
  orderDiscardLine,
}) => {
  const [showSelectWorkspaceModal, setShowSelectWorkspaceModal] = React.useState<boolean>(false);
  const [newOrExistingLot, setNewOrExistingLot] = React.useState<string>(undefined);
  const [priceEdit, setPriceEdit] = React.useState<boolean>();
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  /**
   * Opens the modal to select products
   */
  const openProductSelectionModal = (e?: any, originLot?: number) => {
    setShowSelectWorkspaceModal(false);
    showProductsModal(e, originLot);
  };

  /**
   * handle on click in import/export option
   */
  function handleLotOptions(op: string): void {
    switch (op) {
      case 'new':
        if (Object.keys(catalogs).length > 1) {
          setShowSelectWorkspaceModal(true);
          setNewOrExistingLot('new');
        } else {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
              workspaceId: Object.values(catalogs)[0].id + '',
              productId: 'new',
            }) + `?originLot=${item.id}`,
          );
        }
        break;
      case 'existing':
        if (Object.keys(catalogs).length > 1) {
          setShowSelectWorkspaceModal(true);
          setNewOrExistingLot('existing');
        } else {
          openProductSelectionModal(undefined, item.id);
        }
        break;
      default:
        logError(new Error('unknow option: ' + op), 'extended.options');
        break;
    }
  }

  /**
   * Render assign lot modal
   */
  const renderSelectWorkspaceModal = () => {
    return (
      <SelectWorkspaceModal
        catalogs={Object.values(catalogs).filter(c => c.members.find(m => m.userId === me.id && m.role !== 'viewer'))}
        contacts={contacts}
        history={history}
        item={item}
        location={location}
        me={me}
        newOrExistingLot={newOrExistingLot}
        close={() => setShowSelectWorkspaceModal(false)}
        openProductSelectionModal={() => openProductSelectionModal(undefined, item.id)}
      />
    );
  };

  /**
   * renders the block where the price should be rendered
   * if there's a price issue, renders a text explaining
   * the options available for the user
   */
  function renderPriceBlock() {
    if (issues?.find(i => i.type === 'product-no-price')) {
      return (
        <S.ProductPriceRow>
          <S.WarningIcon name="Warning" disableHover={true} />
          <ColumnContainer>
            <S.Text showGrey={showGrey}>{__('Components.ProductDetails.price_missing')}</S.Text>
            {amEditor && weAreSeller && orderStatus !== ORDER_STATUS.CANCELED ? (
              <S.Text showGrey={showGrey}>
                {__('Components.ProductDetails.price_missing_actions.0') + ' '}
                <S.TextLink onClick={showPORModal}>
                  {__('Components.ProductDetails.price_missing_actions.1')}
                </S.TextLink>
                {deletable ? (
                  <>
                    {' ' + __('Components.ProductDetails.price_missing_actions.2') + ' '}
                    <S.TextLink onClick={discardLine}>
                      {__('Components.ProductDetails.price_missing_actions.3')}
                    </S.TextLink>
                    .
                  </>
                ) : null}
              </S.Text>
            ) : null}
          </ColumnContainer>
        </S.ProductPriceRow>
      );
    }
    return renderPrice();
  }

  /**
   * renders the title of the order line
   * if there's an issue about the product not being found
   * renders the available options for the user
   */
  function renderItemTitle() {
    const typeVariety = productService.getProductTypeVarietyDisplay(
      item.type,
      prodTypes[item.type] ? prodTypes[item.type].name : '',
      item.title,
    );
    const notFoundIssue = issues?.find(i =>
      ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
    );
    const unavailableIssue = issues?.find(i => 'product-unavailable' === i.type);
    if (notFoundIssue) {
      return (
        <S.ProductErrorRow>
          <S.WarningIcon name="Warning" disableHover={true} />
          <ColumnContainer>
            <S.Text showGrey={showGrey}>
              <>
                {utils.formatText(
                  notFoundIssue.type === 'many-internal-codes-for-external'
                    ? __('Components.ProductDetails.product_mapping_duplicated', {
                        name: item.title,
                        code: notFoundIssue.code,
                      })
                    : __('Components.ProductDetails.product_missing', {
                        name: item.title,
                        code: notFoundIssue.code,
                      }),
                  (s, index) => (
                    <S.TextBold showGrey={showGrey} key={s + index}>
                      {s}
                    </S.TextBold>
                  ),
                )}
              </>
            </S.Text>
            {amEditor && weAreSeller && orderStatus !== ORDER_STATUS.CANCELED ? (
              <S.Text showGrey={showGrey}>
                {__('Components.ProductDetails.product_missing_actions.0') + ' '}
                <S.TextLink onClick={e => showProductsModal(e, undefined, notFoundIssue.code)}>
                  {__('Components.ProductDetails.product_missing_actions.1')}
                </S.TextLink>
                {' ' + __('Components.ProductDetails.product_missing_actions.2') + ' '}
                <S.TextLink onClick={discardLine}>
                  {__('Components.ProductDetails.product_missing_actions.3')}
                </S.TextLink>
                .
              </S.Text>
            ) : null}
          </ColumnContainer>
        </S.ProductErrorRow>
      );
    }
    if (unavailableIssue) {
      return (
        <S.ProductErrorRow>
          <S.WarningIcon name="Warning" disableHover={true} />
          <ColumnContainer>
            <S.ItemTitle showGrey={showGrey}>
              {utils.firstToUpperCase(`${item.productTitle || typeVariety + ' ' + item.size}`)}
            </S.ItemTitle>
            <TagBubble
              color="error"
              label={__('Components.ProductCard.out_of_stock')}
              disabled={true}
              showIcon={true}
            />
            {amEditor && weAreSeller && orderStatus !== ORDER_STATUS.CANCELED ? (
              <S.Text>
                {__('Components.ProductDetails.product_unavailable_actions.0') + ' '}
                <S.TextLink
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onEnableItem(item, unavailableIssue);
                  }}
                >
                  {__('Components.ProductDetails.product_unavailable_actions.1')}
                </S.TextLink>
                {' ' + __('Components.ProductDetails.product_unavailable_actions.2') + ' '}
                <S.TextLink onClick={discardLine}>
                  {__('Components.ProductDetails.product_unavailable_actions.3')}
                </S.TextLink>
                .
              </S.Text>
            ) : null}
          </ColumnContainer>
        </S.ProductErrorRow>
      );
    }
    return (
      <>
        <S.ItemTitle showGrey={showGrey}>
          {utils.firstToUpperCase(`${item.productTitle || typeVariety + ' ' + item.size}`)}
        </S.ItemTitle>
        {item.packaging ? <S.Text showGrey={showGrey}>{item.packaging}</S.Text> : null}
      </>
    );
  }

  /**
   * Render quantity display (ordered or served)
   * TODO: add salesUnits to order item
   */
  function renderQuantity() {
    const invalidAmountIssue = issues?.find(i => i.type === 'amount-not-valid');
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.Row>
          {invalidAmountIssue ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
          {(editable && !editAfterAccept) ||
          (!item.amount && (orderOrigin === ORDER_ORIGIN.IMPORT_UI || orderOrigin === ORDER_ORIGIN.EMAIL)) ? (
            <>
              <S.AmountInput
                id={`amount-${item.id}`}
                name="amount"
                onBlur={(key: string, value: string, error: string) => {
                  const v = Number(value);
                  cartUpdateItem(
                    { ...item, amount: v, servedQuantity: v },
                    invalidAmountIssue?.type === 'amount-not-valid' ? invalidAmountIssue : undefined,
                  );
                }}
                value={item.servedQuantity || 0}
                minValue={0}
                precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                width="80px"
                type="number"
                textAlign="right"
                autoFocus={false}
                hasError={!item.servedQuantity}
                containerMargin="0 6px 0 0"
                isIssue={!!invalidAmountIssue}
              />
              <Select
                value={item.servedSaleUnit}
                name={`served-sale-unit-${item.id}`}
                options={item.saleUnits.map(s => ({ value: s, label: parsers.getUnitText(s, item.weightUnit, 0) }))}
                onChange={(n, v) => {
                  cartUpdateItem({
                    ...item,
                    saleUnit: v as PRODUCT_UNIT,
                    servedSaleUnit: v as PRODUCT_UNIT,
                  });
                }}
                width="100px"
              />
            </>
          ) : (
            <>
              <S.Amount showGrey={showGrey}>{item.amount.toFixed(item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0)}</S.Amount>
              <S.Amount showGrey={showGrey}>
                {' '}
                {parsers.getUnitText(item.saleUnit, item.weightUnit, item.amount)}
              </S.Amount>
            </>
          )}
        </S.Row>
      </S.AmountRow>
    );
  }

  /**
   * Render weight served
   */
  function renderWeight() {
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        {isPrepared ? (
          item.isPrepared ? (
            <S.Row>
              <S.Amount>{item.servedWeight || 0}</S.Amount>
              <S.Amount> {item.weightUnit}</S.Amount>
            </S.Row>
          ) : (
            __('Components.Cart.items.prepare')
          )
        ) : (
          <Input
            id={`served-weight-${item.id}`}
            name="servedWeight"
            onBlur={(key: string, value: string, error: string) => {
              const v = Number(value);
              if (!error && !isNaN(v) && v !== item.servedWeight) {
                orderUpdatePrepared(myId, item.orderId, item.id, item.catalogId, v, item.isPrepared, () => {
                  cartUpdateItem({ ...item, servedWeight: v });
                });
              }
            }}
            value={item.servedWeight || 0}
            minValue={0}
            precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
            width="80px"
            type="number"
            textAlign="right"
            autoFocus={false}
            containerMargin="0 6px 0 0"
            variableTextSingular={item.weightUnit}
            variableTextPlural={item.weightUnit}
            disabled={isPrepared}
          />
        )}
      </S.AmountRow>
    );
  }

  /**
   * Render weight served
   */
  function renderPrepared() {
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.Option>
          <S.Check
            isChecked={item.isPrepared}
            onClick={val => {
              orderUpdatePrepared(myId, item.orderId, item.id, item.catalogId, item.servedWeight, val, () => {
                cartUpdateItem({ ...item, isPrepared: val });
              });
            }}
            size={20}
          />
          <S.OptionText isChecked={item.isPrepared}>
            {item.isPrepared ? __('Components.Cart.items.prepared') : __('Components.Cart.items.prepare')}
          </S.OptionText>
        </S.Option>
      </S.AmountRow>
    );
  }

  /**
   * Render served quantity inputs
   */
  function renderServedQuantity() {
    const hasServedChange =
      showServedColumn && (item.servedQuantity !== item.amount || item.servedSaleUnit !== item.saleUnit);

    if (!editable || !item.servedQuantity)
      return (
        <S.AmountRow onClick={e => e.stopPropagation()}>
          <S.Amount hasServedChange={hasServedChange} servedQuantity={true} showGrey={!item.servedQuantity}>
            {item.servedQuantity.toFixed(item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0)}{' '}
            {parsers.getUnitText(item.servedSaleUnit, item.weightUnit, item.servedQuantity)}
          </S.Amount>
        </S.AmountRow>
      );

    const saleUnits = item.saleUnits || [item.saleUnit]; // hack fallback
    return (
      <S.AmountRow onClick={e => e.stopPropagation()}>
        <S.Row>
          <Input
            id={`served-quantity-${item.id}`}
            name="servedQuantity"
            onBlur={(key: string, value: string, error: string) => {
              const v = Number(value);
              if (!error && !isNaN(v) && v !== item.servedQuantity) {
                let newCartItem = { ...item, servedQuantity: v };
                if (!showServedColumn) {
                  newCartItem = { ...newCartItem, amount: 0 };
                }
                cartUpdateItem(newCartItem);
              }
            }}
            value={item.servedQuantity || 0}
            minValue={0}
            precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
            width="80px"
            type="number"
            textAlign="right"
            autoFocus={false}
            hasError={!item.servedQuantity}
            disabled={!editable}
            containerMargin="0 6px 0 0"
          />
          <Select
            value={item.servedSaleUnit}
            name={`served-sale-unit-${item.id}`}
            options={saleUnits.map(s => ({ value: s, label: parsers.getUnitText(s, item.weightUnit, 0) }))}
            onChange={(n, v) => {
              cartUpdateItem({
                ...item,
                servedSaleUnit: v as PRODUCT_UNIT,
              });
            }}
            width="100px"
          />
        </S.Row>
      </S.AmountRow>
    );
  }

  /**
   * Render price display or input
   */
  const renderPrice = () => {
    const hasServedChange = showServedColumn && item.price !== item.servedPrice;
    const priceDisplay = priceToShow();
    const price = showServedColumn ? item.servedPrice : item.price;
    const invalidPriceIssue = issues?.find(i => i.type === 'price-not-valid');
    if (invalidPriceIssue) {
      return (
        <S.PriceColumn>
          <S.ProductPriceRow>
            <S.WarningIcon name="Warning" disableHover={true} />
            {item.childId ? (
              <S.PriceInput
                id={`price-${item.id}`}
                name={showServedColumn ? 'servedPrice' : 'price'}
                onBlur={updateItemPrice}
                value={0}
                minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
                precision={pricePrecision}
                width="120px"
                type="number"
                textAlign="right"
                autoFocus={autoFocus}
                hasError={true}
                isIssue={true}
              />
            ) : (
              <S.Text width="120px" showGrey={showGrey}>
                {__('Components.ProductDetails.price_missing_no_product')}
              </S.Text>
            )}
          </S.ProductPriceRow>
          {item.childId ? (
            <S.Text width="120px" showGrey={showGrey} padding="6px 0">
              {__('order.priceUnit')}{' '}
              <S.TextLink
                onClick={() => cartUpdateItem({ ...item, isPor: false, servedPrice: item.price }, invalidPriceIssue)}
              >
                {currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit, item.price)}
              </S.TextLink>
            </S.Text>
          ) : null}
        </S.PriceColumn>
      );
    }
    const mismatchPriceIssue = issues?.find(i => i.type === 'no-pricegroup-matching');
    const pricePriceGroup = mismatchPriceIssue?.name ? Number(mismatchPriceIssue.name) : 0;
    const priceDiff = Number.isNaN(pricePriceGroup) ? 0 : item.price - pricePriceGroup;
    if (!editable || priceMode !== 'edit' || !item.servedQuantity) {
      return (
        <S.PriceColumn>
          <S.PriceRow>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
            <S.ItemPrice showGrey={showGrey} hasServedChange={hasServedChange}>
              {priceDisplay}
            </S.ItemPrice>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
              ? renderArrowPercentage(priceDiff, 'price')
              : null}
          </S.PriceRow>
          {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
            <S.TextPriceGroup padding="6px 12px 0 0">
              {__('Components.ProductDetails.mismatch_price.price')}
              <S.TextPriceMismatch>
                {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                  currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
              </S.TextPriceMismatch>
            </S.TextPriceGroup>
          ) : null}
        </S.PriceColumn>
      );
    }
    if (priceEdit || !item.price || showServedColumn) {
      return (
        <S.PriceColumn>
          <S.PriceRow>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
            <Input
              id={`price-${item.id}`}
              name={showServedColumn ? 'servedPrice' : 'price'}
              onBlur={updateItemPrice}
              value={price || 0}
              minValue={currency.getPriceInputMinimumValue(pricePrecision)}
              variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
              precision={pricePrecision}
              width="120px"
              type="number"
              textAlign="right"
              autoFocus={autoFocus}
              hasError={showServedColumn ? !item.servedPrice : !item.price}
            />
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
              ? renderArrowPercentage(priceDiff, 'input')
              : null}
          </S.PriceRow>
          {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
            <S.TextPriceGroup padding="6px 0 0 0">
              {__('Components.ProductDetails.mismatch_price.price')}
              <S.TextLink onClick={() => updateItemPrice('servedPrice', mismatchPriceIssue.name, '')}>
                {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                  currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
              </S.TextLink>
            </S.TextPriceGroup>
          ) : null}
        </S.PriceColumn>
      );
    }
    return (
      <S.PriceEditRow enabled={priceMode === 'edit'} onClick={() => priceMode === 'edit' && setPriceEdit(true)}>
        <S.Price enabled={!!item.price} hasServedChange={hasServedChange} showGrey={showGrey}>
          {currency.getPrice(item.currency, price, pricePrecision)}
        </S.Price>
        <S.PriceUnit showGrey={showGrey}>/{parsers.getUnitText(item.priceUnit, item.weightUnit, 1)}</S.PriceUnit>
        {priceMode === 'edit' ? (
          <S.IconEditWraper>
            <FontIcon name="Edit" />
          </S.IconEditWraper>
        ) : null}
      </S.PriceEditRow>
    );
  };

  /**
   * Render tooltip for mismatch price
   */
  function renderTooltip() {
    return (
      <S.TooltipContainer>
        <S.TooltipPrice
          position="bottom"
          text={__('Components.ProductDetails.mismatch_price.tooltip')}
          width="200px"
          themeMode="dark"
        >
          <S.InfoIcon disableHover={true} name="Info" />
        </S.TooltipPrice>
      </S.TooltipContainer>
    );
  }

  /**
   * Render arrow percentage for price mismatch
   */
  const renderArrowPercentage = (priceDiff: number, from: 'input' | 'price') => {
    if (!priceDiff) return null;
    return (
      <S.ArrowPercentage
        currency={item.currency}
        isDiscount={priceDiff < 0}
        mode="currency"
        rightPosition={`-${from === 'input' ? 30 : 15}%`}
        value={priceDiff}
      />
    );
  };

  /**
   * Render subtotal price
   */
  const renderSubTotal = () => {
    const price = orderService.getTotalPriceItem(item);
    return (
      <S.ItemPriceTotal showGrey={showGrey}>
        {priceMode !== 'none' && price ? currency.getPrice(item.currency, price, pricePrecision) : '-'}
      </S.ItemPriceTotal>
    );
  };

  /**
   * Get price formated or 'P.O.R.' text.
   */
  function priceToShow() {
    if (priceMode === 'none') return '-';
    const price = showServedColumn ? item.servedPrice : item.price;
    if (price) {
      return currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit, price, pricePrecision);
    }
    return __('Components.ProductDetails.por_on');
  }

  /**
   * Show search products modal
   */
  const showProductsModal = React.useCallback(
    (e?: React.MouseEvent, originLot?: number, mappingCode?: string) => {
      e?.stopPropagation();
      showSearchProductModal(item, originLot, mappingCode);
    },
    [item, showSearchProductModal],
  );

  const showPORModal = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      showPORProductModal(item);
    },
    [item, showPORProductModal],
  );

  /**
   * Discard line (remove + solve issue)
   */
  const discardLine = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      // Mark product issue as ignored in order
      issues.forEach(i => orderDiscardLine({ ...i, status: 'ignored' }));
    },
    [issues, orderDiscardLine],
  );

  /**
   * Update POR item price
   * key: price or servedPrice
   */
  const updateItemPrice = React.useCallback(
    (key: string, value: string, error: string) => {
      const v = Number(value);
      if (!error && v > 0 && v !== item.price) {
        const invalidPriceIssue = issues?.find(i => i.type === 'price-not-valid');
        cartUpdateItem({ ...item, [key]: v, isPor: false, servedPrice: v }, invalidPriceIssue);
      }
      setPriceEdit(false);
    },
    [cartUpdateItem, issues, item],
  );
  return (
    <S.Tr>
      {item.servedQuantity ||
      (!item.amount && (orderOrigin === ORDER_ORIGIN.IMPORT_UI || orderOrigin === ORDER_ORIGIN.EMAIL)) ? (
        <>
          <S.CloseTd>
            {deletable ? (
              <S.IconWrapper>
                <FontIcon
                  name="Close"
                  onClick={() =>
                    cartUpdateItem({ ...item, servedQuantity: 0, ...(showServedColumn ? {} : { amount: 0 }) })
                  }
                />
              </S.IconWrapper>
            ) : null}
          </S.CloseTd>
          <S.ImageTd>
            <S.ImageContainer
              onClick={() => {
                navigate?.(item);
              }}
              isClickable={!!navigate}
            >
              <ImageCover
                relation={constants.IMAGE_RELATION.AVATAR}
                relationFixed={true}
                imageUrl={(
                  (item.images && item.images.length && item.images[0].image.url) ||
                  (prodTypes[item.type] && prodTypes[item.type].defaultImageUrl) ||
                  IMAGE_DEFAULT
                ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
              />
            </S.ImageContainer>
          </S.ImageTd>
        </>
      ) : (
        <S.CloseTd colSpan={2}>
          <S.DeleteContainer>
            <S.Deleted>{__('Components.ProductDetails.deleted')}</S.Deleted>
            {amEditor && weAreSeller && editAfterAccept ? (
              <S.Undo onClick={() => cartUpdateItem({ ...item, servedQuantity: item.amount })}>
                {__('Components.ProductDetails.undo')}
              </S.Undo>
            ) : null}
          </S.DeleteContainer>
        </S.CloseTd>
      )}
      <S.ProductTd>
        <S.TypePackagingContainer
          onClick={() => {
            navigate?.(item);
          }}
          isClickable={!!navigate}
        >
          {renderItemTitle()}
        </S.TypePackagingContainer>
      </S.ProductTd>
      <S.Td>{renderQuantity()}</S.Td>
      {showServedColumn ? <S.Td>{renderServedQuantity()}</S.Td> : null}
      {isPrepared ? <S.Td>{renderWeight()}</S.Td> : null}
      {hidePrices || isPrepare ? null : (
        <>
          <S.Td>{renderPriceBlock()}</S.Td>
          <S.Td>{renderSubTotal()}</S.Td>
        </>
      )}
      {isPrepare && !editAfterAccept ? (
        <>
          <S.Td>{renderWeight()}</S.Td>
          <S.Td>{renderPrepared()}</S.Td>
        </>
      ) : null}
      <S.SubtotalTd>
        {!weAreSeller && orderStatus === ORDER_STATUS.ACCEPTED && config.TOGGLE_PRODUCT_LOT.enabled ? (
          <SimpleDropdown
            hAlign="right"
            onSelect={(name: string) => handleLotOptions(name)}
            options={[
              { key: 'new', value: __('Components.ProductDetails.lot.assign_new') },
              { key: 'existing', value: __('Components.ProductDetails.lot.assign_existing') },
            ]}
          >
            <S.KebabIcon name="Kebab" />
          </SimpleDropdown>
        ) : null}
        {showSelectWorkspaceModal ? renderSelectWorkspaceModal() : null}
      </S.SubtotalTd>
    </S.Tr>
  );
};

export default React.memo(OrderItem);
