import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  isDiscount?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
`;

export const ArrowIcon = styled(FontIcon)<IStyledProps>`
  color: ${(p: IStyledProps) => (p.isDiscount ? p.theme.colors.red1 : p.theme.colors.green1)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.small};
  height: ${(p: IStyledProps) => p.theme.fontSize.small};
  transform: ${(p: IStyledProps) => (p.isDiscount ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PercentageText = styled(Text)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => (p.isDiscount ? p.theme.colors.red1 : p.theme.colors.green1)};
  padding-left: 3px;
`;
