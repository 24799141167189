import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms';
import CL from '../../molecules/ContactsList';
import ActionsModal from '../ActionsModal/ActionsModal.component';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
  numImages?: number;
}

export const Scroll = styled.div`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

export const Modal = styled(ActionsModal)`
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: max-content;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  margin: 0;
  min-height: min-content;
`;

export const Actions = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingView};
  min-width: 200px;
  justify-content: flex-end;
`;

export const Role = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ContactsList = styled(CL)`
  min-height: 130px;
`;
