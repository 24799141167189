import {
  Api,
  CHANNEL_TYPE,
  chatActions,
  contactActions,
  modalActions,
  notificationsActions,
  orderActions,
  productService,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import ChatHeader, {
  IChatHeaderDispatchProps,
  IChatHeaderRouteProps,
  IChatHeaderStateProps,
} from '../components/organisms/ChatHeader';
import { IReduxState } from '../reducers';

function mapStateToPropsHeader(state: IReduxState, props: IChatHeaderRouteProps): IChatHeaderStateProps {
  const {
    user: { hideOrdersMagicZero, user },
    catalog: { clients, catalogs },
    product: { prices },
    contact: { inConsentio },
    chat: { channels, unreadMessageCount },
    order: { pendingByUser },
    nav: { workspaceSelected },
    workspace: { suppliers },
  } = state;
  const channelId = props.match.params.channelId;
  const channel = channels[channelId];
  const data = {
    workspaceSelected,
    contacts: inConsentio,
    isClient: false,
    isSupplier: false,
    messagesUnreads: unreadMessageCount?.[channelId] || 0,
    ordersActives: 0,
    me: user,
    channel,
    catalogs,
    subtitle: '',
    avatarColor: { background: '', text: '' },
    title: '',
    hideOrdersMagicZero,
  };
  if (!channel)
    return {
      ...data,
      blocked: true,
    };
  if (channel.type === CHANNEL_TYPE.GROUP) {
    const catalog = catalogs[channel.workspaceId];
    return {
      ...data,
      avatar: channel.imageUrl,
      avatarColor: channel.avatarColor,
      currency: catalog?.defaultCurrency,
      title: channel.name,
      isClient: channel.workspaceId === workspaceSelected,
    };
  }
  const contactId = channel.members?.[0]?.id;
  const contact = inConsentio[contactId];
  if (!contact) {
    return {
      ...data,
      ordersActives: pendingByUser[contactId] || 0,
      title: channel.name,
      avatarColor: channel.avatarColor,
    };
  }
  const catalogKey = productService.getKey(user.sellerWorkspaceId!, contact.id!);
  return {
    ...data,
    ordersActives: pendingByUser[contactId] || 0,
    contact,
    currency: Object.values(prices[catalogKey] || {})[0]?.currency,
    blocked: contact.imBlocked || contact.imBlocking,
    avatar: contact.avatar,
    title: contact.name,
    subtitle: contact.companyName,
    avatarColor: contact.avatarColor,
    isClient: !!clients[workspaceSelected]?.find(c => c.userId === contact.id),
  };
}

function mapDispatchToPropsHeader(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IChatHeaderDispatchProps {
  return bindActionCreators(
    {
      addMembers: chatActions.addMembers,
      navigateChannelBySection,
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      contactsAddressbookGet: contactActions.contactsAddressbookGet,
      contactsInvite: contactActions.contactsInvite,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      channelMute: chatActions.channelMute,
      channelArchive: chatActions.channelArchive,
      contactBlock: contactActions.contactBlock,
      clientsAdd: sellerWorkspaceActions.clientsAdd,
      contactUpdateMySellerWorkspace: contactActions.contactUpdateMySellerWorkspace,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsHeader, mapDispatchToPropsHeader)(ChatHeader);
