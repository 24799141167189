import { Api, notificationsActions, orderActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import MultipleCarts, { IDispatchProps, IOwnProps, IStateProps } from '../components/organisms/MultipleCarts';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: IOwnProps): IStateProps {
  const {
    order: { cart, orders },
    contact: { inConsentio },
    user: { user },
  } = state;

  return {
    cart,
    contacts: inConsentio,
    me: user,
    orders,
    type: 'purchase',
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      orderGenerateNew: orderActions.orderGenerateNew,
      orderNew: orderActions.orderNew,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleCarts);
