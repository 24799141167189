import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  max-height: 100%;
  overflow: hidden;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(7)} ${(p: IStyledProps) => p.theme.paddingSize(12)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  overflow-y: auto;
`;

export const Body = styled(ColumnContainer)``;

export const Title = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextStep = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: left;
`;

const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
`;

export const TextBigRegular = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextDescription = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextBold = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
  text-align: left;
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const DescriptionContainer = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CTA = styled(Button)`
  width: 171px;
`;
