import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: 0;
  align-items: center;
  width: 400px;
  max-width: 400px;
  position: relative;
  &:hover {
    opacity: 0.75;
  }

  .react-player__preview {
    min-height: 250px;
    border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  }

  .react-video-player video {
    border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  }
`;

export const TextWrapper = styled(ColumnContainer)`
  width: auto;
`;

export const TextItalic = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-style: italic;
  padding-right: 2px;
`;

export const DurationContainer = styled(RowContainer)`
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextDuration = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const PlayContainer = styled(RowContainer)`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  align-items: center;
  justify-content: center;
`;

export const PlayIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 42px;
  height: 42px;
  font-size: 42px;
  line-height: 42px;
  position: relative;
  left: 3%;
`;
