import styled, { ITheme } from '../../../styled-components';
import { FontIcon } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: 0;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  max-width: 1456px;
  cursor: pointer;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(6)};

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const Header = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: flex;
  flex-direction: row;
`;

export const TitleBadge = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  color: white;
  padding: 0 7px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  height: 18px;
  justify-content: center;
`;

export const SubTitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};

  @media only screen and (max-width: 1385px) {
    display: none;
  }
`;

export const Benefits = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-between;
`;

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  margin-left: ${(p: IStyledProps) => p.theme.paddingView};
  margin-right: ${(p: IStyledProps) => p.theme.paddingView};
`;

export const BenefitIcon = styled(FontIcon)`
  font-size: 40px;
  height: 40px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const BenefitText = styled.div`
  margin-top: 5px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const CTA = styled.div`
  width: auto;
  min-height: 36px;
  text-align: center;
  align-self: center;
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding: 8px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
