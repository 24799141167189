import 'react-datepicker/dist/react-datepicker.css';

import { __, date as dateUtils } from 'common-services';
import { isBefore } from 'date-fns';
import * as React from 'react';
import DatePicker from 'react-datepicker';

import FontIcon from '../FontIcon';
import * as S from './DatePicker.styled';

export interface IProps {
  className?: string;
  clearable?: boolean;
  editable?: boolean;
  customInput?: React.ReactNode;
  dateFormat?: string;
  id: string;
  initDate?: Date;
  maxDate?: Date;
  minDate?: Date;
  minTimeToday?: Date /* Min time for only today */;
  minTime?: Date /* Generic min and max time for each day */;
  maxTime?: Date;
  onDateChange?: (date?: string) => void;
  openDate?: Date;
  showTimeSelect?: boolean;
  timeIntervals?: number;
}

const DatePickerComponent: React.FC<IProps> = ({
  className,
  clearable = true,
  editable = true,
  customInput,
  dateFormat = 'd MMMM yyyy',
  id,
  initDate,
  maxDate,
  maxTime,
  minDate,
  minTime,
  minTimeToday,
  onDateChange = () => null,
  openDate,
  showTimeSelect = false,
  timeIntervals = 30,
}) => {
  const [date, setDate] = React.useState(initDate);
  React.useEffect(() => {
    if (initDate) {
      setDate(initDate);
    }
  }, [initDate]);
  const datePickerId = 'date-picker-' + id;
  const finalDateFormat = showTimeSelect ? dateFormat + ' · HH:mm' : dateFormat;
  return (
    <S.Container className={className} showTimeSelect={showTimeSelect}>
      {customInput ? (
        <DatePicker
          className="date-picker"
          dateFormat={finalDateFormat}
          id={datePickerId}
          maxDate={maxDate}
          maxTime={maxTime}
          minDate={minDate}
          minTime={minTime}
          name={datePickerId}
          onChange={newDate => handleChange(newDate)}
          openToDate={date || openDate}
          placeholderText={__('Components.OrderLogistics.select_date')}
          selected={date}
          popperPlacement="top-end"
          readOnly={!editable}
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          customInput={customInput}
        />
      ) : (
        <S.DatePickerWrapper>
          <DatePicker
            className="date-picker"
            dateFormat={finalDateFormat}
            id={datePickerId}
            maxDate={maxDate}
            maxTime={maxTime}
            minDate={minDate}
            minTime={minTime}
            name={datePickerId}
            onChange={newDate => handleChange(newDate)}
            openToDate={date || openDate}
            placeholderText={__('Components.OrderLogistics.select_date')}
            readOnly={!editable}
            selected={date}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
          />
          {date && clearable ? (
            <S.FontIconWrapper onClick={() => clearDate()} id="date-picker-close-icon">
              <FontIcon name="Close" />
            </S.FontIconWrapper>
          ) : (
            <S.FontIconWrapper id="date-picker-calendar-icon" editable={editable}>
              <FontIcon
                name="Calendar"
                onClick={() => {
                  const s = document.querySelector(`[name=date-picker-${id}]`) as HTMLInputElement;
                  if (s) s.focus();
                }}
              />
            </S.FontIconWrapper>
          )}
        </S.DatePickerWrapper>
      )}
    </S.Container>
  );

  /**
   * Handle change when a new date is selected.
   *  - Adjust timezone
   *  - If minTimeToday set, adjust date time
   */
  function handleChange(newDate: Date) {
    let dateResult = adjustTimezoneIfNeeded(newDate);
    if (minTimeToday && isBefore(dateResult, minTimeToday))
      dateResult = dateUtils.setSpecificDateTime(
        dateResult,
        minTimeToday.getHours(),
        minTimeToday.getMinutes(),
        minTimeToday.getSeconds(),
      );
    setDate(dateResult);
    onDateChange(dateUtils.dateObjToText(newDate));
  }

  /**
   * Remove date and clear input
   */
  function clearDate() {
    setDate(undefined);
    onDateChange(undefined);
  }
};

/**
 * Adjust timezone to date if hours is set to 0
 */
function adjustTimezoneIfNeeded(date: Date): Date {
  if (date.getHours() > 0) return date;
  date.setHours(0);
  return date;
}

export default React.memo(DatePickerComponent);
