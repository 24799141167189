import { utils } from 'common-services';
import * as React from 'react';

import { FontIcon, LettersAvatar, SimpleDropdown } from '../../atoms';
import * as S from './MemberSelect.styled';

/**
 *  Render a member management dropdown.
 */
const MemberSelect: React.FC<{
  memberIdSelected: number;
  memberIds: Array<number>;
  contacts: { [id: number]: IContact };
  className?: string;
  disabled: boolean;
  me?: IUser;
  setMemberIdSelected: (id: number) => void;
}> = ({ contacts, memberIds, className, disabled, me, memberIdSelected, setMemberIdSelected }) => {
  if (!memberIds.length) return null;
  const contactSelected = contacts[memberIdSelected];
  let membersParsed: Array<{
    id?: number;
    name: string;
    avatarColor: { background: string; text: string };
    avatar?: string;
  }>;
  if (me) {
    membersParsed = [
      { ...me, avatarColor: utils.getAvatarColor(me.name), avatar: me.settings.avatar },
      ...memberIds.map(mId => contacts[mId]).filter(c => c),
    ];
  } else {
    membersParsed = memberIds.map(mId => contacts[mId]).filter(c => c);
  }
  const member = contactSelected || membersParsed[0];
  const name = member?.name || '';
  const avatar = member?.avatar || '';
  const avatarColor = member?.avatarColor;
  return (
    <SimpleDropdown
      className={className}
      disabled={disabled}
      hAlign="left"
      vAlign="flex-start"
      onSelect={v => setMemberIdSelected(Number(v))}
      options={membersParsed.map(m => {
        return {
          key: m.id + '',
          value: m.name,
          avatar: <LettersAvatar avatarColor={m.avatarColor} img={m.avatar} size={30} text={m.name} />,
        };
      })}
      margin="12px 0 0 0"
    >
      {disabled ? (
        <S.MemberSelectedColumn>
          <S.MemberSelectedValue>{name}</S.MemberSelectedValue>
        </S.MemberSelectedColumn>
      ) : (
        <S.MemberSelected>
          <LettersAvatar avatarColor={avatarColor} img={avatar} size={30} text={name} />
          <S.MemberSelectedColumn>
            <S.MemberSelectedValue>{name}</S.MemberSelectedValue>
          </S.MemberSelectedColumn>
          <S.DownIconWrapper>
            <FontIcon name="Down" />
          </S.DownIconWrapper>
        </S.MemberSelected>
      )}
    </SimpleDropdown>
  );
};

export default React.memo(MemberSelect);
