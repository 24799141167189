import styled, { ITheme, css } from '../../../styled-components';
import { Badge as B, FontIcon, RowContainer, TextLabel, Tooltip as TooltipAtom } from '../../atoms';

interface IStyledProps {
  color?: string;
  hasImage?: boolean;
  maxWidth?: string;
  overflowX?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const Tabs = styled(RowContainer)<IStyledProps>`
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  ${(p: IStyledProps) =>
    p.overflowX &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
    `};
`;

export const Tab = styled(RowContainer)`
  align-items: center;
  min-height: calc(${(p: IStyledProps) => p.theme.headerHeight} - 2px);
  max-height: calc(${(p: IStyledProps) => p.theme.headerHeight} - 2px);
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-bottom: 2px solid ${(p: IStyledProps) => p.theme.colors.black};
  border-color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.black : p.theme.colors.transparent)};
  color: ${(p: IStyledProps) => p.color || (p.selected ? p.theme.colors.black : p.theme.colors.grey1)};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  box-sizing: content-box;
  cursor: pointer;
  padding-top: 2px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  }
`;

export const TabContainer = styled(RowContainer)`
  align-items: center;
  height: auto;
  color: inherit;
  font-weight: inherit;
`;

export const TabRowContainerRight = styled(RowContainer)`
  flex: 1;
  justify-content: flex-end;
  color: inherit;
  font-weight: inherit;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: inherit;
  font-weight: inherit;
  padding-left: ${(p: IStyledProps) => (p.hasImage ? p.theme.paddingSize(1.5) : 0)};
  max-width: ${(p: IStyledProps) => p.maxWidth || 'max-content'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  }
`;

export const Chevron = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: inherit;
  font-weight: inherit;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Badge = styled(B)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const New = styled.div`
  display: flex;
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  min-width: 40px;
  max-width: 60px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0px 5px;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding: 1px 5px 0 5px;
`;

export const Tooltip = styled(TooltipAtom)`
  margin-left: 5px;
  align-self: center;
`;
export const AlertIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: 1.125rem;
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const Icon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.color || p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const IconCart = styled(Icon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  width: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  height: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0)};
`;