import {
  Api,
  broadcastActions,
  chatActions,
  contactActions,
  modalActions,
  notificationsActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  chatGroupsExpandedSet,
  navChannelAction,
  navigateChannelByPath,
  navLastChannelSet,
  navMiniChannelAction,
} from '../actions/nav';
import * as notificationActions from '../actions/notification';
import ChatList, {
  IChatListDispatchProps,
  IChatListRouteProps,
  IChatListStateProps,
} from '../components/organisms/ChatList';
import { REGISTER_STEPS } from '../constants';
import { IReduxState } from '../reducers';

function mapStateToPropsChatList(state: IReduxState, props: IChatListRouteProps): IChatListStateProps {
  const {
    broadcast,
    catalog: { catalogs, clients },
    workspace: { workspaces, suppliers },
    chat: { channels, lastMessageAt, unreadMessageCount },
    contact: { inConsentio, hasOutContacts },
    nav: { auth, chatGroupsExpanded, lastChannelId, workspaceSelected },
    notification: { showHeader },
    order,
    user: { user },
  } = state;
  return {
    broadcastLastMessageAt: broadcast.lastMessageAt,
    broadcasts: broadcast.broadcasts,
    channels,
    chatGroupsExpanded,
    contacts: inConsentio,
    disabled: auth === REGISTER_STEPS.INVITE,
    hasOutContacts,
    lastChannelId,
    lastMessageAt,
    me: user,
    pendingOrders: order.pendingByUser,
    showNotificationHeader: showHeader,
    unreadMessageCount,
    workspaceSelected: catalogs[workspaceSelected] || workspaces[workspaceSelected],
  };
}

function mapDispatchToPropsChatList(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IChatListDispatchProps {
  return bindActionCreators(
    {
      accessedByInvitationHashId: contactActions.accessedByInvitationHashId,
      channelArchive: chatActions.channelArchive,
      channelMute: chatActions.channelMute,
      chatGroupsExpandedSet,
      contactsAddressbookGet: contactActions.contactsAddressbookGet,
      contactsInvite: contactActions.contactsInvite,
      createChannel: chatActions.createChannel,
      createNewBroadcast: broadcastActions.createBroadcast,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navChannelAction,
      navigateChannelByPath,
      navLastChannelSet,
      navMiniChannelAction,
      notificationShow: notificationsActions.notificationShow,
      setChannelsAction: chatActions.setChannelsAction,
      showNotificationRequestPermission: notificationActions.showRequestPermission,
      updateBroadcast: broadcastActions.updateBroadcast,
      updateUser: userActions.updateUser,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChatList, mapDispatchToPropsChatList)(ChatList);
