import { ORDER_STATUS } from 'common-services';
import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TagBubble, TextLabel, Tooltip } from '../../atoms';

interface IStyledProps {
  align?: 'flex-start' | 'flex-end' | 'center';
  amSeller?: boolean;
  checked?: boolean;
  isRead?: boolean;
  overlay?: boolean;
  selectable?: boolean;
  showFooter?: boolean;
  status?: ORDER_STATUS;
  textAlign?: string;
  theme: ITheme;
  width?: string;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
`;

// Generic styles for each column (header + rows)
export const FillCol = styled(ColumnContainer)`
  align-items: ${(p: IStyledProps) => p.align || 'flex-start'};
  min-width: 22px;
  width: 30px;
  max-width: 30px;
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};

  ${(p: IStyledProps) =>
    p.selectable &&
    css`
      width: 60px;
      max-width: 60px;
    `};
`;

export const Fill = styled(FillCol)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  position: relative;
`;
const Col = styled(ColumnContainer)`
  align-items: ${(p: IStyledProps) => p.align || 'flex-start'};
  min-width: 50px;
  vertical-align: middle;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-overflow: ellipsis;
  max-width: 170px;
`;

export const ContactCol = styled(ColumnContainer)`
  align-items: ${(p: IStyledProps) => p.align || 'flex-start'};
  max-width: 200px;
  vertical-align: middle;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  min-width: 70px;
`;

export const CreatedCol = styled(Col)`
  width: 50px;
`;

export const ArticlesCol = styled(Col)`
  width: 40px;
  max-width: 40px;
`;

export const DeliveryCol = styled(Col)`
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// Value styles for data rows

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PriceCol = styled(Col)`
  align-items: center;
`;

export const PriceFlexRow = styled(RowContainer)`
  align-items: center;
`;

export const AmountFlexRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
`;

export const PriceText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: right;
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TooltipPrice = styled(Tooltip)``;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const ReadCol = styled(ColumnContainer)`
  align-items: ${(p: IStyledProps) => p.align || 'flex-start'};
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: right;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
`;
export const HashText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
`;
export const TextLight = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
`;

export const TextIssue = styled(Text)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.blue1)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
`;

export const TextGrey = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const TextSecondary = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Light : p.theme.fontWeight.Regular)};
`;

export const TextArticles = styled(TextBlack)`
  text-align: center;
`;

export const TextSmall = styled(Text)`
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const RadioCheckmark = styled.span`
  background-color: ${(p: IStyledProps) => (p.checked ? p.theme.colors.green1 : p.theme.colors.white)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => (p.checked ? 'none' : p.theme.colors.grey2)};
  width: 18px;
  height: 18px;
  padding: 0 2px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const RadioLabel = styled.label`
  display: block;
  position: relative;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};

  input:checked + span {
    background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  }

  input:checked + span:after {
    display: block;
  }
`;

export const FlexColumnDel = styled(ColumnContainer)`
  text-overflow: ellipsis;
  width: 100%;
`;

export const FlexColumn = styled(ColumnContainer)``;
export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

const Link = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const LinkBlue = styled(Link)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const ReadWrapper = styled(RowContainer)`
  max-width: min-content;
  height: max-content;
  position: relative;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  & .readText {
    display: none;
  }
  &:hover {
    background-color: ${(p: IStyledProps) => (p.isRead ? p.theme.colors.white : p.theme.colors.grey3)};
    & .readText {
      display: block;
    }
  }
`;

export const ReadIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const ReadText = styled.span`
  position: absolute;
  top: -25px;
  right: -10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  white-space: nowrap;
  &::after {
    content: '';
    position: absolute;
    right: 27px;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #e8e8e8;
    clear: both;
  }
`;

export const TotalResultsContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
`;
