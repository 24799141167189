import styled, { ITheme } from '../../../styled-components';
import { FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isDragging?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  align-items: center;
`;

export const SectionRow = styled(RowContainer)`
  padding: 8px 5px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 42px;
  max-height: 42px;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  align-items: center;
  opacity: ${(p: IStyledProps) => (p.isDragging ? 0 : 1)};
  cursor: ${(p: IStyledProps) => (p.isDragging ? 'grabbing' : 'grab')};
`;

export const TextCell = styled.div`
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  vertical-align: center;
`;

export const OptionsCell = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  min-width: 92px;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
`;

export const TextNumber = styled(Text)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 8px ${(p: IStyledProps) => p.theme.paddingObject};
  padding-left: 0;
  min-width: 24px;
`;

export const TextTag = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingObject};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.75)')};
  font-size: 16px;
  width: 16px;
  margin: 0 5px;
`;

export const CloseIcon = styled(Icon)`
  margin: 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const Divider = styled.div`
  width: 1px;
  min-height: 100%;
  height: 22px;
  margin: 0 10px;
  border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;
