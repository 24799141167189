import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FilterSwitch as FS, FontIcon, RowContainer, TextLabel } from '../../atoms';
import SimpleSearchComponent from '../../molecules/SimpleSearch/SimpleSearch.component';

interface IStyledProps {
  disabled?: boolean;
  numHeaders?: number;
  position?: 'top' | 'below';
  theme: ITheme;
}

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: min(500px, 100%);
  // Fix to refresh element in safari
  transform: translate3d(0, 0, 0);
`;

export const SubHeaderDashboard = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const DashboardContainer = styled(RowContainer)`
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 100%;
  width: 100%;

  .orders-widget:first-child {
    margin-left: 0;
  }
  .orders-widget:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    overflow-x: auto;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    justify-content: start;
  }
`;

export const FilterTab = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  box-shadow: none;
  border: none;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }

  &:hover {
    color: ${(p: IStyledProps) => p.theme.colors.black};
  }
`;

export const Container = styled(ColumnContainer)`
  flex-grow: 1;
  overflow: hidden;
  align-items: flex-start;
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ScrollContainer = styled(ColumnContainer)`
  flex: 1;
  overflow: auto;
  width: 100%;
`;

const OrdersContainer = styled(ColumnContainer)`
  overflow: hidden;
  flex: 1;
  width: 100%;
`;

export const OrdersContainerRow = styled(RowContainer)`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const ShowroomContainer = styled(OrdersContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const ShowroomContainerRow = styled(OrdersContainerRow)``;

export const BackContainer = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
`;

export const Back = styled(RowContainer)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const CartContainer = styled(RowContainer)`
  position: absolute;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 100%;
  width: 100%;
  z-index: 2;
  ${(p: IStyledProps) =>
    p.numHeaders
      ? css`
          min-height: calc(${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight} * ${p.numHeaders});
        `
      : css`
          top: 0;
        `};
`;

export const FilterSwitch = styled(FS)`
  height: 37px;
`;

export const LeftFiltersRow = styled(RowContainer)`
  align-items: center;
`;

export const TextTableHeader = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const ModalShare = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  padding: 40px;
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  justify-content: center;
  align-items: center;
`;

export const HeaderDashboards = styled(ColumnContainer)`
  width: 100%;
`;

export const HeaderFilters = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const RightCtaRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

export const SimpleSearch = styled(SimpleSearchComponent)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 235px;
  max-width: 492px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 185px;
  }
`;
export const MOButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;
