import { constants } from 'common-services';
import * as React from 'react';

import * as S from './ImageThumbnail.styled';

export interface IProps {
  className?: string;
  imagePreview: IImagePreview;
  isSelected: boolean;
  onDeleteClick: () => void;
  onImageClick: () => void;
}

const ImageThumbnail: React.FC<IProps> = React.memo(
  ({ className, imagePreview, isSelected, onDeleteClick, onImageClick }) => {
    const isImageFile = imagePreview.file?.type ? (imagePreview.file?.type).startsWith('image/') : true;
    let filePreview =
      imagePreview.file && isImageFile
        ? `data:${imagePreview.file.type};base64,${imagePreview.file.content}`
        : imagePreview.imageUrl;
    if (!isImageFile) filePreview = constants.getLogoFromMime(imagePreview.file?.type);
    return (
      <S.ImageThumbnailContainer className={className} isImage={isImageFile} selected={isSelected}>
        <S.ImageThumbnail
          alt={imagePreview.file?.name || ''}
          isImage={isImageFile}
          onClick={() => onImageClick()}
          src={filePreview}
        />
        <S.DeleteIcon name="Delete" onClick={() => onDeleteClick()} />
      </S.ImageThumbnailContainer>
    );
  },
);

export default ImageThumbnail;
