import {
  __,
  addresses as addressUtils,
  ColumnConfigType,
  countrySelectors,
  currency,
  date,
  EventTrack,
  parsers,
  prodTypeSelectors,
  PRODUCT_UNIT,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../bindings/config';
import { AVAILABILITY, getProductColumns, getProductColumnsAlternative, IMAGE_DEFAULT } from '../../../constants';
import { navSelectors } from '../../../selectors';
import { FontIcon, Select } from '../../atoms';
import { ContactCell, Table } from '../../molecules';
import { TYPE_CELL } from '../../molecules/Table/Table.component';
import * as S from './ProductTable.styled';
import { priceUnitTranslate } from '../../../util/utils';
import { getColumnField } from './ColumnsField';
import { getNonRemovableColumns, onClickRow, onKeyDownPrice, translateColumns } from './Functions';

interface IProps {
  addresses?: Array<IAddress>;
  cartItems?: Array<IOrderItem>;
  carts?: Record<string, ICart>;
  cartUpdateItem?: (item: IOrderItem) => void;
  catalog: IWorkspace;
  clickProduct?: (productId: number) => void;
  configId: ColumnConfigType;
  disabled?: boolean;
  excludedProducts?: Array<number>;
  hasOverflow?: boolean;
  me: IUser;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onSelectProduct?: (product: IProduct) => void;
  onShareProduct?: (productId?: number, productHashId?: string) => void;
  place?: string;
  precision: number;
  prices?: { [key: number]: IPrice };
  products: Array<IProduct>;
  productUpdate?: (catalogId: number, product: IProduct, cb?: () => void) => void;
  productDelete?: (productId: number, cb?: () => void) => void;
  role: ICatalogRole;
  searchState?: ISearch;
  selectedProducts?: Array<number> | 'all';
  selectMode?: 'integration' | 'multiple' | 'offers';
  setFocus?: (dataSelector: string) => void;
  showCustomColumns: boolean;
  suppliers?: Array<ISuppliersSearch>;
  totalResults?: number;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  updateSortMode?: (sortMode: string) => void;
}

const ProductTable: React.FC<IProps> = ({
  addresses,
  cartItems,
  carts,
  cartUpdateItem,
  catalog,
  clickProduct,
  configId,
  disabled,
  excludedProducts,
  hasOverflow = true,
  me,
  onScroll,
  onSelectProduct,
  onShareProduct,
  place,
  precision,
  prices,
  products,
  productDelete,
  productUpdate,
  role,
  searchState,
  selectedProducts,
  selectMode,
  setFocus,
  showCustomColumns,
  suppliers,
  totalResults,
  touchImage,
  updateSortMode,
}) => {
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const columnConfigWorkspace = useSelector(sellerWorkspaceSelectors.getColumnConfig(workspaceSelected?.id, configId));
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const isCatalogActive = sellerWorkspaceService.isActive(catalog, me.id);
  const countries = useSelector(countrySelectors.getCountries);
  const dispatch = useDispatch<any>();
  React.useEffect(() => {
    if (showCustomColumns && me.id && workspaceSelected?.id && configId.startsWith('product_list'))
      dispatch(sellerWorkspaceActions.tableVisibilityConfigGet(me.id, workspaceSelected.id, configId));
  }, [dispatch, me, workspaceSelected?.id, configId]);

  const getColumns = React.useCallback(() => {
    // Default share option
    const result = onShareProduct
      ? [
          getColumnField(
            { name: 'share', order: -1, visible: true },
            catalog.id,
            catalog.mandatoryPalletizationByClient,
            {
              getElement: (data: IProduct) =>
                isCatalogActive ? (
                  <S.ShareTouchableTable
                    className="product-item-share-icon"
                    iconName="Share"
                    iconSize="14px"
                    withoutPadding={true}
                    type={data.status === 'inactive' ? 'skip' : 'link'}
                    disableHover={data.status === 'inactive'}
                    disabled={data.status === 'inactive'}
                    onClick={e => {
                      e.stopPropagation();
                      if (data.status !== 'inactive') onShareProduct(data.id!, data.hashId);
                    }}
                    title={data.status === 'inactive' ? __('Components.ProductsList.Table.shareTooltip') : undefined}
                  />
                ) : null,
            },
          ),
        ]
      : [];

    const configCol = columnConfigWorkspace?.columns?.length
      ? (columnConfigWorkspace?.columns as Array<IProductColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig(configId);
    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        const isLast = columnIndex === arr.length - 1;
        switch (column.name) {
          case 'title':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getValue: (data: IProduct) =>
                  data.productTitle ||
                  productService.getProductTypeVarietyDisplay(
                    data.type.type,
                    prodTypes[data.type.type]?.name || '',
                    data.type.variety,
                  ),
              }),
            );
            break;
          case 'variety':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getValue: (data: IProduct) => data.type.variety,
              }),
            );
            break;
          case 'type':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getValue: (data: IProduct) => prodTypes[data.type.type]?.name || '',
              }),
            );
            break;
          case 'title-with-image':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getElement: (data: IProduct) => {
                  return (
                    <ContactCell
                      avatar={(
                        (data.images && data.images.length && data.images[0].image.url) ||
                        (prodTypes[data.type.type] && prodTypes[data.type.type].defaultImageUrl) ||
                        IMAGE_DEFAULT
                      ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
                      imgText={'image text'}
                      primaryText={
                        data.productTitle ||
                        `${productService.getProductTypeVarietyDisplay(
                          data.type.type,
                          prodTypes[data.type.type]?.name || '',
                          data.type.variety,
                        )} ${data.size}`
                      }
                      showOver={true}
                    />
                  );
                },
              }),
            );
            break;

          case 'supplier':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getElement: (data: IProduct) => {
                  const supplier = suppliers.find(s => s.workspaceId === data.catalogId);
                  if (!supplier) return null;
                  return (
                    <ContactCell
                      avatar={supplier.companyLogo}
                      avatarColor={utils.getAvatarColor(supplier.companyName)}
                      imgText={supplier.companyName}
                      isRead={true}
                      primaryText={supplier.companyName}
                      secondaryText={supplier.name}
                    />
                  );
                },
              }),
            );
            break;

          case 'updated':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getValue: (data: IProduct) =>
                  date.timeToText(data.updatedAt, me.settings.hourFormat, undefined, undefined, me.settings.dateFormat),
              }),
            );
            break;

          case 'price-display':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getElement: (data: IProduct) => {
                  const price = data.price || prices?.[data.id!]?.price;
                  return price ? (
                    <S.TextBoldBlack>
                      {price.toFixed(precision)}
                      {currency.getPricePerUnit(
                        data.currency,
                        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(data.catalogId)
                          ? priceUnitTranslate(data.priceUnit)
                          : data.priceUnit,
                        data.weightUnit,
                      )}
                    </S.TextBoldBlack>
                  ) : (
                    <S.TextBlack>{'-'}</S.TextBlack>
                  );
                },
              }),
            );
            break;

          case 'price':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getElement: (data: IProduct, idx: number, rowIdx: number) =>
                  data.isPor ? (
                    <S.POR>
                      {__('Components.ProductsList.Table.POR').toLocaleUpperCase()}
                      <S.HiddenInput
                        data-selector={'price_' + rowIdx}
                        tabIndex={idx * 2 + 1}
                        onKeyDown={e => onKeyDownPrice(e, rowIdx, setFocus)}
                      />
                    </S.POR>
                  ) : (
                    <S.PriceInput
                      disabled={disabled}
                      dataSelector={'price_' + rowIdx}
                      tabIndex={idx * 2 + 1}
                      containerMargin={`5px ${isLast ? 6 : 18}px 5px 0`}
                      name={data.id!.toString()}
                      value={data.price.toString()}
                      type="number"
                      minValue={currency.getPriceInputMinimumValue(precision)}
                      onBlur={(key: string, value: string, error: string) => {
                        if (!error && Number(value) > 0 && Number(value) !== data.price) {
                          data.price = Number(value);
                          productUpdate?.(catalog.id, { ...data, price: Number(value) });
                        }
                      }}
                      precision={precision}
                      variableTextSingular={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
                      variableTextPlural={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
                      onKeyDown={e => onKeyDownPrice(e, rowIdx, setFocus)}
                    />
                  ),
              }),
            );
            break;

          case 'status':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getDataCell: (data: IProduct, idx: number, rowIdx: number) => {
                  return {
                    isLast,
                    customOption: o => {
                      return (
                        <S.OptionRow {...o.innerProps} isSelected={o.isSelected}>
                          <S.StatusBubbleOption status={o.data.value as any} />
                          <S.TextOption>{o.data.label}</S.TextOption>
                        </S.OptionRow>
                      );
                    },
                    onChange: (name, val) => {
                      productUpdate?.(catalog.id, { ...data, price: data.price, status: val as IStatus });
                    },
                    value: data.status,
                    dataSelector: 'status_' + rowIdx,
                    options: [
                      { label: __('Components.Switch.available'), value: AVAILABILITY.ACTIVE },
                      { label: __('Components.Switch.unavailable'), value: AVAILABILITY.UNAVAILABLE },
                      { label: __('Components.Switch.hidden'), value: AVAILABILITY.INACTIVE },
                    ],
                    disabled: catalog.type === 'buyer' ? true : disabled,
                    showStatusBubble: true,
                    type: TYPE_CELL.DROPDOWN,
                    footer:
                      data.status === AVAILABILITY.UNAVAILABLE && data.availableSoon?.availableFrom
                        ? `Est: ${date.formatLongDate(
                            data.availableSoon.availableFrom,
                            undefined,
                            undefined,
                            'EEE, dd MMM yyyy',
                          )}`
                        : null,
                  };
                },
              }),
            );
            break;

          case 'warehouse':
            result.push(
              getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                getValue: (data: IProduct) => {
                  const address =
                    data.warehouseId && addresses ? addresses.find(a => a.id === data.warehouseId) : undefined;
                  return address ? addressUtils.getAddressName(address, countries) : '';
                },
              }),
            );
            break;
          case 'product-id':
            if (config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled) {
              result.push(
                getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient, {
                  getValue: (data: IProduct) => {
                    return data.hashId;
                  },
                }),
              );
            }
            break;

          default:
            result.push(getColumnField(column, catalog.id, catalog.mandatoryPalletizationByClient));
            break;
        }
      });
    return result;
  }, [
    addresses,
    cartItems,
    carts,
    catalog,
    columnConfigWorkspace,
    countries,
    disabled,
    excludedProducts,
    isCatalogActive,
    me,
    onSelectProduct,
    onShareProduct,
    precision,
    prices,
    prodTypes,
    productUpdate,
    selectedProducts,
    selectMode,
    suppliers,
    touchImage,
  ]);
  const columns = getColumns().filter(c => c);
  if (configId === 'product_list_modal') {
    columns.push(
      getColumnField(
        { name: 'select-product', order: 15, visible: true },
        catalog.id,
        catalog.mandatoryPalletizationByClient,
        {
          getElement: (data: IProduct) => {
            if (selectMode === 'integration') {
              if (catalog.productMappingStrategy === 'sku' && !data.sku) {
                return (
                  <S.RefMissingRow>
                    <S.ErrorIcon name="Error" disableHover={true} />
                    <S.TextGrey>{__('Components.ProductCard.sku_missing_short')}</S.TextGrey>
                  </S.RefMissingRow>
                );
              } else if (catalog.productMappingStrategy === 'ean_code' && !data.eanCode) {
                return (
                  <S.RefMissingRow>
                    <S.ErrorIcon name="Error" disableHover={true} />
                    <S.TextGrey>{__('Components.ProductCard.code_missing_short')}</S.TextGrey>
                  </S.RefMissingRow>
                );
              }
            }
            if (selectMode === 'offers') {
              if (!data.sku) {
                return (
                  <S.RefMissingRow>
                    <S.ErrorIcon name="Error" disableHover={true} />
                    <S.TextGrey>{__('Components.ProductCard.sku_missing_short')}</S.TextGrey>
                  </S.RefMissingRow>
                );
              }
            }

            const isSelected =
              selectedProducts === 'all'
                ? !(excludedProducts || []).includes(data.id)
                : (Array.isArray(selectedProducts) ? selectedProducts : []).includes(data.id);

            return (
              <S.RefMissingRow>
                <S.SelectButton type={isSelected ? 'principal' : 'other'} onClick={() => onSelectProduct?.(data)}>
                  {isSelected ? __('Components.ProductCard.selected') : __('Components.ProductCard.select')}
                </S.SelectButton>
              </S.RefMissingRow>
            );
          },
        },
      ),
    );
  }
  if (configId === 'product_list_supplier_contract') {
    columns.unshift(
      getColumnField({ name: 'delete', order: 0, visible: true }, catalog.id, catalog.mandatoryPalletizationByClient, {
        getElement: (data: IProduct) => {
          return (
            <S.Close>
              <FontIcon name="Close" onClick={() => productDelete(data.id)} />
            </S.Close>
          );
        },
      }),
    );
  }

  if (['product_list_buyer', 'product_list_showroom', 'product_list_showroom_buyer'].includes(configId)) {
    columns.push(
      getColumnField(
        { name: 'add-product-to-cart', order: 15, visible: true },
        catalog.id,
        catalog.mandatoryPalletizationByClient,
        {
          getElement: (data: IProduct) => {
            const supplier = suppliers?.find(s => s.workspaceId === data.catalogId);
            const key = productService.getKey(supplier?.userId || 0, me.id);
            const cartItem = (carts?.[key]?.items || cartItems)?.find(c => c.childId === data.id);
            const genericProduct = parsers.productToGenericProduct(data);

            if (cartItem?.amount) {
              return (
                <S.CartRowCenter onClick={e => e.stopPropagation()}>
                  <CartUpdateInputs item={cartItem} cartUpdateItem={cartUpdateItem} />
                </S.CartRowCenter>
              );
            }

            return (
              <S.CartRow onClick={e => e.stopPropagation()}>
                {data.status !== 'active' ? (
                  <S.Tooltip
                    position={'bottom-right'}
                    text={__('Components.ProductCard.out_of_stock_tooltip')}
                    width="400px"
                    className="tooltip"
                    themeMode="dark"
                  >
                    <S.InfoIcon name="Info" disableHover={true} />
                  </S.Tooltip>
                ) : null}
                <S.AddButton
                  type="other"
                  id="add-to-cart"
                  onClick={e => {
                    e.stopPropagation();
                    cartUpdateItem?.(
                      parsers.genericProductToOrderItem(
                        genericProduct,
                        data.price,
                        1,
                        data.baseMarginPercentage,
                        false,
                      ),
                    );
                  }}
                  disabled={data.status !== 'active'}
                >
                  {__('Components.ProductCard.add')}
                </S.AddButton>
              </S.CartRow>
            );
          },
        },
      ),
    );
  }
  const myRole = workspaceSelected ? sellerWorkspaceService.getRole(workspaceSelected, me.id) : 'viewer';
  return (
    <S.TableContainer className="products-container" onScroll={onScroll} hasOverflow={hasOverflow}>
      {searchState ? (
        <Stats
          place={place}
          processingTimeMS={2}
          role={role}
          searchState={searchState}
          totalResults={totalResults}
          workspaceId={catalog.id}
        />
      ) : null}
      <Table
        columns={config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalog.id) ? translateColumns(columns) : columns}
        updateSortMode={updateSortMode ? (id, direction) => updateSortMode(`${id} ${direction}`) : undefined}
        sort={searchState?.sort}
        sortOrder={searchState?.sortOrder}
        configId={configId}
        emptyText={''}
        nonRemovableColumns={getNonRemovableColumns(configId)}
        onClickRow={(data, e) => onClickRow(e, data.id, clickProduct)}
        rowCursor={disabled ? 'default' : 'pointer'}
        selectable={false}
        showCustomColumns={showCustomColumns && myRole !== 'viewer'}
        showStickyHeader={!['product_list_modal', 'product_list_supplier_contract'].includes(configId)}
        values={products}
        scrollClassName="products-container"
        productColumns={
          config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalog.id)
            ? getProductColumnsAlternative()
            : getProductColumns()
        }
      />
    </S.TableContainer>
  );
};

export default React.memo(ProductTable);

interface ICartUpdateInputsProps {
  item: IOrderItem;
  cartUpdateItem: (item: IOrderItem) => void;
}


const CartUpdateInputs = ({ cartUpdateItem, item }: ICartUpdateInputsProps) => {
  return (
    <S.FlexRow onClick={e => e.stopPropagation()}>
      <S.CloseIcon name="Close" onClick={() => cartUpdateItem({ ...item, amount: 0, servedQuantity: 0 })} />
      <S.AmountInput
        id={`amount-${item.id}`}
        name="amount"
        onBlur={(key: string, value: string, error: string) => {
          const v = Number(value);
          cartUpdateItem({ ...item, amount: v, servedQuantity: v });
        }}
        value={item.servedQuantity || 0}
        minValue={0}
        precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
        width="76px"
        type="number"
        textAlign="right"
        autoFocus={false}
        hasError={!item.servedQuantity}
        containerMargin="0 6px 0 0"
      />
      <Select
        containerMargin="0"
        value={item.servedSaleUnit}
        name={`served-sale-unit-${item.id}`}
        options={item.saleUnits.map(s => ({
          value: s,
          label: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(item.catalogId)
            ? parsers.getUnitTextAbbreviation(priceUnitTranslate(s), item.weightUnit)
            : parsers.getUnitTextAbbreviation(s, item.weightUnit),
        }))}
        onChange={(n, v) => {
          cartUpdateItem({
            ...item,
            saleUnit: v as PRODUCT_UNIT,
            servedSaleUnit: v as PRODUCT_UNIT,
          });
        }}
        width="76px"
      />
    </S.FlexRow>
  );
};

interface IStatsProp {
  place?: string;
  processingTimeMS?: number;
  role?: string;
  searchState: ISearch;
  totalResults?: number;
  workspaceId: number;
}

const Stats: React.FC<IStatsProp> = ({ processingTimeMS, searchState, totalResults, workspaceId }) => {
  React.useEffect(() => {
    if (searchState.text)
      EventTrack.track('workspace_products_search', {
        results: totalResults,
        text: searchState.text,
        workspace_id: workspaceId,
      });
  }, [totalResults, searchState.text, workspaceId]);
  return (
    <S.StatsRow>
      <S.SearchStats>
        {__('Components.ProductsList.SearchStats', {
          hits: totalResults,
          seconds: (processingTimeMS / 1000).toFixed(3),
        })}
      </S.SearchStats>
    </S.StatsRow>
  );
};
