import { Api, chatActions, contactActions, imageActions, modalActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navMiniChannelAction } from '../actions/nav';
import { IChatListRouteProps } from '../components/organisms/ChatList';
import ChatPublic, { IDispatchProps, IRouteProps, IStateProps } from '../components/organisms/ChatPublic';
import { IReduxState } from '../reducers';

const emptyMessages = [];

function mapStateToPropsChat(state: IReduxState, props: IRouteProps): IStateProps {
  const channelId = props.match.params.channelId;
  const {
    chat: { channels, initialized, lastMessageAt, lastReadContactAt, messages, unreadMessageCount },
    contact: { inConsentio },
    user: { user },
  } = state;
  const channel = channels[channelId];
  const chatStateProps = {
    channel,
    channels,
    contacts: inConsentio,
    initialized,
    lastMessageAt,
    lastReadContactAt: lastReadContactAt[channelId] || 0,
    me: user,
    messages: emptyMessages,
    unreadMessageCount: unreadMessageCount[channelId],
  };
  if (!channel) {
    return chatStateProps;
  }
  return {
    ...chatStateProps,
    channel,
    messages: messages[channelId] || emptyMessages,
  };
}

function mapDispatchToPropsChat(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IChatListRouteProps,
): IDispatchProps {
  return bindActionCreators(
    {
      addMembers: chatActions.addMembers,
      contactsInvite: contactActions.contactsInvite,
      deleteMessage: chatActions.deleteMessage,
      getMessages: chatActions.getMessages,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navMiniChannelAction,
      notificationShow: notificationsActions.notificationShow,
      openChannel: chatActions.openChannel,
      sendMessage: chatActions.sendMessage,
      setActiveChannel: chatActions.setActiveChannel,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChat, mapDispatchToPropsChat)(ChatPublic);
