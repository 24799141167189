import { ORDER_STATUS } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, LettersAvatar, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isRead?: boolean;
  status?: ORDER_STATUS;
  textAlign?: string;
  showGrey?: boolean;
  theme: ITheme;
  width?: string;
}

const FlexColumn = styled(ColumnContainer)``;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;
export const ContactImagesRow = styled(RowContainer)`
  position: relative;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: ${(p: IStyledProps) => p.width || '30px'};
`;

export const ContactImage = styled(LettersAvatar)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};

  ${(p: IStyledProps) =>
    p.status === 'canceled' &&
    css`
      filter: brightness(50%);
    `}
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const ContactNameColumn = styled(FlexColumn)`
  justify-content: center;
  min-width: 85px;
`;
export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const TextSecondary = styled(TextBlack)``;
