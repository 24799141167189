import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingView} 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  min-height: min-content;
`;

export const ContainerList = styled.div`
  display: grid;
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    justify-items: center;
  }
`;
