import {
  Api,
  buyerWorkspaceActions,
  chatActions,
  contactActions,
  IOrder,
  modalActions,
  notificationsActions,
  orderActions,
  orderService,
  productActions,
  productService,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import * as orderLocalActions from '../actions/order';
import { supportAction } from '../actions/user';
import OrderDetails, { DispatchProps, HeritageProps, StateProps } from '../components/organisms/OrderDetails';
import { IReduxState } from '../reducers';
import { navSelectors } from '../selectors';

const emptyCart: ICart = {
  asSeller: false,
  catalogId: 0,
  contactId: 0,
  customItems: [],
  initialComments: [],
  isServedFlow: false,
  items: [],
  key: '',
  orderId: 0,
  updatedAt: 0,
  priceMode: 'none',
};

function mapStateToProps(state: IReduxState, props: HeritageProps): StateProps {
  const {
    catalog: { catalogs, clients },
    chat: { lastReadAt, lastReadContactAt },
    contact: { inConsentio },
    country,
    nav: { workspaceSelected },
    order: { orders, cart },
    prodType,
    product: { catalog, prices, priceGroupIds },
    user: { user },
    workspace: { suppliers, workspaces, addresses },
  } = state;

  const order = orders.find((d: IOrder) => d.id === Number(props.orderId))!;

  const prodTypes = prodType.prodTypes;
  const countries = country.countries;
  if (!order) {
    const k = productService.getKey(
      props.amSeller ? user.id! : props.contact?.id!,
      props.amSeller ? props.contact?.id! : user.id!,
    );
    emptyCart.key = k;

    return {
      cart: cart[k] || emptyCart,
      catalogs,
      contacts: inConsentio,
      countries,
      lastReadAt,
      lastReadContactAt,
      me: user,
      prodTypes,
      self: false,
      weAreAuthor: false,
      weAreBuyer: !props.amSeller,
      weAreSeller: props.amSeller,
      workspaces,
      workspaceSelected: navSelectors.getSelectedWorkspace(state),
    };
  }
  const buyerSellerResult = orderService.areWeBuyerOrSeller(props.openedFrom, order, catalogs, workspaces, user);
  const { weAreBuyer, weAreSeller } = buyerSellerResult;
  const weAreAuthor = orderService.areWeAuthor(user, order, buyerSellerResult);
  const self = orderService.amLastAuthor(order, buyerSellerResult);

  const contactId = weAreSeller ? order.buyerId : order.sellerId;
  const channel = Object.values(state.chat.channels).find(
    c => c.type === 'private' && c.members.find(m => m.id === contactId),
  );
  const key = productService.getKey(weAreSeller ? user.id : order.sellerId, weAreBuyer ? user.id : order.buyerId);
  const sellerCatalog = weAreSeller ? catalogs[order.catalogId] : (catalog && catalog[order.catalogId]) || undefined;
  const catalogKey = sellerCatalog ? productService.getKey(sellerCatalog.id, order.buyerId) : undefined;
  const buyerWorkspace = workspaces[order.buyerWorkspaceId];
  const columnConfig = state.catalog.columnConfig[workspaceSelected];
  return {
    addresses: addresses[buyerWorkspace?.id],
    amEditor:
      (weAreBuyer && ['admin', 'editor'].includes(buyerWorkspace?.members.find(m => m.userId === user.id)?.role)) ||
      (weAreSeller && ['admin', 'editor'].includes(sellerCatalog?.members.find(m => m.userId === user.id)?.role)) ||
      order.sellerId === user.id ||
      order.buyerId === user.id,
    cart: cart[key] || { ...emptyCart, key },
    catalog: sellerCatalog,
    catalogs,
    channelId: channel?.id,
    clientsOld: clients && clients[sellerCatalog?.id],
    commentsChannel: order?.commentsChannel ? state.order.channels[order.commentsChannel] : undefined,
    contacts: inConsentio,
    countries,
    lastReadAt,
    lastReadContactAt,
    me: user,
    order,
    columnConfig,
    priceGroupIds: priceGroupIds[catalogKey],
    priceMode: weAreBuyer ? cart[key]?.priceMode : 'edit',
    prices: prices[catalogKey],
    prodTypes,
    self,
    suppliersOld: suppliers[buyerWorkspace?.id],
    weAreAuthor,
    weAreBuyer,
    weAreSeller,
    workspaces,
    workspaceSelected: navSelectors.getSelectedWorkspace(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      acceptOrder: orderActions.acceptOrder,
      addMessageReaction: chatActions.addMessageReaction,
      cancelOrder: orderActions.cancelOrder,
      cartClean: orderActions.cartClean,
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      cartSet: orderActions.cartSet,
      cartUpdateCustomItems: orderActions.cartUpdateCustomItems,
      cartUpdateInitialComments: orderActions.cartUpdateInitialComments,
      cartUpdateItem: orderActions.cartUpdateItem,
      clientAddressAdd: sellerWorkspaceActions.clientAddressAdd,
      clientsGet: sellerWorkspaceActions.clientsGet,
      clientUpdate: sellerWorkspaceActions.clientUpdate,
      supplierUpdate: buyerWorkspaceActions.supplierUpdate,
      contactUpdate: contactActions.contactUpdate,
      createNewContact: contactActions.createNewContact,
      getExcelOrder: orderLocalActions.getExcelOrder,
      getMessages: chatActions.getMessages,
      getPrices: productActions.getPrices,
      productAddAttributeValue: productActions.productAddAttributeValue,
      productDefaultAttributeValue: productActions.productDefaultAttributeValue,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelBySection,
      notificationShow: notificationsActions.notificationShow,
      orderAddressFixIssue: orderActions.orderAddressFixIssue,
      orderAttachmentDownload: orderActions.orderAttachmentDownload,
      orderAttachmentsGet: orderActions.orderAttachmentsGet,
      orderAttachmentUpload: orderActions.orderAttachmentUpload,
      orderChannelGet: orderActions.orderChannelGet,
      orderClone: orderActions.orderClone,
      orderDelete: orderActions.orderDelete,
      orderCommentSend: orderActions.orderCommentSend,
      orderCustomPdforExcelDownload: orderActions.orderCustomPdforExcelDownload,
      orderDateFixIssue: orderActions.orderDateFixIssue,
      orderFakeNew: orderActions.orderFakeNew,
      orderFixIssue: orderActions.orderFixIssue,
      orderGet: orderActions.orderGet,
      orderItemFixIssue: orderActions.orderItemFixIssue,
      orderClientFixIssue: orderActions.orderClientFixIssue,
      orderNew: orderActions.orderNew,
      orderOriginalFileDownload: orderActions.orderOriginalFileDownload,
      orderPdfDownload: orderActions.orderPdfDownload,
      orderSendToERP: orderActions.orderSendToERP,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      orderUpdate: orderActions.orderUpdate,
      orderUpdateComments: orderActions.orderUpdateComments,
      orderUpdateCustomItems: orderActions.orderUpdateCustomItems,
      orderUpdateExternal: orderActions.orderUpdateExternal,
      orderUpdateIssues: orderActions.orderUpdateIssues,
      orderItemIgnore: orderActions.orderItemIgnore,
      orderItemRecover: orderActions.orderItemRecover,
      orderItemStopDiscarding: orderActions.orderItemStopDiscarding,
      orderUpdatePrepared: orderActions.orderUpdatePrepared,
      orderUpdateProducts: orderActions.orderUpdateProducts,
      productGet: productActions.productGet,
      productGetByHashPublic: productActions.productGetByHashPublic,
      productShare: productActions.productShare,
      relaunchOrderExport: orderActions.relaunchOrderExport,
      removeMessageReaction: chatActions.removeMessageReaction,
      sendMessage: chatActions.sendMessage,
      setCommentsRead: orderActions.setCommentsRead,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
      supportAction,
      tableVisibilityConfigGet: sellerWorkspaceActions.tableVisibilityConfigGet,
      touchFile: chatActions.downloadFile,
      touchImage: modalActions.touchImage,
      workspaceAddressAdd: buyerWorkspaceActions.workspaceAddressAdd,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
