import {
  buyerWorkspaceActions,
  contactActions,
  IDateRange,
  modalActions,
  notificationsActions,
  orderActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { RouteComponentProps } from 'react-router-dom';

export type IRouteProps = RouteComponentProps<{
  hashId?: string;
  workspaceId?: string;
  channelId?: string;
  contactId?: string;
}>;

export enum TABS {
  UNREAD = 'unread',
}

export interface IOwnProps {
  catalogHash?: string;
  contactId?: number;
  fromChat?: boolean;
  fromMessages?: boolean;
  hashId?: string;
  hideBack?: boolean;
  numberOfHeaders?: number;
  onlyUnreads?: boolean;
  setContactSelected?: (contact?: IContact) => void;
  setViewMode?: (viewMode: 'order' | 'delivery' | 'request') => void;
  tabSelected?: string;
  type?: IOrderType;
  updateUrl?: (
    searchState: ISearchOrder,
    dateRange: IDateRange,
    order?: IOrder | { hashId?: string; isRepeatedOrder?: boolean },
  ) => void;
  viewMode?: 'order' | 'delivery' | 'request';
  breadcrumb?: {
    parentSections: Array<IBreadcrumb>;
    title: string;
  };
  updateBreadcrumb?: (newCtx: { parentSections: Array<IBreadcrumb>; title: string }) => void;
}

export interface IStateProps {
  amSeller: boolean;
  catalogs: { [id: number]: IWorkspace };
  clients: { [id: number]: Array<IClient> };
  contacts: { [cId: number]: IContact };
  dashboard: IOrderDashboard;
  facets: IFacets;
  facetsGlobal: IFacets;
  filters: IOrderFilters;
  hasMore?: boolean;
  me: IUser;
  orders: Array<IOrder>;
  orderSelected: IOrder;
  searchId: string;
  suppliers: { [id: number]: Array<ISupplier> };
  totalResults: number;
  workspaces: { [id: number]: IWorkspace };
}

export interface IDispatchProps {
  clientsGet: typeof sellerWorkspaceActions.clientsGet;
  modalOpen: typeof modalActions.modalOpen;
  orderFiltersSet: typeof orderActions.orderFiltersSet;
  orderGetByHash: typeof orderActions.orderGetByHash;
  ordersAllMarkAsRead: typeof orderActions.ordersAllMarkAsRead;
  orderScrollSearch: typeof orderActions.orderScrollSearch;
  ordersDashboardGet: typeof orderActions.ordersDashboardGet;
  ordersDashboardWorkspaceGet: typeof orderActions.ordersDashboardWorkspaceGet;
  orderSearch: typeof orderActions.orderSearch;
  orderToggleReadStatus: typeof orderActions.toggleOrderReadStatus;
  requestProInfo: typeof userActions.requestProInfo;
  suppliersGet: typeof buyerWorkspaceActions.suppliersGet;
  touchImage: typeof modalActions.touchImage;
  createNewContact: typeof contactActions.createContactWithName;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
  notificationShow: typeof notificationsActions.notificationShow;
}
