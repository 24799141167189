import { utils } from 'common-services';
import * as React from 'react';

import * as S from './LandingTitle.styled';

export interface IProps {
  className?: string;
  isInvite: boolean;
  title: string;
}

const LandingTitle: React.FC<IProps> = ({ className, isInvite, title }) => {
  return (
    <S.Container className={className} isInvite={isInvite}>
      {title.split('\n').map((t, i) => (
        <S.Row key={i}>
          <S.TextTitle isInvite={isInvite}>
            {
              utils.formatText(t, s => (
                <S.TextGradient key={s} isInvite={isInvite}>
                  {s}
                </S.TextGradient>
              )) as any // TYPEERROR
            }
          </S.TextTitle>
        </S.Row>
      ))}
    </S.Container>
  );
};

export default React.memo(LandingTitle);
