import {
  __,
  apiParsers,
  chatActions,
  chatService,
  debounce,
  EventTrack,
  imageActions,
  modalActions,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { MAX_FILE_SIZE, MAX_PICTURES_PER_POST } from '../../../constants';
import { resizeImage } from '../../../services/image';
import { FontIcon, Input } from '../../atoms';
import InputWithLabel from '../InputWithLabel';
import { PostHeader, PostLink } from '../PostMessage/PostMessage.component';
import * as S from './PostWriteModal.styled';

interface IProps {
  channel: IChannel;
  className?: string;
  handleSelectFile: (files: Array<File>, resetEdit: boolean) => void;
  imagePreviewsInit?: Array<IImagePreview>;
  initImageIndex?: number;
  me: IUser;
  mode?: 'post' | 'poll';
  onAddImageClick: (withCrop?: boolean, imageIndex?: number) => void;
  onClose: () => void;
  post?: IMessage;
  toShow: boolean;
}

const debouncedMetadataGetFromURLs = debounce((postContent, setLinkMetadata, dispatch) => {
  const url = utils.splitTextUrls(postContent).find(m => m.isLink!);
  if (url && url.text) {
    const urlData = utils.analyzeUrl(url.text);
    if (!['image', 'video'].includes(urlData.type)) {
      dispatch(
        chatActions.metadataGetFromUrl(urlData.url, (metadata: IMetadata) => {
          setLinkMetadata(metadata);
        }),
      );
    }
  }
}, 400);

// Get empty poll option with a given id
const getEmptyPollOption = (id: number): IPollOption => ({
  id,
  voterIds: [],
  text: '',
});

/**
 * Write or edit a post modal
 */
export const PostWriteModal: React.FC<IProps> = ({
  channel,
  className,
  handleSelectFile,
  initImageIndex,
  imagePreviewsInit,
  me,
  mode = 'post',
  onAddImageClick,
  onClose,
  post,
  toShow,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [postToEdit, setPostToEdit] = React.useState<IMessage>(post);
  const [linkMetadata, setLinkMetadata] = React.useState<IMetadata>();
  const [postContent, setPostContent] = React.useState('');
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [imagePreviews, setImagePreviews] = React.useState<Array<IImagePreview>>();
  const [imageIndex, setImageIndex] = React.useState(initImageIndex || 0);
  const [pollOptions, setPollOptions] = React.useState<Array<IPollOption>>([
    getEmptyPollOption(1),
    getEmptyPollOption(2),
  ]);
  const inputArea = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    if (imagePreviewsInit) setImagePreviews([...imagePreviewsInit]);
  }, [imagePreviewsInit]);

  // Use effect to set post content for edition
  React.useEffect(() => {
    if (post) {
      const { extraData, messageType } = post;
      const { metadata } = extraData as IPost;
      const { options } = extraData as IPoll;
      setPostToEdit(post);
      setPostContent(post.message);
      if (messageType === 'poll') setPollOptions(options);
      setLinkMetadata(metadata?.link ? metadata : undefined);
    }
  }, [imagePreviewsInit, post, postToEdit, setLinkMetadata, setPostContent, setPostToEdit]);

  /**
   * Reset all state + hide modal
   */
  const resetSendPost = React.useCallback(() => {
    setPostToEdit(undefined);
    setLinkMetadata(undefined);
    setPostContent('');
    setImagePreviews(undefined);
    onClose();
  }, [onClose, setPostToEdit, setLinkMetadata, setPostContent, setImagePreviews]);

  /**
   * Analyze post content to find some URLs
   */
  const analyzePostContent = React.useCallback(
    (content, setLinkMetadataCb, dispatchCb) => debouncedMetadataGetFromURLs(content, setLinkMetadataCb, dispatchCb),
    [],
  );

  React.useEffect(() => {
    if (postContent && !imagePreviews) {
      analyzePostContent(postContent, setLinkMetadata, dispatch);
    }
  }, [analyzePostContent, postContent, imagePreviews, setLinkMetadata, dispatch]);

  /**
   * Send a post
   */
  const sendPost = React.useCallback(
    (imageUrls?: Array<string>, mediaMetadata?: Array<IMediaMetadata>) => {
      let extraData: IPostAPI | IPollAPI = {};
      if (linkMetadata)
        extraData = {
          metadata: {
            title: linkMetadata.title,
            description: linkMetadata.description,
            image_url: linkMetadata.image_url,
            link: linkMetadata.link,
          },
        };
      if (imageUrls) extraData = { image_urls: imageUrls, media_metadata: mediaMetadata };
      if (mode === 'poll') {
        extraData = { options: pollOptions.filter(o => o.text).map(apiParsers.pollOptionToAPI) };
      }

      if (postToEdit)
        dispatch(
          chatActions.editMessage(me.id, {
            ...postToEdit,
            message: postContent.trim(),
            extraData,
          }),
        );
      else {
        const newMessage = {
          channelId: channel.id!,
          createdAt: new Date().getTime(),
          extraData,
          message: postContent.trim(),
          messageId: 0,
          messageType: mode,
          reactions: {},
          senderId: me.id,
        };
        dispatch(chatActions.sendMessage(me.id, newMessage as IMessage));
        EventTrack.track('post_send', {
          channel_id: channel.id,
          image_count: imageUrls?.length || 0,
          length: postContent.length,
          linkMetadata: linkMetadata?.link,
          poll_options: pollOptions.length,
        });
      }
      resetSendPost();
    },
    [dispatch, channel, me, linkMetadata, postContent, postToEdit, resetSendPost, mode, pollOptions],
  );

  /**
   * Upload image and then call a callback with the url of the image
   */
  const uploadImage = React.useCallback(
    (file: IFile, fileString: string, cb?: (url?: string, err?: Error, data?: any) => void) => {
      if (file.type.startsWith('image/')) {
        if (['image/gif', 'image/webp'].includes(file.type)) {
          if (file.size > MAX_FILE_SIZE) {
            dispatch(modalActions.modalOpen(__('Components.Chat.max_size_exceeded', { max: 3 })));
            return;
          }
          dispatch(
            imageActions.mediaUploadWithProgress({ ...file, content: fileString }, (data, err) =>
              cb?.(data?.secure_url, err, data),
            ),
          );
        } else {
          resizeImage(fileString, (imageResized: string) =>
            dispatch(
              imageActions.mediaUploadWithProgress({ ...file, content: imageResized }, (data, err) =>
                cb?.(data?.secure_url, err, data),
              ),
            ),
          );
        }
      }
    },
    [dispatch],
  );

  /**
   * Send a post with images. It first uploads the image to cloudinary if necessary, then sends the post with the image urls.
   */
  const sendPostWithImages = React.useCallback(
    (images: Array<IImagePreview>, mediaMetadata?: Array<IMediaMetadata>) => {
      const result = [];
      const resultMetadata: Array<IMediaMetadata> = [];
      let iterations = 0;

      images.forEach(image => {
        const { file, fileString, imageUrl } = image;
        if (file && fileString) {
          uploadImage(file, fileString, (url, err, data) => {
            iterations++;
            if (url && data && !err) {
              result.push(url);
              resultMetadata.push(apiParsers.APICloudinaryToMetadata(data));
            }
            if (iterations === images.length) sendPost(result, resultMetadata);
          });
        } else if (imageUrl) {
          iterations++;
          result.push(imageUrl);
          const existingMetadata = mediaMetadata?.find(m => m.id === utils.getIdentifierFromCloudinaryUrl(imageUrl));
          if (existingMetadata) resultMetadata.push(existingMetadata);
          if (iterations === images.length) sendPost(result, resultMetadata);
        }
      });
      onClose();
    },
    [onClose, sendPost, uploadImage],
  );

  let imageData;
  const imagePreview = imagePreviews?.length ? imagePreviews[imageIndex] : undefined;
  if (imagePreview) {
    imageData = imagePreview.file
      ? `data:${imagePreview.file.type};base64,${imagePreview.file.content}`
      : imagePreview.imageUrl;
  }
  const isGifImage = (imagePreview?.imageUrl || '').endsWith('.gif') || imagePreview?.file?.type === 'image/gif';
  const hasMultipleImages = imagePreviews?.length > 1;
  let title = postToEdit ? __('ChatPublic.create_post_modal.title_edit') : __('ChatPublic.create_post_modal.title');
  if (mode === 'poll') title = __('ChatPublic.create_poll_modal.title');

  if (!toShow) return null;

  let isSubmitDisabled = !postContent && !imagePreview;
  if (mode === 'poll') {
    isSubmitDisabled =
      !postContent ||
      pollOptions.reduce((acc: boolean, o, idx) => {
        if (!o.text && idx < 2) acc = true;
        return acc;
      }, false);
  }

  return (
    <S.WritePostModal
      className={className}
      contentGrow={false}
      onClose={() => {
        if (postToEdit) resetSendPost();
        else onClose();
      }}
      onDropFiles={files => files.length && handleSelectFile(files, false)}
      title={title}
      minHeight="450px"
      multiple={true}
    >
      <S.ModalContent>
        <PostHeader
          member={chatService.meAsChannelMember(channel, me)}
          subtitle={__('ChatPublic.create_post_modal.subtitle', { channelName: channel.name })}
        />
        <S.InputContainer>
          <S.InputArea
            autoFocus={true}
            autoIncreaseHeight={true}
            bigInput={!imagePreview && mode === 'post'}
            inputRef={ref => (inputArea.current = ref)}
            maxLength={1500}
            name="write-post-text-area"
            onChange={(name, value) => {
              setPostContent(value + '');
            }}
            placeholder={
              mode === 'post'
                ? __('ChatPublic.create_post_modal.placeholder')
                : __('ChatPublic.create_poll_modal.placeholder')
            }
            rows={3}
            showCharactersCount={false}
            value={postContent}
          />

          <S.ButtonContainer>
            <S.CardButton
              type="link"
              withoutPadding
              onClick={() => {
                let result = postContent;
                if (!result.trim().endsWith('#')) {
                  result = '#';
                  if (postContent)
                    result = postContent + (postContent.endsWith('\n') || postContent.endsWith(' ') ? '' : ' ') + '#';
                }
                setPostContent(result);
                inputArea.current?.focus();
                const postLength = result.length;
                inputArea.current?.setSelectionRange(postLength, postLength);
              }}
            >
              {'# ' + __('ChatPublic.add_hashtag')}
            </S.CardButton>
            <EmojiPicker
              hasImage={!!imagePreview}
              inputArea={inputArea.current}
              setPostContent={setPostContent}
              showEmojiPicker={showEmojiPicker}
              toggleEmojiPicker={setShowEmojiPicker}
            />
          </S.ButtonContainer>

          {mode === 'poll' && pollOptions.length ? (
            <S.PollContainer>
              {pollOptions.map((option, idx) => (
                <InputWithLabel
                  isRequired={true}
                  key={idx}
                  label={__('ChatPublic.create_poll_modal.option') + ` ${idx + 1}`}
                >
                  <S.PollRow>
                    <Input
                      name={`poll-${idx}`}
                      placeholder={__('ChatPublic.option_placeholder')}
                      onChange={debounce((_, val) => {
                        const optionsCopy = pollOptions.slice();
                        optionsCopy[idx] = { ...option, text: val + '' };
                        setPollOptions(optionsCopy);
                      }, 250)}
                      value={option.text}
                    />
                  </S.PollRow>
                </InputWithLabel>
              ))}
              {pollOptions.length < 10 ? (
                <S.CardButton
                  id="post-write-modal-add-option"
                  iconName="Add-more"
                  iconSize="15px"
                  onClick={() => {
                    const optionsCopy = pollOptions.slice();
                    optionsCopy.push(getEmptyPollOption(optionsCopy.length + 1));
                    setPollOptions(optionsCopy);
                  }}
                  type="link"
                  withoutPadding={true}
                >
                  {__('ChatPublic.create_poll_modal.add_option')}
                </S.CardButton>
              ) : null}
            </S.PollContainer>
          ) : null}
        </S.InputContainer>

        {imagePreview ? (
          <S.ImageContainer className="write-post-image-container">
            <S.ImagePreview src={imageData} hasMultipleImages={hasMultipleImages} />
            <S.ImageActionsContainer>
              <S.ActionButton
                className="write-post-modal-action-button"
                onClick={() => onAddImageClick(isGifImage ? false : true, imageIndex)}
              >
                {isGifImage ? <S.EditIcon name="Edit" /> : <S.CropIcon name="Crop" />}
              </S.ActionButton>
              <S.ActionButton
                className="write-post-modal-action-button"
                onClick={() => {
                  setImagePreviews([...imagePreviews.filter((_, j) => j !== imageIndex)]);
                  setImageIndex(Math.max(0, imageIndex - 1));
                }}
              >
                <S.CloseIcon name="Close" />
              </S.ActionButton>
            </S.ImageActionsContainer>
          </S.ImageContainer>
        ) : null}
        {linkMetadata ? (
          <PostLink
            className="write-post-link-preview"
            metadata={linkMetadata}
            showClose={true}
            onClose={() => setLinkMetadata(undefined)}
          />
        ) : null}
      </S.ModalContent>
      {hasMultipleImages ? (
        <S.ThumbnailsContainer>
          {imagePreviews.slice(0, MAX_PICTURES_PER_POST).map((f, i) => {
            return (
              <S.ImageThumbnail
                key={i + '_' + f.file?.name}
                imagePreview={f}
                isSelected={i === imageIndex}
                onDeleteClick={() => {
                  setImagePreviews([...imagePreviews.filter((_, j) => j !== i)]);
                  if (i <= imageIndex) setImageIndex(Math.max(0, imageIndex - 1));
                }}
                onImageClick={() => setImageIndex(i)}
              />
            );
          })}
          {imagePreviews?.length < MAX_PICTURES_PER_POST ? (
            <S.AddContainer onClick={() => onAddImageClick()} id="post-write-modal-thumbnail-add">
              <S.AddIcon name="Plus" disableHover={true} />
            </S.AddContainer>
          ) : null}
        </S.ThumbnailsContainer>
      ) : null}
      {!linkMetadata && !imagePreview && mode === 'post' ? (
        <S.AddImageCTA
          id="add-image-button"
          iconName="Add-image"
          iconSize="18px"
          onClick={() => onAddImageClick()}
          type="skip"
          withoutPadding={true}
        >
          {__('ChatPublic.image')}
        </S.AddImageCTA>
      ) : null}
      <S.CTAContainer hasImage={imagePreviews?.length === 1}>
        {imagePreviews?.length === 1 ? (
          <S.AddContainerAbsolute onClick={() => onAddImageClick()}>
            <S.AddIcon name="Plus" disableHover={true} />
          </S.AddContainerAbsolute>
        ) : null}
        <S.ModalCTA
          disabled={isSubmitDisabled}
          onClick={() => {
            if (imagePreviews?.length) sendPostWithImages(imagePreviews, postToEdit?.extraData?.mediaMetadata);
            else sendPost();
          }}
          type="principal"
        >
          {postToEdit ? __('ChatPublic.create_post_modal.cta_edit') : __('ChatPublic.create_post_modal.cta')}
        </S.ModalCTA>
      </S.CTAContainer>
    </S.WritePostModal>
  );
};

export default PostWriteModal;

interface IEmojiPickerProps {
  hasImage: boolean;
  inputArea: HTMLTextAreaElement;
  setPostContent: (text: string) => void;
  showEmojiPicker: boolean;
  toggleEmojiPicker: (toShow: boolean) => void;
}
const Picker = React.lazy(() => import('emoji-picker-react'));

const EmojiPicker: React.FC<IEmojiPickerProps> = ({
  hasImage,
  inputArea,
  setPostContent,
  showEmojiPicker,
  toggleEmojiPicker,
}) => {
  const emojiContainerRef = React.createRef<HTMLDivElement>();
  /**
   * On click outside, hide emoji picker
   */
  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (emojiContainerRef?.current && !emojiContainerRef.current.contains(event.target as any)) {
        toggleEmojiPicker(false);
      }
    },
    [emojiContainerRef, toggleEmojiPicker],
  );
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  /**
   * Add emoji to input
   */
  const addEmojiToInput = React.useCallback(
    (emoji: string) => {
      const textBeforeCaret = inputArea.value.slice(0, inputArea.selectionStart);
      const textAfterCaret = inputArea.value.slice(inputArea.selectionStart) || '';
      const textBeforePlusEmoji = textBeforeCaret + emoji;

      setPostContent(textBeforePlusEmoji + textAfterCaret);
      const nextPosition = textBeforePlusEmoji.length - 1;

      setTimeout(() => {
        inputArea.focus();
        inputArea.setSelectionRange(nextPosition, nextPosition);
      });
    },
    [inputArea, setPostContent],
  );

  return (
    <S.EmojiRow onClick={() => toggleEmojiPicker(!showEmojiPicker)}>
      <S.FontIconWrapper>
        <FontIcon name="Emoji" />
      </S.FontIconWrapper>
      <S.EmojiWrapper
        hasImage={hasImage}
        hidden={!showEmojiPicker}
        onClick={e => e.stopPropagation()}
        ref={emojiContainerRef}
      >
        {showEmojiPicker ? (
          <Picker
            onEmojiClick={(e, data) => {
              e.stopPropagation();
              addEmojiToInput(data.emoji + ' ');
            }}
            disableSearchBar={false}
            searchPlaceholder={__('Components.Chat.emoji_search_placeholder')}
            disableSkinTonePicker={true}
            preload={true}
            native
          />
        ) : null}
      </S.EmojiWrapper>
      <S.CardButton type="link" withoutPadding>
        {__('ChatPublic.add_emoji')}
      </S.CardButton>
    </S.EmojiRow>
  );
};
