import {
  __,
  buyerWorkspaceActions,
  buyerWorkspaceService,
  chatActions,
  contactActions,
  debounce,
  EventTrack,
  INVITE_ORIGIN,
  INVITE_VIA,
  ISearchSupplier,
  modalActions,
  notificationsActions,
  ORDER_ACCEPT_TYPE,
  orderActions,
  productService,
  RenderTrack,
  throttle,
  utils,
  VIA,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { getDefaultColumns, ROUTE_PATHS } from '../../../constants';
import { getInviteOptions } from '../../../services/invite';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { copyToClipboard } from '../../../util/utils';
import { Button, LettersAvatar } from '../../atoms';
import {
  AddSuppliers,
  InviteAddressbookModal,
  Members,
  MiniImages,
  NonProviders,
  SimpleSearch,
  Table,
} from '../../molecules';
import { IColumn } from '../../molecules/Table/Table.component';
import Workspace from '../Workspace/Workspace.component';
import WorkspaceSupplierEdit from '../WorkspaceSupplierEdit';
import * as S from './WorkspaceSuppliers.styled';

export interface IStateProps {
  amEditor: boolean;
  channels: { [channelId: string]: IChannel };
  contacts: { [cId: number]: IContact };
  me: IUser;
  hasOutContacts: boolean;
  prodTypes: { [key: string]: IProdType };
  suppliersOld: Array<ISupplier>;
  workspace: IWorkspace;
}

export interface IDispatchProps {
  cartCleanIfNeeded: typeof orderActions.cartCleanIfNeeded;
  contactsAddressbookGet: typeof contactActions.contactsAddressbookGet;
  contactsInvite: typeof contactActions.contactsInvite;
  createChannel: typeof chatActions.createChannel;
  createNewContact: typeof contactActions.createContactWithName;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  notificationShow: typeof notificationsActions.notificationShow;
  suppliersGet: typeof buyerWorkspaceActions.suppliersGet;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ workspaceId: string; supplierId: string }> & { t?: number };

const WorkspaceSuppliers: React.FC<IProps> = ({
  match: {
    params: { workspaceId, supplierId },
  },
  me,
  contactsInvite,
  suppliersGet,
  amEditor,
  cartCleanIfNeeded,
  contacts,
  createNewContact,
  hasOutContacts,
  history,
  location,
  prodTypes,
  notificationShow,
  suppliersOld,
  touchImage,
  workspace,
}) => {
  const [showAddSuppliers, setShowAddSuppliers] = React.useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = React.useState<INVITE_VIA>();
  const [elasticSuppliers, setElasticSuppliers] = React.useState<Array<ISupplier>>([]);
  const [searchId, setSearchId] = React.useState<string>();
  const [searchState, setSearchState] = React.useState<ISearchSupplier>({
    workspaceId: Number(workspaceId),
    language: me.settings.language as LOCALE,
    text: '',
    sort: 'name_sort',
    sortOrder: 'asc',
  });

  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [totalResults, setTotalResults] = React.useState<number>(0);
  const sendSearch = React.useCallback(
    debounce(() => {
      buyerWorkspaceService
        .supplierSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api)
        .then(onReceiveSuppliers);
    }, 200),
    [me, searchState],
  );

  const scrollSearch = React.useCallback(
    throttle(() => {
      buyerWorkspaceService.supplierScrollSearch(searchId, api).then(onReceiveSuppliers);
    }, 200),
    [searchId],
  );
  /**
   * Track search event
   */
  const trackSearch = React.useCallback(
    debounce(() => {
      EventTrack.track('workspace_suppliers_search', { workspace_id: workspaceId, search: searchState.text });
    }, 1000),
    [searchState, workspaceId],
  );

  React.useEffect(() => {
    RenderTrack.track('WorkspaceSuppliers', {});
    suppliersGet(me.id, Number(workspaceId));
    sendSearch();
  }, []);

  React.useEffect(() => {
    setSearchState({ ...searchState, workspaceId: Number(workspaceId) });
  }, [workspaceId]);

  React.useEffect(() => {
    trackSearch();
  }, [searchState.text]);

  React.useEffect(() => {
    if (searchState.workspaceId) sendSearch();
  }, [searchState]);

  const suppliersToShow = elasticSuppliers.map(c => {
    c.name =
      c.name ||
      (c.names
        ? c.names.find(n => n.supplier_id === me.id)?.name ||
          c.names.find(n => n.supplier_id === workspace.ownerId)?.name ||
          c.names[0]?.name ||
          ''
        : '');
    return c;
  });

  return (
    <>
      {supplierId ? <WorkspaceSupplierEdit /> : null}
      <Workspace
        isBuyerWorkspace={true}
        subtitle={''}
        tabSelected="suppliers"
        title={__('Components.Header.WorkspaceSuppliers')}
        workspaceId={Number(workspaceId)}
      >
        <S.Container onScroll={onScroll} className="suppliers-scroll-container">
          {suppliersOld?.length ? (
            <>
              <S.SearchContainer>
                <SimpleSearch
                  onChange={f => setSearchState({ ...searchState, text: utils.toLocaleLowerCaseNormalized(f) })}
                  placeHolder={__('Components.ChatList.search.placeholder')}
                  id="input_search_chats_list"
                />
                {amEditor ? renderAddSuppliersLink() : null}
              </S.SearchContainer>
              <S.Stats>{__('Components.OrdersList.results', { count: totalResults })}</S.Stats>
            </>
          ) : null}
          <NonProviders hasHits={!!suppliersToShow.length} workspaceId={workspace?.id}>
            <SupplierTable
              suppliers={suppliersToShow}
              contacts={contacts}
              me={me}
              navigateToSupplier={(cId: number) => {
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT,
                    workspaceId: workspace.id + '',
                    supplierId: cId + '',
                  }),
                  {
                    from: location.pathname,
                  },
                );
              }}
              navigateToBuy={(supplier: ISupplier) => {
                const key = productService.getKey(supplier.userId, me.id);
                cartCleanIfNeeded(key, undefined, contacts[supplier.userId].theirSellerWorkspaceId, 0);
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY,
                    supplierId: supplier.userId + '',
                    workspaceId,
                  }),
                );
              }}
              prodTypes={prodTypes}
              createNewContact={supplier =>
                createNewContact(
                  me.id,
                  supplier.userId,
                  supplier.name,
                  err =>
                    !err &&
                    notificationShow({
                      title: __('SuppliersList.create_contact_success'),
                      subtitle: __('SuppliersList.create_contact_success_sub'),
                      closable: true,
                      style: 'info',
                    }),
                )
              }
              sort={searchState.sort}
              sortOrder={searchState.sortOrder}
              touchImage={touchImage}
              updateSortMode={(sortBy: string, sortDirection: 'desc' | 'asc') => {
                setSearchState({ ...searchState, sort: sortBy, sortOrder: sortDirection });
                EventTrack.track('workspace_suppliers_sort', {
                  workspace_id: workspace.id,
                  sort: `${sortBy} ${sortDirection}`,
                });
              }}
            />
          </NonProviders>
        </S.Container>
        {showAddSuppliers ? <AddSuppliers close={() => setShowAddSuppliers(false)} workspaceId={workspace.id} /> : null}
        {!showAddSuppliers && showInviteModal ? renderInviteFromPhonebook() : null}
      </Workspace>
    </>
  );

  /**
   * return the add Suppliers link
   */
  function renderAddSuppliersLink() {
    return (
      <S.LinkAdd
        onSelect={handleInviteOptions}
        options={getInviteOptions([
          INVITE_VIA.CONSENTIO,
          ...(hasOutContacts ? [INVITE_VIA.PHONEBOOK] : []),
          'copy',
          INVITE_VIA.EMAIL,
          INVITE_VIA.SMS,
        ])}
      >
        <Button type="link" iconName="Add-contact" iconSize="16px">
          {__('SuppliersList.add_suppliers')}
        </Button>
      </S.LinkAdd>
    );
  }

  /**
   * Handle invite options: contacts / agenda / copy / email / SMS
   */
  function handleInviteOptions(key: string) {
    switch (key) {
      case INVITE_VIA.PHONEBOOK:
        setShowInviteModal(INVITE_VIA.PHONEBOOK);
        break;
      case 'copy':
        contactsInvite(
          me.id!,
          VIA.LINK,
          'web',
          INVITE_ORIGIN.WORKSPACE,
          undefined,
          (data, error) => {
            if (data?.inviteLink && !error)
              setTimeout(() =>
                copyToClipboard(data.inviteLink, err =>
                  notificationShow(
                    {
                      style: err ? 'error' : 'info',
                      title: err ? __('ClipboardError.title') : __('ClipboardInvite.title'),
                      subtitle: err ? __('ClipboardError.subtitle') : __('ClipboardInvite.subtitle'),
                      closable: true,
                    },
                    4000,
                  ),
                ),
              );
          },
          undefined,
          workspace.id,
        );
        break;
      case INVITE_VIA.EMAIL:
        setShowInviteModal(INVITE_VIA.EMAIL);
        break;
      case INVITE_VIA.SMS:
        setShowInviteModal(INVITE_VIA.SMS);
        break;
      case INVITE_VIA.CONSENTIO:
        setShowAddSuppliers(true);
        break;
    }
  }

  /**
   * Render modal with invite from address book
   */
  function renderInviteFromPhonebook() {
    return (
      <InviteAddressbookModal
        addressbookTitle={__('Components.ChatList.invite.addressbook.suppliers_title')}
        otherTitle={__('Components.ChatList.invite.other.suppliers_title')}
        catalogId={Number(workspaceId)}
        defaultInviteBy={showInviteModal}
        from="workspace-suppliers"
        me={me}
        onClose={() => setShowInviteModal(undefined)}
        origin={INVITE_ORIGIN.WORKSPACE}
      />
    );
  }

  function onReceiveSuppliers(res: ISearchData<ISupplier>) {
    if (!res) return;
    const { data, toReplace } = res;
    if (searchState.text) {
      EventTrack.track('workspace_suppliers_search', { workspace_id: workspace.id, search: searchState.text });
    }
    if (toReplace) {
      setElasticSuppliers(data.hits);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    } else {
      setElasticSuppliers([...elasticSuppliers, ...data.hits]);
      setSearchId(data.searchId);
      setTotalResults(data.totalResults);
      setHasMore(data.hasMore!);
    }
  }

  /**
   * handle the scroll event for sticky the searchbar
   */
  function onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      scrollSearch();
    }
  }
};

/**
 * Supplier table new for Supplier dashboard
 */
const SupplierTable = ({
  contacts,
  createNewContact,
  me,
  navigateToBuy,
  navigateToSupplier,
  prodTypes,
  suppliers,
  touchImage,
  updateSortMode,
  sort,
  sortOrder,
}: {
  contacts: { [cId: number]: IContact };
  createNewContact: (supplier: ISupplier) => void;
  me: IUser;
  navigateToBuy: (supplier: ISupplier) => void;
  navigateToSupplier: (cId: number) => void;
  prodTypes: { [key: string]: IProdType };
  sort?: string;
  sortOrder?: 'asc' | 'desc';
  suppliers: Array<ISupplier>;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  updateSortMode: (sortBy: string, sortDirection: 'desc' | 'asc') => void;
}) => {
  const columns: Array<IColumn> = [
    {
      id: 'supplier_company',
      title: __('SuppliersList.supplier_company'),
      sort: 'name_sort',
      sortOrder: ['desc', 'asc'],
      element: (data: ISupplier) => (
        <S.FlexRow>
          <S.ClientImagesRow>
            <LettersAvatar size={35} text={data.name} img={data.avatar} avatarColor={utils.getAvatarColor(data.name)} />
          </S.ClientImagesRow>
          <S.ClientNameColumn>
            {data.name ? <S.TextBlack>{data.name}</S.TextBlack> : null}
            <S.TextGrey> {data.company}</S.TextGrey>
          </S.ClientNameColumn>
        </S.FlexRow>
      ),
    },
    {
      id: 'accept_purchases',
      title: __('SuppliersList.accept_purchases'),
      value: (data: ISupplier) =>
        data.orderAccept === ORDER_ACCEPT_TYPE.AUTOMATIC
          ? __('ClientsList.accept.automatic')
          : __('ClientsList.accept.manually'),
    },
    {
      id: 'colleagues',
      title: __('SuppliersList.colleagues'),
      element: (data: ISupplier) => {
        const { teammates = [] } = data;
        return teammates.length ? (
          <Members
            className="suppliers-members"
            contacts={contacts}
            disableHover={true}
            me={me}
            members={teammates.map(t => ({
              userId: t.teammateId,
            }))}
            size={36}
          />
        ) : (
          <S.TextBlack>{__('SuppliersList.no_teammates')}</S.TextBlack>
        );
      },
    },
    {
      id: 'products',
      title: __('SuppliersList.products'),
      element: (data: ISupplier) => {
        const { purchasedProducts = [] } = data;
        const productImages = getProductImages(purchasedProducts, prodTypes);
        const fourProductImages = productImages.slice(0, 4);
        return fourProductImages.length ? (
          <MiniImages
            className="suppliers-product-images"
            images={fourProductImages}
            onImageClick={index =>
              touchImage(
                productImages.map(url => ({ src: url })),
                index,
              )
            }
            count={purchasedProducts.length}
          />
        ) : (
          <S.TextBlack>
            {purchasedProducts.length === 0
              ? __('SuppliersList.no_products')
              : __('WorkspacePriceGroups.products', { count: purchasedProducts.length })}
          </S.TextBlack>
        );
      },
    },
    {
      id: 'actions',
      title: '',
      element: (data: ISupplier) =>
        !!contacts[data.userId] ? (
          <S.ShowProducts
            className="show-products"
            type="other"
            onClick={e => {
              e.stopPropagation();
              navigateToBuy(data);
            }}
          >
            {__('SuppliersList.view_products')}
          </S.ShowProducts>
        ) : data.userId !== me.id ? (
          <S.AddContact
            iconName="Add-more"
            iconSize="18px"
            type="link"
            onClick={e => {
              e.stopPropagation();
              createNewContact(data);
            }}
          >
            {__('SuppliersList.add_contact')}
          </S.AddContact>
        ) : null,
    },
  ];
  return (
    <Table
      values={suppliers}
      onClickRow={(data: ISupplier) => navigateToSupplier(data.userId)}
      emptyText={__('SuppliersList.empty')}
      columns={columns}
      selectable={false}
      showStickyHeader={true}
      scrollClassName="suppliers-scroll-container"
      sort={sort}
      sortOrder={sortOrder}
      updateSortMode={updateSortMode}
      productColumns={getDefaultColumns()}
    />
  );
};

function getProductImages(products: Array<ILightProduct>, prodTypes: { [key: string]: IProdType }) {
  return products.reduce((acc, product) => {
    const productImages = product.imageUrls.filter(i => i);
    if (productImages.length) acc.push(productImages[0]);
    else if (prodTypes[product.type] && prodTypes[product.type].defaultImageUrl)
      acc.push(prodTypes[product.type].defaultImageUrl);
    return acc;
  }, [] as Array<string>);
}

export default React.memo(WorkspaceSuppliers);
