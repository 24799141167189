import { __ } from "common-services";

const findTerm = (s: string, term: string) => {
  if (s.includes(term)) {
    return s;
  }
};

export const unitTranslator = (unit: string, count: number) => {
  switch (unit) {
    case "piece":
      return __("Global.Product.Units.piece", { count });
    case "box":
      return __("Global.Product.Units.box", { count });
    case "pallet":
      return __("Global.Product.Units.pallet", { count });
    case "kg":
      return __("Global.Product.Units.kg", { count });
    default:
      return unit;
  }
};

export const priceUnitTranslator = (unit: string) => {
  switch (unit) {
    case findTerm(unit, "piece"):
      return unit.replaceAll(
        "piece",
        __("Global.Product.UnitAbbreviations.piece")
      );
    case findTerm(unit, "box"):
      return unit.replaceAll("box", __("Global.Product.UnitAbbreviations.box"));
    case findTerm(unit, "pallet"):
      return unit.replaceAll(
        "pallet",
        __("Global.Product.UnitAbbreviations.pallet")
      );
    case findTerm(unit, "kg"):
      return unit.replaceAll("kg", __("Global.Product.UnitAbbreviations.kg"));
    default:
      return unit;
  }
};
