import * as React from 'react';

import { IFontIconKeys } from '../FontIcon';
import * as S from './ExpansionPanel.styled';

export interface IProps {
  children?: React.ReactNode;
  className?: string;
  closedDescription?: React.ReactNode;
  color?: string;
  contentWithLeftPadding?: boolean; // Left padding for the content
  defaultExpanded?: boolean;
  iconPosition?: 'left' | 'right';
  id?: string;
  notCollapsible?: boolean;
  renderTitle?: (title: string) => React.ReactNode;
  showWarning?: boolean;
  title: string;
  titleContainerStyle?: React.CSSProperties;
  titleRight?: string;
  titleSize?: string;
  titleWithLeftPadding?: boolean; // Left padding for the container
}

const ExpansionPanel: React.FC<IProps> = React.memo(
  ({
    children,
    className,
    closedDescription,
    color,
    contentWithLeftPadding = true,
    defaultExpanded = true,
    iconPosition = 'right',
    id = '',
    notCollapsible,
    renderTitle,
    showWarning,
    title = '',
    titleContainerStyle = {},
    titleRight,
    titleSize,
    titleWithLeftPadding = true,
  }) => {
    const [expanded, setExpanded] = React.useState(defaultExpanded || notCollapsible);
    const childrenContainerHeight = expanded ? 'auto' : '0';
    const downIcon = expanded ? 'Down' : 'Right';

    React.useEffect(() => {
      setExpanded(defaultExpanded || notCollapsible);
    }, [id, defaultExpanded, notCollapsible]);

    const titleContent = title + (titleRight ? ': ' : '');
    return (
      <S.ExpansionPanel className={className}>
        <S.TitleContainer
          notCollapsible={notCollapsible}
          style={titleContainerStyle}
          onClick={() => (notCollapsible ? null : setExpanded(!expanded))}
          titleWithLeftPadding={titleWithLeftPadding}
        >
          {iconPosition === 'left' ? renderIcon(downIcon, notCollapsible, color) : null}
          <S.TitleRow
            className="title"
            iconPosition={iconPosition}
            notCollapsible={notCollapsible}
            expanded={expanded}
            titleSize={titleSize}
          >
            {renderTitle ? (
              <>{renderTitle(titleContent)}</>
            ) : (
              <S.TitleLeft expanded={expanded}>{titleContent}</S.TitleLeft>
            )}
            {titleRight ? <S.TitleRight>{titleRight}</S.TitleRight> : null}
            {showWarning ? <S.Warning /> : null}
          </S.TitleRow>

          {closedDescription && !expanded ? closedDescription : null}
          {iconPosition === 'right' ? renderIcon(downIcon, notCollapsible) : null}
        </S.TitleContainer>
        <S.ChildrenContainer
          contentWithLeftPadding={contentWithLeftPadding}
          height={childrenContainerHeight}
          titleWithLeftPadding={titleWithLeftPadding}
        >
          {expanded ? children : null}
        </S.ChildrenContainer>
      </S.ExpansionPanel>
    );
  },
);

/**
 * Render chevron icon
 */
function renderIcon(iconName?: IFontIconKeys, notCollapsible?: boolean, color?: string) {
  if (!iconName) return null;
  return <S.ArrowIcon name={iconName} disableHover={true} notCollapsible={notCollapsible} color={color} />;
}

export default ExpansionPanel;
