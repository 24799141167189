import {
  __,
  EventTrack,
  IOfferSummary,
  LOCALE,
  offerActions,
  RenderTrack,
  sellerWorkspaceSelectors,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { DatePicker } from '../../atoms';
import { EmptyListResource, InputWithLabel } from '../../molecules';
import { MultiTableV1dot0 } from '../../molecules/MultiTable/MultiTable.component.v1.0';
import OfferFilter from '../../molecules/OfferFilter';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceOffersBuyer.styled';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  contactId?: string;
}>;

export type IProps = IRouteProps;

const WorkspaceOffers: React.FC<IProps> = ({
  match: {
    params: { workspaceId },
  },
}) => {
  const me = useSelector(userSelectors.getUser);
  const catalog = useSelector(sellerWorkspaceSelectors.getCatalog(Number(workspaceId)));
  const dispatch = useDispatch<Dispatch<any>>();

  const [t, setT] = React.useState(Date.now());
  const [searchState, setSearchState] = React.useState({
    text: '',
    language: me?.settings?.language || LOCALE,
    index: '',
    status: ['active', 'unavailable'],
    sort: '',
    type: '',
    sellers: [],
    warehouses: [],
    productKinds: [],
    email: localStorage.getItem('email'),
  });

  React.useEffect(() => {
    const savedState = localStorage.getItem('offerSearchState');
    const savedStateObject = JSON.parse(savedState);
    const localStorageEmail = localStorage.getItem('email');
    if (savedStateObject && savedStateObject.email === localStorageEmail) {
      setSearchState(savedStateObject);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('offerSearchState', JSON.stringify(searchState));
  }, [searchState]);

  const [offerSummaries, setOfferSummaries] = React.useState<Array<IOfferSummary>>([]);

  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const initialDate = queryParams.get('date')
    ? new Date(queryParams.get('date')).toISOString().slice(0, -5).concat('Z')
    : new Date(Date.now() + 86400000).toISOString().slice(0, -5).concat('Z');

  // This filter will have to change depending on what data we get from rtapi
  const filteredData = offerSummaries.filter(offer => {
    return (
      (searchState.text.length > 0
        ? offer.buyerReference.name.toLowerCase().includes(searchState.text.toLowerCase())
        : true) &&
      (searchState.sellers.length === 0 || searchState.sellers.includes(offer.seller.id)) &&
      (searchState.warehouses.length === 0 || searchState.warehouses.includes(offer.buyerWarehouseId)) &&
      (searchState.productKinds.length === 0 || searchState.productKinds.includes(offer.buyerReference.kindId)) &&
      offer.buyerReference.name !== '' // if the API returns a empty reference name, it means the offered product is not linked with a buyer's reference, so we don't want to show it for now
    );
  });

  const [hideFilters, setHideFilters] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(initialDate);

  React.useEffect(() => {
    const searchParams = new URLSearchParams();
    const dateParam = new Date(selectedDate).toISOString().split('T')[0];
    searchParams.set('date', dateParam);
    history.push({ search: searchParams.toString() });
  }, [selectedDate, history]);

  React.useEffect(() => {
    RenderTrack.track('WorkspaceOffersBuyer', {
      renderTime: t,
    });
  }, []);

  const offerRequest = {
    day: selectedDate, // '2024-02-07T00:00:00Z'
    // filtering: {
    //   sellerIds: [111, 222], // optional
    //   productKindIds: [333, 444], // optional
    //   addressIds: [555, 666], // optional,
    // },
  };

  React.useEffect(() => {
    dispatch(
      offerActions.offerSummaryGet(Number(workspaceId), offerRequest, res => {
        setOfferSummaries(
          res.map(offer =>
            offer.buyerWarehouseName !== ''
              ? offer
              : { ...offer, buyerWarehouseName: __('Components.OffersList.noWarehouse') },
          ),
        );
        setHideFilters(res.length === 0);
      }),
    );
  }, [selectedDate]);

  const renderProductsSubheader = () => {
    return (
      <S.SubheaderCustom>
        <S.SubheaderLeftSearch>
          {offerSummaries.length > 0 ? (
            <S.FilterButton
              filtersOpened={!hideFilters && getFiltersCount() === 0}
              filtersSelected={getFiltersCount()}
              onClick={() => setHideFilters(!hideFilters)}
            />
          ) : null}
          <S.Search
            id="search-references"
            query={searchState.text}
            placeHolder={__('Components.OffersList.search')}
            onChange={(t: string) => setSearchState({ ...searchState, text: t })}
          />
          <S.DateContainer>
            <InputWithLabel isRequired={true} width="150px">
              <DatePicker
                id="offers_date"
                clearable={false}
                initDate={new Date(selectedDate)}
                onDateChange={selectedDate => {
                  setSelectedDate(selectedDate.slice(0, -10).concat('Z'));
                }}
                openDate={new Date(Date.now())}
              />
            </InputWithLabel>
          </S.DateContainer>
        </S.SubheaderLeftSearch>
      </S.SubheaderCustom>
    );
  };

  /**
   * Get product's filters count
   */
  const getFiltersCount = () => {
    const { productKinds, sellers, warehouses } = searchState;
    return productKinds.length + sellers.length + warehouses.length || 0;
  };

  return (
    <Workspace
      subtitle={''}
      title={'Dashboard'}
      tabSelected={'offers'}
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={true}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.offers'),
        },
      ]}
    >
      <S.Row>
        <OfferFilter
          changeSearchState={v => setSearchState({ ...searchState, ...v })}
          clearFilters={() => {
            EventTrack.track('workspace_offers_filter_clear', {
              workspace_id: workspaceId,
            });
            setSearchState({
              text: '',
              language: me?.settings?.language || LOCALE,
              index: '',
              status: ['active', 'unavailable'],
              sort: '',
              type: '',
              sellers: [],
              warehouses: [],
              productKinds: [],
              email: localStorage.getItem('email'),
            });
          }}
          numberOfHeaders={3}
          searchState={searchState as any}
          onHide={() => setHideFilters(!hideFilters)}
          data={offerSummaries}
          showClosed={hideFilters}
          catalog={catalog}
        />
        <S.WsWrapper>
          {renderProductsSubheader()}
          {filteredData.length > 0 ? (
            <MultiTableV1dot0 buyerOffers={filteredData} />
          ) : (
            <EmptyListResource
              text={__('Components.OffersList.zero_case.title')}
              text2={__('Components.OffersList.zero_case.subtitle')}
              imageUrl={'https://media.consentio.co/image/upload/v1641830463/ilustrations/Wholesale.png'}
              showButton={false}
            />
          )}
        </S.WsWrapper>
      </S.Row>
    </Workspace>
  );
};

export const WorkspaceOffersV1dot0 = React.memo(WorkspaceOffers);
