import { PICTURE_SMALL_SIZE } from '../../../constants';
import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';

interface IStyledProps {
  isImage?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const ImageThumbnailContainer = styled(ColumnContainer)<IStyledProps>`
  position: relative;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  border-radius: 8px;
  border: 2px solid ${(p: IStyledProps) => (p.selected ? p.theme.colors.green1 : p.theme.colors.white)};
  ${(p: IStyledProps) =>
    !p.selected &&
    !p.isImage &&
    css`
      border: 0.5px solid ${p.theme.colors.grey2};
    `}
`;

export const ImageThumbnail = styled.img<IStyledProps>`
  width: ${PICTURE_SMALL_SIZE};
  height: ${PICTURE_SMALL_SIZE};
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => (p.isImage ? '0' : p.theme.paddingSize())};
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  &:hover {
    opacity: 0.8;
  }
`;

export const DeleteIcon = styled(FontIcon)`
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: 50%;
`;
