import * as React from 'react';

import * as S from './RadioInput.styled';

interface IProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  isRadio?: boolean;
  onClick?: (value: string) => void;
  size?: number;
  value: string;
}

const RadioInput: React.FC<IProps> = React.memo(
  ({ checked, className, disabled = false, isRadio = true, onClick, value, size = 14 }) => (
    <S.Container className={className} disabled={disabled}>
      <S.RadioInput
        disabled={disabled}
        id={value}
        isChecked={checked}
        isRadio={isRadio}
        onClick={e => onClick && onClick(value)}
        size={size}
      />
    </S.Container>
  ),
);

export default RadioInput;
