import styled, { ITheme, css } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, NavigationLinks as NL, RowContainer, TextLabel } from '../../atoms';
import { FilterButton as FB, SimpleSearch as SS } from '../../molecules';

interface IStyledProps {
  color?: string;
  deleted?: boolean;
  margin?: string;
  theme: ITheme;
  toHide?: boolean;
}

export const Modal = styled(ColumnContainer)`
  position: relative;
  justify-content: flex-start;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ScrollContainer = styled(ColumnContainer)`
  flex: 1;
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
`;

export const MainRow = styled(RowContainer)`
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

export const BodyContainer = styled(ColumnContainer)`
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const ContentContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const HeaderRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const RibbonContainer = styled(ColumnContainer)``;

export const RibbonText = styled(TextLabel)`
  display: block;
`;

export const EmptyResourceWrapper = styled(RowContainer)`
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  align-items: center;
  align-self: center;
`;

export const NavigationLinks = styled(NL)`
  justify-content: flex-start;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SearchRow = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SimpleSearch = styled(SS)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  ${(p: IStyledProps) =>
    p.toHide &&
    css`
      display: none;
    `}
`;

export const FilterButton = styled(FB)`
  height: 40px;
  margin-right: 0;
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const FlexCol = styled(ColumnContainer)``;

export const TextRegular = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => (p.deleted ? p.theme.colors.grey2 : p.theme.colors.black)};
`;

export const AddressTitle = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const TextGrey2 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const AddressIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 26px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextSemiBold = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const Link = styled(Button)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: inline;
`;

export const TextBold = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: inherit;
  display: inline;
`;
