import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  allSelected?: boolean;
  badgeCount?: number;
  isFirst?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
  minWidth?: string;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  overflow: hidden;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const OptionItem = styled.span<IStyledProps>`
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.white : p.theme.colors.transparent)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(p: IStyledProps) => p.minWidth || '105px'};
  cursor: pointer;
  &:hover {
    opacity: ${(p: IStyledProps) => (p.isSelected ? 1.0 : 0.8)};
  }
  height: 100%;
`;

export const Text = styled(TextLabel)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => (p.isSelected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.black : p.theme.colors.grey1)};
  text-align: center;
`;

const getBadgeWidth = (p: IStyledProps) => {
  if (p.badgeCount < 10) return '15px';
  if (p.badgeCount < 100) return '16px';
  if (p.badgeCount < 1000) return '23px';
  return '30px';
};

export const Badge = styled(RowContainer)`
  content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
  font-family: 'Nunito';
  background-color: ${(p: IStyledProps) => p.theme.colors.red1};
  width: ${getBadgeWidth};
  height: 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;

  display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 ? 'flex' : 'none')};

  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: 1px;
`;

export const TextBadge = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  display: inline;
  font-size: 10px;
  line-height: 10px;
`;
