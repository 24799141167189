import {
  __,
  addresses,
  date,
  debounce,
  EventTrack,
  MAPPING_TOPIC,
  mappingActions,
  modalActions,
  orderActions,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceService,
  throttle,
  userActions,
  utils,
  WORKING_STATUS,
} from 'common-services';
import { APIToMapping } from 'common-services/dist/api-parsers';
import { SEARCH_SUFFIX } from 'common-services/dist/constants';
import { getSearchIndex } from 'common-services/dist/search/service';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { getDefaultColumns, IMAGE_DEFAULT, ROUTE_PATHS } from '../../../constants';
import { api } from '../../../store';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { Button, ColumnContainer, Ribbon } from '../../atoms';
import { ContactCell, EmptyListResource, FormSection, MappingFilter, Table } from '../../molecules';
import * as S from './MappingCodeList.styled';

export type OwnProps = RouteComponentProps<{ workspaceId: string; channelId?: string; clientId?: string }> & {
  contact?: IContact;
};
export interface IStateProps {
  catalog: IWorkspace;
  clientId: number;
  contacts: { [id: number]: IContact };
  countries: { [key: string]: ICountry };
  formats: IFormatsResponse;
  me: IUser;
  priceGroupIds?: { [key: string]: Array<string> };
  prices?: { [key: string]: { [key: number]: IPrice } };
  prodTypes: { [key: string]: IProdType };
}

export interface IDispatchProps {
  getAddressMappings: typeof mappingActions.addressMappingsGet;
  getPrices: typeof productActions.getPrices;
  getProductMappings: typeof mappingActions.productMappingsGet;
  importFormatsGet: typeof orderActions.importFormatsGet;
  mappingDelete: typeof mappingActions.mappingDelete;
  mappingUpdate: typeof mappingActions.mappingUpdate;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  mappingSearch: typeof mappingActions.mappingSearch;
  mappingScrollSearch: typeof mappingActions.mappingScrollSearch;
  requestProInfo: typeof userActions.requestProInfo;
}

interface IState {
  hideFilters: boolean;
  searchProductsState: ISearchMapping;
  searchAddressesState: ISearchMapping;
  searchProductsId?: string;
  searchAddressesId?: string;
  hasMoreProductsResults: boolean;
  hasMoreAddressesResults: boolean;
  // this represent the selected mappings
  productMapping?: IProductMapping;
  addressMapping?: IAddressMapping;
  // this are the ones that we show to the user
  addressMappings: Array<IAddressMapping>;
  productMappings: Array<IProductMapping>;
  // addresses that we've to choose
  deliveryAddresses: Array<IAddress>;
  pickupAddresses: Array<IAddress>;
  // tells if we have to show tables to avoid flickering
  loaded: boolean;
  amEditor: boolean;
  navTabSelected: 'products' | 'addresses';
  filterProducts: string;
  filterAddresses: string;
  facetsProducts?: IFacets;
  facetsAddresses?: IFacets;
  facetsProductsGlobal?: IFacets;
  facetsAddressesGlobal?: IFacets;
  totalProducts?: number;
  totalAddresses?: number;
  removedProducts?: { count: number; mapping: IMapping; mappingId: number };
  removedAddresses?: { count: number; mapping: IMapping; mappingId: number; mappingTopic: MAPPING_TOPIC };
}

export type IProps = IStateProps & IDispatchProps & OwnProps;

/**
 * Mapping code list component
 */
class MappingCodeList extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { me, catalog, clientId } = props;
    const amEditor = sellerWorkspaceService.getRole(catalog, me.id) !== 'viewer';
    const initSearchState = {
      catalogId: catalog.id,
      clients: clientId ? [clientId] : null,
      language: props.me.settings.language as LOCALE,
    };
    this.state = {
      hideFilters: true,
      hasMoreAddressesResults: false,
      hasMoreProductsResults: false,
      searchProductsState: initSearchState,
      searchAddressesState: initSearchState,
      amEditor,
      deliveryAddresses: [],
      addressMappings: [],
      filterProducts: '',
      filterAddresses: '',
      loaded: false,
      navTabSelected: 'products',
      pickupAddresses: [],
      productMappings: [],
    };
  }

  public componentDidMount() {
    RenderTrack.track('MappingCodeList', { renderTime: this.t });
    const { catalog, me, formats, importFormatsGet, clientId } = this.props;
    if (catalog.id) {
      // Update mapping after 200ms
      setTimeout(() => {
        this.updateMappings(true);
      }, 200);
    }
    if (!formats.all.length && catalog) {
      importFormatsGet(me.id, catalog.id);
    }
    const clientQuery = clientId ? [{ terms: { 'mapping.clients': clientId } }] : [];
    api.search
      .facets<IProductMappingAPI>(
        getSearchIndex(config.SEARCH_PREFIX, SEARCH_SUFFIX.PRODUCT_MAPPINGS),
        {
          size: 1,
          query: {
            bool: {
              must: [
                { term: { 'mapping.catalog_id': catalog.id } },
                { term: { product_found: false } },
                ...clientQuery,
              ],
            },
          },
        },
        me.id,
      )
      .then(d => {
        if (d.totalResults) {
          this.setState({
            removedProducts: {
              count: d.totalResults,
              mapping: APIToMapping(d.hits[0].mapping),
              mappingId: d.hits[0].mapping.id,
            },
          });
        }
      });
    api.search
      .facets<IAddressMappingAPI>(
        getSearchIndex(config.SEARCH_PREFIX, SEARCH_SUFFIX.ADDRESS_MAPPINGS),
        {
          size: 1,
          query: {
            bool: {
              must: [
                { term: { 'mapping.catalog_id': catalog.id } },
                { term: { address_found: false } },
                ...clientQuery,
              ],
            },
          },
        },
        me.id,
      )
      .then(d => {
        if (d.totalResults) {
          this.setState({
            removedAddresses: {
              count: d.totalResults,
              mapping: APIToMapping(d.hits[0].mapping),
              mappingId: d.hits[0].mapping.id,
              mappingTopic: d.hits[0].mapping.topic,
            },
          });
        }
      });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalog, importFormatsGet, me } = this.props;
    const { filterProducts, filterAddresses, searchProductsState, searchAddressesState, navTabSelected } = this.state;
    if (prevProps.catalog !== catalog && catalog) {
      this.setState({
        searchProductsState: { ...searchProductsState, catalogId: catalog.id },
        searchAddressesState: { ...searchAddressesState, catalogId: catalog.id },
      });
      this.updateEditPermissions();
      importFormatsGet(me.id, catalog.id);
    }
    if (
      prevState.searchProductsState !== searchProductsState ||
      prevState.searchAddressesState !== searchAddressesState ||
      prevState.navTabSelected !== navTabSelected
    ) {
      this.updateMappings();
    }
    if (prevState.filterProducts !== filterProducts || prevState.filterAddresses !== filterAddresses) {
      this.updateFilterSearch();
    }
  }

  public render() {
    const { clientId, catalog, history } = this.props;
    const { amEditor, removedProducts, removedAddresses } = this.state;
    return (
      <S.Modal>
        {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
        <S.MainRow>
          {this.renderFilters()}
          <S.ScrollContainer onScroll={this.onScroll} className="mappings-scroll-container">
            {removedProducts || removedAddresses ? (
              <Ribbon
                type="warning"
                text={
                  <ColumnContainer>
                    {removedProducts ? (
                      <S.Text>
                        {
                          utils.formatText(
                            __('MappingCodeList.removedProducts', {
                              count: removedProducts.count,
                              name: removedProducts.mapping.internal,
                            }),
                            (s, i) => <S.TextBold key={s + i}>{s}</S.TextBold>,
                          ) as any
                        }
                        {removedProducts.count === 1 ? (
                          <S.Link
                            onClick={() => {
                              history.push(
                                getPath({
                                  path: clientId
                                    ? ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS
                                    : ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS,
                                  workspaceId: catalog.id + '',
                                  clientId: clientId + '',
                                  codeId: removedProducts.mappingId + '',
                                  referenceType: 'product',
                                }),
                              );
                            }}
                            type="link"
                            withoutPadding={true}
                          >
                            {__('MappingCodeList.go_to_details')}
                          </S.Link>
                        ) : null}
                      </S.Text>
                    ) : null}
                    {removedAddresses ? (
                      <S.Text>
                        {
                          utils.formatText(
                            __('MappingCodeList.removedAddresses', {
                              count: removedAddresses.count,
                              name: removedAddresses.mapping.internal,
                            }),
                            (s, i) => <S.TextBold key={s + i}>{s}</S.TextBold>,
                          ) as any
                        }
                        {removedAddresses.count === 1 ? (
                          <S.Link
                            onClick={() => {
                              history.push(
                                getPath({
                                  path: clientId
                                    ? ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS
                                    : ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS,
                                  workspaceId: catalog.id + '',
                                  clientId: clientId + '',
                                  codeId: removedAddresses.mappingId + '',
                                  referenceType:
                                    removedAddresses.mappingTopic === MAPPING_TOPIC.ADDRESS_DELIVERY
                                      ? 'delivery'
                                      : 'pickup',
                                }),
                              );
                            }}
                            type="link"
                            withoutPadding={true}
                          >
                            {__('MappingCodeList.go_to_details')}
                          </S.Link>
                        ) : null}
                      </S.Text>
                    ) : null}
                  </ColumnContainer>
                }
              />
            ) : null}
            <S.BodyContainer>{this.renderContent()}</S.BodyContainer>
          </S.ScrollContainer>
        </S.MainRow>
      </S.Modal>
    );
  }

  /**
   * Update the search state with new search filter. (debounced)
   */
  private updateFilterSearch = debounce(() => {
    const { filterProducts, filterAddresses, navTabSelected, searchProductsState, searchAddressesState } = this.state;
    const filter = navTabSelected === 'products' ? filterProducts : filterAddresses;

    if (navTabSelected === 'products') {
      this.setState({
        searchProductsState: { ...searchProductsState, text: filter.length > 1 ? filter : '' },
      });
    } else {
      this.setState({
        searchAddressesState: { ...searchAddressesState, text: filter.length > 1 ? filter : '' },
      });
    }
  }, 500);

  private updateEditPermissions = () => {
    const { me, catalog } = this.props;
    if (catalog) {
      const amEditor = sellerWorkspaceService.getRole(catalog, me.id) !== 'viewer';
      this.setState({
        amEditor,
      });
    }
  };

  private updateMappings = (firstLoad?: boolean) => {
    const { searchProductsState, searchAddressesState, navTabSelected } = this.state;
    const { me, mappingSearch } = this.props;
    if (navTabSelected === 'products' || firstLoad) {
      mappingSearch(
        searchProductsState,
        config.SEARCH_PREFIX,
        (data: ISearchResponse, toReplace?: boolean, facets?: IFacets, facetsGlobal?: IFacets) => {
          this.setState({
            facetsProducts: facets,
            facetsProductsGlobal: facetsGlobal,
            hasMoreProductsResults: data.hasMore,
            loaded: true,
            productMappings: data.hits,
            searchProductsId: data.searchId,
            totalProducts: data.totalResults,
          });
        },
        me.id,
        'products',
      );
    }
    if (navTabSelected === 'addresses' || firstLoad) {
      mappingSearch(
        searchAddressesState,
        config.SEARCH_PREFIX,
        (data: ISearchResponse, toReplace?: boolean, facets?: IFacets, facetsGlobal?: IFacets) => {
          this.setState({
            facetsAddresses: facets,
            facetsAddressesGlobal: facetsGlobal,
            hasMoreAddressesResults: data.hasMore,
            loaded: true,
            addressMappings: data.hits,
            searchAddressesId: data.searchId,
            totalAddresses: data.totalResults,
          });
        },
        me.id,
        'addresses',
      );
    }
  };

  private loadMoreMappings = throttle(() => {
    const { catalog, mappingScrollSearch } = this.props;
    const {
      addressMappings,
      hasMoreAddressesResults,
      hasMoreProductsResults,
      navTabSelected,
      productMappings,
      searchAddressesId,
      searchProductsId,
    } = this.state;
    const searchId = navTabSelected === 'products' ? searchProductsId : searchAddressesId;
    if (searchId && catalog)
      mappingScrollSearch(
        searchId,
        catalog.id,
        navTabSelected === 'products' ? 'products' : 'addresses',
        (data?: ISearchResponse) => {
          if (data) {
            this.setState({
              productMappings: navTabSelected === 'products' ? [...productMappings, ...data.hits] : productMappings,
              addressMappings: navTabSelected === 'addresses' ? [...addressMappings, ...data.hits] : addressMappings,
              searchProductsId: navTabSelected === 'products' ? data.searchId : searchProductsId,
              searchAddressesId: navTabSelected === 'addresses' ? data.searchId : searchAddressesId,
              hasMoreProductsResults: navTabSelected === 'products' ? data.hasMore : hasMoreProductsResults,
              hasMoreAddressesResults: navTabSelected === 'addresses' ? data.hasMore : hasMoreAddressesResults,
            });
          }
        },
      );
  }, 200);

  /**
   * handle the scroll event for get more results
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { navTabSelected, hasMoreProductsResults, hasMoreAddressesResults } = this.state;
    const hasMoreResults = navTabSelected === 'products' ? hasMoreProductsResults : hasMoreAddressesResults;
    if (
      hasMoreResults &&
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180
    ) {
      this.loadMoreMappings();
    }
  };

  private renderFilters() {
    const {
      hideFilters,
      navTabSelected,
      facetsAddresses,
      facetsProducts,
      facetsAddressesGlobal,
      facetsProductsGlobal,
      searchProductsState,
      searchAddressesState,
    } = this.state;
    const searchState = navTabSelected === 'products' ? searchProductsState : searchAddressesState;
    const { catalog, clientId } = this.props;
    const facets = navTabSelected === 'products' ? facetsProducts : facetsAddresses;
    const facetsGlobal = navTabSelected === 'products' ? facetsProductsGlobal : facetsAddressesGlobal;
    return (
      <MappingFilter
        catalog={catalog}
        changeSearchState={s => {
          const filters = productService.getTrackedFilters(s);
          if (filters.length) {
            EventTrack.track('mappings_filters', {
              catalog_id: catalog?.id,
              client_id: clientId,
              filters,
              type: navTabSelected,
            });
          }
          if (navTabSelected === 'products') this.setState({ searchProductsState: s });
          if (navTabSelected === 'addresses') this.setState({ searchAddressesState: s });
        }}
        clearFilters={this.clearFilters}
        facets={facetsGlobal}
        facetsGlobal={facetsGlobal}
        numberOfHeaders={0}
        onHide={hide => this.setState({ hideFilters: hide })}
        searchState={searchState}
        showClosed={hideFilters}
        showOver="only-ipad"
      />
    );
  }

  private clearFilters = () => {
    const { catalog, clientId, me } = this.props;
    const { navTabSelected } = this.state;
    const resetState = {
      catalogId: catalog?.id,
      clients: clientId ? [clientId] : null,
      language: me.settings.language as LOCALE,
    };

    if (navTabSelected === 'products') {
      this.setState({
        searchProductsState: resetState,
      });
    } else {
      this.setState({
        searchAddressesState: resetState,
      });
    }
    EventTrack.track('mappings_filter_clear', {
      catalog_id: catalog?.id,
      client_id: clientId,
      type: navTabSelected,
    });
  };

  private renderContent = () => {
    const { catalog, clientId, history } = this.props;
    const {
      filterProducts,
      filterAddresses,
      hideFilters,
      productMappings,
      addressMappings,
      loaded,
      navTabSelected,
      totalProducts,
      totalAddresses,
    } = this.state;
    if (!loaded) return null;
    const filter = navTabSelected === 'products' ? filterProducts : filterAddresses;
    if (!productMappings.length && !addressMappings.length && !filter) {
      return this.renderEmptySelection();
    }
    const showSearch =
      filter ||
      (navTabSelected === 'products' && productMappings.length > 0) ||
      (navTabSelected === 'addresses' && addressMappings.length > 0);
    return (
      <S.ContentContainer>
        <S.HeaderRow>
          <S.NavigationLinks
            className="mapping-code-list-navigation-links"
            keySelected={navTabSelected}
            options={[
              {
                key: 'products',
                label: __('MappingCodeList.ProductTable.title'),
                count: totalProducts,
              },
              {
                key: 'addresses',
                label: __('MappingCodeList.AddressTable.title_address'),
                count: totalAddresses,
              },
            ]}
            onSelectOption={(key: string) => this.setState({ navTabSelected: key as 'products' | 'addresses' })}
          />
          {config.TOGGLE_MAPPING_V1.enabled ? (
            <Button
              type="principal"
              onClick={() =>
                history.push(
                  getPath({
                    path: clientId
                      ? ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS
                      : ROUTE_PATHS.WORKSPACE_NEW_REFERENCE_DETAILS,
                    workspaceId: catalog.id + '',
                    clientId: clientId + '',
                    codeId: 'new',
                  }),
                )
              }
            >
              {__('MappingCodeList.Add.cta')}
            </Button>
          ) : null}
        </S.HeaderRow>
        {showSearch ? (
          <S.SearchRow>
            <S.FilterButton
              filtersOpened={!hideFilters}
              filtersSelected={this.getFiltersCount()}
              onClick={() => this.setState({ hideFilters: !hideFilters })}
            />
            <S.SimpleSearch
              onChange={f => this.setState({ filterProducts: f })}
              placeHolder={__('MappingCodeList.ProductTable.search')}
              id="input_search_mapping_code_list_products"
              toHide={navTabSelected === 'addresses'}
            />
            <S.SimpleSearch
              onChange={f => this.setState({ filterAddresses: f })}
              placeHolder={__('MappingCodeList.AddressTable.search')}
              id="input_search_mapping_code_list_addresses"
              toHide={navTabSelected === 'products'}
            />
          </S.SearchRow>
        ) : null}
        {navTabSelected === 'products' ? this.renderProductTable() : null}
        {navTabSelected === 'addresses' ? this.renderAddressTable() : null}
      </S.ContentContainer>
    );
  };

  /**
   * Render Product table
   */
  private renderProductTable = () => {
    const { contacts, prodTypes, catalog, clientId, history, formats, me } = this.props;
    const { filterAddresses, filterProducts, productMappings, navTabSelected } = this.state;
    if (!productMappings.length) {
      return this.renderEmptyResource();
    }
    const filter = navTabSelected === 'products' ? filterProducts : filterAddresses;
    const isRefEan = catalog.productMappingStrategy === 'ean_code';

    return (
      <Table
        productColumns={getDefaultColumns()}
        className="mapping-code-list-product-table"
        columns={[
          {
            id: 'product',
            title: __('MappingCodeList.ProductTable.product_field'),
            element: (productMapping: IProductMapping) => {
              const { product } = productMapping;
              const typeVariety = productService.getProductTypeVarietyDisplay(
                product.type.type,
                prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
                product.type.variety,
              );

              return (
                <S.FlexRow>
                  {productMapping.productFound ? (
                    <ContactCell
                      avatar={
                        product.images[0]?.image?.url || prodTypes[product.type.type]?.defaultImageUrl || IMAGE_DEFAULT
                      }
                      primaryText={product.productTitle || typeVariety}
                      workingStatus={
                        product.status === 'active' ? WORKING_STATUS.AVAILABLE : WORKING_STATUS.NOT_AVAILABLE
                      }
                    />
                  ) : (
                    <>
                      <S.WarningIcon name="Warning" disableHover={true} />
                      <S.TextGrey2>
                        {__('ProductMapping.product_info.text_1')}
                        <S.TextRegular>{' "' + productMapping.mapping.internal + '" '}</S.TextRegular>
                        {__('ProductMapping.product_info.text_2')}
                      </S.TextGrey2>
                    </>
                  )}
                </S.FlexRow>
              );
            },
            minWidth: '140px',
            width: '25%',
          },
          {
            id: 'internal-reference',
            title: isRefEan ? __('MappingCodeList.ProductTable.ean_code') : 'SKU',
            width: '20%',
            value: (productMapping: IProductMapping) => productMapping.mapping.internal,
            getColor: (productMapping: IProductMapping) =>
              productMapping.productFound ? theme.colors.black : theme.colors.grey2,
          },
          {
            id: 'client-reference',
            title: __('MappingCodeList.ProductTable.client_reference'),
            width: '20%',
            value: (productMapping: IProductMapping) => productMapping.mapping.external,
            getColor: (productMapping: IProductMapping) =>
              productMapping.productFound ? theme.colors.black : theme.colors.grey2,
            info: __('MappingCodeList.ProductTable.tooltip_client_reference'),
          },
          {
            id: 'format',
            title: 'Magic Order',
            width: '15%',
            element: (productMapping: IProductMapping) => {
              const format = formats.all.find(f => f.slug === productMapping.mapping.parseFormat);
              if (!format) return null;
              return <ContactCell avatar={format.logo_url} primaryText={format.label} />;
            },
          },
          {
            id: 'last-update',
            title: __('MappingCodeList.ProductTable.last_update'),
            width: '15%',
            element: (productMapping: IProductMapping) => {
              const { mapping } = productMapping;
              if (!mapping.updatedAt && !mapping.updatedBy)
                return (
                  <S.FlexCol>
                    <S.TextRegular deleted={!productMapping.productFound}>-</S.TextRegular>
                  </S.FlexCol>
                );
              let lastAuthorName = mapping.updatedBy ? contacts[mapping.updatedBy]?.name : undefined;
              if (!lastAuthorName && mapping.updatedBy === me.id) lastAuthorName = me.name;
              return (
                <S.FlexCol>
                  {mapping.updatedAt ? (
                    <S.TextSemiBold deleted={!productMapping.productFound}>
                      {date.formatLongDate(mapping.updatedAt || mapping.createdAt) +
                        ' · ' +
                        date.formatTime(mapping.updatedAt || mapping.createdAt, me.settings.hourFormat)}
                    </S.TextSemiBold>
                  ) : null}
                  {lastAuthorName ? (
                    <S.TextRegular deleted={!productMapping.productFound}>{lastAuthorName}</S.TextRegular>
                  ) : null}
                </S.FlexCol>
              );
            },
          },
        ]}
        emptyText={__('Components.OrdersList.noProducts.title', { product: filter })}
        onClickRow={(data, e) => {
          history.push(
            getPath({
              path: clientId
                ? ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS
                : ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS,
              workspaceId: catalog.id + '',
              clientId: clientId + '',
              codeId: data.mapping.id + '',
              referenceType: 'product',
            }),
          );
        }}
        rowCursor={'pointer'}
        selectable={false}
        showStickyHeader={true}
        scrollClassName="mappings-scroll-container"
        values={productMappings}
      />
    );
  };

  /**
   * Render address table
   */
  private renderAddressTable = () => {
    const { catalog, clientId, contacts, history, formats, me } = this.props;
    const { filterAddresses, filterProducts, addressMappings, navTabSelected } = this.state;
    if (!addressMappings.length) {
      return this.renderEmptyResource();
    }
    const filter = navTabSelected === 'products' ? filterProducts : filterAddresses;
    return (
      <Table
        productColumns={getDefaultColumns()}
        className="mapping-code-list-address-table"
        columns={[
          {
            id: 'address',
            title: __('MappingCodeList.AddressTable.address_field'),
            element: (addressMapping: IAddressMapping) => {
              const { address, addressFound } = addressMapping;
              const { countries } = this.props;

              if (addressFound) {
                return (
                  <S.FlexRow>
                    <S.AddressIcon name="Address" disableHover={true} />
                    <S.FlexCol>
                      <S.AddressTitle deleted={!addressFound}>
                        {addresses.getAddressName(address, countries, true)}
                      </S.AddressTitle>
                      <S.TextRegular deleted={!addressFound}>
                        {addresses.getAddressName(address, countries, false)}
                      </S.TextRegular>
                    </S.FlexCol>
                  </S.FlexRow>
                );
              }

              return (
                <S.FlexRow>
                  <S.WarningIcon name="Warning" disableHover={true} />
                  <S.FlexCol>
                    <S.TextGrey2>
                      {__('AddressMapping.address_info.text_1')}
                      <S.TextRegular>{' "' + addressMapping.mapping.internal + '" '}</S.TextRegular>
                      {__('AddressMapping.address_info.text_2')}
                    </S.TextGrey2>
                  </S.FlexCol>
                </S.FlexRow>
              );
            },
            minWidth: '140px',
            width: '30%',
          },
          {
            id: 'external-id',
            title: __('MappingCodeList.AddressTable.external_id'),
            width: '15%',
            value: (addressMapping: IAddressMapping) => addressMapping.mapping.internal,
            getColor: (addressMapping: IAddressMapping) =>
              addressMapping.addressFound ? theme.colors.black : theme.colors.grey2,
          },
          {
            id: 'client-reference',
            title: __('MappingCodeList.ProductTable.client_reference'),
            width: '15%',
            value: (addressMapping: IAddressMapping) => addressMapping.mapping.external,
            getColor: (addressMapping: IAddressMapping) =>
              addressMapping.addressFound ? theme.colors.black : theme.colors.grey2,
          },
          {
            id: 'format',
            title: 'Magic Order',
            width: '15%',
            element: (addressMapping: IAddressMapping) => {
              const format = formats.all.find(f => f.slug === addressMapping.mapping.parseFormat);
              if (!format) return null;
              return <ContactCell avatar={format.logo_url} primaryText={format.label} />;
            },
          },
          {
            id: 'last-update',
            title: __('MappingCodeList.ProductTable.last_update'),
            width: '15%',
            element: (addressMapping: IAddressMapping) => {
              const { mapping } = addressMapping;
              if (!mapping.updatedAt && !mapping.updatedBy)
                return (
                  <S.FlexCol>
                    <S.TextRegular deleted={!addressMapping.addressFound}>-</S.TextRegular>
                  </S.FlexCol>
                );
              let lastAuthorName = mapping.updatedBy ? contacts[mapping.updatedBy]?.name : undefined;
              if (!lastAuthorName && mapping.updatedBy === me.id) lastAuthorName = me.name;
              return (
                <S.FlexCol>
                  {mapping.updatedAt ? (
                    <S.TextSemiBold deleted={!addressMapping.addressFound}>
                      {date.formatLongDate(mapping.updatedAt || mapping.createdAt) +
                        ' · ' +
                        date.formatTime(mapping.updatedAt || mapping.createdAt, me.settings.hourFormat)}
                    </S.TextSemiBold>
                  ) : null}
                  {lastAuthorName ? (
                    <S.TextRegular deleted={!addressMapping.addressFound}>{lastAuthorName}</S.TextRegular>
                  ) : null}
                </S.FlexCol>
              );
            },
          },
        ]}
        emptyText={__('Components.OrdersList.noProducts.title', { product: filter })}
        onClickRow={(data, e) => {
          history.push(
            getPath({
              path: clientId
                ? ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS
                : ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS,
              workspaceId: catalog.id + '',
              clientId: clientId + '',
              codeId: data.mapping.id + '',
              referenceType: data.mapping.topic === MAPPING_TOPIC.ADDRESS_DELIVERY ? 'delivery' : 'pickup',
            }),
          );
        }}
        rowCursor={'pointer'}
        selectable={false}
        showStickyHeader={true}
        scrollClassName="mappings-scroll-container"
        values={addressMappings}
      />
    );
  };

  private renderEmptySelection = () => {
    return (
      <FormSection
        className="mapping-code-list-form-empty"
        containerPadding={4}
        id="products"
        title={__('MappingCodeList.EmptyResource.title')}
      >
        {this.renderEmptyResource()}
      </FormSection>
    );
  };

  private renderEmptyResource = () => {
    const { catalog, clientId, history } = this.props;
    const { navTabSelected, filterAddresses, filterProducts } = this.state;
    const filter = navTabSelected === 'products' ? filterProducts : filterAddresses;
    return (
      <S.EmptyResourceWrapper>
        {filter ? (
          <EmptyListResource
            text={__('Components.ProductsList.QueryEmptyResults', { search: filter })}
            text2={__('Components.ProductsList.EmptyResultsTryAgain', { search: filter })}
            showButton={false}
            imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
          />
        ) : (
          <EmptyListResource
            imageUrl={IMAGES.mappingCodeOrderImport.replace('f_auto', 'f_auto,c_scale,w_260')}
            buttonText={__('MappingCodeList.Add.cta')}
            showButton={config.TOGGLE_MAPPING_V1.enabled}
            text2={__('MappingCodeList.EmptyResource.both')}
            buttonAction={() => {
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS,
                  workspaceId: catalog.id + '',
                  clientId: clientId + '',
                  codeId: 'new',
                }),
              );
            }}
          />
        )}
      </S.EmptyResourceWrapper>
    );
  };

  private renderRibbon = (productErrorsCount: number, addressErrorsCount: number) => {
    if (productErrorsCount || addressErrorsCount) {
      const ribbonText = (
        <S.RibbonContainer>
          {addressErrorsCount ? (
            <S.RibbonText>
              {addressErrorsCount === 1
                ? __('MappingCodeList.Ribbon.address')
                : __('MappingCodeList.Ribbon.addresses', { errored_addresses: addressErrorsCount })}
            </S.RibbonText>
          ) : null}
          {productErrorsCount ? (
            <S.RibbonText>
              {productErrorsCount === 1
                ? __('MappingCodeList.Ribbon.product')
                : __('MappingCodeList.Ribbon.products', { errored_products: productErrorsCount })}
            </S.RibbonText>
          ) : null}
        </S.RibbonContainer>
      );
      return <Ribbon text={ribbonText} type="warning" />;
    }
    return null;
  };

  /**
   * Count selected filters
   */
  private getFiltersCount() {
    const { navTabSelected, searchAddressesState, searchProductsState } = this.state;
    const searchState = navTabSelected === 'products' ? searchProductsState : searchAddressesState;
    const { clients, formats } = searchState;
    return (clients?.length || 0) + (formats?.length || 0);
  }
}

export default MappingCodeList;
