import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer } from '../../atoms';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
}
export const EmptyAddressContainer = styled(ColumnContainer)`
  max-width: 450px;
  align-items: center;
`;

export const FontIconWrapper = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.primary};
  font-size: 68px;
  line-height: 1.1;
  height: 68px;
`;

export const Text = styled(TextLabel)`
  width: 100%;
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  margin: ${(p: IStyledProps) => p.theme.paddingView};
`;
export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: auto;
`;
