import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import * as userAppActions from '../../../actions/user';
import * as S from './ZeroCase.styled';

export interface IProps {
  link: string;
  title: string;
  subtitle: string;
  isCta?: boolean;
  hasButton?: boolean;
  buttonText?: string;
  action?: () => void;
}
const ZeroCase: React.FC<IProps> = ({ link, title, subtitle, isCta = false, hasButton, buttonText, action }) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const formatTextLink = (text: string) => {
    return text.split('**').map((s, index) =>
      index % 2 ? (
        <S.TextBlue
          isCta={isCta}
          key={s + index}
          onClick={isCta ? () => dispatch(userAppActions.supportAction()) : null}
        >
          {s}
        </S.TextBlue>
      ) : (
        s
      ),
    );
  };

  return (
    <S.ZeroCaseWrapper>
      <S.Image src={link} />
      <S.ZeroCaseTitle>{title}</S.ZeroCaseTitle>
      <S.ZeroCaseText>{formatTextLink(subtitle)}</S.ZeroCaseText>
      {hasButton ? (
        <S.ZeroCaseButton type="secondary" onClick={action}>
          {buttonText}
        </S.ZeroCaseButton>
      ) : null}
    </S.ZeroCaseWrapper>
  );
};

export default ZeroCase;
