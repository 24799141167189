import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import { FormSection as FS } from '../../molecules';
import { PostHeader } from '../../molecules/PostMessage/PostMessage.component';

interface IStyledProps {
  isLast?: boolean;
  maxLines?: number;
  seeMore?: boolean;
  showSeeMore?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: 100%;
  justify-content: flex-start;
  position: relative;
  outline: none;
  overflow-y: auto;
`;

export const BodyContainer = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ContentContainer = styled(ColumnContainer)`
  min-width: 615px;
  max-width: 615px;
`;

export const CardContainer = styled(ColumnContainer)`
  min-height: min-content;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const CardBottom = styled(ColumnContainer)`
  width: 100%;
`;

export const TitleCard = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
    padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const TextBig = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  white-space: pre-wrap;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentCardText = styled(TextBig)<IStyledProps>`
  position: relative;

  ${(p: IStyledProps) =>
    p.showSeeMore &&
    !p.seeMore &&
    css`
      -webkit-line-clamp: ${p.maxLines || 10};
      -webkit-box-orient: vertical;
      display: -webkit-box;
    `}
`;

export const TitleCardGrey = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const SubtitleCard = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const OtherMemberSection = styled(ColumnContainer)`
  min-height: min-content;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const MemberItem = styled(PostHeader)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 0 : 2)};
`;

export const FormSection = styled(FS)`
  padding-right: 0;
`;

export const Row = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
`;

export const ArchiveButton = styled(Button)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const Link = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  line-height: 22px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  :visited {
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
