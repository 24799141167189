import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../../atoms';

interface IStyledProps {
  isHigher?: boolean;
  theme: ITheme;
}

export const GMVContainer = styled(ColumnContainer)`
  width: 100%;
  height: 40%;
  padding: 8px;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  align-items: center;
  justify-content: center;
`;

export const FooterContainer = styled(ColumnContainer)`
  width: 100%;
  height: 35%;
  padding: 8px calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  align-items: center;
  justify-content: center;
`;

export const ChangeRow = styled(RowContainer)`
  align-items: center;
  padding-top: 4px;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: center;
`;

export const TextGMV = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  text-align: center;
  padding: 4px 0;
`;

export const TextCountOrders = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  text-align: center;
`;

export const TextGrey = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: center;
`;

export const ArrowContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => (p.isHigher ? p.theme.colors.green1 : p.theme.colors.red1)};
  padding: 2px;
  width: 21px;
  height: 21px;
  margin-right: 8px;
`;

export const ArrowIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  width: ${(p: IStyledProps) => p.theme.fontSize.small};
  height: ${(p: IStyledProps) => p.theme.fontSize.small};
  transform: ${(p: IStyledProps) => (p.isHigher ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;
