import { ORDER_STATUS } from 'common-services';
import styled from 'styled-components';

import { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  status?: ORDER_STATUS;
  theme: ITheme;
  isMessage?: boolean;
  isRead?: boolean;
}

const ORDER_CARD_WIDTH = '405px';
const lightGrey = '#F1F1F1';

export const Container = styled(ColumnContainer)<IStyledProps>`
  max-width: ${ORDER_CARD_WIDTH};
  min-width: ${ORDER_CARD_WIDTH};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
  box-shadow: ${(p: IStyledProps) => (p.isRead ? 'none' : p.theme.boxShadow)};
  background-color: ${(p: IStyledProps) => (p.isRead ? lightGrey : p.theme.colors.white)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  align-self: flex-start;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const Head = styled(RowContainer)<IStyledProps>`
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  height: 52px;
  padding: 8px ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => getBackgroundColor(p.theme, p.status!).replace('1)', '0.4)')};
  align-items: center;
`;

export const Body = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: 0;
  overflow: hidden;
`;
export const Details = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const BodyFooter = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const Link = styled(TextLabel)`
  line-height: 16px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  text-decoration: underline;
`;
export const Price = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const PriceCopy = styled(RowContainer)`
  line-height: 18px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const Name = styled(RowContainer)`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const Role = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const SectionIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const Action = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CloneButton = styled(Button)`
  flex: 1;
`;

export const HeadIconWrapper = styled(RowContainer)`
  align-self: flex-start;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 15px;
`;
export const Icon = styled(FontIcon)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => getColor(p.theme, p.status!)};
`;
export const IconFakeRow = styled(RowContainer)`
  height: 18px;
`;

export const HeadTitleWrapper = styled(ColumnContainer)`
  flex: 1;
`;
export const HeadRow = styled(RowContainer)`
  justify-content: space-between;
  align-items: flex-end;
`;
export const HeadTitle = styled(TextLabel)`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: flex;
`;
export const HeadTitleHighlight = styled(HeadTitle)<IStyledProps>`
  flex: 0;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => getColor(p.theme, p.status!)};
  white-space: nowrap;
`;
export const HeadSubtitle = styled(TextLabel)`
  flex: 1;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const HeadTime = styled(TextLabel)`
  text-align: right;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;
export const HeadDate = styled(HeadTime)`
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const OrderItemRow = styled(RowContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2px;
  min-width: calc(${ORDER_CARD_WIDTH} - 58px);
  max-width: calc(${ORDER_CARD_WIDTH} - 58px);
`;
export const SectionColumn = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`;

export const TextOrderItem = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const TextMoreItems = styled(TextOrderItem)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  flex: 1;
`;
export const TextItemPrice = styled(TextOrderItem)`
  text-align: right;
`;

export const Section = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: flex-start;
  overflow: hidden;
`;

export const ItemPrice = styled(RowContainer)`
  min-width: 65px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

function getBackgroundColor(theme: ITheme, status: ORDER_STATUS): string {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return theme.colors.purple2;

    case ORDER_STATUS.ACCEPTED:
      return theme.colors.green2;

    case ORDER_STATUS.CANCELED:
      return theme.colors.grey3;

    default:
      return theme.colors.grey3;
  }
}

function getColor(theme: ITheme, status: ORDER_STATUS): string {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return theme.colors.purple1;

    case ORDER_STATUS.ACCEPTED:
      return theme.colors.green1;

    case ORDER_STATUS.CANCELED:
      return theme.colors.grey2;

    default:
      return theme.colors.grey2;
  }
}
