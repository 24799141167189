import { CHANNEL_MEMBER_ROLE } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  DotsIndicator as DI,
  FontIcon,
  RowContainer,
  SimpleDropdown,
  TextLabel,
} from '../../atoms';
import MI from '../MessageInput';
import PostComment from '../PostComment/PostComment.component';

interface IStyledProps {
  hasMultipleImages?: boolean;
  hasReactions?: boolean;
  hasText?: boolean;
  height?: number;
  isImageLoading?: boolean;
  isInfoSection?: boolean;
  reaction?: PostReaction;
  role?: CHANNEL_MEMBER_ROLE;
  seeMore?: boolean;
  showClose?: boolean;
  showSeeMore?: boolean;
  sidePosition?: 'left' | 'right';
  size?: number;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  min-height: min-content;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
`;

export const CardTop = styled(RowContainer)`
  width: 100%;
  overflow: hidden;
  min-height: 66px;
`;

export const HeaderContent = styled(ColumnContainer)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-height: 48px;
  ${(p: IStyledProps) =>
    p.isInfoSection &&
    css`
      min-width: 85%;
    `}
`;

export const RowName = styled(RowContainer)`
  align-items: center;
  flex-wrap: wrap;
  ${(p: IStyledProps) =>
    p.isInfoSection &&
    css`
      justify-content: space-between;
    `}
`;

const getBubbleColor = (p: IStyledProps) => {
  switch (p.role) {
    case CHANNEL_MEMBER_ROLE.OWNER:
      return p.theme.colors.grey1;
    case CHANNEL_MEMBER_ROLE.ADMIN:
      return p.theme.colors.green1;
    default:
      return p.theme.colors.black;
  }
};

const getBubbleBackground = (p: IStyledProps) => {
  switch (p.role) {
    case CHANNEL_MEMBER_ROLE.OWNER:
      return p.theme.colors.grey3;
    case CHANNEL_MEMBER_ROLE.ADMIN:
      return p.theme.colors.green2;
    default:
      return p.theme.colors.grey4;
  }
};

export const RoleBubble = styled.span<IStyledProps>`
  display: flex;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${getBubbleColor};
  background-color: ${getBubbleBackground};
  border-radius: 3px;
  padding: 0 3px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CardBody = styled(RowContainer)`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const TextBold = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextSemiBold = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 22px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p: IStyledProps) =>
    p.showSeeMore &&
    !p.seeMore &&
    css`
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    `}
`;

export const TextDate = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: visible;
`;

export const Link = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  line-height: 22px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  :visited {
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const ImageContainer = styled(RowContainer)<IStyledProps>`
  width: calc(100% + ${(p: IStyledProps) => p.theme.paddingSize(6)});
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img<IStyledProps>`
  width: 100%;
  height: auto;
  max-height: 550px;
  margin-top: ${(p: IStyledProps) => (p.hasText ? p.theme.paddingSize(3) : '0')};
  object-fit: contain;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  position: relative;
  left: -18px;

  ${(p: IStyledProps) =>
    p.hasMultipleImages &&
    css`
      max-width: 100%;
      min-height: 350px;
      max-height: 350px;

      @media (max-width: ${p.theme.sizes.mobile}) {
        min-height: 250px;
        max-height: 250px;
      }
    `}

  ${(p: IStyledProps) =>
    p.isImageLoading &&
    css`
      filter: brightness(0.5);
    `}
`;

export const ArrowContainer = styled(RowContainer)<IStyledProps>`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 50%;
  ${(p: IStyledProps) =>
    p.sidePosition === 'right'
      ? css`
          right: ${p.theme.paddingSize(9)};

          @media (max-width: ${p.theme.sizes.mobile}) {
            right: ${p.theme.paddingSize(7)};
          }
        `
      : css`
          left: ${p.theme.paddingSize(3)};

          @media (max-width: ${p.theme.sizes.mobile}) {
            left: ${p.theme.paddingSize(1)};
          }
        `}
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  transform: translateY(-50%) rotate(${(p: IStyledProps) => (p.sidePosition === 'right' ? 0 : '180deg')});
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  align-items: center;
  justify-content: center;
`;

export const ArrowIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: 22px;
`;

export const DotsIndicator = styled(DI)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  margin-bottom: 0;
  align-self: center;
`;

export const MetadataContainer = styled(RowContainer)`
  width: 100%;
  min-height: 130px;
  height: 130px;
  max-height: 130px;
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

export const MetadataImage = styled.img`
  height: 130px;
  width: 130px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  object-fit: cover;
  overflow: hidden;
`;

export const MetadataRight = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 130px;
  flex: 1;
  overflow: hidden;
`;

export const TextTitleMetadata = styled(TextBold)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  overflow: hidden;
  ${(p: IStyledProps) =>
    p.showClose &&
    css`
      padding-right: ${p.theme.paddingSize(3)};
    `}
`;

export const TextDescriptionMetadata = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

export const TextLinkMetadata = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseButton = styled(RowContainer)`
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2px;
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingSize()};
  top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const CloseIcon = styled(FontIcon)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const SeeMoreContainer = styled(ColumnContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-left: 2px;
  justify-content: center;
  height: 22px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TextLink = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-align: right;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const OptionsDropDown = styled(SimpleDropdown)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  right: 15px;
`;

export const KebabIcon = styled(FontIcon)`
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const BottomContainer = styled(ColumnContainer)`
  width: 100%;
  align-items: center;
`;

export const ActionsContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const CommentsContainer = styled(RowContainer)`
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const ActionButton = styled(Button)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  min-width: 100px;
`;

const getReactionColor = (p: IStyledProps) => {
  switch (p.reaction) {
    case 'interesting':
      return p.theme.colors.orange1;
    case 'funny':
      return p.theme.colors.green1;
    case 'love':
      return p.theme.colors.red1;
    case 'go':
      return p.theme.colors.purple1;
    case 'like':
      return p.theme.colors.blue1;
    default:
      return 'inherit';
  }
};

export const ReactionButton = styled(ActionButton)<IStyledProps>`
  font-weight: ${(p: IStyledProps) => (p.reaction ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  color: ${getReactionColor};
  transition: none;
`;

export const MessageInput = styled(MI)`
  flex: 1;
  padding: 0;
`;

export const LikesCommentSummary = styled(RowContainer)`
  width: calc(100% + 36px);
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: ${(p: IStyledProps) => (p.hasReactions ? 'space-between' : 'flex-end')};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const ThreadContainer = styled(ColumnContainer)`
  width: 100%;
`;

export const ThreadMessage = styled(PostComment)`
  margin: 0;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} 0;
  width: 100%;
`;

export const ThreadDivider = styled(RowContainer)`
  width: 100%;
  height: 0.5px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const SeeMoreCommentsContainer = styled(RowContainer)`
  width: 100%;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-50%);
  z-index: 1;
  min-width: max-content;
  margin: 0;
  min-height: min-content;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.6s ease-in-out;

  &:hover {
    .post-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const PopupPreview = styled(Popup)`
  top: -45px;
`;

export const ButtonContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};

    .post-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
  position: relative;
  cursor: pointer;
`;

export const ReactionsSummaryContainer = styled(RowContainer)`
  &:hover {
    .post-reactions-popup {
      visibility: visible;
      opacity: 1;
    }
  }
  position: relative;
`;

export const OfflineContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextGrey = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-align: center;
`;

export const BlurredContainer = styled(ColumnContainer)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 62%;
  background: linear-gradient(180deg, transparent 0%, ${(p: IStyledProps) => p.theme.colors.grey4} 25%);
  justify-content: center;
  align-items: center;
  min-height: 125px;
`;

export const SignInContainer = styled(ColumnContainer)`
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const SignInButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  min-width: 280px;
`;

export const ImageLoadingBackground = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: -18px;
  right: 18px;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;

export const PollContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const PollButton = styled(Button)`
  width: 100%;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const PollAnswer = styled(ColumnContainer)`
  width: 100%;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  .poll-message-progress-text {
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
    color: ${(p: IStyledProps) => p.theme.colors.black};
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  }
`;

export const TextPollVotes = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
