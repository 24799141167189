import { __ } from 'common-services';
import * as React from 'react';

import * as S from './FilterButton.styled';

export interface IProps {
  className?: string;
  filtersOpened: boolean;
  filtersSelected: number;
  onClick?: () => void;
}

const FilterButton: React.FC<IProps> = ({ className, filtersSelected, filtersOpened, onClick }) => (
  <S.FilterButton
    className={className}
    filtersOpened={filtersOpened}
    filtersSelected={filtersSelected}
    iconName="Filter"
    iconSize="15px"
    onClick={onClick}
    type="skip"
  >
    <span>{__('PublicShowroom.filter')}</span>
    {filtersSelected ? <S.Badge>{filtersSelected}</S.Badge> : null}
  </S.FilterButton>
);

export default React.memo(FilterButton);
