/**
 * Takes a image file resize if needed and return a string base64-encoded
 */
export function resizeImage(url: string, cb: (resizedUrl: string) => void, maxSize?: number) {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    let width = img.width;
    let height = img.height;
    const maxImageSize = maxSize || MAX_IMAGE_SIZE;
    if (width / height > 1) {
      width = img.width > maxImageSize ? maxImageSize : img.width;
      height = img.width > maxImageSize ? (img.height / img.width) * maxImageSize : img.height;
    } else {
      width = img.height > maxImageSize ? (img.width / img.height) * maxImageSize : img.width;
      height = img.height > maxImageSize ? maxImageSize : img.height;
    }
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(async blob => {
      if (!blob) return;
      const res = await fetch(window.URL.createObjectURL(blob));
      const file: any = await res.blob();
      file.lastModifiedDate = new Date();
      file.name = `${file.lastModifiedDate.getTime()}.png`;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = event => {
        throw new Error('File could not be read! Code ' + (event.target as any).error.code);
      };
      reader.onload = () => cb(reader.result as string);
    }, 'image/png');
  };
}

const MAX_IMAGE_SIZE = 1200;
