import * as React from 'react';

import * as S from './PublicShowroom.styled';

interface IProps {
  children: any;
}

interface IState {
  showArrowBack: boolean;
  showArrowForward: boolean;
}

/**
 * List of products in a single row
 */
class RowList extends React.PureComponent<IProps, IState> {
  private innerRef: React.RefObject<HTMLDivElement>;
  private outerRef: React.RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      showArrowBack: false,
      showArrowForward: false,
    };
    this.innerRef = React.createRef();
    this.outerRef = React.createRef();
  }

  public componentDidMount() {
    if (this.innerRef.current.offsetWidth > this.outerRef.current.offsetWidth) {
      this.setState({
        showArrowBack: !!this.outerRef.current.scrollLeft,
        showArrowForward: this.outerRef.current.scrollLeft < this.innerRef.current.offsetWidth,
      });
    }
  }

  public render() {
    const { children } = this.props;
    const { showArrowBack, showArrowForward } = this.state;
    return (
      <S.RowWrapper>
        <S.RowGroup className="outerRow" ref={this.outerRef} onScroll={this.onScroll}>
          {showArrowBack ? (
            <S.ArrowBackWrapper
              onClick={() =>
                (this.outerRef.current.scrollLeft =
                  this.outerRef.current.scrollLeft - this.outerRef.current.offsetWidth / 2)
              }
            >
              <S.ArrowBack name="Back" />
            </S.ArrowBackWrapper>
          ) : null}
          <S.RowWraper className="innerRow" ref={this.innerRef}>
            {children}
          </S.RowWraper>
          {showArrowForward ? (
            <S.ArrowForwardWrapper
              onClick={() =>
                (this.outerRef.current.scrollLeft =
                  this.outerRef.current.scrollLeft + this.outerRef.current.offsetWidth / 2)
              }
            >
              <S.ArrowForward name="Right" />
            </S.ArrowForwardWrapper>
          ) : null}
        </S.RowGroup>
      </S.RowWrapper>
    );
  }

  private onScroll = () => {
    if (this.innerRef.current.offsetWidth > this.outerRef.current.offsetWidth) {
      this.setState({
        showArrowBack: !!this.outerRef.current.scrollLeft,
        showArrowForward:
          this.outerRef.current.scrollLeft < this.innerRef.current.offsetWidth - this.outerRef.current.offsetWidth,
      });
    }
  };
}

export default RowList;
