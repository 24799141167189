/**
 * Write Object.values for browsers that doesn't support it
 */
// @ts-ignore
const objectToValuesPolyfill = object => {
  return Object.keys(object).map(key => object[key]);
};
Object.values = Object.values || objectToValuesPolyfill;

// EDGE Polyfill https://stackoverflow.com/questions/47475647/canvas-to-blob-on-edge
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      setTimeout(() => {
        const binStr = atob(this.toDataURL(type, quality).split(',')[1]);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    },
  });
}
window.global ||= window;
