import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, Input, RowContainer, TextLabel } from '../../atoms';
import SI from '../ShareInvite/ShareInvite.component';

interface IStyledProps {
  from?: 'onboarding' | 'invite-contacts';
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  justify-content: space-between;
`;

export const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
`;

export const TextLink = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: left;
  width: max-content;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CTASkip = styled(Button)``;

export const FormContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  width: auto;
`;

export const InputRow = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  width: 100%;
`;

export const InputEmail = styled(Input)`
  width: auto;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex: 2;
`;

export const InputName = styled(Input)`
  min-width: 165px;
`;

export const OptionsRow = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AddMoreButton = styled(Button)`
  margin: 0;
  padding: 0;
  min-width: 50px;
`;

export const TextPurpleLink = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  text-align: left;
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CTARow = styled(RowContainer)`
  width: auto;
  align-items: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(16)};
`;

export const PhoneInputContainer = styled(RowContainer)`
  align-items: center;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const ShareInvite = styled(SI)<IStyledProps>`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(12)};
  ${(p: IStyledProps) =>
    p.from === 'invite-contacts' &&
    css`
      align-self: center;
    `}
`;

export const Buttons = styled(RowContainer)`
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const CheckIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: 15px;
  height: 15px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  padding-top: 4px;
  line-height: 15px;
`;

export const EmptySpace = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const FooterContainer = styled(ColumnContainer)``;

export const DividerContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
`;

export const DividerBar = styled(RowContainer)`
  height: 1px;
  width: 45%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextGrey = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const CTA = styled(Button)`
  flex: 0.45;
  width: auto;
`;
