import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';

interface IStyledProps {
  color?: string;
  isSelected?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)<IStyledProps>``;

export const DotTouchable = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(0.75)};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Dot = styled(RowContainer)<IStyledProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.color || p.theme.colors.blue1 : p.theme.colors.grey3)};
`;
