import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  display?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  maxWidth?: string;
  minWidth?: string;
  padding?: string;
  textAlign?: string;
  textTransform?: string;
  theme: ITheme;
  width?: string;
}

const StyledTextLabel = styled.span<IStyledProps>`
  display: ${(p: IStyledProps) => p.display || 'block'};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.fontSize || p.theme.fontSize.normal};
  color: inherit;
  text-align: ${(p: IStyledProps) => p.textAlign || 'left'};
  text-transform: ${(p: IStyledProps) => p.textTransform || 'none'};
  font-weight: ${(p: IStyledProps) => p.fontWeight || p.theme.fontWeight.Regular};
  margin: ${(p: IStyledProps) => p.margin || '0'};
  padding: ${(p: IStyledProps) => p.padding || '0'};
  width: ${(p: IStyledProps) => p.width || 'auto'};
  ${(p: IStyledProps) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth};
    `}
  ${(p: IStyledProps) =>
    p.maxWidth &&
    css`
      max-width: ${p.maxWidth};
    `}
  ${(p: IStyledProps) =>
    p.lineHeight &&
    css`
      line-height: ${p.lineHeight};
    `}
`;

export default StyledTextLabel;
