import { Api, modalActions, orderActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import ContactOrders, { IDispatchProps, IStateProps, OwnProps } from '../components/organisms/ContactOrders';
import { IReduxState } from '../reducers';

const emptyObject = {};
function mapStateToProps(state: IReduxState, props: OwnProps): IStateProps {
  const {
    catalog: { catalogs },
    contact: { inConsentio },
    order: { facets, filters, hasMore, orders, unreads, searchId, totalResults },
    user: { user },
    workspace: { workspaces },
  } = state;
  const contactId = props.contact ? props.contact.id : 0;
  const orderFiltersState = filters || emptyObject;
  return {
    catalogs,
    contacts: inConsentio,
    facets,
    filters: (contactId && !props.match?.params?.hashId && orderFiltersState[contactId]) || emptyObject,
    hasMore,
    me: user,
    orders,
    orderUnreads: unreads,
    searchId,
    totalResults,
    workspaces,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      navigateChannelBySection,
      orderFiltersSet: orderActions.orderFiltersSet,
      orderGetByHash: orderActions.orderGetByHash,
      ordersAllMarkAsRead: orderActions.ordersAllMarkAsRead,
      orderScrollSearch: orderActions.orderScrollSearch,
      orderSearch: orderActions.orderSearch,
      orderToggleReadStatus: orderActions.toggleOrderReadStatus,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactOrders);
