import * as React from 'react';

import { ColumnContainer, RowContainer } from '../FlexContainer';
import RadioInput from '../RadioInput';
import * as S from './Radio.styled';

interface IOption {
  label: string;
  value: string;
  tooltip?: string;
  subtitle?: string;
}

export interface IProps {
  name: string;
  onChange: (name: string, value: string, error?: string | boolean) => void;
  containerMargin?: string;
  options: Array<IOption>;
  itemsChecked: Array<any>;
  itemsEnabled?: Array<string>;
  disabled?: boolean;
  direction?: 'column' | 'row';
  alignItems?: string;
  margin?: string;
  style?: React.CSSProperties;
  styleLabel?: React.CSSProperties;
  tooltipType?: 'none' | 'text' | 'image';
}

const Radio: React.FC<IProps> = React.memo(
  ({
    name = '',
    onChange = (value: string) => null,
    containerMargin = '0',
    options = [],
    itemsChecked = [],
    itemsEnabled,
    disabled = false,
    direction = 'row',
    alignItems = 'center',
    margin = '0',
    style = {},
    styleLabel = {},
    tooltipType = 'none',
  }) => {
    const Label = direction === 'column' ? ColumnContainer : RowContainer;

    return (
      <Label width="100%" justifyContent="space-around" alignItems={alignItems} margin={containerMargin} style={style}>
        {options.map((option, index) => {
          return renderRadioButton(index, option);
        })}
      </Label>
    );

    function renderRadioButton(index: number, option: IOption) {
      const isChecked = itemsChecked.includes(option.value);
      const isItemEnabled = itemsEnabled ? itemsEnabled.includes(option.value) : true;
      const isEnabled = isItemEnabled && !disabled;

      return (
        <S.RadioLabel
          key={index}
          margin={margin}
          disabled={!isEnabled}
          style={styleLabel}
          onClick={() => isEnabled && onChange(name, option.value, false)}
        >
          <S.RadioInputContainer>
            <RadioInput checked={isChecked} value={option.value} disabled={!isEnabled} />
            <S.Text disabled={!isEnabled}>{option.label}</S.Text>
            {option.tooltip && tooltipType !== 'none' ? (
              <S.TooltipIconWrapper onClick={e => e.stopPropagation()}>
                <S.Tooltip
                  position={'bottom-right'}
                  image={tooltipType === 'image' ? option.tooltip : undefined}
                  text={tooltipType === 'text' ? option.tooltip : undefined}
                  width="470px"
                  className="tooltip-importfile"
                  themeMode="dark"
                >
                  <S.InfoIcon name="Info" disableHover={true} />
                </S.Tooltip>
              </S.TooltipIconWrapper>
            ) : null}
          </S.RadioInputContainer>
          {option.subtitle ? <S.Subtitle>{option.subtitle}</S.Subtitle> : null}
        </S.RadioLabel>
      );
    }
  },
);

export default Radio;
