import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  clickable?: boolean;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  margin-bottom: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  cursor: ${(p: IStyledProps) => (p.clickable ? 'pointer' : 'not-allowed')};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 85px;
  width: 300px;
  // Fix to refresh element in iPad
  transform: translate3d(0, 0, 0);
  position: relative;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  overflow: hidden;

  &:hover {
    box-shadow: ${(p: IStyledProps) => p.theme.selectedBoxShadow};
  }
`;

export const Left = styled.div`
  height: 100%;
  width: 101px;
  display: flex;
`;

export const Favorite = styled(Left)`
  background-color: ${(p: IStyledProps) => (p.clickable ? p.theme.colors.secondary : 'rgba(250,227,180,1)')};
`;

export const Inner = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) =>
    p.clickable ? p.theme.colors.black.replace('1)', '0.3)') : p.theme.colors.black.replace('1)', '0.1)')};
  width: 61px;
  height: 61px;
  margin: auto;
  display: flex;
`;

export const Icon = styled.span`
  margin: auto;
  color: white;
  font-size: 45px;
`;

export const Recommended = styled(Left)`
  background-color: ${(p: IStyledProps) => (p.clickable ? p.theme.colors.green1 : p.theme.colors.green2)};
`;

export const Recent = styled(Left)`
  background-color: ${(p: IStyledProps) => (p.clickable ? p.theme.colors.primary : p.theme.colors.primary2)};
`;

export const Column = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingObject} ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const Right = styled(Column)`
  height: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 259px;
`;

export const Type = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: center;
`;

export const Zero = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: center;
`;
export const TextBold = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;
export const Results = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: center;
`;
