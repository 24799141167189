import { sellerWorkspaceSelectors, userSelectors, __ } from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import Workspace from '../Workspace/Workspace.component';
import getPath from '../../../util/routes';
import MappingCodeList from '../../../screens/mapping-code-list';
import { useSelector } from 'react-redux';
import { api } from '../../../store';

export type IProps = RouteComponentProps<{ workspaceId: string; clientId?: string }>;

const WorkspaceClientReference: React.FC<IProps> = (props: IProps) => {
  const {
    history,
    location,
    match,
    match: {
      params: { clientId, workspaceId },
    },
  } = props;
  const [clientName, setClientName] = React.useState('');
  const clients = useSelector(sellerWorkspaceSelectors.getClients(Number(workspaceId)));
  const me = useSelector(userSelectors.getUser);

  React.useEffect(() => {
    if (clients && clientId) {
      api.sellerWorkspace.clientIdsGet(me.id, Number(workspaceId), [Number(clientId)]).then(c => {
        if (c.clients.length) setClientName(c.clients[0]?.name);
      });
    }
  }, [clients, clientId, workspaceId]);

  return (
    <Workspace
      parentSections={[
        {
          label: clientId ? __('Components.Header.WorkspaceClients') : __('Components.Header.Sales'),
          action: () =>
            history.push(
              getPath({
                path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENTS : ROUTE_PATHS.WORKSPACE_SALES,
                workspaceId,
              }),
            ),
        },
        {
          label: clientId ? clientName : __('Components.OrdersList.magic_orders'),
          action: () =>
            history.push(
              getPath({
                path: clientId ? ROUTE_PATHS.WORKSPACE_CLIENT_EDIT : ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                workspaceId,
                clientId: clientId + '',
              }),
            ),
        },
      ]}
      subtitle=""
      title={__('WorkspaceClientEdit.reference_list.title')}
      tabSelected={clientId ? 'clients' : 'sale'}
      workspaceId={Number(workspaceId)}
    >
      <MappingCodeList {...props} />
    </Workspace>
  );
};
export default React.memo(WorkspaceClientReference);
