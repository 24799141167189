import { __, IModalExtraInfo } from 'common-services';
import * as React from 'react';

import { ColumnContainer, Modal, RowContainer, TextLabel } from '../../atoms';
import * as S from './InputModal.styled';

interface IProps {
  text: string;
  acceptAction?: (text: string) => void;
  cancelAction?: () => void;
  acceptButtonText?: string;
  cancelButtonText?: string;
  closeModalAction?: () => void;
  showCancelAction?: boolean;
  extra?: IModalExtraInfo;
}

const InputModal: React.FC<IProps> = ({
  text = '',
  acceptAction = () => null,
  cancelAction = () => null,
  acceptButtonText,
  cancelButtonText,
  closeModalAction = () => null,
  showCancelAction = true,
  extra,
}) => {
  const [defaultText, setDefaultText] = React.useState((extra && extra.text2) || '');
  return (
    <Modal toShow={true} onCloseClick={closeModalAction}>
      <RowContainer flex="1" justifyContent="center" alignItems="center">
        <ColumnContainer flex="1">
          <RowContainer>
            <TextLabel margin="0 0 20px 0" textAlign="left" fontWeight="600" width="97%">
              {text}
            </TextLabel>
          </RowContainer>
          <S.TextArea
            id="chat-modal-input-message"
            placeholder={__('Components.Chat.placeholder')}
            value={defaultText}
            onChange={e => setDefaultText(e.target.value)}
          />
          <RowContainer width="100%" justifyContent="flex-end" alignItems="center" margin="5px 0 0 0">
            {showCancelAction ? (
              <S.CancelButton onClick={cancelAction}>
                {cancelButtonText || __('Components.Modal.cancel')}
              </S.CancelButton>
            ) : null}
            <S.AcceptButton onClick={() => acceptAction(defaultText)}>
              {acceptButtonText || __('Components.Modal.accept')}
            </S.AcceptButton>
          </RowContainer>
        </ColumnContainer>
      </RowContainer>
    </Modal>
  );
};

export default React.memo(InputModal);
