import { __, colors, currency as currencyServices, CURRENCY_CODES, IContact, ITopUsersResponse } from 'common-services';
import * as React from 'react';

import { LettersAvatar } from '../../../atoms';
import * as SW from '../Widgets.styled';
import * as S from './TopUsersWidget.styled';

export interface IProps {
  className?: string;
  contacts: { [cId: number]: IContact };
  currency: CURRENCY_CODES;
  amSeller: boolean;
  data: ITopUsersResponse;
  limit: number;
  width?: string;
}

const TopUsersWidget: React.FC<IProps> = ({ amSeller, className, contacts, currency, data, limit, width }) => {
  const { users } = data;
  const backgroundColors = [colors.blue1, colors.secondary, colors.green1];
  return (
    <SW.Container width={width} className={className}>
      <S.HeaderContainer>
        <SW.TextTitle>
          {amSeller ? __('Widgets.TopUsers.top_clients') : __('Widgets.TopUsers.top_suppliers')}
        </SW.TextTitle>
      </S.HeaderContainer>
      <S.UsersContainer>
        {users
          .slice(0, limit)
          .sort((u1, u2) => u2.gmv - u1.gmv)
          .map((user, idx) => {
            const contact = contacts[user.userId];
            const userName = user.name || (contact && contact.name);
            const companyName = user.companyName || (contact && contact.companyName);
            return (
              <S.UserRow key={idx}>
                <S.UserPictureNameRow>
                  {user.imageUrl ? (
                    <S.UserImage src={user.imageUrl} />
                  ) : (
                    <LettersAvatar
                      text={userName || 'U'}
                      size={34}
                      img=""
                      avatarColor={{ text: 'white', background: backgroundColors[idx] }}
                    />
                  )}
                  <S.UserInfoCol>
                    {companyName ? <S.TextCompany>{companyName}</S.TextCompany> : null}
                    {userName ? <S.TextName>{userName}</S.TextName> : null}
                  </S.UserInfoCol>
                </S.UserPictureNameRow>
                {user.gmv ? <S.TextGMV>{currencyServices.getPriceAbbreviation(currency, user.gmv)}</S.TextGMV> : null}
              </S.UserRow>
            );
          })}
      </S.UsersContainer>
    </SW.Container>
  );
};

export default React.memo(TopUsersWidget);
