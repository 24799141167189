import { __ } from 'common-services';
import * as React from 'react';
import * as S from './WorkspaceReferenceList.styled';
import ReferenceListTable from '../../molecules/ReferenceListTable';
import { EmptyReferenceList } from './EmptyReferenceList';

interface ReferenceListProps {
  associateReferenceList: Array<IGroupedDataReferenceList>;
  setShowClientsModal: React.Dispatch<React.SetStateAction<boolean>>;
  onLoadMore?: () => void;
}

const ReferenceList: React.FC<ReferenceListProps> = ({ 
  associateReferenceList, 
  setShowClientsModal,
  onLoadMore
}) => {
  
  return (
    <S.Row>
      <S.WsWrapper>
        <S.HeaderDashboards />
        {associateReferenceList?.length === 0 ? (
          EmptyReferenceList(setShowClientsModal)
        ) : (
          <ReferenceListTable data={associateReferenceList || []} onLoadMore={onLoadMore}/>
        )}
      </S.WsWrapper>
    </S.Row>
  );
};

export default ReferenceList;
