import {
  __,
  constants,
  countrySelectors,
  date,
  modalActions,
  parsers,
  prodTypeSelectors,
  productActions,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import config from '../../../../../bindings/config';
import { AVAILABILITY } from '../../../../constants';
import ProductListShare from '../../../../screens/product-list-share';
import { logError } from '../../../../services/log';
import theme from '../../../../theme';
import { FontIcon, ImageCover } from '../../../atoms';
import Attachment from '../../../molecules/Attachment';
import Sustainability from '../../ProductEdit/Sustainability';
import * as S from '../ProductInfo.styled';
import { AddToCart } from './AddToCart/AddToCart.component';

interface IProps {
  address?: IAddress;
  catalogId: number;
  contact?: IContact;
  contactId: number;
  contactName: string;
  contacts?: { [id: number]: IContact };
  deletable?: boolean;
  featuredToggle?: (type: IFeaturedType, productHashId: string) => void;
  forceSetPrice?: boolean;
  from: 'order' | 'product' | 'pricelist';
  history: { push: (path: string) => void };
  amSeller: boolean;
  isContactUnregistered: boolean;
  isFavorite?: boolean;
  isRecommended?: boolean;
  isRecent?: boolean;
  isServedFlow?: boolean;
  item?: IOrderItem;
  priceMode?: IPriceMode;
  pricePrecision: number;
  product: GenericProduct;
  showTraceability?: () => void;
  disabled?: boolean;
  sendMessage?: (text: string) => void;
  showFeatured: boolean;
  showDefaultPicture: boolean;
  showShare: boolean;
  isPublic?: boolean;
  typeVariety: string;
  cartUpdateItem: (item: IOrderItem, stay?: boolean) => void;
}
/**
 * Render the left column with all infromation about the product
 */
export const ProductInfoContent = ({
  address,
  amSeller,
  cartUpdateItem,
  catalogId,
  contact,
  contactId,
  contactName,
  contacts,
  deletable,
  disabled,
  featuredToggle,
  from,
  history,
  isContactUnregistered,
  isFavorite,
  isPublic,
  isRecent,
  isRecommended,
  isServedFlow,
  item,
  priceMode,
  pricePrecision,
  product,
  sendMessage,
  showDefaultPicture,
  showFeatured,
  showShare,
  showTraceability,
  typeVariety,
}: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const countries = useSelector(countrySelectors.getCountries);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const me = useSelector(userSelectors.getUser);
  const [showShareModal, setshowShareModal] = React.useState(false);
  const seller = contacts && contacts[contactId];
  return (
    <>
      <S.LeftContainer isPublic={isPublic} className="product-left-container">
        <S.LeftWrapper isPublic={isPublic}>
          <S.ContainerWrapper>
            <S.ProductRow>
              <S.NewColumn showInIpad={true}>
                <S.TitleLabel>
                  {product.productTitle ||
                    typeVariety + (!product.promoterConfiguration?.id ? ' ' + product.size : null)}
                </S.TitleLabel>
                <S.CompanyText>{seller?.companyName || contact?.companyName}</S.CompanyText>
                <ShareButton onShareProduct={() => setshowShareModal(true)} />
                <Images
                  contactName={contactName}
                  isContactUnregistered={isContactUnregistered}
                  from={from}
                  product={product}
                  amSeller={amSeller}
                  prodTypes={prodTypes}
                  showDefaultPicture={showDefaultPicture}
                  typeVariety={typeVariety}
                  showInIpad={true}
                  sendMessage={sendMessage}
                />
              </S.NewColumn>
              <Images
                contactName={contactName}
                isContactUnregistered={isContactUnregistered}
                from={from}
                product={product}
                amSeller={amSeller}
                prodTypes={prodTypes}
                showDefaultPicture={showDefaultPicture}
                typeVariety={typeVariety}
                showInIpad={false}
                sendMessage={sendMessage}
              />
              <S.InfoColumn showInIpad={false}>
                <S.TitleLabel>
                  {product.productTitle ||
                    typeVariety + (!product.promoterConfiguration?.id ? ' ' + product.size : null)}
                </S.TitleLabel>
                <S.CompanyText>{seller?.companyName || contact?.companyName}</S.CompanyText>
                {amSeller && showShare && product.productHashId ? (
                  <ShareButton onShareProduct={() => setshowShareModal(true)} />
                ) : null}
                {amSeller && !item ? (
                  <>
                    <S.StatusText status={product.status}>
                      {product.status === 'active'
                        ? __('Components.ProductDetails.available')
                        : product.status === AVAILABILITY.UNAVAILABLE
                        ? __('Components.ProductDetails.unavailable')
                        : __('Components.ProductDetails.hidden')}
                    </S.StatusText>
                    {product.status === AVAILABILITY.UNAVAILABLE && product.availableSoon?.availableFrom ? (
                      <S.TextGray>
                        {__('Components.ProductCard.estimated_date', {
                          date: date.formatLongDate(
                            product.availableSoon.availableFrom,
                            undefined,
                            undefined,
                            'EEE, dd MMM yyyy',
                          ),
                        })}
                      </S.TextGray>
                    ) : null}
                  </>
                ) : null}
                <S.InfoTable showInIpad={false}>
                  {product.productTitle ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.name') + ':'}</S.Text>
                      <S.BoldText>{product.productTitle}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.type && prodTypes[product.type] ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.type') + ':'}</S.Text>
                      <S.BoldText>{prodTypes[product.type].name}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.variety ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.variety') + ':'}</S.Text>
                      <S.BoldText>{product.variety}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.origin ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.origin') + ':'}</S.Text>
                      <S.BoldText>{getOriginText(product.origin, countries)}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.category && !product.promoterConfiguration?.id ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.category') + ':'}</S.Text>
                      <S.BoldText>
                        {parsers.getCategoryText(product.category as (typeof constants.PRODUCT_CATEGORIES)[number])}
                      </S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.size && !product.promoterConfiguration?.id ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.size') + ':'}</S.Text>
                      <S.BoldText>{product.size}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.organic && !product.promoterConfiguration?.id ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.organic') + ':'}</S.Text>
                      <S.BoldText>{parsers.getOrganicText(product.organic)}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.brand ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.product.brand') + ':'}</S.Text>
                      <S.BoldText>{product.brand}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                  {product.packaging && product.promoterConfiguration?.id ? (
                    <S.InfoText>
                      <S.Text>{__('Components.ProductDetails.distribution.packaging') + ':'}</S.Text>
                      <S.BoldText>{product.packaging}</S.BoldText>
                    </S.InfoText>
                  ) : null}
                </S.InfoTable>
                {contactId ? (
                  <Featured
                    showFeatured={showFeatured}
                    isFavorite={isFavorite}
                    isRecommended={isRecommended}
                    featuredToggle={featuredToggle}
                    amSeller={amSeller}
                    product={product}
                  />
                ) : null}
              </S.InfoColumn>
              {item ? (
                <AddToCart
                  address={address}
                  isPublic={isPublic}
                  isServedFlow={isServedFlow}
                  disabled={disabled}
                  cartUpdateItem={cartUpdateItem}
                  deletable={deletable}
                  forceSetPrice={!amSeller && isContactUnregistered}
                  from={from}
                  amSeller={amSeller}
                  item={item}
                  priceMode={!amSeller && isContactUnregistered ? 'edit' : priceMode}
                  pricePrecision={pricePrecision}
                  product={product}
                  showInIpad={true}
                  typeVariety={typeVariety}
                />
              ) : null}
            </S.ProductRow>
            <S.FavoritesRow showInIpad={true}>
              {contactId ? (
                <Featured
                  showFeatured={showFeatured}
                  isFavorite={isFavorite}
                  isRecommended={isRecommended}
                  featuredToggle={featuredToggle}
                  amSeller={amSeller}
                  product={product}
                />
              ) : null}
            </S.FavoritesRow>
            <S.Tags>
              {product.organic === 'organic' ? <S.Organic>{parsers.getOrganicText(product.organic)}</S.Organic> : null}
              {isRecommended ? (
                <S.StatusTag
                  label={
                    window.innerWidth <= Number(theme.sizes.ipad)
                      ? __('Components.ProductDetails.tag.recommended_mobile')
                      : __('Components.ProductDetails.tag.recommended')
                  }
                  color={'accepted'}
                  disabled={true}
                />
              ) : null}
              {isRecent ? (
                <S.StatusTag label={__('Components.ProductDetails.tag.recent')} color={'pending'} disabled={true} />
              ) : null}
            </S.Tags>
            <S.InfoTitle>{__('Components.ProductDetails.information')}</S.InfoTitle>

            <S.InfoTable showInIpad={true}>
              {product.productTitle ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.name') + ':'}</S.Text>
                  <S.BoldText>{product.productTitle}</S.BoldText>
                </S.InfoText>
              ) : null}
              {product.variety ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.variety') + ':'}</S.Text>
                  <S.BoldText>{product.variety}</S.BoldText>
                </S.InfoText>
              ) : null}
              {product.origin ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.origin') + ':'}</S.Text>
                  <S.BoldText>{getOriginText(product.origin, countries)}</S.BoldText>
                </S.InfoText>
              ) : null}
              {product.category ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.category') + ':'}</S.Text>
                  <S.BoldText>
                    {parsers.getCategoryText(product.category as (typeof constants.PRODUCT_CATEGORIES)[number])}
                  </S.BoldText>
                </S.InfoText>
              ) : null}
              {product.size ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.size') + ':'}</S.Text>
                  <S.BoldText>{product.size}</S.BoldText>
                </S.InfoText>
              ) : null}
              {product.organic ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.organic') + ':'}</S.Text>
                  <S.BoldText>{parsers.getOrganicText(product.organic)}</S.BoldText>
                </S.InfoText>
              ) : null}
              {product.brand ? (
                <S.InfoText>
                  <S.Text>{__('Components.ProductDetails.product.brand') + ':'}</S.Text>
                  <S.BoldText>{product.brand}</S.BoldText>
                </S.InfoText>
              ) : null}
            </S.InfoTable>

            {!product.promoterConfiguration?.id ? (
              <Characteristics
                product={product}
                catalogId={catalogId}
                amSeller={amSeller}
                me={me}
                countries={countries}
              />
            ) : null}

            {product.moreInfo ? (
              <S.MoreInfo>
                {product.moreInfo.split(/(?:\r\n|\r|\n)/).map((t, i) => (
                  <S.MoreInfoP key={i}>
                    {utils.splitTextUrls(t).map(s =>
                      s.isLink ? (
                        <S.MoreInfoA href={s.text} key={i + s.text} target="_blank">
                          {s.text}
                        </S.MoreInfoA>
                      ) : (
                        <S.MoreInfoSpan key={i + s.text}>{s.text}</S.MoreInfoSpan>
                      ),
                    )}
                  </S.MoreInfoP>
                ))}
              </S.MoreInfo>
            ) : null}
            {me.id ? (
              <S.RequestInfo>
                <S.MoreInfoIcon>
                  <FontIcon name="Info" className="request-more-info" />
                </S.MoreInfoIcon>
                <S.TextRequestInfo>{__('Components.ProductDetails.request_more_info_text')}</S.TextRequestInfo>
                <S.LinkButton
                  type="link"
                  withoutPadding={true}
                  onClick={() =>
                    dispatch(
                      modalActions.modalOpen(
                        __('Components.ProductDetails.ask_title_info', { name: contactName }),
                        (text: string) => {
                          sendMessage(text);
                          dispatch(modalActions.modalClose());
                        },
                        {
                          text2: __('Components.ProductDetails.ask_message_info', {
                            productName: typeVariety + ' ' + product.size,
                            name: contactName,
                          }),
                          buttonText: __('Components.ProductDetails.send_message'),
                        },
                        'message' as IModalName,
                      ),
                    )
                  }
                >
                  {__('Components.ProductDetails.request_more_info')}
                </S.LinkButton>
              </S.RequestInfo>
            ) : null}
          </S.ContainerWrapper>

          {product.attachments.length > 0 ? <AdditionalFilesComponent product={product} contact={contact} /> : null}
          {config.TOGGLE_SUSTAINABILITY.enabled && !product.promoterConfiguration?.id ? (
            <SustainabilityComponent product={product} prodTypes={prodTypes} me={me} />
          ) : null}
          {config.TOGGLE_PRODUCT_LOT.enabled ? (
            <S.ContainerWrapper>
              <Traceability showTraceability={showTraceability} />
            </S.ContainerWrapper>
          ) : null}
        </S.LeftWrapper>
      </S.LeftContainer>
      {showShareModal ? (
        <S.Modal>
          <ProductListShare
            amSeller={amSeller}
            close={() => setshowShareModal(false)}
            ownerId={amSeller ? me.id : contactId}
            catalogId={catalogId}
            product={product}
            productHashId={product.productHashId}
            productName={`${typeVariety} ${product.size}`}
            contactId={contactId}
            history={history}
          />
        </S.Modal>
      ) : null}
    </>
  );
};

const AdditionalFilesComponent = ({ product, contact }: { product: GenericProduct; contact: IContact }) => {
  const me = useSelector(userSelectors.getUser);
  const dispatch = useDispatch<Dispatch<any>>();
  return (
    <S.ContainerWrapper>
      <S.InfoTitle>{__('ProductEdit.additionalFiles.title')}</S.InfoTitle>
      <S.Row>
        {contact ? (
          <S.ImgColumn>
            <S.Image src={contact?.companyLogo} />
          </S.ImgColumn>
        ) : null}
        <S.Column>
          {contact ? (
            product.attachments.length > 1 ? (
              <S.Subtitle>
                {__('ProductEdit.additionalFiles.files.several', {
                  seller: contact?.companyName,
                  count: product.attachments.length,
                })}
              </S.Subtitle>
            ) : (
              <S.Subtitle>{__('ProductEdit.additionalFiles.files.one', { seller: contact.companyName })}</S.Subtitle>
            )
          ) : null}

          {product.attachments?.map((att, idx) => {
            return (
              <Attachment
                key={idx}
                attachment={att}
                download={download => {
                  try {
                    dispatch(productActions.getProductFile(me.id, product?.productId, att.id, download));
                  } catch (err) {
                    logError(err, 'Error, unable to download attachment');
                  }
                }}
              />
            );
          })}
        </S.Column>
      </S.Row>
    </S.ContainerWrapper>
  );
};
const SustainabilityComponent = ({
  product,
  prodTypes,
  me,
}: {
  product: GenericProduct;
  prodTypes: Record<string, IProdType>;
  me: IUser;
}) => {
  const sustainability = Sustainability[product.type];
  const co2Max = sustainability?.co2Max?.toFixed(2);
  const co2Min = sustainability?.co2Min?.toFixed(2);
  const waterMax = (sustainability?.waterMax * 1000)?.toFixed(2);
  const waterMin = (sustainability?.waterMin * 1000)?.toFixed(2);
  const name = prodTypes[product.type]?.name;
  return sustainability || product.plasticType ? (
    <S.ContainerWrapper>
      <S.InfoTitle>{__('ProductEdit.Sustainability.title')}</S.InfoTitle>
      <S.GreenSpan>
        <FontIcon name="Producer-solid" disableHover={true} />
        <S.GreenSubtitle>{__('ProductEdit.Sustainability.green_subtitle')}</S.GreenSubtitle>
      </S.GreenSpan>
      {sustainability ? (
        <>
          {co2Max
            ? renderSustainabilityItem(
                '🌤',
                'sun',
                __('ProductEdit.Sustainability.co2.title'),
                co2Max === co2Min
                  ? __('ProductEdit.Sustainability.co2.similar_data', { product: name, co2Max })
                  : __('ProductEdit.Sustainability.co2.data', { product: name, co2Max, co2Min }),
                '(1)',
              )
            : null}
          {waterMax
            ? renderSustainabilityItem(
                '💧',
                'water',
                __('ProductEdit.Sustainability.water.title'),
                waterMax === waterMin
                  ? __('ProductEdit.Sustainability.water.similar_data', { product: name, waterMax })
                  : __('ProductEdit.Sustainability.water.data', { product: name, waterMax, waterMin }),
                '(1)',
              )
            : null}
        </>
      ) : (
        <>
          {renderSustainabilityItem(
            '🌤',
            'sun',
            __('ProductEdit.Sustainability.co2.title'),
            __('ProductEdit.Sustainability.no_data'),
            undefined,
          )}
          {renderSustainabilityItem(
            '💧',
            'water',
            __('ProductEdit.Sustainability.water.title'),
            __('ProductEdit.Sustainability.no_data'),
            undefined,
          )}
        </>
      )}
      {product.plasticType
        ? renderSustainabilityItem(
            '🥤',
            'plastic',
            __('ProductEdit.Sustainability.plastic.title'),
            __('ProductEdit.Sustainability.plastic.data', {
              plastic_type: constants.getPlasticLiteral(product.plasticType),
              plastic_unit: constants.getPriceUnitLabels(product.plasticUnit, me.settings.language).toLocaleLowerCase(),
              plastic_amount: product.plasticAmount,
            }),
            undefined,
          )
        : null}
      <S.SubRowInfo>
        <S.SupInfo>{'(1)'}</S.SupInfo>
        <S.Text>
          {
            utils.formatText(__('ProductEdit.Sustainability.data_origin'), (s, idx) =>
              idx === 1 ? (
                <S.ExtLink key={idx} target="_blank" href="https://doc.agribalyse.fr/documentation/methodologie-acv">
                  {s}
                </S.ExtLink>
              ) : (
                <S.ExtLink key={idx} target="_blank" href="https://www.iso.org/standard/38498.html">
                  {s}
                </S.ExtLink>
              ),
            ) as any // TYPEERROR
          }
        </S.Text>
      </S.SubRowInfo>
    </S.ContainerWrapper>
  ) : null;
};

const Traceability = ({ showTraceability }: { showTraceability?: () => void }) =>
  showTraceability ? (
    <>
      <S.InfoTitle>{__('ProductEdit.Traceability.title')}</S.InfoTitle>
      <S.Sustainabilitytext>{__('ProductEdit.Traceability.subtitle')}</S.Sustainabilitytext>
      <S.ButtonTraceability type="other" onClick={showTraceability}>
        {__('ProductEdit.Traceability.cta')}
      </S.ButtonTraceability>
    </>
  ) : null;

const renderSustainabilityItem = (icon: string, label: string, title: string, subtitle: string, sup?: string) => {
  return (
    <>
      <S.Sustainabilitytitle>
        <span role="img" aria-label={label}>
          {icon}
        </span>{' '}
        {title}
      </S.Sustainabilitytitle>
      <S.SubRow withoutPaddingBottom={true}>
        <S.Sustainabilitytext>
          <>
            {subtitle ? utils.formatText(subtitle, (s, idx) => <S.TextBold key={idx}>{s}</S.TextBold>) : null}
            {sup ? <S.Sup>{sup}</S.Sup> : null}
          </>
        </S.Sustainabilitytext>
      </S.SubRow>
    </>
  );
};
const ShareButton = ({ onShareProduct }: { onShareProduct: () => void }) => {
  return (
    <S.ShareTouchable id="product-info-share-product" onClick={onShareProduct}>
      <S.ShareIcon name="Share" />
      <S.TextShare>{__('Components.ProductDetails.share')}</S.TextShare>
    </S.ShareTouchable>
  );
};
/**
 * render The main image and the small images
 */
const Images = ({
  amSeller,
  contactName,
  from,
  isContactUnregistered,
  prodTypes,
  product,
  sendMessage,
  showDefaultPicture,
  showInIpad,
  typeVariety,
}: {
  amSeller: boolean;
  contactName: string;
  from: 'order' | 'product' | 'pricelist';
  isContactUnregistered: boolean;
  prodTypes: { [key: string]: IProdType };
  product: GenericProduct;
  sendMessage?: (text: string) => void;
  showDefaultPicture: boolean;
  showInIpad: boolean;
  typeVariety: string;
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [selectedImg, setSelectedImg] = React.useState(0);
  const productImages: Array<ISizeImage> = product.images ? product.images : [];
  const shouldAllowAsk = !amSeller && !isContactUnregistered && from !== 'pricelist';
  let mainImageUrl = productImages[selectedImg] ? productImages[selectedImg].image.url : undefined;
  if (!mainImageUrl && showDefaultPicture) {
    mainImageUrl = prodTypes[product.type] && prodTypes[product.type].defaultImageUrl;
  }
  if (mainImageUrl) {
    return (
      <S.Head showInIpad={showInIpad}>
        <ImageCover
          imageUrl={mainImageUrl.replace('f_auto', 'f_auto,c_limit,w_800')}
          relation={constants.IMAGE_RELATION.PRODUCT}
          relationFixed={true}
          borderRadius={'10px'}
          touchImage={() =>
            dispatch(
              modalActions.touchImage(
                productImages.map(im => ({ src: im.image.url, caption: im.image.comment })),
                selectedImg,
              ),
            )
          }
        />
        <S.SmallImages>
          {productImages.slice(0, 4).map((img, i) => (
            <S.SmallImage
              src={img.image ? img.image.url : ''}
              imgSelected={img.image?.url === mainImageUrl ? true : false}
              key={i}
              onClick={e => {
                e.stopPropagation();
                setSelectedImg(productImages.indexOf(img));
              }}
            />
          ))}
        </S.SmallImages>
      </S.Head>
    );
  }
  if (!shouldAllowAsk) return null;
  return (
    <S.NoPhoto>
      <S.Row>
        <S.FontIconWrapper>
          <FontIcon name="Gallery-solid" className="product-card-fav-icon" />
        </S.FontIconWrapper>
        <S.TextPhoto>{__('Components.ProductDetails.no_photo')}</S.TextPhoto>
      </S.Row>
      <S.ButtonPhoto
        onClick={() =>
          dispatch(
            modalActions.modalOpen(
              __('Components.ProductDetails.ask_title_photo', {
                name: contactName,
              }),
              (text: string) => {
                sendMessage(text);
                dispatch(modalActions.modalClose());
              },
              {
                text2: __('Components.ProductDetails.ask_message_photo', {
                  productName: typeVariety + ' ' + product.size,
                  name: contactName,
                }),
                buttonText: __('Components.ProductDetails.send_message'),
              },
              'message' as IModalName,
            ),
          )
        }
      >
        {__('Components.ProductDetails.request_photo')}
      </S.ButtonPhoto>
    </S.NoPhoto>
  );
};

const Characteristics = ({
  product,
  catalogId,
  amSeller,
  me,
  countries,
}: {
  product: GenericProduct;
  catalogId: number;
  amSeller: boolean;
  me: IUser;
  countries: Record<string, ICountry>;
}) => {
  let eanType: string;
  switch (product.eanType) {
    case 'PLU':
      eanType = 'PLU';
      break;
    case 'UPC':
      eanType = 'UPC';
      break;
    default:
      eanType = 'EAN (GTIN)';
  }
  const data: Array<{ value: string; literal: string; parser?: (value: string) => string }> = [
    {
      value: product.boxWeight ? product.boxWeight.toString() : '',
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.shelfWeight')
        : __('Components.ProductDetails.distribution.boxWeight'),
      parser: (w: string) => `${w} ${product.weightUnit}`,
    },
    {
      value: product.piecesPerBox ? product.piecesPerBox.toString() : '',
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.piecesPerShelf')
        : __('Components.ProductDetails.distribution.piecesPerBox'),
    },

    {
      value: product.boxesPerPallet ? product.boxesPerPallet.toString() : '',
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.shelvesPerCart')
        : __('Components.ProductDetails.distribution.boxesPerPallet'),
    },
    {
      value: product.box?.name,
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.shelfType')
        : __('Components.ProductDetails.distribution.boxType'),
    },
    {
      value: product.box?.tare.toString(),
      literal: __('WorkspaceSettings.Distribution.boxes.tare'),
    },
    {
      value: product.packaging,
      literal: __('Components.ProductDetails.distribution.packaging'),
    },
    {
      value: product.pallet?.name,
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.cartType')
        : __('Components.ProductDetails.distribution.palletType'),
    },
    { value: product.eanCode, literal: eanType },
    {
      value: product.palletHeight as unknown as string,
      literal: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
        ? __('Components.ProductDetails.distribution.cartHeight')
        : __('Components.ProductDetails.distribution.palletHeight'),
    },
    {
      value:
        amSeller && product.updatedAt
          ? date.formatDate(product.updatedAt, me.settings.dateFormat === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') +
            ' · ' +
            date.formatTime(product.updatedAt, me.settings.hourFormat)
          : null,
      literal: __('Components.ProductDetails.updated_at'),
    },
    ...(config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled
      ? [
          {
            value: product.productHashId,
            literal: __('Components.ProductDetails.product.unique_id'),
          },
        ]
      : []),
    ...(product.status === 'active'
      ? [
          {
            value: product.warehouse?.zip
              ? `${product.warehouse.zip}, ${product.warehouse.city}, ${
                  countries[product.warehouse.country]?.name || product.warehouse.country
                }`
              : '',
            literal: __('Components.ProductDetails.distribution.warehouse_info'),
          },
        ]
      : []),
  ];
  return (
    <S.Characteristics className="characteristics">
      {data.map((o, i) => (
        <Characteristic value={o.value} title={o.literal} key={i} parser={o.parser} />
      ))}
    </S.Characteristics>
  );
};
const Characteristic = ({
  value,
  title,
  parser,
}: {
  value: string;
  title: string;
  parser?: (value: string) => string;
}) => {
  if (!value || (parser && !parser(value))) return null;
  return (
    <S.TableSpan>
      <S.TableField>{title}</S.TableField>
      <S.TableValue>{parser ? parser(value) : value}</S.TableValue>
    </S.TableSpan>
  );
};
const getOriginText = (origin: string, countries: Record<string, ICountry>) => {
  return Object.values(countries).find(c => c.iso2Code === origin)?.name || '';
};

/**
 * Microinteraction for mark/umark featured (if showMarkFeatured is true)
 * - if seller -> recommended
 * - if buyer -> favorites
 */
const Featured = ({
  showFeatured,
  isFavorite,
  isRecommended,
  featuredToggle,
  amSeller,
  product,
}: {
  showFeatured: boolean;
  isFavorite: boolean;
  isRecommended: boolean;
  featuredToggle?: (type: IFeaturedType, productHashId: string) => void;
  amSeller: boolean;
  product: GenericProduct;
}) => {
  if (!showFeatured || !featuredToggle) {
    return null;
  }
  return (
    <>
      {!amSeller ? (
        <S.Featured
          isFeatured={isFavorite}
          featuredColor={theme.colors.orange1}
          className="productinfo-featured-nohover"
          onClick={e => {
            e.stopPropagation();
            featuredToggle('favorite', product.productHashId);
          }}
        >
          <S.FeaturedIcon
            isFeatured={isFavorite}
            featuredColor={theme.colors.orange1}
            className="productinfo-featured-nohover"
          >
            <FontIcon name="Favorit" disableHover={true} />
          </S.FeaturedIcon>

          <S.FeaturedText className="productinfo-featured-nohover">
            {!isFavorite ? __('Components.ProductCard.favorite_mark') : __('Components.ProductCard.favorite')}
          </S.FeaturedText>

          <S.FeaturedText className="productinfo-featured-hover">
            {!isFavorite ? __('Components.ProductCard.favorite_mark') : __('Components.ProductCard.favorite_unmark')}
          </S.FeaturedText>
        </S.Featured>
      ) : null}

      {amSeller ? (
        <S.Featured
          isFeatured={isRecommended}
          featuredColor={theme.colors.green1}
          onClick={e => {
            e.stopPropagation();
            featuredToggle('recommended', product.productHashId);
          }}
        >
          <S.FeaturedIcon featuredColor={theme.colors.green1} isFeatured={isRecommended}>
            <FontIcon name={'Recommended'} disableHover={true} />
          </S.FeaturedIcon>
          <S.FeaturedText
            featuredColor={theme.colors.black}
            isFeatured={isRecommended}
            className="productinfo-featured-nohover"
          >
            {!isRecommended ? __('Components.ProductCard.recommended_mark') : __('Components.ProductCard.recommended')}
          </S.FeaturedText>
          <S.FeaturedText
            featuredColor={theme.colors.black}
            isFeatured={isRecommended}
            className="productinfo-featured-hover"
          >
            {!isRecommended
              ? __('Components.ProductCard.recommended_mark')
              : __('Components.ProductCard.recommended_unmark')}
          </S.FeaturedText>
        </S.Featured>
      ) : null}
    </>
  );
};
