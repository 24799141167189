import {
  __,
  currency,
  date,
  DATE_FORMAT,
  HOUR_FORMAT,
  ORDER_STATUS,
  orderService,
  productService,
  SCHEDULE,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { priceUnitTranslate } from '../../../../../util/utils';
import * as S from './OrderMessage.styled';
import { useSelector } from 'react-redux';

export interface IProps {
  catalog?: IWorkspace;
  clients?: Array<IClient>;
  cloneOrder: (order: IOrder) => void;
  contactEmail: string;
  contactName: string;
  contactNameToDisplay: string;
  contacts: { [contactId: number]: IContact };
  dateFormat: DATE_FORMAT;
  hourFormat: HOUR_FORMAT;
  message?: IMessage;
  myId: number;
  onClick: (message: IMessage) => void;
  prodTypes: { [key: string]: IProdType };
  showContactName?: boolean;
}
const ITEMS_TO_SHOW = 2;

const OrderCard: React.FC<IProps> = ({
  contactName,
  hourFormat,
  myId,
  message,
  showContactName,
  cloneOrder,
  contacts,
  clients,
  catalog,
  onClick,
  message: {
    extraData: { deliverTo, deliveryEstimatedAt, deliveryEstimatedSchedule, items, pricePrecision },
  },
  prodTypes,
}) => {
  function renderClone() {
    if (message.extraData.sellerId === myId || message.extraData.buyerId === myId) {
      return (
        <S.Action>
          <S.CloneButton
            iconName="Refresh"
            type="secondary"
            onClick={e => {
              e.stopPropagation();
              cloneOrder(message.extraData);
            }}
          >
            {__('Components.OrderDetails.options.clone')}
          </S.CloneButton>
        </S.Action>
      );
    }
  }

  /**
   * Get order's price display
   */
  function getPriceDisplay(order: IOrder) {
    return order.price ? currency.getPrice(order.currency, order.price, order.pricePrecision) : '-';
  }

  /**
   * Parser for title and body to change **whatever** for a featured text.
   */
  const formatText = (text: string, renderHighlightText: (s: string) => void) => {
    return text.split('**').map((str, index) => (index % 2 ? renderHighlightText(str) : str));
  };

  function renderCounterpart() {
    const seller = contacts[message.extraData.sellerId];
    const sellerName = seller
      ? `${seller.name} (${seller.companyName})`
      : clients?.find(c => c.userId === message.extraData.sellerId)?.name;
    const buyer = contacts[message.extraData.buyerId];
    const buyerName = buyer
      ? `${buyer.name} ${buyer.companyName ? '(' + buyer.companyName + ')' : ''}`
      : clients?.find(c => c.userId === message.extraData.buyerId)?.name;

    return sellerName || buyerName ? (
      <S.Section>
        <S.SectionIcon name="Profile" />
        <S.SectionColumn>
          {buyerName ? (
            <S.OrderItemRow>
              <S.Role>{__('OrderDetail.role_client')}</S.Role>
              <S.Name>{buyerName}</S.Name>
            </S.OrderItemRow>
          ) : null}
          {sellerName ? (
            <S.OrderItemRow>
              <S.Role>{__('OrderDetail.role_supplier')}</S.Role>
              <S.Name>{sellerName}</S.Name>
            </S.OrderItemRow>
          ) : null}
        </S.SectionColumn>
      </S.Section>
    ) : null;
  }

  /**
   * render the name of the workspace if I am a seller and I haven't the catalog anymore
   */
  function renderWorkspace() {
    if (!amSeller || !catalog) return null;
    return (
      <S.Section>
        <S.SectionIcon name="Workspace" />
        <S.Name>
          {catalog?.name ||
            __('ProductsListScreen.nameTeam', {
              name: contactName,
            })}
        </S.Name>
      </S.Section>
    );
  }

  function renderTransport() {
    if (!deliverTo) return null;
    const estimatedDeliveryDate = deliveryEstimatedAt
      ? ' · ' + utils.capitalizeWords(date.formatLongDate(deliveryEstimatedAt, undefined, undefined, 'EEE, dd MMM'))
      : '';
    const estimatedDeliverySchedule =
      deliveryEstimatedAt && deliveryEstimatedSchedule && deliveryEstimatedSchedule !== SCHEDULE.UNKNOWN
        ? ' · ' + orderService.getScheduleLabel(deliveryEstimatedSchedule).toLocaleLowerCase()
        : '';

    return (
      <S.Section>
        <S.SectionIcon name="Truck" />
        <S.Name>
          {deliverTo.rest}
          {estimatedDeliveryDate}
          {estimatedDeliverySchedule}
        </S.Name>
      </S.Section>
    );
  }

  function renderProducts() {
    const itemsWithAmount = items
      .filter(i => i.amount || i.servedQuantity)
      .sort(orderService.sortOrderItems(prodTypes, catalog?.sortItemsBy));
    const itemsToShow =
      itemsWithAmount.length > ITEMS_TO_SHOW ? [...itemsWithAmount].slice(0, ITEMS_TO_SHOW) : itemsWithAmount;

    const moreItemsText =
      itemsWithAmount.length > ITEMS_TO_SHOW ? (
        <S.OrderItemRow>
          <S.TextMoreItems>{__('Messages.Order.andMore', { num: itemsWithAmount.length - 2 })}</S.TextMoreItems>
        </S.OrderItemRow>
      ) : null;
    return (
      <S.Section>
        <S.SectionIcon name="Order" />
        <S.SectionColumn>
          {itemsToShow.map((i: IOrderItem, idx: number) => {
            const typeVariety = productService.getProductTypeVarietyDisplay(
              i.type,
              prodTypes[i.type] ? prodTypes[i.type].name : '',
              i.title,
            );
            if (config.TOGGLE_NEW_SALES_UNITS.organizations.includes(i.catalogId)) {
              i.servedSaleUnit = priceUnitTranslate(i.servedSaleUnit);
            }
            return (
              <S.OrderItemRow key={i.title + '_' + idx}>
                <S.TextOrderItem>{`${orderService.getAmountText(i)} ${
                  i.productTitle || typeVariety + ' ' + i.size
                }`}</S.TextOrderItem>
                {showPrice ? (
                  <S.ItemPrice>
                    <S.TextItemPrice>
                      {orderService.getPriceText(
                        i,
                        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(message.extraData.catalogId)
                          ? priceUnitTranslate(i.priceUnit)
                          : i.priceUnit,
                        !showPrice,
                        pricePrecision,
                      )}
                    </S.TextItemPrice>
                  </S.ItemPrice>
                ) : null}
              </S.OrderItemRow>
            );
          })}
          {moreItemsText}
        </S.SectionColumn>
      </S.Section>
    );
  }

  /**
   * Handle click on the order message
   */
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick(message);
  };

  const {
    authorId,
    buyerId,
    createdAt,
    delegateId,
    hashId,
    priceMode,
    readBy,
    sellerId,
    status,
    updatedAt,
    showHours,
    externalIdSeller,
    externalIdBuyer,
  } = message.extraData;
  // TODO now only show as a message my own orders, we need test seller and author with the team
  const amSeller = myId !== buyerId;
  const amAuthor = myId === authorId;
  const showPrice = amSeller || priceMode !== 'none';
  const orderDate = updatedAt || createdAt;
  const { title } = orderService.getOrderLiterals(message.extraData, amSeller, amAuthor);
  const amOwner = myId === sellerId || myId === buyerId || myId === delegateId;
  const isRead = !amOwner || (readBy ? !!readBy.find(r => r === myId) : message.extraData.isRead);

  return (
    <S.Container
      className="order-message"
      isMessage={!!message}
      status={status}
      isRead={isRead}
      onClick={e=> contactName && handleClick(e)}
    >
      <S.Head status={status}>
        <S.HeadIconWrapper>
          <S.Icon status={status} name={getIcon(status)} />
          <S.IconFakeRow />
        </S.HeadIconWrapper>
        <S.HeadTitleWrapper>
          <S.HeadRow>
            <S.HeadTitle>
              {
                formatText(title, (text: string) => (
                  <S.HeadTitleHighlight status={status} key={text}>
                    {text}
                  </S.HeadTitleHighlight>
                )) as any // TYPEERROR
              }
            </S.HeadTitle>
            {showHours ? <S.HeadTime>{date.formatTime(orderDate!, hourFormat)}</S.HeadTime> : null}
          </S.HeadRow>
          <S.HeadRow>
            <S.HeadSubtitle>{externalIdSeller || externalIdBuyer || '#' + hashId}</S.HeadSubtitle>
            <S.HeadDate>{date.formatLongDate(orderDate!, undefined, undefined, 'EEE, dd MMM')}</S.HeadDate>
          </S.HeadRow>
        </S.HeadTitleWrapper>
      </S.Head>
      <S.Body>
        <S.Details>
          {showContactName ? renderCounterpart() : null}
          {renderWorkspace()}
          {renderTransport()}
          {renderProducts()}
        </S.Details>
        <S.BodyFooter>
          <S.Link>{__('Components.OrderCard.see_order')}</S.Link>
          {showPrice ? (
            <S.PriceCopy>
              {__('Components.OrderCard.price_amount')}
              <S.Price>{getPriceDisplay(message.extraData)}</S.Price>
            </S.PriceCopy>
          ) : null}
        </S.BodyFooter>
      </S.Body>
      {message.extraData.status === ORDER_STATUS.ACCEPTED ? renderClone() : null}
    </S.Container>
  );
};

export default React.memo(OrderCard);

function getIcon(status: ORDER_STATUS) {
  if (status === ORDER_STATUS.ACCEPTED) {
    return 'Checkmark';
  }
  if (status === ORDER_STATUS.CANCELED) {
    return 'Forbidden';
  }
  return 'Clock';
}
