import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, Tooltip as TooltipAtom } from '../../../atoms';
import { OrderImportWidgetType } from './OrderImportWidget.component';

interface IStyledProps {
  theme: ITheme;
  widgetType?: OrderImportWidgetType;
}

export const Container = styled(ColumnContainer)`
  border-radius: 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  width: 312px;
  height: 177px;
  min-width: 250px;
  max-width: 350px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  position: relative;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const HeaderRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const TextTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextGrey = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TextTimeUnit = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextValue = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: 42px;
  line-height: 57px;
`;

const getWidgetIconColor = (p: IStyledProps) => {
  switch (p.widgetType) {
    case 'time-saved':
      return p.theme.colors.green1;
    case 'errors':
      return p.theme.colors.orange1;
    default:
      return p.theme.colors.blue1;
  }
};

const getWidgetBackgroundColor = (p: IStyledProps) => {
  switch (p.widgetType) {
    case 'time-saved':
      return p.theme.colors.green2.replace('1)', '0.5)');
    case 'errors':
      return p.theme.colors.orange2.replace('1)', '0.5)');
    default:
      return p.theme.colors.blue2.replace('1)', '0.5)');
  }
};

export const IconContainer = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: center;
  background-color: ${getWidgetBackgroundColor};
  border-radius: 10px;
  width: 41px;
  height: 41px;
`;

export const WidgetIcon = styled(FontIcon)`
  color: ${getWidgetIconColor};
  font-size: 27px;
  width: 27px;
  height: 27px;
  line-height: 27px;
`;

export const ValueContainer = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  height: 65px;
`;

export const TooltipContainer = styled(ColumnContainer)`
  position: absolute;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 14px;
  height: 14px;
  width: 14px;
  line-height: 14px;
`;

export const Tooltip = styled(TooltipAtom)``;
