import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import LettersAvatar from '../LettersAvatar';
import TextLabel from '../TextLabel';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Top = styled(RowContainer)`
  width: 100%;
  cursor: pointer;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const Chevron = styled.div`
  flex: 1;
  text-align: right;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const List = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  width: 100%;
`;

export const Option = styled(RowContainer)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  cursor: pointer;
`;
export const TextColumn = styled(ColumnContainer)`
  align-items: flex-start;
`;

export const OptionText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
  bottom: -2px;
`;

export const Amount = styled(OptionText)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const Link = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  text-align: right;
  width: 100%;
`;

export const Avatar = styled(LettersAvatar)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
