import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { qs, date, CURRENCY_CODES } from 'common-services';

interface InitialFilters {
  dateRange: IDateRange;
  defaultCurrency: CURRENCY_CODES;
  isUnselect: boolean;
  orderType: IOrderType;
  selected: string | undefined;
}

interface QueryParams {
  currency?: CURRENCY_CODES;
  range?: IDateRange;
  selected?: string;
  is_unselect?: string;
  since?: string;
  type?: string;
  until?: string;
  only_unreads?: string;
}

const parseQueryParams = (search: string): QueryParams => {
  return qs.parse(search || '', [
    'currency',
    'is_unselect',
    'range',
    'selected',
    'since',
    'type',
    'until',
  ]) as QueryParams;
};

const getDateRange = (queryRange: IDateRange | undefined, defaultRange: IDateRange, fromChat: boolean): IDateRange => {
  if (queryRange && ['today', 'yesterday', 'week', 'month', 'year', 'custom', 'all'].includes(queryRange)) {
    return queryRange;
  }
  if (defaultRange) {
    return defaultRange;
  }
  return fromChat ? 'all' : 'month';
};

export const useInitialFilters = (
  filters: IOrderFilters,
  fromChat: boolean,
  amSeller: boolean,
  catalogs: any,
  meId: number,
) => {
  const location = useLocation();

  return React.useMemo(() => {
    const { currency, is_unselect, selected, since, type: qsType, until, range } = parseQueryParams(location.search);

    const dateRange = getDateRange(range, filters.range, fromChat);
    const defaultType = filters.type || (amSeller ? 'sale' : 'purchase');
    const sinceTime = since && Number(since) ? Number(since) : filters.since;
    const untilTime = until && Number(until) ? Number(until) : filters.until;
    const beginDate = sinceTime ? new Date(sinceTime) : undefined;
    const endDate = untilTime ? new Date(untilTime) : undefined;
    const orderType = (qsType || defaultType) as IOrderType;
    const dates = date.getDatesFromRange(dateRange);

    const defaultCurrency = currency || filters.currency || catalogs[meId]?.defaultCurrency;

    const result: InitialFilters = {
      dateRange,
      defaultCurrency,
      isUnselect: is_unselect === 'true',
      orderType,
      selected,
    };

    return { ...result, beginDate: beginDate || dates.beginDate, endDate: endDate || dates.endDate };
  }, [location.search, filters, fromChat, amSeller, catalogs, meId]);
};
