import { AxiosError } from 'axios';
import { __, modalActions, buyerWorkspaceActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { CHANNEL_SECTIONS } from '../../../../../constants';

import { ActionsModal, AddressAutoComplete, AddressesTable, FormSection, InputWithLabel } from '../../../../molecules';

interface IProps {
  addresses: Array<IAddress>;
  addressesRef: React.RefObject<HTMLDivElement>;
  amEditor: boolean;
  countries: ICountries;
  me: IUser;
  workspace: IWorkspace;
}

const AddressPreferences: React.FC<IProps> = ({ addresses, addressesRef, amEditor, countries, me, workspace }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = React.useState<{ id?: number; section?: CHANNEL_SECTIONS }>({});

  React.useEffect(() => {
    if (me.id && workspace) {
      dispatch(buyerWorkspaceActions.workspaceAddressesGet(me.id, workspace.id) as any);
    }
  }, [me.id, workspace]);
  const addressSelectedObj = address.id ? addresses.find(adr => adr.id === address.id) : undefined;
  return (
    <FormSection id="addresses" title={__('WorkspaceSettings.pickup_addresses')} sectionRef={addressesRef}>
      <InputWithLabel isRequired={true} width="90%" align="flex-start" disabled={!amEditor}>
        <AddressesTable
          addresses={addresses}
          emptyAddressText={__('WorkspaceSettings.zero_case')}
          navAddressAction={addId => setAddress({ id: addId, section: CHANNEL_SECTIONS.ADDRESS_EDIT })}
          navResetAddressAction={() => setAddress({})}
          onAddressDelete={(addr: IAddress) =>
            dispatch(
              modalActions.modalOpen(
                __('Components.AddNewAddress.modal_remove_text'),
                () => {
                  dispatch(
                    buyerWorkspaceActions.workspaceAddressDelete(me.id, workspace.id, addr.id, () =>
                      dispatch(modalActions.modalClose()),
                    ) as any,
                  );
                },
                {
                  buttonText: __('Components.AddNewAddress.modal_remove_cta'),
                  showCancelButton: true,
                  actionType: 'dangerous',
                },
                'nice',
              ),
            )
          }
          showAddButton={amEditor}
          disabled={!amEditor}
          weightUnit={workspace.defaultWeightUnit}
        />
      </InputWithLabel>
      {address.section ? (
        <ActionsModal
          onClose={() => setAddress({})}
          title={address.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
        >
          <AddressAutoComplete
            address={addressSelectedObj}
            countries={countries}
            me={me}
            onCancel={() => setAddress({})}
            onSubmit={(formAddress: IAddress) => {
              const userID = me.id;
              const workspaceID = workspace.id;

              if (formAddress.id) {
                dispatch(buyerWorkspaceActions.workspaceAddressUpdate(userID, workspaceID, formAddress) as any);
              } else {
                dispatch(buyerWorkspaceActions.workspaceAddressAdd(userID, workspaceID, formAddress) as any);
              }

              setAddress({});
            }}
            weightUnit={workspace.defaultWeightUnit}
          />
        </ActionsModal>
      ) : null}
    </FormSection>
  );
};

export default React.memo(AddressPreferences);
