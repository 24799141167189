import styled, { ITheme } from '../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-self: center;
`;
