import { __, CHANNEL_TYPE, chatActions, chatService, contactActions, notificationsActions } from 'common-services';
import { History } from 'history';
import * as React from 'react';

import * as navActions from '../../../actions/nav';
import { ROUTE_PATHS } from '../../../constants';
import * as S from './Mention.styled';

export interface HeritageProps {
  contactId: number;
  history: History<any>;
  name: string;
}

export interface StateProps {
  channels: { [channelId: string]: IChannel };
  contacts: { [contactId: number]: IContact };
  me: IUser;
}

export interface DispatchProps {
  createChannel: typeof chatActions.createChannel;
  createNewContact: typeof contactActions.createContactWithName;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  notificationShow: typeof notificationsActions.notificationShow;
}

export type IProps = StateProps & DispatchProps & HeritageProps;
enum MENTION_ACTIONS {
  BUY = 'buy',
  SELL = 'sell',
  ADD = 'add',
  INFO = 'info',
  MESSAGE = 'message',
}

const Mention: React.FC<IProps> = ({
  createChannel,
  channels,
  contactId,
  contacts,
  createNewContact,
  history,
  me,
  name,
  navigateChannelByPath,
  notificationShow,
}) => {
  const contact = contacts[contactId];
  const contactName = `@${contact?.name || name}`;
  const channel = chatService.getChannelByContactId(channels, contactId);
  const options = getMentionOptions();
  const isMe = me.id === contactId;
  if (isMe) return <S.Mention isMe={isMe}>{contactName}</S.Mention>;

  return (
    <S.Link onSelect={handleMentionOptions} options={options} position="top">
      <S.Mention isMe={isMe}>{contactName}</S.Mention>
    </S.Link>
  );

  /**
   * Get mention's options
   */
  function getMentionOptions() {
    if (!contact) return [{ key: MENTION_ACTIONS.ADD, value: __('MentionActions.add'), icon: 'Add-contact' }];
    const result = [];
    if (contact.isBuyer && me.settings.isSeller)
      result.push({ key: MENTION_ACTIONS.SELL, value: __('MentionActions.sell'), icon: 'Cart' });
    if (contact.isSeller && me.settings.isBuyer)
      result.push({ key: MENTION_ACTIONS.BUY, value: __('MentionActions.buy'), icon: 'Sales' });
    return [
      ...result,
      { key: MENTION_ACTIONS.INFO, value: __('MentionActions.info'), icon: 'Account' },
      { key: MENTION_ACTIONS.MESSAGE, value: __('MentionActions.message'), icon: 'Comment' },
    ];
  }

  /**
   * handle Click on add group or add broadcast and navigate to the creation page.
   */
  function handleMentionOptions(key: string) {
    switch (key) {
      case MENTION_ACTIONS.SELL:
        createChannelIfNeeded(chan =>
          navigateChannelByPath(ROUTE_PATHS.CONTACT_SELL, chan.id, (path: string) => history.push(path)),
        );
        break;
      case MENTION_ACTIONS.BUY:
        createChannelIfNeeded(chan =>
          navigateChannelByPath(ROUTE_PATHS.CONTACT_BUY, chan.id, (path: string) => history.push(path)),
        );
        break;
      case MENTION_ACTIONS.ADD:
        createNewContact(
          me.id,
          contactId,
          contactName,
          err =>
            !err &&
            notificationShow({
              title: __('Components.Chat.create_contact_success'),
              subtitle: __('Components.Chat.create_contact_success_sub'),
              closable: true,
              style: 'info',
            }),
        );
        break;
      case MENTION_ACTIONS.INFO:
        createChannelIfNeeded(chan =>
          navigateChannelByPath(ROUTE_PATHS.CONTACT_INFO, chan.id, (path: string) => history.push(path)),
        );
        break;
      case MENTION_ACTIONS.MESSAGE:
        createChannelIfNeeded(chan =>
          navigateChannelByPath(ROUTE_PATHS.CONTACT, chan.id, (path: string) => history.push(path)),
        );
        break;
    }
  }

  /**
   * Before navigating we create channel if needed
   */
  function createChannelIfNeeded(cb: (channel: IChannel) => void) {
    if (channel) return cb(channel);
    createChannel(me.id, [contactId], contact.name, contact.avatar, CHANNEL_TYPE.PRIVATE, (c: IChannel) => cb(c), true);
  }
};

export default React.memo(Mention);
