import emoji from '@jukben/emoji-search';
import { __, CHANNEL_TYPE, ModalActions, utils } from 'common-services';
import * as React from 'react';

import theme from '../../../theme';
import { isMobileDevice } from '../../../util/utils';
import * as S from './ChatFooter.styled';
import MentionModal from './MentionModal/MentionModal.component';

export const DEFAULT_INPUT_HEIGHT = 34;

export interface IProps {
  acceptOnlyImages?: boolean;
  channel?: IChannel;
  contacts: Record<number, IContact>;
  draftText?: string;
  handleSendFile?: (files: Array<File>) => void;
  id: string;
  me: IUser;
  modalOpen: (text: string, action?: any, extra?: IModalExtraInfo, name?: IModalName) => ModalActions;
  name: string;
  onTextInputRef?: (ref: HTMLTextAreaElement | null) => void;
  replyFromSenderAvatar?: string;
  replyFromSenderAvatarColor?: IAvatarColor;
  replyFromSenderName?: string;
  replyToMessage?: IMessage;
  sendMessage: (channelId: string, text: string, isFile?: boolean, file?: IFile, replyToMessage?: IMessage) => void;
  setMessageDraft: (myId: number, channelId: string, draftText: string, replyToMessage?: IMessage) => void;
  setReplyToMessage?: (message?: IMessage) => void;
}

interface IState {
  showEmojiPicker: boolean;
  text: string;
  showMentionModal?: string;
  mentionSelected: number;
}

class ChatFooter extends React.PureComponent<IProps, IState> {
  private input: HTMLTextAreaElement;
  private emojiContainerRef: React.RefObject<HTMLDivElement>;
  private mentionsContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      mentionSelected: 0,
      showEmojiPicker: false,
      text: props.draftText || '',
    };
    this.emojiContainerRef = React.createRef();
    this.mentionsContainerRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.id !== this.props.id) {
      this.setState({ text: this.props.draftText || '' });
      prevProps.setMessageDraft(prevProps.me.id, prevProps.id, this.state.text, this.props.replyToMessage);
    }

    const { showMentionModal, mentionSelected } = this.state;
    if (showMentionModal !== undefined && mentionSelected !== prevState.mentionSelected) {
      this.scrollToMemberInList(this.getMembersFilteredForMention()[mentionSelected]);
    }
  }

  public componentWillUnmount() {
    const { setMessageDraft, id, replyToMessage, me } = this.props;
    setMessageDraft(me.id, id, this.state.text, replyToMessage);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { channel, replyToMessage } = this.props;
    const { showMentionModal } = this.state;

    if (channel && ['left', 'evicted'].includes(channel.status)) {
      return (
        <S.Container height="100%">
          <S.Message>{__('Components.Channel.not_active')}</S.Message>
        </S.Container>
      );
    }
    return (
      <S.Container>
        {replyToMessage ? this.renderReplyToMessage() : null}
        {this.renderChatInput()}
        {showMentionModal !== undefined ? this.renderMentions() : null}
      </S.Container>
    );
  }

  /**
   * Renders chat input component
   */
  private renderChatInput = () => {
    const { handleSendFile, onTextInputRef } = this.props;
    const { mentionSelected, text } = this.state;

    return (
      <S.MessageInput
        autoFocus={!isMobileDevice()}
        maxLength={2500}
        name="chat-footer-input-message"
        onChangeText={(val: string) => {
          this.setState({ text: val });
          this.setShowMentionModal();
        }}
        onInputRef={ref => {
          this.input = ref;
          onTextInputRef?.(ref);
        }}
        onKeyDown={e => {
          const hasMentionSuggestions = this.getMembersFilteredForMention().length > 0;
          if (text && e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !hasMentionSuggestions) {
            e.preventDefault();
            this.send();
          }

          if (hasMentionSuggestions) {
            if (['Tab', 'ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) e.preventDefault();
            switch (e.key) {
              case 'Tab':
              case 'ArrowDown':
                if (mentionSelected !== this.getMembersFilteredForMention().length - 1)
                  this.setState({
                    mentionSelected: mentionSelected + 1,
                  });
                break;
              case 'ArrowUp':
                if (mentionSelected > 0)
                  this.setState({
                    mentionSelected: mentionSelected - 1,
                  });
                break;
              case 'Enter':
                const memberToAdd = this.getMembersFilteredForMention()[mentionSelected];
                this.addMentionToInput(memberToAdd);
                break;
              case 'Escape':
                this.setState({ showMentionModal: undefined, mentionSelected: 0 });
                break;
              default:
                break;
            }
          }
          switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
              this.setShowMentionModal();
              break;
          }
        }}
        onFileUpload={e => handleSendFile(Array.from(e.target.files))}
        onSendFiles={files => handleSendFile(files)}
        onFocus={() => this.setShowMentionModal()}
        onSendMessage={(_: string) => this.send()}
        placeholder={__('Components.Chat.placeholder')}
        sendMultipleFiles={true}
        sendButtonId="chat-footer-send-message"
        showEmojiSelector={true}
        showSendFiles={true}
        text={text}
      />
    );
  };

  /**
   * Check input content to display or not the mention modal
   */
  private setShowMentionModal() {
    const { channel } = this.props;

    if (channel?.type === CHANNEL_TYPE.GROUP && this.input) {
      const textBeforeCaret = this.input.value.slice(0, this.input.selectionStart) as string;
      const textFromLastArobas = utils.getTextAfterLastMention(this.input.value, this.input.selectionStart);
      const normalizedLastMention = utils.toLocaleLowerCaseNormalized(
        textFromLastArobas.replace(/ /g, '').replace('_', ''),
      );

      if (normalizedLastMention.length === 0 && !textBeforeCaret.endsWith('@')) {
        this.setState({ showMentionModal: undefined });
        return;
      }

      const matchingMembers = channel.members.reduce(
        (acc, member) => {
          if (member.isRegistered !== undefined && !member.isRegistered) {
            return acc;
          }
          const normalizedMemberName = utils.toLocaleLowerCaseNormalized(member.name.replace(/ /g, ''));
          const partialMatch = normalizedMemberName.startsWith(normalizedLastMention);
          const exactMatch = member.name.replace(/ /g, '_').trim() === textFromLastArobas.trim();

          if (exactMatch) {
            acc.exact++;
          }
          if (partialMatch && !exactMatch) {
            acc.partial++;
          }
          return acc;
        },
        { partial: 0, exact: 0 },
      );

      if (matchingMembers.partial > 0 && matchingMembers.exact !== 1) {
        this.setState({ showMentionModal: normalizedLastMention });
      } else {
        this.setState({ showMentionModal: undefined });
      }
    }
  }

  /**
   * Deprecated chat input
   */
  private renderOldInput = () => {
    const { onTextInputRef } = this.props;
    const { mentionSelected, text } = this.state;

    return (
      <S.InputTextWithAutocomplete
        id="chat-footer-input-message"
        placeholder={__('Components.Chat.placeholder')}
        value={text}
        autoFocus={!isMobileDevice()}
        loadingComponent={() => null}
        containerStyle={{
          width: '100%',
          display: 'flex',
          height: DEFAULT_INPUT_HEIGHT,
          cursor: 'pointer',
        }}
        listStyle={{
          background: theme.colors.white,
          borderRadius: theme.borderRadius,
          bottom: '63px',
          boxShadow: theme.boxShadow,
          display: 'flex',
          height: '35px',
          left: '80px',
          marginTop: '-100px',
          position: 'absolute',
        }}
        trigger={{
          ':': {
            dataProvider: token => {
              return emoji(token)
                .slice(0, 10)
                .map(({ name, char }) => ({ name, char }));
            },
            component: ({ entity: { char }, selected }) => {
              return (
                <S.EmojiAutocompleteElement isSelected={selected}>
                  {char} {selected}
                </S.EmojiAutocompleteElement>
              );
            },
            output: item => item.char,
          },
        }}
        onFocus={() => {
          this.setShowMentionModal();
        }}
        onChange={e => {
          const input = e.target;
          if (input.value === '\n') input.value = '';
          this.setState({ text: input.value });
          this.setShowMentionModal();

          let height = DEFAULT_INPUT_HEIGHT + 'px';
          if (input.value) {
            if (input.scrollHeight > 68) height = '68px';
            else if (input.scrollHeight > DEFAULT_INPUT_HEIGHT) height = input.scrollHeight + 'px';
          }
          input.style.height = height;
          if (input.parentElement) {
            input.parentElement.style.height = height;
          }
        }}
        onKeyDown={e => {
          const hasMentionSuggestions = this.getMembersFilteredForMention().length > 0;
          if (text && e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !hasMentionSuggestions) {
            e.preventDefault();
            this.send();
            e.currentTarget.style.height = DEFAULT_INPUT_HEIGHT + 'px';
          }

          if (hasMentionSuggestions) {
            if (['Tab', 'ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) e.preventDefault();
            switch (e.key) {
              case 'Tab':
              case 'ArrowDown':
                if (mentionSelected !== this.getMembersFilteredForMention().length - 1)
                  this.setState({
                    mentionSelected: mentionSelected + 1,
                  });
                break;
              case 'ArrowUp':
                if (mentionSelected > 0)
                  this.setState({
                    mentionSelected: mentionSelected - 1,
                  });
                break;
              case 'Enter':
                const memberToAdd = this.getMembersFilteredForMention()[mentionSelected];
                this.addMentionToInput(memberToAdd);
                break;
              case 'Escape':
                this.setState({ showMentionModal: undefined, mentionSelected: 0 });
                break;
              default:
                break;
            }
          }
          switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
              this.setShowMentionModal();
              break;
          }
        }}
        innerRef={ref => {
          this.input = ref;
          if (onTextInputRef) onTextInputRef(ref);
        }}
      />
    );
  };

  /**
   * Render mentions modal
   */
  private renderMentions = () => {
    const { contacts } = this.props;
    const { mentionSelected, showMentionModal } = this.state;
    return (
      <MentionModal
        className="mention-modal"
        contacts={contacts}
        innerRef={this.mentionsContainerRef}
        members={this.getMembersFilteredForMention()}
        mentionSelected={mentionSelected}
        onClick={(member: IMember) => this.addMentionToInput(member)}
        onMouseEnter={() => this.setState({ mentionSelected: -1 })}
        showMentionModal={showMentionModal}
      />
    );
  };

  /**
   * Add mention in the text input for a member
   */
  private addMentionToInput(member: IMember) {
    if (!member) return;
    const { channel, me } = this.props;
    const { text } = this.state;
    if (!this.input) return;
    if (channel.members.find(m => m.id === member.id && member.name) || member.id === me.id) {
      const [, wordCaretEnd] = utils.getWordBoundariesAtPosition(this.input.value, this.input.selectionStart);

      // We need to find where the mention start to strip it out of the input
      const textBeforeCaret = this.input.value.slice(0, this.input.selectionStart);
      const textBeforeCaretSplitByArobas = textBeforeCaret.split('@');
      const textFromLastArobas = textBeforeCaretSplitByArobas[textBeforeCaretSplitByArobas.length - 1];
      const mentionStartsAt = textBeforeCaret.length - textFromLastArobas.length - 1;

      // Get where the next before and after the mention to reconstruct the input
      const textBeforeMention = text.slice(0, mentionStartsAt).trim();
      const mentionDisplayed = `@${member.name.replace(/ /g, '_')}`;
      const textAfterMention = text.slice(wordCaretEnd).trim();
      const nextText =
        textBeforeMention.length > 0
          ? `${textBeforeMention} ${mentionDisplayed}  ${textAfterMention}`
          : `${mentionDisplayed}  ${textAfterMention}`;
      const newCaretPosition = nextText.length - textAfterMention.length - 1;

      this.setState(
        {
          mentionSelected: 0,
          showMentionModal: undefined,
          text: nextText,
        },
        () => {
          this.focusWithCaretAtPosition(this.input, newCaretPosition);
        },
      );
    }
  }

  private focusWithCaretAtPosition(input, position) {
    if (input) {
      input.focus();
      input.setSelectionRange(position, position);
      this.setShowMentionModal();
    }
  }

  /**
   * Scroll to a member in the list
   */
  private scrollToMemberInList(member: IMember) {
    if (!member) return;
    const { mentionSelected } = this.state;
    const mentionModal = document.getElementById('mention-modal');
    const memberElement = document.getElementById(`member-select-${member.id}`);
    // Don't scroll for the first 6 elements
    if (mentionSelected < 6 && mentionModal?.scrollTop === 0) return;

    memberElement?.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Get list of members filtered to add as a mention
   */
  private getMembersFilteredForMention = () => {
    const { showMentionModal } = this.state;
    const { channel } = this.props;

    if (showMentionModal === undefined) {
      return [];
    }
    const normalizedLookup = utils.toLocaleLowerCaseNormalized(showMentionModal).replace('_', '');

    return channel.members.filter(m => {
      if (m.status !== 'active' || !m.name || (m.isRegistered !== undefined && !m.isRegistered)) {
        return false;
      }

      const lowercaseName = utils.toLocaleLowerCaseNormalized(m.name);
      const normalizedName = lowercaseName.replace(/ /g, '').replace(/_/g, '');

      return normalizedName && normalizedName.startsWith(normalizedLookup);
    });
  };

  /**
   * Render reply to message above text input
   */
  private renderReplyToMessage() {
    const {
      contacts,
      channel,
      me,
      replyToMessage,
      replyFromSenderName,
      replyFromSenderAvatar,
      replyFromSenderAvatarColor,
    } = this.props;
    return (
      <S.RowReply>
        <S.ReplyMessage
          hourFormat={me.settings.hourFormat}
          members={channel?.members.map(member => ({
            ...member,
            id: member.id,
            name: member.name || contacts[member.id]?.name || '',
            companyName: '',
          }))}
          me={me}
          message={replyToMessage}
          numberOfLines={1}
          senderAvatar={replyFromSenderAvatar}
          senderAvatarColor={replyFromSenderAvatarColor}
          senderName={replyFromSenderName}
        />
        <S.CloseIcon id="chat-footer-remove-reply" name="Close" onClick={() => this.removeReplyToMessage()} />
      </S.RowReply>
    );
  }

  /**
   * Remove reply to message in state & draft
   */
  private removeReplyToMessage = () => {
    const { setReplyToMessage, setMessageDraft, id, me } = this.props;
    if (setReplyToMessage) setReplyToMessage();
    setMessageDraft(me.id, id, this.state.text);
  };

  /**
   * send message to server
   */
  private send() {
    const { text } = this.state;
    if (!text.trim()) {
      this.setState({ text: '' });
      return;
    }
    const { channel, replyToMessage, sendMessage, setReplyToMessage } = this.props;
    sendMessage(channel?.id || '', text.trim(), false, undefined, replyToMessage);
    if (this.input) this.input.style.height = DEFAULT_INPUT_HEIGHT + 'px';
    this.setState({ text: '', showMentionModal: undefined, mentionSelected: 0 }, () => {
      if (setReplyToMessage) setReplyToMessage();
    });
  }

  /**
   * On click outside, hide emoji picker
   */
  private handleClickOutside = (event: MouseEvent) => {
    if (this.emojiContainerRef?.current && !this.emojiContainerRef.current.contains(event.target as any)) {
      this.setState({ showEmojiPicker: false });
    }
    if (this.mentionsContainerRef?.current && !this.mentionsContainerRef.current.contains(event.target as any)) {
      this.setState({ showMentionModal: undefined, mentionSelected: 0 });
    }
  };
}

export default ChatFooter;
