import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
}

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const LetterRow = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.light2};
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingView};
  width: 100%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  margin-top: -1px;
  height: 20px;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
