import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import Table from '../Table/Table.component';
import * as S from './ReferenceListTable.styled';
import { columnsReferenceListTable, getColumns } from './ReferenceListTable.columns';
import { __, associateReferenceActions } from 'common-services';
import { useDispatch } from 'react-redux';
import { IGroupedDataReferenceList } from 'common-services/dist/associate-reference/interface';

export interface IProps {
  data: Array<IGroupedDataReferenceList>;
  onLoadMore?: () => void;
}

const ReferenceListTable: React.FC<IProps> = ({ data, onLoadMore }) => {
  const { workspaceId } = useParams<{ workspaceId: string }>(); 
  const history = useHistory();
  const dispatch = useDispatch();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleClickRow = (d: IGroupedDataReferenceList) => {
    dispatch(associateReferenceActions.setAssociateReferenceSelect(d));
    history.push(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST_SELECT,
        workspaceId,
        referenceId: d.sku,
      }),
    );
  };

    // Scroll columns 
    React.useEffect(() => {
      const handleScroll = () => {
        if (!containerRef.current) return;
        
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        
        if (scrollHeight - scrollTop <= clientHeight + 1) {
          onLoadMore();
        }
      };
  
      const container = containerRef.current;
      container?.addEventListener('scroll', handleScroll);
  
      return () => {
        container?.removeEventListener('scroll', handleScroll);
      };
    }, [onLoadMore]);

  return (
    <S.Container ref={containerRef}>
      <Table
        selectable={false}
        columns={getColumns()}
        emptyText=""
        onClickRow={d =>
          handleClickRow(d as IGroupedDataReferenceList)
        }
        values={data}
        productColumns={columnsReferenceListTable}
        showStickyHeader={true}
        sort="updatedAt"
        sortOrder="desc"
      />
    </S.Container>
  );
};

export default ReferenceListTable;
