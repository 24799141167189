import {
  __,
  CHANNEL_DISPLAY,
  CHANNEL_TYPE,
  chatService,
  contactActions,
  date,
  notificationsActions,
  RenderTrack,
  utils,
} from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { EmptyListResource } from '../../molecules';
import Header from '../Header';
import * as S from './ChatUnread.styled';

export type IRouteProps = RouteComponentProps<{ channelId?: string }> & {
  isModal?: boolean;
};

export interface IStateProps {
  contacts: { [contactId: number]: IContact };
  channels: { [channelId: string]: IChannel };
  lastMessageAt: Record<string, number>;
  me: IUser;
  pendingOrders: { [contactId: number]: number };
  unreadMessageCount: Record<string, number>;
}

export interface IDispatchProps {
  contactsInvite: typeof contactActions.contactsInvite;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  notificationShow: typeof notificationsActions.notificationShow;
}

export type IProps = IStateProps & IRouteProps & IDispatchProps;

interface IState {
  channelsToShow: Array<IContactShow>;
}

/**
 * Chats unread
 */
export default class ChatUnread extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      channelsToShow: [],
    };
  }

  public componentDidMount() {
    const channelsWithUnread = this.getChannelsToShow();
    RenderTrack.track('ChatUnread', {
      renderTime: this.t,
      channelsCount: channelsWithUnread.length,
    });
    this.setState({
      channelsToShow: channelsWithUnread,
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { channels, lastMessageAt, unreadMessageCount } = this.props;
    if (
      prevProps.channels !== channels ||
      prevProps.lastMessageAt !== lastMessageAt ||
      prevProps.unreadMessageCount !== unreadMessageCount
    ) {
      const channelsWithUnread = this.getChannelsToShow();
      this.setState({
        channelsToShow: channelsWithUnread,
      });
    }
  }

  public render() {
    const { contacts, contactsInvite, lastMessageAt, me, unreadMessageCount, notificationShow } = this.props;
    const { channelsToShow } = this.state;

    return (
      <>
        <Header show={true} title={__('ChatUnread.title')} tabSelected={'chat'} />
        <S.Container>
          {channelsToShow.length ? (
            channelsToShow.map((c, i, list) => {
              const contactId = !['group', 'broadcast'].includes(c.type) && c.members?.[0]?.id;
              const contact = contactId && contacts[contactId];
              let featuredSubtitle = c.pendingOrders
                ? __('Components.ChatList.subtitle', { count: c.pendingOrders, amount: c.pendingOrders })
                : undefined;
              if (contact?.isNew && !contact?.isUnregistered && !contact?.isDefaultContact) {
                featuredSubtitle = __('Messages.Chat.greetings');
              }
              const isLast = i === list.length - 1;

              const lastMessageDate = lastMessageAt[c.id] || c.createdAt;
              const lastMessageDatePrevious = i ? lastMessageAt[list[i - 1].id] || list[i - 1].createdAt : 0;
              const isFirstOfTheDay = !i || differenceInCalendarDays(lastMessageDate, lastMessageDatePrevious) !== 0;

              const isPublic = c.type === CHANNEL_TYPE.PUBLIC;
              const defaultPublicImage = isPublic
                ? c.configuration?.thumbnail || c.imageUrl || IMAGES.publicChannelDefault.thumbnail
                : undefined;
              return (
                <React.Fragment key={c.id + '_' + i}>
                  {isFirstOfTheDay ? (
                    <S.TextDate isFirstDate={i === 0}>
                      {utils.firstToUpperCase(date.formatLongDate(lastMessageDate))}
                    </S.TextDate>
                  ) : null}
                  <S.ChatItem
                    amActive={c.status === 'active'}
                    avatarColor={c.avatarColor}
                    badge={unreadMessageCount?.[c.id]}
                    channelId={c.id}
                    contact={contact}
                    dateFormat={me.settings.dateFormat}
                    featuredSubtitle={featuredSubtitle}
                    hourFormat={me.settings.hourFormat}
                    imBlocking={c.imBlocking}
                    img={isPublic ? defaultPublicImage : c.imageUrl}
                    isArchived={c.display === CHANNEL_DISPLAY.ARCHIVED}
                    isBroadcast={c.type === CHANNEL_TYPE.BROADCAST}
                    isGroup={c.type === CHANNEL_TYPE.GROUP}
                    isInvited={contact?.isUnregistered}
                    isLast={isLast}
                    isMuted={c.muted}
                    isNew={c.isNew}
                    isSelected={false}
                    lastTimeInvited={contact?.lastTimeInvited}
                    myId={me.id!}
                    notificationShow={notificationShow}
                    onClick={this.handleClickChannel}
                    resendInvite={contactsInvite}
                    showOpen={true}
                    subtitle={
                      featuredSubtitle ||
                      (c.type === CHANNEL_TYPE.BROADCAST
                        ? __('Constants.NumMembers', { count: c.numMembers || 0 })
                        : c.companyName)
                    }
                    time={lastMessageAt?.[c.id] || c.createdAt}
                    title={c.name}
                    type={c.type}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <S.CenterContainer>
              <EmptyListResource
                imageSize="240px"
                imageUrl={IMAGES.messagesGrey}
                showButton={false}
                text={__('ChatUnread.zero_case.title')}
                text2={__('ChatUnread.zero_case.description')}
              />
            </S.CenterContainer>
          )}
        </S.Container>
      </>
    );
  }

  /**
   * Get channels to show sorted by last message
   */
  private getChannelsToShow() {
    const { contacts, channels, lastMessageAt, pendingOrders, unreadMessageCount } = this.props;
    return Object.values(channels)
      .filter(c => (unreadMessageCount[c.id] || 0) > 0)
      .map(c => {
        if (c.type === CHANNEL_TYPE.PRIVATE && contacts[c.members?.[0]?.id])
          return chatService.contactToContactShow(
            contacts[c.members?.[0]?.id],
            pendingOrders[c.members?.[0]?.id],
            lastMessageAt[c.id],
            unreadMessageCount[c.id],
            c,
          );
        return chatService.channelToContactShow(c, lastMessageAt[c.id], unreadMessageCount[c.id], pendingOrders);
      })
      .sort((a: IContactShow, b: IContactShow) => {
        const lastA = lastMessageAt[a.id] ? lastMessageAt[a.id] : a.createdAt;
        const lastB = lastMessageAt[b.id] ? lastMessageAt[b.id] : b.createdAt;
        if (!lastA) return 1;
        if (!lastB) return -1;
        return lastB - lastA || Number(b.id) - Number(a.id);
      });
  }

  /**
   * Navigate to a specific channel
   */
  private handleClickChannel = (channelId: string, type: CHANNEL_TYPE, contactId: number) => {
    const { history, navigateChannelByPath } = this.props;
    if (channelId) {
      if (type === CHANNEL_TYPE.PUBLIC) {
        return history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId }));
      }
      return navigateChannelByPath(contactId ? ROUTE_PATHS.CONTACT : ROUTE_PATHS.GROUP, channelId, (path: string) => {
        history.push(path);
      });
    }
  };
}
