import { WORKING_STATUS } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  LettersAvatar,
  RowContainer,
  SelectWorkingStatus as SWS,
  SimpleDropdown,
  Skeleton as SkeletonItem,
  TextLabel,
} from '../../atoms';
import FontIcon from '../../atoms/FontIcon/FontIcon.component';
import { ChatGrouped, InputWithLabel as IWL } from '../../molecules';

interface IStyledProps {
  channelsCount?: number;
  hasUnread?: boolean;
  isSelected?: boolean;
  numHeaders?: number;
  showOpen?: boolean;
  status?: WORKING_STATUS;
  theme: ITheme;
}

export const IpadGap = styled.div`
  width: 59px;
  height: 0px;
`;

export const Wrapper = styled.div<IStyledProps>`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  max-width: ${(p: IStyledProps) => p.theme.chatListWidth.xl}px;
  width: ${(p: IStyledProps) => p.theme.chatListWidth.xl}px;
  height: ${(p: IStyledProps) => p.theme.contentHeight};
  border-right: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  position: relative;
  transition: max-width 0.5s;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    max-width: ${(p: IStyledProps) => p.theme.chatListWidth.l}px;
    width: ${(p: IStyledProps) => p.theme.chatListWidth.l}px;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: ${(p: IStyledProps) => (p.showOpen ? p.theme.chatListWidth.l : p.theme.chatListWidth.s)}px;
    position: absolute;
    left: 0;
    z-index: 100;
    height: 100%;
    background: white;
  }
`;

export const HideIcon = styled(FontIcon)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: 26px;
  width: 26px;
  padding: 2.5px 5px;
  position: absolute;
  top: 39px;
  right: -15px;
  transition: right 0.5s;
  z-index: 1;
  display: none;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: flex;
  }
`;

export const Container = styled(ColumnContainer)``;
export const OutContainer = styled(ColumnContainer)<IStyledProps>`
  transition: width 0.5s;
  width: ${(p: IStyledProps) => p.theme.chatListWidth.xl}px;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    width: ${(p: IStyledProps) => p.theme.chatListWidth.l}px;
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: ${(p: IStyledProps) => (p.showOpen ? p.theme.chatListWidth.l : p.theme.chatListWidth.s)}px;
  }
`;

export const Channels = styled(ColumnContainer)``;

const getScrollHeight = (p: IStyledProps) => {
  return p.theme.windowHeight + ' - ' + p.numHeaders + ' * ' + p.theme.headerHeight;
};

export const Scroll = styled.div`
  height: calc(${getScrollHeight});
  max-height: calc(${getScrollHeight});
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

export const AddButtons = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  height: calc(${(p: IStyledProps) => p.theme.headerHeight} - 1px);
  padding: 0;
  @media only screen and (min-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
    ${(p: IStyledProps) =>
      p.showOpen &&
      css`
        display: flex;
      `}
  }
`;

export const AddButtonsIpad = styled(ColumnContainer)`
  justify-content: ${(p: IStyledProps) => 'space-between'};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  display: none;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    ${(p: IStyledProps) =>
      !p.showOpen &&
      css`
        display: flex;
      `}
  }
`;

export const FontIconWrapper = styled.div`
  align-self: center;
  max-width: 30px;
  size: 18px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
`;

export const Link = styled(SimpleDropdown)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const IpadIcon = styled(FontIcon)`
  font-size: 18px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
`;

export const IpadSearchIcon = styled(FontIcon)`
  font-size: 18px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey4};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
`;

export const SearchContainer = styled(RowContainer)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  @media only screen and (min-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    display: none;
    ${(p: IStyledProps) =>
      p.showOpen &&
      css`
        display: flex;
      `}
  }
`;

export const SearchContainerIpad = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  display: none;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    ${(p: IStyledProps) =>
      !p.showOpen &&
      css`
        display: flex;
      `}
  }
`;

export const NotificationWrapper = styled(RowContainer)`
  overflow: hidden;
`;
export const NotificationHeader = styled(RowContainer)`
  align-items: center;
  padding: 5px ${(p: IStyledProps) => p.theme.paddingSize(5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  overflow: hidden;
  min-width: ${(p: IStyledProps) => p.theme.chatListWidth.xl}px;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    min-width: ${(p: IStyledProps) => p.theme.chatListWidth.l}px;
    padding: 5px ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const NotificationIconContainer = styled.div`
  display: flex;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 32px;
  width: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const NotificationIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue2};
  font-size: 18px;
`;

export const NotificationCol = styled(ColumnContainer)`
  margin-left: 11px;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    ${(p: IStyledProps) =>
      !p.showOpen &&
      css`
        display: none;
      `}
  }
`;

export const NotificationText = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const NotificationLink = styled.a`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-decoration: underline;
  text-decoration-color: ${(p: IStyledProps) => p.theme.colors.black};
  @media only screen and (max-width: 1450px) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
`;

export const ContainerDisabled = styled(Wrapper)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SkeletonRow = styled(RowContainer)`
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Skeleton = styled(SkeletonItem)`
  height: 14px;
`;

export const InviteCard = styled(ColumnContainer)<IStyledProps>`
  width: auto;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};

  ${(p: IStyledProps) =>
    p.channelsCount > 4 &&
    css`
      display: none;
    `}

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    ${(p: IStyledProps) =>
      !p.showOpen &&
      css`
        display: none;
      `}
  }
`;

export const InviteCTA = styled(Button)`
  width: 100%;
`;

export const InviteCardTitle = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  line-height: 28px;
`;

export const InviteCardTitleGradient = styled(InviteCardTitle)`
  display: inline-block;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  background: linear-gradient(
    104deg,
    ${(p: IStyledProps) => p.theme.colors.green1},
    ${(p: IStyledProps) => p.theme.colors.blue1} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0;
`;

export const InviteCardDescription = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ZeroCaseContactsBanner = styled(RowContainer)`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextBanner = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const ArchiveChatsHeader = styled(RowContainer)`
  align-items: center;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const BackArrow = styled(FontIcon)`
  height: 35px;
  width: 35px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: 50%;
  padding-left: 10px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TitleArchived = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const InputWithLabel = styled(IWL)``;

const SelectWorkingStatus = styled(SWS)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 92%;
`;

const TextBlack = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextGrey2 = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const ZeroCaseContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const UnreadChannelsContainer = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  cursor: pointer;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.blue2 : p.theme.colors.transparent)};

  &:hover {
    background-color: ${(p: IStyledProps) =>
      p.isSelected ? p.theme.colors.grey4 : p.theme.colors.grey4.replace('1)', '0.8)')};
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const RowCenter = styled(RowContainer)`
  align-items: center;
`;

export const MessageIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 14px;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const UnreadRow = styled(RowContainer)`
  align-items: center;
  min-width: 140px;
`;

export const TextUnreadTitle = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) => (p.hasUnread ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const BadgeIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.red1};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
