import {
  __,
  CHANNEL_DISPLAY,
  chatService,
  IChannel,
  IChannelView,
  IContact,
  IMessage,
  IUser,
  utils,
} from 'common-services';
import * as React from 'react';

import { ContactItemSelectable } from '..';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import * as S from './ForwardMessage.styled';

export interface IProps {
  channelId: string;
  channels: { [channelId: string]: IChannel };
  close: () => void;
  contacts: { [contactId: number]: IContact };
  forwardMessage: (myId: number, msg: IMessage, channels: Array<string>) => void;
  history?: { push: (url: string) => void; replace?: (url: string) => void };
  lastMessageAt: Record<string, number>;
  me: IUser;
  message: IMessage;
  title?: string;
}

const ForwardMessage: React.FC<IProps> = ({
  close,
  title,
  channelId,
  me,
  lastMessageAt,
  history,
  message,
  forwardMessage,
  contacts,
  channels,
}) => {
  const [filter, setFilter] = React.useState<string>('');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [selectedBroadcast, setSelectedBroadcast] = React.useState<Array<string>>([]);
  /**
   * the channels views from contacts and channels.
   * Gather groups + private channels
   */
  const channelViews = chatService.getChannelViews(
    channels,
    {},
    contacts,
    filter,
    CHANNEL_DISPLAY.NORMAL,
    lastMessageAt,
    {},
    { publicExcluded: true },
  );

  /**
   * Render contact list (in + out consentio)
   */
  function renderChannelsList() {
    const f = filter && filter.length > 1 && utils.toLocaleLowerCaseNormalized(filter);
    const channelsToShow = channelViews.filter(
      channelView => !f || utils.toLocaleLowerCaseNormalized(channelView.name || '').includes(f),
    );
    const currentChannelIndex = channelId ? channelsToShow.findIndex(c => c.id === channelId) : -1;
    let currentChannel: Array<IChannelView>;
    if (currentChannelIndex !== -1) {
      currentChannel = channelsToShow.splice(currentChannelIndex, 1);
    }
    const latestChannels = channelsToShow.splice(0, 3);
    const hasSelected = !!(selected.length || selectedBroadcast.length);

    return (
      <S.Scroll hasSelected={hasSelected}>
        {currentChannel ? (
          <S.Contacts>
            <S.SectionTitle>{__('Components.ProductListShare.current')}</S.SectionTitle>
            {renderChannelItem(currentChannel[0], 0, true)}
          </S.Contacts>
        ) : null}
        <S.Contacts>
          <S.SectionTitle>{__('Components.ProductListShare.frequent')}</S.SectionTitle>
          {latestChannels.map((channelView, index, arr) =>
            renderChannelItem(channelView, index, index === arr.length - 1),
          )}
        </S.Contacts>
        {channelsToShow.length ? (
          <S.Contacts>
            <S.SectionTitle>{__('Components.ProductListShare.recent_chats')}</S.SectionTitle>
            {channelsToShow.map((channelView, index, arr) =>
              renderChannelItem(channelView, index, index === arr.length - 1),
            )}
          </S.Contacts>
        ) : null}
      </S.Scroll>
    );
  }

  /**
   * Render selectable contact item
   */
  function renderChannelItem(channelView: IChannelView, index: number, isLast: boolean) {
    const { id, avatar, avatarColor, name, subtitle, type } = channelView;
    const selectedIdx = type === 'broadcast' ? selectedBroadcast.indexOf(id) : selected.indexOf(id);
    const isSelected = selectedIdx !== -1;
    return (
      <ContactItemSelectable
        key={index + '-' + id}
        avatar={avatar}
        avatarColor={avatarColor}
        isAdmin={false}
        isLast={isLast}
        selected={isSelected}
        time={lastMessageAt[id]}
        dateFormat={me.settings.dateFormat}
        hourFormat={me.settings.hourFormat}
        onPress={() => {
          if (type === 'broadcast') {
            const selectedCopy = selectedBroadcast.slice();
            if (isSelected) {
              selectedCopy.splice(selectedIdx, 1);
            } else {
              selectedCopy.push(id);
            }
            setSelectedBroadcast(selectedCopy);
          } else {
            const selectedCopy = selected.slice();
            if (isSelected) {
              selectedCopy.splice(selectedIdx, 1);
            } else {
              selectedCopy.push(id);
            }
            setSelected(selectedCopy);
          }
        }}
        self={false}
        subtitle={subtitle}
        name={name}
        type={type}
      />
    );
  }

  /**
   * On next action, forward message to channels selected.
   * If we select only one channel, we navigate to it
   */
  const onNext = React.useCallback(() => {
    if (selected.length) {
      close();
      forwardMessage(me.id, message, selected);
      if (selected.length === 1)
        history?.replace?.(
          getPath({
            path: ROUTE_PATHS.CHAT,
            channelId: selected[0],
          }),
        );
    }
  }, [close, forwardMessage, history, me, message, selected]);

  return (
    <S.ActionsModal onClose={close} title={title || __('Components.Forward.title')}>
      <S.Search
        onChange={s => setFilter(s)}
        placeHolder={__('ProductListShare.search_placeholder')}
        id="input_search_product_share"
      />
      {renderChannelsList()}
      {selected.length || selectedBroadcast.length ? (
        <S.CTA iconName="Send-message" iconSize="23px" id="button-next-share-product" onClick={onNext}>
          {__('ProductListShare.cta', { number: selected.length + selectedBroadcast.length })}
        </S.CTA>
      ) : null}
    </S.ActionsModal>
  );
};

export default React.memo(ForwardMessage);
