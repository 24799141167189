import { __, IPublicUser, userActions, utils } from 'common-services';
import * as React from 'react';

import { getDeepLink } from '../../../services/link';
import { isMobileDevice } from '../../../util/utils';
import * as S from './BannerSignUp.styled';

interface IProps {
  className?: string;
  isFooter?: boolean; // Is footer for desktop version, header for mobile
  owner?: IPublicUser;
  subtitle?: string;
  title?: string;
  showLogin?: typeof userActions.showLogin;
}

const BannerSignUp: React.FC<IProps> = ({ className, isFooter = true, owner, showLogin, subtitle, title }) => {
  return (
    <S.Container className={className} isFooter={isFooter}>
      <S.Content>
        {owner ? (
          <S.UserImage img={owner.avatar} text={owner.name} size={35} avatarColor={utils.getAvatarColor(owner.name)} />
        ) : null}
        <S.TextsContainer>
          {title ? <S.TextTitle>{title}</S.TextTitle> : null}
          <S.TextRegular>
            {
              utils.formatText(subtitle || __('BannerLanding.pricelist_invite', { name: owner?.name }), str => (
                <S.TextBold key={str}>{str}</S.TextBold>
              )) as any // TYPEERROR
            }
          </S.TextRegular>
        </S.TextsContainer>
        <S.InstallButton
          type="landing-transparent"
          onClick={() => {
            if (isMobileDevice() || !showLogin) {
              window.location.href = getDeepLink('/');
            } else {
              showLogin();
            }
          }}
        >
          {isFooter ? __('BannerLanding.sign_up_free') : __('BannerLanding.install_cta')}
        </S.InstallButton>
      </S.Content>
    </S.Container>
  );
};

export default React.memo(BannerSignUp);
