import { ORDER_STATUS } from 'common-services';

import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  borderColor?: string;
  status?: ORDER_STATUS;
}

const StyledCardItem = styled.div`
  padding: 10px ${({ theme }) => theme.paddingObject};
  margin-bottom: ${({ theme }) => theme.paddingObject};
  padding-right: 0;
  border-left: 12px solid ${(p: IStyledProps) => p.borderColor || p.theme.colors.primary2};
  border-radius: 3px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;

export default StyledCardItem;
