import styled, { ITheme } from '../../../styled-components';
import { Button, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
}

export const FooterContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CTA = styled(Button)`
  width: 182px;
`;
