import { __, EventTrack, ICountry, userActions, UserExistsCode } from 'common-services';
import { AsYouType, CountryCode } from 'libphonenumber-js/mobile';
import * as React from 'react';

import * as webUserActions from '../../../../../actions/user';
import { getCountriesSelect, ILoginData } from '../../../../../constants';
import { Input, Select } from '../../../../atoms';
import * as CS from '../../2FAModal.styled';
import * as S from './AddPhone.styled';

interface IProps {
  loading: boolean;
  countries: { [key: string]: ICountry };
  goToNextStep: () => void;
  loginData: ILoginData;
  updateLoginData: (name: string, value: string | number) => void;
  userExists: typeof userActions.userExists;
  verifyPhone: typeof webUserActions.verifyPhone;
}

interface IState {
  countriesSelect: Array<{ label: string; value: string }>;
  errors: Map<string, string>;
  textError: string;
}

/**
 * Add user phone if not set previously
 */
export default class AddPhone extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      countriesSelect: getCountriesSelect(props.countries),
      errors: new Map(),
      textError: '',
    };
  }

  public render() {
    const { loading, loginData } = this.props;
    const { textError } = this.state;

    return (
      <CS.StepContainer>
        <form
          id="add_phone"
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit();
          }}
        >
          <CS.Card id="add-phone">
            <CS.Title>{__('TwoFAModal.AddPhone.title')}</CS.Title>
            <CS.TextSubtitle>{__('TwoFAModal.AddPhone.subtitle')}</CS.TextSubtitle>
            <S.InputContainer>{this.renderPhoneInput()}</S.InputContainer>

            {textError ? <CS.TextError>{textError}</CS.TextError> : null}
            <CS.SubmitButton id="next" disabled={loading || !loginData.phoneNumber} loading={loading} type="principal">
              {__('Components.Onboarding.LoginPhoneEmail.cta')}
            </CS.SubmitButton>
          </CS.Card>
        </form>
      </CS.StepContainer>
    );
  }

  /**
   * Render phone input.
   * Read only if user has already logged previously.
   */
  private renderPhoneInput() {
    const { loginData, updateLoginData } = this.props;
    const { countriesSelect, errors, textError } = this.state;
    const { country, phoneNumber, countryCode } = loginData;

    return (
      <S.PhoneInputContainer>
        <Select
          containerMargin="0"
          hasError={!!(errors.get('login_country') || textError)}
          menuWidth="max-content"
          name="country"
          onChange={(name, val) => {
            this.setError(name, val ? '' : 'empty');
            updateLoginData(name, val);
          }}
          options={countriesSelect}
          placeholder={__('Components.Onboarding.LoginPhoneEmail.country_placeholder')}
          value={country}
        />
        <Input
          containerMargin="0 0 0 5px"
          hasError={!!(errors.get('phoneNumber') || textError)}
          isRequired={true}
          name="phoneNumber"
          onChange={(name, value) => {
            this.setError(name, value ? '' : 'empty');
            updateLoginData(name, new AsYouType(countryCode as CountryCode).input(value.toString().replace(/\D/g, '')));
          }}
          placeholder={__('Components.Onboarding.LoginPhoneEmail.phoneNumber_placeholder')}
          type="text"
          value={phoneNumber}
          width="60%"
        />
      </S.PhoneInputContainer>
    );
  }

  /**
   * Set error for a particular field represented by key
   */
  private setError(key: string, error: string) {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  }

  /**
   * Handle on submit click for Login phone
   * Validate form and exit if errors.
   */
  private onSubmit() {
    const { loginData, userExists } = this.props;
    const { errors, textError } = this.validatePhoneLogin(loginData);
    this.setState({ errors, textError });

    const { phoneParsed, countryPrefix } = loginData;
    const phoneWithoutPrefix = phoneParsed?.number.replace(`+${countryPrefix}`, '');
    const phone = phoneParsed?.number.replace('+', '');

    if (phoneWithoutPrefix && errors.size === 0) {
      EventTrack.track('settings-validate-phone', { phone, valid: 'success' });
      userExists({ phone }, (result, code) => {
        if (result === 'error') {
          this.setState({ textError: __('Components.Onboarding.error_generic') });
          return;
        }
        global.localStorage.setItem('phone', loginData.phoneNumber);
        global.localStorage.setItem('country', loginData.country);
        global.localStorage.setItem('mode', 'phone');
        switch (code) {
          case UserExistsCode.UserNotExists:
          case UserExistsCode.UserExistsUnregistered:
            this.goToVerifyCodeStep({
              phoneNumber: phoneWithoutPrefix,
              countryPrefix: Number(loginData.countryPrefix),
            });
            break;
          case UserExistsCode.UserExistsWithoutPassword:
            this.setState({ textError: __('TwoFAModal.AddPhone.error_add_phone') });
            break;
          case UserExistsCode.UserExistsWithPassword:
            this.setState({ textError: __('TwoFAModal.AddPhone.error_existing_phone') });
            break;
          default:
            this.setState({ textError: __('Components.Onboarding.error_generic') });
            return;
        }
      });
    } else {
      EventTrack.track('settings-validate-phone', { phone, valid: 'invalid' });
    }
  }

  /**
   * Set phone number info + go to verify the phone number step
   */
  private goToVerifyCodeStep(phoneData?: { phoneNumber: string; countryPrefix: number }) {
    const { goToNextStep, verifyPhone } = this.props;
    if (phoneData) {
      verifyPhone(phoneData.phoneNumber, Number(phoneData.countryPrefix), false, resultVerify => {
        if (resultVerify === 'error') {
          this.setState({ textError: __('Components.Onboarding.error_generic') });
          return;
        }
        goToNextStep();
      });
    }
  }

  /**
   * Validate the phone login data (fields not empty, phone number validation)
   */
  private validatePhoneLogin(data: ILoginData): { errors: Map<string, string>; textError: string } {
    const errors = new Map();
    let textError = '';
    if (!data.country) {
      errors.set('login_country', 'empty');
      textError = __('Components.Onboarding.LoginPhoneEmail.country_empty');
      return { errors, textError };
    }
    if (!data.phoneNumber) {
      errors.set('phoneNumber', 'empty');
      textError = __('Components.Onboarding.LoginPhoneEmail.phone_empty');
      return { errors, textError };
    }
    if (!data.phoneValid) {
      errors.set('phoneNumber', 'validation');
      textError = __('Components.Onboarding.LoginPhoneEmail.phone_validation');
    }
    return { errors, textError };
  }
}
