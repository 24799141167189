import { __, IContact, ImageGalleryObject, IOrderRequest, IUser, IWorkspace } from 'common-services';
import * as React from 'react';

import TableHeader from '../../atoms/TableHeader/TableHeader.component';
import * as S from './OrderRequestTable.styled';
import OrderTableRow from './OrderRequestTableRow/OrderRequestTableRow.component';

interface IProps {
  catalogs?: { [id: number]: IWorkspace };
  className?: string;
  contacts: { [contactId: number]: IContact };
  hasMore?: boolean;
  me: IUser;
  orderRequestClick: (orderRequest: IOrderRequest) => void;
  orderRequests: Array<IOrderRequest>;
  totalResults: number;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  workspaceSelected?: string;
}

const OrderRequestTable: React.FC<IProps> = ({
  orderRequests,
  workspaceSelected,
  catalogs,
  contacts,
  me,
  orderRequestClick,
  touchImage,
  className = 'order-request-container',
  totalResults,
}) => {
  function renderTable() {
    if (orderRequests.length === 0) return null;

    const columns = [
      {
        title: __('Components.OrdersList.TableDashboard.Company'),
      },
      { title: __('Components.OrdersList.TableDashboard.Phone'), width: '150px' },
      {
        title: __('Components.OrdersList.TableDashboard.Email'),
        width: '150px',
      },
      { title: __('Components.OrdersList.TableDashboard.EmailValidation'), width: '150px' },
      {
        title: getWorkspaceLiteral(),
      },
      { title: __('Components.OrdersList.TableDashboard.Date'), width: '120px' },
      { title: __('Components.OrdersList.TableDashboard.Articles'), width: '80px' },
    ];

    return (
      <S.Table>
        <TableHeader selected={'none'} columns={columns} />
        <S.Body>{orderRequests.map(renderOrderRow)}</S.Body>
      </S.Table>
    );
  }

  function getWorkspaceLiteral() {
    return workspaceSelected
      ? __('Components.OrdersList.TableDashboard.Colleagues')
      : __('Components.OrdersList.TableDashboard.Workspace');
  }

  const renderOrderRow = (orderRequest: IOrderRequest) => {
    return (
      <OrderTableRow
        catalogs={catalogs}
        className="order-request-row"
        contacts={contacts}
        key={orderRequest.id}
        me={me}
        orderRequest={orderRequest}
        orderRequestClick={orderRequestClick}
        touchImage={touchImage}
        workspaceSelected={workspaceSelected}
      />
    );
  };

  return (
    <S.Container className={className}>
      <S.TextSmall>{__('Components.OrdersList.results', { count: totalResults })}</S.TextSmall>
      {renderTable()}
    </S.Container>
  );
};

export default React.memo(OrderRequestTable);
