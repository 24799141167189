import styled, { ITheme } from '../../../styled-components';
import { Button } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const TextArea = styled.textarea`
  background: white;
  flex: 9;
  font-family: inherit;
  justify-content: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  border-radius: 10px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: 8px;
  line-height: 1.2;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingObject};
  &:focus-within {
    outline: none !important;
    border-color: ${(p: IStyledProps) => p.theme.colors.primary};
    box-shadow: 0 0 4px ${(p: IStyledProps) => p.theme.colors.light1};
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.light1};
  box-shadow: none;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0 5px 0 0;
`;

export const AcceptButton = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;
