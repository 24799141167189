import { __, constants, date, HOUR_FORMAT, IAvatarColor, ImageGalleryObject, IMessage, utils } from 'common-services';
import * as React from 'react';
import { IMAGES } from '../../../../../assets';

import { downloadFile } from '../../../../../services/file';
import { ColumnContainer } from '../../../../atoms';
import * as S from './ReplyMessage.styled';

interface IProps {
  className?: string;
  enableSeeAllMessage?: boolean;
  hourFormat: HOUR_FORMAT;
  me: IUser;
  members: Array<IMember>;
  message: IMessage;
  numberOfLines?: number;
  onTouchFile?: (url: string, cb: (data: string) => void) => void;
  onTouchImage?: (images: Array<ImageGalleryObject>, selected: number) => void;
  senderAvatar?: string;
  senderAvatarColor?: IAvatarColor;
  senderName: string;
}

const ReplyMessage: React.FC<IProps> = ({
  className,
  enableSeeAllMessage,
  hourFormat,
  me,
  members,
  message,
  numberOfLines = 3,
  onTouchFile,
  onTouchImage,
  senderAvatar,
  senderAvatarColor,
  senderName,
}) => {
  const [seeAll, setSeeAll] = React.useState(false);
  if (!message) return null;
  const { extraData, messageType } = message;

  return <S.Container className={className}>{renderMessageContent()}</S.Container>;

  /**
   * Render message content (text, image, file) according to its type
   * If text starts with an image link, we print it as an image
   */
  function renderMessageContent() {
    let messageTypeDisplay = messageType;
    let messageMediaUrl = '';
    const splitTexts = utils.splitTextUrls(message.message);
    if (splitTexts.length && splitTexts[0].isLink) {
      const { type, url, thumbnailUrl } = utils.analyzeUrl(splitTexts[0].text);
      if (type === 'image' || type === 'video') {
        messageTypeDisplay = 'image';
        messageMediaUrl = type === 'video' ? thumbnailUrl : url;
      } else if (type === 'other' && utils.isVideoFile(url)) {
        messageTypeDisplay = 'video';
        messageMediaUrl = utils.getThumbnailFromVideoUrl(url);
      }
    }

    switch (messageTypeDisplay) {
      case 'text':
        const maxCharactersLength = enableSeeAllMessage && numberOfLines ? numberOfLines * 95 : Infinity;
        const shouldTruncate = enableSeeAllMessage && message.message.length > maxCharactersLength;
        const messageWithMentions = message.message
          .split(/(@mention{\d+})/g)
          .map((ts, index) => {
            const mention = ts.match(/@mention{(\d+)}/);
            const memberId = mention ? Number(mention[1]) : 0;
            const memberName = memberId === me.id ? me.name : members.find(m => m.id === memberId)?.name;
            return memberName ? ts.replace(/@mention{(\d+)}/, `@${memberName.replace(/ /g, '_')}`) : ts;
          })
          .join('')
          .trimLeft();
        return (
          <S.Row>
            {extraData.type === 'metadata' && extraData.metadata.image_url ? (
              <S.Img src={extraData.metadata.image_url} />
            ) : (
              <S.Avatar
                text={senderName}
                img={senderAvatar || ''}
                avatarColor={senderAvatarColor}
                size={36}
                type="comment"
              />
            )}
            <S.Column>
              <S.WhoWhen>
                <S.TextName>{senderName}</S.TextName>
                <S.DateTimeText>{date.formatTime(message.createdAt, hourFormat)}</S.DateTimeText>
              </S.WhoWhen>
              {message.isRemoved ? (
                <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
              ) : (
                <S.Text oneLine={numberOfLines === 1}>
                  {shouldTruncate && !seeAll
                    ? utils.truncateText(messageWithMentions, maxCharactersLength)
                    : messageWithMentions}
                </S.Text>
              )}
              {shouldTruncate ? (
                <S.TextLink
                  onClick={e => {
                    e.stopPropagation();
                    setSeeAll(!seeAll);
                  }}
                >
                  {seeAll ? __('Constants.view_less') : __('Constants.view_more')}
                </S.TextLink>
              ) : null}
            </S.Column>
          </S.Row>
        );

      case 'image':
      case 'video':
        // force media domain migration
        // *.cloudinary.com is apparently banned in some organization proxies
        const url = messageMediaUrl
          ? messageMediaUrl
          : message.message
              .replace('res.cloudinary.com/consentiotrade', 'media.consentio.co')
              .replace('consentiotrade-res.cloudinary.com', 'media.consentio.co');
        const touchImageEnabled = !!(onTouchImage && !message.isRemoved && !url.endsWith('.gif'));
        return (
          <S.Row>
            <S.Img
              src={message.isRemoved ? IMAGES.unavailable : url}
              clickable={touchImageEnabled}
              onClick={
                touchImageEnabled
                  ? () => {
                      onTouchImage([{ src: url }], 0);
                    }
                  : undefined
              }
            />
            <ColumnContainer>
              <S.WhoWhen>
                <S.TextName>{senderName}</S.TextName>
                <S.DateTimeText>{date.formatTime(message.createdAt, hourFormat)}</S.DateTimeText>
              </S.WhoWhen>
              {message.isRemoved ? (
                <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
              ) : (
                <S.Text>
                  {messageTypeDisplay === 'image' ? __('Components.Chat.image') : __('Components.Chat.video')}
                </S.Text>
              )}
            </ColumnContainer>
          </S.Row>
        );

      case 'file':
        const contentType = extraData.contentType;
        const fileName = extraData.name || __('FileBubble.no_name');
        const downloadFileAction = () =>
          onTouchFile(message.message, data => downloadFile(data, contentType, fileName));
        const mimeTypeLogo: string = message.isRemoved
          ? IMAGES.unavailable
          : constants.getLogoFromMime(message.extraData.contentType);
        return (
          <S.Row
            onClick={e => {
              e.stopPropagation();
              if (onTouchFile && !message.isRemoved) downloadFileAction();
            }}
          >
            {mimeTypeLogo ? <S.MimeTypeLogo src={mimeTypeLogo} /> : <S.Icon name="Document" disableHover={true} />}
            <ColumnContainer>
              <S.WhoWhen>
                <S.TextName>{senderName}</S.TextName>
                <S.DateTimeText>{date.formatTime(message.createdAt, hourFormat)}</S.DateTimeText>
              </S.WhoWhen>
              {message.isRemoved ? (
                <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
              ) : (
                <S.Text>{utils.cropWithExtension(fileName, 30)}</S.Text>
              )}
            </ColumnContainer>
          </S.Row>
        );
      default:
        return null;
    }
  }
};

export default React.memo(ReplyMessage);
