import { AxiosError } from 'axios';
import {
  __,
  buyerWorkspaceActions,
  chatActions,
  constants,
  contactActions,
  debounce,
  EventTrack,
  i18n,
  IContact,
  modalActions,
  notificationsActions,
  ORDER_ACCEPT_TYPE,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
  parsers,
  PRODUCT_UNIT,
  productActions,
  productService,
  qs,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  to,
  userSelectors,
  utils,
} from 'common-services';
import { getCartLoadSummaryText } from 'common-services/dist/order/service';
import { History } from 'history';
import * as React from 'react';
import { Prompt } from 'react-router-dom';

import config from '../../../../bindings/config';
import { navigateChannelBySection } from '../../../actions/nav';
import * as orderLocalActions from '../../../actions/order';
import * as userWebActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import OrderLogistics from '../../../screens/order-logistics';
import { downloadBinaryFile, downloadFile } from '../../../services/file';
import { api } from '../../../store';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { isElementInViewport } from '../../../util/utils';
import { BackButton, Button, ColumnContainer, Input, Ribbon, Select, Tooltip } from '../../atoms';
import SimpleDropdown, { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import { ActionsModal, ContactsList, EmptyListResource, SimpleSearch } from '../../molecules';
import ClientsList from '../../molecules/ClientsList';
import ProductInfo from '../ProductInfo/ProductInfo.component';
import {
  ActionsCard,
  Comments,
  CustomItems,
  ItemsCard,
  LostLines,
  OrderInfo,
  SearchProducts,
  TransportCard,
} from './Fragments';
import PORProduct from './Fragments/PORProduct';
import * as S from './OrderDetails.styled';
import { useSelector } from 'react-redux';

const DAYS_SERVE_ALLOWED = 7;

interface IAddProductModalProps {
  contactId: number;
  deletable: boolean;
  forceSetPrice?: boolean;
  item: IOrderItem;
  priceMode?: IPriceMode;
  saleUnits?: Array<PRODUCT_UNIT>;
  weAreSeller: boolean;
}

export interface StateProps {
  addresses?: Array<IAddress>;
  amEditor?: boolean;
  cart: ICart;
  catalog?: IWorkspace;
  catalogs: { [cId: number]: IWorkspace };
  channelId?: string;
  clientsOld?: Array<IClient>;
  commentsChannel?: IChannel;
  contacts: { [key: number]: IContact };
  countries: { [key: string]: ICountry };
  featured?: { [key: string]: Array<string> };
  lastReadAt: Record<string, number>;
  lastReadContactAt: Record<string, number>;
  me: IUser;
  order?: IOrder;
  origin?: string;
  columnConfig?: Array<IVisibilityConfig>;
  priceGroupIds?: Array<string>;
  priceMode?: IPriceMode;
  prices?: { [key: number]: IPrice };
  prodTypes: Record<string, IProdType>;
  self: boolean;
  suppliersOld?: Array<ISupplier>;
  weAreAuthor: boolean;
  weAreBuyer: boolean;
  weAreSeller: boolean;
  workspaces: { [cId: number]: IWorkspace };
  workspaceSelected: IWorkspace;
}

export interface DispatchProps {
  acceptOrder: typeof orderActions.acceptOrder;
  addMessageReaction: typeof chatActions.addMessageReaction;
  cancelOrder: typeof orderActions.cancelOrder;
  cartClean: typeof orderActions.cartClean;
  cartUpdateCustomItems: typeof orderActions.cartUpdateCustomItems;
  cartCleanIfNeeded: typeof orderActions.cartCleanIfNeeded;
  cartSet: typeof orderActions.cartSet;
  cartUpdateInitialComments: typeof orderActions.cartUpdateInitialComments;
  cartUpdateItem: typeof orderActions.cartUpdateItem;
  clientAddressAdd: typeof sellerWorkspaceActions.clientAddressAdd;
  clientsGet: typeof sellerWorkspaceActions.clientsGet;
  clientUpdate: typeof sellerWorkspaceActions.clientUpdate;
  supplierUpdate: typeof buyerWorkspaceActions.supplierUpdate;
  createNewContact: typeof contactActions.createNewContact;
  getExcelOrder: typeof orderLocalActions.getExcelOrder;
  getMessages: typeof chatActions.getMessages;
  getPrices: typeof productActions.getPrices;
  productAddAttributeValue: typeof productActions.productAddAttributeValue;
  productDefaultAttributeValue: typeof productActions.productDefaultAttributeValue;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelBySection: typeof navigateChannelBySection;
  notificationShow: typeof notificationsActions.notificationShow;
  orderAddressFixIssue: typeof orderActions.orderAddressFixIssue;
  orderAttachmentDownload: typeof orderActions.orderAttachmentDownload;
  orderAttachmentsGet: typeof orderActions.orderAttachmentsGet;
  orderAttachmentUpload: typeof orderActions.orderAttachmentUpload;
  orderChannelGet: typeof orderActions.orderChannelGet;
  orderClone: typeof orderActions.orderClone;
  orderCommentSend: typeof orderActions.orderCommentSend;
  orderCustomPdforExcelDownload: typeof orderActions.orderCustomPdforExcelDownload;
  orderDelete: typeof orderActions.orderDelete;
  orderFakeNew: typeof orderActions.orderFakeNew;
  orderFixIssue: typeof orderActions.orderFixIssue;
  orderGet: typeof orderActions.orderGet;
  orderItemFixIssue: typeof orderActions.orderItemFixIssue;
  orderClientFixIssue: typeof orderActions.orderClientFixIssue;
  orderNew: typeof orderActions.orderNew;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  orderPdfDownload: typeof orderActions.orderPdfDownload;
  orderSendToERP: typeof orderActions.orderSendToERP;
  orderToggleReadStatus: typeof orderActions.toggleOrderReadStatus;
  orderUpdate: typeof orderActions.orderUpdate;
  orderUpdateComments: typeof orderActions.orderUpdateComments;
  orderUpdateCustomItems: typeof orderActions.orderUpdateCustomItems;
  orderUpdateExternal: typeof orderActions.orderUpdateExternal;
  orderUpdateIssues: typeof orderActions.orderUpdateIssues;
  orderItemIgnore: typeof orderActions.orderItemIgnore;
  orderItemRecover: typeof orderActions.orderItemRecover;
  orderItemStopDiscarding: typeof orderActions.orderItemStopDiscarding;
  orderUpdatePrepared: typeof orderActions.orderUpdatePrepared;
  orderUpdateProducts: typeof orderActions.orderUpdateProducts;
  productGet: typeof productActions.productGet;
  productGetByHashPublic: typeof productActions.productGetByHashPublic;
  productShare: typeof productActions.productShare;
  relaunchOrderExport: typeof orderActions.relaunchOrderExport;
  removeMessageReaction: typeof chatActions.removeMessageReaction;
  sendMessage: typeof chatActions.sendMessage;
  setCommentsRead: typeof orderActions.setCommentsRead;
  tableVisibilityConfigGet: typeof sellerWorkspaceActions.tableVisibilityConfigGet;
  orderDateFixIssue: typeof orderActions.orderDateFixIssue;
  suppliersGet: typeof buyerWorkspaceActions.suppliersGet;
  supportAction: typeof userWebActions.supportAction;
  touchFile: typeof chatActions.downloadFile;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressAdd: typeof buyerWorkspaceActions.workspaceAddressAdd;
}

export interface HeritageProps {
  amSeller: boolean;
  backLink: string;
  channelId?: string;
  channelMembers?: Array<IMember>;
  closeCart: () => void;
  contact?: IContact;
  editAfterAccept?: boolean;
  goBack?: () => void;
  history: History<any>;
  isPrepare?: boolean;
  location: any;
  match: any;
  name?: string;
  navCloneAction: () => void;
  navShowroom: () => void;
  notMarkAsRead?: boolean;
  openedFrom?: 'other' | 'purchases' | 'sales';
  orderId: number;
  hideBack?: boolean;
  previewMode?: boolean;
  breadcrumb?: {
    parentSections: Array<IBreadcrumb>;
    title: string;
  };
  updateBreadcrumb?: (newCtx: { parentSections: Array<IBreadcrumb>; title: string }) => void;
}

export type IProps = StateProps & DispatchProps & HeritageProps;

interface IState {
  addProductModalProps?: IAddProductModalProps & { product: GenericProduct };
  commentsRibbon?: string;
  editAfterAccept: boolean;
  isFavoriteCheck: boolean;
  itemWithIssue?: { item: IOrderItem; originLot?: number; mappingCode?: string; productsMapped?: Array<IProduct> };
  lastMessageAt?: number;
  logisticAddresses: Array<IAddress>;
  newExternalId?: string;
  pdfFormats: Array<IOrderDownloadOptions>;
  pickupAddresses: Array<IAddress>;
  previousOrderState?: IOrder;
  showClientsModal?: boolean;
  showEditExternal?: boolean;
  showLogistic?: boolean;
  showPORProduct?: boolean;
  showProductPage?: boolean;
  showTooltip?: boolean;
  updatesRibbon?: string;
  originLot?: number;
  onlyApplyToCurrentOrder?: boolean;
  showClientViewModal?: boolean;
  showIssuesModal?: boolean;
  showBoxesPerPalletModal?: boolean;
  selectedItem?: IOrderItem;
  searchState: ISearch;
  productAttributeValue?: any;
  showNewMbppValueInput?: boolean;
  newMbppValue?: string | number;
  productList?: Array<IProduct>;
  discardForever?: boolean;
}

interface IPropsPureComponent extends IProps {
  togglePriceMismatchEnable: boolean;
}

class OrderDetailsPureComponent extends React.PureComponent<IPropsPureComponent, IState> {
  private onBodyScroll = debounce(event => {
    const { commentsChannel, order, me, setCommentsRead } = this.props;
    if (commentsChannel && isElementInViewport('cart-comments')) {
      setCommentsRead(me.id, order.id);
    }
  }, 50);

  constructor(props: IPropsPureComponent) {
    super(props);
    const text = (qs.parse(window.location.search, ['query']) as { query: string })?.query || '';
    this.state = {
      commentsRibbon: '',
      discardForever: false,
      editAfterAccept: props.editAfterAccept,
      previousOrderState: props.editAfterAccept ? { ...props.order, items: [...props.order.items] } : undefined,
      isFavoriteCheck: true,
      onlyApplyToCurrentOrder: false,
      logisticAddresses: [],
      pickupAddresses: [],
      updatesRibbon: '',
      pdfFormats: [],
      productList: [],
      showNewMbppValueInput: false,
      showEditExternal: !props.orderId,
      searchState: {
        text,
        language: props.me.settings.language as LOCALE,
        index: props.catalog ? props.catalog.hashId : props.me.sellerWorkspaceHashId,
        status: [],
        sort: text ? '' : `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: 'asc',
      },
    };
  }

  public componentDidMount() {
    const { catalog, order, orderId, me, orderGet } = this.props;
    if (orderId && !order?.changedItems && !order?.changedCustomItems && !order?.changedLogistics) {
      orderGet(me.id!, orderId);
    }
    if (!order) return;
    this.initOrderDetails();
  }

  public componentDidUpdate(pp: IProps, prevState: IState) {
    const {
      amSeller,
      catalog,
      cart,
      cartSet,
      contact,
      me,
      order,
      orderChannelGet,
      notificationShow,
      productGetByHashPublic,
      weAreBuyer,
      weAreSeller,
    } = this.props;
    const { searchState } = this.state;
    if (contact !== pp.contact || (order && (!pp.order || order.id !== pp.order.id))) this.initOrderDetails();
    if (contact && order && pp.order && pp.order.id !== order.id) {
      cartSet(
        productService.getKey(weAreSeller ? me.id : order.sellerId, weAreBuyer ? me.id : order.buyerId),
        order,
        contact.id,
      );
    }
    if (!pp.order?.commentsChannel && order?.commentsChannel) {
      orderChannelGet(me.id!, order.commentsChannel);
    }
    if (
      pp.order &&
      order &&
      ((pp.order.exportErrorSeller && !order.exportErrorSeller) ||
        (pp.order.exportErrorBuyer && !order.exportErrorBuyer))
    ) {
      notificationShow(
        {
          style: 'info',
          title: __('Components.OrderDetails.export_notification.title'),
          subtitle: __('Components.OrderDetails.export_notification.subtitle'),
          closable: true,
        },
        10000,
      );
    }
    // TODO this sometimes doesnt load the products, we need to check why
    if (
      order &&
      pp.order !== order &&
      order.status === ORDER_STATUS.INCOMPLETE &&
      catalog?.mandatoryPalletizationByClient &&
      order.issues.some(o => o.type === 'multi-boxes-per-pallet-without-buyer')
    ) {
      const items = cart.items.filter(
        i =>
          order.servedFlowEnabled ||
          i.amount ||
          order.origin === ORDER_ORIGIN.IMPORT_UI ||
          order.origin === ORDER_ORIGIN.EMAIL,
      );
      const productHashIds = items.filter(item => item.productHashId.length > 1).map(item => item.productHashId);
      const productList = [];
      productHashIds.forEach(hashId => {
        if (hashId) {
          productGetByHashPublic(hashId, product => {
            productList.push(product);
            this.setState({ productList });
          });
        }
      });
    }
  }

  /**
   * Clean cart when order has id.
   * Remove order from redux when discarding a clone / new order.
   */
  public componentWillUnmount(): void {
    const { cartClean, order, orderDelete, previewMode, weAreBuyer, me } = this.props;
    if (!order || previewMode) return;
    if (order.id) {
      cartClean(productService.getKey(weAreBuyer ? order.sellerId : me.id, order.buyerId));
    } else {
      orderDelete(order);
    }
  }

  public render() {
    const {
      addresses,
      acceptOrder,
      addMessageReaction,
      amEditor,
      backLink,
      cancelOrder,
      cart,
      cartClean,
      cartSet,
      catalog,
      catalogs,
      channelMembers,
      clientAddressAdd,
      clientsOld,
      closeCart,
      columnConfig,
      commentsChannel,
      contact,
      contacts,
      countries,
      getMessages,
      goBack,
      hideBack,
      history,
      isPrepare,
      lastReadAt,
      lastReadContactAt,
      location,
      match,
      me,
      modalClose,
      modalOpen,
      name,
      navCloneAction,
      navShowroom,
      notificationShow,
      openedFrom,
      order,
      orderAddressFixIssue,
      orderAttachmentDownload,
      orderAttachmentsGet,
      orderAttachmentUpload,
      orderCommentSend,
      orderDateFixIssue,
      orderClientFixIssue,
      orderNew,
      orderSendToERP,
      orderUpdateExternal,
      orderUpdateIssues,
      orderUpdatePrepared,
      orderItemRecover,
      orderItemStopDiscarding,
      previewMode,
      priceMode,
      prices,
      prodTypes,
      productDefaultAttributeValue,
      productAddAttributeValue,
      removeMessageReaction,
      self,
      suppliersOld,
      supportAction,
      tableVisibilityConfigGet,
      touchFile,
      touchImage,
      productGetByHashPublic,
      weAreAuthor,
      weAreBuyer,
      weAreSeller,
      workspaceAddressAdd,
      workspaces,
      workspaceSelected,
    } = this.props;
    const {
      editAfterAccept,
      isFavoriteCheck,
      logisticAddresses,
      pickupAddresses,
      showClientsModal,
      showEditExternal,
      newExternalId,
      productAttributeValue,
      showTooltip,
      showClientViewModal,
      showIssuesModal,
      showBoxesPerPalletModal,
      selectedItem,
    } = this.state;
    if (!order) return null;
    const items = cart.items.filter(
      i =>
        order.servedFlowEnabled ||
        i.amount ||
        order.origin === ORDER_ORIGIN.IMPORT_UI ||
        order.origin === ORDER_ORIGIN.EMAIL,
    );
    const hasItems = !!items.length;
    const pricesEmpty = items.filter(i => !i.price);
    const { buyer, seller } = orderService.getSellerBuyer(order, contacts, me, clientsOld || [], suppliersOld || []);
    const sellerWorkspace = catalogs[order.catalogId];
    const mySellerRole = weAreSeller ? sellerWorkspaceService.getRole(sellerWorkspace, me.id) : undefined;
    const hourFormat = me.settings.hourFormat;
    const issues = order.issues;
    const inactiveCatalog = weAreSeller
      ? sellerWorkspaceService.getMember(sellerWorkspace, me.id)?.status !== 'active'
      : false; // As a buyer we cannot know all the seller's workspaces
    const contactId = weAreSeller ? order.buyerId : order.sellerId;
    const client = clientsOld?.find(c => c.userId === contactId);
    const servedFlowEnabled = order.status === ORDER_STATUS.ACCEPTED && order.servedFlowEnabled;
    const canServeOrder = this.canServeOrder(servedFlowEnabled, weAreSeller);
    const canEditDraft = weAreSeller && order.status === ORDER_STATUS.DRAFT;
    const orderEditable = order.status === 'pending' || canServeOrder || canEditDraft;
    const showRibbon =
      (mySellerRole && mySellerRole !== 'viewer' && order.exportErrorSeller) || (weAreBuyer && order.exportErrorBuyer);
    const customItems = cart.customItems?.filter(ci => ci.type === 'custom') || [];
    const downloadKeys = this.getDownloadFormats();
    const totals = commentsChannel?.extraData?.totals;
    const unreads = commentsChannel?.extraData?.unreads;
    const totalComments = totals?.comments || 0;
    const totalUpdates = totals?.updates || 0;
    const totalAttachments = totals?.attachments
      ? Object.values(totals?.attachments).reduce((acc: number, a: number) => acc + a, 0)
      : 0;
    const membersAsClient = channelMembers?.filter(m => !catalog.members.find(c => c.userId === m.id));
    const showSendToERPRibbon =
      catalog?.hasIntegrationExport &&
      catalog?.manuallyPushOrdersToERP &&
      weAreSeller &&
      [ORDER_STATUS.ACCEPTED].includes(order.status) &&
      [ORDER_ORIGIN.INTERNAL, ORDER_ORIGIN.IMPORT_UI, ORDER_ORIGIN.EMAIL].includes(order.origin) &&
      !order.sentToERP &&
      !order.issues?.length;

    const orderHasChanges =
      order.hasChanges || order.changedLogistics || order.changedItems || order.changedCustomItems;
    const isPro = sellerWorkspaceService.isProPlan(catalog?.plan);
    const selectedIssue = selectedItem ? issues?.find(i => i.orderItemId === selectedItem.id) : undefined;
    const selectedItemName = productService.getProductTypeVarietyDisplay(
      selectedItem?.type,
      prodTypes[selectedItem?.type]?.name,
      selectedItem?.title,
    );
    const addOption = {
      value: 'add_option',
      label: __('Components.Cart.boxesPerPallet.add_option'),
    };

    const mbppDropdownOptions = this.state.productList
      ?.find(p => p.id === selectedItem?.childId)
      ?.boxesPerPalletValues?.map((v: any) => ({
        value: v.value.toString(),
        label: v.value.toString(),
      }));
    mbppDropdownOptions?.push(addOption);
    return (
      <S.Container id="cart-container">
        <Prompt
          when={order.status === ORDER_STATUS.DRAFT && !!orderHasChanges}
          message={__('Components.ProductDetails.confirm_exit_changes')}
        />
        {hideBack ? null : (
          <S.BackContainer>
            <BackButton onClick={goBack || closeCart} text={backLink} />
            <S.ActionsContainer>
              {downloadKeys.length && !editAfterAccept && !previewMode ? (
                <SimpleDropdown options={downloadKeys} hAlign="left" margin="0">
                  <S.DropdownContent>
                    <S.DownloadWrapper>
                      <S.DownloadIcon name="Download" />
                    </S.DownloadWrapper>
                    <S.DropdownText>{__('Components.Cart.download_dropdown_test')}</S.DropdownText>
                    <S.VerticalLine />
                    <S.DownIcon name="Down" />
                  </S.DropdownContent>
                </SimpleDropdown>
              ) : null}
              {amEditor && hasItems ? (
                <ActionsCard
                  acceptOrder={acceptOrder}
                  anyPriceEmpty={!!pricesEmpty.length}
                  askForAutoAccept={this.askForAutoAccept}
                  buyerName={buyer.name}
                  cancelOrder={cancelOrder}
                  cart={cart}
                  cartClean={(notUpdate?: boolean) =>
                    cartClean(productService.getKey(weAreBuyer ? order.sellerId : me.id, order.buyerId), notUpdate)
                  }
                  cloneOrder={this.cloneOrder}
                  closeCart={closeCart}
                  contactId={contactId}
                  contactName={name || contact?.name || client?.name}
                  editAfterAccept={editAfterAccept}
                  hasIssues={!!issues?.filter(i => i.status === 'pending').length}
                  isBlocked={contact?.imBlocked || contact?.imBlocking || client?.blockedOrders}
                  isFavoriteCheck={isFavoriteCheck}
                  isPro={isPro}
                  me={me}
                  modalClose={modalClose}
                  modalOpen={modalOpen}
                  navCloneAction={navCloneAction}
                  navigateToOrders={weAreSeller ? this.navigateToOrders : undefined}
                  notificationShow={notificationShow}
                  openedFrom={openedFrom || 'other'}
                  order={editAfterAccept ? { ...order, changedItems: true } : order}
                  orderNew={orderNew}
                  orderSendToERP={orderSendToERP}
                  orderUpdate={this.orderUpdate}
                  previousComments={cart.initialComments}
                  resetOrder={this.resetOrder}
                  self={self}
                  sellerName={seller.name}
                  sendOrderManuallyToERP={catalog?.hasIntegrationExport && catalog?.manuallyPushOrdersToERP}
                  setShowTooltip={show => this.setState({ showTooltip: show })}
                  weAreSeller={weAreSeller}
                />
              ) : null}
            </S.ActionsContainer>
          </S.BackContainer>
        )}
        {showRibbon ? this.renderRibbon() : null}
        {(issues && issues.length) || (pricesEmpty.length && weAreSeller && hasItems)
          ? this.renderIssuesHeader(issues, pricesEmpty)
          : null}
        {showSendToERPRibbon ? (
          <Ribbon
            type="info"
            text={
              <ColumnContainer>
                <S.Text>
                  {
                    utils.formatText(__('Components.Cart.errors.send_manual_erp'), (text, i) => (
                      <S.TextLinkInline
                        onClick={() =>
                          history.push(
                            getPath({
                              path: ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER,
                              workspaceId: catalog.id + '',
                              tab: 'order',
                            }),
                          )
                        }
                        key={text + i}
                      >
                        {text}
                      </S.TextLinkInline>
                    )) as any
                  }
                </S.Text>
              </ColumnContainer>
            }
          />
        ) : null}
        {unreads?.unread_updates ? this.renderUpdatesRibbon(unreads.unread_updates) : null}
        {unreads?.unread_comments ? this.renderCommentsRibbon(unreads.unread_comments) : null}
        {inactiveCatalog && hasItems ? this.renderInactiveCatalog() : null}
        <S.Row>
          <S.ScrollRow onScroll={this.onBodyScroll}>
            <S.Body>
              <S.General>
                <S.TitleRow>
                  <S.AlignLeft>
                    <S.Title>{order.id ? __('Components.Cart.title_order') : __('Components.Cart.title_new')}</S.Title>
                    <S.TextRef>
                      {__('Components.Cart.ref', {
                        hashId: showEditExternal
                          ? ''
                          : order.externalIdSeller ||
                            order.externalIdBuyer ||
                            cart.externalIdSeller ||
                            cart.externalIdBuyer ||
                            '#' + order.hashId,
                      })}
                    </S.TextRef>
                    {weAreSeller ? (
                      showEditExternal ? (
                        <>
                          {this.renderTooltipRefError(
                            <S.ExternalInput
                              hasError={showTooltip}
                              placeholder={
                                order.externalIdSeller || order.externalIdBuyer || order.hashId
                                  ? '#' + order.hashId
                                  : '#ABCDEFGHIJ'
                              }
                              value={
                                newExternalId || (weAreSeller ? order.externalIdSeller : order.externalIdBuyer) || ''
                              }
                              onChange={(k, v) => {
                                this.setState({ showTooltip: false });
                              }}
                              onBlur={(k, v) => {
                                this.setState({ newExternalId: v as string, showTooltip: false });
                              }}
                            />,
                          )}

                          <S.AddButton
                            name="Checkmark"
                            enabled={!!order?.hashId || !!newExternalId}
                            onClick={() =>
                              order?.hashId || newExternalId
                                ? setTimeout(() => {
                                    if (order.id) {
                                      orderUpdateExternal(me.id, order.id, newExternalId, (err: AxiosError) => {
                                        if (!err) {
                                          if (weAreSeller)
                                            // This is a hack, we modify unmutable object but it's ok
                                            order.externalIdSeller = newExternalId;
                                          else order.externalIdBuyer = newExternalId;
                                          this.setState({ showEditExternal: false, newExternalId: '' });
                                          notificationShow(
                                            {
                                              style: 'success',
                                              title: __('Components.OrderDetails.update_hash'),
                                              subtitle: '',
                                              closable: true,
                                            },
                                            5000,
                                          );
                                        } else {
                                          this.setState({ showTooltip: true });
                                        }
                                      });
                                    } else {
                                      cartSet(
                                        productService.getKey(
                                          weAreSeller ? me.id : order.sellerId,
                                          weAreBuyer ? me.id : order.buyerId,
                                        ),
                                        {
                                          ...order,
                                          externalIdSeller: weAreSeller ? newExternalId : order.externalIdSeller,
                                          externalIdBuyer: weAreSeller ? order.externalIdBuyer : newExternalId,
                                        },
                                        weAreBuyer ? order.sellerId : order.buyerId,
                                      );
                                      this.setState({ showEditExternal: false, newExternalId: '' });
                                      notificationShow(
                                        {
                                          style: 'success',
                                          title: __('Components.OrderDetails.update_hash'),
                                          subtitle: '',
                                          closable: true,
                                        },
                                        5000,
                                      );
                                    }
                                  })
                                : null
                            }
                          />

                          {order?.hashId || newExternalId ? (
                            <S.CloseButton
                              name="Close"
                              onClick={() => {
                                this.setState({ showEditExternal: false });
                              }}
                            />
                          ) : null}
                        </>
                      ) : (
                        <S.EditLink
                          name="Edit"
                          id="edit_transport_button"
                          onClick={() => this.setState({ showEditExternal: true })}
                        />
                      )
                    ) : null}
                  </S.AlignLeft>
                  {hideBack ? (
                    <S.ActionsContainer>
                      {downloadKeys.length && !editAfterAccept && !previewMode ? (
                        <SimpleDropdown options={downloadKeys} hAlign="left" margin="0">
                          <S.DropdownContent>
                            <S.DownloadWrapper>
                              <S.DownloadIcon name="Download" />
                            </S.DownloadWrapper>
                            <S.DropdownText>{__('Components.Cart.download_dropdown_test')}</S.DropdownText>
                            <S.VerticalLine />
                            <S.DownIcon name="Down" />
                          </S.DropdownContent>
                        </SimpleDropdown>
                      ) : null}
                      {amEditor && hasItems && !previewMode ? (
                        <>
                          <ActionsCard
                            acceptOrder={acceptOrder}
                            anyPriceEmpty={!!pricesEmpty.length}
                            askForAutoAccept={this.askForAutoAccept}
                            buyerName={buyer.name}
                            cancelOrder={cancelOrder}
                            cart={cart}
                            cartClean={(notUpdate?: boolean) =>
                              cartClean(
                                productService.getKey(weAreBuyer ? order.sellerId : me.id, order.buyerId),
                                notUpdate,
                              )
                            }
                            cloneOrder={this.cloneOrder}
                            closeCart={closeCart}
                            contactId={contactId}
                            contactName={name || contact?.name || client?.name}
                            editAfterAccept={editAfterAccept}
                            hasIssues={!!issues?.filter(i => i.status === 'pending').length}
                            isBlocked={contact?.imBlocked || contact?.imBlocking || client?.blockedOrders}
                            isFavoriteCheck={isFavoriteCheck}
                            isPro={isPro}
                            me={me}
                            modalClose={modalClose}
                            modalOpen={modalOpen}
                            navCloneAction={navCloneAction}
                            navigateToOrders={weAreSeller ? this.navigateToOrders : undefined}
                            notificationShow={notificationShow}
                            openedFrom={openedFrom || 'other'}
                            order={editAfterAccept ? { ...order, changedItems: true } : order}
                            orderSendToERP={orderSendToERP}
                            orderNew={orderNew}
                            orderUpdate={this.orderUpdate}
                            previousComments={cart.initialComments}
                            resetOrder={this.resetOrder}
                            self={self}
                            sellerName={seller.name}
                            sendOrderManuallyToERP={catalog?.hasIntegrationExport && catalog?.manuallyPushOrdersToERP}
                            setShowTooltip={show => this.setState({ showTooltip: show })}
                            weAreSeller={weAreSeller}
                          />
                          {weAreSeller && order.status !== ORDER_STATUS.INCOMPLETE ? (
                            <S.ClientsView
                              id="preview_order_button"
                              type="link"
                              iconType="background"
                              iconName="Info"
                              withoutPadding={true}
                              onClick={() => {
                                this.setState({ showClientViewModal: true });
                              }}
                              iconSize={theme.fontSize.big}
                            >
                              {order.id && order.status !== ORDER_STATUS.DRAFT
                                ? __('Components.Cart.preview')
                                : __('Components.Cart.preview_unsend')}
                            </S.ClientsView>
                          ) : null}
                        </>
                      ) : null}
                    </S.ActionsContainer>
                  ) : weAreSeller && !previewMode ? (
                    <Button
                      id="preview_button"
                      type="link"
                      iconType="background"
                      iconName="Info"
                      withoutPadding={true}
                      onClick={() => {
                        this.setState({ showClientViewModal: true });
                      }}
                      iconSize={theme.fontSize.big}
                    >
                      {order.id && order.status !== ORDER_STATUS.DRAFT
                        ? __('Components.Cart.preview')
                        : __('Components.Cart.preview_unsend')}
                    </Button>
                  ) : null}
                </S.TitleRow>
                <S.InfoRow>
                  <S.Column>
                    <S.SectionTitle>{__('Components.Cart.info')}</S.SectionTitle>
                    <S.CardItem>
                      <OrderInfo
                        buyerName={buyer.name}
                        companyName={buyer.companyName}
                        hourFormat={hourFormat}
                        membersAsClient={membersAsClient}
                        myId={me.id!}
                        navigateToComments={this.navigateToComments}
                        noClientIssue={issues.find(i => i.type === 'client-code-not-found')}
                        numComments={totalComments + totalAttachments}
                        numUpdates={totalUpdates}
                        order={order}
                        orderDateFixIssue={orderDateFixIssue}
                        previewMode={previewMode}
                        sellerName={seller.name}
                        servedFlowEnabled={servedFlowEnabled}
                        setShowClientsModal={s => this.setState({ showClientsModal: s })}
                        weAreAuthor={weAreAuthor}
                        weAreSeller={weAreSeller}
                      />
                    </S.CardItem>
                  </S.Column>
                  <TransportCard
                    addDeliveryAddress={(addr, cb) => {
                      clientAddressAdd(me.id, order.catalogId, contactId, addr, async (err, address) => {
                        await api.user
                          .getAddressesBySellerBuyer(
                            order.buyerId,
                            order.sellerId,
                            order.catalogId,
                            order.buyerWorkspaceId,
                          )
                          .then(resp => {
                            this.setState({
                              logisticAddresses: resp.shippingAddresses,
                              pickupAddresses: resp.pickupAddresses,
                            });
                          });
                        this.initOrderDetails();
                        cb(address);
                      });
                    }}
                    addPickupAddress={(addr, cb) => {
                      workspaceAddressAdd(me.id, order.catalogId, addr, async (err, address) => {
                        await api.user
                          .getAddressesBySellerBuyer(
                            order.buyerId,
                            order.sellerId,
                            order.catalogId,
                            order.buyerWorkspaceId,
                          )
                          .then(resp => {
                            this.setState({
                              logisticAddresses: resp.shippingAddresses,
                              pickupAddresses: resp.pickupAddresses,
                            });
                          });
                        this.initOrderDetails();
                        cb(address);
                      });
                    }}
                    amEditor={amEditor}
                    amViewer={mySellerRole === 'viewer'}
                    contact={contact}
                    countries={countries}
                    currency={prices && Object.values(prices).length && Object.values(prices)[0].currency}
                    deliveryAddressIssue={issues.find(err =>
                      ['address-not-found', 'address-code-not-found'].includes(err.type),
                    )}
                    hasItems={hasItems}
                    history={history}
                    logisticAddresses={logisticAddresses}
                    me={me}
                    order={order}
                    orderAddressFixIssue={orderAddressFixIssue}
                    orderUpdateIssues={orderUpdateIssues}
                    orderDateFixIssue={orderDateFixIssue}
                    pickupAddresses={pickupAddresses}
                    pickupAddressIssue={issues.find(err =>
                      ['pickup-not-found', 'pickup-code-not-found'].includes(err.type),
                    )}
                    showLogistic={this.showLogistic}
                    weightUnit={catalog?.defaultWeightUnit}
                  />
                </S.InfoRow>
                {hasItems ? (
                  <S.CardItem>
                    <ItemsCard
                      productGetByHashPublic={productGetByHashPublic}
                      addresses={addresses}
                      amEditor={amEditor}
                      amSeller={weAreSeller}
                      anyPriceEmpty={!!pricesEmpty.length}
                      canServeOrder={canServeOrder}
                      cartUpdateItem={this.cartUpdateItem}
                      cartSet={cartSet}
                      catalog={weAreSeller ? sellerWorkspace : workspaces[order.buyerWorkspaceId]}
                      catalogs={catalogs}
                      columnConfig={columnConfig}
                      contactId={contactId}
                      contacts={contacts}
                      countries={countries}
                      deletable={!order?.id || items.length > 1}
                      editAfterAccept={editAfterAccept}
                      hideTotal={!!customItems.length}
                      history={history}
                      getCartLoadSummaryText={getCartLoadSummaryText}
                      inactiveCatalog={inactiveCatalog}
                      isFavoriteCheck={isFavoriteCheck}
                      isPrepare={isPrepare}
                      issues={issues}
                      openIssuesModal={(orderItem: IOrderItem) =>
                        this.setState({ selectedItem: orderItem, showIssuesModal: true })
                      }
                      openBoxesPerPalletModal={(orderItem: IOrderItem) =>
                        this.setState({ selectedItem: orderItem, showBoxesPerPalletModal: true })
                      }
                      openBppConfirmationModal={this.showBoxesPerPalletModal}
                      items={items}
                      location={location}
                      me={me}
                      navigateToProductInfo={this.navigateToProductInfo}
                      navigateToShowroom={this.navShowroom}
                      notificationShow={notificationShow}
                      onEnableItem={this.onEnableItem}
                      onModifyAfterAccept={this.modifyAfterAccept}
                      order={order}
                      orderDiscardLine={this.onDiscardLine}
                      orderEditable={orderEditable}
                      orderUpdatePrepared={orderUpdatePrepared}
                      orderItemRecover={orderItemRecover}
                      orderItemStopDiscarding={orderItemStopDiscarding}
                      productAddAttributeValue={productAddAttributeValue}
                      previewMode={previewMode}
                      priceMode={priceMode}
                      pricePrecision={this.getPricePrecision()}
                      prodTypes={prodTypes}
                      self={self}
                      servedFlowEnabled={servedFlowEnabled}
                      setIsFavoriteCheck={checked => this.setState({ isFavoriteCheck: checked })}
                      showCustomColumns={true}
                      showPORProductModal={this.showPORProductModal}
                      showSearchProductModal={this.showSearchProductModal}
                      tableVisibilityConfigGet={tableVisibilityConfigGet}
                      touchImage={touchImage}
                      modalOpen={modalOpen}
                      modalClose={modalClose}
                      weAreSeller={weAreSeller}
                      workspaceSelected={workspaceSelected}
                    />
                  </S.CardItem>
                ) : (
                  <S.CenterContainer>
                    <EmptyListResource
                      text={__('Components.OrderDetails.empty_cart.title')}
                      showButton={true}
                      buttonAction={() => navShowroom()}
                      buttonText={__('Components.OrderDetails.empty_cart.cta')}
                      imageUrl={IMAGES.emptyBox}
                    />
                  </S.CenterContainer>
                )}
                {hasItems ? this.renderCustomItems(customItems, amEditor, weAreSeller, orderEditable) : null}
              </S.General>
              {hasItems && !previewMode ? (
                <S.Comments id="comments">
                  {amEditor ? <S.SectionTitleComments>{__('Components.Cart.comments')}</S.SectionTitleComments> : null}
                  <Comments
                    addMessageReaction={addMessageReaction}
                    amSeller={weAreSeller}
                    history={history}
                    removeMessageReaction={removeMessageReaction}
                    catalog={catalog}
                    clients={clientsOld}
                    commentsChannel={commentsChannel}
                    commentsUnreadCount={unreads?.unread_comments}
                    commentsId="cart-comments"
                    contactId={contactId}
                    initialComments={cart.initialComments}
                    isContactBlocked={contact?.imBlocked || contact?.imBlocking || client?.blockedOrders}
                    isUnregistered={contact?.isUnregistered}
                    contacts={contacts}
                    getMessages={getMessages}
                    supportAction={supportAction}
                    me={me}
                    modalOpen={modalOpen}
                    showRibbon={(commentsRibbon: string, updatesRibbon: string) =>
                      this.setState({ commentsRibbon, updatesRibbon })
                    }
                    onLastMessageAt={this.setOnLastMessageAt}
                    order={order}
                    orderAttachmentDownload={orderAttachmentDownload}
                    orderOriginalFileDownload={this.downloadOriginalFile}
                    orderAttachmentsGet={orderAttachmentsGet}
                    orderAttachmentUpload={orderAttachmentUpload}
                    orderCommentSend={orderCommentSend}
                    orderPreviousCommentAdd={this.addPreviousComment}
                    prodTypes={prodTypes}
                    touchFile={touchFile}
                    touchImage={touchImage}
                    updatesUnreadCount={unreads?.unread_updates}
                    lastReadAt={lastReadAt[commentsChannel?.id]}
                    lastReadContactAt={lastReadContactAt[commentsChannel?.id]}
                  />
                </S.Comments>
              ) : null}
            </S.Body>
          </S.ScrollRow>
          {this.state.showLogistic ? (
            <S.RightCard>
              <OrderLogistics
                contact={contact}
                match={match}
                navChannelAction={this.hideLogistic}
                navOrderAction={this.hideLogistic}
                orderId={order.id}
                weAreSeller={weAreSeller}
              />
            </S.RightCard>
          ) : null}
        </S.Row>
        {this.renderProductInfo()}
        {this.renderSearchProductModal()}
        {this.renderPORProductModal()}
        {showClientsModal ? (
          <SearchClientsModal
            members={membersAsClient}
            clients={clientsOld}
            hide={() => this.setState({ showClientsModal: false })}
            contacts={contacts}
            order={order}
            orderUpdateIssues={cId => {
              const issue = issues.find(i => i.type === 'client-code-not-found');
              orderUpdateIssues({ ...issue, status: 'solved' }, order.id!, me.id!, o =>
                orderClientFixIssue(me.id!, o.id, cId, issue.id),
              );
            }}
            orderUpdate={this.orderUpdate}
          />
        ) : null}
        {showClientViewModal ? (
          <ActionsModal
            minHeight="450px"
            onClose={() => this.setState({ showClientViewModal: false })}
            title={order.id ? __('Components.Cart.client_view_title_draft') : __('Components.Cart.client_view_title')}
            width="90%"
          >
            <OrderDetails
              {...this.props}
              weAreSeller={false}
              amEditor={true}
              priceMode={order.priceMode}
              previewMode={true}
              hideBack={true}
              acceptOrder={() => null}
              addMessageReaction={() => null}
              cancelOrder={() => null}
              cartClean={() => null}
              cartUpdateCustomItems={() => null}
              cartCleanIfNeeded={() => null}
              cartSet={() => null}
              cartUpdateInitialComments={() => null}
              cartUpdateItem={() => null}
              clientAddressAdd={() => null}
              clientsGet={() => null}
              createNewContact={() => null}
              getExcelOrder={() => null}
              getMessages={() => null}
              getPrices={() => null}
              modalClose={() => null}
              modalOpen={() => null}
              navigateChannelBySection={() => null}
              notificationShow={() => null}
              orderAddressFixIssue={() => null}
              orderAttachmentDownload={() => null}
              orderAttachmentsGet={() => null}
              orderAttachmentUpload={() => null}
              orderChannelGet={() => null}
              orderClone={() => null}
              orderCommentSend={() => null}
              orderCustomPdforExcelDownload={() => null}
              orderFakeNew={() => null}
              orderFixIssue={() => null}
              orderGet={() => null}
              orderItemFixIssue={() => null}
              orderNew={() => null}
              orderOriginalFileDownload={() => null}
              orderPdfDownload={() => null}
              orderSendToERP={() => null}
              orderToggleReadStatus={() => null}
              orderUpdate={() => null}
              orderUpdateComments={() => null}
              orderUpdateCustomItems={() => null}
              orderUpdateExternal={() => null}
              orderUpdateIssues={() => null}
              orderUpdatePrepared={() => null}
              orderUpdateProducts={() => null}
              productGet={() => null}
              productShare={() => null}
              relaunchOrderExport={() => null}
              removeMessageReaction={() => null}
              sendMessage={() => null}
              setCommentsRead={() => null}
              tableVisibilityConfigGet={() => null}
              orderDateFixIssue={() => null}
              suppliersGet={() => null}
              touchFile={() => null}
              touchImage={() => null}
              workspaceAddressAdd={() => null}
              closeCart={() => null}
              goBack={() => null}
              navCloneAction={() => null}
              navShowroom={() => null}
              updateBreadcrumb={() => null}
            />
          </ActionsModal>
        ) : null}
        {showIssuesModal ? (
          <ActionsModal
            minHeight="300px"
            width="500px"
            onClose={() =>
              this.setState({ showIssuesModal: false, selectedItem: undefined, showNewMbppValueInput: false })
            }
            title={__('Components.OrderDetails.issues_modal.title', { product: selectedItemName })}
            subtitle={__('Components.OrderDetails.issues_modal.text')}
            overFlowVisible={true}
          >
            <S.InputTitle>
              {selectedIssue?.type === 'no-box-weight'
                ? __('Components.OrderDetails.issues_modal.weight_per_box')
                : selectedIssue?.type === 'no-pieces-per-box'
                ? __('Components.OrderDetails.issues_modal.units_per_box')
                : __('Components.OrderDetails.issues_modal.boxes_per_pallet')}
            </S.InputTitle>
            {config.TOGGLE_BOXES_PER_PALLET.enabled &&
            catalog?.mandatoryPalletizationByClient &&
            selectedIssue?.type === 'multi-boxes-per-pallet-without-buyer' &&
            !this.state.showNewMbppValueInput ? (
              <>
                <Select
                  name="productCode"
                  value={selectedItem?.boxesPerPallet.toString() || ''}
                  options={mbppDropdownOptions}
                  onChange={(name, value) => {
                    if (value === 'add_option') {
                      this.setState({ showNewMbppValueInput: true });
                    } else {
                      this.setState({ selectedItem: { ...selectedItem, boxesPerPallet: Number(value) } });
                    }
                  }}
                  containerMargin="4px 24px 4px 0"
                  width="100%"
                />
              </>
            ) : (
              <Input
                name="issue"
                onChange={(name, value) => {
                  selectedIssue?.type === 'no-box-weight'
                    ? this.setState({ selectedItem: { ...selectedItem, boxWeight: Number(value) } })
                    : selectedIssue?.type === 'no-pieces-per-box'
                    ? this.setState({ selectedItem: { ...selectedItem, piecesPerBox: Number(value) } })
                    : selectedIssue?.type === 'no-boxes-per-pallet'
                    ? this.setState({ selectedItem: { ...selectedItem, boxesPerPallet: Number(value) } })
                    : this.setState({
                        selectedItem: { ...selectedItem, boxesPerPallet: Number(value) },
                      });
                }}
                type="number"
                width="100%"
                variableTextPlural={
                  selectedIssue?.type === 'no-box-weight'
                    ? __('Components.OrderDetails.issues_modal.kg')
                    : selectedIssue?.type === 'no-pieces-per-box'
                    ? __('Components.OrderDetails.issues_modal.unit')
                    : __('Components.OrderDetails.issues_modal.box')
                }
              />
            )}
            <S.ModalRow>
              <S.ModalButton
                type="secondary"
                onClick={() =>
                  this.setState({
                    showIssuesModal: false,
                    selectedItem: undefined,
                    showNewMbppValueInput: false,
                    newMbppValue: undefined,
                  })
                }
              >
                {__('Components.OrderDetails.issues_modal.cancel')}
              </S.ModalButton>
              <S.CtaButton
                disabled={false}
                onClick={() => {
                  this.setState({ itemWithIssue: { ...this.state.itemWithIssue, item: selectedItem } }, () => {
                    this.changeProductItem(false, selectedItem);

                    if (selectedIssue?.type === 'multi-boxes-per-pallet-without-buyer') {
                      productAddAttributeValue(
                        selectedItem.childId,
                        'boxes-per-pallet',
                        {
                          value: selectedItem.boxesPerPallet,
                          client_id: order.buyerId,
                        },
                        (res, err) => {
                          if (err) {
                            notificationShow({
                              title: __('Components.OrderDetails.notification.error'),
                              subtitle: __('Components.OrderDetails.notification.error_description'),
                              closable: true,
                              style: 'error',
                            });
                          }
                        },
                      );
                    }
                    this.setState({
                      showIssuesModal: false,
                      selectedItem: undefined,
                      showNewMbppValueInput: false,
                      newMbppValue: undefined,
                    });
                  });
                }}
              >
                {__('Components.OrderDetails.issues_modal.cta')}
              </S.CtaButton>
            </S.ModalRow>
          </ActionsModal>
        ) : null}
        {showBoxesPerPalletModal ? (
          <ActionsModal
            minHeight="300px"
            width="500px"
            onClose={() => this.setState({ showBoxesPerPalletModal: false, selectedItem: undefined })}
            title={__('Components.Cart.boxesPerPallet.modal.title')}
            subtitle={__('Components.Cart.boxesPerPallet.modal.description')}
            overFlowVisible={true}
          >
            <S.InputTitle>{__('Components.OrderDetails.issues_modal.boxes_per_pallet')}</S.InputTitle>
            <Input
              name="issue"
              onChange={(name, value) => {
                this.setState({ productAttributeValue: value });
              }}
              type="number"
              width="100%"
              variableTextPlural={__('Components.Cart.boxesPerPallet.modal.placeholder')}
            />
            <S.ModalRow>
              <S.ModalButton
                type="secondary"
                onClick={() => {
                  this.setState({
                    showBoxesPerPalletModal: false,
                    selectedItem: undefined,
                    productAttributeValue: undefined,
                  });
                }}
              >
                {__('Components.OrderDetails.issues_modal.cancel')}
              </S.ModalButton>
              <S.CtaButton
                disabled={!productAttributeValue}
                onClick={() => {
                  this.showBoxesPerPalletModal(selectedItem);
                  this.setState({ showBoxesPerPalletModal: false, selectedItem: undefined });
                }}
              >
                {__('Components.OrderDetails.issues_modal.cta')}
              </S.CtaButton>
            </S.ModalRow>
          </ActionsModal>
        ) : null}
      </S.Container>
    );
  }

  /**
   * Render tooltip error
   */
  private renderTooltipRefError = (children: React.ReactNode) => {
    const { showTooltip } = this.state;
    return showTooltip ? (
      <Tooltip position="bottom" text={__('Components.Cart.invalid_hash')} width="200px" themeMode="dark" showed={true}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    );
  };

  private onEnableItem = (item: IOrderItem, issue: IOrderIssue) => {
    const { me, order, orderItemFixIssue } = this.props;
    orderItemFixIssue(me.id!, order.id, item, 0, issue.id, false, item.price, false);
  };
  private getDownloadFormats = (): Array<IItem> => {
    const { catalogs, getExcelOrder, me, order, orderCustomPdforExcelDownload, orderPdfDownload, weAreSeller } =
      this.props;
    const { pdfFormats } = this.state;
    const sellerWorkspace = catalogs[order.catalogId];
    const mySellerRole = weAreSeller ? sellerWorkspaceService.getRole(sellerWorkspace, me.id) : undefined;
    return [
      ...(order.status === ORDER_STATUS.ACCEPTED
        ? mySellerRole && pdfFormats.length
          ? pdfFormats.reduce((acc, p) => {
              if (p.isExcel) {
                acc.push({
                  key: `excel_${p.id}`,
                  value: p.name,
                  action: () => {
                    orderCustomPdforExcelDownload(me.id, order.id, p.id, true, false, data => {
                      const url = window.URL.createObjectURL(new Blob([data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute(
                        'download',
                        `order-${
                          order.externalIdSeller || order.externalIdBuyer || order.hashId
                        }-${new Date().getTime()}.xlsx`,
                      );
                      document.body.appendChild(link);
                      link.click();
                    });
                  },
                  image: constants.getLogoFromMime('excel'),
                });
              }
              if (p.isPdf) {
                acc.push({
                  key: `pdf_${p.id}`,
                  value: p.name,
                  action: () => {
                    orderCustomPdforExcelDownload(me.id, order.id, p.id, false, true, data => {
                      downloadFile(
                        data,
                        'application/pdf',
                        `order-${
                          order.externalIdSeller || order.externalIdBuyer || order.hashId
                        }-${new Date().getTime()}.pdf`,
                      );
                    });
                  },
                  image: constants.getLogoFromMime('application/pdf'),
                });
              }
              return acc;
            }, [])
          : [
              {
                key: 'pdf',
                value: __('Components.Cart.format_pdf'),
                action: () => {
                  orderPdfDownload(me.id, order.id, data => {
                    downloadFile(
                      data,
                      'application/pdf',
                      `order-${
                        order.externalIdSeller || order.externalIdBuyer || order.hashId
                      }-${new Date().getTime()}.pdf`,
                    );
                  });
                },
                image: constants.getLogoFromMime('application/pdf'),
              },
              {
                key: 'excel',
                value: __('Components.Cart.format_xls'),
                action: () => {
                  getExcelOrder(
                    order.id,
                    me.id,
                    `proforma-${order.externalIdSeller || order.externalIdBuyer || order.hashId}.xlsx`,
                  );
                },
                image: constants.getLogoFromMime('excel'),
              },
            ]
        : []),
      ...(order?.origin !== ORDER_ORIGIN.INTERNAL
        ? [
            {
              key: 'import',
              value: __('Components.Cart.format_original'),
              action: this.downloadOriginalFile,
              icon: 'File',
            },
          ]
        : []),
    ];
  };

  private downloadOriginalFile = () => {
    const { me, order, orderOriginalFileDownload } = this.props;
    orderOriginalFileDownload(me.id, order.id, (data: string, contenType: string, filename: string) => {
      const fileName =
        filename || `order-${order.externalIdSeller || order.externalIdBuyer || order.hashId}-${new Date().getTime()}`;
      downloadBinaryFile(data, contenType, fileName);
    });
  };
  private orderUpdate = (o: IOrder) => {
    const { orderUpdate, me, closeCart, weAreSeller, order, orderUpdateIssues, orderItemFixIssue } = this.props;
    const contactId = weAreSeller ? order.buyerId : order.sellerId;
    const mismatchPriceIssues = order.issues.filter(i => i.type === 'no-pricegroup-matching');
    mismatchPriceIssues.forEach(mismatchPriceIssue => {
      const item = order.items.find(oi => mismatchPriceIssue.orderItemId === oi.id && oi.price !== oi.servedPrice);
      if (item) {
        orderUpdateIssues({ ...mismatchPriceIssue, status: 'solved' }, order.id!, me.id!, () => {
          orderItemFixIssue(me.id!, order.id, item, 0, mismatchPriceIssue.id, false, item.price, false);
        });
      }
    });

    orderUpdate(o, me.id, () => {
      const { editAfterAccept } = this.state;
      if (contactId && (order.changedItems || order.changedCustomItems)) {
        if (editAfterAccept) {
          this.setState({ editAfterAccept: false });
          return this.showSuccessUpdateNotification(order);
        }
        closeCart();
        this.showSuccessUpdateModal(order);
      }
      this.hideLogistic();
    });
  };

  /**
   * Returns if order can be modified when accepted
   */
  private canServeOrder(servedFlowEnabled: boolean, weAreSeller: boolean): boolean {
    const { order, catalogs, me } = this.props;
    if (!servedFlowEnabled) return false;
    if (!weAreSeller) return false;
    if (catalogs[order.catalogId]?.members.find(member => member.userId === me.id)?.role === 'viewer') return false;
    if (order.pickupEstimatedAt) {
      const days = Math.ceil((Number(new Date(order.pickupEstimatedAt)) - Date.now()) / 86400 / 1000);
      if (days < -DAYS_SERVE_ALLOWED) return false;
    } else if (order.deliveryEstimatedAt) {
      const days = Math.ceil((Number(new Date(order.deliveryEstimatedAt)) - Date.now()) / 86400 / 1000);
      if (days < -DAYS_SERVE_ALLOWED) return false;
    }
    return true;
  }

  /**
   * Render custom items
   */
  private renderCustomItems(
    customItems: Array<ICustomItem>,
    amEditor: boolean,
    weAreSeller: boolean,
    orderEditable: boolean,
  ) {
    const { cart, cartUpdateCustomItems, contact, me, order, orderUpdateCustomItems, priceMode } = this.props;
    const { editAfterAccept } = this.state;

    const canEdit = weAreSeller && amEditor && orderEditable;
    if (!canEdit && !customItems.length) return null;
    const customItemsCount = customItems.length ? ` (${customItems.length})` : '';

    return (
      <S.CustomItemsContainer>
        <S.SectionTitleCustomItems>
          {__('Components.Cart.custom_items.title') + customItemsCount}
          <S.Tooltip
            position="bottom"
            text={__('Components.Cart.custom_items.tooltip')}
            width="200px"
            className="tooltip-add-line"
            themeMode="dark"
          >
            <S.InfoIcon name="Info" disableHover={true} />
          </S.Tooltip>
        </S.SectionTitleCustomItems>
        <CustomItems
          canEdit={canEdit}
          customItemDelete={(ci, idx) => {
            const customItemsCopy = customItems.slice();
            customItemsCopy.splice(idx, 1);
            cartUpdateCustomItems(cart.key, cart.catalogId, contact.id, customItemsCopy, priceMode);
            orderUpdateCustomItems(customItemsCopy, order.id, me.id);
          }}
          customItems={customItems}
          customItemUpdate={(item, index) => {
            const customItemsCopy = customItems.slice();
            if (index >= 0 && customItemsCopy[index]) {
              customItemsCopy[index] = item;
            } else {
              customItemsCopy.push(item);
            }
            cartUpdateCustomItems(cart.key, cart.catalogId, contact.id, customItemsCopy, priceMode);
            orderUpdateCustomItems(customItemsCopy, order.id, me.id);
          }}
          me={me}
          priceMode={priceMode}
          order={order}
          editAfterAccept={editAfterAccept}
          onModifyAfterAccept={this.modifyAfterAccept}
        />
      </S.CustomItemsContainer>
    );
  }

  /**
   * Render order issues summary header
   */
  private renderIssuesHeader(issues: Array<IOrderIssue>, pricesEmpty: Array<IOrderItem>) {
    const {
      weAreSeller,
      cart: { items },
      prodTypes,
      order,
      togglePriceMismatchEnable,
    } = this.props;
    const hasPendingIssues = !!issues.find(i => i.status === 'pending' || i.status === 'unknown');
    const hasMissingDeliveryAddress = !!issues.find(err =>
      ['address-not-found', 'address-code-not-found'].includes(err.type),
    );
    const hasMissingPickupAddress = !!issues.find(err =>
      ['pickup-not-found', 'pickup-code-not-found'].includes(err.type),
    );
    const missingProductsCount = issues.filter(err =>
      ['product-not-found', 'code-not-found'].includes(err.type),
    ).length;
    const productIssues = issues.find(err =>
      ['no-pieces-per-box', 'no-boxes-per-pallet', 'no-box-weight', 'multi-boxes-per-pallet-without-buyer'].includes(
        err.type,
      ),
    );
    const mbppIssues = issues.filter(err => err.type === 'multi-boxes-per-pallet-without-buyer');

    const noClientIssue = issues.find(err => err.type === 'client-code-not-found');

    const manyInternalsCodesCount = issues.filter(err => 'many-internal-codes-for-external' === err.type).length;
    const unavailableProducts = issues.filter(err => ['product-unavailable'].includes(err.type));
    const invalidAmountCount = issues.filter(err => err.type === 'amount-not-valid').length;
    const invalidPriceCount = issues.filter(err => err.type === 'price-not-valid').length;
    const unexpectedErrorsCount = issues.filter(err => err.type === 'unexpected').length;
    const unknownErrorsCount = issues.filter(err => err.type === 'unknown').length;
    const hasMissingDateCreate = !!issues.find(err => err.type === 'date-not-valid' && err.code === 'order_date');
    const hasMissingDateDelivery = !!issues.find(err => err.type === 'date-not-valid' && err.code === 'delivery_date');
    const hasMissingDatePickup = !!issues.find(err => err.type === 'date-not-valid' && err.code === 'pickup_date');
    const mismatchPricesCount = issues.filter(err => err.type === 'no-pricegroup-matching')?.length || 0;
    const lostLines = issues.find(err => err.type === 'kind-loss-of-line');
    const itemMismatchRelated =
      mismatchPricesCount === 1
        ? items.find(item => item.id === issues.find(err => err.type === 'no-pricegroup-matching').orderItemId)
        : undefined;
    const productName = itemMismatchRelated
      ? utils.firstToUpperCase(
          `${
            itemMismatchRelated.productTitle ||
            productService.getProductTypeVarietyDisplay(
              itemMismatchRelated?.type,
              prodTypes[itemMismatchRelated?.type]?.name || '',
              itemMismatchRelated?.title,
            ) +
              ' ' +
              itemMismatchRelated?.size
          }`,
        )
      : '';

    const itemsWithIssue = items.filter(item =>
      issues.some(
        err =>
          [
            'no-pieces-per-box',
            'no-boxes-per-pallet',
            'no-box-weight',
            'multi-boxes-per-pallet-without-buyer',
          ].includes(err.type) &&
          !issues.find(e => ['product-not-found', 'code-not-found'].includes(e.type) && item.id === e.orderItemId) &&
          item.id === err.orderItemId,
      ),
    );

    const productNames = itemsWithIssue.map(p => {
      return (
        `${productService.getProductTypeVarietyDisplay(
          p.type,
          prodTypes[p.type] ? prodTypes[p.type].name : '',
          p.title,
        )}` +
        ' ' +
        `${p.size}`
      );
    });
    return (
      <>
        {hasPendingIssues ? (
          <Ribbon
            type="warning"
            text={
              <ColumnContainer>
                {!!pricesEmpty.find(p => !issues.find(i => p.id === i.orderItemId && i.type !== 'product-no-price')) &&
                weAreSeller ? (
                  <S.Text>{__('Components.OrderDetails.por_message')}</S.Text>
                ) : null}
                {hasMissingPickupAddress ? (
                  <S.Text>{__('Components.Cart.errors.pickup_address_not_found')}</S.Text>
                ) : null}
                {hasMissingDeliveryAddress ? (
                  <S.Text>{__('Components.Cart.errors.delivery_address_not_found')}</S.Text>
                ) : null}
                {hasMissingDateCreate ? <S.Text>{__('Components.Cart.errors.date_create')}</S.Text> : null}
                {hasMissingDateDelivery ? <S.Text>{__('Components.Cart.errors.date_delivery')}</S.Text> : null}
                {hasMissingDatePickup ? <S.Text>{__('Components.Cart.errors.date_pickup')}</S.Text> : null}
                {manyInternalsCodesCount > 0 ? (
                  <S.Text>
                    {__('Messages.ImportJobFile.errors.many_internal_codes_for_external', {
                      errorCode: issues.find(i => i.type === 'many-internal-codes-for-external').code,
                    })}
                  </S.Text>
                ) : null}
                {missingProductsCount > 0 ? (
                  <S.Text>{__('Components.Cart.errors.missing_products', { count: missingProductsCount })}</S.Text>
                ) : null}
                {noClientIssue ? (
                  <S.Text>
                    {
                      utils.formatText(__('Components.Cart.errors.no_client'), (text, i) => (
                        <S.TextBold key={text + i}>{text}</S.TextBold>
                      )) as any
                    }
                  </S.Text>
                ) : null}
                {unavailableProducts.length > 0 ? (
                  <S.Text>
                    {__('Components.Cart.errors.unavailable_products', {
                      count: unavailableProducts.length,
                      name:
                        unavailableProducts.length > 1
                          ? ''
                          : (() => {
                              const item = items.find(i => i.id === unavailableProducts[0].orderItemId);
                              if (!item) return '';
                              const typeVariety = productService.getProductTypeVarietyDisplay(
                                item.type,
                                prodTypes[item.type] ? prodTypes[item.type].name : '',
                                item.title,
                              );
                              return `${item.productTitle || typeVariety + ' ' + item.size}`;
                            })(),
                    })}
                  </S.Text>
                ) : null}
                {invalidAmountCount > 0 ? (
                  <S.Text>{__('Components.Cart.errors.invalid_amount', { count: invalidAmountCount })}</S.Text>
                ) : null}
                {invalidPriceCount > 0 ? (
                  <S.Text>{__('Components.Cart.errors.invalid_price', { count: invalidPriceCount })}</S.Text>
                ) : null}
                {unexpectedErrorsCount > 0 ? (
                  <S.Text>{__('Components.Cart.errors.unexpected', { count: unexpectedErrorsCount })}</S.Text>
                ) : null}
                {unknownErrorsCount > 0 ? (
                  <S.Text>{__('Components.Cart.errors.unknown', { count: unknownErrorsCount })}</S.Text>
                ) : null}
                {productIssues && productNames.length > 0 && config.TOGGLE_ORDER_ITEM_ISSUE.enabled ? (
                  <S.Text>{__('Components.Cart.errors.product_issue', { name: productNames })}</S.Text>
                ) : null}
                {mbppIssues?.length > 0 &&
                productNames.length > 0 &&
                config.TOGGLE_BOXES_PER_PALLET.enabled &&
                !config.TOGGLE_ORDER_ITEM_ISSUE.enabled &&
                this.props.catalog?.mandatoryPalletizationByClient ? (
                  <S.Text>{__('Components.Cart.errors.product_issue', { name: productNames })}</S.Text>
                ) : null}
              </ColumnContainer>
            }
          />
        ) : null}
        {togglePriceMismatchEnable && weAreSeller && (mismatchPricesCount || lostLines) ? (
          <Ribbon
            type="info"
            text={
              <ColumnContainer>
                <S.Text>
                  {mismatchPricesCount
                    ? (utils.formatText(
                        __('Components.Cart.errors.mismatch_price', { count: mismatchPricesCount, productName }),
                        (text, i) => <S.TextBold key={text + i}>{text}</S.TextBold>,
                      ) as any)
                    : null}
                </S.Text>
                {lostLines ? <LostLines lostLines={lostLines} /> : null}
              </ColumnContainer>
            }
          />
        ) : null}
      </>
    );
  }

  /**
   * Render comments unread count
   */
  private renderCommentsRibbon(count: number) {
    const { commentsRibbon } = this.state;
    return commentsRibbon ? (
      <S.CommentsHeader>
        <S.CommentsIcon name="Comment" disableHover={true} />
        <S.TextHeader>{commentsRibbon + ' '}</S.TextHeader>
        <S.TextLink onClick={this.navigateToComments}>{__('Components.Cart.see_comments', { count })}</S.TextLink>
      </S.CommentsHeader>
    ) : null;
  }

  /**
   * Render updates unread count
   */
  private renderUpdatesRibbon(count: number) {
    const { updatesRibbon } = this.state;
    return updatesRibbon ? (
      <S.CommentsHeader>
        <S.CommentsIcon name="Recent" disableHover={true} />
        <S.TextHeader>{updatesRibbon + ' '}</S.TextHeader>
        <S.TextLink onClick={this.navigateToComments}>{__('Components.Cart.see_updates', { count })}</S.TextLink>
      </S.CommentsHeader>
    ) : null;
  }

  private navigateToComments = () => {
    const { me, order, setCommentsRead } = this.props;
    const { lastMessageAt } = this.state;

    const commentsElement = document.getElementById('cart-comments');
    if (commentsElement) {
      if (lastMessageAt) setCommentsRead(me.id, order.id);
      commentsElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  /**
   * Navigate to the workspaces orders
   */
  private navigateToOrders = () => {
    const { history, order } = this.props;
    setTimeout(() => {
      history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_SALES, workspaceId: order.catalogId + '' }));
    }, 100);
  };

  /**
   * Render this order if for a inactive catalog
   */
  private renderInactiveCatalog() {
    const { order, me, catalogs, contacts, weAreSeller } = this.props;
    return (
      <Ribbon
        type="warning"
        text={
          weAreSeller
            ? __('Components.Cart.inactive_catalog_seller', {
                catalog: sellerWorkspaceService.getCatalogName(catalogs[order.catalogId], contacts, me),
              })
            : __('Components.Cart.inactive_catalog_buyer')
        }
      />
    );
  }

  /**
   * Render search product page
   */
  private renderSearchProductModal = () => {
    const {
      catalog,
      featured,
      history,
      location,
      me,
      order,
      priceMode,
      prices,
      priceGroupIds,
      prodTypes,
      weAreSeller,
      weAreBuyer,
    } = this.props;
    const { itemWithIssue, showPORProduct } = this.state;
    return itemWithIssue && !showPORProduct ? (
      <SearchProducts
        itemWithIssue={itemWithIssue}
        history={history}
        location={location}
        catalog={catalog}
        featured={featured}
        me={me}
        order={order}
        priceGroupIds={priceGroupIds}
        priceMode={priceMode}
        prices={prices}
        weAreBuyer={weAreBuyer}
        weAreSeller={weAreSeller}
        changeProductItem={this.changeProductItem}
        prodTypes={prodTypes}
        onClose={() => this.setState({ itemWithIssue: undefined })}
      />
    ) : null;
  };

  private renderPORProductModal = () => {
    const { showPORProduct, itemWithIssue } = this.state;
    const { me, history } = this.props;
    return showPORProduct ? (
      <PORProduct
        close={() => this.setState({ showPORProduct: false, itemWithIssue: undefined })}
        history={history}
        item={itemWithIssue.item}
        me={me}
        onAccept={this.onPORProductAccept}
        pricePrecision={this.getPricePrecision()}
      />
    ) : null;
  };

  /**
   * Get price precision. If order is new, take setting from catalog.
   */
  private getPricePrecision() {
    const { catalog, order } = this.props;
    if (order.id) return order.pricePrecision;
    return catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
  }

  /**
   * Show search product modal to choose a product to replace an item
   */
  private showSearchProductModal = async (item: IOrderItem, originLot?: number, mappingCode?: string) => {
    const { me, order } = this.props;
    const { buyer, seller } = orderService.getSellerBuyer(
      order,
      this.props.contacts,
      me,
      this.props.clientsOld || [],
      this.props.suppliersOld || [],
    );
    const { data } = mappingCode
      ? await to(
          api.mapping.getProductMappingsForExternalRef(
            me.id,
            order.catalogId,
            mappingCode,
            config.TOGGLE_MAPPINGS_PER_CLIENT.enabled ? buyer.id : null,
          ),
        )
      : { data: undefined };
    this.setState({
      itemWithIssue: {
        mappingCode,
        item,
        originLot,
        productsMapped: data,
      },
    });
  };

  private showPORProductModal = (item: IOrderItem) => {
    this.setState({ showPORProduct: true, itemWithIssue: { item } });
  };

  private modifyAfterAccept = () => {
    const { order } = this.props;
    this.setState({ editAfterAccept: true, previousOrderState: { ...order, items: [...order.items] } });
  };

  private onPORProductAccept = (item: IOrderItem, updateProductPrice: boolean) => {
    const { me, order, orderItemFixIssue, orderUpdateIssues } = this.props;
    const issueRelated = order.issues.find(issue => issue.orderItemId === item.id && issue.type === 'product-no-price');

    orderUpdateIssues({ ...issueRelated, status: 'solved' }, order?.id!, me?.id!, o => {
      orderItemFixIssue(me?.id!, order?.id, item, 0, issueRelated?.id, false, item.price, updateProductPrice);
    });
    this.setState({
      itemWithIssue: undefined,
    });
  };

  /**
   * Change product item to fix order issue
   */
  private changeProductItem = (fixAll: boolean, itemUpdated: IOrderItem) => {
    const { me, notificationShow, order, orderItemFixIssue, orderUpdateIssues } = this.props;
    const { itemWithIssue } = this.state;
    const issueRelated = order.issues.find(
      issue =>
        issue.orderItemId === itemWithIssue.item.id &&
        [
          'product-not-found',
          'code-not-found',
          'product-unavailable',
          'many-internal-codes-for-external',
          'no-pieces-per-box',
          'no-boxes-per-pallet',
          'no-box-weight',
          'multi-boxes-per-pallet-without-buyer',
        ].includes(issue.type),
    );
    const isDistributionIssue = ['no-pieces-per-box', 'no-boxes-per-pallet', 'no-box-weight'].includes(
      issueRelated?.type,
    );
    orderUpdateIssues({ ...issueRelated, status: 'solved' }, order.id!, me.id!, o => {
      orderItemFixIssue(
        me.id!,
        order.id,
        {
          ...itemWithIssue.item,
          boxWeight: itemUpdated.boxWeight,
          boxesPerPallet: itemUpdated.boxesPerPallet,
          piecesPerBox: itemUpdated.piecesPerBox,
        },
        itemUpdated.childId,
        issueRelated?.id,
        fixAll,
        itemUpdated.price,
        isDistributionIssue ? true : false, // aqui, si es multi valor no queremos cambiar, sería false, pero se está ctualizando igualmente el producto
      );
      notificationShow(
        {
          style: 'success',
          title: __('Components.OrderDetails.issues_modal.success'),
          subtitle: '',
          closable: true,
        },
        5000,
      );
    });
    this.setState({
      itemWithIssue: undefined,
    });
  };

  /**
   * Update order issues
   */
  private onDiscardLine = (issue: IOrderIssue) => {
    const { me, modalClose, modalOpen, cart, order, orderFixIssue, orderItemIgnore, catalog } = this.props;

    this.setState({ discardForever: false });

    config.TOGGLE_DISCARD_MO.enabled
      ? modalOpen(
          __('Components.Cart.discard.message'),
          () => {
            const isForever = this.state.discardForever;
            orderItemIgnore(catalog.id, me.id, order.id!, issue.orderItemId, issue.id, this.state.discardForever, o => {
              const orderIdx = o.items.findIndex(i => i.id === issue.orderItemId);
              order.items[orderIdx] = { ...order.items[orderIdx], alwaysIgnored: isForever, servedQuantity: 0 };
              const cartIdx = cart.items.findIndex(i => i.id === issue.orderItemId);
              cart.items[cartIdx] = { ...cart.items[cartIdx], alwaysIgnored: isForever, servedQuantity: 0 };
            });
            this.setState({ discardForever: false });
            modalClose();
          },
          {
            icon: IMAGES.informativePineapple,
            buttonText: __('Components.Cart.discard.cta'),
            showCancelButton: true,
            buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
            checkBox: __('Components.Cart.discard.checkbox'),
            checkBoxAction: () => {
              this.setState({ discardForever: !this.state.discardForever });
            },
            cancelAction: () => {
              this.setState({ discardForever: false }, () => modalClose());
            },
          },
          'nice',
        )
      : modalOpen(
          __('Components.Cart.discard.message'),
          () => {
            this.props.orderUpdateIssues(issue, order.id!, me.id!, o => orderFixIssue(me.id!, o));
            modalClose();
          },
          {
            buttonText: __('Components.Cart.discard.cta'),
            showCancelButton: true,
          },
        );
  };

  /**
   * hide the logistic column
   */
  private hideLogistic = () => {
    this.setState({ showLogistic: false });
  };

  /**
   * show the logistic column
   */
  private showLogistic = () => {
    if (!this.props.previewMode) this.setState({ showLogistic: true });
  };

  /**
   * Render product info page
   */
  private renderProductInfo() {
    const { addProductModalProps, showProductPage } = this.state;
    if (!addProductModalProps || !showProductPage) return null;

    const {
      catalog,
      channelId,
      clientsOld,
      contact,
      countries,
      history,
      me,
      modalClose,
      modalOpen,
      order,
      prodTypes,
      sendMessage,
      touchImage,
      breadcrumb,
      weAreBuyer,
    } = this.props;
    const { item, priceMode, weAreSeller, deletable, product } = addProductModalProps!;
    const contactId = weAreBuyer ? order.sellerId : order.buyerId;
    return (
      <S.Modal>
        <ProductInfo
          cartUpdateItem={(cId, itemToUpdate) => {
            this.cartUpdateItem(itemToUpdate);
            if (this.props.updateBreadcrumb) {
              // Update breadcrumb setting the title to the last parent section and removing the last parent section
              const newBreadcrumb = {
                parentSections: breadcrumb.parentSections.slice(0, -1),
                title: breadcrumb.parentSections.slice(-1)[0].label,
              };
              this.props.updateBreadcrumb(newBreadcrumb);
            }
          }}
          contactId={contactId}
          contact={contact}
          contactName={contact?.name || clientsOld?.find(c => c.userId === contactId)?.name || ''}
          isContactUnregistered={contact?.isUnregistered}
          isServedFlow={order.status === ORDER_STATUS.ACCEPTED && order.servedFlowEnabled}
          catalogId={
            order.buyerId !== me.id || contact?.isUnregistered
              ? contact?.mySellerWorkspaceId || me.sellerWorkspaceId
              : contact?.theirSellerWorkspaceId
          }
          close={() => this.setState({ showProductPage: false })}
          countries={countries}
          deletable={deletable}
          forceSetPrice={!weAreSeller && contact?.isUnregistered}
          from="order"
          history={history}
          amSeller={weAreSeller}
          item={item}
          modalClose={modalClose}
          modalOpen={modalOpen}
          me={me}
          priceMode={priceMode}
          pricePrecision={this.getPricePrecision()}
          prodTypes={prodTypes}
          product={{ ...parsers.orderItemToGenericProduct(item), updatedAt: product.updatedAt }}
          disabled={
            order.status !== 'pending' ||
            (![order.sellerId, order.buyerId].includes(me.id) &&
              sellerWorkspaceService.getRole(catalog, me.id) === 'viewer')
          }
          sendMessage={
            channelId
              ? (text: string) => {
                  if (text) {
                    const message = text.replace(/(?:\r\n|\r|\n)/, '\\n');
                    sendMessage(me.id!, {
                      channelId,
                      createdAt: new Date().getTime() + 60000,
                      extraData: {},
                      message,
                      messageId: 0,
                      messageType: 'text',
                      reactions: {},
                      senderId: me.id,
                    });
                    EventTrack.track('message_send', {
                      channelId: 'order_' + order.id,
                      type: 'order',
                      messageType: 'text',
                      length: message.length,
                    });
                  }
                }
              : undefined
          }
          showBack={!breadcrumb}
          showDefaultPicture={false}
          showFeatured={true}
          showShare={true}
          touchImage={touchImage}
        />
      </S.Modal>
    );
  }

  /**
   * Navigate to product info
   */
  private navigateToProductInfo = (i: IOrderItem) => {
    const { order, priceMode, weAreBuyer, weAreSeller, updateBreadcrumb, breadcrumb, prodTypes, previewMode } =
      this.props;
    if (previewMode) return;
    const productItem: IOrderItem = this.getOrderItem(i);
    const product = parsers.orderItemToGenericProduct(productItem);
    const title = productService.getProductTypeVarietyDisplay(
      productItem?.type,
      prodTypes[productItem?.type]?.name || '',
      productItem?.title,
    );
    if (updateBreadcrumb && breadcrumb?.title !== title) {
      updateBreadcrumb({
        parentSections: [
          ...(breadcrumb.parentSections || []),
          {
            label: breadcrumb.title,
            action: () => {
              updateBreadcrumb(breadcrumb);
              this.setState({ showProductPage: false });
            },
          },
        ],
        title,
      });
    }
    this.setState({
      showProductPage: true,
      addProductModalProps: {
        contactId: weAreBuyer ? order.sellerId : order.buyerId,
        deletable: order.items.filter(oi => oi.amount).length > 1,
        item: productItem,
        priceMode,
        product,
        saleUnits: product.saleUnits,
        weAreSeller,
      },
    });
  };

  /**
   * Update item in cart
   * We set the total price to 0 in order to force to be calculated again
   */
  private cartUpdateItem = (item: IOrderItem, issue?: IOrderIssue) => {
    const {
      cart,
      cartUpdateItem,
      me,
      order,
      orderUpdateProducts,
      orderUpdateIssues,
      orderItemFixIssue,
      weAreSeller,
      weAreBuyer,
      previewMode,
      updateBreadcrumb,
      breadcrumb,
    } = this.props;
    if (previewMode || order === undefined) return;
    const items = order.items;
    const idx = items.findIndex(i => (item.childId ? i.childId === item.childId : i.title === item.title));
    const newItem = { ...item, totalPrice: 0 };
    if (idx !== -1) {
      items[idx] = newItem;
    } else {
      items.push(newItem);
    }
    const contactId = weAreBuyer ? order.sellerId : order.buyerId;
    cartUpdateItem(cart.key, contactId, newItem, weAreSeller, cart.priceMode);
    orderUpdateProducts(items, order.id, me.id);
    if (issue) {
      orderUpdateIssues({ ...issue, status: 'solved' }, order.id!, me.id!, o => {
        orderItemFixIssue(me.id!, order.id, item, 0, issue.id, false, item.price, false);
      });
    }
  };

  /**
   * Get order item from cart or item passed
   */
  private getOrderItem(item: IOrderItem) {
    // it's possible orderItems without childId
    return (item.childId && this.props.order.items.find(i => i.childId === item.childId)) || item;
  }

  /**
   * function called at the moment of mount or the update.
   * request required data and clean old data in case the change order or the component isn't mounted yet
   * request logistics addresses if there is an address issue
   */
  private initOrderDetails = () => {
    const {
      cartSet,
      catalog,
      clientsGet,
      clientsOld,
      commentsChannel,
      contact,
      getPrices,
      me,
      notMarkAsRead,
      order,
      orderChannelGet,
      orderToggleReadStatus,
      prices,
      suppliersOld,
      suppliersGet,
      weAreBuyer,
      weAreSeller,
      workspaces,
    } = this.props;
    if (!order) return;
    if (order.issues.find(i => i.type === 'client-code-not-found')) {
      this.setState({ showClientsModal: true });
    }
    // Cannot get clients if I do not belong to the catalog
    if (catalog && !clientsOld && sellerWorkspaceService.getMember(catalog, me.id)) {
      clientsGet(me.id, catalog.id);
    }
    const buyerWorkspace = workspaces[order.buyerWorkspaceId];
    if (buyerWorkspace && !suppliersOld && sellerWorkspaceService.getMember(buyerWorkspace, me.id)) {
      suppliersGet(me.id, buyerWorkspace.id);
    }

    if (order.id && !notMarkAsRead) {
      if (order.sellerId === me.id || order.buyerId === me.id || order.delegateId === me.id)
        orderToggleReadStatus(me.id!, order.id!, true);
      RenderTrack.track('OrderDetails', {
        orderId: order.id.toString(),
        status: order.status,
        price: order.price,
        numItems: order.items.length,
        sellerId: order.sellerId.toString(),
        buyerId: order.buyerId.toString(),
        authorId: order.authorId.toString(),
        renderTime: Date.now(),
      });
    }
    const contactId = weAreBuyer ? order.sellerId : order.buyerId;
    cartSet(
      productService.getKey(weAreSeller ? me.id : order.sellerId, weAreBuyer ? me.id : order.buyerId),
      order,
      contactId,
    );

    /**
     * if not prices fetch prices according to each case mode (Buy / Sell)
     */
    if (!prices) {
      if (me.id === contactId || !contactId) return;
      if (order) {
        getPrices(order.catalogId, contactId);
      } else if (weAreSeller || contact?.isUnregistered) {
        getPrices(contact?.mySellerWorkspaceId || me.sellerWorkspaceId!, contactId);
      } else if (contact) {
        getPrices(contact.theirSellerWorkspaceId!, me.id!);
      }
    }

    if (
      order.issues &&
      order.issues.find(err =>
        ['address-not-found', 'address-code-not-found', 'pickup-not-found', 'pickup-code-not-found'].includes(err.type),
      )
    ) {
      api.user
        .getAddressesBySellerBuyer(order.buyerId, order.sellerId, order.catalogId, order.buyerWorkspaceId)
        .then(({ pickupAddresses, shippingAddresses }) => {
          this.setState({ logisticAddresses: shippingAddresses, pickupAddresses });
        });
    }

    if (order.commentsChannel && !commentsChannel) orderChannelGet(me.id, order.commentsChannel);
    if (weAreSeller) {
      api.sellerWorkspace.getSettingsDownloadPdf(me.id, order.catalogId).then(pdfFormats => {
        this.setState({ pdfFormats: pdfFormats || [] });
      });
    }
  };

  /**
   * Clone orderItems and navigate to cart screen
   */
  private cloneOrder = () => {
    const { contact, navCloneAction, order, prices, orderClone } = this.props;

    orderClone(order, contact, prices, () => {
      navCloneAction();
      this.setState({ showEditExternal: true });
    });
  };

  /**
   * Reset orderItems to original state.
   */
  private resetOrder = () => {
    const { orderGet, me, order } = this.props;
    orderGet(me.id!, order.id);
    this.setState({ editAfterAccept: false });
  };

  /**
   * Add a previous comment before order is created
   */
  private addPreviousComment = (m: IMessage) => {
    const { cart, cartUpdateInitialComments } = this.props;
    cartUpdateInitialComments(cart.key, cart.catalogId, cart.contactId, [m, ...cart.initialComments], cart.priceMode);
  };

  /**
   * Set state last message callback
   */
  private setOnLastMessageAt = lastMessageAt => this.setState({ lastMessageAt });

  /**
   * askForAutoAccept shows a modal for asking user if want to enable autoaccept for contact
   */
  private askForAutoAccept = () => {
    const { contact, modalOpen, clientsOld, suppliersOld, me, weAreSeller, order, clientUpdate, supplierUpdate } =
      this.props;
    if (weAreSeller && clientsOld && contact) {
      const client = clientsOld.find(c => c.userId === contact?.id);
      if (client?.orderAccept === ORDER_ACCEPT_TYPE.NONE) {
        const automaticFn = () =>
          clientUpdate(me.id!, order.catalogId, { ...client, orderAccept: ORDER_ACCEPT_TYPE.AUTOMATIC }, () =>
            this.showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.AUTOMATIC),
          );

        const manualFn = () =>
          clientUpdate(me.id!, order.catalogId, { ...client, orderAccept: ORDER_ACCEPT_TYPE.MANUAL }, () =>
            this.showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.MANUAL),
          );

        const extra = {
          showCancelButton: true,
          icon: IMAGES.acceptAuto,
          text2: __('Components.OrderDetails.autoaccept.body', { contact_name: client.name }),
          buttonText: __('Components.OrderDetails.autoaccept.cta'),
          cancelAction: manualFn,
          buttonCancelText: __('Components.OrderDetails.autoaccept.cancel'),
        };
        modalOpen(__('Components.OrderDetails.autoaccept.title_auto'), automaticFn, extra, 'nice');
      }
    } else {
      const supplier = suppliersOld?.find(c => c.userId === contact?.id);

      if (supplier && supplier?.orderAccept === ORDER_ACCEPT_TYPE.NONE) {
        const automaticFn = () =>
          supplierUpdate(
            me.id!,
            order.buyerWorkspaceId,
            { ...supplier, orderAccept: ORDER_ACCEPT_TYPE.AUTOMATIC },
            () => this.showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.AUTOMATIC),
          );

        const manualFn = () =>
          supplierUpdate(me.id!, order.buyerWorkspaceId, { ...supplier, orderAccept: ORDER_ACCEPT_TYPE.MANUAL }, () =>
            this.showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.MANUAL),
          );

        const extra = {
          showCancelButton: true,
          icon: IMAGES.acceptAuto,
          text2: __('Components.OrderDetails.autoaccept.body', { contact_name: supplier.name }),
          buttonText: __('Components.OrderDetails.autoaccept.cta'),
          cancelAction: manualFn,
          buttonCancelText: __('Components.OrderDetails.autoaccept.cancel'),
        };
        modalOpen(__('Components.OrderDetails.autoaccept.title_auto'), automaticFn, extra, 'nice');
      }
    }
  };

  /**
   *  showAutoAcceptFeedback shows feedback modal after choosing an option in askForAutoAccept
   */
  private showAutoAcceptFeedback = (type: ORDER_ACCEPT_TYPE) => {
    const { modalClose, contact, modalOpen } = this.props;
    if (!contact) return;
    const image = type === ORDER_ACCEPT_TYPE.AUTOMATIC ? IMAGES.acceptAuto : IMAGES.acceptManual;
    const body =
      type === ORDER_ACCEPT_TYPE.MANUAL
        ? __('Components.OrderDetails.autoaccept.body_manual', { contact_name: contact.name })
        : __('Components.OrderDetails.autoaccept.body_auto', { contact_name: contact.name });
    const title =
      type === ORDER_ACCEPT_TYPE.MANUAL
        ? __('Components.OrderDetails.autoaccept.title_manual')
        : __('Components.OrderDetails.autoaccept.title_auto');
    modalOpen(
      title,
      modalClose,
      {
        icon: image,
        text2: body,
        buttonText: __('Components.OrderDetails.autoaccept.cta_feedback'),
      },
      'nice',
    );
  };

  private navShowroom = () => {
    const { contacts, createNewContact, navShowroom, me, order, weAreSeller } = this.props;
    const contactId = weAreSeller ? order.buyerId : order.sellerId;
    if (contactId !== me.id && !contacts[contactId]) {
      createNewContact(me.id!, contactId, (err?: Error) => err || navShowroom());
    } else {
      navShowroom();
    }
  };

  private renderRibbon() {
    const { supportAction, me, order, relaunchOrderExport, weAreBuyer } = this.props;
    const exportErrorSelected = weAreBuyer ? order.exportErrorBuyer : order.exportErrorSeller;
    const canRelaunch = constants.exportErrorsRelaunchList.includes(exportErrorSelected);

    return (
      <Ribbon
        type="warning"
        text={
          exportErrorSelected === 'unknown'
            ? constants.getExportErrorText(exportErrorSelected)
            : canRelaunch
            ? __('Components.OrderDetails.ribbon_relaunch', {
                error_code: constants.getExportErrorText(exportErrorSelected),
                solution_code: constants.getExportErrorSolution(exportErrorSelected),
              })
            : __('Components.OrderDetails.ribbon_no_relaunch', {
                code: constants.getExportErrorText(exportErrorSelected),
              })
        }
        actionText={
          exportErrorSelected === 'unknown'
            ? constants.getExportErrorSolution(exportErrorSelected)
            : canRelaunch
            ? __('Components.OrderDetails.ribbon_action')
            : null
        }
        onClickAction={() =>
          exportErrorSelected === 'unknown' ? supportAction() : relaunchOrderExport(me.id, order.id)
        }
      />
    );
  }

  /**
   * Show a success modal when updating an order
   */
  private showSuccessUpdateModal = (newOrder: IOrder) => {
    const { modalOpen, modalClose, contact, clientsOld, name, order, weAreSeller } = this.props;
    const contactId = weAreSeller ? order.buyerId : order.sellerId;
    const client = clientsOld?.find(c => c.userId === contactId);
    modalOpen(
      __('Components.OrderDetails.success_update.title', {
        hashId: newOrder.externalIdSeller || order.externalIdBuyer || '#' + newOrder.hashId,
      }),
      modalClose,
      {
        text2: __('Components.OrderDetails.success_update.description', {
          name: name || contact?.name || client?.name,
        }),
        buttonText: __('Components.OrderDetails.success_update.cta'),
        icon: IMAGES.checkOK,
      },
      'nice',
    );
  };

  /**
   * Show a confirmation modal when setting boxes per pallet
   */
  private showBoxesPerPalletModal = (orderItem: IOrderItem, newBppValue?: number) => {
    const { modalOpen, modalClose, notificationShow, order, prodTypes, productAddAttributeValue } = this.props;
    const { productAttributeValue } = this.state;
    modalOpen(
      prodTypes[orderItem.type]?.name + ' ' + orderItem.variety || orderItem.title,
      () => {
        this.cartUpdateItem({ ...orderItem, boxesPerPallet: newBppValue || Number(productAttributeValue) });
        if (!this.state.onlyApplyToCurrentOrder) {
          productAddAttributeValue(
            orderItem.childId,
            'boxes-per-pallet',
            { value: newBppValue || productAttributeValue, client_id: order.buyerId },
            (res, err) => {
              if (err) {
                notificationShow({
                  title: __('Components.OrderDetails.notification.error'),
                  subtitle: __('Components.OrderDetails.notification.error_description'),
                  closable: true,
                  style: 'error',
                });
              }
            },
          );
        }
        this.setState({ selectedItem: undefined, productAttributeValue: undefined, onlyApplyToCurrentOrder: false });
        modalClose();
      },

      {
        text2: !orderItem.boxesPerPallet
          ? __('Components.Cart.boxesPerPallet.confirmation_modal.text_add', {
              newValue: newBppValue || productAttributeValue,
            })
          : __('Components.Cart.boxesPerPallet.confirmation_modal.text', {
              oldValue: orderItem.boxesPerPallet,
              newValue: newBppValue || productAttributeValue,
            }),
        buttonText: __('Components.Cart.boxesPerPallet.confirmation_modal.cta'),
        showCancelButton: true,
        icon: IMAGES.informativePineapple,
        checkBox: __('Components.Cart.boxesPerPallet.confirmation_modal.checkbox'),
        checkBoxAction: () => {
          this.setState({ onlyApplyToCurrentOrder: !this.state.onlyApplyToCurrentOrder });
        },
      },
      'nice',
    );
  };

  /**
   * Show a success notification when updating an order
   */
  private showSuccessUpdateNotification = (newOrder: IOrder) => {
    const { notificationShow } = this.props;
    notificationShow(
      {
        style: 'success',
        title: __('Components.OrderDetails.success_serve_update.title'),
        subtitle: '',
        closable: true,
      },
      5000,
    );
  };
}

const OrderDetails: React.FC<IProps> = (props: IProps) => {
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));
  return <OrderDetailsPureComponent {...props} togglePriceMismatchEnable={togglePriceMismatchEnable} />;
};

export default OrderDetails;

function SearchClientsModal({
  members,
  clients,
  hide,
  contacts,
  order,
  orderUpdate,
  orderUpdateIssues,
}: {
  members: Array<IMember>;
  clients: Array<IClient | IContact>;
  hide: () => void;
  contacts: Record<number, IContact>;
  order: IOrder;
  orderUpdate: (order: IOrder) => void;
  orderUpdateIssues: (cId: number) => void;
}) {
  const [filter, setFilter] = React.useState('');
  return (
    <ActionsModal minHeight="450px" onClose={hide} title={__('Components.Cart.clients_title')}>
      {clients?.length ? (
        <S.SearchContainer>
          <SimpleSearch
            onChange={setFilter}
            placeHolder={__('Components.ChatList.search.placeholder')}
            id="input_search_contacts_addressbook"
          />
        </S.SearchContainer>
      ) : null}
      {members ? (
        <ContactsList
          contacts={members.map(m => contacts[m.id])}
          contactSelected={cId => order.buyerId === cId}
          onSelectContact={cId => {
            orderUpdate({ ...order, buyerId: cId });
            hide();
          }}
          action="radio"
          search={filter}
        />
      ) : (
        <ClientsList
          clients={clients as Array<IClient>}
          clientSelected={cId => order.buyerId === cId}
          onSelectClient={cId => {
            orderUpdateIssues(cId);
            hide();
          }}
          action="radio"
          search={filter}
        />
      )}
    </ActionsModal>
  );
}
