import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';

interface IStyledProps {
  isFirst?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  overflow: hidden;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  height: 40px;
`;

export const OptionItem = styled(RowContainer)<IStyledProps>`
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey2 : p.theme.colors.transparent)};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: ${(p: IStyledProps) => (p.isSelected ? 1.0 : 0.8)};
  }
  height: 100%;
`;

export const Icon = styled(FontIcon)<IStyledProps>`
  color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.white : p.theme.colors.grey2)};
  font-size: 22px;
  line-height: 22px;
  width: 22px;
  height: 22px;
`;
