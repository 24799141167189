import { Api, contactActions, modalActions, notificationsActions, orderActions, orderService, sellerWorkspaceActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import OrderRequestDetails, {
  DispatchProps,
  HeritageProps,
  StateProps,
} from '../components/organisms/OrderRequestDetails';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: HeritageProps): StateProps {
  const {
    contact: { inConsentio },
    nav: { workspaceSelected },
    prodType,
    user: { user },
    workspace: { addresses}
  } = state;

  const prodTypes = prodType.prodTypes;
  const columnConfig = state.catalog.columnConfig[workspaceSelected];
  return {
    contacts: inConsentio,
    columnConfig,
    me: user,
    prodTypes,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      contactsInvite: contactActions.contactsInvite,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      requestAskForInformation: orderActions.requestAskForInformation,
      notificationShow: notificationsActions.notificationShow,
      tableVisibilityConfigGet: sellerWorkspaceActions.tableVisibilityConfigGet,
      touchImage: modalActions.touchImage
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderRequestDetails);
