import {
  __,
  contactActions,
  EventTrack,
  INVITE_ORIGIN,
  INVITE_VIA,
  modalActions,
  notificationsActions,
  validations,
  VIA,
} from 'common-services';
import parsePhoneNumberFromString, { AsYouType, CountryCode } from 'libphonenumber-js/mobile';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { getCountriesSelect, getNavigatorCountryCode, ILoginData } from '../../../constants';
import { copyToClipboard } from '../../../util/utils';
import { ColumnContainer, Input, RowContainer, Select } from '../../atoms';
import * as S from './InviteForm.styled';

export interface IProps {
  catalogId?: number;
  channelId?: string;
  className?: string;
  countries: { [key: string]: ICountry };
  defaultInviteBy?: INVITE_VIA.EMAIL | INVITE_VIA.SMS;
  from: 'onboarding' | 'invite-contacts';
  me: IUser;
  onClose: (contacts?: Array<IContact>) => void;
  origin: INVITE_ORIGIN;
  showAddressbook?: () => void;
  trackingFrom: string;
}

export const InviteForm: React.FC<IProps> = ({
  catalogId,
  channelId,
  className,
  countries,
  defaultInviteBy,
  from,
  me,
  onClose,
  origin,
  showAddressbook,
  trackingFrom,
}) => {
  const getInitialPhoneData: () => ILoginData = React.useCallback(() => {
    const selectedCountry = getNavigatorCountryCode(countries);
    return {
      country: selectedCountry ? selectedCountry.id : '',
      countryPrefix: selectedCountry ? selectedCountry.phonePrefix : '',
      countryCode: selectedCountry ? selectedCountry.iso2Code : '',
      phoneNumber: '',
      password: '',
    };
  }, [countries]);
  const [countriesSelect, setCountriesSelect] = React.useState<Array<{ label: string; value: string }>>(
    getCountriesSelect(countries),
  );
  const [emailNames, setEmailNames] = React.useState<Array<string>>(['']);
  const [emails, setEmails] = React.useState<Array<string>>(['']);
  const [errors, setErrors] = React.useState<Map<string, string>>(new Map());
  const [inviteBy, setInviteBy] = React.useState<INVITE_VIA.EMAIL | INVITE_VIA.SMS>(
    defaultInviteBy || INVITE_VIA.EMAIL,
  );
  const [inviteLinkToShare, setInviteLinkToShare] = React.useState<string>('');
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
  const [phones, setPhones] = React.useState<Array<ILoginData>>([getInitialPhoneData()]);
  const [phoneNames, setPhoneNames] = React.useState<Array<string>>(['']);
  const dispatch = useDispatch();
  const modalClose: typeof modalActions.modalClose = React.useCallback(
    () => dispatch(modalActions.modalClose()),
    [dispatch],
  );
  const modalOpen: typeof modalActions.modalOpen = React.useCallback(
    (text, action, extra, name) => dispatch(modalActions.modalOpen(text, action, extra, name)),
    [dispatch],
  );
  const contactsInvite = React.useCallback(
    (
      myId: number,
      via: VIA,
      platform: IPlatform,
      invitedUsers: Array<{
        name: string;
        email: string;
        phone: string;
        counterpart_id: string;
      }>,
      cb?: (
        data?: {
          errors?: Array<string>;
          contacts?: Array<IContact>;
          inviteLink?: string;
        },
        error?: Error,
      ) => void,
      trackingData?: {
        viaAgenda?: boolean;
        from?: string;
      },
      messageId?: number,
    ) =>
      dispatch(
        contactActions.contactsInvite(
          myId,
          via,
          platform,
          origin,
          invitedUsers,
          cb,
          channelId,
          catalogId,
          trackingData,
          messageId,
        ) as any,
      ),
    [dispatch],
  );
  const notificationShow: typeof notificationsActions.notificationShow = React.useCallback(
    (notification, milliseconds) => dispatch(notificationsActions.notificationShow(notification, milliseconds) as any),
    [dispatch],
  );

  React.useEffect(() => {
    setCountriesSelect(getCountriesSelect(countries));
  }, [countries]);

  const onAddMore = React.useCallback(() => {
    if (inviteBy === 'email') {
      const emailsCopy = emails.slice();
      emailsCopy.push('');
      const emailNamesCopy = emailNames.slice();
      emailNamesCopy.push('');
      setEmails(emailsCopy);
      setEmailNames(emailNamesCopy);
    } else {
      const phonesCopy = phones.slice();
      phonesCopy.push(getInitialPhoneData());
      const phoneNamesCopy = phoneNames.slice();
      phoneNamesCopy.push('');
      setPhones(phonesCopy);
      setPhoneNames(phoneNamesCopy);
    }
  }, [emailNames, emails, getInitialPhoneData, inviteBy, phoneNames, phones]);

  function renderEmailInput(email: string, index: number) {
    const nameValue = emailNames[index] || '';
    return (
      <S.InputRow key={`email-${index}`}>
        <S.InputEmail
          value={email}
          name={`email-${index}`}
          placeholder={__('Components.InviteOnboarding.2.email_placeholder')}
          onChange={(name, value) => {
            const emailsCopy = emails.slice();
            emailsCopy[index] = value + '';
            setEmails(emailsCopy);
            if (value) {
              setError(name, validations.validateEmail(value + '') ? '' : 'validation');
            } else {
              setError(name, '');
            }
          }}
          hasError={errors.has(`email-${index}`)}
        />
        <S.InputName
          value={nameValue}
          name={`email-name-${index}`}
          placeholder={__('Components.InviteOnboarding.2.name_placeholder')}
          onChange={(name, value) => {
            const emailNamesCopy = emailNames.slice();
            emailNamesCopy[index] = value + '';
            setEmailNames(emailNamesCopy);
          }}
        />
      </S.InputRow>
    );
  }

  function renderPhoneInput(phoneData: ILoginData, index: number) {
    const { country, phoneNumber, countryCode, phoneValid } = phoneData;
    return (
      <S.PhoneInputContainer key={`phone-input-${index}`}>
        <Select
          containerMargin="0"
          hasError={!!errors.get(`phone-country-${index}`)}
          menuWidth="max-content"
          name="country"
          onChange={(name, val) => {
            updatePhoneData(name, val, index, data => validatePhone(index, data));
          }}
          options={countriesSelect}
          width="33%"
          placeholder={__('Components.Onboarding.LoginPhoneEmail.country_placeholder')}
          value={country}
        />
        <Input
          containerMargin="0 6px"
          hasError={!!errors.get(`phone-number-${index}`)}
          isRequired={true}
          name="phoneNumber"
          onChange={(name, value) => {
            updatePhoneData(
              name,
              new AsYouType(countryCode as CountryCode).input(value.toString().replace(/\D/g, '')),
              index,
              data => validatePhone(index, data),
            );
          }}
          maxLength={15}
          placeholder={__('Components.Onboarding.LoginPhoneEmail.phoneNumber_placeholder')}
          type="text"
          value={phoneNumber}
          width="33%"
        />
        <Input
          key={`phone-name-${index}`}
          value={phoneNames[index] || ''}
          name={`phone-name-${index}`}
          placeholder={__('Components.InviteOnboarding.2.name_placeholder')}
          onChange={(name, value) => {
            const phoneNamesCopy = phoneNames.slice();
            phoneNamesCopy[index] = value + '';
            setPhoneNames(phoneNamesCopy);
          }}
          width="33%"
        />
      </S.PhoneInputContainer>
    );
  }

  const renderCtaAndSkip = (disabled: boolean) => {
    return (
      <S.CTARow>
        <S.CTA type="principal" className="cta-send-invite" disabled={disabled} onClick={onSubmit}>
          {__('Components.InviteOnboarding.2.cta')}
        </S.CTA>
        <S.CTASkip type="skip" className="cta-skip-invite" onClick={onSkip}>
          {__('Components.InviteOnboarding.2.skip')}
        </S.CTASkip>
      </S.CTARow>
    );
  };

  const onSubmit = React.useCallback(() => {
    contactsInvite(
      me.id!,
      inviteBy === 'email' ? VIA.EMAIL : VIA.SMS,
      'web',
      inviteBy === 'email'
        ? emails.reduce((acc, email, index) => {
            if (email) {
              acc.push({
                name: emailNames[index] || email,
                email,
                phone: '',
                counterpart_id: '',
              });
            }
            return acc;
          }, [])
        : phones.reduce((acc, phone, index) => {
            if (phone.phoneValid) {
              const phoneClean = phone.phoneParsed?.number.replace(/\D/g, '') || '';
              acc.push({
                name: phoneNames[index] || phoneClean,
                email: '',
                phone: phoneClean,
                counterpart_id: '',
              });
            }
            return acc;
          }, []),
      (data, error) => {
        if (data && !error) {
          notificationShow(
            {
              style: 'info',
              title: __('Components.InviteOnboarding.2.contacts_invite'),
              subtitle: __('Components.InviteOnboarding.2.invited', { count: data.contacts?.length || 0 }),
              closable: true,
            },
            5000,
          );
          onClose(data.contacts);
        }
      },
      { viaAgenda: false, from: trackingFrom },
    );
  }, [contactsInvite, emailNames, emails, inviteBy, me, notificationShow, onClose, phoneNames, phones]);

  const onSkip = React.useCallback(() => {
    modalOpen(
      __('Components.InviteOnboarding.skip_modal.title'),
      () => {
        const { email, country, phone } = me;
        let extraTrack = {};
        if (email) {
          extraTrack = { ...extraTrack, email };
        }
        if (phone) {
          extraTrack = { ...extraTrack, phone: `${country}${phone}` };
        }
        EventTrack.track('onboard_invite_skip', extraTrack);
        onClose();
        modalClose();
      },
      {
        showCancelButton: true,
        buttonCancelText: __('Components.InviteOnboarding.skip_modal.invite'),
        text2: me.settings.isSeller
          ? __('Components.InviteOnboarding.skip_modal.description.seller')
          : __('Components.InviteOnboarding.skip_modal.description.buyer'),
        buttonText: __('Components.InviteOnboarding.skip_modal.skip'),
      },
      'nice',
    );
  }, [me, modalClose, modalOpen, onClose]);

  const onCopyLink = React.useCallback(() => {
    contactsInvite(
      me.id!,
      VIA.LINK,
      'web',
      undefined,
      (data, error) => {
        if (data?.inviteLink && !error)
          setTimeout(() =>
            copyToClipboard(data.inviteLink, err => {
              setLinkCopied(!err);
              setInviteLinkToShare(data.inviteLink || '');
              setTimeout(() => setLinkCopied(false), 5000);
            }),
          );
      },
      { viaAgenda: false, from: trackingFrom },
    );
  }, [contactsInvite, me]);

  function updatePhoneData(name: string, value: string | number, index: number, cb: (data: ILoginData) => void) {
    const phonesCopy = phones.slice();
    const phoneData = phonesCopy[index];
    const selectedCountry = countries[name === 'country' ? value : phoneData.country];
    const phone = name === 'phoneNumber' ? value + '' : phoneData.phoneNumber;
    const parsed =
      phone && selectedCountry ? parsePhoneNumberFromString(phone, selectedCountry.iso2Code as CountryCode) : undefined;

    phonesCopy[index] = {
      ...phoneData,
      [name]: value,
      countryPrefix: name === 'country' && selectedCountry ? selectedCountry.phonePrefix : phoneData.countryPrefix,
      countryCode: selectedCountry ? selectedCountry.iso2Code : undefined,
      phoneParsed: parsed,
      phoneValid: parsed ? parsed.isValid() : false,
    };
    setPhones(phonesCopy);
    cb(phonesCopy[index]);
  }

  function validatePhone(index: number, phoneData: ILoginData) {
    setError(`phone-country-${index}`, phoneData.country ? '' : 'empty');
    setError(`phone-number-${index}`, phoneData.phoneNumber && !phoneData.phoneValid ? 'validation' : '');
  }

  function setError(key: string, error?: string) {
    const cloneErrors = new Map(errors);
    if (error) {
      cloneErrors.set(key, error);
    } else {
      cloneErrors.delete(key);
    }
    setErrors(cloneErrors);
  }

  const hasWrittenEmail =
    inviteBy === INVITE_VIA.EMAIL &&
    emails.reduce((acc, email) => {
      if (email) acc = true;
      return acc;
    }, false);
  const hasWrittenPhone =
    inviteBy === INVITE_VIA.SMS &&
    phones.reduce((acc, phone) => {
      if (phone.phoneNumber && phone.country) acc = true;
      return acc;
    }, false);
  const hasFilledData =
    (inviteBy === INVITE_VIA.EMAIL && hasWrittenEmail) || (inviteBy === INVITE_VIA.SMS && hasWrittenPhone);
  const ctaDisabled = errors.size > 0 || !hasFilledData;
  return (
    <S.Container className={className}>
      <ColumnContainer>
        <S.FormContainer>
          {!hasFilledData ? (
            <S.TextLink
              className="link-invite-by"
              onClick={() => {
                setInviteBy(inviteBy === INVITE_VIA.SMS ? INVITE_VIA.EMAIL : INVITE_VIA.SMS);
                setErrors(new Map());
              }}
            >
              {inviteBy === 'sms'
                ? __('Components.InviteOnboarding.2.invite_email')
                : __('Components.InviteOnboarding.2.invite_sms')}
            </S.TextLink>
          ) : null}
          {inviteBy === 'sms'
            ? phones.map((phone, idx) => renderPhoneInput(phone, idx))
            : emails.map((email, idx) => renderEmailInput(email, idx))}
        </S.FormContainer>
        <S.OptionsRow>
          <S.AddMoreButton type="link" onClick={onAddMore} className="cta-add-more" iconName="Add-more" iconSize="20px">
            {__('Components.InviteOnboarding.2.add_more')}
          </S.AddMoreButton>

          <S.TextRegular>
            {linkCopied ? (
              <RowContainer>
                <S.CheckIcon name="Check" disableHover={true} />
                <S.TextRegular>{__('Components.Header.LinkCopied')}</S.TextRegular>
              </RowContainer>
            ) : (
              <>
                <S.TextPurpleLink onClick={onCopyLink} className="link-copy-invite">
                  {__('Components.InviteOnboarding.2.copy_link') + ' '}
                </S.TextPurpleLink>
                {__('Components.InviteOnboarding.2.copy_link_2')}
              </>
            )}
          </S.TextRegular>
        </S.OptionsRow>
        {from === 'onboarding' ? renderCtaAndSkip(ctaDisabled) : <S.EmptySpace />}
      </ColumnContainer>

      <S.FooterContainer>
        {from === 'onboarding' ? (
          <S.TextRegular>{__('Components.InviteOnboarding.2.other_ways')}</S.TextRegular>
        ) : (
          <S.DividerContainer>
            <S.DividerBar />
            <S.TextGrey>{__('Components.Onboarding.LoginPhoneEmail.or')}</S.TextGrey>
            <S.DividerBar />
          </S.DividerContainer>
        )}
        <S.ShareInvite
          catalogId={catalogId}
          channelId={channelId}
          className="onboarding-share-invite"
          from={from}
          me={me}
          origin={origin}
        />

        {from === 'invite-contacts' ? (
          <S.Buttons>
            {showAddressbook ? (
              <S.CTA disabled={!ctaDisabled} type="secondary" onClick={showAddressbook}>
                {__('Components.ChatList.invite.other.cta_addressbook')}
              </S.CTA>
            ) : (
              <div />
            )}
            <S.CTA type="principal" className="cta-send-invite" disabled={ctaDisabled} onClick={onSubmit}>
              {__('Components.InviteOnboarding.2.cta')}
            </S.CTA>
          </S.Buttons>
        ) : null}
      </S.FooterContainer>
    </S.Container>
  );
};

export default React.memo(InviteForm);
