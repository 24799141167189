import { constants } from 'common-services';

import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, ImageCover, TextLabel } from '../../atoms';

interface IStyledProps {
  hasImage?: boolean;
  theme: ITheme;
  bColor?: string;
}

export const SectionCard = styled(ColumnContainer)`
  min-width: 230px;
  max-width: 230px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  overflow: hidden;
  cursor: pointer;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 168px;
    width: calc(33vw - 54px);
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: calc(50vw - 36px);
  }
`;
export const SectionImage = styled(ImageCover)`
  width: 100%;
  height: 105.8px;
  object-fit: cover;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    height: calc((33vw - 66px) * ${constants.IMAGE_RELATION.SECTION});
    max-height: 105.8px;
    min-height: 77.3px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: calc((50vw - 48px) * ${constants.IMAGE_RELATION.SECTION});
  }
`;
export const SectionText = styled(TextLabel)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  min-height: 71px;
  max-height: 71px;
`;
