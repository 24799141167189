import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  borderRadius?: string;
  height?: string;
  margin?: string;
  theme: ITheme;
  width?: string;
  widthFixed?: boolean;
}

const Skeleton = styled.div`
  background: linear-gradient(
    270deg,
    ${(p: IStyledProps) => p.theme.colors.grey4},
    ${(p: IStyledProps) => p.theme.colors.grey3}
  );
  background-size: 400% 400%;
`;

export const SkeletonItem = styled(Skeleton)<IStyledProps>`
  width: ${(p: IStyledProps) => p.width};
  height: ${(p: IStyledProps) => p.height};
  border-radius: ${(p: IStyledProps) => p.borderRadius || p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.margin || '0'};
  ${(p: IStyledProps) =>
    p.widthFixed &&
    css`
      min-width: ${p.width};
      max-width: ${p.width};
    `};
`;
