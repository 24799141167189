import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  color?: string;
  hAlign?: 'right' | 'left';
  isLast?: boolean;
  isLink?: boolean;
  itemMinWidth?: string;
  margin?: string;
  numOptions?: number;
  position?: 'bottom' | 'top';
  theme: ITheme;
  vAlign?: 'center' | 'flex-start' | 'flex-end';
  width?: string;
}

export const Container = styled.div`
  padding: 0;
  align-self: ${(p: IStyledProps) => p.vAlign || 'center'};
  position: relative;
  margin: ${(p: IStyledProps) => p.margin || 0};
  ${(p: IStyledProps) =>
    p.width &&
    css`
      width: ${p.width};
    `}
`;

export const Trigger = styled.div`
  margin: 0;
  padding: 0;
  align-self: center;
  position: relative;
`;

export const Content = styled.ul<IStyledProps>`
  position: absolute;
  ${(p: IStyledProps) =>
    p.position === 'top' &&
    css`
      top: calc(-5px - ${p.numOptions} * 30px);
    `}
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 'max-content';
  padding: 3px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  left: 'inherit';
  right: 'inherit';
  z-index: 2;
`;

export const Item = styled.li<IStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-width: ${(p: IStyledProps) => p.itemMinWidth || 'max-content'};
  width: 100%;
  text-align: ${(p: IStyledProps) => p.hAlign};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ItemText = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const ItemTitle = styled.div<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const ItemSubtitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const IconItem = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: 15px;
  height: 15px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
