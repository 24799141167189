import {
  Api,
  broadcastActions,
  CHANNEL_DISPLAY,
  chatActions,
  chatService,
  contactActions,
  imageActions,
  modalActions,
  notificationsActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as navActions from '../actions/nav';
import ChatInfo, { DispatchProps, OwnProps, StateProps } from '../components/organisms/ChatInfo';
import { IReduxState } from '../reducers';

const emptyChannel: IChannel = chatService.emptyChannel();

const emptyBroadcast: IBroadcast = {
  avatarColor: { background: '', text: '' },
  createdAt: 0,
  display: CHANNEL_DISPLAY.NORMAL,
  id: '',
  invitePolicy: 'admin',
  lastMessageAtOld: 0,
  members: {},
  name: '',
  numberOfMembers: 0,
  updatedAt: 0,
};

function mapStateToPropsDetails(state: IReduxState, props: OwnProps): StateProps {
  const {
    broadcast: { broadcasts },
    catalog: { catalogs },
    contact: { inConsentio },
    chat: { channels },
    user: { user },
    contact: { hasOutContacts },
  } = state;
  const cId = props.match.params.channelId;
  let broadcast = emptyBroadcast;
  let channel = emptyChannel;
  if (props.isBroadcast) {
    const bId = Number(cId);
    if (bId) {
      broadcast = broadcasts[bId] || emptyBroadcast;
    }
  } else if (cId) {
    channel = channels[cId] || emptyChannel;
  }
  return {
    broadcast,
    catalogs,
    channel,
    channels,
    contacts: inConsentio,
    hasOutContacts,
    me: user,
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: OwnProps,
): DispatchProps {
  return bindActionCreators(
    {
      addBroadcastMembers: broadcastActions.addMembers,
      addMembers: chatActions.addMembers,
      channelArchive: chatActions.channelArchive,
      channelLeave: chatActions.channelLeave,
      channelMute: chatActions.channelMute,
      createNewContact: contactActions.createNewContact,
      contactsAddressbookGet: contactActions.contactsAddressbookGet,
      contactsInvite: contactActions.contactsInvite,
      deleteBroadcastMembers: broadcastActions.deleteMembers,
      deleteMembers: chatActions.deleteMembers,
      deleteWorkspaceToChannel: chatActions.deleteWorkspaceToChannel,
      getMembers: broadcastActions.getMembers,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelByPath: navActions.navigateChannelByPath,
      setWorkspaceToChannel: chatActions.setWorkspaceToChannel,
      notificationShow: notificationsActions.notificationShow,
      updateChannel: chatActions.updateChannel,
      updateMember: chatActions.updateMember,
      updateBroadcast: broadcastActions.updateBroadcast,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(ChatInfo);
