import * as React from 'react';
import * as S from './WorkspaceOfferSeller.styled';
import { ColumnContainer, SimpleDropdown } from '../../atoms';
import { Availability, DepartureAndDeliveredPrice } from '../../molecules/OfferedProductTable/ConstantsAndColumnsDeparturePrice';
import { Availability as ava, DepartureAndDeliveredPrice as depa } from '../../molecules/OfferedProductTable/ConstantsAndColumns';

interface DropdownAvailabilityAndPriceProps {
  setAvailability: (value: React.SetStateAction<Availability>) => void;
  availability: Availability;
  setDepartureAndDeliveredPrice: (value: React.SetStateAction<DepartureAndDeliveredPrice>) => void;
  departureAndDeliveredPrice: DepartureAndDeliveredPrice;
  __: (scope: I18n.Scope, options?: I18n.TranslateOptions) => string;
}

const DropdownAvailabilityAndPrice: React.FC<DropdownAvailabilityAndPriceProps> = ({
  setAvailability,
  availability,
  setDepartureAndDeliveredPrice,
  departureAndDeliveredPrice,
  __,
}) => {
  return (
    <S.divider>
      <S.DropDownRow>
        <ColumnContainer>
          <SimpleDropdown
            hAlign="left"
            noPadding
            options={[
              {
                key: 'total',
                value: __('Components.OffersList.availability.total'),
                isSelected: availability === 'total',
              },
              {
                key: 'address',
                value: __('Components.OffersList.availability.address'),
                isSelected: availability === 'address',
              },
            ]}
            onSelect={(key: Availability) => setAvailability(key)}
          >
            <S.ChatButton>
              {__(`Components.OffersList.availability.${availability}`)}
              <S.FontDownIcon name="Down" onClick={() => null} disableHover={true} />
            </S.ChatButton>
          </SimpleDropdown>
        </ColumnContainer>
        <ColumnContainer>
          <SimpleDropdown
            hAlign="left"
            noPadding
            options={[
              {
                key: DepartureAndDeliveredPrice.departureAndDeliveredPrice,
                value: __('Components.OffersList.price.departureAndDeliveredPrice'),
                isSelected: departureAndDeliveredPrice === DepartureAndDeliveredPrice.departureAndDeliveredPrice,
              },
              {
                key: DepartureAndDeliveredPrice.departurePrice,
                value: __('Components.OffersList.price.departurePrice'),
                isSelected: departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice,
              },
              {
                key: DepartureAndDeliveredPrice.deliveredPrice,
                value: __('Components.OffersList.price.deliveredPrice'),
                isSelected: departureAndDeliveredPrice === DepartureAndDeliveredPrice.deliveredPrice,
              },
            ]}
            onSelect={(key: DepartureAndDeliveredPrice) => setDepartureAndDeliveredPrice(key)}
          >
            <S.ChatButton>
              {__(`Components.OffersList.price.${departureAndDeliveredPrice}`)}
              <S.FontDownIcon name="Down" onClick={() => null} disableHover={true} />
            </S.ChatButton>
          </SimpleDropdown>
        </ColumnContainer>
      </S.DropDownRow>
    </S.divider>
  );
};

export default DropdownAvailabilityAndPrice;
