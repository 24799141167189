import { __, constants, LOCALE } from 'common-services';
import * as React from 'react';

import { getDeepLink } from '../../../services/link';
import * as S from './LandingFooter.styled';
import { getAboutUsLink, getAskDemoLink, getConsentioLink } from './LandingFooter.utils';

export interface IProps {
  className?: string;
  inviteHash?: string;
  lang: LOCALE;
  trackEvent?: (key: string, url: string) => void;
}

const LandingFooter: React.FC<IProps> = ({ className, inviteHash, lang, trackEvent }) => {
  return (
    <S.Footer className={className}>
      <S.Content>
        <S.Link
          target="_blank"
          href={getConsentioLink(lang)}
          onClick={() => trackEvent?.('what_is_consentio', getAboutUsLink(lang))}
          rel="noopener noreferrer"
        >
          {__('LoginLanding.footer.what_is_consentio', { locale: lang })}
        </S.Link>
        <S.Link
          onClick={() => {
            trackEvent?.('register', window.location.pathname);
            window.location.href = getDeepLink(`/register?lang=${lang}`, undefined, inviteHash);
          }}
        >
          {__('LoginLanding.footer.create_account', { locale: lang })}
        </S.Link>
        <S.Link
          onClick={() => {
            trackEvent?.('login', window.location.pathname);
            window.location.href = getDeepLink(`/login`, undefined, inviteHash);
          }}
        >
          {__('LoginLanding.footer.begin_session', { locale: lang })}
        </S.Link>
        <S.Link
          target="_blank"
          href={getAboutUsLink(lang)}
          onClick={() => trackEvent?.('about_us', getAboutUsLink(lang))}
          rel="noopener noreferrer"
        >
          {__('LoginLanding.footer.about_us', { locale: lang })}
        </S.Link>
        <S.Link
          target="_blank"
          href="mailto:support@consentio.co"
          onClick={() => trackEvent?.('support', 'mailto:support@consentio.co')}
          rel="noopener noreferrer"
        >
          {__('LoginLanding.footer.contact_support', { locale: lang })}
        </S.Link>
        <S.Link
          href={getAskDemoLink(lang)}
          onClick={() => trackEvent?.('demo', getAskDemoLink(lang))}
          rel="noopener noreferrer"
          target="_blank"
        >
          {__('LoginLanding.footer.ask_demo', { locale: lang })}
        </S.Link>
        <S.FooterRight>
          <S.SocialNetworks>
            {constants.getFooterSocials().map((social, i) => (
              <S.Link
                target="_blank"
                href={social.link}
                key={i + '_' + social.alt}
                onClick={() => trackEvent?.(social.alt, social.link)}
                rel="noopener noreferrer"
              >
                <S.SocialNetworkImage src={social.icon} alt={social.alt} width="32px" height="32px" />
              </S.Link>
            ))}
          </S.SocialNetworks>
        </S.FooterRight>
      </S.Content>
    </S.Footer>
  );
};

export default React.memo(LandingFooter);
