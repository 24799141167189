import styled, { ITheme } from '../../../../../styled-components';
import { Button, CheckBox as CB, ColumnContainer, FontIcon, RowContainer } from '../../../../atoms';
import Input from '../../../../atoms/Input';

interface IStyledProps {
  theme: ITheme;
  paddingMultiplier?: number;
}

export const Container = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  max-width: min(700px, 100%);
  width: min(700px, 100%);
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: auto;
  position: relative;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Row = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(p.paddingMultiplier || 2)};
`;

const BaseText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  align-self: center;
  width: 100%;
`;

const Title = styled(BaseText)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  text-align: left;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingView};
  line-height: 1.6;
`;

export const BigText = styled(BaseText)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const CheckBox = styled(CB)``;

export const CheckBoxText = styled(BaseText)`
  padding-left: 10px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const AcceptButton = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  align-self: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TextInput = styled(Input)``;
