import styled, { ITheme } from "../../../styled-components";
import { FontIcon, TextLabel, Input } from "../../atoms";

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  backgroundColorDisabled?: string;
  showColor?: boolean;
  disabled?: boolean;
  enabled?: boolean;
}

const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
`;

export const TitleText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  display: inline;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Icon = styled(FontIcon)`
  justify-content: center;
  align-items: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  background: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.theme.paddingSize()};
  display: inline-flex;
  padding: 5px 7px;
  color: ${(p: IStyledProps) => p.color || p.theme.colors.grey1};
  background: ${(p: IStyledProps) =>
    p.enabled
      ? p.backgroundColor
        ? p.backgroundColor
        : p.theme.colors.grey3
      : p.backgroundColorDisabled
      ? p.backgroundColorDisabled
      : p.theme.colors.grey4};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: ${(p: IStyledProps) => (p.color ? `1px solid ${p.color}` : `none`)};
  cursor: ${(p: IStyledProps) => (p.enabled ? "pointer" : "not-allowed")};
`;

export const DefaultInput = styled(Input)`
  display: inline-flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
