import * as Sentry from '@sentry/react';
import {
  IReduxStateAssociateReference,
  associateReferenceReducer,
  broadcastReducer,
  buyerWorkspaceReducer,
  chatReducer,
  contactReducer,
  countryReducer,
  enableReplace,
  hydrateReducer,
  mappingReducer,
  modalReducer,
  notificationsReducer,
  orderReducer,
  prodTypeReducer,
  productReducer,
  sellerWorkspaceReducer,
  setStorage,
  tagReducer,
  userReducer,
} from 'common-services';
import localforage from 'localforage';
import { routerReducer, RouterState } from 'react-router-redux';
import { combineReducers } from 'redux';

import nav, { IReduxStateNav } from './nav';
import notification, { IReduxStateNotification as IReduxStatePermission } from './notification';
import pricelist, { IReduxStatePricelist } from './pricelist';

export interface IReduxState {
  _persist: IReduxPersist;
  broadcast: IReduxStateBroadcast;
  // catalog: Omit<IReduxStateCatalog, 'clients'>;
  catalog: IReduxStateCatalog;
  chat: IReduxStateChat;
  contact: IReduxStateContact;
  country: IReduxStateCountry;
  mapping: IReduxStateMapping;
  modal: IReduxStateModal;
  nav: IReduxStateNav;
  notification: IReduxStatePermission;
  notifications: IReduxStateNotification;
  order: IReduxStateOrder;
  pricelist: IReduxStatePricelist;
  prodType: IReduxStateProdType;
  product: IReduxStateProduct;
  routing: RouterState;
  tag: IReduxStateTag;
  user: IReduxStateUser;
  workspace: IReduxStateWorkspace;
  associateReference: IReduxStateAssociateReference;
}

export const storage: IStorage =
  typeof window !== 'undefined'
    ? {
        setItem: (key: string, value: string): Promise<string> => {
          return localforage.setItem(key, value);
        },
        getItem: (key: string): Promise<string> => {
          return localforage.getItem(key);
        },
        removeItem: (key: string): Promise<void> => {
          return localforage.removeItem(key);
        },
        clearAll: () => localforage.clear(),
      }
    : {
        setItem: (key: string, value: string): Promise<string> => Promise.resolve(''),
        getItem: (key: string): Promise<string> => Promise.resolve(''),
        removeItem: (key: string): Promise<void> => Promise.resolve(),
        clearAll: () => Promise.resolve(),
      };

// Sentry transaction tracker (disabled for next)
export const tracker =
  typeof window !== 'undefined'
    ? {
        startTransaction: (name: string) => Sentry.startTransaction({ name, sampled: true }),
      }
    : undefined;
setStorage(storage);

export default combineReducers({
  _persist: hydrateReducer,
  broadcast: broadcastReducer.default,
  catalog: sellerWorkspaceReducer.default,
  chat: enableReplace(chatReducer.default, {
    name: 'chat',
    transform: (c: any) => ({
      ...c,
      channels: {},
      drafts: {},
      initialized: false,
      messages: {},
      threads: {},
    }),
    encrypt: true,
    tracker,
    splitPersist: ['channels'],
    delay: 5000,
  }),
  contact: enableReplace(contactReducer.default, {
    name: 'contact',
    encrypt: true,
    tracker,
  }),
  country: enableReplace(countryReducer.default, {
    name: 'country',
    tracker,
  }),
  mapping: mappingReducer.default,
  modal: modalReducer.default,
  nav: enableReplace(nav, {
    name: 'nav',
    transform: (c: any) => ({
      ...c,
      bWsInitialized: false,
      sWsInitialized: false,
    }),
    encrypt: true,
    tracker,
    delay: 500,
  }),
  notification,
  order: enableReplace(orderReducer.default, {
    name: 'order',
    transform: (c: any) => ({
      ...orderReducer.ORDER_INITIAL_STATE,
      cart: c.cart,
    }),
    encrypt: true,
    tracker,
    delay: 5000,
  }),
  pricelist,
  prodType: enableReplace(prodTypeReducer.default, {
    name: 'prodType',
    tracker,
  }),
  product: productReducer.default,
  routing: routerReducer,
  tag: tagReducer.default,
  user: enableReplace(userReducer.default, {
    name: 'user',
    encrypt: true,
    tracker,
  }),
  notifications: notificationsReducer.default,
  workspace: buyerWorkspaceReducer.default,
  associateReference: associateReferenceReducer.default,
});
