import { constants } from 'common-services';

const IMAGES = {
  acceptAuto: getCloudinaryURL('v1578667465/aut.jpg'),
  acceptManual: getCloudinaryURL('v1579857484/hand.png'),
  acceptOrderPdf: getCloudinaryURL('v1594646899/logos/descarga.jpg', ['q_auto', 'w_200']),
  acceptOrderTxt: getCloudinaryURL('v1594646743/logos/text-file-outlined-interface-symbol_icon-icons.com_57502.png', [
    'q_auto',
    'w_200',
  ]),
  acceptOrderXls: getCloudinaryURL('v1594646971/logos/excel-logo-250.png', ['q_auto', 'w_200']),
  alert: getCloudinaryURL('v1613062273/ilustrations/Advertencia-25.png'),
  appStoreBanner: `${constants.MEDIA_URL}/v1589440096/logos/z_store_apple.svg`,
  buyerWorkspaceZeroCase: getCloudinaryURL('/v1626945960/ilustrations/create_buyer_workspace.png'),
  sellerWholesaleZeroCase: getCloudinaryURL('/v1641830463/ilustrations/Wholesale.png'),
  cart: getCloudinaryURL('v1622128656/ilustrations/cart.png'),
  camera: getCloudinaryURL('v1588240574/ilustrations/Camera.png'),
  chatUser: getCloudinaryURL('v1579868446/an%CC%83adir_user.png', ['f_auto', 'w_350', 'c_limit']),
  checkOK: getCloudinaryURL('v1580911843/ok.png'),
  consentioLogo: getCloudinaryURL('v1592989631/logos/FR-square-logo-black-color_copia.png'),
  consentioLogoBlack: getCloudinaryURL('v1589440095/logos/consentio-logo-black.png'),
  consentioLogoC: getCloudinaryURL('v1600158782/logos/consentio-c.png'),
  consentioLogoWhite: getCloudinaryURL('v1638549391/login/en_logo_white.svg'),
  consentioLogoWhiteJPG: getCloudinaryURL('v1588844507/logos/txgieaahtgoaxia7jm76.jpg'),
  consentioProDashboardsMain: getCloudinaryURL('v1605721592/ilustrations/Componente_407_1.png'),
  consentioProOrderImportMain: getCloudinaryURL('v1605721545/ilustrations/Componente_405_1.png'),
  consentioProPricelistMain: getCloudinaryURL('v1605721626/ilustrations/Componente_404_1.png'),
  consentioProSidebarDashboards: getCloudinaryURL('v1605260755/ilustrations/Componente_400_1.png'),
  consentioProSidebarDashboardsBN: getCloudinaryURL('v1605260755/ilustrations/Componente_397_5.png'),
  consentioProSidebarOrderImport: getCloudinaryURL('v1605260755/ilustrations/Componente_396_1.png'),
  consentioProSidebarOrderImportBN: getCloudinaryURL('v1605260755/ilustrations/Componente_158_36.png'),
  consentioProSidebarPricelist: getCloudinaryURL('v1605259947/ilustrations/Componente_395_1.png'),
  consentioProSidebarPricelistBN: getCloudinaryURL('v1605260755/ilustrations/Componente_155_31.png'),
  consentioProSidebarTeams: getCloudinaryURL('v1605260755/ilustrations/Componente_397_1.png'),
  consentioProSidebarTeamsBN: getCloudinaryURL('v1605260754/ilustrations/Componente_395_2.png'),
  consentioProTeamsMain: getCloudinaryURL('v1605721665/ilustrations/Componente_406_1.png'),
  contactsAdded: getCloudinaryURL('v1615468877/ilustrations/Eggplant_party.png', ['c_scale', 'w_260']),
  conversation: getCloudinaryURL('v1625584185/ilustrations/convesation.png'),
  ediCarrot: getCloudinaryURL('v1667912135/ilustrations/edi_carrot.png'),
  email: getCloudinaryURL('v1587390718/mail.png'),
  emailCheck: getCloudinaryURL('v1588240574/ilustrations/Email_verification_-_Confirmed_-.png'),
  emptyBox: getCloudinaryURL('v1623238172/ilustrations/empty_cart.png'),
  facebook: getCloudinaryURL('v1587390732/facebook.png'),
  facebookLogo: getCloudinaryURL('v1587390732/facebook.png'),
  features: {
    catalogOnline: getCloudinaryURL('v1643020367/landings/catalog_online.png', ['c_scale', 'w_1000']),
    chatList: getCloudinaryURL('v1643020367/landings/chat_list.png', ['c_scale', 'w_1000']),
    comments: getCloudinaryURL('v1643040126/landings/comments.png', ['c_scale', 'w_400']),
    dashboards: getCloudinaryURL('v1643020367/landings/dashboards.png', ['c_scale', 'w_400']),
    magicOrders: {
      en: getCloudinaryURL('v1643036607/landings/magic_orders_en.png', ['c_scale', 'w_1000']),
      es: getCloudinaryURL('v1643036607/landings/magic_orders_es.png', ['c_scale', 'w_1000']),
      fr: getCloudinaryURL('v1643036607/landings/magic_orders_fr.png', ['c_scale', 'w_1000']),
    },
    multiplatform: getCloudinaryURL('v1643020368/landings/multiplatform.png', ['c_scale', 'w_1000']),
    multiplatformCard: getCloudinaryURL('v1643020367/landings/mini_multiplatform.png', ['c_scale', 'w_400']),
    outOfOffice: getCloudinaryURL('v1643020368/landings/out_of_office.png', ['c_scale', 'w_1000']),
    pricegroups: getCloudinaryURL('v1643020367/landings/mini_pricegroups.png', ['c_scale', 'w_400']),
    pro: getCloudinaryURL('v1643020368/landings/pro.png', ['c_scale', 'w_400']),
    providers: getCloudinaryURL('v1643020368/landings/providers.png', ['c_scale', 'w_1000']),
    purchases: getCloudinaryURL('v1643020368/landings/purchases.png', ['c_scale', 'w_1000']),
    request: getCloudinaryURL('v1643020368/landings/request.png', ['c_scale', 'w_1000']),
    saveThePlanet: {
      en: getCloudinaryURL('v1643037654/landings/save_the_planet_en.png', ['c_scale', 'w_1000']),
      es: getCloudinaryURL('v1643037654/landings/save_the_planet_es.png', ['c_scale', 'w_1000']),
      fr: getCloudinaryURL('v1643037654/landings/save_the_planet_fr.png', ['c_scale', 'w_1000']),
    },
    workspaceBuyerCard: getCloudinaryURL('v1643020367/landings/mini_ws_buyer.png', ['c_scale', 'w_400']),
    workspaceSellerCard: getCloudinaryURL('v1643020368/landings/mini_ws_seller.png', ['c_scale', 'w_400']),
  },
  fruits: {
    banana: getCloudinaryURL('v1654702172/ilustrations/Banana.png'),
    apple: getCloudinaryURL('v1654702172/ilustrations/Apple.png'),
    pineapple: getCloudinaryURL('v1654702172/ilustrations/Pineapple.png'),
    kiwi: getCloudinaryURL('v1654702172/ilustrations/Kiwi.png'),
    peach: getCloudinaryURL('v1654702172/ilustrations/Peach.png'),
  },
  googleLogo: getCloudinaryURL('v1639474528/social/google_logo.png'),
  googlePlayBanner: `${constants.MEDIA_URL}/v1589440095/logos/z_store_google.svg`,
  informativePineapple: getCloudinaryURL('v1623079777/ilustrations/informativepineapple.png'),
  landingMarketing1: getCloudinaryURL('v1601476146/login/Tiempo_y_agiliza.png'),
  landingMarketing2: getCloudinaryURL('v1601476139/login/Simplifica.png'),
  landingMarketing3: getCloudinaryURL('v1601476121/login/Negocio.png'),
  landingMarketing4: getCloudinaryURL('v1601476110/login/Compra_y_vende.png'),
  cautionGrape: getCloudinaryURL('v1628240183/ilustrations/caution_grapes.png'),
  linkedin: getCloudinaryURL('v1587390720/linkedin.png'),
  loginBackground: getCloudinaryURL('v1600164276/login/background_login-01-2500.png'),
  mappingCodeOrderImport: getCloudinaryURL('v1617965246/ilustrations/import_order.png'),
  messages: getCloudinaryURL('v1641830463/ilustrations/Message.png'),
  messagesGrey: getCloudinaryURL('v1648733796/ilustrations/Message_grey_background-61.png'),
  microsoftLogo: getCloudinaryURL('v1639580046/logos/logo_microsoft.png'),
  mockupLogin: getCloudinaryURL('v1600164956/login/Mockup_login-1000.png'),
  notificationsOff: getCloudinaryURL('v1619795688/ilustrations/notification_error.png'),
  notificationsPineapple: getCloudinaryURL('v1620380737/ilustrations/notificativepineapple.png'),
  placeMarker: getCloudinaryURL('v1629904396/ilustrations/place_marker.png'),
  previewPDFComfortable: getCloudinaryURL('v1617174771/previews/comfortable.png'),
  previewPDFCompact: getCloudinaryURL('v1617174779/previews/compact.png'),
  productCardFlagFavorite: getCloudinaryURL('v1600435384/ilustrations/Fovoritos-02.png'),
  productCardFlagRecent: getCloudinaryURL('v1600435468/ilustrations/Recientes.png'),
  productCardFlagRecommended: getCloudinaryURL('v1600435384/ilustrations/Recomendados-03.png'),
  productsNoResult: getCloudinaryURL('v1592480797/ilustrations/no-products.png'),
  ordersNoResult: getCloudinaryURL('v1631633072/ilustrations/no-products.png'),
  publicChannelThumbnailPlaceholder: getCloudinaryURL(
    'v1657090218/ilustrations/Channels/Default_additional_image_community.png',
  ),
  publicChannelDefault: {
    landscape: getCloudinaryURL('v1649085912/ilustrations/Groups_defaul_image.png', ['w_1200']),
    thumbnail: getCloudinaryURL('v1649085912/ilustrations/Groups_defaul_image.png', [
      'w_100',
      'ar_1:1',
      'c_fill',
      'g_center',
    ]),
  },
  reactions: {
    like: getCloudinaryURL('v1653587015/ilustrations/Channels/Like.png', ['w_250']),
    funny: getCloudinaryURL('v1653587015/ilustrations/Channels/Funny.png', ['w_250']),
    interesting: getCloudinaryURL('v1653587015/ilustrations/Channels/Interesting.png', ['w_250']),
    go: getCloudinaryURL('v1653587015/ilustrations/Channels/Go_for_it.png', ['w_250']),
    love: getCloudinaryURL('v1653587015/ilustrations/Channels/Love_it.png', ['w_250']),
  },
  reportUser: getCloudinaryURL('v1588240575/ilustrations/Report_user.png'),
  sectionZeroCase: [
    getCloudinaryURL('v1600965469/ilustrations/Zero_care_sections1.png'),
    getCloudinaryURL('v1600965469/ilustrations/Zero_case_section2.png'),
    getCloudinaryURL('v1600965469/ilustrations/Zero_case_section3.png'),
    getCloudinaryURL('v1600965469/ilustrations/Zero_case_section4.png'),
    getCloudinaryURL('v1600965469/ilustrations/Zero_case_section5.png'),
  ],
  sectionDefaultCase: [
    getCloudinaryURL('v1621591666/ilustrations/section_default_image.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_1.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_2.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_3.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_4.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_5.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_6.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_7.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_8.png'),
    getCloudinaryURL('v1621591666/ilustrations/section_default_image_9.png'),
  ],
  sendOrder: getCloudinaryURL('v1622542622/ilustrations/send_order.png'),
  welcome: getCloudinaryURL('v1611243688/ilustrations/Bienvenida.png'),
  warningGrape: getCloudinaryURL('v1632913769/ilustrations/cry_advertence.png'),
  whatsapp: getCloudinaryURL('v1587719223/social/Whatsapp.png'),
  unavailable: getCloudinaryURL('v1588946775/ilustrations/No_disponible-18.png'),
  mail: getCloudinaryURL('v1598606051/ilustrations/mail.png'),
  error: getCloudinaryURL('v1588240574/ilustrations/Error.png'),
  consentioLogo1: getCloudinaryURL('v1611847201/ilustrations/Consentio-logo.png'),
  addUser: getCloudinaryURL('v1588240573/ilustrations/Add_user.png'),
  blockUser: getCloudinaryURL('v1588240574/ilustrations/Block_user.png'),
  logo: getCloudinaryURL('v1589440097/logos/logo.svg'),
};

const orderImportAsset = {
  alby: {
    logo: getCloudinaryURL('v1606211479/logos/alby_logo.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/csd_alby_tooltip.png', ['f_auto', 'w_600']),
  },
  aldi: {
    logo: getCloudinaryURL('v1596625548/logos/aldi_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/aldi_tooltip.png', ['f_auto', 'w_600']),
  },
  auchan: {
    logo: getCloudinaryURL('v1603974219/logos/auchan_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787641/logos/tooltip/auchan_tooltip.png', ['f_auto', 'w_600']),
  },
  auchanEmail: {
    logo: getCloudinaryURL('v1614674205/logos/auchan_email_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/auchan_mail_tooltip.png', ['f_auto', 'w_600']),
  },
  auchanZelanco: {
    logo: getCloudinaryURL('v1623918087/logos/auchan_zenalco.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1623918047/logos/tooltip/auchan_zenalco_tooltip.png', ['f_auto', 'w_600']),
  },
  carrefourXls: {
    logo: getCloudinaryURL('v1593445827/logos/logo-carrefour-400.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/carrefour_xls_tooltip.png', ['f_auto', 'w_600']),
  },
  casino: {
    logo: getCloudinaryURL('v1603899051/logos/casino_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/casino_tooltip.png', ['f_auto', 'w_600']),
  },
  colruyt: {
    logo: getCloudinaryURL('v1626777942/logos/colruyt.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1626777897/logos/tooltip/colruyt_tooltip.png', ['f_auto', 'w_600']),
  },
  consentioExcel: {
    logo: getCloudinaryURL('v1600158782/logos/consentio-c.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1615542124/logos/tooltip/consentio_tooltip.png', ['f_auto', 'w_600']),
  },
  consum: {
    logo: getCloudinaryURL('v1624882590/logos/consum_txt.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1624884476/logos/tooltip/consum_tooltip.png', ['f_auto', 'w_600']),
  },
  cora: {
    logo: getCloudinaryURL('v1603899291/logos/cora_logo.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787435/logos/tooltip/cora_tooltip.png', ['f_auto', 'w_600']),
  },
  costcoFr: {
    logo: getCloudinaryURL('v1626942718/logos/costco.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1626942754/logos/tooltip/costco_tooltip.png', ['f_auto', 'w_600']),
  },
  dia: {
    logo: getCloudinaryURL('v1624882590/logos/dia_pdf.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1624884476/logos/tooltip/dia_tooltip.png', ['f_auto', 'w_600']),
  },
  ecoOrganic: {
    logo: getCloudinaryURL('v1625843802/logos/ecorganic.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1625843769/logos/tooltip/ecorganic_tooltip.png', ['f_auto', 'w_600']),
  },
  franprix: {
    logo: getCloudinaryURL('v1602762358/logos/franprix_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787436/logos/tooltip/franprix_tooltip.png', ['f_auto', 'w_600']),
  },
  hellofresh: {
    logo: getCloudinaryURL('v1605087522/logos/hellofresh_logo.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614783945/logos/tooltip/hellofresh_tooltip.png', ['f_auto', 'w_600']),
  },
  intermarche: {
    logo: getCloudinaryURL('v1593445815/logos/intermarche.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787436/logos/tooltip/intermache_tooltip.png', ['f_auto', 'w_600']),
  },
  leclerc: {
    logo: getCloudinaryURL('v1594378077/logos/leclerc-square-400.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1615542124/logos/tooltip/leclerc_tooltip.png', ['f_auto', 'w_600']),
  },
  leclercSocara: {
    logo: getCloudinaryURL('v1617897569/logos/leclerc_socara_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1617897537/logos/tooltip/leclerc_socara_tooltip.png', ['f_auto', 'w_600']),
  },
  lidl: {
    logo: getCloudinaryURL('v1595582175/logos/logo-lidl.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787436/logos/tooltip/lidl_tooltip.png', ['f_auto', 'w_600']),
  },
  lidlGarcia: {
    logo: getCloudinaryURL('v1595582175/logos/lidl_garcia_lax.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787436/logos/tooltip/lidl_garcia_lax_tooltip.png', ['f_auto', 'w_600']),
  },
  match: {
    logo: getCloudinaryURL('v1631004599/logos/match.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1631004648/logos/tooltip/match_tooltip.png', ['f_auto', 'w_600']),
  },
  metroFrance: {
    logo: getCloudinaryURL('v1603703873/logos/metro_france_logo.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614787436/logos/tooltip/metro_france_tooltip.png', ['f_auto', 'w_600']),
  },
  metroGermany: {
    logo: getCloudinaryURL('v1614682088/logos/metro_germany_logo.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614788171/logos/tooltip/metro_germany_tooltip.png', ['f_auto', 'w_600']),
  },
  metroValencia: {
    logo: getCloudinaryURL('v1597220613/logos/metro_valencia_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614789929/logos/tooltip/metro_valencia_tooltip.png', ['f_auto', 'w_600']),
  },
  monoprix: {
    logo: getCloudinaryURL('v1593677691/logos/monoprix.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1615542124/logos/tooltip/monoprix_tooltip.png', ['f_auto', 'w_600']),
  },
  prosol: {
    logo: getCloudinaryURL('v1626777206/logos/prosol.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1626777249/logos/tooltip/prosol_tooltip.png', ['f_auto', 'w_600']),
  },
  rewe: {
    logo: getCloudinaryURL('v1593448544/logos/rewe-dein-markt.jpg', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1614790672/logos/tooltip/rewe_tooltip.png', ['f_auto', 'w_600']),
  },
  schiever: {
    logo: getCloudinaryURL('v1594893214/logos/shiever-square.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1615455771/logos/tooltip/schiever_tooltip.png', ['f_auto', 'w_600']),
  },
  sedifrais: {
    logo: getCloudinaryURL('v1626431371/logos/sedifrais.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1626431394/logos/tooltip/sedifrais_tooltip.png', ['f_auto', 'w_600']),
  },
  socomo: {
    logo: getCloudinaryURL('v1623920408/logos/socomo_carrefour.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1623920423/logos/tooltip/socomo_carrefour_tooltip.png', ['f_auto', 'w_600']),
  },
  systemeU: {
    logo: getCloudinaryURL('v1621006360/logos/systeme_u.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1621006226/logos/tooltip/systeme_u_tooltip.png', ['f_auto', 'w_600']),
  },
  veritas: {
    logo: getCloudinaryURL('v1625841658/logos/veritas.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1625841690/logos/tooltip/veritas_tooltip.png', ['f_auto', 'w_600']),
  },
  vtoMetro: {
    logo: getCloudinaryURL('v1624531036/logos/metro_csv.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1624531067/logos/tooltip/mtv_metro_tooltip.png', ['f_auto', 'w_600']),
  },
  walmart: {
    logo: getCloudinaryURL('v1597220613/logos/walmart_logo.png', ['f_auto', 'w_250']),
    tooltip: getCloudinaryURL('v1615455771/logos/tooltip/walmart_tooltip.png', ['f_auto', 'w_600']),
  },
};

const orderImportZeroAsset = {
  dashboards: {
    es: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_es.png', ['c_scale', 'w_1400']),
    fr: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_fr.png', ['c_scale', 'w_1400']),
    en: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_en.png', ['c_scale', 'w_1400']),
    it: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_it.png', ['c_scale', 'w_1400']),
    ca: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_cat.png', ['c_scale', 'w_1400']),
    de: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_ge.png', ['c_scale', 'w_1400']),
    nl: getCloudinaryURL('v1635435973/logos/magic_orders/landing/dasboards_nl.png', ['c_scale', 'w_1400']),
  },
  formats: {
    es: getCloudinaryURL('v1635437074/logos/magic_orders/landing/formats_landing_es.png', ['c_scale', 'w_1400']),
    fr: getCloudinaryURL('v1635437074/logos/magic_orders/landing/formats_landing_fr.png', ['c_scale', 'w_1400']),
    other: getCloudinaryURL('v1635437074/logos/magic_orders/landing/formats_landing_general.png', [
      'c_scale',
      'w_1400',
    ]),
  },
  search: getCloudinaryURL('v1635437204/logos/magic_orders/landing/searcher.png', ['c_scale', 'w_1400']),
};

/**
 * Get URL frm cloudinary adding some image params in the path. (Default: 'f_auto')
 */
function getCloudinaryURL(path: string, params?: Array<string>): string {
  return `${constants.MEDIA_URL}/${(params || ['f_auto']).join(',')}/${path}`;
}

export { IMAGES, orderImportAsset, orderImportZeroAsset };
