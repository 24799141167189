import styled, { css, ITheme } from '../../../styled-components';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';
import { Position } from './Tooltip.component';

interface IStyledProps {
  isVisible?: boolean;
  position?: Position;
  theme: ITheme;
  themeMode?: 'light' | 'dark';
  width?: string;
  height?: string;
  closeable?: boolean;
}

export const Container = styled.div`
  display: inline-block;
  position: relative;

  &:hover {
    .tooltip {
      display: block;
    }
  }
`;

const getTooltipDimensions = (p: IStyledProps) => {
  switch (p.position) {
    case 'right':
      return css`
        width: ${p.width || '200px'};
      `;
    case 'left':
      return css`
        width: ${p.width || '200px'};
      `;
    default:
      return css`
        min-width: 150px;
        max-width: 500px;
        width: ${p.width || 'auto'};
      `;
  }
};

export const Tooltip = styled.div<IStyledProps>`
  ${(p: IStyledProps) => getTooltipDimensions(p)};
  padding: ${(p: IStyledProps) =>
    `${p.theme.paddingSize(p.closeable ? 3 : 1)} ${p.theme.paddingSize(p.closeable ? 3 : 2)}`};
  color: ${(p: IStyledProps) => (p.themeMode === 'light' ? p.theme.colors.grey1 : p.theme.colors.white)};
  background-color: ${(p: IStyledProps) => (p.themeMode === 'light' ? p.theme.colors.white : p.theme.colors.black)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: fixed;
  z-index: 100;
  box-sizing: border-box;
  display: ${(p: IStyledProps) => (p.isVisible ? 'block' : 'none')};
  ${(p: IStyledProps) =>
    p.themeMode === 'light' &&
    css`
      border: 1px solid ${p.theme.colors.grey3};
    `}
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.themeMode === 'light' ? p.theme.colors.grey1 : p.theme.colors.white)};
  text-align: left;
`;
export const Title = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Appendice = styled.i<IStyledProps>`
  position: absolute;
  ${(p: IStyledProps) => p.position && getPositionAppendice(p.position)};
  ${(p: IStyledProps) => p.position && getAppendiceSize(p.position)};
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    ${(p: IStyledProps) => p.position && getPositionAppendiceAfter(p.position)};
    background-color: ${(p: IStyledProps) => (p.themeMode === 'light' ? p.theme.colors.white : p.theme.colors.black)};

    ${(p: IStyledProps) =>
      p.themeMode === 'light' &&
      css`
        border: 1px solid ${p.theme.colors.grey3};
      `}

    ${(p: IStyledProps) =>
      p.position &&
      css`
        transform: ${getTransformAfter(p.position)};
      `};
  }
`;

function getAppendiceSize(position: Position) {
  switch (position) {
    case 'right':
      return css`
        height: 20px;
        width: 10px;
        margin-top: -10px;
      `;
    case 'left':
      return css`
        height: 20px;
        width: 10px;
        margin-top: -10px;
      `;
    default:
      return css`
        width: 20px;
        height: 10px;
        margin-left: -10px;
      `;
  }
}

function getPositionAppendice(position: Position) {
  switch (position) {
    case 'top':
      return css`
        top: 100%;
        left: 50%;
      `;
    case 'bottom':
      return css`
        bottom: 100%;
        left: 50%;
      `;
    case 'bottom-left':
      return css`
        bottom: 100%;
        left: 90%;
      `;
    case 'bottom-right':
      return css`
        bottom: 100%;
        left: 9%;
      `;
    case 'right':
      return css`
        top: 50%;
        right: 100%;
      `;
    case 'left':
      return css`
        top: 50%;
        left: 100%;
      `;
    default:
      return css`
        bottom: 100%;
        left: 50%;
      `;
  }
}

function getTransformAfter(position: Position) {
  switch (position) {
    case 'top':
      return 'translate(-50%, -50%) rotate(45deg)';
    case 'bottom':
    case 'bottom-left':
      return 'translate(-50%, 50%) rotate(45deg)';
    case 'bottom-right':
      return 'translate(-40%, 50%) rotate(45deg)';
    case 'right':
      return 'translate(50%, -50%) rotate(-45deg)';
    case 'left':
      return 'translate(-150%, -50%) rotate(45deg)';
    default:
      return 'translate(-50%, 50%) rotate(45deg)';
  }
}

function getPositionAppendiceAfter(position: Position) {
  switch (position) {
    case 'top':
      return css`
        top: 0;
        left: 50%;
      `;
    case 'right':
      return css`
        top: 50%;
        left: 0%;
      `;
    case 'left':
      return css`
        top: 50%;
        left: 100%;
      `;
    default:
      return css`
        left: 50%;
      `;
  }
}

export const Image = styled.img`
  object-fit: contain;
  width: 450px;
  height: 450px;
`;

export const CloseIcon = styled(FontIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TooltipText = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
`;

export const TooltipTextLink = styled(TooltipText)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;
