import { currency, IOrderAggregationItem, ISearchOrder, __ } from 'common-services';
import { getWeight } from 'common-services/dist/order/service';
import * as React from 'react';
import * as S from '../OrderDelivery.styled';

interface IProps {
  orderItems: Array<IOrderAggregationItem>;
  searchState: ISearchOrder;
}

const OrderDeliveryTotal: React.FC<IProps> = ({ searchState, orderItems }) => {
  let totalPrice = '-';
  let unitPrice = '-';
  let totalAmount = '-';
  const { childId, saleUnit, priceUnit, weightUnit, pricePrecision } = orderItems[0];
  if (orderItems.every(i => i.priceMode !== 'none')) {
    totalPrice = currency.getPriceAbbreviation(
      searchState.currency,
      orderItems.reduce((acc, item) => {
        acc += item.totalPrice;
        return acc;
      }, 0),
    );
  }
  if (orderItems.every(i => i.saleUnit === saleUnit)) {
    const numbers = orderItems.reduce(
      (acc, item) => {
        acc.price += item.price * item.amount;
        acc.amount += item.amount;
        return acc;
      },
      { price: 0, amount: 0 },
    );
    unitPrice = currency.getPricePerUnit(
      searchState.currency,
      priceUnit,
      weightUnit,
      numbers.price / numbers.amount,
      pricePrecision,
    );
  }
  if (orderItems.every(i => getWeight(i))) {
    totalAmount = orderItems.reduce((acc, i) => (acc += getWeight(i)!), 0).toFixed(1) + ' ' + weightUnit;
  }
  return (
    <tfoot>
      <S.Footer key={childId + '-subtotal'} className="order-items-subtotal">
        <S.TotalImageCol />
        <S.TotalBigCol colSpan={4}>{__('Components.OrdersList.Subtotal')}</S.TotalBigCol>
        <S.TotalSmallCol>{totalAmount}</S.TotalSmallCol>
        <S.TotalSmallCol>{unitPrice}</S.TotalSmallCol>
        <S.TotalSmallCol>{totalPrice}</S.TotalSmallCol>
        <S.TotalRegularCol />
      </S.Footer>
    </tfoot>
  );
};

export default React.memo(OrderDeliveryTotal);
