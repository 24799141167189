import { initializeApp } from 'firebase/app';
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';

import config from './bindings/config';

const firebaseConfig = {
  apiKey: 'AIzaSyBHZ0FyalEbM3f-KQVhb_jxsNQx5-2vnOQ',
  authDomain: 'consentio-5be12.firebaseapp.com',
  databaseURL: 'https://consentio-5be12.firebaseio.com',
  messagingSenderId: '1093575594883',
  projectId: 'consentio-5be12',
  storageBucket: 'consentio-5be12.appspot.com',
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
};

let messaging;
let auth;
let googleProvider;
let facebookProvider;

let signInWithGoogle;
let signInWithFacebook;
if (typeof window !== 'undefined') {
  const firebaseApp = initializeApp(firebaseConfig);
  auth = getAuth(firebaseApp);
  signInWithGoogle = () => signInWithPopup(auth, googleProvider);
  signInWithFacebook = () => signInWithPopup(auth, facebookProvider);
  googleProvider = new GoogleAuthProvider();
  googleProvider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
  googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
  facebookProvider = new FacebookAuthProvider();
  facebookProvider.addScope('email');

  messaging = getMessaging(firebaseApp);
}
export { auth, getToken, signInWithFacebook, signInWithGoogle, messaging };
