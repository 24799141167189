import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Footer = styled(RowContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
`;

export const Content = styled(RowContainer)`
  width: min(100%, 1500px);
  max-width: 1500px;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  min-height: 80px;
  max-height: 80px;
  @media (max-height: ${(p: IStyledProps) => p.theme.landingMinHeight}) {
    min-height: 60px;
    max-height: 60px;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: auto;
    min-height: min-content;
    max-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  }
`;

export const Link = styled.a`
  display: inherit;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-decoration: none;
  &:hover {
    color: ${(p: IStyledProps) => p.theme.colors.grey1};
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: block;
    padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  }
`;

export const FooterRight = styled(RowContainer)`
  align-items: center;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
    justify-content: center;
  }
`;

export const SocialNetworks = styled(RowContainer)`
  align-items: center;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  a:last-child img {
    margin-right: 0;
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    margin-left: 0;
    margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
    margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const SocialNetworkImage = styled.img`
  height: 32px;
  width: 32px;
  object-fit: contain;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: 45px;
    width: 45px;
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  }
`;
