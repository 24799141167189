import { ICatalogRole, utils } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import {
  ColumnContainer,
  FontIcon,
  RowContainer,
  SimpleDropdown as SD,
  TextLabel,
  Tooltip as TooltipAtom,
} from '../../atoms';

interface IStyledProps {
  isActive?: boolean;
  timestamp?: number;
  role?: ICatalogRole;
  theme: ITheme;
  imageUrl?: string;
}

export const Workspace = styled(ColumnContainer)<IStyledProps>`
  width: 360px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize()} 8px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  overflow: hidden;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  cursor: pointer;
  ${(p: IStyledProps) =>
    p.isActive &&
    css`
      border: 2px solid ${p.theme.colors.green1};
    `};
`;

export const WorkspaceContent = styled(ColumnContainer)`
  position: relative;
  overflow: hidden;
  height: 220px;
`;

export const WorkspaceHeader = styled(ColumnContainer)<IStyledProps>`
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  background-color: ${(p: IStyledProps) => utils.getTeamColor(p.timestamp)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  ${(p: IStyledProps) =>
    p.imageUrl &&
    css`
      background: url(${p.imageUrl}) ${p.theme.colors.black.replace('1)', '0.7)')};
      background-size: cover;
      background-blend-mode: multiply;
    `}
`;

export const WorkspaceIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  opacity: 0.2;
  width: 75px;
  font-size: 75px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const WorkspaceBody = styled(RowContainer)`
  flex: 1;
`;

export const WorkspaceBodyLeft = styled(ColumnContainer)`
  flex: 1;
  justify-content: space-between;
  height: 100%;
`;

export const WorkspaceBodyRight = styled(ColumnContainer)`
  width: 100px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: calc(${(p: IStyledProps) => p.theme.paddingSize(2)} / 2);
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Author = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-top: 3px;
`;

export const AuthorName = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 13px;
  max-height: 15px;
  overflow: hidden;
  padding-top: 3px;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 90%;
  white-space: nowrap;
`;

export const RoleDescription = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  white-space: nowrap;
  line-height: 1.2;
`;

export const MiddleDot = styled(RoleDescription)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

const getRoleDescriptionColor = (p: IStyledProps) => {
  if (p.role === 'admin') return p.theme.colors.green1;
  if (p.role === 'editor') return p.theme.colors.blue1;
  if (p.role === 'viewer') return p.theme.colors.orange1;
  return p.theme.colors.grey2;
};

export const Role = styled(RoleDescription)<IStyledProps>`
  color: ${getRoleDescriptionColor};
`;

export const WorkspaceTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  line-height: 1.2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-height: 32px;
`;

export const WorkspaceTitleContainer = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CatalogSelect = styled(RowContainer)`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
  padding: 2px 6px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;

  ${(p: IStyledProps) =>
    !p.isActive &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    `}
`;

export const SimpleDropdown = styled(SD)``;

export const WorkspaceHeaderRight = styled(RowContainer)`
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const Default = styled(TextLabel)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextSelect = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding: 0 6px;
`;

export const ChevronIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ActiveIndicator = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(p: IStyledProps) => (p.isActive ? p.theme.colors.green1 : p.theme.colors.grey2)};
`;

export const TextNumber = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  display: inline;
`;

export const TextGrey2 = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-bottom: 2px;
`;

export const StatsContainer = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TooltipIconWrapper = styled(RowContainer)`
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const Tooltip = styled(TooltipAtom)`
  margin-left: 5px;
  align-self: center;
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
