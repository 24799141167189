import { __, constants, utils } from 'common-services';
import * as React from 'react';

import { downloadFile } from '../../../services/file';
import { SimpleDropdown } from '../../atoms';
import * as S from './Attachment.styled';

export interface IProps {
  attachment: IAttachment;
  download: (download: (data, error) => void) => void;
  otherOptions?: { options: Array<{ key: string; value: string; color?: string }>; onSelect: (key: string) => void };
}
const Attachment: React.FC<IProps> = ({ attachment, download, otherOptions }) => {
  const mimeType = attachment.mimeType;
  const fileName = attachment.filename;
  const mimeTypeLogo: string = constants.getLogoFromMime(mimeType);

  const downloadAttachment = React.useCallback(() => {
    download((data, error) => {
      downloadFile(data, attachment.mimeType, attachment.filename);
    });
  }, [download, attachment]);
  return (
    <S.Container className="attachment-container">
      {mimeTypeLogo ? <S.MimeTypeLogo src={mimeTypeLogo} /> : <S.DocumentIcon name="Document" disableHover={true} />}
      <S.TextSizeContainer>
        <S.TextWrap>{utils.cropWithExtension(fileName, 50)}</S.TextWrap>
        <S.DownloadContainer>
          <S.Size>{utils.formatSize(attachment.size)}</S.Size>
          <S.Download onClick={() => downloadAttachment()}>{__('Components.FileMessage.Download')}</S.Download>
        </S.DownloadContainer>
      </S.TextSizeContainer>
      {otherOptions ? (
        <SimpleDropdown hAlign="left" options={otherOptions.options} onSelect={otherOptions.onSelect}>
          <S.KebabIconContainer>
            <S.KebabIcon name="Kebab" />
          </S.KebabIconContainer>
        </SimpleDropdown>
      ) : (
        <S.Icon name="Download" onClick={() => downloadAttachment()} />
      )}
    </S.Container>
  );
};

export default Attachment;
