import * as Sentry from '@sentry/react';
import {
  Api,
  constants,
  enableBatching,
  EventTrack,
  Logger,
  modalActions,
  setToHydrate,
  userActions,
} from 'common-services';
import localforage from 'localforage';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import Config from '../bindings/config';
import { auth } from '../setupFcm';
import { verifyEmail } from './actions/user';
import { ROUTE_PATHS } from './constants';
import stateReady from './hooks/stateReady';
import reducers, { IReduxState, tracker } from './reducers';
import getPath from './util/routes';

let store: Store<any>;

const log: Logger = {
  error: console.log,
};

/**
 * Default access error Api callback.
 * Is changed once persisted redux state is loaded (see below).
 */
const onError = {
  login: (status: number, data: string) => {
    if (status === 401 || status === 403) doLogOut();
  },
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const api = new Api(
  Config.API_URL,
  log,
  '1000',
  (status: number, cb, data) => onError.login(status, data),
  Config.APP_ENV === 'dev',
);
api.setPatchNumber('web/' + Config.REVISION);
const composeEnhancers =
  Config.APP_ENV !== 'production'
    ? composeWithDevTools({})(applyMiddleware(thunk.withExtraArgument(api)), sentryReduxEnhancer)
    : compose(applyMiddleware(thunk.withExtraArgument(api)), sentryReduxEnhancer);

export default function myStore(initialState: any = {}) {
  // ATTENTION!!!! this is a ÑAPA please refactorice in the future. -------------------------
  const auxStore: any = { api };
  store = createStore(enableBatching(reducers, auxStore), initialState, composeEnhancers);
  setToHydrate(
    [
      { name: 'chat' },
      { name: 'chat.channels' },
      { name: 'contact' },
      { name: 'user' },
      { name: 'country' },
      { name: 'prodType' },
      { name: 'nav' },
      { name: 'order' },
    ],
    store,
    () => {
      if (typeof window !== 'undefined') {
        stateReady(store as Store<IReduxState>);
      }
    },
    tracker,
  );
  auxStore.dispatch = store.dispatch;

  /**
   * If status code is 401 and window location path is different from / or /login => Show login modal
   */
  onError.login = (status: number, data: string) => {
    if (
      status === 401 &&
      window.location.pathname !== ROUTE_PATHS.ROOT &&
      window.location.pathname !== ROUTE_PATHS.LOGIN
    ) {
      store.dispatch(modalActions.hideLoading('pre-login')); // if the user hasn't access the loading is over the login
      const twoFactorRequired = !!data?.includes('2fa_expired') || !!data?.includes('2fa_geo_changed');
      if (!!data?.includes('2fa_expired')) EventTrack.track('2fa_expired');
      if (!!data?.includes('2fa_geo_changed')) EventTrack.track('2fa_geo_changed');
      if (twoFactorRequired) {
        doLogOut();
        global.localStorage.setItem('2fa_required', 'true');
      } else {
        store.dispatch(userActions.showLogin());
      }
    }
    store.dispatch(userActions.loginErrorAction('Invalid token'));
    if (status === 401) store.dispatch(verifyEmail() as any);
  };
  return { store };
}

/**
 * Clear storages but keep some local storage values like: country, language, phone, username.
 * Redirect to Login page.
 */
export function doLogOut(isRegister?: boolean) {
  const keysToKeep = ['country', 'email', 'language', 'mode', 'phone', 'platform', 'tutorials', 'username', 'offerSearchState'];
  const valuesToKeep = {};
  for (const key of keysToKeep) {
    const value = localStorage.getItem(key);
    if (value) valuesToKeep[key] = value;
  }
  sessionStorage.clear();
  localStorage.clear();
  localforage.clear();
  store.dispatch({ type: constants.RESET_STATE });

  // logout oauth
  const { currentUser } = auth;
  if (currentUser && currentUser.email) {
    auth.signOut();
  }

  for (const key in valuesToKeep) {
    if (valuesToKeep.hasOwnProperty(key)) {
      const value = valuesToKeep[key];
      if (value) localStorage.setItem(key, value);
    }
  }
  setTimeout(() => {
    window.location.replace(getPath({ path: isRegister ? ROUTE_PATHS.REGISTER : ROUTE_PATHS.LOGIN }));
  });
}
