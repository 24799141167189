import { Api, contactActions, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelByPath } from '../actions/nav';
import ChatUnread, { IDispatchProps, IStateProps } from '../components/organisms/ChatUnread';
import { IReduxState } from '../reducers';

function mapStateToPropsChat(state: IReduxState, props: any): IStateProps {
  const {
    chat: { channels, lastMessageAt, unreadMessageCount },
    contact: { inConsentio },
    order,
    user: { user },
  } = state;
  return {
    channels,
    contacts: inConsentio,
    lastMessageAt,
    me: user,
    pendingOrders: order.pendingByUser,
    unreadMessageCount,
  };
}

function mapDispatchToPropsChat(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, props: any): IDispatchProps {
  return bindActionCreators(
    {
      contactsInvite: contactActions.contactsInvite,
      navigateChannelByPath,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsChat, mapDispatchToPropsChat)(ChatUnread);
