import { ILightContact } from 'common-services';
import * as React from 'react';

import { LettersAvatar } from '../../../../atoms';
import * as S from './ContactSelected.styled';

interface IProps {
  contact: ILightContact;
  onUnselectContact: (cId: number) => void;
}

const ContactSelected: React.FC<IProps> = ({ contact, onUnselectContact }) => {
  if (!contact) return null;
  return (
    <S.ContactContainer>
      <S.Icon>
        <LettersAvatar size={30} text={contact.name} img={contact.avatar} avatarColor={contact.avatarColor} />
      </S.Icon>
      <S.Info>
        <S.InfoTitle>{contact.name}</S.InfoTitle>
        <S.InfoSubtitle>{contact.companyName}</S.InfoSubtitle>
      </S.Info>
      <S.InfoRight>
        <S.CloseIcon name="Close" onClick={() => onUnselectContact(contact.id)} id="contact-unselect-icon" />
      </S.InfoRight>
    </S.ContactContainer>
  );
};

export default React.memo(ContactSelected);
