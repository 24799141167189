import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import IF from '../../molecules/InviteForm';
import SI from '../../molecules/ShareInvite';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
  showInvite?: boolean;
}

function getScrollHeight(p: IStyledProps) {
  return p.theme.contentHeight;
}

export const Scroll = styled.div`
  max-height: calc(${getScrollHeight});
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  flex: 1;
`;

export const CTAContainer = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: ${(p: IStyledProps) => (p.showInvite ? 'space-between' : 'flex-end')};
`;

export const ExtraContainer = styled.div``;

export const InviteForm = styled(IF)`
  overflow-y: auto;
  flex: 1;
`;

export const CenterContainer = styled(ColumnContainer)`
  align-items: center;
  align-self: center;
  justify-content: center;
  flex: 1;
  max-width: 380px;
`;

export const DividerContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DividerBar = styled(RowContainer)`
  height: 1px;
  width: 45%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
`;

export const TextGrey = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ShareInvite = styled(SI)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const CTA = styled(Button)`
  flex: 0.4;
  width: auto;
`;
