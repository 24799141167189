import * as React from 'react';

import { FontIcon } from '../../atoms';
import * as S from './ThirdColumnHeader.styled';

export interface IProps {
  title?: string;
  subtitle?: string;
  closeAction?: () => void;
  backAction?: () => void;
  id?: string;
  darkBackground?: boolean;
}

export default React.memo(({ title = '', subtitle = '', closeAction, backAction, darkBackground, id }: IProps) => {
  return (
    <S.Header darkBackground={darkBackground} id={id}>
      <S.Title>
        <S.TitleText>{title}</S.TitleText>
        <S.SubTitleText>{subtitle}</S.SubTitleText>
      </S.Title>
      {backAction && (
        <S.FontIconWrapperLeft onClick={backAction} id="third-column-header-icon-left">
          <FontIcon name="Back" />
        </S.FontIconWrapperLeft>
      )}
      {closeAction && (
        <S.FontIconWrapperRight onClick={closeAction} id="third-column-header-icon-right">
          <FontIcon name="Close" />
        </S.FontIconWrapperRight>
      )}
    </S.Header>
  );
});
