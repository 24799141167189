import {
  __,
  chatSelectors,
  constants,
  contactActions,
  contactSelectors,
  countrySelectors,
  CURRENCY_CODES,
  debounce,
  modalActions,
  notificationsActions,
  ORDER_ACCEPT_TYPE,
  ORDER_DELIVERY_MODE,
  PRICING_STRATEGY,
  prodTypeSelectors,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  TRANSPORT_UNIT,
  userSelectors,
  utils,
  WEIGHT_UNIT,
} from 'common-services';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, getPriceAdjusts, ROUTE_PATHS } from '../../../constants';
import ShowroomSell from '../../../screens/showroom-sell';
import getPath from '../../../util/routes';
import { Button, Input, Radio, Ribbon, Select, Switch } from '../../atoms';
import {
  ActionsModal,
  AddressAutoComplete,
  ContactCard,
  FormContainer,
  FormMenu,
  FormSection,
  InputWithLabel,
  SortableLine,
} from '../../molecules';
import PriceGroupSelectTable from '../../molecules/PriceGroupSelectTable';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceClientEdit.styled';

export type IProps = { t?: number };

const WorkspaceClientEdit: React.FC<IProps> = ({ t = Date.now() }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  const clientRef: React.RefObject<HTMLDivElement> = React.createRef();
  const priceRef: React.RefObject<HTMLDivElement> = React.createRef();
  const ordersRef: React.RefObject<HTMLDivElement> = React.createRef();
  const integrationRef: React.RefObject<HTMLDivElement> = React.createRef();
  const addressesRef: React.RefObject<HTMLDivElement> = React.createRef();
  const securityRef: React.RefObject<HTMLDivElement> = React.createRef();
  const history = useHistory();
  const params = useParams<{ workspaceId: string; clientId: string }>();
  const workspaceId = Number(params.workspaceId);
  const clientId = Number(params.clientId);

  const addresses: Array<IAddress> = useSelector(sellerWorkspaceSelectors.getAddressesByClient(workspaceId, clientId));
  const channel: IChannel = useSelector(chatSelectors.getChannelByContactId(clientId));
  const client: IClient = useSelector(sellerWorkspaceSelectors.getClient(workspaceId, clientId));
  const contacts: { [cId: number]: IContact } = useSelector(contactSelectors.getContacts());
  const counterparts: Array<ICounterpart> = useSelector(sellerWorkspaceSelectors.getCounterparts(workspaceId));
  const countries: { [key: string]: ICountry } = useSelector(countrySelectors.getCountries);
  const me: IUser = useSelector(userSelectors.getUser);
  const priceGroups: { [priceGroupId: string]: IPriceGroup } = useSelector(
    sellerWorkspaceSelectors.getPriceGroups(workspaceId),
  );
  const prodTypes: { [key: string]: IProdType } = useSelector(prodTypeSelectors.getProdTypes);
  const workspace: IWorkspace = useSelector(sellerWorkspaceSelectors.getCatalog(workspaceId));

  const [newClient, setNewClient] = React.useState(client);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [viewing, setViewing] = React.useState('client');
  const [address, setAddress] = React.useState<{ id?: number; section?: CHANNEL_SECTIONS }>({});
  const [priceGroupsAdded, setPriceGroupsAdded] = React.useState(client?.pricegroups || []);
  const [showPricegroupsModal, setShowPricegroupsModal] = React.useState(false);
  const [showOrders, setShowOrders] = React.useState(false);
  const [orderHashId, setOrderHashId] = React.useState(undefined);

  React.useEffect(() => {
    dispatch(sellerWorkspaceActions.priceGroupsGet(workspaceId));
    RenderTrack.track('WorkspaceClientEdit', { renderTime: t, cliendId: clientId });
    if (!client) {
      dispatch(sellerWorkspaceActions.clientsGet(me.id, workspaceId));
    } else {
      dispatch(sellerWorkspaceActions.clientAddressesGet(me.id, workspaceId, clientId));
      dispatch(sellerWorkspaceActions.counterpartsGet(me.id, workspaceId));
    }

    const offset = 150;
    if (sectionsRef.current)
      sectionsRef.current.addEventListener('scroll', () => {
        if (sectionsRef.current?.scrollTop + offset >= securityRef?.current?.offsetTop) {
          setViewing('security');
        } else if (sectionsRef.current?.scrollTop + offset >= addressesRef?.current?.offsetTop) {
          setViewing('addresses');
        } else if (sectionsRef.current?.scrollTop + offset >= integrationRef?.current?.offsetTop) {
          setViewing('integration');
        } else if (sectionsRef.current?.scrollTop + offset >= ordersRef?.current?.offsetTop) {
          setViewing('orders');
        } else if (sectionsRef.current?.scrollTop + offset >= priceRef?.current?.offsetTop) {
          setViewing('prices');
        } else if (sectionsRef.current?.scrollTop + offset >= clientRef?.current?.offsetTop) {
          setViewing('client');
        }
      });
  }, []);

  React.useEffect(() => {
    setNewClient(client);
    setPriceGroupsAdded(client?.pricegroups);
  }, [client]);
  React.useEffect(() => {
    dispatch(sellerWorkspaceActions.clientAddressesGet(me.id, workspaceId, clientId));
  }, [clientId]);
  React.useEffect(() => {
    dispatch(sellerWorkspaceActions.counterpartsGet(me.id, workspaceId));
  }, [workspaceId]);

  if (!workspace || !newClient) return null;
  const amEditor = !(sellerWorkspaceService.getRole(workspace, me.id) === 'viewer');
  const amPro = sellerWorkspaceService.isProPlan(workspace.plan);
  const contact = contacts[client?.userId];
  const isQuoterMode = (config.TOGGLE_MARGINS.enabled && workspace?.plan?.addons?.quoterMarginsEnabled) || false;
  return (
    <>
      {window.location.pathname.includes('/sell') ? (
        <WorkspaceShowroom history={history} location={location} contact={contact} />
      ) : null}
      {/* <Route path={ROUTE_PATHS.WORKSPACE_CLIENT_SELL} component={p => <WorkspaceShowroom {...p} contact={contact} />} /> */}
      <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
      <Workspace
        parentSections={
          showOrders
            ? [
                {
                  label: __('Components.Header.WorkspaceClients'),
                  action: () =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_CLIENTS,
                        workspaceId: params.workspaceId,
                      }),
                    ),
                },
                ...(orderHashId
                  ? [
                      {
                        label: newClient.name,
                        action: () =>
                          history.push(
                            getPath({
                              path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT,
                              workspaceId: params.workspaceId,
                              clientId: newClient.userId + '',
                            }),
                          ),
                      },
                      {
                        label: __('Components.Header.Orders'),
                        action: () => setOrderHashId(undefined),
                      },
                    ]
                  : [
                      {
                        label: newClient.name,
                        action: () => setShowOrders(false),
                      },
                    ]),
              ]
            : [
                {
                  label: __('Components.Header.WorkspaceClients'),
                  action: () =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_CLIENTS,
                        workspaceId: params.workspaceId,
                      }),
                    ),
                },
              ]
        }
        subtitle={''}
        title={showOrders ? (orderHashId ? orderHashId : __('Components.Header.Orders')) : newClient.name}
        tabSelected="clients"
        workspaceId={Number(workspaceId)}
      >
        {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
        {client && client.blockedOrders ? (
          <Ribbon
            text={__('WorkspaceClientEdit.blocked')}
            onClickAction={() => disableForAll(false)}
            actionText={amEditor ? __('WorkspaceClientEdit.unblock') : undefined}
            type="warning"
          />
        ) : null}
        <S.Container>
          <FormMenu
            sections={[
              {
                id: 'client',
                title: __('WorkspaceClientEdit.Menu.client.title'),
                icon: 'Account',
              },
              {
                id: 'prices',
                title: __('WorkspaceClientEdit.Menu.prices.title'),
                icon: 'Price',
              },
              {
                id: 'orders',
                title: __('WorkspaceClientEdit.Menu.orders.title'),
                icon: 'Order',
              },
              {
                id: 'integration',
                title: __('WorkspaceClientEdit.Menu.integration.title'),
                icon: 'Integration',
              },
              {
                id: 'addresses',
                title: __('WorkspaceClientEdit.Menu.addresses.title'),
                icon: 'Address',
              },
              {
                id: 'security',
                title: __('WorkspaceClientEdit.Menu.security.title'),
                icon: 'Security',
              },
            ]}
            hideOnIpad={true}
            selected={viewing}
          />
          <FormContainer
            className="WorkspaceClientEdit-FormContainer"
            canSave={hasChanges}
            save={() => {
              dispatch(
                sellerWorkspaceActions.clientUpdate(me.id, workspace.id, newClient, error => {
                  if (error) {
                    return dispatch(
                      notificationsActions.notificationShow({
                        title: __('WorkspaceClientEdit.error'),
                        subtitle: __('WorkspaceClientEdit.error_description'),
                        closable: true,
                        style: 'error',
                      }),
                    );
                  }
                  return dispatch(
                    notificationsActions.notificationShow({
                      title: __('WorkspaceClientEdit.success'),
                      subtitle: __('WorkspaceClientEdit.success_description'),
                      closable: true,
                      style: 'success',
                    }),
                  );
                }),
              );
              if (amPro) {
                dispatch(
                  sellerWorkspaceActions.clientPriceGroupsUpdate(workspace.id, newClient.userId, priceGroupsAdded),
                );
              }
              setHasChanges(false);
            }}
            sectionsRef={sectionsRef}
          >
            <>
              <Client
                createNewContact={() =>
                  dispatch(
                    contactActions.createContactWithName(
                      me.id,
                      client.userId,
                      client.name || '',
                      err =>
                        !err &&
                        dispatch(
                          notificationsActions.notificationShow({
                            title: __('SuppliersList.create_contact_success'),
                            subtitle: __('SuppliersList.create_contact_success_sub'),
                            closable: true,
                            style: 'info',
                          }),
                        ),
                    ),
                  )
                }
                navigateToChat={() => {
                  if (channel)
                    dispatch(
                      navActions.navigateChannelByPath(ROUTE_PATHS.CONTACT, channel.id, (path: string) =>
                        history.push(path),
                      ),
                    );
                }}
                myId={me.id}
                client={newClient}
                contact={contacts[client?.userId]}
                clientRef={clientRef}
              />
              <Prices
                amEditor={amEditor}
                amPro={amPro}
                client={newClient}
                isQuoterMode={isQuoterMode}
                movePricegroup={movePricegroup}
                onClickPricegroupAddButton={onClickPricegroupAddButton}
                onDeletePricegroup={onDeletePricegroup}
                priceGroups={priceGroups}
                priceGroupsAdded={priceGroupsAdded}
                priceGroupsUpdate={updateClientPrices}
                priceRef={priceRef}
                updateClient={updateClient}
                workspace={workspace}
              />
              <Orders
                amEditor={amEditor}
                client={newClient}
                clientEmail={contact?.email}
                isUnregistered={contact?.isUnregistered}
                ordersRef={ordersRef}
                updateClient={updateClient}
              />
              <Integration
                amEditor={amEditor}
                client={newClient}
                counterparts={counterparts}
                integrationRef={integrationRef}
                updateClient={updateClient}
              />
              <Addresses
                addresses={addresses}
                addressesRef={addressesRef}
                addressSelected={address.id}
                amEditor={amEditor}
                client={newClient}
                contacts={contacts}
                me={me}
                navAddressAction={navAddressAction}
                navResetAddressAction={navResetAddressAction}
                updateClient={updateClient}
                workspace={workspace}
              />
              <Security
                amEditor={amEditor}
                client={newClient}
                deleteClient={deleteClient}
                disableForAll={disableForAll}
                me={me}
                securityRef={securityRef}
                workspace={workspace}
              />
            </>
          </FormContainer>
          <S.ColumnRight>
            {renderClientOrders()}
            {contact ? renderClientReferences() : null}
          </S.ColumnRight>
        </S.Container>
        {address.section ? (
          <ActionsModal
            onClose={() => setAddress({})}
            title={address.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
          >
            {renderNewAddressModal()}
          </ActionsModal>
        ) : null}
      </Workspace>
      {showPricegroupsModal ? (
        <PriceGroupModal
          clientPricegroups={priceGroupsAdded}
          contacts={contacts}
          isQuoterMode={isQuoterMode}
          me={me}
          onCloseModal={onClosePricegroupModal}
          priceGroups={priceGroups}
          prodTypes={prodTypes}
          updateClientPricegroups={updateClientMultiplePrices}
        />
      ) : null}
    </>
  );

  function renderClientOrders() {
    return (
      <FormSection id="client-orders" title={__('WorkspaceClientEdit.client_sales.title')}>
        <S.FormCol className="client-orders">
          <S.TextPlaceholder>{__('WorkspaceClientEdit.client_sales.text')}</S.TextPlaceholder>
          {
            <S.OrderButton
              onClick={() => {
                if (contacts[client.userId]) {
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_CLIENT_SELL,
                      clientId: params.clientId,
                      workspaceId: params.workspaceId,
                    }),
                  );
                } else {
                  dispatch(
                    modalActions.modalOpen(
                      __('WorkspaceClientEdit.client_sales.create_contact.title'),
                      () => {
                        dispatch(
                          contactActions.createContactWithName(me.id, client.userId, client.name, err => {
                            if (err) return null;
                            dispatch(
                              contactActions.contactUpdateMySellerWorkspace(
                                me.id,
                                client.userId,
                                workspaceId,
                                workspace.hashId,
                                error =>
                                  !error &&
                                  dispatch(
                                    notificationsActions.notificationShow({
                                      title: __('ClientsList.create_contact_success'),
                                      subtitle: __('ClientsList.create_contact_success_sub'),
                                      closable: true,
                                      style: 'info',
                                    }),
                                  ),
                              ),
                            );
                          }),
                        );
                      },
                      {
                        text2: __('WorkspaceClientEdit.client_sales.create_contact.subtitle'),
                        buttonText: __('WorkspaceClientEdit.client_sales.create_contact.cta'),
                        showCancelButton: true,
                        icon: IMAGES.informativePineapple,
                      },
                      'nice',
                    ),
                  );
                }
              }}
            >
              {__('Components.OrdersList.sale_cta')}
            </S.OrderButton>
          }
          {channel ? (
            <S.OrderButton
              type="other"
              onClick={() =>
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS,
                    clientId: params.clientId,
                    workspaceId: params.workspaceId,
                  }),
                )
              }
            >
              {__('WorkspaceSettings.Menu.Magic.title')}
            </S.OrderButton>
          ) : null}
          <S.TextLink
            onClick={() =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_CLIENT_SALES,
                  clientId: params.clientId,
                  workspaceId: params.workspaceId,
                }),
              )
            }
          >
            {__('WorkspaceClientEdit.client_sales.link')}
          </S.TextLink>
        </S.FormCol>
      </FormSection>
    );
  }

  function renderClientReferences() {
    return (
      <FormSection id="client-references" title={__('WorkspaceClientEdit.reference_list.title')}>
        <S.FormCol className="client-references">
          <S.TextPlaceholder>{__('WorkspaceClientEdit.reference_list.text')}</S.TextPlaceholder>
          {channel ? (
            <S.OrderButton
              type="secondary"
              onClick={() =>
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES,
                    clientId: params.clientId,
                    workspaceId: params.workspaceId,
                  }),
                )
              }
            >
              {__('WorkspaceClientEdit.reference_list.cta')}
            </S.OrderButton>
          ) : null}
        </S.FormCol>
      </FormSection>
    );
  }

  function renderNewAddressModal() {
    if (!workspace || !newClient) return null;
    const addressSelectedObj = address.id ? addresses.find(adr => adr.id === address.id) : undefined;
    const addressComponentProps = {
      address: addressSelectedObj,
      countries,
      isDefault: addresses.length === 0,
      me,
      onCancel: navResetAddressAction,
      onSubmit: (adr: IAddress) => {
        adr.id
          ? dispatch(sellerWorkspaceActions.clientAddressUpdate(me.id, workspace.id, newClient.userId, adr))
          : dispatch(sellerWorkspaceActions.clientAddressAdd(me.id, workspace.id, newClient.userId, adr));
        setAddress({});
      },
      tradeCurrency: newClient.currency,
      weightUnit: workspace?.defaultWeightUnit,
    };

    return <AddressAutoComplete {...addressComponentProps} />;
  }

  /**
   * Change the local state of user with the changes in the form.
   */
  function updateClient(client: IClient) {
    setHasChanges(true);
    setNewClient(client);
  }

  /**
   * Change the local state of user with the changes in the form.
   */
  function updateClientPrices(priceId: string) {
    const idx = priceGroupsAdded.findIndex(p => p === priceId);
    const pricesNew = [...priceGroupsAdded];
    idx > -1 ? pricesNew.splice(idx, 1) : pricesNew.push(priceId);

    setHasChanges(true);
    setPriceGroupsAdded(pricesNew);
  }

  function updateClientMultiplePrices(prices: Array<string>) {
    setHasChanges(true);
    setPriceGroupsAdded([...priceGroupsAdded, ...prices]);
  }

  /**
   * Open de address modal
   */
  function navAddressAction(id: number, section: CHANNEL_SECTIONS) {
    setAddress({ id, section });
  }

  /**
   * Move a section by dragging
   */
  function movePricegroup(dragIndex: number, hoverIndex: number) {
    priceGroupsAdded.splice(hoverIndex, 0, priceGroupsAdded.splice(dragIndex, 1)[0]);
    setNewPricegroups(priceGroupsAdded);
  }

  function setNewPricegroups(priceGroupsAdded: Array<string>) {
    setPriceGroupsAdded([...priceGroupsAdded]);
    setHasChanges(true);
  }

  function onDeletePricegroup(index: number) {
    priceGroupsAdded.splice(index, 1);
    setNewPricegroups(priceGroupsAdded);
  }

  function onClickPricegroupAddButton() {
    setShowPricegroupsModal(true);
  }

  function onClosePricegroupModal() {
    setShowPricegroupsModal(false);
  }

  /**
   * Close de address modal
   */
  function navResetAddressAction() {
    setAddress({});
  }
  /**
   * Delete a client.
   */
  function deleteClient(client: IClient) {
    dispatch(
      modalActions.modalOpen(
        __('WorkspaceClientEdit.Menu.privacy.title'),
        () => {
          dispatch(
            sellerWorkspaceActions.clientRemove(me.id, client.catalogId, client, error => {
              if (!error) {
                dispatch(modalActions.modalClose());
                history.goBack();
                dispatch(
                  notificationsActions.notificationShow(
                    {
                      style: 'info',
                      title: __('WorkspaceClientEdit.Menu.privacy.notification.title'),
                      subtitle: __('WorkspaceClientEdit.Menu.privacy.notification.subtitle'),
                      closable: true,
                    },
                    3000,
                  ),
                );
              }
            }),
          );
        },
        {
          showCancelButton: true,
          buttonText: __('WorkspaceClientEdit.Menu.privacy.remove_button'),
          text2: __('WorkspaceClientEdit.Menu.privacy.modal_description', { client: client.name }),
          actionType: 'dangerous',
        },
        'nice',
      ),
    );
  }

  /**
   * disable the posibility of buy for this client to any members.
   */
  function disableForAll(enabled: boolean) {
    dispatch(
      modalActions.modalOpen(
        newClient.blockedOrders
          ? __('Components.ContactDetails.unblock_client_shop.modal.title', newClient)
          : __('Components.ContactDetails.block_client_shop.modal.title', newClient),
        () => {
          updateClient({ ...newClient, blockedOrders: enabled });
          dispatch(modalActions.modalClose());
        },
        {
          subtitle: newClient.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.subtitle', { teamName: workspace.name })
            : __('Components.ContactDetails.block_client_shop.modal.subtitle', { teamName: workspace.name }),
          buttonText: newClient.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.button')
            : __('Components.ContactDetails.block_client_shop.modal.button'),
          actionType: newClient.blockedOrders ? undefined : 'dangerous',

          icon: newClient.blockedOrders ? IMAGES.addUser : IMAGES.blockUser,
          text2: newClient.blockedOrders
            ? __('Components.ContactDetails.unblock_client_shop.modal.text', newClient)
            : __('Components.ContactDetails.block_client_shop.modal.text', newClient),
          showCancelButton: true,
        },
        'nice',
      ),
    );
  }
};

export default React.memo(WorkspaceClientEdit);

const WorkspaceShowroom: React.FC<{ history: any; location: any; contact: IContact }> = React.memo(
  ({ history, location, contact }) => {
    const match = useRouteMatch<{ workspaceId: string; clientId: string }>();
    const { clientId, workspaceId } = match.params;
    const [breadcrumb, setBreadcrumb] = React.useState<{
      parentSections: Array<IBreadcrumb>;
      title: string;
    }>({
      parentSections: [
        {
          label: __('Components.Header.WorkspaceClients'),
          action: () =>
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_CLIENTS,
                workspaceId,
              }),
            ),
        },
        {
          label: contact?.name,
          action: () => history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_CLIENT_EDIT, workspaceId, clientId })),
        },
      ],
      title: __('Components.OrdersList.modal.sale.title'),
    });

    return (
      <Workspace
        parentSections={breadcrumb.parentSections}
        subtitle={''}
        title={breadcrumb.title}
        tabSelected="clients"
        workspaceId={Number(workspaceId)}
      >
        <ShowroomSell
          updateBreadcrumb={brc => setBreadcrumb(brc)}
          breadcrumb={{
            parentSections: breadcrumb.parentSections,
            title: breadcrumb.title,
          }}
          BackHeader={null}
          contact={contact}
          match={match}
          history={history}
          location={location}
        />
      </Workspace>
    );
  },
);

const Client = React.memo(
  ({
    client,
    clientRef,
    contact,
    myId,
    createNewContact,
    navigateToChat,
  }: {
    client: IClient;
    clientRef: React.RefObject<HTMLDivElement>;
    contact?: IContact;
    createNewContact: (client: IClient) => void;
    myId: number;
    navigateToChat: (cId: number) => void;
  }) => {
    return (
      <FormSection id="client" title={__('WorkspaceClientEdit.Menu.client.title')} sectionRef={clientRef}>
        <ContactCard
          name={client.name}
          companyName={client.company}
          contact={contact}
          action={
            contact ? (
              <S.TextLink
                onClick={e => {
                  e.stopPropagation();
                  navigateToChat(client.userId);
                }}
              >
                <S.ChatIcon name="Chat" /> {__('WorkspaceSupplierEdit.go_to_chat')}
              </S.TextLink>
            ) : client.userId !== myId ? (
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  createNewContact(client);
                }}
                iconName="Add-more"
                withoutPadding={true}
              >
                {__('SuppliersList.add_contact')}
              </Button>
            ) : null
          }
        />
      </FormSection>
    );
  },
);

const Prices = React.memo(
  ({
    amEditor,
    amPro,
    client,
    isQuoterMode,
    movePricegroup,
    onClickPricegroupAddButton,
    onDeletePricegroup,
    priceGroups,
    priceGroupsAdded,
    priceGroupsUpdate,
    priceRef,
    updateClient,
    workspace,
  }: {
    amEditor: boolean;
    amPro: boolean;
    client: IClient;
    isQuoterMode: boolean;
    movePricegroup: (dragIndex: number, hoverIndex: number) => void;
    onClickPricegroupAddButton: () => void;
    onDeletePricegroup: (index: number) => void;
    priceGroups: { [priceId: string]: IPriceGroup };
    priceGroupsAdded: Array<string>;
    priceGroupsUpdate: (priceGroupId: string) => void;
    priceRef: React.RefObject<HTMLDivElement>;
    updateClient: (client: IClient) => void;
    workspace: IWorkspace;
  }) => {
    const [isDiscount, setIsDiscount] = React.useState(isQuoterMode ? false : client.priceAdjust < 0);
    const clientCurrency = workspace.currencyConversions[client.currency] || 1;
    const renderAddPriceGroupButton = () => {
      if (!amEditor) {
        return null;
      }

      const priceGroupsLength = priceGroups ? Object.keys(priceGroups).length : 0;
      if (priceGroupsAdded.length === priceGroupsLength) {
        return (
          <S.NoMoreAvailablePricegroupsText>
            {isQuoterMode
              ? __('WorkspaceClientEdit.pricegroup_strategy.sorted_margins.no_more_available')
              : __('WorkspaceClientEdit.pricegroup_strategy.sorted.no_more_available')}
          </S.NoMoreAvailablePricegroupsText>
        );
      }

      return (
        <S.AddPricegroupsButton iconName="Add-more" iconSize="18px" type="link" onClick={onClickPricegroupAddButton}>
          {isQuoterMode
            ? __('WorkspaceClientEdit.pricegroup_strategy.sorted_margins.add_button')
            : __('WorkspaceClientEdit.pricegroup_strategy.sorted.add_button')}
        </S.AddPricegroupsButton>
      );
    };
    let footerText: string = __(`Components.ContactDetails.none_prices`, {
      contact_name: client.name,
    });
    if (client.priceMode === 'edit') {
      footerText = __(`Components.ContactDetails.edit_prices`, {
        contact_name: client.name,
      });
    } else if (client.priceMode === 'read') {
      footerText = __(`Components.ContactDetails.read_prices`, {
        contact_name: client.name,
      });
    }
    return (
      <FormSection
        id="prices"
        title={__('WorkspaceClientEdit.Menu.prices.title')}
        subtitle={__('WorkspaceClientEdit.Menu.prices.subtitle')}
        sectionRef={priceRef}
      >
        <InputWithLabel
          isRequired={true}
          label={__('Components.ContactDetails.prices')}
          footerText={footerText}
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <Select
            value={client.priceMode}
            onChange={(name, priceMode: IPriceMode) => updateClient({ ...client, priceMode })}
            options={getPriceModes()}
            containerMargin="0"
            width="300px"
            disabled={!amEditor}
          />
        </InputWithLabel>
        {!priceGroups || Object.keys(priceGroups).length === 0 ? null : (
          <>
            <InputWithLabel
              isRequired={true}
              label={__('WorkspaceClientEdit.pricegroup_strategy.label')}
              disabled={!amEditor}
            >
              <Radio
                name={'pricegroup_strategy'}
                direction={'column'}
                options={[
                  {
                    label: __('WorkspaceClientEdit.pricegroup_strategy.cheapest.label'),
                    value: 'cheapest',
                    subtitle: isQuoterMode
                      ? __('WorkspaceClientEdit.pricegroup_strategy.cheapest.subtitle_margin')
                      : __('WorkspaceClientEdit.pricegroup_strategy.cheapest.subtitle'),
                  },
                  {
                    label: isQuoterMode
                      ? __('WorkspaceClientEdit.pricegroup_strategy.sorted_margins.label')
                      : __('WorkspaceClientEdit.pricegroup_strategy.sorted.label'),
                    value: 'sorted',
                    subtitle: isQuoterMode
                      ? __('WorkspaceClientEdit.pricegroup_strategy.sorted_margins.subtitle')
                      : __('WorkspaceClientEdit.pricegroup_strategy.sorted.subtitle'),
                  },
                ]}
                containerMargin={'4px 0'}
                tooltipType={'image'}
                alignItems={'flex-start'}
                styleLabel={{ margin: '5px 0' }}
                itemsChecked={[client.strategy]}
                onChange={(key, value: 'cheapest' | 'sorted', error) => {
                  if (!error) {
                    updateClient({ ...client, strategy: value as PRICING_STRATEGY });
                  }
                }}
                disabled={!amEditor}
              />
            </InputWithLabel>
            {amPro ? (
              <>
                {client.strategy === PRICING_STRATEGY.CHEAPEST ? (
                  <>
                    <InputWithLabel
                      isRequired={true}
                      label={
                        isQuoterMode
                          ? __('Components.ContactDetails.margins_applied')
                          : __('Components.ContactDetails.rates_applied')
                      }
                      width="90%"
                      align="flex-start"
                      disabled={!amEditor}
                    >
                      {priceGroupsAdded.length ? (
                        <S.TagsRow disabled={!amEditor}>
                          {priceGroupsAdded.map((priceId, i) => {
                            const price = priceGroups?.[priceId];
                            if (!price) return null;
                            return (
                              <S.PriceGroupTag
                                label={price.name || price.description || price.priceGroupId || ''}
                                className="price-group-tag"
                                key={price.priceGroupId + i}
                                onDelete={() => priceGroupsUpdate(price.priceGroupId)}
                                disabled={!amEditor}
                              />
                            );
                          })}
                        </S.TagsRow>
                      ) : (
                        <S.EmptyTags disabled={!amEditor}>
                          {isQuoterMode
                            ? __('Components.ContactDetails.empty_margins')
                            : __('Components.ContactDetails.empty_rates')}
                        </S.EmptyTags>
                      )}
                    </InputWithLabel>
                    {renderAddPriceGroupButton()}
                  </>
                ) : (
                  <>
                    <InputWithLabel
                      isRequired={true}
                      label={
                        isQuoterMode
                          ? __('Components.ContactDetails.margins_applied')
                          : __('Components.ContactDetails.rates_applied')
                      }
                      width="90%"
                      align="flex-start"
                      disabled={!amEditor}
                    >
                      {priceGroupsAdded.length ? (
                        <S.DnDContainer>
                          <DndProvider backend={HTML5Backend}>
                            {priceGroupsAdded.map((priceId, i) => {
                              const price = priceGroups?.[priceId];
                              if (!price) return null;
                              return (
                                <SortableLine
                                  key={price.priceGroupId}
                                  name={price.name || price.description || price.priceGroupId || ''}
                                  index={i}
                                  moveLine={movePricegroup}
                                  editMode={amEditor}
                                  onDelete={() => {
                                    onDeletePricegroup(i);
                                  }}
                                />
                              );
                            })}
                          </DndProvider>
                        </S.DnDContainer>
                      ) : (
                        <S.EmptyTags disabled={!amEditor}>
                          {isQuoterMode
                            ? __('Components.ContactDetails.empty_margins')
                            : __('Components.ContactDetails.empty_rates')}
                        </S.EmptyTags>
                      )}
                    </InputWithLabel>
                    {renderAddPriceGroupButton()}
                  </>
                )}
              </>
            ) : null}
          </>
        )}
        <InputWithLabel
          isRequired={true}
          label={
            isQuoterMode
              ? __('Components.ContactDetails.price_adjust_title_margin')
              : __('Components.ContactDetails.price_adjust_title')
          }
          description={
            isQuoterMode
              ? __('Components.ContactDetails.price_adjust_margin')
              : __('Components.ContactDetails.price_adjust')
          }
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <S.CardInputs>
            {client.priceAdjust || amEditor ? (
              <Select
                name="priceAdjustSelect"
                value={isDiscount ? 'discount' : 'markup'}
                onChange={(name, val) => {
                  setIsDiscount(val === 'discount');
                  updateClient({
                    ...client,
                    priceAdjust: (val === 'discount' ? -1 : 1) * Math.abs(client.priceAdjust || 0),
                  });
                }}
                options={getPriceAdjusts(isQuoterMode)}
                width="300px"
                containerMargin="0 10px 0 0"
                disabled={!amEditor}
              />
            ) : null}
            {!amEditor && client.priceAdjust ? <S.Separator> · </S.Separator> : null}
            <Input
              name="priceAdjustInput"
              type="number"
              onChange={debounce(
                (name: string, value: number) =>
                  updateClient({
                    ...client,
                    priceAdjust: (isDiscount ? -1 : 1) * value,
                  }),
                1000,
              )}
              value={Math.abs(client.priceAdjust || 0)}
              minValue={0}
              maxValue={99}
              variableTextSingular={'%'}
              precision={2}
              containerMargin="0 10px 0 0"
              width={client.priceAdjust || amEditor ? '150px' : ''}
              disabled={!amEditor}
              zeroCase={__('Components.ContactDetails.price_adjust_zero')}
            />
          </S.CardInputs>
        </InputWithLabel>

        <InputWithLabel
          isRequired={true}
          label={__('Components.ContactDetails.transport_cost_title')}
          description={
            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(workspace.id)
              ? __('Components.ContactDetails.transport_cost_cart')
              : __('Components.ContactDetails.transport_cost')
          }
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <S.CardInputs>
            {client.transportCost || amEditor ? (
              <Select
                name="transportCostUnit"
                value={client.transportCostUnit}
                onChange={(name, val) => updateClient({ ...client, transportCostUnit: val as TRANSPORT_UNIT })}
                options={getTransportCostUnits(
                  config.TOGGLE_CLIENT_CURRENCY.enabled ? workspace.defaultCurrency : client.currency,
                  workspace.defaultWeightUnit,
                  workspace.id,
                )}
                width="300px"
                containerMargin="0 10px 0 0"
                disabled={!amEditor}
              />
            ) : null}
            {!amEditor && client.transportCost ? <S.Separator> · </S.Separator> : null}
            <Input
              name="transportCost"
              type="number"
              onChange={debounce(
                (name: string, value: boolean | number) =>
                  config.TOGGLE_CLIENT_CURRENCY.enabled && client.currency !== workspace.defaultCurrency
                    ? updateClient({ ...client, transportCost: Number(value) * clientCurrency })
                    : updateClient({ ...client, transportCost: Number(value) }),
                1000,
              )}
              value={
                config.TOGGLE_CLIENT_CURRENCY.enabled && client.currency !== workspace.defaultCurrency
                  ? client.transportCost / clientCurrency || 0
                  : client.transportCost || 0
              }
              minValue={0}
              precision={constants.PRICE_PRECISION}
              variableTextSingular={
                config.TOGGLE_CLIENT_CURRENCY.enabled
                  ? workspace.defaultCurrency
                  : constants.CURRENCIES[client.currency].symbol
              }
              containerMargin="0 0"
              width={client.transportCost || amEditor ? '150px' : ''}
              disabled={!amEditor}
              zeroCase={__('Components.ContactDetails.transport_cost_zero')}
            />
          </S.CardInputs>
        </InputWithLabel>

        {Object.keys(workspace.currencyConversions).length ? (
          <S.Row>
            <InputWithLabel
              isRequired={false}
              label={__('Components.ContactDetails.currency_label', { contact_name: client.name })}
              align="flex-start"
              disabled={!amEditor}
            >
              <Select
                value={client.currency}
                placeholder="default"
                options={[
                  {
                    value: workspace.defaultCurrency,
                    label:
                      constants.CURRENCIES[workspace.defaultCurrency].code +
                      ' - ' +
                      constants.CURRENCIES[workspace.defaultCurrency].name(),
                  },
                  ...Object.keys(workspace.currencyConversions).map(c => ({
                    value: c,
                    label: c + ' - ' + constants.CURRENCIES[c].name(),
                  })),
                ]}
                onChange={(key: string, value: string | number, error?: string | undefined) => {
                  updateClient({
                    ...client,
                    currency: value as CURRENCY_CODES,
                    transportCost: undefined,
                  });
                }}
                containerMargin="0 10px 0 0"
                width="300px"
                disabled={!amEditor}
              />
            </InputWithLabel>
            <S.EmptyTags>
              {config.TOGGLE_CLIENT_CURRENCY.enabled &&
              client.transportCost &&
              client.currency !== workspace.defaultCurrency
                ? Number(client.transportCost / clientCurrency) +
                  ' ' +
                  workspace.defaultCurrency +
                  ' = ' +
                  client.transportCost +
                  ' ' +
                  client.currency
                : null}
            </S.EmptyTags>
          </S.Row>
        ) : null}
      </FormSection>
    );
  },
);

const Orders = React.memo(
  ({
    client,
    ordersRef,
    updateClient,
    amEditor,
    isUnregistered,
    clientEmail,
  }: {
    client: IClient;
    updateClient: (client: IClient) => void;
    ordersRef: React.RefObject<HTMLDivElement>;
    amEditor: boolean;
    isUnregistered?: boolean;
    clientEmail?: string;
  }) => {
    return (
      <FormSection id="orders" title={__('WorkspaceClientEdit.Menu.orders.title')} sectionRef={ordersRef}>
        {isUnregistered ? (
          <>
            <S.SwitchContainer>
              <S.SwitchRow>
                <S.OrdersSubtitle disabled={client.unregisteredNotifications}>
                  {__('WorkspaceClientEdit.Menu.orders.notifications.label')}
                </S.OrdersSubtitle>
                <Switch
                  name="notifications"
                  isChecked={client.unregisteredNotifications}
                  onChange={(name, value) => updateClient({ ...client, unregisteredNotifications: value })}
                />
              </S.SwitchRow>
            </S.SwitchContainer>
            <S.TextPlaceholder>
              {client.unregisteredNotifications
                ? __('WorkspaceClientEdit.Menu.orders.notifications.notify', {
                    email: clientEmail,
                  })
                : __('WorkspaceClientEdit.Menu.orders.notifications.not_notify')}
            </S.TextPlaceholder>
          </>
        ) : null}
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceClientEdit.order_accept_label', { contact_name: client.name })}
          footerText={
            ['default', 'manual'].includes(client.orderAccept)
              ? __('WorkspaceClientEdit.order_accept_manual_text', { contact_name: client.name })
              : __('WorkspaceClientEdit.order_accept_auto_text', { contact_name: client.name })
          }
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <Select
            value={client.orderAccept === 'default' ? 'manual' : client.orderAccept}
            placeholder={__('WorkspaceClientEdit.order_accept_manual')}
            options={[
              { value: 'automatic', label: __('WorkspaceClientEdit.order_accept_auto') },
              { value: 'manual', label: __('WorkspaceClientEdit.order_accept_manual') },
            ]}
            onChange={(key: string, value: string | number, error?: string | undefined) => {
              updateClient({ ...client, orderAccept: value as ORDER_ACCEPT_TYPE });
            }}
            width="300px"
            containerMargin="0"
            disabled={!amEditor}
          />
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Integration = React.memo(
  ({
    client,
    counterparts,
    integrationRef,
    updateClient,
    amEditor,
  }: {
    client: IClient;
    counterparts: Array<ICounterpart>;
    updateClient: (client: IClient) => void;
    integrationRef: React.RefObject<HTMLDivElement>;
    amEditor: boolean;
  }) => {
    const [counterpartId, setCounterPartId] = React.useState(client.counterpartId || '');

    return (
      <FormSection
        id="integration"
        title={__('WorkspaceClientEdit.Menu.integration.title')}
        sectionRef={integrationRef}
      >
        <InputWithLabel
          isRequired={false}
          label={__('Components.ContactDetails.counterpart_label')}
          description={__('Components.ContactDetails.counterpart_description', { name: client.name })}
          width="90%"
          disabled={!amEditor}
        >
          <S.CardInputs>
            {counterpartId || amEditor ? (
              <Select
                value={client.counterpartId}
                options={[
                  { value: '', label: __('Components.ContactDetails.other') },
                  ...counterparts.map(c => ({ value: c.externalId, label: c.name })),
                ]}
                onChange={(key: string, value: string | number, error?: string | undefined) => {
                  setCounterPartId(value as string);
                  updateClient({ ...client, counterpartId: value as string });
                }}
                width="300px"
                containerMargin="0 10px 0 0"
                disabled={!amEditor}
              />
            ) : null}
            {!amEditor && counterpartId ? <S.Separator> · </S.Separator> : null}
            <Input
              name="counterpart"
              onBlur={(key: string, value: string | number, error?: string | undefined) => {
                setCounterPartId(value as string);
                updateClient({ ...client, counterpartId });
              }}
              onChange={(key: string, value: string | number, error?: string | undefined) => {
                setCounterPartId(value as string);
              }}
              value={counterpartId}
              type="text"
              placeholder={__('Components.ContactDetails.counterpart_placeholder')}
              containerMargin="0 0"
              width={counterpartId || amEditor ? '200px' : ''}
              disabled={!amEditor}
              zeroCase={__('Components.ContactDetails.counterpart_zero')}
            />
          </S.CardInputs>
        </InputWithLabel>
        {config.TOGGLE_EDIFACT_CODE.enabled ? (
          <InputWithLabel
            isRequired={false}
            label={__('Components.ContactDetails.edifactcode_label')}
            description={__('Components.ContactDetails.edifactcode_description', { name: client.name })}
            width="90%"
            disabled={!amEditor}
          >
            <Input
              name="edifactcode"
              onBlur={(key: string, value: string | number, error?: string | undefined) => {
                updateClient({ ...client, edifactCode: value as string });
              }}
              onChange={(key: string, value: string | number, error?: string | undefined) => {
                updateClient({ ...client, edifactCode: value as string });
              }}
              value={client.edifactCode}
              type="text"
              placeholder={__('Components.ContactDetails.edifactcode_placeholder')}
              containerMargin="0 0"
              width={counterpartId || amEditor ? '510px' : ''}
              disabled={!amEditor}
              zeroCase={__('Components.ContactDetails.counterpart_zero')}
            />
          </InputWithLabel>
        ) : null}
      </FormSection>
    );
  },
);

const Addresses = React.memo(
  ({
    addresses,
    addressesRef,
    addressSelected,
    amEditor,
    client,
    contacts,
    me,
    navAddressAction,
    navResetAddressAction,
    workspace,
    updateClient,
  }: {
    addresses: Array<IAddress>;
    addressesRef: React.RefObject<HTMLDivElement>;
    addressSelected: number;
    amEditor: boolean;
    client: IClient;
    contacts: Record<number, IContact>;
    me: IUser;
    navAddressAction: (id: number, section: CHANNEL_SECTIONS) => void;
    navResetAddressAction: () => void;
    workspace: IWorkspace;
    updateClient: (client: IClient) => void;
  }) => {
    const dispatch = useDispatch<Dispatch<any>>();
    const footerText =
      client.orderDeliveryMode === ORDER_DELIVERY_MODE.DELIVERY
        ? __('Components.ContactDetails.delivery_footer_text', {
            name: sellerWorkspaceService.getCatalogName(workspace, contacts, me),
          })
        : __('Components.ContactDetails.pickup_footer_text', { name: client.name });
    return (
      <FormSection id="addresses" sectionRef={addressesRef} title={__('WorkspaceClientEdit.Menu.addresses.title')}>
        <InputWithLabel
          isRequired={true}
          label={__('Components.ContactDetails.delivery_mode_label')}
          footerText={footerText}
          width="90%"
          align="flex-start"
          disabled={!amEditor}
        >
          <Select
            value={client.orderDeliveryMode}
            onChange={(name, orderDeliveryMode: ORDER_DELIVERY_MODE) => updateClient({ ...client, orderDeliveryMode })}
            options={getDeliveryModes()}
            containerMargin="0"
            width="350px"
            disabled={!amEditor}
          />
        </InputWithLabel>
        <InputWithLabel isRequired={false} label={__('WorkspaceClientEdit.delivery_addresses')} width="100%">
          <S.AddressesTableCustom
            addresses={addresses}
            addressSelected={addressSelected}
            disabled={!amEditor}
            emptyAddressText={__('Components.AddNewAddress.empty_addresses')}
            navAddressAction={(addressId: number) => navAddressAction(addressId, CHANNEL_SECTIONS.ADDRESS_EDIT)}
            navResetAddressAction={() => navResetAddressAction()}
            onMakeAddressAsDefault={(adr: IAddress) => {
              dispatch(
                sellerWorkspaceActions.clientAddressUpdate(
                  me.id,
                  workspace.id,
                  client.userId,
                  { ...adr, isDefault: true },
                  (err: Error) => {
                    if (err) {
                      dispatch(
                        notificationsActions.notificationShow({
                          title: __('WorkspaceClientEdit.error'),
                          subtitle: __('WorkspaceClientEdit.error_description'),
                          closable: true,
                          style: 'error',
                        }),
                      );
                    } else {
                      dispatch(sellerWorkspaceActions.clientAddressesGet(me.id, workspace.id, client.userId));
                      dispatch(
                        notificationsActions.notificationShow({
                          title: __('WorkspaceClientEdit.success'),
                          subtitle: __('WorkspaceClientEdit.success_description'),
                          closable: true,
                          style: 'success',
                        }),
                      );
                    }
                  },
                ),
              );
            }}
            onAddressDelete={(adr: IAddress) => {
              dispatch(
                modalActions.modalOpen(
                  __('Components.AddNewAddress.modal_remove_text'),
                  () => {
                    dispatch(sellerWorkspaceActions.clientAddressDelete(me.id, workspace.id, client.userId, adr.id));
                    dispatch(modalActions.modalClose());
                  },
                  {
                    buttonText: __('Components.AddNewAddress.modal_remove_cta'),
                    actionType: 'dangerous',
                    showCancelButton: true,
                  },
                  'nice',
                ),
              );
            }}
            showAddButton={true}
            tradeCurrency={client.currency}
            weightUnit={workspace?.defaultWeightUnit}
          />
        </InputWithLabel>
      </FormSection>
    );
  },
);

const Security = React.memo(
  ({
    client,
    disableForAll,
    securityRef,
    amEditor,
    workspace,
    deleteClient,
    me,
  }: {
    client: IClient;
    disableForAll: (block: boolean) => void;
    securityRef: React.RefObject<HTMLDivElement>;
    amEditor: boolean;
    workspace: IWorkspace;
    deleteClient: (client: IClient) => void;
    me: IUser;
  }) => {
    return (
      <FormSection
        id="security"
        title={__('WorkspaceClientEdit.Menu.security.title')}
        numHeaders={amEditor ? 2 : 3}
        sectionRef={securityRef}
      >
        <S.SwitchContainer>
          <S.SwitchRow>
            <S.SwitchLabel disabled={!amEditor}>
              {amEditor
                ? __('WorkspaceClientEdit.for_team', { workspace: workspace.name })
                : __('WorkspaceClientEdit.for_team_block', { workspace: workspace.name })}
            </S.SwitchLabel>
            <Switch
              name="settings"
              isChecked={amEditor ? client.blockedOrders : !client.blockedOrders}
              onChange={(n, checked) => disableForAll(checked)}
              disabled={!amEditor}
            />
          </S.SwitchRow>
        </S.SwitchContainer>
        <S.TextPlaceholder>{__('WorkspaceClientEdit.Menu.security.subtitle')}</S.TextPlaceholder>
        <S.FormCol className="privacy">
          <S.TitleLabel>{__('WorkspaceClientEdit.Menu.privacy.title')}</S.TitleLabel>
          <S.TextPlaceholder>{__('WorkspaceClientEdit.Menu.privacy.subtitle')}</S.TextPlaceholder>
          <S.DeleteButton type="delete" iconSize="15px" iconName="Trash" onClick={() => deleteClient(client)}>
            {__('WorkspaceClientEdit.Menu.privacy.remove_button')}
          </S.DeleteButton>
        </S.FormCol>
      </FormSection>
    );
  },
);

/**
 * Get price modes for select component
 */
function getPriceModes(): Array<{ value: string; label: string }> {
  return [
    { value: 'none', label: __('Components.ContactDetails.none_prices_title') },
    { value: 'read', label: __('Components.ContactDetails.read_prices_title') },
    { value: 'edit', label: __('Components.ContactDetails.edit_prices_title') },
  ];
}

/**
 * Get delivery modes for select component
 */
function getDeliveryModes(): Array<{ value: string; label: string }> {
  return [
    { value: ORDER_DELIVERY_MODE.DELIVERY, label: __('Components.ContactDetails.delivery_mode_delivery') },
    { value: ORDER_DELIVERY_MODE.PICKUP, label: __('Components.ContactDetails.delivery_mode_pickup') },
  ];
}

/**
 * Get transport cost units for select component
 */
function getTransportCostUnits(
  currency: CURRENCY_CODES,
  weightUnit: WEIGHT_UNIT,
  workspaceId: number,
): Array<{ value: string; label: string }> {
  const { symbol } = constants.CURRENCIES[currency];
  return [
    { value: TRANSPORT_UNIT.KG, label: __('Components.ContactDetails.transport_kg', { currency: symbol, weightUnit }) },
    {
      value: TRANSPORT_UNIT.PALLET,
      label: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(workspaceId)
        ? __('Components.ContactDetails.transport_cart', { currency: symbol })
        : __('Components.ContactDetails.transport_pallet', { currency: symbol }),
    },
  ];
}

/**
 * Filter pricegroups based on the pricegroups that the client has already assigned
 * and the searchText
 */
function filterPricegroups(
  priceGroups: { [key: string]: IPriceGroup },
  clientPricegroups: Array<string>,
  searchText: string,
): Array<IPriceGroup> {
  const filteredPricegroups = Object.values(priceGroups)
    .filter(priceGroup => clientPricegroups.indexOf(priceGroup.priceGroupId) === -1)
    .reduce((acc, priceGroup) => {
      acc.push(priceGroup);
      return acc;
    }, []);
  if (!searchText || searchText.length < 3) return filteredPricegroups;
  const searchNormalized = utils.toLocaleLowerCaseNormalized(searchText);
  return filteredPricegroups.filter(
    (pg: IPriceGroup) =>
      utils.toLocaleLowerCaseNormalized(pg.name).includes(searchNormalized) ||
      utils.toLocaleLowerCaseNormalized(pg.description).includes(searchNormalized),
  );
}

const PriceGroupModal = React.memo(
  ({
    clientPricegroups,
    contacts,
    isQuoterMode,
    me,
    onCloseModal,
    priceGroups,
    prodTypes,
    updateClientPricegroups,
  }: {
    clientPricegroups: Array<string>;
    contacts: { [cId: number]: IContact };
    isQuoterMode: boolean;
    me: IUser;
    onCloseModal: () => void;
    priceGroups: { [key: string]: IPriceGroup };
    prodTypes: { [key: string]: IProdType };
    updateClientPricegroups: (pricegroups: Array<string>) => void;
  }) => {
    const [addedPricegroups, setAddedPricegroups] = React.useState<Array<string>>([]);
    const [isSelect, setIsSelect] = React.useState(false);
    const [searchText, setSearchText] = React.useState<string>('');
    const filteredPricegroups = filterPricegroups(priceGroups, clientPricegroups, searchText);
    const selectAll = () => {
      setIsSelect(true);
      setAddedPricegroups(filteredPricegroups.map(p => p.priceGroupId));
    };
    const removeSelection = () => {
      setIsSelect(false);
      setAddedPricegroups([]);
    };
    const selectPricegroupsAndCancelSelectAll = (pricegroups: Array<string>) => {
      setAddedPricegroups(pricegroups);
      setIsSelect(false);
    };
    return (
      <ActionsModal
        title={
          isQuoterMode
            ? __('WorkspaceClientEdit.actions_modal.title_quoter')
            : __('WorkspaceClientEdit.actions_modal.title')
        }
        onClose={onCloseModal}
        width="900px"
      >
        <PriceGroupSelectTable
          contacts={contacts}
          isQuoterMode={isQuoterMode}
          isSelect={isSelect}
          me={me}
          priceGroups={filteredPricegroups}
          priceGroupsSelected={addedPricegroups}
          prodTypes={prodTypes}
          removeSelection={removeSelection}
          searchText={searchText}
          selectAll={selectAll}
          setPricegroupSelected={selectPricegroupsAndCancelSelectAll}
          setSearchText={setSearchText}
        />
        <S.ConfirmationButtonContainer>
          <S.ConfirmationButton
            disabled={!addedPricegroups.length}
            onClick={() => {
              updateClientPricegroups(addedPricegroups);
              onCloseModal();
            }}
          >
            {addedPricegroups.length
              ? __('WorkspaceClientEdit.actions_modal.button', { count: addedPricegroups.length })
              : __('WorkspaceClientEdit.actions_modal.button_zero_case')}
          </S.ConfirmationButton>
        </S.ConfirmationButtonContainer>
      </ActionsModal>
    );
  },
);
