import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isInvite?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  height: 296px;
  width: 100%;
  min-height: min-content;
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(8)} ${(p: IStyledProps) => (p.isInvite ? '4%' : '8%')};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue2};

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} ${(p: IStyledProps) => (p.isInvite ? '4%' : '6%')};
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(8)} 4%;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: min-content;
    padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  }
`;

export const TextTitle = styled(TextLabel)<IStyledProps>`
  font-size: ${(p: IStyledProps) => (p.isInvite ? 60 : 90)}px;
  line-height: ${(p: IStyledProps) => (p.isInvite ? 1.1 : 1)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-align: center;
  max-width: ${(p: IStyledProps) => (p.isInvite ? 1500 : 1100)}px;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    font-size: ${(p: IStyledProps) => (p.isInvite ? 52 : 78)}px;
  }
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    font-size: ${(p: IStyledProps) => (p.isInvite ? 30 : 46)}px;
    line-height: 1.1;
  }
`;

export const TextGradient = styled(TextTitle)`
  display: inline;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  background: linear-gradient(
    104deg,
    ${(p: IStyledProps) => p.theme.colors.green1},
    ${(p: IStyledProps) => p.theme.colors.blue1} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: inline-block;
  }
`;

export const Row = styled(RowContainer)`
  min-height: min-content;
`;
