import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  type?: string;
}

export const Container = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingView};
`;

export const FontIconWrapper = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  color: ${(p: IStyledProps) => (p.type === 'warning' ? p.theme.colors.secondary : p.theme.colors.red1)};
  padding: 0 4px;
`;

export const CancelButton = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.light1};
  box-shadow: none;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 0 5px 0 0;
`;

export const AcceptButton = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;
