import styled, { css, ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, LettersAvatar, RowContainer } from '../../../../atoms';
import TextLabel from '../../../../atoms/TextLabel';

interface IStyledProps {
  clickable?: boolean;
  oneLine?: boolean;
  theme: ITheme;
}

export const Container = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-left: 3px solid ${(p: IStyledProps) => p.theme.colors.green1};
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize(2)} ${p.theme.paddingSize()}`};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Text = styled(TextLabel)`
  text-align: left;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  overflow: hidden;
  ${(p: IStyledProps) =>
    p.oneLine &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const TextItalic = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-style: italic;
  padding-right: 2px;
`;

export const TextLink = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  text-align: right;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  text-decoration: underline;
`;

export const Row = styled(RowContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`;

export const Column = styled(ColumnContainer)`
  flex: 1;
  overflow: hidden;
`;

export const Img = styled.img`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  display: block;
  object-fit: cover;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};

  ${(p: IStyledProps) =>
    p.clickable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `};
`;

export const WhoWhen = styled(RowContainer)`
  justify-content: flex-start;
`;

export const TextName = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  min-width: max-content;
  padding: 0;
`;

export const DateTimeText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-left: 5px;
  min-width: max-content;
`;

export const MimeTypeLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  object-fit: cover;
  align-self: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Icon = styled(FontIcon)`
  align-self: center;
  max-width: 30px;
  font-size: ${(p: IStyledProps) => p.theme!.fontSize.big};
  text-align: center;
  vertical-align: middle;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Avatar = styled(LettersAvatar)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
