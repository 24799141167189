import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  theme: ITheme;
  imageSize?: string;
  hasText?: boolean;
}

export const Container = styled(ColumnContainer)`
  max-width: 450px;
  align-items: center;
  align-self: center;
`;

export const Image = styled.img<IStyledProps>`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  ${(p: IStyledProps) =>
    p.imageSize &&
    css`
      max-width: ${p.imageSize};
      min-width: ${p.imageSize};
    `}
`;

export const Text = styled(TextLabel)`
  width: 100%;
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: 22px;
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => (p.hasText ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  margin-bottom: ${(p: IStyledProps) => (p.hasText ? p.theme.paddingSize(3) : 0)};
  line-height: 27px;
`;

export const CTAButton = styled(Button)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  min-width: 200px;
`;

export const CTAContainer = styled(RowContainer)`
  justify-content: space-evenly;
`;
