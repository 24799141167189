import styled, { ITheme } from '../../../styled-components';
import { BackButton as BB, ColumnContainer, StepIndicator as SI, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  align-items: center;
`;

export const StepIndicator = styled(SI)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TitleContainer = styled(ColumnContainer)`
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  position: relative;
`;

export const TextRegular = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextTitle = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: 32px;
  line-height: 40px;
`;

export const SubitleContainer = styled(ColumnContainer)`
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TextSubtitle = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  line-height: 26px;
`;

export const TextSubtitleBold = styled(TextSubtitle)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const BackButton = styled(BB)`
  position: absolute;
  top: 2px;
  left: -${(p: IStyledProps) => p.theme.paddingSize(18)};
`;
