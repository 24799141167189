import { date, utils } from 'common-services';
import * as React from 'react';

import * as S from './DateMessage.styled';

 interface IProps {
  datetime: number;
}

const DateMessage: React.FC<IProps> = props => {
  const { datetime } = props;
  return (
    <S.Container>
      <S.Date>{utils.firstToUpperCase(date.formatLongDate(datetime))}</S.Date>
    </S.Container>
  );
};

export default DateMessage;
