import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  toShow?: boolean;
}

export const Container = styled(ColumnContainer)<IStyledProps>`
  position: fixed;
  top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  right: ${(p: IStyledProps) => (p.toShow ? p.theme.paddingSize(2) : '-500px')};
  z-index: 200;
  transition: right 0.5s ease-in-out;
`;
