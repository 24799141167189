import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const InputContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingObject} 0;
`;

export const PhoneInputContainer = styled(RowContainer)`
  align-items: center;
`;
