import styled, { css, ITheme } from "../../../styled-components";
import { RowContainer } from "../FlexContainer";
import FontIcon from "../FontIcon";
import TextLabel from "../TextLabel";
import TooltipComponent from "../Tooltip/Tooltip.component";

interface IStyledProps {
  align?: string;
  hiddenAtScreenWidth?: string;
  isBold?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isGrouped?: boolean;
  isBlue?: boolean;
  selectable?: boolean;
  selected?: "all" | "any" | "none";
  showHeaderShadow?: boolean;
  showStickyHeader?: boolean;
  headerHeight?: number;
  theme: ITheme;
  width?: string;
}

export const Head = styled.thead<IStyledProps>`
  outline: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};

  ${(p: IStyledProps) => p.showStickyHeader && cssStickyHeader};
  ${(p: IStyledProps) => p.showHeaderShadow && cssHeaderShadow};
`;

export const Header = styled.tr<IStyledProps>`
`;

// Generic styles for each column (header + rows)
export const FillCol = styled.th<IStyledProps>`
  min-width: 22px;
  width: 30px;
  max-width: 30px;
  border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};

  ${(p: IStyledProps) =>
    p.selectable &&
    css`
      width: 60px;
      max-width: 60px;
      padding-left: ${p.theme.paddingSize(1)};
    `};
`;

export const Col = styled.th<IStyledProps>`
  min-width: 70px;
  width: ${(p: IStyledProps) => p.width || "auto"};
  vertical-align: middle;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(p.isFirst ? 5 : 0)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 5 : 2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  position: relative;
  border-right: 1px solid
    ${(p: IStyledProps) =>
      p.isBlue
        ? p.theme.colors.grey4
        : p.isGrouped
        ? p.theme.colors.grey3
        : "none"};
  border-left: 1px solid
    ${(p: IStyledProps) =>
      p.isBlue
        ? p.theme.colors.grey4
        : p.isGrouped
        ? p.theme.colors.grey3
        : "none"};
  background-color: ${(p: IStyledProps) =>
    p.isBlue ? p.theme.colors.blue2 : p.theme.colors.white};
  ${(p: IStyledProps) =>
    p.hiddenAtScreenWidth &&
    css`
      @media only screen and (max-width: ${p.hiddenAtScreenWidth}) {
        display: none;
      }
    `};

  ${(p: IStyledProps) => p.showHeaderShadow && `border: none;`}
`;

const cssStickyHeader = css<{ headerHeight: number }>`
  position: sticky;
  top: ${(p: IStyledProps) => (p.headerHeight ? p.headerHeight + 1 : 1)}px;
  z-index: 2;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border: none !important;
`;

const cssHeaderShadow = css`
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    top: 100%;
    height: 5px;
    background: linear-gradient(
      ${(p: IStyledProps) => p.theme.colors.black.replace("1)", "0.11)")} 0px,
      ${(p: IStyledProps) => p.theme.colors.black.replace("1)", "0.07)")} 1px,
      ${(p: IStyledProps) => p.theme.colors.black.replace("1)", "0.05)")} 1px,
      ${(p: IStyledProps) => p.theme.colors.black.replace("1)", "0)")} 5px
    );
  }
`;

export const TextHeader = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) =>
    p.isBold ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: ${(p: IStyledProps) => p.align || "left"};
  align-self: center;
`;

export const RadioLabel = styled.label`
  display: block;
  position: relative;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  input:checked + span {
    background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  }

  input:checked + span:after {
    display: block;
  }
`;

export const SelectAllIcon = styled(FontIcon)`
  background-color: ${(p: IStyledProps) =>
    p.selected === "all"
      ? p.theme.colors.green1
      : p.selected === "any"
      ? p.theme.colors.grey2
      : p.theme.colors.white};

  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid
    ${(p: IStyledProps) =>
      p.selected === "none" ? p.theme.colors.grey2 : "none"};
  width: 18px;
  height: 18px;
  padding: 0 2px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const TooltipContainer = styled.span`
  display: inline-block;
  padding-left: 4px;
`;

export const Tooltip = styled(TooltipComponent)`
  bottom: -2px;
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const SettingsButton = styled(RowContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  bottom: 8px;
`;
export const SortButton = styled(RowContainer)`
  width: min-width;
  height: min-height;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const SettingsIcon = styled(FontIcon)`
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
`;

export const SortIcon = styled(FontIcon)`
  font-size: 8px;
  height: 8px;
  width: 8px;
`;
