import { __, ICatalogRole, IContact, ILightContact, IUser, utils } from 'common-services';
import * as React from 'react';

import { ContactSelectable, Select, SimpleDropdown } from '../../atoms';
import Members from '../Members';
import * as S from './MembersUpdate.styled';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  contacts: { [cId: number]: IContact };
  disabled: boolean;
  disableSelectViewers?: boolean;
  me: IUser;
  members: Array<{ userId: number; role: ICatalogRole; disabled?: boolean }>;
  memberSelected?: number;
  onRemove?: (mId: number) => void;
  onSelect?: (mId?: number) => void;
  onUpdateRole?: (userId: number, role: ICatalogRole) => void;
  role: ICatalogRole;
  showAuthor?: boolean;
  title: string;
}

interface IState {
  memberSelected: number;
}

const MembersUpdate: React.FC<IProps> = ({
  onRemove,
  onSelect,
  members,
  onUpdateRole,
  role,
  children,
  className,
  contacts,
  disabled,
  disableSelectViewers,
  me,
  memberSelected,
  showAuthor = true,
  title,
}) => {
  const [memberSelectedId, setMemberSelectedId] = React.useState<number>(memberSelected || 0);

  /**
   * Get type of action for the contact list
   */
  function getListAction() {
    if (amAdmin && onRemove) return 'remove';
    if (onSelect) return 'switch';
    return undefined;
  }

  /**
   * Render de right column with the role change action.
   */
  const ContactExtraActions: React.FC<{ contactId: number; idx: number }> = React.useCallback(
    ({ contactId, idx }) => {
      const member = members.find(m => m.userId === contactId);
      return (
        <S.Actions>
          {role === 'admin' ? (
            <Select
              isSearchable={false}
              name={`select-role-${contactId}-${idx}`}
              value={member.role}
              onChange={(name, val) => onUpdateRole(contactId, val as ICatalogRole)}
              options={[
                { value: 'viewer', label: __('Constants.CatalogRoles.viewer') },
                { value: 'editor', label: __('Constants.CatalogRoles.editor') },
              ]}
              maxWidth="150px"
              containerMargin="0"
              dropUp={idx > 1 && idx === members.length - 2}
              disabled={!onUpdateRole}
            />
          ) : (
            <S.Role>
              {member.role === 'viewer' ? __('Constants.CatalogRoles.viewer') : __('Constants.CatalogRoles.editor')}
            </S.Role>
          )}
        </S.Actions>
      );
    },
    [members, onUpdateRole, role],
  );

  /**
   * Handler the remove member in the team
   */
  const removeMember = React.useCallback(
    (cId: number) => {
      onRemove(cId);
    },
    [onRemove],
  );

  const onSelectContact = React.useCallback(
    (contactId: number, isSelected?: boolean) => {
      if (onSelect) {
        setMemberSelectedId(isSelected ? 0 : contactId);
        onSelect(isSelected ? undefined : contactId);
      }
    },
    [onSelect],
  );

  let admin;
  const membersCopy = [...members];
  if (showAuthor) {
    const adminIdx = members.findIndex(m => m.role === 'admin');
    admin = membersCopy.splice(adminIdx, 1)[0];
  }

  const meAsContact: ILightContact = {
    avatar: me.settings.avatar,
    avatarColor: utils.getAvatarColor(me.name),
    name: me.name,
    phoneNumbers: [me.phone + ''],
    companyName: me.companyName,
    id: me.id,
    isUnregistered: false,
  };
  const amAdmin = role === 'admin';
  return (
    <SimpleDropdown
      className={className}
      disabled={disabled}
      Content={
        <S.Modal title={title} onlyContent={true} contentGrow={false}>
          <S.Scroll>
            {showAuthor ? (
              <ContactSelectable
                contact={(admin && contacts && contacts[admin.userId]) || meAsContact}
                isSelected={true}
                isLast={members.length === 1}
                onSelectContact={() => null}
                label={__('Constants.CatalogRoles.author')}
              />
            ) : null}
            <S.ContactsList
              action={getListAction()}
              className="members-update-contacts-list"
              ContactExtraActions={ContactExtraActions}
              contacts={membersCopy.map(m => (m.userId === me.id ? meAsContact : contacts[m.userId]))}
              contactSelected={(cId: number) => memberSelectedId && memberSelectedId === cId}
              contactDisabled={
                disableSelectViewers ? membersCopy.filter(m => m.role === 'viewer').map(m => m.userId) : []
              }
              hideLetters={true}
              onSelectContact={amAdmin && onRemove ? removeMember : onSelectContact}
            />
          </S.Scroll>
        </S.Modal>
      }
    >
      {children ? children : <Members contacts={contacts} me={me} members={members} />}
    </SimpleDropdown>
  );
};

export default React.memo(MembersUpdate);
