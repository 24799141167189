import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import {
  __,
  CHANNEL_TYPE,
  chatActions,
  chatSelectors,
  constants,
  date,
  EventTrack,
  LOCALE,
  ModalActions,
  modalActions,
  orderActions,
  parsers,
  PRODUCT_UNIT,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  throttle,
} from 'common-services';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS, ShowroomPage, THIRD_COLUMN_SECTIONS } from '../../../constants';
import Chat from '../../../screens/chat';
import OrderDetails from '../../../screens/order-details';
import ProductListShare from '../../../screens/product-list-share';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { SimpleDropdown } from '../../atoms';
import { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import { AddressModal, ShowroomHeader } from '../../molecules';
import ProductInfo from '../ProductInfo/ProductInfo.component';
import About from './Fragments/About/About.component';
import Home from './Fragments/Home/Home.component';
import Products from './Fragments/Products';
import Sections from './Fragments/Sections';
import * as S from './Showroom.styled';

export type OwnProps = {
  contact?: IContact;
  next?: () => void;
  BackHeader?: React.ReactNode;
  closeCart?: () => void;
  breadcrumb?: {
    parentSections: Array<IBreadcrumb>;
    title: string;
  };
  hideBack?: boolean;
  updateBreadcrumb?: (newCtx: { parentSections: Array<IBreadcrumb>; title: string }) => void;
} & RouteComponentProps<{
  channelId?: string;
  productHash?: string;
  hashId?: string;
  workspaceId?: string;
  clientId?: string;
  supplierId?: string;
  key?: string;
}>;

interface IAddProductModalProps {
  amSeller: boolean;
  contactId: number;
  deletable: boolean;
  forceSetPrice?: boolean;
  item: IOrderItem;
  priceMode?: IPriceMode;
  saleUnits?: Array<PRODUCT_UNIT>;
}

export interface StateProps {
  amSeller: boolean;
  cart: Array<IOrderItem>;
  catalogs?: { [cId: number]: IWorkspace };
  catalog: IWorkspace;
  catalogId: number;
  client?: IClient;
  contact: IContact;
  countries: { [key: string]: ICountry };
  featured: { [key: string]: Array<string> };
  isServedFlow: boolean;
  lang: LOCALE;
  me: IUser;
  channelMembers?: Array<IMember>;
  name?: string;
  orderId: number;
  priceGroupIds?: Array<string>;
  priceMode: IPriceMode;
  prices: { [key: number]: IPrice };
  prodTypes: { [key: string]: IProdType };
  sectionsEnabled: boolean;
  suppliers?: { [id: number]: Array<ISupplier> };
}

export interface DispatchProps {
  cartUpdateItem: (
    key: string,
    contactId: number,
    item: IOrderItem,
    priceMode: IPriceMode,
    deliveryAddress?: IAddress,
  ) => void;
  orderFakeNew: (
    me: IUser,
    contact: IContact,
    goBack?: (() => void) | undefined,
    cbWithoutId?: ((contact: IContact) => void) | undefined,
    defaultDeliveryMethod?: 'shipping' | 'pickup' | 'none',
  ) => void;
  cartClean: typeof orderActions.cartClean;
  cartCleanIfNeeded: typeof orderActions.cartCleanIfNeeded;
  clientsAdd?: typeof sellerWorkspaceActions.clientsAdd;
  clientsGet: typeof sellerWorkspaceActions.clientsGet;
  downloadExcel?: (catalogId: number, sellerId: number, buyerId: number, filename: string) => void;
  featuredToggle: typeof productActions.featuredToggle;
  getPrices: typeof productActions.getPrices;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  notificationShow?: (notif: INotification, millis: number) => void;
  productGet: typeof productActions.productGet;
  productShare: typeof productActions.productShare;
  sendExcel?: (sellerID: number, catalogId: number, buyerId: number) => void;
  sendMessage: typeof chatActions.sendMessage;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = StateProps & DispatchProps & OwnProps & { t?: number };

interface ShowroomNavigation {
  page: ShowroomPage;
  sectionKey?: string;
  query?: string;
  featured?: IFeaturedType;
}

const Showroom: React.FC<IProps> = ({
  amSeller,
  BackHeader,
  breadcrumb,
  cart,
  cartClean,
  cartCleanIfNeeded,
  cartUpdateItem,
  catalog,
  catalogId,
  client,
  clientsAdd,
  clientsGet,
  closeCart,
  channelMembers,
  contact,
  countries,
  downloadExcel,
  featured,
  featuredToggle,
  getPrices,
  hideBack,
  history,
  isServedFlow,
  match,
  me,
  modalClose,
  modalOpen,
  name,
  navigateChannelBySection,
  next,
  notificationShow,
  orderFakeNew,
  orderId,
  priceGroupIds,
  priceMode,
  prices,
  prodTypes,
  productGet,
  sendExcel,
  sendMessage,
  touchImage,
  updateBreadcrumb,
  t = Date.now(),
}) => {
  const location = useLocation();
  const channelId = match.params?.channelId;

  const [addProductModalProps, setAddProductModalProps] = React.useState<
    IAddProductModalProps & { product: IProduct }
  >();
  const [address, setAddress] = React.useState<IAddress>();
  const [addresses, setAddresses] = React.useState<Array<IAddress>>();
  const [pageNavigation, setPageNavigation] = React.useState<ShowroomNavigation>({
    page:
      featured.recent?.length ||
      featured.recommended?.length ||
      featured.favorite?.length ||
      (catalog?.sectionsEnabled && catalog?.sections?.length)
        ? 'home'
        : 'products',
  });
  const [productToShare, setProductToShare] = React.useState<GenericProduct>();
  const [productToShareName, setProductToShareName] = React.useState<string>();
  const [rightModalData, setRightModalData] = React.useState<{ section: THIRD_COLUMN_SECTIONS; sectionId: number }>();
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const [showAddressesModal, setShowAddressesModal] = React.useState<boolean>(false);
  const [boxTypes, setBoxTypes] = React.useState<Array<ICustomBoxType>>();
  const [palletTypes, setPalletTypes] = React.useState<Array<ICustomPalletType>>();
  const [miniChannelId, setMiniChannelId] = React.useState('');
  const channel = useSelector(chatSelectors.getChannelByContactId(contact?.id));
  const dispatch = useDispatch<any>();

  React.useEffect(() => {
    if (!contact || !me) return;
    if (channel) {
      setMiniChannelId(channel.id);
    } else {
      dispatch(
        chatActions.createChannel(
          me.id,
          [contact.id],
          contact.name,
          '',
          CHANNEL_TYPE.PRIVATE,
          (c: IChannel) => setMiniChannelId(c.id),
          true,
          true,
        ),
      );
    }
  }, [channel, me, contact]);

  const nextFn = throttle((externalNavigation?: boolean) => {
    const cb = () => {
      if (updateBreadcrumb && breadcrumb.title !== __('Components.OrdersList.modal.sale.title')) {
        updateBreadcrumb({
          parentSections: [
            ...(breadcrumb?.parentSections || []),
            {
              label: breadcrumb?.title,
              action: () => {
                updateBreadcrumb(breadcrumb);
                setRightModalData(undefined);
              },
            },
          ],
          title: __('Components.OrdersList.modal.sale.title'),
        });
      }
      setRightModalData(
        rightModalData ? undefined : { section: THIRD_COLUMN_SECTIONS.ORDER_DETAILS, sectionId: orderId },
      );
    };
    return orderFakeNew(
      me,
      contact,
      next || cb,
      next || cb,
      client ? (client.orderDeliveryMode === 'delivery' ? 'shipping' : 'pickup') : undefined,
    );
  }, 1000);

  React.useEffect(() => {
    RenderTrack.track('Showroom', {
      contactId: contact && contact.id ? contact.id + '' : '',
      renderTime: t,
    });
    {
      if (amSeller) {
        api.product
          .getBoxTypes(me.id!, catalogId || contact?.mySellerWorkspaceId || me.sellerWorkspaceId!)
          .then(res => {
            setBoxTypes(res);
          });
        api.product
          .getPalletTypes(me.id!, catalogId || contact?.mySellerWorkspaceId || me.sellerWorkspaceId!)
          .then(res => {
            setPalletTypes(res);
          });
      } else {
        api.product.getBoxTypes(me.id!, catalogId || contact?.theirSellerWorkspaceId!).then(res => {
          setBoxTypes(res);
        });
        api.product.getPalletTypes(me.id!, catalogId || contact?.theirSellerWorkspaceId!).then(res => {
          setPalletTypes(res);
        });
      }
      if (location.pathname.endsWith('items')) {
        nextFn();
      }
    }
  }, []);
  React.useEffect(() => {
    // Reset product info in case we change the chat
    if (updateBreadcrumb) {
      const b = breadcrumb?.parentSections?.length ? breadcrumb.parentSections.pop() : { label: '' };
      updateBreadcrumb({
        parentSections: [...(breadcrumb?.parentSections || [])],
        title: b.label,
      });
    }
    setAddProductModalProps(undefined);
    fetchPrices();
  }, [contact?.id]);

  React.useEffect(() => {
    // Cannot get clients if I do not belong to the catalog
    if (catalog && !client && sellerWorkspaceService.getMember(catalog, me.id)) {
      clientsGet(me.id, catalog.id);
    }
  }, [catalog, client]);

  React.useEffect(() => {
    if (addresses && !address) {
      const defaultAddress = addresses.find(ad => ad.transportCost);
      if (defaultAddress) {
        setAddress(defaultAddress);
      }
    }
  }, [addresses, address]);

  React.useEffect(() => {
    if (catalog) {
      setPageNavigation({
        ...pageNavigation,
        page:
          featured.recent?.length ||
          featured.recommended?.length ||
          featured.favorite?.length ||
          (catalog?.sectionsEnabled && catalog?.sections?.length)
            ? 'home'
            : 'products',
      });
    }
  }, [catalog]);

  React.useEffect(() => {
    api.product.getBoxTypes(me.id!, catalogId).then(res => {
      setBoxTypes(res);
    });
    api.product.getPalletTypes(me.id!, catalogId).then(res => {
      setPalletTypes(res);
    });
  }, [catalogId]);

  React.useEffect(() => {
    if (!location.pathname.includes('items') && match.params?.channelId) {
      setRightModalData(undefined);
    }
  }, [match]);
  return (
    <S.Column>
      {hideBack ? null : typeof BackHeader !== 'undefined' ? (
        BackHeader
      ) : (
        <S.BackContainer>
          <S.Back
            onClick={() =>
              navigateChannelBySection(CHANNEL_SECTIONS.MESSAGES, match.params?.channelId, true, pathBack =>
                history.push(getPath({ path: pathBack as any, channelId: match.params?.channelId })),
              )
            }
          >
            <S.ChevronIcon name="Back" />
            {__('Showroom.back_to_chat', { name: name || contact?.name })}
          </S.Back>
        </S.BackContainer>
      )}
      <ShowroomHeader
        cart={cart}
        catalog={catalog}
        companyName={amSeller ? me.companyName : contact?.companyName}
        from="showroom"
        hideCompanyMobile={true}
        path={location.pathname}
        params={match.params}
        channelId={match.params?.channelId}
        clientId={match.params?.clientId || client?.userId.toString()}
        supplierId={match.params?.supplierId}
        initQuery={''}
        lang={me.settings.language as LOCALE}
        me={me}
        name={amSeller ? me.name : contact?.name}
        onCartClick={nextFn}
        onChatClick={() => null}
        onNavigate={onNavigate}
        onSearch={query => onNavigate('products', undefined, query)}
        selected={pageNavigation.page}
        showLangSelector={false}
        ShareLink={renderShareLink()}
      />
      <S.Container className="showroom-container" hasBack={!!BackHeader}>
        {getPage(pageNavigation)}
      </S.Container>
      {rightModalData ? renderOrderModal() : null}
      {addProductModalProps ? renderProductInfoPage() : null}
      {showShare ? renderShareModal() : null}
      {showAddressesModal ? renderAddressesModal() : null}
      {(config.TOGGLE_MINI_CHAT.enabled || config.TOGGLE_MINI_CHAT.organizations.includes(catalogId)) &&
      miniChannelId ? (
        <Chat
          match={{ params: { ...match.params, channelId: miniChannelId } } as any}
          isModal={true}
          history={history}
          location={{} as any}
          styleModal={{ right: '90px' }}
          startMinimized={true}
        />
      ) : null}
    </S.Column>
  );

  /**
   * Get page of the showroom (Home / about / products / sections)
   */
  function getPage(pageNavigation: ShowroomNavigation) {
    switch (pageNavigation.page) {
      case 'about':
        return (
          <About
            amSeller={amSeller}
            catalog={catalog}
            channelId={channelId}
            me={me}
            onChat={() => {
              if (amSeller) {
                history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_PRODUCTS, workspaceId: catalog.id + '' }));
              } else {
                history.push(getPath({ path: ROUTE_PATHS.CHAT, channelId }));
              }
            }}
            owner={
              catalog
                ? parsers.userToPublicUser(catalog, amSeller ? me : undefined, amSeller ? undefined : contact)
                : undefined
            }
          />
        );
      case 'sections':
        return (
          <Sections
            catalog={catalog}
            from="showroom"
            lang={(me.settings.language as LOCALE) || LOCALE.EN}
            me={me}
            onNavigate={onNavigate}
            priceGroupIds={priceGroupIds}
          />
        );
      case 'products':
        return (
          <Products
            AddressHeader={
              <AddressHeader
                address={address}
                openAddressesModal={() => setShowAddressesModal(true)}
                showChangeLink={(addresses?.length || 0) > 1}
              />
            }
            amSeller={amSeller}
            cart={cart}
            cartUpdateItem={cartUpdateItemFn}
            catalog={catalog}
            client={client}
            clientsAdd={clientsAdd}
            contact={contact}
            ExcelDropdown={
              amSeller ? (
                <ExcelDropdown
                  contact={contact}
                  downloadExcel={onDownloadExcel}
                  modalOpen={modalOpen}
                  showExcelModal={showExcelModal}
                />
              ) : undefined
            }
            featured={featured}
            featuredToggle={featuredToggleFn}
            from="showroom"
            hideFeatured={!me.id}
            history={history}
            isServedFlow={isServedFlow}
            lang={(me.settings.language as LOCALE) || LOCALE.EN}
            location={location}
            me={me}
            myId={me.id}
            notificationShow={notificationShow}
            onNavigate={onNavigate}
            onShareSearch={onShareSearch}
            priceGroupIds={priceGroupIds}
            priceMode={priceMode}
            prices={prices}
            query={pageNavigation.query || ''}
            section={pageNavigation.sectionKey || ''}
            showBack={!!(catalog?.sections?.length || pageNavigation.featured)}
            showFeatured={pageNavigation.featured}
            toHide={false}
            boxTypes={boxTypes}
            palletTypes={palletTypes}
            touchImage={touchImage}
          />
        );
      default:
        return (
          <Home
            AddressHeader={
              <AddressHeader
                address={address}
                openAddressesModal={() => setShowAddressesModal(true)}
                showChangeLink={(addresses?.length || 0) > 1}
              />
            }
            amSeller={amSeller}
            cartItems={cart}
            cartUpdateItem={cartUpdateItemFn}
            catalog={catalog}
            client={client}
            ExcelDropdown={
              amSeller ? (
                <ExcelDropdown
                  contact={contact}
                  downloadExcel={() => onDownloadExcel()}
                  modalOpen={modalOpen}
                  showExcelModal={showExcelModal}
                />
              ) : undefined
            }
            featured={featured}
            featuredToggle={featuredToggleFn}
            from="showroom"
            hideFeatured={!me.id}
            history={history}
            isServedFlow={isServedFlow}
            lang={me.settings.language as LOCALE}
            me={me}
            onNavigate={onNavigate}
            onShareSearch={onShareSearch}
            priceGroupIds={priceGroupIds}
            prices={prices}
            priceMode={priceMode}
            showShare={true}
          />
        );
    }
  }

  /**
   * Handle header navigation to different pages
   */
  function onNavigate(page: ShowroomPage, sectionKey?: string, query?: string, product?: IProduct) {
    if (product) {
      return navigateToProduct(
        cart.find(i => i.childId === product.id),
        product,
      );
    }

    let finalPage = page;
    const isFeaturedPage = ['recommended', 'favorite', 'recent'].includes(page);
    if (sectionKey || isFeaturedPage) finalPage = 'products';
    setPageNavigation({
      ...pageNavigation,
      page: finalPage,
      sectionKey,
      query,
      featured: isFeaturedPage ? (page as IFeaturedType) : undefined,
    });
  }

  /**
   * Fetch products according to each case mode (Buy / Sell)
   */
  function fetchPrices(addressId?: number) {
    if (!contact || me.id === contact.id) return;
    if (amSeller) {
      getPrices(
        catalogId || contact.mySellerWorkspaceId || me.sellerWorkspaceId!,
        contact.id!,
        undefined,
        addressId,
        addr => setAddresses(addr),
      );
    } else {
      getPrices(catalogId || contact.theirSellerWorkspaceId!, me.id!, undefined, addressId, addr => setAddresses(addr));
    }

    const key = productService.getKey(amSeller ? me.id : contact.id, amSeller ? contact.id : me.id);
    // Handle cart navigation if needed
    if (window.location.pathname.includes('/cart/buy') && cart.length) {
      setTimeout(() => nextFn(true));
    } else {
      cartCleanIfNeeded(
        key,
        prices && Object.values(prices).length && Object.values(prices)[0].currency,
        amSeller ? catalog?.id || contact.mySellerWorkspaceId || me.sellerWorkspaceId : contact.theirSellerWorkspaceId,
        orderId,
      );
      if (match.params?.productHash) {
        productGet(match.params?.productHash, me.id, contact.id, amSeller, (product: IProduct) => {
          const productItem: IOrderItem = getOrderItem(
            parsers.genericProductToOrderItem(
              parsers.productToGenericProduct(product),
              product.price,
              0,
              product.baseMarginPercentage,
              isServedFlow,
              cart.find(item => item.childId === product.id)?.id,
            ),
          );

          if (updateBreadcrumb) {
            updateBreadcrumb({
              parentSections: [
                ...(breadcrumb.parentSections || []),
                {
                  label: breadcrumb.title,
                  action: () => {
                    updateBreadcrumb(breadcrumb);
                    setAddProductModalProps(undefined);
                  },
                },
              ],
              title: productService.getProductTypeVarietyDisplay(
                productItem?.type,
                prodTypes[productItem?.type]?.name || '',
                productItem?.title,
              ),
            });
          }
          setAddProductModalProps({
            amSeller,
            contactId: contact.id!,
            deletable: cart.length > 1,
            item: productItem,
            priceMode,
            product,
            saleUnits: product.saleUnits,
          });
        });
      }
    }
  }

  /**
   * handle update cart item
   */
  function cartUpdateItemFn(item: IOrderItem) {
    const cb = () => null;
    // TODO: can be positive to use a debounce
    EventTrack.cartAddItem({
      quantity: item.amount,
      unit: item.saleUnit,
      price: item.price,
      sku: item.sku,
      eanCode: item.eanCode,
      productId: item.childId,
      productType: item.type,
      role: amSeller ? 'seller' : 'buyer',
    });
    const key =
      amSeller || contact?.isUnregistered
        ? productService.getKey(me.id!, contact?.id!)
        : productService.getKey(contact.id!, me.id!);
    cartUpdateItem(key, contact?.id, item, priceMode, address);
    if (rightModalData) {
      orderFakeNew(
        me,
        contact,
        cb,
        cb,
        client ? (client.orderDeliveryMode === 'delivery' ? 'shipping' : 'pickup') : undefined,
      );
    }
  }

  /**
   * handle toggle featured product
   */
  function featuredToggleFn(type: IFeaturedType, productHashId: string) {
    featuredToggle(type, catalog.id, me.id, amSeller ? contact.id : me.id, productHashId);
  }

  /**
   * handle click in product card
   */
  function navigateToProduct(i: IOrderItem, product: IProduct) {
    const productItem: IOrderItem = i
      ? getOrderItem(i)
      : parsers.genericProductToOrderItem(
          parsers.productToGenericProduct(product),
          prices?.[product.id]?.price || product.price,
          0,
          prices?.[product.id]?.baseMarginPercentage || product.baseMarginPercentage,
          isServedFlow,
          cart.find(item => item.childId === product.id)?.id,
        );

    if (updateBreadcrumb) {
      updateBreadcrumb({
        parentSections: [
          ...(breadcrumb.parentSections || []),
          {
            label: breadcrumb.title,
            action: () => {
              updateBreadcrumb(breadcrumb);
              setAddProductModalProps(undefined);
            },
          },
        ],
        title: productService.getProductTypeVarietyDisplay(
          productItem?.type,
          prodTypes[productItem?.type]?.name || '',
          productItem?.title,
        ),
      });
    }
    setAddProductModalProps({
      product,
      item: productItem,
      amSeller,
      contactId: contact?.id!,
      priceMode,
      saleUnits: product.saleUnits,
      deletable: cart.length > 1,
    });
  }

  /**
   * Render the share button for the search or for the all the products
   */
  function renderShareLink(isSearch?: boolean) {
    return (
      <S.ShareTouchable
        id="showroom-header-share-catalog"
        type="link"
        onClick={() => onShareSearch()}
        iconName="Share"
        iconSize="14px"
        withoutPadding={true}
      >
        <S.ShareSpan className="share-catalog-text">
          {isSearch ? __('Components.ProductListShare.share_search') : __('Components.ProductListShare.share_showroom')}
        </S.ShareSpan>
      </S.ShareTouchable>
    );
  }

  /**
   * On share product, opens modal
   */
  function onShareSearch(product?: GenericProduct, productName?: string) {
    setProductToShare(product);
    setProductToShareName(productName);
    setShowShare(true);
  }

  /**
   * Render share product modal
   */
  function renderShareModal() {
    return (
      <S.ModalShare>
        <ProductListShare
          amSeller={amSeller}
          close={onCloseShareModal}
          contactId={contact && contact.id}
          history={history}
          ownerId={amSeller ? me.id : contact?.id}
          catalogId={
            amSeller || contact?.isUnregistered
              ? catalog?.id || contact?.mySellerWorkspaceId || me.sellerWorkspaceId
              : contact?.theirSellerWorkspaceId
          }
          productHashId={productToShare ? productToShare.productHashId : undefined}
          product={productToShare}
          productName={productToShareName}
        />
      </S.ModalShare>
    );
  }

  /**
   * Close share product modal
   */
  function onCloseShareModal() {
    setProductToShare(undefined);
    setProductToShareName(undefined);
    setShowShare(false);
  }

  /**
   * Render product info page.
   */
  function renderProductInfoPage() {
    if (!addProductModalProps) return null;
    const { item, priceMode, amSeller, deletable, product } = addProductModalProps!;
    const key = productService.getKey(amSeller ? me.id : contact?.id, amSeller ? contact?.id : me.id);
    const isRecent = featured && featured.recent && featured.recent.includes(product.hashId);
    const isFavorite = featured && featured.favorite && featured.favorite.includes(product.hashId);
    return (
      <S.Modal hasBack={!breadcrumb}>
        <ProductInfo
          address={address}
          cartUpdateItem={(contactId: number, i: IOrderItem) => cartUpdateItem(key, contactId, i, priceMode)}
          contactId={contact?.id}
          contactName={contact?.name}
          isContactUnregistered={contact?.isUnregistered}
          catalogId={
            amSeller || contact?.isUnregistered
              ? catalog.id || contact?.mySellerWorkspaceId || me.sellerWorkspaceId
              : contact?.theirSellerWorkspaceId
          }
          countries={countries}
          deletable={deletable}
          forceSetPrice={!amSeller && contact.isUnregistered}
          from="product"
          amSeller={amSeller}
          isFavorite={isFavorite}
          isRecent={isRecent}
          isRecommended={featured && featured.recommended && featured.recommended.includes(product.hashId)}
          isServedFlow={isServedFlow}
          item={item}
          history={history}
          close={() => {
            if (updateBreadcrumb) {
              const b = breadcrumb.parentSections.length ? breadcrumb.parentSections.pop() : { label: '' };
              updateBreadcrumb({
                parentSections: [...(breadcrumb.parentSections || [])],
                title: b.label,
              });
            }
            setAddProductModalProps(undefined);
          }}
          modalClose={modalClose}
          modalOpen={modalOpen}
          me={me}
          priceMode={priceMode}
          pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
          prodTypes={prodTypes}
          product={parsers.productToGenericProduct(product)}
          touchImage={touchImage}
          sendMessage={(text: string) => {
            if (text) {
              const message = text.replace(/(?:\r\n|\r|\n)/, '\\n');
              sendMessage(me.id!, {
                channelId,
                createdAt: new Date().getTime() + 60000,
                extraData: {},
                message,
                messageId: 0,
                messageType: 'text',
                reactions: {},
                senderId: me.id,
              });
              EventTrack.track('message_send', {
                channelId,
                length: text.length,
                messageType: 'text',
                type: CHANNEL_TYPE.PRIVATE,
              });
            }
          }}
          showBack={!breadcrumb}
          showDefaultPicture={false}
          showFeatured={true}
          showShare={true}
        />
      </S.Modal>
    );
  }

  /**
   * Render the order details in the third column
   */
  function renderOrderModal() {
    return (
      <S.CartContainer>
        <OrderDetails
          amSeller={amSeller}
          channelMembers={channelMembers}
          notMarkAsRead={true}
          closeCart={
            closeCart
              ? closeCart
              : () => {
                  navigateChannelBySection(CHANNEL_SECTIONS.MESSAGES, channelId, true, path =>
                    history.push(getPath({ path: path as any, channelId })),
                  );
                }
          }
          goBack={() => {
            setRightModalData(undefined);
            if (channelId) {
              navigateChannelBySection(
                amSeller ? CHANNEL_SECTIONS.SHOWROOM_SELL : CHANNEL_SECTIONS.SHOWROOM_BUY,
                channelId,
                true,
                path => history.push(getPath({ path: path as any, channelId })),
              );
            }
          }}
          contact={contact}
          channelId={channelId}
          orderId={rightModalData?.sectionId}
          history={history}
          location={location}
          match={match}
          name={name}
          navShowroom={() => setRightModalData(undefined)}
          navCloneAction={() => {
            if (updateBreadcrumb) {
              updateBreadcrumb({
                parentSections: [
                  ...(breadcrumb.parentSections || []),
                  {
                    label: breadcrumb.title,
                    action: () => {
                      updateBreadcrumb(breadcrumb);
                      setRightModalData(undefined);
                    },
                  },
                ],
                title: __('Components.OrdersList.modal.sale.title'),
              });
            }
            setRightModalData({ section: THIRD_COLUMN_SECTIONS.ORDER_DETAILS, sectionId: 0 });
          }}
          hideBack={!channelId}
          backLink={
            amSeller
              ? __('Components.Cart.back_my_products')
              : __('Components.Cart.back_products', { name: contact?.name })
          }
          openedFrom={amSeller ? 'sales' : 'purchases'}
          breadcrumb={breadcrumb}
          updateBreadcrumb={updateBreadcrumb}
        />
      </S.CartContainer>
    );
  }

  /**
   * Return the cart item if exist this product in the cart or else return the original order item.
   */
  function getOrderItem(item: IOrderItem): IOrderItem {
    const cartItem = cart.find(i => i.childId === item?.childId);
    return cartItem || item;
  }

  /**
   * Render the modal with the all addresses for select
   */
  function renderAddressesModal() {
    return (
      <AddressModal
        catalogId={catalogId}
        address={address}
        addresses={addresses}
        onSelectAddress={(a: IAddress) => {
          fetchPrices(a.id);
          setShowAddressesModal(false);
          setAddress(a);
          cartClean(productService.getKey(amSeller ? me.id : contact?.id, amSeller ? contact?.id : me.id));
        }}
        onClose={() => {
          setShowAddressesModal(false);
        }}
        title={__('Showroom.addressModalTitle')}
        subtitle={__('Showroom.addressModalDescription')}
      />
    );
  }

  /**
   * Triggers excel modal asking user confirmation to send excel email
   */
  function showExcelModal() {
    modalOpen(
      __('Showroom.SendExcel.modalTitle'),
      () => {
        sendExcel(me.id, catalog.id || me.sellerWorkspaceId, contact?.id);
        notificationShow(
          {
            title: __('Showroom.SendExcel.notification_title'),
            subtitle: __('Showroom.SendExcel.notification_subtitle'),
            closable: true,
            style: 'info',
          },
          8000,
        );
        modalClose();
      },
      {
        text2: contact.companyName
          ? __('Showroom.SendExcel.modalBody', {
              email: contact.email,
              name: contact.name,
              company: contact.companyName,
            })
          : __('Showroom.SendExcel.modalBodyNoCompany', {
              email: contact.email,
              name: contact.name,
              company: contact.companyName,
            }),
        subtitle: __('Showroom.SendExcel.modalSubTitle'),
        buttonText: __('Showroom.SendExcel.modalCTA'),
        icon: IMAGES.email,
        showCancelButton: false,
      },
      'nice',
    );
  }

  function onDownloadExcel() {
    const excelFileName =
      __('Showroom.SendExcel.fileNamePreffix') +
      `_${me.name.replace(' ', '-')}` +
      `_${me.companyName.replace(' ', '-')}` +
      `_${date.formatDate(new Date().getTime(), 'yyyyMMdd')}.xlsx`;
    downloadExcel(catalog.id || me.sellerWorkspaceId, me.id, contact?.id, excelFileName);
  }
};

export default React.memo(Showroom);

const AddressHeader = ({
  address,
  openAddressesModal,
  showChangeLink,
}: {
  address: IAddress;
  openAddressesModal: () => void;
  showChangeLink: boolean;
}) => {
  if (!address) return null;
  const cityZip = address.city || address.zip ? ` - ${address.city || ''} ${address.zip || ''}` : '';
  return (
    <S.AddressHeader>
      <S.AddressIcon name="Address" disableHover={true} />
      <S.TextGrey1>
        {__('Showroom.addressSeleceted') + ' '}
        <S.TextBlack>{address.displayName + cityZip} </S.TextBlack>
      </S.TextGrey1>
      {showChangeLink ? (
        <S.Link onClick={() => openAddressesModal()} withoutPadding={true} type="link">
          {__('Showroom.change')}
        </S.Link>
      ) : null}
    </S.AddressHeader>
  );
};

/**
 * Render dropdown which invokes excel download/send behavior
 */
const ExcelDropdown = ({
  modalOpen,
  contact,
  downloadExcel,
  showExcelModal,
}: {
  modalOpen: (text: string, action: (text: string) => void, extra: IModalExtraInfo, name?: IModalName) => ModalActions;
  contact: IContact;
  downloadExcel: () => void;
  showExcelModal: () => void;
}) => {
  if (!modalOpen || !contact) {
    return null;
  }
  const options: Array<IItem> = [
    {
      value: __('Showroom.SendExcel.dropdown_download'),
      value2: __('Showroom.SendExcel.dropdown_subtitle'),
      key: 'download',
      icon: 'Download',
    },
  ];
  // Only show send option when there's an email
  if (contact?.email) {
    options.push({
      value: __('Showroom.SendExcel.dropdown_send'),
      value2: __('Showroom.SendExcel.dropdown_subtitle'),
      key: 'send',
      icon: 'Send',
    });
  }
  return (
    <SimpleDropdown
      hAlign={'right'}
      vAlign="flex-end"
      margin="0"
      onSelect={(v: string) => {
        if (v === 'download') downloadExcel();
        if (v === 'send') showExcelModal();
      }}
      position="bottom"
      options={options}
    >
      <S.ExcelWrapper>
        <S.ExcelImage src="https://media.consentio.co/image/upload/v1594646971/logos/excel-logo-250.png" />
        <S.DownIcon name="Down" disableHover={true} />
      </S.ExcelWrapper>
    </SimpleDropdown>
  );
};
