import { __ } from 'common-services';
import * as React from 'react';
import ActionsModal from '../ActionsModal';

import * as S from './UpdateConfirmationModal.styled';

export interface IProps {
  onClose: () => void;
  onCancel?: () => void;
  defaultCheck?: boolean;
  disableCheck?: boolean;
  title: string;
  image?: string;
  description?: string;
  checkLabel?: string;
  cancelCTA?: string;
  ctaChecked?: string;
  ctaNotChecked?: string;
  onConfirm: (isChecked: boolean) => void;
}

const UpdateConfirmationModal: React.FC<IProps> = ({
  defaultCheck,
  onClose,
  onCancel,
  disableCheck,
  onConfirm,
  title,
  description,
  image,
  checkLabel,
  cancelCTA,
  ctaChecked,
  ctaNotChecked,
}) => {
  const [isChecked, setIsChecked] = React.useState(!!defaultCheck);
  return (
    <ActionsModal onClose={onClose} minHeight="min-content">
      {image ? <S.Image src={image} /> : null}
      <S.Title>{title}</S.Title>
      {description ? <S.ModalDescription>{description}</S.ModalDescription> : null}
      <S.CheckBoxRow>
        {!disableCheck ? (
          <>
            <S.CheckBox isChecked={isChecked} onClick={() => setIsChecked(!isChecked)} />
            <S.CheckBoxLabel onClick={() => setIsChecked(!isChecked)}>{checkLabel}</S.CheckBoxLabel>
          </>
        ) : null}
      </S.CheckBoxRow>
      <S.ButtonsContainer>
        <S.ModalButton type="secondary" onClick={onCancel || onClose}>
          {cancelCTA || __('Components.Cart.update_confirmation.cancel')}
        </S.ModalButton>
        <S.ModalButton onClick={() => onConfirm(isChecked)}>
          {disableCheck || !isChecked ? ctaNotChecked : ctaChecked}
        </S.ModalButton>
      </S.ButtonsContainer>
    </ActionsModal>
  );
};
export default React.memo(UpdateConfirmationModal);
