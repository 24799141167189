import {
  __,
  CHANNEL_DISPLAY,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_MEMBER_STATUS,
  CHANNEL_TYPE,
  chatActions,
  colors,
  contactActions,
  CURRENCY_CODES,
  EventTrack,
  INVITE_ORIGIN,
  modalActions,
  notificationsActions,
  orderActions,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import SelectContacts from '../../../screens/select-contacts';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button, FontIcon, LettersAvatar } from '../../atoms';
import SimpleDropdown, { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import { InviteAddressbookModal, Members, NavigationTabs } from '../../molecules';
import * as S from './ChatHeader.styled';

export type RouteProps = RouteComponentProps<{ channelId?: string }>;

export interface StateProps {
  avatar?: string;
  avatarColor: IAvatarColor;
  blocked?: boolean;
  catalogs: { [id: number]: IWorkspace };
  channel?: IChannel;
  children?: React.ReactNode;
  contact?: IContact;
  contacts: { [contactId: number]: IContact };
  currency?: CURRENCY_CODES;
  hideOrdersMagicZero: boolean;
  me: IUser;
  messagesUnreads: number;
  ordersActives: number;
  subtitle: string;
  title: string;
  isClient: boolean;
  workspaceSelected: number;
}

export interface DispatchProps {
  addMembers: typeof chatActions.addMembers;
  cartCleanIfNeeded: typeof orderActions.cartCleanIfNeeded;
  channelArchive: typeof chatActions.channelArchive;
  channelMute: typeof chatActions.channelMute;
  clientsAdd: typeof sellerWorkspaceActions.clientsAdd;
  contactBlock: typeof contactActions.contactBlock;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  notificationShow: typeof notificationsActions.notificationShow;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
}

type IProps = StateProps & DispatchProps & RouteProps;

const ChatHeader: React.FC<IProps> = ({
  addMembers,
  clientsAdd,
  avatar,
  avatarColor,
  isClient,
  blocked,
  cartCleanIfNeeded,
  catalogs,
  channel,
  channelMute,
  channelArchive,
  contactBlock,
  modalClose,
  modalOpen,
  children,
  contactUpdateMySellerWorkspace,
  contact,
  contacts,
  currency,
  hideOrdersMagicZero,
  history,
  match: {
    path,
    params: { channelId },
  },
  me,
  messagesUnreads,
  navigateChannelBySection,
  notificationShow,
  subtitle,
  title,
  workspaceSelected,
}) => {
  const [showInviteModal, setShowInviteModal] = React.useState<INVITE_VIA | undefined>();
  const [showAddContacts, setShowAddContacts] = React.useState<boolean>();
  const [hideInfoTooltip, setHideInfoTooltip] = React.useState<boolean>();
  const [client, setClient] = React.useState<IClient>();
  const [supplier, setSupplier] = React.useState<ISupplier>();

  React.useEffect(() => {
    const contactId = channel?.members?.[0]?.id;
    if (contactId) {
      api.sellerWorkspace
        .clientIdsGet(me.id, contact?.mySellerWorkspaceId || me.sellerWorkspaceId, [contactId])
        .then(c => {
          if (c.clients.length) setClient(c.clients[0]);
        });
      if (me.buyerWorkspaceId) {
        api.buyerWorkspace.supplierIdsGet(me.id, me.buyerWorkspaceId, [contactId]).then(s => {
          if (s.suppliers.length) setSupplier(s.suppliers[0]);
        });
      }
    }
  }, [channel?.members?.[0]?.id, contact?.mySellerWorkspaceId, me]);
  const catalog = catalogs[workspaceSelected];
  React.useEffect(() => {
    setHideInfoTooltip(false);
    const hideTimeout = setTimeout(() => setHideInfoTooltip(true), 3000);
    return () => clearTimeout(hideTimeout);
  }, [channelId]);
  if (!channel || !channelId) return null;

  const canSell =
    (channel.type === CHANNEL_TYPE.GROUP
      ? channel.workspaceId && me.settings.isSeller && !!catalogs[channel.workspaceId]
      : me.settings.isSeller &&
        contact?.isBuyer &&
        !contact.imBlocked &&
        !client?.blockedOrders &&
        contact.mySellerWorkspaceId === workspaceSelected) &&
    (isClient || !config.TOGGLE_CLIENT_CHAT.enabled);
  const canBuy =
    (channel.type === CHANNEL_TYPE.GROUP
      ? channel.workspaceId && me.settings.isBuyer && !catalogs[channel.workspaceId]
      : me.settings.isBuyer && contact.isSeller && !supplier?.blockedOrders) &&
    (!catalog || !config.TOGGLE_CLIENT_CHAT.enabled);
  const tabs = [];
  if (title)
    tabs.push({
      id: 'messages',
      label: __('Components.ContactDetails.Header.chat'),
      badge: messagesUnreads,
      action: () => navigate(CHANNEL_SECTIONS.MESSAGES),
    });
  if (contact && (canBuy || canSell))
    tabs.push({
      id: 'orders',
      label: __('Components.ContactDetails.Header.orders'),
      action: config.TOGGLE_MO_FROM_CHAT.enabled
        ? canSell
          ? () => {
              history.push(
                getPath({
                  path: ROUTE_PATHS.CONTACT_SALES,
                  workspaceId: workspaceSelected.toString(),
                  contactId: contact?.id.toString(),
                }),
              );
            }
          : () => {
              history.push(
                getPath({
                  path: ROUTE_PATHS.CONTACT_PURCHASES,
                  workspaceId: workspaceSelected.toString(),
                  contactId: contact?.id.toString(),
                }),
              );
            }
        : () => navigate(CHANNEL_SECTIONS.ORDERS),
    });
  if (canSell && !blocked && channel.status === 'active' && contact)
    tabs.push({
      id: 'magic-orders',
      label: __('Components.ContactDetails.Header.magic_orders'),
      action: config.TOGGLE_MO_FROM_CHAT.enabled
        ? () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
                contactId: contact?.id.toString(),
                workspaceId: workspaceSelected.toString(),
              }),
            );
          }
        : () => navigate(CHANNEL_SECTIONS.ORDERS_MAGIC),
      new: !hideOrdersMagicZero,
    });
  if (((channel || contact) && !config.TOGGLE_CLIENT_CHAT.enabled) || channel?.type === CHANNEL_TYPE.GROUP)
    tabs.push({
      id: 'info',
      label: __('Components.ContactDetails.Header.info'),
      action: () => navigate(CHANNEL_SECTIONS.INFORMATION),
    });
  if (canBuy && !blocked && channel.status === 'active')
    tabs.push({
      id: 'buy',
      label: utils.firstToUpperCase(__('Showroom.buy').toLocaleLowerCase()),
      color: colors.purple1,
      action: () => {
        const key = productService.getKey(channel.defaultSellerId || contact?.id, me.id);
        cartCleanIfNeeded(key, undefined, channel.workspaceId || contact?.theirSellerWorkspaceId, 0);
        navigate(CHANNEL_SECTIONS.SHOWROOM_BUY);
      },
    });
  if (canSell && !blocked && channel.status === 'active') {
    const catalogToSell =
      catalogs[
        channel.workspaceId ||
          (sellerWorkspaceService.isActive(catalogs[contact?.mySellerWorkspaceId], me.id)
            ? contact?.mySellerWorkspaceId
            : me.sellerWorkspaceId)
      ];
    tabs.push({
      id: 'sell',
      label: utils.firstToUpperCase(__('Showroom.sell').toLocaleLowerCase()),
      color: colors.purple1,
      action: () => {
        const key = productService.getKey(me.id, channel.defaultBuyerId || contact.id);
        cartCleanIfNeeded(
          key,
          currency || catalogToSell?.defaultCurrency,
          channel.workspaceId || contact?.mySellerWorkspaceId || me.sellerWorkspaceId,
          0,
        );
        navigate(CHANNEL_SECTIONS.SHOWROOM_SELL);
      },
    });
  }

  const members = channel?.members
    ?.filter(member => member.status !== 'evicted')
    ?.sort((a, b) => {
      if (!a.name && !b.name) return 0;
      return a.name.length < b.name.length ? 1 : -1;
    })
    .map(member => ({ userId: member.id, name: member.name }));
  return (
    <S.RightContainer>
      <S.Container>
        <S.Content onClick={() => (channel || contact ? navigate(CHANNEL_SECTIONS.INFORMATION) : null)}>
          {avatar || config.TOGGLE_CLIENT_CHAT.enabled ? (
            <S.Icon>
              <LettersAvatar text={title} size={35} img={avatar} avatarColor={avatarColor} type={channel.type} />
            </S.Icon>
          ) : null}
          <S.TitleContainer>
            <S.Title>
              {title || __('Components.Channel.title')}
              <S.SubTitle>{title || subtitle ? '' : __('Components.Channel.new')}</S.SubTitle>
            </S.Title>
            {contact && config.TOGGLE_CLIENT_CHAT.enabled ? (
              <S.Description hide={hideInfoTooltip}>{__('Channel.info_tooltip')}</S.Description>
            ) : null}
          </S.TitleContainer>
          {config.TOGGLE_CLIENT_CHAT.enabled ? (
            <>
              {contact &&
              contact.isBuyer &&
              catalog &&
              (!isClient || contact?.mySellerWorkspaceId !== workspaceSelected) ? (
                <Button type="link" withoutPadding={true} onClick={addToClient}>
                  {contact?.mySellerWorkspaceId === workspaceSelected && workspaceSelected === me.sellerWorkspaceId
                    ? __('ClientsList.add_as_client', { newCatalog: catalog.name })
                    : __('ClientsList.match_catalog', { newCatalog: catalog.name })}
                </Button>
              ) : null}
              <SimpleDropdown
                hAlign="right"
                onSelect={(key: string) => {
                  switch (key) {
                    case 'info':
                      return navigate(CHANNEL_SECTIONS.INFORMATION);
                    case 'add_client':
                      return addToClient();
                    case 'archive':
                      return archiveChannel();
                    case 'mute':
                      return channelMute(me.id!, channel.id, !channel.muted);
                    case 'block':
                      return blockContact();
                  }
                }}
                options={
                  [
                    { key: 'info', value: __('Channel.contact_info') },
                    ...(contact &&
                    contact.isBuyer &&
                    catalog &&
                    (!isClient || contact?.mySellerWorkspaceId !== workspaceSelected)
                      ? [
                          {
                            key: 'add_client',
                            value: !isClient
                              ? __('Channel.add_client')
                              : __('ClientsList.match_catalog', { newCatalog: catalog.name }),
                          },
                        ]
                      : []),
                    {
                      key: 'archive',
                      value:
                        channel.display !== CHANNEL_DISPLAY.ARCHIVED
                          ? __('ContactInfo.archive')
                          : __('ContactInfo.unarchive'),
                    },
                    {
                      key: 'mute',
                      value: channel.muted ? __('Channel.unmute_notifications') : __('Channel.mute_notifications'),
                    },
                    {
                      key: 'block',
                      value: contact?.imBlocking
                        ? __('Components.ContactDetails.unblock_contact.button')
                        : __('Components.ContactDetails.block_contact.button'),
                    },
                  ] as Array<IItem>
                }
              >
                <S.KebabIcon name="Kebab" />
              </SimpleDropdown>
            </>
          ) : null}
        </S.Content>
      </S.Container>
      {((isClient && contact?.mySellerWorkspaceId === workspaceSelected) ||
        !catalog ||
        !config.TOGGLE_CLIENT_CHAT.enabled) &&
      'info' !== getTabSelected() &&
      channel.type !== CHANNEL_TYPE.GROUP ? (
        <NavigationTabs selected={getTabSelected()} tabs={tabs} history={history} />
      ) : null}
      {channel.type === CHANNEL_TYPE.GROUP ? (
        <>
          <NavigationTabs selected={getTabSelected()} tabs={tabs} history={history} />
          <S.MembersContainer
            className="chat-header-members-container"
            onClick={() => navigate(CHANNEL_SECTIONS.INFORMATION)}
          >
            <Members
              contacts={contacts}
              me={me}
              members={members}
              numOfMembers={channel.numMembers}
              showZeroCase={false}
            />
            {channel.invitePolicy === 'any' ||
            ([CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(channel.role) &&
              channel.status === 'active') ? (
              <S.AddMember onClick={() => setShowAddContacts(true)}>
                <FontIcon id="group-icon" name="Add-contact" />
              </S.AddMember>
            ) : null}
          </S.MembersContainer>
        </>
      ) : null}
      {children}
      {showInviteModal ? renderInviteModal() : null}
      {showAddContacts ? renderAddContacts() : null}
    </S.RightContainer>
  );

  function getTabSelected() {
    if (
      [ROUTE_PATHS.CHAT, ROUTE_PATHS.CONTACT, ROUTE_PATHS.GROUP, ROUTE_PATHS.PUBLIC_CHANNEL].includes(
        path as ROUTE_PATHS,
      )
    )
      return 'messages';
    if ([ROUTE_PATHS.CONTACT_INFO, ROUTE_PATHS.GROUP_INFO].includes(path as ROUTE_PATHS)) return 'info';
    return null;
  }

  function renderInviteModal() {
    return (
      <InviteAddressbookModal
        addressbookSubtitle={__('Channel.invite_modal.addresbook.subtitle')}
        addressbookTitle={__('Channel.invite_modal.addresbook.title')}
        channelId={channelId}
        defaultInviteBy={showInviteModal}
        from="chat-header"
        me={me}
        onClose={() => setShowInviteModal(undefined)}
        origin={INVITE_ORIGIN.GROUP}
      />
    );
  }

  /**
   * Navigate to the channel section
   */
  function navigate(s?: CHANNEL_SECTIONS) {
    navigateChannelBySection(s, channelId, !!contact, p => history.push(getPath({ path: p as any, channelId })));
  }

  /**
   * Render modal for add members
   */
  function renderAddContacts(): JSX.Element {
    const contactAddedIds = channel.members.filter(member => member.status === 'active').map(member => member.id);
    const contactsNotAdded = Object.values(contacts).filter(c => !contactAddedIds.includes(c.id) && c.id !== me.id!);

    return (
      <SelectContacts
        channelId={channel?.id}
        onClose={() => setShowAddContacts(false)}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          if (channel.id)
            addMembers(
              me.id,
              channel.id,
              channel.imageUrl,
              contactsToAdd.map(mId => ({
                id: mId,
                companyName: contacts[mId].companyName,
                name: contacts[mId].name,
                role: CHANNEL_MEMBER_ROLE.MEMBER,
                status: CHANNEL_MEMBER_STATUS.ACTIVE,
                avatarColor: contacts[mId].avatarColor,
              })),
              { from: 'chat-header' },
            );
          setShowAddContacts(false);
        }}
        origin={INVITE_ORIGIN.GROUP}
        showUnregistered={true}
        title={__('Channel.invite_modal.title_consentio')}
        subtitle={__('Channel.invite_modal.subtitle_consentio')}
        showInvite={true}
        trackingFrom="chat-header"
      />
    );
  }

  /**
   * On archive chat
   */
  function archiveChannel() {
    const toArchive = channel.display !== CHANNEL_DISPLAY.ARCHIVED;
    channelArchive(me.id, channel.id, toArchive, (error?: Error) => {
      if (error) {
        notificationShow(
          {
            title: __('Notification.error.title'),
            subtitle: __('Notification.error.description'),
            closable: true,
            style: 'error',
          },
          4000,
        );
      } else {
        notificationShow(
          {
            title: toArchive ? __('Notification.archive.title') : __('Notification.unarchive.title'),
            subtitle: toArchive
              ? __('Notification.archive.description_contact')
              : __('Notification.unarchive.description_contact'),
            closable: true,
            style: 'info',
          },
          4000,
        );
      }
    });
  }
  /**
   * Show confirm modal with the info about block a caontact.
   */
  function blockContact() {
    EventTrack.blockUser({
      myId: me.id!,
      contactId: contact.id!,
      action: contact.imBlocking ? 'preunblock' : 'preblock',
    });
    modalOpen(
      contact.imBlocking
        ? __('Components.ContactDetails.unblock_contact.modal.title', { name: contact.name })
        : __('Components.ContactDetails.block_contact.modal.title', { name: contact.name }),
      () => {
        EventTrack.blockUser({
          myId: me.id!,
          contactId: contact.id!,
          action: contact.imBlocking ? 'unblock' : 'block',
        });
        contactBlock(me.id!, contact.id!, !contact.imBlocking, (err: Error) => {
          if (!err) modalClose();
          else {
            modalOpen(__('modal_error.text'), modalClose, {
              buttonText: __('modal_error.accept'),
              icon: 'Delete',
            });
          }
        });
      },
      {
        buttonText: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.button')
          : __('Components.ContactDetails.block_contact.modal.button'),
        icon: contact.imBlocking ? IMAGES.addUser : IMAGES.blockUser,
        subtitle: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.subtitle')
          : __('Components.ContactDetails.block_contact.modal.subtitle'),
        text2: contact.imBlocking
          ? __('Components.ContactDetails.unblock_contact.modal.text')
          : __('Components.ContactDetails.block_contact.modal.text'),

        showCancelButton: true,
        actionType: 'dangerous',
      },
      'nice',
    );
  }

  function addToClient(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e?.stopPropagation();
    e?.preventDefault();
    isClient
      ? modalOpen(
          __('ContactInfo.Menu.workspace.modal.title', {
            client: contact.name,
            workspaceNew: catalog.name,
          }),
          () => contactUpdateMySellerWorkspace(me.id, contact.id, catalog.id, catalog.hashId),
          {
            icon: IMAGES.informativePineapple,
            text2: __('ContactInfo.Menu.workspace.modal.text', {
              client: contact.name,
              workspaceOld: catalogs[contacts[contact.id]?.mySellerWorkspaceId]?.name,
              workspaceNew: catalog.name,
            }),
            buttonText: __('ContactInfo.Menu.workspace.modal.cta'),
            showCancelButton: true,
            buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
          },
          'nice',
        )
      : contact.mySellerWorkspaceId !== workspaceSelected
      ? modalOpen(
          __('ContactInfo.Menu.workspace.modal.title', {
            client: contact.name,
            workspaceNew: catalog.name,
          }),
          () => {
            clientsAdd(
              me.id,
              workspaceSelected,
              [sellerWorkspaceService.contactToClient(contact, workspaceSelected)],
              error => {
                if (error) {
                  return notificationShow({
                    title: __('ClientsList.error', { count: 1 }),
                    subtitle: __('ClientsList.error_description', { count: 1 }),
                    closable: true,
                    style: 'error',
                  });
                }
                return notificationShow({
                  title: __('ClientsList.success', { count: 1 }),
                  subtitle: __('ClientsList.success_description', { count: 1 }),
                  closable: true,
                  style: 'info',
                });
              },
            );
            contactUpdateMySellerWorkspace(me.id, contact.id, catalog.id, catalog.hashId);
          },
          {
            icon: IMAGES.informativePineapple,
            text2: __('ContactInfo.Menu.workspace.modal.text', {
              client: contact?.name,
              workspaceOld: catalogs[contact?.mySellerWorkspaceId]?.name,
              workspaceNew: catalog.name,
            }),

            buttonText: __('ContactInfo.Menu.workspace.modal.cta'),
            showCancelButton: true,
            buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
          },
          'nice',
        )
      : clientsAdd(
          me.id,
          workspaceSelected,
          [sellerWorkspaceService.contactToClient(contact, workspaceSelected)],
          error => {
            if (error) {
              return notificationShow({
                title: __('ClientsList.error', { count: 1 }),
                subtitle: __('ClientsList.error_description', { count: 1 }),
                closable: true,
                style: 'error',
              });
            }
            return notificationShow({
              title: __('ClientsList.success', { count: 1 }),
              subtitle: __('ClientsList.success_description', { count: 1 }),
              closable: true,
              style: 'info',
            });
          },
        );
  }
};
export default React.memo(ChatHeader);
