import { css } from 'styled-components';
import styled, { IFontSize, IFontWeight, ITheme } from '../../../styled-components';
import {
  Badge as BadgeAtom,
  ColumnContainer,
  FontIcon,
  RowContainer,
  TextLabel,
  Tooltip as TooltipAtom,
} from '../../atoms';

interface IStyledProps {
  ctaBlue?: boolean;
  count?: number;
  isExpanded?: boolean;
  hasNotification?: boolean;
  hasSearch?: boolean;
  theme: ITheme;
  size?: keyof IFontSize;
  weight?: keyof IFontWeight;
  hasBackground?: boolean;
}

export const Container = styled(ColumnContainer)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  ${(p: IStyledProps) =>
    p.hasBackground &&
    css`
      background-color: ${p.theme.colors.grey4};
      border: 1px solid ${p.theme.colors.grey3};
      border-radius: ${p.theme.borderRadius};
      margin-bottom: ${p.theme.paddingSize(3)};
    `}
  padding-left:  0;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin: 0;
    padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;

export const HeaderContainer = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-left: 0;

  &:hover {
    .add-icon {
      display: ${p => (p.hasSearch ? 'none' : 'flex')};
    }
  }
`;

export const TitleRow = styled(RowContainer)<IStyledProps>`
  align-items: center;
  cursor: ${p => (p.hasSearch ? 'inherit' : 'pointer')};
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const CenterRow = styled(RowContainer)`
  align-items: center;
`;

export const ChevronIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme!.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const AddIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme!.colors.blue1};
  display: none;
`;

export const CTAContainer = styled(RowContainer)`
  align-items: center;
  min-width: min-content;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
`;

export const AddContainer = styled(RowContainer)`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => (p.ctaBlue ? p.theme!.colors.blue2 : p.theme.colors.grey3)};
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  }
`;

export const AddIconBlack = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => (p.ctaBlue ? p.theme!.colors.blue1 : p.theme!.colors.black)};
`;

export const TextRegular = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.ctaBlue ? p.theme!.colors.blue1 : p.theme!.colors.black)};
  min-width: 140px;
`;

export const TextTitle = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) =>
    p.hasNotification && !p.isExpanded && !p.hasSearch
      ? p.theme.fontWeight.Bold
      : p.theme.fontWeight[p.weight || 'Regular']};
  font-size: ${(p: IStyledProps) => p.theme.fontSize[p.size || 'normal']};
  color: ${(p: IStyledProps) => p.theme!.colors.black};
`;

export const Badge = styled(BadgeAtom)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Tooltip = styled(TooltipAtom)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
`;
