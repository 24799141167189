import { __, ImageGalleryObject } from 'common-services';
import * as React from 'react';
import Lightbox from 'react-images';

interface IProps {
  images: Array<ImageGalleryObject>;
  isOpen: boolean;
  onClose: () => void;
  initialImage: number;
}

/**
 * Image gallery modal
 */
const ImageGallery: React.FC<IProps> = ({ images = [], isOpen = false, onClose = () => null, initialImage }) => {
  const [currentImage, setCurrentImage] = React.useState(initialImage);
  if (!images.length || !isOpen) return null;

  const showThumbnails = images.length > 1;
  return (
    <Lightbox
      images={images}
      isOpen={isOpen}
      onClose={onClose}
      currentImage={currentImage}
      onClickNext={() => setCurrentImage(Math.min(images.length - 1, currentImage + 1))}
      onClickPrev={() => setCurrentImage(Math.max(0, currentImage - 1))}
      backdropClosesModal={true}
      imageCountSeparator={' ' + __('Components.ImageGallery.count_separator') + ' '}
      rightArrowTitle={__('Components.ImageGallery.right_arrow_title')}
      leftArrowTitle={__('Components.ImageGallery.left_arrow_title')}
      closeButtonTitle={__('Components.ImageGallery.close_title')}
      showThumbnails={showThumbnails}
      onClickThumbnail={index => handleThumbnailClick(index)}
    />
  );

  /**
   * Handle click on thumbnail when they are activated
   */
  function handleThumbnailClick(index: number) {
    if (!showThumbnails) return;
    setCurrentImage(index);
  }
};

export default React.memo(ImageGallery);
