import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';
import { WorkspaceSelect } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  showColor?: boolean;
}

export const Container = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  overflow: hidden;
  flex: 1;
  overflow-y: auto;
`;

export const RowSwitch = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SwitchLabel = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const ButtonsContainer = styled(RowContainer)``;

export const ActionButton = styled(Button)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Text = styled(TextLabel)``;

const TeamTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TeamDate = styled(TeamTitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TeamLink = styled(TeamDate)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;
export const BlackText = styled(TeamTitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TextWrapper = styled.div`
  display: flex;
`;
export const ArchiveButton = styled(Button)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const Workspace = styled(WorkspaceSelect)`
  margin: 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${(p: IStyledProps) => p.theme.colors.orange2};
  padding: 2px 3px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const TeamUnactive = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;

export const BackContainer = styled(RowContainer)`
  position: relative;
  align-items: center;
  left: -4px;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
