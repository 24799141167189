import * as React from 'react';

import { sizes } from '../../../theme';
import * as S from './Header.styled';

export interface IHeaderProps {
  show?: boolean;
  title?: string;
  subtitle?: string;
  breadcrumbs?: Array<IBreadcrumb>;
  tabSelected:
    | 'products'
    | 'sale'
    | 'purchase'
    | 'settings-buyer'
    | 'settings-seller'
    | 'clients'
    | 'pricelist'
    | 'prepare-orders'
    | 'suppliers'
    | 'multiple-carts'
    | 'chat'
    | 'offers'
    | 'referential'
    | 'settings';
}

const Header: React.FC<IHeaderProps> = ({ breadcrumbs, show, title = '', subtitle = '' }) => {
  return !show ? null : (
    <S.Container id="header">
      {breadcrumbs ? (
        <S.Content>
          {breadcrumbs.map((b, idx, arr) => {
            const isLast = idx === arr.length - 1;
            if (window.innerWidth <= sizes.ipad && arr.length > 3 && idx < arr.length - 3) {
              return <S.Item>{'... >'}</S.Item>;
            } else {
              return (
                <React.Fragment key={idx}>
                  <S.Item isLast={isLast} onClick={b.action} hasAction={!!b.action}>
                    {b.label}
                  </S.Item>
                  {isLast ? null : <S.Item>{'>'}</S.Item>}
                </React.Fragment>
              );
            }
          })}
        </S.Content>
      ) : (
        <S.Content>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subtitle}</S.SubTitle>
        </S.Content>
      )}
    </S.Container>
  );
};

export default React.memo(Header);
