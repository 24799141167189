import * as React from 'react';

import styled, { css, ITheme } from '../../../styled-components';
import Button from '../Button';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  editable?: boolean;
  hasImage?: boolean;
  height?: string;
  isDragActive?: boolean;
  margin?: string;
  pictureMode?: boolean;
  size?: 'extra-small' | 'small' | 'big';
  theme: ITheme;
  width?: string;
}

const sizes = {
  'extra-small': '30px',
  small: '60px',
  big: '120px',
};
const sizesMobile = {
  'extra-small': '25px',
  small: '50px',
  big: '90px',
};

export const PictureContainer = styled.div<IStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(p: IStyledProps) => p.width || sizes[p.size]};
  width: ${(p: IStyledProps) => p.width || sizes[p.size]};
  min-height: ${(p: IStyledProps) => p.height || sizes[p.size]};
  border-radius: 4px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  position: relative;
  overflow: hidden;
  cursor: ${(p: IStyledProps) => (p.editable ? 'pointer' : 'inherit')};
  transition: all 0.6s;

  ${(p: IStyledProps) =>
    p.margin &&
    css`
      margin: ${p.margin};
    `}

  ${(p: IStyledProps) =>
    p.editable &&
    css`
      &:hover {
        opacity: 0.75;
      }
      &:hover img {
        filter: blur(1px) grayscale(0.95) brightness(0.6);
      }
      &:hover .picture-actions-container {
        display: flex;
      }
    `}

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: ${(p: IStyledProps) => sizesMobile[p.size]};
    height: ${(p: IStyledProps) => sizesMobile[p.size]};
  }
`;

export const PictureLinkContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p: IStyledProps) => sizes[p.size]};
  height: ${(p: IStyledProps) => sizes[p.size]};
  border-radius: 4px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  position: relative;
  overflow: hidden;
  transition: all 0.6s;

  ${(p: IStyledProps) =>
    p.margin &&
    css`
      margin: ${p.margin};
    `}

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: ${(p: IStyledProps) => sizesMobile[p.size]};
    height: ${(p: IStyledProps) => sizesMobile[p.size]};
  }
`;

export const PictureImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.6s;
  border-radius: 4px;
`;

export const PictureActionsContainer = styled(RowContainer)`
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 100%;
  padding: ${(p: IStyledProps) => (p.size === 'small' ? '0 13%' : '0 22%')};
`;

export const FontIconActionWrapper = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => (p.size === 'small' ? p.theme.fontSize.big : p.theme.fontSize.H2)};
`;

export const PictureInputFile = styled.input`
  display: none;
`;

export const CropWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: ${(p: IStyledProps) => p.theme.colors.black.replace(', 1)', ', 0.9)')};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SaveButton = styled(props => <Button {...props} />)`
  margin: 10px;
`;

export const CancelButton = styled(props => <Button {...props} />)`
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: 10px;
`;

/**
 * Get dropzone padding
 */
const getDropzoneContentPadding = (p: IStyledProps) => {
  if (p.hasImage) return 0;
  if (p.pictureMode) return `${p.theme.paddingSize(3)} ${p.theme.paddingSize(2)}`;
  return `${p.theme.paddingSize(3)} ${p.theme.paddingSize(4)}`;
};

/**
 * Get dropzone width
 */
const getDropzoneContentWidth = (p: IStyledProps) => {
  if (p.pictureMode) return p.width || sizes[p.size];
  return p.hasImage ? 'max-content' : '100%';
};

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${getDropzoneContentPadding};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: ${(p: IStyledProps) => (p.hasImage ? '0' : '1px')};
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  background-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.grey4 : p.theme.colors.white)};
  width: ${getDropzoneContentWidth};
  height: ${(p: IStyledProps) => p.height || (p.pictureMode ? sizes[p.size] : 'max-content')};
  min-height: min-content;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
  cursor: pointer;
  ${(p: IStyledProps) =>
    p.margin &&
    css`
      margin: ${p.margin};
    `}
`;

const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const TextSubtitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  display: flex;
`;

export const TextCenter = styled(Text)`
  text-align: center;
`;

export const SelectButton = styled(props => <Button {...props} />)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  width: 120px;
  padding: 5px ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CameraIcon = styled(FontIcon)`
  font-size: 34px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ReadOnly = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-family: inherit;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  flex: 1;
  background: inherit;
  position: relative;
  justify-content: flex-start;
`;

export const Buttons = styled(RowContainer)`
  justify-content: center;
`;
