import { __, ORDER_STATUS, orderActions, productService, sellerWorkspaceSelectors, to } from 'common-services';
import { History } from 'history';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../../../bindings/config';
import { IMAGES } from '../../../../../assets';
import { api } from '../../../../../store';
import { SearchProductModal, UpdateConfirmationModal } from '../../../../molecules';
import MappedProductModal from '../MappedProductModal';
import SetOtherConfirmationModal from '../SetOtherConfirmationModal';

const SearchProducts: React.FC<{
  itemWithIssue?: { item: IOrderItem; originLot?: number; mappingCode?: string; productsMapped?: Array<IProduct> };
  history: History<any>;
  location: any;
  catalog?: IWorkspace;
  featured?: { [key: string]: Array<string> };
  me: IUser;
  order?: IOrder;
  priceGroupIds?: Array<string>;
  priceMode?: IPriceMode;
  prices?: { [key: number]: IPrice };
  weAreBuyer: boolean;
  weAreSeller: boolean;
  changeProductItem: (forAll: boolean, item: IOrderItem) => void;
  prodTypes: Record<string, IProdType>;
  onClose: () => void;
}> = ({
  catalog,
  featured,
  history,
  itemWithIssue,
  location,
  me,
  order,
  priceMode,
  prices,
  priceGroupIds,
  weAreSeller,
  weAreBuyer,
  changeProductItem,
  prodTypes,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [showSearchProduct, setShowSearchProduct] = React.useState<boolean>();
  const [showSetOtherConfirmation, setShowSetOtherConfirmation] = React.useState<IOrderItem>();
  const [itemUpdated, setItemUpdated] = React.useState<IOrderItem>();
  const [showSetMappingConfirmation, setShowSetMappingConfirmation] = React.useState<Array<IMapping>>();
  const [showUpdateConfirmation, setShowUpdateConfirmation] = React.useState<boolean>(false);
  const [showCustomUpdateModal, setShowCustomUpdateModal] = React.useState<boolean>(false);
  const client = useSelector(sellerWorkspaceSelectors.getClient(Number(catalog.id), Number(order.buyerId)));
  /**
   * Select product item
   */
  const selectProductItem = (item: IOrderItem) => {
    setShowUpdateConfirmation(true);
    setItemUpdated(item);
  };
  const renderCustomUpdateModal = (item: IOrderItem) => {
    setShowCustomUpdateModal(true);
    setItemUpdated(item);
  };
  const showSearchProductModal = async () => {
    setShowSearchProduct(true);
  };
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showUpdateConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    return (
      <UpdateConfirmationModal
        disableCheck={amViewer}
        defaultCheck={amViewer}
        checkLabel={__('Components.Cart.update_confirmation.check')}
        onClose={onClose}
        onCancel={() => setShowUpdateConfirmation(false)}
        ctaChecked={__('Components.Cart.update_confirmation.cta2')}
        ctaNotChecked={__('Components.Cart.update_confirmation.cta')}
        onConfirm={isChecked => changeProductItem(!isChecked, itemUpdated)}
        title={__('Components.Cart.update_confirmation.title', { name: itemWithIssue.item.title })}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue for a specific client)
   */
  if (showCustomUpdateModal) {
    const externalCode = order.issues.find(i => i.orderItemId === itemWithIssue.item.id).code;
    return (
      <UpdateConfirmationModal
        checkLabel={__('Components.Cart.update_confirmation.check')}
        onClose={() => setShowCustomUpdateModal(false)}
        ctaChecked={__('Components.Cart.update_confirmation.cta2')}
        ctaNotChecked={__('Components.Cart.update_confirmation.cta')}
        image={IMAGES.notificationsPineapple}
        onConfirm={isChecked => {
          changeProductItem(isChecked ? false : true, itemUpdated);
          onClose();
        }}
        title={__('Components.OrderDetails.setOtherConfirmationModal.title_client', {
          count: 1,
          name: itemWithIssue.item.title,
          code: externalCode,
          client: client.name,
        })}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showSetOtherConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    return (
      <SetOtherConfirmationModal
        disableCheck={amViewer}
        defaultCheck={amViewer}
        client={client}
        onClose={onClose}
        onConfirm={isChecked => changeProductItem(!isChecked, itemUpdated)}
        productName={
          config.TOGGLE_MAPPINGS_PER_CLIENT.enabled
            ? showSetOtherConfirmation?.productTitle ||
              showSetOtherConfirmation.type + ' ' + showSetOtherConfirmation.variety
            : itemWithIssue.item.title
        }
        onCancel={() => setShowSetOtherConfirmation(undefined)}
        externalCode={order.issues.find(i => i.orderItemId === itemWithIssue.item.id).code}
        mappedProducts={itemWithIssue.productsMapped}
        prodTypes={prodTypes}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showSetMappingConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    const externalCode = order.issues.find(i => i.orderItemId === itemWithIssue.item.id).code;
    return (
      <UpdateConfirmationModal
        defaultCheck={amViewer}
        onClose={onClose}
        onCancel={() => setShowSetMappingConfirmation(undefined)}
        disableCheck={amViewer}
        onConfirm={checked => changeProductItem(!checked, itemUpdated)}
        title={__('Components.OrderDetails.setMappingConfirmationModal.title', {
          name: itemUpdated.title,
          code: externalCode,
        })}
        description={__('Components.OrderDetails.setMappingConfirmationModal.description', {
          name: itemUpdated.title,
          code: externalCode,
        })}
        image={IMAGES.notificationsPineapple}
        checkLabel={__('Components.OrderDetails.setMappingConfirmationModal.check')}
        cancelCTA={__('Components.OrderDetails.setMappingConfirmationModal.cancel')}
        ctaNotChecked={__('Components.OrderDetails.setMappingConfirmationModal.cta')}
        ctaChecked={__('Components.OrderDetails.setMappingConfirmationModal.cta2')}
      />
    );
  }

  /**
   * Render search product page
   */
  if (itemWithIssue.productsMapped?.length && !showSearchProduct) {
    return (
      <MappedProductModal
        amSeller={weAreSeller}
        catalog={catalog}
        close={onClose}
        featured={featured}
        history={history}
        myId={me.id}
        name={itemWithIssue?.mappingCode || ''}
        priceMode={priceMode}
        item={itemWithIssue.item}
        products={itemWithIssue.productsMapped}
        setProductSelected={item => {
          config.TOGGLE_MAPPINGS_PER_CLIENT.enabled ? renderCustomUpdateModal(item) : changeProductItem(false, item);
        }}
        viewAll={showSearchProductModal}
      />
    );
  }

  return (
    <SearchProductModal
      amSeller={weAreSeller}
      addressId={order.deliverToId}
      cartClean={(notUpdate?: boolean) =>
        dispatch(
          orderActions.cartClean(productService.getKey(weAreBuyer ? order.sellerId : me.id, order.buyerId), notUpdate),
        )
      }
      catalog={catalog}
      close={onClose}
      contactId={weAreBuyer ? order.sellerId : order.buyerId}
      featured={featured}
      history={history}
      isServedFlow={order.status === ORDER_STATUS.ACCEPTED && order.servedFlowEnabled}
      location={location}
      me={me}
      name={itemWithIssue?.item.title || ''}
      priceMode={priceMode}
      prices={prices}
      priceGroupIds={priceGroupIds}
      selectMode="integration"
      selectProductItem={async item => {
        if (itemWithIssue.productsMapped?.length) {
          setShowSetOtherConfirmation(item);
          setItemUpdated(item);
          return;
        }
        const { data } = await to(
          api.mapping.getProductMappingsForInternalRef(
            me.id,
            order.catalogId,
            catalog?.productMappingStrategy === 'sku' ? item.sku : item.eanCode,
          ),
        );
        if (data?.length) {
          setShowSetMappingConfirmation(data);
          setItemUpdated(item);
          return;
        }
        selectProductItem(item);
      }}
      showFeatured={false}
      status="active"
      originLot={itemWithIssue.originLot}
    />
  );
};

export default SearchProducts;
