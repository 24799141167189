import { __ } from 'common-services';
import * as React from 'react';

import * as S from './Reaction.styled';

export interface IProps {
  className?: string;
  code: string;
  members: Array<IMember>;
  myId: number;
  contactsId: Array<string>;
  onClick: () => void;
}

export default React.memo(({ code, className, contactsId, members, myId, onClick }: IProps) => {
  const amReacted = contactsId.includes(myId + '');
  const membersString = members.filter(m => contactsId.includes(m.id + '')).map(m => m.name);
  if (!code) return null;
  if (amReacted) membersString.push(__('Components.Chat.you'));
  let content: string = '';
  try {
    content = String.fromCodePoint.apply(
      null,
      code.split('-').map(c => `0x${c}`),
    );
  } catch (error) {
    return null;
  }
  return (
    <S.Wrapper amReacted={amReacted} onClick={onClick}>
      <S.Emoji className={className} content={content} />
      <S.ReactionText className="reactionText">{membersString.join(', ')}</S.ReactionText>
      <S.Number>{contactsId.length}</S.Number>
    </S.Wrapper>
  );
});
