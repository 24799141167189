import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';
import AM from '../ActionsModal/ActionsModal.component';
import SimpleSearch from '../SimpleSearch';

interface IStyledProps {
  theme: ITheme;
}

export const ActionsModal = styled(AM)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Scroll = styled.div<IStyledProps>`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FlexCol = styled(ColumnContainer)``;

export const FooterRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CTA = styled(Button)`
  min-width: 200px;
`;

export const Search = styled(SimpleSearch)`
  margin: 0;
  margin-top: 5px;
  flex: 0;
`;

export const SectionTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  width: 100%;
  padding: calc(${(p: IStyledProps) => p.theme.paddingView} / 2) 0;
`;
