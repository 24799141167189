import {
  __,
  IOrderDownloadOptions,
  IPdfColumn,
  notificationsActions,
  sellerWorkspaceSelectors,
  to,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import Workspace from '../Workspace/Workspace.component';
import { api } from '../../../store';
import { ExpansionPanel, Select } from '../../atoms';
import { FormContainer, InputWithLabel, SortableLine } from '../../molecules';
import * as S from './WorkspacePdfFormat.styled';

interface IProps {
  pdfFormat: IOrderDownloadOptions;
  history: any;
  location: any;
  match: any;
  onBack: () => void;
  updateFormat: (pdfFormat: IOrderDownloadOptions) => void;
  deleteFormat: () => void;
}

const WorkspacePdfFormats: React.FC<IProps> = ({
  deleteFormat,
  history,
  location,
  match,
  onBack,
  pdfFormat,
  updateFormat,
}) => {
  const dispatch = useDispatch();
  const me = useSelector(userSelectors.getUser);
  const role = useSelector(sellerWorkspaceSelectors.getRoleInCatalog(pdfFormat.workspaceId, me.id));
  const [newPdfFormat, setNewPdfFormat] = React.useState(pdfFormat);
  const [showCustomColumn, setShowCustomColumn] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const inputNameRef = React.createRef<HTMLInputElement>();
  const updatePdfFormat = React.useCallback(async () => {
    if (!newPdfFormat.name) {
      setNameError(true);
      inputNameRef.current?.focus();
      return;
    } else setNameError(false);

    newPdfFormat.config.columns = newPdfFormat.config.columns.map((c, i) => ({ ...c, column: getColumnLetter(i) }));
    const res = await to(api.sellerWorkspace.updateSettingsDownloadPdf(me.id, newPdfFormat.workspaceId, newPdfFormat));
    if (res.err) {
      return;
    }
    dispatch(
      notificationsActions.notificationShow({
        title: __('Components.Settings.saved'),
        subtitle: __('Components.Settings.saved_subtitle'),
        closable: true,
        style: 'info',
      }) as any,
    );
    updateFormat(res.data);
    onBack();
  }, [newPdfFormat, me.id, onBack, updateFormat]);
  const addColumnPdfFormat = React.useCallback(
    async (field: string, displayName: string) => {
      setNewPdfFormat({
        ...newPdfFormat,
        config: {
          ...newPdfFormat.config,
          columns: [
            ...newPdfFormat.config.columns,
            { field, column: getColumnLetter(newPdfFormat.config.columns.length), displayName },
          ],
        },
      });
    },
    [newPdfFormat, setNewPdfFormat],
  );
  if (!role) return null;
  const options = firstOptions().filter(o => !newPdfFormat.config.columns.find(c => c.field === o.value));
  return (
    <S.FixedContainer>
      <Workspace
        parentSections={[
          {
            label: __('Components.Header.WorkspaceSettings'),
            action: () => onBack(),
          },
        ]}
        subtitle={''}
        title={pdfFormat.id ? __('PdfFormat.title') : __('PdfFormat.title_new')}
        tabSelected="settings-seller"
        workspaceId={newPdfFormat.workspaceId}
      >
        <S.Container>
          {pdfFormat.id ? (
            <S.TrashButton iconName="Trash" iconSize="16px" className="Trash-icon" type="skip" onClick={deleteFormat}>
              {__('PdfFormat.remove')}
            </S.TrashButton>
          ) : null}
          <FormContainer
            className="WorkspacePdfFormat-FormContainer"
            canSave={pdfFormat !== newPdfFormat}
            save={updatePdfFormat}
          >
            <>
              <S.FormBlock>
                <ExpansionPanel title={__('PdfFormat.name.title')} iconPosition="left" defaultExpanded={true}>
                  <InputWithLabel description={__('PdfFormat.name.description')} isRequired>
                    <S.Input
                      disabled={role === 'viewer'}
                      hasError={nameError}
                      isRequired={true}
                      name="name"
                      inputRef={inputNameRef}
                      onBlur={(n, name: string) => setNewPdfFormat({ ...newPdfFormat, name })}
                      onChange={(n, name: string) => setNewPdfFormat({ ...newPdfFormat, name })}
                      placeholder={__('PdfFormat.name.placeholder')}
                      trim={true}
                      value={newPdfFormat.name}
                      zeroCase={newPdfFormat.name}
                    />
                  </InputWithLabel>
                </ExpansionPanel>
                <ExpansionPanel title={__('PdfFormat.type.title')} iconPosition="left" defaultExpanded={true}>
                  <InputWithLabel isRequired>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.isExcel}
                        disabled={role === 'viewer'}
                        onClick={val => setNewPdfFormat({ ...newPdfFormat, isExcel: val })}
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val => setNewPdfFormat({ ...newPdfFormat, isExcel: !newPdfFormat.isExcel })}
                      >
                        Excel
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.isPdf}
                        disabled={role === 'viewer'}
                        onClick={val => setNewPdfFormat({ ...newPdfFormat, isPdf: val })}
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val => setNewPdfFormat({ ...newPdfFormat, isPdf: !newPdfFormat.isPdf })}
                      >
                        PDF
                      </S.OptionText>
                    </S.Option>
                  </InputWithLabel>
                  {newPdfFormat.isPdf ? (
                    <InputWithLabel label={__('PdfFormat.config.fontSize')} isRequired={false}>
                      <Select
                        name={'fontSize'}
                        onChange={(n, field, err, displayName) => {
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: {
                              ...newPdfFormat.config,
                              fontSize: field as 'big' | 'small' | 'title' | 'tiny' | 'normal',
                            },
                          });
                        }}
                        options={[
                          { value: 'tiny', label: 1 },
                          { value: 'small', label: 2 },
                          { value: 'normal', label: 3 },
                          { value: 'big', label: 4 },
                          { value: 'title', label: 5 },
                        ]}
                        disabled={role === 'viewer'}
                        value={newPdfFormat.config.fontSize + ''}
                        width="366px"
                      />
                    </InputWithLabel>
                  ) : null}
                </ExpansionPanel>
                <ExpansionPanel title={__('PdfFormat.columns.title')} iconPosition="left" defaultExpanded={true}>
                  <S.InputDescription>{__('PdfFormat.columns.subtitle')}</S.InputDescription>
                  <InputWithLabel
                    isRequired
                    label={__('PdfFormat.columns.label')}
                    description={__('PdfFormat.columns.sublabel')}
                  >
                    <Select
                      name={'new_column'}
                      onChange={(n, field, err, displayName) => {
                        if (field === 'new') {
                          setShowCustomColumn(true);
                        } else {
                          addColumnPdfFormat(field, displayName);
                        }
                      }}
                      options={options}
                      disabled={role === 'viewer'}
                      value={''}
                      maxWidth="366px"
                    />
                    {newPdfFormat.config.columns.length ? (
                      <S.DnDContainer>
                        <DndProvider backend={HTML5Backend}>
                          {newPdfFormat.config.columns.map((column, i) => {
                            if (!column) return null;
                            return (
                              <SortableLine
                                key={column.field}
                                name={utils.firstToUpperCase(
                                  column.displayName ||
                                    firstOptions().find(o => o.value === column.field)?.label ||
                                    customOptions().find(o => o.value === column.field)?.label ||
                                    '',
                                )}
                                index={i}
                                indexText={getColumnLetter(i)}
                                moveLine={(dragIndex: number, hoverIndex: number) => {
                                  const columns = [...newPdfFormat.config.columns];
                                  columns.splice(hoverIndex, 0, columns.splice(dragIndex, 1)[0]);
                                  setNewPdfFormat({
                                    ...newPdfFormat,
                                    config: { ...newPdfFormat.config, columns },
                                  });
                                }}
                                editMode={role !== 'viewer'}
                                onDelete={() => {
                                  const columns = [...newPdfFormat.config.columns];
                                  columns.splice(i, 1);
                                  setNewPdfFormat({
                                    ...newPdfFormat,
                                    config: { ...newPdfFormat.config, columns },
                                  });
                                }}
                              />
                            );
                          })}
                        </DndProvider>
                      </S.DnDContainer>
                    ) : null}
                  </InputWithLabel>
                </ExpansionPanel>
                <ExpansionPanel title={__('PdfFormat.config.title')} iconPosition="left" defaultExpanded={true}>
                  <InputWithLabel isRequired>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.load}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({ ...newPdfFormat, config: { ...newPdfFormat.config, load: val } })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, load: !newPdfFormat.config.load },
                          })
                        }
                      >
                        {__('PdfFormat.config.load')}
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.transportDetails}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, transportDetails: val },
                          })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: {
                              ...newPdfFormat.config,
                              transportDetails: !newPdfFormat.config.transportDetails,
                            },
                          })
                        }
                      >
                        {__('PdfFormat.config.transportDetails')}
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.incoterms}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({ ...newPdfFormat, config: { ...newPdfFormat.config, incoterms: val } })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, incoterms: !newPdfFormat.config.incoterms },
                          })
                        }
                      >
                        {__('PdfFormat.config.incoterms')}
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.totalPrice}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({ ...newPdfFormat, config: { ...newPdfFormat.config, totalPrice: val } })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, totalPrice: !newPdfFormat.config.totalPrice },
                          })
                        }
                      >
                        {__('PdfFormat.config.totalPrice')}
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.orderComments}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, orderComments: val },
                          })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, orderComments: !newPdfFormat.config.orderComments },
                          })
                        }
                      >
                        {__('PdfFormat.config.orderComments')}
                      </S.OptionText>
                    </S.Option>
                    <S.Option>
                      <S.Check
                        isChecked={newPdfFormat.config.showBorders}
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, showBorders: val },
                          })
                        }
                        size={20}
                      />
                      <S.OptionText
                        disabled={role === 'viewer'}
                        onClick={val =>
                          setNewPdfFormat({
                            ...newPdfFormat,
                            config: { ...newPdfFormat.config, showBorders: !newPdfFormat.config.showBorders },
                          })
                        }
                      >
                        {__('PdfFormat.config.showBorders')}
                      </S.OptionText>
                    </S.Option>
                  </InputWithLabel>
                </ExpansionPanel>
              </S.FormBlock>
            </>
          </FormContainer>
        </S.Container>
      </Workspace>
      {showCustomColumn ? (
        <AddCustomColumn
          setShowCustomColumn={setShowCustomColumn}
          addColumnPdfFormat={addColumnPdfFormat}
          columns={newPdfFormat.config.columns}
        />
      ) : null}
    </S.FixedContainer>
  );
};
export default React.memo(WorkspacePdfFormats);

function getColumnLetter(numColumns: number) {
  return String.fromCharCode(65 + numColumns);
}
const AddCustomColumn: React.FC<{
  setShowCustomColumn: (show: boolean) => void;
  addColumnPdfFormat: (field: string, displayName: string) => void;
  columns: Array<IPdfColumn>;
}> = ({ setShowCustomColumn, addColumnPdfFormat, columns }) => {
  const [field, setField] = React.useState<string>('');
  const [displayName, setDisplayName] = React.useState<string>('');
  return (
    <S.NewColumnModal
      title={__('PdfFormat.newColumn.title')}
      onClose={() => setShowCustomColumn(false)}
      ctaText={__('PdfFormat.newColumn.add')}
      onSubmit={() => {
        setShowCustomColumn(false);
        addColumnPdfFormat(field, displayName);
      }}
      ctaDisabled={!field || !displayName}
    >
      <InputWithLabel label={__('PdfFormat.newColumn.display_name_label')} isRequired>
        <S.Input
          name="displayName"
          placeholder={__('PdfFormat.newColumn.display_name_placeholder')}
          value={displayName}
          trim={true}
          isRequired={true}
          onChange={(n, dn: string) => setDisplayName(dn)}
          onBlur={(n, dn: string) => setDisplayName(dn)}
          zeroCase={displayName}
        />
      </InputWithLabel>
      <InputWithLabel label={__('PdfFormat.newColumn.type_label')} isRequired>
        <Select
          onChange={(n, f, err, dn) => {
            setField(f);
            if (!['custom_column', 'custom_column_big'].includes(f)) setDisplayName(utils.firstToUpperCase(dn));
          }}
          options={customOptions()
            .filter(
              o => ['custom_column', 'custom_column_big'].includes(o.value) || !columns.find(c => c.field === o.value),
            )
            .map(o => ({ ...o, label: utils.firstToUpperCase(o.label) }))}
          value={field}
          maxWidth="366px"
          width="366px"
        />
      </InputWithLabel>
    </S.NewColumnModal>
  );
};

const firstOptions = () => {
  const list = [
    { value: 'sku', label: __('order.product.sku') },
    { value: 'ean_code', label: __('PdfFormat.columns.ean') },
    { value: 'description', label: __('PdfFormat.columns.description') },
    { value: 'price', label: __('PdfFormat.columns.unitPrice') },
    { value: 'tax', label: __('PdfFormat.columns.tax') },
    { value: 'served_quantity', label: __('Components.Cart.items.served_quantity') },
    { value: 'total_price', label: __('PdfFormat.columns.totalPrice') },
    { value: 'tare', label: __('PdfFormat.columns.tare') },
  ].sort((a, b) => a.label.localeCompare(b.label));
  list.push({ value: 'new', label: __('PdfFormat.columns.new') });
  return list;
};

const customOptions = () => {
  const list = [
    { value: 'amount', label: __('Components.Cart.items.quantity') },
    { value: 'category', label: __('Components.ProductDetails.product.category') },
    { value: 'origin', label: __('Components.ProductDetails.product.origin') },
    { value: 'size', label: __('Components.ProductDetails.product.size') },
    { value: 'served_weight', label: __('Components.Cart.items.weight') },
    { value: 'brand', label: __('Components.ProductDetails.product.brand') },
    { value: 'organic', label: __('Components.ProductDetails.product.organic') },
    { value: 'pieces_per_box', label: __('Components.ProductDetails.distribution.piecesPerBox') },
    { value: 'box_type', label: __('Components.ProductDetails.distribution.boxType') },
    { value: 'box_weight', label: __('Components.ProductDetails.distribution.boxWeight') },
    { value: 'packaging', label: __('Components.ProductDetails.distribution.packaging') },
    { value: 'boxes_per_pallet', label: __('Components.ProductDetails.distribution.boxesPerPallet') },
    { value: 'pallet_type', label: __('Components.ProductDetails.distribution.palletType') },
  ].sort((a, b) => a.label.localeCompare(b.label));
  list.push(
    { value: 'custom_column_big', label: __('PdfFormat.newColumn.emptyColumn') },
    {
      value: 'custom_column',
      label: __('PdfFormat.newColumn.emptyColumnSmall'),
    },
  );
  return list;
};
