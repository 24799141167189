import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, FontIcon } from '../../atoms';

interface IStyledProps {
  margin?: string;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

export const BodyContainer = styled(RowContainer)`
  background-color: white;
  width: 100%;
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingView};
  overflow-y: auto;
`;

export const LeftContainer = styled(ColumnContainer)`
  width: 35%;
  padding-right: 15px;
`;

export const RightContainer = styled(ColumnContainer)`
  width: 65%;
`;

export const ResultsTitle = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ResultsUl = styled.ul`
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
  width: 100%;
  list-style-type: circle;
`;

export const ResultsLi = styled.li`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ButtonBack = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const Title = styled.span`
  display: block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.primary};
  text-align: left;
  text-transform: uppercase;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin: ${(p: IStyledProps) => p.margin || '0 0 ' + p.theme.paddingView + ' 0'};
`;

export const LangSelect = styled.select`
  appearance: none;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  margin-top: 2px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32'%3E%3Ctitle%3Echevron-down%3C/title%3E%3Cpath d='M26.514 7.314h-20.114c-0.914 0-1.463 1.006-0.914 1.829l10.057 14.263c0.457 0.64 1.371 0.64 1.829 0l10.057-14.263c0.549-0.731 0-1.829-0.914-1.829z'%3E%3C/path%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  line-height: 1.3;
  padding: 0 1.4em 0.2em 0.3em;
  width: 60px;
`;

export const Label = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const MicrophoneContainer = styled(RowContainer)`
  width: 40px;
  height: 40px;
  margin-top: 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.light1};
  cursor: pointer;
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.5)')};
  }
`;
export const MicrophoneIcon = styled(FontIcon)`
  font-size: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
