import {
  Api,
  buyerWorkspaceActions,
  imageActions,
  modalActions,
  notificationsActions,
  sellerWorkspaceActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { supportAction } from '../actions/user';

import WorkspaceSettings, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceSettingsSeller';
import { IReduxState } from '../reducers';

const emptyArray: Array<any> = [];
function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    catalog: { catalogs },
    contact: { inConsentio },
    user: { user },
    country: { countries },
    workspace: { addresses },
  } = state!;
  const catalogId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;

  return {
    contacts: inConsentio,
    catalog: catalogs[catalogId],
    me: user!,
    countries,
    addresses: addresses?.[catalogId] || emptyArray,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      catalogUpdate: sellerWorkspaceActions.catalogUpdate,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      supportAction,
      touchImage: modalActions.touchImage,
      workspaceAddressAdd: buyerWorkspaceActions.workspaceAddressAdd,
      workspaceAddressDelete: buyerWorkspaceActions.workspaceAddressDelete,
      workspaceAddressesGet: buyerWorkspaceActions.workspaceAddressesGet,
      workspaceAddressUpdate: buyerWorkspaceActions.workspaceAddressUpdate,
      requestProInfo: userActions.requestProInfo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettings);
