import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import IF from '../InviteForm';
import SI from '../ShareInvite';

interface IStyledProps {
  channelsCount?: number;
  numHeaders?: number;
  theme: ITheme;
}

export const SearchAddressbook = styled(RowContainer)`
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Buttons = styled(RowContainer)`
  justify-content: space-between;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const AddressbookContainer = styled(ColumnContainer)`
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`;

export const InviteForm = styled(IF)`
  padding: 0;
  overflow-y: auto;
  flex: 1;
`;

export const CenterContainer = styled(ColumnContainer)`
  align-items: center;
  align-self: center;
  justify-content: center;
  flex: 1;
  max-width: 380px;
`;

export const DividerContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DividerBar = styled(RowContainer)`
  height: 1px;
  width: 45%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
`;

export const TextGrey = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const ShareInvite = styled(SI)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  align-self: center;
`;

export const CTA = styled(Button)`
  flex: 0.45;
  width: auto;
`;
