import { AxiosError } from 'axios';
import {
  __,
  currency,
  HOUR_FORMAT,
  IAggregationGroup,
  IContact,
  ISearchOrder,
  prodTypeSelectors,
  productService,
  WEIGHT_UNIT,
} from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ExpansionPanel } from '../../atoms';
import * as S from './AggregationTable.styled';
import { GroupList } from './Fragments';

export interface IProps {
  asSeller: boolean;
  contacts: { [cId: number]: IContact };
  hourFormat: HOUR_FORMAT;
  myId: number;
  onClick: (orderHash: string) => void;
  orders: IAggregationGroup;
  searchState: ISearchOrder;
  weightUnit: WEIGHT_UNIT;
}

const AggregationTable: React.FC<IProps> = ({
  asSeller,
  searchState,
  contacts,
  hourFormat,
  myId,
  onClick,
  orders,
  weightUnit,
}) => {
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  return (
    <S.Container className="aggregation-table-container">
      {Object.keys(orders).map(k => renderGroup(orders[k], k))}
    </S.Container>
  );

  /**
   * Returns a group component of orderitem list
   */
  function renderGroup(o: IAggregationGroup, key: string) {
    return (
      <S.ItemsPanel key={key} className="group-row">
        <ExpansionPanel
          contentWithLeftPadding={false}
          title={`${productService.getProductTypeVarietyDisplay(
            o.type,
            prodTypes[o.type] ? prodTypes[o.type].name : '',
            o.title,
          )} ${o.category} ${o.origin}`}
          defaultExpanded={false}
          iconPosition="left"
          closedDescription={
            <S.TableOrders style={{ position: 'absolute', background: 'transparent' }}>
              <S.Body>
                <tr>
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.ClosedCol>{getTotalAmountText(o.amount)} </S.ClosedCol>
                  <S.ClosedCol>-</S.ClosedCol>
                  <S.ClosedCol>{currency.getPriceAbbreviation(searchState.currency, o.priceSum)}</S.ClosedCol>
                </tr>
              </S.Body>
            </S.TableOrders>
          }
        >
          <GroupList
            asSeller={asSeller}
            hourFormat={hourFormat}
            contacts={contacts}
            searchState={{ ...searchState, groupKey: key, onlyAggregation: false }}
            myId={myId}
            onClick={onClick}
            footerChildren={renderSubtotal(o, key)}
          />
        </ExpansionPanel>
      </S.ItemsPanel>
    );
  }

  /**
   * Render subtotal line for an aggregation group
   */
  function renderSubtotal(o: IAggregationGroup, key: string) {
    return (
      <tfoot>
        <S.SubTotalTr key={key + '-subtotal'} className="orders-subtotal">
          <S.AutoCol colSpan={6} />
          <S.SubTotalCol>{__('Components.OrdersList.Subtotal')}</S.SubTotalCol>
          <S.BoldCol>{getTotalAmountText(o.amount)}</S.BoldCol>
          <S.ClosedCol>-</S.ClosedCol>
          <S.PriceCol>{currency.getPriceAbbreviation(searchState.currency, o.priceSum)}</S.PriceCol>
        </S.SubTotalTr>
      </tfoot>
    );
  }

  /**
   * Get total amount
   * Example: "X pallets & X boxes & X kg"
   */
  function getTotalAmountText(amount: { [key: string]: number }) {
    const result = [];

    if (amount.pallet)
      result.push(amount.pallet.toFixed(1) + ' ' + __('Constants.ProductUnitPlural.pallet', { count: amount.pallet }));

    if (amount.box)
      result.push(amount.box.toFixed(0) + ' ' + __('Constants.ProductUnitPlural.box', { count: amount.box }));

    if (amount.kg) result.push(`${amount.kg.toFixed(1)} ${weightUnit}`);

    if (amount.piece)
      result.push(amount.piece.toFixed(0) + ' ' + __('Constants.ProductUnitPlural.piece', { count: amount.piece }));

    return result.length ? result.join(' & ') : '-';
  }
};

export default React.memo(AggregationTable);
