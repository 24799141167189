import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isRead?: boolean;
  notificationType?: 'alert' | 'informative' | 'undefined';
  side?: 'left' | 'right';
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  display: grid;
  grid-template-columns: 1fr 6fr 2fr;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  align-items: start;
  cursor: pointer;
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
    opacity: 0.75;
  }
`;

export const NotificationTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  overflow: hidden;
`;

export const NotificationTitleBold = styled(NotificationTitle)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const NotificationSubtitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

const getIconColor = (p: IStyledProps) => {
  if (p.notificationType === 'informative') return p.theme.colors.blue1;
  if (p.notificationType === 'alert') return p.theme.colors.orange1;
  return p.theme.colors.grey1;
};

export const NotificationIcon = styled(FontIcon)<IStyledProps>`
  align-self: start;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  vertical-align: middle;
  color: ${getIconColor};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const TextContainer = styled(ColumnContainer)`
  width: auto;
  overflow: hidden;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const TextWrapper = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  line-height: 22px;
`;

export const RightColumn = styled(ColumnContainer)`
  width: auto;
  align-items: flex-end;
`;

export const Cta = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
`;

export const TextGrey2 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextOrange = styled(TextLabel)<IStyledProps>`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  margin-right: ${(p: IStyledProps) => (p.isRead ? '0' : p.theme.paddingSize(1.5))};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  background-color: ${(p: IStyledProps) => p.theme.colors.orange2.replace('1)', '0.5)')};
`;

export const UnreadIndicator = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const AlertWrapper = styled(RowContainer)`
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
`;
export const BlueText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  overflow: hidden;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
