import { css } from 'styled-components';
import styled, { ITheme } from '../../../styled-components';
import SimpleDropdown from '../../atoms/SimpleDropdown';

interface IStyledProps {
  isMe?: boolean;
  theme: ITheme;
}

export const Mention = styled.span`
  display: inline-block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  background: ${(p: IStyledProps) => (p.isMe ? p.theme.colors.orange2 : p.theme.colors.blue2)};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};

  ${(p: IStyledProps) =>
    !p.isMe &&
    css`
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    `}
`;

export const Link = styled(SimpleDropdown)`
  display: inline-block;
`;
