import { IMAGES } from '../../../assets';
import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, LettersAvatar as LA, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isFooter?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  background-image: url('${IMAGES.loginBackground}');
  background-position: 54% 23%;
  align-items: center;
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 100%;
  display: ${(p: IStyledProps) => (p.isFooter ? 'flex' : 'none')};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: ${(p: IStyledProps) => (p.isFooter ? 'none' : 'flex')};
    background-size: ${(p: IStyledProps) => p.theme.sizes.mobile};
    padding: ${(p: IStyledProps) => p.theme.paddingSize()} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const Content = styled(RowContainer)`
  max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l};
  width: min(${(p: IStyledProps) => p.theme.sizes.desktop_l}, 100vw);
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const UserImage = styled(LA)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TextsContainer = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  justify-content: center;
`;

export const TextRegular = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const TextTitle = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: 2px;
`;

export const TextBold = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
`;

export const InstallButton = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: 36px;
  min-width: 230px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 80px;
    width: 90px;
  }
`;
