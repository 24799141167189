import { WORKING_STATUS } from 'common-services';

import { AVAILABILITY } from '../../../constants';
import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  isSelected?: boolean;
  theme: ITheme;
  width?: string;
  workingStatus?: WORKING_STATUS | AVAILABILITY;
}

export const Container = styled.div<IStyledProps>`
  width: auto;
  position: relative;
`;

export const ReadOnlyText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

const getBubbleColor = (p: IStyledProps) => {
  if (p.workingStatus === WORKING_STATUS.NOT_AVAILABLE) return p.theme.colors.red1;
  if (p.workingStatus === WORKING_STATUS.AWAY) return p.theme.colors.orange1;
  if (p.workingStatus === AVAILABILITY.INACTIVE) return p.theme.colors.grey2;
  if (p.workingStatus === AVAILABILITY.UNAVAILABLE) return p.theme.colors.red1;
  return p.theme.colors.green1;
};

export const StatusBubble = styled.div<IStyledProps>`
  width: ${(p: IStyledProps) => p.theme.fontSize.small};
  height: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: 50%;
  background-color: ${getBubbleColor};
`;

export const StatusBubbleAbsolute = styled(StatusBubble)`
  width: ${(p: IStyledProps) => p.theme.fontSize.small};
  height: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: 50%;
  background-color: ${getBubbleColor};
  position: absolute;
  left: 12px;
  bottom: 18.5px;
  transform: translateY(50%);
`;

export const OptionRow = styled(RowContainer)<IStyledProps>`
  min-height: 36px;
  max-height: 36px;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 100%;
  &:hover {
    background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.grey4)};
  }
`;

export const StatusBubbleOption = styled(StatusBubble)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextOption = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
