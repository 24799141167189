import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  content?: string;
  theme?: ITheme;
  amReacted?: boolean;
}

export const Emoji = styled.div`
  font-family: 'Consentio-icons' !important;
  font-size: 99%;
  display: inherit;

  &:before {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
    content: '${(p: IStyledProps) => p.content}';
    align-self: center;
    color: inherit;
    padding-bottom: 2px;
  }
`;

export const Wrapper = styled(RowContainer)`
  position: relative;
  border: 1px solid ${(p: IStyledProps) => (p.amReacted ? p.theme.colors.blue1 : p.theme.colors.light1)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) =>
    p.amReacted ? p.theme.colors.blue1.replace('1)', '0.2)') : p.theme.colors.white};
  padding: 1px ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  cursor: pointer;
  & .reactionText {
    display: none;
  }
  &:hover {
    & .reactionText {
      display: block;
    }
  }
`;

export const Number = styled(TextLabel)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-self: center;
`;

export const ReactionText = styled.span`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 30px;
  width: max-content;
  min-width: 90px;
  right: 0;
  text-align: center;
  left: -25px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  padding: 0 calc(${(p: IStyledProps) => p.theme.paddingObject} / 4);
  &::after {
    content: '';
    position: absolute;
    left: 40px;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(p: IStyledProps) => p.theme.colors.white};
    clear: both;
  }
`;
