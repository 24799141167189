import { __ } from 'common-services';
import * as React from 'react';
import { IFontSize, IFontWeight } from '../../../styled-components';

import { SimpleDropdown } from '../../atoms';
import { IItem } from '../../atoms/SuggestionsDropdown/SuggestionsDropdown.component';
import * as S from './ChatGrouped.styled';

export interface IProps {
  children: React.ReactNode;
  className?: string;
  clickOptions?: Array<IItem>;
  cta?: string;
  ctaBlue?: boolean;
  ctaId?: string;
  defaultExpanded?: boolean;
  hasSearch: boolean;
  infoText?: string;
  onAddClick?: (key?: string) => void;
  onExpand?: (isExpanded: boolean, key?: string) => void;
  title: string;
  titleSize?: keyof IFontSize;
  titleWeight?: keyof IFontWeight;
  unread: number;
  hasBackground?: boolean;
}

const ChatGrouped = ({
  children,
  clickOptions,
  className,
  cta,
  ctaBlue,
  ctaId,
  defaultExpanded = true,
  hasSearch,
  infoText,
  onAddClick,
  onExpand,
  unread,
  title,
  titleSize,
  titleWeight,
  hasBackground,
}: IProps) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);
  const headerDropDown = React.useRef<SimpleDropdown>();
  const hasNotification = !!unread;

  React.useEffect(() => {
    setIsExpanded(!!defaultExpanded);
  }, [setIsExpanded, defaultExpanded]);

  const toggleExpand = React.useCallback(() => {
    if (hasSearch) return;
    const toExpand = !isExpanded;
    setIsExpanded(toExpand);
    onExpand?.(toExpand, className);
  }, [isExpanded, setIsExpanded, onExpand, className, hasSearch]);

  return (
    <S.Container className={className} hasBackground={hasBackground}>
      <S.HeaderContainer onMouseLeave={() => headerDropDown?.current?.hide()} hasSearch={hasSearch}>
        <S.TitleRow onClick={() => toggleExpand()} hasSearch={hasSearch}>
          {hasSearch ? null : (
            <S.ChevronIcon name={isExpanded ? 'Chevron-down' : 'Chevron-right'} disableHover={true} />
          )}
          <S.TextTitle
            hasSearch={hasSearch}
            hasNotification={hasNotification}
            isExpanded={isExpanded}
            size={titleSize}
            weight={titleWeight}
          >
            {title}
          </S.TextTitle>
          {infoText ? (
            <S.Tooltip position="right" text={infoText} width="200px" className="tooltip-add-line" themeMode="dark">
              <S.InfoIcon name="Info" disableHover={true} />
            </S.Tooltip>
          ) : null}
        </S.TitleRow>
        <S.CenterRow>
          {onAddClick ? (
            <Dropdown
              className="dropdown-add-icon"
              clickOptions={clickOptions}
              onAddClick={onAddClick}
              hAlign="right"
              dropDownRef={headerDropDown}
            >
              <S.AddIcon name="Plus" className="add-icon" onClick={() => (clickOptions ? null : onAddClick?.())} />
            </Dropdown>
          ) : null}
          {unread && !isExpanded ? <S.Badge count={unread} /> : null}
        </S.CenterRow>
      </S.HeaderContainer>
      {isExpanded || hasSearch ? children : null}
      {isExpanded && cta ? (
        <S.CTAContainer onClick={() => (clickOptions ? null : onAddClick?.())} id={ctaId}>
          <Dropdown className="dropdown-add-icon" clickOptions={clickOptions} onAddClick={onAddClick} hAlign="left">
            <S.CenterRow>
              <S.AddContainer ctaBlue={ctaBlue}>
                <S.AddIconBlack name="Plus" disableHover={true} ctaBlue={ctaBlue} />
              </S.AddContainer>
              <S.TextRegular ctaBlue={ctaBlue}>{cta}</S.TextRegular>
            </S.CenterRow>
          </Dropdown>
        </S.CTAContainer>
      ) : null}
    </S.Container>
  );
};

const Dropdown = React.memo(
  ({
    children,
    className,
    clickOptions,
    dropDownRef,
    hAlign,
    onAddClick,
  }: {
    children: React.ReactElement;
    className: string;
    clickOptions?: Array<IItem>;
    dropDownRef?: React.RefObject<SimpleDropdown>;
    hAlign: 'left' | 'right';
    onAddClick?: (key?: string) => void;
  }) => {
    if (!clickOptions || !clickOptions.length) return children;
    return (
      <SimpleDropdown
        options={clickOptions}
        hAlign={hAlign}
        onSelect={onAddClick}
        className={className}
        ref={dropDownRef}
      >
        {children}
      </SimpleDropdown>
    );
  },
);

export default React.memo(ChatGrouped);
