import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';

interface IStyledProps {
  selected?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  height: 653px;
  min-height: 653px;
  overflow: hidden;
`;

export const SideBar = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  width: 258px;
  height: 100%;
  border-radius: ${(p: IStyledProps) => p.theme.paddingObject} 0 0 ${(p: IStyledProps) => p.theme.paddingObject};
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const SideBarTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-top: 45px;
  margin-bottom: 15px;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  line-height: 1.4;
`;

export const SideBarFeature = styled(RowContainer)`
  height: 88px;
  align-items: center;
  cursor: pointer;
  background-color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.white : p.theme.colors.blue1)};
`;

export const FeatureImageContainer = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  flex: 3;
`;

export const FeatureImage = styled.img`
  max-height: 60px;
  max-width: 50px;
`;

export const FeatureName = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.black : p.theme.colors.white)};
`;

export const FeatureNameContainer = styled(RowContainer)`
  flex: 7;
`;

export const Content = styled(ColumnContainer)`
  flex: 1;
`;

export const TitleContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: center;
  align-items: center;
`;

export const TitleText = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TitleBadge = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  color: white;
  padding: 0 7px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  height: 18px;
  justify-content: center;
`;

export const SubtitleContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 20px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SubtitleText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const MainImageContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  justify-content: center;
`;

export const MainImage = styled.img`
  height: 116px;
`;

export const Benefits = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  justify-content: space-between;
`;

export const Benefit = styled(ColumnContainer)`
  flex: 1;
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(6)}; ;
`;

export const BenefitIconWrapper = styled.div`
  height: 60px;
`;

export const BenefitIcon = styled(FontIcon)`
  font-size: 70px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const BenefitTitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: center;
`;

export const BenefitDescription = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: center;
`;

export const ActionWrapper = styled(ColumnContainer)`
  align-items: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(9)};
`;

export const CTA = styled.div`
  width: 277px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding: 8px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const CTASubtitle = styled.div`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
