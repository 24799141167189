import {
  __,
  broadcastActions,
  CHANNEL_DISPLAY,
  CHANNEL_MEMBER_ROLE,
  CHANNEL_MEMBER_STATUS,
  CHANNEL_TYPE,
  chatActions,
  chatService,
  colors,
  contactActions,
  imageActions,
  INVITE_ORIGIN,
  INVITE_VIA,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceService,
  utils,
  VIA,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import SelectContacts from '../../../screens/select-contacts';
import { resizeImage } from '../../../services/image';
import { getInviteOptions } from '../../../services/invite';
import { logError } from '../../../services/log';
import { copyToClipboard, deviceIsIpad } from '../../../util/utils';
import { Button, Input, Picture, SimpleDropdown, Switch } from '../../atoms';
import {
  FormContainer,
  FormSection,
  InputWithLabel,
  InviteAddressbookModal,
  MemberSelect,
  WorkspaceSelect,
} from '../../molecules';
import * as S from './ChatInfo.styled';

enum MEMBER_OPTIONS {
  ROLE = 'role',
  MESSAGE = 'message',
  INFO = 'info',
  ADD = 'add',
  DELETE = 'delete',
}

export type OwnProps = RouteComponentProps<{ channelId?: string }> & { isBroadcast?: boolean };

export interface StateProps {
  catalogs: { [id: number]: IWorkspace };
  channel: IChannel;
  broadcast: IBroadcast;
  channels: { [key: string]: IChannel };
  contacts: { [contactId: number]: IContact };
  hasOutContacts: boolean;
  me: IUser;
}

export interface DispatchProps {
  addBroadcastMembers: typeof broadcastActions.addMembers;
  addMembers: typeof chatActions.addMembers;
  channelArchive: typeof chatActions.channelArchive;
  channelLeave: typeof chatActions.channelLeave;
  channelMute: typeof chatActions.channelMute;
  contactsInvite: typeof contactActions.contactsInvite;
  createNewContact: typeof contactActions.createNewContact;
  deleteBroadcastMembers: typeof broadcastActions.deleteMembers;
  deleteMembers: typeof chatActions.deleteMembers;
  deleteWorkspaceToChannel: typeof chatActions.deleteWorkspaceToChannel;
  getMembers: typeof broadcastActions.getMembers;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelByPath: typeof navActions.navigateChannelByPath;
  notificationShow: typeof notificationsActions.notificationShow;
  setWorkspaceToChannel: typeof chatActions.setWorkspaceToChannel;
  updateChannel: typeof chatActions.updateChannel;
  updateMember: typeof chatActions.updateMember;
  updateBroadcast: typeof broadcastActions.updateBroadcast;
}

export type IProps = OwnProps & StateProps & DispatchProps;

const DEFAULT_NUMBER_OF_CONTACT_TO_DISPLAY = 5;

interface IState {
  contactsToShow: Array<ILightContact>;
  members: Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }>;
  errors: Map<string, string>;
  imageUrl: string;
  invited: Array<number>;
  inviteLinkToShare: string;
  linkCopied: boolean;
  name: string;
  searchText: string;
  showAddContacts?: boolean;
  showAll: boolean;
  showInviteModal?: INVITE_VIA;
  showWorkspace: boolean;
}

export default class ChatInfo extends React.PureComponent<IProps, IState> {
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { channel, isBroadcast, catalogs, me, broadcast } = this.props;
    this.state = {
      members: [],
      contactsToShow: [],
      errors: new Map(),
      imageUrl: channel.imageUrl || '',
      invited: [],
      inviteLinkToShare: '',
      linkCopied: false,
      name: isBroadcast ? broadcast.name : channel.name || '',
      searchText: '',
      showAll: false,
      showWorkspace:
        catalogs?.[channel.workspaceId] &&
        sellerWorkspaceService.getRole(catalogs[channel.workspaceId], me.id) !== 'viewer',
    };
  }

  public componentDidMount() {
    RenderTrack.track('ChatInfo', { renderTime: this.t });
    const { broadcast, getMembers, me } = this.props;
    const contactsToShow = this.getContactsToShow() as Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }>;
    if (broadcast.id) getMembers(me.id, broadcast.id);
    this.setState({
      members: this.getMembers() as Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }>,
      contactsToShow,
      showAll: contactsToShow.length < DEFAULT_NUMBER_OF_CONTACT_TO_DISPLAY,
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { broadcast, channel, contacts, catalogs, me } = this.props;
    const { members, showAll, searchText } = this.state;
    if (
      prevProps.contacts !== contacts ||
      prevProps.channel.members !== channel.members ||
      prevProps.broadcast.members !== broadcast.members
    ) {
      const m = this.getMembers() as Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }>;
      this.setState({ members: m, showAll: m.length < DEFAULT_NUMBER_OF_CONTACT_TO_DISPLAY });
    }
    if (catalogs !== prevProps.catalogs) {
      this.setState({
        showWorkspace:
          catalogs?.[channel.workspaceId] &&
          sellerWorkspaceService.getRole(catalogs[channel.workspaceId], me.id) !== 'viewer',
      });
    }
    if (prevState.showAll !== showAll || prevState.searchText !== searchText || prevState.members !== members) {
      this.setState({
        contactsToShow: this.getContactsToShow() as Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }>,
      });
    }
  }

  public render() {
    const { channel, contacts, isBroadcast } = this.props;
    const { showInviteModal, showAddContacts } = this.state;
    const amActive = channel.status === 'active';
    return (
      <S.Container>
        {Object.keys(contacts).length === 0 ? (
          <S.Loading loadingTheme="dark" />
        ) : (
          <FormContainer className="ChatInfo-FormContainer" canSave={false} save={() => null} withoutMenu={true}>
            {this.renderGeneralSection(amActive)}
            {isBroadcast ? null : this.renderWorkspaceSection(amActive)}
            {this.renderMembersSection(amActive)}
            {this.renderFooter(amActive)}
          </FormContainer>
        )}
        {showInviteModal ? this.renderInviteModal() : null}
        {showAddContacts ? this.renderAddContacts() : null}
      </S.Container>
    );
  }

  /**
   * Render the general section
   */
  private renderGeneralSection = (amActive: boolean) => {
    const { channel, channelMute, updateChannel, updateBroadcast, me, isBroadcast, broadcast } = this.props;
    const { name, imageUrl, errors } = this.state;
    const imOwner = channel.role === CHANNEL_MEMBER_ROLE.OWNER && amActive;
    return (
      <FormSection title={__('Channel.general')} subtitle={isBroadcast ? '' : __('Channel.general_subtitle')}>
        <S.RowGeneral>
          <S.LettersAvatarContainer>
            {imOwner || imageUrl ? (
              <Picture
                editable={imOwner}
                imageUrl={imageUrl}
                onDelete={this.onPicturedDelete}
                onFileChange={this.onPictureChange}
                pictureMode={true}
                picturePlaceholder={__('Components.Picture.placeholder.group')}
                relation={1}
                size="big"
                withCrop={deviceIsIpad() ? false : true}
              />
            ) : (
              <S.LettersAvatar
                avatarColor={utils.getAvatarColor(name)}
                disabled={true}
                img={imageUrl}
                size={120}
                text={name}
                type={isBroadcast ? 'broadcast' : channel.type === CHANNEL_TYPE.GROUP ? 'group' : undefined}
              />
            )}
          </S.LettersAvatarContainer>
          <InputWithLabel
            isRequired={true}
            label={isBroadcast ? __('Components.Broadcast.name_label') : __('Channel.name_label')}
          >
            <Input
              value={name}
              onChange={(n, value) => {
                this.setError('name', value ? '' : 'empty');
                this.setState({ name: (value as string) || '' });
              }}
              onBlur={(key: string, value: string | number) => {
                this.setState({ name: (value as string) || '' });
                if (name) {
                  isBroadcast
                    ? updateBroadcast(me.id!, broadcast.id, name)
                    : updateChannel(me.id!, channel.id, { name, imageUrl }, channel.invitePolicy);
                }
              }}
              hasError={!!errors.get('name')}
              maxLength={64}
              placeholder={__('Channel.name_placeholder')}
              disabled={!imOwner && !isBroadcast}
              width="350px"
            />
          </InputWithLabel>
        </S.RowGeneral>
        {!isBroadcast && amActive ? (
          <S.RowSwitch>
            <S.SwitchLabel>{__('Channel.mute_notifications')}</S.SwitchLabel>
            <Switch
              isChecked={channel.muted}
              onChange={(n: string, checked: boolean) => channelMute(me.id!, channel.id, checked)}
            />
          </S.RowSwitch>
        ) : null}
      </FormSection>
    );
  };

  /**
   * render the workspace section
   */
  private renderWorkspaceSection = (amActive: boolean) => {
    const { catalogs, channel, contacts, deleteWorkspaceToChannel, setWorkspaceToChannel, me } = this.props;
    const { showWorkspace } = this.state;
    let catalog = catalogs[channel.workspaceId];
    const buyerIds = channel.members
      .filter(m => !sellerWorkspaceService.getMember(catalog, m.id) && m.status === 'active' && m.id !== me.id)
      .map(m => m.id);
    if (
      !me.settings.isSeller ||
      channel.role !== CHANNEL_MEMBER_ROLE.OWNER ||
      (!catalog && channel.workspaceId) ||
      !amActive
    ) {
      return null;
    }
    if (!catalog) {
      catalog = catalogs[me.sellerWorkspaceId];
    }
    const catalogArray = Object.values(catalogs);
    const sellerIds = catalog?.members
      .filter(m => (channel.members.find(c => c.id === m.userId) && m.status === 'active') || m.userId === me.id)
      .map(m => m.userId);
    const disabled = channel.role !== CHANNEL_MEMBER_ROLE.OWNER;
    const name = sellerWorkspaceService.getCatalogName(catalog, contacts, me);
    const defaultName = sellerWorkspaceService.getCatalogName(catalogs[me.sellerWorkspaceId], contacts, me);

    return (
      <FormSection title={__('Channel.workspace.title')} subtitle={__('Channel.workspace.subtitle')}>
        <S.RowSwitch>
          <S.SwitchLabel>{__('Channel.workspace.switch_label')}</S.SwitchLabel>
          <Switch
            isChecked={showWorkspace}
            disabled={disabled}
            onChange={(n: string, checked: boolean) => {
              if (checked) {
                setWorkspaceToChannel(me.id!, me.sellerWorkspaceId, channel.id, me.id, buyerIds[0]);
              } else {
                deleteWorkspaceToChannel(me.id!, catalog.id, channel.id);
              }
              this.setState({ showWorkspace: checked });
            }}
          />
        </S.RowSwitch>
        {showWorkspace ? (
          <>
            <InputWithLabel
              isRequired={true}
              label={__('Channel.workspace.select_label')}
              description={__('Channel.workspace.select_description')}
              disabled={disabled}
            >
              <WorkspaceSelect
                catalogIdSelected={catalog?.id}
                catalogs={catalogs}
                contacts={contacts}
                filteredCatalogs={catalogArray}
                me={me}
                setCatalogIdSelected={id =>
                  setWorkspaceToChannel(
                    me.id!,
                    id,
                    channel.id,
                    sellerIds.includes(channel.defaultSellerId) ? channel.defaultSellerId : me.id,
                    buyerIds.includes(channel.defaultBuyerId) ? channel.defaultBuyerId : buyerIds[0],
                  )
                }
                disabled={disabled}
              />
              {sellerWorkspaceService.isActive(catalog, me.id) ? null : (
                <S.TeamUnactive>
                  <S.WarningIcon name={'Warning'} disableHover={true} />
                  <S.Text>{__('ContactInfo.Menu.workspace.inactive', { default_name: defaultName, name })}</S.Text>
                </S.TeamUnactive>
              )}
            </InputWithLabel>
            <InputWithLabel
              isRequired={true}
              label={__('Channel.workspace.seller_label')}
              description={__('Channel.workspace.seller_description')}
              disabled={disabled}
            >
              <MemberSelect
                memberIdSelected={channel.defaultSellerId || me.id}
                memberIds={sellerIds}
                contacts={contacts}
                me={me}
                setMemberIdSelected={id =>
                  setWorkspaceToChannel(me.id!, catalog.id, channel.id, id, channel.defaultBuyerId)
                }
                disabled={disabled}
              />
            </InputWithLabel>
            <InputWithLabel
              isRequired={true}
              label={__('Channel.workspace.buyer_label')}
              description={__('Channel.workspace.buyer_description')}
              disabled={disabled}
            >
              <MemberSelect
                memberIdSelected={channel.defaultBuyerId || buyerIds[0]}
                memberIds={buyerIds}
                contacts={contacts}
                setMemberIdSelected={id =>
                  setWorkspaceToChannel(me.id!, catalog.id, channel.id, channel.defaultSellerId, id)
                }
                disabled={disabled}
              />
            </InputWithLabel>
          </>
        ) : null}
      </FormSection>
    );
  };

  /**
   * reander all the content over the members
   */
  private renderMembersSection = (amActive: boolean) => {
    const { channel, hasOutContacts, updateChannel, me, isBroadcast, broadcast } = this.props;
    const { contactsToShow, members, showAll, searchText, linkCopied } = this.state;
    return (
      <FormSection
        title={
          isBroadcast
            ? __('Components.Broadcast.members', { count: Object.keys(broadcast.members).length })
            : __('Channel.members')
        }
      >
        {amActive && [CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(channel.role) ? (
          <S.RowSwitch>
            <S.SwitchLabel>{__('Channel.invite_all')}</S.SwitchLabel>
            <Switch
              isChecked={channel.invitePolicy === 'any'}
              onChange={(n: string, checked: boolean) =>
                updateChannel(
                  me.id,
                  channel.id,
                  { name: channel.name, imageUrl: channel.imageUrl },
                  checked ? 'any' : 'admin',
                )
              }
            />
          </S.RowSwitch>
        ) : null}
        <S.NumMembers>
          {isBroadcast
            ? __('Components.Broadcast.members_number', { count: Object.keys(broadcast.members).length })
            : __('Channel.members_number', { count: channel.numMembers })}
        </S.NumMembers>
        <S.Search
          onChange={f => this.setState({ searchText: f })}
          placeHolder={__('Components.ChatList.search.placeholder')}
          id="input_search_members_list"
        />
        {amActive &&
        (isBroadcast ||
          [CHANNEL_MEMBER_ROLE.ADMIN, CHANNEL_MEMBER_ROLE.OWNER].includes(channel.role) ||
          channel.invitePolicy === 'any') ? (
          <S.Buttons>
            <Button
              type="link"
              onClick={() => this.setState({ showAddContacts: true })}
              iconName="Add-more"
              withoutPadding={true}
              iconSize="14px"
            >
              {__('Channel.add_contact')}
            </Button>
            {isBroadcast ? null : (
              <SimpleDropdown
                onSelect={this.handleInviteOptions}
                vAlign="flex-start"
                options={getInviteOptions([
                  ...(hasOutContacts ? [INVITE_VIA.PHONEBOOK] : []),
                  INVITE_VIA.EMAIL,
                  INVITE_VIA.SMS,
                ])}
              >
                <Button type="link" iconName="Add-group" withoutPadding={true} iconSize="14px">
                  {__('Channel.invite')}
                </Button>
              </SimpleDropdown>
            )}
            {isBroadcast ? null : linkCopied ? (
              <Button type="skip" iconName="Check" withoutPadding={true} iconSize="14px">
                {__('Components.Header.LinkCopied')}
              </Button>
            ) : (
              <Button type="link" onClick={this.onCopyLink} iconName="Clipboard" withoutPadding={true} iconSize="14px">
                {__('Channel.copy_share')}
              </Button>
            )}
          </S.Buttons>
        ) : null}
        <S.MembersContainer>{contactsToShow.map(this.renderContactItem)}</S.MembersContainer>
        {showAll || searchText.trim().length > 1 ? null : (
          <Button type="link" onClick={() => this.setState({ showAll: true })}>
            {__('Channel.see_all')}
          </Button>
        )}
      </FormSection>
    );
  };

  /**
   * Render channels group actions (create / leave)
   */
  private renderFooter = (amActive: boolean) => {
    const { broadcast, channel, isBroadcast } = this.props;
    return (
      <FormSection
        title={isBroadcast ? __('Components.Broadcast.activity.title') : __('Channel.activity.title')}
        subtitle={
          isBroadcast
            ? __('Components.Broadcast.activity.description')
            : channel.role === 'owner'
            ? __('Channel.activity.description_no_leave')
            : __('Channel.activity.description')
        }
      >
        <S.Row>
          <S.ArchiveButton
            type="secondary"
            onClick={isBroadcast ? this.archiveBroadcast : this.archiveChannel}
            iconName="Archive"
          >
            {isBroadcast
              ? broadcast.display !== CHANNEL_DISPLAY.ARCHIVED
                ? __('Components.Broadcast.archive')
                : __('Components.Broadcast.unarchive')
              : channel.display !== CHANNEL_DISPLAY.ARCHIVED
              ? __('Channel.archive')
              : __('Channel.unarchive')}
          </S.ArchiveButton>
          {channel.role === 'owner' || !amActive || isBroadcast ? null : (
            <Button type="delete" onClick={this.leaveChannel} iconName="Leave-group">
              {__('Channel.leave_group')}
            </Button>
          )}
        </S.Row>
      </FormSection>
    );
  };

  /**
   * Leave channel with confirmation modal
   */
  private leaveChannel = () => {
    const { modalOpen, modalClose, channelLeave, channel, me } = this.props;
    modalOpen(
      __('Channel.leave'),
      () => {
        channelLeave(me.id!, channel.id);
        modalClose();
      },
      {
        icon: IMAGES.cautionGrape,
        text2: __('Channel.leave_confirmation'),
        buttonText: __('Channel.leave'),
        actionType: 'dangerous',
        showCancelButton: true,
      },
      'nice',
    );
  };

  /**
   * On archive chat
   */
  private archiveBroadcast = () => {
    const { broadcast, updateBroadcast, me, notificationShow } = this.props;
    const toArchive = broadcast.display !== CHANNEL_DISPLAY.ARCHIVED;
    updateBroadcast(
      me.id,
      broadcast.id,
      broadcast.name,
      broadcast.display !== CHANNEL_DISPLAY.ARCHIVED ? CHANNEL_DISPLAY.ARCHIVED : CHANNEL_DISPLAY.NORMAL,
      (error?: Error) => {
        if (error) {
          notificationShow(
            {
              title: __('Notification.error.title'),
              subtitle: __('Notification.error.description'),
              closable: true,
              style: 'error',
            },
            2000,
          );
        } else {
          notificationShow(
            {
              title: toArchive ? __('Notification.archive.title') : __('Notification.unarchive.title'),
              subtitle: toArchive
                ? __('Notification.archive.description_broadcast')
                : __('Notification.unarchive.description_broadcast'),
              closable: true,
              style: 'info',
            },
            2000,
          );
        }
      },
    );
  };

  /**
   * On archive chat
   */
  private archiveChannel = () => {
    const { channel, channelArchive, me, notificationShow } = this.props;
    const toArchive = channel.display !== CHANNEL_DISPLAY.ARCHIVED;
    channelArchive(me.id, channel.id, toArchive, (error?: Error) => {
      if (error) {
        notificationShow(
          {
            title: __('Notification.error.title'),
            subtitle: __('Notification.error.description'),
            closable: true,
            style: 'error',
          },
          4000,
        );
      } else {
        notificationShow(
          {
            title: toArchive ? __('Notification.archive.title') : __('Notification.unarchive.title'),
            subtitle: toArchive
              ? __('Notification.archive.description_group')
              : __('Notification.unarchive.description_group'),
            closable: true,
            style: 'info',
          },
          4000,
        );
      }
    });
  };

  /**
   * Render selectable contact item
   */
  private renderContactItem = (member: ILightContact & IMember) => {
    const { channel, me, contacts } = this.props;
    const isMemberOwner = member
      ? member.role === CHANNEL_MEMBER_ROLE.OWNER
      : channel.role === CHANNEL_MEMBER_ROLE.OWNER;
    const isMemberAdmin = member
      ? member.role === CHANNEL_MEMBER_ROLE.ADMIN
      : channel.role === CHANNEL_MEMBER_ROLE.ADMIN;
    const imOwner = channel.role === CHANNEL_MEMBER_ROLE.OWNER;
    const imAdmin = channel.role === CHANNEL_MEMBER_ROLE.ADMIN;
    return (
      <S.MemberSelectable
        key={member.id}
        contact={member}
        isSelected={true}
        isLast={true}
        containerClickable={false}
        onSelectContact={() => (imOwner ? this.deleteMember(member.id!, member.name) : undefined)}
        label={isMemberOwner ? __('Channel.owner') : isMemberAdmin ? __('Channel.admin') : undefined}
        labelColor={isMemberOwner ? 'grey' : isMemberAdmin ? 'green' : undefined}
        action={'none'}
      >
        {me.id === member.id ? null : (
          <SimpleDropdown
            onSelect={k => this.handleMemberOptions(k as MEMBER_OPTIONS, member)}
            hAlign="right"
            options={this.getMemberOptions(
              imOwner,
              imAdmin,
              isMemberOwner,
              isMemberAdmin,
              !!contacts[member.id],
              channel.status === 'active',
            )}
          >
            <S.DropdownIcon name="Down" className="dropdown-icon" />
          </SimpleDropdown>
        )}
      </S.MemberSelectable>
    );
  };

  private renderInviteModal = () => {
    const { channel, me } = this.props;
    const { showInviteModal } = this.state;
    return (
      <InviteAddressbookModal
        addressbookSubtitle={__('Channel.invite_modal.addresbook.subtitle')}
        addressbookTitle={__('Channel.invite_modal.addresbook.title')}
        channelId={channel.id}
        defaultInviteBy={showInviteModal}
        from="chat-info"
        me={me}
        onClose={() => this.setState({ showInviteModal: undefined })}
        origin={INVITE_ORIGIN.GROUP}
      />
    );
  };

  /**
   * Render modal for add members
   */
  private renderAddContacts = (): JSX.Element => {
    const { addMembers, addBroadcastMembers, me, broadcast, channel, contacts, isBroadcast } = this.props;
    const { imageUrl } = this.state;

    const contactAddedIds = channel.members.filter(member => member.status === 'active').map(member => member.id);
    const contactsNotAdded = Object.values(contacts).filter(c => !contactAddedIds.includes(c.id) && c.id !== me.id!);

    return (
      <SelectContacts
        channelId={channel?.id}
        onClose={() => this.setState({ showAddContacts: false })}
        me={me}
        contacts={contactsNotAdded}
        onSave={(contactsToAdd: Array<number>) => {
          if (isBroadcast) {
            addBroadcastMembers(me.id, broadcast.id, contactsToAdd);
          } else {
            addMembers(
              me.id,
              channel.id,
              imageUrl,
              contactsToAdd.map(mId => ({
                id: mId,
                companyName: contacts[mId].companyName,
                name: contacts[mId].name,
                role: CHANNEL_MEMBER_ROLE.MEMBER,
                status: CHANNEL_MEMBER_STATUS.ACTIVE,
                avatarColor: contacts[mId].avatarColor,
              })),
              { from: 'chat-info' },
            );
          }
          this.setState({
            showAddContacts: false,
          });
        }}
        origin={INVITE_ORIGIN.GROUP}
        showUnregistered={true}
        title={__('Channel.invite_modal.title_consentio')}
        subtitle={__('Channel.invite_modal.subtitle_consentio')}
        showInvite={!isBroadcast}
        trackingFrom="chat-info"
      />
    );
  };

  /**
   * Get channels to show with normalized search strings.
   * If contact name is empty, do not show the contact
   */
  private getContactsToShow(): Array<{
    id?: number;
    avatar?: string;
    name: string;
    companyName: string;
    avatarColor: IAvatarColor;
  }> {
    const { showAll, searchText } = this.state;
    const { channel, isBroadcast } = this.props;
    const filter = searchText && searchText.length > 1 ? utils.toLocaleLowerCaseNormalized(searchText).trim() : '';
    const members =
      filter || channel.status !== 'active' || isBroadcast
        ? this.membersFiltered(filter)
        : [this.memberMe(), ...this.membersFiltered(filter)];
    const orderedMembers = members.sort((a, b) => {
      if (
        (a.role === CHANNEL_MEMBER_ROLE.OWNER && b.role !== CHANNEL_MEMBER_ROLE.OWNER) ||
        (a.role === CHANNEL_MEMBER_ROLE.ADMIN && b.role !== CHANNEL_MEMBER_ROLE.ADMIN) ||
        (a.name < b.name && a.role === b.role)
      )
        return -1;
      if (
        (a.role !== CHANNEL_MEMBER_ROLE.OWNER && b.role === CHANNEL_MEMBER_ROLE.OWNER) ||
        (a.role !== CHANNEL_MEMBER_ROLE.ADMIN && b.role === CHANNEL_MEMBER_ROLE.ADMIN) ||
        (a.name > b.name && a.role === b.role)
      )
        return 1;
      return 0;
    });

    return showAll || filter ? orderedMembers : orderedMembers.slice(0, 5);
  }

  /**
   * returns a dict with myself user data for showing in member list
   */
  private memberMe() {
    const { channel, me } = this.props;
    return {
      id: me.id!,
      avatar: me.settings.avatar,
      name: __('Components.Chat.you'),
      companyName: me.companyName,
      avatarColor: utils.getAvatarColor(me.name),
      role: channel.role,
    };
  }

  private getMembers() {
    const {
      channel: { members },
      broadcast,
      isBroadcast,
      contacts,
    } = this.props;
    return (
      isBroadcast
        ? Object.keys(broadcast.members)
            .map(key => contacts[Number(key)])
            .filter(m => m)
        : members
    )

      .sort((a, b) => {
        const aName = utils.toLocaleLowerCaseNormalized(a.name);
        const bName = utils.toLocaleLowerCaseNormalized(b.name);
        return aName <= bName ? -1 : 1;
      })
      .map(m => {
        const c = {
          id: m.id,
          avatar: '',
          name: m.name,
          companyName: '',
          avatarColor: m?.avatarColor,
          role: m.role,
          status: m.status,
          isUnregistered: false,
          phoneNumbers: [],
        };
        const matched = contacts[m.id];
        if (matched) {
          c.name = matched.name;
          c.avatar = matched.avatar || '';
          c.companyName = matched.companyName;
          c.isUnregistered = matched.isUnregistered;
          c.phoneNumbers = matched.phoneNumbers;
        }
        return c;
      })
      .filter(m => {
        return m && (m.status === 'active' || isBroadcast) && m.name;
      });
  }

  /**
   * filter by search string and sort the member of de group
   */
  private membersFiltered = (search: string): Array<ILightContact & { role: CHANNEL_MEMBER_ROLE }> => {
    const { members } = this.state;
    return search
      ? members.filter(m => {
          return utils.toLocaleLowerCaseNormalized(m.name).includes(search);
        })
      : members;
  };

  /**
   * Add/remove a member from channel.
   */
  private deleteMember(mId: number, n: string) {
    const { channel, addMembers, deleteMembers, me } = this.props;
    const { imageUrl } = this.state;
    const idx = channel.members.findIndex(m => m && m.status === 'active' && m.id === mId);
    if (idx !== -1) {
      if (channel.id) deleteMembers(me.id!, channel.id, mId);
    } else {
      const m = {
        id: mId,
        companyName: '',
        name: n,
        role: CHANNEL_MEMBER_ROLE.MEMBER,
        status: CHANNEL_MEMBER_STATUS.ACTIVE,
        avatarColor: utils.getAvatarColor(n),
      };
      if (channel.id) addMembers(me.id!, channel.id, imageUrl, [m], { from: 'chat-info' });
    }
  }

  /**
   * Add/remove a member from broadcast.
   */
  private deleteBroadcastMember(mId: number, n: string) {
    const { broadcast, addBroadcastMembers, deleteBroadcastMembers, me } = this.props;
    const idx = Object.keys(broadcast.members).indexOf(mId + '');
    if (idx !== -1) {
      if (broadcast.id) deleteBroadcastMembers(me.id!, broadcast.id, mId);
    } else {
      if (broadcast.id) addBroadcastMembers(me.id!, broadcast.id, [mId]);
    }
  }
  /**
   * Set error for a particular field represented by key
   */
  private setError = (key: string, error?: string) => {
    const { errors } = this.state;
    const cloneErrors = new Map(errors);
    if (error) {
      cloneErrors.set(key, error);
    } else {
      cloneErrors.delete(key);
    }
    this.setState({ errors: cloneErrors });
  };

  /**
   * On picture change:
   * * Upload picture
   * * Update in product state
   */
  private onPictureChange = (file: File) => {
    const { mediaUpload, updateChannel, me, channel } = this.props;
    const { name } = this.state;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = event =>
      logError(new Error('File could not be read! Code ' + (event.target as any).error.code), 'upload.image');
    reader.onload = () =>
      reader.result &&
      resizeImage(reader.result as string, (imageResized: string) =>
        mediaUpload({ ...file, name: file.name, content: imageResized }, result => {
          this.setState({ imageUrl: result ? result.secure_url : undefined });
          if (channel.id)
            updateChannel(
              me.id,
              channel.id,
              { name, imageUrl: result ? result.secure_url : undefined },
              channel.invitePolicy,
            );
        }),
      );
  };

  /**
   * On delete picture with confirmation modal
   */
  private onPicturedDelete = (): void => {
    const { modalOpen, updateChannel, modalClose, me, channel } = this.props;
    const { name } = this.state;
    modalOpen(
      __('Components.ProductDetails.confirm_remove_picture'),
      () => {
        updateChannel(me.id, channel.id, { name, imageUrl: '' }, channel.invitePolicy);
        modalClose();
      },
      {
        buttonText: __('Components.Modal.delete'),
      },
    );
  };

  /**
   * Copy link to invite to the clipboard
   */
  private onCopyLink = () => {
    const { contactsInvite, channel, me } = this.props;
    contactsInvite(
      me.id!,
      VIA.LINK,
      'web',
      INVITE_ORIGIN.GROUP,
      undefined,
      (data, error) => {
        if (data?.inviteLink && !error)
          setTimeout(() =>
            copyToClipboard(
              __('Channel.share_message', {
                name: me.name,
                company: me.companyName,
                group_name: channel.name,
                link: data.inviteLink,
              }),
              () =>
                this.setState({ linkCopied: true, inviteLinkToShare: data.inviteLink || '' }, () =>
                  setTimeout(() => this.setState({ linkCopied: false }), 5000),
                ),
            ),
          );
      },
      channel.id,
      undefined,
      { from: 'chat-info' },
    );
  };

  /**
   * handle Click on add group or add broadcast and navigate to the creation page.
   */
  private handleInviteOptions = (key: INVITE_VIA) => {
    switch (key) {
      case INVITE_VIA.PHONEBOOK:
        this.setState({ showInviteModal: INVITE_VIA.PHONEBOOK });
        break;
      case INVITE_VIA.EMAIL:
        this.setState({ showInviteModal: INVITE_VIA.EMAIL });
        break;
      case INVITE_VIA.SMS:
        this.setState({ showInviteModal: INVITE_VIA.SMS });
        break;
    }
  };

  /**
   * handle Click on add group or add broadcast and navigate to the creation page.
   */
  private handleMemberOptions = (key: MEMBER_OPTIONS, m: IMember) => {
    const {
      broadcast,
      channel,
      channels,
      createNewContact,
      history,
      isBroadcast,
      me,
      modalClose,
      modalOpen,
      navigateChannelByPath,
      notificationShow,
      updateMember,
    } = this.props;
    const privateChannel = chatService.getChannelByContactId(channels, m.id);
    switch (key) {
      case MEMBER_OPTIONS.ROLE:
        updateMember(
          me.id,
          channel.id,
          m.id,
          m.role === CHANNEL_MEMBER_ROLE.ADMIN ? CHANNEL_MEMBER_ROLE.MEMBER : CHANNEL_MEMBER_ROLE.ADMIN,
        );
        break;
      case MEMBER_OPTIONS.MESSAGE:
        navigateChannelByPath(ROUTE_PATHS.CHAT, privateChannel.id, (path: string) => history.push(path));
        break;
      case MEMBER_OPTIONS.INFO:
        navigateChannelByPath(ROUTE_PATHS.CONTACT_INFO, privateChannel.id, (path: string) => history.push(path));
        break;
      case MEMBER_OPTIONS.ADD:
        createNewContact(
          me.id,
          m.id,
          err =>
            !err &&
            notificationShow({
              title: __('ClientsList.create_contact_success'),
              subtitle: __('ClientsList.create_contact_success_sub'),
              closable: true,
              style: 'info',
            }),
        );
        break;
      case MEMBER_OPTIONS.DELETE:
        isBroadcast
          ? modalOpen(
              __('Components.Broadcast.modal_remove.title'),
              () => {
                modalClose();
                this.deleteBroadcastMember(m.id, m.name);
              },
              {
                closeable: true,
                text2: __('Components.Broadcast.modal_remove.description', {
                  memberName: m.name,
                  channelName: broadcast.name,
                }),
                buttonText: __('Components.Broadcast.modal_remove.cta'),
                showCancelButton: true,
                buttonCancelText: __('Components.Broadcast.modal_remove.cta_cancel'),
                actionType: 'dangerous',
                icon: IMAGES.cautionGrape,
              },
              'nice',
            )
          : modalOpen(
              __('Channel.modal_remove.title'),
              () => {
                modalClose();
                this.deleteMember(m.id, m.name);
              },
              {
                closeable: true,
                text2: __('Channel.modal_remove.description', { memberName: m.name, channelName: channel.name }),
                buttonText: __('Channel.modal_remove.cta'),
                showCancelButton: true,
                buttonCancelText: __('Channel.modal_remove.cta_cancel'),
                actionType: 'dangerous',
                icon: IMAGES.cautionGrape,
              },
              'nice',
            );
        break;
    }
  };

  /**
   * Options to show wen click in invite by other way
   */
  private getMemberOptions(
    imOwner: boolean,
    imAdmin: boolean,
    isOwner: boolean,
    isAdmin: boolean,
    isContact: boolean,
    amActive: boolean,
  ) {
    const { isBroadcast } = this.props;
    if (imOwner && amActive) {
      return [
        {
          key: MEMBER_OPTIONS.ROLE,
          value: !isOwner && !isAdmin ? __('Channel.do_admin') : __('Channel.undo_admin'),
        },
        {
          key: MEMBER_OPTIONS.MESSAGE,
          value: __('Channel.send_message'),
        },
        {
          key: MEMBER_OPTIONS.INFO,
          value: __('Channel.contact_info'),
        },
        {
          key: MEMBER_OPTIONS.DELETE,
          value: __('Channel.delete_member'),
          color: colors.red1,
        },
      ];
    }
    if (imAdmin && !isOwner && amActive) {
      return [
        ...(isContact
          ? [
              {
                key: MEMBER_OPTIONS.ROLE,
                value: !isAdmin ? __('Channel.do_admin') : __('Channel.undo_admin'),
              },
              {
                key: MEMBER_OPTIONS.MESSAGE,
                value: __('Channel.send_message'),
              },
              {
                key: MEMBER_OPTIONS.INFO,
                value: __('Channel.contact_info'),
              },
            ]
          : [
              {
                key: MEMBER_OPTIONS.ADD,
                value: __('Channel.add_as_contact'),
              },
            ]),
        {
          key: MEMBER_OPTIONS.DELETE,
          value: __('Channel.delete_member'),
          color: colors.red1,
        },
      ];
    }
    return isContact
      ? [
          {
            key: MEMBER_OPTIONS.MESSAGE,
            value: __('Channel.send_message'),
          },
          {
            key: MEMBER_OPTIONS.INFO,
            value: __('Channel.contact_info'),
          },
          ...(isBroadcast
            ? [
                {
                  key: MEMBER_OPTIONS.DELETE,
                  value: __('Components.Broadcast.delete_member'),
                  color: colors.red1,
                },
              ]
            : []),
        ]
      : [
          {
            key: MEMBER_OPTIONS.ADD,
            value: __('Channel.add_as_contact'),
          },
        ];
  }
}
