import styled, { css, ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon/FontIcon.component';

interface IStyledProps {
  theme: ITheme;
  size?: number;
  isChecked?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p: IStyledProps) =>
    !p.disabled &&
    css`
      &:hover {
        opacity: 0.8;
      }
    `}
`;

export const CheckBox = styled(RowContainer)<IStyledProps>`
  border: ${(p: IStyledProps) => getBorder(p.isChecked, p.disabled, p.theme)};
  background-color: ${(p: IStyledProps) => getBackgroundColor(p.isChecked, p.disabled, p.theme)};
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => p.size}px;
  width: ${(p: IStyledProps) => p.size}px;
`;

export const Icon = styled(FontIcon)<IStyledProps>`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.size * 0.6}px;
  height: ${(p: IStyledProps) => p.size * 0.6}px;
  line-height: ${(p: IStyledProps) => p.size * 0.6}px;
`;

function getBorder(isChecked: boolean, disabled: boolean, theme: ITheme): string {
  if (disabled) return `1px solid ${theme.colors.grey3}`;
  return `1px solid ${isChecked ? theme.colors.green1 : theme.colors.black}`;
}

function getBackgroundColor(isChecked: boolean, disabled: boolean, theme: ITheme): string {
  if (disabled) return isChecked ? theme.colors.grey3 : theme.colors.white;
  return isChecked ? theme.colors.green1 : theme.colors.white;
}
