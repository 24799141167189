import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const CodeInputContainer = styled(RowContainer)`
  width: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  text-align: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
  height: 54px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.black};
  &:focus-within {
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.green1};
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(p: IStyledProps) => p.theme.colors.grey2};
  }
`;

export const CodeInput = styled.input<IStyledProps>`
  line-height: 1.2;
  outline: none;
  border: 0;
  font-family: inherit;
  width: 0;
  flex: 1 1 auto;
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  letter-spacing: 4px;
`;

export const CodeNotReceivedContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingView} 0;
`;

export const SubmitButton = styled(Button)`
  width: 170px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  align-self: center;
`;
