import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';
import { FilterButton as FB } from '../../molecules';

interface IStyledProps {
  numHeaders?: number;
  theme: ITheme;
  disabled?: boolean;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const BodyContainer = styled(ColumnContainer)<IStyledProps>`
  position: relative;
  width: 100%;
  max-height: ${(p: IStyledProps) => p.theme.contentHeightWithBreadcrumb};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const CTACreate = styled(Button)<IStyledProps>`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextBlack = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px 0;
`;

export const TextGrey = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextSemiBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-top: 2px;
`;

export const FormBlock = styled(ColumnContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const StatusMarginBlock = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ClientsAddCol = styled(ColumnContainer)`
  justify-content: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  width: min-content;
`;

export const ProductsAddRow = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const SearchWrapper = styled(RowContainer)`
  min-height: 54px;
  overflow: visible;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: 0;
  width: 100%;
  min-width: 400px;
  align-items: center;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    min-width: 100%;
  }
`;

export const ButtonRemove = styled(Button)`
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  border: none;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  box-shadow: none;
`;

export const ActionText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TrashIcon = styled(FontIcon)`
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const StatusRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: space-between;
  width: 180px;
`;

export const TransportCostRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TransportCostDescriptionRow = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const RowTop = styled(RowContainer)`
  position: fixed;
  top: 44px;
  right: 0;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const ExpandedContainer = styled(ColumnContainer)`
  padding-left: 58px;
`;

export const Column = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
`;
export const FilterButton = styled(FB)`
  height: 40px;
`;
export const AddClientsButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Sort = styled(SimpleDropdown)`
  align-self: end;
`;
export const SortBlackText = styled(TextBlack)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const SortGreyText = styled(TextGrey)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const RightContainer = styled(RowContainer)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const ModifyButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const DeleteButton = styled(ModifyButton)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const ModalButton = styled(ModifyButton)`
  width: 200px;
`;
export const CtaButton = styled(ModalButton)`
  background-color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.theme.colors.green1)};
`;

export const ModalRow = styled(RowContainer)`
  flex: 1;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  align-items: center;
`;
export const SwitchRow = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;
