import { ORDER_STATUS } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import { TagBubble, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  status?: ORDER_STATUS;
  overlay?: boolean;
}
export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  flex: 1;
`;

export const TableOrders = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
`;
// Generic styles for each column (header + rows)

export const Col = styled.td`
  vertical-align: middle;
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  min-width: 70px;
  max-width: 170px;
`;
export const ClosedCol = styled.td`
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: 140px;
`;

export const BoldCol = styled(Col)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const PriceCol = styled(Col)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const SubTotalCol = styled(Col)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const AutoCol = styled(Col)`
  width: auto;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
`;
export const ItemsPanel = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const SubTotalTr = styled.tr`
  height: 56px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  cursor: pointer;
  overflow: hidden;
  border: none;
`;

export const MoreWrapper = styled.td`
  text-align: center;
  padding: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const Link = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
`;

export const ProductImage = styled.img`
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  ${(p: IStyledProps) =>
    p.overlay &&
    css`
      opacity: 0.35;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};
  &:hover {
    filter: grayscale(0.95) brightness(0.6);
  }
  cursor: pointer;
`;
const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
`;
export const TextBig = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const TextBigWarning = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
`;
