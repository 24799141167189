export const checkRepeatedClientAssociations = (data, clientAssociations, products) => {
    let hasRepeatedReferences = false;
    let filteredAssociations = [];
    let referenceSellerView;
    let hasMultipleReferences;
    if (clientAssociations?.length) {
      referenceSellerView = clientAssociations.find(ca => ca.sku === data.sku);
      hasRepeatedReferences =
        clientAssociations.filter(as => as?.reference.id === referenceSellerView?.reference.id).length > 1;
      const associationsWithReferencesInTheOffer = clientAssociations.filter(
        as => as?.reference.id === referenceSellerView?.reference.id,
      );
      const productSkus = products.map(p => p.sku);
      const associationsWithRepeatedSkus = clientAssociations.filter((ca, index, self) => {
        return self.filter(ca2 => ca2?.reference.id === ca?.reference.id).length > 1;
      });
      const selectedSkus = products.map(p => +p.sku);
      filteredAssociations = associationsWithRepeatedSkus.filter(as => selectedSkus.includes(+as.sku));
      // check if associationsWithReferencesInTheOffer has an element with sku in the array of productSKus besides the one that is being checked
      hasMultipleReferences = associationsWithReferencesInTheOffer.some(
        el => productSkus.includes(el.sku) && el.sku !== data.sku,
      );
    }
  
    const hasRepeatedSkus = hasMultipleReferences && filteredAssociations.length > 1;
  
    return { hasRepeatedSkus, referenceSellerView };
  };
  
  export const columnsFunction = (__: (scope: I18n.Scope, options?: I18n.TranslateOptions) => string) => {
    const cols = [
      { value: 'sku', label: 'sku' },
      { value: 'name', label: 'name' },
      { value: 'origin', label: 'origin' },
      { value: 'availability', label: __('Components.OffersList.table.total_availability') },
    ];
    return cols;
  };