import { AggregationFn, Cell, createColumnHelper, Row } from '@tanstack/react-table';
import { IOfferSummary, IReferenceSummary } from 'common-services';

export const getPairedCell = (cell: Cell<IOfferSummary, unknown>, idx: number, row: Row<IOfferSummary>) => {
  if (cell.column.id.startsWith('quantity')) {
    return idx < row.getVisibleCells().length - 1 ? row.getVisibleCells()[idx + 1].column.id : null;
  } else if (cell.column.id.startsWith('price')) {
    return idx > 0 ? row.getVisibleCells()[idx - 1].column.id : null;
  } else {
    return null;
  }
};

export const offersColumnHelper = createColumnHelper<any>();

type renderingFn = (data: IOfferSummary) => string;
type mergingFn = (data: Array<Row<IOfferSummary>>) => IOfferSummary;

export interface IProps {
  buyerOffers: Array<IOfferSummary>;
}

export const compareReferenceSummaries = (a: IReferenceSummary, b: IReferenceSummary) => {
  // Special handling if either rank is 0
  if (a.rank === 0 && b.rank === 0) {
    // If both ranks are 0, sort by kindName, then by name
    const kindNameComparison = a.kindName.localeCompare(b.kindName);
    if (kindNameComparison !== 0) {
      return kindNameComparison;
    }
    return a.name.localeCompare(b.name);
  } else if (a.rank === 0) {
    // If A rank is 0, it should come last
    return 1;
  } else if (b.rank === 0) {
    // If B rank is 0, it should come last
    return -1;
  }

  // Normal rank comparison if neither is 0
  if (a.rank !== b.rank) {
    return a.rank - b.rank;
  }

  // Ranks are equal and not 0, compare by kindName
  const kindNameComparison = a.kindName.localeCompare(b.kindName);
  if (kindNameComparison !== 0) {
    return kindNameComparison;
  }

  // Finally, compare by name if all else is equal
  return a.name.localeCompare(b.name);
};

export const mergeCells = (renderFn: renderingFn, mergeFn?: mergingFn): AggregationFn<any> => {
  return (columnId, _, childRows) => {
    const parts = columnId.split(':');
    const sellerId = parts[1];

    const filteredRows: Array<Row<IOfferSummary>> = childRows.filter(row => {
      return String(row.original.seller.name) === sellerId;
    });
    if (filteredRows && filteredRows.length > 0) {
      if (filteredRows.length === 1) {
        return renderFn(filteredRows[0].original);
      } else {
        if (mergeFn) {
          return renderFn(mergeFn(filteredRows));
        } else {
          return renderFn(filteredRows[0].original);
        }
      }
    }
    return '';
  };
};

export const sortSellers = (offersGroupedBySeller: Record<string, Record<string, any>>) => {
  const sortedSellers = Object.keys(offersGroupedBySeller).sort((a, b) => {
    return b.localeCompare(a);
  });
  const sortedOffersGroupedBySeller: Record<string, Record<string, any>> = {};
  sortedSellers.forEach(seller => {
    sortedOffersGroupedBySeller[seller] = offersGroupedBySeller[seller];
  });
  return sortedOffersGroupedBySeller;
};

export const getObjectFromBuyerOffers = (buyerOffers, warehouseNames, setWarehouseNames) => {
  return buyerOffers.reduce((group, offer) => {
    const sellerName = offer.seller.name;
    const productName = offer.buyerReference.name;

    if (!group[sellerName]) {
      group[sellerName] = {
        seller: offer.seller,
        totalAvailability: 0,
        totalPrice: 0,
        products: {},
      };
    }

    group[sellerName].totalAvailability += offer.quantity;
    group[sellerName].totalPrice += offer.price;

    if (!group[sellerName].products[productName]) {
      group[sellerName].products[productName] = {
        name: productName,
        totalAvailability: 0,
        totalPrice: 0,
        warehouses: {},
      };
    }

    const product = group[sellerName].products[productName];
    product.totalAvailability += offer.quantity;
    product.totalPrice += offer.price;

    const warehouseName = offer.buyerWarehouseName;

    if (!warehouseNames.includes(warehouseName)) {
      setWarehouseNames([...warehouseNames].concat(warehouseName));
    }

    if (!product.warehouses[warehouseName]) {
      product.warehouses[warehouseName] = {
        offers: [],
        totalAvailability: 0,
        totalPrice: 0,
      };
    }

    const warehouse = product.warehouses[warehouseName];
    warehouse.offers.push(offer);
    warehouse.totalAvailability += offer.quantity;
    warehouse.totalPrice += offer.price;
    return group;
  }, {});
};
