import * as React from 'react';

import { FontIcon } from '..';
import * as S from './Modal.styled';

export interface IProps {
  backgroundColor?: string;
  boxShadow?: string;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  maxWidth?: string;
  minWidth?: string;
  onCloseClick?: () => void;
  padding?: string;
  showCloseIcon?: boolean;
  toShow?: boolean;
}

const Modal: React.FC<IProps> = ({
  backgroundColor,
  boxShadow,
  children,
  className,
  color,
  maxWidth,
  minWidth,
  onCloseClick = () => null,
  padding,
  showCloseIcon = true,
  toShow = true,
}) => {
  if (!toShow) return null;
  return (
    <S.ModalContainer className={className}>
      <S.ModalContent
        backgroundColor={backgroundColor}
        boxShadow={boxShadow}
        className="modal-content"
        color={color}
        maxWidth={maxWidth}
        minWidth={minWidth}
        padding={padding}
      >
        {children}
        {showCloseIcon ? renderCloseIcon(color, onCloseClick) : null}
      </S.ModalContent>
    </S.ModalContainer>
  );
};

function renderCloseIcon(color?: string, onCloseClick?: () => void) {
  return (
    <S.IconWrapper color={color}>
      <FontIcon id="close-modal" name="Close" onClick={onCloseClick} />
    </S.IconWrapper>
  );
}

export default React.memo(Modal);
