import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer } from '../../atoms';

interface IStyledProps {
  disableHover?: boolean;
  isActive?: boolean;
  isSearch?: boolean;
  numHeaders?: number;
  selected?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  align-items: flex-start;
  position: relative;
  flex: 1;
  font-weight: normal;
`;

export const ChildrenContainer = styled(ColumnContainer)`
  width: 100%;
  overflow: hidden;
  flex: 1;
`;
