import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';
import FB from '../../molecules/FilterButton/FilterButton.component';

interface IStyledProps {
  color?: string;
  isSearch?: boolean;
  searchFixed?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  overflow: hidden;
`;

export const SearchContainer = styled(ColumnContainer)`
  flex: 1;
`;

export const CenterContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  min-height: 70vh;
`;

export const SearchFixed = styled(ColumnContainer)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
`;

export const SearchFixedWrapper = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const SearchWrapper = styled(RowContainer)`
  min-height: 58px;
  overflow: visible;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 100%;
  min-width: 100%;
`;

export const TitleContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const Text = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: block;
`;

export const ChevronIcon = styled(FontIcon)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  align-self: flex-start;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const BigTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const ShareTouchable = styled.div`
  display: flex;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.purple1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-width: 100px;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-height: 37px;
  align-self: center;
  display: table;
  ${(p: IStyledProps) =>
    p.isSearch
      ? css`
          border: none;
          padding: 0;
        `
      : ''}
  width: min-content;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const ShareIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  font-size: 14px;
  padding-right: 5px;
  vertical-align: middle;
  display: table-cell;
`;

export const TextShare = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  flex-wrap: wrap;
  text-align: center;
  line-height: 13px;
  padding: 5px 0;
  vertical-align: middle;
  display: table-cell;
`;

export const ControlsRow = styled(RowContainer)`
  min-height: 112px;
`;

export const AddressSelected = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: 0;
`;

export const AddressSelect = styled(TextLabel)`
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const AddressTitle = styled(TextLabel)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const AddressText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const AddressIcon = styled(FontIcon)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  align-self: flex-start;
`;

export const SearchRow = styled(RowContainer)`
  justify-content: space-between;
`;

export const SearchSecondRow = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FeaturedContainer = styled(ColumnContainer)`
  width: 100%;
  padding: 0px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  min-height: min-content;
`;

export const FeaturedList = styled.div`
  display: grid;
  grid-gap: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  grid-template-columns: repeat(auto-fill, 300px);

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    grid-template-columns: repeat(auto-fit, 300px);
    justify-items: center;
  }
`;

export const BackContainer = styled(RowContainer)`
  display: flex;
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Back = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.75;
  }
`;

export const SearchStats = styled.div``;

export const Link = styled.a`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Row = styled(RowContainer)`
  overflow: hidden;
`;

export const ScrollContainer = styled(ColumnContainer)`
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow: auto;
  padding: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const ModalShare = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.65)')};
  padding: 40px;
  width: 100vw;
  height: ${(p: IStyledProps) => p.theme.windowHeight};
  justify-content: center;
  align-items: center;
`;

export const FilterButton = styled(FB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 40px;
`;

const TextBlack = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 4px 0;
`;

const TextGrey = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const Sort = styled(SimpleDropdown)``;
export const SortBlackText = styled(TextBlack)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const SortGreyText = styled(TextGrey)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const RightContainer = styled(RowContainer)<IStyledProps>`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(p: IStyledProps) => (p.searchFixed ? p.theme.paddingSize(10) : 0)};
`;
