import { ICatalogRole, IClient, IContact, ILightContact, IUser, sellerWorkspaceService, utils } from 'common-services';
import * as React from 'react';

import { SimpleDropdown } from '../../atoms';
import ContactsList from '../ContactsList';
import Members from '../Members';
import * as S from './MembersModal.styled';

interface IProps {
  className?: string;
  clients?: Array<IClient>;
  contacts: { [cId: number]: IContact };
  me: IUser;
  members: Array<{ userId: number; role?: ICatalogRole }>;
  title: string;
  size?: number;
}

/**
 * Members giving a modal to see the complete members list.
 * No actions here (read only).
 */
const MembersModal: React.FC<IProps> = ({ className, clients, members, contacts, me, size, title }) => {
  const meAsContact: ILightContact = {
    avatar: me.settings.avatar,
    avatarColor: utils.getAvatarColor(me.name),
    name: me.name,
    phoneNumbers: [me.phone + ''],
    companyName: me.companyName,
    id: me.id,
    isUnregistered: false,
  };
  return (
    <SimpleDropdown
      className={className}
      Content={
        <S.Modal title={title} onlyContent={true}>
          <S.Scroll>
            <ContactsList
              action="none"
              contacts={members.map(m =>
                m.userId === me.id
                  ? meAsContact
                  : contacts[m.userId] ||
                    sellerWorkspaceService.clientToLightContact(clients.find(c => c.userId === m.userId)),
              )}
              hideLetters={true}
              onSelectContact={() => null}
            />
          </S.Scroll>
        </S.Modal>
      }
    >
      <Members contacts={contacts} me={me} members={members} size={size} clients={clients} />
    </SimpleDropdown>
  );
};

export default React.memo(MembersModal);
