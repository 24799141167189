import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import Config from './config';
import { getReadableVersion } from './info';

/**
 * wrapper to assimilate the api of sentry in app and web
 */


export default {
  config: () => ({
    install: () => {
      if (Config.APP_ENV === 'production') {
        Sentry.init({
          dsn: Config.SENTRY_TRACK_URL,
          release: getReadableVersion(),
          integrations: [
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
              tracingOrigins: [Config.APP_ENV, Config.API_URL, /^\//],
            }),
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: true,
              networkCaptureBodies: true,
              networkDetailAllowUrls: ['rtapi.consentio-tool.com'],
              networkRequestHeaders: ['origin', 'referer'],
              networkResponseHeaders: [
                'server',
                'access-control-allow-origin',
                'cache-control',
                'connection',
                'date',
                'expires',
                'transfer-encoding',
                'x-cache',
                'x-content-type-options'
              ]
            }),
          ],
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, 
          tracesSampleRate: 0.2,
          beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'xhr' && ![200, 201].includes(hint.xhr.__sentry_xhr__.status_code)) {
              const data = {
                requestBody: hint.xhr.__sentry_xhr__.body,
                response: hint.xhr.response,
                responseUrl: hint.xhr.responseURL,
                statusCode: hint.xhr.__sentry_xhr__.status_code,
              };
              return { ...breadcrumb, data };
            }
            return breadcrumb;
          },
        });
      }
      else {
        Sentry.init({
          dsn: Config.SENTRY_TRACK_URL,
          release: getReadableVersion(),
          integrations: [
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
              tracingOrigins: [Config.APP_ENV, Config.API_URL, /^\//],
            }),
          ],
          tracesSampleRate: 0.2,
          beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'xhr' && ![200, 201].includes(hint.xhr.__sentry_xhr__.status_code)) {
              const data = {
                requestBody: hint.xhr.__sentry_xhr__.body,
                response: hint.xhr.response,
                responseUrl: hint.xhr.responseURL,
                statusCode: hint.xhr.__sentry_xhr__.status_code,
              };
              return { ...breadcrumb, data };
            }
            return breadcrumb;
          },
        });
      }
    },
  }),
  setTagsContext: (tagContext: any) =>
    Sentry.configureScope(scope => {
      scope.setTags(tagContext);
    }),
  setUserContext: (userContext: any) =>
    Sentry.configureScope(scope => {
      scope.setUser(userContext);
    }),
};
