import { IReduxState } from '../reducers';

/**
 * Get current selected workspace
 */
export function getSelectedWorkspace(state: IReduxState): IWorkspace | undefined {
  const { workspaceSelected, workspaceType } = state.nav;
  if (!workspaceSelected) {
    return undefined;
  }
  return workspaceType === 'seller'
    ? state.catalog.catalogs[workspaceSelected]
    : state.workspace.workspaces[workspaceSelected];
}
