import { __, BUSINESS_TYPE } from 'common-services';

interface BusinessTypeInformation {
  title: string;
  description: string;
}

/**
 * Get business type + isSeller / buyer labels
 */
export function getBusinessTypeInfo(
  businessType: BUSINESS_TYPE,
  isBuyer: boolean,
  isSeller: boolean,
): BusinessTypeInformation {
  let bType: string = '';
  let companyUse: string = '';
  let consentioFor: string = '';

  if (isBuyer && isSeller) {
    companyUse = __('Components.Settings.business_type_section.company_use.both');
  } else if (isBuyer) {
    companyUse = __('Components.Settings.business_type_section.company_use.buy');
  } else {
    companyUse = __('Components.Settings.business_type_section.company_use.sell');
  }
  consentioFor = __('Components.Settings.business_type_section.consentio_for', { company_use: companyUse });
  switch (businessType) {
    case BUSINESS_TYPE.WHOLESALER:
      bType = __('Components.Settings.business_type_section.business_types.wholesaler');
      break;
    case BUSINESS_TYPE.GROWER:
      bType = __('Components.Settings.business_type_section.business_types.grower');
      break;
    case BUSINESS_TYPE.SUPERMARKET:
      bType = __('Components.Settings.business_type_section.business_types.supermarket');
      break;
    case BUSINESS_TYPE.POINT_OF_SALE:
      bType = __('Components.Settings.business_type_section.business_types.point_of_sale');
      break;
    case BUSINESS_TYPE.CONSUMER:
      bType = __('Components.Settings.business_type_section.business_types.consumer');
      break;
    case BUSINESS_TYPE.UNKNOWN:
      bType = __('Components.Settings.business_type_section.business_types.unknown');
      break;
    default:
      break;
  }
  const disclaimer: string = __('Components.Settings.business_type_section.disclaimer');
  const description: string = __('Components.Settings.business_type_section.description', {
    business_type: bType.toLocaleLowerCase(),
    consentio_for: consentioFor,
  });
  return {
    title: `${bType} · ${consentioFor}`,
    description: `${description} ${disclaimer}`,
  };
}
