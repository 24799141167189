import styled, { ITheme } from '../../../styled-components';
import { ChatCard, ColumnContainer, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isFirstDate?: boolean;
  theme: ITheme;
  isSelected?: boolean;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(6)};
  overflow-y: auto;
`;

export const TextDate = styled(TextLabel)<IStyledProps>`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(p.isFirstDate ? 1 : 6)};
`;

export const ChatItem = styled(ChatCard)`
  max-width: 1000px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;
