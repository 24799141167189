import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  color?: string;
  contentWithLeftPadding?: boolean;
  expanded?: boolean;
  height?: string;
  iconPosition?: 'left' | 'right';
  notCollapsible?: boolean;
  theme: ITheme;
  titleSize?: string;
  titleWithLeftPadding?: boolean;
}

export const ExpansionPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: min-content;
`;

/**
 * Get padding left for the content
 */
const getContentPaddingLeft = (p: IStyledProps) => {
  if (p.contentWithLeftPadding && p.titleWithLeftPadding) {
    return 'calc(' + p.theme.paddingSize(5) + ' + 28px)';
  }
  if (p.contentWithLeftPadding) {
    return '28px';
  }
  return 0;
};

export const TitleContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  cursor: ${(p: IStyledProps) => (p.notCollapsible ? 'default' : 'pointer')};
  position: relative;
  padding-left: ${(p: IStyledProps) => (p.titleWithLeftPadding ? p.theme.paddingSize(5) : 0)};
  justify-content: flex-end;
`;

export const ChildrenContainer = styled(ColumnContainer)`
  width: 100%;
  transition: height 0.6s ease;
  height: ${(p: IStyledProps) => p.height};
  overflow: ${(p: IStyledProps) => (p.height === '0' ? 'hidden' : 'visible')};
  min-height: min-content;
  padding-left: ${getContentPaddingLeft};
`;

export const TitleRow = styled(RowContainer)<IStyledProps>`
  width: auto;
  flex: 1 1 auto;
  align-items: center;
  font-weight: ${(p: IStyledProps) => (p.expanded ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  font-size: ${(p: IStyledProps) => p.titleSize || p.theme.fontSize.big};
  ${(p: IStyledProps) =>
    p.iconPosition === 'left' &&
    css`
      padding-left: ${p.theme.paddingSize(2)};
    `}
`;

export const TitleLeft = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => (p.expanded ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  display: inline;
  font-size: inherit;
  line-height: 30px;
`;

export const TitleRight = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  display: inline;
`;

export const ArrowIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0;
  color: ${(p: IStyledProps) => p.color || p.theme.colors.black};
  ${(p: IStyledProps) =>
    p.notCollapsible &&
    css`
      visibility: hidden;
    `}
`;

export const Warning = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(p: IStyledProps) => p.theme.colors.orange1};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: 2px;
`;
