import { __ } from 'common-services';
import { InputWithLabel } from '../../../../molecules';
import * as S from './ReferenceListDetail.style';
import * as React from 'react';

interface ReferenceModalProps {
  setShowReferenceModal: React.Dispatch<React.SetStateAction<boolean>>;
  products: Array<{
    label: string;
    value: string;
  }>;
  setProductSelect: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  productSelect: {
    label: string;
    value: string;
  };
  setClientSelectedId: React.Dispatch<React.SetStateAction<{
    buyerWorkspaceId: number;
    imageUrl: string;
}>>
  onAssociateReference: () => void;
}

const ReferenceModalComponent: React.FC<ReferenceModalProps> = ({
  setShowReferenceModal,
  products,
  setProductSelect,
  productSelect,
  setClientSelectedId,
  onAssociateReference,
}) => {

  return (
    <>
      <S.SelectProductModal
        title={__('Components.SellerOffer.referenceList.referenceListSelected.selectReferenceModal.associateProduct')}
        subtitle={__('Components.SellerOffer.referenceList.referenceListSelected.selectReferenceModal.selectReference')}
        onClose={() => setShowReferenceModal(false)}
      >
        <S.Row>
          <InputWithLabel
            label={__('Components.SellerOffer.referenceList.referenceListSelected.selectReferenceModal.referenceName')}
            isRequired={true}
            width="100%"
          >
            <S.SelectContainer
              containerMargin="4px 0"
              name="type"
              onChange={(n, value) => {
                const product = products.find(p => p.value === value);
                setProductSelect(product);
              }}
              options={products}
              value={productSelect.value}
              zIndex={1000}
            />
          </InputWithLabel>
        </S.Row>
        <S.ContainerButtonsAddReference>
          <S.ButtonCustom
            type="principal"
            onClick={() => {
              onAssociateReference();
            }}
            disabled={!productSelect.value}
          >
            {__('Components.SellerOffer.referenceList.referenceListSelected.selectReferenceModal.accept')}
          </S.ButtonCustom>

          <S.ButtonCustom
            onClick={() => {
              setShowReferenceModal(false);
              setClientSelectedId({buyerWorkspaceId: 0, imageUrl: ''});
            }}
            type="secondary"
          >
            {__('Components.SellerOffer.referenceList.referenceListSelected.selectReferenceModal.cancel')}
          </S.ButtonCustom>
        </S.ContainerButtonsAddReference>
      </S.SelectProductModal>
    </>
  );
};

export default ReferenceModalComponent;
