import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  backgroundColor?: string;
  boxShadow?: string;
  color?: string;
  maxWidth?: string;
  minWidth?: string;
  padding?: string;
  theme: ITheme;
}

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.92)')};
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  position: relative;
  max-width: min(${(p: IStyledProps) => p.maxWidth || '455px'}, 90vw);
  min-width: min(${(p: IStyledProps) => p.minWidth || '350px'}, 95vw);
  margin: auto;
  box-shadow: ${(p: IStyledProps) => p.boxShadow || p.theme.boxShadow};
  padding: ${(p: IStyledProps) => p.padding || p.theme.paddingView};
  background-color: ${(p: IStyledProps) => p.backgroundColor || p.theme.colors.white};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.black};
  text-align: left;
  max-height: ${(p: IStyledProps) => p.theme.windowHeight};
  border-radius: ${(p: IStyledProps) => p.theme.paddingObject};
  overflow: auto;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.grey1};
`;
