import { IProduct } from 'common-services';

export function validateProductForm(product: IProduct) {
  const errors = new Map();

  if (!product.price && !product.isPor) errors.set('price', 'empty');
  if (!product.type.variety) errors.set('variety', 'empty');
  if (!product.type.type) errors.set('type', 'empty');

  return errors;
}
