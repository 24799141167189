import * as React from 'react';
import * as S from '../ProductsImport.styled';
import { __, costActions, modalActions, notificationsActions, utils } from 'common-services';
import Dropzone from 'react-dropzone';
import history from '../../../../history';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../../../reducers';
import { Dispatch } from 'redux';
import { ICostImportError, ICreatedCost } from 'common-services/dist/cost/interface';

const CostImportComponent: React.FC = () => {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [importErrors, setImportErrors] = React.useState<Array<ICostImportError>>([]);
  const [replace, setReplace] = React.useState(false);
  const t = Date.now();
  const workspaceId = useSelector((state: IReduxState) => state.nav.workspaceSelected);
  const dispatch = useDispatch<Dispatch<any>>();
  const [hasCosts, setHasCosts] = React.useState(false);
  const language = useSelector((state: IReduxState) => state.user.user.settings.language);
  const moreInfo = 'https://consentio.app.box.com/s/4e0lpza66187fs6oz8z3b68zfya0i58v'

  const getRibbonText = () => {
    return (
      <S.Text>
        {
          utils.formatText(__('Components.costImport.issues.ribbon'), s => (
            <S.TextLink
              key={s}
              onClick={() => {
                const el = document.getElementById('errors-container');
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {s}
            </S.TextLink>
          )) as any
        }
      </S.Text>
    );
  };

  const upload = () => {
    const reader = new FileReader();
    reader.onload = () => {
      dispatch(
        costActions.costImport(
          reader.result as string,
          workspaceId,
          replace,
          language,
          (data?: { createdCost: Array<ICreatedCost>; errors: Array<IImportError> }) => {
            if (data?.errors?.length > 0) {
              setImportErrors(data.errors);
              setFile(undefined);
            } else {
              history.goBack();
              dispatch(
                notificationsActions.notificationShow(
                  {
                    title: __('Components.costImport.notification.title'),
                    subtitle: __('Components.costImport.notification.subtitle'),
                    closable: true,
                    style: 'success',
                  },
                  8000,
                ),
              );
            }
          },
        ),
      );
    };
    reader.onerror = err => {
      modalActions.modalOpen(__('Components.Settings.error', { error: err }));
      modalActions.modalClose();
    };
    if (file) reader.readAsText(file);
  };

  const renderDropzone = () => {
    return (
      <Dropzone accept={'.csv'} multiple={false} onDrop={getFile}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input type="file" multiple={false} accept={'.csv'} {...getInputProps()} />
              <S.UploadFileIcon name="Upload" />
              <S.TextUpload>
                {__('Components.costImport.dropzone.subtitle')}{' '}
                {file && (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('Components.costImport.fileDescription.fileCount')}</S.TextUploadGreen>
                  </>
                )}
              </S.TextUpload>
              <S.TextCenter>{__('Components.costImport.dropzone.text')}</S.TextCenter>
              <S.SelectButton type={file ? 'secondary' : 'principal'}>
                {file ? __('Components.costImport.dropzone.change') : __('Components.costImport.dropzone.select')}
              </S.SelectButton>
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
    );
  };

  const renderIssues = () => {
    return (
      <>
        <S.IssuesHeader>
          <S.Title>{__('Components.costImport.issues.title')}</S.Title>
          <S.WarningIcon name="Warning" disableHover={true} />
        </S.IssuesHeader>
        <S.IssuesColumn>
          {importErrors.map((error, idx) => {
            return (
              <S.IssueLabel key={idx}>
                <>{error.message}</>
              </S.IssueLabel>
            );
          })}
        </S.IssuesColumn>
      </>
    );
  };

  const getFile = (files: Array<File>) => {
    if (files && files.length > 0) {
      setFile(files[0]);
    } else {
      dispatch(
        notificationsActions.notificationShow(
          {
            title: __('Components.costImport.formatError.title'),
            subtitle: __('Components.costImport.formatError.description'),
            closable: true,
            style: 'error',
          },
          6000,
        ),
      );
    }
  };

  React.useEffect(() => {
    dispatch(
      costActions.getCost(workspaceId, (res: { costs: Array<ICreatedCost>; errors: Array<ICostImportError> }) => {
        setHasCosts(res.costs?.length > 0);
      }),
    );
  }, []);

  return (
    <>
      <S.Container>
        {importErrors?.length && (<S.HeaderRibbon type="warning" text={getRibbonText()} />)}
        <S.ScrollContainer>
          <S.FormContainer>
            <S.Title>{__('Components.costImport.header.title')}</S.Title>
            <S.Subtitle>
              {__('Components.costImport.header.subtitle')}{' '}
              <S.TextLink href={moreInfo} target="blank">
                {__('Components.costImport.header.moreInfo')}
              </S.TextLink>
            </S.Subtitle>
            {renderDropzone()}
            {hasCosts && (
              <S.CheckboxRow>
                <S.CheckBox isChecked={replace} onClick={() => setReplace(!replace)} />
                <S.CheckBoxLabel onClick={() => setReplace(!replace)}>
                  {__('Components.costImport.checkbox.text')}
                </S.CheckBoxLabel>
              </S.CheckboxRow>
            ) }
            <S.ButtonUpload disabled={!file} onClick={() => upload()}>
              {__('Components.costImport.uploadButton')}
            </S.ButtonUpload>
          </S.FormContainer>
          {importErrors?.length && (
            <S.ErrorsContainer id='errors-container'>
              {renderIssues()}
            </S.ErrorsContainer>
          )}
        </S.ScrollContainer>
      </S.Container>
    </>
  );
};

export default CostImportComponent;
