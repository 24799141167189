import {
  __,
  constants,
  debounce,
  EventTrack,
  INVITE_ORIGIN,
  LOCALE,
  modalActions,
  notificationsActions,
  PRICE_ADJUSTMENT_TYPE,
  PRICE_GROUP_TYPE,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  throttle,
  to,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import Layout from '../../../layout-flex';
import SelectContacts from '../../../screens/select-contacts';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button, ExpansionPanel, FontIcon, Input, Ribbon, Select, Switch } from '../../atoms';
import {
  ActionsModal,
  Members,
  MembersModal,
  PriceItemTable,
  ProductFilter,
  SearchProductModal,
  SimpleSearch,
} from '../../molecules';
import { getPriceAdjustmentTypes } from '../../molecules/PriceItemTable/PriceItemTable.utils';
import * as S from './WorkspacePriceGroupEdit.styled';

const emptyObject = {};

export interface IStateProps {
  catalogId: number;
  catalogs: { [cId: number]: IWorkspace };
  clientsOld: Array<IClient>;
  contacts: { [cId: number]: IContact };
  me: IUser;
  priceGroup?: IPriceGroup;
  priceGroups: Array<IPriceGroup>;
  prices: { [productId: number]: IPrice };
  rateId?: string;
  isQuoterMode: boolean;
}

export interface IDispatchProps {
  getPrices: typeof productActions.getPrices;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  priceGroupAddAllPrices: typeof sellerWorkspaceActions.priceGroupAddAllPrices;
  priceGroupAddAllSearchPrices: typeof sellerWorkspaceActions.priceGroupAddAllSearchPrices;
  priceGroupAddClients: typeof sellerWorkspaceActions.priceGroupAddClients;
  priceGroupAddPrices: typeof sellerWorkspaceActions.priceGroupAddPrices;
  priceGroupCreate: typeof sellerWorkspaceActions.priceGroupCreate;
  priceGroupDelete: typeof sellerWorkspaceActions.priceGroupDelete;
  priceGroupDeleteClient: typeof sellerWorkspaceActions.priceGroupDeleteClient;
  priceGroupDeletePrices: typeof sellerWorkspaceActions.priceGroupDeletePrices;
  priceGroupDeletePrice: typeof sellerWorkspaceActions.priceGroupDeletePrice;
  priceGroupsGet: typeof sellerWorkspaceActions.priceGroupsGet;
  priceGroupUpdate: typeof sellerWorkspaceActions.priceGroupUpdate;
  priceGroupActivateRank: typeof sellerWorkspaceActions.priceGroupActivateRank;
  priceGroupCheckRank: typeof sellerWorkspaceActions.priceGroupCheckRank;
  notificationShow: typeof notificationsActions.notificationShow;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string; priceGroupId: string }>;
interface IPropsPureComponent extends IProps {
  togglePriceGroupRanksEnabled: boolean;
}

interface IState {
  clientsAdded: Array<IClient>;
  facets: IFacets;
  facetsGlobal: IFacets;
  hasMore?: boolean;
  hideFilters?: boolean;
  isRankActive: boolean;
  isSearched?: boolean;
  mode: 'create' | 'edit';
  name: string;
  products: Array<IProduct>;
  productsExcludedIds: Array<number>;
  scrollElement?: HTMLDivElement;
  searchId?: string;
  searchState: ISearch;
  showAddClients: boolean;
  showAddProducts: boolean;
  showProductsLoading: number;
  showShare?: boolean;
  totalResults: number;
  boxTypes?: Array<ICustomBoxType>;
  palletTypes?: Array<ICustomPalletType>;
  selectedPriceGroupItems: Array<IPriceGroupItem>;
  selectedProducts: Array<IProduct>;
  showBulkModifyModal: boolean;
}

/**
 * Price group edit or create
 */
class WorkspacePriceGroupEditPureComponent extends React.PureComponent<IPropsPureComponent, IState> {
  private t: number;
  /**
   * Update name with a debounce
   */
  private onUpdateName = (name: string) => {
    const { catalogId, priceGroupUpdate, priceGroup } = this.props;
    EventTrack.track('workspace_pricegroup_update', {
      workspace_id: catalogId,
      pricegroup_id: priceGroup.priceGroupId,
    });
    priceGroupUpdate(catalogId, priceGroup.priceGroupId, {
      name,
      status: priceGroup.status,
      shouldAddTransportCost: priceGroup.shouldAddTransportCost,
    });
  };

  private sendSearch: () => void = debounce(() => {
    const { me } = this.props;
    const { searchState } = this.state;
    this.setState({ isSearched: false });
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true, true)
      .then(this.onReceiveProducts);
  }, 200);

  private scrollSearch = throttle(() => {
    const { searchId } = this.state;
    productService.productScrollSearch(searchId, api).then(this.onReceiveProducts);
  }, 200);

  constructor(props: IPropsPureComponent) {
    super(props);
    this.t = Date.now();
    const { catalogs, catalogId, priceGroup } = props;
    this.state = {
      clientsAdded: props.priceGroup?.clients || [],
      hideFilters: true,
      mode: props.priceGroup ? 'edit' : 'create',
      name: props.priceGroup?.name || props.priceGroup?.description || '',
      showAddClients: false,
      showAddProducts: false,
      showProductsLoading: 0,
      productsExcludedIds: props.priceGroup?.prices?.map(p => p.productId) || [],
      products: [],
      facets: {},
      totalResults: 0,
      hasMore: false,
      isRankActive: false,
      searchId: '',
      facetsGlobal: {},
      searchState: {
        text: '',
        language: props.me.settings.language as LOCALE,
        index: catalogs[catalogId]?.hashId,
        rate: [priceGroup?.priceGroupId],
        status: [],
        sort: `title_sort.${props.me.settings.language}`,
        sortOrder: 'asc',
      },
      selectedPriceGroupItems: [],
      selectedProducts: [],
      showBulkModifyModal: false,
    };
  }

  public componentDidMount() {
    const {
      catalogId,
      getPrices,
      match,
      me,
      priceGroups,
      priceGroup,
      priceGroupsGet,
      prices,
      priceGroupCheckRank,
      togglePriceGroupRanksEnabled,
    } = this.props;
    RenderTrack.track('WorkspacePriceGroupEdit', { pricegroupId: priceGroup?.priceGroupId, renderTime: this.t });
    this.productSearch();

    const {
      params: { priceGroupId },
    } = match;
    if (priceGroupId !== 'new' && (!priceGroups || !priceGroups.length)) {
      priceGroupsGet(catalogId);
    }
    if (priceGroupId !== 'new' && !Object.keys(prices).length) {
      getPrices(catalogId, me.id);
    }
    api.product.getBoxTypes(me.id!, catalogId).then(res => {
      this.setState({ boxTypes: res });
    });
    api.product.getPalletTypes(me.id!, catalogId).then(res => {
      this.setState({ palletTypes: res });
    });

    if (togglePriceGroupRanksEnabled) {
      priceGroupCheckRank(catalogId, priceGroup?.priceGroupId, (res: any) => {
        this.setState({ isRankActive: res.ranked });
        if (res.ranked) {
          this.setState({
            searchState: { ...this.state.searchState, sort: 'rank' },
          });
        }
      });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { priceGroup, catalogs, catalogId, me, priceGroupCheckRank, togglePriceGroupRanksEnabled } = this.props;
    const { searchState, showProductsLoading } = this.state;

    if (
      prevProps.priceGroup?.priceGroupId !== priceGroup?.priceGroupId &&
      togglePriceGroupRanksEnabled &&
      priceGroup?.priceGroupId !== undefined
    ) {
      priceGroupCheckRank(catalogId, priceGroup?.priceGroupId, (res: any) => {
        this.setState({ isRankActive: res.ranked });
        if (res.ranked) {
          this.setState({
            searchState: { ...this.state.searchState, sort: 'rank' },
          });
        }
      });
    }

    if (!prevProps.priceGroup && priceGroup) {
      this.setState({
        mode: 'edit',
        name: priceGroup.name,
        clientsAdded: priceGroup.clients,
        searchState: {
          ...searchState,
          rate: [priceGroup?.priceGroupId],
        },
      });
      this.setState({ showProductsLoading: showProductsLoading || 3 });
      setTimeout(() => this.productSearch(), 2500);
    }
    if (priceGroup && prevProps.priceGroup !== priceGroup) {
      if (prevProps.priceGroup) {
        this.setState({
          name: priceGroup.name,
          clientsAdded: priceGroup.clients,
          searchState: {
            ...searchState,
            rate: [priceGroup?.priceGroupId],
          },
        });
        if (prevProps.priceGroup.prices.length < priceGroup.prices.length) {
          this.setState({ showProductsLoading: showProductsLoading || 3 });
          setTimeout(() => this.productSearch(), 2500);
        }
      }
      if (prevProps.priceGroup?.prices !== priceGroup.prices) {
        this.setState({
          productsExcludedIds: priceGroup.prices?.map(p => p.productId) || [],
        });
      }
    }
    if (catalogs[catalogId] !== prevProps.catalogs[prevProps.catalogId]) {
      this.setState({
        searchState: { ...searchState, index: catalogs[catalogId]?.hashId },
      });
    }
    if (JSON.stringify(prevState.searchState) !== JSON.stringify(searchState)) {
      this.productSearch();
    }
    if (prevProps.catalogId !== catalogId) {
      api.product.getBoxTypes(me.id!, catalogId).then(res => {
        this.setState({ boxTypes: res });
      });
      api.product.getPalletTypes(me.id!, catalogId).then(res => {
        this.setState({ palletTypes: res });
      });
    }
  }

  public render() {
    const {
      clientsAdded,
      facets,
      facetsGlobal,
      hasMore,
      hideFilters,
      isRankActive,
      mode,
      name,
      products,
      scrollElement,
      searchState,
      showAddClients,
      showAddProducts,
      showProductsLoading,
      boxTypes,
      palletTypes,
      selectedPriceGroupItems,
      selectedProducts,
      showBulkModifyModal,
    } = this.state;
    const {
      catalogId,
      catalogs,
      contacts,
      history,
      location,
      match,
      me,
      modalClose,
      modalOpen,
      priceGroup,
      priceGroupDelete,
      priceGroupUpdate,
      priceGroupActivateRank,
      prices,
      notificationShow,
      touchImage,
      isQuoterMode,
      togglePriceGroupRanksEnabled,
    } = this.props;
    const catalog = catalogs[catalogId];
    const showCTACreate = !!(mode === 'create' && name);
    const priceGroupExists = !!(priceGroup && priceGroup.priceGroupId);
    const amEditor = ['admin', 'editor'].includes(sellerWorkspaceService.getRole(catalog, me.id));
    const moreProductsToAdd = priceGroupExists ? priceGroup.prices.length < Object.keys(prices).length : false;

    let breadcrumbLabel =
      mode === 'create' ? __('WorkspacePriceGroupEdit.title_create') : __('WorkspacePriceGroupEdit.title_edit');

    if (isQuoterMode) {
      breadcrumbLabel =
        mode === 'create' ? __('WorkspaceMarginEdit.title_create') : __('WorkspaceMarginEdit.title_edit');
    }

    return (
      <Layout
        header={{
          show: true,
          breadcrumbs: [
            {
              label: isQuoterMode ? __('WorkspaceMargins.title') : __('Components.Header.WorkspacePriceGroups'),
              action: () =>
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_PRICE_GROUPS,
                    workspaceId: catalogId + '',
                  }),
                ),
            },
            {
              label: breadcrumbLabel,
            },
          ],
          tabSelected: 'pricelist',
        }}
      >
        <S.Container>
          {mode === 'edit' && amEditor ? (
            <S.RowTop>
              <S.TextGrey>{__('WorkspacePriceGroupEdit.realtime_save')}</S.TextGrey>
              <S.ButtonRemove
                onClick={() =>
                  modalOpen(
                    isQuoterMode
                      ? __('WorkspaceMarginEdit.modal_remove_rate.title')
                      : __('WorkspacePriceGroupEdit.modal_remove_rate.title'),
                    () => {
                      modalClose();
                      priceGroupDelete(catalogId, priceGroup.priceGroupId, () => {
                        history.goBack();
                        notificationShow(
                          {
                            title: isQuoterMode
                              ? __('WorkspaceMarginEdit.delete_notification.title')
                              : __('WorkspacePriceGroupEdit.delete_notification.title'),
                            subtitle: __('WorkspaceMarginEdit.delete_notification.subtitle', {
                              name: priceGroup.name,
                            }),
                            closable: true,
                            style: 'info',
                          },
                          4000,
                        );
                      });
                    },
                    {
                      actionType: 'dangerous',
                      subtitle: isQuoterMode
                        ? __('WorkspaceMarginEdit.modal_remove_rate.subtitle')
                        : __('WorkspacePriceGroupEdit.modal_remove_rate.subtitle'),
                      icon: IMAGES.error,
                      closeable: true,
                      text2: isQuoterMode
                        ? __('WorkspaceMarginEdit.modal_remove_rate.text')
                        : __('WorkspacePriceGroupEdit.modal_remove_rate.text'),
                      showCancelButton: true,
                      buttonText: isQuoterMode
                        ? __('WorkspaceMarginEdit.modal_remove_rate.cta')
                        : __('WorkspacePriceGroupEdit.modal_remove_rate.cta'),
                    },
                    'nice',
                  )
                }
              >
                <S.TrashIcon name="Trash" className="price-group-Trash-icon" disableHover={true} />
                <S.ActionText>
                  {isQuoterMode ? __('WorkspaceMargins.remove_action') : __('WorkspacePriceGroups.remove_action')}
                </S.ActionText>
              </S.ButtonRemove>
            </S.RowTop>
          ) : null}
          <S.Row>
            <ProductFilter
              changeSearchState={s => this.setState({ searchState: s })}
              clearFilters={() => {
                EventTrack.track('workspace_products_filter_clear', {
                  workspace_id: catalog.id,
                });
                this.setState({
                  searchState: {
                    text: searchState.text,
                    language: searchState.language,
                    index: searchState.index,
                    status: [],
                    sort: searchState.sort,
                    sections: searchState.sections,
                    priceType: [],
                  },
                });
              }}
              facets={facets}
              facetsGlobal={facetsGlobal}
              me={me}
              numberOfHeaders={2}
              searchState={searchState}
              onHide={h => this.setState({ hideFilters: h })}
              showClosed={hideFilters}
              showOver="only-ipad"
              boxTypes={boxTypes}
              palletTypes={palletTypes}
            />
            <S.Column>
              {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}

              <S.BodyContainer
                className="price-group-edit-body-container"
                ref={e => {
                  if (e) this.setState({ scrollElement: e });
                }}
              >
                {priceGroupExists ? (
                  <>
                    {isQuoterMode ? (
                      <S.StatusMarginBlock>
                        <S.StatusRow>
                          <S.TextBlack>
                            {priceGroup.status === 'active'
                              ? __('WorkspaceMarginEdit.status.active')
                              : __('WorkspaceMarginEdit.status.inactive')}
                          </S.TextBlack>
                          <Switch
                            isChecked={priceGroup.status === 'active'}
                            name="pricegroup-status"
                            className="pricegroup-status-switch"
                            selectable={false}
                            onChange={(n: string, isChecked: boolean) => {
                              priceGroupUpdate(catalogId, priceGroup.priceGroupId, {
                                status: isChecked ? 'active' : 'inactive',
                                name: priceGroup.name,
                                shouldAddTransportCost: priceGroup.shouldAddTransportCost,
                              });
                            }}
                          />
                        </S.StatusRow>
                        <S.TextGrey>{__('WorkspaceMarginEdit.status.description')}</S.TextGrey>
                      </S.StatusMarginBlock>
                    ) : (
                      <S.FormBlock>
                        <ExpansionPanel
                          title={
                            amEditor
                              ? __('WorkspacePriceGroupEdit.status.title')
                              : __('WorkspacePriceGroupEdit.status.title_read_only')
                          }
                          iconPosition="left"
                          defaultExpanded={mode === 'create'}
                          notCollapsible={!amEditor}
                        >
                          {amEditor ? (
                            <>
                              <S.TextSemiBold>{__('WorkspacePriceGroupEdit.status.subtitle')}</S.TextSemiBold>
                              <S.TextGrey>{__('WorkspacePriceGroupEdit.status.description')}</S.TextGrey>
                              <S.StatusRow>
                                <S.TextBlack>
                                  {priceGroup.status === 'active'
                                    ? __('WorkspacePriceGroupEdit.status.active')
                                    : __('WorkspacePriceGroupEdit.status.inactive')}
                                </S.TextBlack>
                                <Switch
                                  isChecked={priceGroup.status === 'active'}
                                  name="pricegroup-status"
                                  className="pricegroup-status-switch"
                                  selectable={false}
                                  onChange={(n: string, isChecked: boolean) => {
                                    priceGroupUpdate(catalogId, priceGroup.priceGroupId, {
                                      status: isChecked ? 'active' : 'inactive',
                                      name: priceGroup.name,
                                      shouldAddTransportCost: priceGroup.shouldAddTransportCost,
                                    });
                                  }}
                                />
                              </S.StatusRow>
                              <S.TextSemiBold>
                                {__('WorkspacePriceGroupEdit.include_transport_cost.title')}
                              </S.TextSemiBold>
                              <S.TextGrey>{__('WorkspacePriceGroupEdit.include_transport_cost.subtitle')}</S.TextGrey>
                              <S.TransportCostRow>
                                <Select
                                  name="shouldAddTransportCost"
                                  value={priceGroup.shouldAddTransportCost ? 'not-included' : 'included'}
                                  onChange={(_, val) =>
                                    priceGroupUpdate(catalogId, priceGroup.priceGroupId, {
                                      shouldAddTransportCost: val === 'not-included',
                                      name: priceGroup.name,
                                      status: priceGroup.status,
                                    })
                                  }
                                  options={[
                                    {
                                      value: 'included',
                                      label: __('WorkspacePriceGroupEdit.include_transport_cost.included'),
                                    },
                                    {
                                      value: 'not-included',
                                      label: __('WorkspacePriceGroupEdit.include_transport_cost.not_included'),
                                    },
                                  ]}
                                  width="280px"
                                  containerMargin="0"
                                />
                              </S.TransportCostRow>
                            </>
                          ) : null}
                          <S.TransportCostDescriptionRow>
                            <S.TextGrey>{__('WorkspacePriceGroupEdit.include_transport_cost.description')}</S.TextGrey>
                          </S.TransportCostDescriptionRow>
                        </ExpansionPanel>
                      </S.FormBlock>
                    )}
                  </>
                ) : null}
                <S.FormBlock>
                  <ExpansionPanel
                    title={
                      isQuoterMode
                        ? __('WorkspaceMarginEdit.clients.title', { count: clientsAdded.length })
                        : __('WorkspacePriceGroupEdit.clients.title', { count: clientsAdded.length })
                    }
                    iconPosition="left"
                    defaultExpanded={mode === 'create'}
                    notCollapsible={!amEditor}
                  >
                    {amEditor ? (
                      <>
                        <S.TextGrey>
                          {isQuoterMode
                            ? __('WorkspaceMarginEdit.clients.description')
                            : __('WorkspacePriceGroupEdit.clients.description')}
                        </S.TextGrey>
                        <S.ClientsAddCol>
                          <Members
                            contacts={contacts}
                            me={me}
                            members={clientsAdded.map(c => ({
                              userId: c.userId,
                            }))}
                            size={36}
                            clients={clientsAdded}
                            onClick={() => this.setState({ showAddClients: true })}
                          />
                          <S.AddClientsButton
                            type="link"
                            iconName="Add-group"
                            iconSize="20px"
                            onClick={() => this.setState({ showAddClients: true })}
                            className="price-group-add-clients"
                            withoutPadding={true}
                          >
                            {clientsAdded.length
                              ? __('WorkspacePriceGroupEdit.clients.cta_edit')
                              : __('WorkspacePriceGroupEdit.clients.cta_add')}
                          </S.AddClientsButton>
                        </S.ClientsAddCol>

                        <S.TextBlack>
                          {isQuoterMode
                            ? __('WorkspaceMarginEdit.clients.clients_count', { count: clientsAdded.length })
                            : __('WorkspacePriceGroupEdit.clients.clients_count', { count: clientsAdded.length })}
                        </S.TextBlack>
                      </>
                    ) : (
                      <S.ClientsAddCol>
                        <MembersModal
                          className="price-group-clients"
                          contacts={contacts}
                          clients={clientsAdded}
                          me={me}
                          size={36}
                          members={clientsAdded.map(c => ({
                            userId: c.userId,
                          }))}
                          title={
                            isQuoterMode
                              ? __('WorkspaceMarginEdit.clients.title', { count: clientsAdded.length })
                              : __('WorkspacePriceGroupEdit.clients.title', { count: clientsAdded.length })
                          }
                        />
                      </S.ClientsAddCol>
                    )}
                  </ExpansionPanel>
                </S.FormBlock>
                <S.FormBlock>
                  <ExpansionPanel
                    title={
                      isQuoterMode ? __('WorkspaceMarginEdit.name.title') : __('WorkspacePriceGroupEdit.name.title')
                    }
                    iconPosition="left"
                    defaultExpanded={mode === 'create'}
                    notCollapsible={!amEditor}
                  >
                    <>
                      {amEditor ? (
                        <S.TextGrey>
                          {isQuoterMode
                            ? __('WorkspaceMarginEdit.name.description')
                            : __('WorkspacePriceGroupEdit.name.description')}
                        </S.TextGrey>
                      ) : null}
                      <Input
                        containerMargin="18px 0"
                        name="price-group-name"
                        onChange={(key, value, error) => {
                          this.setState({ name: value + '' });
                        }}
                        onBlur={() => mode === 'edit' && name && this.onUpdateName(name)}
                        placeholder={
                          isQuoterMode
                            ? __('WorkspaceMarginEdit.name.input_placeholder')
                            : __('WorkspacePriceGroupEdit.name.input_placeholder')
                        }
                        type="text"
                        value={name}
                        width="450px"
                        disabled={!amEditor}
                      />
                      {!priceGroupExists && amEditor && showCTACreate ? (
                        <S.CTACreate onClick={this.onCreate} className="price-group-create">
                          {__('WorkspacePriceGroupEdit.cta_create')}
                        </S.CTACreate>
                      ) : null}
                    </>
                  </ExpansionPanel>
                </S.FormBlock>
                {priceGroupExists ? (
                  <S.FormBlock>
                    <ExpansionPanel
                      title={
                        isQuoterMode
                          ? __('WorkspaceMarginEdit.products.title', { count: priceGroup.prices.length })
                          : __('WorkspacePriceGroupEdit.products.title', { count: priceGroup.prices.length })
                      }
                      iconPosition="left"
                      notCollapsible={!amEditor}
                      contentWithLeftPadding={false}
                    >
                      <>
                        <S.ExpandedContainer>
                          {amEditor ? (
                            <S.TextGrey>
                              {isQuoterMode
                                ? __('WorkspaceMarginEdit.products.description')
                                : __('WorkspacePriceGroupEdit.products.description')}
                            </S.TextGrey>
                          ) : null}
                          {togglePriceGroupRanksEnabled ? (
                            <>
                              <S.SwitchRow>
                                <S.SwitchLabel>
                                  {isRankActive
                                    ? __('WorkspacePriceGroups.rank.active')
                                    : __('WorkspacePriceGroups.rank.inactive')}
                                </S.SwitchLabel>
                                <Switch
                                  name="settings"
                                  isChecked={isRankActive}
                                  onChange={(n, checked) =>
                                    priceGroupActivateRank(catalogId, priceGroup.priceGroupId, checked, () => {
                                      this.setState({ isRankActive: checked });
                                      if (checked) {
                                        this.setState({ searchState: { ...searchState, sort: 'rank' } });
                                      } else {
                                        this.setState({
                                          searchState: { ...searchState, sort: `title_sort.${me.settings.language}` }, // aqui
                                        });
                                      }
                                    })
                                  }
                                  disabled={false}
                                  selectable={false}
                                />
                              </S.SwitchRow>
                              <S.TextGrey>
                                {isRankActive
                                  ? __('WorkspacePriceGroups.rank.text_active')
                                  : __('WorkspacePriceGroups.rank.text_inactive')}
                              </S.TextGrey>
                            </>
                          ) : null}
                          <S.ProductsAddRow>
                            {amEditor && moreProductsToAdd ? (
                              <Button
                                type="link"
                                iconName="Add-more"
                                iconSize="20px"
                                onClick={() => this.setState({ showAddProducts: true })}
                                className="price-group-add-clients"
                                withoutPadding={true}
                              >
                                {clientsAdded.length
                                  ? __('WorkspacePriceGroupEdit.products.cta_edit')
                                  : __('WorkspacePriceGroupEdit.products.cta_add')}
                              </Button>
                            ) : null}
                          </S.ProductsAddRow>

                          <S.TextBlack>
                            {isQuoterMode
                              ? __('WorkspaceMarginEdit.products.products_count', { count: priceGroup.prices.length })
                              : __('WorkspacePriceGroupEdit.products.products_count', {
                                  count: priceGroup.prices.length,
                                })}
                          </S.TextBlack>
                          {priceGroup.prices.length ? (
                            <>
                              <S.Sort
                                onSelect={(k: string) =>
                                  this.setState({
                                    searchState: { ...searchState, sort: k, sortOrder: k ? 'desc' : 'asc' },
                                  })
                                }
                                options={[
                                  {
                                    key: `price_updated_at`,
                                    value: __('WorkspacePriceGroupEdit.products.sort.price_updated_at'),
                                  },
                                  {
                                    key: `updated_at`,
                                    value: __('WorkspacePriceGroupEdit.products.sort.updated_at'),
                                  },
                                  { key: '', value: __('WorkspacePriceGroupEdit.products.sort.alphabetical') },
                                ]}
                              >
                                <Button type="skip" iconName="Sort" iconSize="14px">
                                  <S.SortBlackText>{__('PublicShowroom.sort_by')}: </S.SortBlackText>
                                  <S.SortGreyText>
                                    {searchState.sort
                                      ? searchState.sort === `updated_at`
                                        ? __('WorkspacePriceGroupEdit.products.sort.updated_at')
                                        : __('WorkspacePriceGroupEdit.products.sort.price_updated_at')
                                      : __('WorkspacePriceGroupEdit.products.sort.alphabetical')}
                                  </S.SortGreyText>
                                  <FontIcon name="Down" />
                                </Button>
                              </S.Sort>
                              <S.SearchWrapper id="search-products-wrapper">
                                <S.FilterButton
                                  filtersOpened={!hideFilters}
                                  filtersSelected={this.getFiltersCount()}
                                  onClick={() => this.setState({ hideFilters: !hideFilters })}
                                />
                                <SimpleSearch
                                  id="search-products-price-group"
                                  query={searchState.text}
                                  onChange={(text: string) => this.setState({ searchState: { ...searchState, text } })}
                                  placeHolder={__('Components.ProductsList.search.placeholder')}
                                />
                                <S.RightContainer>
                                  {config.TOGGLE_BULK_CHANGES_PRICEGROUPS.enabled &&
                                  selectedPriceGroupItems.length > 0 ? (
                                    <>
                                      <S.ModifyButton
                                        type="secondary"
                                        onClick={() => this.setState({ showBulkModifyModal: true })}
                                      >
                                        {__('WorkspacePriceGroupEdit.modify')}
                                      </S.ModifyButton>
                                      <S.DeleteButton type="delete" onClick={this.onRemovePriceModal}>
                                        {isQuoterMode
                                          ? __('WorkspaceMargins.delete_from_margin')
                                          : __('WorkspacePriceGroupEdit.delete')}
                                      </S.DeleteButton>
                                    </>
                                  ) : null}
                                </S.RightContainer>
                              </S.SearchWrapper>
                            </>
                          ) : null}
                        </S.ExpandedContainer>
                        {products.length > 0 || showProductsLoading > 0 ? (
                          <PriceItemTable
                            catalog={catalog}
                            className="prices-items-table"
                            contacts={contacts}
                            disabled={!amEditor}
                            draggable={isRankActive}
                            hasMore={hasMore}
                            isQuoterMode={isQuoterMode}
                            me={me}
                            modalClose={modalClose}
                            modalOpen={modalOpen}
                            onRemovePrice={this.onRemovePrice}
                            onUpdatePrice={this.onUpdatePrice}
                            priceGroupItems={priceGroup.prices}
                            priceGroupId={priceGroup.priceGroupId}
                            prices={prices}
                            products={products}
                            scrollClassName="price-group-edit-body-container"
                            scrollElement={scrollElement}
                            searchMore={this.scrollSearch}
                            showLoading={Math.min(showProductsLoading, 3)}
                            showStickyHeader={true}
                            sortBy={searchState.sort}
                            sortDirection={searchState.sortOrder}
                            touchImage={touchImage}
                            setSelectedPriceGroupItems={this.setPriceGroups}
                            selectedPriceGroupItems={selectedPriceGroupItems}
                            fromProduct={false}
                            updateSortMode={this.updateSortMode}
                          />
                        ) : null}
                      </>
                    </ExpansionPanel>
                  </S.FormBlock>
                ) : null}
                {showAddClients ? this.renderAddClients() : null}
                {showAddProducts ? this.renderAddProductsModal(catalog) : null}
              </S.BodyContainer>
            </S.Column>
          </S.Row>
          {showBulkModifyModal ? (
            <RenderPriceGroupModifyModal
              catalog={catalog}
              prices={prices}
              selectedPriceGroupItems={selectedPriceGroupItems}
              selectedProducts={selectedProducts}
              onClose={() => this.setState({ showBulkModifyModal: false })}
              onUpdateBulkPrice={this.onUpdateBulkPrice}
              onUpdateBulkPriceList={this.onUpdateBulkPriceList}
              hideBulkButtons={() => this.setState({ selectedPriceGroupItems: [] })}
              isQuoterMode={isQuoterMode}
            />
          ) : null}
        </S.Container>
      </Layout>
    );
  }

  private updateSortMode = (sort: string, sortOrder: 'desc' | 'asc') => {
    const { catalogId } = this.props;
    const { searchState } = this.state;
    this.setState({ searchState: { ...searchState, sort, sortOrder } });
    EventTrack.track('workspace_prricegroup_sort', { workspace_id: catalogId, sort: `${sort} ${sortOrder}` });
  };
  /**
   * Count selected filters
   */
  private getFiltersCount() {
    const { searchState } = this.state;
    const { brand, category, organic, origin, size, type, priceType, status, sections } = searchState;
    return (
      (origin?.length || 0) +
      (organic?.length || 0) +
      (brand?.length || 0) +
      (type?.length || 0) +
      (size?.length || 0) +
      (category?.length || 0) +
      (priceType?.length || 0) +
      (status?.length || 0) +
      (sections?.length || 0)
    );
  }

  /**
   * Render modal to add clients
   */
  private renderAddClients = (): JSX.Element => {
    const { catalogId, me, clientsOld, priceGroup, priceGroupAddClients, priceGroupDeleteClient } = this.props;
    const { clientsAdded, mode } = this.state;
    const clientsAddedIds = clientsAdded.map(m => m.userId);
    return (
      <SelectContacts
        catalogId={catalogId}
        canIncludeMyself={true}
        contacts={clientsOld.map(sellerWorkspaceService.clientToLightContact)}
        defaultSelected={clientsAddedIds}
        me={me}
        onClose={() => this.setState({ showAddClients: false })}
        onDelete={(client: ILightContact) => {
          if (mode === 'edit' && priceGroup && client && client.id) {
            EventTrack.track('workspace_pricegroup_update', {
              workspace_id: catalogId,
              pricegroup_id: priceGroup.priceGroupId,
              client_removed: client.id,
            });
            priceGroupDeleteClient(catalogId, priceGroup.priceGroupId, client.id);
          }
        }}
        onSave={this.onSaveClients}
        onSelect={(client: ILightContact) => {
          if (mode === 'edit' && priceGroup && client && client.id) {
            EventTrack.track('workspace_pricegroup_update', {
              workspace_id: catalogId,
              pricegroup_id: priceGroup.priceGroupId,
              client_added: client.id,
            });
            priceGroupAddClients(catalogId, priceGroup.priceGroupId, [client.id]);
          }
        }}
        origin={INVITE_ORIGIN.WORKSPACE}
        title={__('WorkspacePriceGroupEdit.add_clients')}
        showCta={mode === 'create'}
        trackingFrom="workspace-pricegroups"
      />
    );
  };

  /**
   * Render add product modal
   */
  private renderAddProductsModal = (catalog: IWorkspace) => {
    const { history, isQuoterMode, location, me, prices, priceGroupAddAllSearchPrices, priceGroup } = this.props;
    const { productsExcludedIds } = this.state;

    return (
      <SearchProductModal
        amSeller={true}
        cartClean={(notUpdate?: boolean) => null}
        catalog={catalog}
        close={() => this.setState({ showAddProducts: false })}
        contactId={me.id}
        featured={emptyObject}
        history={history}
        location={location}
        me={me}
        onSelectAllSearch={(search: ISearch) => {
          productService
            .productSearchIds(search, me.settings.language as LOCALE, config.SEARCH_PREFIX, undefined, api)
            .then(data => {
              priceGroupAddAllSearchPrices(catalog.id, priceGroup.priceGroupId, data.hits as Array<number>);
              this.setState({ showAddProducts: false });
            });
        }}
        onSubmitSelectedProducts={this.onSubmitSelectedProducts}
        priceMode="read"
        prices={prices}
        productsExcludedIds={productsExcludedIds}
        selectAll={true}
        selectMode="multiple"
        showFeatured={false}
        subtitle={__('Components.ProductCard.add_products_to_price_group.subtitle')}
        title={
          isQuoterMode
            ? __('Components.ProductCard.add_products_to_price_group.margin')
            : __('Components.ProductCard.add_products_to_price_group.title')
        }
      />
    );
  };

  /**
   * On update a price group item
   */
  private onUpdateBulkPrice = (
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
    priceAdjust: PRICE_ADJUSTMENT_TYPE,
    price: number,
    adjustValue: number,
    modal?: boolean,
  ) => {
    const item = { ...priceGroupItem };
    item.isPor = false;
    item.useBase = false;
    item.price = 0;
    item.percentage = 0;
    switch (priceAdjust) {
      case PRICE_ADJUSTMENT_TYPE.POR:
        item.isPor = true;
        break;
      case PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE:
        item.percentage = -1 * adjustValue;
        break;
      case PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE:
        item.percentage = 1 * adjustValue;
        break;
      case PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE:
        // TODO - Add adjust to price item with currency value
        break;
      case PRICE_ADJUSTMENT_TYPE.MARKUP_PRICE:
        // TODO - Add adjust to price item with currency value
        break;
      case PRICE_ADJUSTMENT_TYPE.FIXED:
        item.price = 1 * adjustValue || 1 * price;
        break;
      case PRICE_ADJUSTMENT_TYPE.BASE:
        item.useBase = true;
        break;
    }
    if (modal) {
      return item;
    } else {
      return this.onUpdatePrice(item);
    }
  };

  /**
   * On update a price group list
   */
  private onUpdateBulkPriceList = (priceProducts: Array<IPriceGroupItem>) => {
    this.onUpdateListPrice(priceProducts);
  };
  /**
   * Set price group items and products for deleting or modifying in bulk
   */
  private setPriceGroups = (priceGroupItem: IPriceGroupItem, product: IProduct) => {
    const { selectedPriceGroupItems, selectedProducts } = this.state;

    if (selectedPriceGroupItems?.includes(priceGroupItem)) {
      this.setState({ selectedPriceGroupItems: selectedPriceGroupItems.filter(group => group !== priceGroupItem) });
    } else {
      this.setState({ selectedPriceGroupItems: [...selectedPriceGroupItems, priceGroupItem] });
    }
    if (selectedProducts?.includes(product)) {
      this.setState({ selectedProducts: selectedProducts.filter(p => p !== product) });
    } else {
      this.setState({ selectedProducts: [...selectedProducts, product] });
    }
  };
  /**
   * Remove price from list in state in bulk
   */
  private onRemovePriceBulk = () => {
    const { catalogId, priceGroup, priceGroupDeletePrices } = this.props;
    const { selectedProducts } = this.state;
    const productIds = selectedProducts.map(product => product.id);
    EventTrack.track('workspace_pricegroup_delete_prices', {
      workspace_id: catalogId,
      pricegroup_id: priceGroup.priceGroupId,
      product_ids: productIds,
    });

    priceGroupDeletePrices(catalogId, priceGroup.priceGroupId, productIds);
  };

  /**
   * Show confirmation modal when removing prices
   */
  private onRemovePriceModal = () => {
    const { modalClose, modalOpen, isQuoterMode } = this.props;
    const { selectedPriceGroupItems } = this.state;

    modalOpen(
      isQuoterMode
        ? __('WorkspaceMarginEdit.modal_remove.title')
        : selectedPriceGroupItems.length > 1
        ? __('WorkspacePriceGroupEdit.modal_remove.title_many')
        : __('WorkspacePriceGroupEdit.modal_remove.title'),
      () => {
        modalClose();
        this.onRemovePriceBulk();
      },
      {
        closeable: true,
        text2: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.description')
          : selectedPriceGroupItems.length > 1
          ? __('WorkspacePriceGroupEdit.modal_remove.description_many')
          : __('WorkspacePriceGroupEdit.modal_remove.description'),
        buttonText: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.cta')
          : __('WorkspacePriceGroupEdit.modal_remove.cta'),
        showCancelButton: true,
        buttonCancelText: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.cta_cancel')
          : __('WorkspacePriceGroupEdit.modal_remove.cta_cancel'),
        icon: IMAGES.cautionGrape,
        actionType: 'dangerous',
      },
      'nice',
    );
  };

  /**
   * Launch search products through elastic
   */
  private productSearch = () => {
    const { catalogs, catalogId, priceGroup } = this.props;
    if (!catalogs[catalogId] || !priceGroup) return;

    this.sendSearch();
    this.setState({ showProductsLoading: 0 });
  };

  /**
   * On save clients
   */
  private onSaveClients = async (contactsToAdd: Array<number>) => {
    const { me, clientsOld, catalogId } = this.props;
    const { mode } = this.state;
    if (mode === 'edit') return;
    let clientsUpdated = clientsOld;
    const { data, err } = await to(api.sellerWorkspace.clientIdsGet(me.id, catalogId, contactsToAdd));
    if (!err && data) {
      clientsUpdated = data.clients;
    }
    this.setState({
      showAddClients: false,
      clientsAdded: clientsUpdated,
    });
  };

  /**
   * Update price from list in state
   */
  private onUpdatePrice = (priceGroupItem: IPriceGroupItem) => {
    const { catalogId, priceGroup, priceGroupAddPrices, isQuoterMode } = this.props;
    EventTrack.track('workspace_pricegroup_update_price', {
      workspace_id: catalogId,
      pricegroup_id: priceGroup.priceGroupId,
      product_id: priceGroupItem.productId,
    });
    priceGroupAddPrices(catalogId, priceGroup.priceGroupId, [priceGroupItem], isQuoterMode);
  };

  /**
   * Update price from array in list
   */
  private onUpdateListPrice = (priceGroupItem: Array<IPriceGroupItem>) => {
    const { catalogId, priceGroup, priceGroupAddPrices, isQuoterMode } = this.props;
    priceGroupItem.forEach(element => {
      EventTrack.track('workspace_pricegroup_update_price', {
        workspace_id: catalogId,
        pricegroup_id: priceGroup.priceGroupId,
        product_id: element.productId,
      });
    });
    priceGroupAddPrices(catalogId, priceGroup.priceGroupId, priceGroupItem, isQuoterMode);
  };

  /**
   * Remove price from list in state
   */
  private onRemovePrice = (priceGroupItem: IPriceGroupItem) => {
    const { catalogId, priceGroup, priceGroupDeletePrice } = this.props;
    EventTrack.track('workspace_pricegroup_delete_price', {
      workspace_id: catalogId,
      pricegroup_id: priceGroup.priceGroupId,
      product_id: priceGroupItem.productId,
    });
    priceGroupDeletePrice(catalogId, priceGroup.priceGroupId, priceGroupItem.productId, priceGroupItem.sku);
  };

  /**
   * Submit selected products
   */
  private onSubmitSelectedProducts = (
    selectedProducts: Array<GenericProduct>,
    allSelected: boolean,
    excludedProducts?: Array<number>,
  ) => {
    const { mode } = this.state;
    const { catalogId, priceGroup, priceGroupAddAllPrices, priceGroupAddPrices, isQuoterMode } = this.props;
    this.setState({
      showAddProducts: false,
      showProductsLoading: allSelected ? 5 : selectedProducts.length,
    });

    const canEdit = mode === 'edit' && priceGroup;

    if (canEdit && selectedProducts.length) {
      EventTrack.track('workspace_pricegroup_update', {
        workspace_id: catalogId,
        pricegroup_id: priceGroup.priceGroupId,
        prices_added: selectedProducts.length,
      });
      priceGroupAddPrices(
        catalogId,
        priceGroup.priceGroupId,
        selectedProducts.map(sellerWorkspaceService.productToPriceGroupItem),
        isQuoterMode,
      );
    } else if (canEdit && allSelected) {
      EventTrack.track('workspace_pricegroup_update', {
        workspace_id: catalogId,
        pricegroup_id: priceGroup.priceGroupId,
        prices_added: 'all',
      });
      priceGroupAddAllPrices(catalogId, priceGroup.priceGroupId, excludedProducts);
    }
  };

  /**
   * Create a price group rtapi call
   */
  private onCreate = () => {
    const { catalogId, history, priceGroupCreate, isQuoterMode } = this.props;
    const { clientsAdded, name } = this.state;
    EventTrack.track('workspace_pricegroup_create', {
      workspace_id: catalogId,
      clients_count: clientsAdded.length,
    });

    priceGroupCreate(
      catalogId,
      name,
      PRICE_GROUP_TYPE.GROUP,
      clientsAdded.map(c => c.userId),
      [],
      'active',
      isQuoterMode,
      (priceGroup?: IPriceGroup) => {
        if (priceGroup) {
          history.replace(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT,
              workspaceId: catalogId + '',
              priceGroupId: priceGroup.priceGroupId,
            }),
          );
        }
      },
    );
  };

  /**
   * Receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return null;
    const { catalogId } = this.props;
    const { searchState } = this.state;
    const { data, toReplace, facets, facetsGlobal } = res;
    if (searchState.text) {
      EventTrack.track('pricegroup_products_search', {
        workspace_id: catalogId,
        results: data?.totalResults || 0,
        text: searchState.text,
      });
    }
    if (toReplace) {
      this.setState({
        products: data.hits,
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
        facets,
        facetsGlobal,
      });
    } else {
      this.setState({
        products: [...this.state.products, ...data.hits],
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
      });
    }
  };
}

const WorkspacePriceGroupEdit: React.FC<IProps> = (props: IProps) => {
  const togglePriceGroupRanksEnabled = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_RANK_PRICEGROUPS));
  return (
    <WorkspacePriceGroupEditPureComponent {...props} togglePriceGroupRanksEnabled={togglePriceGroupRanksEnabled} />
  );
};

export default WorkspacePriceGroupEdit;

const RenderPriceGroupModifyModal = ({
  catalog,
  selectedPriceGroupItems,
  selectedProducts,
  prices,
  onClose,
  onUpdateBulkPrice,
  onUpdateBulkPriceList,
  hideBulkButtons,
  isQuoterMode,
}: {
  catalog: IWorkspace;
  selectedPriceGroupItems: Array<IPriceGroupItem>;
  selectedProducts: Array<IProduct>;
  prices: Record<number, IPrice>;
  onClose: () => void;
  onUpdateBulkPrice: (
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
    priceAdjust: PRICE_ADJUSTMENT_TYPE,
    price: number,
    adjustValue: number,
    modal?: boolean,
  ) => void;
  onUpdateBulkPriceList: (priceGroupItem: Array<IPriceGroupItem>) => void;
  hideBulkButtons: () => void;
  isQuoterMode: boolean;
}) => {
  const [adjustValue, setAdjustValue] = React.useState(0);
  const [adjustType, setAdjustType] = React.useState(
    isQuoterMode ? PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE : PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE,
  );
  const [hasChanges, setHasChanges] = React.useState(false);

  // TO DO - check if there is a better way to obtain the product for price conf

  const productForPriceConf = selectedProducts[0];

  const priceConf = prices?.[productForPriceConf?.id];
  const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
  // TO DO check if we can send an array of pricegroupitems isntead of doing a forEach
  const handleSubmit = () => {
    const tmpObj = [];
    selectedPriceGroupItems.forEach(priceGroupItem => {
      const product = selectedProducts.find(p => p.id === priceGroupItem.productId);
      const price = prices[product?.id]?.price;
      tmpObj.push(onUpdateBulkPrice(priceGroupItem, product, adjustType, price, adjustValue, true));
    });
    onUpdateBulkPriceList(tmpObj);
    onClose();
    hideBulkButtons();
  };
  return (
    <ActionsModal
      minHeight="300px"
      width="500px"
      onClose={onClose}
      title={__('WorkspacePriceGroupEdit.modal.title')}
      subtitle={__('WorkspacePriceGroupEdit.modal.subtitle')}
      overFlowVisible={true}
    >
      <S.ModalRow>
        <Select
          value={adjustType}
          onChange={(name, val) => {
            setAdjustType(val as PRICE_ADJUSTMENT_TYPE);
          }}
          options={
            isQuoterMode
              ? [
                  {
                    value: PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE,
                    label: __('WorkspacePriceGroupEdit.table.margin') + ':',
                  },
                ]
              : getPriceAdjustmentTypes(priceConf?.currency)
          }
          containerMargin="0 6px"
          width="200px"
          disabled={isQuoterMode}
        />
        {adjustType !== PRICE_ADJUSTMENT_TYPE.BASE && adjustType !== PRICE_ADJUSTMENT_TYPE.POR ? (
          <Input
            containerMargin="0 6px"
            name="price-group-name"
            onChange={(key, value) => {
              setAdjustValue(value as number);
              setHasChanges(true);
            }}
            type="number"
            width="200px"
            variableTextSingular={
              [PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE, PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE].includes(adjustType)
                ? '%'
                : null
            }
            startText={
              adjustType === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE
                ? '-'
                : adjustType === PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE
                ? '+'
                : ''
            }
            precision={
              [PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE, PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE].includes(adjustType)
                ? 2
                : pricePrecision
            }
          />
        ) : (
          <S.TextBlack> - </S.TextBlack>
        )}
      </S.ModalRow>
      <S.ModalRow>
        <S.ModalButton type="secondary" onClick={onClose}>
          {__('WorkspacePriceGroupEdit.modal.cancel')}
        </S.ModalButton>
        <S.CtaButton
          disabled={
            [
              PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE,
              PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE,
              PRICE_ADJUSTMENT_TYPE.FIXED,
            ].includes(adjustType) && hasChanges === false
          }
          onClick={handleSubmit}
        >
          {__('WorkspacePriceGroupEdit.modal.cta')}
        </S.CtaButton>
      </S.ModalRow>
    </ActionsModal>
  );
};
