import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FilterSwitch as FS } from '../../atoms';
import { NavigationTabs as NT } from '../../molecules';

interface IStyledProps {
  filtersOpened?: boolean;
  position?: 'top' | 'below';
  theme: ITheme;
  disabled?: boolean;
}

export const OrdersContainer = styled(ColumnContainer)`
  overflow: hidden;
  flex: 1;
  width: 100vw;
`;

export const FilterSwitch = styled(FS)`
  height: 37px;
`;

export const NavigationTabs = styled(NT)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
