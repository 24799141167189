import styled, { ITheme } from '../../../../../styled-components';
import { FontIcon, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
`;

export const TextLink = styled(Text)`
  width: auto;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IssueIcon = styled(FontIcon)`
  font-size: 9px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const LostLineUl = styled.ul`
  margin: ${(p: IStyledProps) => `${p.theme.paddingSize()} ${p.theme.paddingSize(2)}`};
`;

export const LostLine = styled.li`
  list-style: disc;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
