import { BUSINESS_TYPE } from 'common-services';
import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  businessType?: BUSINESS_TYPE;
  selected?: boolean;
  theme: ITheme;
}

export const OptionsContainer = styled(ColumnContainer)`
  align-self: center;
  width: 350px;
`;

export const BusinessContainer = styled(RowContainer)`
  width: 350px;
  border: 1px solid ${(p: IStyledProps) => (p.selected ? p.theme.colors.green1 : p.theme.colors.transparent)};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
  &:hover {
    border: 1px solid ${(p: IStyledProps) => (p.selected ? p.theme.colors.green1 : p.theme.colors.grey3)};
  }
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  cursor: pointer;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const IconContainer = styled(RowContainer)`
  height: 28px;
  width: 28px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  justify-content: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

const getBusinessTypeColor = (p: IStyledProps) => {
  switch (p.businessType) {
    case BUSINESS_TYPE.GROWER:
      return p.theme.colors.blue1;
    case BUSINESS_TYPE.SUPERMARKET:
      return p.theme.colors.green1;
    case BUSINESS_TYPE.POINT_OF_SALE:
      return p.theme.colors.red1;
    default:
      return p.theme.colors.orange1;
  }
};

export const BusinessIcon = styled(FontIcon)`
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${getBusinessTypeColor};
`;

export const TitleText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Semi : p.theme.fontWeight.Regular)};
`;

export const SubtitleText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Regular : p.theme.fontWeight.Light)};
  padding-top: 2px;
`;
