import { LOCALE } from 'common-services';

/**
 * Get "About us" link according to the navigator's language
 */
export function getAboutUsLink(language?: LOCALE) {
  if (language === LOCALE.FR) return 'https://fr.consentio.co/company/a-propos-de-consentio';
  if (language === LOCALE.ES) return 'https://es.consentio.co/empresa/sobre-consentio';
  return 'https://www.consentio.co/company/about-consentio';
}

/**
 * Get "Consentio" link according to the navigator's language
 */
export function getConsentioLink(language?: LOCALE) {
  if (language === LOCALE.FR) return 'https://fr.consentio.co/';
  if (language === LOCALE.ES) return 'https://es.consentio.co/';
  return 'https://www.consentio.co/';
}

/**
 * Get "Ask demo" link according to the navigator's language
 */
export function getAskDemoLink(language?: LOCALE) {
  if (language === LOCALE.FR) return 'https://calendly.com/consentioteam';
  if (language === LOCALE.ES) return 'https://calendly.com/consentioes';
  return 'https://calendly.com/consentioen';
}
