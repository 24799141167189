import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  canSave?: boolean;
  numHeaders?: number;
  withoutMenu?: boolean;
  full?: boolean;
}

const menuWidth = '324px';

export const FormContainer = styled(ColumnContainer)`
  position: relative;
  height: auto;
  max-height: 100%;
  min-width: ${(p: IStyledProps) => (p.full ? `min-content` : '')};
  max-width: ${(p: IStyledProps) => (p.full ? `100%` : `min(100% - ${p.withoutMenu ? '0px' : menuWidth}, 1000px)`)};
  width: ${(p: IStyledProps) => (p.full ? `100%` : `min(100% - ${p.withoutMenu ? '0px' : menuWidth}, 1000px)`)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SaveSection = styled(RowContainer)`
  justify-content: flex-end;
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const SaveButton = styled(Button)`
  margin: 0 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: max-content;
  max-width: 180px;
`;

export const DiscardButton = styled(Button)`
  margin: 0 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: 120px;
`;

export const Sections = styled(ColumnContainer)`
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  margin-left: 0;
  & > :first-child {
    padding-top: 0;
  }
`;
