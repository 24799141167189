import styled, { ITheme } from '../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  isSelected?: boolean;
}

export const ContactContainer = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 3px calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
  margin: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const Icon = styled.div`
  align-self: center;
  padding-right: calc(${(p: IStyledProps) => p.theme.paddingObject} / 2);
`;

export const Info = styled(ColumnContainer)`
  flex: 1;
  justify-content: space-around;
  align-content: flex-start;
  justify-content: center;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoTitle = styled.div`
  flex: 1;
  line-height: ${(p: IStyledProps) => p.theme.paddingObject};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const InfoSubtitle = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${(p: IStyledProps) => p.theme.paddingObject};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const InfoRight = styled(Info)`
  min-width: ${(p: IStyledProps) => p.theme.paddingView};
  max-width: ${(p: IStyledProps) => p.theme.paddingView};
  align-items: flex-end;
  justify-content: center;
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
