import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';
import SimpleSearch from '../../molecules/SimpleSearch';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  link?: string;
}

export const FormContainer = styled.div`
  overflow: hidden;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding-top: 0;
  width: 500px;
`;

export const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh;
  -webkit-overflow-scrolling: touch;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const Contacts = styled(ColumnContainer)``;

export const SectionTitle = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const SubtitleContent = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const SubtitleText = styled.span`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const SubtitleTextPrimary = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const Search = styled(SimpleSearch)`
  margin: 0;
  margin-top: 5px;
`;

export const CTA = styled(Button)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  height: 44px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: absolute;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-shadow: 1px 1px 3px ${(p: IStyledProps) => p.theme.colors.grey1};
  & > .button-icon {
    padding: 0 5px 4px 0;
  }
`;

export const Column = styled(ColumnContainer)``;
export const ActionRow = styled(RowContainer)`
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
