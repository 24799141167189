import { __, IGlobalWeb, qs, constants } from 'common-services';

import Config from '../../bindings/config';

declare var global: IGlobalWeb;

export const CONSENTIO_LOGO_URL = constants.MEDIA_URL + '/f_auto/v1559124638/logos_consentio_460x150.png';

/**
 * Get a deeplink from a url.
 * pricelistOwner optional for having pricelist metadata
 */
export function getDeepLink(
  url: string,
  pricelistOwner?: { name: string; companyName: string; avatar?: string },
  inviteHash?: string,
) {
  if (!Config.DEEPLINK_URL) return url;
  if (!url.startsWith('http')) {
    url = `${Config.WEB_URL}${url}`;
  }
  url = `${url}${url.includes('?') ? '&' : '?'}pu=${window.location.pathname}`;
  if (inviteHash) url = `${url}&h=${inviteHash}`;
  const anonymousId = global.analytics?.user?.().anonymousId();
  if (anonymousId) url = `${url}&a=${anonymousId}`;
  return (
    Config.DEEPLINK_URL.replace('LINK', encodeURIComponent(url)) +
    getDeepLinkMetadata(pricelistOwner) +
    getIpadUrlSupport(url)
  );
}

/**
 * In case of page preview we pass some metadata to the deep link
 */
function getDeepLinkMetadata(pricelistOwner?: { name: string; companyName: string; avatar?: string }) {
  return `&st=${encodeURIComponent(
    pricelistOwner
      ? __('deep_link.pricelist.title', { name: pricelistOwner.name, company: pricelistOwner.companyName })
      : __('deep_link.title'),
  )}&sd=${encodeURIComponent(
    pricelistOwner ? __('deep_link.pricelist.description', { name: pricelistOwner.name }) : __('deep_link.description'),
  )}&si=${encodeURIComponent(
    pricelistOwner && pricelistOwner.avatar ? pricelistOwner.avatar : CONSENTIO_LOGO_URL,
  )}&efr=1`;
}

/**
 * Get Ipad url support parameter
 */
function getIpadUrlSupport(url: string) {
  return `&ipfl=${encodeURIComponent(url)}`;
}

/**
 * Get query params from a link
 */
export function getQueryParams(url: string) {
  const urlSplit = url.split('?');
  if (urlSplit.length < 2) return {};
  return qs.parse(`?${urlSplit[1]}`);
}
