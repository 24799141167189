import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FilterSwitch as FS, FontIcon, RowContainer } from '../../atoms';
import SimpleSearchComponent from '../../molecules/SimpleSearch/SimpleSearch.component';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  selected?: boolean;
}

export const Modal = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.contentHeightWithBreadcrumb};
  width: 100%;
`;

export const Container = styled(ColumnContainer)`
  width: 100%;
  overflow-y: hidden;
`;

export const BodyContainer = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const ListContainer = styled(RowContainer)`
  overflow: auto;
`;

export const Content = styled(ColumnContainer)`
  flex: 1;
`;

export const TitleContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: center;
  align-items: center;
`;

export const TitleText = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TitleBadge = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  color: white;
  padding: 0 7px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  height: 18px;
  justify-content: center;
`;

export const SubtitleContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 20px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SubtitleText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const MainImageContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  justify-content: center;
`;

export const MainImage = styled.img`
  height: 116px;
`;

export const Benefits = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  justify-content: space-between;
`;

export const Benefit = styled(ColumnContainer)`
  flex: 1;
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const BenefitIconWrapper = styled.div`
  height: 60px;
`;

export const BenefitIcon = styled(FontIcon)`
  font-size: 70px;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const BenefitTitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: center;
`;

export const BenefitDescription = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: center;
`;

export const ActionWrapper = styled(ColumnContainer)`
  align-items: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(9)};
`;

export const CTA = styled.div`
  width: 277px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => p.theme.colors.blue1};
  padding: 8px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const CTASubtitle = styled.div`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const MOSwitch = styled(FS)`
  height: 37px;
  width: fit-content;
`;

export const HeaderContainer = styled(RowContainer)`
  position: relative;
  align-items: center;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  background: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: space-between;
`;

export const ReferencesButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
