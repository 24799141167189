// TODO this functions only are used in the app, here they are defined only for compatibility
import { ContactSyncOrigin, IContact, RawContact } from 'common-services';
import { Dispatch } from 'redux';

const contact = {
  createContact: (name: string, phone: string): Promise<boolean> => Promise.resolve(true),
  contactsFromRaw: (rawContacts: Array<RawContact>, country: number) => [],
  contactsAdd: (contacts: Array<IContact>) => undefined,
  contactInvite: () => () => undefined,
  checkPermission: () => Promise.resolve('granted' as any),
  getContacts: (time: number, origin: ContactSyncOrigin) => Promise.resolve([]),
  showPermissionModal: (dispatch: Dispatch<any>) => null,
};
export default contact;
