import { __, constants, IMessage, utils } from 'common-services';
import * as React from 'react';

import { downloadFile } from '../../../../../services/file';
import * as S from './FileMessage.styled';

interface IProps {
  message: IMessage;
  touchFile: (url: string, cb: (data: string) => void) => void;
}

const FileMessage: React.FC<IProps> = ({ message, touchFile }) => {
  const contentType = message.extraData.contentType;
  const fileName = message.extraData.name;
  const mimeTypeLogo: string = constants.getLogoFromMime(message.extraData.contentType);

  if (message.isRemoved)
    return (
      <S.TextWrapper className="file-message-container">
        <S.TextItalic>{__('Components.Chat.message_deleted')}</S.TextItalic>
      </S.TextWrapper>
    );

  return (
    <S.Container className="file-message-container">
      {mimeTypeLogo ? <S.MimeTypeLogo src={mimeTypeLogo} /> : <S.DocumentIcon name="Document" disableHover={true} />}
      <S.TextSizeContainer>
        <S.TextWrap>{utils.cropWithExtension(fileName, 50)}</S.TextWrap>
        <S.DownloadContainer>
          <S.Size>{utils.formatSize(message.extraData.size)} </S.Size>
          <S.Download>{__('Components.FileMessage.Download')}</S.Download>
        </S.DownloadContainer>
      </S.TextSizeContainer>
      <S.Icon
        name="Download"
        onClick={() => touchFile(message.message, data => downloadFile(data, contentType, fileName))}
      />
    </S.Container>
  );
};

export default FileMessage;
