import { ORDER_STATUS } from 'common-services';
import { css } from 'styled-components';

import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, LettersAvatar, RowContainer, TagBubble as TB, TextLabel } from '../../atoms';

interface IStyledProps {
  amSeller?: boolean;
  checked?: boolean;
  isRead?: boolean;
  isSelected?: boolean;
  overlay?: boolean;
  selectable?: boolean;
  showFooter?: boolean;
  status?: ORDER_STATUS;
  theme: ITheme;
}

export const Container = styled.div`
  width: 100%;
  padding-top: 8px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Table = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
  tr:first-child td {
    border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const OrderTr = styled.tr`
  height: 72px;
  max-height: 72px;
  background-color: ${(p: IStyledProps) => (p.isRead ? p.theme.colors.grey4 : p.theme.colors.white)};
  cursor: pointer;
  overflow: hidden;
  td {
    border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }

  & .read {
    display: none;
  }
  &:hover {
    & .read {
      display: block;
      float: right;
    }
  }
  > td {
    max-height: 72px;
  }
`;

// Generic styles for each column (header + rows)
export const FillCol = styled.td`
  min-width: 22px;
  width: 30px;
  max-width: 30px;

  ${(p: IStyledProps) =>
    p.selectable &&
    css`
      width: 60px;
      max-width: 60px;
      padding-left: ${p.theme.paddingSize(2)};
    `};
`;

const Col = styled.td`
  min-width: 70px;
  max-width: 170px;
  vertical-align: middle;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const DateCol = styled(Col)`
  width: 110px;
`;

export const ContactCol = styled(Col)`
  width: 150px;
`;

export const ArticlesCol = styled(Col)`
  width: 80px;
  text-align: center;
  padding-left: 10px;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isRead ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextSmall = styled(Text)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.status === 'canceled' ? p.theme.colors.grey1 : p.theme.colors.black)};
`;

export const TextDate = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextTime = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const FlexColumn = styled(ColumnContainer)``;
export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const ContactImage = styled(LettersAvatar)`
  width: 30px;
  height: 30px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TagBubble = styled(TB)`
  display: inline-block;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};

  .tag-bubble-label {
    padding: 0;
  }
`;
