import {
  __,
  currency,
  date,
  debounce,
  HOUR_FORMAT,
  IContact,
  IFacets,
  IOrderAggregationItem,
  ISearchOrder,
  orderService,
  utils,
} from 'common-services';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { getDefaultColumns } from '../../../../../constants';
import { api } from '../../../../../store';
import { RowContainer } from '../../../../atoms';
import { BubbleColor } from '../../../../atoms/TagBubble/TagBubble.component';
import ContactCell from '../../../ContactCell';
import Table, { IColumn } from '../../../Table/Table.component';
import * as S from '../../AggregationTable.styled';

interface IProps {
  asSeller: boolean;
  myId: number;
  contacts: { [cId: number]: IContact };
  hourFormat: HOUR_FORMAT;
  onClick: (orderHash: string) => void;
  searchState: ISearchOrder;
  footerChildren: React.ReactElement;
}

interface IState {
  hits: Array<IOrderAggregationItem>;
  searchId: string;
  totalResults: number;
  hasMore?: boolean;
  facets: IFacets;
}

// This is not used yet, but it's a good idea to have it in case we want to show a warning when the price is different (Metro usecase)
interface Price {
  id: number;
  price: number;
}

// This is a first implementation of the function that checks if the price is different from the most common price - not used yet
function isPriceDifferent(prices: Array<Price>, myPrice: number): boolean {
  const priceOccurrences: { [key: number]: number } = {};

  // Count the occurrences of each price in the array
  for (const price of prices) {
    if (priceOccurrences[price.price]) {
      priceOccurrences[price.price]++;
    } else {
      priceOccurrences[price.price] = 1;
    }
  }

  // Get the max occurrence in the prices
  const maxOccurrence = Math.max(...Object.values(priceOccurrences));

  // If my price is not the most common price, return true
  if (priceOccurrences[myPrice] !== maxOccurrence) {
    return true;
  }

  return false;
}

export default class GroupList extends React.PureComponent<IProps, IState> {
  private sendSearch = debounce(() => {
    const { myId, searchState } = this.props;
    orderService
      .searchOrderItem(searchState, config.SEARCH_PREFIX, api, myId)
      .then((res: ISearchData<IOrderAggregationItem>) => {
        if (res)
          this.setState({
            ...res.data,
          });
      });
  }, 200);

  private scrollSearch = debounce(
    () =>
      orderService.scrollSearchOrderItem(this.state.searchId, api).then((res: ISearchData<IOrderAggregationItem>) => {
        if (res) this.setState({ hits: [...this.state.hits, ...res.data.hits], hasMore: res.data.hasMore });
      }),
    200,
  );

  constructor(props) {
    super(props);
    this.state = {
      hits: [],
      searchId: '',
      totalResults: 0,
      facets: {},
    };
  }

  public componentDidMount() {
    this.sendSearch();
  }

  public render() {
    const { hasMore, hits } = this.state;
    const { onClick, footerChildren } = this.props;

    // This is not used yet
    // get the prices of the hits less the one of my id
    // const getPrices = function (id: number): Array<Price> {
    //   const hit = hits.find(h => h.id === id);
    //   const hitDate = hit ? new Date(hit.orderDeliveryAt) : '';
    //   const hitYMD = hitDate ? hitDate.toISOString().split('T')[0] : '';
    //   return hits
    //     .filter(h => h.orderDeliveryAt && new Date(h.orderDeliveryAt).toISOString().split('T')[0] === hitYMD)
    //     .map(h => {
    //       return {
    //         id: h.id,
    //         price: h.servedPrice,
    //       };
    //     });
    // };

    return hits ? (
      <Table
        productColumns={getDefaultColumns()}
        selectable={false}
        values={hits}
        // values={hits.map(h => ({ ...h, prices: getPrices(h.id) })) as any} // This is not used yet
        onClickRow={data => onClick(data.orderHash)}
        emptyText={''}
        columns={this.getColumns()}
        footerChildren={
          hasMore ? (
            <tfoot>
              <tr>
                <S.MoreWrapper colSpan={9}>
                  <S.Link onClick={this.scrollSearch}>{__('Components.ProductsList.ShowMore')}</S.Link>
                </S.MoreWrapper>
              </tr>
              {footerChildren}
            </tfoot>
          ) : (
            footerChildren
          )
        }
      />
    ) : null;
  }
  private getColumns(): Array<IColumn> {
    const { asSeller, contacts, searchState, hourFormat, myId } = this.props;
    return [
      {
        title: __('Components.OrdersList.Table.Status'),
        id: 'status',
        element: (data: IOrderAggregationItem) => {
          return (
            <S.StatusTag
              label={__(`Messages.Order.status.${data.status}`)}
              disabled={true}
              color={data.status as BubbleColor}
            />
          );
        },
      },
      {
        title: __('Components.OrdersList.Table.Shipping'),
        id: 'shipping',
        element: (data: IOrderAggregationItem) => {
          return (
            <>
              {data.orderDeliveryAt ? (
                <S.TextBlack>
                  {utils.firstToUpperCase(
                    date.formatLongDate(data.orderDeliveryAt, undefined, undefined, 'EEE, dd MMM yyyy'),
                  )}
                </S.TextBlack>
              ) : null}

              <S.TextBlack>{data.shippingAddress}</S.TextBlack>
            </>
          );
        },
      },
      {
        title: __('Components.OrdersList.Table.size'),
        id: 'size',
        value: (data: IOrderAggregationItem) => {
          return data.size;
        },
      },
      {
        title: __('Components.OrdersList.Table.origin'),
        id: 'origin',
        value: (data: IOrderAggregationItem) => {
          return data.origin === '' ? '-' : data.origin;
        },
      },
      {
        title: __('Components.OrdersList.Table.category'),
        id: 'category',
        value: (data: IOrderAggregationItem) => {
          return data.category;
        },
      },
      {
        title: __('Components.OrdersList.Table.packaging'),
        id: 'packaging',
        value: (data: IOrderAggregationItem) => {
          return data.packaging;
        },
      },
      {
        title: asSeller ? __('Components.OrdersList.Table.client') : __('Components.OrdersList.Table.provider'),
        id: 'client',
        element: (data: IOrderAggregationItem) => {
          const contact = contacts[asSeller ? data.buyerId : data.sellerId];
          if (!contact) return <RowContainer />;

          return (
            <ContactCell
              avatar={contact.companyLogo}
              primaryText={contact.companyName}
              secondaryText={contact.name}
              workingStatus={contact.workingStatus}
            />
          );
        },
      },
      {
        title: __('Components.OrdersList.Table.quantity'),
        id: 'quantity',
        width: '140px',
        element: (data: IOrderAggregationItem) => {
          return <S.TextBig>{orderService.getAmountText(data)}</S.TextBig>;
        },
      },
      {
        title: __('Components.OrdersList.Table.unitPrice'),
        id: 'unit-price',
        width: '140px',
        element: (data: any) => {
          const price =
            data.servedPrice === 0
              ? '-'
              : currency.getPriceAbbreviation(searchState.currency, data.servedPrice) + '/' + data.priceUnit;
          const priceDisplay = data.isPor ? 'P.O.R' : price;

          // This is commented as we're not sure if we want to show the warning, and when
          // const isDifferent = isPriceDifferent(data.prices, data.servedPrice);
          // return isDifferent ? (
          //   <S.TextBigWarning>{priceDisplay}</S.TextBigWarning>
          // ) : (
          //   <S.TextBig>{priceDisplay}</S.TextBig>
          // );
          return <S.TextBig>{priceDisplay}</S.TextBig>;
        },
      },
      {
        title: __('Components.OrdersList.Table.totalPrice'),
        id: 'total-price',
        width: '140px',
        element: (data: IOrderAggregationItem) => {
          const price =
            data.totalPrice === 0 ? '-' : currency.getPriceAbbreviation(searchState.currency, data.totalPrice);
          const priceDisplay = data.isPor ? 'P.O.R' : price;
          return <S.TextBig>{priceDisplay}</S.TextBig>;
        },
      },
    ];
  }
}
