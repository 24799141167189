import {
  __,
  IFile,
  imageActions,
  IModalExtraInfo,
  IModalName,
  ISection,
  IUser,
  IWorkspace,
  LOCALE,
  ModalActions,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  tagActions,
} from 'common-services';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RouteComponentProps } from 'react-router-dom';

import { getLanguageLiteral, ROUTE_PATHS } from '../../../constants';
import Workspace from '../Workspace/Workspace.component';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { Ribbon, Select, SimpleDropdown, Switch } from '../../atoms';
import { FormContainer, FormMenu, FormSection } from '../../molecules';
import { Sections } from '../../molecules/FormMenu/FormMenu.component';
import SectionLine from './Fragments/SectionLine.component';
import * as S from './WorkspaceSections.styled';

export interface IStateProps {
  catalog: IWorkspace;
  me: IUser;
  tags: Array<string>;
}

export interface IDispatchProps {
  catalogUpdate: typeof sellerWorkspaceActions.catalogUpdate;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  touchImage: typeof modalActions.touchImage;
  tagsGet: typeof tagActions.tagsGet;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string }>;

interface IState {
  errors: Map<string, string | (() => JSX.Element)>;
  newCatalog: IWorkspace;
  hasChanges: boolean;
  viewing: string;
}

class WorkspaceSections extends React.PureComponent<IProps, IState> {
  private t: number;
  private sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private showRef: React.RefObject<HTMLDivElement> = React.createRef();
  private manageRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      errors: new Map(),
      newCatalog: props.catalog,
      hasChanges: false,
      viewing: 'show',
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceSections', { renderTime: this.t });
    const { catalog, me, tagsGet } = this.props;
    tagsGet(me?.id, catalog?.id);
    const offset = 150;
    if (this.sectionsRef.current)
      this.sectionsRef.current.addEventListener('scroll', () => {
        if (this.sectionsRef.current.scrollTop + offset >= this.manageRef?.current?.offsetTop) {
          this.setState({ viewing: 'manage' });
        } else if (this.sectionsRef.current.scrollTop + offset >= this.showRef?.current?.offsetTop) {
          this.setState({ viewing: 'show' });
        }
      });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { tagsGet, me, catalog } = this.props;
    if (!prevProps.catalog && catalog) {
      this.setState({ newCatalog: catalog });
      tagsGet(me?.id, catalog?.id);
    }
  }

  public render() {
    const {
      catalog,
      catalogUpdate,
      history,
      mediaUpload,
      location,
      match,
      me,
      modalClose,
      modalOpen,
      notificationShow,
      tags,
    } = this.props;
    const { hasChanges, newCatalog, viewing } = this.state;
    const amEditor = catalog && sellerWorkspaceService.getRole(catalog, me.id) !== 'viewer';
    if (!newCatalog) return null;
    const hideSections = !amEditor && !catalog.sections.length;
    return (
      <Workspace
        parentSections={[
          {
            label: __('Components.Header.WorkspaceProducts'),
            action: () =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                  workspaceId: catalog.id + '',
                }),
              ),
          },
        ]}
        subtitle={''}
        title={__('Components.Header.WorkspaceSections')}
        tabSelected="products"
        workspaceId={catalog.id}
      >
        {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
        <S.Container>
          <FormMenu
            sections={this.getFormSections(hideSections)}
            selected={viewing}
            onSelect={(s: string) => this.setState({ viewing: s })}
          />
          <FormContainer
            className="TeamSetting-FormContainer"
            canSave={hasChanges}
            save={() => {
              catalogUpdate(me.id, newCatalog, error => {
                if (error) {
                  return notificationShow({
                    title: __('WorkspaceSections.error'),
                    subtitle: __('WorkspaceSections.error_description'),
                    closable: true,
                    style: 'error',
                  });
                }
                return notificationShow({
                  title: __('WorkspaceSections.success'),
                  subtitle: __('WorkspaceSections.success_description'),
                  closable: true,
                  style: 'info',
                });
              });
              this.setState({ hasChanges: false });
            }}
            sectionsRef={this.sectionsRef}
          >
            <>
              <Show
                editMode={amEditor}
                catalog={newCatalog}
                updateCatalog={this.updateCatalog}
                showRef={this.showRef}
              />
              {hideSections ? null : (
                <Manage
                  editMode={amEditor}
                  manageRef={this.manageRef}
                  catalog={newCatalog}
                  updateCatalog={this.updateCatalog}
                  modalOpen={modalOpen}
                  modalClose={modalClose}
                  tags={tags}
                  mediaUpload={mediaUpload}
                />
              )}
            </>
          </FormContainer>
        </S.Container>
        {this.renderTutorial()}
      </Workspace>
    );
  }

  /**
   * Render the tags tutorial.
   */
  private renderTutorial() {
    // TODO Temporal disabled
    // return <Tutorial name="tags" isExplanation={true} />;
    return null;
  }

  /**
   * Get form sections
   */
  private getFormSections(hideManageSections: boolean) {
    const result: Sections = [
      {
        id: 'show',
        title: __('WorkspaceSections.Menu.Show.title'),
        description: __('WorkspaceSections.Menu.Show.description'),
        icon: 'Eye-see',
      },
    ];
    if (hideManageSections) return result;
    result.push({
      id: 'manage',
      title: __('WorkspaceSections.Menu.Manage.title'),
      description: __('WorkspaceSections.Menu.Manage.description'),
      icon: 'Edit',
    });
    return result;
  }

  /**
   * Change the local state of user with the changes in the form.
   */
  private updateCatalog = (catalog: IWorkspace) => {
    this.setState({
      hasChanges: true,
      newCatalog: catalog,
    });
  };
}

export default WorkspaceSections;

const Show = React.memo(
  ({
    catalog,
    editMode,
    showRef,
    updateCatalog,
  }: {
    catalog: IWorkspace;
    editMode: boolean;
    showRef: React.RefObject<HTMLDivElement>;
    updateCatalog: (catalog: IWorkspace) => void;
  }) => {
    return (
      <FormSection id="show" title={__('Components.Settings.sections.title')} sectionRef={showRef}>
        {editMode ? <S.TextDescription>{__('Components.Settings.sections.description')}</S.TextDescription> : null}
        <S.SwitchRow>
          <S.SwitchLabel disabled={!editMode}>
            {__('Components.Settings.sections.switch_label')}
            {!editMode ? ':' : ''}
          </S.SwitchLabel>
          <Switch
            name="settings"
            isChecked={!!catalog.sectionsEnabled}
            onChange={(n, checked) => updateCatalog({ ...catalog, sectionsEnabled: checked })}
            disabled={!editMode}
            selectable={false}
          />
        </S.SwitchRow>
      </FormSection>
    );
  },
);

const Manage = React.memo(
  ({
    catalog,
    editMode,
    mediaUpload,
    manageRef,
    modalOpen,
    modalClose,
    tags,
    updateCatalog,
  }: {
    catalog: IWorkspace;
    editMode: boolean;
    mediaUpload: (image: IFile, cb: (data: any) => void) => void;
    updateCatalog: (catalog: IWorkspace) => void;
    manageRef: React.RefObject<HTMLDivElement>;
    modalClose: () => ModalActions;
    modalOpen: (text: string, action: any, extra?: IModalExtraInfo, name?: IModalName) => ModalActions;
    tags: Array<string>;
  }) => {
    const sections = catalog ? catalog.sections : [];
    const [newSections, setNewSections] = React.useState<Array<ISection>>(
      sections.sort((s1, s2) => s1.position - s2.position),
    );
    const [languagesAdded, setLanguagesAdded] = React.useState<Array<LOCALE>>(
      sections.reduce((acc, section) => {
        Object.keys(section.translations).forEach(locale => {
          if (!acc.includes(locale)) acc.push(locale);
        });
        return acc;
      }, []),
    );
    const tagsOptions = tags
      .reduce((acc, tag) => {
        if (!newSections.find(s => s && s.tag === tag)) acc.push(tag);
        return acc;
      }, [])
      .sort();
    const languages = Object.values(LOCALE)
      .filter(l => !languagesAdded.includes(l))
      .map(l => ({
        key: l,
        value: getLanguageLiteral(l),
      }))
      .sort();
    return (
      <FormSection
        hasOverflowX={!!newSections.length}
        id="manage"
        numHeaders={2}
        sectionRef={manageRef}
        title={__('WorkspaceSections.Menu.Manage.title')}
      >
        <S.SectionsInfoCol>
          {editMode ? <S.TextPlaceholder>{__('Components.Settings.sections.input_label')}</S.TextPlaceholder> : null}
          <Select
            name="tags"
            containerMargin="0 0 15px 0"
            width="55%"
            value=""
            options={tagsOptions}
            noOptionsMessage={__('Components.Settings.sections.no_tag_available')}
            disabled={!editMode}
            onChange={(name, value) => {
              const newSectionsCopy = newSections.slice();
              newSectionsCopy.push(sellerWorkspaceService.sectionNew(value, newSections.length + 1, catalog?.id || 0));
              setNewSections(newSectionsCopy);
              updateSectionsInCatalog(catalog, newSectionsCopy, updateCatalog);
            }}
            placeholder={__('Components.Settings.sections.input_placeholder')}
          />
          {editMode ? (
            <S.TextDescription>
              {newSections.length
                ? __('Components.Settings.sections.input_description')
                : tagsOptions
                ? __('Components.Settings.sections.empty_section')
                : __('Components.Settings.sections.empty_tag')}
            </S.TextDescription>
          ) : null}
        </S.SectionsInfoCol>
        {newSections.length ? (
          <S.SectionsTabCol>
            <S.SectionRow padding={`5px 0 5px ${editMode ? '68px' : '45px'}`}>
              <S.HeaderCell>
                <S.HeaderText>{__('Components.Settings.sections.tag')}</S.HeaderText>
              </S.HeaderCell>
              <S.ImageCell />
              {languagesAdded.sort().map(locale => (
                <S.HeaderCell key={locale}>
                  <S.HeaderText>{getLanguageLiteral(locale)}</S.HeaderText>
                  <SimpleDropdown
                    onSelect={(name: string) => {
                      if (name === 'remove') {
                        onDeleteLanguage(locale);
                      }
                    }}
                    width="80px"
                    options={[{ value: __('Components.ProductsList.Remove'), key: 'remove' }]}
                  >
                    <S.KebabIcon name="Kebab" />
                  </SimpleDropdown>
                </S.HeaderCell>
              ))}
              <S.OptionsCell>
                {editMode ? (
                  <SimpleDropdown
                    hAlign="right"
                    onSelect={(name: string) => {
                      const languagesAddedCopy = languagesAdded.slice();
                      languagesAddedCopy.push(name as LOCALE);
                      setLanguagesAdded(languagesAddedCopy);
                    }}
                    options={languages}
                  >
                    <S.TranslateCta iconName="Plus">{__('Components.Settings.sections.translate')}</S.TranslateCta>
                  </SimpleDropdown>
                ) : null}
              </S.OptionsCell>
            </S.SectionRow>
            <DndProvider backend={HTML5Backend}>
              {newSections.map((section, index) => (
                <SectionLine
                  key={section.tag}
                  editMode={editMode}
                  index={index}
                  onAddImage={file => onAddSectionImage(file, section, index)}
                  onDelete={() => onDeleteSection(index)}
                  onTranslate={(locale, value) => {
                    const newSectionsCopy = newSections.slice();
                    const sectionCopy = { ...section };
                    sectionCopy.translations[locale] = value;
                    newSectionsCopy[index] = sectionCopy;
                    setNewSections(newSectionsCopy);
                    updateSectionsInCatalog(catalog, newSectionsCopy, updateCatalog);
                  }}
                  languages={languagesAdded}
                  moveSection={moveSection}
                  section={section}
                />
              ))}
            </DndProvider>
          </S.SectionsTabCol>
        ) : null}
      </FormSection>
    );

    /**
     * Asks confirmation modal to delete a section
     */
    function onDeleteSection(index: number) {
      modalOpen(__('Components.Settings.sections.confirmation_delete'), () => {
        const newSectionsCopy = newSections.slice();
        newSectionsCopy.splice(index, 1);
        setNewSections(newSectionsCopy);
        updateSectionsInCatalog(catalog, newSectionsCopy, updateCatalog);
        modalClose();
      });
    }

    /**
     * Asks confirmation modal to delete a language
     */
    function onDeleteLanguage(locale: LOCALE) {
      modalOpen(__('Components.Settings.sections.confirmation_delete_language'), () => {
        const languagesAddedCopy = languagesAdded.slice();
        languagesAddedCopy.splice(languagesAddedCopy.indexOf(locale), 1);
        setLanguagesAdded(languagesAddedCopy);
        const newSectionsCopy = newSections.slice();
        newSectionsCopy.forEach(section => {
          delete section.translations[locale];
        });
        updateSectionsInCatalog(catalog, newSectionsCopy, updateCatalog);
        modalClose();
      });
    }

    /**
     * Move a section by dragging
     */
    function moveSection(dragIndex: number, hoverIndex: number) {
      newSections.splice(hoverIndex, 0, newSections.splice(dragIndex, 1)[0]);
      setNewSections(newSections);
      updateSectionsInCatalog(catalog, newSections, updateCatalog);
    }

    /**
     * Add image to a section
     */
    function onAddSectionImage(file: File, section: ISection, index: number) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = event =>
        logError(
          new Error('File could not be read! Code ' + (event.target as any).error.code),
          `upload.section.${section.tag}`,
        );
      reader.onload = () =>
        reader.result &&
        resizeImage(reader.result as string, (imageResized: string) =>
          mediaUpload({ ...file, name: file.name, content: imageResized }, result => {
            const newSectionsCopy = newSections.slice();
            newSectionsCopy[index].imageUrl = result.secure_url;
            setNewSections(newSectionsCopy);
            updateSectionsInCatalog(catalog, newSectionsCopy, updateCatalog);
          }),
        );
    }
  },
);
/**
 * Update sections to my catalog
 */
function updateSectionsInCatalog(
  catalog: IWorkspace,
  sections: Array<ISection>,
  updateCatalog: (catalog: IWorkspace) => void,
) {
  for (let i = 0; i < sections.length; i++) {
    sections[i].position = i + 1;
  }
  updateCatalog({ ...catalog, sections: [...sections] });
}
