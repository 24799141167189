import styled, { ITheme } from '../../../../../styled-components';
import {
  ArrowPercentage as AP,
  Button,
  CheckBox,
  ColumnContainer,
  FontIcon,
  Input,
  RowContainer,
  SimpleDropdown,
  TextLabel,
  Tooltip,
} from '../../../../atoms';
import { Table } from '../../../../molecules';

interface IStyledProps {
  theme: ITheme;
  align?: string;
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  margin?: string;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  width?: string;
}

export const SubText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

const Text = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

const Td = styled.td`
  vertical-align: middle;
  text-align: left;
`;

export const InfoIconBlack = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const AmountInput = styled(Input)`
  border: ${(p: IStyledProps) =>
    `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.grey2}`};
  &:focus-within {
    border: ${(p: IStyledProps) =>
      `1px solid ${p.hasError ? (p.isIssue ? p.theme.colors.orange1 : p.theme.colors.red1) : p.theme.colors.green1}`};
  }
`;

const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const ItemPrice = styled(Text)`
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) =>
    p.showGrey ? p.theme.colors.grey2 : p.isCancelled ? p.theme.colors.grey1 : p.theme.colors.black};
  text-align: right;
  flex: none;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: auto;
`;

const getMarginColor = (p: IStyledProps) => {
  if (!p.baseMarginPercentage || !p.marginPercentage) {
    return p.theme.colors.black;
  }
  return p.marginPercentage.toFixed(2) >= p.baseMarginPercentage.toFixed(2)
    ? p.theme.colors.green1
    : p.theme.colors.red1;
};

export const MarginPercentage = styled(Text)`
  font-weight: ${(p: IStyledProps) => (p.baseMarginPercentage ? p.theme.fontWeight.Regular : p.theme.fontWeight.Bold)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${getMarginColor};
  flex: none;
  display: inline;
  width: auto;
`;

export const ItemPriceTotal = styled(ItemPrice)`
  float: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const AmountRow = styled(RowContainer)`
  align-items: start;
  align-self: center;
  cursor: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const PriceRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

export const PriceColumn = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  justify-content: flex-start;
  align-items: flex-start;
`;
export const PriceGroupColumn = styled(PriceColumn)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const PriceEditRow = styled(RowContainer)`
  cursor: ${(p: IStyledProps) => (p.enabled ? 'pointer' : 'default')};
  align-self: center;
  justify-content: flex-start;
`;

export const ProductPriceRow = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const QuantityRow = styled(RowContainer)`
  align-items: center;
`;
export const DeleteContainer = styled(RowContainer)`
  justify-content: flex-start;
`;
export const Deleted = styled(Text)`
  width: auto;
`;
export const Undo = styled(TextLink)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const Close = styled.div`
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const IconWrapper = styled.div`
  width: auto;
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;

export const TextGrey = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const Reference = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const BlueText = styled(TextLabel)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ReferenceCol = styled(ColumnContainer)``;

export const PriceGroupContainer = styled(ColumnContainer)``;

export const ItemsTable = styled(Table)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  .table-header-container > tr > th {
    background-color: ${(p: IStyledProps) => p.theme.colors.transparent};
  }
`;
