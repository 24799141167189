import { XYCoord } from 'dnd-core';
import * as React from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Cell, IColumn } from '../Table/Table.component';
import * as S from './DNDRow.styled';

export interface IProps {
  id: string;
  index: number;
  indexText?: string;
  onDrag: (id: string, hoverIndex: number) => void;
  onDrop: () => void;
  onDelete: () => void;
  name: string;
  editMode: boolean;
  data: object;
  cursor?: string;
  onClick: (data: Record<string, any>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  columns: Array<IColumn>;
  rowIdx: number;
  isReadRow?: boolean;
  isLoading?: boolean;
  selectable: boolean;
}

interface DragSection extends ISection {
  id: string;
  index: number;
  type: string;
}

const DNDRow: React.FC<IProps> = ({
  id,
  editMode,
  index,
  onDrag,
  onDrop,
  onDelete,
  name,
  indexText,
  data,
  onClick,
  columns,
  cursor,
  rowIdx,
  isReadRow,
  isLoading,
  selectable,
}) => {
  const dragRef = React.useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop({
    accept: 'section',
    drop: onDrop,
    hover(item: DragSection, monitor: DropTargetMonitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;

      // Use a smaller fraction of the element's height for more sensitivity
      const hoverSensitivity = hoverHeight * 0.25; // 25% of the height

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverSensitivity) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverHeight - hoverSensitivity) {
        return;
      }

      // Time to actually perform the action
      onDrag(item.id, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: { type: 'section', name, index, id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // if the row is in edit mode, we want to be able to drag it and drop it
  if (editMode) drag(drop(dragRef));

  return (
    <S.Tr
      isReadRow={isReadRow}
      isDragging={editMode && isDragging}
      onClick={e => (isLoading ? undefined : onClick(data, e))}
      cursor={cursor}
      ref={dragRef}
    >
      {columns.map((c, i) => (
        <Cell
          key={c.id + '_' + i}
          isLoading={isLoading}
          columns={columns}
          c={c}
          data={data}
          selectable={selectable}
          idx={i}
          rowIdx={rowIdx}
        />
      ))}
    </S.Tr>
    // </S.SectionRow>
  );
};

export default React.memo(DNDRow);
