import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  disabled?: boolean;
  isImage?: boolean;
  withoutCategories?: boolean;
}

export const Container = styled(ColumnContainer)`
  margin: 35px;
  max-height: min(${(p: IStyledProps) => p.theme.windowHeight}, 600px);
  padding: 5px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const Row = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled(ColumnContainer)`
  justify-content: center;
  align-items: space-between;
  margin-top: ${(p: IStyledProps) => p.theme.paddingView};
  padding: ${(p: IStyledProps) => p.theme.paddingObject} ${(p: IStyledProps) => p.theme.paddingView};
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;

export const Description = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
`;
export const DescriptionText = styled.span`
  align-self: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const Img = styled.img`
  max-width: min(100%, ${(p: IStyledProps) => (p.isImage ? '550px' : '100px')});
  max-height: min(${(p: IStyledProps) => p.theme.windowHeight} - 150px, 500px);
  display: block;
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  align-self: center;
`;

export const SendButton = styled(RowContainer)`
  justify-content: center;
  align-items: center;
  align-self: ${(p: IStyledProps) => (p.withoutCategories ? 'flex-end' : 'auto')};
  margin-bottom: ${(p: IStyledProps) => (p.withoutCategories ? '15px' : '0')};
  height: 40px;
  width: 40px;
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  border-radius: 50%;
  opacity: ${(p: IStyledProps) => (p.disabled ? 0.3 : 1)};
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
`;
export const SendIcon = styled(FontIcon)`
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-top: 2px;
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
`;
export const SendText = styled.span`
  align-self: flex-end;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

export const DocumentIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 95px;
  width: 100px;
  height: 100px;
  line-height: 80px;
  padding-left: 1px;
  align-self: center;
`;

export const GalleryIcon = styled(DocumentIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SelectWrapper = styled(RowContainer)`
  flex: 1;
`;
