import config from '../../../../../../bindings/config';
import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';
import { InputWithLabel as IWL } from '../../../../molecules';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  enabled?: boolean;
}

export const Text = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

const TextTable = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextPrice = styled(TextTable)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const TextGrey1 = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const ValueSpan = styled.span`
  width: 110px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: auto;
`;

export const DeleteIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
`;

export const EditIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const EditContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ActionLink = styled(Button)`
  padding: 0;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const InputWithLabel = styled(IWL)``;

export const FormContainer = styled(ColumnContainer)`
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const ValueRow = styled(RowContainer)`
  justify-content: flex-end;
  align-items: center;
  width: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FooterRow = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: flex-end;
  width: auto;
`;
