import { ICatalogRole, IClient, IContact, ILightContact, IUser, sellerWorkspaceService, utils } from 'common-services';
import * as React from 'react';

import MiniImages from '../MiniImages';

export interface IProps {
  className?: string;
  clients?: Array<IClient>;
  contacts: { [cId: number]: IContact };
  disableHover?: boolean;
  me: IUser;
  members: Array<{ userId: number; role?: ICatalogRole; name?: string }>;
  mode?: 'images' | 'icon';
  numOfMembers?: number;
  onClick?: () => void;
  showZeroCase?: boolean;
  size?: number;
}

const Members: React.FC<IProps> = ({
  clients = [],
  contacts,
  me,
  members,
  mode,
  numOfMembers,
  onClick = () => null,
  showZeroCase,
}) => {
  const meAsContact: ILightContact = {
    avatar: me.settings.avatar,
    avatarColor: utils.getAvatarColor(me.name),
    name: me.name,
    phoneNumbers: [me.phone + ''],
    companyName: me.companyName,
    id: me.id,
    isUnregistered: false,
  };

  let people: Array<ILightContact> = members.map(m => {
    let person = m.userId === me.id ? meAsContact : contacts[m.userId];
    if (!person && clients.length) {
      const client = clients.find(c => c.userId === m.userId);
      person = client ? sellerWorkspaceService.clientToLightContact(client) : undefined;
    }
    if (person && !person.name) {
      person.name = m.name;
    }
    if (!person) {
      person = {
        avatarColor: utils.getAvatarColor(m.name),
        companyName: '',
        id: m.userId,
        isUnregistered: false,
        name: m.name,
        phoneNumbers: [],
      };
    }
    return person;
  });
  people = people.sort((a, b) => {
    if (a.name && b.name) {
      if (a.name === b.name) return 0;
      return a.name > b.name ? 1 : -1;
    }
    return a.id > b.id ? 1 : -1;
  });
  const membersToDisplay = people.length > 4 ? 4 : people.length;

  return (
    <MiniImages
      count={numOfMembers || people.length}
      mode={mode}
      onImageClick={onClick}
      persons={people.slice(0, membersToDisplay)}
      showZeroCase={showZeroCase}
    />
  );
};

export default React.memo(Members);
