import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  hasAtLeastOneImage?: boolean;
  isBold?: boolean;
  margin?: string;
  theme: ITheme;
}
const IMAGE_SIZE = 68;

export const Container = styled.div`
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
`;

export const Global = styled(Container)`
  justify-content: center;
`;

export const ContainerColumn = styled(Container)`
  flex-direction: column;
  padding-top: ${(p: IStyledProps) => p.theme.paddingObject};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingObject};
  align-items: flex-start;
`;

export const ContainerRow = styled(Container)`
  align-items: flex-start;
  @media only screen and (max-width: 640px) {
    flex-direction: column-reverse;
  }
`;

export const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isBold ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  display: inline;
`;

export const TextShadow = styled(Text)`
  background: ${(p: IStyledProps) => p.theme.colors.light1};
  display: inline-block;
  padding: 0 6px;
  margin-bottom: 2px;
  border-radius: 12px;
`;

export const TextP = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => (p.isBold ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
`;

export const TextGreen = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  display: inline;
`;

export const Title = styled(TextLabel)`
  width: 100%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  line-height: 1.6;
`;

export const TitleOrderInfo = styled(Title)`
  padding: ${(p: IStyledProps) => p.theme.paddingView};
`;

export const TitleGreen = styled(Title)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
`;

export const SubTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const SubTitleDivider = styled(TextLabel)`
  flex: 1;
  border-top: 2px solid ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.7)')};
  height: 2px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingView};
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;

export const Image = styled.img`
  height: ${IMAGE_SIZE};
  width: ${IMAGE_SIZE};
  object-fit: cover;
  max-width: ${IMAGE_SIZE};
`;

export const Icon = styled.div`
  align-self: center;
  padding: 0 5px;
`;

export const Info = styled.div`
  flex: 1;
  justify-content: space-around;
  display: flex;
  align-content: flex-end;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  text-align: right;
`;

export const InfoTitle = styled.p`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 20px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    line-height: 16px;
    font-size: 12px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
    font-size: 12px;
  }
`;

export const InfoSubtitle = styled.p`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    line-height: 16px;
    font-size: 12px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
    font-size: 12px;
  }
`;

export const Td = styled.td`
  vertical-align: top;
  padding: ${(p: IStyledProps) => p.theme.paddingObject} 0 ${(p: IStyledProps) => p.theme.paddingObject} 0;
`;

export const PriceDetailsLeft = styled(Td)`
  width: 75%;
  text-align: right;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const PriceDetailsRight = styled(Td)`
  width: 25%;
  text-align: right;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const ProductPrice = styled(Td)`
  width: 22%;
  text-align: right;
`;

export const ProductDescription = styled(Td)`
  width: ${(p: IStyledProps) => (p.hasAtLeastOneImage ? '63%' : '78%')};
  vertical-align: top;
`;

export const ImageTd = styled(Td)`
  vertical-align: top;
  width: 15%;
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const FontIconWrapper = styled.div`
  font-size: 30px;
  margin-top: 6px;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
`;

export const IconTd = styled(Td)`
  width: 40px;
`;

export const DescriptionTd = styled(Td)``;

export const TextTitle = styled(TextLabel)`
  width: auto;
  text-transform: 'uppercase';
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-bottom: 2px;
`;

export const TextSmall = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

const TextRegular = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const TextGreyRegular = styled(TextRegular)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TextBold = styled(TextLabel)`
  width: auto;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const Logo = styled.div`
  display: flex;
`;

export const ImageLogo = styled.img`
  max-width: 260px;
  max-height: 75px;
  align-self: center;
`;

export const Consentio = styled.div`
  display: flex;
  align-items: center;
  margin: ${(p: IStyledProps) => p.theme.paddingView} 0;
  max-height: 150px;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ConsentioLogo = styled.div`
  display: flex;
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-image: url('https://media.consentio.co/image/upload/v1589440097/logos/logo.svg');
  background-size: cover;
  align-self: center;
  border-radius: 13px;
  margin: 0 11px;
`;

export const ContainerStores = styled(Container)`
  max-width: 190px;
  @media only screen and (max-width: 640px) {
    justify-ontent: center;
    padding-top: ${(p: IStyledProps) => p.theme.paddingObject};
  }
`;

export const Stores = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const StoresText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  line-height: 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const FontIconWrapperStore = styled.div`
  font-size: 20px;
  height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  margin: 0 5px 0 ${(p: IStyledProps) => p.theme.paddingObject};
`;

export const LeftColumn = styled.div`
  width: 65%;
  padding: ${(p: IStyledProps) => p.theme.paddingView};

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  width: 35%;
  padding: ${(p: IStyledProps) => p.theme.paddingView};

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const Header = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
`;

export const OrderButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingObject};
  height: 29px;
  padding: 0 35px;
  width: 100%;
`;

export const CommentsContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingView};
  width: 100%;
`;
