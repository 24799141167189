import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}
export const Container = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;

export const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

export const CardContainer = styled(Container)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: calc(100% - 40px);
  align-self: flex-start;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  max-width: 1000px;
  overflow: hidden;
`;

export const CreateButton = styled(Button)`
  margin: 0 0 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const KebabIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
