import {
  __,
  addresses,
  countrySelectors,
  currency,
  CURRENCY_CODES,
  date,
  IOrderType,
  notificationsActions,
  orderActions,
  orderService,
  RenderTrack,
  sellerWorkspaceActions,
  utils,
} from 'common-services';
import { MINIMUM_ORDER_PRICE, PRICE_PRECISION } from 'common-services/dist/constants';
import { getProductsPrice } from 'common-services/dist/order/service';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { getDefaultColumns, ROUTE_PATHS } from '../../../constants';
import OrderDetails from '../../../screens/order-details';
import ShowroomBuy from '../../../screens/showroom-buy';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { LettersAvatar } from '../../atoms';
import { EmptyListResource } from '../../molecules';
import Table, { IColumn } from '../../molecules/Table/Table.component';
import Workspace from '../Workspace/Workspace.component';
import ItemsCard from './Fragments/ItemsCard';
import * as S from './MultipleCarts.styled';

export type IOwnProps = RouteComponentProps<{ workspaceId: string; key: string }>;
export interface IStateProps {
  cart: Record<string, ICart>;
  me: IUser;
  contacts: Record<number, IContact>;
  orders: Array<IOrder>;
  type?: 'sale' | 'purchase';
}

export interface IDispatchProps {
  orderGenerateNew: typeof orderActions.orderGenerateNew;
  orderNew: typeof orderActions.orderNew;
  notificationShow: typeof notificationsActions.notificationShow;
}
interface IState {
  orderSelected?: IOrder;
  sendOrder?: boolean;
  showroomSelected?: boolean;
  isSearched?: boolean;
}
type IProps = IStateProps & IDispatchProps & IOwnProps;

export default class MultipleCarts extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {};
  }
  public componentDidMount() {
    RenderTrack.track('MultipleCarts', {
      renderTime: this.t,
    });
    const {
      cart,
      me,
      match: {
        params: { key },
      },
      orderGenerateNew,
    } = this.props;

    if (this.props.match.path.endsWith('key')) {
      const data = cart[key];
      orderGenerateNew(me, data, os => this.setState({ orderSelected: os }));
    }
  }
  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { contacts, notificationShow, orders, orderNew, me, type } = this.props;
    const { orderSelected } = this.state;
    if (prevState.orderSelected !== orderSelected && orderSelected) {
      this.openOrderSelected(orderSelected);
    }
    if (!prevState.sendOrder && this.state.sendOrder) {
      orderNew(
        orders.find(o => o?.id === 0),
        me?.id,
        (newOrder: IOrder, err: Error) => {
          if (err) return;
          this.setState({ sendOrder: false }, () =>
            notificationShow({
              title: __('Components.OrderDetails.success_create.title', {
                hashId: newOrder.externalIdSeller || newOrder.externalIdBuyer || '#' + newOrder.hashId,
              }),
              closable: true,
              subtitle: __('Components.OrderDetails.success_create.description', {
                name: contacts[type === 'purchase' ? newOrder.sellerId : newOrder.buyerId]?.name || '',
              }),
              style: 'success',
            }),
          );
        },
      );
    }
    if (!this.props.match.params.hasOwnProperty('key')) {
      this.setState({ orderSelected: undefined });
    }
  }
  public render() {
    const { cart, type, contacts, orderGenerateNew, me, match, history } = this.props;
    const { orderSelected } = this.state;
    const unsentOrders = Object.values(cart).filter(el => el.orderId === 0);
    const buyerCarts = unsentOrders.filter(el => {
      return el.asSeller === false && el.contactId && el.items.find(item => item.amount > 0);
    });
    const sellerCarts = unsentOrders.filter(el => {
      return el.asSeller === true && el.contactId && el.items.find(item => item.amount > 0);
    });
    const columns: Array<IColumn> = [
      {
        title:
          type === ('purchase' as IOrderType)
            ? __('Components.MultipleCarts.provider')
            : __('Components.MultipleCarts.client'),
        id: 'provider',
        element: (data: ICart) => {
          const contact = contacts[data.contactId];
          if (!contact) return null;
          return (
            <S.FlexRow>
              <S.ContactImagesRow>
                <LettersAvatar
                  size={35}
                  text={contact.name}
                  img={contact.avatar}
                  avatarColor={{ background: '', text: '' }}
                  type={type}
                />
              </S.ContactImagesRow>
              <S.ClientNameColumn>
                <S.TextBold>{contact.companyName}</S.TextBold>
                <S.TextBlack>{contact.name}</S.TextBlack>
              </S.ClientNameColumn>
            </S.FlexRow>
          );
        },
      },
      {
        title: __('Components.MultipleCarts.items'),
        id: 'items',
        element: (data: ICart) => (
          <S.FlexRow>
            <S.ClientNameColumn>
              <S.TextNumber data-testid="edit-order">{data.items.filter(i => i.amount).length}</S.TextNumber>
            </S.ClientNameColumn>
          </S.FlexRow>
        ),
      },
      {
        title: __('Components.MultipleCarts.transportation'),
        id: 'transportation',
        element: (data: ICart) => (
          <S.FlexRow>
            <S.ClientNameColumn>
              <S.TextBlack>
                {data?.deliveryAddress?.type ? utils.firstToUpperCase(data.deliveryAddress.type) : null}
                {data.deliveryAddress?.type ? ' - ' : null}
                {data.deliveryAddress?.city}
                {data.deliveryAddress?.city ? ', ' : null}
                {data.deliveryAddress?.zip}
              </S.TextBlack>
            </S.ClientNameColumn>
          </S.FlexRow>
        ),
      },
      {
        title: __('Components.MultipleCarts.comments'),
        id: 'comments',
        element: (data: ICart) => (
          <S.FlexRow>
            <S.ClientNameColumn>
              <S.TextCentered>{data.initialComments.length}</S.TextCentered>
            </S.ClientNameColumn>
          </S.FlexRow>
        ),
      },
      {
        title: __('Components.MultipleCarts.total_cost'),
        id: 'total-cost',
        element: (data: ICart) => {
          let isHidden = true;
          const result = data.items.reduce((acc, item) => {
            if (item.totalPrice !== 0) isHidden = false;
            acc += item.totalPrice;
            return acc;
          }, 0);
          let priceCurrency = '';
          switch (data.items[0].currency) {
            case CURRENCY_CODES.USD:
              priceCurrency = '$';
              break;
            case CURRENCY_CODES.GBP:
              priceCurrency = '£';
              break;
            default:
              priceCurrency = '€';
          }
          return (
            <S.FlexRow>
              <S.ClientNameColumn>
                <S.TextPrice>{isHidden === true ? '-' : result?.toFixed(2) + priceCurrency}</S.TextPrice>
              </S.ClientNameColumn>
            </S.FlexRow>
          );
        },
      },
      {
        title: '',
        id: 'send-offer',
        element: (data: ICart) => {
          return (
            <S.FlexRow>
              <S.SendButton
                id="send-offer-btc"
                onClick={e => {
                  e.stopPropagation();
                  orderGenerateNew(me, data, () => this.setState({ sendOrder: true }));
                }}
              >
                {type === ('provider' as IOrderType)
                  ? __('Components.MultipleCarts.offer_cta')
                  : __('Components.MultipleCarts.order_cta')}
              </S.SendButton>
            </S.FlexRow>
          );
        },
      },
    ];

    const cartsToShow = type === 'purchase' ? buyerCarts : sellerCarts;
    return (
      <Workspace
        subtitle={''}
        title={__('Components.MultipleCarts.title')}
        tabSelected={'multiple-carts'}
        workspaceId={Number(match.params?.workspaceId)}
        isBuyerWorkspace={true}
      >
        <S.Container>
          {orderSelected ? this.renderNewCart() : null}

          {cartsToShow.length ? (
            config.TOGGLE_NEW_CART_CARD.enabled ? (
              this.renderOrders()
            ) : (
              <S.TableContainer>
                <Table
                  values={cartsToShow}
                  onClickRow={(data: ICart) => {
                    orderGenerateNew(me, data, os => this.setState({ orderSelected: os }));
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_CARTS_DETAIL,
                        workspaceId: match.params?.workspaceId,
                        key: data.key,
                      }),
                    );
                  }}
                  emptyText=""
                  columns={columns}
                  selectable={false}
                  productColumns={getDefaultColumns()}
                />
              </S.TableContainer>
            )
          ) : (
            <S.CenterContainer>
              <EmptyListResource
                buttonAction={() =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER,
                      workspaceId: me.buyerWorkspaceId.toString(),
                    }),
                  )
                }
                buttonText={__('Components.MultipleCarts.empty.cta')}
                imageUrl={IMAGES.productsNoResult}
                showButton={true}
                text={__('Components.MultipleCarts.empty.title')}
                text2={__('Components.MultipleCarts.empty.subTitle')}
                buttonType="principal"
              />
            </S.CenterContainer>
          )}
        </S.Container>
      </Workspace>
    );
  }
  /**
   * Render the order selected in the new cart screen
   */
  private renderNewCart() {
    const { contacts, match, history, location } = this.props;
    const { orderSelected, showroomSelected } = this.state;
    const contactId = orderSelected.sellerId;
    const contact = orderSelected ? contacts[contactId] : undefined;
    const childProps = {
      ...this.props,
      BackHeader: (
        <S.BackContainer>
          <S.Back
            onClick={() => {
              this.setState({ showroomSelected: undefined });
            }}
          >
            <S.ChevronIcon name="Back" />
            {__('Components.OrdersList.back_order')}
          </S.Back>
        </S.BackContainer>
      ),
      contact,
      match: match as any,
      next: () => {
        this.setState({ showroomSelected: undefined });
      },
    };
    return (
      <S.CartContainer>
        <OrderDetails
          amSeller={false}
          closeCart={() => {
            this.closeOrderSelected();
          }}
          contact={contact}
          orderId={orderSelected && orderSelected.id}
          history={history}
          location={location}
          match={match}
          backLink={__('Components.Cart.back_orders')}
          navCloneAction={() => undefined}
          navShowroom={() => this.setState({ showroomSelected: true })}
          openedFrom={'purchases'}
        />
        {showroomSelected ? (
          <S.ShowroomContainer>
            <S.ShowroomContainerRow>
              <ShowroomBuy {...childProps} />
            </S.ShowroomContainerRow>
          </S.ShowroomContainer>
        ) : null}
      </S.CartContainer>
    );
  }

  private renderOrders() {
    const { cart, me, type, contacts } = this.props;
    const unsentOrders = Object.values(cart).filter(el => el.orderId === 0);
    const buyerCarts = unsentOrders.filter(el => {
      return el.asSeller === false && el.contactId && el.items.find(item => item.amount > 0);
    });
    const sellerCarts = unsentOrders.filter(el => {
      return el.asSeller === true && el.contactId && el.items.find(item => item.amount > 0);
    });

    const cartsToShow = type === 'purchase' ? buyerCarts : sellerCarts;
    return cartsToShow.length ? (
      <>
        {cartsToShow.map((c, i) => (
          <OrderCard
            key={c.key}
            contacts={contacts}
            index={i}
            me={me}
            cart={c}
            selectOrder={(o?: IOrder) => this.setState({ orderSelected: o })}
          />
        ))}
      </>
    ) : null;
  }
  /**
   * Show one order in the cart view
   */
  private openOrderSelected = (orderSelected: IOrder) => {
    this.setState({ orderSelected });
  };
  /**
   * Close the selected order
   */
  private closeOrderSelected = () => {
    this.setState({ showroomSelected: undefined, orderSelected: undefined });
    history.back();
  };
}

function OrderCard({
  cart,
  contacts,
  me,
  index,
  selectOrder,
}: {
  cart: ICart;
  contacts: Record<number, IContact>;
  me: IUser;
  index: number;
  selectOrder: (o?: IOrder) => void;
}) {
  const dispatch = useDispatch<Dispatch<any>>();
  const history = useHistory();
  const params = useParams<{ workspaceId: string }>();
  const countries = useSelector(countrySelectors.getCountries);
  const [expanded, setExpanded] = React.useState<boolean>(!index);
  const [deliveryEstimatedAt, setDeliveryEstimatedAt] = React.useState<string>('');
  const [pickupEstimatedAt, setPickupEstimatedAt] = React.useState<string>('');
  const downIcon = expanded ? 'Down' : 'Right';
  const meAsOrderUser = {
    avatar: me.settings.avatar,
    avatarColor: utils.getAvatarColor(me.name),
    catalogId: me.sellerWorkspaceId,
    companyLogo: '',
    companyName: me.companyName,
    id: me?.id,
    name: me.name,
    workingStatus: me.settings.workingStatus,
  };
  const seller = cart.asSeller ? meAsOrderUser : contacts[cart.contactId];
  React.useEffect(() => {
    if (seller) {
      dispatch(
        sellerWorkspaceActions.getMinimunDeliveryDate(cart.catalogId!, seller.id, Date.now(), undefined, min => {
          setDeliveryEstimatedAt(new Date(min.deliveryAt || Date.now()).toISOString());
          setPickupEstimatedAt(new Date(min.shippingAt || Date.now()).toISOString());
        }),
      );
    }
  }, [seller]);
  const price = getTotalPrice(cart);

  const totalPrice =
    cart.priceMode !== 'none' && price ? currency.getPrice(cart.items[0]?.currency, price, PRICE_PRECISION) : '-';
  if (!seller) return null;
  return (
    <S.OrderCard>
      <S.Resume onClick={() => setExpanded(!expanded)}>
        <S.DownIcon name={downIcon} disableHover={true} />
        <S.ResumeLeft>
          <S.TitleRow>
            <S.ContactImagesRow>
              <S.ContactImage img={seller.avatar} text={seller.name} avatarColor={utils.getAvatarColor(seller.name)} />
            </S.ContactImagesRow>
            <S.ContactNameColumn>
              <S.TextTitle>
                {seller.companyName} ({seller.name}) · {cart.items.length} articles
              </S.TextTitle>
              <S.TextBlackSmall>{orderService.getCartLoadSummaryText(cart.items)}</S.TextBlackSmall>
            </S.ContactNameColumn>
          </S.TitleRow>
        </S.ResumeLeft>
        <S.ResumeRight>
          <S.TextBlack>{__('Components.Cart.total')}</S.TextBlack>
          <S.TextTitle>{totalPrice}</S.TextTitle>
        </S.ResumeRight>
      </S.Resume>
      {expanded ? (
        <>
          <S.HeadRow>
            <S.ProductTh>{__('OrderPrepare.contact')}</S.ProductTh>
            <S.ProductTh>{__('OrderPrepare.deliveryTo')}</S.ProductTh>
            <S.ProductTh>{__('Components.OrderLogistics.delivery_date')}</S.ProductTh>
          </S.HeadRow>
          <S.DataRowHead>
            <S.ProductTd>
              <S.Row>
                <S.ContactImagesRow>
                  <S.ContactImage
                    img={seller.avatar}
                    text={seller.name}
                    avatarColor={utils.getAvatarColor(seller.name)}
                  />
                </S.ContactImagesRow>
                <S.ContactNameColumn>
                  <S.TextBlack>{seller.companyName}</S.TextBlack>
                  <S.TextSecondary>{seller.name}</S.TextSecondary>
                </S.ContactNameColumn>
              </S.Row>
            </S.ProductTd>
            <S.ProductTd>
              <S.Row>
                <S.ContactImagesRow>
                  <S.ContactImage
                    iconName="Truck"
                    img=""
                    text={seller.companyName}
                    avatarColor={{ text: theme.colors.grey1, background: theme.colors.grey4 }}
                    type="other"
                    size={30}
                  />
                </S.ContactImagesRow>
                {cart.deliveryAddress ? (
                  <S.ContactNameColumn>
                    <S.TextBlack>{addresses.getAddressName(cart.deliveryAddress, countries, true)}</S.TextBlack>
                    <S.TextSecondary>
                      {addresses.getAddressName(cart.deliveryAddress, countries, false)}
                    </S.TextSecondary>
                  </S.ContactNameColumn>
                ) : (
                  <S.TextBlack>-</S.TextBlack>
                )}
              </S.Row>
            </S.ProductTd>
            <S.ProductTd>
              <S.Row>
                <S.ContactImagesRow>
                  <S.ContactImage
                    iconName="Availability"
                    img=""
                    text={seller.companyName}
                    avatarColor={{ text: theme.colors.grey1, background: theme.colors.grey4 }}
                    type="other"
                    size={30}
                  />
                </S.ContactImagesRow>
                <S.TextBlack>
                  {deliveryEstimatedAt ? utils.capitalizeWords(date.formatLongDate(deliveryEstimatedAt)) : '-'}
                </S.TextBlack>
              </S.Row>
            </S.ProductTd>
          </S.DataRowHead>
          <S.TableContainer>
            <ItemsCard
              cartUpdateItem={cartUpdateItem}
              cart={cart}
              me={me}
              priceMode={cart.priceMode}
              showCustomColumns={true}
              weAreSeller={cart.asSeller}
            />
          </S.TableContainer>
          <S.ButtonsRow>
            <S.DeleteActionButton type="delete" onClick={removeOrder}>
              {__('Components.MultipleCarts.remove_cart')}
            </S.DeleteActionButton>
            <S.ActionButton type="secondary" onClick={editOrder} id="edit-order">
              {__('Components.MultipleCarts.edit_cart')}
            </S.ActionButton>
            <S.ActionButton type="principal" onClick={sendOrder} id="send-offer-btc">
              {__('Components.MultipleCarts.order_cta')}
            </S.ActionButton>
          </S.ButtonsRow>
        </>
      ) : null}
    </S.OrderCard>
  );
  function removeOrder() {
    dispatch(orderActions.cartClean(cart.key));
  }
  function editOrder() {
    dispatch(orderActions.orderGenerateNew(me, cart, o => selectOrder(o)));
    history.push(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_CARTS_DETAIL,
        workspaceId: params?.workspaceId,
        key: cart.key,
      }),
    );
  }
  function sendOrder() {
    dispatch(
      orderActions.orderGenerateNew(me, cart, o => {
        selectOrder(o);
        dispatch(
          orderActions.orderNew(o, me?.id, (newOrder: IOrder, err: Error) => {
            if (err) return;
            dispatch(
              notificationsActions.notificationShow({
                title: __('Components.OrderDetails.success_create.title', {
                  hashId: newOrder.externalIdSeller || newOrder.externalIdBuyer || '#' + newOrder.hashId,
                }),
                closable: true,
                subtitle: __('Components.OrderDetails.success_create.description', {
                  name: contacts[cart.asSeller ? newOrder.buyerId : newOrder.sellerId]?.name || '',
                }),
                style: 'success',
              }),
            );
          }),
        );
      }),
    );
  }
  function cartUpdateItem(item: IOrderItem) {
    const items = cart.items;
    const idx = items.findIndex(i => (item.childId ? i.childId === item.childId : i.title === item.title));

    const newItem = { ...item, totalPrice: 0 };
    if (idx !== -1) {
      items[idx] = newItem;
    } else {
      items.push(newItem);
    }
    dispatch(orderActions.cartUpdateItem(cart.key, cart.contactId, newItem, cart.asSeller, cart.priceMode));
  }

  function getTotalPrice(cart: ICart): number {
    const discount = cart.customItems && cart.customItems.find(c => c.type === 'discount');
    const discountPrice = (discount && discount.price) || 0;
    const shipping = cart.customItems && cart.customItems.find(c => c.type === 'shipping');
    const shippingPrice = (shipping && shipping.price) || 0;
    const customItems = cart.customItems && cart.customItems.filter(c => c.type === 'custom');
    const customItemsPrice = (customItems || []).reduce((acc, customItem) => {
      acc += customItem.price;
      return acc;
    }, 0);
    if (cart.items.find(i => i.isPor && i.servedQuantity > 0)) {
      return 0;
    }
    const totalPrice = getProductsPrice(cart.items) + discountPrice + shippingPrice + customItemsPrice;
    return Math.max(MINIMUM_ORDER_PRICE, totalPrice);
  }
}
