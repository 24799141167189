import * as React from 'react';

import IE11Modal from './components/organisms/IE11Modal/IE11Modal.component';
import { MainHeader } from './components/organisms/MainHeader';
import SmallScreen from './components/organisms/SmallScreen';
import * as S from './layout-flex.styled';
import { IHeaderProps } from './screens/header';
import LoginModal from './screens/login-modal';

export interface IProps {
  children: React.ReactNode;
  header?: IHeaderProps;
  loginModal?: boolean;
}

// Detect IE11 or older versions
const msie = typeof window !== 'undefined' && window.navigator.userAgent.indexOf('MSIE ');
const showIESupportModal =
  // @ts-ignore
  (typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode) || msie > 0;

const Layout: React.FC<IProps> = ({ header, children, loginModal = true }) => {
  const Container = S.Container;
  const showHeader = !header || header.show === undefined || header.show!;
  const hasBreadcrumbs = !!header?.breadcrumbs?.length;
  return (
    <Container className="layout-flex-container">
      <MainHeader {...header} />
      <S.Body className="layout-flex-body">
        <SmallScreen />
        <S.ColumnRight id="column-right-layout" showHeader={showHeader} hasBreadcrumbs={hasBreadcrumbs}>
          <S.Content id="content-layout">{children}</S.Content>
        </S.ColumnRight>
        {loginModal && <LoginModal />}
        {showIESupportModal ? <IE11Modal /> : null}
      </S.Body>
    </Container>
  );
};

export default Layout;
