import { Api, broadcastActions, chatActions, imageActions, modalActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateToShowroom } from '../actions/nav';
import { supportAction } from '../actions/user';
import ChatBroadcast, { DispatchProps, StateProps } from '../components/organisms/ChatBroadcast';
import { IReduxState } from '../reducers';
import { IBroadcastRouterProps } from './chat-broadcast-container';

function mapStateToPropsDetails(state: IReduxState, props: IBroadcastRouterProps): StateProps {
  const { channelId } = props.match.params;
  const {
    prodType,
    broadcast: { broadcasts, initialized, messages },
    contact: { inConsentio },
    user: { user },
  } = state;

  const prodTypes = prodType.prodTypes;
  return {
    broadcast: broadcasts[channelId],
    contacts: inConsentio,
    initialized,
    messages: messages[channelId],
    me: user,
    prodTypes,
  };
}

function openChannel(channelId: string, myId: number) {
  return (d: (object) => void, getState: () => IReduxState) => {
    d(broadcastActions.getMessages(myId, channelId, true));
    d(broadcastActions.setActiveBroadcast(channelId));
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IBroadcastRouterProps,
): DispatchProps {
  return bindActionCreators(
    {
      createBroadcast: broadcastActions.createBroadcast,
      getMessages: broadcastActions.getMessages,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateToShowroom,
      openChannel,
      sendMessage: broadcastActions.sendMessage,
      setActiveBroadcast: broadcastActions.setActiveBroadcast,
      setMessageDraft: broadcastActions.setMessageDraft,
      supportAction,
      touchFile: chatActions.downloadFile,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(ChatBroadcast);
