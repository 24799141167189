import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
}

export const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;

export const RightText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const SearchContainer = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Filters = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const FilterItem = styled.span`
  cursor: ${(p: IStyledProps) => (p.selected ? 'default' : 'pointer')};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  font-size: inherit;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  height: 100%;
  &:first-child {
    padding-left: 0;
  }
`;
