import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { __, IDateRange } from 'common-services';

import { CHANNEL_SECTIONS } from '../../../constants';

import OrderDetails from '../../../screens/order-details';
import ShowroomBuy from '../../../screens/showroom-buy';
import ShowroomSell from '../../../screens/showroom-sell';

import * as S from './OrdersList.styled';

interface NewCartProps {
  orderSelected: IOrder;
  contacts: { [id: number]: IContact };
  isPurchase: () => boolean;
  numberOfHeaders: number;
  hideBack: boolean;
  fromMessages: boolean;
  updateUrl?: (
    searchState: ISearchOrder,
    dateRange: IDateRange,
    order?: IOrder | { hashId?: string; isRepeatedOrder?: boolean },
  ) => void;
  search: ISearchOrder;
  dateRange: IDateRange;
  closeOrderSelected: () => void;
  breadcrumb?: {
    parentSections: Array<IBreadcrumb>;
    title: string;
  };
  updateBreadcrumb: (newCtx: { parentSections: Array<IBreadcrumb>; title: string }) => void;
  sendSearch: () => void;
  me: IUser;
  type: 'purchase' | 'sale';
  catalogs: { [id: number]: IWorkspace };
  workspaces: { [id: number]: IWorkspace };
}

const NewCartComponent: React.FC<NewCartProps> = ({
  orderSelected,
  contacts,
  isPurchase,
  numberOfHeaders,
  hideBack,
  fromMessages,
  updateUrl,
  search,
  dateRange,
  closeOrderSelected,
  breadcrumb,
  updateBreadcrumb,
  sendSearch,
  me,
  type,
  catalogs,
  workspaces,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{
    workspaceId: string;
    channelId: string;
    contactId: string;
    productHash: string;
    hashId: string;
    clientId: string;
    supplierId: string;
    key: string;
  }>();

  const [showroomSelected, setShowroomSelected] = React.useState<CHANNEL_SECTIONS | undefined>(undefined);

  const contactId = isPurchase() ? orderSelected.sellerId : orderSelected.buyerId;
  const contact = orderSelected ? contacts[contactId] : undefined;

  const handleBackClick = React.useCallback(() => {
    setShowroomSelected(undefined);
    sendSearch();
  }, [sendSearch]);

  const handleNavShowroom = React.useCallback(() => {
    setShowroomSelected(isPurchase() ? CHANNEL_SECTIONS.SHOWROOM_BUY : CHANNEL_SECTIONS.SHOWROOM_SELL);
  }, [isPurchase]);

  const handleNavCloneAction = React.useCallback(() => {
    updateUrl(search, dateRange, { hashId: undefined, isRepeatedOrder: true });
  }, [updateUrl, search, dateRange]);

  const childProps = {
    BackHeader: (
      <S.BackContainer>
        <S.Back onClick={handleBackClick}>
          <S.ChevronIcon name="Back" />
          {__('Components.OrdersList.back_orders')}
        </S.Back>
      </S.BackContainer>
    ),
    contact,
    match: params as any,
    next: () => setShowroomSelected(undefined),
    me,
    type,
    catalogs,
    workspaces,
    location,
    history,
    closeCart: closeOrderSelected,
  };

  const openedFrom = isPurchase() ? 'purchases' : 'sales';

  return (
    <S.CartContainer numHeaders={numberOfHeaders} className="orders-list-cart-container">
      <OrderDetails
        amSeller={!isPurchase()}
        closeCart={closeOrderSelected}
        contact={contact}
        orderId={orderSelected?.id}
        hideBack={hideBack}
        history={history}
        location={location}
        match={params}
        backLink={fromMessages ? __('Components.Cart.back_to_chat') : __('Components.Cart.back_orders')}
        navCloneAction={handleNavCloneAction}
        navShowroom={handleNavShowroom}
        openedFrom={openedFrom}
        breadcrumb={breadcrumb}
        updateBreadcrumb={updateBreadcrumb}
      />
      {showroomSelected && (
        <S.ShowroomContainer>
          <S.ShowroomContainerRow>
            {showroomSelected === CHANNEL_SECTIONS.SHOWROOM_BUY ? (
              <ShowroomBuy {...childProps} />
            ) : (
              <ShowroomSell {...childProps} />
            )}
          </S.ShowroomContainerRow>
        </S.ShowroomContainer>
      )}
    </S.CartContainer>
  );
};

export const NewCart = React.memo(NewCartComponent);
