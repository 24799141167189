import { EventTrack, IGlobalWeb, RenderTrack, setCommonServices } from 'common-services';
import { Store } from 'redux';

import { checkVerifyEmailFromURL, supportAction } from '../../app/actions/user';
import { logError } from '../../app/services/log';
import Config from '../../bindings/config';
import { getReadableVersion, getUniqueID } from '../../bindings/info';
import Sentry from '../../bindings/sentry';
import contactServices from '../services/contacts';
import { bcDebug } from '../services/log';
import { Segment } from '../services/segment';

declare var global: IGlobalWeb;

const unauthID = 'unauth-' + getUniqueID();

function setUpSegment(): void {
  bcDebug('hooks', 'app_start - setup segment', '');
  global.segment = new Segment();
  if (Config.APP_ENV === 'dev') {
    const track = global.segment.track;
    const screen = global.segment.screen;
    // tslint:disable
    global.segment.track = (...args) => {
      console.log('------- TRACK:', ...args);
      return track.bind(global.segment, args);
    };
    global.segment.screen = (...args) => {
      console.groupEnd();
      console.group('------- SCREEN:', ...args);
      return screen.bind(global.segment, args);
    };
  }
  RenderTrack.init(global.segment, getReadableVersion());
  EventTrack.init(global.segment);
}

function setUpSentry(): void {
  bcDebug('hooks', 'app_start - setup sentry', '');
  Sentry.config().install();
  Sentry.setTagsContext({ environment: Config.APP_ENV });
  Sentry.setUserContext({
    id: unauthID,
  });
}

/**
 * Set up commons services
 */
function setUpCommonServices(store: Store) {
  setCommonServices(contactServices, logError, EventTrack, () => store.dispatch(supportAction() as any));
}

export default function appStart(store: Store): void {
  bcDebug('hooks', 'app_start', '');
  window.onfocus = () => {
    EventTrack.foreground();
  };
  checkVerifyEmailFromURL();
  setUpSegment();
  setUpSentry();
  setUpCommonServices(store);
}
