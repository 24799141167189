import * as React from 'react';

import * as S from './NavigationLinks.styled';

interface IProps {
  className?: string;
  options: Array<{ key: string; label: string; count?: number; disabled?: boolean }>;
  onSelectOption: (key: string, disabled?: boolean) => void;
  keySelected: string;
}

const NavigationLinks = ({ className, options, onSelectOption, keySelected }: IProps) => {
  return (
    <S.Container className={className}>
      {options
        .filter(o => o)
        .map(({ key, label, count, disabled }, index, array) => {
          const isSelected = key === keySelected;
          return (
            <React.Fragment key={index + key}>
              <S.FilterItem disabled={disabled} onClick={() => onSelectOption(key, disabled)}>
                <S.FilterText selected={isSelected}>{label + (count !== undefined ? ` (${count})` : '')}</S.FilterText>
              </S.FilterItem>
            </React.Fragment>
          );
        })}
    </S.Container>
  );
};

export default React.memo(NavigationLinks);
