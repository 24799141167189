import {
  __,
  constants,
  CURRENCY_CODES,
  date,
  debounce,
  EventTrack,
  orderActions,
  orderService,
  qs,
  RenderTrack,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import Layout from '../../../layout-flex';
import OrderDetails from '../../../screens/order-details';
import ShowroomBuy from '../../../screens/showroom-buy';
import ShowroomSell from '../../../screens/showroom-sell';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button } from '../../atoms';
import { AggregationTable, EmptyListResource, OrderAggregationFooter } from '../../molecules';
import * as S from './OrderAggregation.styled';

export type IRouteProps = RouteComponentProps<{
  workspaceId: string;
}> & { asSeller: boolean };

export interface IStateProps {
  contacts: { [cId: number]: IContact };
  me: IUser;
  orders: Array<IOrder>;
  publicUrl: string;
  workspaceSelected: IWorkspace;
}

export interface IDispatchProps {
  orderGetByHash: typeof orderActions.orderGetByHash;
}

export type IProps = IStateProps & IDispatchProps & IRouteProps;

interface IState {
  searchState: ISearchOrder;
  facets: IFacets;
  orderSelected?: IOrder;
  showroomSelected?: CHANNEL_SECTIONS.SHOWROOM_BUY | CHANNEL_SECTIONS.SHOWROOM_SELL;
  generatingPDF?: boolean;
}

export default class OrderAggregation extends React.PureComponent<IProps, IState> {
  private t: number;
  private sendSearch = debounce(() => {
    const { searchState } = this.state;
    orderService.searchOrderItem(searchState, config.SEARCH_PREFIX, api, this.props.me.id).then(({ data }) => {
      this.setState({
        facets: data?.facets || {},
      });
    });
  }, 200);

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const {
      me,
      location: { search },
      workspaceSelected,
    } = props;
    // Load selected orders from url params
    const { currency, selected, since, type, only_unreads, until, unselected } = qs.parse(search || '', [
      'currency',
      'range',
      'selected',
      'unselected',
      'since',
      'type',
      'until',
      'only_unreads',
    ]) as {
      currency?: CURRENCY_CODES;
      selected?: string;
      unselected?: string;
      since?: string;
      type?: string;
      until?: string;
      only_unreads?: string;
    };
    const beginDate = since && Number(since) ? new Date(Number(since)) : undefined;
    const endDate = until && Number(until) ? new Date(Number(until)) : undefined;
    this.state = {
      facets: {},
      searchState: {
        language: me.settings.language as LOCALE,
        myId: me.id,
        currency,
        beginDate,
        endDate,
        buyerWsHash: type === 'purchase' ? workspaceSelected?.hashId : undefined,
        catalogHash: type === 'sale' ? workspaceSelected?.hashId : undefined,
        selectedIds: selected ? selected.split(',').map(id => Number(id)) : [],
        unselectedIds: unselected ? unselected.split(',').map(id => Number(id)) : [],
        onlyAggregation: true,
        onlyUnreads: only_unreads === 'true',
      },
    };
  }

  public componentDidMount() {
    RenderTrack.track('OrderAggregation', { renderTime: this.t });
    this.sendSearch();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { workspaceSelected } = this.props;
    const { searchState } = this.state;
    if (workspaceSelected !== prevProps.workspaceSelected) {
      this.setState({
        searchState: {
          ...searchState,
          buyerWsHash: workspaceSelected.type === 'buyer' ? workspaceSelected?.hashId : undefined,
          catalogHash: workspaceSelected.type === 'seller' ? workspaceSelected?.hashId : undefined,
        },
      });
    }
    if (prevState.searchState !== searchState) {
      this.sendSearch();
    }
  }

  public render() {
    const { asSeller, workspaceSelected, contacts, history, me, match, location, orderGetByHash } = this.props;
    const { facets, searchState, orderSelected } = this.state;

    const activeDatesFilterStart = searchState.beginDate && searchState.beginDate.getTime();
    const activeDatesFilterEnd = searchState.endDate && searchState.endDate.getTime();
    const hourFormat = me.settings.hourFormat;

    let filtersAppliedText = '';
    if (activeDatesFilterStart || activeDatesFilterEnd) {
      if (activeDatesFilterStart && activeDatesFilterStart === activeDatesFilterEnd) {
        filtersAppliedText += date.dateToText(activeDatesFilterStart, hourFormat).date;
      } else {
        filtersAppliedText += `${__('Components.OrdersList.from')}${
          (searchState.beginDate && date.dateToText(activeDatesFilterStart, hourFormat).date) || '__'
        } ${__('Components.OrdersList.to')} ${
          (activeDatesFilterEnd && date.dateToText(activeDatesFilterEnd, hourFormat).date) || '__'
        }`;
      }
    }
    return (
      <Layout
        header={{
          show: true,
          title: __('Components.Header.Aggregation'),
          subtitle: '',
          tabSelected: asSeller ? 'sale' : 'purchase',
          breadcrumbs: [
            {
              label: asSeller ? __('Components.OrdersList.title_sales') : __('Components.OrdersList.title_purchases'),
              action: () =>
                history.push(
                  getPath({
                    path: asSeller
                      ? match.params.workspaceId
                        ? ROUTE_PATHS.WORKSPACE_SALES
                        : ROUTE_PATHS.SALES
                      : match.params.workspaceId
                      ? ROUTE_PATHS.WORKSPACE_PURCHASES
                      : ROUTE_PATHS.PURCHASES,
                    workspaceId: match.params.workspaceId,
                  }),
                ),
            },
            { label: __('Components.Header.Aggregation') },
          ],
        }}
      >
        <S.Container className="order-aggregation-container">
          {facets.byType && Object.keys(facets.byType).length ? (
            <>
              <S.HeaderRow>
                <S.FiltersTitle>
                  {facets.byType && Object.keys(facets.byType).length}
                  {__('Components.OrdersList.orders_found')}
                  {filtersAppliedText}
                </S.FiltersTitle>
                {match.params.workspaceId ? (
                  <Button
                    type="secondary"
                    iconUrl={constants.getLogoFromMime('application/pdf')}
                    loading={this.state.generatingPDF}
                    onClick={async () => {
                      this.setState({ generatingPDF: true });
                      EventTrack.track('download_orders_grouped_pdf');
                      const downloadOrderAggregationPdf = (await import('./OrderAggregation.download')).default;

                      await downloadOrderAggregationPdf({
                        contacts,
                        facets: facets?.byType,
                        me,
                        workspace: workspaceSelected,
                        beginDate: searchState.beginDate,
                        endDate: searchState.endDate,
                        selected: searchState.selectedIds.join(','),
                        unselected: searchState.unselectedIds.join(','),
                        currency: searchState.currency,
                        asSeller,
                      });
                      this.setState({ generatingPDF: false });
                    }}
                  >
                    {__('Components.OrdersList.download_PDF')}
                  </Button>
                ) : null}
              </S.HeaderRow>
              <AggregationTable
                asSeller={asSeller}
                myId={me.id}
                hourFormat={me.settings.hourFormat}
                searchState={searchState}
                orders={facets.byType}
                contacts={contacts}
                onClick={orderHash =>
                  orderGetByHash(me.id!, orderHash, order => {
                    this.openOrderSelected(order);
                  })
                }
                weightUnit={workspaceSelected?.defaultWeightUnit}
              />
              <OrderAggregationFooter
                groups={facets}
                groupedBy="product"
                searchCurrency={searchState.currency}
                weightUnit={workspaceSelected?.defaultWeightUnit}
              />
            </>
          ) : (
            <S.CenterContainer>
              <EmptyListResource
                buttonAction={() => history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST }))}
                buttonText={__('Components.OrdersList.EmptyResults.cta')}
                imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
                showButton={true}
                text={__('Components.OrdersList.EmptyResults.title')}
                text2={__('Components.OrdersList.EmptyResults.description')}
              />
            </S.CenterContainer>
          )}
          {orderSelected ? this.renderNewCart() : null}
        </S.Container>
      </Layout>
    );
  }

  /**
   * Render the order selected in the new cart screen
   */
  private renderNewCart() {
    const { contacts, match, history, location, workspaceSelected } = this.props;
    const { orderSelected, showroomSelected } = this.state;
    const contactId = workspaceSelected?.type === 'buyer' ? orderSelected.sellerId : orderSelected.buyerId;
    const contact = orderSelected ? contacts[contactId] : undefined;

    const childProps = {
      ...this.props,
      BackHeader: (
        <S.BackContainer>
          <S.Back
            onClick={() => {
              const { searchState } = this.state;
              this.setState({ showroomSelected: undefined });
              // Temporary solution before we move search state to redux
              this.sendSearch(searchState);
            }}
          >
            <S.ChevronIcon name="Back" />
            {__('Components.OrdersList.back_order')}
          </S.Back>
        </S.BackContainer>
      ),
      contact,
      match: match as any,
      next: () => {
        this.setState({ showroomSelected: undefined });
      },
    };
    return (
      <S.CartContainer>
        <OrderDetails
          amSeller={workspaceSelected?.type === 'seller'}
          closeCart={() => {
            this.closeOrderSelected();
            const { searchState } = this.state;
            // Temporary solution before we move search state to redux
            this.sendSearch(searchState);
          }}
          contact={contact}
          orderId={orderSelected && orderSelected.id}
          history={history}
          location={location}
          match={match}
          backLink={__('Components.Cart.back_orders')}
          navCloneAction={() => null}
          navShowroom={() =>
            this.setState({
              showroomSelected:
                workspaceSelected?.type === 'seller' ? CHANNEL_SECTIONS.SHOWROOM_SELL : CHANNEL_SECTIONS.SHOWROOM_BUY,
            })
          }
          openedFrom={workspaceSelected?.type === 'seller' ? 'sales' : 'purchases'}
        />
        {showroomSelected ? (
          <S.ShowroomContainer>
            <S.ShowroomContainerRow>
              {showroomSelected === CHANNEL_SECTIONS.SHOWROOM_BUY ? (
                <ShowroomBuy {...childProps} />
              ) : (
                <ShowroomSell {...childProps} />
              )}
            </S.ShowroomContainerRow>
          </S.ShowroomContainer>
        ) : null}
      </S.CartContainer>
    );
  }
  /**
   * Show one order in the cart view
   */
  private openOrderSelected = (orderSelected: IOrder) => {
    this.setState({
      orderSelected,
    });
  };
  /**
   * Close the selected order
   */
  private closeOrderSelected = () => {
    this.setState({ showroomSelected: undefined, orderSelected: undefined });
  };
}
