import {
  __,
  buyerWorkspaceSelectors,
  constants,
  debounce,
  EventTrack,
  modalActions,
  notificationsActions,
  productService,
  sellerWorkspaceService,
  throttle,
  to,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import * as webConstants from '../../../constants';
import { IReduxState } from '../../../reducers';
import Workspace from '../Workspace/Workspace.component';
import { convertToIFile } from '../../../services/file';
import { logError } from '../../../services/log';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button, FontIcon, Input, Ribbon } from '../../atoms';
import {
  Attachment,
  FormContainer,
  FormSection,
  InputWithLabel,
  ProductFilter,
  ProductTable,
  SearchProductModal,
  SimpleSearch,
} from '../../molecules';
import * as S from './WorkspaceSupplierContractEdit.styled';

type IProps = RouteComponentProps<{ workspaceId: string; supplierId: string; contractId: string }>;
const emptyObject = {};
const catalogObject = {
  id: 0,
  hashId: '',
} as any;
const WorkspaceSupplierEdit: React.FC<IProps> = ({
  match: {
    params: { workspaceId, supplierId, contractId },
  },
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const me = useSelector(userSelectors.getUser);
  const workspace = useSelector(buyerWorkspaceSelectors.getWorkspace(Number(workspaceId)));
  const supplier = useSelector((state: IReduxState) =>
    state.workspace.suppliers[Number(workspaceId)]?.find(s => s.userId === Number(supplierId)),
  );
  const contact = useSelector((state: IReduxState) => state.contact.inConsentio[Number(supplierId)]);
  catalogObject.id = contact?.theirSellerWorkspaceId || 0;
  catalogObject.hashId = contact?.theirSellerWorkspaceHash || '';
  const catalog = useSelector((state: IReduxState) => state.product.catalog[Number(workspaceId)] || catalogObject);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [facets, setFacets] = React.useState({});
  const [facetsGlobal, setFacetsGlobal] = React.useState({});
  const [hideFilters, setHideFilters] = React.useState(true);
  const [searchState, setSearchState] = React.useState<ISearch>({
    text: '',
    language: me.settings.language as LOCALE,
    index: contact?.theirSellerWorkspaceHash,
    status: [],
    contract: Number(contractId),
  });
  const [searchId, setSearchId] = React.useState('');
  const [isSearched, setIsSearched] = React.useState(false);
  const [showAddProducts, setShowAddProducts] = React.useState(false);
  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [totalResults, setTotalResults] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [contract, setContract] = React.useState<IContract>({
    id: 0,
    reference: '',
    createdAt: 0,
    supplierId: 0,
    workspaceId: 0,
    filename: '',
    mimeType: '',
    size: 0,
    productImages: [],
    totalProducts: 0,
  });

  const modifyFiles = React.useCallback(
    (existingFiles: Array<File>) => {
      const fileToUpload = [...existingFiles];

      uploadedFiles.forEach(file => {
        fileToUpload.push(file);
      });
      return fileToUpload;
    },
    [uploadedFiles],
  );

  const setFiles = React.useCallback(
    (files: Array<File>) => {
      if (files.length > 0) {
        setUploadedFiles(modifyFiles(files));
        setHasChanges(true);
      }
    },
    [modifyFiles],
  );

  const sendSearch = React.useCallback(
    debounce(() => {
      setIsSearched(false);
      productService
        .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
        .then(onReceiveProducts);
    }, 200),
    [searchState],
  );

  const scrollSearch = React.useCallback(
    throttle(() => {
      productService.productScrollSearch(searchId, api).then(onReceiveProducts);
    }, 200),
    [searchId],
  );

  const onReceiveProducts = React.useCallback(
    (res: ISearchData<IProduct>) => {
      if (!res) return null;
      const { data, toReplace } = res;
      if (searchState.text) {
        EventTrack.track('pricegroup_products_search', {
          workspace_id: contact.theirSellerWorkspaceId,
          results: data?.totalResults || 0,
          text: searchState.text,
        });
      }
      if (toReplace) {
        setProducts(data.hits);
        setSearchId(data.searchId);
        setTotalResults(data.totalResults);
        setHasMore(data.hasMore!);
        setFacets(res.facets);
        setFacetsGlobal(res.facetsGlobal);
      } else {
        setProducts([...products, ...data.hits]);
        setSearchId(data.searchId);
        setTotalResults(data.totalResults);
        setHasMore(data.hasMore!);
      }
    },
    [products, searchState.text],
  );

  React.useEffect(() => {
    if (contractId && contractId !== 'new') {
      api.buyerWorkspace
        .contractGet(me.id, Number(workspaceId), Number(supplierId), Number(contractId))
        .then(setContract);
      setSearchState({ ...searchState, contract: Number(contractId) });
    }
  }, [contractId]);

  React.useEffect(() => {
    if (contractId && contractId !== 'new') sendSearch();
  }, [searchState]);

  const contractUploadFile = React.useCallback(
    async (res?: IFile) => {
      const { data, err } = await to(
        api.buyerWorkspace.contractUploadFile(
          me.id,
          Number(workspaceId),
          Number(supplierId),
          Number(contractId),
          contract.reference,
          res,
        ),
      );
      if (err) {
        dispatch(
          notificationsActions.notificationShow(
            {
              title: contract.id
                ? __('WorkspaceSupplierEdit.contracts.error_update')
                : __('WorkspaceSupplierEdit.contracts.error_create'),
              subtitle: '',
              closable: true,
              style: 'error',
            },
            5000,
          ),
        );
      } else {
        setHasChanges(false);
        history.replace(
          getPath({
            path: webConstants.ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT,
            workspaceId,
            supplierId,
            contractId: data.id + '',
          }),
        );
        if (res) {
          setContract({ ...contract, filename: res.name, mimeType: res.type, size: res.size });
        }
        dispatch(
          notificationsActions.notificationShow(
            {
              title: contract.id
                ? __('WorkspaceSupplierEdit.contracts.success_update')
                : __('WorkspaceSupplierEdit.contracts.success_create'),
              subtitle: '',
              closable: true,
              style: 'success',
            },
            5000,
          ),
        );
      }
    },
    [contract],
  );

  if (!workspace) return null;
  const amEditor = sellerWorkspaceService.getRole(workspace, me.id) !== 'viewer';
  const fromContactInfo = ((location.state as any)?.from || '').endsWith('/info');

  return (
    <>
      <Workspace
        isBuyerWorkspace={true}
        parentSections={[
          {
            label: __('Components.Header.WorkspaceSuppliers'),
            action: () =>
              history.push(
                getPath({
                  path: webConstants.ROUTE_PATHS.WORKSPACE_SUPPLIERS,
                  workspaceId: workspace.id + '',
                }),
              ),
          },
          {
            label: supplier?.name,
            action: () =>
              history.push(
                getPath({
                  path: webConstants.ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT,
                  workspaceId,
                  supplierId,
                }),
              ),
          },
        ]}
        subtitle={''}
        title={
          contractId === 'new'
            ? __('WorkspaceSupplierEdit.contracts.new')
            : __('WorkspaceSupplierEdit.contracts.name', { name: contract.reference })
        }
        tabSelected="suppliers"
        workspaceId={Number(workspaceId)}
      >
        {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
        <S.Container>
          <FormContainer
            className="WorkspaceSupplierEdit-FormContainer"
            canSave={hasChanges}
            cta={
              contractId !== 'new'
                ? __('WorkspaceSupplierEdit.contracts.save')
                : __('WorkspaceSupplierEdit.contracts.create')
            }
            save={() =>
              uploadedFiles?.length
                ? convertToIFile(uploadedFiles[uploadedFiles.length - 1], contractUploadFile)
                : contractUploadFile()
            }
          >
            <FormSection title={__('WorkspaceSupplierEdit.contracts.info_title')}>
              <InputWithLabel isRequired={true} label={__('WorkspaceSupplierEdit.contracts.ref')}>
                <Input
                  name="ref"
                  type="text"
                  placeholder={__('WorkspaceSupplierEdit.contracts.add_ref')}
                  value={contract.reference}
                  onBlur={(k, v: string) => {
                    setContract({ ...contract, reference: v });
                    setHasChanges(true);
                  }}
                  isRequired={true}
                />
              </InputWithLabel>
              <InputWithLabel
                isRequired={false}
                label={__('WorkspaceSupplierEdit.contracts.file_label')}
                description={__('WorkspaceSupplierEdit.contracts.file_description')}
              >
                {contract.filename ? (
                  <Attachment
                    attachment={contract as any}
                    download={async download => {
                      try {
                        const { data, err } = await to(
                          api.buyerWorkspace.getContractFile(
                            me.id,
                            Number(workspaceId),
                            Number(supplierId),
                            Number(contractId),
                          ),
                        );
                        download(data, err);
                      } catch (err) {
                        logError(err, 'Error, unable to download attachment');
                      }
                    }}
                    otherOptions={{
                      options: [
                        {
                          key: 'delete',
                          value: __('WorkspaceSupplierEdit.contracts.delete'),
                          color: 'red',
                        },
                      ],
                      onSelect: key => {
                        if (key === 'delete') {
                          api.buyerWorkspace
                            .deleteContractFile(me.id, Number(workspaceId), Number(supplierId), Number(contractId))
                            .then(() => {
                              setContract({ ...contract, filename: '', mimeType: '', size: 0 });
                              setUploadedFiles([]);
                            });
                        }
                      },
                    }}
                  />
                ) : (
                  <FileDropzone files={uploadedFiles} setFiles={setFiles} />
                )}
              </InputWithLabel>
            </FormSection>
            {contract.id ? (
              <FormSection
                title={__('WorkspaceSupplierEdit.contracts.products_included')}
                subtitle={__('WorkspaceSupplierEdit.contracts.products_included_subtitle')}
              >
                <>
                  <Button
                    type="link"
                    iconName="Add-more"
                    withoutPadding={true}
                    onClick={() => setShowAddProducts(true)}
                  >
                    {products.length === 0
                      ? __('WorkspaceSupplierEdit.contracts.add_products')
                      : __('WorkspaceSupplierEdit.contracts.add_more')}
                  </Button>
                  <S.Row>
                    <ProductFilter
                      changeSearchState={s => setSearchState(s)}
                      clearFilters={() => {
                        EventTrack.track('workspace_products_filter_clear', {
                          workspace_id: contact?.theirSellerWorkspaceId,
                        });
                        setSearchState({
                          text: searchState.text,
                          language: searchState.language,
                          index: searchState.index,
                          status: searchState.status,
                          sort: searchState.sort,
                          sections: searchState.sections,
                          priceType: searchState.priceType,
                        });
                      }}
                      facets={facets}
                      facetsGlobal={facetsGlobal}
                      me={me}
                      numberOfHeaders={2}
                      searchState={searchState}
                      onHide={h => setHideFilters(h)}
                      showClosed={hideFilters}
                      showOver="only-ipad"
                    />
                    <S.Column>
                      {products.length ? (
                        <>
                          <S.SearchWrapper id="search-products-wrapper">
                            <S.FilterButton
                              filtersOpened={!hideFilters}
                              filtersSelected={getFiltersCount()}
                              onClick={() => setHideFilters(!hideFilters)}
                            />
                            <SimpleSearch
                              id="search-products-price-group"
                              query={searchState.text}
                              onChange={(text: string) => setSearchState({ ...searchState, text })}
                              placeHolder={__('Components.ProductsList.search.placeholder')}
                            />
                            <S.RightContainer>
                              <S.Sort
                                onSelect={(k: string) =>
                                  setSearchState({ ...searchState, sort: k, sortOrder: k ? 'desc' : 'asc' })
                                }
                                options={[
                                  {
                                    key: `price_updated_at`,
                                    value: __('WorkspacePriceGroupEdit.products.sort.price_updated_at'),
                                  },
                                  {
                                    key: `updated_at`,
                                    value: __('WorkspacePriceGroupEdit.products.sort.updated_at'),
                                  },
                                  { key: '', value: __('WorkspacePriceGroupEdit.products.sort.alphabetical') },
                                ]}
                              >
                                <Button type="skip" iconName="Sort" iconSize="14px">
                                  <S.SortBlackText>{__('PublicShowroom.sort_by')}: </S.SortBlackText>
                                  <S.SortGreyText>
                                    {searchState.sort
                                      ? searchState.sort === `updated_at`
                                        ? __('WorkspacePriceGroupEdit.products.sort.updated_at')
                                        : __('WorkspacePriceGroupEdit.products.sort.price_updated_at')
                                      : __('WorkspacePriceGroupEdit.products.sort.alphabetical')}
                                  </S.SortGreyText>
                                  <FontIcon name="Down" />
                                </Button>
                              </S.Sort>
                            </S.RightContainer>
                          </S.SearchWrapper>

                          <S.TextBlack>
                            {__('WorkspaceSupplierEdit.contracts.contracts_count', {
                              count: totalResults,
                            })}
                          </S.TextBlack>

                          <ProductTable
                            catalog={catalog}
                            configId={'product_list_supplier_contract'}
                            hasOverflow={false}
                            me={me}
                            onScroll={onScroll}
                            precision={catalog?.numberOfDecimalsShowed || constants.PRICE_PRECISION}
                            products={products}
                            productDelete={productDelete}
                            role={sellerWorkspaceService.getRole(catalog, me.id)}
                            showCustomColumns={!!me.id}
                            touchImage={(images, selected) => dispatch(modalActions.touchImage(images, selected))}
                          />
                        </>
                      ) : null}
                    </S.Column>
                  </S.Row>
                  {showAddProducts ? (
                    <SearchProductModal
                      amSeller={true}
                      cartClean={(notUpdate?: boolean) => null}
                      catalog={catalog}
                      close={() => setShowAddProducts(false)}
                      contactId={me.id}
                      featured={emptyObject}
                      history={history}
                      location={location}
                      me={me}
                      notInContract={Number(contractId)}
                      onSelectAllSearch={(search: ISearch) => {
                        productService.productSearchIds(
                          search,
                          me.settings.language as LOCALE,
                          config.SEARCH_PREFIX,
                          undefined,
                          api,
                        );
                      }}
                      onSubmitSelectedProducts={prod =>
                        api.buyerWorkspace
                          .setProductToContract(
                            me.id,
                            Number(workspaceId),
                            Number(supplierId),
                            Number(contractId),
                            prod.map(p => p.productId),
                            [],
                          )
                          .then(() => {
                            sendSearch();
                            setShowAddProducts(false);
                          })
                      }
                      priceMode="read"
                      prices={emptyObject}
                      selectAll={false}
                      selectMode="multiple"
                      showFeatured={false}
                      title={__('WorkspaceSupplierEdit.contracts.add_products')}
                    />
                  ) : null}
                </>
              </FormSection>
            ) : null}
          </FormContainer>
        </S.Container>
      </Workspace>
    </>
  );
  /**
   * Count selected filters
   */
  function getFiltersCount() {
    const { status } = searchState;
    let result = 0;
    result = result + (status?.length || 0);
    return result;
  }

  function productDelete(productId: number) {
    api.buyerWorkspace
      .deleteProductFromContract(me.id, Number(workspaceId), Number(supplierId), Number(contractId), productId)
      .then(() => {
        sendSearch();
      });
  }

  function onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      scrollSearch();
    }
  }
};

export default React.memo(WorkspaceSupplierEdit);

/**
 * Render files drop zone
 */
const FileDropzone: React.FC<{
  files: Array<string>;
  setFiles: (files: Array<any>) => void;
}> = ({ files, setFiles }) => {
  const dispatch = useDispatch<Dispatch>();
  return (
    <S.FileDropzone>
      <Dropzone
        accept={webConstants.FORMAT_FILES_ALLOWED.join(',')}
        multiple={false}
        maxFiles={1}
        disabled={files.length > 0}
        onDrop={acceptedFiles => {
          if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
          } else {
            dispatch(
              notificationsActions.notificationShow(
                {
                  title: __('ProductEdit.additionalFiles.format_error.title'),
                  subtitle: __('ProductEdit.additionalFiles.format_error.description', {
                    formats: webConstants.FORMAT_FILES_ALLOWED.join(', '),
                  }),
                  closable: true,
                  style: 'error',
                },
                6000,
              ) as any,
            );
          }
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input
                type="file"
                multiple={false}
                disabled={files.length > 0}
                accept={webConstants.FORMAT_FILES_ALLOWED.join(',')}
                {...getInputProps()}
              />
              <S.UploadFileIcon name="Upload" disableHover={true} />
              <S.TextUpload>
                {__('MagicOrders.add_orders')}
                {files.length ? (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('MagicOrders.orders_added', { count: files.length })}</S.TextUploadGreen>
                  </>
                ) : null}
              </S.TextUpload>
              <S.TextCenter>{__('MagicOrders.add_orders_description')}</S.TextCenter>
              {files.length === 0 ? (
                <S.SelectButton type="secondary">{__('Components.OrdersImport.select')}</S.SelectButton>
              ) : null}
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
    </S.FileDropzone>
  );
};
