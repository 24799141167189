import { __, constants, i18n, parsers } from 'common-services';

import config from '../../../../bindings/config';
import { IColumn, IDataCell } from '../Table/Table.component';

/**
 * Get column according to a custom config
 */

export function getColumnField(
  columnConfig: IColumnConfig,
  catalogId?: number,
  mandatoryPalletization?: boolean,
  options?: {
    getValue?: (data: IProduct, idx: number, rowIdx: number) => string | number;
    getElement?: (data: IProduct, idx: number, rowIdx: number) => React.ReactElement;
    getDataCell?: (data: IProduct, idx: number, rowIdx: number) => IDataCell;
  },
): IColumn {
  const { getValue, getElement, getDataCell } = options || {};
  switch (columnConfig.name) {
    case 'delete':
      return {
        id: 'delete',
        title: '',
        element: getElement,
        width: '7%',
      };
    case 'share':
      return {
        id: 'share',
        title: '',
        element: getElement,
        width: '7%',
      };
    case 'variety':
      return {
        id: 'variety',
        title: __('Components.ProductsList.Table.variety'),
        sort: `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: ['desc', 'asc'],
        value: getValue,
        minWidth: '100px',
        width: '12%',
      };
    case 'title':
      return {
        id: 'title',
        title: __('Components.ProductsList.Table.TypeVariety'),
        sort: `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: ['desc', 'asc'],
        value: getValue,
        minWidth: '100px',
        width: '12%',
        showOver: true,
      };
    case 'type':
      return {
        id: 'type',
        title: __('Components.ProductsList.Table.type'),
        sort: `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: ['desc', 'asc'],
        value: getValue,
        minWidth: '100px',
        width: '12%',
      };
    case 'title-with-image':
      return {
        id: 'title-with-image',
        title: __('Components.ProductsList.Table.TypeVariety'),
        sort: `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: ['desc', 'asc'],
        element: getElement,
        minWidth: '140px',
        width: '20%',
      };
    case 'supplier':
      return {
        id: 'supplier',
        title: __('Components.ProductsList.Table.Supplier'),
        element: getElement,
        minWidth: '120px',
        width: '12%',
      };
    case 'size':
      return {
        id: 'size',
        title: __('Components.ProductsList.Table.Size'),
        minWidth: '70px',
        width: '9%',
      };
    case 'origin':
      return {
        id: 'origin',
        title: __('Components.ProductsList.Table.Origin'),
        value: (data: IProduct) => data.type.origin,
        minWidth: '70px',
        width: '9%',
      };
    case 'category':
      return {
        id: 'category',
        title: __('Components.ProductsList.Table.Category'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) =>
          parsers.getCategoryText(data.category as (typeof constants.PRODUCT_CATEGORIES)[number]),
      };
    case 'packaging':
      return {
        id: 'packaging',
        title: __('Components.ProductsList.Table.Packaging'),
        minWidth: '70px',
        width: '9%',
      };
    case 'box-weight':
      return {
        id: 'box-weight',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.shelfWeight')
          : __('Components.ProductsList.Table.BoxWeight'),
        value: (data: IProduct) => (data.boxWeight ? `${data.boxWeight} ${data.weightUnit}` : ''),
        minWidth: '70px',
        width: '9%',
      };
    case 'box-type':
      return {
        id: 'box-type',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.shelfType')
          : __('Components.ProductsList.Table.BoxType'),
        value: (data: IProduct) => data.box.name,
        minWidth: '70px',
        width: '9%',
      };
    case 'pallet-type':
      return {
        id: 'pallet-type',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.cartType')
          : __('Components.ProductsList.Table.PalletType'),
        value: (data: IProduct) => data.pallet.name,
        minWidth: '70px',
        width: '9%',
      };
    case 'pallet-height':
      return {
        id: 'pallet-height',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.cartHeight')
          : __('Components.ProductsList.Table.PalletHeight'),
        value: (data: IProduct) => data.palletHeight,
        minWidth: '70px',
        width: '9%',
      };
    case 'units-per-box':
      return {
        id: 'units-per-box',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.piecesPerShelf')
          : __('Components.ProductsList.Table.UnitsPerBox'),
        value: (data: IProduct) => data.piecesPerBox,
        minWidth: '70px',
        width: '9%',
      };
    case 'boxes-per-pallet':
      return {
        id: 'boxes-per-pallet',
        title: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? __('Components.ProductDetails.distribution.shelvesPerCart')
          : __('Components.ProductsList.Table.BoxesPerPallet'),
        sort: 'boxes_per_pallet',
        sortOrder: ['desc', 'asc'],
        value:
          config.TOGGLE_BOXES_PER_PALLET.enabled && mandatoryPalletization
            ? (data: IProduct) =>
                data.boxesPerPalletValues?.find(b => b.isDefault)?.value
                  ? `${data.boxesPerPalletValues?.find(b => b.isDefault)?.value} pp`
                  : data.boxesPerPalletValues?.length
                  ? `${data.boxesPerPalletValues[0].value} pp`
                  : ''
            : (data: IProduct) => (data.boxesPerPallet ? `${data.boxesPerPallet} pp` : ''),
        minWidth: '70px',
        width: '9%',
      };
    case 'updated':
      return {
        id: 'updated',
        sort: 'updated_at',
        sortOrder: ['desc', 'asc'],
        title: __('Components.ProductsList.Table.UpdatedAt'),
        value: getValue,
        minWidth: '60px',
        width: '8%',
      };
    case 'price':
      return {
        id: 'price',
        title: __('Components.ProductsList.Table.Price'),
        element: getElement,
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '120px',
        width: '10%',
      };
    case 'price-display':
      return {
        id: 'price-display',
        title: __('Components.ProductsList.Table.Price'),
        element: getElement,
        minWidth: '70px',
        width: '9%',
      };
    case 'status':
      return {
        id: 'status',
        title: __('Components.ProductsList.Table.Visible'),
        sort: `status`,
        sortOrder: ['desc', 'asc'],
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '70px',
        width: '9%',
        align: 'left',
        getDataCell,
      };
    case 'region':
      return {
        id: 'region',
        title: __('Components.ProductsList.Table.Region'),
        minWidth: '70px',
        width: '9%',
      };
    case 'brand':
      return {
        id: 'brand',
        title: __('Components.ProductsList.Table.Brand'),
        minWidth: '70px',
        width: '9%',
      };
    case 'type-of-production':
      return {
        id: 'type-of-production',
        title: __('Components.ProductsList.Table.TypeOfProduction'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) => parsers.getOrganicText(data.organic),
      };
    case 'sku':
      return {
        id: 'sku',
        sort: `sku`,
        sortOrder: ['desc', 'asc'],
        title: __('Components.ProductsList.Table.Sku'),
        minWidth: '70px',
        width: '9%',
      };
    case 'ean':
      return {
        id: 'ean',
        sort: `ean_code`,
        sortOrder: ['desc', 'asc'],
        title: __('Components.ProductsList.Table.Ean'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) => data.eanCode,
      };
    case 'warehouse':
      return {
        id: 'warehouse',
        title: __('Components.ProductsList.Table.Warehouse'),
        minWidth: '120px',
        width: '10%',
        value: getValue,
      };
    case 'product-id':
      return {
        id: 'product-id',
        title: __('Components.ProductsList.Table.unique_id'),
        minWidth: '120px',
        width: '10%',
        value: getValue,
      };
    case 'select-product':
      return {
        id: 'select-product',
        title: '',
        minWidth: '125px',
        width: '10%',
        element: getElement,
      };
    case 'add-product-to-cart':
      return {
        id: 'add-product-to-cart',
        title: '',
        minWidth: '200px',
        width: '14%',
        align: 'right',
        element: getElement,
      };
    default:
      return undefined;
  }
}
