import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';
import SimpleSearch from '../SimpleSearch';

interface IStyledProps {
  isDisabled?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  flex: 1;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingView};
`;

export const AddressItem = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin: calc(${(p: IStyledProps) => p.theme.paddingView} / 2);
  align-items: center;
`;

export const ItemContainer = styled(Container)`
  flex: 1;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
  cursor: ${(p: IStyledProps) => (p.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ItemTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-left: 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const ItemTitleDisabled = styled(ItemTitle)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-style: italic;
  padding-bottom: 0;
  display: inline;
`;

export const AddressText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const AddressIconContainer = styled(RowContainer)`
  width: 32px;
  height: 32px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: center;
  justify-content: center;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2.5)};
`;

export const AddressIcon = styled(FontIcon)`
  width: 20px;
  height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  line-height: 20px;
  font-size: 20px;
`;

export const EmptyContainer = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(15)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Search = styled(SimpleSearch)`
  margin: 0;
  margin-top: 5px;
  flex: 0;
`;

export const AddMoreCta = styled(Button)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  height: auto;
`;
