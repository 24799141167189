import { ColumnContainer, LettersAvatar, RowContainer } from '../components/atoms';
import styled, { ITheme } from '../styled-components';

interface IStyledProps {
    disableHover?: boolean;
    hiddenAtScreenWidth?: string;
    isSearch?: boolean;
    overlay?: boolean;
    selected?: boolean;
    theme: ITheme;
}

export const UserRow = styled(RowContainer)`
  align-items: center;
`;

export const UserAvatar = styled(LettersAvatar)`
margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const UserColumn = styled(ColumnContainer)`
  align-items: flex-start;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;
export const BoldText = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
