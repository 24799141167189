import { XYCoord } from 'dnd-core';
import * as React from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import * as S from './SortableLine.styled';

export interface IProps {
  index: number;
  indexText?: string;
  moveLine: (dragIndex: number, hoverIndex: number) => void;
  onDelete: () => void;
  name: string;
  editMode: boolean;
}

interface DragSection extends ISection {
  index: number;
  type: string;
}

const PricegroupLine: React.FC<IProps> = ({ editMode, index, moveLine, onDelete, name, indexText }) => {
  const dragRef = React.useRef<HTMLDivElement>();

  const [, drop] = useDrop({
    accept: 'section',
    hover(item: DragSection, monitor: DropTargetMonitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse posiPricegroupLinetion
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveLine(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: { type: 'section', name, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (editMode) drag(drop(dragRef));

  return (
    <S.Container className="section-line">
      <S.TextNumber>{indexText || index + 1}</S.TextNumber>
      <S.SectionRow ref={dragRef} isDragging={editMode && isDragging}>
        {editMode ? <S.Icon name="Drag" cursor={isDragging ? 'grabbing' : 'grab'} /> : null}
        <S.TextCell>
          <S.TextTag>{name}</S.TextTag>
        </S.TextCell>
        {editMode ? (
          <S.OptionsCell>
            <S.Divider />
            <S.CloseIcon name="Close" onClick={onDelete} />
          </S.OptionsCell>
        ) : null}
      </S.SectionRow>
    </S.Container>
  );
};

export default React.memo(PricegroupLine);
