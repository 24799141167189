import { __ } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import EmptyListResource from '../EmptyListResource';
import * as S from './NonResults.styled';

interface IProps {
  amSeller: boolean;
  buttonAction?: () => void;
  children: any;
  hasHits: boolean;
  isZeroCase?: boolean;
  searchText: string;
}

const NonResults = React.memo(({ buttonAction, searchText, hasHits, children, isZeroCase = false }: IProps) => {
  return hasHits ? (
    children
  ) : (
    <S.CenterContainer>
      <EmptyListResource
        text={
          searchText
            ? __('Components.ProductsList.QueryEmptyResults', { search: searchText })
            : isZeroCase
            ? __('Components.ProductsList.EmptyResults.zero_case')
            : __('Components.ProductsList.EmptyResults.buyer')
        }
        text2={searchText ? __('Components.ProductsList.EmptyResultsTryAgain', { search: searchText }) : ''}
        showButton={isZeroCase}
        buttonText={__('Components.ProductsList.EmptyResults.zero_case_cta')}
        buttonType="principal"
        buttonIcon="Add-more"
        buttonAction={buttonAction}
        imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
      />
    </S.CenterContainer>
  );
});
export default NonResults;
