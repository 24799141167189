import * as React from 'react';

import { LettersAvatar } from '..';
import RadioInput from '../RadioInput';
import Tooltip from '../Tooltip';
import * as S from './ContactSelectable.styled';

interface IProps {
  action?: 'remove' | 'switch' | 'radio' | 'none';
  children?: React.ReactNode;
  className?: string;
  contact: ILightContact;
  containerClickable?: boolean;
  disabled?: boolean;
  extraInfo?: string;
  isLast: boolean;
  isSelected: boolean;
  label?: string;
  labelColor?: 'blue' | 'green' | 'grey';
  onSelectContact: () => void;
  subtitle?: string;
  title?: string;
  tooltipAction?: (cId: number) => void;
  tooltipDisabledText?: string;
}

const ContactSelectable: React.FC<IProps> = ({
  action,
  children,
  className,
  contact,
  containerClickable = true,
  disabled = false,
  extraInfo,
  isLast,
  isSelected,
  label,
  labelColor,
  onSelectContact,
  subtitle,
  title,
  tooltipAction,
  tooltipDisabledText,
}) => {
  return (
    <S.ContactContainer
      disabled={disabled}
      isLast={isLast}
      className={className}
      onClick={() => {
        if (action !== 'remove' && containerClickable && !disabled) onSelectContact();
      }}
    >
      {action !== 'none' ? (
        <S.ActionWrapper onClick={disabled ? undefined : onSelectContact}>
          {action === 'switch' || action === 'radio' ? (
            <Tooltip
              disabled={!disabled}
              onLinkClick={() => tooltipAction?.(contact.id)}
              position="right"
              text={tooltipDisabledText}
              width="300px"
              themeMode="dark"
            >
              <RadioInput checked={isSelected} value="" isRadio={action === 'radio'} disabled={disabled} />
            </Tooltip>
          ) : null}
          {action === 'remove' ? <S.CloseIcon name="Close" id="remove-contact-icon" /> : null}
        </S.ActionWrapper>
      ) : null}
      <S.Icon>
        <LettersAvatar
          size={35}
          text={contact.name}
          img={contact.avatar}
          avatarColor={contact.avatarColor}
          disabled={disabled}
        />
      </S.Icon>
      <S.Info>
        <S.Row>
          <S.InfoTitle disabled={disabled}>
            {title || contact.name || contact.email || contact.phoneNumbers[0]}
          </S.InfoTitle>{' '}
          {extraInfo ? <S.TitleInfo disabled={disabled}>{extraInfo}</S.TitleInfo> : null}
          {label ? <S.Label color={labelColor}>{label}</S.Label> : null}
        </S.Row>
        <S.InfoSubtitle disabled={disabled}>{subtitle || contact.companyName}</S.InfoSubtitle>
      </S.Info>
      {children ? <S.InfoRight>{children}</S.InfoRight> : null}
    </S.ContactContainer>
  );
};

export default React.memo(ContactSelectable);
