import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, RowContainer, TableHeader, TextLabel } from '../../atoms';
import FormSection from '../FormSection';
import MI from '../MiniImages';

interface IStyledProps {
  color?: string;
  cursor?: string;
  disabled?: boolean;
  hiddenAtScreenWidth?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isReadRow?: boolean;
  minWidth?: string;
  selectable?: boolean;
  status?: string;
  theme: ITheme;
  width?: string;
}

export const Table = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-spacing: 0 5px;
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  height: 69px;
  background-color: ${(p: IStyledProps) => (p.isReadRow ? p.theme.colors.grey4 : p.theme.colors.white)};
  cursor: ${(p: IStyledProps) => p.cursor || 'pointer'};
  & .chat {
    display: block;
    float: right;
  }
`;

export const EmptyTr = styled.tr`
  height: 80px;
`;

export const Col = styled.td<IStyledProps>`
  min-width: 40px;
  max-width: 170px;
  vertical-align: middle;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  ${(p: IStyledProps) =>
    p.hiddenAtScreenWidth &&
    css`
      @media only screen and (max-width: ${p.hiddenAtScreenWidth}) {
        display: none;
      }
    `};
  ${(p: IStyledProps) =>
    p.width &&
    css`
      width: ${p.width};
    `};
  ${(p: IStyledProps) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth};
    `};
  ${(p: IStyledProps) =>
    p.isLast &&
    css`
      padding-right: ${p.theme.paddingSize(5)};
      text-align: right;
    `};

  ${(p: IStyledProps) =>
    p.isFirst &&
    css`
      padding-left: ${p.theme.paddingSize(5)};
    `};

  ${(p: IStyledProps) =>
    p.isFirst &&
    p.selectable &&
    css`
      padding-left: ${p.theme.paddingSize(1)};
    `};
`;

export const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.color || p.theme.colors.black};
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const TextEmpty = styled(Text)`
  text-align: left;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const SectionsInfoCol = styled(ColumnContainer)`
  width: auto;
  min-height: min-content;
`;

export const SectionsTabCol = styled(ColumnContainer)`
  width: 100%;
  margin: ${(p: IStyledProps) => p.theme.paddingView} 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingView};
  overflow-y: auto;
  min-height: 300px;
`;

export const HeaderCell = styled(RowContainer)`
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  align-items: center;
`;

export const ImageCell = styled(RowContainer)`
  width: 60px;
  max-width: 60px;
  min-width: 60px;
`;

export const RankText = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const InfoText = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  white-space: nowrap;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(7)};
`;

export const SectionRow = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  align-items: center;
`;
export const CTARow = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  justify-content: space-around;
`;
export const CTAOk = styled(Button)`
  width: 180px;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
`;
export const CTACancel = styled(Button)`
  width: 180px;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
`;
export const FormSectionCustom = styled(FormSection)`
  overflow: auto;
`;

export const TextError = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
export const SelectContainer = styled.div<IStyledProps>`
  width: auto;
  position: relative;
  padding-right: ${(p: IStyledProps) => (p.isLast ? '0' : p.theme.paddingSize(2))};
`;
const getBubbleColor = (p: IStyledProps) => {
  if (p.status === 'inactive') return p.theme.colors.grey2;
  if (p.status === 'active') return p.theme.colors.green1;
  if (p.status === 'unavailable') return p.theme.colors.red1;
  return p.theme.colors.green1;
};
const StatusBubble = styled.div<IStyledProps>`
  width: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  height: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  border-radius: 50%;
  background-color: ${getBubbleColor};
`;

export const StatusBubbleAbsolute = styled(StatusBubble)`
  width: ${(p: IStyledProps) => p.theme.fontSize.small};
  height: ${(p: IStyledProps) => p.theme.fontSize.small};
  border-radius: 50%;
  background-color: ${getBubbleColor};
  position: absolute;
  left: 12px;
  bottom: ${(p: IStyledProps) => (p.disabled ? '10.5px' : '18.5px')};
  transform: translateY(50%);
`;

export const MiniImages = styled(MI)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(p.isLast ? 0 : 3)};
`;
export const FooterText = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  position: absolute;
`;
export const BottomText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;
export const TableHeaderOver = styled(TableHeader)`
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;
