import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, Input as TextInput, TextLabel } from '../../atoms';

interface IStyledProps {
  mode?: 'email-form' | 'success';
  theme: ITheme;
}

export const BodyContainer = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(10)};
  flex: 1;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const MainImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  align-self: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TextTitle = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  line-height: 1.4;
  text-align: ${(p: IStyledProps) => (p.mode === 'success' ? 'center' : 'left')};
`;

export const TextDescription = styled(TextTitle)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Input = styled(TextInput)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const TextFooter = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  text-align: center;
`;

export const TextAccount = styled(TextFooter)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
`;

export const LinkLogin = styled(TextAccount)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const FooterContainer = styled(ColumnContainer)`
  align-self: center;
`;

export const CTAForm = styled(Button)`
  width: 160px;
  align-self: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CTAOk = styled(Button)`
  width: 160px;
  align-self: center;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
`;
