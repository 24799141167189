import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';

interface IStyledProps {
  theme: ITheme;
  toShow?: boolean;
  kind?: 'info' | 'warning' | 'error' | 'success';
}

export const Container = styled.div`
  display: ${(p: IStyledProps) => (p.toShow ? 'flex' : 'none')};
  max-width: 500px;
  min-width: 300px;
  height: 50px;
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  padding: 5px ${(p: IStyledProps) => p.theme.paddingObject};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  text-align: left;
  border-radius: 10px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-left: 8px solid ${(p: IStyledProps) => getColor(p.kind, p.theme)};
  z-index: 200;
`;

export const Content = styled(RowContainer)`
  flex: 1;
  align-items: center;
`;

export const MessageContainer = styled(ColumnContainer)`
  margin-right: calc(${(p: IStyledProps) => p.theme.paddingObject} * 2);
`;

export const Icon = styled.div`
  color: ${(p: IStyledProps) => getColor(p.kind, p.theme)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  align-self: center;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Subtitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
`;

function getColor(style: string, theme: ITheme): string {
  if (style === 'error') return theme.colors.red1;
  if (style === 'warning') return theme.colors.orange1;
  if (style === 'success') return theme.colors.green1;
  return theme.colors.blue1;
}
