import { utils } from 'common-services';
import * as React from 'react';

import * as S from './Tooltip.styled';

export type Position = 'top' | 'bottom' | 'bottom-left' | 'bottom-right' | 'right' | 'left';
export interface IProps {
  children: React.ReactNode;
  component?: React.ReactNode;
  className?: string;
  container?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  image?: string;
  onClose?: () => void;
  onLinkClick?: (index: number) => void;
  position?: Position;
  showed?: boolean;
  text?: string;
  themeMode?: 'light' | 'dark';
  title?: string;
  width?: string;
  keepPropagation?: boolean;
}

interface IState {
  isVisible: boolean;
  positions?: string;
}

/**
 * Tooltip container
 */
class Tooltip extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement>;
  private tooltipRef: React.RefObject<HTMLDivElement>;
  private appendiceRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.state = { isVisible: false };
    this.containerRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.appendiceRef = React.createRef();
  }

  public componentDidMount() {
    this.handleTooltipPosition();
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('scroll', this.handleTooltipPosition, true);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('scroll', this.handleTooltipPosition, true);
  }

  public render() {
    const {
      children,
      component,
      className,
      disabled,
      image,
      onClose,
      onLinkClick,
      position,
      showed,
      text,
      themeMode = 'light',
      title,
      width,
      keepPropagation,
    } = this.props;
    const { isVisible } = this.state;
    if (disabled) return children;
    return (
      <S.Container
        className={className}
        ref={this.containerRef}
        onMouseEnter={this.handleTooltipPosition}
        onClick={e => {
          if (!keepPropagation) e.stopPropagation();
          this.setState({ isVisible: !isVisible });
        }}
      >
        {children}
        <S.Tooltip
          ref={this.tooltipRef}
          className="tooltip"
          closeable={!!onClose}
          isVisible={showed || isVisible}
          width={width}
          themeMode={themeMode}
        >
          {onClose ? <S.CloseIcon name="Close" onClick={onClose} /> : null}
          {title ? <S.Title themeMode={themeMode}>{title}</S.Title> : null}
          {component ? <>{component}</> : null}
          {text ? (
            <S.Text themeMode={themeMode}>
              {
                utils.formatText(text, (txt: string, index: number) =>
                  txt ? (
                    <S.TooltipTextLink key={txt} onClick={() => onLinkClick?.(index)}>
                      {txt}
                    </S.TooltipTextLink>
                  ) : null,
                ) as any
              }
            </S.Text>
          ) : null}
          {image ? <S.Image src={image} /> : null}
          <S.Appendice ref={this.appendiceRef} position={position} themeMode={themeMode} />
        </S.Tooltip>
      </S.Container>
    );
  }

  /**
   * Handle click outside to close tooltip
   */
  private handleClickOutside = () => {
    this.setState({ isVisible: false });
  };

  private handleTooltipPosition = () =>
    setTimeout(() => {
      // Define screen padding for edge cases
      const screenPadding = 18;

      // Check if all required elements are available, otherwise, exit the function
      if (!this.tooltipRef?.current || !this.containerRef?.current || !this.appendiceRef?.current) return;

      // Reset styles to their default values
      this.tooltipRef.current.style.right = '';
      this.tooltipRef.current.style.bottom = '';
      this.tooltipRef.current.style.transform = '';
      this.appendiceRef.current.style['margin-left'] = '';
      this.appendiceRef.current.style.transform = '';

      // Get bounding rectangle of the container element
      const placeholderRect = this.containerRef.current.getBoundingClientRect();

      // Calculate and set the left and top positions of the tooltip
      this.tooltipRef.current.style.left = this.getTooltipLeft(placeholderRect);
      this.tooltipRef.current.style.top = this.getTooltipTop(placeholderRect);

      // Adjust tooltip position based on its size and screen boundaries
      this.tooltipRef.current.style.transform = this.getTransform();
      const tooltipRect = this.tooltipRef.current.getBoundingClientRect();

      // Calculate the rightmost edge of the dropdown relative to the viewport
      const dropdownRightX = tooltipRect.x + tooltipRect.width;

      // Handle cases where the tooltip overflows the viewport horizontally
      if (tooltipRect.x < 0) {
        // If the tooltip is overflowing to the left
        this.tooltipRef.current.style.left = '0';
        this.tooltipRef.current.style.right = 'auto';
        this.tooltipRef.current.style.transform = `translateX(${screenPadding}px)`;
        this.appendiceRef.current.style.transform = `translateX(${tooltipRect.x - screenPadding}px)`;
      } else if (dropdownRightX > window.outerWidth) {
        // If the tooltip is overflowing to the right
        this.tooltipRef.current.style.left = 'auto';
        this.tooltipRef.current.style.right = `${screenPadding}px`;
        this.tooltipRef.current.style.transform = `translateX(0px)`;
        this.appendiceRef.current.style.transform = `translateX(${
          tooltipRect.x + tooltipRect.width - window.outerWidth + screenPadding
        }px)`;
      }
    });

  private getTransform = () => {
    const { position } = this.props;
    switch (position) {
      case 'top':
        return 'translate(-50%, -100%)';
      case 'bottom':
        return 'translate(-50%, 0)';
      case 'bottom-left':
        return 'translate(-90%, 0)';
      case 'bottom-right':
        return 'translate(-10%, 0)';
      case 'right':
        return 'translate(0, -50%)';
      case 'left':
        return 'translate(-100%, -50%)';
      default:
        return 'translate(-50%, 0)';
    }
  };

  private getTooltipTop = (placeholderRect: DOMRect) => {
    const { position } = this.props;
    switch (position) {
      case 'top':
        return `calc(${placeholderRect.top}px - 10px)`;
      case 'right':
        return `calc(${placeholderRect.top}px + ${placeholderRect.height / 2}px)`;
      case 'bottom':
        return `calc(${placeholderRect.top}px + ${placeholderRect.height}px + 10px)`;
      case 'bottom-left':
        return `calc(${placeholderRect.top}px + ${placeholderRect.height}px)`;
      case 'bottom-right':
        return `calc(${placeholderRect.top}px + ${placeholderRect.height}px)`;
      case 'left':
        return `calc(${placeholderRect.top}px + ${placeholderRect.height / 2}px)`;
      default:
        return `calc(${placeholderRect.top}px + ${placeholderRect.height}px)`;
    }
  };

  private getTooltipLeft = (placeholderRect: DOMRect) => {
    const { position } = this.props;
    switch (position) {
      case 'top':
        return `calc(${placeholderRect.width * 0.5}px + ${placeholderRect.left}px)`;
      case 'right':
        return `calc(${placeholderRect.left}px + ${placeholderRect.width}px + 10px)`;
      case 'bottom-right':
        return `calc(${placeholderRect.width * 0.75}px + ${placeholderRect.left}px)`;
      case 'bottom-left':
        return `calc(${placeholderRect.width * 0.25}px + ${placeholderRect.left}px)`;
      case 'left':
        return `calc(${placeholderRect.left}px - 10px)`;
      default:
        return `calc(${placeholderRect.width * 0.5}px + ${placeholderRect.left}px)`;
    }
  };
}

export default Tooltip;
