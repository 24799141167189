import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel, Tooltip as TooltipAtom } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  isDragActive?: boolean;
  selected?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Header = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
`;

export const CTA = styled(Button)``;

export const CenterContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled(RowContainer)`
  align-items: center;
  max-width: 350px;
  width: 350px;
`;

export const Stats = styled(TextLabel)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const Row = styled(RowContainer)`
  align-items: center;
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const ImportContainer = styled.div`
  position: absolute;
  top: 0;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 50;
`;
export const ImportModal = styled.div`
  margin: 0 auto;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  width: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: fit-content;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const DropzoneContent = styled(ColumnContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-width: 1px;
  border-style: ${(p: IStyledProps) => (p.isDragActive ? 'solid' : 'dashed')};
  border-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.black : p.theme.colors.grey1)};
  background-color: ${(p: IStyledProps) => (p.isDragActive ? p.theme.colors.light2 : p.theme.colors.white)};
  width: 100%;
  height: auto;
  min-height: min-content;
  &:focus {
    outline: none;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 450px;
  }
`;

export const SelectButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const UploadButton = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-self: start;
`;

export const UploadFileIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

const TextSubtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  align-items: center;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const TextUpload = styled(TextSubtitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextUploadGreen = styled(TextUpload)`
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 0;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
export const TextCenter = styled(TextLabel)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
export const ImportTitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  position: relative;
`;
export const ImportSubtitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  display: inline;
`;
export const LinkText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  display: inline;
  cursor: pointer;
`;

export const PriceGroupDropzone = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  align-items: center;
`;
export const DropzoneText = styled(RowContainer)`
  display: inline;
`;
