import { ORDER_STATUS, WORKING_STATUS } from 'common-services';
import * as React from 'react';

import config from '../../../../bindings/config';
import { Tooltip } from '../../atoms';
import * as S from './ContactCell.styled';

interface IProps {
  avatar: string;
  avatarColor?: {
    background: string;
    text: string;
  };
  avatarSize?: number;
  className?: string;
  imgText?: string;
  isRead?: boolean;
  primaryText: string;
  secondaryText?: string;
  showOver?: boolean;
  showGrey?: boolean; // Show text in grey2 color
  status?: ORDER_STATUS;
  type?: string;
  workingStatus?: WORKING_STATUS;
}

const ContactCell: React.FC<IProps> = React.memo(
  ({
    avatar,
    avatarColor,
    avatarSize = 30,
    className,
    imgText,
    isRead = true,
    primaryText,
    secondaryText,
    showOver,
    showGrey,
    status,
    type,
    workingStatus,
  }) => {
    return (
      <S.FlexRow className={className}>
        <S.ContactImagesRow>
          <S.ContactImage
            avatarColor={avatarColor}
            img={avatar}
            size={avatarSize}
            text={imgText || primaryText}
            type={type}
            workingStatus={workingStatus}
            status={status}
          />
        </S.ContactImagesRow>
        <S.ContactNameColumn>
          {config.TOGGLE_PRODUCT_TOOLTIP.enabled ? (
            <Tooltip text={primaryText} themeMode="dark" position="right" disabled={!showOver} keepPropagation={true}>
              <S.TextBlack isRead={isRead} status={status} showGrey={showGrey}>
                {primaryText}
              </S.TextBlack>
            </Tooltip>
          ) : (
            <S.TextBlack isRead={isRead} status={status} showGrey={showGrey}>
              {primaryText}
            </S.TextBlack>
          )}
          <S.TextSecondary isRead={primaryText ? true : isRead} status={status} showGrey={showGrey}>
            {secondaryText}
          </S.TextSecondary>
        </S.ContactNameColumn>
      </S.FlexRow>
    );
  },
);

export default React.memo(ContactCell);
