import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  width?: string;
}

export const Container = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  width: calc(${(p: IStyledProps) => p.width || '25%'});
  height: 160px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.5)')};
  min-width: 250px;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  overflow: hidden;
  max-width: 350px;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const TitleContainer = styled(RowContainer)`
  width: 100%;
  height: 25%;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const Text = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const TextTitle = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: center;
`;
