import * as React from 'react';
import { useHistory } from 'react-router-dom';

import ProductListShare from '../../../screens/product-list-share';

import * as S from './OrdersList.styled';

interface ShareModalProps {
  amSeller: boolean;
  ownerId: number;
  catalogId: number;
  showShare: boolean;
  setShowShare: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareModalComponent: React.FC<ShareModalProps> = ({ amSeller, ownerId, catalogId, showShare, setShowShare }) => {
  const history = useHistory();

  if (!showShare) {
    return null;
  }

  return (
    <S.ModalShare>
      <ProductListShare
        amSeller={amSeller}
        close={() => setShowShare(false)}
        history={history}
        ownerId={ownerId}
        catalogId={catalogId}
      />
    </S.ModalShare>
  );
};

export const ShareModal = React.memo(ShareModalComponent);
