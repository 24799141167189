import {
  __,
  CHANNEL_DISPLAY,
  CHANNEL_TYPE,
  chatActions,
  constants,
  date,
  DATE_FORMAT,
  HOUR_FORMAT,
  IAvatarColor,
  IContact,
  IInvitedUser,
  INotification,
  INVITE_ORIGIN,
  IPlatform,
  utils,
  VIA,
} from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';

import Badge from '../Badge';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import LettersAvatar from '../LettersAvatar';
import SimpleDropdown from '../SimpleDropdown';
import * as S from './ChatCard.styled';

export interface IProps {
  amActive: boolean;
  avatarColor: IAvatarColor;
  badge?: number;
  channelArchive?: typeof chatActions.channelArchive;
  channelId: string;
  channelMute?: typeof chatActions.channelMute;
  className?: string;
  contact?: IContact;
  contactPhonebook?: IContact;
  dateFormat: DATE_FORMAT;
  featuredSubtitle?: string;
  hourFormat: HOUR_FORMAT;
  imBlocking?: boolean;
  img: string;
  isArchived: boolean;
  isBroadcast?: boolean;
  isGroup?: boolean;
  isInvited?: boolean;
  isLast?: boolean;
  isMuted?: boolean;
  isNew?: boolean;
  isSelected?: boolean;
  lastTimeInvited: number;
  myId: number;
  notificationShow?: (notif: INotification, millis: number) => void;
  onClick: (
    channelId: string,
    type: CHANNEL_TYPE,
    contactId?: number,
    setInvited?: (invited: boolean) => void,
    contactPhonebook?: IContact,
  ) => void;
  resendInvite?: (
    myId: number,
    via: VIA,
    platform: IPlatform,
    origin: INVITE_ORIGIN,
    invitedUsers?: Array<IInvitedUser>,
    cb?: (data: { errors?: Array<string>; contacts?: Array<IContact>; inviteLink?: string }) => void,
    channelId?: string,
    catalogId?: number,
  ) => void;
  searchText?: string;
  sendInvite?: (contact?: IContact) => void;
  showOpen?: boolean;
  subtitle: string;
  time?: number;
  title: string;
  toInvite?: boolean;
  type: CHANNEL_TYPE;
  updateBroadcast?: (
    myId: number,
    bId: string,
    name: string,
    display?: CHANNEL_DISPLAY,
    cb?: (error?: Error) => void,
  ) => void;
}

export default React.memo(
  ({
    amActive,
    avatarColor,
    badge,
    channelArchive,
    channelId,
    channelMute,
    className,
    contact,
    contactPhonebook,
    dateFormat,
    featuredSubtitle,
    hourFormat,
    imBlocking,
    img,
    isArchived,
    isBroadcast,
    isInvited,
    isLast,
    isMuted,
    isNew,
    isSelected,
    lastTimeInvited,
    myId,
    notificationShow,
    onClick,
    resendInvite,
    searchText,
    sendInvite,
    showOpen,
    subtitle,
    time,
    title,
    toInvite,
    type,
    updateBroadcast,
  }: IProps) => {
    const [invited, setInvited] = React.useState(false);

    const archiveNotificationCallback = React.useCallback(
      (error?: Error, toArchive?: boolean) => {
        if (error) {
          notificationShow?.(
            {
              title: __('Notification.error.title'),
              subtitle: __('Notification.error.description'),
              closable: true,
              style: 'error',
            },
            2000,
          );
        } else {
          const archivedLiterals = constants.getArchivedNotificationLiterals(toArchive, type);
          notificationShow?.(
            {
              title: archivedLiterals.title,
              subtitle: archivedLiterals.subtitle,
              closable: true,
              style: 'info',
            },
            2000,
          );
        }
      },
      [type, notificationShow],
    );

    const handleChatOptions = React.useCallback(
      value => {
        if (!channelId) return;
        if (value === 'archive' || value === 'unarchive') {
          const toArchive = value === 'archive';
          if (isBroadcast) {
            updateBroadcast?.(
              myId,
              channelId,
              title,
              toArchive ? CHANNEL_DISPLAY.ARCHIVED : CHANNEL_DISPLAY.NORMAL,
              (err: Error) => archiveNotificationCallback(err, toArchive),
            );
          } else {
            channelArchive?.(myId, channelId, toArchive, (err: Error) => archiveNotificationCallback(err, toArchive));
          }
        }
        if (value === 'mute') {
          channelMute?.(myId, channelId, !isMuted);
        }
      },
      [
        archiveNotificationCallback,
        channelArchive,
        channelId,
        channelMute,
        isBroadcast,
        isMuted,
        myId,
        title,
        updateBroadcast,
      ],
    );
    const channelOptions = getChannelOptions();
    return (
      <S.Container
        channelId={channelId}
        className={className}
        hasChannelOptions={!!channelOptions.length}
        isLast={isLast}
        isSelected={isSelected}
        onClick={() => onClick(channelId, type, contact?.id, setInvited, contactPhonebook)}
      >
        <S.Icon showOpen={showOpen} badgeCount={imBlocking ? 0 : badge}>
          <LettersAvatar
            size={35}
            text={title}
            img={img}
            avatarColor={avatarColor || { background: '', text: '' }}
            type={type}
          />
        </S.Icon>
        <S.FirstRow>
          <S.InfoTitle>
            <>
              {type === 'group' ? <S.Group>{__('Components.ChatList.group')}</S.Group> : null}
              {type === 'broadcast' ? <S.Broadcast>{__('Components.Channel.broadcast')}</S.Broadcast> : null}
              {searchText
                ? utils.highlightWordsWithPattern(
                    title,
                    searchText,
                    (s, i) => <S.InfoTitleHighlight key={i}>{s}</S.InfoTitleHighlight>,
                    { recognize: 'include' },
                  )
                : title}
            </>
          </S.InfoTitle>
          {imBlocking ? (
            <S.Blocked>{__('Components.ChatList.blocked')}</S.Blocked>
          ) : (
            <>
              {time ? (
                <S.Time className="chat-card-time">
                  {' '}
                  {date.timeToText(time, hourFormat, undefined, undefined, dateFormat)}
                </S.Time>
              ) : null}
              {channelId && channelOptions.length ? (
                <S.ArrowContainer className="arrow-options">
                  <SimpleDropdown options={channelOptions} hAlign="right" onSelect={handleChatOptions}>
                    <S.ArrowOptions name="Down" />
                  </SimpleDropdown>
                </S.ArrowContainer>
              ) : null}
            </>
          )}
        </S.FirstRow>
        {isInvited ? (
          <S.SecondRow>
            <S.Invited>
              {__('Components.ChatList.invited')}{' '}
              {lastTimeInvited
                ? __('Components.ChatList.invited_days', {
                    count: differenceInCalendarDays(Date.now(), lastTimeInvited),
                  })
                : null}
            </S.Invited>
            {isInvited && !isArchived ? (
              <S.ResendInvitation
                onClick={e => {
                  e.stopPropagation();
                  resendInvite?.(
                    myId,
                    contact.email ? VIA.EMAIL : VIA.SMS,
                    'web',
                    INVITE_ORIGIN.REGULAR,
                    contact.email
                      ? [{ name: contact.name || contact.email, email: contact.email, phone: '', counterpart_id: '' }]
                      : [
                          {
                            name: contact.name || contact.phoneNumbers[0],
                            email: '',
                            phone: contact.phoneNumbers[0],
                            counterpart_id: '',
                          },
                        ],
                  );
                  setInvited(true);
                }}
                invited={invited}
              >
                {invited ? __('Components.ChatList.sent_invitation') : __('Components.ChatList.resend_invitation')}
              </S.ResendInvitation>
            ) : null}
            {toInvite ? (
              <S.SendInvitation
                onClick={e => {
                  e.stopPropagation();
                  setInvited(true);
                  sendInvite?.(contact);
                }}
                invited={invited}
              >
                {invited ? __('Components.ChatList.sent_invitation') : __('Components.ChatList.invite_card.cta')}
              </S.SendInvitation>
            ) : null}
          </S.SecondRow>
        ) : featuredSubtitle ? (
          <S.InfoFeaturedSubtitle>{featuredSubtitle}</S.InfoFeaturedSubtitle>
        ) : (
          <S.InfoSubtitle>
            {searchText
              ? (utils.highlightWordsWithPattern(
                  subtitle,
                  searchText,
                  (s, i) => <S.InfoSubtitleHighlight key={i}>{s}</S.InfoSubtitleHighlight>,
                  { recognize: 'include' },
                ) as any) // TYPEERROR
              : subtitle}
          </S.InfoSubtitle>
        )}
        {imBlocking ? (
          <S.FontIconWrapper id="block-icon">
            <FontIcon name="Block" disableHover={true} />
          </S.FontIconWrapper>
        ) : (
          <>
            {!isArchived ? (
              <RowContainer style={{ gridRow: 2, gridColumn: 3, justifySelf: 'end' }}>
                {isMuted ? (
                  <S.FontIconWrapper id="muted-icon">
                    <FontIcon name="Notification-off" disableHover={true} />
                  </S.FontIconWrapper>
                ) : null}
                {badge && !imBlocking ? <Badge className="chat-card-badge" count={badge} /> : null}
                {isNew && !isInvited && !isArchived ? <S.New>{__('Components.ChatList.new')}</S.New> : null}
              </RowContainer>
            ) : null}
            {isArchived ? <S.Archived>{__('Components.ChatList.archived')}</S.Archived> : null}
          </>
        )}
      </S.Container>
    );

    function getChannelOptions() {
      const result = [];
      if (channelArchive)
        result.push({
          key: isArchived ? 'unarchive' : 'archive',
          value: isArchived ? __('Components.ChatList.options.unarchive') : __('Components.ChatList.options.archive'),
          icon: 'Archive',
        });
      if (!isBroadcast && amActive && channelMute)
        result.push({
          key: 'mute',
          value: isMuted ? __('Components.ChatList.options.unmute') : __('Components.ChatList.options.mute'),
          icon: isMuted ? 'Notification' : 'Notification-off',
        });
      return result;
    }
  },
);
