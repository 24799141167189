import { countryActions, EventTrack, IGlobalWeb, IUser, prodTypeActions } from 'common-services';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Store } from 'redux';

import config from '../../bindings/config';
import { messaging } from '../../setupFcm';
import { initializeDesktopNotifications, notificationShowHeader } from '../actions/notification';
import { autoLogin, initializeApp, verifyEmail } from '../actions/user';
import { IReduxState } from '../reducers';
import { bcDebug } from '../services/log';

declare var global: IGlobalWeb;

/**
 * Retrieve list of countries if we have no countries or we change language.
 * Current language: take it from localStorage (last language related to user) or navigator one by default
 */
function setupCountries(store: Store<IReduxState>) {
  const {
    country: { language },
    user: { user },
  } = store.getState();
  const currentLanguage = global.localStorage.getItem('language') || window.navigator.language;
  const userLanguage = user.id ? user.settings.language : undefined;

  if (!language || (userLanguage && language !== userLanguage)) {
    store.dispatch(countryActions.countriesGet((userLanguage || currentLanguage).substring(0, 2)) as any);
  }
}

/**
 * triggers the first population of product types into the store
 * @param store the redux store we're going to populate
 */
function setupProdTypes(store: Store<IReduxState>) {
  const {
    prodType: { language },
    user: { user },
  } = store.getState();
  const currentLanguage = global.localStorage.getItem('language') || window.navigator.language;
  const userLanguage = user.id ? user.settings.language : undefined;

  if (!language || (userLanguage && language !== userLanguage)) {
    store.dispatch(prodTypeActions.prodTypesGet((userLanguage || currentLanguage).substring(0, 2)) as any);
  }
}

/**
 * Set up locales for react dates component
 * TODO: ca language not importable, see issue https://github.com/date-fns/date-fns/issues/1248
 */
function setupReactDatesLanguage(user: IUser) {
  const datesLocales = { es, en, fr, nl, it, de };
  for (const local in datesLocales) {
    if (datesLocales.hasOwnProperty(local)) registerLocale(local, datesLocales[local]);
  }
  const currentLanguage = global.localStorage.getItem('language') || window.navigator.language;
  const userLanguage = user.id ? user.settings.language : undefined;
  setDefaultLocale((userLanguage || currentLanguage).substring(0, 2));
}

function setUserSegment(user: IUser, storeLength: number) {
  bcDebug('hooks', 'state_ready - segment user', '');
  global.segment.userInfo({ user });
  EventTrack.start((storeLength * 2) / 1024, global.startTime);
}

/**
 * Show / hide notification header
 * Initialize desktop notif if permission is granted
 */
function setUpNotifications(store: Store<IReduxState>) {
  if (!config.TOGGLE_PUSH_NOTIFICATIONS.enabled || !messaging || !Notification) return;
  store.dispatch(notificationShowHeader(Notification?.permission === 'default'));
  if (Notification.permission === 'granted') store.dispatch(initializeDesktopNotifications() as any);
}

/**
 * Callback executed when store state is ready.
 * If userId exists, initialize app.
 * If accessToken & refreshToken are stored in localStorage, retrieve userInfo and initialize app.
 */
export default function stateReady(store: Store<IReduxState>): void {
  bcDebug('hooks', 'state_ready', '');
  const state = store.getState();
  const user = state.user.user;
  const accessToken = localStorage.getItem('accessToken');
  if (user.id && user.hashId && accessToken) {
    setUserSegment(user, JSON.stringify(state).length);
    store.dispatch(initializeApp(user) as any);
  } else {
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken && accessToken !== 'null' && refreshToken !== 'null') {
      store.dispatch(autoLogin() as any);
    } else {
      store.dispatch(verifyEmail() as any);
    }
  }
  setupCountries(store);
  setupProdTypes(store);
  setupReactDatesLanguage(user);
  setUpNotifications(store);
}
