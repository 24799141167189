import {
  __,
  chatActions,
  EventTrack,
  INVITE_ORIGIN,
  modalActions,
  notificationsActions,
  RenderTrack,
  utils,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as navActions from '../../../actions/nav';
import { ACCEPT_ANIMATED_IMAGES, ADMIN_OWNER_ROLES, MAX_PICTURES_PER_POST, ROUTE_PATHS } from '../../../constants';
import { convertToIFile } from '../../../services/file';
import share from '../../../services/sharer';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { IOSLoading, Picture } from '../../atoms';
import { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import {
  ChannelModal,
  ChatPublicHeader,
  ForwardMessage,
  InviteLinkModal,
  PostMessage,
  PostWriteModal,
} from '../../molecules';
import * as CS from '../ChatPublic/ChatPublic.styled';
import * as S from './ChatPublicSinglePost.styled';

export type IRouteProps = RouteComponentProps<{ channelId?: string; postId?: string }>;
export interface IStateProps {
  channel: IChannel;
  channels: { [id: string]: IChannel };
  contacts: { [contactId: number]: IContact };
  initialized: boolean;
  isModal?: boolean;
  lastMessageAt: Record<string, number>;
  lastReadContactAt: number;
  me: IUser;
  post?: IMessage;
  postId?: number;
  unreadMessageCount: number;
}

export interface IDispatchProps {
  deleteMessage: typeof chatActions.deleteMessage;
  editMessage: typeof chatActions.editMessage;
  getMessage: typeof chatActions.getMessage;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navMiniChannelAction: typeof navActions.navMiniChannelAction;
  notificationShow: typeof notificationsActions.notificationShow;
  openChannel: typeof chatActions.openChannel;
  sendMessage: typeof chatActions.sendMessage;
  setActiveChannel: typeof chatActions.setActiveChannel;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = IStateProps &
  IDispatchProps &
  IRouteProps & { onClose?: () => void; onMinimize?: () => void; styleModal?: React.CSSProperties };

interface IState {
  imagePreviews?: Array<IImagePreview>;
  inviteLink?: string;
  minimized: boolean;
  post?: IMessage;
  postToEdit?: IMessage;
  postToShare?: IMessage;
  showPostLinkModal: boolean;
  showSendLinkModal: boolean;
  showWritePost: boolean;
}

/**
 * Public channel: post single view
 */
class ChatPublicSinglePost extends React.PureComponent<IProps, IState> {
  private t: number;
  private channelOpened: boolean;
  private pictureRef: React.RefObject<Picture>;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.pictureRef = React.createRef();
    this.state = {
      minimized: false,
      post: props.post,
      showPostLinkModal: false,
      showSendLinkModal: false,
      showWritePost: false,
    };
  }

  public componentDidMount() {
    const { channel, initialized, openChannel, me } = this.props;

    if (channel && initialized) {
      RenderTrack.track('ChatPublicSinglePost', {
        renderTime: this.t,
        channelId: channel.id,
      });
      openChannel(me.id, channel.id);
      this.channelOpened = true;
    }
  }

  public componentWillUnmount() {
    const { channel, initialized, me, setActiveChannel } = this.props;
    if (channel && initialized) {
      setActiveChannel(me.id);
      this.loadPost();
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { channel, initialized, me, openChannel, post, setActiveChannel } = this.props;
    const { minimized } = this.state;
    if (minimized !== prevState.minimized) {
      if (minimized) {
        setActiveChannel(me.id);
      } else {
        openChannel(me.id, channel.id);
        this.channelOpened = true;
      }
    }
    if (channel && initialized && (!this.channelOpened || !prevProps.channel || prevProps.channel.id !== channel.id)) {
      RenderTrack.track('ChatPublicSinglePost', {
        renderTime: this.t,
        channelId: channel.id,
      });
      openChannel(me.id, channel.id);
      this.channelOpened = true;
      this.loadPost();
    }
    if (post && prevProps.post !== post) this.setState({ post });
  }

  public render() {
    const { channel, isModal, navMiniChannelAction, onClose, onMinimize, styleModal } = this.props;
    const { minimized } = this.state;
    if (!channel) return null;
    return isModal ? (
      <ChannelModal
        style={styleModal}
        title={channel?.name}
        type={channel.type}
        minimized={minimized}
        onMinimize={() => {
          if (onMinimize) {
            onMinimize();
          }
          this.setState({ minimized: !minimized });
        }}
        onClose={() => (onClose ? onClose() : navMiniChannelAction(''))}
      >
        {this.renderContent()}
      </ChannelModal>
    ) : (
      this.renderContent()
    );
  }

  /**
   * Render all page content
   */
  private renderContent = () => {
    const { channel } = this.props;
    if (!channel) return null;
    const { post, showWritePost, showPostLinkModal, showSendLinkModal } = this.state;
    return (
      <S.Container className="chat-public-post-container">
        {this.renderHeader()}
        <CS.BodyContainer className="chat-public-post-body-container">
          <CS.ContentContainer className="chat-public-post-content-container">
            {this.renderBackButton()}
            {post ? this.renderSinglePost() : this.renderLoading()}
          </CS.ContentContainer>
        </CS.BodyContainer>
        {showWritePost ? this.renderWritePostModal() : null}
        {showPostLinkModal ? this.renderPostLinkModal() : null}
        {showSendLinkModal ? this.renderSendPostLinkModal() : null}
        <Picture
          accept={ACCEPT_ANIMATED_IMAGES}
          cropStrategy="contain"
          hidden={true}
          id="chat-public-file-input"
          onCropCancel={() => this.setState({ showWritePost: true })}
          onCropShown={() => this.setState({ showWritePost: false })}
          onFileChange={(f, fromCrop?: boolean) => this.handleSelectFile([f], fromCrop ? false : true)}
          onFilesChange={files => (files.length ? this.handleSelectFile(files as Array<File>, false) : undefined)}
          ref={this.pictureRef}
          withCrop={false}
        />
      </S.Container>
    );
  };

  private renderHeader = () => {
    const { channel, history } = this.props;
    return (
      <ChatPublicHeader
        channel={channel}
        className="chat-public-post-header-container"
        history={history}
        onTabChange={this.onTabChange}
        tabSelected="messages"
      />
    );
  };

  /**
   * On tab click, performs a page change
   */
  private onTabChange = (tab: string) => {
    const { channel, history, postId } = this.props;
    switch (tab) {
      case 'messages':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL_POST, postId: postId + '', channelId: channel.id }));
        break;
      case 'info':
        history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL_INFO, channelId: channel.id }));
        break;
    }
  };

  private renderLoading = () => {
    return (
      <S.CenterContainer>
        <IOSLoading loading={true} />
      </S.CenterContainer>
    );
  };

  private renderBackButton = () => {
    const { channel, history } = this.props;
    return (
      <S.BackContainer
        onClick={() => history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId: channel.id }))}
      >
        <S.BackButton />
        <S.TextBack>{__('ChatPublicSinglePost.back')}</S.TextBack>
      </S.BackContainer>
    );
  };

  /**
   * Render write a post modal
   */
  private renderWritePostModal = () => {
    const { me, channel } = this.props;
    const { imagePreviews, showWritePost, post, postToEdit } = this.state;

    return (
      <PostWriteModal
        channel={channel}
        className="chat-public-single-post-write-post-modal"
        handleSelectFile={this.handleSelectFile}
        imagePreviewsInit={imagePreviews}
        me={me}
        onAddImageClick={this.onAddImageClick}
        onClose={() => this.setState({ showWritePost: false })}
        post={postToEdit || post}
        toShow={showWritePost}
      />
    );
  };

  /**
   * Open file input selector
   */
  private onAddImageClick = () => {
    const imageInput = document.getElementById('chat-public-file-input') as HTMLInputElement;
    if (imageInput) imageInput.click();
  };

  /**
   * Handle converting an array of File to IFile after having selecting it
   */
  private handleSelectFile = (files: Array<File>, resetEdit: boolean) => {
    const { imagePreviews } = this.state;
    const imagePreviewsCopy = imagePreviews ? imagePreviews.slice() : [];
    let iterations = 0;

    files.forEach(f => {
      if (f) {
        convertToIFile(
          f,
          (file: IFile, fileString: string) => {
            if (imagePreviewsCopy?.length < MAX_PICTURES_PER_POST) imagePreviewsCopy.push({ file, fileString });
            if (iterations === files.length - 1) {
              this.setState(
                {
                  showWritePost: true,
                  imagePreviews: imagePreviewsCopy,
                },
                () => resetEdit && this.setState({ postToEdit: undefined }),
              );
            }
            iterations++;
          },
          () => iterations++,
        );
      }
    });
  };

  /**
   * Load post message
   */
  private loadPost = () => {
    const { channel, getMessage, postId, me } = this.props;
    getMessage(channel.id, me.id, postId, (err, post) => {
      if (!err && post) {
        this.setState({ post });
      }
    });
  };

  /**
   * Render posts
   */
  private renderSinglePost() {
    const { channel, contacts, me, touchImage } = this.props;
    const { post } = this.state;
    if (!post || post?.isRemoved) return null; // not found case ?

    const sender =
      post.senderId === me.id
        ? {
            avatar: me.settings.avatar,
            avatarColor: utils.getAvatarColor(me.name),
            id: me.id,
            name: me.name,
            role: channel.role,
          }
        : post.sender || channel.members.find(member => member.id === post.senderId);

    return (
      <PostMessage
        contact={contacts[sender?.id]}
        channelId={channel.id}
        channelName={channel.name}
        message={post}
        onOptionClick={this.onPostOptionClick}
        options={this.getPostOptions(post)}
        sender={sender as IMember}
        touchImage={touchImage}
      />
    );
  }

  /**
   * Get options of a post.
   */
  private getPostOptions = (message: IMessage) => {
    if (message.messageType === 'admin') return [];
    // Admins can delete posts.
    // Only the author can edit their posts
    const { channel, me } = this.props;
    const amOwnerOrAdmin = ADMIN_OWNER_ROLES.includes(channel.role);
    const amAuthor = message.senderId === me.id;

    let options: Array<IItem> = [];
    if (amAuthor) options = [{ key: 'edit', value: __('ChatPublic.options.edit'), icon: 'Edit' }];
    if (amOwnerOrAdmin || channel.invitePolicy === 'any')
      options.push({ key: 'share', value: __('ChatPublic.options.share'), icon: 'Forward-web' });
    if (amAuthor || amOwnerOrAdmin)
      options.push({ key: 'delete', value: __('ChatPublic.options.delete'), icon: 'Trash', color: theme.colors.red1 });
    return options;
  };

  /**
   * On click on a post option (modify / delete)
   */
  private onPostOptionClick = (key: string, message: IMessage) => {
    const { channel, deleteMessage, me, modalOpen, modalClose, history } = this.props;
    if (key === 'delete') {
      modalOpen(
        __('ChatPublic.post_delete_modal.title'),
        () => {
          deleteMessage(me.id, message);
          modalClose();
          history.push(getPath({ path: ROUTE_PATHS.PUBLIC_CHANNEL, channelId: message.channelId }));
        },
        {
          showCancelButton: true,
          actionType: 'dangerous',
          text2: __('ChatPublic.post_delete_modal.description'),
          buttonText: __('ChatPublic.post_delete_modal.cta'),
        },
        'nice',
      );
    } else if (key === 'edit') {
      const { extraData } = message;
      const { imageUrls } = extraData as IPost;
      this.setState({
        imagePreviews: imageUrls?.length
          ? imageUrls.map(url => ({
              imageUrl: url,
            }))
          : undefined,
        postToEdit: message,
        showWritePost: true,
      });
    } else if (key === 'share') {
      this.setState({
        postToShare: message,
        showPostLinkModal: true,
      });
      EventTrack.track('post_share_open_modal', { channel_id: channel.id, post_id: message.messageId });
    }
  };

  /**
   * Renders invite modal for a post
   */
  private renderPostLinkModal = () => {
    const { me, channel } = this.props;
    const { postToShare } = this.state;
    return (
      <InviteLinkModal
        channel={channel}
        description={__('ChatPublic.post_link_invite.description')}
        inviteOrigin={INVITE_ORIGIN.PUBLIC_POST}
        me={me}
        onClose={() => this.setState({ showPostLinkModal: false })}
        onSendLink={(inviteLinkReceived: string, through: 'message' | 'whatsapp') => {
          this.setState({ showPostLinkModal: false, inviteLink: inviteLinkReceived });
          if (through === 'message') {
            this.setState({ showSendLinkModal: true });
          } else if (through === 'whatsapp') {
            share({
              sharer: 'whatsapp',
              url: inviteLinkReceived,
              title: __('ChatPublic.post_link_invite.external'),
              web: !deviceIsIpad(),
            });
          }
        }}
        post={postToShare}
        title={__('ChatPublic.post_link_invite.title')}
        trackFrom="chat-public"
      />
    );
  };

  /**
   * Renders send link modal for post through Consentio
   */
  private renderSendPostLinkModal = () => {
    const { me, channel, contacts, history, channels, sendMessage, lastMessageAt } = this.props;
    const { inviteLink } = this.state;
    const message: IMessage = {
      channelId: '',
      createdAt: new Date().getTime() + 60000,
      extraData: {},
      message: __('ChatPublic.post_link_invite.message', { link: inviteLink }),
      messageId: 0,
      messageType: 'text',
      reactions: {},
      senderId: me.id,
    };
    return (
      <ForwardMessage
        channelId={channel.id}
        channels={channels}
        close={() => this.setState({ showSendLinkModal: false })}
        contacts={contacts}
        forwardMessage={(myId: number, msg: IMessage, channelIds: Array<string>) => {
          channelIds.forEach(channelId => {
            sendMessage(myId, {
              ...msg,
              channelId,
            });
            EventTrack.track('message_send', {
              channelId,
              length: msg.message.length,
              messageType: 'text',
              type: channels[channelId]?.type,
            });
          });
        }}
        history={history}
        lastMessageAt={lastMessageAt}
        message={message}
        me={me}
        title={__('Components.Chat.send_invite_title')}
      />
    );
  };
}

export default ChatPublicSinglePost;
