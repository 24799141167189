import styled, { css, ITheme } from '../../../styled-components';

interface IStyledProps {
  hasRightButton?: boolean;
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  padding: 6px 5px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey4};
  flex: 1;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  max-height: ${(p: IStyledProps) => p.theme.inputHeight};
  min-height: ${(p: IStyledProps) => p.theme.inputHeight};
  max-width: 350px;
  &:focus-within {
    outline: none !important;
    border-color: ${(p: IStyledProps) => p.theme.colors.black};
    background: ${(p: IStyledProps) => p.theme.colors.white};
    .search-icon {
      color: ${(p: IStyledProps) => p.theme.colors.black};
    }
  }

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding: 4px;
  }
`;

export const FontIconWrapper = styled.div`
  align-self: center;
  margin-left: 5px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const Input = styled.input`
  border: 0;
  outline: none;
  margin-left: 5px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
  width: calc(100% - 35px);
  font-family: inherit;
  background: ${(p: IStyledProps) => p.theme.colors.grey4};
  &:focus {
    background: ${(p: IStyledProps) => p.theme.colors.white};
  }
`;

export const FontIconWrapperRight = styled.div`
  align-self: center;
  margin-right: 5px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};

  ${(p: IStyledProps) =>
    p.hasRightButton &&
    css`
      border-left: 1px solid ${p.theme.colors.grey1};
    `}
`;
