import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel, CheckBox as CB } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  line-height: 1.4;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: center;
`;
export const Image = styled.img<IStyledProps>`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  max-width: 100px;
  min-width: 100px;
  align-self: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ModalDescription = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  line-height: 1.4;
  text-align: center;
`;

export const CheckBoxRow = styled(RowContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  align-self: flex-start;
`;

export const CheckBox = styled(CB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CheckBoxLabel = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonsContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ModalButton = styled(Button)`
  width: 45%;
`;
