import * as React from 'react';

import { ColumnContainer } from './components/atoms';
import { ROUTE_PATHS } from './constants';
import Layout from './layout-flex';
import BroadcastContainer, { IBroadcastRouterProps } from './screens/chat-broadcast-container';
import ChatBroadcastHeader from './screens/chat-broadcast-header';
import ChatContainer, { IChatRouteProps } from './screens/chat-container';
import ChatHeader from './screens/chat-header';
import ChatList from './screens/chat-list';
import styled, { ITheme } from './styled-components';

interface IStyledProps {
  theme: ITheme;
}

const RightContainer = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export default React.memo((props: IBroadcastRouterProps) => {
  const { match, location, history } = props;
  return (
    <Layout
      header={{
        show: true,
        tabSelected: 'chat',
        breadcrumbs: undefined,
      }}
    >
      <ChatList {...props} />
      {getRightContainer(props)}
    </Layout>
  );
});

/**
 * Get the container with content on the right of the contacts list
 */
function getRightContainer(props: IBroadcastRouterProps) {
  if ((props as IBroadcastRouterProps).match.url.startsWith('/broadcast/'))
    return (
      <ChatBroadcastHeader {...props}>
        <BroadcastContainer {...props} />
      </ChatBroadcastHeader>
    );

  if ((props as IChatRouteProps).showInviteOnboarding || isShowroomPath(props))
    return <ChatContainer {...props} fullScreen={true} />;

  if (props.location.pathname.endsWith(ROUTE_PATHS.CHAT_UNREAD) || props.location.pathname.includes('/public/'))
    return <ChatContainer {...props} />;

  return (
    <ChatHeader {...props}>
      <ChatContainer {...props} />
    </ChatHeader>
  );
}

/**
 * Is showroom path
 */
function isShowroomPath(props: IChatRouteProps | IBroadcastRouterProps) {
  const { pathname } = props.location;
  // return pathname.match(/(\/sell\/\w+|\/buy\/\w+|\/sell\/|\/buy\/|\/sell|\/buy)$/);
  return pathname.match(/(\/sell|\/buy)(\/)?(\/\w+)?$/);
}
