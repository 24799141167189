import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../../atoms/FlexContainer';
import FontIcon from '../../atoms/FontIcon';
import TextLabel from '../../atoms/TextLabel';

interface IStyledProps {
  size?: number;
  theme: ITheme;
}

export const Row = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const BackContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const BackArrow = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => (p.size ? `${p.size}px` : p.theme.fontSize.H3)};
`;

export const Text = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
