import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  badgeCount?: number;
  channelId?: string;
  hasChannelOptions?: boolean;
  imBlocking?: boolean;
  invited?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
  showOpen?: boolean;
  theme: ITheme;
}

export const Container = styled.div<IStyledProps>`
  display: grid;
  grid-template-columns: 42px 1fr 60px;
  grid-auto-rows: 50% 50%;
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.blue2 : p.theme.colors.transparent)};
  height: 60px;
  min-height: 60px;
  padding: 10px ${(p: IStyledProps) => p.theme.paddingSize(2)} 10px ${(p: IStyledProps) => p.theme.paddingSize(5)};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    height: 55px;
    min-height: 55px;
    padding: 10px ${(p: IStyledProps) => p.theme.paddingSize()};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: 50px;
    min-height: 50px;
    padding: 10px ${(p: IStyledProps) => p.theme.paddingSize()};
  }

  &:hover {
    background-color: ${(p: IStyledProps) =>
      p.isSelected ? p.theme.colors.grey3 : p.theme.colors.grey3.replace('1)', '0.8)')};
    .chat-card-time {
      display: ${(p: IStyledProps) => (p.channelId && p.hasChannelOptions ? 'none' : 'block')};
    }
    .arrow-options {
      display: flex;
    }
  }
`;

export const Icon = styled.div`
  position: relative;
  align-self: center;
  grid-column: 1;
  grid-row: 1 / 3;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  &:after {
    content: '${(p: IStyledProps) => (p.badgeCount >= 1000 ? '999+' : p.badgeCount)}';
    width: ${getBadgeWidth};
    height: 17px;
    border-radius: 15px;
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
    align-items: center;
    justify-content: center;
    top: -3px;
    right: 15px;
    line-height: 1;
    transform: translateX(50%);
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
    font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
    background-color: ${(p: IStyledProps) => p.theme.colors.red1};
    color: ${(p: IStyledProps) => p.theme.colors.white};
    position: absolute;
    display: none;

    @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
      display: ${(p: IStyledProps) => (p.badgeCount && p.badgeCount > 0 && !p.showOpen ? 'flex' : 'none')};
    }
  }
`;

export const Info = styled.div`
  flex: 1;
  justify-content: space-around;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

export const InfoTitle = styled.div`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    font-size: 12px;
  }
`;
export const FirstRow = styled(RowContainer)`
  grid-column: 2 / 4;
  grid-row: 1;
  justify-content: space-between;
`;
export const SecondRow = styled(RowContainer)`
  grid-column: 2 / 4;
  grid-row: 2;
  justify-content: space-between;
`;

export const InfoTitleHighlight = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    font-size: 12px;
  }
`;

export const InfoSubtitle = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  grid-column: 2;
  grid-row: 2;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    line-height: 16px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
  }
`;

export const InfoSubtitleHighlight = styled(InfoTitleHighlight)`
  line-height: 20px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    line-height: 16px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
  }
`;

export const Invited = styled(InfoSubtitle)`
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
  flex: 1;
`;

export const ResendInvitation = styled(InfoSubtitle)`
  color: ${(p: IStyledProps) => (p.invited ? p.theme.colors.grey1 : p.theme.colors.blue1)};
  width: min-content;
`;

export const SendInvitation = styled(InfoSubtitle)`
  color: ${(p: IStyledProps) => (p.invited ? p.theme.colors.grey1 : p.theme.colors.blue1)};
  align-items: center;
  display: flex;
`;

export const InfoFeaturedSubtitle = styled(InfoSubtitle)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  grid-column: 2;
  grid-row: 2;
`;

export const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const InnerBadge = styled.span`
  min-width: 17px;
  height: 17px;
  line-height: 17px;
  border-radius: 8px;
  color: white;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  background: ${(p: IStyledProps) => p.theme.colors.purple1};
  text-align: center;
  vertical-align: middle;
  padding: 0 2px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    min-width: 15px;
    height: 15px;
    line-height: 15px;
  }
`;

export const Time = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  align-self: start;
`;

export const New = styled.div`
  display: flex;
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  min-width: 40px;
  max-width: 60px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding: 0 5px;
  margin-left: 3px;
`;

export const Archived = styled(New)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: 3px;
  min-width: 50px;
  max-width: 60px;
  padding: 2px;
  margin: 0;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  height: 18px;
  margin-top: 1px;
`;

export const InfoRight = styled(Info)`
  flex: none;
  min-width: 50px;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: visible;
`;

export const FontIconWrapper = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 0 4px;
  justify-self: end;
  grid-column: 3;
  grid-row: 2;
`;

export const Blocked = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  justify-self: end;
  grid-column: 3;
  grid-row: 1;
`;

export const Group = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background: ${(p: IStyledProps) => p.theme.colors.blue1};
  border-radius: 3px;
  padding: 0 3px;
  margin-right: 3px;
`;

export const Broadcast = styled(Group)`
  background: ${(p: IStyledProps) => p.theme.colors.red1.replace('1)', '0.6)')};
`;

export const ArrowContainer = styled.div`
  display: none;
  min-height: 19px;
  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  align-self: start;
`;

export const ArrowOptions = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

function getBadgeWidth(p: IStyledProps) {
  if (p.badgeCount < 10) return '17px';
  if (p.badgeCount < 100) return '18px';
  if (p.badgeCount < 1000) return '25px';
  return '32px';
}
