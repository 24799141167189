import {
  Api,
  buyerWorkspaceActions,
  chatActions,
  IAddress,
  IOrderItem,
  LOCALE,
  modalActions,
  orderActions,
  productActions,
  productService,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import Showroom, { DispatchProps, OwnProps, StateProps } from '../components/organisms/Showroom';
import { IReduxState } from '../reducers';

const emptyFeatured = { recommended: [], favorite: [], recent: [] };

function mapStateToProps(state: IReduxState, props: OwnProps): StateProps {
  const {
    chat: { channels },
    contact: { inConsentio },
    order: { cart, orders },
    product: { catalog, featured, priceMode, prices, priceGroupIds, sectionsEnabled },
    user: { user },
  } = state;
  const { contact, match } = props;
  let items: Array<IOrderItem> = [];
  let orderId = 0;
  let isServedFlow = false;

  const channelId = match.params?.channelId;
  const contactId = contact ? contact.id : 0;
  const channel = channels[channelId];
  const c = contactId ? inConsentio[contactId] : inConsentio[channel?.defaultSellerId];

  const key = c && (c.isUnregistered ? productService.getKey(user.id!, c.id!) : productService.getKey(c.id!, user.id!));
  if (cart[key] && cart[key].contactId === c?.id && !cart[key].asSeller) {
    items = cart[key].items;
    orderId = cart[key].orderId;
    isServedFlow = cart[key].isServedFlow;
  }
  const catalogKey =
    c &&
    (c.isUnregistered
      ? productService.getKey(channel?.workspaceId || c.mySellerWorkspaceId || user.sellerWorkspaceId!, c.id!)
      : productService.getKey(channel?.workspaceId || c.theirSellerWorkspaceId!, user.id!));
  const catalogId =
    channel?.workspaceId ||
    (c && (c.isUnregistered ? c.mySellerWorkspaceId || user.sellerWorkspaceId! : c.theirSellerWorkspaceId!));
  const p = c?.id && prices[catalogKey] ? prices[catalogKey] : undefined;
  const prodTypes = state.prodType.prodTypes;
  const countries = state.country.countries;
  const order = orders.find(o => o.id === orderId);
  return {
    amSeller: false,
    cart: items,
    catalog: catalog[catalogId],
    catalogId,
    contact: c,
    countries,
    featured: catalogKey
      ? {
          favorite: featured.favorite[catalogKey],
          recent: featured.recent[catalogKey],
          recommended: featured.recommended[catalogKey],
        }
      : emptyFeatured,
    isServedFlow,
    lang: user.settings.language as LOCALE,
    me: state.user.user,
    name: contactId ? null : channel?.name,
    orderId,
    priceMode: c?.isUnregistered ? 'read' : priceMode[catalogKey],
    prices: p,
    prodTypes,
    priceGroupIds: priceGroupIds[catalogKey],
    sectionsEnabled: sectionsEnabled[catalogKey],
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): DispatchProps {
  return bindActionCreators(
    {
      cartClean: orderActions.cartClean,
      cartCleanIfNeeded: orderActions.cartCleanIfNeeded,
      cartUpdateItem: (
        key: string,
        contactId: number,
        item: IOrderItem,
        priceMode: IPriceMode,
        deliveryAddress?: IAddress,
      ) => orderActions.cartUpdateItem(key, contactId, item, false, priceMode, deliveryAddress),
      clientsGet: sellerWorkspaceActions.clientsGet,
      featuredToggle: productActions.featuredToggle,
      getPrices: productActions.getPrices,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelBySection,
      orderFakeNew: orderActions.orderFakeNew.bind(undefined, false),
      productGet: productActions.productGet,
      productShare: productActions.productShare,
      sendMessage: chatActions.sendMessage,
      touchImage: modalActions.touchImage,
      suppliersGet: buyerWorkspaceActions.suppliersGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Showroom);
