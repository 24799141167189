import {
  Api,
  contactActions,
  countryActions,
  modalActions,
  notificationsActions,
  prodTypeActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navAuthAction, navResetAuthAction } from '../actions/nav';
import {
  register,
  resendCode,
  resetUser,
  setRegisterInfo,
  updatePassword,
  verifyEmailCode,
  verifyPhone,
  verifyPhoneCode,
  webloginOauth,
  webloginPassword,
} from '../actions/user';
import LoginLanding, { IDispatchProps, IStateProps } from '../components/organisms/LoginLanding';
import { ROUTE_PATHS } from '../constants';
import { IReduxState } from '../reducers';
import getPath from '../util/routes';

function mapStateToProps(state: IReduxState, props: RouteComponentProps): IStateProps {
  const { user } = state;
  if (user.user.id) props.history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST }) + window.location.search);

  return {
    countries: state.country.countries,
    me: user.user,
    loading: user.loading,
    error: user.error,
    authStep: state.nav.auth!,
    redirect: user.redirectOnLogin,
    contactUrl: user.contactUrl,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: RouteComponentProps,
): IDispatchProps {
  return bindActionCreators(
    {
      addContactByPricelistUrl: contactActions.addContactByPricelistUrl,
      countriesGet: countryActions.countriesGet,
      hideLoading: modalActions.hideLoading,
      hideLogin: userActions.hideLogin,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navAuthAction,
      navResetAuthAction,
      prodTypesGet: prodTypeActions.prodTypesGet,
      register,
      resendCode,
      resetPassword: userActions.resetPassword,
      resetUser,
      scanContacts: contactActions.scanContacts,
      setRegisterInfo,
      showLoading: modalActions.showLoading,
      showLogin: userActions.showLogin,
      updatePassword,
      updateSettings: userActions.updateSettings,
      userExists: userActions.userExists,
      userSet: userActions.userSet,
      isEmailValid: userActions.isEmailValid,
      verifyEmailCode,
      verifyPhone,
      verifyPhoneCode,
      verifyUser: userActions.verifyUserData,
      webloginOauth,
      webloginPassword,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLanding);
