import { __, ISection, LOCALE, sellerWorkspaceService } from 'common-services';
import * as React from 'react';

import { NO_TAG } from '../../../constants';
import SectionCard from '../SectionCard';
import * as S from './SectionList.styled';

export interface IProps {
  facets: any;
  myLocale: LOCALE;
  onClick: (tag: string) => void;
  sections: Array<ISection>;
}

export const SectionList: React.FC<IProps> = ({ facets, myLocale, sections, onClick }) => {
  return (
    <S.Container>
      <S.ContainerList>
        {sections
          .filter(s => facets && facets.tags?.[s.tag])
          .sort((a, b) => {
            return a.position - b.position;
          })
          .map((s, idx: number) => {
            return (
              <SectionCard
                amount={(facets && facets.tags?.[s.tag]) || 0}
                key={s.tag}
                myLocale={myLocale}
                onClick={() => onClick(s.tag)}
                section={s}
              />
            );
          })}
        {facets && facets.tags?.[NO_TAG] ? (
          <SectionCard
            amount={facets.tags?.[NO_TAG]}
            myLocale={myLocale}
            onClick={() => onClick(NO_TAG)}
            section={sellerWorkspaceService.sectionNew(__('Showroom.no_tag'), sections.length + 1, 0)}
          />
        ) : null}
      </S.ContainerList>
    </S.Container>
  );
};

export default React.memo(SectionList);
