import { Api, CHANNEL_TYPE, IContact } from 'common-services';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navChannelAction, navResetChannelAction } from '../actions/nav';
import { ColumnContainer } from '../components/atoms';
import { CHANNEL_SECTIONS } from '../constants';
import { IReduxState } from '../reducers';
import styled from '../styled-components';

export type IChatRouteProps = RouteComponentProps<{ channelId?: string }> & {
  Children?: any;
  fullScreen?: boolean;
  showInviteOnboarding?: boolean;
};

const Container = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

export type OwnProps = {
  contact?: IContact;
  next?: () => void;
} & RouteComponentProps<{ channelId?: string; productHash?: string; hashId?: string }>;

interface IPropsState {
  Children?: any;
  section?: CHANNEL_SECTIONS;
  sectionId?: number;
  contact?: IContact;
}

interface IPropsDispatch {
  navChannelAction: (channelId: string, section?: CHANNEL_SECTIONS, sectionId?: number) => void;
  navResetChannelAction: (channelId?: string) => void;
  navCloneAction: (channelId: string, section?: CHANNEL_SECTIONS, sectionId?: number) => void;
}

const ChatContainer: React.FC<OwnProps> = React.memo(props => {
  const { Children } = props as any;
  return <Container id="ContactContainer">{Children ? <Children {...props} /> : null}</Container>;
});

function mapStateToPropsDetails(state: IReduxState, props: IChatRouteProps): IPropsState {
  const channelId = props.match.params.channelId;
  if (!channelId) return {};
  const channel = state.chat.channels[channelId];
  if (!channel || (channel.type === CHANNEL_TYPE.PRIVATE && !state.contact.inConsentio[channel.members?.[0]?.id]))
    return {};
  const contact =
    channel.type === CHANNEL_TYPE.PRIVATE ? state.contact.inConsentio[channel.members?.[0]?.id] : undefined;
  const channelStatus = state.nav.channels[channel.id];
  return {
    contact,
    section: channelStatus && channelStatus.section,
    sectionId: channelStatus && channelStatus.sectionId,
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IChatRouteProps,
): IPropsDispatch {
  return bindActionCreators(
    {
      navChannelAction,
      navResetChannelAction,
      navCloneAction: navChannelAction,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(ChatContainer);
