import styled, { ITheme } from '../../../styled-components';
import {
  CheckBox as CB,
  ColumnContainer,
  DatePicker as DP,
  Input as InputAtom,
  RowContainer,
  SelectWorkingStatus as SWS,
  Switch,
  TextLabel,
  WorkspaceAvatar as TA,
} from '../../atoms';

interface IStyledProps {
  activated?: boolean;
  disabled?: boolean;
  margin?: string;
  padding?: string;
  textAlign?: 'left' | 'center';
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  overflow: hidden;
  height: 100%;
  flex: 1;
  max-width: 100%;
`;

export const LinkSupport = styled.a`
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const Subtitle = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const SubtitleGrey2 = styled(Subtitle)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextError = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  text-align: center;
`;

export const TextLink = styled.a<IStyledProps>`
  text-align: ${(p: IStyledProps) => p.textAlign || 'center'};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-decoration-color: ${(p: IStyledProps) => p.theme.colors.blue1};
`;

export const TextActive2FA = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => (p.activated ? p.theme.colors.green1 : p.theme.colors.grey1)};
  display: inline;
`;

export const TextSemiGrey2 = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: inline;
`;

export const WarningIconWrapper = styled.div`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 2px 0;
`;

export const ResendEmailContainer = styled(RowContainer)`
  align-items: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Input = styled(InputAtom)``;

export const RowForm = styled(RowContainer)`
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Section = styled(ColumnContainer)``;

export const SectionHeader = styled(RowContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const SectionTitle = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const SectionSwitch = styled(Switch)``;

export const TwoFactorHeader = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const TwoFactorSwitch = styled(Switch)``;

export const Option = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;
export const Check = styled(CB)``;
export const OptionText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.grey2 : p.theme.colors.black)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const CheckboxRow = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
`;

export const CheckBox = styled(CB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CheckBoxLabel = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const DatePicker = styled(DP)`
  margin-top: 0;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  width: 350px;
`;

export const SelectWorkingStatus = styled(SWS)`
  margin: 4px 0;
  width: 350px;
`;

export const DelegateContainer = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const DelegateRow = styled(RowContainer)`
  align-items: center;
`;

export const WorkspaceAvatar = styled(TA)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const WorkspaceRow = styled(RowContainer)`
  align-items: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;
