import { Api, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import NotificationGroup, {
  IDispatchProps,
  IStateProps,
} from '../components/molecules/NotificationGroup/NotificationGroup.component';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState): IStateProps {
  const { current, files } = state.notifications;
  return {
    notification: current,
    files,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      clearNotification: notificationsActions.notificationClear,
      clearFileNotification: notificationsActions.notificationFileClear,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationGroup);
