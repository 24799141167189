import { __ } from 'common-services';
import * as React from 'react';

import { FontIcon, Modal, RowContainer, TextLabel } from '../../atoms';
import * as S from './AlertModal.styled';

interface IProps {
  text: string;
  acceptAction?: () => void;
  cancelAction?: () => void;
  acceptButtonText?: string;
  cancelButtonText?: string;
  closeModalAction?: () => void;
  extra?: IModalExtraInfo;
}

const AlertModal: React.FC<IProps> = ({
  text = '',
  acceptAction = () => null,
  cancelAction = () => null,
  acceptButtonText,
  cancelButtonText,
  closeModalAction = () => null,
  extra,
}) => {
  return (
    <Modal toShow={true} onCloseClick={closeModalAction}>
      <S.Container>
        <RowContainer>
          {extra && extra.icon ? (
            <S.FontIconWrapper id="modal-icon" type={extra.icon}>
              <FontIcon name={extra.icon} disableHover={true} />
            </S.FontIconWrapper>
          ) : null}
          <TextLabel margin="0 0 20px 0" textAlign="left" fontWeight="600" width="97%">
            {text}
          </TextLabel>
        </RowContainer>
        {extra && extra.text2 && (
          <TextLabel width="97%">
            {extra.text2.split('\n').reduce((acc, item, idx, array) => {
              acc.push(item);
              if (idx < array.length - 1) {
                acc.push(<br key={idx} />);
              }
              return acc;
            }, [])}
          </TextLabel>
        )}
        {renderActionsButtons(
          cancelAction,
          acceptAction,
          cancelButtonText,
          acceptButtonText,
          !extra || extra.showCancelButton !== false,
        )}
      </S.Container>
    </Modal>
  );
};

function renderActionsButtons(
  cancelAction: () => void,
  acceptAction: () => void,
  cancelButtonText?: string,
  acceptButtonText?: string,
  showCancelAction?: boolean,
) {
  return (
    <RowContainer width="100%" justifyContent="flex-end" alignItems="center" margin="5px 0 0 0">
      {showCancelAction ? (
        <S.CancelButton onClick={cancelAction}>{cancelButtonText || __('Components.Modal.cancel')}</S.CancelButton>
      ) : null}
      <S.AcceptButton onClick={acceptAction}>{acceptButtonText || __('Components.Modal.accept')}</S.AcceptButton>
    </RowContainer>
  );
}

export default React.memo(AlertModal);
