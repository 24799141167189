import styled, { ITheme } from '../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  background: white;
  justify-self: flex-end;
  height: 62px;
  width: 100%;

  max-width: calc(100vw - ${(p: IStyledProps) => p.theme.chatListWidth.xl}px);

  @media only screen and (max-width: 1450px) {
    max-width: calc(100vw - ${(p: IStyledProps) => p.theme.chatListWidth.l}px);
  }
`;

export const Message = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const MessageLink = styled(Message)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;
