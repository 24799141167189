import { __, LOCALE } from 'common-services';
import * as React from 'react';

import * as S from './CatalogHeader.styled';

const BIO_MAX_PREVIEW_BIG = 450;
const BIO_MAX_PREVIEW_MEDIUM = 250;

export interface IProps {
  amLogged: boolean;
  amOwner: boolean;
  avatar: string;
  bio: string;
  companyColor: string;
  companyImage: string;
  email: string;
  from: 'showroom' | 'pricelist';
  locale?: LOCALE;
  name: string;
  navigate?: () => void;
  ownerPhoneNumber: string;
}
const CatalogHeader: React.FC<IProps> = ({
  amLogged,
  amOwner,
  avatar,
  bio = '',
  companyColor,
  companyImage,
  email,
  from,
  name,
  navigate,
  ownerPhoneNumber,
}) => {
  const fullBio = bio;
  const limit = from === 'pricelist' ? BIO_MAX_PREVIEW_MEDIUM : BIO_MAX_PREVIEW_BIG;
  let shortBio: string;
  const toggleBioNeeded = bio.length > limit;
  if (toggleBioNeeded) {
    shortBio = bio.substring(0, limit) + '...';
  }
  const [isFullBio, setFullBio] = React.useState(bio.length <= limit);

  return !avatar && !bio && !companyImage ? null : (
    <S.TopContainer>
      {companyImage ? (
        <S.Image image={companyImage.replace('w_450', '').replace('h_250', '')} from={from}>
          {avatar && from === 'showroom' ? (
            <S.AvatarWrapper>
              <S.AvatarImage src={avatar.replace('w_450', '').replace('h_150', 'h_60')} />
            </S.AvatarWrapper>
          ) : null}
        </S.Image>
      ) : null}
      {from === 'pricelist' ? (
        <S.ContactCol>
          <S.Contact>
            <S.ContactInfo>
              {avatar ? <S.Avatar image={avatar} from={from} /> : null}
              {from === 'pricelist' ? (
                <S.ContactCard>
                  <S.Name>{name}</S.Name>
                  <S.Phone color={companyColor} href={`tel:${ownerPhoneNumber}`}>
                    {ownerPhoneNumber}
                  </S.Phone>
                  <S.Email color={companyColor} href={`mailto:${email}`}>
                    {email}
                  </S.Email>
                </S.ContactCard>
              ) : null}
            </S.ContactInfo>
            {from === 'pricelist' ? (
              <S.ChatButton onClick={navigate} backgroundColor={companyColor}>
                {amLogged && amOwner
                  ? __('Pricelist.go_my_products')
                  : __('Pricelist.chat_with', { name: name.toUpperCase() })}
              </S.ChatButton>
            ) : null}
          </S.Contact>
          <S.TextCompany>
            {toParagraph(isFullBio ? fullBio : shortBio)}
            {toggleBioNeeded && (
              <span onClick={() => setFullBio(!isFullBio)}>
                {isFullBio ? __('Constants.view_less') : __('Constants.view_more')}
              </span>
            )}
          </S.TextCompany>
        </S.ContactCol>
      ) : (
        <React.Fragment>
          {avatar && !companyImage ? (
            <S.CompanyLogoWrapper>
              <S.AvatarImage src={avatar.replace('w_450', '').replace('h_150', 'h_60')} />
            </S.CompanyLogoWrapper>
          ) : null}
          <S.ContactRow>
            <S.TextCompany>
              {toParagraph(isFullBio ? fullBio : shortBio)}
              {toggleBioNeeded && (
                <span onClick={() => setFullBio(!isFullBio)}>
                  {isFullBio ? __('Constants.view_less') : __('Constants.view_more')}
                </span>
              )}
            </S.TextCompany>
          </S.ContactRow>
        </React.Fragment>
      )}
    </S.TopContainer>
  );
};

/**
 * Convert an string with newlines in multiple paragraphs
 */
function toParagraph(text: string = '') {
  return text.split(/(?:\r\n|\r|\n)/).map((t, i) => {
    return t ? <p key={i}>{t}</p> : <br key={i} />;
  });
}

export default React.memo(CatalogHeader);
