import styled, { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const StyledListUl = styled.ul`
  list-style-type: square;
  margin: ${(p: IStyledProps) => '0 ' + p.theme.paddingView};
`;

export const StyledListLi = styled.li`
  font-family: 'Nunito';
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: inherit;
  text-align: left;
`;
