import styled, { ITheme } from '../../../styled-components';
import { FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  isDragging?: boolean;
  theme: ITheme;
  color?: string;
  cursor?: string;
  disabled?: boolean;
  hiddenAtScreenWidth?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isReadRow?: boolean;
  minWidth?: string;
  selectable?: boolean;
  status?: string;
  width?: string;
}

export const Container = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  align-items: center;
`;

export const TextCell = styled.div`
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  vertical-align: center;
`;

export const OptionsCell = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  min-width: 92px;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
`;

export const TextNumber = styled(Text)`
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 8px ${(p: IStyledProps) => p.theme.paddingObject};
  padding-left: 0;
  min-width: 24px;
`;

export const TextTag = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingObject};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.75)')};
  font-size: 16px;
  width: 16px;
  margin: 0 5px;
`;

export const CloseIcon = styled(Icon)`
  margin: 0;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const Divider = styled.div`
  width: 1px;
  min-height: 100%;
  height: 22px;
  margin: 0 10px;
  border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;
export const Tr = styled.tr`
  background-color: ${(p: IStyledProps) => (p.isReadRow ? p.theme.colors.grey4 : p.theme.colors.white)};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  height: 69px;
  cursor: ${(p: IStyledProps) => (p.isDragging ? 'grabbing' : 'pointer')};
  & .chat {
    display: block;
    float: right;
  }
`;

export const PlaceholderRow = styled.tr<{ columns: number }>`
  height: 40px; /* Adjust the height to match your row height */
  background-color: rgba(0, 0, 0, 0.1); /* Light gray background for the placeholder */

  & > td {
    border: 1px solid #ddd; /* Optional: to mimic the cell borders */
    width: ${({ columns }) => `calc(100% / ${columns})`}; /* Distribute columns evenly */
  }
`;

export const SectionRow = styled(RowContainer)`
  padding: 8px 5px;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};

  /* box-shadow: $ {(p: IStyledProps) => (p.editMode ? p.theme.boxShadow : 'none')}; */
  align-items: center;
  opacity: ${(p: IStyledProps) => (p.isDragging ? 0 : 1)};
  cursor: ${(p: IStyledProps) => (p.isDragging ? 'grabbing' : 'inherit')};
`;
