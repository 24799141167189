import * as React from 'react';

/**
 * Hook to listen to the current window width / height
 */
export function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);

  const canUseDOM = typeof window !== 'undefined';
  const useLayoutEffect = canUseDOM ? React.useLayoutEffect : React.useEffect;

  useLayoutEffect(() => {
    function updateSize() {
      if (canUseDOM) setSize([window.innerWidth, window.innerHeight]);
    }
    if (canUseDOM) window.addEventListener('resize', updateSize);
    updateSize();
    return () => (canUseDOM ? window.removeEventListener('resize', updateSize) : null);
  }, [canUseDOM]);
  return size;
}

/**
 * Use debounced value as a hook
 */
export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time useEffect is re-called.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you need to be able to change that dynamically.
    [value, delay],
  );

  return debouncedValue;
}

/**
 * Hook to listen to the current scroll position of an element
 */
export const useScrollPosition = (scrollClassName?: string) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const scrollElement = document.getElementsByClassName(scrollClassName)[0];

  React.useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(scrollElement?.scrollTop);
    };
    scrollElement?.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => scrollElement?.removeEventListener('scroll', updatePosition);
  }, [scrollElement]);

  return scrollPosition;
};
