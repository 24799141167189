import { __, IContact, IPriceGroup, IProdType, IUser } from 'common-services';
import * as React from 'react';

import TableHeader from '../../atoms/TableHeader/TableHeader.component';
import SimpleSearch from '../SimpleSearch';
import * as S from './PriceGroupSelectTable.styled';
import PriceGroupTableRow from './PriceGroupTableRow/PriceGroupTableRow.component';

export interface IProps {
  className?: string;
  contacts: { [cId: number]: IContact };
  isQuoterMode: boolean;
  isSelect: boolean;
  me: IUser;
  priceGroups: Array<IPriceGroup>;
  priceGroupsSelected: Array<string>;
  prodTypes: { [key: string]: IProdType };
  removeSelection: () => void;
  searchText: string;
  selectAll: () => void;
  setPricegroupSelected: (priceGroups: Array<string>) => void;
  setSearchText: (searchText: string) => void;
}

const PriceGroupSelectTable: React.FC<IProps> = ({
  className = 'pricegroup-select-table-container',
  contacts,
  isQuoterMode,
  isSelect,
  me,
  priceGroups,
  priceGroupsSelected,
  prodTypes,
  removeSelection,
  searchText,
  selectAll,
  setPricegroupSelected,
  setSearchText,
}) => {
  function renderTable() {
    if (priceGroups.length === 0) return null;
    return (
      <S.Table>
        <TableHeader
          selectable={true}
          selected={isSelect && priceGroupsSelected.length ? 'all' : priceGroupsSelected.length ? 'any' : 'none'}
          selectAll={selectAll}
          removeSelection={removeSelection}
          columns={[
            {
              title: __('PriceGroupSelectTable.columns.name'),
              width: '150px',
            },
            {
              title: __('PriceGroupSelectTable.columns.products'),
              width: '80px',
            },
            {
              title: __('PriceGroupSelectTable.columns.clients'),
              width: '80px',
            },
            ...(isQuoterMode
              ? []
              : [
                  {
                    title: __('PriceGroupSelectTable.columns.transport'),
                    width: '80px',
                  },
                ]),
            {
              title: __('PriceGroupSelectTable.columns.status'),
              width: '70px',
            },
          ]}
        />
        <S.Body>{priceGroups.map(renderPriceGroupRow)}</S.Body>
      </S.Table>
    );
  }

  const renderPriceGroupRow = (priceGroup: IPriceGroup) => {
    return (
      <PriceGroupTableRow
        className="price-group-row"
        contacts={contacts}
        key={priceGroup.priceGroupId}
        me={me}
        priceGroup={priceGroup}
        prodTypes={prodTypes}
        priceGroupsSelected={priceGroupsSelected}
        setPriceGroupsSelected={setPricegroupSelected}
      />
    );
  };

  return (
    <S.Container className={className}>
      <S.SearchContainer>
        <SimpleSearch
          id="search-price-groups-select"
          query={searchText}
          placeHolder={
            isQuoterMode
              ? __('PriceGroupSelectTable.search_placeholder_quoter')
              : __('PriceGroupSelectTable.search_placeholder')
          }
          onChange={(t: string) => setSearchText(t)}
        />
        <S.TextSlim>
          {isQuoterMode
            ? __('PriceGroupSelectTable.margin_found', { count: priceGroups.length })
            : __('PriceGroupSelectTable.price_group_found', { count: priceGroups.length })}
        </S.TextSlim>
      </S.SearchContainer>
      {renderTable()}
    </S.Container>
  );
};

export default React.memo(PriceGroupSelectTable);
