import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  enabled?: boolean;
}

export const Card = styled(ColumnContainer)`
  align-items: flex-start;
  position: relative;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const AddressIssueCard = styled(ColumnContainer)`
  align-items: flex-start;
  position: relative;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Container = styled(ColumnContainer)`
  flex: 1;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TextBlack = styled.span`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
`;

export const Text = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  text-transform: initial;
  width: auto;
  display: flex;
`;

export const CardItem = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  width: 100%;
  position: relative;
  max-width: 35vw;
`;

export const Row = styled(RowContainer)`
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SectionTitle = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const AddressesSelect = styled.select`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  display: inline;
  border: none;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-family: 'Nunito';
`;

export const AddressIssueRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SelectOption = styled.option``;

export const AddAddressLink = styled(Button)`
  padding: 0;
  padding-left: 0;
`;

export const InfoIcon = styled(FontIcon)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;
