import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  deleted?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;

export const SelectButton = styled(Button)``;

export const FlexRow = styled(RowContainer)`
  align-items: center;
`;

export const FlexCol = styled(ColumnContainer)``;

export const TextRegular = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextGrey2 = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const AddressIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 26px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const MainActionButton = styled(Button)`
  width: 158px;
  padding: 0px;
`;

export const KebabIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
`;

export const AddressTitle = styled(TextRegular)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  color: ${(p: IStyledProps) => (p.deleted ? p.theme.colors.grey2 : p.theme.colors.black)};
`;
