import * as React from 'react';

import FontIcon, { IFontIconKeys } from '../FontIcon';
import Tooltip from '../Tooltip/Tooltip.component';
import * as S from './SimpleDropdown.styled';

export interface IItem {
  action?: () => void;
  avatar?: React.ReactNode;
  color?: string;
  customProps?: any; // Used by Autocomplete for mouse enter/leave logic
  icon?: IFontIconKeys;
  image?: string;
  isLink?: boolean;
  key: string;
  tooltip?: string;
  value: string;
  value2?: string;
  isSelected?: boolean;
}

export interface IProps {
  children: React.ReactNode;
  className?: string;
  Content?: React.ReactNode;
  disabled?: boolean;
  hAlign?: 'left' | 'right';
  itemMinWidth?: string;
  margin?: string;
  onClickOutside?: () => void;
  onOpen?: () => void;
  onSelect?: (key: string) => void;
  options?: Array<IItem>;
  position?: 'top' | 'bottom';
  showIcon?: boolean;
  vAlign?: 'flex-end' | 'flex-start' | 'center';
  width?: string;
  noPadding?: boolean;
}

interface IState {
  isVisible: boolean;
}

export default class SimpleDropdown extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      isVisible: false,
    };
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const {
      children,
      className,
      Content,
      hAlign,
      itemMinWidth,
      margin = '',
      options = [],
      position = 'bottom',
      showIcon = false,
      vAlign,
      width,
      noPadding,
    } = this.props;
    const { isVisible } = this.state;
    const filteredOptions = options.filter(o => o);
    if (!Content && !filteredOptions.length) return null;
    return (
      <S.Container className={className} ref={this.containerRef} vAlign={vAlign} margin={margin} width={width}>
        <S.Trigger onClick={this.handleOnFocus}>
          {children}
          {showIcon && this.renderIcon()}
        </S.Trigger>
        {isVisible &&
          (Content || (
            <S.Content
              hAlign={hAlign}
              position={position}
              numOptions={filteredOptions.length}
              itemMinWidth={itemMinWidth}
              noPadding={noPadding}
            >
              {filteredOptions.map(
                (
                  {
                    key,
                    action,
                    icon,
                    image,
                    avatar,
                    customProps,
                    color,
                    value,
                    isLink,
                    value2,
                    tooltip,
                    isSelected,
                  }: IItem,
                  idx: number,
                  array,
                ) => (
                  <S.Item
                    key={key}
                    onClick={e => {
                      e.stopPropagation();
                      if (action) action();
                      else this.handleSelect(key, true);
                    }}
                    isLast={idx === array.length - 1}
                    itemMinWidth={itemMinWidth}
                    isSelected={isSelected}
                    {...customProps}
                  >
                    {icon ? (
                      <S.IconItem color={color}>
                        <FontIcon name={icon} />
                      </S.IconItem>
                    ) : null}
                    {image ? (
                      <S.ImageItem>
                        <S.Image src={image} />
                      </S.ImageItem>
                    ) : null}
                    {avatar ? <S.AvatarItem>{avatar}</S.AvatarItem> : null}
                    <S.ItemText>
                      <S.ItemTitle color={color} isLink={isLink}>
                        {value}
                      </S.ItemTitle>
                      {value2 ? <S.ItemSubtitle>{value2}</S.ItemSubtitle> : null}
                    </S.ItemText>
                    {tooltip ? (
                      <Tooltip text={tooltip} themeMode="dark">
                        <S.InfoIcon name="Info" />
                      </Tooltip>
                    ) : null}
                  </S.Item>
                ),
              )}
            </S.Content>
          ))}
      </S.Container>
    );
  }

  /**
   * Hide content
   */
  public hide() {
    this.setState({ isVisible: false });
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Show content
   */
  public show() {
    const { onOpen } = this.props;
    onOpen?.();
    this.setState({ isVisible: true });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Render default icon
   */
  private renderIcon() {
    return (
      <S.IconWrapper>
        <FontIcon name="Down" disableHover={true} />
      </S.IconWrapper>
    );
  }

  /**
   * handle the click action, calling the callback 'onSelect' and close the drop down if the hideOnClick prop is true
   */
  private handleSelect = (key: string, hideOnClick: boolean) => {
    const { onSelect = () => null } = this.props;
    onSelect(key);
    if (hideOnClick) this.hide();
  };

  /**
   * Alert if clicked on outside of element
   */
  private handleClickOutside = (event: MouseEvent) => {
    if (this.containerRef?.current && !this.containerRef.current.contains(event.target as any)) {
      this.hide();
      this.props.onClickOutside?.();
    }
  };

  /**
   * Event to be called to get focus either on click or on focus
   */
  private handleOnFocus = (event: React.FocusEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const { disabled = false } = this.props;
    const { isVisible } = this.state;
    if (disabled) return;
    if (isVisible) this.hide();
    else this.show();
  };
}