import styled, { ITheme } from '../../../styled-components';
import { Button, CheckBox as CB, ColumnContainer, Input as InputAtom, RowContainer, TextLabel } from '../../atoms';
import { FormModal } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  showColor?: boolean;
  disabled?: boolean;
}

export const Container = styled(RowContainer)`
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Input = styled(InputAtom)`
  margin: 4px 0;
  max-width: 366px;
`;

export const FormBlock = styled(ColumnContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Option = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;
export const OptionText = styled.label`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.grey2 : p.theme.colors.black)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
`;

export const Check = styled(CB)``;

export const DnDContainer = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const NewColumnModal = styled(FormModal)`
  min-width: 400px;
  min-height: 200px;
  overflow: visible;
  .form-wrapper {
    overflow: visible;
  }
`;

export const FixedContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
`;

export const InputDescription = styled(TextLabel)`
  margin: 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 95%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TrashButton = styled(Button)`
  min-width: 0;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  position: absolute;
  right: 0;
  top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  .button-icon  {
    color: ${(p: IStyledProps) => p.theme.colors.grey1};
  }
  :hover {
    background: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
`;
