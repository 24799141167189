import { __, debounce, IContact, utils } from 'common-services';
import { AsYouType } from 'libphonenumber-js/mobile';
import * as React from 'react';

import { Input, LettersAvatar } from '../../atoms';
import * as S from './ContactCard.styled';

interface IProps {
  companyName?: string;
  name?: string;
  contact?: IContact;
  contactUpdate?: (contact: IContact) => void;
  action?: React.ReactNode;
}
const ContactCard: React.FC<IProps> = React.memo(({ action, contact, companyName, contactUpdate, name }) => {
  const phoneNumber = contact?.phoneNumbers[0] ? '+' + contact.phoneNumbers[0] : '';
  return (
    <S.ClientRow>
      <S.AvatarHeader>
        <LettersAvatar
          text={name || contact?.name}
          size={146}
          img={contact?.avatar}
          avatarColor={contact?.avatarColor || utils.getAvatarColor(name)}
        />
      </S.AvatarHeader>
      <S.ClientNameColumn>
        {contact?.isUnregistered && contactUpdate ? (
          <UnregisteredContact contact={contact} contactUpdate={contactUpdate} />
        ) : (
          <S.TextContactName>{name || contact?.name}</S.TextContactName>
        )}
        <S.TextCompany>{companyName || contact?.companyName}</S.TextCompany>
        {phoneNumber ? <S.TextGrey2>{new AsYouType().input(phoneNumber)}</S.TextGrey2> : null}
        {contact?.email ? <S.TextGrey2>{contact?.email}</S.TextGrey2> : null}
        {action}
      </S.ClientNameColumn>
    </S.ClientRow>
  );
});

const UnregisteredContact = React.memo(
  ({ contact, contactUpdate }: { contact: IContact; contactUpdate: (contact: IContact) => void }) => {
    const [contactName, setContactName] = React.useState(contact?.name);
    return (
      <Input
        name={'contactNameInput'}
        containerMargin={'4px 0'}
        width={'100%'}
        type={'text'}
        value={contactName}
        placeholder={__('Components.AddNonRegisteredUser.username_placeholder')}
        onChange={(key: string, value: string, error?: string | undefined) => {
          setContactName(value);
          contactUpdateDebounce(value, contact, contactUpdate);
        }}
      />
    );
  },
);

const contactUpdateDebounce = debounce(
  (name: string, contact: IContact, contactUpdate: (contact: IContact) => void) => {
    if (name) {
      contactUpdate({ ...contact, name });
    }
  },
  1000,
);
export default ContactCard;
