import { __, ILightContact, utils } from 'common-services';
import * as React from 'react';

import * as S from './MemberSelector.styled';

interface IProps {
  className?: string;
  member?: ILightContact;
}

const MemberSelector: React.FC<IProps> = ({ className, member }) => {
  return (
    <S.Container className={className}>
      {member ? (
        <S.LettersAvatar
          avatarColor={utils.getAvatarColor(member.name)}
          img={member.avatar}
          size={30}
          text={member.name}
        />
      ) : (
        <S.NoSelectedContainer>
          <S.MemberIcon name="Profile" disableHover={true} />
        </S.NoSelectedContainer>
      )}
      <S.TextName>{member?.name || __('MemberSelector.unselected')}</S.TextName>
      <S.DownIcon name="Down" />
    </S.Container>
  );
};

export default React.memo(MemberSelector);
