import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { __ } from 'common-services';

import { ROUTE_PATHS } from '../../../constants';
import { EmptyListResource, OrderTable, OrderAggregationFooter } from '../../molecules';
import * as S from './OrdersList.styled';
import getPath from '../../../util/routes';

interface OrdersContentProps {
  orders: Array<IOrder>;
  search: ISearchOrder;
  type: 'purchase' | 'sale';
  contactId?: number;
  setShowContactsModal: (show: boolean) => void;
  catalogHash?: string;
  catalogs: { [id: number]: IWorkspace };
  clients: { [id: number]: Array<IClient> };
  contacts: { [cId: number]: IContact };
  dateRange: string;
  hasMore: boolean;
  isUnselected: boolean;
  me: IUser;
  openOrderSelected: (order: IOrder) => void;
  selectedOrderIds: Array<number>;
  setSelectedOrderIds: (arr: Array<number>) => void;
  orderToggleReadStatus: (userId: number, orderId: number, status: boolean) => void;
  removeSelection: () => void;
  selectAll: () => void;
  suppliers: { [id: number]: Array<ISupplier> };
  totalResults: number;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  tabSelected: string;
  facets: IFacets;
  globalFacets: IFacets;
  markAllAsRead: () => void;
}

const OrdersContentComponent: React.FC<OrdersContentProps> = ({
  orders,
  search,
  type,
  catalogHash,
  catalogs,
  clients,
  contacts,
  dateRange,
  hasMore,
  isUnselected,
  me,
  openOrderSelected,
  selectedOrderIds,
  setSelectedOrderIds,
  orderToggleReadStatus,
  removeSelection,
  selectAll,
  suppliers,
  totalResults,
  touchImage,
  tabSelected,
  facets,
  globalFacets,
  markAllAsRead,
}) => {
  const history = useHistory();

  return orders.length ? (
    <>
      <OrderTable
        catalogHash={catalogHash}
        catalogs={type === 'purchase' ? catalogs : catalogs}
        clients={clients}
        contacts={contacts}
        dateRangeDescription={dateRange}
        hasMore={hasMore}
        isUnselect={isUnselected}
        markAllAsRead={tabSelected === 'unread' ? markAllAsRead : undefined}
        me={me}
        orderClick={openOrderSelected}
        orders={orders}
        ordersSelected={selectedOrderIds}
        orderToggleReadStatus={orderToggleReadStatus}
        orderUnreads={tabSelected === 'unread' ? totalResults : undefined}
        removeSelection={removeSelection}
        selectable={tabSelected !== 'unread'}
        selectAll={selectAll}
        sellerFacets={facets.sellers}
        setOrdersSelected={setSelectedOrderIds}
        showContactName={true}
        suppliers={suppliers}
        totalResults={totalResults}
        touchImage={touchImage}
        showOrderSource={!!(globalFacets.origin && Object.keys(globalFacets.origin).length > 1)}
        type={type}
      />
      {isUnselected || selectedOrderIds.length ? (
        <OrderAggregationFooter
          searchCurrency={search.currency}
          groupedBy="order"
          numberOfOrders={isUnselected ? totalResults - selectedOrderIds.length : selectedOrderIds.length}
          weightUnit={catalogs[me.sellerWorkspaceId!]?.defaultWeightUnit}
        />
      ) : null}
    </>
  ) : (
    <S.CenterContainer className="orders-list-center-container">
      <EmptyListResource
        text={__('Components.OrdersList.EmptyResults.title')}
        text2={__('Components.OrdersList.EmptyResults.description')}
        buttonText={__('Components.OrdersList.EmptyResults.cta')}
        buttonAction={() => history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST }))}
        showButton={true}
      />
    </S.CenterContainer>
  );
};

export const OrdersContent = React.memo(OrdersContentComponent);
