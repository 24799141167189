import styled, { ITheme } from '../../../styled-components';
import {
  BackButton as BB,
  Button,
  ColumnContainer,
  FontIcon,
  LettersAvatar,
  RowContainer,
  TextLabel,
} from '../../atoms';
import { SimpleSearch as SS } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;

export const BodyContainer = styled(ColumnContainer)`
  height: auto;
  width: 830px;
  align-self: center;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

export const AddMembersButton = styled(Button)`
  align-self: center;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TextGrey1 = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const SearchRow = styled(RowContainer)`
  align-items: center;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const UserRow = styled(RowContainer)`
  align-items: center;
`;

export const UserAvatar = styled(LettersAvatar)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SimpleSearch = styled(SS)`
  width: 100%;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  max-width: inherit;
`;

const BackButton = styled(BB)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
