import { connect } from 'react-redux';

import SelectContacts, { IScreenProps } from '../components/molecules/SelectContacts';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: any): IScreenProps {
  const {
    user: { user },
    country: { countries },
  } = state;

  return {
    me: user!,
    countries,
  };
}

export default connect(mapStateToProps)(SelectContacts);
