import styled, { css, keyframes, ITheme } from '../../../styled-components';

interface IStyledProps {
  borderRadius?: string;
  height?: string;
  margin?: string;
  theme: ITheme;
  width?: string;
  widthFixed?: boolean;
}

const backgroundAnimation = keyframes`
0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Skeleton = styled.div`
  background: linear-gradient(
    270deg,
    ${(p: IStyledProps) => p.theme.colors.light2},
    ${(p: IStyledProps) => p.theme.colors.light1}
  );
  background-size: 400% 400%;
  animation: ${backgroundAnimation} 1.8s ease-in infinite;
`;

export const SkeletonItem = styled(Skeleton)<IStyledProps>`
  width: ${(p: IStyledProps) => p.width};
  height: ${(p: IStyledProps) => p.height};
  border-radius: ${(p: IStyledProps) => p.borderRadius || p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.margin || '0'};
  ${(p: IStyledProps) =>
    p.widthFixed &&
    css`
      min-width: ${p.width};
      max-width: ${p.width};
    `};
`;
