import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, Picture, RowContainer } from '../../atoms';
import ActionsModal from '../ActionsModal';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(ActionsModal)`
  padding: 0;
  border: none;
  .actions-modal-header {
    padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  }
`;

export const ModalContent = styled(ColumnContainer)``;

export const ImageContainer = styled(RowContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  position: relative;
`;

export const ChannelImage = styled.img`
  width: 100%;
  height: 150px;
  border-radius: 0;
  object-fit: cover;
`;

export const PictureThumbnailContainer = styled(ColumnContainer)`
  position: absolute;
  bottom: -15%;
  left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.white};
`;

export const PictureThumbnail = styled(Picture)`
  border-radius: 0;
  margin: 0;
  .picture-img {
    border-radius: 0;
  }
`;

export const FormContent = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  min-width: 260px;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const ActionButtonsContainer = styled(RowContainer)`
  position: absolute;
  right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  top: ${(p: IStyledProps) => p.theme.paddingSize(3)};

  .chat-public-edit-picture {
    margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const ActionButton = styled(RowContainer)`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: ${(p: IStyledProps) => p.theme.colors.black.replace('1)', '0.6)')};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

export const ActionIcon = styled(FontIcon)`
  width: 17px;
  height: 17px;
  font-size: 17px;
  color: ${(p: IStyledProps) => p.theme.colors.white};
`;
