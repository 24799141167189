import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, TextLabel } from '../../atoms';

interface IStyledProps {
  align?: string;
  asColumn?: boolean;
  disabled?: boolean;
  display?: string;
  isRequired?: boolean;
  isVerified?: boolean;
  theme: ITheme;
  width?: string;
}

export const Container = styled.div`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: ${(p: IStyledProps) => p.width || 'auto'};
  ${(p: IStyledProps) =>
    p.align
      ? css`
          align-self: ${p.align};
        `
      : null};
  ${(p: IStyledProps) =>
    p.isVerified &&
    css`
      position: relative;
    `}
  scroll-margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const InputLabel = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: auto;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
`;

export const InputLabelName = styled.span`
  white-space: nowrap;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const InputLabelOptional = styled(InputLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  padding-bottom: 0;
`;

export const CheckIconWrapper = styled.div`
  ${(p: IStyledProps) =>
    !p.disabled &&
    css`
      position: absolute;
      top: 49%;
      right: 10px;
    `}
  border-radius: 50%;
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  padding: 0 3px;
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)}; 
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  margin-right: ${(p: IStyledProps) => (p.disabled ? p.theme.paddingObject : 0)};
`;

export const InputDescription = styled(TextLabel)`
  margin: 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 95%;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextHiglighted = styled(InputLabelName)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const Wrapper = styled(ColumnContainer)`
  flex-direction: ${(p: IStyledProps) => (p.disabled && !p.asColumn ? 'row' : 'column')};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const AlertIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: 1.125rem;
  width: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  position: relative;
  bottom: -3px;
`;

export const InfoIconBlack = styled(InfoIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;
