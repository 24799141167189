import { LOCALE, utils } from 'common-services';
import * as React from 'react';

import SimpleDropdown, { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import * as S from './LanguageSelect.styled';

interface IProps {
  className?: string;
  label: string;
  onSelect: (l: LOCALE) => void;
  options: Array<IItem>;
}

const LanguageSelect: React.FC<IProps> = ({ className, label, onSelect, options }) => {
  return (
    <S.Container className={className}>
      <SimpleDropdown options={options} onSelect={key => onSelect(key as LOCALE)}>
        <S.MenuRow>
          <S.Title>{utils.firstToUpperCase(label)}</S.Title>
          <S.Chevron name="Down" />
        </S.MenuRow>
      </SimpleDropdown>
    </S.Container>
  );
};

export default React.memo(LanguageSelect);
