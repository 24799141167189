import * as React from 'react';

import * as S from './ProgressBar.styled';

export interface IProps {
  barHeight?: number;
  className?: string;
  hideCount?: boolean;
  progress: number;
  progressPrecision?: number;
  textClassName?: string;
  title: string;
}

const ProgressBar: React.FC<IProps> = ({
  barHeight = 5,
  className,
  progress,
  progressPrecision,
  textClassName,
  title,
  hideCount,
}) => {
  const progressMin = Math.min(progress, 100);
  const progressDisplay = Math.ceil(progressMin);
  const showDecimals = progressPrecision && progressDisplay !== progressMin;
  return (
    <S.Container className={className}>
      {title ? (
        <S.Row>
          <S.Text progress={progress} className={textClassName}>
            {title}
          </S.Text>
          {hideCount ? null : (
            <S.Text className={textClassName}>
              {showDecimals ? progressMin.toFixed(progressPrecision) : progressDisplay}%
            </S.Text>
          )}
        </S.Row>
      ) : null}
      <S.ProgressContainer height={barHeight}>
        <S.ProgressBar height={barHeight} progress={progress} />
      </S.ProgressContainer>
    </S.Container>
  );
};

export default React.memo(ProgressBar);
