import {
  Api,
  broadcastActions,
  CHANNEL_MEMBER_ROLE,
  chatActions,
  contactActions,
  imageActions,
  modalActions,
  notificationsActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import ChatCreate, { DispatchProps, OwnProps, StateProps } from '../components/organisms/ChatCreate';
import { IReduxState } from '../reducers';

function mapStateToPropsDetails(state: IReduxState, props: OwnProps): StateProps {
  const {
    contact: { inConsentio },
    chat: { channels },
    broadcast: { broadcasts },
    user: { user },
  } = state;

  return {
    me: user,
    broadcasts,
    contacts: inConsentio,
    channels,
    role: CHANNEL_MEMBER_ROLE.OWNER,
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: OwnProps,
): DispatchProps {
  return bindActionCreators(
    {
      addMembers: chatActions.addMembers,
      channelLeave: chatActions.channelLeave,
      channelMute: chatActions.channelMute,
      createChannel: chatActions.createChannel,
      deleteMembers: chatActions.deleteMembers,
      hideLoading: modalActions.hideLoading,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      showLoading: modalActions.showLoading,
      touchImage: modalActions.touchImage,
      updateChannel: chatActions.updateChannel,
      addBroadcastMembers: broadcastActions.addMembers,
      createBroadcast: broadcastActions.createBroadcast,
      deleteBroadcastMembers: broadcastActions.deleteMembers,
      updateBroadcast: broadcastActions.updateBroadcast,
      getMembers: broadcastActions.getMembers,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(ChatCreate);
