import {
  __,
  constants,
  debounce,
  ICountry,
  IProdType,
  IProduct,
  IUser,
  LOCALE,
  modalActions,
  parsers,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import Layout from '../../../layout-flex';
import { ColumnContainer, RowContainer, SubHeader, Switch, TextArea } from '../../atoms';
import ProductInfo from '../ProductInfo';
import * as S from './Athina.styled';

export interface IStateProps {
  me: IUser;
  countries: { [key: string]: ICountry };
  prodTypes: { [key: string]: IProdType };
}

export interface IDispatchProps {
  analyzeProduct: (
    text: string,
    language: string,
    exactFind: boolean,
    callback?: (error?: Error, product?: IProduct, results?: Array<{ text: string; result: string }>) => void,
  ) => void;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  touchImage: typeof modalActions.touchImage;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps;

interface IState {
  text: string;
  lang: LOCALE;
  product?: IProduct;
  results?: Array<{ text: string; result: string }>;
  debug: boolean;
  fuzzy: boolean;
}

export default class Athina extends React.PureComponent<IProps, IState> {
  private analyzeProduct;
  constructor(props) {
    super(props);
    this.state = { text: '', lang: LOCALE.ES, debug: false, fuzzy: true };
    this.analyzeProduct = debounce(props.analyzeProduct, 500);
  }

  public render() {
    const { countries, history, match, location, prodTypes, modalClose, modalOpen, me, touchImage } = this.props;
    const { lang, product, text, results, debug, fuzzy } = this.state;
    return (
      <Layout
        header={{
          show: true,
          title: 'Athina ',
          subtitle: 'Analyze',
          tabSelected: 'chat',
        }}
      >
        <S.Container>
          <SubHeader className="Athina-SubHeader">
            <S.ButtonBack onClick={history.goBack} iconName="Arrow">
              {'Go back'}
            </S.ButtonBack>
          </SubHeader>
          <S.BodyContainer>
            <S.LeftContainer>
              <S.Title>Athina product string analyzer</S.Title>
              <RowContainer margin="0 0 20px 0">
                <ColumnContainer>
                  <S.Label htmlFor="lang-select">{__('Pricelist.language')}</S.Label>
                  <S.LangSelect
                    id="lang-select"
                    value={lang || ''}
                    onChange={e => {
                      const l = e.target.value as LOCALE;
                      this.setState({ lang: l });
                    }}
                  >
                    {Object.keys(LOCALE)
                      .sort()
                      .map(p => (
                        <option key={p} value={LOCALE[p]}>
                          {LOCALE[p]}
                        </option>
                      ))}
                  </S.LangSelect>
                </ColumnContainer>
                <ColumnContainer margin="0 0 0 20px">
                  <S.Label htmlFor="debug">Debug</S.Label>
                  <Switch
                    name="debug"
                    isChecked={debug}
                    onChange={(name, checked) => this.setState({ debug: checked })}
                  />
                </ColumnContainer>
                <ColumnContainer margin="0 0 0 20px">
                  <S.Label htmlFor="fuzzy">Fuzzy</S.Label>
                  <Switch
                    name="fuzzy"
                    isChecked={fuzzy}
                    onChange={(name, checked) => this.setState({ fuzzy: checked })}
                  />
                </ColumnContainer>
                <ColumnContainer margin="0 0 0 20px">
                  <Dictaphone language={lang} updateText={this.updateText} />
                </ColumnContainer>
              </RowContainer>
              <TextArea
                name="Text"
                rows={5}
                value={text}
                onChange={(name, val) => {
                  this.setState({ text: val + '' });
                  this.analyzeProduct(val + '', lang, !fuzzy, (err, prod, resultsList) => {
                    if (err) return console.log('err', err);
                    this.setState({ product: prod, results: resultsList });
                  });
                }}
                placeholder={'This is the Athina product analyzer'}
                maxLength={512}
              />
              {debug && results && results.length ? (
                <ColumnContainer margin="10px 0 0 0">
                  {results.map((item, index) => (
                    <S.ResultsUl key={index}>
                      <S.ResultsLi>
                        <S.ResultsTitle>Text - </S.ResultsTitle>
                        {item.text}
                      </S.ResultsLi>
                      <S.ResultsLi>
                        <S.ResultsTitle>Result - </S.ResultsTitle>
                        {item.result}
                      </S.ResultsLi>
                    </S.ResultsUl>
                  ))}
                </ColumnContainer>
              ) : null}
            </S.LeftContainer>

            {product ? (
              <S.RightContainer>
                <ProductInfo
                  amSeller={true}
                  close={() => this.setState({ product: undefined })}
                  contactId={0}
                  contactName={''}
                  isContactUnregistered={false}
                  catalogId={me.sellerWorkspaceId}
                  countries={countries}
                  from="product"
                  history={history}
                  me={me}
                  modalClose={modalClose}
                  modalOpen={modalOpen}
                  priceMode="read"
                  pricePrecision={constants.PRICE_PRECISION}
                  product={{
                    ...parsers.productToGenericProduct(product),
                    updatedAt: product.updatedAt,
                    saleUnits: product.saleUnits,
                  }}
                  prodTypes={prodTypes}
                  showBack={false}
                  showDefaultPicture={true}
                  showFeatured={false}
                  showShare={false}
                  showVarietyAsCharacteristic={true}
                  touchImage={touchImage}
                />
              </S.RightContainer>
            ) : null}
          </S.BodyContainer>
        </S.Container>
      </Layout>
    );
  }
  private updateText = (speech: string) => {
    const { lang, fuzzy } = this.state;
    this.setState({ text: speech });
    this.analyzeProduct(speech + '', lang, !fuzzy, (err, prod, resultsList) => {
      if (err) return console.log('err', err);
      this.setState({ product: prod, results: resultsList });
    });
  };
}

const Dictaphone = (props: { updateText: (value: string) => void; language: LOCALE }) => {
  const { transcript } = useSpeechRecognition();
  const { updateText } = props;
  const [listening, setListening] = React.useState(false);
  React.useEffect(() => {
    if (transcript) {
      updateText(transcript);
    }
  }, [transcript, updateText]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  function operateMicrophone() {
    if (!listening) {
      SpeechRecognition.startListening({ language: props.language, continuous: true });
      setListening(true);
    } else {
      SpeechRecognition.stopListening();
      setListening(false);
    }
  }

  return (
    <S.MicrophoneContainer onClick={operateMicrophone}>
      <S.MicrophoneIcon name={listening ? 'Microphone-off' : 'Microphone-on'} />
    </S.MicrophoneContainer>
  );
};
