import styled, { ITheme } from '../../../styled-components';
import {
  Button,
  ColumnContainer,
  ContactSelectable,
  FontIcon,
  LettersAvatar as LA,
  RowContainer,
  TextLabel,
} from '../../atoms';
import { LoadingModal, SimpleSearch } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
  error?: boolean;
  red?: boolean;
  isLast?: boolean;
}

export const Container = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  overflow: hidden;
  flex: 1;
  overflow-y: auto;
`;

export const LettersAvatar = styled(LA)``;

export const Loading = styled(LoadingModal)`
  padding-top: 100px;
`;

export const Buttons = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
  align-items: flex-start;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const Search = styled(SimpleSearch)`
  margin: 0;
  width: 350px;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const NumMembers = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const RowSwitch = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const RowGeneral = styled(RowContainer)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const LettersAvatarContainer = styled(ColumnContainer)`
  align-self: center;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  position: relative;
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;

export const Row = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
`;

export const ArchiveButton = styled(Button)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const DropdownIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;
export const MembersContainer = styled(ColumnContainer)`
  max-width: 523px;
`;
export const MemberSelectable = styled(ContactSelectable)`
  margin-left: -${(p: IStyledProps) => p.theme.paddingSize()};
  .dropdown-icon {
    display: none;
  }
  :hover {
    .dropdown-icon {
      display: block;
    }
  }
`;

export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${(p: IStyledProps) => p.theme.colors.orange2};
  padding: 2px 3px;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const TeamUnactive = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
`;

export const Text = styled(TextLabel)``;
