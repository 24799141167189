import {
  __,
  addresses,
  CHANNEL_TYPE,
  chatActions,
  chatSelectors,
  CURRENCY_CODES,
  date,
  debounce,
  EventTrack,
  ICommentChannelExtraDataAPI,
  modalActions,
  ORDER_STATUS,
  orderActions,
  orderService,
  parsers,
  productService,
  qs,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userActions,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import { IReduxState } from '../../../reducers';
import Chat from '../../../screens/chat';
import OrderDetails from '../../../screens/order-details';
import ShowroomSell from '../../../screens/showroom-sell';
import Workspace from '../Workspace/Workspace.component';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { ExpansionPanel } from '../../atoms';
import { ContactCell, EmptyListResource, Facets } from '../../molecules';
import * as S from './WorkspacePrepareOrders.styled';

export type IRouteProps = RouteComponentProps<{ hashId: string; workspaceId: string }>;

export interface IStateProps {
  catalog: IWorkspace;
  clients: { [id: number]: Array<IClient> };
  contacts: { [cId: number]: IContact };
  countries: { [key: string]: ICountry };
  facets: IFacets;
  facetsGlobal: IFacets;
  filters: IOrderFilters;
  hasMore?: boolean;
  me: IUser;
  onlyUnreads?: boolean;
  orders: Array<IOrder>;
  searchId: string;
  totalResults: number;
}

export interface IDispatchProps {
  unreadCommentsGet: typeof sellerWorkspaceActions.unreadCommentsGet;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  orderFiltersSet: typeof orderActions.orderFiltersSet;
  orderGetByHash: typeof orderActions.orderGetByHash;
  ordersAllMarkAsRead: typeof orderActions.ordersAllMarkAsRead;
  orderScrollSearch: typeof orderActions.orderScrollSearch;
  orderSearch: typeof orderActions.orderSearch;
  orderToggleReadStatus: typeof orderActions.toggleOrderReadStatus;
  requestProInfo: typeof userActions.requestProInfo;
  touchImage: typeof modalActions.touchImage;
}

type IProps = IStateProps & IDispatchProps & IRouteProps;

interface IState {
  commentsData?: Record<number, ICommentChannelExtraDataAPI>;
  dateRange: IDateRange;
  editAfterAccept?: boolean;
  facets: IFacets;
  facetsGlobal: IFacets;
  hasMore?: boolean;
  hideFilters?: boolean;
  isSearched?: boolean;
  isUnselect: boolean;
  miniChannelId?: string;
  orderItems: Array<IOrderAggregationItem>;
  orderRequests: Array<IOrderRequest>;
  orderSelected?: IOrder;
  orderSelectedIds: Array<number>;
  searchId?: string;
  searchState: ISearchOrder;
  showroomSelected?: IOrder;
  totalResults: number;
  isPrepare: boolean;
  breadcrumb: {
    parentSections: Array<IBreadcrumb>;
    title: string;
  };
}

/**
 * Prepare orders screen
 */
class WorkspacePrepareOrders extends React.PureComponent<IProps, IState> {
  private t: number;
  private lastDatesUsed: { beginDate: Date; endDate: Date };

  private sendSearch = debounce(() => {
    const { me, orderSearch } = this.props;
    const { searchState } = this.state;
    this.setState({ isSearched: false });
    return orderSearch(searchState, config.SEARCH_PREFIX, me.id, true);
  }, 200);

  private scrollSearch = debounce(() => {
    const { searchId, orderScrollSearch } = this.props;
    orderScrollSearch(searchId);
  }, 200);
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const {
      facets,
      totalResults,
      facetsGlobal,
      hasMore,
      match: { params },
      history,
    } = props;
    const { dateRange, isUnselect, selected } = this.getInitialFilters();
    const breadcrumb = {
      parentSections: [
        {
          label: __('Components.Header.WorkspacePrepareOrders'),
          action: () => {
            this.setState({ orderSelected: undefined, showroomSelected: undefined, breadcrumb });
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS,
                workspaceId: params?.workspaceId,
              }),
            );
          },
        },
      ],
      title: '',
    };
    this.state = {
      dateRange,
      facets,
      orderItems: [],
      orderRequests: [],
      orderSelectedIds: selected ? selected.split(',').map(id => Number(id)) : [],
      isUnselect,
      searchState: this.getSearchState(),
      totalResults,
      isPrepare: true,
      hasMore,
      facetsGlobal,
      hideFilters: true,
      breadcrumb,
    };
  }

  public componentDidMount() {
    const { match, me, orderGetByHash } = this.props;
    const { dateRange, searchState } = this.state;
    RenderTrack.track('WorkpacePrepareOrders', { renderTime: this.t, type: 'sale' });
    const dates =
      dateRange === 'custom'
        ? { beginDate: searchState.beginDate, endDate: searchState.endDate }
        : date.getDatesFromRange(dateRange);
    this.lastDatesUsed = dates;
    this.sendSearch(searchState);
    if (match.params.hashId) {
      orderGetByHash(me.id!, match.params.hashId, order => {
        this.openOrderSelected(order);
      });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const {
      catalog,
      clients,
      contacts,
      facets,
      facetsGlobal,
      filters,
      hasMore,
      history,
      match: { params },
      me,
      orders,
      searchId,
      totalResults,
      unreadCommentsGet,
    } = this.props;
    const { dateRange, searchState, orderSelected, showroomSelected, breadcrumb } = this.state;

    if (prevProps.catalog?.id !== catalog?.id) {
      let dateRangeUpdate = dateRange;
      if (dateRange === 'all') dateRangeUpdate = filters.range && filters.range !== 'all' ? filters.range : 'month';
      this.setDates(dateRangeUpdate, true);
      this.setState({
        searchState: this.getSearchState(),
      });
    }
    if (
      totalResults !== prevProps.totalResults ||
      hasMore !== prevProps.hasMore ||
      facetsGlobal !== prevProps.facetsGlobal ||
      facets !== prevProps.facets
    ) {
      this.setState({ totalResults, hasMore, facets, facetsGlobal });
    }

    if (prevState.searchState !== searchState) {
      this.lastDatesUsed = { beginDate: searchState.beginDate, endDate: searchState.endDate };
      this.sendSearch(searchState);
    }

    if (facets.currency && Object.keys(facets.currency).length && !facets.currency[searchState.currency]) {
      this.setState({
        searchState: {
          ...searchState,
          currency: Object.keys(facets.currency)[0] as CURRENCY_CODES,
        },
      });
    }
    if (
      (prevProps.orders !== orders && Object.keys(contacts).length) ||
      prevProps.contacts !== contacts ||
      prevProps.match.params?.workspaceId !== params?.workspaceId
    ) {
      // Try yo show orders when we have orders and contacts
      this.showOrderFromUrl();
      if (orders.length) {
        unreadCommentsGet(
          me.id,
          orders.map(o => o.id),
          data => {
            this.setState({ commentsData: data });
          },
        );
      }
    }
    if (searchId && prevProps.searchId !== searchId) {
      this.setState({ isSearched: true });
    }
    if (
      prevProps.match.params?.workspaceId !== params?.workspaceId ||
      prevState.orderSelected !== orderSelected ||
      prevState.showroomSelected !== showroomSelected
    ) {
      this.setState({
        breadcrumb: {
          parentSections: [
            {
              label: __('Components.Header.WorkspacePrepareOrders'),
              action: () => {
                this.setState({ orderSelected: undefined, showroomSelected: undefined, breadcrumb });
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS,
                    workspaceId: params?.workspaceId,
                  }),
                );
              },
            },
          ],
          title: orderSelected
            ? orderSelected?.externalIdSeller || orderSelected?.externalIdBuyer || orderSelected?.hashId
            : showroomSelected
            ? __('Components.Header.Products', {
                name: clients[params?.workspaceId]?.find((c: IClient) => c.userId === showroomSelected?.buyerId)?.name,
              })
            : '',
        },
      });
    }
  }

  public componentWillUnmount() {
    const { filters, orderFiltersSet } = this.props;
    const { dateRange, searchState } = this.state;
    orderFiltersSet(0, {
      ...filters,
      currency: searchState.currency,
      range: dateRange,
      since: this.lastDatesUsed.beginDate ? this.lastDatesUsed.beginDate.getTime() : undefined,
      type: 'sale',
      until: this.lastDatesUsed.endDate ? this.lastDatesUsed.endDate.getTime() : undefined,
    });
  }

  public render() {
    const { catalog, clients, contacts, history, match, location, me } = this.props;
    const {
      breadcrumb,
      dateRange,
      facets,
      facetsGlobal,
      hideFilters,
      isSearched,
      miniChannelId,
      orderSelected,
      searchState,
      showroomSelected,
    } = this.state;
    return (
      <Workspace
        subtitle={''}
        parentSections={breadcrumb.parentSections}
        title={breadcrumb.title}
        tabSelected="prepare-orders"
        workspaceId={catalog?.id}
      >
        <S.OrdersContainer>
          <S.OrdersContainerRow>
            <Facets
              catalogs={[catalog]}
              clients={clients[catalog?.id]}
              changeSearchState={(s, range) => {
                const filters = orderService.getTrackedFilters(s, (range as IDateRange) || dateRange);
                if (filters.length) {
                  EventTrack.track('prepare_orders_filters', {
                    workspace_id: catalog?.id,
                    filters,
                  });
                }
                this.setState({ searchState: s }, () => {
                  if (range) this.setDates(range, false);
                });
              }}
              clearFilters={() => {
                this.setState({ searchState: this.getSearchState() });
                EventTrack.track('prepare_orders_filter_clear', { workspace_id: catalog?.id });
              }}
              contacts={contacts}
              dateRange={''}
              facets={facets}
              facetsGlobal={facetsGlobal}
              me={me}
              numHeaders={2}
              onHide={hide => this.setState({ hideFilters: hide })}
              searchState={searchState}
              showClosed={hideFilters}
              viewMode="prepare-orders"
              type={'sale'}
            />
            <S.Container className="orders-dashboard-container">{isSearched ? this.renderOrders() : null}</S.Container>
          </S.OrdersContainerRow>
          {orderSelected ? this.renderNewCart() : null}
          {miniChannelId ? (
            <Chat
              match={{ params: { channelId: miniChannelId } } as any}
              isModal={true}
              history={{} as any}
              location={{} as any}
              styleModal={{ right: '90px' }}
              onClose={() => this.setState({ miniChannelId: undefined })}
              onMinimize={() => this.setState({ miniChannelId: undefined })}
            />
          ) : null}
        </S.OrdersContainer>
        {showroomSelected ? this.renderShowroom() : null}
      </Workspace>
    );
  }

  /**
   * Set new date range and update search state accordingly
   */
  private setDates = (key: string, newSearch: boolean) => {
    const { searchState } = this.state;
    const newSearchState = {
      ...(newSearch ? this.getSearchState() : searchState),
      ...(key === 'unknown'
        ? { withoutDeliveryAt: true, beginDate: null, endDate: null }
        : {
            withoutDeliveryAt: undefined,
            ...(key === 'custom' ? this.lastDatesUsed : date.getDatesFromRange(key as IDateRange)),
          }),
    };
    this.setState({
      dateRange: key as IDateRange,
      searchState: newSearchState,
    });
  };

  private getSearchState = (): ISearchOrder => {
    const { catalog, me } = this.props;

    return {
      allCatalogHashs: catalog?.hashId ? [catalog?.hashId] : [],
      catalogHash: catalog?.hashId,
      language: me.settings.language as LOCALE,
      myId: me.id,
      status: [ORDER_STATUS.ACCEPTED],
      isDelivery: true,
    };
  };

  private renderOrders() {
    const { catalog, history, me, orders, totalResults, clients, contacts, countries } = this.props;
    const { commentsData, hideFilters } = this.state;
    const role = sellerWorkspaceService.getRole(catalog, me.id);
    return orders.length ? (
      <>
        <S.FilterButton
          filtersOpened={!hideFilters}
          filtersSelected={this.getFiltersCount()}
          onClick={() => this.setState({ hideFilters: !hideFilters })}
        />

        <S.Container className="prepare-orders-container" onScroll={this.onScroll}>
          <S.TotalResultsContainer>
            <S.TextSmall>{__('Components.OrdersList.results', { count: totalResults })}</S.TextSmall>
          </S.TotalResultsContainer>
          {orders
            .sort((a, b) => {
              const aDate = a.pickupEstimatedAt!;
              const bDate = b.pickupEstimatedAt!;
              if (aDate === bDate) return 0;
              return aDate > bDate ? 1 : -1;
            })
            .reduce((acc: Array<Array<IOrder>>, o, idx, arr) => {
              if (
                !idx ||
                date.formatLongDate(o.pickupEstimatedAt) !== date.formatLongDate(arr[idx - 1].pickupEstimatedAt)
              ) {
                acc.push([o]);
              } else {
                acc[acc.length - 1].push(o);
              }
              return acc;
            }, [])
            .map((t, index) => {
              const hasChanges = t.find(o => o.items.find(i => i.modifiedAfterPrepared));
              return (
                <ExpansionPanel
                  key={t[0].hashId}
                  title={date.formatLongDate(t[0].pickupEstimatedAt).toLocaleUpperCase()}
                  defaultExpanded={!index}
                  iconPosition="left"
                  titleContainerStyle={{ paddingLeft: theme.paddingSize(2) }}
                  showWarning={!!hasChanges}
                >
                  {t.length
                    ? t.map((o, i) => (
                        <OrderCard
                          clients={clients?.[catalog?.id]}
                          commentsData={commentsData}
                          contacts={contacts}
                          countries={countries}
                          index={i}
                          key={o.hashId}
                          me={me}
                          navShowroom={this.navShowroom}
                          openOrderDetail={(editAfterAccept?: boolean) => {
                            this.setState({ orderSelected: o, editAfterAccept });
                          }}
                          order={o}
                          role={role}
                          setMiniChannelId={id => this.setState({ miniChannelId: id })}
                        />
                      ))
                    : null}
                </ExpansionPanel>
              );
            })}
        </S.Container>
      </>
    ) : (
      <S.CenterContainer>
        <EmptyListResource
          text={__('Components.OrdersList.EmptyResults.title')}
          text2={__('Components.OrdersList.EmptyResults.description')}
          buttonText={__('Components.OrdersList.EmptyResults.cta')}
          buttonAction={() => history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST }))}
          showButton={true}
        />
      </S.CenterContainer>
    );
  }

  /**
   * Count selected filters
   */
  private getFiltersCount() {
    const { searchState } = this.state;
    const { sellers, sections } = searchState;
    return 0 + (sections?.length || 0) + (sellers?.length || 0);
  }
  /**
   * Render the order selected in the new cart screen
   */
  private renderNewCart() {
    const { contacts, match, history, location } = this.props;
    const { orderSelected, editAfterAccept, isPrepare, breadcrumb } = this.state;
    const contactId = orderSelected.buyerId;
    const contact = orderSelected ? contacts[contactId] : undefined;
    return (
      <S.CartContainer>
        <OrderDetails
          amSeller={false}
          closeCart={() => {
            this.closeOrderSelected();
          }}
          contact={contact}
          orderId={orderSelected && orderSelected.id}
          history={history}
          hideBack={true}
          isPrepare={isPrepare}
          location={location}
          match={match}
          backLink={__('Components.Cart.back_orders')}
          navCloneAction={() => {
            this.updateUrl({ hashId: undefined });
          }}
          navShowroom={this.navShowroom}
          editAfterAccept={editAfterAccept}
          openedFrom={'sales'}
          breadcrumb={breadcrumb}
          updateBreadcrumb={brc => this.setState({ breadcrumb: brc })}
        />
      </S.CartContainer>
    );
  }

  /**
   * Render the order selected in the new cart screen
   */
  private renderShowroom() {
    const { contacts } = this.props;
    const { showroomSelected, breadcrumb } = this.state;
    const contactId = showroomSelected.buyerId;
    const contact = showroomSelected ? contacts[contactId] : undefined;
    const cp = {
      ...this.props,
      hideBack: true,
      contact,
      next: () => this.setState({ showroomSelected: undefined, orderSelected: showroomSelected, isPrepare: false }),
      breadcrumb,
      updateBreadcrumb: brc => this.setState({ breadcrumb: brc }),
    };
    return showroomSelected ? (
      <S.ShowroomContainer>
        <S.ShowroomContainerRow>
          <ShowroomSell {...cp} />
        </S.ShowroomContainerRow>
      </S.ShowroomContainer>
    ) : null;
  }

  private navShowroom = (order?: IOrder) => {
    const { orderSelected } = this.state;
    this.setState({
      showroomSelected: order || orderSelected,
    });
  };
  /**
   * Show order from url param hashId.
   */
  private showOrderFromUrl() {
    const { match, me, orders, orderGetByHash } = this.props;
    const { orderSelected } = this.state;
    const { hashId } = match.params;
    if (hashId && (!orderSelected || orderSelected.hashId !== hashId)) {
      const order = hashId === 'new' ? orders.find(or => or.id === 0) : orders.find(or => or.hashId === hashId);
      if (order && (!orderSelected || !order.id || orderSelected.id === order.id)) {
        this.openOrderSelected(order);
      } else if (hashId !== 'new') {
        orderGetByHash(me.id, hashId, (o?: IOrder, err?: Error) => {
          if (!err) this.openOrderSelected(o);
        });
      }
    }
  }

  /**
   * Show one order in the cart view
   */
  private openOrderSelected = (orderSelected: IOrder) => {
    this.setState({
      orderSelected,
    });
    this.updateUrl(orderSelected);
  };

  /**
   * Close the selected order
   */
  private closeOrderSelected = () => {
    this.updateUrl();
    this.setState({ showroomSelected: undefined, orderSelected: undefined, isPrepare: true });
  };
  /**
   * Update the current url in the browser if navigate to/from a order.
   */
  private updateUrl(order?: IOrder | { hashId?: string }) {
    const { history, totalResults, catalog } = this.props;
    const { orderSelectedIds, dateRange, isUnselect, searchState } = this.state;

    const isAll = totalResults > 0 && orderSelectedIds.length === totalResults;

    history.replace(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_PREPARE_ORDER_DETAILS,
        hashId: order?.hashId || '',
        workspaceId: catalog.id + '',
      }).replace('?', '') +
        qs.stringify({
          currency: searchState.currency,
          range: dateRange,
          selected: isAll ? 'all' : orderSelectedIds.join(','),
          since: searchState.beginDate && searchState.beginDate.getTime(),
          until: searchState.endDate && searchState.endDate.getTime(),
          is_unselect: isUnselect,
        }),
    );
  }
  /**
   * Get initial filters for this contact
   */
  private getInitialFilters() {
    // Load selected orders from url params
    const { catalog, filters, location } = this.props;
    const { currency, is_unselect, selected, since, type, until, range } = qs.parse(location.search || '', [
      'currency',
      'is_unselect',
      'range',
      'selected',
      'since',
      'type',
      'until',
    ]) as {
      currency?: CURRENCY_CODES;
      range?: IDateRange;
      selected?: string;
      is_unselect?: string;
      since?: string;
      type?: string;
      until?: string;
      only_unreads?: string;
    };
    const dateRange =
      range && ['today', 'yesterday', 'week', 'month', 'year', 'custom'].includes(range)
        ? range
        : filters.range || 'month';
    const defaultType = filters.type || 'sale';
    const sinceTime = since && Number(since) ? Number(since) : filters.since;
    const untilTime = until && Number(until) ? Number(until) : filters.until;
    const beginDate = sinceTime ? new Date(sinceTime) : undefined;
    const endDate = untilTime ? new Date(untilTime) : undefined;
    const orderType = (type || defaultType) as IOrderType;
    const dates = date.getDatesFromRange(dateRange);
    this.lastDatesUsed = { beginDate: beginDate || dates.beginDate, endDate: endDate || dates.endDate };
    const defaultCurrency = currency || filters.currency || catalog?.defaultCurrency;

    return {
      dateRange,
      defaultCurrency,
      isUnselect: is_unselect === 'true',
      orderType,
      selected,
    };
  }

  /**
   * handle the scroll event getMore orders
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore } = this.props;
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.scrollSearch();
    }
  };
}

export default WorkspacePrepareOrders;

function OrderCard({
  order,
  role,
  contacts,
  clients,
  commentsData,
  countries,
  me,
  miniChannelId,
  navShowroom,
  setMiniChannelId,
  index,
  openOrderDetail,
}: {
  order: IOrder;
  role: ICatalogRole;
  contacts: Record<number, IContact>;
  countries: Record<string, ICountry>;
  clients: Array<IClient>;
  me: IUser;
  miniChannelId?: string;
  navShowroom: (order: IOrder) => void;
  setMiniChannelId: (id: string) => void;
  openOrderDetail: (editAfterAccept?: boolean) => void;
  index: number;
  commentsData?: Record<number, ICommentChannelExtraDataAPI>;
}) {
  const [expanded, setExpanded] = React.useState<boolean>(!index);
  const dispatch = useDispatch<Dispatch<any>>();
  const progress = (order.items.filter(oi => oi.isPrepared).length / order.items.length) * 100;
  const downIcon = expanded ? 'Down' : 'Right';
  const { buyer, seller } = orderService.getSellerBuyer(order, contacts, me, clients, []);
  const channel = useSelector(chatSelectors.getChannelByContactId(seller.id));
  const openMiniChat = React.useCallback(() => {
    if (!miniChannelId) {
      if (channel) {
        setMiniChannelId(channel.id);
      } else {
        dispatch(
          chatActions.createChannel(
            me.id,
            [seller.id],
            seller.name,
            '',
            CHANNEL_TYPE.PRIVATE,
            (c: IChannel) => setMiniChannelId(c.id),
            true,
            true,
          ),
        );
      }
    } else {
      setMiniChannelId('');
    }
  }, [channel, dispatch, miniChannelId, me, seller, setMiniChannelId]);
  const editedAfter = order.items.filter(i => i.modifiedAfterPrepared).length;
  const unreadComments =
    commentsData?.[order.id]?.unreads?.unread_comments + commentsData?.[order.id]?.unreads?.unread_updates || 0;
  return (
    <S.OrderCard>
      <S.Resume onClick={() => setExpanded(!expanded)}>
        <S.DownIcon name={downIcon} disableHover={true} />
        <S.ResumeLeft>
          <S.TitleColumn>
            <S.TextBlack isOpen={expanded}>
              {editedAfter ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
              {__('OrderPrepare.hashId', { hashId: order.externalIdSeller || order.externalIdBuyer || order.hashId })}
            </S.TextBlack>
            <S.TextBlackSmall>{orderService.getCartLoadSummaryText(order.items)}</S.TextBlackSmall>
          </S.TitleColumn>
          <S.CommentsButton
            type="link"
            iconName="Comment"
            iconSize="16px"
            onClick={e => {
              e.stopPropagation();
              openOrderDetail();
            }}
            badgeCount={unreadComments}
          >
            {unreadComments ? ' ' : ''}
            {__('Components.Cart.numComments', { count: order.totalComments })}
          </S.CommentsButton>
        </S.ResumeLeft>
        {progress < 100 ? (
          <S.ProgressBar
            barHeight={10}
            progress={progress}
            hideCount={true}
            title={__('MagicOrders.completing', {
              count: Math.ceil(Math.min(progress, 100)),
            })}
          />
        ) : (
          <S.FileRow>
            <S.SuccessIcon name="Check" disableHover={true} />
            <S.TextGreen>{__('MagicOrders.completed')}</S.TextGreen>
          </S.FileRow>
        )}
      </S.Resume>
      {expanded ? (
        <S.Column>
          <S.HeadRow>
            <S.ProductTh>{__('OrderPrepare.contact')}</S.ProductTh>
            <S.ProductTh>{__('OrderPrepare.deliveryTo')}</S.ProductTh>
            <S.ProductTh>{__('OrderPrepare.transportation')}</S.ProductTh>
          </S.HeadRow>
          <S.DataRowHead>
            <S.ProductTd>
              <S.Row>
                <S.ContactImagesRow>
                  <S.ContactImage
                    img={seller.avatar}
                    text={seller.name}
                    avatarColor={utils.getAvatarColor(seller.name)}
                    workingStatus={seller.workingStatus}
                  />
                </S.ContactImagesRow>
                <S.ContactNameColumn>
                  <S.TextBlack status={order.status}>
                    {seller.name + (seller.id === me.id ? ' ' + __('Facets.you') : '')}
                  </S.TextBlack>
                  {seller.id !== me.id ? (
                    <S.TextLink status={order.status} onClick={openMiniChat}>
                      {__('OrderPrepare.open_chat')}
                    </S.TextLink>
                  ) : null}
                </S.ContactNameColumn>
              </S.Row>
            </S.ProductTd>
            <S.ProductTd>
              {order.deliverTo ? (
                <S.Row>
                  <S.ContactImagesRow>
                    <S.ContactImage
                      img={buyer.companyLogo.replace('w_450', '')}
                      text={buyer.companyName}
                      avatarColor={buyer.avatarColor}
                      type="team"
                    />
                  </S.ContactImagesRow>
                  <S.ContactNameColumn>
                    <S.TextBlack status={order.status}>{buyer.companyName}</S.TextBlack>
                    {order.deliverTo ? (
                      <S.TextSecondary>{addresses.getAddressName(order.deliverTo, countries, false)}</S.TextSecondary>
                    ) : null}
                  </S.ContactNameColumn>
                </S.Row>
              ) : (
                <ContactCell
                  avatar={buyer.companyLogo}
                  primaryText={buyer.companyName}
                  secondaryText={buyer.name}
                  status={order.status}
                  workingStatus={buyer.workingStatus}
                />
              )}
            </S.ProductTd>
            <S.ProductTd>
              <S.TextBlack>{order.transportDetails ? order.transportDetails : '-'}</S.TextBlack>
            </S.ProductTd>
          </S.DataRowHead>
          {role !== 'viewer' && order.servedFlowEnabled ? (
            <S.Row>
              <S.ButtonLink
                type="link"
                iconName="Add-more"
                iconSize="16px"
                onClick={e => {
                  e.stopPropagation();
                  navShowroom(order);
                }}
              >
                {__('Components.Cart.cta_add_products')}
              </S.ButtonLink>
              <S.ButtonLink
                type="link"
                iconName="Edit"
                iconSize="16px"
                onClick={e => {
                  e.stopPropagation();
                  openOrderDetail(true);
                }}
              >
                {__('Components.Cart.cta_quantities')}
              </S.ButtonLink>
            </S.Row>
          ) : null}
          <S.HeadRow>
            {editedAfter ? <S.TdWarning /> : null}
            <S.Th>{__('Components.Cart.items.quantity')}</S.Th>
            <S.Th>{__('Components.Cart.items.sku')}</S.Th>
            <S.ProductTh>{__('Components.Cart.items.product')}</S.ProductTh>
            <S.Th>{__('Components.Cart.items.weight')}</S.Th>
            <S.Th>{__('Components.Cart.items.prepare_title')}</S.Th>
          </S.HeadRow>
          {editedAfter ? (
            <S.WarningRow>
              <S.WarningWrapper>
                <S.WarningIcon name="Warning" disableHover={true} />
              </S.WarningWrapper>
              {__('Components.Cart.items.modified_after_prepared', { count: editedAfter })}
            </S.WarningRow>
          ) : null}

          {order.items.map(oi => (
            <OrderItem item={oi} key={oi.id} />
          ))}
        </S.Column>
      ) : null}
    </S.OrderCard>
  );
}

function OrderItem({ item }: { item: IOrderItem }) {
  const dispatch = useDispatch<Dispatch<any>>();
  const myId = useSelector((state: IReduxState) => state.user.user.id);
  const prodTypes = useSelector((state: IReduxState) => state.prodType.prodTypes);
  const typeVariety = productService.getProductTypeVarietyDisplay(
    item.type,
    prodTypes[item.type] ? prodTypes[item.type].name : '',
    item.title,
  );
  return (
    <S.DataRow>
      {item.modifiedAfterPrepared ? (
        <S.WarningWrapper>
          <S.WarningIcon name="Warning" disableHover={true} />
        </S.WarningWrapper>
      ) : null}
      <S.Td>
        <S.Row>
          <S.Amount prepared={item.isPrepared}>{item.servedQuantity}</S.Amount>
          <S.Amount prepared={item.isPrepared}>
            {parsers.getUnitText(item.servedSaleUnit, item.weightUnit, item.servedQuantity)}
          </S.Amount>
        </S.Row>
      </S.Td>
      <S.Td>
        <S.TextBlack prepared={item.isPrepared}>{item.sku || '-'}</S.TextBlack>
      </S.Td>

      <S.ProductTd>
        <S.ItemTitle prepared={item.isPrepared}>
          {utils.firstToUpperCase(
            `${typeVariety}${item.size ? `, ${item.size}` : ''}${item.packaging ? `, ${item.packaging}` : ''} `,
          )}
        </S.ItemTitle>
      </S.ProductTd>
      <S.Td>
        <S.AmountInput
          id={`served-weight-${item.id}`}
          name="servedWeight"
          onBlur={(key: string, value: string, error: string) => {
            const v = Number(value);
            dispatch(
              orderActions.orderUpdatePrepared(
                myId,
                item.orderId,
                item.id,
                item.catalogId,
                v,
                item.isPrepared || false,
              ),
            );
          }}
          value={item.servedWeight || 0}
          minValue={0}
          precision={2}
          width="120px"
          type="number"
          textAlign="right"
          autoFocus={false}
          containerMargin="0 6px 0 0"
          variableTextSingular={item.weightUnit}
          variableTextPlural={item.weightUnit}
          disabled={item.isPrepared || !item.servedQuantity}
          prepared={item.isPrepared}
        />
      </S.Td>
      <S.Td>
        <S.Option>
          <S.Check
            isChecked={item.isPrepared}
            onClick={val => {
              dispatch(
                orderActions.orderUpdatePrepared(myId, item.orderId, item.id, item.catalogId, item.servedWeight, val),
              );
            }}
            size={20}
          />
          <S.OptionText isChecked={item.isPrepared}>
            {item.isPrepared ? __('Components.Cart.items.prepared') : __('Components.Cart.items.prepare')}
          </S.OptionText>
        </S.Option>
      </S.Td>
    </S.DataRow>
  );
}
