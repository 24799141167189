import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, Input as InputAtom, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  numHeaders?: number;
  showColor?: boolean;
}
export const Container = styled(RowContainer)`
  overflow: hidden;
  height: 100%;
`;

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-start;
  max-width: 98%;

  .workspace-settings-members {
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  }
`;

export const TextError = styled(TextLabel)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  line-height: 1.2;
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
`;

export const Input = styled(InputAtom)`
  max-width: 450px;
`;

export const TextLink = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;
