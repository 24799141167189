import styled, { ITheme } from '../../../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../atoms';
import { NavigationTabs } from '../../../../molecules';

interface IStyledProps {
  theme: ITheme;
  isRegister?: boolean;
  validated?: boolean;
  isSelected?: boolean;
}

export const Container = styled.div`
  max-width: 400px;
  min-width: 400px;
  margin: 0;
  position: relative;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_l}) {
    max-width: 370px;
    min-width: 370px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    max-width: 350px;
    min-width: 350px;
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    max-width: 330px;
    min-width: 330px;
  }
`;

export const Card = styled(ColumnContainer)`
  padding: 23px 10px 0 10px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  position: relative;
`;

export const SubmitButton = styled(Button)`
  min-width: 225px;
  margin-bottom: ${(p: IStyledProps) => (p.isRegister ? p.theme.paddingSize(5) : 0)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
`;

export const OauthButton = styled(SubmitButton)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  min-width: 260px;
`;

export const InputContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const PhoneInputContainer = styled(RowContainer)`
  align-items: center;
`;

export const DividerContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0;
`;

export const DividerBar = styled(RowContainer)`
  height: 1px;
  width: 45%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const PreTitle = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Title = styled(PreTitle)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const SubTitle = styled(Title)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
`;
export const Label = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const Link = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  text-decoration: none;
  :visited {
    color: ${(p: IStyledProps) => p.theme.colors.blue1};
  }
`;

const TextRegular = styled(TextLabel)`
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextGrey = styled(TextRegular)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Tabs = styled(NavigationTabs)`
  min-height: 30px;
  max-height: 30px;
  padding-left: 0;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const ForgetPasswordContainer = styled(ColumnContainer)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-top: -${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
