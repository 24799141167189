import {
  Api,
  mappingActions,
  modalActions,
  notificationsActions,
  orderActions,
  productActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WorkspaceClientReferenceDetails, {
  IDispatchProps,
  IStateProps,
  OwnProps,
} from '../components/organisms/WorkspaceClientReferenceDetails';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: OwnProps): IStateProps {
  const {
    catalog: { catalogs, clients },
    contact: { inConsentio },
    country: { countries },
    prodType: { prodTypes },
    product: { prices, priceGroupIds },
    order: { formats },
    user: { user },
  } = state!;
  const {
    match: {
      params: { clientId, workspaceId },
    },
  } = props;
  const catalog = catalogs[workspaceId];
  return {
    catalog,
    clientId: Number(clientId),
    clientsOld: clients[workspaceId],
    contacts: inConsentio,
    countries,
    formats,
    me: user!,
    priceGroupIds,
    prices,
    prodTypes,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      getAddressMappings: mappingActions.addressMappingsGet,
      getPrices: productActions.getPrices,
      getProductMappings: mappingActions.productMappingsGet,
      mappingCreate: mappingActions.mappingCreate,
      mappingDelete: mappingActions.mappingDelete,
      mappingUpdate: mappingActions.mappingUpdate,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      requestProInfo: userActions.requestProInfo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceClientReferenceDetails);
