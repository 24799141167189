import { __ } from 'common-services';
import * as React from 'react';

import theme from '../../../theme';
import { IFontIconKeys } from '../../atoms/FontIcon/FontIcon.component';
import * as S from './FormMenu.styled';

export interface Section {
  id: string;
  title: string;
  description?: string;
  icon: IFontIconKeys;
  alertIcon?: IFontIconKeys;
}
export type Sections = Array<Section>;
export interface IProps {
  onSelect?: (select: string) => void;
  sections: Sections;
  selected?: string;
  className?: string;
  hideOnIpad?: boolean;
}

const FormMenu: React.FC<IProps> = ({ onSelect, sections, selected, className, hideOnIpad }) => {
  return (
    <S.Container hideOnIpad={hideOnIpad} className={className}>
      {sections
        .filter(s => s)
        .map((s, idx) => {
          return (
            <S.Section
              className="form-section"
              key={s.id + '_' + idx}
              isSelected={s.id === selected}
              onClick={() => {
                const el = document.getElementById(s.id);
                if (el) el.scrollIntoView({ behavior: 'smooth' });
                if (onSelect) onSelect(s.id);
                highlightFormSection(s.id);
              }}
            >
              <S.Icon name={s.icon} disableHover={true} />
              <S.Content>
                <S.Title isSelected={s.id === selected}>{s.title}</S.Title>
                <S.Description isSelected={s.id === selected}>{s.description}</S.Description>
              </S.Content>
              {s.alertIcon ? (
                <S.AlertIcon
                  name={s.alertIcon}
                  tooltipLabel={__('WorkspaceSettings.missing_information')}
                  disableHover={true}
                />
              ) : null}
            </S.Section>
          );
        })}
    </S.Container>
  );
};

/**
 * Highlight with a background effect the form section when it's selected
 */
export const highlightFormSection = (selected: string) => {
  const sectionEl = document.getElementById(`form-section-container-${selected}`);
  if (sectionEl) {
    sectionEl.style.backgroundColor = theme.colors.grey3;
    setTimeout(() => (sectionEl.style.backgroundColor = theme.colors.white), 650);
  }
};

export default React.memo(FormMenu);
