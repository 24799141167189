import styled, { ITheme } from '../../../styled-components';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';
import TextLabel from '../TextLabel';

interface IStyledProps {
  isDragging?: boolean;
  editMode?: boolean;
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  width: auto;
  margin-bottom: 5px;
  align-items: center;
`;

export const SectionRow = styled(RowContainer)`
  padding: 8px 5px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 42px;
  max-height: 42px;
  box-shadow: ${(p: IStyledProps) => (p.editMode ? p.theme.boxShadow : 'none')};
  align-items: center;
  opacity: ${(p: IStyledProps) => (p.isDragging ? 0 : 1)};
  cursor: ${(p: IStyledProps) => (p.isDragging ? 'grabbing' : 'inherit')};
`;

export const TextCell = styled.div`
  width: 291px;
  max-width: 291px;
  min-width: 291px;
  vertical-align: center;
`;

export const OptionsCell = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  min-width: 92px;
`;

export const ImageCell = styled(RowContainer)`
  width: 60px;
  max-width: 60px;
  min-width: 60px;
`;

const Text = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  overflow: hidden;
`;

export const TextNumber = styled(Text)`
  margin-right: 24px;
  text-align: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  padding: 8px ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 55px;
`;

export const TextTag = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  color: ${(p: IStyledProps) => (p.editMode ? p.theme.colors.black : p.theme.colors.grey1)};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.75)')};
  font-size: 16px;
  width: 16px;
  margin: 0 5px;
`;

export const AddImageIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.75)')};
  font-size: 21px;
  width: 30px;
  margin: 0 5px;
  padding: 5px;
`;

export const Divider = styled.div`
  width: 1px;
  min-height: 100%;
  height: 22px;
  margin: 0 10px;
  border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
`;
