import { modalActions } from 'common-services';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import GlobalModal, { IModalProps } from '../components/organisms/GlobalModal';
import { IReduxState } from '../reducers';

const modalTypes = ['loading', 'error', 'image', 'nice', 'message', 'consentiopro'];

function mapStateToProps(state: IReduxState, props: any): IModalProps {
  const { isOpen, name, text, action, extra, images, imageSelected, loadingTheme } = state.modal;
  const { buttonText, buttonCancelText } = extra;
  return {
    toShow: isOpen,
    type: modalTypes.includes(name) ? (name as any) : 'generic',
    description: text,
    acceptAction: action,
    acceptButtonText: buttonText,
    cancelButtonText: buttonCancelText,
    images,
    imageSelected,
    extra,
    loadingTheme,
  };
}

const { modalOpen, modalClose } = modalActions;

function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return bindActionCreators(
    {
      modalOpen,
      modalClose,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);
