import { colors } from 'common-services';

import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon';

interface IStyledProps {
  color?: 'blue' | 'green' | 'grey';
  theme: ITheme;
  isLast?: boolean;
  isSelected?: boolean;
}

export const ClientContainer = styled(RowContainer)<IStyledProps>`
  background: ${(p: IStyledProps) =>
    p.isSelected ? p.theme.colors.blue2.replace('1)', '0.4)') : p.theme.colors.white};
  height: 60px;
  min-height: 60px;
  padding: 10px ${(p: IStyledProps) => p.theme.paddingSize()};
  ${(p: IStyledProps) =>
    !p.isLast &&
    css`
      border-bottom: 0.5px solid ${p.theme.colors.grey3};
    `}
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  &:hover {
    background: ${(p: IStyledProps) => p.theme.colors.grey4};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    height: 55px;
    min-height: 55px;
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    height: 50px;
    min-height: 50px;
  }
`;

export const Icon = styled.div`
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Info = styled(ColumnContainer)`
  flex: 1;
  justify-content: space-around;
  align-content: flex-start;
  justify-content: center;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoTitle = styled.div`
  width: auto;
  min-width: min-content;
  display: flex;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 20px;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    line-height: 16px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
`;

export const InfoSubtitle = styled.div`
  flex: 1;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    line-height: 16px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
`;
export const TitleInfo = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 20px;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    line-height: 16px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    line-height: 14px;
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
`;
export const Row = styled(RowContainer)`
  max-width: 274px;
`;

export const InfoRight = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  align-self: center;
`;

export const ActionWrapper = styled(RowContainer)`
  justify-content: flex-end;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 34px;
  height: 100%;
`;

export const CloseIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const Label = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => getLabelColor(p.color)};
  background-color: ${(p: IStyledProps) => getLabelBackgroundColor(p.color)};
  padding: 0px ${(p: IStyledProps) => p.theme.paddingSize()};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-right: 4px;
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const InfoGrey = styled.div`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-left: 4px;
`;

function getLabelColor(color?: 'blue' | 'green' | 'grey') {
  switch (color) {
    case 'blue':
      return colors.white;
    case 'green':
      return colors.green1;
    case 'grey':
      return colors.grey1;
    default:
      return colors.white;
  }
}
function getLabelBackgroundColor(color?: 'blue' | 'green' | 'grey') {
  switch (color) {
    case 'blue':
      return colors.blue1;
    case 'green':
      return colors.green2;
    case 'grey':
      return colors.grey3;
    default:
      return colors.blue1;
  }
}
