import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { __, IContact, IOrderType } from 'common-services';

import getPath from '../../../util/routes';
import { ROUTE_PATHS } from '../../../constants';
import { FilterButton, SelectClientModal } from '../../molecules';
import { Button } from '../../atoms';

import * as S from './OrdersList.styled';

interface DashboardHeaderProps {
  tabSelected: string;
  hideFilters: boolean;
  setHideFilters: (hide: boolean) => void;
  viewMode: 'order' | 'delivery' | 'request';
  type: IOrderType;
  fromChat: boolean;
  contactId?: number;
  contacts: { [cId: number]: IContact };
  me: IUser;
  selectedOrderIds: Array<number>;
  isUnselected: boolean;
  setSearch: (updater: (prevState: ISearchOrder) => ISearchOrder) => void;
  setViewMode: (mode: 'order' | 'delivery' | 'request') => void;
  getFiltersCount: () => number;
  goToAggregateList: () => void;
}

const ViewModeSwitch: React.FC<{ viewMode: string; setViewMode: (mode: string) => void; type: IOrderType }> =
  React.memo(({ viewMode, setViewMode, type }) => {
    if (type === 'sale') {
      return (
        <S.FilterSwitch
          className="view-mode-filter"
          keySelected={viewMode}
          selectAll={!viewMode}
          onSelectOption={(key: 'order' | 'request') => setViewMode(key)}
          options={[
            { key: 'order', value: __('Components.OrdersList.TableDashboard.order_view') },
            { key: 'request', value: __('Components.OrdersList.TableDashboard.request_view') },
          ]}
        />
      );
    }
    if (type === 'purchase') {
      return (
        <S.FilterSwitch
          className="view-mode-filter"
          keySelected={viewMode}
          selectAll={!viewMode}
          onSelectOption={(key: 'order' | 'delivery') => setViewMode(key)}
          options={[
            { key: 'order', value: __('Components.OrdersList.TableDashboard.order_view') },
            { key: 'delivery', value: __('Components.OrdersList.TableDashboard.delivery_view') },
          ]}
        />
      );
    }
    return null;
  });

const AggregateButton: React.FC<{
  selectedOrderIds: Array<number>;
  isUnselected: boolean;
  goToAggregateList: () => void;
}> = React.memo(({ selectedOrderIds, isUnselected, goToAggregateList }) => {
  if (selectedOrderIds.length > 0 || isUnselected) {
    return (
      <S.FilterTab id="orders-dashboard-aggregate" iconName="Grouped" onClick={goToAggregateList}>
        {__('Components.OrdersList.Aggregate.Cta')}
      </S.FilterTab>
    );
  }
  return null;
});

const DashboardHeaderComponent: React.FC<DashboardHeaderProps> = ({
  tabSelected,
  hideFilters,
  setHideFilters,
  viewMode,
  type,
  fromChat,
  contactId,
  contacts,
  me,
  selectedOrderIds,
  isUnselected,
  setSearch,
  setViewMode,
  getFiltersCount,
  goToAggregateList,
}) => {
  const history = useHistory();
  const params = useParams<{ workspaceId?: string }>();

  const [showContactsModal, setShowContactsModal] = React.useState(false);

  const contact = React.useMemo(() => contacts[contactId], [contacts, contactId]);

  const isPurchase = type === 'purchase';

  const handleNewOrderClick = (selectedContactId: number) => {
    if (selectedContactId) {
      history.push(
        getPath(
          isPurchase
            ? {
                path: ROUTE_PATHS.WORKSPACE_PURCHASES_BUY,
                workspaceId: params.workspaceId,
                supplierId: selectedContactId.toString(),
              }
            : {
                path: ROUTE_PATHS.WORKSPACE_SALES_SELL,
                workspaceId: params.workspaceId,
                clientId: selectedContactId.toString(),
              },
        ),
      );
      setShowContactsModal(false);
    } else {
      setShowContactsModal(true);
    }
  };

  if (tabSelected === 'unread') return null;

  return (
    <S.HeaderDashboards>
      <S.HeaderFilters>
        <S.LeftFiltersRow>
          <FilterButton
            filtersOpened={!hideFilters}
            filtersSelected={getFiltersCount()}
            onClick={() => setHideFilters(!hideFilters)}
          />
          {viewMode === 'order' && (
            <S.SimpleSearch
              id="search-orders"
              placeHolder={__('Components.OrdersList.search')}
              onChange={(t: string) => setSearch(prevState => ({ ...prevState, hashId: t }))}
            />
          )}
          {!fromChat && <ViewModeSwitch viewMode={viewMode} setViewMode={setViewMode} type={type} />}
          <AggregateButton
            selectedOrderIds={selectedOrderIds}
            isUnselected={isUnselected}
            goToAggregateList={goToAggregateList}
          />
        </S.LeftFiltersRow>
        <S.RightCtaRow>
          {(!contact ||
            (isPurchase ? contact?.isSeller && me.settings.isBuyer : contact?.isBuyer && me.settings.isSeller)) && (
            <>
              {type === 'sale' && params.workspaceId && (
                <S.MOButton
                  type="other"
                  onClick={() =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
                        workspaceId: params.workspaceId,
                      }),
                    )
                  }
                >
                  {__('Components.OrdersList.magic_orders')}
                </S.MOButton>
              )}
              <Button
                onClick={() => {
                  if (contacts) handleNewOrderClick(contactId);
                }}
              >
                {isPurchase ? __('Components.OrdersList.buy_cta') : __('Components.OrdersList.sale_cta')}
              </Button>
            </>
          )}
        </S.RightCtaRow>
      </S.HeaderFilters>
      {showContactsModal && (
        <SelectClientModal
          title={
            isPurchase ? __('Components.OrdersList.modal.buy.title') : __('Components.OrdersList.modal.sale.title')
          }
          subtitle={
            isPurchase
              ? __('Components.OrdersList.modal.buy.subtitle')
              : __('Components.OrdersList.modal.sale.subtitle')
          }
          onClose={() => setShowContactsModal(false)}
          isPurchase={isPurchase}
          selectContact={handleNewOrderClick}
          catalogId={Number(params.workspaceId)}
          me={me}
        />
      )}
    </S.HeaderDashboards>
  );
};

export const DashboardHeader = React.memo(DashboardHeaderComponent);
