import { __, Api, IResetAction, modalActions, realtime, userActions, constants } from 'common-services';
import { messageTypes } from 'common-services/dist/realtime';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IReduxState } from '../reducers';
import { logError } from '../services/log';
import { handlePushReceived } from '../services/notification';
import { messaging, getToken } from '../../setupFcm';

declare var global: IGlobalWeb;
export enum NotificationTypes {
  NOTIFICATION_BADGE_SET = 'NOTIFICATION_BADGE_SET',
  NOTIFICATION_SHOW_HEADER = 'NOTIFICATION_SHOW_HEADER',
  NOTIFICATION_INTERNAL_SET = 'NOTIFICATION_INTERNAL_SET',
}
interface NotificationAction {
  type: NotificationTypes.NOTIFICATION_BADGE_SET | NotificationTypes.NOTIFICATION_SHOW_HEADER | IResetAction;
  value: any;
}
interface NotificationInternalSetAction {
  type: NotificationTypes.NOTIFICATION_INTERNAL_SET;
  notificationCounters: Record<number, { alert: number; informative: number }>;
}

/**
 * Show / hide notification header
 */
export function notificationShowHeader(showHeader: boolean): NotificationAction {
  return {
    type: NotificationTypes.NOTIFICATION_SHOW_HEADER,
    value: showHeader,
  };
}

/**
 * Show notification request permission + nice modal
 */
export function showRequestPermission() {
  return (dispatch: any) => {
    dispatch(
      modalActions.modalOpen(
        __('modal_notification.title'),
        () => dispatch(modalActions.modalClose()),
        {
          text2: __('modal_notification.body'),
          buttonText: __('modal_notification.cta'),
          icon: constants.MEDIA_URL + '/v1587652183/Notification-17-17-17.png',
        },
        'nice',
      ),
    );

    Promise.resolve(Notification.requestPermission()).then(permission => {
      dispatch(notificationShowHeader(false));
      dispatch(modalActions.modalClose());
      if (permission === 'granted') {
        dispatch(initializeDesktopNotifications());
      }
    });
  };
}

export function setInternalNotificationsBadge(
  notificationCounters: Record<number, { alert: number; informative: number }>,
): NotificationInternalSetAction {
  return { type: NotificationTypes.NOTIFICATION_INTERNAL_SET, notificationCounters };
}

/**
 * Register push token & add event listener to service worker messages
 */
export function initializeDesktopNotifications() {
  return async (dispatch: any, getState: () => IReduxState, api: Api) => {
    if (messaging) {
      getToken(messaging, {
        vapidKey: 'BKwQicyyQ07BPMMKa_iZ96WvrsNGBDLZSKNz6YWa4uB-7MO3gh2WVfZld0sEVTL6yVuM4riUeXXDEkL1-uKtNHs',
      }).then(token => {
        if (token) {
          // console.log('FCM token', token);
          dispatch(registerPushToken(token) as any);
        } else {
          logError(new Error(), 'No registration token available. Request permission to generate one.');
        }
      });
    }
    // Register push message event listener
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', message => {
        // type could be: "notification-clicked"
        if (message?.data?.firebaseMessaging?.type === 'push-received' && message.data.firebaseMessaging.payload) {
          return handlePushReceived(message.data.firebaseMessaging.payload, dispatch);
        }
        if (message?.data?.msg === 'push:click' && message.data.url) {
          if (message.data.internal_notification_id)
            api.notification.notificationMarkAsRead(getState().user.user.id, message.data.internal_notification_id);
          window.location.href = message.data.url;
        }
        return Promise.resolve();
      });
    }
  };
}
realtime.addEventListener(messageTypes.counterInternalNotification, (data: any) => {
  return setInternalNotificationsBadge(data.action.notification_counters);
});
/**
 * Sends push token to rtapi
 */
export function registerPushToken(token: string) {
  return async (dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, getState: () => IReduxState) => {
    const { user } = getState().user;
    if (user.id) {
      dispatch(userActions.updatePushToken('web', token || '', ''));
      global.pendingPushToken = undefined;
    } else {
      global.pendingPushToken = token;
    }
  };
}

export type Action = NotificationAction | NotificationInternalSetAction;
