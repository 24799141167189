import { Api, modalActions, notificationsActions, sellerWorkspaceActions, userActions } from 'common-services';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelByPath } from '../actions/nav';
import { supportAction } from '../actions/user';
import WorkspaceColleagues, { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceColleagues';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    chat: { channels },
    catalog: { catalogs },
    contact: { inConsentio },
    user: { user },
  } = state!;
  const workspaceId = Number(props.match.params.workspaceId) || user.sellerWorkspaceId;

  return {
    channels,
    contacts: inConsentio,
    workspace: catalogs[workspaceId],
    me: user!,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      catalogMemberDelete: sellerWorkspaceActions.catalogMemberDelete,
      catalogMembersUpdate: sellerWorkspaceActions.catalogMembersUpdate,
      catalogUpdate: sellerWorkspaceActions.catalogUpdate,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelByPath,
      notificationShow: notificationsActions.notificationShow,
      requestProInfo: userActions.requestProInfo,
      supportAction,
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceColleagues);
