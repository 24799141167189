import styled, { ITheme } from '../../../../../styled-components';
import { RowContainer } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0)} ${(p: IStyledProps) => p.theme.paddingSize()}
    ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex-wrap: wrap;
  flex-shrink: 0;
  height: min-content;
  max-height: 150px;
  width: 560px;
  max-width: 560px;
  overflow-y: auto;
  &::after {
    content: '';
    padding: 0.5em;
  }
`;

export const Text = styled(RowContainer)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
