import { __, chatService, IAdminMessageExtraData, IMessage, LOCALE, prodTypeSelectors, utils } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import * as S from './OrderUpdateMessage.styled';

interface IProps {
  locale?: LOCALE;
  message: IMessage;
  pricePrecision?: number;
}

const OrderUpdateMessage = ({ message, locale, pricePrecision }: IProps) => {
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const extraData = message.extraData as IAdminMessageExtraData;
  if (['order_external_id_updated', 'order_sent_to_erp'].includes(extraData.code)) {
    return (
      <S.Container className="admin-message" code={extraData.code}>
        {extraData.code === 'order_external_id_updated'
          ? (utils.formatText(
              __('Messages.Admin.activity.order_reference_modified', {
                oldReference: extraData.content.oldExternalId,
                reference: extraData.content.newExternalId,
              }),
              (text: string) => (text ? <S.MessageTextBold key={text}>{text}</S.MessageTextBold> : null),
              (text: string) => (text ? <S.MessageText key={text}>{text}</S.MessageText> : null),
            ) as any) // TYPEERROR
          : null}
        {extraData.code === 'order_sent_to_erp' ? (
          <S.MessageText>{__('Messages.Admin.activity.order_sent_to_erp')}</S.MessageText>
        ) : null}
      </S.Container>
    );
  }
  if (!extraData.content || !Array.isArray(extraData.content)) return null;
  return (
    <>
      {extraData.content.map((c, idx) => {
        const messageText = chatService.getUpdatedOrderMessageLiteral(c, prodTypes, locale, pricePrecision);
        if (!messageText) return null;
        return (
          <S.Container key={c.type + c.item?.id + idx} className="admin-message" code={c.type}>
            {
              utils.formatText(
                messageText,
                (text: string) => (text ? <S.MessageTextBold key={text}>{text}</S.MessageTextBold> : null),
                (text: string) => (text ? <S.MessageText key={text}>{text}</S.MessageText> : null),
              ) as any // TYPEERROR
            }
          </S.Container>
        );
      })}
    </>
  );
};

export default React.memo(OrderUpdateMessage);
