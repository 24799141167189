/*
  Segment use and implementation implementation.
  ScreenView and eventTrack should be used from EventTrack & ScreenTrack in order to ensure types.
*/

import Analytics from '../../bindings/analytics';
import { getReadableVersion as bindingGetReadableVersion } from '../../bindings/info';

interface IObject {
  [key: string]: any;
}

/**
 * In this function we extract the utm params from the url.
 * - if exist 'utm_source' rewrite all the utm in the localStorage.
 * - return a dictionary with the new utms in the localStorage
 * more info at https://ga-dev-tools.appspot.com/campaign-url-builder/
 */
function getUTMParams() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('utm_source')) {
    window.localStorage.setItem('utm_source', urlParams.get('utm_source') || '');
    window.localStorage.setItem('utm_medium', urlParams.get('utm_medium') || '');
    window.localStorage.setItem('utm_campaign', urlParams.get('utm_campaign') || '');
    window.localStorage.setItem('utm_term', urlParams.get('utm_term') || '');
    window.localStorage.setItem('utm_content', urlParams.get('utm_content') || '');
  }
  return {
    utmSource: window.localStorage.getItem('utm_source') || '',
    utmMedium: window.localStorage.getItem('utm_medium') || '',
    utmCampaign: window.localStorage.getItem('utm_campaign') || '',
    utmTerm: window.localStorage.getItem('utm_term') || '',
    utmContent: window.localStorage.getItem('utm_content') || '',
  };
}

export class Segment {
  private instance: any;
  private userId: string = '';
  private phone: string = '';
  private defaultParams: { [key: string]: string };

  public constructor() {
    this.defaultParams = getUTMParams();
    this.instance = new Analytics();
  }

  public getPhone(): string {
    return this.phone;
  }

  public getReadableVersion(): string {
    return bindingGetReadableVersion();
  }

  /**
   * userInfo is used to identify the user in segment (mostly triggered by login or updateUser)
   *
   * There is some trait duplication to enable active campaign compatibility and attribution
   */
  public userInfo(extra: IObject = {}): Promise<void> {
    this.userId = extra.user.id;

    if (extra.user) {
      if (extra.user.phone) {
        this.phone = `${extra.user.country}${extra.user.phone}`;
        extra.phone = this.phone;
      }
      extra.email = extra.user.email;
      extra.name = extra.user.name;
      extra.company = extra.user.companyName;
      extra.consentioId = extra.user.id;
      extra.consentioPhonePrefix = extra.user.country;
      extra.consentioStatus = extra.user.status;
      if (extra.user.settings) {
        extra.language = extra.user.settings.language;
        extra.consentioBusinessType = extra.user.settings.businessType;
      }
    }
    return this.instance.identify(this.userId, {
      traits: extra,
    });
  }

  public screen(screen: string, extra: IObject = {}): Promise<void> {
    return this.instance.screen(screen, {
      userId: this.userId,
      ...this.defaultParams,
      ...extra,
    });
  }

  public track(event: string, extra: IObject = {}): Promise<void> {
    return this.instance.track(event, {
      event,
      userId: this.userId,
      ...this.defaultParams,
      ...extra,
    });
  }
}
