import { __ } from 'common-services';
import * as React from 'react';

import { ColumnContainer, Input } from '../../atoms';
import * as S from './PasswordInput.styled';

interface IProps {
  className?: string;
  hasError?: boolean;
  lang?: LOCALE;
  isRegister: boolean;
  password: string;
  setError: (error: string) => void;
  updatePassword: (password: string) => void;
  width?: string;
}
const PasswordInput: React.FC<IProps> = ({
  className,
  hasError,
  isRegister,
  lang,
  password,
  setError,
  updatePassword,
  width,
}) => {
  const [validPassword, setValidPassword] = React.useState<{
    hasLowerCase?: boolean;
    hasUpperCase?: boolean;
    hasNumber?: boolean;
    hasMinLength?: boolean;
  }>();
  return (
    <ColumnContainer className={className} margin={`0 0 ${validPassword && isRegister ? 0 : 12}px`}>
      <Input
        name="password"
        placeholder={__('Components.Onboarding.Register.password_placeholder', { locale: lang })}
        value={password}
        isRequired={true}
        type="password"
        onChange={(name, value) => {
          const valid = {
            hasMinLength: (value as string).length >= 8,
            hasNumber: (value as string).match(/\d+/)?.length > 0,
            hasUpperCase: (value as string).toLowerCase() !== value,
            hasLowerCase: (value as string).toUpperCase() !== value,
          };
          setError(
            value
              ? valid.hasMinLength && valid.hasLowerCase && valid.hasNumber && valid.hasUpperCase
                ? ''
                : 'password_invalid'
              : 'empty',
          );
          setValidPassword(valid);
          updatePassword(value as string);
        }}
        hasError={hasError}
        autoFocus={true}
        containerMargin={`0 0 6px`}
        startIcon="Padlock"
        width={width}
      />
      {validPassword && isRegister ? (
        <>
          <S.Row>
            <S.Validation validated={validPassword.hasLowerCase}>
              <S.CheckIcon disableHover={true} name="Check" />
              {__('WelcomeRegister.lower_case', { locale: lang })}
            </S.Validation>
            <S.Validation validated={validPassword.hasNumber}>
              <S.CheckIcon disableHover={true} name="Check" />
              {__('WelcomeRegister.number', { locale: lang })}
            </S.Validation>
          </S.Row>
          <S.Row>
            <S.Validation validated={validPassword.hasUpperCase}>
              <S.CheckIcon disableHover={true} name="Check" />
              {__('WelcomeRegister.upper_case', { locale: lang })}
            </S.Validation>
            <S.Validation validated={validPassword.hasMinLength}>
              <S.CheckIcon disableHover={true} name="Check" />
              {__('WelcomeRegister.min_length', { locale: lang })}
            </S.Validation>
          </S.Row>
        </>
      ) : null}
    </ColumnContainer>
  );
};

export default React.memo(PasswordInput);
