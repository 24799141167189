import { chatService, date, HOUR_FORMAT, IAdminMessageExtraData, IMessage, utils } from 'common-services';
import * as React from 'react';

import * as S from './AdminMessage.styled';

interface IProps {
  members: Array<IMember>;
  message: IMessage;
  myId: number;
  hourFormat: HOUR_FORMAT;
  action?: () => void;
}

const AdminMessage: React.FC<IProps> = ({ members, message, myId, hourFormat, action }) => {
  const extraData = message.extraData as IAdminMessageExtraData;
  const messageText = chatService.getAdminMessageLiteral(
    extraData,
    myId,
    members.map(m => ({ name: m.name, id: m.id, companyName: m.companyName })),
  );
  if (!messageText) return null;
  return (
    <S.Container className="admin-message" code={extraData.code}>
      <S.TextContainer>
        <S.MessageText code={extraData.code}>
          {extraData.code !== 'price_disclaimer'
            ? (formatText(
                messageText,
                (text: string) => (text ? <S.MessageText key={text}>{text}</S.MessageText> : null),
                (text: string) => (text ? <S.MessageTextBold key={text}>{text}</S.MessageTextBold> : null),
              ) as any) // TYPEERROR
            : utils.formatTextWithDelimiters(messageText, ['##', '**'], (s, idx, delimiter) => {
                switch (delimiter) {
                  case '##':
                    return (
                      <S.TextInfoLink key={s + '_' + idx} onClick={action}>
                        {s}
                      </S.TextInfoLink>
                    );
                  case '**':
                    return <S.MessageTextBold key={s + '_' + idx}>{s}</S.MessageTextBold>;
                  default:
                    return s;
                }
              })}
        </S.MessageText>
        {extraData.content?.link ? <S.Link onClick={action}>{extraData.content?.link}</S.Link> : null}
      </S.TextContainer>
      {['accepted_order', 'canceled_order', 'created_order'].includes(extraData.code) ? (
        <S.DateTimeText className="date">{date.formatTime(message.createdAt, hourFormat)}</S.DateTimeText>
      ) : null}
    </S.Container>
  );
};

/**
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatText(text: string, renderRegularText: (s: string) => void, renderHighlightText: (s: string) => void) {
  return text.split('**').map((str, index) => (index % 2 ? renderHighlightText(str) : renderRegularText(str)));
}

export default React.memo(AdminMessage);
