import {
  __,
  contactActions,
  contactService,
  countrySelectors,
  INVITE_ORIGIN,
  INVITE_VIA,
  notificationsActions,
  RenderTrack,
  VIA,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMAGES } from '../../../assets';
import ActionsModal from '../ActionsModal';
import ContactsList from '../ContactsList';
import EmptyListResource from '../EmptyListResource';
import SimpleSearch from '../SimpleSearch';
import * as S from './InviteAddressbookModal.styled';

interface IProps {
  addressbookSubtitle?: string;
  addressbookTitle?: string;
  catalogId?: number;
  changeStep?: (step: number, contacts?: Array<IContact>) => void;
  channelId?: string;
  defaultInviteBy?: INVITE_VIA;
  from: string;
  me: IUser;
  onClose: (contacts?: Array<IContact>) => void;
  origin: INVITE_ORIGIN;
  otherTitle?: string;
  skippable?: boolean;
  step?: number;
  stepsNumber?: number;
}

const InviteAddressbookModal: React.FC<IProps> = ({
  addressbookSubtitle,
  addressbookTitle,
  catalogId,
  changeStep,
  channelId,
  defaultInviteBy,
  from,
  me,
  onClose,
  origin,
  otherTitle,
  skippable,
  step,
  stepsNumber,
}) => {
  const countries = useSelector(countrySelectors.getCountries);
  const [outContacts, setOutContacts] = React.useState<Array<IContact>>([]);
  const [filter, setFilter] = React.useState('');
  const [mode, setMode] = React.useState(defaultMode(defaultInviteBy, outContacts));
  const [selected, setSelected] = React.useState<Array<IContactValidPhone>>([]);

  const dispatch = useDispatch();

  const contactsAddressbookGet: typeof contactActions.contactsAddressbookGet = React.useCallback(
    (myId, cb) => dispatch(contactActions.contactsAddressbookGet(myId, cb) as any),
    [dispatch],
  );

  React.useEffect(() => {
    RenderTrack.track('InviteModal', {
      renderTime: Date.now(),
      from,
      origin,
      default_invite_by: defaultInviteBy,
      catalog_id: catalogId,
    });
  }, [from, origin, defaultInviteBy, catalogId]);

  const contactsInvite = React.useCallback(
    (
      via: VIA,
      platform: IPlatform,
      invitedUsers: Array<{
        name: string;
        email: string;
        phone: string;
        counterpart_id: string;
      }>,
      cb?: (
        data?: {
          errors?: Array<string>;
          contacts?: Array<IContact>;
          inviteLink?: string;
        },
        error?: Error,
      ) => void,
      trackingData?: {
        viaAgenda?: boolean;
        from?: string;
      },
      messageId?: number,
    ) =>
      dispatch(
        contactActions.contactsInvite(
          me.id,
          via,
          platform,
          origin,
          invitedUsers,
          cb,
          channelId,
          catalogId,
          trackingData,
          messageId,
        ) as any,
      ),
    [dispatch],
  );
  const notificationShow: typeof notificationsActions.notificationShow = React.useCallback(
    (notification, milliseconds) => dispatch(notificationsActions.notificationShow(notification, milliseconds) as any),
    [dispatch],
  );

  const toggleSelected = React.useCallback(
    (c: IContactValidPhone, isSelected: boolean) => {
      const sel = [...selected];
      const idx = sel.findIndex(s => s.phoneNumber === c.phoneNumber);
      if (isSelected) sel.splice(idx, 1);
      else sel.push(c);
      setSelected(sel);
    },
    [selected, setSelected],
  );
  const checkIsSelected = React.useCallback(
    (c: IContactValidPhone) => {
      return selected.findIndex(s => s.phoneNumber === c.phoneNumber) !== -1;
    },
    [selected],
  );
  React.useEffect(() => {
    const fetchData = () => {
      contactsAddressbookGet(me.id, setOutContacts);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    setMode(defaultMode(defaultInviteBy, outContacts));
  }, [setMode, defaultInviteBy, outContacts]);
  const validOutContacts = contactService.getValidPhoneContacts(outContacts);
  const hasContacts = validOutContacts.length;
  return (
    <ActionsModal
      title={
        mode === INVITE_VIA.PHONEBOOK
          ? addressbookTitle || __('Components.ChatList.invite.addressbook.title')
          : otherTitle || __('Components.ChatList.invite.other.title')
      }
      subtitle={
        mode === INVITE_VIA.PHONEBOOK
          ? addressbookSubtitle || __('Components.ChatList.invite.addressbook.subtitle')
          : __('Components.InviteOnboarding.2.subtitle')
      }
      onClose={onClose}
      step={step}
      stepsNumber={stepsNumber}
      changeStep={changeStep}
      skippable={skippable}
    >
      {mode === INVITE_VIA.PHONEBOOK ? (
        <S.AddressbookContainer>
          {hasContacts ? (
            <S.SearchAddressbook>
              <SimpleSearch
                onChange={setFilter}
                placeHolder={__('Components.ChatList.search.placeholder')}
                id="input_search_contacts_addressbook"
              />
            </S.SearchAddressbook>
          ) : null}
          {hasContacts ? (
            <ContactsList
              outContacts={validOutContacts}
              onSelectOutContact={toggleSelected}
              outContactSelected={checkIsSelected}
              search={filter}
              action="switch"
            />
          ) : (
            renderZeroCase()
          )}
          {hasContacts ? (
            <S.Buttons>
              <S.CTA disabled={!!selected.length} type="secondary" onClick={() => setMode(INVITE_VIA.EMAIL)}>
                {__('Components.ChatList.invite.addressbook.cta_other')}
              </S.CTA>
              <S.CTA disabled={!selected.length} type="principal" onClick={sendInviteFromAddressbook}>
                {__('Components.ChatList.invite.addressbook.cta_next', { count: selected.length })}
              </S.CTA>
            </S.Buttons>
          ) : null}
        </S.AddressbookContainer>
      ) : (
        <S.InviteForm
          catalogId={catalogId}
          channelId={channelId}
          className="chatlist-invite-form"
          countries={countries}
          defaultInviteBy={mode as INVITE_VIA.SMS | INVITE_VIA.EMAIL}
          from="invite-contacts"
          me={me}
          onClose={(contacts: Array<IContact>) => (changeStep ? changeStep(++step, contacts) : onClose(contacts))}
          origin={origin}
          showAddressbook={outContacts?.length ? () => setMode(INVITE_VIA.PHONEBOOK) : null}
          trackingFrom={from}
        />
      )}
    </ActionsModal>
  );

  /**
   * Render zero case
   */
  function renderZeroCase() {
    return (
      <S.CenterContainer>
        <EmptyListResource
          imageUrl={IMAGES.contactsAdded}
          showButton={true}
          text={__('ActionsModal.agenda.zero_case.title')}
          text2={__('ActionsModal.agenda.zero_case.description')}
          buttonText={__('ActionsModal.agenda.zero_case.cta')}
          buttonAction={() => setMode(INVITE_VIA.EMAIL)}
          imageSize="160px"
        />
        <S.DividerContainer>
          <S.DividerBar />
          <S.TextGrey>{__('Components.Onboarding.LoginPhoneEmail.or')}</S.TextGrey>
          <S.DividerBar />
        </S.DividerContainer>
        <S.ShareInvite
          catalogId={catalogId}
          channelId={channelId}
          className="invite-addressbook-share-invite"
          me={me}
          origin={origin}
        />
      </S.CenterContainer>
    );
  }

  /**
   * handle send invitations from address book.
   */
  function sendInviteFromAddressbook() {
    contactsInvite(
      VIA.SMS,
      'web',
      selected.map(s => ({
        name: s.contact.name || '',
        email: s.contact.email || '',
        phone: s.phoneNumber || '',
        counterpart_id: '',
      })),
      (data, error) => {
        if (data && !error) {
          const { contacts } = data;
          notificationShow(
            {
              style: 'info',
              title: __('Components.InviteOnboarding.2.contacts_invite'),
              subtitle: __('Components.InviteOnboarding.2.invited', { count: contacts?.length || 0 }),
              closable: false,
            },
            2000,
          );
          changeStep ? changeStep(++step, contacts) : onClose(contacts);
        }
      },
      { viaAgenda: true, from },
    );
  }
};

/**
 * If hasn't defaultInviteBy return email
 * If has a defaultInviteBy and is phonebook and has outcontacts return phonebook else return email.
 * If has defaultInviteBy distinct than phonebook return it
 */
function defaultMode(defaultInviteBy: INVITE_VIA, outContacts: Array<IContact>) {
  if (defaultInviteBy === INVITE_VIA.PHONEBOOK) {
    if (outContacts?.length) return INVITE_VIA.PHONEBOOK;
    return INVITE_VIA.EMAIL;
  }
  return defaultInviteBy || INVITE_VIA.EMAIL;
}

export default InviteAddressbookModal;
