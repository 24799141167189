import styled, { css, ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
  showingLogin?: boolean;
  image?: string;
  color?: string;
  backgroundColor?: string;
  isSections?: boolean;
  hide?: boolean;
  numberOfHeaders?: number;
  showOver?: 'always' | 'never' | 'only-ipad';
}

export const Wrapper = styled.div<IStyledProps>`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  max-width: 275px;
  width: 275px;
  position: relative;
  transition: max-width 0.5s;
  max-width: ${(p: IStyledProps) => (p.hide ? 0 : '275px')};
  border-right: ${(p: IStyledProps) => (p.hide ? 0 : '1px')} solid ${(p: IStyledProps) => p.theme.colors.grey3};

  ${(p: IStyledProps) =>
    ['always', 'only-ipad'].includes(p.showOver)
      ? css`
          @media screen and (max-width: calc(${p.showOver === 'only-ipad'
              ? p.theme.sizes.ipad
              : p.theme.sizes.desktop_l} + 275px)) {
            position: absolute;
            left: 0;
            z-index: 1;
            height: calc(${p.theme.contentHeightWithBreadcrumb} - ${p.numberOfHeaders || 0} * ${p.theme.headerHeight});
            background: white;
            border-right: 1px solid ${p.theme.colors.grey3};
          }
        `
      : ''};

  @media screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    z-index: 2;
    height: 100%;
    background: white;
    display: ${(p: IStyledProps) => (p.hide ? 'none' : 'block')};
    position: fixed;
    min-width: 100vw;
    min-height: ${(p: IStyledProps) => p.theme.windowHeight};
    max-width: 100vw;
    max-height: ${(p: IStyledProps) => p.theme.windowHeight};
    top: 0;
    left: 0;
  }
`;

export const FacetsContainer = styled(ColumnContainer)<IStyledProps>`
  transition: max-width 0.5s;
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;

  @media screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    overflow-y: ${(p: IStyledProps) => (p.hide ? 'hidden' : 'auto')};
  }
`;

export const Header = styled(RowContainer)`
  width: 275px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  position: relative;
  @media screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
  }
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const FiltersBody = styled(ColumnContainer)`
  width: 275px;
  @media screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
  }
`;

export const CloseIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  border-radius: 50%;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: 28px;
  width: 28px;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  position: absolute;
  top: 11px;
  right: 11px;
  display: ${(p: IStyledProps) => (p.hide ? 'none' : 'block')};
`;

export const Clear = styled(Button)`
  position: absolute;
  top: 8px;
  left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  display: ${(p: IStyledProps) => (p.hide ? 'none' : 'block')};
`;

export const ApplyButton = styled(Button)`
  position: fixed;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  display: none;
  align-self: center;
  @media screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    display: block;
  }
`;
