import * as React from 'react';

import * as S from './AddMorePicture.styled';

interface IProps {
  className?: string;
  ctaLabel: string;
  onFileChange?: (file: File) => void;
}

const AddMorePicture: React.FC<IProps> = ({ className, ctaLabel, onFileChange }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleChange = React.useCallback((files: FileList | null) => {
    if (files && files.length) {
      onFileChange(Array.from(files)[0]);
    }
  }, []);

  return (
    <S.Container className={className} onClick={handleClick}>
      <S.MoreImageIcon name="Plus" disableHover={true} />
      <S.Text>{ctaLabel}</S.Text>
      {onFileChange ? (
        <S.PictureInputFile
          type="file"
          accept="image/png, image/jpeg"
          ref={inputRef}
          onChange={e => handleChange(e.target.files)}
        />
      ) : null}
    </S.Container>
  );
};

export default React.memo(AddMorePicture);
