import { __, ColumnConfigType } from "common-services";

export function getNonRemovableColumns(configId: ColumnConfigType) {
  const result = ['title-with-image', 'price-display'];
  if (configId === 'product_list_modal') result.pop();
  return result;
}
/**
 * Translates boxes and pallets to shelves and CC
 */

export function translateColumns(arr) {
  const mapping = {
    'box-weight': {
      title: __('Components.ProductDetails.distribution.shelfWeight'),
    },
    'box-type': {
      title: __('Components.ProductDetails.distribution.shelfType'),
    },
    'pallet-height': {
      title: __('Components.ProductDetails.distribution.cartHeight'),
    },
    'pallet-type': {
      title: __('Components.ProductDetails.distribution.cartType'),
    },
    'boxes-per-pallet': {
      title: __('Components.ProductDetails.distribution.shelvesPerCart'),
    },
    'units-per-box': {
      title: __('Components.ProductDetails.distribution.piecesPerShelf'),
    },
  };
  const updatedColumns = arr.map(column => ({
    ...column,
    ...(mapping[column.id] || {}),
  }));

  return updatedColumns;
}

/**
 * handle click in a product item
 */
export function onClickRow(
  e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  pId: number,
  clickProduct?: (productId: number) => void,
) {
  e.preventDefault();
  clickProduct?.(pId);
}

/**
 * Keyboard navigation functionality for the visibility switch
 * we catch the keydown for 'enter', 'arrow up', 'arrow down', 'arrow left' and 'arrow right'
 */
export function onKeyDownPrice(
  e: React.KeyboardEvent<HTMLInputElement>,
  idx: number,
  setFocus?: (dataSelector: string) => void,
) {
  // move down
  if (e.key === 'Enter' || e.key === 'ArrowDown') {
    e.preventDefault();
    return setFocus?.(`[data-selector=price_${idx + 1}]`);
  }
  // move right
  if ((e.key === 'ArrowRight' || e.key === 'Tab') && e.currentTarget.selectionEnd === e.currentTarget.value.length) {
    e.preventDefault();
    return setFocus?.(`[data-selector=status_${idx}]`);
  }
  // move up
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    return setFocus?.(`[data-selector=price_${idx - 1}]`);
  }
  // move left
  if (e.key === 'ArrowLeft' && e.currentTarget.selectionStart === 0) {
    e.preventDefault();
    return setFocus?.(`[data-selector=status_${idx - 1}]`);
  }

}
