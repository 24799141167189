import * as React from 'react';

import * as S from './DotsIndicator.styled';

interface IProps {
  className?: string;
  color?: string;
  index: number;
  numberOfDots: number;
  onDotClick: (dotIndex: number) => void;
}

const DotsIndicator: React.FC<IProps> = ({ className, color, index, numberOfDots, onDotClick }) => {
  return (
    <S.Container className={className}>
      {Array.from(Array(numberOfDots)).map((_, idx) => (
        <S.DotTouchable key={idx} onClick={() => onDotClick(idx)}>
          <S.Dot isSelected={index === idx} color={color} />
        </S.DotTouchable>
      ))}
    </S.Container>
  );
};

export default React.memo(DotsIndicator);
