import { __, CHANNEL_TYPE, contactActions, INVITE_ORIGIN, notificationsActions, utils, VIA } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { copyToClipboard } from '../../../util/utils';
import { LettersAvatar } from '../../atoms';
import * as S from './InviteLinkModal.styled';

interface IProps {
  channel?: IChannel;
  className?: string;
  description: string;
  inviteOrigin: INVITE_ORIGIN;
  me: IUser;
  onClose: () => void;
  onSendLink: (inviteLink: string, through: 'message' | 'whatsapp') => void;
  post?: IMessage;
  title: string;
  trackFrom?: 'chat-group' | 'chat-public';
}

const InviteLinkModal: React.FC<IProps> = props => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { className, channel, description, inviteOrigin, me, onClose, onSendLink, post, title, trackFrom } = props;
  const [linkCopied, setLinkCopied] = React.useState(false);
  const [linkCopiedInButton, setLinkCopiedInButton] = React.useState(false);

  /**
   * Copy invite link
   */
  const onCopyLink = React.useCallback(
    (from: 'button' | 'input') => {
      dispatch(
        contactActions.contactsInvite(
          me.id!,
          VIA.LINK,
          'web',
          inviteOrigin,
          undefined,
          (data, error) => {
            if (data?.inviteLink && !error)
              setTimeout(() =>
                copyToClipboard(data.inviteLink, err => {
                  if (err)
                    dispatch(
                      notificationsActions.notificationShow(
                        {
                          style: 'error',
                          title: __('ClipboardError.title'),
                          subtitle: __('ClipboardError.subtitle'),
                          closable: true,
                        },
                        4000,
                      ),
                    );
                  if (!err) from === 'button' ? setLinkCopiedInButton(true) : setLinkCopied(true);
                }),
              );
          },
          channel?.id,
          undefined,
          { from: trackFrom || 'chat' },
          post?.messageId,
        ),
      );
    },
    [dispatch, me.id, channel, post, inviteOrigin],
  );

  const sendLink = React.useCallback(
    (through: 'message' | 'whatsapp') => {
      dispatch(
        contactActions.contactsInvite(
          me.id!,
          through === 'message' ? VIA.LINK : VIA.WHATSAPP,
          'web',
          inviteOrigin,
          undefined,
          (data, error) => {
            if (data?.inviteLink && !error) onSendLink(data.inviteLink, through);
          },
          channel?.id,
          undefined,
          { from: trackFrom || 'chat' },
          post?.messageId,
        ),
      );
    },
    [dispatch, channel, me.id, onSendLink, post, inviteOrigin, trackFrom],
  );

  const isPublic = channel?.type === CHANNEL_TYPE.PUBLIC;
  const imageUrl = post?.extraData?.imageUrl || channel?.configuration?.thumbnail || channel?.imageUrl;
  let linkTitle = isPublic ? '#' + channel?.name : '';
  if (post && isPublic) linkTitle = __('ChatPublicPostLanding.title', { channelName: channel?.name });

  return (
    <S.ContainerModal onClose={onClose} title={title} minHeight="min-content" className={className}>
      <S.BodyContainer>
        <S.HeaderRow>
          <LettersAvatar
            avatarColor={utils.getAvatarColor(post?.message || channel?.name)}
            disabled={true}
            img={imageUrl}
            size={100}
            text={post?.message || channel?.name}
            type="group"
          />
          <S.HeaderLinkInfo>
            <S.TextGroupTitle>{linkTitle}</S.TextGroupTitle>
            <S.LinkContainer
              onClick={() => {
                if (!linkCopied) onCopyLink('input');
              }}
            >
              <S.TextGrey2>
                {linkCopied ? __('Components.Header.LinkCopied') : 'https://invite.consentio.co/aA12/.../invite'}
              </S.TextGrey2>
            </S.LinkContainer>
          </S.HeaderLinkInfo>
        </S.HeaderRow>
        <S.TextDescription>{description}</S.TextDescription>
        <S.ButtonOption id="invite-link-modal-send-link" onClick={() => sendLink('message')}>
          <S.IconContainer color="green">
            <S.ButtonIcon disableHover={true} name="Forward-web" />
          </S.IconContainer>
          <S.TitleText>{__('Components.Chat.link_invite_modal.send_link')}</S.TitleText>
        </S.ButtonOption>
        <S.ButtonOption
          id="invite-link-modal-whatsapp"
          onClick={() => {
            sendLink('whatsapp');
          }}
        >
          <S.IconImage src={IMAGES.whatsapp} alt="whatsapp" />
          <S.TitleText>{'WhatsApp'}</S.TitleText>
        </S.ButtonOption>
        <S.ButtonOption
          id="invite-link-modal-copy-link"
          onClick={() => {
            if (!linkCopiedInButton) onCopyLink('button');
          }}
        >
          <S.IconContainer color="blue">
            <S.ButtonIcon disableHover={true} name="Clipboard" />
          </S.IconContainer>
          <S.TitleText>
            {linkCopiedInButton
              ? __('Components.Header.LinkCopied')
              : __('Components.Chat.link_invite_modal.copy_link')}
          </S.TitleText>
        </S.ButtonOption>
      </S.BodyContainer>
    </S.ContainerModal>
  );
};

export default React.memo(InviteLinkModal);
