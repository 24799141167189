import { __ } from 'common-services';
import * as React from 'react';

import { SimpleSearch } from '../../../../molecules';
import * as S from './Search.styled';

interface IProps {
  setSearch: (f: string) => void;
  id: string;
}

export default React.memo(({ id, setSearch }: IProps) => {
  return (
    <S.SearchContainer>
      <SimpleSearch
        onChange={setSearch}
        placeHolder={__('Components.ChatList.search.placeholder')}
        id={'search-' + id}
      />
    </S.SearchContainer>
  );
});
