import { __, constants, LENGTH_UNIT, modalActions, notificationsActions, WEIGHT_UNIT } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import config from '../../../../../../bindings/config';
import { IMAGES } from '../../../../../assets';
import { getDefaultColumns } from '../../../../../constants';
import { api } from '../../../../../store';
import { Input, Select } from '../../../../atoms';
import { ActionsModal, FormSection, InputWithLabel, Table } from '../../../../molecules';
import * as S from './ProductPreferences.styled';

interface IProps {
  workspace: IWorkspace;
  editMode: boolean;
  errors: Map<string, string | (() => JSX.Element)>;
  myId: number;
  sectionRef: React.RefObject<HTMLDivElement>;
  updateCatalog: (workspace: IWorkspace) => void;
}

const ProductPreferences: React.FC<IProps> = ({ workspace, editMode, errors, myId, sectionRef, updateCatalog }) => {
  const dispatch = useDispatch<any>();
  const [boxTypes, setBoxTypes] = React.useState<Array<ICustomBoxType>>([]);
  const [palletTypes, setPalletTypes] = React.useState<Array<ICustomPalletType>>([]);
  const [editBox, setEditBox] = React.useState<ICustomBoxType>();
  const [editPallet, setEditPallet] = React.useState<ICustomPalletType>();
  const [hasBoxChanges, setHasBoxChanges] = React.useState<boolean>(false);
  const [hasPalletChanges, setHasPalletChanges] = React.useState<boolean>(false);
  React.useEffect(() => {
    api.product.getBoxTypes(myId, workspace.id).then((res: any) => {
      setBoxTypes(res);
    });
    api.product.getPalletTypes(myId, workspace.id).then((res: any) => {
      setPalletTypes(res);
    });
  }, [dispatch]);
  const showNotification = React.useCallback(
    (type: 'box' | 'pallet', crud: 'edit' | 'create' | 'delete', error?: Error) => {
      if (error) {
        dispatch(
          notificationsActions.notificationShow({
            title: __('WorkspaceSettings.error'),
            subtitle: __('WorkspaceSettings.error_description'),
            closable: true,
            style: 'error',
          }),
        );
      } else {
        let message = '';
        switch (crud) {
          case 'create':
            message =
              type === 'box'
                ? __('WorkspaceSettings.Distribution.boxes.notification_success')
                : __('WorkspaceSettings.Distribution.pallets.notification_success');
            break;
          case 'edit':
            message = __('Components.Settings.saved');
            break;
          case 'delete':
            message =
              type === 'box'
                ? __('WorkspaceSettings.Distribution.boxes.notification_delete')
                : __('WorkspaceSettings.Distribution.pallets.notification_delete');
            break;
        }
        dispatch(
          notificationsActions.notificationShow({
            title: message,
            subtitle: '',
            closable: true,
            style: 'success',
          }),
        );
      }
    },
    [dispatch],
  );
  const { defaultWeightUnit, lengthUnit } = workspace;
  return (
    <FormSection sectionRef={sectionRef} id="product" title={__('WorkspaceSettings.Menu.Product.title')}>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.weight_unit.label')}
        description={__('Components.Settings.weight_unit.description')}
        disabled={!editMode}
      >
        <Select
          name="default_weight_unit"
          value={defaultWeightUnit}
          options={Object.values(constants.WEIGHT).map(l => ({
            value: l.code,
            label: l.code + ' - ' + l.name(),
          }))}
          onChange={onChangeDefaultWeightUnit}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
          hasError={!!errors.get('default_weight_unit')}
        />
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('Components.Settings.length_unit.label')}
        description={__('Components.Settings.length_unit.description')}
        disabled={!editMode}
      >
        <Select
          name="length_unit"
          value={lengthUnit}
          options={Object.values(constants.LENGTH).map(l => ({
            value: l.code,
            label: l.code + ' - ' + l.name(),
          }))}
          onChange={onChangeDefaultLengthUnit}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
          hasError={!!errors.get('length_unit')}
        />
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Decimals.label')}
        description={__('WorkspaceSettings.Decimals.description')}
        disabled={!editMode}
        footerText={getFooterText(workspace.numberOfDecimalsShowed)}
      >
        <Select
          name="number_of_decimals_showed"
          value={workspace.numberOfDecimalsShowed.toString()}
          options={['2', '3'].map(l => ({
            value: l,
            label: `${l} ${__('WorkspaceSettings.Decimals.decimal', { count: Number(l) })}`,
          }))}
          onChange={(n, v) => onChangeNumOfDecimals(v)}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
        />
      </InputWithLabel>
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Distribution.label')}
        description={
          config.TOGGLE_NEW_SALES_UNITS.organizations.includes(workspace.id)
            ? __('WorkspaceSettings.Distribution.description_alt_units')
            : __('WorkspaceSettings.Distribution.description')
        }
        disabled={!editMode}
        asColumn={true}
      >
        <S.Expansion
          title={
            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(workspace.id)
              ? __('WorkspaceSettings.Distribution.shelves.title', { count: boxTypes?.length })
              : __('WorkspaceSettings.Distribution.boxes.title', { count: boxTypes?.length })
          }
          iconPosition="left"
          defaultExpanded={false}
          contentWithLeftPadding={false}
          titleWithLeftPadding={false}
        >
          <Table
            selectable={false}
            columns={[
              { id: 'name', title: __('WorkspaceSettings.Distribution.boxes.name') },
              {
                id: 'cost',
                title: __('WorkspaceSettings.Distribution.boxes.cost'),
                info: __('WorkspaceSettings.Distribution.boxes.cost_tooltip'),
                value: (data: ICustomBoxType) =>
                  data.cost ? `${data.cost} ${constants.CURRENCIES[workspace.defaultCurrency].symbol}` : '',
              },
              {
                id: 'tare',
                title: __('WorkspaceSettings.Distribution.boxes.tare'),
                value: (data: ICustomBoxType) => (data.tare ? `${data.tare} ${workspace.defaultWeightUnit}` : ''),
              },
              { id: 'externalCode', title: __('WorkspaceSettings.Distribution.boxes.external_id') },
              {
                id: 'actions',
                width: '5%',
                title: '',
                element: (data: ICustomBoxType) =>
                  editMode ? (
                    <S.Dropdown
                      hAlign="right"
                      onSelect={(name: string) => {
                        if (name === 'edit') setEditBox(data);
                        else if (name === 'delete') {
                          dispatch(
                            modalActions.modalOpen(
                              __('WorkspaceSettings.Distribution.boxes.modal_delete.title', { name: data.name }),
                              () => {
                                api.product
                                  .deleteBoxType(myId, workspace.id, data.id)
                                  .then(res => {
                                    setBoxTypes([...boxTypes.filter(b => b.id !== data.id)]);
                                    showNotification('box', 'delete');
                                  })
                                  .catch((error: Error) => {
                                    showNotification('box', 'delete', error);
                                  });
                              },
                              {
                                text2: __('WorkspaceSettings.Distribution.boxes.modal_delete.description'),
                                showCancelButton: true,
                                buttonText: __('WorkspaceSettings.Distribution.boxes.modal_delete.cta'),
                                actionType: 'dangerous',
                                icon: IMAGES.warningGrape,
                              },
                              'nice',
                            ),
                          );
                        }
                      }}
                      options={[
                        { key: 'edit', value: __('WorkspaceSettings.Distribution.boxes.edit') },
                        { key: 'delete', value: __('WorkspaceSettings.Distribution.boxes.delete') },
                      ]}
                    >
                      <S.KebabIcon name="Kebab" />
                    </S.Dropdown>
                  ) : null,
              },
            ]}
            emptyText={__('WorkspaceSettings.Distribution.boxes.table_empty')}
            onClickRow={data => (editMode ? setEditBox(data as ICustomBoxType) : null)}
            rowCursor={editMode ? 'pointer' : 'default'}
            values={boxTypes}
            productColumns={getDefaultColumns()}
          />
          {editMode ? (
            <S.AddMoreCta
              iconName={'Add-more'}
              type="link"
              onClick={() =>
                setEditBox({ id: 0, workspaceId: workspace.id, name: '', cost: 0, tare: 0, externalCode: '' })
              }
              withoutPadding
              id="add-address-link"
            >
              {__('WorkspaceSettings.Distribution.boxes.create_cta')}
            </S.AddMoreCta>
          ) : null}
        </S.Expansion>
        <S.Expansion
          title={
            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(workspace.id)
              ? __('WorkspaceSettings.Distribution.carts.title', { count: palletTypes?.length })
              : __('WorkspaceSettings.Distribution.pallets.title', { count: palletTypes?.length })
          }
          iconPosition="left"
          defaultExpanded={false}
          contentWithLeftPadding={false}
          titleWithLeftPadding={false}
        >
          <Table
            selectable={false}
            columns={[
              { id: 'name', title: __('WorkspaceSettings.Distribution.boxes.name') },
              {
                id: 'cost',
                title: __('WorkspaceSettings.Distribution.boxes.cost'),
                info: __('WorkspaceSettings.Distribution.pallets.cost_tooltip'),
                value: (data: ICustomPalletType) =>
                  data.cost ? `${data.cost} ${constants.CURRENCIES[workspace.defaultCurrency].symbol}` : '',
              },
              { id: 'externalCode', title: __('WorkspaceSettings.Distribution.boxes.external_id') },
              {
                id: 'actions',
                width: '5%',
                title: '',
                element: (data: ICustomPalletType) =>
                  editMode ? (
                    <S.Dropdown
                      hAlign="right"
                      onSelect={(name: string) => {
                        if (name === 'edit') setEditPallet(data);
                        else if (name === 'delete') {
                          dispatch(
                            modalActions.modalOpen(
                              __('WorkspaceSettings.Distribution.pallets.modal_delete.title', { name: data.name }),
                              () => {
                                api.product
                                  .deletePalletType(myId, workspace.id, data.id)
                                  .then(res => {
                                    setPalletTypes([...palletTypes.filter(b => b.id !== data.id)]);
                                    showNotification('pallet', 'delete');
                                  })
                                  .catch((error: Error) => {
                                    showNotification('pallet', 'delete', error);
                                  });
                              },
                              {
                                text2: __('WorkspaceSettings.Distribution.pallets.modal_delete.description'),
                                showCancelButton: true,
                                buttonText: __('WorkspaceSettings.Distribution.pallets.modal_delete.cta'),
                                actionType: 'dangerous',
                                icon: IMAGES.warningGrape,
                              },
                              'nice',
                            ),
                          );
                        }
                      }}
                      options={[
                        { key: 'edit', value: __('WorkspaceSettings.Distribution.pallets.edit') },
                        { key: 'delete', value: __('WorkspaceSettings.Distribution.pallets.delete') },
                      ]}
                    >
                      <S.KebabIcon name="Kebab" />
                    </S.Dropdown>
                  ) : null,
              },
            ]}
            emptyText={__('WorkspaceSettings.Distribution.pallets.table_empty')}
            onClickRow={data => (editMode ? setEditPallet(data as ICustomPalletType) : null)}
            rowCursor={editMode ? 'pointer' : 'default'}
            values={palletTypes}
            productColumns={getDefaultColumns()}
          />
          {editMode ? (
            <S.AddMoreCta
              iconName={'Add-more'}
              type="link"
              onClick={() => setEditPallet({ id: 0, workspaceId: workspace.id, name: '', cost: 0, externalCode: '' })}
              withoutPadding
              id="add-address-link"
            >
              {__('WorkspaceSettings.Distribution.pallets.create_cta')}
            </S.AddMoreCta>
          ) : null}
        </S.Expansion>
      </InputWithLabel>

      {editBox ? (
        <ActionsModal
          title={
            editBox.id
              ? __('WorkspaceSettings.Distribution.boxes.modal_title_edit')
              : __('WorkspaceSettings.Distribution.boxes.modal_title')
          }
          subtitle={
            editBox.id
              ? __('WorkspaceSettings.Distribution.boxes.modal_description_edit')
              : __('WorkspaceSettings.Distribution.boxes.modal_description')
          }
          onClose={() => setEditBox(undefined)}
          minHeight={'max-content'}
        >
          <InputWithLabel isRequired label={__('WorkspaceSettings.Distribution.boxes.name')}>
            <Input
              value={editBox.name}
              onChange={(k, value: string) => {
                setHasBoxChanges(true);
                setEditBox({ ...editBox, name: value });
              }}
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('WorkspaceSettings.Distribution.boxes.cost')}>
            <Input
              type="number"
              precision={workspace.numberOfDecimalsShowed || constants.PRICE_PRECISION}
              value={editBox.cost}
              onChange={() => setHasBoxChanges(true)}
              onBlur={(k, value: number) => {
                setEditBox({ ...editBox, cost: value });
              }}
              variableTextSingular={constants.CURRENCIES[workspace.defaultCurrency].symbol}
              variableTextPlural={constants.CURRENCIES[workspace.defaultCurrency].symbol}
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('WorkspaceSettings.Distribution.boxes.tare')}>
            <Input
              type="number"
              precision={2}
              value={editBox.tare}
              onChange={() => setHasBoxChanges(true)}
              onBlur={(k, value: number) => {
                setEditBox({ ...editBox, tare: value });
              }}
              variableTextSingular={workspace.defaultWeightUnit}
              variableTextPlural={workspace.defaultWeightUnit}
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('WorkspaceSettings.Distribution.boxes.external_id')}>
            <Input
              value={editBox.externalCode}
              onChange={() => setHasBoxChanges(true)}
              onBlur={(k, value: string) => {
                setEditBox({ ...editBox, externalCode: value });
              }}
            />
          </InputWithLabel>

          <S.Buttons>
            <S.CTA
              type="secondary"
              onClick={() => {
                setEditBox(undefined);
                setHasBoxChanges(false);
              }}
            >
              {__('WorkspaceSettings.Distribution.boxes.cancel')}
            </S.CTA>
            <S.CTA
              type="principal"
              disabled={!editBox.name || !hasBoxChanges}
              onClick={() => {
                editBox.id
                  ? api.product
                      .updateBoxType(myId, workspace.id, editBox)
                      .then(res => {
                        const idx = boxTypes.findIndex(b => b.id === res.id);
                        if (idx !== -1) {
                          boxTypes[idx] = res;
                          setBoxTypes([...boxTypes]);
                        } else {
                          setBoxTypes([...boxTypes, res]);
                        }
                        setEditBox(undefined);
                        setHasBoxChanges(false);
                        showNotification('box', 'edit');
                      })
                      .catch((error: Error) => {
                        showNotification('box', 'edit', error);
                      })
                  : api.product
                      .createBoxType(myId, workspace.id, editBox)
                      .then(res => {
                        setBoxTypes([...boxTypes, res]);
                        setEditBox(undefined);
                        setHasBoxChanges(false);
                        showNotification('box', 'create');
                      })
                      .catch((error: Error) => {
                        showNotification('box', 'create', error);
                      });
              }}
            >
              {__('WorkspaceSettings.Distribution.boxes.save')}
            </S.CTA>
          </S.Buttons>
        </ActionsModal>
      ) : null}

      {editPallet ? (
        <ActionsModal
          title={
            editPallet.id
              ? __('WorkspaceSettings.Distribution.pallets.modal_title_edit')
              : __('WorkspaceSettings.Distribution.pallets.modal_title')
          }
          subtitle={
            editPallet.id
              ? __('WorkspaceSettings.Distribution.pallets.modal_description_edit')
              : __('WorkspaceSettings.Distribution.pallets.modal_description')
          }
          onClose={() => setEditPallet(undefined)}
          minHeight={'max-content'}
        >
          <InputWithLabel isRequired label={__('WorkspaceSettings.Distribution.boxes.name')}>
            <Input
              value={editPallet.name}
              onChange={(k, value: string) => {
                setEditPallet({ ...editPallet, name: value });
                setHasPalletChanges(true);
              }}
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('WorkspaceSettings.Distribution.boxes.cost')}>
            <Input
              type="number"
              precision={workspace.numberOfDecimalsShowed || constants.PRICE_PRECISION}
              value={editPallet.cost}
              onChange={() => setHasPalletChanges(true)}
              onBlur={(k, value: number) => {
                setEditPallet({ ...editPallet, cost: value });
              }}
              variableTextSingular={constants.CURRENCIES[workspace.defaultCurrency].symbol}
              variableTextPlural={constants.CURRENCIES[workspace.defaultCurrency].symbol}
            />
          </InputWithLabel>
          <InputWithLabel isRequired={false} label={__('WorkspaceSettings.Distribution.boxes.external_id')}>
            <Input
              value={editPallet.externalCode}
              onChange={() => setHasPalletChanges(true)}
              onBlur={(k, value: string) => {
                setEditPallet({ ...editPallet, externalCode: value });
              }}
            />
          </InputWithLabel>

          <S.Buttons>
            <S.CTA
              type="secondary"
              onClick={() => {
                setEditPallet(undefined);
                setHasPalletChanges(false);
              }}
            >
              {__('WorkspaceSettings.Distribution.boxes.cancel')}
            </S.CTA>
            <S.CTA
              type="principal"
              disabled={!editPallet.name || !hasPalletChanges}
              onClick={() => {
                editPallet.id
                  ? api.product
                      .updatePalletType(myId, workspace.id, editPallet)
                      .then(res => {
                        const idx = palletTypes.findIndex(b => b.id === res.id);
                        if (idx !== -1) {
                          palletTypes[idx] = res;
                          setPalletTypes([...palletTypes]);
                        } else {
                          setPalletTypes([...palletTypes, res]);
                        }
                        setEditPallet(undefined);
                        setHasPalletChanges(false);
                        showNotification('pallet', 'edit');
                      })
                      .catch((error: Error) => {
                        showNotification('pallet', 'edit', error);
                      })
                  : api.product
                      .createPalletType(myId, workspace.id, editPallet)
                      .then(res => {
                        setPalletTypes([...palletTypes, res]);
                        setEditPallet(undefined);
                        setHasPalletChanges(false);
                        showNotification('pallet', 'create');
                      })
                      .catch((error: Error) => {
                        showNotification('pallet', 'create', error);
                      });
              }}
            >
              {__('WorkspaceSettings.Distribution.boxes.save')}
            </S.CTA>
          </S.Buttons>
        </ActionsModal>
      ) : null}
    </FormSection>
  );

  // onChangeDefaultWeightUnit manage the main weight unit change
  // A modal will be show to the user in order to let them understand the consequences
  function onChangeDefaultWeightUnit(n: string, v: string): void {
    if (v !== defaultWeightUnit) {
      dispatch(
        modalActions.modalOpen(
          __('Components.Settings.weight_unit.modal.title'),
          () => {
            updateCatalog({
              ...workspace,
              defaultWeightUnit: v as WEIGHT_UNIT,
            });
            dispatch(modalActions.modalClose());
          },
          {
            text2: __('Components.Settings.weight_unit.modal.description'),
            buttonText: __('Components.Settings.weight_unit.modal.cta'),
            showCancelButton: true,
            buttonCancelText: __('Components.Settings.weight_unit.modal.cancel'),
            closeable: false,
          },
          'nice',
        ),
      );
    }
  }

  // onChangeDefaultLengthUnit manage the main weight unit change
  // A modal will be show to the user in order to let them understand the consequences
  function onChangeDefaultLengthUnit(n: string, v: string): void {
    if (v !== lengthUnit) {
      dispatch(
        modalActions.modalOpen(
          __('Components.Settings.length_unit.modal.title'),
          () => {
            updateCatalog({
              ...workspace,
              lengthUnit: v as LENGTH_UNIT,
            });
            dispatch(modalActions.modalClose());
          },
          {
            text2: __('Components.Settings.length_unit.modal.description'),
            buttonText: __('Components.Settings.length_unit.modal.cta'),
            showCancelButton: true,
            buttonCancelText: __('Components.Settings.length_unit.modal.cancel'),
            closeable: false,
          },
          'nice',
        ),
      );
    }
  }

  // onChangeNumOfDecimals manage the number of decimals showed setting
  function onChangeNumOfDecimals(v: string): void {
    if (v !== workspace.numberOfDecimalsShowed.toString()) {
      updateCatalog({
        ...workspace,
        numberOfDecimalsShowed: Number(v),
      });
    }
  }
  // getFooterText returns the example test according to num of decimals in settings
  function getFooterText(v: number): string {
    if (v === 2) {
      return __('WorkspaceSettings.Decimals.footer2');
    } else {
      return __('WorkspaceSettings.Decimals.footer3');
    }
  }
};

export default React.memo(ProductPreferences);
