import {
  __,
  buyerWorkspaceActions,
  buyerWorkspaceSelectors,
  contactSelectors,
  i18n,
  notificationsActions,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  userSelectors,
  userService,
  utils,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { workspaceBuyerSelect, workspaceSellerSelect } from '../../../actions/nav';
import { ROUTE_PATHS } from '../../../constants';
import { IReduxState } from '../../../reducers';
import getPath from '../../../util/routes';
import { getNewWorkspace, WorkspaceCreate } from '../Workspaces/Workspaces.component';
import * as S from './WorkspaceSelectModal.styled';

export interface IProps {
  className?: string;
  hide?: () => void;
  history: History<any>;
  workspaceSelected: IWorkspace;
}

const WorkspaceSelectModal = ({ className, workspaceSelected, hide, history }: IProps) => {
  const dispatch = useDispatch<any>();
  const me = useSelector(userSelectors.getUser);
  const contacts = useSelector(contactSelectors.getContacts());
  const sellerWorkspaces = Object.values(useSelector(sellerWorkspaceSelectors.getCatalogs()));
  const buyerWorkspaces = Object.values(useSelector(buyerWorkspaceSelectors.getWorkspaces()));
  const badge = useSelector((state: IReduxState) => state.notification.internalNotifications);
  const isBuyerWorkspace = workspaceSelected.type === 'buyer';
  let workspaces = me.settings.isBuyer ? buyerWorkspaces : sellerWorkspaces;
  if (me.settings.isBuyer && me.settings.isSeller) workspaces = [...sellerWorkspaces, ...buyerWorkspaces];
  const { plan, priceListUrl } = workspaceSelected;
  const showPriceListUrl = !isBuyerWorkspace && priceListUrl;
  const isPro = isBuyerWorkspace ? false : sellerWorkspaceService.isProPlan(plan);
  const workspaceName = sellerWorkspaceService.getCatalogName(workspaceSelected, contacts, me);
  const priceListCompleteUrl = `${config.WEB_URL}/pricelist/${priceListUrl}`;

  const [showCreateModal, setShowCreateModal] = React.useState(false);

  /**
   * Get workspaces to show. Limited to 4, but can be up to 7.
   * If the default workspace is not shown, we add it.
   * If the selected workspace is not here, we add it.
   */
  const getWorkspacesToShow = React.useCallback(() => {
    const result = [];
    if (me.settings.isSeller && sellerWorkspaces.length) {
      result.push(...sellerWorkspaces.slice(0, 2));
      const defaultSellerWorkspace = sellerWorkspaces.find(w => w.id === me.sellerWorkspaceId);
      if (result.indexOf(defaultSellerWorkspace) === -1) result.push(defaultSellerWorkspace);
    }
    if (me.settings.isBuyer && buyerWorkspaces.length) {
      result.push(...buyerWorkspaces.slice(0, 2));
      const defaultBuyerWorkspace = buyerWorkspaces.find(w => w.id === me.buyerWorkspaceId);
      if (result.indexOf(defaultBuyerWorkspace) === -1) result.push(defaultBuyerWorkspace);
    }
    if (result.indexOf(workspaceSelected) === -1) result.push(workspaceSelected);
    return result.filter(w => w);
  }, [me, buyerWorkspaces, sellerWorkspaces, workspaceSelected]);

  const getSettingsOptions = React.useCallback(() => {
    return [
      {
        name: __('MainHeader.workspace_select.settings', { workspaceName }),
        action: () =>
          history.push(
            getPath({
              path: isBuyerWorkspace ? ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER : ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER,
              workspaceId: workspaceSelected.id + '',
              tab: 'team',
            }),
          ),
      },
      {
        name: __('MainHeader.workspace_select.colleagues', { count: workspaceSelected.members.length }),
        action: () =>
          history.push(
            getPath({
              path: isBuyerWorkspace ? ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER : ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER,
              workspaceId: workspaceSelected.id + '',
            }),
          ),
      },
    ];
  }, [workspaceSelected]);

  /**
   * When clicking on the advertising link for the consentio university
   */
  const onProLinkClick = React.useCallback(() => {
    window.open(
      userService.getConsentioUniversityLink(
        isPro,
        i18n.default.currentLocale() as LOCALE,
        me.settings.isBuyer,
        me.settings.isSeller,
        workspaceSelected.type === 'seller',
        config.TOGGLE_UNIVERSITY.enabled,
      ),
      '_blank',
    );
  }, [isPro, dispatch]);

  /**
   * Select and navigate to a workspace
   */
  const navigateToWorkspace = React.useCallback(
    (ws: IWorkspace) => {
      if (ws.type === 'buyer') {
        dispatch(workspaceBuyerSelect(ws.id));
        dispatch(buyerWorkspaceActions.suppliersGet(me.id, ws.id));
        history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER, workspaceId: ws.id + '' }));
      } else {
        dispatch(workspaceSellerSelect(ws.id));
        dispatch(sellerWorkspaceActions.clientsGet(me.id, ws.id));
        history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_PRODUCTS, workspaceId: ws.id + '' }));
      }
    },
    [dispatch, history, workspaceBuyerSelect, workspaceSellerSelect],
  );

  /**
   * Create a new workspace
   */
  const onCreateWorkspace = React.useCallback(
    (name: string, companyLogo: string, type: 'seller' | 'buyer') => {
      const defaultWorkspace = sellerWorkspaces.find(w => w.id === me.sellerWorkspaceId);
      dispatch(
        sellerWorkspaceActions.workspaceCreate(
          me.id,
          getNewWorkspace(defaultWorkspace, name, companyLogo, type),
          type,
          (workspace: IWorkspace, error: Error) => {
            if (error) {
              return dispatch(
                notificationsActions.notificationShow({
                  title: __('WorkspaceCreate.error'),
                  subtitle: '',
                  closable: true,
                  style: 'error',
                }),
              );
            }
            dispatch(
              notificationsActions.notificationShow({
                title: __('WorkspaceCreate.success'),
                subtitle: '',
                closable: true,
                style: 'success',
              }),
            );
            setShowCreateModal(false);
            if (!me.buyerWorkspaceId && type === 'buyer') {
              dispatch(buyerWorkspaceActions.workspaceAssign(me.id, workspace.id));
            }
          },
        ),
      );
    },
    [dispatch, getNewWorkspace, me, navigateToWorkspace, setShowCreateModal, sellerWorkspaces],
  );
  return (
    <>
      <S.ContainerModal className={className}>
        <S.HeaderRow>
          <S.RowCenter>
            <S.WorkspaceAvatar
              catalog={workspaceSelected}
              isActive={sellerWorkspaceService.isActive(workspaceSelected, me.id)}
              showActiveIcon={true}
              size={42}
            />
            <S.HeaderInfoCol>
              <S.TextSemiBold>{workspaceName}</S.TextSemiBold>
              {showPriceListUrl ? <S.TextPriceListUrl>{priceListCompleteUrl}</S.TextPriceListUrl> : null}
            </S.HeaderInfoCol>
          </S.RowCenter>
          {showPriceListUrl ? (
            <S.LinkButton
              iconName="External-link"
              iconSize="10px"
              onClick={() => window.open(priceListCompleteUrl, '_blank')}
              type="link"
              withoutPadding={true}
            >
              {__('MainHeader.workspace_select.open')}
            </S.LinkButton>
          ) : null}
        </S.HeaderRow>
        <S.Divider />

        <S.TextRegular>
          {
            utils.formatText(
              isPro
                ? __('MainHeader.workspace_select.pro_subscription')
                : __('MainHeader.workspace_select.basic_subscription'),
              (s, idx) => (
                <S.TextLink key={idx} onClick={() => onProLinkClick()}>
                  {s}
                </S.TextLink>
              ),
            ) as any
          }
        </S.TextRegular>
        <S.Divider marginBottomMultiplier={1.5} />

        <S.SettingsCol>
          {getSettingsOptions().map((setting, idx, arr) => (
            <SettingLink
              action={setting.action}
              className="setting-link"
              isLast={idx === arr.length - 1}
              key={idx}
              name={setting.name}
              hide={hide}
            />
          ))}
        </S.SettingsCol>
        <S.Divider marginTopMultiplier={1.5} />
        {getWorkspacesToShow().map((workspace, idx) => (
          <WorkspaceLine
            className="workspace-line"
            contacts={contacts}
            isSelected={workspace.id === workspaceSelected.id}
            onWorkspaceSelect={ws => navigateToWorkspace(ws)}
            key={workspace.id}
            me={me}
            workspace={workspace}
            badge={badge[workspace.id]}
          />
        ))}
        <S.BottomLink>
          <S.TextLink
            onClick={() => {
              if (workspaces.length === 1) {
                setShowCreateModal(true);
              } else history.push(getPath({ path: ROUTE_PATHS.WORKSPACES }));
            }}
          >
            {workspaces.length === 1 ? __('MainHeader.workspace_select.add') : __('MainHeader.workspace_select.manage')}
          </S.TextLink>
        </S.BottomLink>
      </S.ContainerModal>

      {showCreateModal ? (
        <WorkspaceCreate
          onCreate={onCreateWorkspace}
          onClose={() => setShowCreateModal(false)}
          typeSelected={!me.settings.isBuyer ? 'seller' : !me.settings.isSeller ? 'buyer' : undefined}
        />
      ) : null}
    </>
  );
};

const SettingLink = React.memo(
  ({
    action,
    className,
    hide,
    isLast,
    name,
  }: {
    action: () => void;
    className?: string;
    hide?: () => void;
    isLast: boolean;
    name: string;
  }) => {
    return (
      <S.SettingRow
        className={className}
        onClick={() => {
          action();
          hide?.();
        }}
        isLast={isLast}
      >
        <S.TextRegular showOneLine={true}>{name}</S.TextRegular>
        <S.ChevronIcon disableHover={true} name="Right" />
      </S.SettingRow>
    );
  },
);

const WorkspaceLine = React.memo(
  ({
    badge,
    className,
    contacts,
    isSelected,
    me,
    onWorkspaceSelect,
    workspace,
  }: {
    badge?: { alert: number; informative: number };
    className?: string;
    contacts: Record<number, IContact>;
    isSelected: boolean;
    me: IUser;
    onWorkspaceSelect: (workspace: IWorkspace) => void;
    workspace: IWorkspace;
  }) => {
    return (
      <S.WorkspaceLineRow
        id={`select_workspace_${workspace.id}`}
        className={className}
        isSelected={isSelected}
        onClick={() => onWorkspaceSelect(workspace)}
      >
        <S.RowCenter>
          <S.WorkspaceAvatar
            catalog={workspace}
            isActive={sellerWorkspaceService.isActive(workspace, me.id)}
            showActiveIcon={true}
            size={30}
          />
          <S.TextRegular isSelected={isSelected}>
            {sellerWorkspaceService.getCatalogName(workspace, contacts, me)}
          </S.TextRegular>
        </S.RowCenter>
        {badge || isSelected ? (
          <S.RowCenter>
            {badge && !isSelected ? (
              <S.Badge className="workspace-select-modal-badge" count={badge?.alert || 0} size={20} color="red" />
            ) : null}
            {isSelected ? <S.CheckIcon disableHover={true} name="Check" /> : null}
          </S.RowCenter>
        ) : null}
      </S.WorkspaceLineRow>
    );
  },
);

export default React.memo(WorkspaceSelectModal);
