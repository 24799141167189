import { constants } from 'common-services';
import * as React from 'react';

import { IButtonType } from '../../atoms/Button/Button.styled';
import { IFontIconKeys } from '../../atoms/FontIcon';
import * as S from './EmptyListResource.styled';

interface IProps {
  buttonAction?: () => void;
  buttonIcon?: IFontIconKeys;
  buttonText?: string;
  buttonType?: IButtonType;
  className?: string;
  imageSize?: string;
  imageUrl?: string;
  links?: Array<{ text: string; action: () => void; iconName: IFontIconKeys }>;
  showButton?: boolean;
  text?: string;
  text2?: string;

  loading?: boolean;
  loadingText?: string;
  loadingText2?: string;
}

/**
 * Placeholder to use when a resource list is empty (orders, products, pricegroups...).
 * Show illustration, explanation (title and/or subtitle) + cta optional.
 */
const EmptyListResource: React.FC<IProps> = ({
  className,
  buttonAction = () => null,
  buttonIcon,
  buttonText = '',
  buttonType = 'secondary',
  imageSize = '170px',
  imageUrl,
  showButton = true,
  links,
  text,
  text2,
  loading,
  loadingText,
  loadingText2,
}) => {
  return (
    <S.Container className={className} data-testid="empty-list-resource">
      <S.Image
        src={imageUrl || constants.MEDIA_URL + '/c_scale,w_260/v1592480797/ilustrations/No_orders_yet.png'}
        imageSize={imageSize}
      />
      {!loading && text ? <S.TextBold hasText={!!text2}>{text}</S.TextBold> : null}
      {loading && loadingText ? <S.TextBold hasText={!!loadingText2}>{loadingText}</S.TextBold> : null}
      {!loading && text2 ? <S.Text>{text2}</S.Text> : null}
      {loading && loadingText2 ? <S.Text>{loadingText2}</S.Text> : null}
      {showButton ? (
        <S.CTAButton onClick={buttonAction} type={buttonType} iconName={buttonIcon}>
          {buttonText}
        </S.CTAButton>
      ) : null}
      {links ? (
        <S.CTAContainer>
          {links.map(l => (
            <S.CTAButton key={l.text} onClick={l.action} type="link" iconName={l.iconName} iconType="background">
              {l.text}
            </S.CTAButton>
          ))}
        </S.CTAContainer>
      ) : null}
    </S.Container>
  );
};

export default React.memo(EmptyListResource);
