import { __, constants, IFile, ORDER_FILE_TYPE, utils } from 'common-services';
import * as React from 'react';

import { Modal, Select } from '../../atoms';
import * as S from './FileModal.styled';

export interface IProps {
  hideCategories?: boolean;
  feedbackText?: string;
  orderHash?: string;
  file: IFile;
  modalClose: () => void;
  sendFile: (type?: ORDER_FILE_TYPE) => void;
}

const FileModal: React.FC<IProps> = ({ feedbackText, file, hideCategories, modalClose, orderHash, sendFile }) => {
  const [type, setType] = React.useState('');
  let mimeTypeLogo: string = constants.getLogoFromMime(file.type);
  if (file.type.startsWith('image/')) mimeTypeLogo = `data:${file.type};base64,${file.content}`;
  return (
    <Modal showCloseIcon={true} onCloseClick={modalClose} minWidth="450px" maxWidth="min(100vw, 600px)">
      <S.Container className="image-modal-container">
        {mimeTypeLogo ? (
          <S.Img src={mimeTypeLogo} isImage={file.type.startsWith('image/')} />
        ) : file.type.startsWith('image/') ? (
          <S.GalleryIcon name="Gallery" disableHover={true} />
        ) : (
          <S.DocumentIcon name="Document" disableHover={true} />
        )}
        {file.type.startsWith('image/') ? null : (
          <S.Description>
            <S.DescriptionText>{file.name}</S.DescriptionText>
            <S.DescriptionText>{utils.formatSize(file.size)}</S.DescriptionText>
          </S.Description>
        )}
      </S.Container>
      {hideCategories ? (
        <S.Column>
          <S.SendButton withoutCategories={hideCategories}>
            <S.SendIcon id="send-file" name="Send-message" onClick={() => sendFile()} />
          </S.SendButton>
          {feedbackText ? <S.SendText>{feedbackText}</S.SendText> : null}
        </S.Column>
      ) : (
        <S.Column>
          <S.Row>
            <S.SelectWrapper>
              <Select
                maxWidth="250px"
                dropUp={true}
                value={type}
                placeholder={__('Components.OrderDetails.file_type.placeholder')}
                options={[
                  {
                    value: ORDER_FILE_TYPE.INVOICE,
                    label: __('Components.OrderDetails.file_type.invoice'),
                  },
                  {
                    value: ORDER_FILE_TYPE.QUALITY_CONTROL,
                    label: __('Components.OrderDetails.file_type.quality_control'),
                  },
                  {
                    value: ORDER_FILE_TYPE.DELIVERY_NOTE,
                    label: __('Components.OrderDetails.file_type.delivery_note'),
                  },
                  {
                    value: ORDER_FILE_TYPE.OTHER,
                    label: __('Components.OrderDetails.file_type.other'),
                  },
                ]}
                onChange={(key: string, value: string, error?: string | undefined) => setType(value)}
              />
            </S.SelectWrapper>
            <S.SendButton disabled={!type}>
              <S.SendIcon
                id="send-file"
                name="Send-message"
                cursor={!type ? 'not-allowed' : 'pointer'}
                onClick={() => (type ? sendFile(type as ORDER_FILE_TYPE) : null)}
              />
            </S.SendButton>
          </S.Row>
          <S.SendText>{__('Components.OrderDetails.send_to', { orderHash })}</S.SendText>
        </S.Column>
      )}
    </Modal>
  );
};

export default React.memo(FileModal);
