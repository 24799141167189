import styled, { ITheme } from '../../../../../styled-components';
import { Button, FontIcon } from '../../../../atoms';

interface IStyledProps {
  theme: ITheme;
  enabled?: boolean;
}

export const ActionButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const OptionButton = styled(ActionButton)``;

const OptionLink = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  text-decoration: underline;
  cursor: pointer;
`;

export const CancelLink = styled(OptionLink)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const Dots = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
