import { IReferenceByProduct } from "common-services";
import { IAssociationsReference } from "common-services/dist/associate-reference/interface";

export function transformToLabelValueList(
  data: Array<IReferenceByProduct>,
): Array<{ label: string; value: string; id: number; kindId: number; kindName: string }> {
  return data.map(ref => ({
    label: ref.name,
    value: ref.id.toString(),
    id: ref.id,
    kindId: ref.productKindId,
    kindName: ref.productKindName,
  }));
}

export interface ValidateReferenceState {
  isPresent: boolean;
  reference: IAssociationsReference | undefined;
}
