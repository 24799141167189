import { Api, IPublicOrderResponse, modalActions, to } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import OrderPublic, { IDispatchProps, IStateProps } from '../components/organisms/OrderPublic';
import { IReduxState } from '../reducers';
import { logError } from '../services/log';

function mapStateToProps(state: IReduxState, props: any): IStateProps {
  const {
    prodType,
    country,
    user: { user },
  } = state;

  const prodTypes = prodType.prodTypes;
  const countries = country.countries;
  return { countries, prodTypes, hourFormat: user.settings.hourFormat };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      orderGetByHash:
        (hash: string, cb: (data?: IPublicOrderResponse) => void) =>
        async (d: ThunkDispatch<IReduxState, Api, AnyAction>, getState: () => IReduxState, api: Api) => {
          const res = await to(api.order.getData(hash));
          if (res.err) {
            return logError(res.err, 'getOrderData');
          }
          cb(res.data);
        },
      touchImage: modalActions.touchImage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPublic);
