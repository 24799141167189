import { __ } from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { IMAGES } from '../../../assets';
import { ACCEPT_ANIMATED_IMAGES } from '../../../constants';
import * as S from './DropzoneContainer.styled';

interface IProps {
  accept?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  imageSize?: number;
  multiple?: boolean;
  noClick?: boolean;
  onFilesChange?: (files: Array<File>) => void;
  showText?: boolean;
}

const DropzoneContainer: React.FC<IProps> = ({
  accept = ACCEPT_ANIMATED_IMAGES,
  children,
  className,
  disabled = false,
  imageSize = 100,
  multiple = false,
  noClick = false,
  onFilesChange,
  showText = true,
}) => {
  return (
    <Dropzone accept={accept} multiple={multiple} onDrop={onFilesChange} disabled={disabled} noClick={noClick}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <S.DropzoneContainer {...getRootProps()} isDragActive={isDragActive} className={className}>
            {children}
            <S.DragOverlay className="drag-illustration">
              <S.IllustrationContainer>
                <S.IllustrationImage src={IMAGES.camera} alt="drag-img" size={imageSize} />
                {showText ? <S.TextDrag>{__('ChatPublic.drag')}</S.TextDrag> : null}
              </S.IllustrationContainer>
            </S.DragOverlay>
          </S.DropzoneContainer>
        );
      }}
    </Dropzone>
  );
};

export default React.memo(DropzoneContainer);
