import {
  __,
  constants,
  countrySelectors,
  currency,
  CURRENCY_CODES,
  debounce,
  i18n,
  ModalActions,
  parsers,
  PRICE_ADJUSTMENT_TYPE,
  prodTypeSelectors,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { getPriceGroupEditColumns, IMAGE_DEFAULT } from '../../../constants';
import { navSelectors } from '../../../selectors';
import { Input, Select } from '../../atoms';
import Members from '../Members';
import DragHandle from '../Table/Drag';
import Table, { IColumn, IDataCell } from '../Table/Table.component';
import * as S from './PriceItemTable.styled';
import { getPriceAdjustmentTypes } from './PriceItemTable.utils';

export interface IProps {
  catalog: IWorkspace;
  className?: string;
  contacts: Record<number, IContact>;
  disabled: boolean;
  draggable?: boolean;
  fromProduct?: boolean;
  hasMore: boolean;
  isQuoterMode: boolean;
  me: IUser;
  modalClose: () => ModalActions;
  modalOpen: (text: string, action: () => void, extra: IModalExtraInfo, name: IModalName) => ModalActions;
  onRemovePrice: (price: IPriceGroupItem) => void;
  onUpdatePrice: (price: IPriceGroupItem) => void;
  priceGroupItems: Array<IPriceGroupItem>;
  priceGroups?: { [priceGroupId: string]: IPriceGroup };
  priceGroupId?: string;
  prices: { [key: number]: IPrice };
  products: Array<IProduct>;
  scrollClassName?: string;
  scrollElement?: HTMLDivElement;
  searchMore: () => void;
  setSelectedPriceGroupItems?: (priceGroupItem: IPriceGroupItem, product: IProduct) => void;
  selectedPriceGroupItems?: Array<IPriceGroupItem>;
  showLoading: number;
  showStickyHeader?: boolean;
  sortBy?: string;
  sortDirection?: 'desc' | 'asc';
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  updateSortMode?: (sortBy: string, sortDirection: 'desc' | 'asc') => void;
}

/**
 * Price items table within a price group
 */
const PriceItemTable: React.FC<IProps> = ({
  className,
  catalog,
  contacts,
  disabled,
  draggable,
  fromProduct,
  hasMore,
  isQuoterMode,
  me,
  modalClose,
  modalOpen,
  onRemovePrice,
  onUpdatePrice,
  priceGroupId,
  priceGroupItems,
  prices,
  products,
  scrollClassName,
  searchMore,
  sortBy,
  sortDirection,
  selectedPriceGroupItems,
  setSelectedPriceGroupItems,
  priceGroups,
  showLoading,
  showStickyHeader,
  touchImage,
  updateSortMode,
}) => {
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const columnConfigWorkspace = useSelector(
    sellerWorkspaceSelectors.getColumnConfig(workspaceSelected?.id, 'pricegroup_edit'),
  );
  const countries = useSelector(countrySelectors.getCountries);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const togglePriceGroupRanksEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_RANK_PRICEGROUPS));

  const [sortedPriceGroupItems, setSortedPriceGroupItems] = React.useState<Array<IPriceGroupItem>>(priceGroupItems);
  const [sortByRank, setSortByRank] = React.useState<boolean>(draggable);
  React.useEffect(() => {
    setSortByRank(draggable);
  }, [draggable]);

  // sorts pricegroup items according to rank when draggable is enabled
  React.useEffect(() => {
    if (draggable) {
      setSortedPriceGroupItems(priceGroupItems.sort((a, b) => a.rank - b.rank));
    }
  }, [draggable]);

  React.useEffect(() => {
    if (draggable) {
      setSortedPriceGroupItems(priceGroupItems.sort((a, b) => a.rank - b.rank));
    }
  }, [priceGroupItems]);

  // when we add or remove pricegroupitems, we need to update the rank of the items
  React.useEffect(() => {
    if (draggable) {
      setPriceGroupRank(
        priceGroupItems.map((item, idx) => {
          return { ...item, rank: idx };
        }),
      );
      setSortedPriceGroupItems(
        priceGroupItems.map((item, idx) => {
          return { ...item, rank: idx };
        }),
      );
    }
  }, [priceGroupItems.length]);

  const [changes, setChanges] = React.useState<
    Record<
      string,
      {
        adjust: number;
        dropPosition: 'up' | 'down';
        percentage: number;
        priceAdjust?: PRICE_ADJUSTMENT_TYPE;
        priceUpdated: number;
      }
    >
  >({});
  const dispatch = useDispatch<any>();
  React.useEffect(() => {
    if (me.id && workspaceSelected?.id)
      dispatch(sellerWorkspaceActions.tableVisibilityConfigGet(me.id, workspaceSelected.id, 'pricegroup_edit'));
  }, [dispatch, me, workspaceSelected?.id]);
  React.useEffect(() => {
    const newChanges = priceGroupItems.reduce(
      (
        acc: Record<
          string,
          {
            adjust: number;
            dropPosition: 'up' | 'down';
            percentage: number;
            priceAdjust?: PRICE_ADJUSTMENT_TYPE;
            priceUpdated: number;
          }
        >,
        p,
      ) => {
        const key = p.productId + '_' + p?.priceGroupId;
        const price = prices[p.productId];
        acc[key] = {
          adjust: 0,
          dropPosition: 'up' as 'up' | 'down',
          percentage: Math.abs(p?.percentage || 0),
          priceAdjust: p?.type,
          priceUpdated: p?.price || price?.price || 0,
        };

        return acc;
      },
      {},
    );
    setChanges(newChanges);
  }, [priceGroupItems]);

  const myRole = workspaceSelected ? sellerWorkspaceService.getRole(workspaceSelected, me.id) : 'viewer';
  if (isQuoterMode) {
    priceGroupItems.forEach(p => (p.type = PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE));
  }
  const getColumns = React.useCallback(() => {
    // Default share option
    const result: Array<IColumn> =
      !config.TOGGLE_BULK_CHANGES_PRICEGROUPS.enabled || fromProduct
        ? [
            {
              title: '',
              id: 'close-button',
              width: '90px',
              element: ({ priceGroupItem }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                return (
                  <S.FlexRow>
                    <S.RemoveIcon name="Close" disableHover={true} onClick={() => onRemovePriceFn(priceGroupItem)} />
                  </S.FlexRow>
                );
              },
            },
          ]
        : [
            {
              title: '',
              id: 'checkbox',
              width: '55px',
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                return (
                  <S.CheckBox
                    onClick={() => {
                      setSelectedPriceGroupItems(priceGroupItem, product);
                    }}
                    className="add-pricegroups-checkbox"
                    isChecked={selectedPriceGroupItems?.includes(priceGroupItem)}
                  />
                );
              },
            },
          ];
    if (togglePriceGroupRanksEnable && draggable) {
      result.splice(1, 0, {
        title: __('WorkspacePriceGroups.rank.title'),
        id: 'rank',
        width: '100px',
        sort: 'rank',
        sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
        element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
          return <DragHandle isDragging={true} rank={priceGroupItem.rank + 1} />;
        },
      });
    }

    const configCol = columnConfigWorkspace?.columns?.length
      ? (columnConfigWorkspace?.columns as Array<IProductColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig('pricegroup_edit');
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'title':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  if (!product) return null;
                  if (product.productTitle) return product.productTitle;
                  const genericProduct = parsers.productToGenericProduct(product);
                  const typeVariety = productService.getProductTypeVarietyDisplay(
                    genericProduct?.type,
                    prodTypes[genericProduct?.type] ? prodTypes[genericProduct?.type].name : '',
                    genericProduct?.variety,
                  );
                  return `${typeVariety} ${product.size}`;
                },
              }),
            );
            break;
          case 'sku':
            result.push(
              getColumnField(column, {
                getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return (
                    <S.FlexRow>
                      <S.TextBlack>{product?.sku || ''}</S.TextBlack>
                    </S.FlexRow>
                  );
                },
              }),
            );
            break;
          case 'based-price':
            result.push(
              getColumnField(
                column,
                {
                  getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                    return getBasePrice(priceGroupItem, product?.id);
                  },
                },
                isQuoterMode,
              ),
            );
            break;
          case 'adjustment-type':
            if (!isQuoterMode) {
              result.push(
                getColumnField(column, {
                  getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                    const priceConf = prices?.[product?.id];
                    const c = getChanges(product?.id, priceGroupItem);
                    const priceAdjustmentType = getPriceAdjustmentTypes(priceConf?.currency, product?.isPor);
                    return (
                      <Select
                        name={`price-adjustment-type-${product?.id}`}
                        value={c.priceAdjust ?? PRICE_ADJUSTMENT_TYPE.BASE}
                        onChange={(name, val) =>
                          onChangePriceAdjustmentType(val as PRICE_ADJUSTMENT_TYPE, priceGroupItem, product.id)
                        }
                        options={priceAdjustmentType}
                        containerMargin="0"
                        disabled={disabled}
                        dropUp={c.dropPosition === 'up'}
                        maxWidth={'176px'}
                        zIndex={9}
                      />
                    );
                  },
                }),
              );
            }
            break;
          case 'adjustment':
            result.push(
              getColumnField(
                column,
                {
                  getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                    return renderAdjustInput(pricePrecision, priceGroupItem, product);
                  },
                },
                isQuoterMode,
              ),
            );
            break;
          case 'variation':
            result.push(
              getColumnField(column, {
                getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  const priceConf = prices?.[product?.id];
                  const basePrice = priceConf?.totalCost;
                  const finalPrice = getFinalPrice(
                    priceGroupItem?.price,
                    basePrice,
                    priceGroupItem?.useBase,
                    product?.id,
                    priceGroupItem,
                  );

                  return renderVariation(
                    priceGroupItem?.isPor,
                    priceGroupItem?.type,
                    finalPrice,
                    pricePrecision,
                    basePrice,
                    priceGroupItem,
                    product,
                  );
                },
              }),
            );
            break;
          case 'final-price':
            result.push(
              getColumnField(column, {
                getElement: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  const priceConf = prices?.[product?.id];

                  const basePrice = isQuoterMode ? priceConf?.totalCost : priceConf?.basePrice;
                  const finalPrice = getFinalPrice(
                    priceGroupItem?.price,
                    basePrice,
                    priceGroupItem?.useBase,
                    product?.id,
                    priceGroupItem,
                  );
                  return renderFinalPrice(
                    priceGroupItem?.isPor,
                    priceGroupItem?.type,
                    finalPrice,
                    pricePrecision,
                    basePrice,
                    priceGroupItem,
                    product,
                  );
                },
              }),
            );
            break;
          case 'product-type':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return prodTypes[product.type.type]?.name || '';
                },
              }),
            );
            break;
          case 'product-variety':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.type.variety || '';
                },
              }),
            );
            break;

          case 'size':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.size || '';
                },
              }),
            );
            break;
          case 'origin':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.type.origin || '';
                },
              }),
            );
            break;
          case 'category':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.category || '';
                },
              }),
            );
            break;
          case 'packaging':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.packaging || '';
                },
              }),
            );
            break;
          case 'box-weight':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.boxWeight || '';
                },
              }),
            );
            break;
          case 'pallet-type':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.pallet.name || '';
                },
              }),
            );
            break;
          case 'box-type':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.box.name || '';
                },
              }),
            );
            break;
          case 'region':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.region || '';
                },
              }),
            );
            break;
          case 'type-of-production':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return parsers.getOrganicText(product.organic);
                },
              }),
            );
            break;
          case 'ean':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.eanCode || '';
                },
              }),
            );
            break;
          case 'boxes-per-pallet':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.boxesPerPallet || '';
                },
              }),
            );
            break;
          case 'warehouse':
            result.push(
              getColumnField(column, {
                getValue: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                  return product.warehouse?.zip
                    ? `${product.warehouse.zip}, ${product.warehouse.city}, ${
                        countries[product.warehouse.country]?.name || product.warehouse.country
                      }`
                    : '';
                },
              }),
            );
            break;

          default:
            result.push(getColumnField(column));
            break;
        }
      });
    return result;
  }, [columnConfigWorkspace, selectedPriceGroupItems, prices, changes, draggable]);
  if (priceGroupItems.length === 0) return null;

  const setPriceGroupRank = async (sortedPriceGroupItems: Array<IPriceGroupItem>) => {
    await dispatch(
      sellerWorkspaceActions.priceGroupAddPrices(catalog.id, priceGroupId, sortedPriceGroupItems, isQuoterMode),
    );
  };

  const onUpdatePriceGroupRows = (rows: any) => {
    const rankedPriceGroupItems = rows.map((row, idx) => {
      return { ...row.priceGroupItem, rank: idx };
    });
    if (!sortByRank) {
      modalOpen(
        __('WorkspacePriceGroups.rank.modal.title'),
        () => {
          setPriceGroupRank(rankedPriceGroupItems);
          setSortedPriceGroupItems(rankedPriceGroupItems.sort((a, b) => a.rank - b.rank));
          setSortByRank(true);
          updateSortMode('rank', 'asc');
        },
        {
          showCancelButton: true,
          buttonCancelText: __('WorkspacePriceGroups.rank.modal.cancel'),
          text2: __('WorkspacePriceGroups.rank.modal.text'),
          buttonText: __('WorkspacePriceGroups.rank.modal.cta'),
          icon: IMAGES.informativePineapple,
        },
        'nice',
      );
    } else {
      setSortedPriceGroupItems(rankedPriceGroupItems.sort((a, b) => a.rank - b.rank));
    }
  };

  // Save changes to pricegroup item only when dropping the item
  const onDrop = () => {
    setPriceGroupRank(sortedPriceGroupItems);
  };

  // Return the product item id used for the drag and drop logic
  const getDragRowId = (p: any) => {
    return p.product.id;
  };

  return (
    <S.Container className={className}>
      <Table
        draggable={draggable}
        onUpdateRows={onUpdatePriceGroupRows}
        onDrop={onDrop}
        getDragRowId={getDragRowId}
        configId={'pricegroup_edit'}
        productColumns={getPriceGroupEditColumns(isQuoterMode)}
        className="priceitem-table"
        columns={
          config.TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS.enabled && !fromProduct
            ? getColumns().filter(c => c)
            : getColumnsByProduct()
        }
        emptyText={''}
        nonRemovableColumns={[
          'title',
          'based-price',
          ...(isQuoterMode ? [] : ['adjustment-type']),
          'adjustment',
          'variation',
          'final-price',
        ]}
        onClickRow={() => undefined}
        scrollClassName={scrollClassName}
        selectable={false}
        showLoading={showLoading}
        showCustomColumns={config.TOGGLE_PRICEGROUPS_CUSTOM_COLUMNS.enabled && !fromProduct && myRole !== 'viewer'}
        showStickyHeader={showStickyHeader}
        values={[
          ...(fromProduct
            ? priceGroupItems.map((priceGroupItem, idx) =>
                products[0] ? { priceGroupItem, product: products[0], rank: idx + 1 } : undefined,
              )
            : sortByRank
            ? sortedPriceGroupItems.map((priceGroupItem, idx) => {
                const product = products.find(p => priceGroupItem.productId === p.id);
                return product ? { priceGroupItem, product, rank: idx + 1 } : undefined;
              })
            : products.map((product, idx) => {
                const priceGroupItem = sortedPriceGroupItems.find(pgi => product.id === pgi.productId);

                return priceGroupItem ? { priceGroupItem, product, rank: idx + 1 } : undefined;
              })),
        ]}
        isQuoterMode={isQuoterMode}
        sort={sortBy}
        sortOrder={sortDirection}
        updateSortMode={async (sortBy, sortDirection) => {
          await updateSortMode(sortBy, sortDirection);
          if (sortBy !== 'rank') {
            setSortByRank(false);
          } else {
            setSortByRank(true);
            if (sortDirection === 'asc') {
              setSortedPriceGroupItems(priceGroupItems.sort((a, b) => a.rank - b.rank));
            } else {
              setSortedPriceGroupItems(priceGroupItems.sort((a, b) => b.rank - a.rank));
            }
          }
        }}
      />
      {hasMore ? (
        <S.MoreWrapper>
          <S.Link onClick={() => searchMore()}>{__('Components.ProductsList.ShowMore')}</S.Link>
        </S.MoreWrapper>
      ) : null}
    </S.Container>
  );

  function getColumnsByProduct(): Array<IColumn> {
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;

    return [
      ...(!config.TOGGLE_BULK_CHANGES_PRICEGROUPS.enabled || fromProduct
        ? [
            {
              title: '',
              id: 'close-button',
              width: '90px',
              element: ({ priceGroupItem }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                return (
                  <S.FlexRow>
                    <S.RemoveIcon name="Close" disableHover={true} onClick={() => onRemovePriceFn(priceGroupItem)} />
                  </S.FlexRow>
                );
              },
            },
          ]
        : [
            {
              title: '',
              id: 'checkbox',
              width: '55px',
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => (
                <S.CheckBox
                  onClick={() => setSelectedPriceGroupItems(priceGroupItem, product)}
                  className="add-pricegroups-checkbox"
                  isChecked={selectedPriceGroupItems?.includes(priceGroupItem)}
                />
              ),
            },
          ]),

      ...(fromProduct
        ? [
            {
              title: __('WorkspacePriceGroupEdit.table.pricegroup'),
              id: 'price-group',
              element: ({ priceGroupItem }: { priceGroupItem: IPriceGroupItem }) => {
                const priceGroup = priceGroups?.[priceGroupItem.priceGroupId];
                return (
                  <S.FlexRow>
                    <S.ActiveIndicator isActive={priceGroup.status === 'active'} />
                    <S.TextBlack>{priceGroup.name || ''}</S.TextBlack>
                  </S.FlexRow>
                );
              },
            },

            {
              title: __('WorkspacePriceGroupEdit.table.clients'),
              id: 'clients',
              element: ({ priceGroupItem }: { priceGroupItem: IPriceGroupItem }) => {
                const priceGroup = priceGroups?.[priceGroupItem.priceGroupId];
                return priceGroup.clients.length ? (
                  <Members
                    className="price-group-item-members"
                    contacts={contacts}
                    clients={priceGroup.clients}
                    disableHover={true}
                    me={me}
                    members={priceGroup.clients}
                    size={36}
                  />
                ) : (
                  <S.TextBlack>{__('WorkspacePriceGroups.products.zero')}</S.TextBlack>
                );
              },
            },
          ]
        : [
            {
              title: __('WorkspacePriceGroupEdit.table.product'),
              id: 'product',
              sort: 'product',
              sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                if (!product) return null;
                const genericProduct = parsers.productToGenericProduct(product);
                const typeVariety = productService.getProductTypeVarietyDisplay(
                  genericProduct?.type,
                  prodTypes[genericProduct?.type] ? prodTypes[genericProduct?.type].name : '',
                  genericProduct?.variety,
                );
                const productImage =
                  priceGroupItem?.productImageUrl || prodTypes[genericProduct?.type]?.defaultImageUrl || IMAGE_DEFAULT;
                return (
                  <S.FlexRow>
                    <S.ProductImage src={productImage} onClick={e => onImageClick(e, [productImage], 0)} />
                    <S.ProductCol>
                      <S.TextProduct>{`${typeVariety} ${product.size}`}</S.TextProduct>
                    </S.ProductCol>
                  </S.FlexRow>
                );
              },
            },
            {
              title: __('WorkspacePriceGroupEdit.table.sku'),
              id: 'sku',
              sort: 'sku',
              sortOrder: ['desc', 'asc'] as ['desc', 'asc'],
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                return (
                  <S.FlexRow>
                    <S.TextBlack>{product?.sku || ''}</S.TextBlack>
                  </S.FlexRow>
                );
              },
            },
            {
              title: isQuoterMode
                ? __('WorkspacePriceGroupEdit.table.cost')
                : __('WorkspacePriceGroupEdit.table.price_base'),
              id: 'based-price',
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                return getBasePrice(priceGroupItem, product?.id);
              },
            },
          ]),
      ...(isQuoterMode
        ? []
        : [
            {
              title: __('WorkspacePriceGroupEdit.table.adjustment_type'),
              id: 'adjustment-type',
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                const priceConf = prices?.[product?.id];
                const c = getChanges(product?.id, priceGroupItem);

                const adjustmentTypeFlag = c.priceAdjust === PRICE_ADJUSTMENT_TYPE.POR;
                const priceAdjustmentType = getPriceAdjustmentTypes(priceConf?.currency, adjustmentTypeFlag);

                return (
                  <Select
                    name={`price-adjustment-type-${product?.id}`}
                    value={c.priceAdjust}
                    onChange={(name, val) =>
                      onChangePriceAdjustmentType(val as PRICE_ADJUSTMENT_TYPE, priceGroupItem, product.id)
                    }
                    options={priceAdjustmentType}
                    containerMargin="0"
                    disabled={disabled}
                    dropUp={c.dropPosition === 'up'}
                    maxWidth={'176px'}
                  />
                );
              },
            },
          ]),
      {
        title: isQuoterMode
          ? __('WorkspacePriceGroupEdit.table.margin')
          : __('WorkspacePriceGroupEdit.table.adjustment'),
        id: 'adjustment',
        element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
          return renderAdjustInput(pricePrecision, priceGroupItem, product);
        },
      },
      ...(config.TOGGLE_MARGINS.enabled
        ? [
            {
              title: __('WorkspacePriceGroupEdit.table.variation'),
              id: 'variation',
              element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
                const priceConf = prices?.[product?.id];

                const basePrice = priceConf?.price;
                const finalPrice = getFinalPrice(
                  priceGroupItem?.price,
                  basePrice,
                  priceGroupItem?.useBase,
                  product?.id,
                  priceGroupItem,
                );

                return renderVariation(
                  priceGroupItem?.isPor,
                  priceGroupItem?.type,
                  finalPrice,
                  pricePrecision,
                  basePrice,
                  priceGroupItem,
                  product,
                );
              },
            },
          ]
        : []),
      {
        title: __('WorkspacePriceGroupEdit.table.price'),
        id: 'price',
        element: ({ priceGroupItem, product }: { priceGroupItem: IPriceGroupItem; product: IProduct }) => {
          const priceConf = prices?.[product?.id];

          const basePrice = priceConf?.price;
          const finalPrice = getFinalPrice(
            priceGroupItem?.price,
            basePrice,
            priceGroupItem?.useBase,
            product?.id,
            priceGroupItem,
          );

          return renderFinalPrice(
            priceGroupItem?.isPor,
            priceGroupItem?.type,
            finalPrice,
            pricePrecision,
            basePrice,
            priceGroupItem,
            product,
          );
        },
      },
    ];
  }

  /**
   * On change price adjustment type.
   * Reset price state if POR or BASE PRICE is selected.
   * Reset percentage state if other type than percentage (discount/markup) is selected.
   */
  function onChangePriceAdjustmentType(
    priceAdjust: PRICE_ADJUSTMENT_TYPE,
    priceGroupItem: IPriceGroupItem,
    productId: number,
  ) {
    if (prices[productId]?.price === undefined) return;
    const price = prices[productId].price;

    const c = getChanges(productId, priceGroupItem);

    let priceUpdated = c.priceUpdated;
    let percentage = c.percentage;

    if (priceAdjust === PRICE_ADJUSTMENT_TYPE.POR) priceUpdated = 0;
    if (priceAdjust === PRICE_ADJUSTMENT_TYPE.BASE) priceUpdated = priceGroupItem.isPor ? 0 : price;
    if (
      priceAdjust !== PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE &&
      priceAdjust !== PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE
    )
      percentage = 0;
    setChanges({
      ...changes,
      [productId + '_' + priceGroupItem.priceGroupId]: {
        ...c,
        percentage,
        priceAdjust,
        priceUpdated,
      },
    });

    onUpdatePriceFn(priceGroupItem, priceAdjust, c.adjust, price, percentage);
  }

  /**
   * Render adjust input or price input
   */
  function renderAdjustInput(pricePrecision: number, priceGroupItem: IPriceGroupItem, product: IProduct) {
    const price = prices[product?.id];
    const c = getChanges(product?.id, priceGroupItem);

    const currencySymbol = constants.CURRENCIES[price?.currency || '']?.symbol || '';
    if (
      c.priceAdjust === PRICE_ADJUSTMENT_TYPE.POR ||
      (c.priceAdjust === PRICE_ADJUSTMENT_TYPE.BASE && c.priceUpdated === 0)
    )
      return <S.TextBlack> - </S.TextBlack>;
    let maxValue;
    if ([PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE, PRICE_ADJUSTMENT_TYPE.MARKUP_PRICE].includes(c.priceAdjust)) {
      maxValue = c.priceAdjust === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE ? price.price - 0.01 : undefined;
      return (
        <Input
          name={`price-adjust-currency-${product.id}`}
          type="number"
          onChange={debounce((key, value) => {
            setChanges({
              ...changes,
              [product.id + '_' + priceGroupItem.priceGroupId]: {
                ...c,
                adjust: maxValue ? Math.min(maxValue, Number(value)) : Number(value),
              },
            });
            onUpdatePriceFn(
              priceGroupItem,
              c.priceAdjust,
              maxValue ? Math.min(maxValue, Number(value)) : Number(value),
              price.price,
              c.percentage,
            );
          }, 1200)}
          value={c.adjust}
          minValue={0}
          maxValue={maxValue}
          variableTextSingular={currencySymbol}
          precision={pricePrecision}
          containerMargin="0"
          width="97%"
          disabled={disabled}
        />
      );
    }
    if ([PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE, PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE].includes(c.priceAdjust)) {
      maxValue = c.priceAdjust === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE ? 99.99 : undefined;
      return (
        <Input
          name={`price-adjust-percentage-${product.id}`}
          type="number"
          onChange={debounce((key, value) => {
            const changedValue = Number(value) < 0 ? 0 : Number(value);
            setChanges({
              ...changes,
              [product.id + '_' + priceGroupItem.priceGroupId]: {
                ...c,
                percentage: maxValue ? Math.min(maxValue, changedValue) : changedValue,
              },
            });
            onUpdatePriceFn(
              priceGroupItem,
              c.priceAdjust,
              c.adjust,
              price.price,
              maxValue ? Math.min(maxValue, changedValue) : changedValue,
            );
          }, 1200)}
          value={c.percentage}
          minValue={0}
          maxValue={maxValue}
          variableTextSingular="%"
          precision={2}
          startText={c.priceAdjust === PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE ? '+' : '-'}
          containerMargin="0"
          width="97%"
          disabled={disabled}
        />
      );
    }
    const inputDisabled =
      (disabled && c.priceAdjust === PRICE_ADJUSTMENT_TYPE.BASE) ||
      (!fromProduct && c.priceAdjust === PRICE_ADJUSTMENT_TYPE.BASE);
    if (inputDisabled) {
      return (
        <S.TextBlack>
          {currency.getPricePerUnit(
            price?.currency || catalog?.defaultCurrency || CURRENCY_CODES.EUR,
            product?.priceUnit,
            product?.weightUnit,
            c.priceUpdated,
            pricePrecision,
          )}
        </S.TextBlack>
      );
    }
    return (
      <Input
        name={`fixed-price-${product?.id}`}
        type="number"
        onBlur={(key, value) => {
          setChanges({
            ...changes,
            [product.id + '_' + priceGroupItem.priceGroupId]: {
              ...c,
              priceUpdated: Number(value),
            },
          });
          onUpdatePriceFn(priceGroupItem, c.priceAdjust, c.adjust, Number(value), c.percentage);
        }}
        value={c.priceUpdated}
        minValue={0.01}
        variableTextSingular={currency.getPricePerUnit(
          price?.currency || catalog?.defaultCurrency || CURRENCY_CODES.EUR,
          product?.priceUnit,
          product?.weightUnit,
        )}
        precision={pricePrecision}
        containerMargin="0"
        width="97%"
      />
    );
  }

  function getBasePrice(priceGroupItem: IPriceGroupItem, productId: number) {
    const priceConfiguration = prices?.[productId];

    const basePrice = isQuoterMode ? priceConfiguration?.totalCost : priceConfiguration?.basePrice;
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const product = products.find(p => p.id === productId);
    return (
      <S.TextBlack>
        {basePrice
          ? currency.getPricePerUnit(
              priceConfiguration?.currency || catalog?.defaultCurrency || CURRENCY_CODES.EUR,
              priceConfiguration?.priceUnit,
              product?.weightUnit || catalog?.defaultWeightUnit,
              basePrice,
              pricePrecision,
            )
          : isQuoterMode
          ? constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.NOT_AVAILABLE)
          : constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.POR)}
      </S.TextBlack>
    );
  }

  /**
   * Get final price
   */
  function getFinalPrice(
    price: number,
    basePrice: number,
    useBase: boolean,
    productId: number,
    priceGroupItem: IPriceGroupItem,
  ) {
    const c = getChanges(productId, priceGroupItem);
    if (useBase) return basePrice;
    let percentageApplied = 0;
    if (c.priceAdjust === PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE) percentageApplied = c.percentage;
    if (c.priceAdjust === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE) percentageApplied = -1 * c.percentage;
    if (isQuoterMode) {
      const result = basePrice / (1 - percentageApplied / 100);
      return result ? Math.max(0.01, result) : result;
    }
    const result = basePrice * ((100 + percentageApplied) / 100);
    return result ? Math.max(0.01, result) : result;
  }

  function getChanges(productId: number, priceGroupItem: IPriceGroupItem) {
    const key = productId + '_' + priceGroupItem?.priceGroupId;
    if (changes[key]) {
      return changes[key];
    } else {
      const price = prices[productId];
      return {
        adjust: 0,
        dropPosition: 'up' as 'up' | 'down',
        percentage: Math.abs(priceGroupItem?.percentage || 0),
        priceAdjust: priceGroupItem?.type,
        priceUpdated: priceGroupItem?.price || price?.price || 0,
      };
    }
  }
  /**
   * Shows image gallery with the order products images
   */
  function onImageClick(e: React.MouseEvent, imagesArray: Array<string>, index: number) {
    e.stopPropagation();
    touchImage(
      imagesArray.map(url => ({ src: url })),
      index,
    );
  }

  /**
   * Create addPercentaje to get TotalVariaton
   */

  function addPercentage(base, percentage) {
    return base * (1 + percentage / 100);
  }

  /**
   * Render Variation price
   */

  function renderVariationPrice(
    priceType: PRICE_ADJUSTMENT_TYPE,
    finalPrice: number,
    pricePrecision: number,
    basePrice: number,
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
    margin: number,
    productChanges: any,
    isPor: boolean,
  ) {
    const price = prices[product?.id];
    const initialPrice = price?.basePrice;
    const { finalPercentage, isDiscount } = getFinalPercentage(
      priceType,
      finalPrice,
      basePrice,
      priceGroupItem,
      product,
    );
    const priceFixed = productChanges.priceAdjust === PRICE_ADJUSTMENT_TYPE.FIXED;
    const percentageFinalPricer =
      addPercentage(productChanges.priceUpdated, productChanges.percentage) - productChanges.priceUpdated;
    const priceDiff =
      // Math.abs(
      priceFixed
        ? productChanges.priceUpdated - initialPrice
        : isDiscount
        ? percentageFinalPricer * -1
        : percentageFinalPricer;
    // );

    return (
      <S.FlexCol>
        <S.TextBlack>
          {currency.getPricePerUnit(
            price?.currency || catalog?.defaultCurrency || CURRENCY_CODES.EUR,
            price?.priceUnit,
            product?.weightUnit || catalog?.defaultWeightUnit,
            priceDiff,
            pricePrecision,
          )}
        </S.TextBlack>
        {priceFixed ? null : (
          <S.TextPercentage isDiscount={isDiscount}>
            {(isDiscount ? '-' : '') + (finalPercentage || 0).toFixed(2)}%
          </S.TextPercentage>
        )}
      </S.FlexCol>
    );
  }

  /**
   * Render variation column
   */
  function renderVariation(
    isPor: boolean,
    priceType: PRICE_ADJUSTMENT_TYPE,
    finalPrice: number,
    pricePrecision: number,
    basePrice: number,
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
  ) {
    const hasFinalPrice = !!finalPrice;
    const margin = finalPrice - basePrice;
    const productChanges = getChanges(product?.id, priceGroupItem);

    if (
      productChanges.priceAdjust === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE ||
      productChanges.priceAdjust === PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE ||
      productChanges.priceAdjust === PRICE_ADJUSTMENT_TYPE.FIXED
    ) {
      return renderVariationPrice(
        priceType,
        finalPrice,
        pricePrecision,
        basePrice,
        priceGroupItem,
        product,
        margin,
        productChanges,
        isPor,
      );
    }
    if (!basePrice || (!hasFinalPrice && priceGroupItem?.isPor) || !margin) {
      return <S.FlexCol>{'-'}</S.FlexCol>;
    }

    return renderVariationPrice(
      priceType,
      finalPrice,
      pricePrecision,
      basePrice,
      priceGroupItem,
      product,
      margin,
      productChanges,
      isPor,
    );
  }

  /**
   * Render final price
   */
  function renderFinalPrice(
    isPor: boolean,
    priceType: PRICE_ADJUSTMENT_TYPE,
    finalPrice: number,
    pricePrecision: number,
    basePrice: number,
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
  ) {
    const price = prices[product?.id];
    const productChanges = getChanges(product?.id, priceGroupItem);
    const hasFinalPrice = !!finalPrice;
    if (isPor || (!hasFinalPrice && priceGroupItem?.isPor)) {
      const isPorLiteral = constants.getPriceAdjustmentTypeLiteral(PRICE_ADJUSTMENT_TYPE.POR);
      return <S.TextSemiBlack>{isPorLiteral}</S.TextSemiBlack>;
    }
    if (!hasFinalPrice) return <S.TextSemiBlack>{'-'}</S.TextSemiBlack>;

    const { finalPercentage, isDiscount } = getFinalPercentage(
      priceType,
      finalPrice,
      basePrice,
      priceGroupItem,
      product,
    );

    const priceFixed = productChanges.priceAdjust === PRICE_ADJUSTMENT_TYPE.FIXED;

    return (
      <S.FlexRow>
        <S.TextPrice>
          {priceFixed ? productChanges.priceUpdated.toFixed(pricePrecision) : finalPrice.toFixed(pricePrecision)}{' '}
          <S.TextGrey2>
            {currency.getPricePerUnit(
              price?.currency || catalog?.defaultCurrency || CURRENCY_CODES.EUR,
              product?.priceUnit,
              product?.weightUnit,
            )}
          </S.TextGrey2>
        </S.TextPrice>
        {/* {!!finalPercentage && !config.TOGGLE_MARGINS.enabled ? (
          <ArrowPercentage isDiscount={isDiscount} value={finalPercentage} />
        ) : null} */}
      </S.FlexRow>
    );
  }

  /**
   * Get final percentage between the base price and the final price
   */
  function getFinalPercentage(
    priceType: PRICE_ADJUSTMENT_TYPE,
    finalPrice: number,
    basePrice: number,
    priceGroupItem: IPriceGroupItem,
    product: IProduct,
  ) {
    const c = getChanges(product?.id, priceGroupItem);
    let isDiscount = c.priceAdjust === PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE;
    let finalPercentage = c.percentage;
    if (priceType === PRICE_ADJUSTMENT_TYPE.FIXED && basePrice && basePrice !== finalPrice) {
      const fixedPercentage = ((finalPrice - basePrice) / basePrice) * 100;
      isDiscount = fixedPercentage < 0;
      finalPercentage = Math.abs(fixedPercentage);
    }
    return { finalPercentage, isDiscount };
  }

  /**
   * On update a price group item
   */
  function onUpdatePriceFn(
    priceGroupItem: IPriceGroupItem,
    priceAdjust: PRICE_ADJUSTMENT_TYPE,
    adjust: number,
    price: number,
    percentage: number,
  ) {
    const item = { ...priceGroupItem };
    item.isPor = false;
    item.useBase = false;
    item.price = 0;
    item.percentage = 0;
    switch (priceAdjust) {
      case PRICE_ADJUSTMENT_TYPE.POR:
        item.isPor = true;
        break;
      case PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE:
        item.percentage = -1 * percentage;
        break;
      case PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE:
        item.percentage = percentage;
        break;
      case PRICE_ADJUSTMENT_TYPE.DISCOUNT_PRICE:
        // TODO - Add adjust to price item with currency value
        break;
      case PRICE_ADJUSTMENT_TYPE.MARKUP_PRICE:
        // TODO - Add adjust to price item with currency value
        break;
      case PRICE_ADJUSTMENT_TYPE.FIXED:
        item.price = price;
        break;
      case PRICE_ADJUSTMENT_TYPE.BASE:
        item.useBase = true;
        break;
    }
    // si (NO es descuento/sobreprecio o el porcentaje no es 0) y (NO es precio fijo o el precio no es 0) o esquoter
    if (
      ((![PRICE_ADJUSTMENT_TYPE.DISCOUNT_PERCENTAGE, PRICE_ADJUSTMENT_TYPE.MARKUP_PERCENTAGE].includes(priceAdjust) ||
        item.percentage !== 0) &&
        (PRICE_ADJUSTMENT_TYPE.FIXED !== priceAdjust || price !== 0)) ||
      isQuoterMode
    ) {
      onUpdatePrice(item);
    }
  }

  /**
   * Show confirmation modal when removing a price
   */
  function onRemovePriceFn(priceGroupItem: IPriceGroupItem) {
    modalOpen(
      isQuoterMode ? __('WorkspaceMarginEdit.modal_remove.title') : __('WorkspacePriceGroupEdit.modal_remove.title'),
      () => {
        modalClose();
        onRemovePrice(priceGroupItem);
      },
      {
        closeable: true,
        text2: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.description')
          : __('WorkspacePriceGroupEdit.modal_remove.description'),
        buttonText: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.cta')
          : __('WorkspacePriceGroupEdit.modal_remove.cta'),
        showCancelButton: true,
        buttonCancelText: isQuoterMode
          ? __('WorkspaceMarginEdit.modal_remove.cta_cancel')
          : __('WorkspacePriceGroupEdit.modal_remove.cta_cancel'),
        icon: IMAGES.cautionGrape,
        actionType: 'dangerous',
      },
      'nice',
    );
  }
};

export default PriceItemTable;

/**
 * Get column according to a custom config
 */
function getColumnField(
  columnConfig: IColumnConfig,
  options?: {
    getValue?: (
      data: { priceGroupItem: IPriceGroupItem; product: IProduct },
      idx: number,
      rowIdx: number,
    ) => string | number;
    getElement?: (
      data: { priceGroupItem: IPriceGroupItem; product: IProduct },
      idx: number,
      rowIdx: number,
    ) => React.ReactElement;
    getDataCell?: (
      data: { priceGroupItem: IPriceGroupItem; product: IProduct },
      idx: number,
      rowIdx: number,
    ) => IDataCell;
  },
  isQuoterMode?: boolean,
): IColumn {
  const { getValue, getElement, getDataCell } = options || {};
  const column: IColumn = {
    value: getValue,
    element: getElement,
    getDataCell,
    id: columnConfig.name,
    title: '',
  };
  switch (columnConfig.name) {
    case 'based-price':
      column.title = isQuoterMode
        ? __('WorkspacePriceGroupEdit.table.cost')
        : __('WorkspacePriceGroupEdit.table.price_base');
      return column;
    case 'adjustment-type':
      column.title = __('WorkspacePriceGroupEdit.table.adjustment_type');
      return column;

    case 'rank':
      column.sort = `rank`;
      column.sortOrder = ['desc', 'asc'];
      column.title = 'Rankin';
      return column;

    case 'sku':
      column.sort = `sku`;
      column.sortOrder = ['desc', 'asc'];
      column.title = __('WorkspacePriceGroupEdit.table.sku');
      return column;
    case 'product-type':
      column.title = __('Components.ProductsList.Table.TypeOfProduction');
      return column;
    case 'product-variety':
      column.title = __('WorkspacePriceGroupEdit.table.ProductVariety');
      return column;
    case 'size':
      column.title = __('Components.ProductsList.Table.Size');
      return column;
    case 'origin':
      column.title = __('Components.ProductsList.Table.Origin');
      return column;
    case 'category':
      column.title = __('Components.ProductsList.Table.Category');
      return column;
    case 'packaging':
      column.title = __('Components.ProductsList.Table.Packaging');
      return column;
    case 'box-weight':
      column.title = __('Components.ProductsList.Table.BoxWeight');
      return column;
    case 'pallet-type':
      column.title = __('Components.ProductsList.Table.PalletType');
      return column;
    case 'box-type':
      column.title = __('Components.ProductsList.Table.BoxType');
      return column;
    case 'region':
      column.title = __('Components.ProductsList.Table.Region');
      return column;
    case 'type-of-production':
      column.title = __('Components.ProductsList.Table.TypeOfProduction');
      return column;
    case 'ean':
      column.title = __('Components.ProductsList.Table.Ean');
    case 'boxes-per-pallet':
      column.title = __('Components.ProductsList.Table.BoxesPerPallet');
      return column;
    case 'warehouse':
      column.title = __('Components.ProductsList.Table.Warehouse');
      return column;
    case 'adjustment':
      column.title = isQuoterMode
        ? __('WorkspacePriceGroupEdit.table.margin')
        : __('WorkspacePriceGroupEdit.table.adjustment');
      return column;
    case 'variation':
      column.title = __('WorkspacePriceGroupEdit.table.variation');
      return column;
    case 'final-price':
      column.title = __('WorkspacePriceGroupEdit.table.price');
      return column;
    case 'title':
      column.showOver = true;
      column.title = __('Components.ProductsList.Table.TypeVariety');
      column.sort = `title_sort.${i18n.default.currentLocale()}`;
      column.sortOrder = ['desc', 'asc'];
      column.minWidth = '140px';
      column.width = '20%';
      return column;
    default:
      return undefined;
  }
}
