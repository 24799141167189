import { __, modalActions, notificationsActions, sellerWorkspaceActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { IMAGES } from '../../../assets';

import Ribbon from '../Ribbon';

const VisibilityPricesRibbon = ({
  client,
  catalogId,
  priceMode,
  myRole,
  myId,
}: {
  catalogId: number;
  client: IClient;
  priceMode: IPriceMode;
  myRole?: ICatalogRole;
  myId: number;
}) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const priceModeUpdate = () => {
    dispatch(
      sellerWorkspaceActions.clientUpdate(myId, catalogId, { ...client, priceMode: 'read' }, err => {
        if (!err) {
          return dispatch(
            notificationsActions.notificationShow({
              title: __('WorkspaceClientEdit.success'),
              subtitle: __('WorkspaceClientEdit.success_description'),
              closable: true,
              style: 'success',
            }),
          );
        }
      }),
    );
  };

  if (!myRole || priceMode !== 'none') return null;
  return (
    <Ribbon
      type="info"
      text={__('Components.OrderDetails.ribbon_prices_hidded')}
      actionText={myRole && myRole !== 'viewer' ? __('Components.OrderDetails.ribbon_action_show_prices') : null}
      onClickAction={() =>
        dispatch(
          modalActions.modalOpen(
            __('Components.OrderDetails.priceModal.title'),
            () => {
              priceModeUpdate();
            },
            {
              showCancelButton: true,
              buttonCancelText: __('Components.OrderDetails.priceModal.cancel'),
              text2: __('Components.OrderDetails.priceModal.text'),
              buttonText: __('Components.OrderDetails.priceModal.cta'),
              icon: IMAGES.informativePineapple,
            },
            'nice',
          ),
        )
      }
    />
  );
};

export default VisibilityPricesRibbon;
