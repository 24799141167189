import { IWorkspaceType } from 'common-services';
import { BUYER_WORKSPACE, WorkspacesSetAction } from 'common-services/dist/buyer-workspace/actions';
import { CatalogsSetAction, SELLER_WORKSPACE } from 'common-services/dist/seller-workspace/actions';

import { Action, IChatGroupsExpanded, NavTypes } from '../actions/nav';
import { CHANNEL_SECTIONS, REGISTER_STEPS } from '../constants';

export interface IReduxStateNav {
  auth?: REGISTER_STEPS;
  channels: { [cId: string]: { section?: CHANNEL_SECTIONS; sectionId?: number } };
  chatGroupsExpanded?: IChatGroupsExpanded;
  lastChannelId: string;
  miniChannelId: string;
  workspaceSelected: number;
  workspaceType: IWorkspaceType;
  bWsInitialized?: boolean;
  sWsInitialized?: boolean;
  filterBuyerProducts?: ISearch;
}

export const NAV_INITIAL_STATE: IReduxStateNav = {
  auth:
    typeof window !== 'undefined' && window.localStorage.getItem('username')
      ? REGISTER_STEPS.WELCOME_LOGIN_REGISTER
      : undefined,
  chatGroupsExpanded:
    typeof window !== 'undefined' && global.localStorage.getItem('chatGroupsExpanded')
      ? JSON.parse(global.localStorage.getItem('chatGroupsExpanded'))
      : undefined,
  channels: {},
  lastChannelId: '',
  miniChannelId: '',
  workspaceSelected: 0,
  workspaceType: 'seller',
};

export default function navReducer(
  state = NAV_INITIAL_STATE,
  action: Action | WorkspacesSetAction | CatalogsSetAction,
): IReduxStateNav {
  switch (action.type) {
    case NavTypes.NAV_CHANNEL: {
      const channels = {
        ...state.channels,
        [action.channelId]: { section: action.section, sectionId: action.sectionId },
      };
      return { ...state, channels };
    }
    case NavTypes.NAV_MINI_CHANNEL: {
      return { ...state, miniChannelId: action.channelId };
    }
    case NavTypes.LAST_CHANNEL_SET: {
      return { ...state, lastChannelId: action.channelId };
    }
    case NavTypes.CHAT_GROUPS_EXPANDED_SET: {
      return { ...state, chatGroupsExpanded: action.expanded };
    }
    case NavTypes.NAV_RESET_CHANNEL: {
      const channels = { ...state.channels, [action.channelId]: {} };
      return { ...state, channels };
    }
    case NavTypes.NAV_AUTH: {
      return { ...state, auth: action.step };
    }
    case NavTypes.NAV_RESET_AUTH: {
      return { ...state, auth: undefined };
    }
    case NavTypes.WORKSPACE_SELECT: {
      return { ...state, workspaceSelected: action.workspaceId, workspaceType: action.workspaceType };
    }
    case NavTypes.FILTER_BUYER_PRODUCTS: {
      return { ...state, workspaceSelected: action.workspaceId, filterBuyerProducts: action.filters };
    }
    case 'RESET_STATE': {
      return NAV_INITIAL_STATE;
    }
    case SELLER_WORKSPACE.SET: {
      return { ...state, sWsInitialized: true };
    }
    case BUYER_WORKSPACE.SET: {
      return { ...state, bWsInitialized: true };
    }
    default:
      return state;
  }
}
