import { IGlobalWeb } from 'common-services';
declare var global: IGlobalWeb;

export default class Analytics {
  // do nothing
  // script is loaded in index.html based on SEGMENT_KEY env var
  // constructor() {
  // }
  public identify(userId: string, extra: any) {
    global.analytics.identify(userId, extra);
  }
  public screen(name: string, extra: any) {
    global.analytics.page(name, extra);
  }
  public track(name: string, extra: any) {
    global.analytics.track(name, extra);
  }
}
