import { REGISTER_STEPS } from '../../../constants';
import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer, TextLabel } from '../../atoms';
import { LanguageSelect as LS } from '../../molecules';

interface IStyledProps {
  authStep?: REGISTER_STEPS;
  theme: ITheme;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  flex: 1;
  max-height: ${(p: IStyledProps) => p.theme.windowHeight};
`;

export const HeaderBackground = styled(RowContainer)`
  flex: 1;
  position: relative;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding: 40px;
  max-height: calc(${(p: IStyledProps) => p.theme.windowHeight} - 80px);

  @media (max-height: ${(p: IStyledProps) => p.theme.landingMinHeight}) {
    padding-right: 0;
    min-height: 620px;
  }
`;

export const BackgroundImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: min(48%, 660px);
  max-height: calc(${(p: IStyledProps) => p.theme.windowHeight} - 80px);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  @media (max-height: ${(p: IStyledProps) => p.theme.landingMinHeight}) {
    min-height: 620px;
  }
`;

export const Header = styled(ColumnContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const HeaderRow = styled(RowContainer)`
  width: 100%;
  margin: 25px 25px 0 25px;
  justify-content: center;
  align-items: center;
`;

export const HeaderLeft = styled(ColumnContainer)`
  width: 660px;
  max-width: min(48%, 660px);
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(12)};
  justify-content: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(8)};
  }
`;

export const HeaderRight = styled(ColumnContainer)`
  width: auto;
  flex: 1;
  align-items: center;
  position: relative;

  ${(p: IStyledProps) =>
    [REGISTER_STEPS.REGISTER, REGISTER_STEPS.VERIFY_CODE, REGISTER_STEPS.RESET_PASSWORD].includes(p.authStep)
      ? css`
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          bottom: 0;
          background: white;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 1;
          overflow: auto;
        `
      : css`
          @media (max-height: 780px) {
            max-height: 565px;
            overflow-y: auto;
          }
        `}
`;

export const Title = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: 2rem;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  text-align: left;
  line-height: 1.2;
`;

export const TextSubtitle = styled(TextLabel)`
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  line-height: 1.6;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const ConsentioLogo = styled.img`
  height: 30px;
  width: 150px;
  object-fit: contain;
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(10)};
  left: calc(min(48%, 660px) + ${(p: IStyledProps) => p.theme.paddingSize(7)});
  z-index: 1;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}), (max-height: 780px) {
    top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  }

  ${(p: IStyledProps) =>
    [REGISTER_STEPS.REGISTER, REGISTER_STEPS.VERIFY_CODE, REGISTER_STEPS.RESET_PASSWORD].includes(p.authStep) &&
    css`
      position: fixed;
      left: ${p.theme.paddingSize(7)};
    `};
`;

export const LanguageSelect = styled(LS)`
  height: 65px;
  width: auto;
  object-fit: contain;
  position: absolute;
  top: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(7)};
  z-index: 1;
  justify-content: center;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}), (max-height: 780px) {
    top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;
