import * as React from 'react';
import { StyledGridCircle, StyledGridLoading } from './GridLoading.styled';

export interface IProps {
  loading: boolean;
}

const GridLoading: React.FC<IProps> = React.memo(({ loading = true }) => {
  if (!loading) return null;

  const gridCircles = [];
  for (let i = 0; i < 9; i++) {
    gridCircles.push(<StyledGridCircle key={i} />);
  }
  return <StyledGridLoading>{gridCircles}</StyledGridLoading>;
});

export default GridLoading;
