import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TagBubble, TextLabel } from '../../atoms';
import { AddressesTable } from '../../molecules';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  color?: string;
  showColor?: boolean;
  disabled?: boolean;
}

export const Container = styled(RowContainer)`
  flex: 1;
  overflow: hidden;
`;
export const Row = styled(RowContainer)`
  align-items: center;
`;

const Text = styled(TextLabel)`
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: 1.2;
`;

const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

const TextGrey = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const EmptyTags = styled(TextGrey)`
  margin-left: ${(p: IStyledProps) => (p.disabled ? p.theme.paddingSize() : 0)};
`;

export const CardInputs = styled.div`
  display: flex;
  width: 97%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AddressesTableCustom = styled(AddressesTable)`
  height: max-content;
  width: 100%;
`;

export const SwitchContainer = styled(ColumnContainer)`
  max-width: max-content;
`;

export const SwitchRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const SwitchLabel = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => (p.disabled ? 0 : p.theme.paddingSize(8))};
`;
export const Separator = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TagsRow = styled(RowContainer)<IStyledProps>`
  align-items: center;
  flex-wrap: wrap;
  margin-left: ${(p: IStyledProps) => (p.disabled ? p.theme.paddingSize() : '0')};
`;

export const PriceGroupTag = styled(TagBubble)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingObject};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const ConfirmationButtonContainer = styled(ColumnContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: flex-end;
`;

export const ConfirmationButton = styled(Button)`
  background-color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.theme.colors.green1)};
`;

export const AddPricegroupsButton = styled(Button)`
  padding-left: 0px;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const NoMoreAvailablePricegroupsText = styled(TextGrey)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const DnDContainer = styled.div`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const FormCol = styled(ColumnContainer)``;

export const TextPlaceholder = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  text-align: left;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const TitleLabel = styled.span`
  display: block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const DeleteButton = styled(Button)`
  align-self: left;
`;
export const ColumnRight = styled(ColumnContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  top: 0;
  right: 0;
  max-width: 290px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: 35%;
    max-height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - ${p.theme.headerHeight} * 2 )`};
  }
`;
export const TextLink = styled(TextLabel)`
  display: inline-flex;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const ChatIcon = styled(FontIcon)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
`;

export const OrderButton = styled(Button)`
  width: 100%;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;
export const OrdersSubtitle = styled(TextLabel)`
  color: inherit;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
