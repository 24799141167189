import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, TagBubble, TextLabel, SimpleDropdown } from '../../atoms';

interface IStyledProps {
  enabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
  hasServedChange?: boolean;
  showGrey?: boolean;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  max-height: 100%;
  overflow-y: hidden;
`;

export const Content = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const BackContainer = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: space-between;
`;

export const Back = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const TextBlack = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
`;

export const TextTitleH1 = styled(TextBlack)`
  display: block;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(6)};
`;

export const TextBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const TextProductName = styled(TextBold)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const TextEmail = styled.a`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const InfoRow = styled(RowContainer)`
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Link = styled(Button)`
  display: inline;
`;

export const ArticlesContainer = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  max-width: 860px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Th = styled.th`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-align: left;
`;

export const Tr = styled.tr`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
  height: 102px;
  td:first-child {
    border-top-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
    border-bottom-left-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  }
  td:last-child {
    border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
    border-bottom-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  }
`;

export const Td = styled.td`
  vertical-align: middle;
  text-align: left;
`;

export const EmptyTd = styled(Td)`
  min-width: 30px;
  width: 30px;
  max-width: 30px;
`;

export const ProductImageTd = styled(Td)`
  min-width: 110px;
  width: 110px;
  max-width: 110px;
  vertical-align: bottom;
`;

export const ProductDescriptionTd = styled(Td)`
  width: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ProductAmountTd = styled(Td)`
  width: 120px;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ProductImage = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
`;

export const TagsRow = styled(RowContainer)`
  align-items: center;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Tag = styled(TagBubble)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const InviteButton = styled(Button)``;

export const CopyIcon = styled(FontIcon)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: 15px;
  width: 15px;
  height: 15px;
`;


export const AmountRow = styled(RowContainer)`
  align-items: start;
  align-self: center;
  cursor: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Amount = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  display: flex;
  color: ${(p: IStyledProps) =>
    p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const QuantityRow = styled(RowContainer)`
  align-items: center;
`;
export const SectionTitle = styled(RowContainer)`
  align-items: center;
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: 0;
`;



export const Reference = styled(TextLabel)`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const BlueText = styled(TextLabel)`
  display: inline;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ReferenceCol = styled(ColumnContainer)``;

export const SubText = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const Text = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const ItemPrice = styled(Text)`
  font-weight: ${(p: IStyledProps) =>
    p.hasServedChange && !p.showGrey ? p.theme.fontWeight.Bold : p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  text-align: right;
  flex: none;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: auto;
`;

export const ItemPriceTotal = styled(ItemPrice)`
  float: left;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  }
`;

export const Row = styled(RowContainer)`
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CardItem = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  height: 100%;
`;

const ButtonGroupItem = styled(Button)`
  min-width: 0;
  padding-left: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TableHeadWrapper = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const LeftRow = styled(RowContainer)`
  align-items: center;
`;

export const SortByDropdown = styled(SimpleDropdown)`
  align-self: flex-start;
`;

export const SortButton = styled(ButtonGroupItem)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const SortBlackText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SortGreyText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;