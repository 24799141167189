import styled, { ITheme } from '../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Container = styled.div`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  box-shadow: ${(p: IStyledProps) => p.theme.boxShadow};
  background: ${(p: IStyledProps) => p.theme!.colors.white};
  align-self: ${(p: IStyledProps) => 'flex-start'};
  position: relative;
  cursor: pointer;
  transition: 0.6s;
  &:hover {
    opacity: 0.75;
  }
`;
