import * as React from 'react';
import { date, IDateRange } from 'common-services';

interface UseDateSetterProps {
  search: ISearchOrder;
  viewMode: 'order' | 'delivery' | 'request';
  setDateRange: (range: IDateRange) => void;
  setSearch: (search: any) => void;
  lastDatesUsedRef: React.MutableRefObject<{ beginDate: Date; endDate: Date } | null>;
}

type DateRangeKey = IDateRange | 'unknown' | 'custom';

export const useDateSetter = ({ search, viewMode, setDateRange, setSearch, lastDatesUsedRef }: UseDateSetterProps) => {
  return React.useCallback(
    (dateRangeKey: DateRangeKey, useNewSearchState: boolean) => {
      let newDates: { beginDate: Date | null; endDate: Date | null };
      let withoutDeliveryAt: boolean | undefined;

      switch (dateRangeKey) {
        case 'unknown':
          newDates = { beginDate: null, endDate: null };
          withoutDeliveryAt = true;
          break;
        case 'custom':
          newDates = lastDatesUsedRef.current || { beginDate: null, endDate: null };
          withoutDeliveryAt = viewMode === 'delivery' ? false : undefined;
          break;
        default:
          newDates = date.getDatesFromRange(dateRangeKey);
          withoutDeliveryAt = viewMode === 'delivery' ? false : undefined;
      }

      const newSearchState = {
        ...(useNewSearchState ? {} : search),
        ...newDates,
        withoutDeliveryAt,
      };

      setDateRange(dateRangeKey === 'unknown' ? 'custom' : dateRangeKey);
      setSearch(newSearchState);
    },
    [search, viewMode, setDateRange, setSearch, lastDatesUsedRef],
  );
};
