import { __, buyerWorkspaceActions, buyerWorkspaceService, INVITE_ORIGIN, notificationsActions } from 'common-services';
import * as React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { IReduxState } from '../../../reducers';
import SelectContacts from '../../../screens/select-contacts';

interface IProps {
  workspaceId: number;
  close: () => void;
}

const AddSuppliers = React.memo(({ close, workspaceId }: IProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const {
    contact: { inConsentio: contacts },
    user: { user: me },
    workspace: { suppliers },
  } = useStore<IReduxState>().getState();
  const contactAddedIds = (suppliers[workspaceId] || []).map((c: ISupplier) => c.userId);
  const myId = me.id;
  const contactsNotAdded = Object.values(contacts).filter(
    (c: IContact) => !contactAddedIds?.includes(c.id) && c.isSeller && c.id !== myId && !c.isUnregistered,
  );
  return (
    <SelectContacts
      catalogId={workspaceId}
      contacts={contactsNotAdded}
      onClose={close}
      onSave={(contactsToAdd: Array<number>) => {
        dispatch(
          buyerWorkspaceActions.suppliersAdd(
            myId,
            Number(workspaceId),
            contactsToAdd.map(cId => {
              const contact = contacts[cId];
              return buyerWorkspaceService.contactToSupplier(contact, Number(workspaceId));
            }),
            error => {
              if (error) {
                return dispatch(
                  notificationsActions.notificationShow({
                    title: __('SuppliersList.error', { count: contactsToAdd.length }),
                    subtitle: __('SuppliersList.error_description', { count: contactsToAdd.length }),
                    closable: true,
                    style: 'error',
                  }),
                );
              }
              dispatch(buyerWorkspaceActions.suppliersGet(me.id, Number(workspaceId)));
              return dispatch(
                notificationsActions.notificationShow({
                  title: __('SuppliersList.success', { count: contactsToAdd.length }),
                  subtitle: __('SuppliersList.success_description', { count: contactsToAdd.length }),
                  closable: true,
                  style: 'info',
                }),
              );
            },
          ),
        );
        close();
      }}
      origin={INVITE_ORIGIN.WORKSPACE}
      showInvite={true}
      showUnregistered={true}
      title={__('SuppliersList.add_suppliers_consentio')}
      trackingFrom="workspace-suppliers"
      zeroCase="Suppliers"
    />
  );
});
export default AddSuppliers;
