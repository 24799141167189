import styled, { ITheme } from '../../../../styled-components';
import { ColumnContainer, LettersAvatar, RowContainer, TextLabel } from '../../../atoms';

interface IStyledProps {
  isSelected?: boolean;
  theme: ITheme;
}

export const MentionModal = styled(ColumnContainer)`
  position: absolute;
  bottom: 53px;
  left: 80px;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  max-height: 332px;
  max-width: max-content;
  overflow-y: auto;
`;

export const Member = styled(RowContainer)`
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => (p.isSelected ? p.theme.colors.grey3 : p.theme.colors.transparent)};

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const MemberAvatar = styled(LettersAvatar)``;
export const MemberText = styled(TextLabel)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MemberTextHighlight = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
