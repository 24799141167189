import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, RowContainer } from '../FlexContainer';
import TextLabel from '../TextLabel';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  disabled?: boolean;
  isImage?: boolean;
}

export const Container = styled(RowContainer)`
  justify-content: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const FilterItem = styled(ColumnContainer)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  align-items: center;
  cursor: ${(p: IStyledProps) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

export const FilterText = styled(TextLabel)`
  color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.black : p.theme.colors.grey2)};
  font-weight: ${(p: IStyledProps) => (p.selected ? p.theme.fontWeight.Bold : p.theme.fontWeight.Regular)};
  text-align: center;
  background-color: ${(p: IStyledProps) => (p.selected ? p.theme.colors.grey4 : p.theme.colors.white)};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0.5)} ${(p: IStyledProps) => p.theme.paddingSize(1)};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
