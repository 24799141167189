/**
 * from: https://github.com/ellisonleao/sharer.js
 */

export interface IShareData {
  sharer: string;
  url: string;
  hashtag?: string;
  hashtags?: string;
  via?: string;
  title?: string;
  to?: string;
  subject?: string;
  width?: number;
  height?: number;
  web?: boolean;
}

interface ISharer {
  shareUrl: string;
  params?: { [key: string]: any };
  isLink?: boolean;
}

/**
 * Open a share new window for facebook, linkedin, twitter or email option
 */
export default function share({
  sharer,
  url,
  hashtag,
  hashtags,
  via,
  title,
  to,
  subject,
  width,
  height,
  web,
}: IShareData) {
  const sharers: { [key: string]: ISharer } = {
    facebook: {
      shareUrl: 'https://www.facebook.com/sharer/sharer.php',
      params: {
        u: url,
        hashtag,
      },
    },
    linkedin: {
      shareUrl: 'https://www.linkedin.com/shareArticle',
      params: {
        url,
        mini: true,
      },
    },
    twitter: {
      shareUrl: 'https://twitter.com/intent/tweet/',
      params: {
        text: title,
        url,
        hashtags,
        via,
      },
    },
    whatsapp: {
      shareUrl: web ? 'https://wa.me/' : 'whatsapp://send',
      params: {
        text: title + ' ' + url,
      },
      isLink: true,
    },
    email: {
      shareUrl: 'mailto:' + (to || ''),
      params: {
        subject,
        body: title + '\n' + url,
      },
      isLink: true,
    },
  };
  const s = sharers[sharer];

  if (s) {
    const p = s.params || {};
    const keys = Object.keys(p);
    let i: number;
    let str = keys.length > 0 ? '?' : '';
    for (i = 0; i < keys.length; i++) {
      if (str !== '?') {
        str += '&';
      }
      if (p[keys[i]]) {
        str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
      }
    }
    s.shareUrl += str;

    if (!s.isLink) {
      const popWidth = width || 600;
      const popHeight = height || 480;
      const left = window.innerWidth / 2 - popWidth / 2 + window.screenX;
      const top = window.innerHeight / 2 - popHeight / 2 + window.screenY;
      const popParams =
        'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left;
      const newWindow = window.open(s.shareUrl, '', popParams);
      if (window.focus) {
        newWindow.focus();
      }
    } else {
      window.open(s.shareUrl, '_blank');
    }
  } else {
    return false;
  }
}
