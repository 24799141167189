import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, Picture, RowContainer, TagBubble, TextLabel } from '../../atoms';
import { css } from 'styled-components';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  clickable?: boolean;
}

export const ContainerImageAndText = styled(RowContainer)`
  display: flex;
  align-items: center;
`;

export const ProductImage = styled(Picture)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProductText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  margin: 0 0 0 9px;
  line-height: 1.38;
  letter-spacing: normal;
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
  justify-content: center;
  align-items: center;
`;

export const EmptyCell = styled(ColumnContainer)`
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
`;


export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: 800;
`;

export const LeftContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

export const FlexRow = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: flex-start;
  ${(p: IStyledProps) =>
    p.clickable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `};
`;
