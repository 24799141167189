import {
  Api,
  broadcastActions,
  chatActions,
  modalActions,
  notificationsActions,
  productActions,
  sellerWorkspaceActions,
} from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { navigateChannelBySection } from '../actions/nav';
import ProductListShare, { IDispatchProps, IOwnProps, IStateProps } from '../components/organisms/ProductListShare';
import { IReduxState } from '../reducers';

function mapStateToProps(state: IReduxState, props: IOwnProps): IStateProps {
  const {
    chat: { channels, lastMessageAt, unreadMessageCount },
    contact: { inConsentio },
    catalog: { catalogs },
    product,
    user: { user: me },
    broadcast,
  } = state;
  const contact = props.contactId && inConsentio[props.contactId];
  const catalog = props.amSeller
    ? catalogs[props.catalogId || me.sellerWorkspaceId]
    : product.catalog?.[props.catalogId];
  return {
    broadcasts: broadcast.broadcasts,
    catalog,
    channels,
    contacts: inConsentio,
    me,
    contact,
    priceListUrl: catalog?.priceListUrl,
    publicPriceList: catalog?.publicPriceList,
    lastMessageAt,
    broadcastLastMessageAt: broadcast.lastMessageAt,
    unreadMessageCount,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>, props: IOwnProps): IDispatchProps {
  return bindActionCreators(
    {
      catalogUpdate: sellerWorkspaceActions.catalogUpdate,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      navigateChannelBySection,
      productShare: productActions.productShare,
      sendBroadcastMessage: broadcastActions.sendMessage,
      sendMessage: chatActions.sendMessage,
      notificationShow: notificationsActions.notificationShow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListShare);
