import { __, constants } from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { ACCEPT_IMAGES } from '../../../constants';
import Icon from '../FontIcon';
import * as S from './Picture.styled';

const ReactCrop = React.lazy(() => import('react-image-crop'));

interface IProps {
  accept?: string;
  children?: React.ReactNode;
  className?: string;
  cropStrategy?: 'contain' | 'extended'; // Default extended
  disabled?: boolean;
  editable?: boolean;
  height?: string;
  hidden?: boolean;
  href?: string;
  id?: string;
  imageUrl?: string;
  margin?: string;
  multiple?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCropCancel?: () => void;
  onCropShown?: () => void;
  onDelete?: () => void;
  onFileChange: (file: File, fromCrop?: boolean) => void;
  onFilesChange?: (files: Array<File>) => void;
  pictureMode?: boolean;
  picturePlaceholder?: string;
  relation?: number;
  size?: 'extra-small' | 'small' | 'big';
  target?: string;
  width?: string;
  withCrop?: boolean;
  zeroCase?: string;
}

interface IState {
  crop: any;
  cropSrc: string;
  croppedImageUrl: string;
}

export default class Picture extends React.PureComponent<IProps, IState> {
  public static defaultProps = {
    imageUrl: '',
    size: 'small',
    editable: true,
    onFileChange: () => null,
    onDelete: () => null,
  };

  private imageRef: any;
  private pictureInputRef: React.RefObject<HTMLInputElement>;
  private fileUrl: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      crop: this.getDefaultCropConfig(),
      cropSrc: '',
      croppedImageUrl: '',
    };
    this.pictureInputRef = React.createRef();
  }

  public render() {
    const {
      accept,
      children,
      className,
      editable,
      height,
      hidden,
      href,
      id,
      imageUrl,
      margin,
      multiple = false,
      onClick,
      size,
      target,
      width,
      zeroCase,
    } = this.props;
    const { cropSrc } = this.state;

    if (hidden)
      return cropSrc ? (
        this.renderCrop()
      ) : (
        <S.PictureInputFile
          accept={accept || ACCEPT_IMAGES}
          className={className}
          hidden
          id={id}
          multiple={multiple}
          onChange={e => this.handleChange(Array.from(e.target.files))}
          ref={this.pictureInputRef}
          type="file"
        />
      );

    let imageUrlDisplay = imageUrl || '';
    // TODO if in the future we stop working with cloudinary this logic won't be working.
    if (size === 'small' || size === 'extra-small')
      imageUrlDisplay = imageUrl.replace('f_auto', 'f_auto,c_thumb,w_60,h_60');

    if (!editable) {
      if (!imageUrlDisplay) {
        return zeroCase ? (
          <S.ReadOnly>{zeroCase}</S.ReadOnly>
        ) : (
          <S.PictureContainer
            className={className}
            editable={editable}
            height={height}
            margin={margin}
            onClick={onClick}
            size={size}
            width={width}
          >
            <S.PictureImage src={constants.DEFAULT_IMAGE_URL} className="picture-img" />
          </S.PictureContainer>
        );
      }
      if (href) {
        return (
          <S.PictureLinkContainer className={className} href={href} margin={margin} size={size} target={target}>
            <S.PictureImage src={imageUrlDisplay} className="picture-img" />
          </S.PictureLinkContainer>
        );
      }
      return (
        <S.PictureContainer
          className={className}
          height={height}
          margin={margin}
          onClick={onClick}
          size={size}
          width={width}
        >
          <S.PictureImage src={imageUrlDisplay} className="picture-img" />
        </S.PictureContainer>
      );
    }

    return cropSrc ? (
      this.renderCrop()
    ) : children ? (
      <div
        onClick={() => {
          if (this.pictureInputRef && this.pictureInputRef.current) this.pictureInputRef.current.click();
        }}
      >
        {children}
        <S.PictureInputFile
          accept={accept || ACCEPT_IMAGES}
          onChange={e => this.handleChange(Array.from(e.target.files))}
          ref={this.pictureInputRef}
          type="file"
        />
      </div>
    ) : (
      this.renderDropzone(imageUrlDisplay)
    );
  }

  /**
   * Render crop tool
   */
  private renderCrop = () => {
    const { className, cropStrategy = 'extended', onCropCancel } = this.props;
    const { cropSrc, crop } = this.state;
    return (
      <S.CropWrapper className={className}>
        <ReactCrop
          src={cropSrc}
          crop={crop}
          onImageLoaded={image => {
            this.imageRef = image;
          }}
          onComplete={this.onCropComplete}
          onChange={c => this.setState({ crop: c })}
          imageStyle={{
            display: 'block',
            height: 'auto',
            width: 'auto',
            margin: '0 auto',
            maxWidth: cropStrategy === 'extended' ? '100%' : 'min(calc(100vw - 80px), 1600px)',
            maxHeight: '100%',
          }}
          style={{
            backgroundColor: 'transparent',
            display: 'block',
            marginBottom: '10px',
            overflow: 'auto',
          }}
        />
        <S.Buttons>
          <S.CancelButton
            onClick={() => {
              onCropCancel?.();
              this.setState({
                cropSrc: '',
                croppedImageUrl: '',
                crop: this.getDefaultCropConfig(),
              });
            }}
          >
            {__('Components.Picture.cancel')}
          </S.CancelButton>
          <S.SaveButton onClick={this.saveCrop}>{__('Components.Picture.save')}</S.SaveButton>
        </S.Buttons>
      </S.CropWrapper>
    );
  };

  private renderDropzone(imageUrlDisplay: string) {
    const {
      accept,
      className,
      editable,
      height,
      margin,
      multiple = false,
      onDelete,
      pictureMode,
      size,
      width,
    } = this.props;
    return (
      <Dropzone accept={accept || ACCEPT_IMAGES} multiple={multiple} onDrop={this.handleChange}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent
              {...getRootProps()}
              className={className}
              hasImage={!!imageUrlDisplay}
              height={height}
              isDragActive={isDragActive}
              margin={margin}
              pictureMode={pictureMode}
              size={size}
              width={width}
            >
              <input type="file" multiple={multiple} {...getInputProps()} />
              {imageUrlDisplay ? (
                <S.PictureContainer className={className} size={size} height={height} width={width} editable={editable}>
                  <S.PictureImage src={imageUrlDisplay} className="picture-img" />
                  {editable && size !== 'extra-small' ? (
                    <S.PictureActionsContainer size={size} className="picture-actions-container">
                      <S.FontIconActionWrapper size={size}>
                        <Icon name={'Edit'} />
                      </S.FontIconActionWrapper>
                      <S.FontIconActionWrapper
                        size={size}
                        onClick={e => {
                          if (onDelete) onDelete();
                          e.stopPropagation();
                        }}
                      >
                        <Icon name={'Trash'} />
                      </S.FontIconActionWrapper>
                    </S.PictureActionsContainer>
                  ) : null}
                </S.PictureContainer>
              ) : pictureMode ? (
                this.renderPicturePlaceholder()
              ) : (
                this.renderDropPlaceholder()
              )}
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
    );
  }

  /**
   * Render dropzone placeholder for picture mode
   */
  private renderPicturePlaceholder() {
    const { picturePlaceholder } = this.props;
    return (
      <>
        <S.CameraIcon name="Add-image" disableHover={true} />
        {picturePlaceholder ? <S.TextCenter>{picturePlaceholder}</S.TextCenter> : null}
      </>
    );
  }

  /**
   * Render dropzone placeholder with text + button
   */
  private renderDropPlaceholder() {
    const { width, height } = this.props;
    return (
      <>
        {width && height ? null : <S.TextSubtitle>{__('Components.Picture.Dropzone.subtitle')}</S.TextSubtitle>}
        <S.TextCenter>{__('Components.Picture.Dropzone.text')}</S.TextCenter>
        {width && height ? (
          <S.TextCenter>
            {__('Components.Picture.Dropzone.sizes', {
              width: width.replace('px', ''),
              height: height.replace('px', ''),
            })}
          </S.TextCenter>
        ) : null}
        <S.SelectButton>{__('Components.Picture.Dropzone.select')}</S.SelectButton>
      </>
    );
  }

  /**
   * Handle input file change
   */
  private handleChange = (files: Array<File>) => {
    if (files && files.length > 0) {
      const { withCrop, onFileChange, onFilesChange, multiple } = this.props;
      if (multiple) return onFilesChange?.(files);

      const isFileGif = files[0].type === 'image/gif';
      if (withCrop && !isFileGif) {
        this.loadCrop(files[0]);
      } else {
        onFileChange(files[0]);
      }
    }
  };

  /**
   * Load a file to crop
   */
  public loadCrop = (file: File) => {
    const { cropStrategy = 'extended', onCropShown, relation } = this.props;
    onCropShown?.();
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (relation && cropStrategy === 'extended') {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = e => {
          const { width, height } = e.target as HTMLImageElement;
          if (width / height === relation) {
            this.setState({ cropSrc: reader.result as string });
          } else if (width / height > relation) {
            this.generateCanvas(image, width, width / relation);
          } else {
            this.generateCanvas(image, height * relation, height);
          }
        };
      } else {
        this.setState({ cropSrc: reader.result as string });
      }
    });
    reader.readAsDataURL(file);
  };

  /**
   * Persist in the state the new crop
   */
  private onCropComplete = async crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  };

  /**
   * Send the file to the server and clean the crop
   */
  private saveCrop = async () => {
    const { onFileChange } = this.props;
    const { cropSrc, croppedImageUrl } = this.state;
    await fetch(croppedImageUrl || cropSrc)
      .then(res => res.blob())
      .then(blob => {
        const b: any = blob;
        b.lastModifiedDate = new Date();
        b.name = `img-cropped-${new Date().getTime()}.jpg`;
        return b;
      })
      .then(file => onFileChange(file, true));
    this.setState({
      cropSrc: '',
      croppedImageUrl: '',
      crop: this.getDefaultCropConfig(),
    });
  };

  /**
   * Adjust the size of the canvas at the relation to select the crop
   */
  private generateCanvas(image: HTMLImageElement, width: number, height: number) {
    const canvas = document.createElement('canvas');
    const scaleX = width / image.naturalWidth;
    const scaleY = height / image.naturalHeight;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const sw = image.naturalWidth * scaleX;
    const sh = image.naturalHeight * scaleY;
    const sx = scaleX > 1 ? (sw - image.naturalWidth) / 2 : 0;
    const sy = scaleY > 1 ? (sh - image.naturalHeight) / 2 : 0;
    ctx.drawImage(image, 0, 0, sw, sh, sx, sy, width, height);

    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }
      (blob as any).name = 'a';
      this.setState({ cropSrc: window.URL.createObjectURL(blob) });
    }, 'image/png');
  }

  /**
   * Proccess the Image cropped
   */
  private getCroppedImg(image, crop, fileName): Promise<string> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        (blob as any).name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/png');
    });
  }

  /**
   * Get crop config
   */
  private getDefaultCropConfig = () => {
    const { cropStrategy = 'extended', relation } = this.props;
    if (cropStrategy === 'extended' || relation === 1)
      return { aspect: relation, unit: '%', x: 25, y: 25, width: 50, height: 50 };
    if (relation > 1) return { aspect: relation, unit: '%', x: 0, y: 0, width: 99.99 };
    else if (relation < 1) return { aspect: relation, unit: '%', x: 0, y: 0, height: 99.99 };
    return { aspect: relation, unit: '%', x: 0, y: 0, height: 99.99, width: 99.99 };
  };
}
