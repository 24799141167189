import { __, IContactValidPhone, ILightContact, utils } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import { ContactSelectable } from '../../atoms';
import EmptyListResource from '../EmptyListResource';
import * as S from './ContactsList.styled';

interface IProps {
  action?: 'none' | 'remove' | 'switch' | 'radio';
  className?: string;
  contactDisabled?: Array<number>;
  ContactExtraActions?: React.FC<{ contactId: number; idx: number }>;
  contacts?: Array<ILightContact>;
  contactSelected?: (cId: number) => boolean;
  extraInfo?: (contact: ILightContact) => string;
  hideLetters?: boolean;
  labels?: { [cId: number]: string };
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  onSelectContact?: (cId: number, isSelected: boolean) => void;
  onSelectOutContact?: (contact: IContactValidPhone, isSelected: boolean) => void;
  outContacts?: Array<IContactValidPhone>;
  outContactSelected?: (contact: IContactValidPhone) => boolean;
  renderSubtitle?: (idx: number, contact: ILightContact) => string;
  search?: string;
  showUnregistered?: boolean;
  tooltipAction?: (cId: number) => void;
  tooltipDisabledText?: string;
}

const ContactsList: React.FC<IProps> = ({
  action,
  className,
  contactDisabled,
  ContactExtraActions,
  contacts,
  contactSelected,
  extraInfo,
  hideLetters,
  labels,
  onScroll,
  onSelectContact,
  onSelectOutContact,
  outContacts,
  outContactSelected,
  renderSubtitle,
  search,
  showUnregistered = false,
  tooltipAction,
  tooltipDisabledText,
}) => {
  const f = search && search.length > 1 && utils.toLocaleLowerCaseNormalized(search);
  return (
    <S.Contacts className={className} onScroll={onScroll}>
      {outContacts ? (
        <ListOutConsentio
          action={action}
          contactDisabled={contactDisabled}
          f={f}
          hideLetters={hideLetters}
          onSelectOutContact={onSelectOutContact}
          outContacts={outContacts}
          outContactSelected={outContactSelected}
        />
      ) : (
        <ListContacts
          action={action}
          contactDisabled={contactDisabled}
          ContactExtraActions={ContactExtraActions}
          contacts={contacts}
          contactSelected={contactSelected}
          extraInfo={extraInfo}
          f={f}
          hideLetters={hideLetters}
          labels={labels}
          onSelectContact={onSelectContact}
          renderSubtitle={renderSubtitle}
          showUnregistered={showUnregistered}
          tooltipAction={tooltipAction}
          tooltipDisabledText={tooltipDisabledText}
        />
      )}
    </S.Contacts>
  );
};

const ListContacts: React.FC<{
  action?: 'none' | 'remove' | 'switch' | 'radio';
  contactDisabled?: Array<number>;
  ContactExtraActions?: React.FC<{ contactId: number; idx: number }>;
  contacts: Array<ILightContact>;
  contactSelected: (cId: number) => boolean;
  extraInfo?: (contact: any) => string;
  f: string;
  hideLetters: boolean;
  labels?: { [cId: number]: string };
  onSelectContact?: (cId: number, isSelected: boolean) => void;
  renderSubtitle?: (idx: number, contact: ILightContact) => string;
  showUnregistered: boolean;
  tooltipAction?: (cId: number) => void;
  tooltipDisabledText?: string;
}> = ({
  action,
  contactDisabled,
  ContactExtraActions,
  contacts,
  contactSelected,
  extraInfo,
  f,
  hideLetters,
  labels,
  onSelectContact,
  renderSubtitle,
  showUnregistered,
  tooltipAction,
  tooltipDisabledText,
}) => {
  const contactsFiltered = contacts.filter(person => {
    return (
      person?.name &&
      !(
        (showUnregistered ? false : person.isUnregistered) ||
        (f &&
          !utils.toLocaleLowerCaseNormalized(person.name).includes(f) &&
          !utils.toLocaleLowerCaseNormalized(person.companyName).includes(f) &&
          !person.phoneNumbers.find((p: string) => p.includes(f)))
      )
    );
  });
  if (f && !contactsFiltered.length) return <EmptySearch search={f} className="empty-search-contacts" />;
  return (
    <>
      {contactsFiltered
        .sort((a, b) => {
          const aName = utils.toLocaleLowerCaseNormalized(a.name);
          const bName = utils.toLocaleLowerCaseNormalized(b.name);
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        })
        .reduce((acc: Array<any>, c: ILightContact, idx: number, arr: Array<ILightContact>) => {
          const initialLetter = utils.toLocaleLowerCaseNormalized(c.name).substring(0, 1).toUpperCase();
          const isSelected = contactSelected ? contactSelected(c.id) : true;
          if (
            !hideLetters &&
            (!idx ||
              utils
                .toLocaleLowerCaseNormalized(arr[idx - 1].name)
                .substring(0, 1)
                .toUpperCase() !== initialLetter)
          ) {
            acc.push(<S.LetterRow key={`${initialLetter}-${idx}`}>{initialLetter}</S.LetterRow>);
          }
          acc.push(
            <ContactSelectable
              action={action}
              className="contacts-list-contact-selectable"
              contact={c}
              containerClickable={ContactExtraActions ? false : true}
              disabled={contactDisabled ? contactDisabled.includes(c.id) && !isSelected : false}
              extraInfo={extraInfo?.(c)}
              isLast={idx === arr.length - 1}
              isSelected={isSelected}
              key={c.id + '_' + idx + '_' + c.name + '_' + c.companyName}
              label={labels && labels[c.id]}
              onSelectContact={() => onSelectContact && onSelectContact(c.id, isSelected)}
              subtitle={renderSubtitle?.(idx, c) || c.companyName}
              tooltipAction={tooltipAction}
              tooltipDisabledText={tooltipDisabledText}
            >
              {ContactExtraActions ? <ContactExtraActions contactId={c.id} idx={idx} /> : null}
            </ContactSelectable>,
          );

          return acc;
        }, [])}
    </>
  );
};

const ListOutConsentio: React.FC<{
  action?: 'none' | 'remove' | 'switch' | 'radio';
  contactDisabled?: Array<number>;
  f: string;
  hideLetters: boolean;
  onSelectOutContact?: (contact: IContactValidPhone, isSelected: boolean) => void;
  outContacts: Array<IContactValidPhone>;
  outContactSelected: (contact: IContactValidPhone) => boolean;
}> = ({ action, contactDisabled, outContacts, f, outContactSelected, hideLetters, onSelectOutContact }) => {
  const contactsFiltered = outContacts.filter(({ contact }) => {
    return (
      contact &&
      contact.name &&
      !(
        f &&
        !utils.toLocaleLowerCaseNormalized(contact.name).includes(f) &&
        !utils.toLocaleLowerCaseNormalized(contact.companyName).includes(f) &&
        !contact.phoneNumbers.find((p: string) => p.includes(f))
      )
    );
  });
  if (f && !contactsFiltered.length) return <EmptySearch search={f} className="empty-search-contacts-agenda" />;
  return (
    <>
      {contactsFiltered
        .sort((a, b) => {
          const aName = utils.toLocaleLowerCaseNormalized(a.contact.name);
          const bName = utils.toLocaleLowerCaseNormalized(b.contact.name);
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        })
        .reduce((acc: Array<any>, c: IContactValidPhone, idx: number, arr: Array<IContactValidPhone>) => {
          const initialLetter = utils.toLocaleLowerCaseNormalized(c.contact.name).substring(0, 1).toUpperCase();
          const isSelected = outContactSelected ? outContactSelected(c) : true;
          if (
            !hideLetters &&
            (!idx ||
              utils
                .toLocaleLowerCaseNormalized(arr[idx - 1].contact.name)
                .substring(0, 1)
                .toUpperCase() !== initialLetter)
          ) {
            acc.push(<S.LetterRow key={`${initialLetter}-${idx}`}>{initialLetter}</S.LetterRow>);
          }
          acc.push(
            <ContactSelectable
              action={action}
              className="contacts-list-contact-selectable"
              contact={c.contact}
              disabled={contactDisabled ? contactDisabled.includes(c.contact.id) && !isSelected : false}
              isLast={idx === arr.length - 1}
              isSelected={isSelected}
              key={c.phoneNumber + '_' + idx + '_' + c.contact.name + '_' + c.contact.companyName}
              onSelectContact={() => onSelectOutContact && onSelectOutContact(c, isSelected)}
              subtitle={c.contact.companyName || c.phoneFormatted}
            />,
          );

          return acc;
        }, [])}
    </>
  );
};

const EmptySearch = ({ className, search }: { className?: string; search: string }) => {
  return (
    <S.CenterContainer className={className} id="contact-list">
      <EmptyListResource
        text={__('Components.ProductsList.QueryEmptyResults', { search })}
        text2={__('Components.ProductsList.EmptyResultsTryAgain')}
        showButton={false}
        imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
      />
    </S.CenterContainer>
  );
};

export default React.memo(ContactsList);
