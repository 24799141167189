import styled, { css, ITheme } from '../../../../../styled-components';
import {
  Button,
  CheckBox as CB,
  ColumnContainer,
  FontIcon,
  Picture as P,
  RowContainer,
  Select,
  TextLabel,
} from '../../../../atoms';
import { ActionsModal as AM, FormContainer as FC, FormMenu, Table } from '../../../../molecules';

interface IStyledProps {
  theme: ITheme;
  clickable?: boolean;
}

export const ContainerImageAndText = styled(RowContainer)`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled(RowContainer)`
  overflow: hidden;
  height: 100%;
  flex: 1;
  max-width: 100%;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  -webkit-overflow-scrolling: touch;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: 0;
  padding-bottom: 0;
`;

export const FormContainer = styled(FC)`
  max-width: 1000px;
`;

export const Row = styled(RowContainer)`
  position: absolute;
  overflow: visible;
  width: 90%;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const FlexRow = styled(RowContainer)<IStyledProps>`
  align-items: center;
  justify-content: flex-start;
  ${(p: IStyledProps) =>
    p.clickable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `};
`;

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const ProductText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Semi};
  line-height: 1.38;
`;

export const ReferenceText = styled(Text)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: 5px;
  padding: 3px 7px 3px 6px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  line-height: 1.38;
  letter-spacing: normal;
  display: inline-block;
`;

export const TitleBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: flex;
  justify-content: center;
  margin: 24px 0 18px;
  line-height: 1.33;
`;

export const Message = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  display: flex;
  justify-content: center;
  margin: 18px 0 30px;
  line-height: 1.33;
  text-align: center;
`;

export const TextButtonCancel = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TextButtonRemove = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.red1};
`;

export const KebabIcon = styled(FontIcon)`
  font-size: 20px;
`;

export const MenuLeft = styled(FormMenu)`
  padding-right: 0;
  @media (max-width: 1325px) {
    display: none;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 40px;
`;

export const ProductTable = styled(Table)`
  .table-header-container {
    outline: none !important;
  }
  margin-bottom: 40px;
`;

export const AssociatedReferenceTable = styled(Table)`
  .table-header-container {
    outline: none !important;
  }
  margin-bottom: 40px;
`;

export const AddAssociateReferenceButton = styled(Button)`
  margin-bottom: 10px;
`;

export const ContainerButtonsDeleteModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;

export const ContainerButtonsAddReference = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 100px;
`;
export const ContainerButtonsConfirmModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 30px;
`;

export const ButtonCustom = styled(Button)`
  width: 170px;
`;

export const SelectProductModal = styled(AM)`
  overflow: visible;
  min-height: min(calc(var(--vh, 1vh) * 100) - 2 * 53px, 200px);
  z-index: 1;
`;

export const ConfirmModal = styled(AM)`
  min-height: min(calc(var(--vh, 1vh) * 100) - 2 * 53px, 200px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteReferenceModal = styled(AM)`
  min-height: 366px;
  min-width: 406px;
  z-index: 1;
`;

export const SelectContainer = styled(Select)``;

export const Picture = styled(P)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  margin: 0 auto;
`;

export const ProductImage = styled(P)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;
