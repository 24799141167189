import * as React from 'react';

import * as SW from '../Widgets.styled';
import * as S from './SummaryWidget.styled';

export interface IProps {
  className?: string;
  count: string;
  isHigher: boolean;
  previousChange: string;
  title: string;
  totalPrevious: string;
  totalPrice: string;
  width?: string;
}

const SummaryWidget: React.FC<IProps> = ({
  className,
  count,
  isHigher,
  previousChange,
  title,
  totalPrevious,
  totalPrice,
  width,
}) => {
  return (
    <SW.Container width={width} className={className}>
      <SW.TitleContainer>
        <SW.TextTitle>{title}</SW.TextTitle>
      </SW.TitleContainer>
      <S.GMVContainer>
        {totalPrice ? <S.TextGMV>{totalPrice}</S.TextGMV> : null}
        <S.TextCountOrders>{count}</S.TextCountOrders>
      </S.GMVContainer>
      <S.FooterContainer>
        {previousChange ? (
          <S.ChangeRow>
            <S.ArrowContainer isHigher={isHigher}>
              <S.ArrowIcon name="Arrow" disableHover={true} isHigher={isHigher} />
            </S.ArrowContainer>
            <S.Text>{previousChange}</S.Text>
          </S.ChangeRow>
        ) : null}
        {totalPrevious ? <S.TextGrey>{totalPrevious}</S.TextGrey> : null}
      </S.FooterContainer>
    </SW.Container>
  );
};

export default React.memo(SummaryWidget);
