import * as React from 'react';

import * as S from './FontIcon.styled';

export type IFontIconKeys = keyof typeof icons;

export interface IProps {
  className?: string;
  cursor?: string;
  disableHover?: boolean;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  name: IFontIconKeys;
  tooltipLabel?: string;
}

const FontIcon: React.FC<IProps> = ({
  className = '',
  cursor = 'pointer',
  disableHover = false,
  id,
  onClick,
  name,
  tooltipLabel,
}) => (
  <S.FontIcon
    className={className}
    content={getIconCode(name)}
    disableHover={disableHover}
    id={id}
    onClick={e => onClick && onClick(e)}
    cursor={cursor}
    title={tooltipLabel}
  />
);

export default React.memo(FontIcon);

export const icons = {
  Account: '\\e969',
  Adaptable: '\\e979',
  'Add-contact': '\\e950',
  'Add-picture': '\\e902',
  'Add-diffusion': '\\e953',
  'Add-group': '\\e951',
  'Add-image': '\\e955',
  'Add-more': '\\e90b',
  'Add-product': '\\e920',
  'Add-reaction': '\\e986',
  Address: '\\e959',
  Agenda: '\\e98e',
  Analysis: '\\e97a',
  Apple: '\\e928',
  'Any-place': '\\e964',
  Archive: '\\e939',
  Arrow: '\\e91f',
  Availability: '\\e990',
  Back: '\\e930',
  Block: '\\e944',
  Box: '\\e93d',
  Business: '\\e966',
  Buy: '\\e93e',
  Calendar: '\\e933',
  Cart: '\\e90e',
  'Cart-solid': '\\e99f',
  Catalog: '\\e96f',
  Chart: '\\e97c',
  'Chat-solid': '\\e99d',
  Chat: '\\e904',
  Check: '\\e91e',
  Checkmark: '\\e93b',
  'Chevron-down': '\\e919',
  'Chevron-right': '\\e916',
  Click: '\\e97b',
  Clip: '\\e905',
  Clipboard: '\\e92b',
  Clock: '\\e97d',
  Close: '\\e903',
  'Close-details': '\\e901',
  Crop: '\\e9b4',
  Grouped: '\\e983',
  Comment: '\\e95a',
  Connect: '\\e92a',
  Consumer: '\\e9a3',
  Currency: '\\e968',
  Decimals: '\\e98c',
  Delete: '\\e940',
  Diffusion: '\\e952',
  'Delivery-note': '\\e961',
  Document: '\\e95c',
  'Double-check': '\\e91d',
  Drag: '\\e956',
  Down: '\\e924',
  'Down-solid': '\\e906',
  Download: '\\e929',
  'Download-files': '\\e9b3',
  Edit: '\\e907',
  'Edit-excel': '\\e9a9',
  Emoji: '\\e98f',
  Enterprise: '\\e993',
  File: '\\e999',
  Edit_order: '\\e9b8',
  Error: '\\e98a',
  Error_order: '\\e9ba',
  'External-link': '\\e92e',
  'Eye-no-see': '\\e957',
  'Eye-see': '\\e958',
  Favorits: '\\e96b',
  'Favorite-add': '\\e931',
  Favorit: '\\e932',
  Filter: '\\e987',
  Folder: '\\e962',
  Forbidden: '\\e976',
  'Forward-app': '\\e948',
  'Forward-web': '\\e94b',
  'Gallery-solid': '\\e91b',
  Gallery: '\\e95b',
  Globe: '\\e980',
  Grid: '\\e996',
  Group: '\\e954',
  'Hamburger-menu': '\\e997',
  Help: '\\e9b1',
  Home: '\\e995',
  Horeca: '\\e9a4',
  Info: '\\e92f',
  Integration: '\\e971',
  Invoice: '\\e95f',
  Kebab: '\\e90f',
  Lead: '\\e9bb',
  Like: '\\e9b2',
  Line: '\\e984',
  'Leave-group': '\\e98d',
  'List-view': '\\e94a',
  Link: '\\e994',
  'Log-out': '\\e92d',
  'Magic-orders': '\\e9b0',
  Message: '\\e985',
  'Microphone-on': '\\e975',
  'Microphone-off': '\\e974',
  Notification: '\\e90c',
  'Notification-off': '\\e9ab',
  'Notification-solid': '\\e946',
  'Notification-solid-off': '\\e947',
  Order: '\\e90d',
  Others: '\\e95d',
  Padlock: '\\e936',
  Pallet: '\\e935',
  Pdf: '\\e98b',
  Phone: '\\e911',
  'Pickup-package': '\\e926',
  'Pickup-truck': '\\e925',
  Piece: '\\e934',
  Pin: '\\e93a',
  Playstore: '\\e927',
  Plus: '\\e900',
  Poll: '\\e9b6',
  Price: '\\e941',
  'Price-group': '\\e97e',
  'Multiple-price-group': '\\e97f',
  Producer: '\\e9a5',
  'Producer-solid': '\\e9b5',
  Profile: '\\e910',
  QR: '\\e94f',
  'Quality-control': '\\e95e',
  'Question-mark': '\\e938',
  Read: '\\e981',
  Recent: '\\e96d',
  Reception: '\\e9a8',
  Recommended: '\\e96c',
  Refresh: '\\e914',
  'Reply-swipe': '\\e943',
  Report: '\\e99c',
  Right: '\\e923',
  Sales: '\\e988',
  'Sales-solid': '\\e9a0',
  Search: '\\e915',
  Security: '\\e967',
  Send: '\\e913',
  'Send-email': '\\e978',
  'Send-message': '\\e94d',
  'Send-solid': '\\e91c',
  Settings: '\\e970',
  'Section-settings': '\\e973',
  Sections: '\\e99a',
  'Share-web': '\\e94c',
  Share: '\\e94e',
  'Shop-online': '\\e96e',
  Simplify: '\\e965',
  Sort: '\\e998',
  'Speed-up': '\\e963',
  Success: '\\e93f',
  Successful: '\\e9a2',
  Supermarket: '\\e9a6',
  'Team-icon': '\\e972',
  'Team-icon-solid': '\\e9a1',
  'Team-zero-case': '\\e977',
  Trash: '\\e921',
  Truck: '\\e992',
  'Turn-on': '\\e922',
  'No-read': '\\e982',
  Upload: '\\e96a',
  'Unread-orders': '\\e991',
  'Unread-orders-solid': '\\e99e',
  Warning: '\\e989',
  Warning_order: '\\e9bc',
  'Warning-solid': '\\e942',
  Weight: '\\e937',
  Wholesaler: '\\e9a7',
  Workspace: '\\e912',
  World: '\\e9ad',
};

const getIconCode = (name: string): string => {
  return icons[name] || '';
};
