import {
  __,
  IReferenceResponse,
  IReferenceResponsePageable,
  referenceActions,
  IReferenceResponseAPI,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { ROUTE_PATHS } from '../../../../constants';
import getPath from '../../../../util/routes';
import { Button } from '../../../atoms';
import { EmptyListResource } from '../../../molecules';
import ReferenceTable from '../../../molecules/ReferenceTable';
import Workspace from '../../Workspace/Workspace.component';
import * as S from './ReferenceProfile.styled';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
}>;

export type IProps = IRouteProps;

const ReferenceProfile: React.FC<IProps> = ({
  match: {
    params: { workspaceId },
  },
}) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const history = useHistory();

  const [referenceResponses, setReferenceResponses] = React.useState<IReferenceResponsePageable>({
    elements: [],
    is_first_page: true,
    is_last_page: false,
    page: 1,
    total_pages: 0,
    total_elements: 0,
  });

  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const [searchState, setSearchState] = React.useState({
    text: '',
    index: '',
    status: ['active', 'unavailable'],
    sort: 'updatedAt',
    type: '',
    sellers: [],
    warehouses: [],
    references: [],
  });
  const [debouncedValue, setDebouncedValue] = React.useState(searchState.text);
  let debounceTimer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      dispatch(
        referenceActions.getPageableReferences(
          +workspaceId,
          (res: IReferenceResponsePageable) => {
            setReferenceResponses(prevState => ({
              ...res,
              elements: [
                ...prevState.elements,
                ...res.elements.filter(
                  newElement => !prevState.elements.some(existingElement => existingElement.id === newElement.id),
                ),
              ],
            }));
            setLoading(false);
          },
          {
            page,
            offset: 0,
            order: 'desc',
            size: 50,
            sort_by: 'created_at',
            name: searchState.text,
          },
        ),
      );
    }
  }, [workspaceId, page, searchState.text]);
  // Sort and filter references
  const [filteredReferences, setFilteredReferences] = React.useState<Array<IReferenceResponse>>([]);

  React.useEffect(() => {
    setFilteredReferences(
      referenceResponses.elements.filter(ref => {
        return searchState.text.length > 0 ? ref.name.toLowerCase().includes(searchState.text.toLowerCase()) : true;
      }),
    );
  }, [searchState, referenceResponses.elements]);

  const handleLoadMore = () => {
    if (!loading && !referenceResponses.is_last_page) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleInputChange = React.useCallback((t: string) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setDebouncedValue(t);
    }, 300);
  }, []);

  React.useEffect(() => {
    setSearchState(prevState => ({ ...prevState, text: debouncedValue }));
  }, [debouncedValue]);

  return (
    <Workspace
      subtitle={''}
      title={''}
      tabSelected={'referential'}
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={true}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.referential'),
        },
      ]}
    >
      <S.Row>
        <S.WsWrapper>
          {referenceResponses.elements.length > 0 ? (
            <>
              <S.HeaderDashboards>
                <S.HeaderFilters>
                  <S.LeftFiltersRow>
                    <S.SimpleSearch
                      id="search-references"
                      placeHolder={__('Components.Referential.reference.search')}
                      onChange={(t: string) => handleInputChange(t)}
                    />
                  </S.LeftFiltersRow>
                  <S.RightCtaRow>
                    <Button
                      onClick={() => {
                        history.push(
                          getPath({
                            path: ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN,
                            workspaceId,
                            referentialId: 'new',
                          }),
                        );
                      }}
                    >
                      {__('Components.Referential.reference.add')}
                    </Button>
                  </S.RightCtaRow>
                </S.HeaderFilters>
              </S.HeaderDashboards>
              <ReferenceTable
                references={filteredReferences.length > 0 ? filteredReferences : referenceResponses.elements}
                workspaceId={workspaceId}
                onLoadMore={handleLoadMore}
              />
            </>
          ) : (
            <EmptyListResource
              text={__('Components.Referential.zero_case.title')}
              text2={__('Components.Referential.zero_case.subtitle')}
              buttonText={__('Components.Referential.zero_case.cta')}
              imageUrl={'https://media.consentio.co/image/upload/v1641830463/ilustrations/Wholesale.png'}
              showButton={true}
              buttonAction={() => {
                history.push(
                  getPath({
                    path: ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN,
                    workspaceId,
                    referentialId: 'new',
                  }),
                );
              }}
            />
          )}
        </S.WsWrapper>
      </S.Row>
    </Workspace>
  );
};

export default React.memo(ReferenceProfile);
