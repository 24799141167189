import { Api, broadcastActions, CHANNEL_DISPLAY, IBroadcast, notificationsActions } from 'common-services';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import BroadcastInfo, { DispatchProps, StateProps } from '../components/organisms/BroadcastInfo';
import { IReduxState } from '../reducers';
import { IBroadcastRouterProps } from './chat-broadcast-container';

const emptyBroadcast: IBroadcast = {
  avatarColor: { background: '', text: '' },
  createdAt: 0,
  display: CHANNEL_DISPLAY.NORMAL,
  id: '',
  invitePolicy: 'admin',
  lastMessageAtOld: 0,
  members: {},
  name: '',
  numberOfMembers: 0,
  updatedAt: 0,
};
function mapStateToPropsDetails(state: IReduxState, props: IBroadcastRouterProps): StateProps {
  const {
    broadcast: { broadcasts },
  } = state;
  const bId = Number(props.match.params.channelId);
  const broadcast = bId ? broadcasts[bId] : emptyBroadcast;
  return {
    myId: state.user.user.id,
    contacts: state.contact.inConsentio,
    broadcast,
  };
}

function mapDispatchToPropsDetails(
  dispatch: ThunkDispatch<IReduxState, Api, AnyAction>,
  props: IBroadcastRouterProps,
): DispatchProps {
  return bindActionCreators(
    {
      addMembers: broadcastActions.addMembers,
      createBroadcast: broadcastActions.createBroadcast,
      deleteMembers: broadcastActions.deleteMembers,
      getMembers: broadcastActions.getMembers,
      notificationShow: notificationsActions.notificationShow,
      updateBroadcast: broadcastActions.updateBroadcast,
    },
    dispatch,
  );
}

export default connect(mapStateToPropsDetails, mapDispatchToPropsDetails)(BroadcastInfo);
