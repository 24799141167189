import { __, constants, countrySelectors, prodTypeSelectors } from 'common-services';
import { getCategoryText } from 'common-services/dist/parsers';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { FacetFilter } from '../../atoms';
import { getOrganicTypes } from '../../organisms/ProductEdit/ProductEdit.constants';
import * as S from './ProductFilter.styled';

export interface IProps {
  changeSearchState: (s: ISearch) => void;
  clearFilters: () => void;
  className?: string;
  facets: IFacets;
  facetsGlobal: IFacets;
  hideStatus?: boolean;
  isPricegroups?: boolean;
  me: IUser;
  numberOfHeaders?: number;
  onHide?: (hide: boolean) => void;
  searchState: ISearch;
  showClosed?: boolean;
  showOver?: 'always' | 'never' | 'only-ipad';
  boxTypes?: Array<ICustomBoxType>;
  palletTypes?: Array<ICustomPalletType>;
  suppliers?: Array<ISuppliersSearch>;
  contacts?: { [id: number]: IContact };
}
const Filters: React.FC<IProps> = ({
  changeSearchState,
  onHide,
  className,
  clearFilters,
  facets,
  facetsGlobal,
  hideStatus,
  numberOfHeaders,
  searchState,
  showClosed,
  showOver = 'never',
  boxTypes,
  palletTypes,
  suppliers,
  me,
  contacts,
}) => {
  const countries = useSelector(countrySelectors.getCountries);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const [hidden, setHidden] = React.useState(showClosed);
  React.useEffect(() => {
    setHidden(showClosed);
  }, [setHidden, showClosed]);
  const hasFilters =
    searchState.status?.length ||
    searchState.sections?.length ||
    searchState.type?.length ||
    searchState.priceType?.length ||
    searchState.type?.length ||
    searchState.variety?.length ||
    searchState.origin?.length ||
    searchState.category?.length ||
    searchState.brand?.length ||
    searchState.organic?.length ||
    searchState.boxId?.length ||
    searchState.palletId?.length ||
    searchState.catalogId?.length ||
    searchState.packaging?.length ||
    searchState.size?.length;

  return (
    <S.Wrapper hide={hidden} showOver={showOver} numberOfHeaders={numberOfHeaders}>
      <S.FacetsContainer className={className} hide={hidden}>
        <S.Header>
          {hasFilters ? (
            <S.Clear withoutPadding={true} type="skip" hide={hidden} onClick={clearFilters}>
              {__('Facets.clear')}
            </S.Clear>
          ) : null}
          <S.Title>{__('Facets.filter_by')}</S.Title>
          <S.CloseIcon
            name="Close"
            onClick={() => {
              onHide?.(true);
              setHidden(true);
            }}
            hide={hidden}
          />
        </S.Header>
        <S.FiltersBody>
          {!hideStatus && facetsGlobal.status && Object.keys(facetsGlobal.status).length ? (
            <FacetFilter
              title={__('Facets.availability')}
              options={Object.keys(facets.status || facetsGlobal.status).map(status => ({
                name:
                  status === 'active'
                    ? __('Facets.active')
                    : status === 'inactive'
                    ? __('Facets.inactive')
                    : __('Facets.unavailable'),
                key: status,
                count: facets.status?.[status] || facetsGlobal.status[status],
                checked: searchState.status?.includes(status),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, status: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal.sections && Object.keys(facetsGlobal.sections).length ? (
            <FacetFilter
              title={__('Facets.sections')}
              options={Object.keys(facets.sections || facetsGlobal.sections).map(section => ({
                name: section,
                key: section,
                count: facets.sections?.[section] || facetsGlobal.sections[section],
                checked: searchState.sections?.includes(section),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, sections: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal.price_type && Object.keys(facetsGlobal.price_type).length ? (
            <FacetFilter
              title={__('WorkspacePriceGroupEdit.table.adjustment_type')}
              options={Object.keys(facets.price_type || facetsGlobal.price_type).map(priceType => ({
                name: constants.getPriceAdjustmentTypeLiteral(priceType.toUpperCase() as PRICE_ADJUSTMENT_TYPE),
                key: priceType,
                count: facets.price_type?.[priceType] || facetsGlobal.price_type[priceType],
                checked: searchState.priceType?.includes(priceType),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, priceType: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal['type.type'] && Object.keys(facetsGlobal['type.type']).length ? (
            <FacetFilter
              title={__('Facets.type')}
              options={Object.keys(facets['type.type'] || facetsGlobal['type.type'])
                .sort()
                .map(type => ({
                  name: prodTypes?.[type]?.name || __('Facets.unspecified'),
                  key: type,
                  count: facets['type.type']?.[type] || facetsGlobal['type.type'][type],
                  checked: searchState.type?.includes(type),
                }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, type: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal['type.variety'] && Object.keys(facetsGlobal['type.variety']).length ? (
            <FacetFilter
              title={__('Facets.variety')}
              options={Object.keys(facets['type.variety'] || facetsGlobal['type.variety']).map(variety => ({
                name: variety || __('Facets.unspecified'),
                key: variety,
                count: facets['type.variety']?.[variety] || facetsGlobal['type.variety'][variety],
                checked: searchState.variety?.includes(variety),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, variety: v as Array<string> })}
            />
          ) : null}
          {config.TOGGLE_SUPPLIER_FILTER.enabled &&
          suppliers &&
          facetsGlobal.catalog_id &&
          Object.keys(facetsGlobal.catalog_id).length ? (
            <FacetFilter
              title={__('Facets.provider')}
              options={Object.keys(facets.catalog_id || facetsGlobal.catalog_id).map(catalogId => ({
                name: suppliers?.find(s => s.workspaceId === Number(catalogId)).companyName,
                key: catalogId,
                type: 'comment',
                image: suppliers?.find(s => s.workspaceId === Number(catalogId)).companyLogo,
                count: facets.catalog_id?.[catalogId] || facetsGlobal.catalog_id[catalogId],
                checked: searchState.catalogId?.includes(Number(catalogId)),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, catalogId: (v as Array<string>).map(c => Number(c)) })}
            />
          ) : null}
          {facetsGlobal['type.origin'] && Object.keys(facetsGlobal['type.origin']).length ? (
            <FacetFilter
              title={__('Facets.origin')}
              options={Object.keys(facets['type.origin'] || facetsGlobal['type.origin']).map(origin => ({
                name: Object.values(countries).find(c => c.iso2Code === origin)?.name || __('Facets.unspecified'),
                key: origin,
                count: facets['type.origin']?.[origin] || facetsGlobal['type.origin'][origin],
                checked: searchState.origin?.includes(origin),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, origin: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal.category && Object.keys(facetsGlobal.category).length ? (
            <FacetFilter
              title={__('Facets.category')}
              options={Object.keys(facets.category || facetsGlobal.category).map(
                (category: (typeof constants.PRODUCT_CATEGORIES)[number]) => ({
                  name: getCategoryText(category) || __('Facets.unspecified'),
                  key: category,
                  count: facets.category?.[category] || facetsGlobal.category[category],
                  checked: searchState.category?.includes(category),
                }),
              )}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, category: v as Array<string> })}
            />
          ) : null}
          {facetsGlobal.brand && Object.keys(facetsGlobal.brand).length ? (
            <FacetFilter
              title={__('Facets.brand')}
              options={Object.keys(facets.brand || facetsGlobal.brand).map(brand => ({
                name: brand || __('Facets.unspecified'),
                key: brand,
                count: facets.brand?.[brand] || facetsGlobal.brand[brand],
                checked: searchState.brand?.includes(brand),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, brand: v as Array<string> })}
            />
          ) : null}

          {facetsGlobal.packaging && Object.keys(facetsGlobal.packaging).length ? (
            <FacetFilter
              title={__('Facets.packaging')}
              options={Object.keys(facets.packaging || facetsGlobal.packaging).map(packaging => ({
                name: packaging,
                key: packaging,
                count: facets.packaging?.[packaging] || facetsGlobal.packaging[packaging],
                checked: searchState.packaging?.includes(packaging),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, packaging: v as Array<string> })}
            />
          ) : null}
          {boxTypes && facetsGlobal.box_id && Object.keys(facetsGlobal.box_id).length ? (
            <FacetFilter
              title={__('Facets.box_type')}
              options={Object.keys(facets.box_id || facetsGlobal.box_id).map(boxId => ({
                name: boxTypes.find(b => b.id === Number(boxId))?.name || __('Facets.unspecified'),
                key: boxId,
                count: facets.box_id?.[boxId] || facetsGlobal.box_id?.[boxId],
                checked: searchState.boxId?.includes(Number(boxId)),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, boxId: (v as Array<string>).map(b => Number(b)) })}
            />
          ) : null}
          {palletTypes && facetsGlobal.palletId && Object.keys(facetsGlobal.palletId).length ? (
            <FacetFilter
              title={__('Facets.pallet_type')}
              options={Object.keys(facets.pallet_id || facetsGlobal.pallet_id).map(palletId => ({
                name: palletTypes.find(p => p.id === Number(palletId))?.name || __('Facets.unspecified'),
                key: palletId,
                count: facets.pallet_id?.[palletId] || facetsGlobal.pallet_id?.[palletId],
                checked: searchState.palletId?.includes(Number(palletId)),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, palletId: (v as Array<string>).map(p => Number(p)) })}
            />
          ) : null}
          {facetsGlobal.organic && Object.keys(facetsGlobal.organic).length ? (
            <FacetFilter
              title={__('Facets.organic')}
              options={getOrganicTypes()
                .filter(o => facets.organic?.[o.value] || facetsGlobal.organic[o.value])
                .map(o => ({
                  name: o.label,
                  key: o.value,
                  count: facets.organic?.[o.value] || facetsGlobal.organic[o.value],
                  checked: searchState.organic?.includes(o.value as IOrganic),
                }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, organic: v as Array<IOrganic> })}
            />
          ) : null}
          {facetsGlobal.size && Object.keys(facetsGlobal.size).length ? (
            <FacetFilter
              title={__('Facets.size')}
              options={Object.keys(facets.size || facetsGlobal.size).map(size => ({
                name: size || __('Facets.unspecified'),
                key: size,
                count: facets.size?.[size] || facetsGlobal.size[size],
                checked: searchState.size?.includes(size),
              }))}
              numVisibleOptions={4}
              startFolded={false}
              onChange={v => changeSearchState({ ...searchState, size: v as Array<string> })}
            />
          ) : null}
        </S.FiltersBody>
        <S.ApplyButton
          type="principal"
          onClick={() => {
            onHide?.(true);
            setHidden(true);
          }}
        >
          {__('Facets.apply_filters')}
        </S.ApplyButton>
      </S.FacetsContainer>
    </S.Wrapper>
  );
};

export default React.memo(Filters);
